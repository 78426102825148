import classNames from 'classnames';
import styles from './rules-matcher.module.scss';
import { Icon16CheckMark } from '../../../../components/UI/icons';

interface IProps {
  text: string;
  isActive: boolean;
  error?: boolean;
}

export const RuleItem = ({ isActive, text, error }: IProps) => {
  return (
    <div className={classNames(styles.containerItem, styles.text, {[styles.active]: isActive}, {[styles.error]: error})}>
      <Icon16CheckMark />
      {text}
    </div>
  );
};
