import { memo } from 'react';

import classNames from 'classnames';
import styles from './quizStep.module.scss';

import { AnswerTypeEnum, IQuizQuestion } from '../../../../../../interfaces/quiz';
import { IQuizResult } from '../store/QuizState';
import { useViewQuizContext } from '../ViewQuizContext';

export type QuizStepStatus = 'unselected' | 'selected' | 'success' | 'error' | 'result';

interface IProps {
  question: IQuizQuestion;
  selectQuestion?: IQuizQuestion | null;
  result: IQuizResult | null;
  isModeration: boolean;
  index: number;
}

export const QuizStepItem = memo(({ question, selectQuestion, index, isModeration, result }: IProps) => {
  const { onSelectQuestion } = useViewQuizContext();
  const onSelectQuestionHandler = () => onSelectQuestion(question);
  const isFreeAnswer =
    question.answer_type === AnswerTypeEnum.audio_input || question.answer_type === AnswerTypeEnum.text_input;
  const toModeration = isFreeAnswer && isModeration;
  const selected = selectQuestion?.id === question.id;
  const isPassed = !!question.choices;
  const isWrong = question.is_passed_with === false;
  const isSuccess = question.is_passed_with === true;
  const moderationNotAccepted = !!result;

  return (
    <div
      className={classNames({
        [styles.containerUnselected]: !selected && !isPassed,
        [styles.containerSelected]: selected && !isPassed,
        [styles.containerErrorSelected]: selected && isWrong,
        [styles.containerError]: isWrong,
        [styles.containerSuccess]: isSuccess,
        [styles.containerSuccessSelected]: isSuccess && selected,
        [styles.containerToModerationSelected]: isPassed && toModeration && selected,
        [styles.containerToModerationUnSelected]: isPassed && toModeration && !selected
      })}
      onClick={onSelectQuestionHandler}
    >
      <div className={styles.text}>{index + 1}</div>
    </div>
  );
});
