import actionCreatorFactory from 'typescript-fsa';

import { AnswerPayloadType, AnswerTypeEnum, IAudioQuizAnswer } from '../../../../../../interfaces/quiz';
import { IViewQuiz, QuizTypeAction } from './QuizState';

export const actionCreator = actionCreatorFactory();

export const QuizActions = {
  getQuiz: actionCreator.async<{ courseId: string; quizId: string }, IViewQuiz, Error>('GET_STUDENT_QUIZ'),
  completeAnswer: actionCreator.async<
    {
      courseId: string;
      blockId: string;
      questionId: string;
      action: QuizTypeAction;
      answers: AnswerPayloadType<AnswerTypeEnum>;
    },
    void,
    Error
  >('COMPLETE_ANSWER'),
  tryAgain: actionCreator.async<
    {
      courseId: string;
      blockId: string;
      action: QuizTypeAction;
    },
    void,
    Error
  >('TRY_QUIZ_AGAIN')
};
