import { useState } from 'react';

import styles from './drop-menu-item.module.scss';
import { Icon32User } from '../../../components/UI/icons';

import { IFile } from '../../../interfaces';
import { OrganizationImg } from '../organization-img/OrganizationImg';

interface IProps {
  avatar: string;
  organizationImg: IFile | null;
  organizationName: string;
  width?: number;
  height?: number;
}

export const DropMenuItem = ({ avatar, organizationImg, organizationName, width = 48, height = 48 }: IProps) => {
  const [isLoaded, setIsLoaded] = useState(false);

  const onLoadAvatar = () => {
    setIsLoaded(true);
  };

  return (
    <div className={styles.logout}>
      <img
        className={styles.avatar}
        src={avatar}
        alt={'avatar'}
        onLoad={onLoadAvatar}
        style={isLoaded ? {} : { display: 'none' }}
        width={width}
        height={height}
        referrerPolicy={'no-referrer'}
      />
      {organizationImg && (
        <OrganizationImg
          organizationImg={organizationImg}
          organizationName={organizationName}
          className={styles.organization}
          textClassName={styles.name}
        />
      )}
      {!isLoaded && (
        <div className={styles.placeholder}>
          <Icon32User className={styles.avatar} />
        </div>
      )}
    </div>
  );
};
