import React from 'react';
import { useSelector } from 'react-redux';

import classNames from 'classnames';
import styles from './funnel-state-message.module.scss';
import { Icon32Archive, Icon32Play } from '../../../../components/UI/icons';

import { BuilderType, BuilderTypeEnum } from '../../../common/builder/store/BuilderActionAsync';
import { funnelStates } from '../funnel-item/FunnelItem';
import { FunnelSelector } from '../store/FunnelSelector';

interface IProps {
  builderType: BuilderType;
  id: string;
}

export const FunnelStateMessage = ({ id, builderType }: IProps) => {
  const funnel = useSelector((state) => FunnelSelector(state, id));
  const isShow =
    builderType === BuilderTypeEnum.funnel &&
    funnel &&
    ['publish', 'archive'].includes(funnelStates[funnel.state].action);

  return funnel && isShow ? (
    <div
      className={classNames(styles.messageContainer, {
        [styles.publish]: funnelStates[funnel.state].action === 'publish',
        [styles.archive]: funnelStates[funnel.state].action === 'archive'
      })}
    >
      <div className={styles.icon}>
        {funnel.state === 1 && <Icon32Play />}
        {funnel.state === 4 && <Icon32Archive />}
      </div>
      <div>
        <div className={styles.title}>
          {funnel.state === 1 && 'This is published funnel'}
          {funnel.state === 4 && 'This is archived funnel'}
        </div>
        <div className={styles.message}>
          {funnel.state === 1 && 'Every changes will stop it automatically'}
          {funnel.state === 4 && 'Published from drafts only'}
        </div>
      </div>
    </div>
  ) : null;
};
