import { memo, useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { fileUploadActionAsync } from 'src/app/layout/shared-components/upload-worker/store/FileUploadActionAsync';
import { chain } from 'lodash';

import classNames from 'classnames';
import styles from './product-card.module.scss';
import { Icon32Cross, IconPencilOnPaper } from '../../../../components/UI/icons';

import { Toast } from '../../../../components/UI';
import { arrStrForSelect, getSeparatedDate, getUUID, lazySchema, validationSchema } from '../../../../helpers';
import { IFile } from '../../../../interfaces';
import { settingsActions } from '../../../../store/settings';
import { FieldSchemaSelector } from '../../field/store/FieldSelector';
import { ProductForm } from '../product-form/ProductForm';
import { ProductSelector } from '../store/ProductDataSelector';
import { ProductsActionAsync } from '../store/ProductsActionAsync';
import { IProductField, ProductType } from '../store/ProductsState';

interface IProps {
  productId?: string;
  type?: ProductType;
  isDelete?: boolean;
  onCreate?: () => void;
}

export const ProductCard = memo(({ productId, type, isDelete, onCreate }: IProps) => {
  const { handleSubmit, setValue, reset, formState } = useFormContext();
  const dispatch = useDispatch();
  const fieldSchemaMemo = useMemo(FieldSchemaSelector, []);
  const { fieldsSchema } = useSelector((state) => fieldSchemaMemo(state));
  const productMemo = useMemo(ProductSelector, [productId]);
  const productData = useSelector((state) => productMemo(state, productId));
  const isCourseProduct = type === 'course';

  const onSuccess = () => {
    onCreate?.();
    if (productId) {
      dispatch(ProductsActionAsync.getProduct(productId));
      Toast('success', 'Success');
    }
  };

  const onSave = async (data: IProductField) => {
    const fields = chain(fieldsSchema.filter((x) => x.entity === (isCourseProduct ? 'course' : 'product')))
      .keyBy('id')
      .mapValues((v) => (v.scope !== 'custom' ? v.scope : v.type))
      .value();
    try {
      const dataValidation = await lazySchema(fields).validate(data, { abortEarly: false });
      const res: Record<string, string | string[] | IFile | null> = {};
      for (const [key, value] of Object.entries(dataValidation)) {
        const field = fieldsSchema.find((x) => x.id === key);
        if (value !== null && field) {
          const validValue = validationSchema(field.type, value);
          if (validValue !== null) {
            res[key] = validValue;
          }
        }
      }
      try {
        const image = fieldsSchema.find((x) => x.type === 'image');
        if (image) {
          if (data[image.id]?.file) {
            await dispatch(
              fileUploadActionAsync.retrieveUrlAndUploadFile(data[image.id].file, (result) => {
                res[image.id] = result;
              })
            );
          }
        }
      } catch (e) {
        console.log('%c⇒ e', 'color: #FF5370', e);
      }

      if (Object.keys(res).length !== 0) {
        if (productId) {
          dispatch(ProductsActionAsync.editProduct(productId, res, onSuccess));
        } else {
          dispatch(ProductsActionAsync.createProduct(res, onSuccess));
        }
        onClose();
      }
      if (Object.keys(res).length === 0) {
        Toast('error', 'At least one field must be filled');
      }
    } catch (error) {
      console.log(error);
      Toast('error', error?.message);
    }
  };

  const onClose = () => {
    reset();
    dispatch(settingsActions.setPayloadShowing({ isPayloadShow: false }));
  };

  const onError = (errors: IProductField) => {
    for (let error in errors) {
      Toast('error', errors[error].message || errors[error].time?.message);
    }
  };

  const openEditFields = () => {
    dispatch(settingsActions.setSidebarShowing({ isSidebarShow: true }));
  };

  useEffect(() => {
    const selects = fieldsSchema.filter((x) => x.type === 'select' || x.type === 'multiselect');
    const dateTimes = fieldsSchema.filter((x) => x.type === 'date_time');
    for (let index = 0; index < fieldsSchema.length; index++) {
      setValue(fieldsSchema[index].id, null);
      if (fieldsSchema[index].type === 'price') {
        setValue(`${fieldsSchema[index].id}.amount`, null);
      }
    }

    if (productData && productData.product.fields) {
      for (const [key, value] of Object.entries(productData.product.fields)) {
        if (selects.find((x) => x.id === key && x.payload.display_options === false)) {
          setValue(key, Array.isArray(value) ? arrStrForSelect(value) : { label: value, value: getUUID() });
        } else if (dateTimes.find((x) => x.id === key) && !Array.isArray(value)) {
          const dateRes = getSeparatedDate(value);
          setValue(key, dateRes);
          setValue(`${key}.zo`, dateRes.zo);
          setValue(`${key}.time`, dateRes.time);
        } else {
          setValue(key, value);
        }
      }
    }
  }, [fieldsSchema, productData, productId, reset, setValue]);

  return (
    <>
      <button onClick={onClose} className={classNames('btn btn-icon', styles.close)}>
        <Icon32Cross />
      </button>
      <ProductForm product={productData?.product} type={type} isDelete={isDelete} />
      <div className={classNames('action-buttons', styles.actionButtons)}>
        <button type={'button'} onClick={openEditFields} className={classNames('btn btn-sm btn-icon-right mr-auto')}>
          Edit fields
          <IconPencilOnPaper className={'icon'} />
        </button>
        <button type="button" className="btn btn-sm mr-3 btn-gradient-secondary" onClick={onClose}>
          Cancel
        </button>
        <button
          type="button"
          className={classNames('btn', 'btn-sm', 'btn-gradient-primary', styles.save)}
          onClick={handleSubmit(onSave, onError)}
          disabled={!formState.isDirty}
        >
          Save
        </button>
      </div>
    </>
  );
});
