import styles from './select-image-preview.module.scss';

import { CommonModal } from '../../../../../../components/UI';
import { Image } from '../../../../../../components/UI/image-components/image/Image';
import { IAttachment } from '../../../../../../interfaces/quiz';

type Props = {
  show: boolean;
  onClose: () => void;
  img: IAttachment;
};

export const SelectImagePreview = ({ onClose, show, img }: Props) => {
  return (
    <CommonModal
      show={show}
      showClose={false}
      onClose={onClose}
      params={{
        dialogClassName: styles.dialog,
        contentClassName: styles.content,
        className: styles.modal
      }}
    >
      <Image bucket_name={img.bucket_name} object_name={img.object_name} className={styles.img} />
    </CommonModal>
  );
};
