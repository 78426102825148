import { FC } from 'react';

import styles from './common-auth.module.scss';
import logo from '../../../../../assets/svg/logo.svg';

export const CommonAuth: FC = ({ children }) => {
  return (
    <div className={styles.container}>
      <div className={styles.logo}>
        <img src={logo} alt={'everact'} />
      </div>
      {children}
      <div className={styles.copy}>©{new Date().getFullYear()} everact.io</div>
    </div>
  );
};
