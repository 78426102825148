import { Col, Row } from 'react-bootstrap';

import classnames from 'classnames';
import styles from './main-contact-list.module.scss';
import { IconSmallLock } from '../../../../../../../components/UI/icons';

export const MainContactList = () => {
  return (
    <Row className={classnames('py-0', styles.container)}>
      <Col className={styles.textDefault}>Contact list</Col>
      <Col className={classnames('px-1', styles.icon)}>
        <IconSmallLock />
      </Col>
    </Row>
  );
};
