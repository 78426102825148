import { FormControl, FormControlProps } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';

import { IField } from '../../../../modules/crm/field';

interface IProps {
  field: IField;
  params?: FormControlProps;
}

export const PriceField = ({ field, params }: IProps) => {
  const { register } = useFormContext();

  return (
    <FormControl
      type="number"
      size={'sm'}
      className={'form-control-underline'}
      placeholder={field.payload.title}
      {...register(`${field.id}.amount`)}
      {...params} />
  );
};
