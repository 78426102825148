import { ICreateDomain, IDomain } from '../interfaces/domain';
import { baseFetch } from './BaseFetch';

export const getDomains = async () => {
  return await baseFetch<{}, IDomain[]>('/integrations/studentdomain', {}, 'GET', true);
};

export const checkDomainStatus = async (domainId: string) => {
  return await baseFetch<{}, IDomain>(`/integrations/studentdomain/${domainId}`, {}, 'GET', true);
};

export const createDomain = async (params: ICreateDomain) => {
  return await baseFetch<ICreateDomain, IDomain>('/integrations/studentdomain', params, 'POST', true);
};

export const editDomain = async (id: string, subdomain: string) => {
  return await baseFetch<{ subdomain: string }, IDomain>(
    `/integrations/studentdomain/${id}`,
    { subdomain },
    'PUT',
    true
  );
};

export const removeDomain = async (id: string) => {
  return await baseFetch<{}, IDomain>(`/integrations/studentdomain/${id}`, {}, 'DELETE', true);
};
