import { useAudioPlayer } from 'react-use-audio-player';

import classNames from 'classnames';
import styles from './audio-btn.module.scss';
import { Icon32MultimediaPause, Icon32MultimediaPlayFilled, Icon32Reload } from '../../UI/icons';

import { Button } from '../../UI';

export const AudioBtn = () => {
  const { togglePlayPause, playing, ended } = useAudioPlayer();

  return (
    <div className={styles.container}>
      <Button
        onClick={togglePlayPause}
        className={classNames(
          styles.btn,
          { 'btn-gradient-primary': playing || (!ended && !playing) },
          { [styles.reload]: ended && !playing }
        )}
        withIcon={false}
      >
        {playing && <Icon32MultimediaPause />}
        {!ended && !playing && <Icon32MultimediaPlayFilled />}
        {ended && !playing && <Icon32Reload />}
      </Button>
    </div>
  );
};
