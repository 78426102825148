import { IFunnel } from '../../../../interfaces';
import { LoadState } from '../../../../store';

export interface IFunnelState {
  error: Error | null;
  loadState: LoadState;
  funnels: IFunnel[];
}

export const FunnelInitialState: IFunnelState = {
  error: null,
  loadState: LoadState.firstLoad,
  funnels: []
};
