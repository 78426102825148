import { FC } from 'react';

import styles from './common-signup.module.scss';
import logo from '../../../../../assets/svg/logo.svg';

export const CommonSignUp: FC = ({ children }) => (
  <div className={styles.container}>
    <div className={styles.logo}>
      <img src={logo} alt={'everact'} />
    </div>
    <div className={styles.form}>{children}</div>
    <div className={styles.copy}>©{new Date().getFullYear()} everact.io</div>
  </div>
);
