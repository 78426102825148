import actionCreatorFactory from 'typescript-fsa';

import {
  CreateSenderErrorResponse,
  IEmail,
  IError,
  IMessage,
  IPaginationResponse,
  ISender
} from '../../../../interfaces';

export const actionCreator = actionCreatorFactory();

export const EmailActions = {
  getMessages: actionCreator.async<void, IPaginationResponse<IMessage[]>, Error>('GET_MESSAGES'),
  getMessage: actionCreator.async<string, IMessage, Error>('GET_MESSAGE'),
  createMessage: actionCreator.async<void, IMessage, Error>('CREATE_MESSAGE'),
  editMessage: actionCreator.async<{id: string}, IMessage, Error>('EDIT_MESSAGE'),
  duplicateMessage: actionCreator.async<string, IMessage, Error>('DUPLICATE_MESSAGE'),
  deleteMessage: actionCreator.async<string, IMessage, Error>('DELETE_MESSAGE'),
  sendTestEmailMessageWithBody: actionCreator.async<{ payload: IEmail; email: string }, void, Error>(
    'SEND_TEST_EMAIL_MESSAGE_WITH_BODY'
  ),
  sendTestEmailMessageWithoutBody: actionCreator.async<{ messageId: string; email: string }, void, Error>(
    'SEND_TEST_EMAIL_MESSAGE_WITHOUT_BODY'
  ),
  getSenders: actionCreator.async<{ isConfirmed?: boolean; withoutDefault?: boolean }, ISender[], Error>('GET_SENDERS'),
  getSender: actionCreator.async<string, ISender, Error>('GET_SENDER'),
  createSender: actionCreator.async<string, ISender, IError<CreateSenderErrorResponse>>('CREATE_SENDER'),
  deleteSender: actionCreator.async<string, null, Error>('DELETE_SENDER'),
  deleteErrorSender: actionCreator<string>('DELETE_ERROR_SENDER'),
  editSender: actionCreator.async<{ id: string }, ISender, Error>('EDIT_SENDER'),
  resendSenderConfirmation: actionCreator.async<string, ISender, Error>('RESEND_SENDER_CONFIRMATION')
};
