import { memo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import styles from './table-tags.module.scss';

import { ITag } from '../../modules/crm/tag/store/TagState';

interface IProps {
  tags?: ITag[];
}

export const TableTags = memo(({ tags }: IProps) => {
  const { tags: fullTags } = useSelector((state) => state.tags);
  const [tagList, setTagList] = useState(tags);

  useEffect(() => {
    if (tags?.every((tag) => typeof tag === 'string')) {
      const ids = tags.map((tag) => String(tag));
      setTagList(fullTags.filter((tag) => ids?.includes(tag.id)));
    }
  }, [fullTags, tags]);

  return (
    <div className={styles.tags}>
      {tagList?.map((tag, index) => (
        <div className={styles.tagCircle} key={index} style={{ backgroundColor: tag?.foreground_color }} />
      ))}
    </div>
  );
});
