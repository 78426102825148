import { Form } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';

import styles from '../connect-new-domain.module.scss';

import { ICustomDomainFormValues } from '../ConnectNewDomain';

export const SubDomainInput = () => {
  const { register, formState } = useFormContext<ICustomDomainFormValues>();
  const errorValidationSubDomain = formState.errors?.subdomain?.message;

  return (
    <>
      <Form.Group className={'mt-3'}>
        <Form.Label className={styles.label}>Subdomain name</Form.Label>
        <Form.Control placeholder={'Ex: school, courses, classes'} {...register('subdomain')} />
        {errorValidationSubDomain && <div className={'error'}>{errorValidationSubDomain}</div>}
      </Form.Group>
    </>
  );
};
