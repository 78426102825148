import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { flatMapDeep } from 'lodash';

import styles from './thread-header.module.scss';
import {
  Icon16ChatBubbles,
  Icon16ChevronRight,
  Icon16Folder,
  Icon16Home,
  Icon16Mark,
  IconNewWindow
} from '../../../../../components/UI/icons';

import { flatten } from '../../../../../components/course/course/CourseHelpers';
import { coursePaths } from '../../../../lms/courses/routes/CourseRoutes';
import { CourseSelector } from '../../../../lms/courses/store/CourseSelector';
import { ICourseNode } from '../../../../lms/courses/store/CourseState';
import { LessonSelector } from '../../../../lms/courses/store/LessonSelector';
import { CoursePreview } from '../../course-preview/CoursePreview';

interface IProps {
  markAsRead: () => void;
  onShowModal: () => void;
  showThreadBtn: boolean;
  hierarchy: ICourseNode;
  courseId: string;
}
export const ThreadCaption = ({ markAsRead, showThreadBtn, onShowModal, courseId, hierarchy }: IProps) => {
  const breadCrumbs = flatMapDeep(hierarchy.children, flatten) as ICourseNode[];
  const lesson = breadCrumbs.find((item) => item.type === 'lesson');
  const nodeMemo = useMemo(() => LessonSelector(courseId, lesson?.id), [courseId, lesson?.id]);
  const node = useSelector((state) => nodeMemo(state));
  const filteredBreadCrumbs = breadCrumbs.filter((item) => item.type === 'folder');
  const courseMemo = useMemo(() => CourseSelector(courseId), [courseId]);
  const { course } = useSelector(courseMemo);

  return (
    <>
      <Link
        className={styles.title}
        to={coursePaths.courseLessons(courseId, node?.lesson?.children[0].id || hierarchy.id)}
      >
        {breadCrumbs.length > 0 ? lesson?.title : hierarchy.title} <IconNewWindow />
      </Link>
      <div className={styles.breadcrumbsContainer}>
        <div className={styles.breadWrapper}>
          <div className={styles.breadItem}>
            <Icon16Home />
            Home
            {filteredBreadCrumbs.length > 0 && <Icon16ChevronRight />}
          </div>
          {filteredBreadCrumbs.map((value: ICourseNode, index) => (
            <div key={value.id} className={styles.breadItem}>
              <Icon16Folder />
              {value.title}
              {index < filteredBreadCrumbs.length - 1 && <Icon16ChevronRight />}
            </div>
          ))}
        </div>
        <div>
          <div className={styles.breadControls}>
            {showThreadBtn && (
              <div className={styles.control} onClick={onShowModal}>
                <Icon16ChatBubbles />
                View thread
              </div>
            )}
            <div className={styles.control} onClick={markAsRead}>
              <Icon16Mark />
              Mark as resolved
            </div>
          </div>
        </div>
      </div>
      {course && (
        <CoursePreview
          className={styles.previewContainer}
          id={course.id}
          active={false}
          image={course.settings.thumbnail}
          title={course.title}
          onActive={() => {}}
        />
      )}
    </>
  );
};
