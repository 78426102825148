import React, { memo, useMemo } from 'react';
import { FormControl } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';

import classNames from 'classnames';
import styles from './status-sent.module.scss';

import { Button } from '../../../../../../components/UI';
import { validateEmail } from '../../../../../../helpers';
import { IMessage } from '../../../../../../interfaces';

interface IProps {
  loading: boolean;
  setEmail: (data: string) => void;
  email: string;
  onSendTest: () => void;
}

export const InitialSent = memo(({ email, loading, onSendTest, setEmail }: IProps) => {
  const {
    formState: { errors }
  } = useFormContext<Pick<IMessage, 'title' | 'payload'>>();
  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value;
    setEmail(value);
  };

  const isValidEmail = useMemo(() => validateEmail(email), [email]);

  return (
    <>
      <div className={styles.title}>Send test email</div>
      <FormControl
        autoComplete={'email'}
        placeholder={'example@gmail.com'}
        className={classNames('form-control form-control-md  mb-2', !isValidEmail && email && 'invalid')}
        type={'email'}
        disabled={loading}
        onChange={onChangeHandler}
        value={email}
      />
      <Button
        onClick={onSendTest}
        size={'lg'}
        iconPosition={'left'}
        withIcon={false}
        loading={loading}
        className={'w-100'}
        btnProps={{ disabled: loading || !isValidEmail }}
      >
        Send
      </Button>
      {errors.payload?.subject && <div className={'error'}>{errors.payload.subject.message}</div>}
    </>
  );
});
