import { reducerWithInitialState } from 'typescript-fsa-reducers';

import { commonErrorHandler } from '../../../../../helpers/common-reducer-handlers';
import { LoadState, newState } from '../../../../../store';
import { StudentCommentActions } from './StudentCommentActions';
import { IStudentCommentState, StudentCommentInitialState } from './StudentCommentState';

export const studentCommentReducer = reducerWithInitialState<IStudentCommentState>(StudentCommentInitialState)
  .case(StudentCommentActions.getComments.started, (state) => {
    return newState(state, {
      ...state,
      loadState: LoadState.loadingMore,
      error: null
    });
  })
  .case(StudentCommentActions.getComments.done, (state, { result }) => {
    const newData = state.comments.data.length > 0 ? [...state.comments.data, ...result.data] : result.data;
    return newState(state, {
      comments: {
        data: newData,
        meta: result.meta
      },
      error: null,
      loadState: LoadState.allIsLoaded
    });
  })
  .case(StudentCommentActions.getComments.failed, commonErrorHandler)
  .case(StudentCommentActions.createComment.done, (state, { result }) => {
    return newState(state, {
      comments: {
        ...state.comments,
        data: [result, ...state.comments.data],
        meta: {
          ...state.comments.meta,
          total: (state.comments.meta.total += 1)
        }
      },
      error: null,
      loadState: LoadState.allIsLoaded
    });
  })
  .case(StudentCommentActions.createComment.failed, commonErrorHandler)
  .case(StudentCommentActions.removeComments, (state) => newState(state, StudentCommentInitialState));
