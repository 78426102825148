import { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { flatMapDeep } from 'lodash';

import styles from './thread-header.module.scss';

import { flatten } from '../../../../../components/course/course/CourseHelpers';
import { LessonThreadModal } from '../../../../lms/courses/curriculum/lesson/lesson-comment/lesson-thread-modal/LessonThreadModal';
import { coursePaths } from '../../../../lms/courses/routes/CourseRoutes';
import { courseActionAsync } from '../../../../lms/courses/store/CourseActionAsync';
import { CourseSelector } from '../../../../lms/courses/store/CourseSelector';
import { ICourseNode } from '../../../../lms/courses/store/CourseState';
import { LessonSelector } from '../../../../lms/courses/store/LessonSelector';
import { CoursePreview } from '../../course-preview/CoursePreview';
import { ThreadCaption } from './ThreadCaption';

type Props = {
  hierarchy: ICourseNode;
  courseId: string;
  threadId: string;
  onRemoveThread: (id: string) => void;
};

export const ThreadHeader = ({ hierarchy, courseId, threadId, onRemoveThread }: Props) => {
  const [showThread, setShowThread] = useState(false);
  const dispatch = useDispatch();

  const markAsRead = () => {
    onRemoveThread(threadId);
    dispatch(courseActionAsync.markAsModeratedComment(threadId));
  };
  const onCloseModal = () => setShowThread(false);
  const onShowModal = () => setShowThread(true);

  return (
    <div className={styles.container}>
      <ThreadCaption
        hierarchy={hierarchy}
        markAsRead={markAsRead}
        onShowModal={onShowModal}
        courseId={courseId}
        showThreadBtn={true}
      />

      {threadId && (
        <LessonThreadModal
          show={showThread}
          onClose={onCloseModal}
          threadId={threadId}
          title={<div className={styles.titleThread}>Thread</div>}
        >
          <div className={styles.captionContainer}>
            <ThreadCaption
              hierarchy={hierarchy}
              markAsRead={markAsRead}
              onShowModal={onShowModal}
              courseId={courseId}
              showThreadBtn={false}
            />
          </div>
        </LessonThreadModal>
      )}
    </div>
  );
};
