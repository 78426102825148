//@ts-nocheck
import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Controller, useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';

import classNames from 'classnames';
import styles from './payload.pages.module.scss';
import { Icon32Page, IconCopy, IconMinus } from '../../../../../../components/UI/icons';

import { Button } from '../../../../../../components/UI';
import { useNodePayload } from '../../../../../../hooks/use-node-payload';
import { IFunnelNodeBuilder, PaletteElementTypes } from '../../../../../../interfaces';
import { funnelPaths } from '../../../../../funnels/routes/FunnelRoutes';
import { pageBuilderPaths } from '../../../../page-builder/routes/PageBuilderRoutes';

interface IProps {
  nodeId: string;
  initNode: (node: IFunnelNodeBuilder<PaletteElementTypes>) => void;
}

type FormValues = {
  medium: string;
  source: string;
  campaign: string;
  content: string;
  term: string;
  link?: string;
};

export const PayloadPages = ({ nodeId, initNode }: IProps) => {
  const { register, watch, setValue } = useFormContext<FormValues>();
  const history = useHistory();
  const path = useRouteMatch<{ id: string }>(funnelPaths.funnelId());
  const funnelId = path?.params.id;
  const [copied, setCopied] = useState(false);
  const [url, setUrl] = useState('');
  const [showForm, setShowForm] = useState(false);
  const data: Partial<FormValues> = watch();
  const nodes = useSelector((state) => state.builder.builder.nodes);
  const [currentNode, setCurrentNode] = useState<NodeBuilder>();
  const node = useNodePayload(nodeId);
  // const node: NodeBuilder<FormValues> | undefined = nodes.find((x) => x.id === nodeId);

  useEffect(() => {
    if (node) {
      setCurrentNode(node);
      setValue('medium', node.payload.medium);
      setValue('source', node.payload.source);
      setValue('campaign', node.payload.campaign);
      setValue('content', node.payload.content);
      setValue('term', node.payload.term);
    }
  }, [node, nodeId, nodes, setValue]);

  const onCopy = (text: string, result: boolean) => {
    setCopied(result);
  };

  useEffect(() => {
    const links = [];
    data.source && links.push(`utm_source=${encodeURI(data.source)}`);
    data.medium && links.push(`utm_medium=${encodeURI(data.medium)}`);
    data.campaign && links.push(`utm_campaign=${encodeURI(data.campaign)}`);
    data.content && links.push(`utm_content=${encodeURI(data.content)}`);
    data.term && links.push(`utm_term=${encodeURI(data.term)}`);
    const link = links.length ? `?${links.join('&')}` : '';
    setUrl(link);
    setValue('link', link);
    setCopied(false);
  }, [data.campaign, data.content, data.medium, data.source, data.term, setValue]);

  const onShow = () => {
    setShowForm(!showForm);
  };

  const openBuilder = () => {
    if (funnelId) {
      history.push(pageBuilderPaths.pageBuilder(funnelId, nodeId));
    }
  };

  useEffect(() => {
    if (node && node.endpoints?.length) {
      initNode(node);
    }
  }, [initNode, node, node?.endpoints?.length]);

  return (
    <>
      <div className={classNames(styles.createPage)} onClick={openBuilder}>
        <Icon32Page className={styles.icon} />
        {currentNode?.payload?.html ? 'Edit Page' : 'Create a Page'}
      </div>

      <div className={classNames(styles.utmBuilder, { [styles.open]: showForm })}>
        <div className={classNames(styles.title, 'd-flex justify-content-between')}>
          UTM Builder
          <div onClick={onShow} className={styles.editLink}>
            {!showForm ? (
              'Edit Link'
            ) : (
              <>
                Hide <IconMinus />
              </>
            )}
          </div>
        </div>
        <div className={classNames(styles.form, { [styles.show]: showForm })}>
          <Form.Group className={'mb-3'}>
            <Form.Label>Medium</Form.Label>
            <Form.Control {...register('medium')} size={'sm'} />
          </Form.Group>
          <Form.Group className={'mb-3'}>
            <Form.Label>Source</Form.Label>
            <Form.Control {...register('source')} size={'sm'} />
          </Form.Group>
          <Form.Group className={'mb-3'}>
            <Form.Label>Campaign</Form.Label>
            <Form.Control {...register('campaign')} size={'sm'} />
          </Form.Group>
          <Form.Group className={'mb-3'}>
            <Form.Label>Content</Form.Label>
            <Form.Control {...register('content')} size={'sm'} />
          </Form.Group>
          <Form.Group className={'mb-3'}>
            <Form.Label>Term</Form.Label>
            <Form.Control {...register('term')} size={'sm'} />
          </Form.Group>
        </div>
        <Form.Group className={'mb-0'}>
          {showForm && <Form.Label>Generated link</Form.Label>}
          <div className={styles.input}>
            <Controller
              render={({ field: { value } }) => <Form.Control size={'sm'} value={value} disabled />}
              name={'link'}
              defaultValue={''}
            />
            <CopyToClipboard text={url} onCopy={onCopy}>
              {showForm ? (
                <Button withIcon={false} onClick={() => {}} btnStyle={'outline'} className={'mt-2 w-100'}>
                  {copied ? 'Link copied!' : 'Copy link'}
                </Button>
              ) : (
                <button type={'button'} className={classNames('btn btn-icon', styles.copy)}>
                  <IconCopy />
                </button>
              )}
            </CopyToClipboard>
          </div>
        </Form.Group>
      </div>
      {showForm && <div className={styles.overlay} />}
    </>
  );
};
