import { Controller, useFormContext } from 'react-hook-form';
import moment from 'moment';

import classNames from 'classnames';
import canvasStyles from '../canvas-node.module.scss';
import styles from './fixed-period.module.scss';

import { useNodePayload } from '../../../../../hooks/use-node-payload';
import { PaletteAutomationElementTypes } from '../../../../../interfaces';
import { EndpointsDelay } from './EndpointsDelay';

interface IProps {
  nodeId: string;
}

export const CanvasDelayFixedTime = ({ nodeId }: IProps) => {
  const { control } = useFormContext();
  const payload = useNodePayload<PaletteAutomationElementTypes.PROCESSOR_DELAY>(nodeId);

  return (
    <div className={classNames(styles.container, canvasStyles.bgContainer)}>
      <div className={styles.timeContainer}>
        <div className={styles.title}>Until fixed time of the day of the week</div>
        <Controller
          control={control}
          name={`${nodeId}.when`}
          defaultValue={payload?.payload.period?.when || null}
          render={({ field: { value } }) => {
            const date = moment(value || payload?.payload?.period?.when, 'HH:mm');
            return <div>{date.isValid() ? date.format('hh:mm A') : '09:00 AM'}</div>;
          }}
        />
      </div>
      <EndpointsDelay nodeId={nodeId} />
    </div>
  );
};
