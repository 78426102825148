import { reducerWithInitialState } from 'typescript-fsa-reducers';

import { commonErrorHandler, commonFirstLoadHandler } from '../../../../helpers/common-reducer-handlers';
import { LoadState, newState } from '../../../../store';
import { AuthActions } from '../../../auth/store/AuthActions';
import { ValidationData } from '../../../crm/offers/create-offer/common/ValidationData';
import { AccountActions } from './AccountActions';
import { IProfileAccountState, ProfileAccountInitialState } from './AccountState';

export const accountReducer = reducerWithInitialState<IProfileAccountState>(ProfileAccountInitialState)
  // .case(AccountActions.getMe.started, commonFirstLoadHandler)
  .case(AccountActions.getMe.done, (state, { result }) => {
    // if (params.isStudent) {
    //   return newState(state, {
    //     account: {
    //       ...state.account,
    //       organizations: [],
    //       is_admin: result.is_admin,
    //       profile: result.profile,
    //       providers: result.providers
    //     },
    //     error: null,
    //     loadState: LoadState.allIsLoaded
    //   });
    // }
    if (!ValidationData(state.account, result)) {
      return newState(state, {
        account: result,
        error: null,
        loadState: LoadState.allIsLoaded
      });
    }
    return state;
  })
  .case(AccountActions.getMe.failed, commonErrorHandler)
  .case(AccountActions.editMe.started, commonFirstLoadHandler)
  .case(AccountActions.editMe.done, (state, { result }) => {
    return newState(state, {
      account: {
        ...state.account,
        profile: result
      },
      error: null,
      loadState: LoadState.idle
    });
  })
  .cases([AccountActions.deleteMe, AuthActions.authTokenDelete], () => ProfileAccountInitialState)
  .case(AccountActions.deleteOrganization, (state) => {
    return newState(state, {
      account: {
        ...state.account,
        organizations: []
      }
    });
  });
