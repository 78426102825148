import { memo, useCallback, useEffect, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { map } from 'lodash';

import classNames from 'classnames';
import styles from './fields-edit.module.scss';
import { IconPlus } from '../../../components/UI/icons';

import { BackReturn } from '../../../components/back-return/BackReturn';
import { settingsActions } from '../../../store/settings';
import { FieldActionAsync, FieldEntity } from '../field';
import { FieldCreator } from '../field/FieldCreator';
import { IFieldSettings } from '../field/FieldSettings';
import { Fields } from './Fields';

interface IProps {
  entity: Exclude<FieldEntity, 'company'>;
  title: string;
  description: string;
}

export interface IFieldsSettings {
  title: string;
  display_options?: string;
  options?: { options: string }[];
  isChecked?: boolean;
}

export const FieldsEdit = memo(({ title, description, entity }: IProps) => {
  const [saveField, setSaveField] = useState(true);
  const [selected, setSelected] = useState<IFieldSettings | undefined>(undefined);
  const bottomRef = useRef<HTMLDivElement | null>(null);

  const dispatch = useDispatch();
  const methods = useForm<IFieldsSettings>({
    defaultValues: {
      options: [{ options: 'Option 1' }, { options: 'Option 2' }],
      display_options: 'Dropdown'
    },
    shouldUnregister: true
  });

  const onAdd = useCallback(() => {
    setSaveField(false);
    setSelected(undefined);
  }, [setSaveField, setSelected]);

  const hideAdd = useCallback(() => {
    setSaveField(true);
  }, []);

  const onClose = useCallback(() => {
    dispatch(settingsActions.setSidebarShowing({ isSidebarShow: false }));
  }, [dispatch]);

  const onSelectedType = useCallback((val: IFieldSettings) => {
    setSelected(val);
  }, []);

  const onCloseModal = useCallback(() => {
    hideAdd();
    setSelected({ showIcon: true, label: '', value: [], icon: '', placeholder: '' });
  }, [hideAdd]);

  const onSaveField = useCallback(
    (payload?: IFieldsSettings) => {
      if (selected?.value && payload?.title) {
        if (selected.value.includes('short_text')) {
          dispatch(
            FieldActionAsync.createField({
              type: payload.isChecked ? 'short_text' : 'long_text',
              payload: { title: payload.title, readonly: false },
              entity
            })
          );
        } else if (selected.value.includes('date')) {
          dispatch(
            FieldActionAsync.createField({
              type: payload.isChecked ? 'date_time' : 'date',
              payload: { title: payload.title, readonly: false },
              entity
            })
          );
        } else if (selected.value.includes('select') || selected.value.includes('multiselect')) {
          dispatch(
            FieldActionAsync.createField({
              type: selected.value[0],
              payload: {
                title: payload.title,
                display_options: payload.display_options === 'Display options',
                options: map(payload.options, 'options'),
                readonly: false
              },
              entity
            })
          );
        } else if (selected.value.includes('toggle')) {
          dispatch(
            FieldActionAsync.createField({
              type: 'toggle',
              payload: { title: payload.title, value: false, readonly: false },
              entity
            })
          );
        } else {
          dispatch(
            FieldActionAsync.createField({
              type: selected.value[0],
              payload: { title: payload.title, readonly: false },
              entity
            })
          );
        }
        hideAdd();
      }
    },
    [dispatch, entity, hideAdd, selected?.value]
  );

  const scrollToBottom = () => {
    bottomRef.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'start'
    });
  };

  useEffect(() => {
    scrollToBottom();
  }, [saveField, selected]);

  return (
    <FormProvider {...methods}>
      <div className={classNames(styles.mainContainer)}>
        <div className={styles.posRel}>
          {!saveField && <div className={styles.darkContainer} />}
          <div className={styles.header}>
            <BackReturn text={'Back'} onClick={onClose} />
            <h3 className={styles.title}>{title}</h3>
            <div className={classNames('mb-3', styles.description)}>{description}</div>
          </div>
          <div className={styles.content}>
            <Fields entity={entity} />
          </div>
        </div>
        {saveField ? (
          <div className={classNames('pb-5', styles.iconContainer)}>
            <button className={'btn btn-gradient-secondary btn-sm btn-icon-right'} onClick={onAdd}>
              Create field
              <IconPlus className={'icon'} />
            </button>
          </div>
        ) : (
          <>
            <div className={styles.bgContainer}>
              <div className={styles.iconContainer}>
                <FieldCreator
                  hideAdd={hideAdd}
                  saveField={saveField}
                  onClose={onCloseModal}
                  onSaveField={onSaveField}
                  onSelectedType={onSelectedType}
                  selected={selected}
                />
              </div>
            </div>
            <div className={classNames(styles.posRel, styles.height)}>
              <div className={styles.darkContainer} />
            </div>
          </>
        )}
      </div>
      <div key={'dummy'} ref={bottomRef} />
    </FormProvider>
  );
});
