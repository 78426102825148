import { memo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import classNames from 'classnames';
import styles from '../contacts/contacts-header.module.scss';
import { IconPlus, IconSpinner, IconUpload } from '../../../../components/UI/icons';

import { Button } from '../../../../components/UI';
import { downloadCSV } from '../../../../helpers';
import { PageTopPanel } from '../../../../layout/shared-components/page-top-panel/PageTopPanel';
import { SearchWrapper } from '../../../../layout/shared-components/SearchWrapper';
import { IList } from '../../lists/store/ListsState';
import { ContactListActionAsync } from '../store/ContactListActionAsync';

interface IProps {
  list?: Partial<IList>;

  onClick(): void;
}

export const ListHeader = memo(({ list, onClick }: IProps) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { contacts } = useSelector((state) => state.contacts);

  const exportContacts = () => {
    if (list?.id) {
      setLoading(true);
      dispatch(ContactListActionAsync.exportContactsFromList(list.id, exportContactsCallback));
    }
  };

  const exportContactsCallback = (data: string) => {
    downloadCSV(data, 'contacts');
    setLoading(false);
  };

  return (
    <PageTopPanel title={list?.title}>
      <SearchWrapper placeholder={'Search by name, id, e-mail...'} isPage className={'mr-2'} />
      <Button
        onClick={exportContacts}
        customIcon={loading ? <IconSpinner className={'spinner'} /> : <IconUpload />}
        btnStyle={'secondary'}
        className={classNames('mr-2', styles.export, { [styles.exporting]: loading })}
        btnProps={{ disabled: loading || contacts.length === 0 }}
      >
        {loading ? 'Exporting...' : 'Export'}
      </Button>
      <Button onClick={onClick} customIcon={<IconPlus className={'icon'} />}>
        Add Contact
      </Button>
    </PageTopPanel>
  );
});
