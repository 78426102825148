import { memo } from 'react';
import Slider, { Settings } from 'react-slick';

import classNames from 'classnames';
import styles from './preview-offer.module.scss';
import iPad from '../../../../../../../assets/images/iPad.png';
import iPhone from '../../../../../../../assets/images/iPhone.png';
import Laptop from '../../../../../../../assets/images/Laptop.png';

import { IOffer } from '../../../store/OffersState';
import { PreviewItem } from './PreviewItem';
import { SliderTitle } from './SliderTitle';

interface IProps {
  offerId: string;
  offer: Omit<
    IOffer,
    | 'id'
    | 'action_add_to_list'
    | 'action_enroll_in_courses'
    | 'action_email_after_purchase'
    | 'organization_id'
    | 'state'
    | 'order_bump_products'
  >;
}

const sliderSettings: Settings = {
  customPaging: (i) => (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <a>
      <SliderTitle index={i} />
    </a>
  ),
  dots: true,
  dotsClass: classNames('slick-dots', styles.textContainer),
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  className: styles.wrapper
};
export const PreviewOffer = memo(({ offerId, offer }: IProps) => {
  return (
    <>
      <div className={styles.title}>Design preview</div>
      {offer && (
        <Slider {...sliderSettings}>
          <PreviewItem
            containerClassNames={styles.imgContainerLaptop}
            wrapperClassName={styles.imgWrapperLaptop}
            widgetClassName={styles.laptopWidget}
            img={Laptop}
            imgClassName={styles.laptop}
            offer={offer}
            offerId={offerId}
          />
          <PreviewItem
            containerClassNames={styles.imgContainerIPad}
            img={iPad}
            imgClassName={styles.ipad}
            offer={offer}
            offerId={offerId}
            widgetClassName={styles.ipadWidget}
            wrapperClassName={styles.imgWrapperIPad}
          />
          <PreviewItem
            containerClassNames={styles.imgContainerIPhone}
            wrapperClassName={styles.imgWrapperIPhone}
            widgetClassName={styles.iphoneWidget}
            img={iPhone}
            imgClassName={styles.iphone}
            offer={offer}
            offerId={offerId}
          />
        </Slider>
      )}
    </>
  );
});
