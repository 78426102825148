import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';

import styles from './add-emil.module.scss';
import { Icon32Plus } from '../../../../../../../../components/UI/icons';

import { Image } from '../../../../../../../../components/UI/image-components/image/Image';
import { IEmail, IFile, IMessage } from '../../../../../../../../interfaces';
import { IEmailActionsAutomationPayload } from '../../../../../../../../interfaces/node-builder-payload/automation-actions/email';
import { RemoveModal } from '../remove-modal/RemoveModal';
import { DropEmailMenu } from './drop-menu/DropEmailMenu';

interface IProps {
  onOpen: () => void;
  setMessage: (message?: IMessage) => void;
  emailId?: string | null;
  thumbnail?: IFile;
  title?: string;
  payload?: IEmail | null;
}

export const AddEmail = ({ onOpen, setMessage, emailId, thumbnail, title, payload }: IProps) => {
  const [showModalRemove, setShowModalRemove] = useState(false);
  const { setValue } = useFormContext<IEmailActionsAutomationPayload>();
  const onOpenOrCreate = (e: React.SyntheticEvent<HTMLDivElement>) => {
    e.preventDefault();
    if (!emailId) {
      onOpen();
    }
  };

  const onCloseModal = () => {
    setShowModalRemove(false);
  };

  const onOpenModal = () => {
    setShowModalRemove(true);
  };

  const onRemove = () => {
    setValue('email_id', null);
    setMessage();
    onCloseModal();
  };

  return (
    <div className={styles.container}>
      {showModalRemove && <RemoveModal closeModal={onCloseModal} onConfirm={onRemove} />}

      {thumbnail && payload ? (
        <div className={styles.thumbnailContainer}>
          <div className={styles.title}>{title}</div>
          <Image
            bucket_name={thumbnail.bucket_name}
            object_name={thumbnail.object_name}
            alt={'thumbnail'}
            className={styles.emailImage}
          />
        </div>
      ) : (
        <div className={styles.textContainer} onClick={onOpenOrCreate}>
          <>
            <Icon32Plus />
            <div className={styles.text}>Add email message</div>
          </>
        </div>
      )}
      {emailId && <DropEmailMenu onOpen={onOpen} onOpenRemoveModal={onOpenModal} />}
    </div>
  );
};
