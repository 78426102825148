import { memo, useEffect, useState } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useRouteMatch } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';

import classNames from 'classnames';
import commonStyles from '../../create-offer.module.scss';
import styles from './update-change.module.scss';
import { Icon32CheckMark } from '../../../../../../components/UI/icons';

import { ActionsPanel, Toast } from '../../../../../../components/UI';
import { LoadState } from '../../../../../../store';
import { coursePaths } from '../../../../../lms/courses/routes/CourseRoutes';

interface IProps<T> {
  methods: UseFormReturn<T>;
  showUpdate: boolean | undefined;
  loadState: LoadState;
  onCancel: () => void;
  onSave: (data: any) => void;
  className?: string;
}

const UpdateChange = <T extends unknown>({
  showUpdate,
  methods,
  loadState,
  onCancel,
  onSave,
  className
}: IProps<T>) => {
  const path = useRouteMatch(coursePaths.coursePrice());
  const [show, setShow] = useState(false);

  useEffect(() => {
    const { isSubmitSuccessful } = methods.formState;

    if (!showUpdate && isSubmitSuccessful) {
      setShow(false);
    } else if (showUpdate) {
      setShow(true);
    }
    if (!isSubmitSuccessful && !showUpdate) {
      setShow(false);
    }
    if (showUpdate && isSubmitSuccessful && loadState === LoadState.allIsLoaded) {
      setShow(false);
    }
  }, [loadState, methods.formState, showUpdate]);

  useEffect(() => {
    if (loadState === LoadState.error) {
      Toast('error', 'Something went wrong!');
      setShow(false);
    }
  }, [loadState]);

  const onExited = () => {
    methods.reset(methods.getValues() as any);
  };

  return (
    <div className={styles.container}>
      <CSSTransition
        in={show}
        timeout={!methods.formState.isSubmitSuccessful ? 300 : 1300}
        classNames={{
          ...styles,
          exitActive: !methods.formState.isSubmitSuccessful ? styles.exitActive : styles.exitActiveSuccess
        }}
        unmountOnExit
        onExited={onExited}
      >
        <ActionsPanel
          onCancel={onCancel}
          onSave={methods.handleSubmit(onSave)}
          classNameWrapper={classNames(commonStyles.actionContainer, className, {
            [commonStyles.full]: path?.path === coursePaths.coursePrice()
          })}
          loading={loadState !== LoadState.allIsLoaded && methods.formState.isSubmitted}
        >
          {loadState === LoadState.allIsLoaded && methods.formState.isSubmitSuccessful && (
            <div className={styles.containerSuccess}>
              <Icon32CheckMark />
              Updated!
            </div>
          )}
        </ActionsPanel>
      </CSSTransition>
    </div>
  );
};

export default memo(UpdateChange) as typeof UpdateChange;
