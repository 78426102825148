import { useParams } from 'react-router-dom';

import { useScript } from '../../../../hooks/use-script';

export const Offer = () => {
  const params = useParams<{ id: string }>();

  useScript('https://s3.dev.wisent.work/widgets-public/everact-checkout-widget-dev.js', 'Offer script');

  const isDev = process.env.REACT_APP_ENV !== 'production';

  return (
    <>
      <div
        className={'everact_widget'}
        data-placement="popup_without_btn"
        data-offer={params.id}
        data-env={isDev ? 'dev' : 'prod'}
      ></div>
    </>
  );
};
