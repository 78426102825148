import classNames from 'classnames';
import styles from '../suggestion.module.scss';

interface IProps {
  query: string;
}
export const CreateNewTag = ({ query }: IProps) => {
  return (
    <>
      {query.length > 0 ? (
        <span className={classNames(styles.createTagContainer)}>
          <span className={styles.createText}>
            + Create new tag
            {query.length > 20 && <div className={styles.overlay} />}
          </span>

          <span className={styles.suggestionsNoneText}>{query}&nbsp;</span>
        </span>
      ) : (
        <span className={classNames(styles.createTagContainer, styles.suggestionsEmptyNoneText)}>
          Start writing to create a new tag
        </span>
      )}
    </>
  );
};
