import { reducerWithInitialState } from 'typescript-fsa-reducers';

import { commonErrorHandler, commonFirstLoadHandler } from '../../../../helpers/common-reducer-handlers';
import { LoadState, newState } from '../../../../store';
import { OrganizationActions } from './OrganizationActions';
import { IOrganizationState, OrganizationInitialState } from './OrganizationState';

export const organizationReducer = reducerWithInitialState<IOrganizationState>(OrganizationInitialState)
  .case(OrganizationActions.getOrganizationMembers.started, commonFirstLoadHandler)
  .case(OrganizationActions.getOrganizationMembers.done, (state, { result }) => {
    return newState(state, {
      ...state,
      error: null,
      loadState: LoadState.allIsLoaded,
      members: result
    });
  })
  .case(OrganizationActions.getOrganizationMembers.failed, commonErrorHandler)

  .case(OrganizationActions.inviteOrganizationMembers.started, commonFirstLoadHandler)
  .case(OrganizationActions.inviteOrganizationMembers.done, (state) => {
    return newState(state, {
      ...state,
      loadState: LoadState.allIsLoaded,
      error: null
    });
  })
  .case(OrganizationActions.inviteOrganizationMembers.failed, commonErrorHandler)

  .case(OrganizationActions.removeMemberFromOrganization.started, commonFirstLoadHandler)
  .case(OrganizationActions.removeMemberFromOrganization.done, (state) => {
    return newState(state, {
      ...state,
      loadState: LoadState.allIsLoaded,
      error: null
    });
  })
  .case(OrganizationActions.removeMemberFromOrganization.failed, commonErrorHandler)

  .case(OrganizationActions.resendInvitationOrganizationMember.started, commonFirstLoadHandler)
  .case(OrganizationActions.resendInvitationOrganizationMember.done, (state) => {
    return newState(state, {
      ...state,
      loadState: LoadState.allIsLoaded,
      error: null
    });
  })
  .case(OrganizationActions.resendInvitationOrganizationMember.failed, commonErrorHandler)

  .case(OrganizationActions.editMemberOrganization.started, commonFirstLoadHandler)
  .case(OrganizationActions.editMemberOrganization.done, (state, { result }) => {
    const newMembers = state.members.map((member) => (member.id === result.id ? result : member));
    return newState(state, {
      ...state,
      members: newMembers,
      error: null,
      loadState: LoadState.allIsLoaded
    });
  })
  .case(OrganizationActions.editMemberOrganization.failed, commonErrorHandler);
