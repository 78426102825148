import actionCreatorFactory from 'typescript-fsa';

import { ICreatePolicy, IEditPolicy, IPolicy } from '../../../../interfaces/policy';

export const actionCreator = actionCreatorFactory();
export const PolicyActions = {
  getPolicyList: actionCreator.async<void, IPolicy[], Error>('GET_POLICY_LIST'),
  createPolicy: actionCreator.async<ICreatePolicy, IPolicy, Error>('CREATE_POLICY'),
  editPolicy: actionCreator.async<{ id: string; data: IEditPolicy }, IPolicy, Error>('EDIT_POLICY'),
  deletePolicy: actionCreator.async<{ id: string }, null, Error>('DELETE_POLICY')
};
