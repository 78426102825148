import { FontFamily } from './modules/CustomFontFamily';

export const fonts = (defaultFontSize: string = '16px') => {
  const fontSizes: (string | boolean)[] = [
    '10px',
    '12px',
    '14px',
    '16px',
    '18px',
    '20px',
    '22px',
    '24px',
    '30px',
    '36px',
    '48px',
    '60px',
    '72px',
    '96px'
  ];
  const index = fontSizes.indexOf(defaultFontSize);
  if (~index) {
    fontSizes[index] = false;
  }
  return fontSizes;
};

export const Toolbar = [
  ['undo', 'redo'],
  [{ font: FontFamily.whitelist }],
  [{ size: fonts('16px') }],
  [{ header: [1, 2, 3, 4, 5, 6, false] }],
  ['blockquote'],
  ['bold', 'underline', 'italic', 'strike', 'sub', 'super'],
  [{ color: [] }, { background: [] }, 'clean'],
  [{ align: [] }],
  [{ indent: '+1' }, 'outdent'],
  [{ list: 'ordered' }, { list: 'bullet' }],
  ['link', 'image', 'video', 'code', 'formula']
];

export const toolbarBubble = [
  ['undo', 'redo'],
  [{ header: [1, 2, 3, 4, 5, 6, false] }, { size: fonts('16px') }],
  ['bold', 'underline', 'italic', 'strike', 'code'],

  [{ color: [] }, { background: [] }, 'clean'],
  [{ align: [] }],
  ['link', 'image']
];

// bubble quill doesn't support formula
export const toolbarBubbleSimple = [
  ['undo', 'redo'],
  [{ size: fonts('16px') }],
  ['bold', 'underline', 'italic', 'strike'],

  [{ color: [] }, { background: [] }, 'clean'],
  [{ align: [] }],
  // [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
  ['link', 'image']
];

export const toolbarSnowSimple = [
  ['undo', 'redo'],
  [{ size: fonts('16px') }],
  ['bold', 'underline', 'italic', 'strike'],

  [{ color: [] }, { background: [] }, 'clean'],
  [{ align: [] }],
  // [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
  ['link', 'image', 'formula']
];

export const CUSTOM_OPERATORS = [
  ['\\pm', '\\pm'],
  ['\\sqrt{x}', '\\sqrt'],
  ['\\sqrt[3]{x}', '\\sqrt[3]{}'],
  ['\\sqrt[n]{x}', '\\nthroot'],
  ['\\frac{x}{y}', '\\frac'],
  ['\\sum^{s}_{x}{d}', '\\sum'],
  ['\\prod^{s}_{x}{d}', '\\prod'],
  ['\\coprod^{s}_{x}{d}', '\\coprod'],
  ['\\int^{s}_{x}{d}', '\\int'],
  ['\\binom{n}{k}', '\\binom']
];
// console.log('%c⇒ fonts(14px)', 'color: #89DDF7', fonts('14px'));
