import { reducerWithInitialState } from 'typescript-fsa-reducers';

import { addDataToTable, removeDataFromTable } from '../../../../helpers';
import { LoadState, newState } from '../../../../store';
import { ContactListActions } from './ContactListActions';
import { ContactsInitialState, IContactsState } from './ContactListState';

export const contactListReducer = reducerWithInitialState<IContactsState>(ContactsInitialState)
  .case(ContactListActions.getContactsList.started, (state) => ({
    ...state,
    loadState: LoadState.firstLoad,
    error: null
  }))
  .case(ContactListActions.getContactsList.done, (state, { result }) => {
    return newState(state, {
      ...result.meta,
      contacts: result.data,
      loadState: result.data.length > 0 ? LoadState.allIsLoaded : LoadState.idle,
      error: null
    });
  })
  .case(ContactListActions.getContactsList.failed, (state, { error }) => ({
    ...state,
    loadState: LoadState.error,
    error
  }))

  .case(ContactListActions.getContact.started, (state) => ({
    ...state,
    loadState: LoadState.firstLoad,
    error: null
  }))
  .case(ContactListActions.getContact.done, (state, { result }) => {
    let isContain = false;
    const newContacts = state.contacts.map((x) => {
      if (x.id === result.id) {
        isContain = true;
      }
      return x.id === result.id ? { x, ...result } : x;
    });
    if (!isContain) {
      newContacts.push(result);
    }
    return newState(state, {
      contacts: newContacts,
      error: null,
      loadState: LoadState.allIsLoaded
    });
  })
  .case(ContactListActions.getContact.failed, (state, { error }) => ({
    ...state,
    loadState: LoadState.error,
    error
  }))

  .case(ContactListActions.createContactInList.started, (state) => ({
    ...state,
    loadState: LoadState.firstLoad,
    error: null
  }))
  .case(ContactListActions.createContactInList.done, (state, { result }) => {
    const { page, total } = addDataToTable(state.page_size, state.total);
    return newState(state, {
      ...state,
      total: total,
      page: page,
      loadState: LoadState.allIsLoaded,
      error: null,
      contacts: [...state.contacts, result]
    });
  })
  .case(ContactListActions.createContactInList.failed, (state, { error }) => ({
    ...state,
    loadState: LoadState.error,
    error
  }))

  .case(ContactListActions.createContact.started, (state) => ({
    ...state,
    loadState: LoadState.firstLoad,
    error: null
  }))
  .case(ContactListActions.createContact.done, (state, { result }) => {
    const { page, total } = addDataToTable(state.page_size, state.total);
    return newState(state, {
      ...state,
      total: total,
      page: page,
      loadState: LoadState.allIsLoaded,
      error: null,
      contacts: [...state.contacts, result]
    });
  })
  .case(ContactListActions.createContact.failed, (state, { error }) => ({
    ...state,
    loadState: LoadState.error,
    error
  }))

  .case(ContactListActions.editContact.started, (state) => ({
    ...state,
    loadState: LoadState.firstLoad,
    error: null
  }))
  .case(ContactListActions.editContact.done, (state, { result }) => {
    const updateContacts = state.contacts.map((x) => (x.id === result.id ? result : x));
    return newState(state, {
      ...state,
      contacts: updateContacts,
      loadState: LoadState.allIsLoaded,
      error: null
    });
  })
  .case(ContactListActions.editContact.failed, (state, { error }) => ({
    ...state,
    loadState: LoadState.error,
    error
  }))

  .case(ContactListActions.deleteContact.started, (state) => ({
    ...state,
    loadState: LoadState.firstLoad,
    error: null
  }))
  .case(ContactListActions.deleteContact.done, (state, { params }) => {
    const newContactList = state.contacts.filter((x) => x.id !== params.id);
    const { page, total } = removeDataFromTable(state.page_size, state.total);
    return newState(state, {
      ...state,
      total: total,
      page: page,
      contacts: newContactList,
      loadState: newContactList.length > 0 ? LoadState.allIsLoaded : LoadState.idle,
      error: null
    });
  })
  .case(ContactListActions.deleteContact.failed, (state, { error }) => ({
    ...state,
    loadState: LoadState.error,
    error
  }));
