import React, { useCallback, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { IOffer as IOfferWidget, Steps, Widget } from '@demonvv/everact-widget';

import styles from './offerWidget.module.scss';
import LogoMono from '../../../../../../../assets/svg/everactMono.svg';
import { Icon32AngleLeft, Icon32AngleRight, IconNewWindow } from '../../../../../../components/UI/icons';

import { IOffer } from '../../../store/OffersState';
import { ColorPicker } from './color-picker/ColorPicker';
import { RenderInWindow } from './OfferWindow';
import { useOfferWidget } from './use-offer-widget';

interface IProps {
  offer: IOffer;
  offerId: string;
  showBrand: boolean;
}

export const OfferWidget = ({ offer, offerId, showBrand }: IProps) => {
  const { control } = useFormContext();
  const [showPreview, setShowPreview] = useState(false);
  const [activeStep, setActiveStep] = useState<Steps>('cart');

  const backgroundData = useWatch({ control });

  const offerWidget = useOfferWidget(offer, backgroundData.settings_background_color || null);
  const onPrev = () => {
    setActiveStep('cart');
  };
  const onNext = () => {
    setActiveStep('checkout');
  };

  const onOpenWindow = () => {
    setShowPreview(true);
  };

  const onCloseWindow = () => {
    setShowPreview(false);
  };

  const onSetActiveStepHandler = useCallback((step: Steps) => {
    if (step !== 'unavailable') {
      setActiveStep(step);
    }
  }, []);

  return (
    <div className={styles.container} style={{ backgroundColor: backgroundData.settings_background_color || '' }}>
      <div className={styles.wrapper}>
        <div className={styles.previewText} onClick={onOpenWindow}>
          Checkout preview <IconNewWindow className="ml-2" />
        </div>
        <div className={styles.previewText}>
          <Icon32AngleLeft onClick={onPrev} />
          {activeStep === 'cart' ? '1/2' : '2/2'}
          <Icon32AngleRight onClick={onNext} />
        </div>
      </div>
      <div className={styles.noneEvents}>
        {offerWidget && (
          <Widget
            showBrand={false}
            widgetPlacement={'page'}
            offerData={offerWidget as IOfferWidget}
            offerId={offerId}
            activeStep={activeStep}
            setActiveStep={onSetActiveStepHandler}
            toCheckout={onNext}
            backToCart={onPrev}
          />
        )}
      </div>

      {!showBrand && (
        <div className={styles.brand}>
          <img src={LogoMono} alt="logo" />
        </div>
      )}

      <ColorPicker backgroundColor={backgroundData.settings_background_color || 'rgba(0, 23, 51, 0.5)'} />
      {showPreview && offerWidget && (
        <RenderInWindow onCloseWindow={onCloseWindow} offerId={offerId} offer={offerWidget} />
      )}
    </div>
  );
};
