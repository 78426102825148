import { memo, useMemo, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import Select, {
  components,
  MultiValueGenericProps,
  NoticeProps,
  OptionProps,
  ValueContainerProps
} from 'react-select';
import { OnChangeValue } from 'react-select/dist/declarations/src/types';

import classNames from 'classnames';
import styles from './create-cohort-modal.module.scss';
import { Icon32Group, IconCheckMark, IconSpinner } from '../../../../../../components/UI/icons';

import { Button, ModalActionComponent } from '../../../../../../components/UI';
import {
  DropdownIndicatorLg,
  DropdownIndicatorLgMulti
} from '../../../../../../components/UI/select/DropdownIndicatorLg';
import { IOption } from '../../../../../../interfaces';
import { OffersActionAsync } from '../../../../../crm/offers/store/OffersActionAsync';
import { CoursesSelector } from '../../../store/CourseSelector';

export interface ICohortForm {
  course: IOption | null;
  offers: IOption[] | null;
  suffix: string;
}

interface IProps {
  show: boolean;

  onClose(): void;

  onContinue(data: ICohortForm): void;
}

export const CreateCohortModal = memo(({ show, onClose, onContinue }: IProps) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const courseMemo = useMemo(CoursesSelector, []);
  const { courses } = useSelector((state) => courseMemo(state, 'all'));
  const courseOptions = courses.map((course) => ({ label: course.title, value: course.id }));
  const { offers, loadState: offerLoadState } = useSelector((state) => state.offers);
  const offerOptions = offers.map((offer) => ({ label: offer.title, value: offer.id }));
  const {
    control,
    register,
    formState: { errors },
    handleSubmit,
    reset,
    watch
  } = useFormContext<ICohortForm>();
  const form = watch();

  const onCourseSelect = (newValue: OnChangeValue<IOption, false>) => {
    if (newValue) {
      reset({ offers: null, course: newValue });
      getOffers(newValue.value);
    }
  };

  const getOffers = (courseId: string) => {
    setLoading(true);
    dispatch(OffersActionAsync.getOffers(undefined, undefined, courseId, false, getOffersCallback));
  };

  const getOffersCallback = () => {
    setLoading(false);
  };

  const ValueContainer = ({ children, ...props }: ValueContainerProps<IOption, true>) => {
    const { getValue, hasValue } = props;
    const nbValues = getValue().length;
    if (!hasValue) {
      return <components.ValueContainer {...props}>{children}</components.ValueContainer>;
    }
    return (
      <components.ValueContainer {...props}>
        {`Offers selected: ${nbValues}`} {children}
      </components.ValueContainer>
    );
  };

  const MultiValueContainer = ({ children, ...props }: MultiValueGenericProps<IOption, true>) => {
    return <components.MultiValueContainer {...props}>{}</components.MultiValueContainer>;
  };

  const Option = ({ children, ...props }: OptionProps<IOption, true>) => {
    const { isSelected } = props;
    return (
      <components.Option {...props}>
        <div className={styles.select}>{isSelected && <IconCheckMark className={styles.checkmark} />}</div>
        {children}
      </components.Option>
    );
  };

  const NoOptionsMessage = ({ children, ...props }: NoticeProps<IOption, true>) => (
    <components.NoOptionsMessage {...props}>
      <div className={styles.noOption}>
        This course has no linked offers. Offers can be created in the <span>"Products"</span> section.
      </div>
    </components.NoOptionsMessage>
  );

  const onContinueClick = (data: ICohortForm) => {
    onContinue(data);
  };

  return (
    <>
      <ModalActionComponent
        title={'Create cohort training'}
        show={show}
        onCloseModal={onClose}
        iconType={'custom'}
        icon={<Icon32Group />}
      >
        <div className={styles.container}>
          <div className={styles.description}>
            A cohort allows you to duplicate a course with content already inside it. Changes made to the cohort will
            not be reflected in the course you are duplicating.
          </div>
          <Controller
            control={control}
            name={'course'}
            defaultValue={null}
            render={({ field: { onChange, onBlur, value } }) => (
              <Select
                classNamePrefix={'select'}
                className={'select-container select-lg mb-3'}
                placeholder={'Select a course to duplicate'}
                components={{
                  DropdownIndicator: DropdownIndicatorLg,
                  IndicatorSeparator: () => null
                }}
                options={courseOptions}
                onChange={(e) => {
                  onChange(e);
                  onCourseSelect(e);
                }}
                onBlur={onBlur}
                isSearchable={false}
                defaultValue={value}
              />
            )}
          />
          {loading && (
            <div className={styles.loading}>
              <IconSpinner className={'spinner'} />
            </div>
          )}
          {form.course && offerLoadState === 'allIsLoaded' && (
            <>
              <Controller
                control={control}
                name={'offers'}
                defaultValue={null}
                render={({ field: { onChange, onBlur, value } }) => (
                  <Select
                    classNamePrefix={'select'}
                    className={'select-container select-lg mb-3'}
                    placeholder={'Select linked offers'}
                    components={{
                      DropdownIndicator: DropdownIndicatorLgMulti,
                      IndicatorSeparator: () => null,
                      MultiValueContainer,
                      ValueContainer,
                      Option,
                      NoOptionsMessage
                    }}
                    options={offerOptions}
                    onChange={(value) => onChange(value)}
                    onBlur={onBlur}
                    hideSelectedOptions={false}
                    closeMenuOnSelect={false}
                    isClearable={false}
                    isSearchable={false}
                    isMulti
                    defaultValue={value}
                  />
                )}
              />
              <input
                {...register('suffix')}
                type="text"
                className={classNames('form-control form-control-lg mb-1', { invalid: errors.suffix })}
                placeholder={'Set the cohort suffix'}
                defaultValue={''}
              />
              <div className={classNames('help-text mb-4', { [styles.error]: errors.suffix })}>
                This suffix will be added after the course name and offers
              </div>
              <div className={'d-flex justify-content-end'}>
                <Button onClick={onClose} btnStyle={'transparent'} withIcon={false} className={'mr-2'}>
                  Cancel
                </Button>
                <Button onClick={handleSubmit(onContinueClick)} type={'arrow-right'} btnStyle={'primary'}>
                  Continue
                </Button>
              </div>
            </>
          )}
        </div>
      </ModalActionComponent>
    </>
  );
});
