import { ReactNode } from 'react';

import styles from './upload-header.module.scss';

interface IProps {
  children: ReactNode;
}

export const UploadHeader = ({ children }: IProps) => {
  return <div className={styles.containerCollapsedHeader}>{children}</div>;
};
