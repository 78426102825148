import { forwardRef, useCallback, useImperativeHandle, useState } from 'react';
import { useDispatch } from 'react-redux';
import { fileUploadActionAsync } from 'src/app/layout/shared-components/upload-worker/store/FileUploadActionAsync';

import styles from './audio-recorder-control.module.scss';

import { useDerivedState } from '../../helpers/use-derived-state';
import { useVoiceRecorder } from '../../hooks/use-voice-recorder';
import { AudioPlayerProvider } from '../audio-player/AudioPlayerProvider';
import { fileUrl } from '../file-upload/file-helper';
import { AudioRecordUpload } from './audio-record-upload/AudioRecordUpload';
import { AudioRecorder } from './audio-recorder/AudioRecorder';

export type AudioControlHandle = {
  onUpload: () => void;
  errorFile: boolean;
  isRecording: boolean;
  record: string | null;
};

interface IProps {
  audioSrc: string | null;
  uploadCallback?: (url: string) => void;
  fileCallback?: (file: File) => void;
  removeCallback?: () => void;
  withDelete?: boolean;
  id: string;
}
const controller = new AbortController();
export const AudioRecorderControl = forwardRef<AudioControlHandle, IProps>(
  ({ id, withDelete, audioSrc, uploadCallback, fileCallback, removeCallback }, forwardedRef) => {
    const dispatch = useDispatch();
    const [progress, setProgress] = useState(0);
    const [errorFile, setErrorFile] = useState(false);

    const [selectedFile, setFileSelected] = useState<File | null>(null);
    const [record, setRecord] = useDerivedState<string | null>(audioSrc, [audioSrc]);
    const { isRecording, stop, start } = useVoiceRecorder((data) => {
      const file = new File([data], `record-${id}.mp3`, {
        type: 'audio/mpeg'
      });
      setFileSelected(file);
      fileCallback?.(file);
      setRecord(window.URL.createObjectURL(data));
    });

    const removeRecord = useCallback(() => {
      setRecord(null);
      setFileSelected(null);
      removeCallback?.();
    }, [removeCallback, setRecord]);

    const onUploadCallback = useCallback(
      (link: string) => {
        const indexStart = link.indexOf('/audio/') + 7;
        const indexEnd = link.indexOf('.mp3') + 4;
        const objectName = link.slice(indexStart, indexEnd);
        uploadCallback?.(objectName);
        setRecord(link);
        setFileSelected(null);
        setProgress(0);
      },
      [uploadCallback, setRecord]
    );

    const onCancelUpload = () => {
      removeRecord();
      setProgress(0);
    };

    const retrieveUrl = useCallback(
      (file: File) => {
        dispatch(
          fileUploadActionAsync.retrieveUrl(
            file,
            (data) => {
              if (data) {
                const audioLink = fileUrl(data);
                dispatch(
                  fileUploadActionAsync.uploadFile(
                    {
                      file,
                      presigned_url: data.presigned_url
                    },
                    (event) => setProgress(Number(((event.loaded * 100) / event.total).toFixed(2))),
                    () => onUploadCallback(audioLink)
                  )
                );
              }
            },
            'audio'
          )
        );
      },
      [dispatch, onUploadCallback]
    );

    const onUpload = () => {
      if (selectedFile) {
        retrieveUrl(selectedFile);
      }
    };
    useImperativeHandle(forwardedRef, () => ({
      onUpload: onUpload,
      errorFile: errorFile,
      isRecording: isRecording,
      record: record
    }));

    return (
      <div>
        {!record && !selectedFile && !errorFile && (
          <AudioRecorder
            removeRecord={removeRecord}
            isRecording={isRecording}
            start={start}
            stop={stop}
            className={styles.container}
          />
        )}
        {record && progress === 0 && (
          <AudioPlayerProvider audioSrc={record} withDelete={withDelete} removeRecord={removeRecord} />
        )}
        <AudioRecordUpload
          fileCallback={fileCallback}
          isRunningRecord={isRecording}
          selectedFile={selectedFile}
          setFileSelected={setFileSelected}
          className={styles.container}
          record={record}
          progress={progress}
          error={errorFile}
          setError={setErrorFile}
          setRecord={setRecord}
          onCancelUpload={onCancelUpload}
        />
      </div>
    );
  }
);
