import React, { FC, memo } from 'react';
import { Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import classnames from 'classnames';
import styles from './tagEditGlobal.module.scss';
import { IconTrash } from '../../../../components/UI/icons';

import { Button, CustomTooltip, ModalActionComponent } from '../../../../components/UI';
import { ColorItem } from '../../../../components/UI/color/ColorItem';
import { ColorItems } from '../../../../interfaces';
import { TagActionAsync } from '../store/TagActionAsync';
import { ITag } from '../store/TagState';

interface IProps {
  show: boolean;
  newTag: ITag | null;
  bigTag: ITag | undefined;

  onCloseModal(): void;

  onChangeText(event: React.ChangeEvent<HTMLInputElement>): void;

  onChangeColor(background: string, color: string): void;

  onDeleteTagHandler(): void;
}

export const TagEditGlobal: FC<IProps> = memo((props) => {
  const { show, newTag, bigTag, onCloseModal, onChangeText, onChangeColor, onDeleteTagHandler } = props;
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<{ tag: string }>({
    resolver: yupResolver(
      yup.object().shape({
        tag: yup.string().required()
      })
    )
  });

  const onChangeTag = () => {
    if (newTag) {
      dispatch(TagActionAsync.editTag(newTag.id, newTag.title, newTag.background_color, newTag.foreground_color));
    }
  };

  return (
    <ModalActionComponent title={'Edit tag globally'} onCloseModal={onCloseModal} show={show} iconType="edit">
      <div className={styles.wrapper}>
        <div className={styles.description}>Changes made to this tag will impact all over the platform.</div>
        {newTag && (
          <>
            <Form.Group controlId={'tag'} className={'position-relative'}>
              <Form.Label>Tag name</Form.Label>
              <Form.Control
                {...register('tag')}
                onChange={onChangeText}
                defaultValue={newTag.title.trim()}
                isInvalid={!!errors.tag}
              />
              {!!errors.tag && <div className={styles.error}>Add a name for the tag</div>}
            </Form.Group>
            <div className={styles.tagTitle}>Tag color</div>
            <div className={styles.colorContainer}>
              {ColorItems.map((x, index) => (
                <div className={styles.colorItem} key={index}>
                  {newTag && (
                    <ColorItem
                      key={index}
                      background={x.background}
                      color={x.color}
                      tag={newTag}
                      onChangeColor={onChangeColor}
                    />
                  )}
                </div>
              ))}
              <CustomTooltip direction={'bottom'}>
                <div className={styles.randomColorContainer}>
                  <span className={styles.randomText}>Random</span>
                  <ColorItem background={'#ffd1ee'} color={'#990079'} tag={bigTag} />
                </div>
              </CustomTooltip>
            </div>
            <div className={styles.footerContainer}>
              <button type={'button'} className={classnames('btn btn-sm btn-icon-right')} onClick={onDeleteTagHandler}>
                Delete globally
                <IconTrash className={'icon'} />
              </button>

              <button type="button" className={classnames('btn btn-sm ml-auto mr-2')} onClick={onCloseModal}>
                Cancel
              </button>

              <Button type={'update'} onClick={handleSubmit(onChangeTag)}>
                Update settings
              </Button>
            </div>
          </>
        )}
      </div>
    </ModalActionComponent>
  );
});
