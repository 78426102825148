import actionCreatorFactory from 'typescript-fsa';

import { IPaginationResponse } from '../../../../interfaces';
import { IProductList } from './ProductListState';
import { IProduct, IProductField, ProductType } from './ProductsState';

export const actionCreator = actionCreatorFactory();

export const ProductsActions = {
  getProductsConfig: actionCreator.async<{ type: ProductType }, IProductList, Error>('GET_PRODUCTS_CONFIG'),
  getProducts: actionCreator.async<void, IPaginationResponse<IProduct[]>, Error>('GET_PRODUCTS'),
  getProduct: actionCreator.async<string, IProduct, Error>('GET_PRODUCT'),
  createProduct: actionCreator.async<{ fields: IProductField }, IProduct, Error>('CREATE_PRODUCT'),
  editProduct: actionCreator.async<{ id: string; fields: IProductField }, IProduct, Error>('EDIT_PRODUCT'),
  deleteProduct: actionCreator.async<{ id: string }, void, Error>('DELETE_PRODUCT')
};
