import { useHistory } from 'react-router-dom';
import { IImage } from '@demonvv/everact-widget';

import classNames from 'classnames';
import { ReactComponent as Logotype } from '../../../assets/svg/everact_logo_sign.svg';

import { Image } from '../../components/UI/image-components/image/Image';
import { mainPaths } from '../../modules/MainRoutes';

interface IProps {
  customStylesContainer?: string;
  customStyles?: string;
  width?: number;
  height?: number;
  themeLogo?: IImage | null;
}

export const Logo = ({ customStyles, customStylesContainer, height = 48, width = 48, themeLogo = null }: IProps) => {
  const history = useHistory();

  const goToDashboard = () => {
    history.push(mainPaths.main);
  };

  return (
    <div className={classNames('logo', customStylesContainer)} onClick={goToDashboard}>
      {!!themeLogo ? (
        <Image
          imgProps={{ height, width }}
          size={{ height, width }}
          bucket_name={themeLogo?.bucket_name}
          object_name={themeLogo?.object_name}
          className={customStyles}
        />
      ) : (
        <Logotype width={width} height={height} className={customStyles} />
      )}
    </div>
  );
};
