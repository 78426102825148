import styles from './fieldPayload.module.scss';

import { CheckButton } from '../../../../components/UI';

interface IProps {
  isShort: boolean;
  label: string;
}

export const FieldTextPayload = ({ isShort, label }: IProps) => {
  return (
    <div className={styles.container}>
      <CheckButton
        name={'isChecked'}
        value={label}
        type={'checkbox'}
        params={{ defaultChecked: isShort, className: 'checkbox-button my-1' }}
      />
    </div>
  );
};
