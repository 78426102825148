import { memo, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { orderBy } from 'lodash';

import classNames from 'classnames';
import styles from './quiz-detail.module.scss';

import { IQuizSession, IQuizSessionDetail, PassingGradeTypeValues } from '../../../../../../../interfaces/quiz';
import { coursePaths } from '../../../../../courses/routes/CourseRoutes';
import { QuizModeration } from '../../../quiz-moderation/QuizModeration';
import { getPathToQuizNode } from '../../helpers/getPathToQuizNode';
import { QuizSessionsActionAsync } from '../../store/QuizSessionsActionAsync';
import { QuizLocation } from '../quiz-location/QuizLocation';
import { QuizQuestion } from '../quiz-question/QuizQuestion';
import { QuizDetailAttempt } from './quiz-detail-attempt/QuizDetailAttempt';
import { QuizDetailHeader } from './quiz-detail-header/QuizDetailHeader';
import { QuizPassingGrade } from './quiz-passing-grade/QuizPassingGrade';

interface IProps {
  id: string;
  sessions?: (IQuizSession & { attempt: number })[];
  groupSession?: IQuizSession[];
  courseId: string | undefined;
  sessionDetail?: IQuizSession;
  showStudentDetail: boolean;

  onChangeSession?(id: string): void;
}

export const QuizDetail = memo(
  ({ id, sessions, courseId, sessionDetail, onChangeSession, groupSession, showStudentDetail }: IProps) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [quizDetail, setQuizDetail] = useState<IQuizSessionDetail | IQuizSession>();
    const [loading, setLoading] = useState<boolean>(false);

    const currentSession = (sessions || []).find((session) => session.id === id);

    useEffect(() => {
      if (!sessionDetail) {
        dispatch(
          QuizSessionsActionAsync.getQuizSession(id, (data) => {
            setQuizDetail(data);
            setLoading(false);
          })
        );
      } else {
        setQuizDetail(sessionDetail);
      }
    }, [dispatch, id, sessionDetail]);

    const onOpenQuiz = () => {
      let hierarchy = currentSession?.hierarchy || quizDetail?.hierarchy;

      if (hierarchy?.id) {
        const treeNodes = getPathToQuizNode(hierarchy);
        const step = treeNodes?.find((node) => node.type === 'step');

        if (step && courseId) {
          history.push(coursePaths.courseLessons(courseId, step.id));
        }
      }
    };

    const quizPassingGrade = quizDetail?.passing_grade;
    const totalQuestions = quizDetail?.quiz_dump.questions.length;

    return (
      <>
        {showStudentDetail && quizDetail?.student && (
          <QuizDetailHeader
            courseTitle={quizDetail.course?.title}
            email={quizDetail.student?.email}
            date_create={quizDetail.date_create}
            first_name={quizDetail.student?.first_name}
            last_name={quizDetail.student?.last_name}
          />
        )}
        <div className={classNames(styles.header, { [styles.containerShadow]: !!sessionDetail })}>
          <div className={'pt-4 px-6 pb-2'}>
            <QuizDetailAttempt
              id={id}
              date_create={quizDetail?.date_create}
              onChangeSession={onChangeSession}
              result={quizDetail?.result}
              state={quizDetail?.state}
              groupSession={groupSession}
            />
            {quizDetail?.quiz_dump.title && <div className={styles.title}>{quizDetail?.quiz_dump.title}</div>}
            {(currentSession?.hierarchy?.id || quizDetail?.hierarchy?.id) && (
              <QuizLocation
                maxLengthItems={3}
                className={styles.breadcrumbs}
                innerClass={styles.color}
                hierarchy={currentSession?.hierarchy || quizDetail?.hierarchy}
              >
                <div className={styles.open}>
                  &bull;
                  <button type={'button'} className={'btn'} onClick={onOpenQuiz}>
                    Open quiz
                  </button>
                </div>
              </QuizLocation>
            )}
          </div>
          {quizPassingGrade && quizDetail && (
            <div className={styles.passing}>
              {quizPassingGrade?.type === PassingGradeTypeValues.by_question && (
                <QuizPassingGrade
                  required={quizPassingGrade.questions_count}
                  scored={quizDetail.result?.total_score || 0}
                  total={totalQuestions || 0}
                  type={PassingGradeTypeValues.by_question}
                />
              )}
              {quizPassingGrade?.type === PassingGradeTypeValues.by_answer_points && (
                <QuizPassingGrade
                  required={quizPassingGrade.points_count}
                  scored={quizDetail.result?.total_score || 0}
                  total={quizDetail.result?.total_points || 0}
                  type={PassingGradeTypeValues.by_answer_points}
                />
              )}
            </div>
          )}
        </div>
        {quizDetail && quizDetail.quiz_dump.moderate_answers && !showStudentDetail && (
          <QuizModeration
            loading={loading}
            id={id}
            setLoading={setLoading}
            setQuizDetail={setQuizDetail}
            status={quizDetail.state}
            showRedirectToAnswer
            className={''}
          />
        )}
        <div
          className={classNames(
            styles.content,
            { [styles.containerShadow]: !!sessionDetail },
            !showStudentDetail && !quizDetail?.quiz_dump.moderate_answers && 'mt-0'
          )}
        >
          {quizDetail &&
            orderBy(quizDetail.quiz_dump.questions, ['order'], ['asc']).map((question, index) => (
              <QuizQuestion
                key={index}
                question={question}
                quizDetail={quizDetail}
                index={index}
                customStyles={!!sessionDetail ? styles.quizQuestion : ''}
              />
            ))}
        </div>
        {quizDetail && quizDetail.quiz_dump.moderate_answers && showStudentDetail && (
          <div className={'my-1'}>
            <QuizModeration
              loading={loading}
              id={id}
              setLoading={setLoading}
              setQuizDetail={setQuizDetail}
              status={quizDetail.state}
            />
          </div>
        )}
      </>
    );
  }
);
