import { useState } from 'react';
import { Collapse } from 'react-bootstrap';
import { useRouteMatch } from 'react-router-dom';

import classNames from 'classnames';
import styles from '../lesson-student.module.scss';
import { IconChevronDown, IconChevronUp } from '../../../../components/UI/icons';

import { BlockType, CommonCourseBlock } from '../../../../interfaces';
import { previewPaths } from '../../../lms/preview/routes/PreviewRoutes';
import { Audio } from '../../common/audio/Audio';
import { FileStudent } from '../../common/file-student/FileStudent';
import { Video } from '../../common/video/Video';
import { Embed } from '../blocks/embed/Embed';
import { ViewQuizWrapper } from '../blocks/quiz/ViewQuizWrapper';
import { Text } from '../blocks/text/Text';

export const LessonBlock = ({ block }: { block: CommonCourseBlock }) => {
  const [open, setOpen] = useState(!block.is_hidden);
  const isPreview = useRouteMatch(previewPaths.previewLessons());

  const onOpen = () => {
    setOpen(!open);
  };

  return (
    <div className={styles.block}>
      {block.title && (
        <div className={styles.title} onClick={onOpen}>
          {block.title}
          {open ? <IconChevronUp className={styles.chevron} /> : <IconChevronDown className={styles.chevron} />}
        </div>
      )}
      <Collapse in={open} timeout={200}>
        <div
          className={classNames(styles.container, {
            [styles.unsetOverflow]: block.type === BlockType.AUDIO || block.type === BlockType.QUIZ
          })}
        >
          {block.type === BlockType.VIDEO && <Video video={block.video} />}
          {block.type === BlockType.AUDIO && <Audio audio={block.audio} />}
          {block.type === BlockType.TEXT && <Text block={block} />}
          {block.type === BlockType.EMBED && <Embed block={block} />}
          {block.type === BlockType.QUIZ && <ViewQuizWrapper block={block} isPreview={!!isPreview} />}
          {block.type === BlockType.BLOCK_ATTACHMENTS && block.attachments.length > 0 && <FileStudent block={block} />}
        </div>
      </Collapse>
    </div>
  );
};
