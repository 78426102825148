import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';

import classNames from 'classnames';
import sidebarStyles from '../../../../styles/components/sidebar.module.scss';
import styles from './student-courses.module.scss';
import { Soon } from '../../../components/UI/icons';

import { sidebarStudentCabinetMenu } from '../../../App';
import { CommonModal, Loader } from '../../../components/UI';
import { LoadState } from '../../../store';
import { useViewCourseContext } from '../../lms/students/student-course/ViewCourseContext';
import { CourseFooter } from '../common/course-footer/CourseFooter';
import { EmptyCourse } from '../common/empty-courses/EmptyCourse';
import { StudentCabinetActionAsync } from '../store/StudentCabinetActionAsync';
import { StudentCabinetSelector } from '../store/StudentCabinetSelector';
import { CourseBlock } from './course-block/CourseBlock';
import { CourseNavbar } from './course-navbar/CourseNavbar';

export const StudentCourses = () => {
  const dispatch = useDispatch();
  const [extend, setExtend] = useState(false);
  const coursesMemo = useMemo(StudentCabinetSelector, []);
  const { cabinetSettings } = useSelector((state) => state.studentCourses);
  const { courses, loadState } = useSelector((state) => coursesMemo(state));
  const { course } = useViewCourseContext();

  useEffect(() => {
    if (cabinetSettings?.id) {
      dispatch(StudentCabinetActionAsync.getStudentCabinetCourses(cabinetSettings.id, course?.id));
    }
  }, [cabinetSettings?.id, course.id, dispatch]);

  const onToggleCollapse = useCallback(() => {
    setExtend((x) => !x);
  }, []);

  const onCloseCollapse = useCallback(() => {
    setExtend(false);
  }, []);

  return (
    <>
      <div className={'sticky-top'}>
        <CourseNavbar onToggleCollapse={onToggleCollapse} showMenuBtn={true} />
      </div>

      <div className={classNames('content', styles.container)}>
        {loadState === LoadState.firstLoad ? (
          <Loader />
        ) : courses.new.length || courses.inProgress.length || courses.completed.length ? (
          <div className={'mb-auto'}>
            {courses.new.length > 0 && <CourseBlock title={'New courses'} course={courses.new} />}
            {courses.inProgress.length > 0 && <CourseBlock title={'In progress'} course={courses.inProgress} />}
            {courses.completed.length > 0 && <CourseBlock title={'Completed'} course={courses.completed} />}
          </div>
        ) : (
          <EmptyCourse />
        )}
        <CourseFooter className={classNames({ 'mt-auto': loadState === LoadState.firstLoad })} />
      </div>
      <CommonModal
        onClose={onCloseCollapse}
        show={extend}
        params={{ centered: false, dialogClassName: styles.modalDialog, contentClassName: styles.modalContent }}
      >
        <CourseNavbar showMenuBtn={false} onToggleCollapse={onCloseCollapse} />
        <div className={'d-flex justify-content-between flex-column flex-sm-row'}>
          {sidebarStudentCabinetMenu.map(({ name, icon, to }, index) => (
            <Fragment key={index}>
              {to.includes('courses') ? (
                <NavLink
                  to={{ pathname: to, state: { isStudent: true } }}
                  exact
                  className={classNames(styles.navLink, 'pl-2 pt-3 pb-3 pr-3 pl-sm-4 pt-sm-4 pb-sm-4')}
                  activeClassName={sidebarStyles.active}
                  onClick={onCloseCollapse}
                >
                  <span>
                    <span className={'mr-3'}>{icon}</span> {name}
                  </span>
                </NavLink>
              ) : (
                <div className={classNames(styles.navLink, 'pl-2 pt-3 pb-3 pr-3 pl-sm-4 pt-sm-4 pb-sm-4')}>
                  <span>
                    <span className={'mr-3'}>{icon}</span> {name}
                  </span>
                  <Soon className={styles.soonIcon} />
                </div>
              )}
            </Fragment>
          ))}
        </div>
      </CommonModal>
    </>
  );
};
