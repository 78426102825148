import React from 'react';
import { useParams } from 'react-router-dom';

import { ContactList } from '../../contact/contact-list/ContactList';
import { Contacts } from '../../contact/contacts/Contacts';

export const ListContacts = () => {
  let { list_id } = useParams<{ list_id: string }>();

  return list_id ? <ContactList listId={list_id} /> : <Contacts />;
};
