import React, { memo, useCallback, useState } from 'react';
import { groupBy } from 'lodash';

import classNames from 'classnames';
import styles from './variable.module.scss';

import { NoSearchResult } from '../../../../components/no-search-result/NoSearchResult';
import { Search } from '../../../../components/search/Search';
import { VariablesInfo } from './info/VariablesInfo';
import { IEmailVariable } from './variables';

interface IProps {
  onSelectVariable: (data: React.SyntheticEvent<HTMLDivElement>) => void;
  variables: IEmailVariable[];
  onSearchVariables: (value: string) => void;
}

export const VariableEditor = memo(({ onSelectVariable, onSearchVariables, variables }: IProps) => {
  const [value, setValue] = useState('');
  const [showInfo, setShowInfo] = useState(false);

  const onToggleInfo = useCallback(() => {
    setShowInfo((x) => !x);
  }, []);

  const onChangeInput = useCallback(
    (data: React.SyntheticEvent<HTMLInputElement>) => {
      const searchValue = data.currentTarget.value;
      setValue(searchValue);
      onSearchVariables(searchValue);
    },
    [onSearchVariables]
  );

  const onClear = useCallback(() => {
    setValue('');
  }, []);

  const groupedEntityVariables = groupBy(variables, 'entity');

  return (
    <div className={styles.wrapper}>
      <div className={classNames(styles.container, { [styles.show]: showInfo })}>
        <div className={'p-3'}>
          <div className={'search-field'}>
            <Search
              className={styles.sizeContainer}
              isPage={true}
              onChange={onChangeInput}
              value={value}
              placeholder={'Search variable'}
              showClear={true}
              onClear={onClear}
            />
          </div>
          {value.trim() !== '' ? (
            variables.map((variable, fieldIndex) => (
              <div
                key={fieldIndex}
                className={styles.field}
                data-name={variable.name}
                data-key={variable.key}
                onClick={onSelectVariable}
              >
                {variable.name}
              </div>
            ))
          ) : (
            <>
              {Object.entries(groupedEntityVariables).map(([entity, variablesEntity], indexVar) => (
                <div key={indexVar} className={styles.field}>
                  <div className={styles.head}>{entity}</div>
                  {variablesEntity.map((variable, index) => (
                    <div
                      key={index}
                      className={styles.field}
                      data-name={variable.name}
                      data-key={variable.key}
                      onClick={onSelectVariable}
                    >
                      {variable.name}
                    </div>
                  ))}
                </div>
              ))}
            </>
          )}

          {variables.length === 0 && value.trim() !== '' && (
            <NoSearchResult searchString={value} className={styles.noSearch} />
          )}
        </div>
      </div>
      <VariablesInfo onToggle={onToggleInfo} show={showInfo} />
    </div>
  );
});
