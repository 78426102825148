import { memo } from 'react';
import moment from 'moment';

import classNames from 'classnames';
import styles from './available-node.module.scss';

interface IProps {
  openAt: string;
  isSelected: boolean;
}

export const AvailableNode = memo(({ openAt, isSelected }: IProps) => {
  return (
    <div className={classNames(styles.dripNode, isSelected && styles.selected)}>
      Available on: {moment(openAt).format('DD MMMM, yyyy, hh:mm A')}
    </div>
  );
});
