import { memo } from 'react';
import Frame from 'react-frame-component';
import InnerHTML from 'dangerously-set-html-content';
import { AudioPlayerProvider } from 'src/app/components/audio-player/AudioPlayerProvider';
import { Button } from 'src/app/components/UI/button/Button';
import { AudioField, TypeOfUploader } from 'src/app/interfaces/fileUpload';

import styles from './ready-video.module.scss';
import { IconReload } from 'src/app/components/UI/icons';

interface IProps {
  audio?: AudioField;
  onOpenModal: () => void;
  onRemoveAudio: () => void;
  className?: string;
}
export const ReadyAudio = memo(({ audio, onOpenModal, onRemoveAudio, className }: IProps) => {
  const isReadyPlayer = audio?.type === TypeOfUploader.upload || audio?.type === TypeOfUploader.link;

  const isEmbedAudio = audio?.code && audio?.type === TypeOfUploader.embed;
  const audioSrc = audio?.audio_url || audio?.url;
  return (
    <div className={className}>
      {isReadyPlayer && audioSrc && <AudioPlayerProvider audioSrc={audioSrc} />}
      {isEmbedAudio && audio?.code && (
        <div className={styles.audioWrapper}>
          <Frame
            scrolling={'no'}
            className={styles.iframe}
            initialContent={
              // eslint-disable-next-line quotes
              "<!DOCTYPE html><html lang='en'><head><style>iframe {width: 100%;height: 100%;min-height: 432px;}</style><title></title></head><body style='margin: 0'><div></div></body></html>"
            }
          >
            <InnerHTML html={audio?.code} />
          </Frame>
        </div>
      )}
      <div className={'d-flex mt-2'}>
        <Button onClick={onOpenModal} btnStyle={'secondary'} customIcon={<IconReload />}>
          Change upload
        </Button>
        <Button type={'delete'} btnStyle={'warning'} className={'ml-2'} onClick={onRemoveAudio}>
          Remove
        </Button>
      </div>
    </div>
  );
});
