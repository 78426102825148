import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import classNames from 'classnames';
import styles from './answer-sidebar.module.scss';

import { courseActionAsync } from '../../../courses/store/CourseActionAsync';
import { ICourse } from '../../../courses/store/CourseState';
import { AnswersCurses, IAnswersCourses } from '../answers-curses/answersCurses';
import { IChips, QuizFilter } from '../quiz-filter/QuizFilter';

export interface IParamSidebar {
  coursesId: string[] | null;
  statesId: string[] | null;
  moderateAnswers: boolean | null;
}

interface IProps {
  onSelectParam: (params?: IParamSidebar) => void;
}

export const AnswersSidebar = ({ onSelectParam }: IProps) => {
  const dispatch = useDispatch();
  const [courses, setCourses] = useState<string[] | null>(null);
  const [chips, setChips] = useState<string[] | null>(null);
  const [moderateAnswers, setModerateAnswers] = useState<boolean | null>(null);
  const [coursesList, setCoursesList] = useState<IAnswersCourses[]>([]);

  const onSelectChips = useCallback(
    (chipsState: IChips[]) => {
      const chipsSelected = chipsState.filter((chip) => chip.selected);
      const defaultChipSelected = chipsSelected.find((chip) => !chip.value);
      const chipsStates = chipsSelected.filter((chip) => chip.value).map((chip) => chip.value) as string[];
      let moderateAnswersFlag = null;

      if (defaultChipSelected?.selected || !chipsStates?.length) {
        setChips(null);
        moderateAnswersFlag = null;
      } else {
        setChips(chipsStates);
        moderateAnswersFlag = false;
      }

      if (chipsStates?.includes('moderated')) {
        moderateAnswersFlag = true;
        onSelectParam({
          coursesId: courses,
          statesId: chipsStates.filter((chip) => chip !== 'moderated'),
          moderateAnswers: moderateAnswersFlag
        });
      } else {
        onSelectParam({
          coursesId: courses,
          statesId: !chipsStates?.length ? null : chipsStates,
          moderateAnswers: moderateAnswersFlag
        });
      }
      setChips(chipsStates.filter((chip) => chip !== 'moderated'));
      setModerateAnswers(moderateAnswersFlag);
    },
    [courses, onSelectParam]
  );

  const onSelectCourses = useCallback(
    (answersCourses: IAnswersCourses[]) => {
      const coursesSelected = answersCourses.filter((course) => course.selected);
      const defaultCoursesSelected = coursesSelected.find((course) => !course.id);
      const coursesIds = coursesSelected.filter((course) => course.id).map((course) => course.id) as string[];

      if (defaultCoursesSelected?.selected || !coursesIds?.length) {
        setCourses(null);
      } else {
        setCourses(coursesIds);
      }
      onSelectParam({ coursesId: !coursesIds?.length ? null : coursesIds, statesId: chips, moderateAnswers });
    },
    [chips, moderateAnswers, onSelectParam]
  );

  const setSelectedData = useCallback(
    (data: ICourse[]) => {
      if (data) {
        const coursesList: IAnswersCourses[] = data.map((course) => {
          return {
            id: course.id,
            title: course.title,
            thumbnail: course.settings.thumbnail,
            selected: false
          };
        });
        coursesList.unshift({ title: 'All courses', id: null, selected: true });
        setCoursesList(coursesList);
      }
    },
    [setCoursesList]
  );

  const resetCoursesSelection = useCallback(() => {
    const courses: IAnswersCourses[] = coursesList.map((course) => {
      return {
        ...course,
        selected: course.id === null
      };
    });
    setCoursesList(courses);
    onSelectParam();
  }, [coursesList, onSelectParam]);

  useEffect(() => {
    dispatch(courseActionAsync.getCourses(setSelectedData));
  }, [dispatch, setSelectedData]);

  return (
    <aside className={classNames(styles.sidebar)}>
      <QuizFilter onSelectChips={onSelectChips} resetCourses={resetCoursesSelection} coursesList={coursesList} />
      <AnswersCurses onSelectCourses={onSelectCourses} coursesList={coursesList} setCoursesList={setCoursesList} />
    </aside>
  );
};
