import { CSSProperties } from 'react';
import { useHistory } from 'react-router-dom';

import classNames from 'classnames';
import styles from './unavailableBtnPlan.module.scss';
import { Icon16ChevronRight, IconChevronRight } from '../UI/icons';

import { SettingsPaths } from '../../modules/settings/routes/SettingsRoutes';
import { Button, CustomTooltip } from '../UI';

const title: CSSProperties = {
  textAlign: 'left',
  fontSize: '14px',
  fontWeight: '600'
};

const description: CSSProperties = { textAlign: 'left', fontSize: '12px' };

interface IProps {
  titleTooltip: string;
  descriptionTooltip: string;
  size?: 'small' | 'medium' | 'large';
  className?: string;
}
export const UnavailableBtnPlan = ({ descriptionTooltip, titleTooltip, className, size = 'medium' }: IProps) => {
  const history = useHistory();

  const planRedirect = () => {
    history.push(SettingsPaths.billing);
  };
  return (
    <CustomTooltip
      direction={'top'}
      customText={
        <>
          <div style={title}>{titleTooltip}</div>
          <div style={description}>{descriptionTooltip}</div>
        </>
      }
    >
      <Button
        onClick={planRedirect}
        withIcon={true}
        customIcon={size === 'small' ? <Icon16ChevronRight /> : <IconChevronRight />}
        className={classNames(styles.planUpgradeBtn, { [styles.smallBtn]: size === 'small' }, className)}
      >
        Upgrade plan
      </Button>
    </CustomTooltip>
  );
};
