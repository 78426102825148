import { CSSProperties, FC, useState } from 'react';
import { ReactNewWindowStyles } from 'react-new-window-styles';
import { IOffer, Steps, Widget } from '@demonvv/everact-widget';

interface IProps {
  offerId: string;
  offer: Omit<IOffer, 'account_id' | 'entity' | 'student_domain_url' | 'paypal_merchant_id'>;
  onCloseWindow: () => void;
}

export const RenderInWindow: FC<IProps> = ({ offer, offerId, onCloseWindow }) => {
  const [activeStep, setActiveStep] = useState<Steps>('cart');

  const onPrev = () => {
    setActiveStep('cart');
  };
  const onNext = () => {
    setActiveStep('checkout');
  };
  const wrapperStyles: CSSProperties = {
    background: offer.settings_background_color || 'rgba(0, 23, 51, 0.5)',
    height: '100%',
    padding: '1rem 0',
    overflowY: 'auto'
  };

  return (
    <>
      <ReactNewWindowStyles
        title={'everact.io — Checkout Preview'}
        windowProps={{
          resizable: true,
          scrollbars: true,
          width: window.innerWidth,
          height: window.innerHeight
        }}
        onClose={onCloseWindow}
        autoClose
        copyStyles
      >
        <div style={wrapperStyles}>
          <Widget
            activeStep={activeStep}
            offerId={offerId}
            setActiveStep={setActiveStep}
            widgetPlacement={'page'}
            offerData={offer as unknown as IOffer | null | undefined}
            toCheckout={onNext}
            backToCart={onPrev}
          />
        </div>
      </ReactNewWindowStyles>
    </>
  );
};
