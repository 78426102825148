import React, { memo, useState } from 'react';
import { FormCheck } from 'react-bootstrap';

import styles from './course-progress.module.scss';

import { ICourseNode } from '../../../../courses/store/CourseState';
import { IStudentAnalytics } from '../../../store/StudentListState';
import { Progress } from '../../progress/Progress';
import { ListItem } from '../list-item/ListItem';

interface IProps {
  lessonList: ICourseNode[];
  analytics?: IStudentAnalytics;
}

export const CourseProgress = memo(({ lessonList, analytics }: IProps) => {
  const [lessonLocation, setLessonLocation] = useState<boolean>(false);

  const onChange = (value: boolean) => {
    setLessonLocation(value);
  };

  return (
    <>
      <div className={'d-flex justify-content-between'}>
        <FormCheck className={'custom-control custom-switch custom-switch-sm mb-1'} custom type={'switch'}>
          <FormCheck.Input
            type="checkbox"
            id={'location'}
            className="custom-control-input"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              onChange(e.target.checked);
            }}
            checked={lessonLocation}
          />
          <FormCheck.Label className="custom-control-label" htmlFor={'location'}>
            Lesson location in hierarchy
          </FormCheck.Label>
        </FormCheck>
        <Progress analytics={analytics} />
      </div>
      <div className={styles.lessonList}>
        {lessonList.map((lessonItem, index) => (
          <ListItem lessonItem={lessonItem} location={lessonLocation} hierarchy={analytics?.hierarchy} key={index} />
        ))}
      </div>
    </>
  );
});
