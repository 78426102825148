import { IPaginationParams } from '../interfaces';
import { IOffer, IOfferState } from '../modules/crm/offers/store/OffersState';
import { IProduct } from '../modules/crm/products/store/ProductsState';
import { baseFetch } from './BaseFetch';

export const getOffers = async (
  pagination?: IPaginationParams,
  state?: IOfferState,
  course_id?: string,
  exclusive_for_course?: boolean
) => {
  let params = {};
  if (pagination) {
    params = { ...pagination };
  }
  if (state) {
    params = { ...params, state };
  }
  if (course_id) {
    params = { ...params, course_id };
  }
  if (exclusive_for_course) {
    params = { ...params, exclusive_for_course };
  }
  return await baseFetch<IPaginationParams | {}, IOffer[]>('/offers', params, 'GET', true);
};

export const getOffer = async (id: string) => {
  return await baseFetch<{}, IOffer>(`/offers/${id}`, {}, 'GET', true);
};

export const createOffer = async (courseId?: string) => {
  return await baseFetch<{ products: string[] }, IOffer>(
    '/offers',
    { products: courseId ? [courseId] : [] },
    'POST',
    true
  );
};

export const editOffer = async (id: string, offer: Partial<IOffer>) => {
  return await baseFetch<Partial<IOffer>, IOffer>(`/offers/${id}`, offer, 'PUT', true);
};

export const deleteOffer = async (id: string) => {
  await baseFetch<{}, {}>(`/offers/${id}`, {}, 'DELETE', true);
};

export const editOfferState = async (id: string, state: IOfferState) => {
  return await baseFetch<{}, { offer: IOffer; status: string }>(`/offers/${id}/${state}`, {}, 'PUT', true);
};

export const getOfferProducts = async (pagination?: IPaginationParams, query?: string) => {
  return await baseFetch<IPaginationParams | {}, IProduct[]>(
    '/offers/products',
    query ? { ...pagination, ...{ q: query } } : { ...pagination },
    'GET',
    true
  );
};
