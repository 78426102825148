import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { Icon16NewWindow } from '../../../../components/UI/icons';

import { CRMPaths } from '../../routes/CRMRoutes';
import { EntityIdWrapper } from './EntityIdWrapper';

interface IProps {
  studentId: string;
}

export const StudentId = ({ studentId }: IProps) => {
  const { students } = useSelector((state) => state.students);
  const fields = useSelector((state) => state.fields.fields);
  const fieldsOfStudent = students.find((x) => x.id === studentId)?.fields;
  const fieldRelationStudent =
    fieldsOfStudent &&
    fields.find(
      (x) => Object.keys(fieldsOfStudent).includes(x.id) && x.type === 'relation' && x.scope === 'contact_relation'
    );
  const student = students.find((student) => student.id === studentId);
  const contactIdFromStudent =
    fieldRelationStudent &&
    student &&
    fieldRelationStudent?.id in student?.fields &&
    student?.fields[fieldRelationStudent.id];

  return (
    <EntityIdWrapper entityId={studentId}>
      {contactIdFromStudent && (
        <Link
          className={'d-flex align-items-center ml-2'}
          to={{ pathname: CRMPaths.crmContacts, state: { contactIdFromStudent } }}
        >
          View contact in CRM <Icon16NewWindow className={'ml-1'} />
        </Link>
      )}
    </EntityIdWrapper>
  );
};
