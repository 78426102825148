import { FormControl } from 'react-bootstrap';
import { FormControlProps } from 'react-bootstrap/FormControl';
import { useFormContext } from 'react-hook-form';

import styles from './send-to.module.scss';

interface IProps {
  label: string;
  name: string;
  defaultValue?: string;
  props?: FormControlProps;
}

export const SendInput = ({ label, name, defaultValue, props }: IProps) => {
  const { register, formState: {errors} } = useFormContext();

  return (
    <>
      <label className={styles.label}>{label}</label>
      <FormControl
        className={'form-control form-control-sm'}
        placeholder={'example@mail.com'}
        defaultValue={defaultValue}
        {...register(name)}
        {...props}
        isInvalid={!!errors[name]}
      />
    </>
  );
};
