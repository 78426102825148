import React, { memo } from 'react';

import classnames from 'classnames';
import styles from './course-drop-menu.module.scss';

interface IProps {
  icon: JSX.Element;
  title: string | JSX.Element;
  id?: string;
  onClick?: (e: React.SyntheticEvent<HTMLDivElement>) => void;
  containerStyles?: string;
  titleStyles?: string;
  isDisable?: boolean;
}

export const CourseDropMenu = memo(
  ({ id = '', icon, title, onClick, containerStyles, titleStyles, isDisable }: IProps) => {
    return (
      <div className={styles.wrapper}>
        <div
          className={classnames(styles.container, isDisable && styles.disable, containerStyles)}
          onClick={!isDisable ? onClick : undefined}
          data-id={id}
        >
          <div className={styles.icon}>{icon}</div>
          <span className={classnames(styles.title, isDisable && styles.disable, titleStyles, 'ellipsis')}>
            {title}
          </span>
        </div>
      </div>
    );
  }
);
