import { useMemo, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useSelector } from 'react-redux';
import { AudioField, TypeOfUploader } from 'src/app/interfaces/fileUpload';
import { FileUploadByUrlSelector } from 'src/app/layout/shared-components/upload-worker/store/FileUploaderSelector';
import { IFileUploader } from 'src/app/layout/shared-components/upload-worker/store/FileUploaderState';

import { FileEditInModal } from '../video-uploader/file-edit-in-modal/FileEditInModal';
import { AudioControl } from './audio-control/AudioControl';
import { ReadyAudio } from './ready-audio/ReadyAudio';

interface IProps {
  audio?: AudioField;
  onSetAudioOptional?: (audioField: AudioField) => void;
  onRemoveAudioOptional?: () => void;
  onCancel: (file: IFileUploader) => void;
  retrieveAndUpload: (file: File) => void;
}
export const AudioUploader = ({
  audio,
  onCancel,
  onRemoveAudioOptional,
  onSetAudioOptional,
  retrieveAndUpload
}: IProps) => {
  const [audioData, setAudioData] = useState<AudioField | undefined>(audio);
  const [typeOfUploader, setTypeOfUploader] = useState<TypeOfUploader | null>(null);
  const [showModal, setShowModal] = useState(false);

  const fileUploaderMemo = useMemo(() => FileUploadByUrlSelector(audio?.audio_url), [audio?.audio_url]);
  const { fileUploaded } = useSelector(fileUploaderMemo);

  const onCloseModal = () => {
    setTypeOfUploader(null);
    setShowModal(false);
  };

  const onOpenModal = () => {
    setTypeOfUploader(audio?.type || TypeOfUploader.upload);
    setShowModal(true);
  };

  const onSetAudio = (audioField: AudioField) => {
    onSetAudioOptional?.(audioField);
  };

  const onRemoveAudio = () => {
    setAudioData(undefined);
    onRemoveAudioOptional?.();
  };

  const statusShowAudio =
    (!fileUploaded &&
      (audio?.audio_url || audio?.url || audio?.code || audioData?.audio_url || audioData?.url || audioData?.code)) ||
    (fileUploaded?.uploaderData.status === 'complete' && fileUploaded?.transcodingStatus?.state === 'ready');

  return (
    <DndProvider backend={HTML5Backend}>
      <div className={'audio'}>
        {statusShowAudio &&
          !(fileUploaded?.uploaderData.status === 'canceled' || fileUploaded?.uploaderData.status === 'error') &&
          (audio || audioData) && (
            <ReadyAudio audio={audio || audioData} onOpenModal={onOpenModal} onRemoveAudio={onRemoveAudio} />
          )}
        {!statusShowAudio && (
          <AudioControl
            retrieveAndUpload={retrieveAndUpload}
            onCancel={onCancel}
            onSetAudio={onSetAudio}
            onCloseModal={onCloseModal}
            setTypeOfUploader={setTypeOfUploader}
            typeOfUploader={typeOfUploader}
            audio={audio}
            fileUploaded={fileUploaded}
          />
        )}
        <FileEditInModal onCloseModal={onCloseModal} showModal={showModal}>
          <AudioControl
            onCancel={onCancel}
            retrieveAndUpload={retrieveAndUpload}
            onSetAudio={onSetAudio}
            onCloseModal={onCloseModal}
            setTypeOfUploader={setTypeOfUploader}
            typeOfUploader={typeOfUploader}
            audio={audio}
            fileUploaded={fileUploaded}
          />
        </FileEditInModal>
      </div>
    </DndProvider>
  );
};
