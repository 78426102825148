import { CSSProperties, ReactNode, useCallback } from 'react';

import classNames from 'classnames';
import styles from './sidebar-wrapper.module.scss';

import { imageUrl } from '../../../../components/UI/image-components/image/Image';
import { IFile } from '../../../../interfaces';

interface IProps {
  children: ReactNode;
  loaded?: boolean;
  themeSignIn?: IFile | null;
}

export const SidebarWrapper = ({ children, loaded = true, themeSignIn }: IProps) => {
  const stylesBGImage = useCallback((): CSSProperties | undefined => {
    if ((!themeSignIn?.bucket_name && !themeSignIn?.object_name) || !loaded) {
      return undefined;
    }

    const themeSignInBgImage = imageUrl({
      bucket_name: themeSignIn?.bucket_name,
      object_name: themeSignIn?.object_name
    });

    return { backgroundImage: `url(${themeSignInBgImage})` };
  }, [loaded, themeSignIn?.bucket_name, themeSignIn?.object_name]);

  return (
    <div
      style={stylesBGImage()}
      className={classNames(styles.container, styles.bg, { [styles.bgDefault]: loaded && !themeSignIn })}
    >
      <div className={styles.sidebar}>
        <div className={styles.wrapper}>{children}</div>
      </div>
    </div>
  );
};
