import { createSelector } from 'reselect';

import { IAppState } from '../../../../store';

export const ContactsTableDataSelector = () =>
  createSelector(
    (state: IAppState) => state.contacts,
    (state: IAppState) => state.contacts.loadState,
    (state: IAppState) => state.contacts.error,
    (contacts, loadState, error) => {
      const tableData = contacts.contacts.map((contact) => {
        return { ...contact.fields, id: contact.id, date_create: contact.date_create };
      });

      return {
        tableData,
        page: contacts.page || 1,
        page_size: contacts.page_size || 1,
        total: contacts.total || 25,
        total_pages: contacts.total_pages || 1,
        loadState,
        error
      };
    }
  );
