import { ErrorOffer } from '../error-offer/ErrorOffer';

interface IProps {
  showPaymentError: boolean;
  text: string | JSX.Element;
}

export const NoPaymentOffer = ({ showPaymentError, text }: IProps) => {
  return <ErrorOffer text={text} show={showPaymentError} type={'error'} />;
};
