import { IconPlus } from '../../../../../components/UI/icons';

import { Button } from '../../../../../components/UI';
import { PageTopPanel } from '../../../../../layout/shared-components/page-top-panel/PageTopPanel';
import { SearchWrapper } from '../../../../../layout/shared-components/SearchWrapper';

interface IProps {
  onCreateList(): void;
}

export const TopPanel = ({ onCreateList }: IProps) => {
  return (
    <PageTopPanel title={'Lists'}>
      <SearchWrapper placeholder={'Search by list name...'} isPage className={'mr-2'} />
      <Button onClick={onCreateList} customIcon={<IconPlus className={'icon'} />}>
        Create List
      </Button>
    </PageTopPanel>
  );
};
