import { CustomDispatch } from 'redux';

import { baseFetch } from '../../../../API/BaseFetch';
import { ITokenResponse } from '../../../../interfaces';
import { IImpersonationPayload } from '../../../../interfaces/impersonation';
import { AuthActions } from '../../../../modules/auth/store/AuthActions';
import { ImpersonationActions } from './ImpersonationActions';

export const ImpersonationActionsAsync = {
  setPayload: (payload: IImpersonationPayload) => async (dispatch: CustomDispatch) => {
    try {
      dispatch(ImpersonationActions.setPayload.started(payload));
      const response = await baseFetch<{}, ITokenResponse>(
        '/users/impersonate',
        { user_id: payload.userId },
        'POST',
        false,
        {
          ...(payload.rootToken ? { Authorization: `Bearer ${payload.rootToken}` } : {})
        }
      );
      const token = response.data?.token;

      dispatch(AuthActions.auth.done({ result: token, params: {} }));
      dispatch(ImpersonationActions.setPayload.done({ result: payload, params: {} }));
    } catch (error: any) {
      dispatch(ImpersonationActions.setPayload.failed({ error, params: payload }));
    }
  },
  reset: (token: string) => async (dispatch: CustomDispatch) => {
    try {
      dispatch(ImpersonationActions.reset.started({}));
      dispatch(AuthActions.auth.done({ result: token, params: {} }));
      dispatch(ImpersonationActions.reset.done({ params: {} }));
    } catch (error: any) {
      dispatch(ImpersonationActions.reset.failed({ error, params: {} }));
    }
  }
};
