import { components, DropdownIndicatorProps } from 'react-select';

import { IOption } from '../../../interfaces';
import { Icon16AngleDown } from '../icons';

export const DropdownIndicator = <T extends IOption>(props: DropdownIndicatorProps<T, false>) => {
  return (
    <components.DropdownIndicator {...props}>
      <Icon16AngleDown />
    </components.DropdownIndicator>
  );
};
