import { useCallback, useEffect, useMemo, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { batch, useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';

import { StudentCabinetPaths } from '../../../../student-cabinet/routes/StudentCabinetRoutes';
import { coursePaths } from '../../routes/CourseRoutes';
import { courseActionAsync } from '../../store/CourseActionAsync';
import { LessonSelector } from '../../store/LessonSelector';
import { DragSection } from '../common/dragSection/DragSection';
import { CourseCurriculum } from '../CourseCurriculum';
import { LessonBlocks } from './LessonBlocks';
import { LessonContext } from './LessonContext';

export const LessonWrapper = () => {
  const [isDelete, setIsDelete] = useState(false);
  const { loadState, courses } = useSelector((state) => state.courses);
  const dispatch = useDispatch();
  const path = useRouteMatch<{ id: string; nodeId: string }>([
    coursePaths.courseLessons(':id', ':nodeId'),
    StudentCabinetPaths.courseStudentLessons(':id', ':nodeId')
  ]);

  const courseId = useMemo(() => path?.params.id, [path?.params.id]);
  const stepId = useMemo(() => path?.params.nodeId, [path?.params.nodeId]);

  const lessonMemo = useMemo(() => LessonSelector(courseId, stepId), [courseId, stepId]);
  const lesson = useSelector((state) => lessonMemo(state));
  const lecture = useSelector((state) => state.courses.lectures.find((o) => o.id === stepId));
  const lectureMemo = useMemo(() => lecture, [lecture]);

  const getCourseBlock = useCallback(() => {
    if (courseId && lesson?.lesson?.type === 'lesson' && lesson?.lesson?.children?.length > 0) {
      dispatch(courseActionAsync.getCourseBlock(courseId, lesson.lesson.id));
    }
    if (courseId && stepId && lesson?.lesson?.type === 'step') {
      dispatch(courseActionAsync.getCourseBlock(courseId, stepId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, lesson?.lesson?.children?.length, lesson?.lesson?.id, lesson?.lesson?.type]);

  const getLecture = useCallback(() => {
    if (courseId && lesson?.lesson?.parent_id) {
      dispatch(courseActionAsync.getLesson(courseId, lesson?.lesson?.parent_id));
    }
  }, [dispatch, courseId, lesson?.lesson?.parent_id]);

  useEffect(() => {
    batch(() => {
      getLecture();
      getCourseBlock();
    });
  }, [getCourseBlock, getLecture]);

  return (
    <LessonContext.Provider value={{ isDelete, onDelete: setIsDelete }}>
      {stepId && (
        <DndProvider backend={HTML5Backend}>
          <DragSection />
        </DndProvider>
      )}
      {stepId && lectureMemo && (
        <>
          <LessonBlocks stepId={stepId} getCourseBlock={getCourseBlock} getLecture={getLecture} />
        </>
      )}
      {lesson?.lesson?.type === 'folder' || (!stepId && <CourseCurriculum />)}
    </LessonContext.Provider>
  );
};
