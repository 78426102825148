import React from 'react';

import classNames from 'classnames';
import styles from './block.module.scss';

interface IProps {
  className?: string;
}

export const Block: React.FC<IProps> = ({ className, children }) => {
  return <div className={classNames(styles.container, className)}>{children}</div>;
};
