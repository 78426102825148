import React, { useCallback, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import _ from 'lodash';

import styles from './payload-list.module.scss';

import { CheckButton } from '../../../../../../components/UI';
import { useNodePayload } from '../../../../../../hooks/use-node-payload';
import { PaletteAutomationElementTypes } from '../../../../../../interfaces';
import { ListsAttacher } from '../../../../../crm/contact/common/contact-card-lists/ListsAttacher';
import { IList } from '../../../../../crm/lists/store/ListsState';

interface IProps {
  automationId: string;
  nodeId: string;
}

export const PayloadList = ({ nodeId }: IProps) => {
  const [lists, setLists] = useState<IList[]>([]);
  const { register, setValue, unregister } = useFormContext();
  const node = useNodePayload<PaletteAutomationElementTypes.ACTION_LIST>(nodeId);
  const allLists = useSelector((state) => state.lists.lists);

  useEffect(() => {
    register('list_id');
    setValue('list_id', _.map(lists, 'id'));
    return () => {
      unregister('list_id');
    };
  }, [register, setValue, lists, unregister]);

  useEffect(() => {
    if (node) {
      const currentLists = allLists.filter((x) => node?.payload.list_id?.includes(x.id.toString()));
      setValue('action', node?.payload.action || 'add');

      if (currentLists) {
        setLists(currentLists);
      }
    }
  }, [allLists, nodeId, node, setValue]);

  const onAttach = useCallback(
    (id: string) => {
      const list = allLists.find((x) => x.id === id);
      if (list) {
        setLists((x) => [...x, list]);
      }
    },
    [allLists]
  );

  const onDetach = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      const id = event.currentTarget.dataset['id'];
      const newCurrentLists = lists.filter((x) => x.id !== id);
      setLists(newCurrentLists);
    },
    [lists]
  );

  return (
    <>
      <CheckButton
        name={'action'}
        value={'add'}
        params={{ className: 'radio-button my-1', label: 'Add contact to list' }}
      />
      <CheckButton
        name={'action'}
        value={'remove'}
        params={{ className: 'radio-button my-1 mt-2', label: 'Removed from list' }}
      />
      <div className={'mt-3 mb-4'}>
        <ListsAttacher
          isShowCreate={false}
          attachedIds={lists}
          titleStyles={styles.titleListAttach}
          attachListHandler={onAttach}
          detachListHandler={onDetach}
        />
      </div>
    </>
  );
};
