import { flexRender, Table } from '@tanstack/react-table';

import { ClassNameFunction } from '../common/helper';

type Props<T> = {
  table: Table<T>;
  getClassName: ClassNameFunction;
};
export const TableHeader = <T extends unknown>({ table, getClassName }: Props<T>) => {
  return (
    <thead>
      {table.getHeaderGroups().map((headerGroup) => (
        <tr key={headerGroup.id}>
          {headerGroup.headers.map((header) => (
            <th key={header.id} className={getClassName(header.column.id)}>
              {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
            </th>
          ))}
        </tr>
      ))}
    </thead>
  );
};
