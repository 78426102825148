import { CustomDispatch } from 'redux';

import { getPreviewListStepBlocks } from '../../../API/courses-api';
import {
  getCabinetSettings,
  getLesson,
  getLessonPreview,
  getListStepBlocks,
  getStatusesCourse,
  getStudentCabinetCourses,
  getStudentCourseHierarchy,
  setProgressNode
} from '../../../API/student-cabinet-api';
import { IError, IGeneralSettings } from '../../../interfaces';
import { ICourseNode, ILecture, StudentLessonErrorStatus } from '../../lms/courses/store/CourseState';
import { StudentCabinetActions } from './StudentCabinetActions';

export const StudentCabinetActionAsync = {
  getStudentCabinetCourses:
    (organizationId: string, courseId?: string, unavailableCourse?: () => void, callback?: () => void) =>
    async (dispatch: CustomDispatch) => {
      try {
        dispatch(StudentCabinetActions.getStudentCabinetCourses.started());
        const response = await getStudentCabinetCourses(organizationId);
        if (response.data) {
          const course = response.data.courses.find((x) => x.id === courseId);
          if (!course) {
            unavailableCourse?.();
          }
          dispatch(StudentCabinetActions.getStudentCabinetCourses.done({ result: response.data }));
          callback?.();
        }
      } catch (error) {
        dispatch(StudentCabinetActions.getStudentCabinetCourses.failed({ error }));
      }
    },
  getStatusesCourse: (courseId: string) => async (dispatch: CustomDispatch) => {
    try {
      dispatch(StudentCabinetActions.getStatusesCourse.started({ courseId }));
      const response = await getStatusesCourse(courseId);
      if (response.data) {
        dispatch(
          StudentCabinetActions.getStatusesCourse.done({ params: { courseId }, result: { ...response.data, courseId } })
        );
      }
    } catch (error) {
      dispatch(StudentCabinetActions.getStatusesCourse.failed({ error, params: { courseId } }));
    }
  },
  getStudentCourseHierarchy:
    (course_id: string, callback?: (courseHierarchy: ICourseNode[]) => void, onError?: () => void) =>
    async (dispatch: CustomDispatch) => {
      try {
        dispatch(StudentCabinetActions.getStudentCabinetCourseHierarchy.started({ course_id }));
        const response = await getStudentCourseHierarchy(course_id);

        if (response.data) {
          dispatch(
            StudentCabinetActions.getStudentCabinetCourseHierarchy.done({
              result: response.data,
              params: { course_id }
            })
          );
          if (callback) {
            callback(response.data);
          }
        }
      } catch (error) {
        console.log(error);
        dispatch(StudentCabinetActions.getStudentCabinetCourseHierarchy.failed({ error, params: { course_id } }));
        if (typeof onError === 'function') {
          onError();
        }
      }
    },
  setStatusNode: (courseId: string, nodeId: string, callback?: () => void) => async (dispatch: CustomDispatch) => {
    try {
      dispatch(StudentCabinetActions.setStatusNode.started({ courseId, nodeId }));
      const response = await setProgressNode(courseId, nodeId);
      if (response.data) {
        dispatch(StudentCabinetActions.setStatusNode.done({ result: response.data, params: { courseId, nodeId } }));
        if (callback) {
          callback();
        }
      }
    } catch (error) {
      dispatch(
        StudentCabinetActions.setStatusNode.failed({
          error,
          params: {
            courseId,
            nodeId
          }
        })
      );
    }
  },

  getListStepBlocks:
    (
      courseId: string,
      stepId: string,
      errorCallback?: (error: StudentLessonErrorStatus) => void,
      isPreview?: boolean
    ) =>
    async (dispatch: CustomDispatch) => {
      try {
        dispatch(StudentCabinetActions.getListStepBlocks.started({ courseId, stepId }));
        let response;
        if (isPreview) {
          response = await getPreviewListStepBlocks(courseId, stepId);
        } else {
          response = await getListStepBlocks(courseId, stepId);
        }
        if (response.data) {
          dispatch(
            StudentCabinetActions.getListStepBlocks.done({ result: response.data, params: { courseId, stepId } })
          );
        }
      } catch (error) {
        if (error?.data?.status) {
          errorCallback?.(error.data?.status);
        } else if (error instanceof Error) {
          errorCallback?.('fail.course.node_not_found');
        }

        dispatch(
          StudentCabinetActions.getListStepBlocks.failed({ error: error?.response, params: { courseId, stepId } })
        );
      }
    },
  getLesson:
    (courseId: string, lessonId: string, callback?: (data: ILecture[]) => void, isPreview?: boolean) =>
    async (dispatch: CustomDispatch) => {
      try {
        dispatch(StudentCabinetActions.getLesson.started({ courseId, lessonId }));
        let response;
        if (isPreview) {
          response = await getLessonPreview(courseId, lessonId);
        } else {
          response = await getLesson(courseId, lessonId);
        }
        if (response.data) {
          dispatch(StudentCabinetActions.getLesson.done({ result: response.data, params: { courseId, lessonId } }));
          if (typeof callback === 'function') {
            callback(response.data);
          }
        }
      } catch (error) {
        dispatch(StudentCabinetActions.getLesson.failed({ error, params: { courseId, lessonId } }));
      }
    },
  getStudentCabinetSettings:
    (host: string, callback: (settings: IGeneralSettings) => void, onError: (error: IError) => void) =>
    async (dispatch: CustomDispatch) => {
      try {
        dispatch(StudentCabinetActions.getStudentCabinetSettings.started(host));
        const response = await getCabinetSettings(host);
        if (response.data) {
          dispatch(StudentCabinetActions.getStudentCabinetSettings.done({ params: host, result: response.data }));
          callback(response.data);
        }
      } catch (error) {
        dispatch(StudentCabinetActions.getStudentCabinetSettings.failed({ params: host, error }));
        onError(error);
      }
    },
  setCourseOpened:
    (
      courseId: string,
      lessonId: string,
      payload: {
        action: string;
      },
      callback?: () => void
    ) =>
    async (dispatch: CustomDispatch) => {
      try {
        dispatch(StudentCabinetActions.setCourseOpened.started({ courseId, lessonId, action: payload.action }));
        await setProgressNode<string, void>(courseId, lessonId, payload.action);
        dispatch(
          StudentCabinetActions.setCourseOpened.done({
            params: { courseId, lessonId, action: payload.action }
          })
        );
        callback?.();
      } catch (error) {
        dispatch(
          StudentCabinetActions.setCourseOpened.failed({
            error,
            params: { courseId, lessonId, action: payload.action }
          })
        );
      }
    }
};
