import { CSSProperties, memo } from 'react';
import { EditableInput } from 'react-color/lib/components/common';

import classNames from 'classnames';
import styles from './inputColor.module.scss';

const inputStyle = {
  input: {
    height: 32,
    width: '100%',
    border: '1px solid #E0EEFF',
    borderRadius: '0.25rem',
    textAlign: 'end',
    padding: '0 0.5rem 0 0.5rem',
    // pointerEvents: 'none'
  } as CSSProperties
};

interface IProps {
  selectColor: (color?: any) => void;
  label: string;
  value?: string | number;
  className?: string;
}

export const InputColor = memo(({ selectColor, label, value, className }: IProps) => {
  return (
    <div className={classNames(styles.inputContainer, className)}>
      <div className={styles.hexLabel}>{label}</div>
      <EditableInput style={inputStyle} value={value} onChange={selectColor} />
    </div>
  );
});
