import React from 'react';
import { PageButtonRendererOptions, PaginationOptions } from 'react-bootstrap-table-next';

import classNames from 'classnames';

import { getUUID } from '../../../helpers';
import { IconArrowLeft, IconArrowRight } from '../icons';

const handleNextPage =
  ({ page, onPageChange, sizePerPage, totalSize }: PaginationOptions) =>
  () => {
    const isEnable = page && sizePerPage && totalSize && page * sizePerPage < totalSize;
    if (page && onPageChange && isEnable) {
      if (sizePerPage != null) {
        onPageChange(page + 1, sizePerPage);
      }
    }
  };

const handlePrevPage =
  ({ page, onPageChange }: PaginationOptions) =>
  () => {
    if (page && page > 1 && onPageChange) {
      onPageChange(page - 1, 3);
    }
  };

export const arrowButtons = (paginationProps: PaginationOptions) => {
  const { page, sizePerPage, totalSize } = paginationProps;
  let nextDisabled = false;
  if (page && sizePerPage && totalSize) {
    nextDisabled = page * sizePerPage >= totalSize;
  }
  return (
    <div className="mr-4">
      <button className="btn btn-pagination-arrow mr-2" onClick={handlePrevPage(paginationProps)} disabled={page === 1}>
        <IconArrowLeft />
      </button>
      <button className="btn btn-pagination-arrow" onClick={handleNextPage(paginationProps)} disabled={nextDisabled}>
        <IconArrowRight />
      </button>
    </div>
  );
};

export const wrapperPageButtonRenderer = (options: PaginationOptions) => {
  return function ({ page, active, onPageChange }: PageButtonRendererOptions) {
    if (typeof page !== 'string') {
      const handleClick = (e: React.SyntheticEvent) => {
        e.preventDefault();
        onPageChange(page, options.sizePerPage!);
      };

      return (
        <li className={classNames('page-item', { active: active })} key={getUUID()}>
          <a href="/" className={'page-link'} onClick={handleClick}>
            {page}
          </a>
        </li>
      );
    }
    return <li key={getUUID()} />;
  };
};
