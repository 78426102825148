import { Dispatch, forwardRef, ReactNode, Ref, UIEvent, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { useEffectOnce } from 'usehooks-ts';

import classNames from 'classnames';
import styles from './lesson-thread.module.scss';

import { SpinnerLoader } from '../../../../../../../components/UI';
import { useLoading } from '../../../../../../../hooks/use-loading';
import { IComment, IPaginationResponse } from '../../../../../../../interfaces';
import { CommentElement } from '../../../../../../comment/comment-element/CommentElement';
import { CommentsEmpty } from '../../../../../../comment/comments-empty/CommentsEmpty';
import { courseActionAsync } from '../../../../store/CourseActionAsync';

const observer = new IntersectionObserver(
  ([e]) => {
    return e.target.classList.toggle(styles.isPinned, e.intersectionRatio < 1);
  },
  { threshold: [1] }
);

type Props = {
  isModerated?: boolean;
  pageSize?: number;
  threadId: string;
  comments: IPaginationResponse<IComment[]>;
  setComments: Dispatch<React.SetStateAction<IPaginationResponse<IComment[]>>>;
  children?: ReactNode;
  className?: string;
  loadMoreFooter?: boolean;
  showToggle?: boolean;
};

export const LessonThread = forwardRef(
  (
    {
      threadId,
      comments,
      setComments,
      children,
      className,
      loadMoreFooter,
      showToggle,
      isModerated,
      pageSize = 25
    }: Props,
    ref: Ref<HTMLDivElement>
  ) => {
    const headerRef = useRef<HTMLDivElement>(null);
    const nextPage = !comments.meta.page ? 1 : comments.meta.page + 1;

    const moreItems = nextPage * comments.meta.page_size < comments.meta.total ? true : false;

    const { startLoading, stopLoading, loading } = useLoading();

    const dispatch = useDispatch();

    const onSetComments = (data: IPaginationResponse<IComment[]>) => {
      setComments((prev) => {
        return {
          data: [...prev.data, ...data.data],
          meta: { ...prev.meta, ...data.meta }
        };
      });

      stopLoading();
    };

    useEffect(() => {
      if (headerRef.current) {
        observer.observe(headerRef.current);
      }
    }, []);

    const onGetComments = () => {
      if (moreItems && !loading) {
        startLoading();
        dispatch(
          courseActionAsync.getComments(
            { threadId: threadId, isModerated: isModerated, sort: ['-created_at'] },
            onSetComments,
            {
              page: nextPage,
              page_size: pageSize
            }
          )
        );
      }
    };

    useEffectOnce(() => {
      startLoading();
      dispatch(
        courseActionAsync.getComments(
          { threadId: threadId, isModerated: isModerated, sort: ['-created_at'] },
          onSetComments,
          {
            page: nextPage,
            page_size: pageSize
          }
        )
      );
    });

    const handleScroll = (e: UIEvent<HTMLDivElement>) => {
      const scrollHeight = e.currentTarget.scrollHeight;
      const scrollTop = e.currentTarget.scrollTop + 1;
      const clientHeight = e.currentTarget.clientHeight;
      const bottom = scrollHeight - scrollTop - 180 < clientHeight;
      if (scrollTop > 1 && headerRef.current) {
        headerRef.current.className = classNames(styles.header, styles.isPinned);
      } else if (scrollTop <= 1 && headerRef.current) {
        headerRef.current.className = styles.header;
      }

      if (bottom) {
        onGetComments();
      }
    };
    const firstLoad = comments.data.length === 0 && loading;
    if (comments.data.length === 0 && loading) {
      return (
        <div className={styles.content}>
          <SpinnerLoader variant={'primary'} size="lg" />
        </div>
      );
    }

    return (
      <div
        className={classNames(
          styles.wrapper,
          {
            [styles.empty]: (comments.data.length === 0 && !loading) || firstLoad
          },
          className
        )}
        onScroll={handleScroll}
        ref={ref}
      >
        {children}

        {comments.data.length === 0 && !loading && (
          <div className={styles.content}>
            <CommentsEmpty />
          </div>
        )}

        {comments.data.map((comment, index) => (
          <CommentElement
            commentId={comment.id}
            isShowComment={comment.is_hidden}
            key={index}
            avatar={comment.user.photo}
            content={comment.content}
            name={`${comment.user.first_name} ${comment.user.last_name}`}
            showToggle={showToggle}
            date={moment(comment.created_at).fromNow()}
          />
        ))}
        {loading && comments.data.length > 0 && (
          <div className={styles.loaderContainer}>
            <SpinnerLoader variant={'primary'} />
          </div>
        )}
        {loadMoreFooter && moreItems && !loading && (
          <div className={styles.loadMoreContainer}>
            <div onClick={onGetComments} className={styles.load}>
              load more comments
            </div>
          </div>
        )}
      </div>
    );
  }
);
