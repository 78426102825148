import ReactQuill, { Quill } from 'react-quill';

import { IEmailVariable } from '../../../../modules/common/email-editor';

const Embed = Quill.import('blots/embed');
const EmptyString = '!e@m#p$t%y^';
class Variables extends Embed {
  static blotName = 'variable';
  static className = 'ql-variable';
  static tagName = 'SPAN';

  static create(value: IEmailVariable) {
    // console.log('%c⇒create value', 'color: #FFCB6B', value);
    let node = document.createElement(this.tagName);

    if (this.className) {
      node.classList.add(this.className);
    }
    node.setAttribute('data-name', value.name);
    node.setAttribute('data-value', value?.value || '');
    node.setAttribute('data-key', value?.key || '');
    // node.setAttribute('style', 'cursor: default; color: #0072ff');
    if (value?.value === EmptyString) {
      node.innerHTML = '';
    } else if (value?.value && value.value !== EmptyString) {
      node.innerHTML = value.value;
    } else {
      node.innerHTML = `{${value.name}}`;
      node.setAttribute('style', 'cursor: default; color: #0072ff');
    }
    return node;
  }

  static value(domNode: HTMLElement): any {
    return {
      name: domNode.getAttribute('data-name'),
      value: domNode.getAttribute('data-value'),
      key: domNode.getAttribute('data-key')
    };
  }
}

export { Variables };

export const onInsertVariable = (quill: ReactQuill, variable: IEmailVariable) => {
  const editor = quill.getEditor();
  const range = editor.getSelection(true);

  editor.insertEmbed(range.index, 'variable', variable);

  editor.setSelection(range.index + 1, 0, 'silent');
};
