import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { CSSTransition } from 'react-transition-group';

import styles from './business-address.module.scss';

import { Button } from '../../../../components/UI';
import { selectedOption } from '../../../../helpers';
import { IBusinessAddress } from '../../../../interfaces';
import { SettingInput } from '../../common/input/SettingInput';
import { SettingSelect } from '../../common/select/SettingSelect';
import { SettingsCollapseBlock } from '../../common/settings-collapse-block/SettingsCollapseBlock';
import countriesOptions from '../../general/data/countries.json';
import { GeneralSettingsActionAsync } from '../../general/store/GeneralSettingsActionAsync';

export const BusinessAddress = () => {
  const dispatch = useDispatch();
  const methods = useForm<IBusinessAddress>({});
  const watchData = useWatch({ control: methods.control });
  const { general } = useSelector((state) => state.general);

  const save = () => {
    dispatch(
      GeneralSettingsActionAsync.editOrganization(watchData, (data) => {
        methods.reset({
          business_address_line_1: data.business_address_line_1,
          business_address_line_2: data.business_address_line_2,
          business_address_country: data.business_address_country,
          business_address_tax_number: data.business_address_tax_number,
          business_address_zip: data.business_address_zip,
          business_address_state: data.business_address_state,
          business_address_city: data.business_address_city
        });
      })
    );
  };

  const reset = () => {
    methods.reset({
      business_address_line_1: general.business_address_line_1,
      business_address_line_2: general.business_address_line_2,
      business_address_country: general.business_address_country,
      business_address_tax_number: general.business_address_tax_number,
      business_address_zip: general.business_address_zip,
      business_address_state: general.business_address_state,
      business_address_city: general.business_address_city
    });
  };

  return (
    //@ts-ignore
    <FormProvider {...methods}>
      <SettingsCollapseBlock title={'Business Address'}>
        <div className={styles.container}>
          <div className="row">
            <div className="col-xl-6 col-lg-8 col-md-10 col-12">
              <div className={styles.description}>
                This address will appear on your invoices. You can edit the address used in the footer of your marketing
                emails under site settings. Your primary business location could also affect how taxes are handled on
                any offers or products you create.
              </div>
              <div className="row">
                <div className="col-12 mb-3">
                  <SettingInput
                    label={'Address line 1'}
                    name={'business_address_line_1'}
                    defaultValue={general.business_address_line_1}
                    className={styles.full}
                  />
                </div>
                <div className="col-md-6 col-12 mb-3">
                  <SettingInput
                    label={'Apt, Suite, Etc'}
                    name={'business_address_line_2'}
                    defaultValue={general.business_address_line_2}
                    className={styles.full}
                  />
                </div>
                <div className="col-md-6 col-12 mb-3">
                  <SettingInput
                    label={'City'}
                    name={'business_address_city'}
                    defaultValue={general.business_address_city}
                    className={styles.full}
                  />
                </div>
                <div className="col-md-6 col-12 mb-3">
                  <SettingInput
                    label={'State/Province/Region'}
                    name={'business_address_state'}
                    defaultValue={general.business_address_state}
                    className={styles.full}
                  />
                </div>
                <div className="col-md-6 col-12 mb-3">
                  <SettingInput
                    label={'Zip/Postal code'}
                    name={'business_address_zip'}
                    defaultValue={general.business_address_zip}
                    className={styles.full}
                  />
                </div>
                <div className="col-md-6 col-12 mb-3">
                  <SettingSelect
                    label={'Country'}
                    name={'business_address_country'}
                    options={countriesOptions}
                    defaultValue={selectedOption(countriesOptions, general.business_address_country!)}
                    props={{ menuPlacement: 'auto' }}
                    containerStyles={styles.full}
                  />
                </div>
                <div className="col-md-6 col-12 mb-3">
                  <SettingInput
                    label={'Tax number'}
                    name={'business_address_tax_number'}
                    defaultValue={general.business_address_tax_number}
                    className={styles.full}
                  />
                  <div className={styles.inputNote}>
                    This is your tax or business number, such as PST, VAT, GST, etc.
                  </div>
                </div>
              </div>
            </div>
          </div>
          <CSSTransition in={methods.formState.isDirty} timeout={300} classNames={styles} unmountOnExit>
            <div className={styles.actions}>
              <div>
                <Button onClick={save} withIcon={false} className={'mr-2'}>
                  Save changes
                </Button>
                <Button onClick={reset} withIcon={false} btnStyle={'secondary'}>
                  Cancel
                </Button>
              </div>
            </div>
          </CSSTransition>
        </div>
      </SettingsCollapseBlock>
    </FormProvider>
  );
};
