import React, { FC, memo, useEffect, useRef, useState } from 'react';
import { FormCheck } from 'react-bootstrap';
import { Controller, useFormContext } from 'react-hook-form';

import classNames from 'classnames';
import styles from './settings-block.module.scss';

interface IProps {
  title: string;
  controlName: string;
  classNameChildren?: string;
  defaultValue: boolean;
  enabled: boolean;
}

export const SettingsBlock: FC<IProps> = memo(
  ({ title, controlName, classNameChildren, children, defaultValue, enabled }) => {
    const { control, setValue } = useFormContext();
    // const [checked, setChecked] = useState(defaultValue);
    const divRef = useRef<HTMLDivElement | null>(null);
    useEffect(() => {
      setValue(controlName, defaultValue);
    }, [controlName, defaultValue, setValue]);

    // useEffect(() => {
    //   if (divRef.current) {
    //     divRef.current.scrollIntoView({
    //       behavior: 'smooth',
    //       block: 'center'
    //     });
    //   }
    // }, [checked]);

    return (
      <div className={styles.container} ref={divRef}>
        <div className={'d-flex align-items-center py-3'}>
          <FormCheck className="custom-control custom-switch custom-switch-sm" custom type={'switch'}>
            <Controller
              name={controlName}
              control={control}
              defaultValue={defaultValue}
              render={({ field: { onChange, value } }) => (
                <FormCheck.Input
                  type="checkbox"
                  className="custom-control-input"
                  id={controlName}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    onChange(e.target.checked);
                    // setChecked(e.target.checked);
                  }}
                  checked={value}
                />
              )}
            />
            <FormCheck.Label className="custom-control-label" htmlFor={controlName} />
          </FormCheck>
          <div className={styles.title}>{title}</div>
        </div>
        {children && (
          <div className={classNames('pb-4', classNameChildren)} style={{ display: enabled ? 'block' : 'none' }}>
            {children}
          </div>
        )}
      </div>
    );
  }
);
