import { Defaults, EndpointOptions } from 'jsplumb';

const commonEndpoint: EndpointOptions = {
  reattach: false,
  connector: [
    'Flowchart',
    {
      stub: [30, 30],
      gap: [-1, 0],
      cornerRadius: 4,
      alwaysRespectStubs: true,
      cssClass: 'connector'
    }
  ],
  endpoint: ['Dot', { radius: 10, cssClass: 'endpoint', hoverClass: 'endpoint-hover' }],
  paintStyle: {
    fill: 'currentColor',
    strokeWidth: 2,
    stroke: '#fff'
  },
  hoverPaintStyle: { fill: 'currentColor' },
  // @ts-ignore
  connectorStyle: { stroke: '#99c7ff', strokeWidth: 2, outlineStroke: '#fff', outlineWidth: 1 },
  connectorHoverStyle: { stroke: '#338eff', strokeWidth: 2 },
  maxConnections: -1,
  scope: 'funnel',
  connectionsDetachable: true,
  reattachConnections: true
};

export const groupEndpoint: EndpointOptions = {
  ...commonEndpoint,
  endpoint: ['Dot', { radius: 7, cssClass: 'group-endpoint', hoverClass: 'endpoint-hover' }],
  paintStyle: {
    fill: 'currentColor',
    strokeWidth: 1,
    stroke: '#fff'
  }
};

const defaultsConfig: Defaults = {
  DragOptions: { cursor: 'pointer', zIndex: 2000 },
  ConnectionOverlays: [
    [
      'Arrow',
      {
        location: 1,
        visible: true,
        width: 16,
        length: 16,
        id: 'arrow',
        foldback: 0.3,
        events: {
          click: () => {
            alert('you clicked on the arrow overlay');
          }
        }
      }
    ]
  ],
  Container: 'canvas'
};

export { commonEndpoint, defaultsConfig };
