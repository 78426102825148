import styles from './resultQuiz.module.scss';
import Aim from '../../../../../../../assets/images/aim.png';
import Attention from '../../../../../../../assets/images/attention.png';
import Badly from '../../../../../../../assets/images/badly.png';
import Ticket from '../../../../../../../assets/images/ticket.png';
import Hooray from '../../../../../../../assets/images/wink.png';
import { Icon32Reload } from '../../../../../../components/UI/icons';

import { Button } from '../../../../../../components/UI';
import { IQuizQuestion, PassingGradeTypeValues } from '../../../../../../interfaces/quiz';
import { IQuizResult, QuizViewState } from '../store/QuizState';
import { ResultQuizAnswerCorrect } from './ResultQuizAnswerCorrect';

interface IProps {
  state: QuizViewState;
  result: IQuizResult;
  questions: IQuizQuestion[];
  onTryAgain(): void;
}

export const ResultQuiz = ({ onTryAgain, questions, result, state }: IProps) => {
  let percent = 0;
  if (result.passing_grade?.type === PassingGradeTypeValues.by_answer_points) {
    if (result.total_score === result.total_points) {
      percent = 100;
    } else if (result.total_score >= result.passing_grade.points_count && result.total_score < result.total_points) {
      percent = 75;
    } else {
      percent = 25;
    }
    // percent = Math.round((result.total_score * 100) / result.total_points);
  } else if (result.passing_grade?.type === PassingGradeTypeValues.by_question) {
    percent = Math.round((result.total_score * 100) / questions.length);
  }

  return (
    <>
      {result.passing_grade ? (
        <>
          {percent > 75 && <img className={styles.image} src={Aim} alt={'aim'} />}
          {percent > 50 && percent <= 75 && <img className={styles.image} src={Ticket} alt={'ticket'} />}
          {percent > 25 && percent <= 50 && <img className={styles.image} src={Attention} alt={'attention'} />}
          {percent <= 25 && <img className={styles.image} src={Badly} alt={'badly'} />}
          <ResultQuizAnswerCorrect
            isCorrect={result.passing_grade.type === PassingGradeTypeValues.by_question}
            percent={percent}
            totalScore={result.total_score}
            isPassed={state === 'success'}
            pointsCount={
              result.passing_grade.type === PassingGradeTypeValues.by_answer_points
                ? result.passing_grade.points_count
                : undefined
            }
            questionCorrectRequired={
              result.passing_grade.type === PassingGradeTypeValues.by_question
                ? result.passing_grade.questions_count
                : undefined
            }
          />
        </>
      ) : (
        <>
          <img className={styles.image} src={Hooray} alt={'thank you'} />
          <div className={styles.text}>Your answers have been accepted</div>
          <div className={styles.descriptionAny}>If you have other lessons to complete, you can go to them</div>
        </>
      )}

      {((result.passing_grade?.type === PassingGradeTypeValues.by_answer_points &&
        result.total_score < result.passing_grade.points_count) ||
        (result.passing_grade?.type === PassingGradeTypeValues.by_question &&
          result.total_score < result.passing_grade.questions_count)) && (
        <Button
          onClick={onTryAgain}
          withIcon={true}
          iconPosition={'left'}
          customIcon={<Icon32Reload />}
          size={'lg'}
          className={'mt-5'}
        >
          {'Try again'}
        </Button>
      )}
    </>
  );
};
