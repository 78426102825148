import React, { memo } from 'react';

import { Button, SpinnerLoader } from '../../../../../components/UI';
import { PageTopPanel } from '../../../../../layout/shared-components/page-top-panel/PageTopPanel';
import { SearchWrapper } from '../../../../../layout/shared-components/SearchWrapper';

interface IProps {
  title: string;
  totalCount: number;
  loading: boolean;

  onClick(): void;
}

export const CoursesListHeader = memo(({ title, totalCount, loading, onClick }: IProps) => {
  return (
    <PageTopPanel title={title}>
      <div className={'count'}>{totalCount}</div>
      <SearchWrapper placeholder={'Search by id, product name, SKU...'} isPage className={'mr-2'} />
      <Button onClick={onClick} btnProps={{ disabled: loading }} withIcon={!loading}>
        {loading ? <SpinnerLoader spinnerProps={{ size: 'sm' }} variant={'light'} /> : 'Create course'}
      </Button>
    </PageTopPanel>
  );
});
