import { memo } from 'react';

import classNames from 'classnames';
import styles from './bulk-video-upload.module.scss';
import { VideoIllustration } from '../../../../../../../components/UI/icons';

interface IProps {
  className?: string;
  handleVideoChange: (files: FileList | null) => void;
}

export const BulkVideoUpload = memo(({ handleVideoChange, className }: IProps) => {
  return (
    <div className={classNames(styles.container, className)}>
      <div className={styles.inner}>
        <input
          accept={'video/*,audio/*'}
          type="file"
          multiple={true}
          className={styles.inputFile}
          id="inputFile"
          onChange={(e) => handleVideoChange(e.target.files)}
          data-title="Drag and drop a file"
        />
        <div className={styles.illustrationContainer}>
          <VideoIllustration />
        </div>
        <div>
          <div className={styles.title}>Drag & drop video files</div>
          <div className={styles.description}>to create multiple lessons in one action</div>
        </div>
      </div>
    </div>
  );
});
