import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { createColumnHelper } from '@tanstack/react-table';
import moment from 'moment';

import classNames from 'classnames';
import styles from './list-payment.module.scss';
import { Icon32Currency } from '../../../../components/UI/icons';

import { EmptyList } from '../../../../components/empty-list/EmptyList';
import { ItemStatus } from '../../../../components/itemStatus/ItemStatus';
import { CustomTooltip, Table } from '../../../../components/UI';
import { PopupCard } from '../../../../components/UI/popup-card/PopupCard';
import { useIntercomUpdate } from '../../../../hooks/use-intercom-update';
import { usePayload } from '../../../../hooks/usePayload';
import { IPaginationResponse, IPayment } from '../../../../interfaces';
import { PageTopPanel } from '../../../../layout/shared-components/page-top-panel/PageTopPanel';
import { ContactCard } from '../../contact/contact-card/ContactCard';
import { CurrentContactListActionAsync } from '../../contact/current-contact-store/CurrentContactActionAsync';
import { currentContactActions } from '../../contact/current-contact-store/CurrentContactActions';
import { ContactListActionAsync } from '../../contact/store/ContactListActionAsync';
import { ContactSelector } from '../../contact/store/ContactListSelector';
import { FieldActionAsync } from '../../field';
import { FieldsEdit } from '../../fields/FieldsEdit';
import { CRMPaths } from '../../routes/CRMRoutes';
import { PaymentIcon } from '../common/payment-icon/PaymentIcon';

const columnHelper = createColumnHelper<IPayment>();

interface IProps {
  data: IPaginationResponse<IPayment[]>;
}

export const PaymentsList = ({ data: { data, meta } }: IProps) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { isPayloadShow, showPayload } = usePayload();
  const { isSidebarShow: isFieldSettingsShow } = useSelector((state) => state.settings);
  const [contactId, setContactId] = useState<string>();
  const contactMemo = useMemo(ContactSelector, [contactId]);
  const contactData = useSelector((state) => contactMemo(state, contactId));

  useIntercomUpdate(isPayloadShow);

  const openContactCard = useCallback(
    (payment: IPayment) => {
      if (!payment.contact.is_deleted) {
        dispatch(
          ContactListActionAsync.getContact(payment.contact.id, () => {
            setContactId(payment.contact.id);
          })
        );
        showPayload();
      }
    },
    [dispatch, showPayload]
  );

  const onOpenOffer = useCallback(
    (payment: IPayment) => {
      if (!payment.offer.is_deleted) {
        history.push({
          pathname: CRMPaths.crmOffer(payment.offer.id),
          state: {
            prev: CRMPaths.payments,
            title: 'Payments'
          }
        });
      }
    },
    [history]
  );

  const columns = useMemo(
    () => [
      columnHelper.accessor((row) => row.id, {
        id: 'id',
        header: 'Payment system ID',
        cell: (props) => props.getValue()
      }),
      columnHelper.accessor((row) => row.contact.email, {
        id: 'name',
        header: 'Name and email',
        cell: (props) =>
          props.row.original.contact.is_deleted ? (
            <>
              <div className={styles.deleted}>{props.getValue()}</div>
              <div className={styles.red}>Deleted contact</div>
            </>
          ) : (
            <div onClick={() => openContactCard(props.row.original)}>
              <div className={styles.email}>{props.getValue()}</div>
              <div>{`${props.row.original.contact.first_name} ${props.row.original.contact.last_name}`}</div>
            </div>
          )
      }),
      columnHelper.accessor((row) => row.offer.title, {
        id: 'title',
        header: 'Offer title and type',
        cell: ({ getValue, row: { original } }) => (
          <div onClick={() => onOpenOffer(original)}>
            <CustomTooltip
              customText={(original.offer.is_deleted ? 'Deleted offer: ' : '') + getValue()}
              direction={'top'}
              className={classNames(styles.offerTitle, { [styles.deleted]: original.offer.is_deleted })}
            >
              {getValue()}
            </CustomTooltip>
            <div>One time payment</div>
          </div>
        )
      }),
      columnHelper.accessor((row) => row.created_at, {
        id: 'date',
        header: 'Date',
        cell: (props) => moment(props.getValue()).format('DD MMM yyyy, HH:mm')
      }),
      columnHelper.accessor((row) => row.price.toString(), {
        id: 'amount',
        header: 'Amount',
        cell: ({ row }) => (
          <div className={styles.amountContainer}>
            <PaymentIcon paymentMethod={row.original.payment_method} />
            {row.original.payment_method === 'free' ? (
              <span className="ml-auto">—</span>
            ) : (
              <div>
                {new Intl.NumberFormat('en-EN', {
                  style: 'currency',
                  currency: row.original.currency
                }).format(row.original.price)}
              </div>
            )}
          </div>
        )
      }),
      columnHelper.accessor((row) => row.status, {
        id: 'status',
        header: 'Status',
        cell: (props) => (
          <ItemStatus
            label={props.getValue()}
            className={classNames(
              { published: props.getValue() === 'success' },
              { draft: props.getValue() === 'pending' },
              { archived: props.getValue() === 'failed' },
              styles.statusContainer
            )}
          />
        )
      })
    ],
    [onOpenOffer, openContactCard]
  );

  useEffect(() => {
    dispatch(FieldActionAsync.getFields());
    dispatch(ContactListActionAsync.getContactsConfig());
  }, [dispatch]);

  useEffect(() => {
    if (contactData) {
      dispatch(currentContactActions.setCurrentContact({ contact: contactData }));
      dispatch(CurrentContactListActionAsync.getListIdsOfContacts(contactData.contact.id));
    }
    if (!contactId) {
      dispatch(currentContactActions.setCurrentContact({ contact: undefined }));
    }
  }, [contactData, contactId, dispatch]);

  return data.length === 0 ? (
    <EmptyList
      // eslint-disable-next-line quotes
      title={"You don't have incoming payments yet"}
      icon={<Icon32Currency width={64} height={64} />}
      description={'All payments from connected payment systems will be stored here'}
    />
  ) : (
    <>
      <PageTopPanel title={'Payments'} />
      <Table columns={columns} data={data} pageCount={meta.total_pages} />
      <PopupCard isShow={isPayloadShow}>
        <ContactCard setContactId={setContactId} contactId={contactId} />
      </PopupCard>
      <PopupCard isShow={isFieldSettingsShow} fullHeight={true}>
        <FieldsEdit
          entity={'contact'}
          title={'Edit contact fields'}
          description={
            'Change the order and the content for the working fields. The settings are applied to all contacts.'
          }
        />
      </PopupCard>
    </>
  );
};
