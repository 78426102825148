import { createContext, useContext } from 'react';

import { Queue } from '../helpers/queue';

export type AppContent = {
  queue: Queue<(params: any) => unknown> | null;
};
export const AppContext = createContext<AppContent>({
  queue: null
});
export const useAppContext = () => useContext(AppContext);
