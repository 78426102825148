import { memo, useRef } from 'react';
import { useFormContext } from 'react-hook-form';

import classNames from 'classnames';
import styles from './canvas-node-funnel.module.scss';

import { ElementThumb } from '../../../../components/illustrations/ElementThumb';
import { useNodePayload } from '../../../../hooks/use-node-payload';
import {
  NodeTypes,
  PaletteAutomationElementNames,
  PaletteAutomationElementTypes,
  PaletteElementTypes
} from '../../../../interfaces';
import { useBuilderContext } from '../../../common/builder/BuilderContext';
import { CanvasNodeAutomation } from '../../../common/builder/canvasNode/canvas-automation/CanvasNodeAutomation';
import { CanvasDelayFixedPeriod } from '../../../common/builder/canvasNode/canvas-delay/CanvasDelayFixedPeriod';
import { CanvasDelayFixedTime } from '../../../common/builder/canvasNode/canvas-delay/CanvasDelayFixedTime';
import { CanvasDelayUntilFixed } from '../../../common/builder/canvasNode/canvas-delay/CanvasDelayUntilFixed';
import { CanvasNodeEmail } from '../../../common/builder/canvasNode/canvas-email/CanvasNodeEmail';
import { BuilderType } from '../../../common/builder/store/BuilderActionAsync';

interface IProps {
  nodeId: string;
  title: string;
  type: NodeTypes;
  builderType: BuilderType;
  setMultiView?: () => void;
}

export const CanvasNodeFunnelItem = memo(({ nodeId, title, type, builderType, setMultiView }: IProps) => {
  const { watch } = useFormContext();
  const payload = useNodePayload<PaletteAutomationElementTypes.PROCESSOR_DELAY>(nodeId);
  const selectedType = watch('period.period_type');
  const whenData = watch('period.when');

  const watchDays = watch('period.days');
  const watchHours = watch('period.hours');
  const watchMinutes = watch('period.minutes');
  const watchNoEarlier = watch('period.no_earlier');
  const watchNoLater = watch('period.no_later');

  const { selectedNode } = useBuilderContext();
  const typeDelay =
    selectedNode?.id === nodeId
      ? selectedType || payload?.payload?.period?.period_type
      : payload?.payload?.period?.period_type;

  let content = useRef<JSX.Element | null>(<CanvasNodeAutomation nodeId={nodeId} />);

  const isSelectNode = nodeId === selectedNode?.id;

  switch (type) {
    case PaletteElementTypes['ACTION_DELAY'] || PaletteAutomationElementNames['PROCESSOR_DELAY']:
      if (typeDelay === 'fixed_datetime' && payload?.id === nodeId) {
        content.current = (
          <CanvasDelayUntilFixed
            nodeId={nodeId}
            when={isSelectNode ? whenData || payload?.payload.period?.when : payload?.payload.period?.when}
          />
        );
      } else if (typeDelay === 'fixed_period' && payload?.id === nodeId) {
        content.current = (
          <CanvasDelayFixedPeriod
            nodeId={nodeId}
            days={isSelectNode ? watchDays || payload?.payload.period?.days : payload?.payload.period?.days}
            hours={isSelectNode ? watchHours || payload?.payload.period?.hours : payload?.payload.period?.hours}
            minutes={isSelectNode ? watchMinutes || payload?.payload.period?.minutes : payload?.payload.period?.minutes}
            noEarlier={isSelectNode ? watchNoEarlier : payload?.payload.period?.no_earlier}
            noLater={isSelectNode ? watchNoLater : payload?.payload.period?.no_later}
          />
        );
      } else if (typeDelay === 'fixed_time_of_day' && payload?.id === nodeId) {
        content.current = <CanvasDelayFixedTime nodeId={nodeId} />;
      } else {
        content.current = <CanvasNodeAutomation nodeId={nodeId} />;
      }
      break;
    case PaletteElementTypes['ACTION_EMAIL']:
      content.current = <CanvasNodeEmail nodeId={nodeId} />;
      break;
    default:
      break;
  }
  const onOpenEndpoints = () => {
    setMultiView && setMultiView();
  };

  if (type === PaletteAutomationElementTypes['PROCESSOR_DELAY'] && builderType !== 'funnel') {
    return <>{content.current}</>;
  }

  if (
    type === PaletteElementTypes['ACTION_AUTOMATION'] ||
    type === PaletteElementTypes['ACTION_DELAY'] ||
    type === PaletteElementTypes['ACTION_EMAIL']
  ) {
    return (
      <div className={styles.wrapper}>
        <div className={classNames('d-flex align-items-center p-2', styles.container)}>
          <div className={styles.icon}>
            <ElementThumb name={type} builderType={'funnel'} />
          </div>
          <div className={classNames('title pl-2 pr-4 mb-0', styles.title, styles.funnel)}>{title}</div>
        </div>
        {content.current}
      </div>
    );
  }

  return (
    <>
      <div className="title">{title}</div>
      <ElementThumb name={type} builderType={'funnel'} />
      {/pages\./.test(type) && (
        <button onClick={onOpenEndpoints} className={styles.pageOutput}>
          Outputs
        </button>
      )}
    </>
  );
});
