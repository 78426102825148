import React, { FC, memo } from 'react';
import { FormCheck } from 'react-bootstrap';
import { Controller, useFormContext } from 'react-hook-form';

import classNames from 'classnames';
import styles from './toggle.module.scss';

import { getUUID } from '../../../../../../helpers';

interface IProps {
  checked: boolean;
  title: string;
  controlName: string;
  className?: string;
  onChangeValue?: (val: boolean) => void;
  valueData?: boolean;
  disable?: boolean;
}

export const Toggle: FC<IProps> = memo(
  ({ checked, title, controlName, className, valueData, onChangeValue, children, disable = false }) => {
    const { control } = useFormContext();
    const id = getUUID();
    return (
      <div className={classNames('bg-white rounded', styles.container, className)}>
        <div className={'d-flex align-items-center'}>
          <FormCheck
            className={'custom-control custom-switch disable custom-switch-sm'}
            custom
            type={'switch'}
            disabled={disable}
          >
            <Controller
              name={controlName}
              control={control}
              render={({ field: { onChange, value } }) => {
                return (
                  <FormCheck.Input
                    type="checkbox"
                    className="custom-control-input disable"
                    id={id}
                    disabled={disable}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      onChange(e.target.checked);
                      onChangeValue?.(e.target.checked);
                    }}
                    checked={valueData !== undefined ? valueData : !!value}
                  />
                );
              }}
            />
            <FormCheck.Label className="custom-control-label" htmlFor={id} />
          </FormCheck>
          <div className={classNames(styles.title, { [styles.active]: checked })}>{title}</div>
        </div>
        {checked && children}
      </div>
    );
  }
);
