import { memo, ReactNode } from 'react';

import styles from './disconnect.module.scss';
import { Icon32Warning, IconLinkDetach } from '../../../../components/UI/icons';

import { Button, CommonModal } from '../../../../components/UI';

interface IProps {
  onClose: () => void;
  onDisconnect: () => void;
  showModal: boolean;
  title: ReactNode;
  variableDescription: ReactNode;
  btnDisconnectText: string;
}

export const DisconnectModal = memo(
  ({ onClose, onDisconnect, showModal, title, variableDescription, btnDisconnectText }: IProps) => {
    return (
      <CommonModal onClose={onClose} show={showModal} showClose={true} params={{ contentClassName: styles.dialog }}>
        <div>
          <div className={styles.titleContainer}>
            <Icon32Warning className={styles.icon} />
            <div className={styles.title}>{title}</div>
          </div>
          <div className={styles.container}>
            <div className={styles.description}>{variableDescription}</div>
            <Button
              onClick={onDisconnect}
              btnStyle={'secondary'}
              customIcon={<IconLinkDetach />}
              iconPosition={'left'}
              size={'lg'}
              className={styles.btnDisconnect}
            >
              {btnDisconnectText}
            </Button>
            <Button
              onClick={onClose}
              btnStyle={'secondary'}
              size={'lg'}
              withIcon={false}
              className={styles.btn}
            >
              Cancel
            </Button>
          </div>
        </div>
      </CommonModal>
    );
  }
);
