import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import classNames from 'classnames';
import styles from './course-list.module.scss';
import { Icon32Archive, Icon32Briefcase, Icon32Megaphone, Icon32PencilOnPaper } from '../../../../components/UI/icons';

import { EmptyList } from '../../../../components/empty-list/EmptyList';
import { ListFilter } from '../../../../components/listFilter/ListFilter';
import { Loader } from '../../../../components/UI';
import { CreateListItem } from '../../../../components/UI/create-list-item/CreateListItem';
import { FilterState } from '../../../../interfaces/filters';
import { PageTopPanel } from '../../../../layout/shared-components/page-top-panel/PageTopPanel';
import { LoadState } from '../../../../store';
import { CreateCohort } from '../common/cohort/create-cohort/CreateCohort';
import { CourseListItem } from '../common/course-list-item/CourseListItem';
import { CreateCourseModal } from '../common/create-course-modal/CreateCourseModal';
import { courseActionAsync } from '../store/CourseActionAsync';
import { CoursesSelector } from '../store/CourseSelector';

const emptyModalTexts = {
  all: {
    description: 'Create your course for student learning',
    icon: <Icon32Briefcase width={64} height={64} />
  },
  draft: {
    description: 'You don’t have any courses to your draft',
    icon: <Icon32PencilOnPaper width={64} height={64} />
  },
  published: {
    description: 'You don’t have any published courses',
    icon: <Icon32Megaphone width={64} height={64} />
  },
  archived: {
    description: 'You don’t have any archived courses',
    icon: <Icon32Archive width={64} height={64} />
  }
};

export const CourseList = () => {
  const dispatch = useDispatch();
  const [activeFilter, setActiveFilter] = useState<FilterState>('all');
  const courseMemo = useMemo(CoursesSelector, [activeFilter]);
  const { courses, loadState } = useSelector((state) => courseMemo(state, activeFilter));
  const [show, setShow] = useState(false);
  const [showCohort, setShowCohort] = useState(false);

  const onFilter = useCallback((selectedKey: string | null = 'all') => {
    if (selectedKey) {
      setActiveFilter(selectedKey as FilterState);
    }
  }, []);

  useEffect(() => {
    const fetcher = async () => {
      dispatch(courseActionAsync.getCourses());
    };
    fetcher().then();
  }, [dispatch]);

  const toAllCourses = () => {
    setActiveFilter('all');
  };

  const showModal = useCallback(() => {
    setShow(true);
  }, []);

  const onClose = useCallback(() => {
    setShow(false);
  }, []);

  const openCreateCohort = useCallback(() => {
    setShowCohort(true);
  }, []);

  const closeCohort = useCallback(() => {
    setShowCohort(false);
  }, []);

  return (
    <>
      <PageTopPanel title={'Courses'}>
        <ListFilter activeKey={activeFilter} onSelect={onFilter} />
      </PageTopPanel>
      <div className={classNames('row', { 'justify-content-center h-100': !courses.length })}>
        {loadState === LoadState.firstLoad ? (
          <Loader />
        ) : courses.length ? (
          <>
            {activeFilter !== 'archived' && (
              <CreateListItem
                text={'new course'}
                subtext={'Share your knowledge with others and make money'}
                createItem={showModal}
                className={styles.create}
              />
            )}
            {courses.map((course) => (
              <CourseListItem course={course} key={course.id} />
            ))}
          </>
        ) : (
          <EmptyList
            title={activeFilter === 'all' ? 'No created courses yet' : 'It looks like it’s empty here'}
            description={emptyModalTexts[activeFilter].description}
            button={activeFilter === 'all' ? 'Create a course' : 'Go to all courses'}
            hideButtonIcon={activeFilter !== 'all'}
            onClick={activeFilter === 'all' ? showModal : toAllCourses}
            icon={emptyModalTexts[activeFilter].icon}
          />
        )}
      </div>
      <CreateCourseModal show={show} onClose={onClose} onCreateCohort={openCreateCohort} />
      {showCohort && <CreateCohort onClose={closeCohort} />}
    </>
  );
};
