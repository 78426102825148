import React, { useCallback, useRef, useState } from 'react';
import { FormControl } from 'react-bootstrap';

import classNames from 'classnames';
import styles from './enrollment-manipulate.module.scss';
import { IconSearch } from '../../../../../components/UI/icons';

import { ICourse } from '../../../courses/store/CourseState';
import { IStudentCourse } from '../../store/StudentListState';
import { EnrollmentElement } from '../enrollment-element/EnrollmentElement';

interface IProps {
  courses: ICourse[];
  activeCourses?: IStudentCourse[];
  selectedCourses: string[];

  setSelected(array: string[]): void;
}

export const EnrollmentManipulate = ({ courses, activeCourses = [], selectedCourses, setSelected }: IProps) => {
  const [data, setData] = useState<ICourse[]>(courses);
  const searchRef = useRef<HTMLInputElement | null>(null);

  const onSelect = useCallback(
    (event: React.SyntheticEvent<HTMLDivElement>) => {
      const id = event.currentTarget.dataset['id'] || '';
      const array = [...selectedCourses];
      selectedCourses.includes(id) ? array.splice(array.indexOf(id), 1) : array.push(id);
      setSelected(array);
    },
    [selectedCourses, setSelected]
  );

  const onSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newData = courses.filter((x) => x.title.toLowerCase().includes(event.currentTarget.value.toLowerCase()));
    setData(newData);
  };

  return (
    <div>
      <div className={styles.searchContainer}>
        <div className={styles.searchMagnifier}>
          <IconSearch />
        </div>
        <FormControl
          className={'form-control form-control-sm mb-2 pl-5'}
          placeholder={'Search by course name'}
          onChange={onSearch}
          ref={searchRef}
        />
      </div>
      <div className={classNames(styles.elementWrapper, data.length === 0 && styles.empty)}>
        {data
          .filter((x) => !activeCourses.find((course) => course.course_id === x.id))
          .map((x, index) => {
            const selected = selectedCourses.includes(x.id);
            const active = !!activeCourses.find((course) => course.course_id === x.id);

            return (
              <EnrollmentElement
                id={x.id}
                state={x.state}
                title={x.title}
                key={index}
                onClick={onSelect}
                active={active}
                selected={selected}
                thumbnail={x.settings.thumbnail}
              />
            );
          })}
      </div>
    </div>
  );
};
