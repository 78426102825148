import { ReactComponent as IconDateTime } from '../../../../assets/icons/24/4-icon-24-px-calendar-time.svg';
import { ReactComponent as IconHashtag } from '../../../../assets/icons/24/4-icon-24-px-hashtag.svg';
import { ReactComponent as IconHashtagTax } from '../../../../assets/icons/24/4-icon-24-px-hashtag-paper.svg';
import { ReactComponent as IconLink } from '../../../../assets/icons/24/4-icon-24-px-link.svg';
import { ReactComponent as IconMultiSelect } from '../../../../assets/icons/24/4-icon-24-px-multiple-selection.svg';
import { ReactComponent as IconPin } from '../../../../assets/icons/24/4-icon-24-px-pin.svg';
import { ReactComponent as IconSingleSelect } from '../../../../assets/icons/24/4-icon-24-px-single-selection.svg';
import { ReactComponent as IconText } from '../../../../assets/icons/24/4-icon-24-px-text.svg';
import { ReactComponent as IconToggle } from '../../../../assets/icons/24/4-icon-24-px-toggle-on.svg';

export const FieldIcons = {
  IconHashtag,
  IconHashtagTax,
  IconToggle,
  IconSingleSelect,
  IconMultiSelect,
  IconDateTime,
  IconLink,
  IconPin,
  IconText
};
