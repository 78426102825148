import React, { memo } from 'react';

import {
  Icon32Archive,
  Icon32Currency,
  Icon32Megaphone,
  Icon32PencilOnPaper
} from '../../../../../components/UI/icons';

import { EmptyList } from '../../../../../components/empty-list/EmptyList';
import { IOfferState } from '../../store/OffersState';

interface IProps {
  state?: IOfferState;

  onClick(): void;
}

export const EmptyOfferList = memo(({ state, onClick }: IProps) => {
  const button = state ? 'Go to all offers' : 'Create new offer';
  let description = 'Start by creating your first offer';
  let icon = <Icon32Currency width={64} height={64} />;
  switch (state) {
    case 'draft':
      description = 'You don’t have any offers to your draft';
      icon = <Icon32PencilOnPaper width={64} height={64} />;
      break;
    case 'published':
      description = 'You don’t have any published offers';
      icon = <Icon32Megaphone width={64} height={64} />;
      break;
    case 'archived':
      description = 'You don’t have any archived offers';
      icon = <Icon32Archive width={64} height={64} />;
      break;
  }
  return (
    <EmptyList
      onClick={onClick}
      button={button}
      hideButtonIcon={!!state}
      title={'It looks like it’s empty here'}
      description={description}
      icon={icon}
    />
  );
});
