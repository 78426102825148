import { IMeta } from '../../../../interfaces';
import { LoadState } from '../../../../store';
import { IList } from '../../lists/store/ListsState';
import { ITag } from '../../tag/store/TagState';

export interface IContactField {
  [id: string]: any;
}

export interface IContact {
  company_id: string;
  date_create: number;
  date_update: number;
  fields: IContactField;
  id: string;
  organization_id: string;
  tags?: ITag[];
  lists?: string[];
}

export interface IContactsState extends IMeta {
  loadState: LoadState;
  error: Error | null;
  contacts: IContact[];
}

export const ContactsInitialState: IContactsState = {
  loadState: LoadState.firstLoad,
  error: null,
  contacts: [],
  page: 1,
  page_size: 25,
  total: 25,
  total_pages: 1
};

export interface IContactListState {
  loadState: LoadState;
  error: Error | null;
  contactList: Partial<IList>;
}

export const ContactListInitialState: IContactListState = {
  loadState: LoadState.firstLoad,
  error: null,
  contactList: {}
};
