import { reducerWithInitialState } from 'typescript-fsa-reducers';

import { LoadState, newState } from '../../../store';
import { automationActions } from './AutomationActions';
import { AutomationInitialState, IAutomationState } from './AutomationState';

const commonFirstLoadHandler = (state: IAutomationState) => ({ ...state, loadState: LoadState.firstLoad, error: null });
const commonErrorHandler = (state: IAutomationState, { error }: { error: Error }) => ({
  ...state,
  loadState: LoadState.error,
  error
});

export const automationReducer = reducerWithInitialState<IAutomationState>(AutomationInitialState)
  .case(automationActions.getAutomations.started, commonFirstLoadHandler)
  .case(automationActions.getAutomations.done, (state, { result }) => {
    return newState(state, {
      automations: result,
      loadState: LoadState.allIsLoaded,
      error: null
    });
  })
  .case(automationActions.getAutomations.failed, commonErrorHandler)

  .case(automationActions.getAutomation.started, commonFirstLoadHandler)
  .case(automationActions.getAutomation.done, (state, { result }) => {
    const automations = state.automations.map((x) => (x.id === result.id ? result : x));
    return newState(state, {
      automations,
      loadState: LoadState.allIsLoaded,
      error: null
    });
  })
  .case(automationActions.getAutomation.failed, commonErrorHandler)

  .case(automationActions.deleteAutomation.started, commonFirstLoadHandler)
  .case(automationActions.deleteAutomation.done, (state, { params }) => {
    const removingIndexElement = state.automations.findIndex((x) => x.id === params.id);
    state.automations.splice(removingIndexElement, 1);

    return newState(state, {
      automations: state.automations,
      loadState: LoadState.allIsLoaded,
      error: null
    });
  })
  .case(automationActions.deleteAutomation.failed, commonErrorHandler)

  .case(automationActions.createAutomation.started, commonFirstLoadHandler)
  .case(automationActions.createAutomation.done, (state, { result }) => {
    return newState(state, {
      loadState: LoadState.firstLoad,
      error: null,
      automations: [...state.automations, { ...result }]
    });
  })
  .case(automationActions.createAutomation.failed, commonErrorHandler)

  .case(automationActions.updateAutomation.started, commonFirstLoadHandler)
  .case(automationActions.updateAutomation.done, (state, { result }) => {
    const newElement = state.automations.map((x) => (x.id === result.id ? result : x));
    return newState(state, {
      automations: newElement,
      loadState: LoadState.allIsLoaded,
      error: null
    });
  })
  .case(automationActions.updateAutomation.failed, commonErrorHandler)

  .case(automationActions.updateAutomationState.started, (state) => ({
    ...state,
    loadState: LoadState.idle,
    error: null
  }))
  .case(automationActions.updateAutomationState.done, (state, { result }) => {
    const newStateElement = state.automations.map((x) =>
      x.id === result.id ? { ...x, name: x.name, state: result.state } : x
    );

    return newState(state, {
      automations: newStateElement,
      loadState: LoadState.allIsLoaded,
      error: null
    });
  })
  .case(automationActions.updateAutomationState.failed, commonErrorHandler);
