import { buildStyles, CircularProgressbar } from 'react-circular-progressbar';

import styles from './status-loader-file.module.scss';
import { IconCheckMark, IconCross } from '../../../../../components/UI/icons';

import { ITranscodingStatus, StatusUpload } from '../../../../../API/video-api';
import { SpinnerLoader } from '../../../../../components/UI';

interface IProps {
  processingStatus?: ITranscodingStatus;
  uploadStatus: StatusUpload;
  hover: boolean;
  progress: number;
  onCancelHandler: () => void;
}

export const StatusLoaderFile = ({ uploadStatus, hover, onCancelHandler, progress, processingStatus }: IProps) => {
  const showCancelButton = hover && uploadStatus === 'in_progress';

  return (
    <>
      {showCancelButton ? (
        <div className={styles.cancelContainer} onClick={onCancelHandler}>
          <div className={styles.cancelText}>Cancel upload</div>
          <IconCross className={styles.canceledIcon} />
        </div>
      ) : (
        <>
          {uploadStatus === 'complete' && processingStatus?.state === 'ready' && (
            <IconCheckMark className={styles.checkMarkIcon} />
          )}
          {uploadStatus === 'error' && <div className={styles.errorText}>Upload error</div>}
          {uploadStatus === 'canceled' && <div className={styles.canceled}>Canceled</div>}
          {uploadStatus === 'in_progress' && (
            <CircularProgressbar
              className={styles.sizeLoader}
              value={Math.round(progress)}
              strokeWidth={12}
              styles={buildStyles({
                pathColor: '#0072FF'
              })}
            />
          )}
          {uploadStatus === 'complete' && processingStatus?.state !== 'ready' && (
            <div className={'d-flex align-items-center'}>
              <div className={styles.processingText}>Processing</div>
              <SpinnerLoader variant={'primary'} size={'md'} spinnerProps={{ className: styles.spinnerSize }} />
            </div>
          )}
        </>
      )}
    </>
  );
};
