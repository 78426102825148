import { useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';

import classNames from 'classnames';
import commonStyles from '../complete-steps.module.scss';
import styles from './ns-servers.module.scss';
import { Icon16CheckMark, Icon16Copy } from '../../../../../../../components/UI/icons';

interface IProps {
  className?: string;
  title?: string;
  nsServers: string[];
}

export const FollowingNameServers = ({ className, nsServers, title }: IProps) => {
  const [copeText, setCopyText] = useState('');
  const onCopy = (text: string) => {
    setCopyText(text);
  };
  return (
    <>
      {title && <span className={commonStyles.text}>{title}</span>}
      <div className={classNames(styles.nameserversContainer, className)}>
        {nsServers.map((name, index) => (
          <CopyToClipboard text={name} onCopy={() => onCopy(name)} key={index}>
            <div className={styles.name}>
              <span>{name}</span>
              <div className={classNames(styles.copy, { [styles.coped]: copeText === name })}>
                {copeText === name ? (
                  <>
                    Copied
                    <Icon16CheckMark />
                  </>
                ) : (
                  <>
                    <Icon16Copy />
                    Copy
                  </>
                )}
              </div>
            </div>
          </CopyToClipboard>
        ))}
      </div>
    </>
  );
};
