import actionCreatorFactory from 'typescript-fsa';

import { ITag } from './TagState';

export const actionCreator = actionCreatorFactory();

export const TagActions = {
  getTags: actionCreator.async<void, ITag[], Error>('GET_TAGS'),
  createTag: actionCreator.async<{ title: string; background_color: string; foreground_color: string }, ITag, Error>(
    'CREATE_TAG'
  ),
  editTag: actionCreator.async<
    { id: string; title?: string; background_color?: string; foreground_color?: string },
    ITag,
    Error
  >('EDIT_TAG'),
  deleteTag: actionCreator.async<{ id: string }, void, Error>('DELETE_TAG')
};
