import React, { memo, SyntheticEvent } from 'react';
import moment from 'moment';

import styles from './subscribed-success.module.scss';

import { HoorayModal } from '../../../../components/hooray/HoorayModal';
import { getPlans } from '../../../../helpers/plans';
import { ISubscription } from '../../../../interfaces';
import { CardIcon } from '../payment-card/CardIcon';

interface IProps {
  show: boolean;
  subscription: ISubscription;

  onUnsubscribe(): void;

  onClose(): void;
}

export const SubscribedSuccess = memo(({ show, subscription, onUnsubscribe, onClose }: IProps) => {
  const plans = getPlans();
  const selectedPlan = plans.find((plan) => plan.id === subscription.plan?.product);

  const unsubscribe = (e: SyntheticEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    onClose();
    onUnsubscribe();
  };

  return (
    <HoorayModal
      onCloseModal={onClose}
      show={show}
      title={`You are now subscribed to ${subscription.plan?.interval === 'month' ? 'monthly' : 'annual'} ${
        selectedPlan?.title
      } plan`}
      modalStyle={'blue'}
      contentClassName={styles.container}
    >
      <div className={styles.payment}>
        First payment ${subscription.plan && subscription.plan.amount / 100} on{' '}
        {moment(subscription.next_payment).format('DD MMM YYYY')}
      </div>
      <div className={styles.card}>
        <CardIcon subscription_payment_source={subscription.subscription_payment_source} />
        ×××× {subscription.subscription_payment_source?.last4}
      </div>
      <div className={styles.note}>
        Subscription will automatically renew every
        {subscription.plan?.interval === 'month'
          ? ` month on the ${moment(subscription.next_payment).format('Do')}.`
          : ` year on ${moment(subscription.next_payment).format('Do MMM')}.`}
        <br /> You can{' '}
        <a href={'/'} onClick={unsubscribe}>
          unsubscribe
        </a>{' '}
        at any time.
      </div>
    </HoorayModal>
  );
});
