import { memo } from 'react';

import classNames from 'classnames';
import styles from './skipped-quiz.module.scss';
import wink from '../../../../../../../assets/images/wink.png';
import { Icon32ArrowRight, Icon32Reload } from '../../../../../../components/UI/icons';

import { Button } from '../../../../../../components/UI';
import { IQuizResult } from '../store/QuizState';

interface IProps {
  result?: Nullable<IQuizResult>;
  onClick(): void;
}

export const SkippedQuiz = memo(({ onClick, result }: IProps) => (
  <div className={styles.container}>
    <img src={wink} alt={'quiz skipped'} className={classNames('mb-3', styles.img)} />
    <div className={styles.text}>
      {result ? 'Course teacher has skipped this quiz for you' : 'Course teacher has skipped this quiz for you'}
    </div>
    <div className={styles.note}>
      {result ? 'You can try to retake it at any time' : 'You can complete it at any time'}
    </div>
    <Button
      onClick={onClick}
      size={'lg'}
      customIcon={result ? <Icon32Reload /> : <Icon32ArrowRight />}
      withIcon
      iconPosition={'left'}
      className={styles.button}
    >
      {result ? 'Retake' : 'Continue'}
    </Button>
  </div>
));
