import { SVGProps } from 'react';

import classNames from 'classnames';
import styles from './circle-progressbar.module.scss';

import { Icon16CheckMark } from '../icons';

interface IProps {
  value: number;
  props?: SVGProps<SVGSVGElement>;
}

export const CircleProgressbar = ({ props, value }: IProps) => {
  let background = '#ffb3cd';
  let color = '#f20d5d';

  switch (true) {
    case value >= 25 && value < 75:
      background = '#fffacc';
      color = '#f9c206';
      break;
    case value >= 75 && value < 100:
      background = '#e0eeff';
      color = '#0072ff';
      break;
  }

  return value === 100 ? (
    <div className={classNames(styles.complete, props?.className)}>
      <Icon16CheckMark />
    </div>
  ) : (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      {...props}
      className={classNames(styles.container, props?.className)}
    >
      <circle cx="8" cy="8" r="6" fill="none" stroke={background} strokeWidth="2" />
      {value > 0 && (
        <circle
          className="percent ninety"
          cx="8"
          cy="8"
          r="6"
          fill="none"
          stroke={color}
          strokeWidth="2"
          pathLength="100"
          strokeDasharray="100"
          strokeDashoffset={100 - value}
        />
      )}
    </svg>
  );
};
