import { IRoutes } from '../../../../interfaces';
import { PageBuilderPanel } from '../../../../layout/header/pageBuilderPanel/PageBuilderPanel';
import { PageBuilder } from '../PageBuilder';

export const pageBuilderPaths = {
  pageBuilder: (funnelId = ':funnelId', id = ':id') => `/page-builder/${funnelId}/${id}`
};

export const PageBuilderRoutes: IRoutes[] = [
  {
    path: pageBuilderPaths.pageBuilder(),
    exact: true,
    header: () => <PageBuilderPanel />,
    main: () => <PageBuilder />
  }
];
