import { FC, memo } from 'react';

import classNames from 'classnames';
import styles from './info-message.module.scss';

import { Button } from '../button/Button';
import { Icon32Info } from '../icons';

interface IProps {
  withIcon?: boolean;
  withIconBtn?: boolean;
  btnIcon?: JSX.Element;
  customIcon?: JSX.Element;
  text: string | JSX.Element;
  btnClick?: () => void;
  btnText?: string;
  show: boolean;
  className?: string;
  textClassName?: string;
  type?: 'info' | 'error';
}

export const InfoMessage: FC<IProps> = memo(
  ({
    text,
    show,
    withIcon = true,
    btnText,
    className,
    btnClick,
    withIconBtn = false,
    btnIcon,
    textClassName,
    customIcon,
    children,
    type = 'info'
  }) => {
    return (
      <div
        className={classNames(styles.container, { [styles.error]: type === 'error' }, className, {
          [styles.hide]: !show
        })}
      >
        <div className={'d-flex align-items-center'}>
          {withIcon && customIcon ? customIcon : <Icon32Info className={styles.icon} />}
          <div className={classNames(styles.text, textClassName)}>{text}</div>
        </div>
        {children}

        {btnClick && btnText && (
          <Button onClick={btnClick} withIcon={withIconBtn}>
            {btnText}
            {btnIcon}
          </Button>
        )}
      </div>
    );
  }
);
