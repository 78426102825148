import { lazy, Suspense, useCallback, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import styles from './payload-email.module.scss';

import { SenderSelect } from '../../../../../../components/sender-select/SenderSelect';
import { Loader } from '../../../../../../components/UI';
import { useNodePayload } from '../../../../../../hooks/use-node-payload';
import { IMessage, PaletteAutomationElementTypes } from '../../../../../../interfaces';
import { IEmailActionsAutomationPayload } from '../../../../../../interfaces/node-builder-payload/automation-actions/email';
import { additionalEmailVariables, EmailActionAsync, organizationFields } from '../../../../email-editor';
import { AddEmail } from './common/add-email/AddEmail';
import { RecipientEmails } from './common/recipient-email/RecipientEmails';
import { SendInput } from './common/send-to/SendInput';
import { SendToRadio } from './common/send-to/SendToRadio';

const EmailEditor = lazy(() => import('../../../../email-editor/EmailEditor'));

interface IProps {
  automationId: string;
  nodeId: string;
}

export const PayloadEmail = ({ nodeId }: IProps) => {
  const [showEditor, setShowEditor] = useState(false);
  const node = useNodePayload<PaletteAutomationElementTypes.ACTION_SEND_EMAIL>(nodeId);
  const [sendToValueTeam, setSendToValueTeam] = useState(Array.isArray(node?.payload.recipient));
  const { setValue, watch } = useFormContext<IEmailActionsAutomationPayload>();
  const [message, setMessage] = useState<IMessage>();
  const emailId = watch('email_id');

  const dispatch = useDispatch();

  const onCloseEditor = useCallback(() => {
    setShowEditor(false);
  }, [setShowEditor]);

  const onMessageCallback = useCallback(
    (message: IMessage) => {
      setMessage(message);
      setValue('email_id', message.id);
    },
    [setValue]
  );

  const getEmailMessage = useCallback(
    (emailId: string) => {
      dispatch(
        EmailActionAsync.getMessage(emailId, (message) => {
          onMessageCallback(message);
          onCloseEditor();
        })
      );
    },
    [dispatch, onCloseEditor, onMessageCallback]
  );

  useEffect(() => {
    if (node?.payload.email_id) {
      getEmailMessage(node?.payload.email_id);
    } else {
      setValue('email_id', null);
    }
  }, [getEmailMessage, node?.payload.email_id, setValue]);

  const onOpenOrCreate = useCallback(() => {
    if (!watch().email_id && !message) {
      dispatch(
        EmailActionAsync.createMessage((createdMessage) => {
          onMessageCallback(createdMessage);
          setShowEditor(true);
        })
      );
    } else {
      setShowEditor(true);
    }
  }, [dispatch, message, onMessageCallback, watch]);

  return (
    <div className={styles.container}>
      {message && (
        <Suspense fallback={<Loader />}>
          <EmailEditor
            onCloseEmailEditor={onCloseEditor}
            showEmailEditor={showEditor}
            message={message}
            excludeScopes={['student_relation', 'course_relation', 'contact_relation']}
            fieldEntities={['contact', 'student']}
            additionalPropertyToEntity={additionalEmailVariables}
            additionalVariables={organizationFields}
            getEmailCallback={getEmailMessage}
          />
        </Suspense>
      )}
      <div>
        <div className={styles.label}>Send email:</div>
        <SendToRadio sendToValue={sendToValueTeam} setSendToValue={setSendToValueTeam} />
        {sendToValueTeam && <RecipientEmails recipientsDefault={node?.payload.recipient || []} />}
        <SenderSelect name={'sender_id'} selectedId={watch('sender_id') || node?.payload.sender_id || null} />
        <SendInput label={'Reply-to'} name={'reply_to'} defaultValue={node?.payload.reply_to} />
      </div>
      <AddEmail
        setMessage={setMessage}
        onOpen={onOpenOrCreate}
        emailId={emailId}
        payload={message?.payload}
        thumbnail={message?.meta?.thumbnail}
        title={message?.title}
      />
    </div>
  );
};
