import { memo, useCallback, useState } from 'react';
import { Collapse } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';

import classNames from 'classnames';
import styles from './recovery-form.module.scss';

import { PasswordControl } from '../../../../components/UI/password-control/PasswordControl';
import { validation } from '../../../../helpers/password-validation';
import { IRecoveryPassword } from '../../../../interfaces';
import { RuleItem } from '../rules-matcher/RuleItem';

interface IProps {
  isLoading: boolean;
  showRules: boolean;

  onFocusHandler(): void;

  onBlurHandler(): void;

  params?: {
    passwordPlaceholder?: string;
    repeatPlaceholder?: string;
    passwordLabel?: string;
    repeatLabel?: string;
    passwordClassName?: string;
    repeatClassName?: string;
    rulesClassName?: string;
  };
}

export const RecoveryForm = memo(
  ({
    isLoading,
    showRules,
    onBlurHandler,
    onFocusHandler,
    params = {
      passwordPlaceholder: 'Enter password',
      repeatPlaceholder: 'Repeat to confirm password'
    }
  }: IProps) => {
    const {
      formState: { errors }
    } = useFormContext<IRecoveryPassword>();
    const [errorData, setErrorData] = useState({
      minimum: false,
      uppercase: false,
      numeric: false
    });

    const onChangeHandler = useCallback((e: string) => {
      const data = validation(e);
      setErrorData(data);
    }, []);

    return (
      <>
        <PasswordControl
          name={'password'}
          controlProps={{
            disabled: isLoading,
            isInvalid: !!errors.password
          }}
          placeholder={params.passwordPlaceholder}
          groupProps={{ onFocus: onFocusHandler, onBlur: onBlurHandler }}
          onChangeHandler={onChangeHandler}
          label={params.passwordLabel}
          className={params.passwordClassName}
        />
        <Collapse in={showRules}>
          <div className={classNames(styles.wrapper, params.rulesClassName)}>
            <RuleItem text={'minimum 8 symbols'} isActive={errorData.minimum} />
            <RuleItem text={'at least 1 upper case'} isActive={errorData.uppercase} />
            <RuleItem text={'numeric character'} isActive={errorData.numeric} />
          </div>
        </Collapse>
        <PasswordControl
          name={'repeatPassword'}
          controlProps={{
            disabled: isLoading,
            isInvalid: !!errors.repeatPassword
          }}
          placeholder={params.repeatPlaceholder}
          className={classNames(params.repeatClassName, 'mb-0')}
          label={params.repeatLabel}
        />
        {errors.repeatPassword && <div className={styles.error}>{errors.repeatPassword.message}</div>}
      </>
    );
  }
);
