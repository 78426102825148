import { IRoutes } from '../../../interfaces';
import { CourseHeaderPanel } from '../../../layout/header/courseHeaderPanel/CourseHeaderPanel';
import { WaitingScreen } from '../../auth/common/waiting-screen/WaitingScreen';
import { UnavailableCourse } from '../../lms/courses/common/unavailable-modal/UnavailableCourse';
import { StudentCourse } from '../../lms/students/student-course/StudentCourse';
import { LoginStudent } from '../auth/login/LoginStudent';
import { StudentCourses } from '../courses/StudentCourses';
import { Policies } from '../policies/Policies';

export const StudentCabinetPaths = {
  main: '/',
  auth: '/login',
  signUp: '/sign-up',
  confirmation: '/confirmation',
  feed: '/my/feed',
  // course: (id = ':id') => `/student/${id}`,
  recovery: '/recovery',
  waitingScreen: '/waiting-screen',
  courses: '/my/courses',
  courseStudentLessons: (id = ':id', nodeId = '') => `/my/courses/${id}/curriculum/${nodeId}`,
  marketplace: '/my/marketplace',
  policies: (name = ':name') => `/policies/${name}`,
  unavailableCourse: (id = ':id') => `/my/courses/${id}/unavailable`
};

export const StudentCabinetRoutes: IRoutes[] = [
  {
    path: StudentCabinetPaths.courses,
    exact: true,
    main: () => (
      <div className={'student-cabinet'}>
        <StudentCourses />
      </div>
    )
  },
  {
    path: StudentCabinetPaths.courseStudentLessons(),
    main: () => <StudentCourse />,
    header: () => <CourseHeaderPanel />
  },
  {
    path: StudentCabinetPaths.unavailableCourse(),
    main: () => <UnavailableCourse />,
    exact: true
  }
];

export const StudentCabinetAuthRoute = {
  path: StudentCabinetPaths.auth,
  exact: false,
  main: () => <LoginStudent />
};

export const StudentCabinetWaitingScreen = {
  path: StudentCabinetPaths.waitingScreen,
  main: () => <WaitingScreen />,
  exact: false
};

export const PoliciesRoute = {
  path: StudentCabinetPaths.policies(),
  main: () => <Policies />,
  exact: false
};
