import { IRoutes } from '../../../interfaces';
import { CourseHeaderPanel } from '../../../layout/header/courseHeaderPanel/CourseHeaderPanel';
import { DashboardCourse } from '../DashboardCourse';

export const dashboardPaths = {
  dashboardLessons: (id = ':id', nodeId = '') => `/dashboard/courses/${id}/curriculum/${nodeId}`
};

export const DashboardRoutes: IRoutes[] = [
  {
    path: dashboardPaths.dashboardLessons(),
    main: () => <DashboardCourse />,
    header: () => <CourseHeaderPanel />
  }
];
