import { createSelector } from 'reselect';
import { find, flatMapDeep } from 'lodash';

import { flatten, getMembers } from '../../../../components/course/course/CourseHelpers';
import { IAppState } from '../../../../store';
import { ICourseNode } from '../../courses/store/CourseState';

export const StudentLectureNavigation = (courseId?: string, nodeId?: string) =>
  createSelector(
    (state: IAppState) => state.studentCourses.studentCourses.courses,
    (studentCourses) => {
      const course = studentCourses?.find((x) => x.id === courseId);
      const hierarchy = course?.children;
      const flatLessons = flatMapDeep(hierarchy, getMembers).filter((x) => x.type === 'lesson') as ICourseNode[];
      const flatSteps = flatMapDeep(hierarchy, getMembers).filter((x) => x.type === 'step') as ICourseNode[];
      const lessonId = find<ICourseNode | undefined>(flatMapDeep(hierarchy, flatten), ['id', nodeId])?.parent_id;
      const lesson = flatLessons.find((x) => x.id === lessonId);
      const stepIndex = lesson?.children?.findIndex((x: ICourseNode) => x.id === nodeId);
      const currentStep = lesson?.children?.find((x: ICourseNode) => x.id === nodeId);
      const flatLessonIndex = flatLessons.findIndex((x) => x.id === lessonId);

      return {
        currentStep: currentStep,
        nextStep: stepIndex !== undefined ? lesson?.children[stepIndex + 1] : undefined,
        prevStep: stepIndex !== undefined ? lesson?.children[stepIndex - 1] : undefined,
        next: flatLessons[flatLessonIndex + 1],
        prev: flatLessons[flatLessonIndex - 1],
        all: flatLessons,
        allSteps: flatSteps
      };
    }
  );

export const StudentLessonSelector = (courseId?: string, nodeId?: string) =>
  createSelector(
    (state: IAppState) => state.studentCourses.studentCourses.courses,
    (state: IAppState) => state.studentCourses.loadState,
    (studentCourses, loadState) => {
      const course = studentCourses?.find((x) => x.id === courseId);

      if (course && course.children) {
        const result = find<ICourseNode | undefined>(flatMapDeep(course.children, flatten), ['id', nodeId]);
        return {
          lesson: result,
          loadState
        };
      }
    }
  );
