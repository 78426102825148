import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { EventKey } from 'react-bootstrap/esm/types';
import { FormProvider, useForm } from 'react-hook-form';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Tag } from 'react-tag-autocomplete-fix';
import { yupResolver } from '@hookform/resolvers/yup';
import { chain, difference, map } from 'lodash';

import classnames from 'classnames';
import styles from './student-card.module.scss';
import { Icon32Cross, IconPencilOnPaper } from '../../../../components/UI/icons';

import { CustomTooltip, Toast } from '../../../../components/UI';
import { arrStrForSelect, getSeparatedDate, getUUID, lazySchema, validationSchema } from '../../../../helpers';
import { validationSchema as commonValidationSchema } from '../../../../helpers/validationSchema';
import { settingsActions } from '../../../../store/settings';
import { StudentId } from '../../../crm/contact/entity-id/StudentId';
import { FieldSchemaSelector, FieldSelector } from '../../../crm/field/store/FieldSelector';
import { courseActionAsync } from '../../courses/store/CourseActionAsync';
import { CoursesSelector } from '../../courses/store/CourseSelector';
import { Analytics } from '../common/analytics/analytics/Analytics';
import { EmptyStatistics } from '../common/analytics/empty-statistics/EmptyStatistics';
import { Enrollment } from '../enrollment/Enrollment';
import { StudentSelector } from '../store/StudentDataSelector';
import { IStudent, IStudentCourse, IStudentField } from '../store/StudentListState';
import { StudentsActionAsync } from '../store/StudentsActionAsync';
import { StudentForm } from '../student-form/StudentForm';

interface IProps {
  studentId?: string;
  courseId?: string;
  onCreate?: () => void;
  setStudentId(id: string): void;
}

export const StudentCard = memo(({ studentId, courseId, setStudentId, onCreate }: IProps) => {
  const dispatch = useDispatch();
  const courseMemo = useMemo(CoursesSelector, []);
  const { courses } = useSelector((state) => courseMemo(state, 'all'));
  const [tags, setTags] = useState<Tag[]>([]);
  const [activeSave, setActiveSave] = useState(false);
  const [activeTabKey, setActiveTabKey] = useState<EventKey>('student-info');
  const fieldSchemaMemo = useMemo(FieldSchemaSelector, []);
  const { fieldsSchema, selects, dateTimes } = useSelector((state) => fieldSchemaMemo(state));
  const studentMemo = useMemo(StudentSelector, [studentId]);
  const { student, studentTags } = useSelector((state) => studentMemo(state, studentId), shallowEqual);
  const [studentCourses, setStudentCourses] = useState<IStudentCourse[]>();
  const fieldMemo = useMemo(FieldSelector, []);
  const { students } = useSelector((state) => fieldMemo(state, 'student'));
  const schema = commonValidationSchema(students);
  const methods = useForm<Record<string, any>>({
    defaultValues: {},
    shouldUnregister: false,
    resolver: yupResolver(schema)
  });

  const { handleSubmit, setValue, reset, formState } = methods;

  const onSetTag = useCallback((tag: Tag[]) => {
    setTags(tag);
  }, []);

  const onCallback = (student?: IStudent) => {
    if (studentId) {
      dispatch(StudentsActionAsync.getStudent(studentId));
    } else if (student) {
      setStudentId(student.id);
      onCreate?.();
    }
  };

  useEffect(() => {
    if (studentId) {
      dispatch(StudentsActionAsync.getStudent(studentId, (data) => setStudentCourses(data.courses)));
    }
  }, [dispatch, studentId]);

  useEffect(() => {
    dispatch(courseActionAsync.getCourses());
  }, [dispatch]);

  useEffect(() => {
    let tagStatus = false;
    if (student?.fields) {
      tagStatus =
        difference(studentTags, tags).length > 0 ||
        difference(tags, studentTags).length > 0 ||
        (studentTags.length > 0 && tags.length === 0);
    }
    setActiveSave(formState.isDirty || tagStatus);
  }, [studentId, formState.isDirty, student?.fields, studentTags, tags]);

  const onSave = async (data: IStudentField) => {
    const fields = chain(fieldsSchema.filter((x) => x.entity === 'student'))
      .keyBy('id')
      .mapValues((v) => (v.scope !== 'custom' ? v.scope : v.type))
      .value();
    try {
      const dataValidation = await lazySchema(fields).validate(data, { abortEarly: false });
      const res: Record<string, string | string[]> = {};
      for (const [key, value] of Object.entries(dataValidation)) {
        const field = fieldsSchema.find((x) => x.id === key);
        if (value !== null && field) {
          res[key] = validationSchema(field.type, value);
        }
      }

      const tagNames = map(tags, 'id') as string[];

      if (Object.keys(res).length !== 0 && !studentId) {
        dispatch(StudentsActionAsync.createStudent(res, tagNames, onCallback));
      }
      if (Object.keys(res).length !== 0 && studentId) {
        dispatch(StudentsActionAsync.editStudent(studentId, res, tagNames, onCallback));
        onClose();
      }
      if (Object.keys(res).length === 0) {
        Toast('error', 'At least one field must be filled');
      }
    } catch (error) {
      console.log('%c⇒ error', 'color: #FF5370', error);
      Toast('error', (error as any)?.message);
    }
  };

  const onClose = () => {
    dispatch(settingsActions.setPayloadShowing({ isPayloadShow: false }));
  };

  const onError = (errors: any) => {
    for (let error in errors) {
      Toast('error', errors[error].message || errors[error].time?.message);
    }
  };

  const openEditFields = () => {
    dispatch(settingsActions.setSidebarShowing({ isSidebarShow: true }));
  };

  useEffect(() => {
    for (let index = 0; index < fieldsSchema.length; index++) {
      setValue(fieldsSchema[index].id, null);
    }

    if (student && student.fields) {
      for (const [key, value] of Object.entries(student.fields)) {
        if (selects.find((x) => x.id === key && x.payload.display_options === false)) {
          setValue(key, Array.isArray(value) ? arrStrForSelect(value) : { label: value, value: getUUID() });
        } else if (dateTimes.find((x) => x.id === key) && !Array.isArray(value)) {
          const dateRes = getSeparatedDate(value);
          setValue(key, dateRes);
          setValue(`${key}.zo`, dateRes.zo);
          setValue(`${key}.time`, dateRes.time);
        } else {
          setValue(key, value);
        }
      }
    }
  }, [dateTimes, fieldsSchema, reset, selects, setValue, student]);

  useEffect(() => {
    if (studentTags) {
      onSetTag(studentTags);
    }
  }, [onSetTag, studentTags]);

  const onChangeTab = (k: string | null) => {
    if (k) {
      setActiveTabKey(k);
    }
  };

  const coursesList = useMemo(
    () =>
      studentCourses?.map((course) => {
        const fullCourse = courses.find((x) => x.id === course.course_id);
        return { ...course, title: fullCourse?.title, settings: fullCourse?.settings };
      }),
    [courses, studentCourses]
  );

  return (
    //@ts-ignore
    <FormProvider {...methods}>
      <button onClick={onClose} className={classnames('btn btn-icon', styles.close)}>
        <Icon32Cross />
      </button>

      <div className={styles.studentId}>{studentId ? <StudentId studentId={studentId} /> : null}</div>

      <Tabs
        defaultActiveKey={activeTabKey}
        id="student-card"
        activeKey={activeTabKey}
        className={classnames('tabs', styles.tabs)}
        onSelect={onChangeTab}
      >
        <Tab eventKey="student-info" title="Student info">
          <StudentForm onSetTag={onSetTag} tags={tags} student={student} />
        </Tab>
        <Tab
          eventKey="enrollment"
          title={
            studentId ? (
              'Enrollments'
            ) : (
              <CustomTooltip direction={'top'} customText={'Save information to enable this option'}>
                Enrollment
              </CustomTooltip>
            )
          }
          disabled={!studentId}
        >
          <Enrollment student={student} />
        </Tab>
        <Tab eventKey="analytics" title="Analytics" className={styles.analytics}>
          {coursesList?.length && student?.user_id ? (
            <Analytics student={student} courseId={courseId} />
          ) : (
            <EmptyStatistics type={'course'} />
          )}
        </Tab>
      </Tabs>

      {activeTabKey === 'student-info' && (
        <div className={classnames('action-buttons', styles.actionButtons)}>
          <button type={'button'} onClick={openEditFields} className={classnames('btn btn-sm btn-icon-right mr-auto')}>
            Edit fields
            <IconPencilOnPaper className={'icon'} />
          </button>
          <button type="button" className="btn btn-sm mr-3 btn-gradient-secondary" onClick={onClose}>
            Cancel
          </button>
          <button
            type="button"
            className={classnames('btn', 'btn-sm', 'btn-gradient-primary', styles.save)}
            onClick={handleSubmit(onSave, onError)}
            disabled={!activeSave}
          >
            Save
          </button>
        </div>
      )}
    </FormProvider>
  );
});
