import { createContext, useContext } from 'react';

import { LoadState } from '../../../../store';
import { ICourse, ICourseNode } from '../../courses/store/CourseState';

export interface CourseNodeMemoParams {
  courseId?: string;
  id?: string;
}

export interface CourseNodeMemoReturn {
  lesson: ICourseNode | undefined;
  loadState: LoadState;
}
export type FlatLesson = {
  currentStep: ICourseNode | undefined;
  nextStep: ICourseNode | undefined;
  prevStep: ICourseNode | undefined;
  next: ICourseNode;
  prev: ICourseNode;
  all: ICourseNode[];
  allSteps: ICourseNode[];
};

export type ViewCourseContent = {
  toPreviousFolder: () => void;
  getHierarchy: () => void;
  currentNode: ICourseNode | null;
  currentHierarchy: ICourseNode[];
  setCurrentNode: (node: ICourseNode) => void;
  currentNav: ICourseNode | null;
  onSelect: (id: string, redirectNode?: ICourseNode, isBack?: boolean) => void;
  nodeId?: string;
  closeSidebar: () => void;
  course: ICourse;
  flatLessons?: FlatLesson;
  onRedirectLessonHandler: (nodeId?: string) => void;
};

export const ViewCourseContext = createContext<ViewCourseContent>({
  toPreviousFolder: () => {},
  getHierarchy: () => {},
  currentNode: null,
  currentHierarchy: [],
  currentNav: null,
  setCurrentNode: () => {},
  onSelect: () => {},
  closeSidebar: () => {},
  onRedirectLessonHandler: () => {},
  course: {
    children: [],
    comment_count: 0,
    date_create: 0,
    date_update: 0,
    id: '',
    industry: 'business',
    lessons_counts: 0,
    organization_id: '',
    rating: 0,
    settings: {
      category: null,
      custom_code: null,
      thumbnail: null,
      progress_sequential_flow: false
    },
    state: 'draft',
    student_count: 0,
    title: '',
    lessons_pass: 0,
    last_lesson: null
  }
});
export const useViewCourseContext = () => useContext(ViewCourseContext);
