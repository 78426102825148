import { memo } from 'react';

import styles from './remove-last-card.module.scss';

import { Button, CommonModal } from '../../../../components/UI';
import { IPaymentCard } from '../../../../interfaces';
import { CardIcon } from '../payment-card/CardIcon';

interface IProps {
  card: IPaymentCard;
  show: boolean;
  onClose(): void;
  onRemove(): void;
  loading: boolean;
}

export const RemoveLastCard = memo(({ show, onClose, onRemove, card, loading }: IProps) => {
  return (
    <CommonModal show={show} onClose={onClose} showClose={!loading} params={{ dialogClassName: styles.wrapper }}>
      <div className={styles.container}>
        <div className={styles.card}>
          <span className={'mr-1'}>Remove card</span> <CardIcon subscription_payment_source={card} />{' '}
          <strong>×××× {card.last4}</strong>
        </div>
        <div className={styles.text}>By removing the last payment card, your subscription will be canceled</div>
        <Button onClick={onClose} withIcon={false} size={'lg'} className={'w-100'} btnProps={{disabled: loading}}>
          Leave this card and stay subscribed
        </Button>
        <Button onClick={onRemove} withIcon={false} size={'lg'} btnStyle={'warning'} className={'w-100'} loading={loading}>
          Continue to unsubscribe
        </Button>
      </div>
    </CommonModal>
  );
});
