import Frame from 'react-frame-component';
import InnerHTML from 'dangerously-set-html-content';
import { VideoPlayer } from 'src/app/components/video/VideoPlayer';
import { TypeOfUploader, VideoField } from 'src/app/interfaces/fileUpload';

import styles from './video.module.scss';

interface IProps {
  video?: VideoField;
}
export const Video = ({ video }: IProps) => {
  return (
    <div className={styles.video}>
      {(video?.type === TypeOfUploader.upload || video?.type === TypeOfUploader.link) && (
        <VideoPlayer url={video?.url || video?.video_url} />
      )}
      {video?.code && video?.type === TypeOfUploader.embed && (
        <div className={styles.videoWrapper}>
          <Frame
            className={styles.iframe}
            initialContent={
              '<!DOCTYPE html><html lang="en"><head><style>iframe {width: 100%;height: 100%;min-height: 432px;}</style><title></title></head><body style="margin: 0"><div></div></body></html>'
            }
            title="Embedded Content"
          >
            <InnerHTML html={video.code} />
          </Frame>
        </div>
      )}
    </div>
  );
};
