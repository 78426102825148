import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { orderBy } from 'lodash';

import classnames from 'classnames';
import styles from './product-form.module.scss';
import { Icon32Trash } from '../../../../components/UI/icons';

import { CustomTooltip, FormField } from '../../../../components/UI';
import { ImageField } from '../../../../components/UI/fields/image-field/ImageField';
import { FieldSelector } from '../../field/store/FieldSelector';
import { ProductFormTitle } from '../common/product-form-title/ProductFormTitle';
import { IProduct, ProductType } from '../store/ProductsState';
import { ProductDelete } from './ProductDelete';

interface IProps {
  product?: IProduct;
  type?: ProductType;
  isDelete?: boolean;
}

export const ProductForm = ({ product, type, isDelete = true }: IProps) => {
  const [showModal, setShowModal] = useState(false);
  const fieldMemo = useMemo(FieldSelector, []);
  const isCourseProduct = type === 'course';
  const { products, productImage } = useSelector((state) => fieldMemo(state, isCourseProduct ? 'course' : 'product'));

  const onShowModalDelete = () => setShowModal(true);
  const onCloseModalDelete = () => setShowModal(false);

  const sortedFields = products.map((product) => {
    switch (product.scope) {
      case 'title':
        product.order = 0;
        break;
      case 'price':
        product.order = 1;
        break;
      case 'quantity':
        product.order = 2;
        break;
      case 'sku':
        product.order = 3;
        break;
      case 'description':
        product.order = 4;
        break;
    }
    return product;
  });

  return (
    <>
      {!isCourseProduct && isDelete && (
        <button type={'button'} className={classnames('btn btn-icon', styles.deleteButton)} onClick={onShowModalDelete}>
          <CustomTooltip customText={'Delete product'} direction={'top'}>
            <Icon32Trash />
          </CustomTooltip>
        </button>
      )}
      <div className={styles.productForm}>
        <ProductFormTitle type={type} product={product} />
        <div className="mb-3">
          <ImageField
            field={productImage[0]}
            params={{
              className: isCourseProduct ? styles.coursePreview : '',
              imageSize: {
                width: isCourseProduct ? 160 : 96,
                height: 96
              }
            }}
          />
        </div>
        {orderBy(sortedFields, ['order']).map((field) => (
          <FormField key={field.id} field={field} />
        ))}
      </div>
      {product && <ProductDelete onClose={onCloseModalDelete} show={showModal} product={product} />}
    </>
  );
};
