import { memo, useCallback, useEffect, useState } from 'react';
import { FormProvider, UnpackNestedValue, useForm, useWatch } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import { yupResolver } from '@hookform/resolvers/yup';

import classNames from 'classnames';
import commonStyles from '../main/setting.module.scss';
import styles from './general.module.scss';

import { selectedOption } from '../../../helpers';
import { IGeneralSettings, IOption } from '../../../interfaces';
import { HelperWrapper } from '../common/help/HelperWrapper';
import { SettingInput } from '../common/input/SettingInput';
import { SettingSelect } from '../common/select/SettingSelect';
import { EmailSender } from '../common/sender/email-sender/EmailSender';
import Footer from '../common/settings-actions/SettingsActions';
import { OrganizationActionAsync } from '../organization/store/OrganizationActionAsync';
import { SettingsHeader } from '../SettingsHeader';
import countriesOptions from './data/countries.json';
import dateOptions from './data/date.json';
import industryOptions from './data/industry.json';
import nameOptions from './data/name.json';
import regionsOptions from './data/regions.json';
import timeOptions from './data/time.json';
import timezonesOptions from './data/timezones.json';
import { generalSchema } from './generalSchema';
import { GeneralSettingsActionAsync } from './store/GeneralSettingsActionAsync';

export const General = memo(() => {
  const dispatch = useDispatch();
  const { general } = useSelector((state) => state.general);
  const [regions, setRegions] = useState<IOption[]>([]);
  const methods = useForm<IGeneralSettings>({ resolver: yupResolver(generalSchema) });
  const watchData = useWatch({
    name: 'region_country',
    control: methods.control
  });

  useEffect(() => {
    if (watchData) {
      const rns = regionsOptions[watchData];
      setRegions(rns);
    }
  }, [watchData]);

  useEffect(() => {
    dispatch(OrganizationActionAsync.getOrganizationMembers());
  }, [dispatch]);

  useEffect(() => {
    if (regions.length > 0 && watchData !== general.region_country) {
      methods.setValue('region_state', regions[0].value);
    }
  }, [general.region_country, methods, regions, watchData]);

  const onSaveCallback = useCallback(() => {
    methods.reset(methods.getValues());
  }, [methods]);

  const onSave = useCallback(
    (data: UnpackNestedValue<IGeneralSettings>) => {
      dispatch(GeneralSettingsActionAsync.editOrganization(data, onSaveCallback));
    },
    [dispatch, onSaveCallback]
  );

  const onCancel = useCallback(() => {
    methods.reset(general);
  }, [general, methods]);

  return (
    //@ts-ignore
    <FormProvider {...methods}>
      <SettingsHeader title={'General'} />
      <HelperWrapper>
        <div className={commonStyles.contentContainer}>
          <div className={styles.title}>Organization info</div>
          <div className={'d-flex'}>
            <SettingInput label={'Name'} name={'general_title'} defaultValue={general.general_title} />
            <SettingSelect
              label={'Industry'}
              name={'general_industry'}
              options={industryOptions}
              defaultValue={selectedOption(industryOptions, general.general_industry)}
            />
          </div>
          <div className={'d-flex mt-4 mb-5'}>
            <SettingInput label={'Email'} name={'general_email'} defaultValue={general.general_email}>
              <div className={styles.helpInputText}>
                We’ll use this address if we need to contact you about your store.
              </div>
            </SettingInput>
            <SettingInput label={'Phone number'} name={'general_phone'} defaultValue={general.general_phone} />
          </div>

          <EmailSender />

          <div className={classNames(styles.title, 'mt-5')}>Region</div>
          <div className={'d-flex'}>
            <SettingSelect
              label={'Country'}
              name={'region_country'}
              options={countriesOptions}
              defaultValue={selectedOption(countriesOptions, general.region_country)}
              props={{ menuPlacement: 'top' }}
            />
            <SettingSelect
              label={'State'}
              name={'region_state'}
              options={regions || []}
              defaultValue={selectedOption(regions, general.region_state)}
              props={{ menuPlacement: 'top' }}
            />
          </div>
          <div className={'d-flex mt-4'}>
            <SettingSelect
              label={'Time zone'}
              name={'region_timezone'}
              options={timezonesOptions}
              defaultValue={selectedOption(timezonesOptions, general.region_timezone)}
              props={{ menuPlacement: 'top' }}
            />
          </div>

          <div className={classNames(styles.title, 'mt-5')}>Formatting</div>
          <div className={'d-flex'}>
            <SettingSelect
              label={'Date'}
              name={'formatting_date_format'}
              options={dateOptions}
              defaultValue={selectedOption(dateOptions, general.formatting_date_format)}
              props={{ menuPlacement: 'top' }}
            />
            <SettingSelect
              label={'Time'}
              name={'formatting_time_format'}
              options={timeOptions}
              containerStyles={styles.timeContainer}
              defaultValue={selectedOption(timeOptions, general.formatting_time_format)}
              props={{ menuPlacement: 'top' }}
            />
          </div>
          <div className={'d-flex mt-4'}>
            <SettingSelect
              label={'Contact name'}
              name={'formatting_contact_name'}
              options={nameOptions}
              defaultValue={selectedOption(nameOptions, general.formatting_contact_name)}
              props={{ menuPlacement: 'top' }}
            />
          </div>
        </div>
        <CSSTransition in={methods.formState.isDirty} timeout={300} classNames={styles} unmountOnExit>
          <Footer methods={methods} onCancel={onCancel} onSave={onSave} />
        </CSSTransition>
      </HelperWrapper>
    </FormProvider>
  );
});
