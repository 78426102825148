import { createSelector } from 'reselect';
import _ from 'lodash';

import { RowType } from '../../../../interfaces/tables';
import { IAppState } from '../../../../store';
import { iTagToTag } from '../../../crm/tag/store/TagSelector';
import { IStudent, StudentListRow } from './StudentListState';

export const StudentDataSelector = () =>
  createSelector(
    (state: IAppState) => state.students,
    (state: IAppState) => state.students.loadState,
    (state: IAppState) => state.students.error,
    (students, loadState, error) => {
      const studentData: RowType<StudentListRow, number | IStudent | undefined>[] = students.students.map((student) => {
        return {
          ...student.fields,
          id: student.id,
          courses_count: student.courses_count,
          date_create: student.date_create,
          progress: student.progress,
          enrolment_date: student.enrolment_date,
          data: student
        };
      });

      return {
        studentData,
        page: students.page,
        page_size: students.page_size,
        total: students.total,
        total_pages: students.total_pages,
        loadState,
        error
      };
    }
  );

export const StudentSelector = () =>
  createSelector(
    (_: IAppState, studentId: string | undefined) => studentId,
    (state: IAppState) => state.students.students,
    (state: IAppState) => state.tags.tags,
    (studentId, students, tags) => {
      const student = students.find((x) => {
        return x.id === studentId;
      });

      if (student) {
        const studentTags = iTagToTag(_.difference(student?.tags, tags));
        return { student, studentTags };
      } else {
        return {
          student: undefined,
          studentTags: []
        };
      }
    }
  );
