import { createSelector } from 'reselect';
import { find, flatMapDeep } from 'lodash';

import { flatten, getMembers } from '../../../components/course/course/CourseHelpers';
import { IAppState } from '../../../store';
import { ICourseNode } from '../../lms/courses/store/CourseState';

export const DashboardLessonSelector = () =>
  createSelector(
    (_: IAppState, props: { courseId?: string; id?: string }) => props,
    (state: IAppState) => state.dashboard.courses,
    (state: IAppState) => state.dashboard.loadState,
    (props, courses, loadState) => {
      const course = courses?.find((x) => x.id === props.courseId);

      if (course && course?.children) {
        const result = find<ICourseNode | undefined>(flatMapDeep(course.children, flatten), ['id', props.id]);
        return {
          lesson: result,
          loadState
        };
      }
    }
  );

export const DashboardLectureNavigation = createSelector(
  (_: IAppState, props: { courseId?: string; nodeId?: string; fromDashboard?: boolean }) => props,
  (state: IAppState) => state.dashboard.courses,
  (props, dashboardCourses) => {
    const course = dashboardCourses?.find((x) => x.id === props.courseId);
    const hierarchy = course?.children;
    const flatLessons = flatMapDeep(hierarchy, getMembers).filter((x) => x.type === 'lesson') as ICourseNode[];
    const flatSteps = flatMapDeep(hierarchy, getMembers).filter((x) => x.type === 'step') as ICourseNode[];
    const lessonId = find<ICourseNode | undefined>(flatMapDeep(hierarchy, flatten), ['id', props.nodeId])?.parent_id;
    const lesson = flatLessons.find((x) => x.id === lessonId);
    const stepIndex = lesson?.children?.findIndex((x: ICourseNode) => x.id === props.nodeId);
    const currentStep = lesson?.children?.find((x: ICourseNode) => x.id === props.nodeId);
    const flatLessonIndex = flatLessons.findIndex((x) => x.id === lessonId);

    return {
      currentStep: currentStep,
      nextStep: stepIndex !== undefined ? lesson?.children[stepIndex + 1] : undefined,
      prevStep: stepIndex !== undefined ? lesson?.children[stepIndex - 1] : undefined,
      next: flatLessons[flatLessonIndex + 1],
      prev: flatLessons[flatLessonIndex - 1],
      all: flatLessons,
      allSteps: flatSteps
    };
  }
);

export const DashboardCourseSelector = () =>
  createSelector(
    (_: IAppState, props: { courseId: string | undefined }) => props,
    (state: IAppState) => state.dashboard.courses,
    (state: IAppState) => state.dashboard.loadState,
    (props, courses, loadState) => {
      return {
        course: courses?.find((course) => course.id === props.courseId),
        loadState
      };
    }
  );
