import { Action, combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import { ThunkAction } from 'redux-thunk';

import { impersonationReducer } from '../layout/shared-components/impersonation/store/ImpersonationReducer';
import { FileUploaderReducer } from '../layout/shared-components/upload-worker/store/FileUploaderReducer';
import { authReducer } from '../modules/auth/store/AuthReducer';
import { automationReducer } from '../modules/automations/store/AutomationReducer';
import { builderReducer } from '../modules/common/builder/store/BuilderReducer';
import { emailReducer } from '../modules/common/email-editor';
import { currentContactReducer } from '../modules/crm/contact/current-contact-store/CurrentContactReducer';
import { contactListReducer } from '../modules/crm/contact/store/ContactListReducer';
import { contactsConfigReducer } from '../modules/crm/contact/store/ContactsConfigReducer';
import { fieldReducer } from '../modules/crm/field/store/FieldReducer';
import { listsReducer } from '../modules/crm/lists/store/ListsReducer';
import { offersReducer } from '../modules/crm/offers/store/OffersReducer';
import { productsConfigReducer } from '../modules/crm/products/store/ProductsConfigReducer';
import { productsReducer } from '../modules/crm/products/store/ProductsReducer';
import { tagReducer } from '../modules/crm/tag/store/TagReducer';
import { dashboardReducer } from '../modules/dashboard/store/DashboardReducer';
import { funnelReducer } from '../modules/funnels/funnels/store/FunnelReducer';
import { courseReducer } from '../modules/lms/courses/store/CourseReducer';
import { studentsConfigReducer } from '../modules/lms/students/store/StudentsConfigReducer';
import { studentsListsReducer } from '../modules/lms/students/store/StudentsListsReducer';
import { studentsReducer } from '../modules/lms/students/store/StudentsReducer';
import { studentCommentReducer } from '../modules/lms/students/student-comment/store/StudentCommentReducer';
import { accountReducer } from '../modules/settings/account/store/AccountReducer';
import { billingReducer } from '../modules/settings/billing/store';
import { domainReducer } from '../modules/settings/domain/store/DomainSettingsReducer';
import { generalSettingsReducer } from '../modules/settings/general/store/GeneralSettingsReducer';
import { policyReducer } from '../modules/settings/legal/store/PolicyReducer';
import { organizationReducer } from '../modules/settings/organization/store/OrganizationReducer';
import { settingCourseReducer } from '../modules/settings/store/SettingCourseReducer';
import { navigationReducer } from '../modules/student-cabinet/store/NavigationReducer';
import { studentCabinetReducer } from '../modules/student-cabinet/store/StudentCabinetReducer';
import { permissionReducer } from '../providers/permission/store/PermissionReducer';
import { IAppState } from './appState';
import { settingsConfig } from './configureStore';
import { settingsReducer } from './settings';

export const createMainReducer = () =>
  combineReducers({
    auth: authReducer,
    impersonation: impersonationReducer,
    permissions: permissionReducer,
    account: accountReducer,
    domains: domainReducer,
    automations: automationReducer,
    builder: builderReducer,
    contacts: contactListReducer,
    contactsConfig: contactsConfigReducer,
    currentContact: currentContactReducer,
    courses: courseReducer,
    dashboard: dashboardReducer,
    fields: fieldReducer,
    funnels: funnelReducer,
    lists: listsReducer,
    tags: tagReducer,
    productsConfig: productsConfigReducer,
    products: productsReducer,
    general: generalSettingsReducer,
    settings: persistReducer(settingsConfig, settingsReducer),
    students: studentsReducer,
    studentsConfig: studentsConfigReducer,
    studentsLists: studentsListsReducer,
    studentCourses: studentCabinetReducer,
    navigation: navigationReducer,
    settingCourse: settingCourseReducer,
    offers: offersReducer,
    organization: organizationReducer,
    policy: policyReducer,
    billing: billingReducer,
    email: emailReducer,
    fileUpload: FileUploaderReducer,
    studentComments: studentCommentReducer
  });

export interface IThunkAction extends ThunkAction<Promise<void>, IAppState, {}, Action> {}
