import { useState } from 'react';
import { Collapse } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import classNames from 'classnames';
import styles from './pending-connection.module.scss';
import { Icon16ChevronDown, Icon16ChevronUp } from '../../../../../../components/UI/icons';

import { CompleteSteps } from '../../../connect-new-domain/components/complete-steps/CompleteSteps';

interface IProps {
  isDomain: boolean;
}

export const PendingConnection = ({ isDomain }: IProps) => {
  const [open, setOpen] = useState(false);
  const handleToggle = () => setOpen((x) => !x);
  const { domains } = useSelector((state) => state.domains);
  const domain = domains.find((dom) => dom.is_default);
  const ourDomain = domain?.subdomain ? `${domain.subdomain}.${domain.domain}` : domain?.domain;

  return (
    <div className={styles.wrapper}>
      <div className={styles.description}>
        Until your domain will be connected, your courses is still available on {ourDomain} as before. It may take some
        time, but we will send you an email when the domain is connected.
      </div>
      <div className={styles.viewAll} onClick={handleToggle} aria-controls="collapse-text" aria-expanded={open}>
        View full setup instructions {open ? <Icon16ChevronUp /> : <Icon16ChevronDown />}
      </div>
      <Collapse in={open} appear>
        <div id="collapse-text" className={classNames({'pt-3': open})}>
          <CompleteSteps isDomain={isDomain} className={'full-instructions'} />
        </div>
      </Collapse>
    </div>
  );
};
