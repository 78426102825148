import { memo, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import classNames from 'classnames';
import styles from './preparing-cohort-modal.module.scss';
import {
  Icon32Briefcase,
  Icon32Cart,
  Icon32Group,
  Icon32Label,
  Icon32Spinner
} from '../../../../../../components/UI/icons';

import { Button, CommonModal } from '../../../../../../components/UI';
import { coursePaths } from '../../../routes/CourseRoutes';
import { courseActionAsync } from '../../../store/CourseActionAsync';
import { ICohortForm } from '../create-cohort-modal/CreateCohortModal';

interface IProps {
  show: boolean;
  cohortData: ICohortForm;

  onClose(): void;

  goBack(): void;
}

export const PreparingCohortModal = memo(({ show, cohortData, onClose, goBack }: IProps) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  const onCourseCreated = useCallback(
    (id: string) => {
      setLoading(false);
      history.push(coursePaths.courseLessons(id));
    },
    [history]
  );

  const createCohort = () => {
    if (cohortData.course) {
      const requestData = {
        course_id: cohortData.course.value,
        offers: cohortData.offers?.map((offer) => offer.value),
        suffix: cohortData.suffix
      };
      setLoading(true);
      dispatch(
        courseActionAsync.createCohort(requestData, onCourseCreated, () => {
          setLoading(false);
        })
      );
    }
  };

  return (
    <CommonModal onClose={onClose} show={show} showClose={!loading} params={{ className: styles.modal }}>
      <div className={styles.header}>
        <Icon32Group width={64} height={64} className={'mb-3'} />
        <div className={styles.label}>New cohort training:</div>
        <div className={styles.name}>
          {cohortData?.course?.label} — {cohortData.suffix}
        </div>
      </div>
      <div className={styles.content}>
        <div className={styles.title}>
          This is what will be created after confirmation with <span>“{cohortData.suffix}”</span> suffix:
        </div>
        <div className={'mb-4'}>
          <div className={styles.item}>
            <div className={styles.new}>New</div>
            Course
            <Icon32Briefcase className={classNames('ml-auto', styles.course)} />
          </div>
          {!!cohortData.offers?.length && (
            <div className={styles.item}>
              <div className={styles.new}>New</div>
              Offer: {cohortData.offers?.length}
              <Icon32Cart className={classNames('ml-auto', styles.offer)} />
            </div>
          )}
          <div className={styles.item}>
            <div className={styles.new}>New</div>
            Product
            <Icon32Label className={classNames('ml-auto', styles.product)} />
          </div>
        </div>
        <Button
          onClick={createCohort}
          withIcon={false}
          size={'lg'}
          className={classNames('w-100 mb-2', { [styles.loading]: loading })}
          btnProps={{ disabled: loading }}
        >
          {loading ? <Icon32Spinner className={'spinner'} /> : 'Create cohort'}
        </Button>
        <Button
          onClick={goBack}
          size={'lg'}
          className={'w-100'}
          btnStyle={'transparent'}
          type={'arrow-left'}
          iconPosition={'left'}
          btnProps={{ disabled: loading }}
        >
          Go back
        </Button>
      </div>
    </CommonModal>
  );
});
