import { CustomDispatch } from 'redux';

import { getPermissions } from '../../../API/permission-api';
import { permissionActions } from './PermissionActions';

export const permissionActionAsync = {
  getPermission: () => async (dispatch: CustomDispatch) => {
    try {
      dispatch(permissionActions.getPermissions.started());
      const permissions = await getPermissions();
      if (permissions.data) {
        dispatch(permissionActions.getPermissions.done({ result: permissions.data }));
      }
    } catch (error) {
      dispatch(permissionActions.getPermissions.failed({ error }));
    }
  }
};
