import { Tag } from 'react-tag-autocomplete-fix';
import { CustomDispatch } from 'redux';

import { createTag, deleteTag, editTag, getTags } from '../../../../API/tag-api';
import { TagActions } from './TagActions';

export const TagActionAsync = {
  getTags: () => async (dispatch: CustomDispatch) => {
    try {
      dispatch(TagActions.getTags.started());
      const response = await getTags();
      if (response.data) {
        dispatch(TagActions.getTags.done({ result: response.data }));
      }
    } catch (error) {
      dispatch(TagActions.getTags.failed({ error }));
    }
  },
  createTag:
    (title: string, background_color: string, foreground_color: string, callbackCreation?: (tag: Tag) => void) =>
    async (dispatch: CustomDispatch) => {
      try {
        dispatch(TagActions.createTag.started({ title, background_color, foreground_color }));
        const response = await createTag(title, background_color, foreground_color);
        if (response.data) {
          dispatch(
            TagActions.createTag.done({ result: response.data, params: { title, background_color, foreground_color } })
          );
          callbackCreation?.({ id: response.data.id, name: response.data.title });
          return { id: response.data.id, name: response.data.title } as Tag;
        }
      } catch (error) {
        dispatch(TagActions.createTag.failed({ error, params: { title, background_color, foreground_color } }));
      }
    },
  editTag:
    (id: string, title?: string, background_color?: string, foreground_color?: string) =>
    async (dispatch: CustomDispatch) => {
      try {
        dispatch(TagActions.editTag.started({ id, title, background_color, foreground_color }));
        const response = await editTag(id, title, background_color, foreground_color);
        if (response.data) {
          dispatch(
            TagActions.editTag.done({
              result: response.data,
              params: { id, background_color, title, foreground_color }
            })
          );
        }
      } catch (error) {
        dispatch(TagActions.editTag.failed({ error, params: { id, background_color, title, foreground_color } }));
      }
    },
  deleteTag: (id: string) => async (dispatch: CustomDispatch) => {
    try {
      dispatch(TagActions.deleteTag.started({ id }));
      await deleteTag(id);
      dispatch(TagActions.deleteTag.done({ params: { id } }));
    } catch (error) {
      dispatch(TagActions.deleteTag.failed({ error, params: { id } }));
    }
  }
};
