import { NavLink } from 'react-router-dom';

import classNames from 'classnames';
import styles from '../../../../styles/components/navbar.module.scss';

import { commentsPaths } from '../../../modules/comment/routes/CommentRoutes';
import { answersPaths } from '../../../modules/lms/answers/routes/AnswersRoutes';
import { coursePaths } from '../../../modules/lms/courses/routes/CourseRoutes';
import { enrollmentPaths } from '../../../modules/lms/enrollment/routes/EnrollmentRoutes';
import { studentPaths } from '../../../modules/lms/students/routes/StudentRoutes';

export const SchoolHeaderPanel = () => {
  const navigation = [
    { id: 1, title: 'Courses', to: coursePaths.courseList },
    { id: 2, title: 'Students', to: studentPaths.studentList },
    { id: 3, title: 'Enrollments', to: enrollmentPaths.enrollment },
    { id: 4, title: 'Answers', to: answersPaths.answers },
    { id: 5, title: 'Comments', to: commentsPaths.comments }
  ];
  return (
    <div className={classNames(styles.navbar, 'navbar navbar-expand')}>
      <ul className={classNames(styles['navbar-nav'], 'navbar-nav mx-auto')}>
        {navigation.map(nav => (
          <li className={classNames('nav-item', styles.item)} key={nav.id}>
            <NavLink
              className={classNames('nav-link', styles.link)}
              to={nav.to}
              activeClassName={styles.active}
            >
              {nav.title}
            </NavLink>
          </li>
        ))}
      </ul>
    </div>
  );
};
