import React, { FC, ReactElement } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import classNames from 'classnames';
import styles from './settings-card.module.scss';

// import { LoadState } from '../../../../common/loadState';
// import { Loader } from '../../../../components/UI/loader/Loader';
import { SettingsPaths } from '../../routes/SettingsRoutes';
// import { SettingCourseActionAsync } from '../../store/SettingCourseActionAsync';

interface IProps {
  title: string;
  description: string;
  ImageElement: ReactElement;
  link: keyof typeof SettingsPaths;
  helpLink?: string;
}

export const SettingsCard: FC<IProps> = ({ description, ImageElement, title, link, helpLink, children }) => {
  const dispatch = useDispatch();
  const { organizations } = useSelector((state) => state.account.account);
  const { loadState } = useSelector((state) => state.settingCourse);
  const url = helpLink ? 'settings' : link;

  const paymentCallback = (path: string) => {
    window.location.assign(path);
  };

  const onClick = () => {
    if (helpLink) {
      window.open(helpLink);
    }
    // if (link === 'billing' && organizations.length > 0) {
    //   dispatch(settingCourseActionAsync.paymentMethod(window.location.href, paymentCallback));
    // }
  };

  // const billingElementState =
  //   (loadState === LoadState.needLoad || loadState === LoadState.allIsLoaded) && link === 'billing';

  return (
    <>
      <Link
        // className={classNames(styles.link, billingElementState ? styles.billingActive : '')}
        className={classNames(styles.link)}
        to={url}
        onClick={onClick}
      >
        <div className={styles.container}>
          <div className={styles.iconContainer}>{ImageElement}</div>
          <div>
            <div className={styles.title}>{title}</div>
            <div className={styles.description}>{description}</div>
            {children}
            {/*{billingElementState ? <Loader /> : null}*/}
          </div>
        </div>
      </Link>
    </>
  );
};
