import { memo,useMemo } from 'react';
import moment from 'moment';

import classNames from 'classnames';
import canvasStyles from '../canvas-node.module.scss';
import styles from './until-fixed.module.scss';

import { NodeCanvasLoader } from '../common/NodeCanvasLoader';
import { EmptyCanvasNode } from '../EmptyCanvasNode';

interface IProps {
  nodeId: string;
  when?: string;
}

export const CanvasDelayUntilFixed = memo(({ nodeId, when }: IProps) => {
  const isValidDate = useMemo(() => moment(when).isValid(), [when]);
  const initialWhen = useMemo(() => moment().add(5, 'days').format('Do MMMM YYYY, hh:mm'), []);
  const whenValue = useMemo(() => isValidDate && moment(when).format('Do MMMM YYYY, hh:mm'), [isValidDate, when]);
  const whenTime = useMemo(() => isValidDate && moment(when).format('hh:mm A'), [isValidDate, when]);
  const ampm = useMemo(() => isValidDate && moment(when).format('A'), [isValidDate, when]);

  return (
    <>
      {isValidDate ? (
        <>
          <div className={classNames(styles.container, canvasStyles.bgContainer)}>
            <div className={styles.title}>Until fixed date and time</div>
            <span className={styles.date}>
              <NodeCanvasLoader nodeId={nodeId}>
                {isValidDate ? (when ? whenValue : initialWhen) : whenTime}
              </NodeCanvasLoader>
              {isValidDate && <span className={styles.zone}> {ampm}</span>}
            </span>
          </div>
        </>
      ) : (
        <EmptyCanvasNode text={'Double click to open the options and manage automations'} />
      )}
    </>
  );
});
