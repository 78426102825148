import { createContext, useContext } from 'react';

export type AuthContent = {
  onForgot: () => void;
  onAuth: () => void;
};

export const AuthContext = createContext<AuthContent>({
  onForgot: () => {},
  onAuth: () => {}
});
export const useAuthContext = () => useContext(AuthContext);
