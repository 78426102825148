import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import classNames from 'classnames';
import canvasStyles from '../canvas-node.module.scss';
import styles from './canvas-list.module.scss';

import { _id, getUUID } from '../../../../../helpers';
import { useNodePayload } from '../../../../../hooks/use-node-payload';
import { PaletteAutomationElementTypes } from '../../../../../interfaces';
import { LoadState } from '../../../../../store';
import { ListItem } from '../../../../crm/contact/common/contact-card-lists/components/list-item/ListItem';
import { ListsActionAsync } from '../../../../crm/lists/store/ListsActionAsync';
import { NodeCanvasLoader } from '../common/NodeCanvasLoader';
import { EmptyCanvasNode } from '../EmptyCanvasNode';

interface IProps {
  nodeId: string;
  list_id?: string[];
}

export const CanvasNodeList = ({ nodeId, list_id }: IProps) => {
  const { lists, loadState } = useSelector((state) => state.lists);
  const dispatch = useDispatch();

  useEffect(() => {
    if (lists.length === 0 && loadState !== LoadState.allIsLoaded) {
      dispatch(ListsActionAsync.getLists());
    }
  }, [dispatch, lists, loadState]);
  const node = useNodePayload<PaletteAutomationElementTypes.ACTION_LIST>(nodeId);
  const currentLists = lists.filter((x) => node?.payload.list_id?.includes(x.id) || list_id?.includes(x.id));

  return (
    <>
      {currentLists.length === 0 ? (
        <EmptyCanvasNode text={'Double click to open the options and manage ‘Lists’ action'} />
      ) : (
        <NodeCanvasLoader nodeId={nodeId}>
          <span
            className={classNames(canvasStyles.bgContainer, styles.listWrapper)}
            data-node-id={nodeId}
            id={_id(nodeId)}
          >
            {currentLists.map((x) => (
              <ListItem id={x.id} name={x.title} isSelected={true} isDetach={false} key={getUUID()} />
            ))}
          </span>
        </NodeCanvasLoader>
      )}
    </>
  );
};
