import { useCallback, useState } from 'react';
import { useCountdown } from 'usehooks-ts';

import { ResetInput } from './ResetInput';
import { ResetSuccess } from './ResetSuccess';

export const ResetComponent = () => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [count, { startCountdown, stopCountdown, resetCountdown }] = useCountdown({
    countStart: 30,
    intervalMs: 1000
  });

  const onSuccessRecoverRequest = useCallback(() => {
    startCountdown();
    setSuccess(true);
  }, [startCountdown]);

  return (
    <>
      {success ? (
        <ResetSuccess count={count} resetCountdown={resetCountdown} stopCountdown={stopCountdown} />
      ) : (
        <ResetInput loading={loading} setLoading={setLoading} setSuccess={onSuccessRecoverRequest} />
      )}
    </>
  );
};
