import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useEffectOnce } from 'usehooks-ts';

import { Loader } from '../../../../components/UI';
import { useLoading } from '../../../../hooks/use-loading';
import { useQueryParams } from '../../../../hooks/use-query-params';
import { IPaginationResponse, IRun } from '../../../../interfaces';
import { ContactListActionAsync } from '../../../crm/contact/store/ContactListActionAsync';
import { FieldActionAsync } from '../../../crm/field';
import { automationActionAsync } from '../../store/AutomationActionAsync';
import { RunsList } from '../runs-list/RunsList';

export const RunsWrapper = () => {
  const dispatch = useDispatch();
  const { loading, startLoading, stopLoading } = useLoading();
  const params = useQueryParams();
  const [data, setData] = useState<IPaginationResponse<IRun[]>>();

  const getAutomationRuns = useCallback(() => {
    dispatch(
      automationActionAsync.getListAutomationRuns(
        {
          page: params.page,
          page_size: params.page_size
        },
        undefined,
        (data) => {
          if (data) {
            setData(data);
          }
          stopLoading();
        }
      )
    );
  }, [dispatch, params.page, params.page_size, stopLoading]);

  const getInitialAutomationRuns = () => {
    startLoading();
    getAutomationRuns();
  };

  useEffect(() => {
    if (
      data &&
      (Number(params.page) !== Number(data?.meta.page) || Number(params.page_size) !== Number(data?.meta.page_size))
    ) {
      getAutomationRuns();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.meta.page, data?.meta.page_size, getAutomationRuns, params.page, params.page_size]);

  useEffectOnce(() => {
    getInitialAutomationRuns();
  });

  useEffect(() => {
    dispatch(FieldActionAsync.getFields());
    dispatch(ContactListActionAsync.getContactsConfig());
  }, [dispatch]);

  return <>{loading ? <Loader /> : data && <RunsList data={data} />}</>;
};
