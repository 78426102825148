import styles from './lost-connection.module.scss';

import everactNS from '../../../../../../../documentation/everact-nameservers.json';
import { FollowingNameServers } from '../../../connect-new-domain/components/complete-steps/ns-servers/FollowingNameServers';

export const LostConnection = () => {
  return (
    <>
      <div className={styles.title}> Something went wrong with your custom domain</div>
      <div>
        <FollowingNameServers
          nsServers={everactNS}
          title={'Open the domain control panel on the side of the service provider and check nameservers:'}
          className={styles.container}
        />
      </div>
      <div className={styles.footerText}>Contact your domain service provider if the error cannot be resolved.</div>
    </>
  );
};
