import { ITag } from '../modules/crm/tag/store/TagState';
import { baseFetch } from './BaseFetch';

export const getTags = () => {
  return baseFetch<{}, ITag[]>('/tags', {}, 'GET', true);
};

export const createTag = (title: string, background_color: string, foreground_color: string) => {
  return baseFetch<{ title: string; background_color: string; foreground_color: string }, ITag>(
    '/tags',
    { title, background_color, foreground_color },
    'POST',
    true
  );
};

export const editTag = (id: string, title?: string, background_color?: string, foreground_color?: string) => {
  return baseFetch<{ title?: string; background_color?: string; foreground_color?: string }, ITag>(
    `/tags/${id}`,
    { title, background_color, foreground_color },
    'PUT',
    true
  );
};

export const deleteTag = (id: string) => {
  return baseFetch<{}, ITag>(`/tags/${id}`, {}, 'DELETE', true);
};
