import { memo, useMemo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';

import styles from './lesson.module.scss';
import { Icon32Gear } from '../../../../../components/UI/icons';

import { BlockType } from '../../../../../interfaces';
import { StudentCabinetPaths } from '../../../../student-cabinet/routes/StudentCabinetRoutes';
import { useLessonNode } from '../../../hooks/use-lesson-node';
import { coursePaths } from '../../routes/CourseRoutes';
import { CreateSection } from '../common/blank/CreateSection';
import { CustomCode } from '../common/custom-code/CustomCode';
import { SettingsBlock } from '../common/settings-block/SettingsBlock';
import { LessonComment } from './lesson-comment/LessonComment';

interface IProps {
  initialEnable: boolean;
  onCreateBlock: (value: BlockType) => void;
}

export const LessonFooter = memo(({ initialEnable, onCreateBlock }: IProps) => {
  const path = useRouteMatch<{ id: string; nodeId: string }>([
    coursePaths.courseLessons(':id', ':nodeId'),
    StudentCabinetPaths.courseStudentLessons(':id', ':nodeId')
  ]);
  const stepId = useMemo(() => path?.params.nodeId, [path?.params.nodeId]);

  const { courses } = useSelector((state) => state.courses);
  const course = courses.find((course) => course.id === path?.params.id);

  const { control } = useFormContext();
  const { nodeLesson } = useLessonNode(course, stepId);
  const watchSettingsData = useWatch({ control, name: 'customCode' });
  return (
    <>
      <CreateSection onCreateBlock={onCreateBlock} />
      {nodeLesson && <LessonComment status={nodeLesson?.show_comments} lessonId={nodeLesson.id} />}

      <div className={styles.settings}>
        <h3 className={styles.blockTitle}>
          <Icon32Gear width={32} height={32} className={styles.icon} />
          Lecture settings
        </h3>

        <SettingsBlock
          title={'Use custom code'}
          controlName={'customCode.enabled'}
          classNameChildren={styles.customCodeContainer}
          defaultValue={initialEnable}
          enabled={watchSettingsData?.enabled || false}
        >
          <CustomCode value={watchSettingsData?.code} />
        </SettingsBlock>
      </div>
    </>
  );
});
