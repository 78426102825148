import React, { ReactElement, useCallback, useState } from 'react';

import classNames from 'classnames';
import styles from './tab.module.scss';

import TabTitle from './TabTitle';

type Props = {
  children: ReactElement[];
  containerClassName?: string;
  titleContainerClassName?: string;
  titleBtnClassName?: string;
  selectedTab: number;
  setSelectedTab: (data: number) => void;
};

const Tabs: React.FC<Props> = ({
  selectedTab,
  children,
  containerClassName,
  titleBtnClassName,
  titleContainerClassName,
  setSelectedTab
}) => {
  return (
    <>
      <div className={classNames(styles.tabsContainer, containerClassName)}>
        {children.map((item, index) => (
          <TabTitle
            key={index}
            title={item.props.title}
            titleBtnClassName={titleBtnClassName}
            titleContainerClassName={titleContainerClassName}
            index={index}
            activeTab={selectedTab === index}
            onClick={setSelectedTab}
          />
        ))}
      </div>
      {children[selectedTab]}
    </>
  );
};

export default Tabs;
