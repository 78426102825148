import { memo } from 'react';
import { Color, ColorResult, CustomPicker } from 'react-color';
import { Alpha, Hue, Saturation } from 'react-color/lib/components/common';
import { ExportedColorProps, InjectedColorProps } from 'react-color/lib/components/common/ColorWrap';
// @ts-ignore
import { toState } from 'react-color/lib/helpers/color';
import { useFormContext } from 'react-hook-form';

import styles from './customPicker.module.scss';

import { InputColor } from './inputColor/InputColor';

const SaturationChosePicker = () => <div className={styles.saturationCircle} />;
const ColorChosePicker = () => (
  <div className={styles.colorCircleWrapper}>
    <div className={styles.colorCircle} />
  </div>
);

interface IProps {
  controlName: string;
  color: Color;

  setColor(val: ColorResult): void;

  setRawData?(data: InjectedColorProps): void;
}

const ColorPicker = memo((props: InjectedColorProps & IProps) => {
  const { setValue, register } = useFormContext();

  const handleChange = (data: ColorResult) => {
    props.setColor(data);
    props.setRawData?.(props);
    if (props.rgb) {
      const { rgb } = props;
      register(props.controlName);
      setValue(props.controlName, `rgba(${rgb.r},${rgb.g},${rgb.b},${rgb.a})`, { shouldDirty: true });
    }
  };

  const selectColor = (color?: any) => {
    console.log('%c⇒ color', 'color: #89DDF7', toState(color));

    let rgb = toState(color).rgb;
    let rgba = `rgba(${rgb.r},${rgb.g},${rgb.b},${rgb.a})`;

    if (rgba) {
      register(props.controlName);
      setValue(props.controlName, rgba, { shouldDirty: true });
    }
  };

  return (
    <>
      <div className={styles.wrapper}>
        <Saturation {...props} onChange={handleChange} pointer={SaturationChosePicker} />
      </div>
      <div className={styles.colorPicker}>
        <Hue {...props} onChange={handleChange} pointer={ColorChosePicker} />
      </div>
      <div className={styles.colorPickerAlpha}>
        <Alpha {...props} pointer={ColorChosePicker} onChange={handleChange} />
      </div>
      <div className={'mt-3'}>
        <InputColor label={'HEX'} selectColor={selectColor} value={props.hex} />
      </div>
      <div className={'d-flex mt-2 justify-content-between'}>
        <InputColor label={'R'} selectColor={selectColor} value={props.rgb?.r} className={styles.rgbInput} />
        <InputColor label={'G'} selectColor={selectColor} value={props.rgb?.g} className={styles.rgbInput} />
        <InputColor label={'B'} selectColor={selectColor} value={props.rgb?.b} className={styles.rgbInput} />
      </div>
    </>
  );
});

const CustomColorPicker = CustomPicker<ExportedColorProps & IProps>(ColorPicker);
export { CustomColorPicker };
