import { memo, useCallback, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { map } from 'lodash';

import { IProduct } from '../../../products/store/ProductsState';
import { OfferUnsavedChanges } from '../../common/unsaved/OfferUnsavedChanges';
import { IOffer } from '../../store/OffersState';
import { InputLabel } from '../common/input-label/InputLabel';
import { ProductSearch } from '../common/search/ProductSearch';
import UpdateChange from '../common/update-change/UpdateChange';
import { useCreateOffer } from '../use-create-offer';
import { ProductArea } from './components/ProductArea';
import { ProductInput } from './components/ProductInput';
import { ProductTitle } from './components/ProductTitle';
import { productSchema } from './productShema';

type ProductOffer = Pick<IOffer, 'title' | 'description' | 'setting_call_to_action' | 'id' | 'products'>;

interface IProps extends ProductOffer {
  getOffer: () => void;
}

export const Products = memo(({ id, title, description, setting_call_to_action, products, getOffer }: IProps) => {
  const [selectProducts, setSelectProducts] = useState<IProduct[]>(products || []);
  const methods = useForm<Omit<ProductOffer, 'products'> & { products: string[] }>({
    defaultValues: {
      setting_call_to_action: setting_call_to_action || 'Proceed to payment',
      description: description,
      title: title,
      products: map(products, 'id')
    },
    shouldUnregister: true,
    resolver: yupResolver(productSchema)
  });

  const onReset = useCallback(() => {
    methods.reset({ title, description, setting_call_to_action, products: map<any, string>(products, 'id') });
  }, [description, methods, products, setting_call_to_action, title]);

  const onCancelOptional = useCallback(() => {
    setSelectProducts(products);
  }, [products]);

  const { onCancel, onClose, onSave, showUnsavedModal, loadState } = useCreateOffer(
    id,
    getOffer,
    onReset,
    methods.formState.isDirty,
    onCancelOptional
  );

  return (
    <FormProvider {...methods}>
      <div className={'bg-white p-4 rounded'}>
        <ProductTitle />

        <InputLabel label={'Add products to your offer'} classContainer={'mt-4'}>
          <ProductSearch
            name={'products'}
            errorMessage={(methods.formState.errors.products as any)?.message}
            isError={!!methods.formState.errors.products}
            productsData={products}
            selectProducts={selectProducts}
            setSelectProducts={setSelectProducts}
          />
        </InputLabel>

        <ProductArea />

        <ProductInput />
      </div>
      <UpdateChange
        showUpdate={methods.formState.isDirty}
        loadState={loadState}
        methods={methods}
        onCancel={onCancel}
        onSave={onSave}
      />

      <OfferUnsavedChanges
        onClose={onClose}
        onCancel={onCancel}
        onSave={onSave}
        show={showUnsavedModal && methods.formState.isDirty}
        methods={methods}
      />
    </FormProvider>
  );
});
