import { ReactNode } from 'react';

import styles from './price-payment-item.module.scss';

interface IProps {
  logoSVG: ReactNode;
  title: string;
  statusConnect: 'connected' | 'notConnected';
}

export const PricePaymentItem = ({ logoSVG, statusConnect, title }: IProps) => {
  return (
    <div className={styles.container}>
      {logoSVG}
      <div>
        <div className={styles.title}>{title}</div>
        {statusConnect === 'connected' ? (
          <span className={styles.connected}>Connected</span>
        ) : (
          <div className={styles.notConnected}>Connect account</div>
        )}
      </div>
    </div>
  );
};
