import { IRoutes } from '../../../interfaces';
import { BuilderHeaderPanel } from '../../../layout/header/funnelHeaderPanel/BuilderHeaderPanel';
import { AutomationBuilder } from '../automation-builder/AutomationBuilder';
import { AutomationList } from '../automation-list/AutomationList';
import { AutomationsHeader } from '../common/automations-header/AutomationsHeader';
import { RunsWrapper } from '../runs/runs-wrapper/RunsWrapper';

export const automationPaths = {
  automationList: '/automations',
  automationListId: (id = ':id') => `/automations/${id}`,
  messages: 'messages',
  runs: '/runs'
};

export const AutomationRoutes: IRoutes[] = [
  {
    path: automationPaths.automationList,
    exact: true,
    header: () => <AutomationsHeader />,
    main: () => (
      <div className={'content'}>
        {/* {process.env.REACT_APP_API_BASE_URL === 'https://api.dev.wisent.work' ? <AutomationList /> : <Automations />} */}
        <AutomationList />
      </div>
    )
  },
  {
    path: automationPaths.automationListId(),
    header: () => <BuilderHeaderPanel route={automationPaths.automationListId()} name={'automation'} />,
    main: () => (
      <div className={'content p-0'}>
        <AutomationBuilder />
      </div>
    )
  },
  {
    path: automationPaths.runs,
    header: () => <AutomationsHeader />,
    main: () => (
      <div className={'content'}>
        <RunsWrapper />
      </div>
    )
  }
];
