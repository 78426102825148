import { memo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import styles from './unenroll-modal.module.scss';
import { Icon32Warning, IconEye, IconUserDeclined } from '../../../../../components/UI/icons';

import { Button, CheckButton, ModalActionComponent } from '../../../../../components/UI';

interface IProps {
  studentName: string | null;
  courseTitle: string | undefined;
  courseId: string | undefined;

  isShow: boolean;

  onClose(): void;

  onUnenroll(courseId: string | undefined, sendNotification: boolean): void;
}

export const UnenrollModal = memo(({ studentName, courseTitle, courseId, isShow, onUnenroll, onClose }: IProps) => {
  const methods = useForm<{ sendNotification: boolean }>({
    defaultValues: { sendNotification: true }
  });

  const onSubmit = (data: { sendNotification: boolean }) => {
    onUnenroll(courseId, data.sendNotification);
  };

  return (
    <ModalActionComponent
      iconType={'custom'}
      show={isShow}
      title={
        <>
          <span>{studentName || 'No name'}</span> will be unenrolled
        </>
      }
      onCloseModal={onClose}
      iconStyles={styles.icon}
      icon={<Icon32Warning />}
    >
      <div className={styles.description}>This student will no longer have access to the “{courseTitle}” course.</div>
      <FormProvider {...methods}>
        <CheckButton
          name={'sendNotification'}
          type={'checkbox'}
          value={undefined}
          params={{
            label: (
              <>
                Send email notification <IconEye className={styles.checkboxIcon} />
              </>
            ),
            className: 'checkbox-button ml-6 mb-3'
          }}
        />
      </FormProvider>
      <div className={styles.buttons}>
        <Button onClick={onClose} btnStyle={'transparent'} withIcon={false} className={'mr-2'}>
          Cancel
        </Button>
        <Button
          onClick={methods.handleSubmit(onSubmit)}
          withIcon={true}
          customIcon={<IconUserDeclined />}
          btnStyle={'warning'}
        >
          Unenroll
        </Button>
      </div>
    </ModalActionComponent>
  );
});
