import { memo, useCallback } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';

import { OfferUnsavedChanges } from '../../common/unsaved/OfferUnsavedChanges';
import { IOffer } from '../../store/OffersState';
import CommonToggle from '../common/toggle/CommonToggle';
import UpdateChange from '../common/update-change/UpdateChange';
import { ValidationData } from '../common/ValidationData';
import { useCreateOffer } from '../use-create-offer';
import { ShippingContent } from './ShippingContent';

type ShippingOffer = Pick<IOffer, 'setting_enable_shipping' | 'id'>;

interface IProps extends ShippingOffer {
  getOffer: () => void;
}

export const Shipping = memo(({ getOffer, setting_enable_shipping, id }: IProps) => {
  const methods = useForm<ShippingOffer>({
    defaultValues: {
      setting_enable_shipping
    },
    shouldUnregister: true
  });

  const onReset = useCallback(() => {
    methods.reset({ setting_enable_shipping });
  }, [methods, setting_enable_shipping]);

  const { onCancel, onClose, onSave, showUnsavedModal, loadState } = useCreateOffer(id, getOffer, onReset);

  const watchShipping = useWatch({ control: methods.control, name: 'setting_enable_shipping' });

  return (
    <FormProvider {...methods}>
      <CommonToggle
        isActive={!!watchShipping}
        name={'setting_enable_shipping'}
        title={'Display shipping address fields'}
        text={'Allowing this option shipping fields will be displayed in the checkout form'}
      />
      {watchShipping && <ShippingContent />}
      <UpdateChange
        showUpdate={methods.formState.isDirty}
        loadState={loadState}
        methods={methods}
        onCancel={onCancel}
        onSave={onSave}
      />
      <OfferUnsavedChanges
        onClose={onClose}
        onCancel={onCancel}
        onSave={onSave}
        show={showUnsavedModal}
        methods={methods}
      />
    </FormProvider>
  );
});
