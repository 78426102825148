import { AxiosResponse } from 'axios';
import actionCreatorFactory from 'typescript-fsa';

import {
  ICoupon,
  IPaginationResponse,
  IPayment,
  IReason,
  ISubscription,
  Plans,
  SubscriptionStatusResponseFail
} from '../../../../interfaces';

export const actionCreator = actionCreatorFactory();

export const billingActions = {
  getSubscription: actionCreator.async<
    void,
    ISubscription | null,
    AxiosResponse<{ status: SubscriptionStatusResponseFail; data: unknown | null }>
  >('GET_SUBSCRIPTION'),
  updateSubscription: actionCreator.async<{ plan: Plans }, ISubscription, Error>('UPDATE_SUBSCRIPTION'),
  createSubscription: actionCreator.async<{ plan: Plans }, ISubscription, Error>('CREATE_SUBSCRIPTION'),
  deleteSubscription: actionCreator.async<{ atPeriodEnd: boolean }, ISubscription, Error>('DELETE_SUBSCRIPTION'),
  renewSubscription: actionCreator.async<{ atPeriodEnd: boolean }, ISubscription, Error>('RENEW_SUBSCRIPTION'),
  subscriptionReason: actionCreator.async<{ reason: IReason }, string, Error>('SUBSCRIPTION_REASON'),
  applyDiscount: actionCreator.async<{ coupon: string }, ISubscription, Error>('APPLY_SUBSCRIPTION_DISCOUNT'),
  getCoupons: actionCreator.async<void, ICoupon, Error>('GET_COUPONS'),
  getTransactions: actionCreator.async<void, IPaginationResponse<IPayment[]>, Error>('GET_TRANSACTIONS')
};
