import { reducerWithInitialState } from 'typescript-fsa-reducers';

import { LoadState, newState } from '../../../../store';
import { TagActions } from './TagActions';
import { ITagState, TagInitialState } from './TagState';

export const tagReducer = reducerWithInitialState<ITagState>(TagInitialState)
  .case(TagActions.getTags.started, (state) => ({ ...state, loadState: LoadState.firstLoad, error: null }))
  .case(TagActions.getTags.done, (state, { result }) => {
    return newState(state, {
      tags: result,
      loadState: LoadState.allIsLoaded,
      error: null
    });
  })
  .case(TagActions.getTags.failed, (state, { error }) => ({
    ...state,
    loadState: LoadState.error,
    error
  }))

  .case(TagActions.createTag.started, (state) => ({ ...state, loadState: LoadState.firstLoad, error: null }))
  .case(TagActions.createTag.done, (state, { result }) => {
    return newState(state, {
      tags: [...state.tags, result],
      loadState: LoadState.allIsLoaded,
      error: null
    });
  })
  .case(TagActions.createTag.failed, (state, { error }) => ({
    ...state,
    loadState: LoadState.error,
    error
  }))

  .case(TagActions.editTag.started, (state) => ({ ...state, loadState: LoadState.firstLoad, error: null }))
  .case(TagActions.editTag.done, (state, { result }) => {
    const updatedTags = state.tags.map((x) => (x.id === result.id ? result : x));
    return newState(state, {
      tags: updatedTags,
      loadState: LoadState.allIsLoaded,
      error: null
    });
  })
  .case(TagActions.editTag.failed, (state, { error }) => ({
    ...state,
    loadState: LoadState.error,
    error
  }))

  .case(TagActions.deleteTag.started, (state) => ({ ...state, loadState: LoadState.firstLoad, error: null }))
  .case(TagActions.deleteTag.done, (state, { params }) => {
    const removingIndexElement = state.tags.filter((x) => x.id !== params.id);
    return newState(state, {
      tags: removingIndexElement,
      loadState: LoadState.allIsLoaded,
      error: null
    });
  })
  .case(TagActions.deleteTag.failed, (state, { error }) => ({
    ...state,
    loadState: LoadState.error,
    error
  }));
