import Quill from 'quill';

import './custom-video.scss';

const QuillVideo = Quill.import('formats/video');
const BlockEmbed = Quill.import('blots/block/embed');

const VIDEO_ATTRIBUTES = ['height', 'width'];

// provides a custom div wrapper around the default Video blot
class CustomVideo extends BlockEmbed {
  static create(value: string) {
    const iframeNode = QuillVideo.create(value);
    const node = super.create();
    node.appendChild(iframeNode);
    return node;
  }

  static formats(domNode: HTMLElement) {
    const iframe = domNode.getElementsByTagName('iframe')[0];
    return VIDEO_ATTRIBUTES.reduce(function (formats, attribute) {
      if (iframe.hasAttribute(attribute)) {
        //@ts-ignore
        formats[attribute] = iframe.getAttribute(attribute);
      }
      return formats;
    }, {});
  }

  static value(domNode: HTMLElement) {
    return domNode.getElementsByTagName('iframe')[0].getAttribute('src');
  }

  format(name: string, value: string) {
    if (VIDEO_ATTRIBUTES.indexOf(name) > -1) {
      if (value) {
        this.domNode.setAttribute(name, value);
      } else {
        this.domNode.removeAttribute(name);
      }
    } else {
      super.format(name, value);
    }
  }
}

CustomVideo.blotName = 'video';
CustomVideo.className = 'ql-video-wrapper';
CustomVideo.tagName = 'DIV';

export { CustomVideo };
