import { createSelector } from 'reselect';
import { map } from 'lodash';

import { IAppState } from '../../../../store';
import { FieldScope } from '../../field';

export const OfferSelector = () =>
  createSelector(
    (_: IAppState, props: { offerId?: string }) => props,
    (state: IAppState) => state.offers.offers,
    (state: IAppState) => state.offers.loadState,
    (state: IAppState) => state.offers.error,
    (props, offers, loadState, error) => {
      return {
        offer: offers.find((x) => x.id === props.offerId),
        loadState,
        error
      };
    }
  );

export const ProductOfferDataSearch = () =>
  createSelector(
    (_: IAppState, scopes: FieldScope[]) => scopes,
    (state: IAppState) => state.fields.fields,
    (state: IAppState) => state.offers.offerProducts,
    (state: IAppState) => state.offers.loadState,
    (state: IAppState) => state.offers.error,
    (scopes, fields, offerProducts, loadState, error) => {
      const filteredField = fields.filter(
        (field) => scopes.includes(field.scope) && (field.entity === 'course' || field.entity === 'product')
      );

      const filterProduct = map(offerProducts, (product) => {
        const field = filteredField.map((x) => {
          return { [x.id]: product.fields[x.id] };
        });
        const filter = field.filter((x) => Object.values(x)[0] !== undefined).flat(1);
        return { ...product, fields: filter };
      });

      return { productsFiltered: filterProduct, fields, loadState, error };
    }
  );
