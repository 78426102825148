import { memo } from 'react';
import { useSelector } from 'react-redux';

import classNames from 'classnames';
import itemStyles from '../../../common/sidebar-common.module.scss';
import styles from './blank.module.scss';
import {
  Icon16Audio,
  Icon16Award,
  Icon16Code,
  Icon16File,
  Icon16Presentation,
  Icon16Survey,
  Icon16Text,
  Icon16Video
} from '../../../../../../components/UI/icons';

import { Button, CustomTooltip } from '../../../../../../components/UI';
import { ObjectEntries } from '../../../../../../helpers';
import { BlockType } from '../../../../../../interfaces';
import { useLessonNode } from '../../../../hooks/use-lesson-node';
import { useCourseContext } from '../../../common/sidebar/CourseContext';

interface ILessonItem {
  title: string;
  icon: JSX.Element;
  css: string;
  disabled: boolean;
}

export const lessonItems = {
  [BlockType.VIDEO]: { title: 'Video', icon: <Icon16Video />, css: itemStyles.videoContainer, disabled: false },
  [BlockType.AUDIO]: {
    title: 'Audio',
    icon: <Icon16Audio />,
    css: itemStyles.audioContainerCreateSection,
    disabled: false
  },
  [BlockType.TEXT]: {
    title: 'Text',
    icon: <Icon16Text />,
    css: itemStyles.textContainerCreateSection,
    disabled: false
  },
  [BlockType.QUIZ]: {
    title: 'Quiz',
    icon: <Icon16Award />,
    css: itemStyles.quizContainer,
    disabled: false
  },

  [BlockType.EMBED]: {
    title: 'Embed',
    icon: <Icon16Code />,
    css: itemStyles.embedContainerCreateSection,
    disabled: false
  },
  [BlockType.BLOCK_ATTACHMENTS]: {
    title: 'Attachments',
    icon: <Icon16File />,
    css: itemStyles.attachmentContainer,
    disabled: false
  },
  [BlockType.PRESENTATION]: {
    title: 'Presentation',
    icon: <Icon16Presentation />,
    css: itemStyles.presentationContainer,
    disabled: true
  },
  [BlockType.SURVEY]: { title: 'Survey', icon: <Icon16Survey />, css: itemStyles.surveyContainer, disabled: true }
  // [BlockType.PLAYLIST]: {
  //   title: 'Playlist',
  //   icon: <Icon16Playlist />,
  //   css: itemStyles.playlistContainer,
  //   disabled: true
  // }
};

interface IProps {
  onCreateBlock: (value: BlockType) => void;
}

export const CreateSection = memo(({ onCreateBlock }: IProps) => {
  const { nodeId, courseId } = useCourseContext();
  const course = useSelector((state) => state.courses.courses.find((course) => course.id === courseId));
  const { nodeLesson } = useLessonNode(course!, nodeId);

  const getButton = (index: number, key: BlockType, value: ILessonItem) => (
    <Button
      key={index}
      onClick={() => onCreateBlock(key)}
      className={classNames(value.css, styles.item, { [styles.disabled]: value.disabled })}
      withIcon={false}
      btnStyle={'secondary'}
    >
      {value.icon}
      {value.title}
    </Button>
  );

  return (
    <div
      className={classNames('d-flex align-items-center flex-column mt-3 mb-3 pb-3', styles.divider, {
        [styles.draft]: nodeLesson?.state === 'draft'
      })}
    >
      <div className={classNames(styles.create, { [styles.draft]: nodeLesson?.state === 'draft' })}>
        <span className={classNames(styles.createSpan, { [styles.draft]: nodeLesson?.state === 'draft' })}>
          Create section
        </span>
      </div>
      <div className={classNames(styles.section)}>
        {ObjectEntries(lessonItems).map(([key, value], index) =>
          value.disabled ? (
            <CustomTooltip key={index} direction={'top'}>
              {getButton(index, key, value)}
            </CustomTooltip>
          ) : (
            getButton(index, key, value)
          )
        )}
      </div>
    </div>
  );
});
