import { reducerWithInitialState } from 'typescript-fsa-reducers';

import { commonErrorHandler, commonFirstLoadHandler } from '../../../helpers/common-reducer-handlers';
import { LoadState, newState } from '../../../store';
import { StudentCabinetActions } from './StudentCabinetActions';
import { IStudentCabinetState, StudentCabinetInitialState } from './StudentCabinetState';

export const studentCabinetReducer = reducerWithInitialState<IStudentCabinetState>(StudentCabinetInitialState)
  .case(StudentCabinetActions.getStudentCabinetCourses.started, commonFirstLoadHandler)
  .case(StudentCabinetActions.getStudentCabinetCourses.done, (state, { result }) => {
    return newState(state, {
      studentCourses: {
        ...state.studentCourses,
        ...result
      },
      error: null,
      loadState: LoadState.allIsLoaded
    });
  })
  .case(StudentCabinetActions.getStudentCabinetCourses.failed, commonErrorHandler)

  .case(StudentCabinetActions.getStudentCabinetCourseHierarchy.started, commonFirstLoadHandler)
  .case(StudentCabinetActions.getStudentCabinetCourseHierarchy.done, (state, { result, params }) => {
    const updatedCourse = state.studentCourses.courses.map((course) => {
      if (course.id === params.course_id) {
        course.children = result;
      }
      return course;
    });

    return newState(state, {
      studentCourses: { ...state.studentCourses, courses: updatedCourse },
      loadState: LoadState.allIsLoaded,
      error: null
    });
  })
  .case(StudentCabinetActions.getStudentCabinetCourseHierarchy.failed, commonErrorHandler)
  .case(StudentCabinetActions.getListStepBlocks.started, commonFirstLoadHandler)
  .case(StudentCabinetActions.getListStepBlocks.done, (state, { result }) => {
    return newState(state, {
      studentCourses: { ...state.studentCourses, payload: result },
      error: null,
      loadState: LoadState.allIsLoaded
    });
  })
  .case(StudentCabinetActions.getListStepBlocks.failed, (state, result) => {
    return newState(state, {
      ...state,
      error: result.error,
      loadState: LoadState.allIsLoaded
    });
  })

  .case(StudentCabinetActions.getStatusesCourse.started, commonFirstLoadHandler)
  .case(StudentCabinetActions.getStatusesCourse.done, (state, { result }) => {
    return newState(state, {
      error: null,
      loadState: LoadState.allIsLoaded,
      studentCourses: state.studentCourses,
      statusCourse: result
    });
  })
  .case(StudentCabinetActions.getStatusesCourse.failed, commonErrorHandler)

  .case(StudentCabinetActions.getLesson.started, commonFirstLoadHandler)
  .case(StudentCabinetActions.getLesson.done, (state, { result }) => {
    if (Array.isArray(result)) {
      return newState(state, {
        studentCourses: { ...state.studentCourses, lectures: result },
        error: null,
        loadState: LoadState.allIsLoaded
      });
    }
    return state;
  })
  .case(StudentCabinetActions.getLesson.failed, commonErrorHandler)
  .case(StudentCabinetActions.getStudentCabinetSettings.started, commonFirstLoadHandler)
  .case(StudentCabinetActions.getStudentCabinetSettings.done, (state, { result }) => {
    return newState(state, {
      ...state,
      error: null,
      loadCabinetSettingsState: LoadState.allIsLoaded,
      cabinetSettings: result
    });
  })
  .case(StudentCabinetActions.getStudentCabinetSettings.failed, (state, { error }) => {
    return newState(state, {
      ...state,
      loadCabinetSettingsState: LoadState.error,
      error,
      cabinetSettings: null
    });
  });
