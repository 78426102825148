import { useLayoutEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import styles from './waiting-screen.module.scss';
import WaitingScreenImage from '../../../../../assets/images/waiting-screen.png';
import { Icon32Spinner } from '../../../../components/UI/icons';

import { Button } from '../../../../components/UI';
import { CommonAuth } from '../../../student-cabinet/auth/common-auth/CommonAuth';
import { StudentCabinetPaths } from '../../../student-cabinet/routes/StudentCabinetRoutes';

export const WaitingScreen = () => {
  const history = useHistory<{ course_id: string }>();
  const dispatch = useDispatch();

  useLayoutEffect(() => {
    setTimeout(() => {
      history.push(StudentCabinetPaths.courseStudentLessons(history.location.state.course_id));
    }, 3000);
  }, [dispatch, history]);

  return (
    <CommonAuth>
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <img className={styles.img} src={WaitingScreenImage} alt={'waiting screen'} />
          <div className={styles.title}>Wait a little while...</div>
          <div className={styles.description}>
            Now we are creating a personal account for you to have access to the course
          </div>
          <Button onClick={() => {}} withIcon={false} btnStyle={'secondary'} className={styles.btn}>
            <Icon32Spinner className={'spinner'} />
          </Button>
        </div>
      </div>
    </CommonAuth>
  );
};
