import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import {
  DataQueryPaginationParams,
  DataQuerySortParams,
  SortType,
  ViewType,
  ViewTypeParam
} from '../interfaces/tableControl';

export const DEFAULT_PAGE = '1';
export const DEFAULT_ROWS_PER_PAGE = '12';
export const DEFAULT_SORT_TYPE: SortType = 'asc';
export const DEFAULT_VIEW_TYPE: ViewType = 'list';

const supportedParams = ['page', 'page_size', 'order', 'sort', 'viewType'];

export const getQueryParams = (
  urlParams: URLSearchParams,
  additionalParams: string[] = []
): DataQueryPaginationParams & DataQuerySortParams & ViewTypeParam => {
  const obj: any = Array.from(urlParams)
    .filter((param) => [...supportedParams, ...additionalParams].includes(param[0]))
    .map((param) => {
      return {
        [param[0]]: param[1]
      };
    })
    .reduce((acc, cur) => ({ ...acc, ...cur }), {});

  const keys = Object.keys(obj);

  if (!keys.includes('page')) {
    obj.page = DEFAULT_PAGE;
  }

  if (!keys.includes('page_size')) {
    obj.page_size = DEFAULT_ROWS_PER_PAGE;
  }

  if (!keys.includes('viewType')) {
    obj.viewType = DEFAULT_VIEW_TYPE;
  }

  return obj as DataQueryPaginationParams & DataQuerySortParams & ViewTypeParam;
};

export const useQueryParams = (additionalParams?: string[]) => {
  const location = useLocation();
  const urlParams = useMemo(() => {
    return new URLSearchParams(location.search);
  }, [location.search]);

  return getQueryParams(urlParams, additionalParams);
};
