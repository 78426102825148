import { IProduct } from '../../../products/store/ProductsState';
import { ErrorOffer } from '../error-offer/ErrorOffer';

interface IProps {
  products: IProduct[];
}

export const NoProductsMessage = ({ products }: IProps) => {
  return (
    <ErrorOffer
      text={'Your offer must contain at least one product to make it public.'}
      show={products.length === 0}
      type={'info'}
    />
  );
};
