import { memo } from 'react';

import styles from './email-actions.module.scss';
import { IconSmGoogle } from '../../../../../components/UI/icons';

import { EmailAction } from '../../../../../API/action-email-api';
import { Copyright } from '../../../../../components/copyright/Copyright';
import { Button, CommonModal } from '../../../../../components/UI';
import { EmailLogin } from '../../../../auth/email-login/EmailLogin';
import { Logout } from '../../../../auth/Logout';

interface IProps {
  description: JSX.Element;
  actionEmail?: string;
  onClose: () => void;
  data: EmailAction | null;
  loading: boolean;
  setLoading: (x: boolean) => void;
  onSwitchAccount: () => void;
  signIn: () => void;
  isAdmin: boolean;
  logoutRedirectParams?: Record<string, string | undefined>;
}

export const EmailActionsModal = memo(
  ({
    actionEmail,
    data,
    description,
    onClose,
    loading,
    setLoading,
    onSwitchAccount,
    signIn,
    logoutRedirectParams,
    isAdmin
  }: IProps) => {
    const isUserExists = data?.status === 'error.email_doesnt_match' && data.payload.user_exists;

    return (
      <CommonModal
        show={!!actionEmail}
        onClose={onClose}
        showClose={true}
        closeButtonClassName={styles.closeIcon}
        params={{ dialogClassName: styles.container, contentClassName: styles.content }}
      >
        <div className={'my-auto'}>
          <div className={styles.title}>Switch account</div>
          <div className={styles.description}>{description}</div>
          {isUserExists && (
            <EmailLogin
              defaultEmail={actionEmail}
              loading={loading}
              setLoading={setLoading}
              isAdmin={isAdmin}
              className={'mb-2'}
              textEnter={'Switch account'}
            />
          )}
          {!isUserExists && (
            <Button withIcon={false} size={'lg'} className={'w-100 mb-2'} onClick={() => {}}>
              <Logout logoutRedirectParams={logoutRedirectParams} optionsSuccessAction={onSwitchAccount}>
                Switch to right account
              </Logout>
            </Button>
          )}
          {isUserExists && (
            <Button
              onClick={signIn}
              size={'lg'}
              btnStyle={'secondary'}
              iconPosition={'left'}
              customIcon={<IconSmGoogle width={32} height={32} />}
              className={'w-100 mb-2'}
              btnProps={{ disabled: loading }}
            >
              Sign in with Google
            </Button>
          )}

          <Button
            onClick={onClose}
            btnStyle={'transparent'}
            withIcon={false}
            className={'text-center w-100'}
            size={'lg'}
          >
            {isUserExists ? 'Stay with current account' : 'Cancel'}
          </Button>
        </div>
        <div className={styles.copy}>
          <Copyright />
          <a href={'https://everact.io/policies/terms'} target={'_blank'} rel="noreferrer" className={'link'}>Terms and Conditions</a>
        </div>
      </CommonModal>
    );
  }
);
