import { useCallback, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { FileSelect } from 'src/app/components/file-upload/upload/file-select/FileSelect';
import { FileUpload } from 'src/app/components/file-upload/upload/file-upload/FileUpload';
import { FileUploadControl } from 'src/app/components/file-upload/upload/FileUploadControl';
import { CustomTooltip } from 'src/app/components/UI/tooltip/CustomTooltip';
import { TypeOfUploader, VideoField } from 'src/app/interfaces/fileUpload';
import { fileUploaderActions } from 'src/app/layout/shared-components/upload-worker/store/FileUploaderActions';
import { IFileUploader } from 'src/app/layout/shared-components/upload-worker/store/FileUploaderState';
import { IUploadFileError } from 'src/app/modules/lms/courses/curriculum/common/upload-invalid-file/UploadInvalidFile';

import styles from './video-control.module.scss';
import { IconScreenFull } from 'src/app/components/UI/icons';

interface IProps {
  file?: VideoField;
  fileUploaded?: IFileUploader;
  typeOfUploader: TypeOfUploader | null;
  setTypeOfUploader: (type: TypeOfUploader | null) => void;
  setVideoUploadsData: (file: File | null) => void;
  onCloseModal: () => void;
  onSetVideo: (video: VideoField) => void;
  className?: string;
  onCancel?: () => void;
}

export const VideoControl = ({
  file,
  fileUploaded,
  typeOfUploader,
  onSetVideo,
  setVideoUploadsData,
  setTypeOfUploader,
  onCloseModal,
  className,
  onCancel
}: IProps) => {
  const [videoError, setVideoError] = useState<IUploadFileError | null>(null);
  const dispatch = useDispatch();
  const onCancelUpload = useCallback(() => {
    if (fileUploaded) {
      dispatch(
        fileUploaderActions.updateUploadFile({
          ...fileUploaded,
          uploaderData: {
            id: fileUploaded?.uploaderData.id,
            provider: fileUploaded?.uploaderData.provider,
            status: 'canceled'
          }
        })
      );
      onCancel?.();
      setTypeOfUploader(null);
    }
  }, [fileUploaded, dispatch, onCancel, setTypeOfUploader]);

  const clearError = useCallback(() => setVideoError(null), []);

  const handleFile = useCallback(
    (files: FileList | null) => {
      if (files && !files[0].type.includes('video')) {
        setVideoError({
          title: 'Wrong file format',
          description: 'The file you are trying to upload is not a video format'
        });
        return;
      } else if (files && files[0]?.size / 1024 / 1024 / 1024 >= 2) {
        setVideoError({
          title: 'Too large file',
          description: 'The maximum file size that you can upload should not exceed 2 Gb'
        });
        return;
      } else if (files) {
        setVideoUploadsData(files[0]);
      }
      onCloseModal?.();
    },
    [onCloseModal, setVideoUploadsData]
  );

  const cancelOrError =
    fileUploaded?.uploaderData.status === 'canceled' || fileUploaded?.uploaderData.status === 'error';

  return (
    <FileUploadControl
      className={className}
      titleBrowser={'Drag and drop video file'}
      titleLink={'Load video from URL'}
      embedTitle={'Embed video player'}
      descriptionBrowser={'Maximum file size 2 GB'}
      embedDescription={'Custom video player'}
      accept={'video/*'}
      fileError={videoError}
      onClearError={clearError}
      tooltip={
        'YouTube, Vimeo, Facebook, Twitch, SoundCloud, Streamable, Vimeo, Wistia, Mixcloud, DailyMotion and Kaltura.'
      }
      viewportText={
        <>
          <IconScreenFull /> Fixed viewport size: 768x432 px
        </>
      }
      descriptionLink={
        <CustomTooltip
          customText={
            'YouTube, Vimeo, Facebook, Twitch, SoundCloud, Streamable, Vimeo, Wistia, Mixcloud, DailyMotion and Kaltura.'
          }
          direction={'bottom'}
        >
          YouTube, Vimeo and
          <span className={styles.noteHighlight}> 9 more</span>
        </CustomTooltip>
      }
      uploadFile={!cancelOrError ? file : undefined}
      typeOfUploader={typeOfUploader}
      setTypeOfUploader={setTypeOfUploader}
      onSetUploadFile={onSetVideo}
      fileSelected={!cancelOrError ? fileUploaded?.file : null}
      inProcessing={
        fileUploaded?.transcodingStatus?.state === 'starting' ||
        fileUploaded?.transcodingStatus?.state === 'standby' ||
        fileUploaded?.transcodingStatus?.state === 'upload_complete' ||
        fileUploaded?.transcodingStatus?.state === 'active' ||
        fileUploaded?.transcodingStatus?.state === 'finishing'
      }
      cardBordered
      completeStatus={fileUploaded?.transcodingStatus}
    >
      {!cancelOrError && fileUploaded?.file ? (
        <FileUpload
          fileSize={fileUploaded?.file.size / 1024 / 1024}
          onCancelUpload={onCancelUpload}
          progress={fileUploaded?.progress || 0}
        />
      ) : (
        <FileSelect
          accept={'video/*'}
          title={'Drag and drop video file'}
          description={'Maximum file size 2 GB'}
          titleOnPreview={'Drag and drop video file'}
          descriptionOnPreview={'Maximum file size 2 GB'}
          typeOfUploader={typeOfUploader}
          setTypeOfUploader={setTypeOfUploader}
          setFileSelected={setVideoUploadsData}
          closeModal={onCloseModal}
          onCancel={onCancelUpload}
          handleFile={handleFile}
        />
      )}
    </FileUploadControl>
  );
};
