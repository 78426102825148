import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import querystring from 'query-string';

import styles from './google-oauth.module.scss';

import { EmailActionStatus } from '../../../API/action-email-api';
import { SpinnerLoader } from '../../../components/UI';
import { useAction } from '../../../hooks/use-action';
import { useQuery } from '../../../hooks/use-query';
import { mainPaths } from '../../MainRoutes';
import { AccountActionAsync } from '../../settings/account/store/AccountActionAsync';
import { OrganizationActionAsync } from '../../settings/organization/store/OrganizationActionAsync';
import { AuthActionAsync } from '../store/AuthActionAsync';
import { AuthActions } from '../store/AuthActions';

export const GoogleOAuth = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const query = useQuery();
  const { returnEmailInvitationData } = useAction();
  const state = query.get('state');
  const code = query.get('code');
  const parseState = (state && querystring.parse(state)) as
    | { link?: string; action?: string; course_id?: string; subdomain?: string }
    | undefined;
  const action = (query.get('action') || parseState?.action) as EmailActionStatus;
  const actionHash = query.get('link') || parseState?.link;
  const courseId = query.get('course_id') || parseState?.course_id;

  const domainState = (state && querystring.parse(state)) as { domain: string } | undefined;
  const onLoginSuccess = useCallback(
    (token?: string) => {
      if (token && domainState?.domain) {
        const url = querystring.stringifyUrl({
          url: domainState?.domain,
          query: { action, link: actionHash, course_id: courseId, token }
        });
        window.location.assign(url);
      }
    },
    [action, actionHash, courseId, domainState?.domain]
  );

  const onSuccessCallback = useCallback(() => {
    dispatch(
      AccountActionAsync.getMe(false, undefined, (account) => {
        if (account.organizations.length > 0) {
          const ownOrg = account.organizations.find((org) => org.role === 'owner');
          dispatch(AuthActions.setCurrentOrganization(ownOrg || account.organizations[0]));
          if (action === 'invite' && actionHash) {
            dispatch(OrganizationActionAsync.invitationEmail(actionHash, returnEmailInvitationData));
          }
          history.push(
            querystring.stringifyUrl({
              url: mainPaths.main,
              query: {
                action,
                link: actionHash
              }
            })
          );
        }
      })
    );
  }, [action, actionHash, dispatch, history, returnEmailInvitationData]);

  useEffect(() => {
    if (code && domainState?.domain) {
      dispatch(
        AuthActionAsync.auth(
          {
            provider: 'google',
            is_admin: false,
            payload: {
              code,
              state: state || '',
              redirect_url: window.location.origin
            }
          },
          undefined,
          onLoginSuccess
        )
      );
    } else if (code && !domainState?.domain) {
      console.log('!!!');

      dispatch(
        AuthActionAsync.auth(
          {
            provider: 'google',
            is_admin: true,
            payload: {
              code,
              state,
              redirect_url: window.location.origin
            }
          },
          undefined,
          onSuccessCallback
        )
      );
    }
  }, [code, dispatch, domainState?.domain, onLoginSuccess, onSuccessCallback, state]);

  return (
    <div className={styles.container}>
      <SpinnerLoader size={'lg'} variant={'primary'} />
    </div>
  );
};
