import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { flatMapDeep } from 'lodash';

import classNames from 'classnames';
import styles from './analytics.module.scss';

import { getMembers } from '../../../../../../components/course/course/CourseHelpers';
import { Button, DropdownToggle } from '../../../../../../components/UI';
import { ThumbnailPlaceholderImage } from '../../../../../../components/UI/image-components/thumbnail-placeholder-image/ThumbnailPlaceholderImage';
import { useDerivedState } from '../../../../../../helpers/use-derived-state';
import { CoursesSelector } from '../../../../courses/store/CourseSelector';
import { ICourseNode } from '../../../../courses/store/CourseState';
import { IStudent, IStudentAnalytics, IStudentCourse } from '../../../store/StudentListState';
import { StudentsActionAsync } from '../../../store/StudentsActionAsync';
import { CourseProgress } from '../course-progress/CourseProgress';
import { QuizResults } from '../quiz/quiz-results/QuizResults';

export type TabsType = 'course' | 'quiz';

interface IProps {
  student?: IStudent;
  courseId?: string;
}

export const Analytics = memo(({ student, courseId }: IProps) => {
  const dispatch = useDispatch();
  const [studentCourses, _] = useDerivedState(student?.courses, [student?.courses]);
  const courseMemo = useMemo(CoursesSelector, []);
  const { courses } = useSelector((state) => courseMemo(state, 'all'));
  const [activeCourse, setActiveCourse] = useState<IStudentCourse>();
  const [lessonList, setLessonList] = useState<ICourseNode[]>([]);
  const [analytics, setAnalytics] = useState<IStudentAnalytics>();
  const [tabs, setTabs] = useState<TabsType>('course');

  const changeTab = (e: React.SyntheticEvent<HTMLButtonElement>) => {
    const key = e.currentTarget.id as TabsType;
    setTabs(key);
  };

  const onAnalyticsCallback = useCallback((data: IStudentAnalytics) => {
    setAnalytics(data);
    const flatLessons = flatMapDeep(data.hierarchy, getMembers).filter((x) => x.type === 'lesson') as ICourseNode[];
    setLessonList(flatLessons);
  }, []);

  const onSelect = (eventKey: string | null) => {
    const selectedCourse = studentCourses?.find((course) => course.course_id === eventKey);
    if (selectedCourse && student) {
      dispatch(StudentsActionAsync.getStudentAnalytics(student.id, selectedCourse?.course_id, onAnalyticsCallback));
      setActiveCourse(selectedCourse);
    }
  };

  const buttonActiveState = (key: TabsType) => {
    return key === tabs ? 'primary' : 'secondary';
  };

  useEffect(() => {
    if (studentCourses?.length) {
      const activeCourse = studentCourses.find((course) => course.course_id === courseId);
      setActiveCourse(activeCourse || studentCourses[0]);
      if (student?.id) {
        dispatch(StudentsActionAsync.getStudentAnalytics(student.id, studentCourses[0]?.course_id, onAnalyticsCallback));
      }
    }
  }, [courseId, courses, dispatch, onAnalyticsCallback, student?.id, studentCourses]);

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <Dropdown onSelect={onSelect}>
          <Dropdown.Toggle as={DropdownToggle} className={styles.top} disabled={studentCourses?.length === 1}>
            <div className={styles.image}>
              <ThumbnailPlaceholderImage
                width={72}
                height={40}
                thumbnailCourse={activeCourse?.settings?.thumbnail || null}
                className={styles.image}
              />
            </div>
            <div className={classNames('ellipsis', styles.name)}>{activeCourse?.course.title}</div>
          </Dropdown.Toggle>
          <Dropdown.Menu className={classNames('sm', styles.menu)}>
            {studentCourses?.map(({ course }) => (
              <Dropdown.Item
                key={course.id}
                eventKey={course.id}
                className={classNames(styles.menuItem, {
                  [styles.active]: course.id === activeCourse?.course_id
                })}
              >
                {course.title}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
        <div className={styles.buttons}>
          <Button
            onClick={changeTab}
            withIcon={false}
            btnProps={{ id: 'course' }}
            btnStyle={buttonActiveState('course')}
          >
            Course progress
          </Button>
          <Button onClick={changeTab} withIcon={false} btnProps={{ id: 'quiz' }} btnStyle={buttonActiveState('quiz')}>
            Quiz results
          </Button>
        </div>
      </div>
      {tabs === 'course' && <CourseProgress lessonList={lessonList} analytics={analytics} />}
      {tabs === 'quiz' && student && activeCourse && (
        <QuizResults studentId={student.id} courseId={activeCourse.course_id} />
      )}
    </div>
  );
});
