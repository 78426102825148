import { UIEvent, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import styles from './workflow-sessions.module.scss';

import { Loader } from '../../../../../components/UI';
import { useLoading } from '../../../../../hooks/use-loading';
import { IPaginationResponse, IRun } from '../../../../../interfaces';
import { ContactSession } from '../../../../automations/runs/common/contact-session/ContactSession';
import { automationActionAsync } from '../../../../automations/store/AutomationActionAsync';

interface IProps {
  contactId: string | undefined;
}

export const WorkflowSessions = ({ contactId }: IProps) => {
  const dispatch = useDispatch();
  const { loading, startLoading, stopLoading } = useLoading();
  const [sessions, setSessions] = useState<IPaginationResponse<IRun[]>>();
  const [page, setPage] = useState(1);
  const [fetching, setFetching] = useState(true);

  useEffect(() => {
    if (contactId && fetching) {
      if (page === 1) {
        startLoading();
      }
      dispatch(
        automationActionAsync.getListAutomationRuns(
          {
            page,
            page_size: 10
          },
          { contact_id: contactId },
          (data) => {
            if (data) {
              setSessions((prevState) => {
                if (prevState) {
                  return { data: [...prevState?.data, ...data.data], meta: data.meta };
                } else {
                  return data;
                }
              });
              stopLoading();
            }
            setFetching(false);
          }
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contactId, dispatch, fetching, startLoading, stopLoading]);

  useEffect(() => {
    setPage(1);
    setSessions(undefined);
    setFetching(true);
  }, [contactId]);

  const handleScroll = (e: UIEvent<HTMLDivElement>) => {
    const { scrollTop, clientHeight, scrollHeight } = e.currentTarget;
    if (scrollTop + clientHeight >= scrollHeight && sessions && page < sessions.meta.total_pages) {
      setPage((prevState) => prevState + 1);
      setFetching(true);
    }
  };

  return (
    <div className={styles.container} onScroll={handleScroll}>
      {loading ? (
        <Loader />
      ) : sessions?.data.length === 0 ? (
        <div className={styles.empty}>
          <div className={styles.title}>No performed workflows</div>
          <div className={styles.description}>The list of sessions the contact went <br/> through will be shown in this section</div>
        </div>
      ) : (
        sessions?.data.map((session) => <ContactSession key={session.id} session={session} />)
      )}
    </div>
  );
};
