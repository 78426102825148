import { memo, PropsWithChildren, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { SuggestionComponentProps } from 'react-tag-autocomplete-fix';

import classNames from 'classnames';
import styles from './suggestion.module.scss';

import { CustomTooltip } from '../../../components/UI';
import { middleColor } from '../../../helpers';
import { TagByIdSelector } from './store/TagSelector';
import { CreateNewTag } from './suggestions/CreateNewTag';

interface IProps extends PropsWithChildren<SuggestionComponentProps & { onAdd: () => void }> {}

export const SuggestionElement = memo(({ item, query, onAdd }: IProps) => {
  const tagMemo = useMemo(TagByIdSelector, []);
  const bigTag = useSelector((state) => tagMemo(state, item));

  const tagName = useRef<HTMLDivElement | null>(null);
  const [isShowTooltip, setIsShowTooltip] = useState(false);

  useEffect(() => {
    if (tagName && tagName.current) {
      setIsShowTooltip(tagName.current?.scrollWidth > tagName.current?.offsetWidth);
    }
  }, [isShowTooltip]);

  return (
    <div
      id={item.id.toString()}
      className={classNames(item.name === query ? 'match' : 'no-match')}
      onClick={() => onAdd()}
    >
      <div className={classNames(styles.container, !bigTag && styles.create)}>
        {bigTag && (
          <div
            className={styles.dot}
            style={{ backgroundColor: middleColor(bigTag.background_color, bigTag.foreground_color) }}
          />
        )}
        <span ref={tagName} className={classNames(styles.text, styles.hidden)}>
          {bigTag ? item.name : 'Create new tag'}
        </span>
        <CustomTooltip disabled={!isShowTooltip} className={styles.text} customText={item.name} direction={'bottom'}>
          {bigTag ? item.name : <CreateNewTag query={query} />}
        </CustomTooltip>
      </div>
    </div>
  );
});
