import classnames from 'classnames';

import { InputText } from '../input-text/InputText';

interface IProps {
  placeholder: string;
  name: string;
  field_id: string;
  className?: string;
}

export const AddressComponent = ({ placeholder, name, field_id, className }: IProps) => {
  return (
    <InputText
      name={`${field_id}.${name}`}
      placeholder={placeholder}
      params={{ className: classnames('form-control-underline', className) }}
      maxLength={5000}
    />
  );
};
