import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import querystring from 'query-string';

import { EmailActionStatus } from '../API/action-email-api';
import { AuthActionAsync } from '../modules/auth/store/AuthActionAsync';
import { mainPaths } from '../modules/MainRoutes';
import { useQuery } from './use-query';

const isReserved = window.location.origin === process.env.REACT_APP_HOST;
const redirectUri = window.location.origin;

export const useGoogleAuth = () => {
  const { authToken } = useSelector((state) => state.auth);
  const query = useQuery();
  const history = useHistory();
  const state = query.get('state');
  const parseState = (state && querystring.parse(state)) as
    | { link?: string; action?: string; course_id?: string; domain?: string }
    | undefined;
  const action = (query.get('action') || parseState?.action) as EmailActionStatus;
  const actionHash = query.get('link') || parseState?.link;
  const courseId = query.get('course_id') || parseState?.course_id;
  const code = query.get('code');

  useEffect(() => {
    if (code && !(authToken && !parseState?.domain)) {
      const url = querystring.stringifyUrl({
        url: mainPaths.googleOAuth,
        query: { action, link: actionHash, course_id: courseId, state, code }
      });
      history.push(url);
    }
  }, [action, actionHash, authToken, code, courseId, history, parseState?.domain, state]);
};

export const useLogin = () => {
  const dispatch = useDispatch();
  const signIn = () => {
    let state = null;
    if (window.location.search && !isReserved) {
      state = `domain=${redirectUri}&${window.location.search.replace('?', '')}`;
    } else if (window.location.search && isReserved) {
      state = `null&${window.location.search.replace('?', '')}`;
    } else if (!window.location.search && !isReserved) {
      state = `domain=${redirectUri}`;
    } else if (window.location.search && !isReserved) {
      state = `null&${window.location.search.replace('?', '')}`;
    }

    dispatch(AuthActionAsync.getGoogleAuth(state, process.env.REACT_APP_HOST!, (url) => window.location.assign(url)));
  };

  return { signIn };
};
