import { memo } from 'react';

import classNames from 'classnames';

import { Icon32Cross } from '../icons';

interface IProps {
  className?: string;
  onClose(): void;
}

export const CloseButton = memo(({ className, onClose }: IProps) => {
  return (
    <button onClick={onClose} className={classNames('btn btn-icon btn-modal-close', className)}>
      <Icon32Cross />
    </button>
  );
});
