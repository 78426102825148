import { AxiosResponse } from 'axios';
import actionCreatorFactory from 'typescript-fsa';

import { IPaginationResponse } from '../../../../interfaces';
import { IProduct } from '../../products/store/ProductsState';
import { IOffer, IOfferState, OfferStatusResponse } from './OffersState';

const actionCreator = actionCreatorFactory();

export const OffersActions = {
  getOffers: actionCreator.async<{ state?: IOfferState; courseId?: string }, IPaginationResponse<IOffer[]>, Error>(
    'GET_OFFERS'
  ),
  getOffer: actionCreator.async<{ id: string }, IOffer, Error>('GET_OFFER'),
  getProductsOffer: actionCreator.async<void, IPaginationResponse<IProduct[]>, Error>('GET_OFFER_PRODUCTS'),
  createOffer: actionCreator.async<string | undefined, IOffer, Error>('CREATE_OFFER'),
  editOffer: actionCreator.async<{ id: string; offer: Partial<IOffer> }, IOffer, Error>('EDIT_OFFER'),
  editState: actionCreator.async<
    { id: string; state: IOfferState; filtered?: boolean },
    { offer: IOffer; status: string },
    AxiosResponse<OfferStatusResponse>
  >('EDIT_OFFER_STATE')
};
