import { memo, useState } from 'react';
import { FormControl } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { AxiosResponse } from 'axios';

import classNames from 'classnames';
import styles from './connect-modal-body.module.scss';
import { Icon32Spinner } from '../../../../../components/UI/icons';

import { Button } from '../../../../../components/UI';
import { InfoMessage } from '../../../../../components/UI/infoMessage/InfoMessage';
import { useLoading } from '../../../../../hooks/use-loading';
import { GeneralSettingsActionAsync } from '../../../general/store/GeneralSettingsActionAsync';
import { PaynetError } from '../../../general/store/GeneralSettingsState';
import { ConnectPaynetType } from '../ConnectModalPaynet';

interface IProps {
  onCloseModal: () => void;
  onShowGuide: () => void;
}
export const ConnectModalBody = memo(({ onCloseModal, onShowGuide }: IProps) => {
  const [errorText, setErrorText] = useState<string | null>(null);
  const { register, handleSubmit, formState } = useFormContext<ConnectPaynetType>();
  const dispatch = useDispatch();
  const { loading, startLoading, stopLoading } = useLoading();
  const onServerErrorHandler = (error: AxiosResponse<PaynetError>) => {
    switch (error.data.status) {
      case 'error.integrations.paynet.wrong_credentials':
        setErrorText('User with such data does not exist');
        break;
      case 'error.integrations.paynet.wrong_merchant_id':
        setErrorText('Wrong merchant ID');
        break;

      case 'error.integrations.paynet.already_exits':
        setErrorText('User already exists');
        break;
      default:
        setErrorText('Check if you filled in the fields correctly');
        break;
    }
    stopLoading();
  };
  const onSuccessCallback = () => {
    stopLoading();
    onCloseModal();
  };

  const onConnectPaynet = (data: ConnectPaynetType) => {
    startLoading();
    dispatch(GeneralSettingsActionAsync.addPaynet(data, onSuccessCallback, onServerErrorHandler));
  };

  const onChangeText = () => {
    if (errorText !== null) {
      setErrorText(null);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.integration}>Enter account information</div>
      <FormControl
        type={'text'}
        {...register('username', {
          onChange: onChangeText
        })}
        disabled={loading}
        className={classNames('mt-3', formState.errors.username && 'invalid')}
        placeholder={'API Username'}
      />
      <FormControl
        type={'password'}
        {...register('password', {
          onChange: onChangeText
        })}
        disabled={loading}
        className={classNames('mt-3', formState.errors.password && 'invalid')}
        placeholder={'API Password'}
      />
      <FormControl
        type={'number'}
        {...register('merchant_id', {
          onChange: onChangeText
        })}
        disabled={loading}
        className={classNames('mt-3 mb-2', formState.errors.merchant_id && 'invalid')}
        placeholder={'Merchant ID'}
      />
      <div className={styles.hint}>You can get merchant ID in your account on paynet.md</div>
      <Button
        onClick={handleSubmit(onConnectPaynet)}
        withIcon={false}
        size={'lg'}
        loading={loading}
        className={classNames(loading ? 'btn-loading' : '', 'mt-3 w-100')}
      >
        {loading ? <Icon32Spinner /> : 'Connect'}
      </Button>
      <Button
        onClick={onCloseModal}
        withIcon={false}
        btnStyle={'secondary'}
        size={'lg'}
        className={'mt-2 w-100'}
        btnProps={{ disabled: loading }}
      >
        Cancel
      </Button>
      <div className={styles.notice}>
        To create a new profile and connect it to everact.io, <br />
        see the{' '}
        <span className={styles.link} onClick={onShowGuide}>
          integration guide
        </span>
      </div>
      {errorText && (
        <InfoMessage show={true} text={errorText} type={'error'} className={styles.error} textClassName={'pr-2'} />
      )}
    </div>
  );
});
