import { useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { OnChangeValue } from 'react-select';
import { map } from 'lodash';

import styles from './sender-select.module.scss';
import { IconPlus } from '../UI/icons';

import { IOption } from '../../interfaces';
import { EmailActionAsync, EmailSelectorSender } from '../../modules/common/email-editor';
import { SettingsPaths } from '../../modules/settings/routes/SettingsRoutes';
import { AutocompleteSelect, Button } from '../UI';

interface IProps {
  name: string;
  selectedId: string | null;
}

export const SenderSelect = ({ name, selectedId }: IProps) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const memoSenders = useMemo(EmailSelectorSender, []);
  const { defaultSender, senders } = useSelector(memoSenders);
  const { register, unregister, setValue } = useFormContext();
  const onCreateNewSender = () => {
    history.push(SettingsPaths.general);
  };

  const selectData = useMemo(
    () =>
      map(senders, (sender) => {
        return {
          label: sender.email,
          value: sender.id
        };
      }),
    [senders]
  );

  useEffect(() => {
    register(name, { shouldUnregister: true });
    if (defaultSender) {
      setValue(name, selectedId || defaultSender.id, { shouldDirty: true });
    }
    return () => {
      unregister(name);
    };
  }, [defaultSender, name, register, selectedId, setValue, unregister]);

  const onSelectSender = (value: OnChangeValue<IOption, false>) => {
    if (value) {
      setValue(name, value.value, { shouldDirty: true });
    }
  };

  useEffect(() => {
    dispatch(EmailActionAsync.getSenders(true));
  }, [dispatch]);

  return (
    <div className={styles.container}>
      <div className={styles.sender}>Sender</div>

      <AutocompleteSelect
        options={selectData}
        onSelect={onSelectSender}
        CreateNewComponent={
          <div onClick={onCreateNewSender}>
            <IconPlus className={styles.createIcon} />
            Add personal sender
          </div>
        }
        NoOptionComponent={
          <Button withIcon={false} className={'mt-3 mx-auto'} onClick={onCreateNewSender}>
            Create new
          </Button>
        }
        noOptionText={'You have not sender'}
        optionIdSelected={selectedId || defaultSender?.id}
      />
    </div>
  );
};
