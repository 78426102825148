import { memo } from 'react';

import classnames from 'classnames';
import styles from './empty-statistics.module.scss';
import analytics from '../../../../../../../assets/svg/analytics_illustration.svg';
import quiz from '../../../../../../../assets/svg/quiz-results.svg';

import { TabsType } from '../analytics/Analytics';

interface IProps {
  type: TabsType;
  className?: string;
}

export const EmptyStatistics = memo(({ type, className }: IProps) => {
  return (
    <div className={classnames(styles.container, className)}>
      <div className={styles.img}>
        <img src={type === 'course' ? analytics : quiz} alt={''} />
      </div>

      <div className={styles.title}>
        {type === 'course' ? (
          <>
            Course statistics will be <br /> displayed here
          </>
        ) : (
          <>
            The student's answers to the <br /> quiz will be displayed here
          </>
        )}
      </div>
      <div className={styles.text}>
        {type === 'course' ? (
          <>
            You will be able to see with what success <br /> the student is taking the course
          </>
        ) : (
          <>
            You can create a quiz inside the course to <br /> evaluate the success of your students
          </>
        )}
      </div>
    </div>
  );
});
