import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { EmailActionStatus } from '../API/action-email-api';
import { Queue } from '../helpers/queue';
import { useAction } from '../hooks/use-action';
import { useGoogleAuth, useLogin } from '../hooks/use-google-auth';
import { useQuery } from '../hooks/use-query';
import { AuthActionAsync } from '../modules/auth/store/AuthActionAsync';
import { AuthActions } from '../modules/auth/store/AuthActions';
import { EmailActionsModal } from '../modules/lms/students/enrollment/email-actions-modal/EmailActionsModal';
import { StudentsActionAsync } from '../modules/lms/students/store/StudentsActionAsync';
import { AccountActionAsync } from '../modules/settings/account/store/AccountActionAsync';
import { StudentCabinetPaths } from '../modules/student-cabinet/routes/StudentCabinetRoutes';

export const useActionStudentQueue = (queue: Queue<(params: any) => unknown>) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const query = useQuery();
  useGoogleAuth();
  const { signIn } = useLogin();

  const token = query.get('token');

  const { onCloseModal, actionEmail, action, actionHash, data, returnEmailEnrollData } = useAction();
  const courseId = query.get('course_id');

  const linkActions = useCallback(() => {
    if (action === 'enroll' && actionHash && courseId) {
      dispatch(StudentsActionAsync.enrollmentEmail(actionHash, courseId, returnEmailEnrollData));
    }
    if (action === 'email_confirmation' && actionHash) {
      dispatch(AuthActionAsync.congratulationEmail(actionHash));
    }
    if (action === 'password_recovery' && actionHash) {
      history.push(`${StudentCabinetPaths.recovery}/?link=${actionHash}&action=${action}`);
    }
  }, [action, actionHash, courseId, dispatch, history, returnEmailEnrollData]);

  const onGoogleOAuth = useCallback(() => {
    if (token) {
      dispatch(AuthActions.authSetToken(token));
      dispatch(
        AccountActionAsync.getMe(true, token, () => {
          linkActions();
        })
      );
    }
  }, [dispatch, linkActions, token]);

  useEffect(() => {
    linkActions();
    onGoogleOAuth();
  }, [linkActions, onGoogleOAuth]);

  const onDequeue = useCallback(() => {
    queue.dequeue()?.apply(null, ['']) as { action: EmailActionStatus; actionHash: string };
  }, [queue]);

  useEffect(() => {
    onDequeue();
  }, [onDequeue]);

  const isUserExists = data?.status === 'error.email_doesnt_match' && data.payload.user_exists && action === 'enroll';
  const descriptionComponent = isUserExists ? (
    <div>
      Previously, you had access to the course from <b>{actionEmail}</b>
    </div>
  ) : (
    <div>
      The current registered user does not have access to the course. You need to switch to the correct account to gain
      access.
    </div>
  );

  const onSwitch = () => {
    queue.clear();
  };
  const onSwitchAccount = () => {
    onSwitch();
    onCloseModal();
  };

  return (
    <EmailActionsModal
      onClose={onCloseModal}
      actionEmail={actionEmail}
      data={data}
      description={descriptionComponent}
      signIn={signIn}
      setLoading={setLoading}
      loading={loading}
      isAdmin={false}
      onSwitchAccount={onSwitchAccount}
      logoutRedirectParams={{
        action,
        link: actionHash
      }}
    />
  );
};
