import { memo, ReactNode } from 'react';

import { ModalDelete } from '../../../components/UI';
import { FieldEntity } from '../field';

interface IProps {
  popup: boolean;
  entity: FieldEntity;
  label?: ReactNode;

  onClosePopup(): void;

  onDeleteField(): void;
}

export const FieldDelete = memo(({ label, entity, popup, onClosePopup, onDeleteField }: IProps) => {
  return (
    <ModalDelete
      isShowed={popup}
      onClose={onClosePopup}
      description={`Information placed in this field will be removed from all ${entity}s from your ${entity} lists along with the field. This information cannot be recovered.`}
      title={
        <>
          You are trying to delete <span>{label}</span> field
        </>
      }
      onDelete={onDeleteField}
    />
  );
});
