import commonStyles from './common-styles.module.scss';

export type ClassNameFunction = (id: string) => string;
export type ColumnClassFunction = () => ClassNameFunction;

export const getClassName = (id: string, styles: Record<string, string>): string => {
  if (id) {
    return styles[id];
  }
  return '';
};

export const commonColumnClass: ColumnClassFunction = () => {
  return (id: string) => getClassName(id, commonStyles);
};
