import { useEffect, useMemo, useState } from 'react';
import { DefaultRootState, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';

import styles from './policies.module.scss';

import { SimpleQuillEditor } from '../../../components/quill-editor/simple-quill-editor/SimpleQuillEditor';
import { IPolicy, PolicyContentTypeEnum, PolicyDefinedLinkEnum } from '../../../interfaces/policy';
import { IPoliciesSelector, PoliciesSelector } from '../../settings/legal/store/PolicySelector';
import { StudentCabinetPaths } from '../routes/StudentCabinetRoutes';

export const Policies = () => {
  const path = useRouteMatch<{ name: string }>(StudentCabinetPaths.policies(':name'));
  const name = path?.params.name;
  const [currentPolicy, setCurrentPolicy] = useState<IPolicy>();
  const policiesMemo = useMemo(PoliciesSelector, [name]);
  const policies = useSelector<DefaultRootState, IPoliciesSelector>((state) => policiesMemo(state, name));
  const { cabinetSettings } = useSelector((state) => state.studentCourses);

  useEffect(() => {
    if (name && !([PolicyDefinedLinkEnum.privacy, PolicyDefinedLinkEnum.terms] as string[]).includes(name)) {
      setCurrentPolicy(policies.policyById);
    } else if (name === PolicyDefinedLinkEnum.privacy) {
      setCurrentPolicy(policies.privacy);
    } else if (name === PolicyDefinedLinkEnum.terms) {
      setCurrentPolicy(policies.terms);
    }
  }, [name, policies]);

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.organization}>{cabinetSettings?.general_title}</div>
        <div className={styles.name}>{currentPolicy?.title}</div>
      </div>
      <div className={styles.content}>
        {currentPolicy?.content && currentPolicy.content.type === PolicyContentTypeEnum.local && (
          <SimpleQuillEditor value={currentPolicy.content.delta} options={{ readOnly: true }} />
        )}
      </div>
    </div>
  );
};
