import { getAttachmentTypeByFileType } from 'src/app/components/file-upload/file-helper';

import classNames from 'classnames';
import styles from './icon-file.module.scss';
import { ReactComponent as AudioIconCancel } from '../../../../../../assets/images/audio_cancel_file_icon.svg';
import { ReactComponent as AudioIcon } from '../../../../../../assets/images/audio_file_icon.svg';
import { ReactComponent as ErrorIcon } from '../../../../../../assets/images/error_file_icon.svg';
import { ReactComponent as FileIcon } from '../../../../../../assets/images/file_file_icon.svg';
import { ReactComponent as ImageIconCancel } from '../../../../../../assets/images/image_cancel_file_icon.svg';
import { ReactComponent as ImageIcon } from '../../../../../../assets/images/image_file_icon.svg';
import { ReactComponent as VideoIconCancel } from '../../../../../../assets/images/video_cancel_file_icon.svg';
import { ReactComponent as FileIconCancel } from '../../../../../../assets/images/video_cancel_file_icon.svg';
import { ReactComponent as VideoIcon } from '../../../../../../assets/images/video_file_icon.svg';

import { StatusUpload } from '../../../../../API/video-api';

interface IProps {
  uploadStatus: StatusUpload | undefined;
  fileType: string;
  className?: string;
}

export const IconFile = ({ uploadStatus, fileType, className }: IProps) => {
  const iconType = getAttachmentTypeByFileType(fileType);

  return (
    <div className={classNames(styles.imgContainer, className)}>
      {uploadStatus === 'error' && <ErrorIcon className={styles.img} />}
      {uploadStatus === 'canceled' && iconType === 'video' && <VideoIconCancel className={styles.img} />}
      {iconType === 'video' && (uploadStatus === 'complete' || uploadStatus === 'in_progress' || !uploadStatus) && (
        <VideoIcon className={styles.img} />
      )}
      {iconType === 'audio' && (uploadStatus === 'complete' || uploadStatus === 'in_progress' || !uploadStatus) && (
        <AudioIcon className={styles.img} />
      )}
      {uploadStatus === 'canceled' && iconType === 'audio' && <AudioIconCancel className={styles.img} />}
      {iconType === 'image' && (uploadStatus === 'complete' || uploadStatus === 'in_progress' || !uploadStatus) && (
        <ImageIcon className={styles.img} />
      )}
      {uploadStatus === 'canceled' && iconType === 'image' && <ImageIconCancel className={styles.img} />}
      {iconType === 'docs' && (uploadStatus === 'complete' || uploadStatus === 'in_progress' || !uploadStatus) && (
        <FileIcon className={styles.img} />
      )}
      {uploadStatus === 'canceled' && iconType === 'docs' && <FileIconCancel className={styles.img} />}
    </div>
  );
};
