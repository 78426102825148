import { useState } from 'react';
import { Dropdown } from 'react-bootstrap';

import {
  IconDuplicate,
  IconKebabMenu,
  IconPencilOnPaper,
  IconSendMessage,
  IconTrash
} from '../../../../../components/UI/icons';

import { Button, DropMenu } from '../../../../../components/UI';

export enum OptionsKeys {
  edit = 'edit',
  duplicate = 'duplicate',
  send_test = 'sendTestEmail',
  delete = 'delete'
}

const options = [
  { key: OptionsKeys.edit, title: 'Edit', icon: <IconPencilOnPaper /> },
  { key: OptionsKeys.duplicate, title: 'Duplicate', icon: <IconDuplicate /> },
  { key: OptionsKeys.send_test, title: 'Send test email', icon: <IconSendMessage /> },
  { key: OptionsKeys.delete, title: 'Delete', icon: <IconTrash />, className: 'iconRed' }
];

export const EditorOptions = ({ onSelect }: { onSelect: (eventKey: string | null) => void }) => {
  const [isShow, setShow] = useState(false);

  const onToggle = (isOpen: boolean) => {
    setShow(isOpen);
  };

  return (
    <DropMenu
      node={
        <Button btnStyle={'secondary'} className={''} customIcon={<IconKebabMenu />}>
          Options
        </Button>
      }
      show={isShow}
      onToggle={onToggle}
      onSelect={onSelect}
      drop={'down'}
    >
      <Dropdown.Menu className={'sm'}>
        {options.map((option) => (
          <Dropdown.Item eventKey={option.key} key={option.key} className={option.className}>
            {option.icon} <span>{option.title}</span>
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </DropMenu>
  );
};
