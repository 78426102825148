import { useCallback, useEffect } from 'react';
import { ColumnDescription } from 'react-bootstrap-table-next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';

import styles from './pricing.module.scss';
import CreateOfferImage from '../../../../../assets/svg/empty-offers.svg';

import { Button } from '../../../../components/UI';
import { LoadState } from '../../../../store';
import { CreateOffer } from '../../../crm/offers/create-offer/CreateOffer';
import { OfferList } from '../../../crm/offers/offer-list/OfferList';
import { OffersActionAsync } from '../../../crm/offers/store/OffersActionAsync';
import { IOffer, IOfferState } from '../../../crm/offers/store/OffersState';
import { coursePaths } from '../routes/CourseRoutes';

const pageSize = 15;

export const Pricing = () => {
  const { offers, loadState } = useSelector((state) => state.offers);
  const dispatch = useDispatch();
  const history = useHistory();
  const path = useRouteMatch<{ id: string; offerId?: string }>([
    coursePaths.coursePrice(':id', ':offerId'),
    coursePaths.coursePrice(':id')
  ]);

  const createOfferCallback = useCallback(
    (id?: string) => {
      if (id) {
        history.push(coursePaths.coursePrice(path?.params.id, id));
      }
    },
    [history, path?.params.id]
  );

  const onCreateOffer = useCallback(() => {
    dispatch(OffersActionAsync.createOffer(path?.params.id, createOfferCallback));
  }, [createOfferCallback, dispatch, path?.params.id]);

  const rowClickHandler = useCallback(
    (e: Event, column: ColumnDescription, columnIndex: number, row: IOffer) => {
      history.push(coursePaths.coursePrice(path?.params.id, row.id));
    },
    [history, path?.params.id]
  );

  const openOffer = useCallback(() => {
    if (offers.length === 0) {
      onCreateOffer();
    } else {
      history.push(coursePaths.coursePrice(path?.params.id, path?.params.offerId));
    }
  }, [onCreateOffer, history, offers.length, path?.params.id, path?.params.offerId]);

  const getOffers = useCallback(
    (page: number, pageSize: number, activeFilter?: IOfferState) => {
      dispatch(OffersActionAsync.getOffers({ page, page_size: pageSize }, activeFilter, path?.params.id));
    },
    [dispatch, path?.params.id]
  );

  useEffect(() => {
    if (!path?.params.offerId) {
      getOffers(1, pageSize);
    }
  }, [getOffers, path?.params.offerId]);

  return (
    <div className={styles.wrapper}>
      {loadState === LoadState.allIsLoaded && (
        <>
          {offers.length === 0 && !path?.params.offerId && (
            <div className={styles.container}>
              <img src={CreateOfferImage} alt={'create offer'} />
              <div className={styles.title}>Create an offer with this course</div>
              <div className={styles.description}>
                You can sell your course online by creating an offer and sharing it with your customers
              </div>
              <Button onClick={onCreateOffer} size={'lg'} className={styles.btn} iconPosition={'left'}>
                Create new offer
              </Button>
            </div>
          )}
          {offers.length > 0 && !path?.params.offerId && (
            <div style={{ position: 'relative' }}>
              <OfferList
                pageSize={pageSize}
                showFilters={false}
                createOffer={onCreateOffer}
                getOffers={getOffers}
                openOffer={openOffer}
                rowClickHandler={rowClickHandler}
              />
            </div>
          )}
        </>
      )}

      {path?.params.offerId && <CreateOffer offerPropsId={path.params.offerId} />}
    </div>
  );
};
