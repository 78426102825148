import { FC } from 'react';
import copy from 'copy-to-clipboard';

import styles from './entity-id.module.scss';

interface IProps {
  entityId: string;
}

export const EntityIdWrapper: FC<IProps> = ({ entityId, children }) => {
  const copyId = () => {
    copy(entityId, {
      format: 'text/plain'
    });
  };

  return (
    <div className={styles.contactId}>
      ID {entityId}
      <span className={styles.copy} onClick={copyId}>
        Copy
      </span>
      {children}
    </div>
  );
};
