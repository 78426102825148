import { buildStyles, CircularProgressbar } from 'react-circular-progressbar';

import classNames from 'classnames';
import 'react-circular-progressbar/dist/styles.css';
import styles from './upload-video-loader.module.scss';
import { IconCheckMark } from '../../../../components/UI/icons';

import { SpinnerLoader } from '../../../../components/UI';
import { IFileUploader } from '../store/FileUploaderState';

interface IProps {
  isUpload: boolean;
  isProcessing: boolean;
  isComplete: boolean;
  totalUploadingPercent: number;
  showUploader: boolean;
  uploading: IFileUploader[];
}

export const UploadVideoLoader = ({
  isComplete,
  isProcessing,
  isUpload,
  totalUploadingPercent,
  showUploader,
  uploading
}: IProps) => {
  return (
    <div className={classNames(styles.container, !showUploader && styles.active)}>
      {isUpload && uploading.length > 0 && (
        <span className={classNames(styles.text, styles.uploading)}>
          <div className={styles.circleSize}>
            <CircularProgressbar
              value={totalUploadingPercent || 0}
              strokeWidth={12}
              styles={buildStyles({
                pathColor: '#0072FF'
              })}
            />
          </div>
          Uploading &#8226; {totalUploadingPercent}%
        </span>
      )}
      {isProcessing && (
        <div className={classNames(styles.text, styles.processing)}>
          <SpinnerLoader variant={'primary'} size={'sm'} />
          Processing...
        </div>
      )}
      {isComplete && (
        <div className={classNames(styles.text, styles.completed)}>
          <IconCheckMark className={styles.iconCheckMark} />
          Upload completed
        </div>
      )}
    </div>
  );
};
