import classNames from 'classnames';
import styles from './invite-people-header.module.scss';
import { Icon32Cross, Icon32Group } from '../../../../../components/UI/icons';

interface IProps {
  onClose: () => void;
}

export const InvitePeopleHeader = ({ onClose }: IProps) => {
  return (
    <>
      <div className={styles.container}>
        <Icon32Group className={styles.icon} />
        <div className={styles.title}>Manage team</div>
      </div>
      <button className={classNames('btn btn-icon', styles.cross)} onClick={onClose}>
        <Icon32Cross />
      </button>
    </>
  );
};
