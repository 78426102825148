import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { capitalize } from 'lodash';

import { Loader } from '../../../../components/UI';
import { LoadState } from '../../../../store';
import { FieldActionAsync, FieldEntity, FieldScope, IField } from '../../../crm/field';
import { VariableEditor } from './VariableEditor';
import { IEmailVariable } from './variables';

const variablesSearch = (value: string, variables: IEmailVariable[]) => {
  return variables.filter((variable) => variable.name.toLowerCase().includes(value.toLowerCase()));
};

const convertFieldsToVariablesEmail = (
  props: Pick<
    IProps,
    'excludeScopes' | 'fieldEntities' | 'filterScopes' | 'additionalVariables' | 'additionalPropertyToEntity'
  >,
  fields: IField[]
) => {
  const filteredEntities = props.fieldEntities
    ? fields.filter((field) => props.fieldEntities?.includes(field.entity))
    : fields;

  const filteredScopes = props.filterScopes
    ? filteredEntities.filter((field) => props.filterScopes?.includes(field.scope))
    : filteredEntities;

  const filteredFieldsExcludeScopes = props.excludeScopes
    ? filteredScopes.filter((field) => !props.excludeScopes?.includes(field.scope))
    : filteredScopes;

  const fieldsSorted = filteredFieldsExcludeScopes.sort((a, b) => a.order - b.order);

  const fieldsForEmailVariables: IEmailVariable[] = fieldsSorted.map((field) => {
    return {
      name: `${field.payload.title}`,
      key: `${field.entity}.fields.${field.id}`,
      entity: field.entity
    };
  });

  return [
    ...fieldsForEmailVariables,
    ...(props.additionalVariables || []),
    ...(props.additionalPropertyToEntity || [])
  ].map((item) => {
    return { ...item, name: `${capitalize(item.entity)} ${item.name}` };
  });
};

interface IProps {
  onSelectVariable: (data: React.SyntheticEvent<HTMLDivElement>) => void;
  filterScopes?: FieldScope[];
  excludeScopes?: FieldScope[];
  fieldEntities?: FieldEntity[];
  additionalVariables?: IEmailVariable[];
  additionalPropertyToEntity?: IEmailVariable[];
}

export const VariableEmailLoader = memo((props: IProps) => {
  const { fields, loadState } = useSelector((state) => state.fields);

  const covertVariablesEmail = useMemo(() => convertFieldsToVariablesEmail(props, fields) || [], [props, fields]);
  const [variables, setVariables] = useState<IEmailVariable[]>([]);
  const dispatch = useDispatch();

  useEffect(() => {
    setVariables(covertVariablesEmail);
  }, [covertVariablesEmail]);

  useEffect(() => {
    if (fields.length === 0) {
      dispatch(FieldActionAsync.getFields());
    }
  }, [dispatch, fields.length]);

  const onChangeInput = useCallback(
    (data: string) => {
      if (data.trim() !== '') {
        setVariables(variablesSearch(data, variables));
      } else {
        setVariables(covertVariablesEmail);
      }
    },
    [covertVariablesEmail, variables]
  );

  return (
    <>
      {loadState === LoadState.allIsLoaded ? (
        <VariableEditor
          onSelectVariable={props.onSelectVariable}
          onSearchVariables={onChangeInput}
          variables={variables}
        />
      ) : (
        <Loader />
      )}
    </>
  );
});
