import classNames from 'classnames';
import styles from './transparent-loader.module.scss';

interface IProps {
  value: number;
  className?: string;
}

export const TransparentPercentLoader = ({ value, className }: IProps) => {
  return (
    <div className={classNames(styles.container, className)}>
      <div className={styles.middleCircle}></div>
      <div
        className={styles.progressPinner}
        style={{ background: `conic-gradient(#0072FF ${value}%, #E0EEFF ${value}%)` }}
      ></div>
    </div>
  );
};
