import { IEditMember, IInviteMember, IMember } from '../interfaces';
import { baseFetch } from './BaseFetch';

export const getOrganizationMembers = async () => {
  return await baseFetch<{}, IMember[]>('/members', {}, 'GET', true);
};

export const inviteOrganizationMember = async (params: IInviteMember) => {
  return await baseFetch<IInviteMember, void>('/members', params, 'POST', true);
};

export const resendInvitationOrganizationMember = async (memberId: string) => {
  return await baseFetch<{}, void>(`/members/${memberId}/resend`, {}, 'POST', true);
};

export const removeMemberFromOrganization = async (memberId: string) => {
  return await baseFetch<{}, void>(`/members/${memberId}`, {}, 'DELETE', true);
};

export const editMemberOrganization = async (memberId: string, params: IEditMember) => {
  return await baseFetch<IEditMember, IMember>(`/members/${memberId}`, params, 'PUT', true);
};
