import React, { memo, useCallback, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import moment from 'moment/moment';

import classNames from 'classnames';
import styles from './quiz-result-item.module.scss';
import {
  Icon16ArrowRight,
  Icon16ChevronRight,
  IconEyeHide,
  IconKebabMenu,
  IconPencilOnPaper,
  IconReload,
  IconStarOutline
} from '../../../../../../../components/UI/icons';

import { KebabToggle } from '../../../../../../../components/UI/kebabToggle/KebabToggle';
import { AnswerSessionStatusEnum, IQuizSession } from '../../../../../../../interfaces/quiz';
import { coursePaths } from '../../../../../courses/routes/CourseRoutes';
import { ConfirmModal } from '../../confirm-modal/ConfirmModal';
import { getPathToQuizNode } from '../../helpers/getPathToQuizNode';
import { QuizSessionsActionAsync } from '../../store/QuizSessionsActionAsync';
import { QuizLocation } from '../quiz-location/QuizLocation';
import { QuizStatus } from '../quiz-status/QuizStatus';

enum EventKey {
  reset = 'reset',
  skipped = 'skipped',
  view = 'view',
  edit = 'edit'
}

interface IProps {
  quizSession: IQuizSession & { attempt: number };
  quizLocation: boolean;
  courseId: string;

  onClick(e?: React.SyntheticEvent<HTMLDivElement>, id?: string, groupId?: string | null): void;

  updateSessions(): void;
}

export const QuizResultItem = memo(({ quizSession, quizLocation, courseId, onClick, updateSessions }: IProps) => {
  const dispatch = useDispatch();
  const [showConfirm, setShowConfirm] = useState(false);
  const [confirmType, setConfirmType] = useState<'skip' | 'reset'>();

  const onChangeState = (eventKey: string | null) => {
    switch (eventKey) {
      case EventKey.reset:
        setConfirmType('reset');
        setShowConfirm(true);
        break;
      case EventKey.skipped:
        setConfirmType('skip');
        setShowConfirm(true);
        break;
      case EventKey.edit:
        if (quizSession.hierarchy) {
          const treeNodes = getPathToQuizNode(quizSession.hierarchy);
          const step = treeNodes?.find((node) => node.type === 'step');
          if (step) {
            window.open(`${window.location.origin}${coursePaths.courseLessons(courseId, step.id)}`);
          }
        }
        break;
      case EventKey.view:
        onClick(undefined, quizSession.id, quizSession.questionnaire.id);
        break;
    }
  };

  const onConfirm = useCallback(() => {
    if (confirmType === 'reset') {
      dispatch(
        QuizSessionsActionAsync.deleteQuizSession(quizSession.id, () => {
          updateSessions();
        })
      );
    }
    if (confirmType === 'skip') {
      dispatch(
        QuizSessionsActionAsync.markSkipableQuizSession(quizSession.id, () => {
          updateSessions();
        })
      );
    }
  }, [confirmType, dispatch, quizSession.id, updateSessions]);

  const closeConfirm = useCallback(() => {
    setShowConfirm(false);
  }, []);

  return (
    <>
      <div className={styles.container}>
        <Dropdown onSelect={onChangeState} className={styles.menu}>
          <Dropdown.Toggle as={KebabToggle} id="dropdown-basic">
            <IconKebabMenu />
          </Dropdown.Toggle>
          <Dropdown.Menu align="right" className={classNames('sm', styles.dropdown)}>
            <Dropdown.Item eventKey={EventKey.reset}>
              <IconReload /> Reset quiz
            </Dropdown.Item>
            {!(quizSession.state === AnswerSessionStatusEnum.success) && quizSession.state !== AnswerSessionStatusEnum.skipable && (
              <Dropdown.Item eventKey={EventKey.skipped} className={styles.remove}>
                <IconEyeHide /> Mark as skipped
              </Dropdown.Item>
            )}
            <Dropdown.Item eventKey={EventKey.view} className={styles.remove}>
              <IconStarOutline /> View results
            </Dropdown.Item>
            <Dropdown.Item eventKey={EventKey.edit} className={styles.remove}>
              <IconPencilOnPaper /> Go to edit
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        <div className={styles.title}>{quizSession.questionnaire.title}</div>
        <div className={styles.info}>
          <div className={styles.attempt}>Attempt: {quizSession.attempt}</div>
          <div className={styles.time}>{moment(quizSession.date_create).format('DD MMM yyyy, hh:mm A')}</div>
          <QuizStatus status={quizSession.state} />
          <div
            className={styles.detail}
            onClick={onClick}
            id={quizSession.id}
            data-group={quizSession.questionnaire.id}
          >
            Answers
            <div className={styles.icon}>
              <Icon16ArrowRight className={styles.arrow} />
              <Icon16ChevronRight className={styles.chevron} />
            </div>
          </div>
        </div>
        {quizLocation && (
          <QuizLocation maxLengthItems={3} hierarchy={quizSession.hierarchy} className={styles.breadcrumbs} />
        )}
      </div>
      <ConfirmModal
        show={showConfirm}
        type={confirmType}
        onClose={closeConfirm}
        quizName={quizSession?.questionnaire?.title || ''}
        onConfirm={onConfirm}
      />
    </>
  );
});
