import { actionCreatorFactory } from 'typescript-fsa';

import { FieldEntity, FieldTypes, IField, IPayload } from './FieldState';

export const actionCreator = actionCreatorFactory();

export type IFieldCreateRequest =
  | {
      type: 'select';
      payload: IPayload;
      entity: Exclude<FieldEntity, 'company'>;
    }
  | {
      type: Exclude<FieldTypes, 'select'>;
      payload: IPayload;
      entity: Exclude<FieldEntity, 'company'>;
    };

export interface IFieldEditRequest {
  id: string;
  payload: Partial<IPayload>;
}

export const FieldActions = {
  getFields: actionCreator.async<void, IField[], Error>('GET_FIELDS'),
  createField: actionCreator.async<IFieldCreateRequest, IField, Error>('CREATE_FIELD'),
  editField: actionCreator.async<IFieldEditRequest, IField, Error>('EDIT_FIELD'),
  deleteField: actionCreator.async<{ id: string }, void, Error>('DELETE_FIELD'),
  reorderField: actionCreator.async<{ id: string; index: number }, IField, Error>('REORDER_FIELD')
};
