import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  ColumnDescription,
  RowEventHandlerProps,
  SelectRowProps,
  TableChangeState,
  TableChangeType
} from 'react-bootstrap-table-next';
import { batch, useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import moment from 'moment';
import { debounce } from 'lodash';

import styles from '../student-list/student-list.module.scss';
import { Icon32User } from '../../../../components/UI/icons';

import { EmptyList } from '../../../../components/empty-list/EmptyList';
import { NoSearchResult } from '../../../../components/no-search-result/NoSearchResult';
import { Loader } from '../../../../components/UI';
import { RemotePagination } from '../../../../components/UI/pagination/RemotePagination';
import { PopupCard } from '../../../../components/UI/popup-card/PopupCard';
import { useIntercomUpdate } from '../../../../hooks/use-intercom-update';
import { usePayload } from '../../../../hooks/usePayload';
import { RowType } from '../../../../interfaces/tables';
import { LoadState } from '../../../../store';
import { FieldActionAsync } from '../../../crm/field';
import { FieldsEdit } from '../../../crm/fields/FieldsEdit';
import { FormatterType } from '../../../crm/lists/listLists/config/listTableConfig';
import { IListField } from '../../../crm/lists/store/ListsState';
import { enrollmentPaths } from '../../enrollment/routes/EnrollmentRoutes';
import { PassingGrade } from '../common/passing-grade/PassingGrade';
import { Tags } from '../common/table-tags/Tags';
import { studentPaths } from '../routes/StudentRoutes';
import { StudentDataSelector } from '../store/StudentDataSelector';
import { IStudent, IStudentListField } from '../store/StudentListState';
import { StudentsActionAsync } from '../store/StudentsActionAsync';
import { StudentCard } from '../student-card/StudentCard';
import { ListHeader } from './list-header/ListHeader';

export const StudentsOfList = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { isPayloadShow, showPayload } = usePayload();
  const { searchString, isSidebarShow: isFieldSettingsShow } = useSelector((state) => state.settings);
  const [studentId, setStudentId] = useState<string>();
  const path = useRouteMatch<{ list_id: string }>(enrollmentPaths.studentListId());
  const id = path?.params.list_id;
  const tableDataMemo = useMemo(StudentDataSelector, []);
  const { studentData, page, page_size, total, loadState } = useSelector((state) => tableDataMemo(state));
  const { fields } = useSelector((state) => state.fields);
  const { studentList } = useSelector((state) => state.studentsConfig);
  const location = useLocation<{ contactId?: string }>();
  const sizePerPage = 25;

  const [isPageChanged, setIsPageChanged] = useState(true);
  const [isSearch, setIsSearch] = useState(false);

  const [studentsTable, setStudentsTable] = useState<{
    items: Row[];
    totalSize: number;
    page: number;
    sizePerPage: number;
  }>({ items: studentData, totalSize: 0, page: 1, sizePerPage: 25 });

  useIntercomUpdate(isPayloadShow);

  const studentFields = useMemo(
    () =>
      studentList.fields?.map((listField) => {
        listField.field = fields.find((field) => field.id === listField.field_id);
        return listField;
      }),
    [fields, studentList.fields]
  );

  const getStudents = useCallback(() => {
    if (id) {
      dispatch(StudentsActionAsync.getStudentsOfList(id, { page, page_size }));
    }
  }, [dispatch, id, page, page_size]);

  const getConfig = useCallback(() => {
    if (id) {
      dispatch(StudentsActionAsync.getStudentsConfig(id));
    }
  }, [dispatch, id]);

  const changeHandler = useCallback(
    (searchString: string) => {
      if (!isPageChanged && id) {
        setIsSearch(searchString !== '');
        dispatch(
          StudentsActionAsync.getStudentsOfList(
            id,
            {
              page: 1,
              page_size: sizePerPage
            },
            searchString,
            () => {
              setIsPageChanged(false);
            }
          )
        );
      }
    },
    [dispatch, id, isPageChanged]
  );

  const debouncedSearch = useMemo(() => debounce(changeHandler, 300), [changeHandler]);

  const debouncedChangeHandler = useCallback(debouncedSearch, [debouncedSearch]);

  useEffect(() => {
    debouncedChangeHandler(searchString);
  }, [debouncedChangeHandler, searchString]);

  useEffect(() => {
    if (loadState === LoadState.allIsLoaded) {
      setStudentsTable({ items: studentData, page: page, sizePerPage: page_size, totalSize: total });
    }
  }, [loadState, page, page_size, studentData, total]);

  useEffect(() => {
    batch(() => {
      dispatch(FieldActionAsync.getFields());
      getStudents();
      getConfig();
    });
  }, [dispatch, getConfig, getStudents]);

  useEffect(() => {
    if (!isPayloadShow) {
      setStudentId(undefined);
    }
    if (location.state?.contactId) {
      setStudentId(location.state?.contactId);
      showPayload();
    }
  }, [isPayloadShow, location.state?.contactId, showPayload]);

  useEffect(() => {
    if (studentId) {
      showPayload();
    }
  }, [showPayload, studentId]);

  const handleTableChange = (type: TableChangeType | 'search', { page, sizePerPage }: TableChangeState<unknown>) => {
    if (type === 'search') {
      setIsPageChanged(false);
    }
    if (type === 'pagination') {
      setIsPageChanged(true);
      if (id) {
        dispatch(StudentsActionAsync.getStudentsOfList(id, { page: page, page_size: sizePerPage }, searchString));
      }
    }
  };

  const columnFormatter = useCallback(
    (cell: string, row: Row, index: number, data: IListField) => {
      if (cell === undefined && data.field?.scope !== 'first_name') {
        return '-';
      }
      if (data.field?.scope === 'first_name') {
        const lastNameField: IStudentListField | undefined = studentFields?.find(
          (field) => field.field?.scope === 'last_name'
        );
        const lastName = (lastNameField && row[lastNameField.field_id]) || '';
        return (
          <div className={styles.name}>
            <div className={styles.link}>{`${cell || '-'} ${lastName}`}</div>
            <Tags id={row.id} />
          </div>
        );
      }
      if (data.field?.scope === 'email') {
        return <div className={styles.email}>{cell}</div>;
      }
      return cell;
    },
    [studentFields]
  );

  const columnsData: ColumnDescription<Row>[] = useMemo(
    () =>
      studentFields
        ?.filter((field) => field.field && ['first_name', 'email'].includes(field.field?.scope))
        .map((field) => {
          const columnTitle = field.field?.payload.title || '-';
          return {
            dataField: field.field_id,
            text: field.field?.scope === 'first_name' ? 'Full name' : columnTitle,
            formatter: columnFormatter,
            formatExtraData: field,
            classes: 'col',
            headerClasses: 'col'
          };
        }) || [],
    [columnFormatter, studentFields]
  );

  const rowEvents: RowEventHandlerProps<Row> = {
    onClick: (e, row) => {
      setStudentId(row.id);
    }
  };

  const inviteStudents = () => {
    history.push(studentPaths.studentList);
  };

  const updateStudentId = (id: string) => {
    setStudentId(id);
  };

  return (
    <>
      {loadState === LoadState.firstLoad && <Loader />}
      <ListHeader totalCount={total} list={studentList} />
      {loadState === LoadState.allIsLoaded && columnsData.length > 0 && (
        <RemotePagination
          page={studentsTable.page}
          sizePerPage={studentsTable.sizePerPage}
          totalSize={studentsTable.totalSize}
          onTableChange={handleTableChange}
          tableProps={{
            keyField: 'id',
            columns: [idColumn, ...columnsData, ...additionalColumn],
            selectRow: selectRow,
            rowClasses: styles.row,
            rowEvents: rowEvents,
            data: studentsTable.items
          }}
        />
      )}
      {loadState === LoadState.idle &&
        (isSearch ? (
          <NoSearchResult searchString={searchString} />
        ) : (
          <EmptyList
            onClick={inviteStudents}
            button={'Invite students'}
            title={'No enrolled students'}
            description={
              'Students will be added to the current list after purchasing the course by your invitation or on the product page.'
            }
            icon={<Icon32User width={64} height={64} />}
          />
        ))}
      <PopupCard isShow={isPayloadShow}>
        {<StudentCard courseId={studentList.payload?.course_id} studentId={studentId} setStudentId={updateStudentId} />}
      </PopupCard>
      <PopupCard isShow={isFieldSettingsShow} fullHeight={true}>
        <FieldsEdit
          entity={'student'}
          title={'Edit student fields'}
          description={
            'Change the order and the content for the working fields. The settings are applied to all students.'
          }
        />
      </PopupCard>
      {/*<ModalDelete
        onClose={onCloseDeletePopup}
        isShowed={showDeleteModal}
        onDelete={deleteStudentHandler}
        title={
          <>
            Student <span>{selectedStudentName}</span> will be deleted
          </>
        }
        description={'This student will be permanently deleted. You will not be able to restore it!'}
      />*/}
    </>
  );
};

export type StudentListRow = {
  id: string;
  courses_count: number;
  date_create: number;
  data: IStudent;
};

type Row = RowType<StudentListRow>;

const idColumn = {
  dataField: 'id',
  text: 'ID',
  classes: 'col-1',
  headerClasses: 'col-1'
};

const dateColumnFormatter: FormatterType<Row> = (cell: string) => {
  return (
    <div className={'d-flex align-items-center h-100'}>
      <div className="value">{cell ? moment(cell).format('DD MMMM YYYY') : '-'}</div>
    </div>
  );
};

const additionalColumn = [
  {
    dataField: 'progress',
    text: 'Course progress',
    classes: 'col',
    headerClasses: 'col',
    formatter: (cell: number) => {
      return <PassingGrade progress={cell} />;
    }
  },
  {
    dataField: 'enrolment_date',
    text: 'Enrollment Date',
    classes: 'col',
    headerClasses: 'col',
    formatter: dateColumnFormatter
  }
  // {
  //   dataField: 'status',
  //   text: 'Status',
  //   classes: 'col',
  //   headerClasses: 'col',
  //   formatter: (cell: 'pending' | 'enrolled') => {
  //     return <span className={classNames(styles[cell], styles.status)}>{cell}</span>;
  //   }
  // }
];

const selectRow: SelectRowProps<any> = {
  mode: 'radio',
  clickToSelect: true,
  hideSelectColumn: true,
  classes: styles.selectedRow
};
