import { memo } from 'react';

import styles from './remove.module.scss';

import { Button } from '../../../../../../components/UI';

interface IProps {
  name: string;
  onCancel: () => void;
  onConfirm: () => void;
}

export const MemberRemoveConformation = memo(({ name, onCancel, onConfirm }: IProps) => {
  return (
    <div className={styles.container}>
      <div className={styles.name}>{name}</div>
      <div className={styles.text}>will no longer have access to your organization</div>
      <div className={'d-flex w-100'}>
        <Button onClick={onCancel} withIcon={false} btnStyle={'secondary'} className={'mr-1 flex-grow-1'}>
          Cancel
        </Button>
        <Button onClick={onConfirm} withIcon={false} btnStyle={'warning'} className={'flex-grow-1'}>
          Confirm
        </Button>
      </div>
    </div>
  );
});
