import { useWatch } from 'react-hook-form';

import { useNodePayload } from '../../../../../hooks/use-node-payload';
import { PaletteAutomationElementTypes } from '../../../../../interfaces';
import { useBuilderContext } from '../../BuilderContext';
import { EmptyCanvasNode } from '../EmptyCanvasNode';
import { AmountNodeItem } from './AmountNodeItem';
import { RangeNodeItem } from './RangeNodeItem';

interface IProps {
  nodeId: string;
}

export const CanvasNodeRange = ({ nodeId }: IProps) => {
  const payload = useNodePayload<PaletteAutomationElementTypes.PROCESSOR_RANGE>(nodeId);
  const { selectedNode } = useBuilderContext();
  const rangeType = useWatch({ name: 'range.type' });

  const type = selectedNode?.id === nodeId ? rangeType || payload?.payload?.range?.type : payload?.payload?.range?.type;

  return (
    <>
      {type === 'range' && <RangeNodeItem id={nodeId} />}
      {type === 'amount' && <AmountNodeItem id={nodeId} />}
      {!type && <EmptyCanvasNode text={'Double click to open the options and manage this element'} />}
    </>
  );
};
