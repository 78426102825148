import { memo } from 'react';

import classNames from 'classnames';
import styles from './node-item-course.module.scss';
import { IconFolderSolid, IconWebinar } from '../../../../../UI/icons';

import { ICourseNode } from '../../../../../../modules/lms/courses/store/CourseState';
import { ItemStatus } from '../../../../../itemStatus/ItemStatus';

export const NodeItemCourse = memo(({ state, title, type }: Pick<ICourseNode, 'title' | 'type' | 'state'>) => {
  return (
    <div className={state === 'draft' ? styles.containerDraft : styles.containerPublish}>
      {type === 'folder' ? (
        <IconFolderSolid className={styles.iconFolder} />
      ) : (
        <IconWebinar className={styles.iconLesson} />
      )}
      <span className={classNames(styles.title, 'ellipsis')}>{title}</span>
      {state === 'draft' && <ItemStatus label={'Draft'} className={'ml-4'} />}
    </div>
  );
});
