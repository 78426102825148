import { MutableRefObject } from 'react';
import ReactQuill from 'react-quill';

export const myOutdent = (reactQuillRef: MutableRefObject<ReactQuill | null>) => {
  const quill = reactQuillRef.current;
  const editor = quill?.getEditor();
  const range = editor?.getSelection();
  if (range) {
    return quill?.getEditor().format('indent', '-1');
  }
};

export const myUndo = (reactQuillRef: MutableRefObject<ReactQuill | null>) => {
  const quill = reactQuillRef.current;
  return (quill?.getEditor() as any).history.undo();
};

export const myRedo = (reactQuillRef: MutableRefObject<ReactQuill | null>) => {
  const quill = reactQuillRef.current;
  return (quill?.getEditor() as any).history.redo();
};

export const mySub = (reactQuillRef: MutableRefObject<ReactQuill | null>) => {
  const quill = reactQuillRef.current;
  const editor = quill?.getEditor();
  const range = editor?.getSelection();
  if (range) {
    return quill?.getEditor().formatText(range.index, range.length, { script: 'sub' });
  }
};

export const mySuper = (reactQuillRef: MutableRefObject<ReactQuill | null>) => {
  const quill = reactQuillRef.current;
  const editor = quill?.getEditor();
  const range = editor?.getSelection();
  if (range) {
    return quill?.getEditor().formatText(range.index, range.length, { script: 'super' });
  }
};

export const insertImageToEditor = (reactQuillRef: MutableRefObject<ReactQuill | null>, url: string) => {
  const quill = reactQuillRef.current;
  const editor = quill?.getEditor();
  const range = editor?.getSelection();
  if (editor && range) {
    editor.insertEmbed(range.index, 'image', url);
  }
};
