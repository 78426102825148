import { IAccountOrganization } from '../../../interfaces';
import { LoadState } from '../../../store';

export interface IAuthState {
  authToken: string | undefined | null;
  currentOrganization: IAccountOrganization | null;
  avatar: string;
  mail: string | null;
  error: Error | null;
  loadState: LoadState;
}

export const AuthTokenInitialState: IAuthState = {
  authToken: undefined,
  currentOrganization: null,
  avatar: '',
  mail: null,
  error: null,
  loadState: LoadState.idle
};
