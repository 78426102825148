import React, { memo, useState } from 'react';
import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { SetupIntent } from '@stripe/stripe-js';

import styles from './card-form.module.scss';

import { Button, Loader } from '../../../../components/UI';

interface IProps {
  onSuccess(setupIntent: SetupIntent | undefined): void;
}

export const CardForm = memo(({ onSuccess }: IProps) => {
  const stripe = useStripe();
  const elements = useElements();
  const [disabled, setDisabled] = useState(true);
  const [errorMessage, setErrorMessage] = useState();
  const [loading, setLoading] = useState(true);

  const onReady = () => {
    setDisabled(false);
    setLoading(false);
  };

  const handleSubmit = async () => {
    if (!stripe || !elements) {
      return;
    }
    setLoading(true);
    const { error, setupIntent } = await stripe.confirmSetup({
      elements,
      redirect: 'if_required'
    });

    setLoading(false);
    if (error) {
      setErrorMessage(error.message as any);
    } else {
      onSuccess(setupIntent);
    }
  };

  return (
    <div className={styles.container}>
      {loading && <Loader />}
      <div className={styles.card}>
        <PaymentElement onReady={onReady} />
        {errorMessage && <div>{errorMessage}</div>}
      </div>
      <div className={styles.plan}>
        <div>
          <div className={styles.label}>Subscription plan</div>
          <div className={styles.value}>
            <strong>Business</strong> $19.99/mo.
          </div>
        </div>
        <div>
          <div className={styles.label}>Starting from</div>
          <div className={styles.value}>21 Sep, 2022</div>
        </div>
      </div>

      <Button
        onClick={handleSubmit}
        size={'lg'}
        className={'w-100 mb-3'}
        withIcon={false}
        btnProps={{ disabled: disabled || !stripe }}
      >
        Save billing information
      </Button>
      <div className={styles.note}>
        The subscription will renew automatically. You can unsubscribe at any time. <br />
        By clicking the button, you agree to the Terms & Conditions.
      </div>
    </div>
  );
});
