import React, { memo } from 'react';

import classNames from 'classnames';
import styles from './choose-plan.module.scss';
import { Icon32CheckMark } from '../../../../../../components/UI/icons';

import { Button } from '../../../../../../components/UI';

interface IProps {
  isCurrentPlan: boolean;
  isCanceled: boolean;
  changePlan: (e: React.MouseEvent<HTMLButtonElement>) => void;
  id: string;
  isPopular: boolean;
  className?: string;
}

export const ChoosePlanBtn = memo(({ id, isCurrentPlan, isCanceled, isPopular, className, changePlan }: IProps) => {
  return (
    <>
      {isCurrentPlan ? (
        <Button
          onClick={() => {}}
          size={'lg'}
          className={classNames(className, styles.btnCurrent)}
          btnStyle={'outline'}
          iconPosition={'left'}
          customIcon={<Icon32CheckMark className={'icon'} />}
          btnProps={{ disabled: true }}
          withIcon={!isCanceled}
        >
          {isCanceled ? 'Canceled plan' : 'Current'}
        </Button>
      ) : (
        <Button
          onClick={changePlan}
          btnProps={{ id }}
          withIcon={false}
          size={'lg'}
          className={className}
          btnStyle={isPopular ? 'secondary' : 'primary'}
        >
          Choose
        </Button>
      )}
    </>
  );
});
