import classnames from 'classnames';
import styles from './field.module.scss';

import { InputText } from '../../../components/UI/fields/input-text/InputText';

interface IProps {
  placeholder: string;
  fieldName: string;
}

export const FieldTitle = ({ fieldName, placeholder }: IProps) => {
  return (
    <div className={styles.fieldNameContainer}>
      <div className={styles.fieldTitle}>{fieldName}</div>
      <InputText
        name={'title'}
        placeholder={placeholder}
        params={{ className: classnames('form-control-underline', styles.bgContainer) }}
        rules={{
          required: 'Field is required',
          minLength: 1,
          maxLength: 64
        }}
      />
    </div>
  );
};
