import React from 'react';
import { ToastProps } from 'react-toastify';

import classNames from 'classnames';
import styles from './toast.module.scss';

import { IconCheckMark, IconCross, IconInfo, IconWarning } from '../icons';

interface IProps {
  title?: string;
  message: string;
  closeToast?: () => void;
  toastProps?: ToastProps;
}

export const ToastMessage = ({ closeToast, toastProps, message, title }: IProps) => {
  let icon;
  switch (toastProps!.type) {
    case 'info':
      icon = <IconInfo />;
      title = title || 'Info';
      break;
    case 'success':
      icon = <IconCheckMark />;
      title = title || 'Success';
      break;
    case 'warning':
      icon = <IconInfo />;
      title = title || 'Warning';
      break;
    case 'error':
      icon = <IconWarning />;
      title = title || 'Error';
      break;
    case 'default':
    case 'dark':
      icon = <IconInfo />;
      title = title || 'Message';
      break;
  }

  return (
    <div className={styles.container}>
      <div className={styles.icon}>{icon}</div>
      <div className={styles.message}>
        <div className={styles.title}>{title}</div>
        <div className={styles.text}>{message}</div>
      </div>
      <button onClick={closeToast} className={classNames('btn btn-icon', styles.close)}>
        <IconCross />
      </button>
    </div>
  );
};
