import { IRoutes } from '../../../interfaces';
import { ContactListHeaderPanel } from '../../../layout/header/contactListHeaderPanel/ContactListHeaderPanel';
import { ListListsPanel } from '../../../layout/header/listListsPanel/ListListsPanel';
import { OfferListHeaderPanel } from '../../../layout/header/offerListHeaderPanel/OfferListHeaderPanel';
import { ListContacts } from '../lists/listContacts/ListContacts';
import { ListLists } from '../lists/listLists/ListLists';
import { ProductListPanel } from '../offers/common/product-list-panel/ProductListPanel';
import { CreateOffer } from '../offers/create-offer/CreateOffer';
import { OfferListWrapper } from '../offers/offer-list/OfferListWrapper';
import { Payments } from '../payments/Payments';
import { CourseListWrapper } from '../products/course-list-wrapper/CourseListWrapper';
import { ProductListWrapper } from '../products/product-list-wrapper/ProductListWrapper';

export const CRMPaths = {
  crmLists: '/crm/lists',
  crmListId: (id = ':list_id') => `/crm/list/${id}`,
  crmContacts: '/crm/contacts',
  crmProducts: '/crm/products',
  crmOffers: '/crm/offers',
  crmCourses: '/crm/courses',
  payments: '/crm/payments',
  crmOffer: (id = ':id') => `/crm/offer/${id}`
  // TODO: while here, in feature we will pass to custom domain
};

export const CRMRoutes: IRoutes[] = [
  {
    path: CRMPaths.crmLists,
    exact: true,
    header: () => <ListListsPanel />,
    main: () => (
      <div className={'content'}>
        <ListLists />
      </div>
    )
  },
  {
    path: CRMPaths.crmListId(),
    exact: true,
    header: () => <ContactListHeaderPanel />,
    main: () => (
      <div className={'content'}>
        <ListContacts />
      </div>
    )
  },
  {
    path: CRMPaths.crmProducts,
    exact: true,
    header: () => <ProductListPanel />,
    main: () => (
      <div className={'content'}>
        <ProductListWrapper />
      </div>
    )
  },
  {
    path: CRMPaths.crmCourses,
    exact: true,
    header: () => <ProductListPanel />,
    main: () => (
      <div className={'content'}>
        <CourseListWrapper />
      </div>
    )
  },
  {
    path: CRMPaths.payments,
    exact: true,
    header: () => <ProductListPanel />,
    main: () => (
      <div className={'content'}>
        <Payments />
      </div>
    )
  },
  {
    path: CRMPaths.crmOffers,
    exact: true,
    header: () => <ProductListPanel />,
    main: () => (
      <div className={'content'}>
        <OfferListWrapper />
      </div>
    )
  },
  {
    path: CRMPaths.crmOffer(),
    exact: true,
    header: () => <OfferListHeaderPanel />,
    main: () => (
      <div className={'content contentOffer'}>
        <CreateOffer />
      </div>
    )
  },
  {
    path: CRMPaths.crmContacts,
    exact: true,
    header: () => <ListListsPanel />,
    main: () => (
      <div className={'content'}>
        <ListContacts />
      </div>
    )
  }
];
