import { memo } from 'react';

import classNames from 'classnames';
import styles from './option-item-plan.module.scss';
import { IconCheckMark, SoonLight } from '../../../../../../components/UI/icons';

import { IPlanDataOption } from '../../../store';

interface IOptionItemProps {
  option: IPlanDataOption;
  isPopular: boolean;
}

export const OptionItemPlan = memo(({ option, isPopular }: IOptionItemProps) => {
  return (
    <div className={styles.options}>
      {option.list.map((item, index) => (
        <div className={styles.listItem} key={index}>
          <IconCheckMark className={classNames(styles.icon, { [styles.iconPopular]: isPopular })} /> {item.value}{' '}
          {item.isSoon && <SoonLight className={'ml-1'} />}
        </div>
      ))}
    </div>
  );
});
