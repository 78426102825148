import { memo } from 'react';
import Select, { components, DropdownIndicatorProps, OnChangeValue } from 'react-select';
import moment from 'moment';

import { IconAngleDown } from '../../../../../UI/icons';

import { IOption } from '../../../../../../interfaces';
import { NodeCondition } from '../../../../../../modules/lms/courses/store/CourseState';
import { SelectOptionDrip } from '../DripModal';

interface IProps {
  setSelectedOption: (value: NodeCondition) => void;
  defaultValue?: NodeCondition;
}

const selectOptions: IOption<SelectOptionDrip>[] = [
  { label: 'None', value: null },
  { label: 'On a specific date', value: 'delayed' }
];

const DropdownIndicator = (props: DropdownIndicatorProps<IOption<SelectOptionDrip>, false>) => {
  return (
    <components.DropdownIndicator {...props}>
      <IconAngleDown />
    </components.DropdownIndicator>
  );
};

export const SelectDripType = memo(({ setSelectedOption, defaultValue }: IProps) => {
  const onSelect = (data: OnChangeValue<IOption<SelectOptionDrip>, false>) => {
    if (data?.value) {
      setSelectedOption({ type: data.value, open_at: moment().toISOString() });
    } else {
      setSelectedOption(null);
    }
  };
  return (
    <Select
      classNamePrefix={'select'}
      className={'select-container select-lg'}
      placeholder={'Select drip method...'}
      components={{
        DropdownIndicator,
        IndicatorSeparator: () => null
      }}
      options={selectOptions}
      onChange={onSelect}
      isSearchable={false}
      defaultValue={
        defaultValue ? selectOptions.find((option) => option.value === defaultValue.type) : selectOptions[0]
      }
    />
  );
});
