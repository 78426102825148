import { CustomDispatch } from 'redux';

import { createList, deleteList, editList, getListDetail, getLists } from '../../../../API/lists-api';
import { ListsActions } from './ListsActions';
import { ICreateListRequest, IList } from './ListsState';

export const ListsActionAsync = {
  getLists: () => async (dispatch: CustomDispatch) => {
    try {
      dispatch(ListsActions.getLists.started());
      const response = await getLists();
      if (response.data) {
        dispatch(ListsActions.getLists.done({ result: response.data }));
      }
    } catch (error) {
      dispatch(ListsActions.getLists.failed({ error }));
    }
  },
  getListDetail: (id: string) => async (dispatch: CustomDispatch) => {
    try {
      dispatch(ListsActions.getListDetail.started(id));
      const response = await getListDetail(id);
      if (response.data) {
        dispatch(ListsActions.getListDetail.done({ result: response.data, params: id }));
      }
    } catch (error) {
      dispatch(ListsActions.getListDetail.failed({ error, params: id }));
    }
  },
  createList: (data: ICreateListRequest, callback?: (list: IList) => void) => async (dispatch: CustomDispatch) => {
    try {
      dispatch(ListsActions.createList.started(data));
      const response = await createList(data);
      if (response.data) {
        dispatch(ListsActions.createList.done({ result: response.data, params: data }));
        if (typeof callback === 'function') {
          callback(response.data);
        }
        return response.data?.id;
      }
    } catch (error) {
      dispatch(ListsActions.createList.failed({ error, params: data }));
    }
  },
  editList: (id: string, data: ICreateListRequest, callback?: () => void) => async (dispatch: CustomDispatch) => {
    try {
      dispatch(ListsActions.editList.started(data));
      const response = await editList(id, data);
      if (response.data) {
        dispatch(ListsActions.editList.done({ result: response.data, params: data }));
        if (typeof callback === 'function') {
          callback();
        }
      }
    } catch (error) {
      dispatch(ListsActions.editList.failed({ error, params: data }));
    }
  },
  deleteList: (id: string, callback: () => void) => async (dispatch: CustomDispatch) => {
    try {
      dispatch(ListsActions.deleteList.started({ id }));
      await deleteList(id);
      dispatch(ListsActions.deleteList.done({ params: { id } }));
      callback();
    } catch (error) {
      dispatch(ListsActions.deleteList.failed({ error, params: { id } }));
    }
  }
};
