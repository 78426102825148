import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ColumnDescription, SelectRowProps, TableChangeState, TableChangeType } from 'react-bootstrap-table-next';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useIntercom } from 'react-use-intercom';
import { yupResolver } from '@hookform/resolvers/yup';
import { debounce } from 'lodash';

import classNames from 'classnames';
import styles from './product-list.module.scss';
import { Icon32Cart, Icon32Webinar, IconNewWindow, IconTrash } from '../../../../components/UI/icons';

import { EmptyList } from '../../../../components/empty-list/EmptyList';
import { ItemStatus } from '../../../../components/itemStatus/ItemStatus';
import { NoSearchResult } from '../../../../components/no-search-result/NoSearchResult';
import { CustomTooltip, Loader, ModalDelete } from '../../../../components/UI';
import { RemotePagination } from '../../../../components/UI/pagination/RemotePagination';
import { PopupCard } from '../../../../components/UI/popup-card/PopupCard';
import { ValidationSchema } from '../../../../helpers/validationSchema';
import { LoadState } from '../../../../store';
import { settingsActions } from '../../../../store/settings';
import { coursePaths } from '../../../lms/courses/routes/CourseRoutes';
import { courseActionAsync } from '../../../lms/courses/store/CourseActionAsync';
import { FieldsEdit } from '../../fields/FieldsEdit';
import { FormatterType } from '../../lists/listLists/config/listTableConfig';
import { IListField } from '../../lists/store/ListsState';
import { CoursesListHeader } from '../common/courses-list-header/CoursesListHeader';
import { ProductListHeader } from '../common/product-list-header/ProductListHeader';
import { ProductCard } from '../product-card/ProductCard';
import { ProductDataSelector } from '../store/ProductDataSelector';
import { IProductListField } from '../store/ProductListState';
import { ProductsActionAsync } from '../store/ProductsActionAsync';
import { IProduct, IProductField } from '../store/ProductsState';

type RowType = Record<string, string> & {
  id: string;
  data: IProduct;
};

const selectRow: SelectRowProps<any> = {
  mode: 'radio',
  clickToSelect: true,
  hideSelectColumn: true,
  classes: styles.selectedRow
};

interface IProps {
  fields: IProductListField[];
  schema: ValidationSchema;
  productType: 'course' | 'regular';
  sortFields: string[];
}

export const ProductList = memo(({ fields, schema, sortFields, productType }: IProps) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { update } = useIntercom();
  const searchString = useSelector((state) => state.settings.searchString);
  const [isSearch, setIsSearch] = useState(false);
  const [isPageChanged, setIsPageChanged] = useState(true);
  const [productId, setProductId] = useState<string>();
  const isFieldSettingsShow = useSelector((state) => state.settings.isSidebarShow);
  const { isPayloadShow } = useSelector((state) => state.settings);
  const productDataMemo = useMemo(ProductDataSelector, []);
  const { productData, loadState, page_size, page, total } = useSelector(productDataMemo);
  const [productsTable, setProductsTable] = useState<{
    items: IProductField[];
    totalSize: number;
    page: number;
    sizePerPage: number;
  }>({ items: productData, totalSize: 0, page: 1, sizePerPage: 25 });
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState<string>();
  const [selectedProductName, setSelectedProductName] = useState<string>();
  const initialCount = useRef<number>(0);
  const isCourseProduct = productType === 'course';
  const [loading, setLoading] = useState(false);

  const changeHandler = useCallback(
    (searchString: string) => {
      if (!isPageChanged) {
        setIsSearch(searchString !== '');
        dispatch(
          ProductsActionAsync.getProducts(
            productType,
            {
              page: 1,
              page_size: 25
            },
            searchString,
            sortFields,
            () => {
              setIsPageChanged(false);
            }
          )
        );
      }
    },
    [dispatch, isPageChanged, productType, sortFields]
  );

  const debouncedSearch = useMemo(() => debounce(changeHandler, 300), [changeHandler]);

  const debouncedChangeHandler = useCallback(debouncedSearch, [debouncedSearch]);

  useEffect(() => {
    debouncedChangeHandler(searchString);
  }, [debouncedChangeHandler, searchString]);

  const headerFormatter = (column: ColumnDescription<RowType, IListField>) => {
    if (column.formatExtraData?.field?.scope === 'price') {
      return (
        <>
          {column.text}
          <span className={styles.currencySign}>$</span>
        </>
      );
    }
    return <>{column.text}</>;
  };

  const columnFormatter = useCallback((cell: string, row: RowType, index: number, data: IListField) => {
    if (cell === undefined) {
      return '-';
    }
    if (data.field?.scope === 'price') {
      // @ts-ignore
      return cell.amount || 0;
    }
    if (data.field?.scope === 'title') {
      return (
        <div className={'d-flex align-items-center flex-grow-1'}>
          <div className={styles.title}>{cell}</div>
        </div>
      );
    }
    return typeof cell !== 'object' ? cell : '-';
  }, []);

  const rowActionsFormatter: FormatterType<RowType> = (cell, row) => {
    return (
      <div className={'d-flex align-items-center h-100'}>
        <div className="value">{cell}</div>
        <div className={styles.buttons}>
          <CustomTooltip customText={'Delete product'} direction={'top'}>
            <button
              type={'button'}
              id={row.id}
              className={classNames(styles.delete, 'btn btn-icon')}
              onClick={onDeleteProduct}
            >
              <IconTrash />
            </button>
          </CustomTooltip>
        </div>
      </div>
    );
  };

  const rowStateFormatter: FormatterType<RowType> = (cell, row) => (
    <div className={'d-flex align-items-center h-100'}>
      {cell && (
        <div className="value">
          <ItemStatus label={cell} />
        </div>
      )}
      <div className={styles.buttons}>
        <CustomTooltip customText={'Open course'} direction={'top'}>
          <button type={'button'} id={row.id} className={'btn btn-icon'} onClick={openCourse}>
            <IconNewWindow />
          </button>
        </CustomTooltip>
      </div>
    </div>
  );

  const openCourse = (e: React.SyntheticEvent) => {
    e.stopPropagation();
    const id = e.currentTarget.id;
    if (id) {
      const currentRow = productsTable.items.find((row) => row.id === id);
      const courseRelationField = fields?.find((field) => field.field?.scope === 'course_relation');
      if (currentRow && courseRelationField) {
        window.open(
          `${window.location.origin}${coursePaths.courseLessons(currentRow[courseRelationField.field_id])}`,
          '_blank'
        );
      }
    }
  };

  const onDeleteProduct = (e: React.SyntheticEvent) => {
    e.stopPropagation();
    const id = e.currentTarget.id;
    const currentRow = productsTable.items.find((row) => row.id === id);
    const nameField = fields?.find((field) => field.field?.scope === 'title');
    if (currentRow?.id && nameField) {
      setSelectedProduct(id);
      setSelectedProductName(currentRow[nameField.field_id]);
      setShowDeleteModal(true);
    }
  };

  const deleteProductHandler = () => {
    if (selectedProduct) {
      setShowDeleteModal(false);
      dispatch(ProductsActionAsync.deleteProduct(selectedProduct));
    }
  };

  const methods = useForm({
    defaultValues: {},
    shouldUnregister: false,
    resolver: yupResolver(schema)
  });

  useEffect(() => {
    if (loadState === LoadState.allIsLoaded) {
      initialCount.current = productData.length;
      setProductsTable({ items: productData, page: page, sizePerPage: page_size, totalSize: total });
    }
  }, [productData, loadState, page, page_size, total]);

  useEffect(() => {
    if (!isPayloadShow) {
      setProductId(undefined);
    }
  }, [isPayloadShow]);

  useEffect(() => {
    update({
      horizontalPadding: isPayloadShow ? 532 : 20
    });
  }, [isPayloadShow, update]);

  useEffect(() => {
    return () => {
      update({
        horizontalPadding: 20
      });
    };
  }, [update]);

  useEffect(() => {
    if (productId) {
      dispatch(settingsActions.setPayloadShowing({ isPayloadShow: true }));
    }
  }, [dispatch, productId]);

  useEffect(() => {
    return () => {
      dispatch(settingsActions.setPayloadShowing({ isPayloadShow: false }));
    };
  }, [dispatch]);

  const rowClickHandler = (e: Event, column: ColumnDescription, columnIndex: number, row: RowType) => {
    setProductId(row.id);
  };

  const openProductCard = () => {
    setProductId(undefined);
    dispatch(settingsActions.setPayloadShowing({ isPayloadShow: true }));
  };

  const columnsData: ColumnDescription<RowType>[] = [
    {
      dataField: 'id',
      text: 'Id',
      classes: classNames('col-1'),
      headerClasses: 'col-1'
    },
    ...fields
      .filter((field) => field.field && !['image', 'description', 'course_relation'].includes(field.field.scope))
      .map((field) => {
        const isLatestColumn = field.field && ['sku', 'state'].includes(field.field?.scope);
        let formatter = isLatestColumn ? rowActionsFormatter : columnFormatter;
        if (field.field?.scope === 'state') {
          formatter = rowStateFormatter;
        }
        return {
          dataField: field.field_id,
          text: field.field?.payload.title || '-',
          formatter: formatter,
          headerFormatter: headerFormatter,
          formatExtraData: field,
          classes: classNames(field.field?.scope === 'title' ? 'col' : 'col-2', { [styles.actions]: isLatestColumn }),
          headerClasses: field.field?.scope === 'title' ? 'col' : 'col-2',
          events: {
            onClick: rowClickHandler
          }
        };
      })
  ];

  const handleTableChange = (type: TableChangeType | 'search', { page, sizePerPage }: TableChangeState<unknown>) => {
    if (type === 'search') {
      setIsPageChanged(false);
    }
    if (type === 'pagination') {
      setIsPageChanged(true);
      dispatch(
        ProductsActionAsync.getProducts(productType, { page: page, page_size: sizePerPage }, searchString, sortFields)
      );
    }
  };

  const onCloseDeletePopup = () => {
    setShowDeleteModal(false);
  };

  const onCourseCreated = (id: string) => {
    setLoading(false);
    history.push(coursePaths.courseLessons(id));
  };

  const createCourseHandler = () => {
    setLoading(true);
    dispatch(courseActionAsync.createCourse('custom', onCourseCreated));
  };

  const onCreateProduct = () => {
    dispatch(ProductsActionAsync.getProducts(productType, { page, page_size }, searchString, sortFields));
  };

  return (
    <>
      {loadState === LoadState.firstLoad && <Loader />}
      {initialCount.current > 0 &&
        (isCourseProduct ? (
          <CoursesListHeader
            title={'Courses'}
            totalCount={productsTable.items.length}
            onClick={createCourseHandler}
            loading={loading}
          />
        ) : (
          <ProductListHeader title={'Products'} onClick={openProductCard} />
        ))}
      {loadState === LoadState.allIsLoaded && columnsData.length > 0 && (
        <>
          <RemotePagination
            page={productsTable.page}
            sizePerPage={productsTable.sizePerPage}
            totalSize={productsTable.totalSize}
            onTableChange={handleTableChange}
            tableProps={{
              keyField: 'id',
              columns: columnsData,
              selectRow: { ...selectRow, selected: isPayloadShow ? (productId ? [productId] : undefined) : [] },
              rowClasses: styles.row,
              data: productsTable.items
            }}
          />
        </>
      )}

      {loadState === LoadState.idle &&
        (isSearch ? (
          <NoSearchResult searchString={searchString} />
        ) : (
          <EmptyList
            onClick={isCourseProduct ? createCourseHandler : openProductCard}
            button={isCourseProduct ? 'Create course' : 'Add products'}
            title={isCourseProduct ? 'You have no created courses' : 'You have no added products'}
            description={
              isCourseProduct
                ? 'Start by creating a course to sell it through the platform'
                : 'You need to add products to sell them through the platform'
            }
            icon={isCourseProduct ? <Icon32Webinar width={64} height={64} /> : <Icon32Cart width={64} height={64} />}
          />
        ))}
      <FormProvider {...methods}>
        <PopupCard isShow={isPayloadShow}>
          <ProductCard productId={productId} type={productType} onCreate={onCreateProduct} />
        </PopupCard>
      </FormProvider>
      <PopupCard isShow={isFieldSettingsShow} fullHeight={true}>
        <FieldsEdit
          entity={isCourseProduct ? 'course' : 'product'}
          title={'Edit product fields'}
          description={
            'Change the order and the content for the working fields. The settings are applied to all products.'
          }
        />
      </PopupCard>
      <ModalDelete
        onClose={onCloseDeletePopup}
        isShowed={showDeleteModal}
        onDelete={deleteProductHandler}
        title={
          <>
            Product <span>{selectedProductName}</span> will be deleted
          </>
        }
        description={'This product will be permanently deleted. You will not be able to restore it!'}
      />
    </>
  );
});
