import { reducerWithInitialState } from 'typescript-fsa-reducers';

import { LoadState, newState } from '../../../store';
import { AuthActions } from './AuthActions';
import { AuthTokenInitialState } from './AuthState';

export const authReducer = reducerWithInitialState(AuthTokenInitialState)
  .case(AuthActions.authSetToken, (state, result) => {
    return newState(state, {
      authToken: result,
      loadState: LoadState.allIsLoaded,
      error: null
    });
  })
  .case(AuthActions.authTokenDelete, (state) => {
    return newState(state, {
      authToken: null,
      avatar: '',
      mail: null,
      error: null,
      loadState: LoadState.allIsLoaded
    });
  })
  .case(AuthActions.auth.started, (state) => ({
    ...state,
    error: null,
    mail: null,
    authToken: null,
    loadState: LoadState.firstLoad
  }))
  .case(AuthActions.auth.done, (state, { result, params }) => {
    let updateOdj = {};
    if (params.request && params.request.provider === 'google') {
      updateOdj = {
        // mail: params.request.payload.profileObj?.email,
        // avatar: params.request.payload.profileObj.imageUrl
      };
    }
    return newState(state, {
      authToken: result,
      ...updateOdj,
      error: null,
      loadState: LoadState.idle
    });
  })
  .case(AuthActions.auth.failed, (state, { error }) => ({
    ...state,
    loadState: LoadState.error,
    error
  }))
  .case(AuthActions.setCurrentOrganization, (state, organization) => {
    return newState(state, {
      ...state,
      currentOrganization: organization
    });
  });
