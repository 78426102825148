import { useCallback, useMemo, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { fileUploadActionAsync } from 'src/app/layout/shared-components/upload-worker/store/FileUploadActionAsync';

import classNames from 'classnames';
import styles from '../course-settings.module.scss';
import { Icon32Picture } from '../../../../../components/UI/icons';

import { ImageBGUpload } from '../../../../../components/image-bg-upload/ImageBGUpload';
import { coursePaths } from '../../routes/CourseRoutes';
import { courseActionAsync } from '../../store/CourseActionAsync';
import { CourseSelector } from '../../store/CourseSelector';
import { CourseCategoriesType, ICourse, ICourseSettings } from '../../store/CourseState';
import { CourseCategory } from '../course-category/CourseCategory';
import { courseSettingsPaths } from '../routes/CourseSettingsRoutes';
import { GeneralForm } from './GeneralForm';

// const GeneralSoon = lazy(() => import('./GeneralSoon').then(({ GeneralSoon }) => ({ default: GeneralSoon })));

export type FormFields = {
  image: FileList | null;
  category: CourseCategoriesType | null;
};

export const CourseGeneral = () => {
  const [imageChanged, setImageChanged] = useState(false);
  const path = useRouteMatch<{ id: string }>([courseSettingsPaths.general(), coursePaths.courseSettings()]);
  const id = path?.params.id;
  const courseMemo = useMemo(() => CourseSelector(id), [id]);
  const { course } = useSelector(courseMemo);
  const dispatch = useDispatch();

  const formMethods = useForm<FormFields>({
    defaultValues: {
      image: null,
      category: null
    }
  });

  const { isDirty } = formMethods.formState;

  const onImageChange = useCallback(() => {
    setImageChanged(true);
  }, []);

  const onSaveCallback = useCallback(
    (course?: ICourse) => {
      formMethods.reset({ image: null, category: course?.settings.category || null });
      setImageChanged(false);
    },
    [formMethods]
  );

  const onSave = useCallback(async () => {
    if (id) {
      const data = formMethods.getValues();
      let settings: Partial<ICourseSettings> = {};
      if (data.category) {
        settings.category = data.category;
      }
      if (data.image && data.image.length) {
        await dispatch(
          fileUploadActionAsync.retrieveUrlAndUploadFile(data.image[0], (result) => {
            settings.thumbnail = result;
          })
        );
      }
      if (imageChanged && data.image && data.image.length === 0) {
        settings.thumbnail = null;
      }
      dispatch(courseActionAsync.editCourseSettings(id, settings, onSaveCallback));
    }
  }, [dispatch, formMethods, id, imageChanged, onSaveCallback]);

  const onCancel = useCallback(() => {
    formMethods.reset({ image: null, category: course?.settings.category || null });
    setImageChanged(false);
  }, [course?.settings.category, formMethods]);

  return (
    <>
      <FormProvider {...formMethods}>
        <h2 className={classNames(styles.title, 'mb-3')}>General course settings</h2>
        <DndProvider backend={HTML5Backend}>
          <ImageBGUpload
            image={course?.settings.thumbnail}
            height={432}
            width={768}
            fileLimit={5 * 1024 * 1024}
            onChange={onImageChange}
          >
            <Icon32Picture className={styles.icon} />
            <div className={styles.text}>Upload course thumbnail or click to browse</div>
            <div className={styles.note}>
              5 MB maximum. Only PNG or JPG supported. <br />
              We recommend to upload images with 16:9 aspect ratio — 1200 x 675 pixels.
            </div>
          </ImageBGUpload>
        </DndProvider>
        <CourseCategory category={course?.settings.category} />
        {/* <Suspense fallback={<Loader />}>
          <GeneralSoon />
        </Suspense> */}
        <GeneralForm
          showPanel={isDirty || imageChanged}
          onCancel={onCancel}
          onSave={formMethods.handleSubmit(onSave)}
        />
      </FormProvider>
    </>
  );
};
