import { memo, useCallback, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { map } from 'lodash';

import { ListsAttacher } from '../../../../contact/common/contact-card-lists/ListsAttacher';
import { CreateListModal } from '../../../../lists/modals/createListModal/CreateListModal';
import { IList } from '../../../../lists/store/ListsState';
import { addToList, filterLists, removeFromList } from './helper';

interface IProps {
  selectedLists?: string[] | null | boolean;
  lists: IList[];
  setSelected: (data: Pick<IList, 'id' | 'title'>[]) => void;
  selected: Pick<IList, 'id' | 'title'>[];
}

export const ActionAddToListWrapper = memo(({ selectedLists, lists, selected, setSelected }: IProps) => {
  const [showCreateModal, setShowCreateModal] = useState(false);
  const { setValue, clearErrors } = useFormContext();

  useEffect(() => {
    if (selectedLists && Array.isArray(selectedLists) && lists.length > 0) {
      const data = filterLists(selectedLists, lists);
      setSelected(data);
    }
  }, [lists, selectedLists, setSelected]);

  const onAttachList = useCallback(
    (id: string) => {
      const data = addToList(selected, lists, id);
      setSelected(data);
      setValue('action_add_to_list', map(data, 'id'), { shouldDirty: true, shouldValidate: true });
      clearErrors('action_add_to_list');
    },
    [clearErrors, lists, selected, setSelected, setValue]
  );

  const onDetachList = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      const id = event.currentTarget.dataset['id'];
      if (id) {
        const data = removeFromList(selected, id);
        setSelected(data);
        setValue('action_add_to_list', map(data, 'id'), { shouldDirty: true, shouldValidate: true });
      }
    },
    [selected, setSelected, setValue]
  );

  const onCloseCreateModal = useCallback(() => {
    setShowCreateModal(false);
  }, []);

  const onCreateList = useCallback(() => {
    setShowCreateModal(true);
  }, []);

  return (
    <>
      <ListsAttacher
        attachListHandler={onAttachList}
        detachListHandler={onDetachList}
        onCreateList={onCreateList}
        isShowCreate={true}
        attachedIds={selected}
        showTitle={false}
      />
      <CreateListModal onCloseModal={onCloseCreateModal} show={showCreateModal} />
    </>
  );
});
