import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import styles from '../../../styles/components/sidebar.module.scss';

import { sidebarStudentCabinetMenu } from '../../App';
import { StudentCabinetSelector } from '../../modules/student-cabinet/store/StudentCabinetSelector';
import { LoadState } from '../../store';
import { Logo } from '../shared-components/Logo';
import { StudentCabinetSidebarItem } from '../shared-components/StudentCabinetSidebarItem';
import { SidebarAccount } from './sidebar-account/SidebarAccount';

export const SidebarStudentCabinet = () => {
  const cabinetSettingsMemo = useMemo(StudentCabinetSelector, []);
  const { cabinetSettings, loadCabinetSettingsState } = useSelector((state) => cabinetSettingsMemo(state));
  const loaded = loadCabinetSettingsState === LoadState.allIsLoaded || loadCabinetSettingsState === LoadState.error;

  return (
    <aside className={'sidebar sidebar-left sidebar-small m-0 d-none d-lg-block'}>
      <div className="navbar-vertical-container d-flex flex-column align-items-center justify-content-between">
        <ul className="navbar-nav side-menu w-100">
          <li className={`${styles.navItem} ${styles.navItemLogo} nav-item py-3 navItemLogo`}>
            {loaded && <Logo themeLogo={cabinetSettings?.theme_logo} />}
          </li>
          {sidebarStudentCabinetMenu.map(({ name, icon, to }, index) => (
            <StudentCabinetSidebarItem icon={icon} index={index} name={name} to={to} key={index} />
          ))}
        </ul>
        <SidebarAccount className={'mb-4'} />
      </div>
    </aside>
  );
};
