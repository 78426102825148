import React, { CSSProperties, SyntheticEvent, useEffect, useMemo, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { useDispatch, useSelector } from 'react-redux';

import classNames from 'classnames';
import styles from './events.module.scss';
import { Icon32Plus } from '../../../components/UI/icons';

import { _id } from '../../../helpers';
import { useOutsideClick } from '../../../hooks/use-outside-click';
import { IAutomationNodeBuilder, NodeTypes, PaletteAutomationElementTypes } from '../../../interfaces';
import { settingsActions } from '../../../store/settings';
import { builderActionAsync } from '../../common/builder/store/BuilderActionAsync';
import { EventNode } from './eventNode/EventNode';
import { EventNodesSelector } from './EventNodesSelector';
import { EventsPopup } from './eventsPopup/EventsPopup';

interface IProps {
  automationId: string;
  hidePopup?: number;
  node: IAutomationNodeBuilder<PaletteAutomationElementTypes>;

  deleteNode(id: string, nodeKey: string): void;

  payload(id: string): void;

  onHover(event: React.SyntheticEvent): void;

  update(node: IAutomationNodeBuilder<PaletteAutomationElementTypes>, callback?: () => void): void;
}

export const Events = ({ automationId, deleteNode, hidePopup, node, payload, onHover, update }: IProps) => {
  const dispatch = useDispatch();
  const [showPalette, setShowPalette] = useState(false);
  const [style, setStyle] = useState<Partial<CSSProperties>>();
  const nodesMemo = useMemo(EventNodesSelector, []);
  const { nodes } = useSelector((state) => nodesMemo(state));
  const [params, setParams] = useState<DOMRect>();
  const [selectedNode, setSelectedNode] = useState<string>();
  const eventsNode = useRef<HTMLDivElement | null>(null);
  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const canvas = document.getElementById('funnel-canvas');
    let canvasCenter = 250;
    if (canvas?.getBoundingClientRect().height) {
      canvasCenter = canvas?.getBoundingClientRect().height / 2;
    }
    setStyle({
      top: `${canvasCenter - (124 + nodes.length * 88) / 2}px`
    });
  }, [nodes.length]);

  useEffect(() => {
    if (hidePopup && hidePopup > 0) {
      setShowPalette(false);
    }
  }, [hidePopup]);

  const togglePopup = () => {
    setShowPalette(!showPalette);
    const positions = eventsNode.current?.getBoundingClientRect();
    setParams(positions);
  };

  const onSelect = async (e: SyntheticEvent) => {
    const elementType = e.currentTarget.id as NodeTypes;
    dispatch(builderActionAsync.addNode(automationId, elementType, 'automation'));
    update(node);
    setShowPalette(false);
  };

  const handleDelete = (id: string) => {
    dispatch(settingsActions.setPayloadShowing({ isPayloadShow: false }));
    deleteNode(automationId, id);
    update(node);
  };

  const setSelected = (id: string) => {
    setSelectedNode(id);
  };

  useOutsideClick(
    ref,
    () => {
      setSelectedNode('');
    },
    ['payload']
  );

  return (
    <div
      className={classNames(styles.eventsContainer, 'element-node')}
      ref={eventsNode}
      id={_id(node.id)}
      data-node-id={node.id}
      onMouseEnter={onHover}
      onMouseLeave={onHover}
      style={style}
    >
      <div className={styles.eventsNode} ref={ref}>
        {nodes.map((event) => (
          <EventNode
            node={event}
            onDelete={handleDelete}
            onSelectNode={setSelected}
            selected={selectedNode}
            onDoubleClick={payload}
            key={event.id}
          />
        ))}
        <div className={classNames(styles.addEvent)}>
          Add event
          <button
            type={'button'}
            className={classNames('btn btn-gradient-secondary', styles.button)}
            onClick={togglePopup}
          >
            <Icon32Plus />
          </button>
        </div>
      </div>
      {showPalette &&
        ReactDOM.createPortal(
          <EventsPopup onClose={togglePopup} onSelect={onSelect} params={params} />,
          document.querySelector('#root') as Element
        )}
    </div>
  );
};
