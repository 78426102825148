import React, { useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { useDispatch } from 'react-redux';

import classNames from 'classnames';
import styles from './sender-item.module.scss';
import {
  IconCheckMark,
  IconNotAllowed,
  IconPencilOnPaper,
  IconSendMessage,
  IconSpinner,
  IconTrash,
  IconWarning
} from '../../../../../components/UI/icons';

import { Button, CustomTooltip, DropMenu } from '../../../../../components/UI';
import { ISender } from '../../../../../interfaces';
import { EmailActionAsync } from '../../../../common/email-editor';
import { EmailActions } from '../../../../common/email-editor/store/EmailActions';
import { SenderEdit } from '../sender-edit/SenderEdit';

interface IProps {
  sender: ISender;
}

export const SenderItem = ({ sender }: IProps) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const deleteSender = () => {
    if (sender.state === 'non-uniq') {
      dispatch(EmailActions.deleteErrorSender(sender.id));
    } else {
      dispatch(EmailActionAsync.deleteSender(sender.id));
    }
  };

  const resendConfirmation = () => {
    dispatch(EmailActionAsync.resendSenderConfirmation(sender.id));
  };

  const setAsDefault = () => {
    setLoading(true);
    dispatch(
      EmailActionAsync.editSender(sender.id, {is_default: true}, () => {
        setLoading(false);
      })
    );
  };

  const updateStatus = () => {
    setLoading(true);
    dispatch(
      EmailActionAsync.getSender(sender.id, () => {
        setLoading(false);
      })
    );
  };

  const onToggleDelete = () => {
    if (sender.is_default) {
      return;
    }
    setShowDelete(!showDelete);
  };

  const setEdit = () => {
    setIsEditing(true);
  };

  return (
    <div
      className={classNames(styles.container, {
        [styles.error]: sender.state === 'non-uniq',
        [styles.loading]: loading
      })}
    >
      {isEditing ? (
        <SenderEdit email={sender.email} setEditing={setIsEditing} id={sender.id} />
      ) : (
        <>
          <div
            className={classNames(styles.status, {
              [styles.confirmed]: sender.state === 'confirmed',
              [styles.wrong]: sender.state === 'pending'
            })}
          >
            {loading ? (
              <IconSpinner className={'spinner text-primary'} />
            ) : (
              <>
                {sender.state === 'confirmed' && <IconCheckMark />}
                {sender.state === 'pending' && <IconWarning />}
                {sender.state === 'non-uniq' && <IconNotAllowed />}
              </>
            )}
          </div>
          <div className={styles.info}>
            <div className={styles.sender}>{sender.email}</div>
            {sender.state === 'pending' && !loading && (
              <div className={styles.note}>
                We sent you a message to confirm the use of this email address as sender.
              </div>
            )}
            {sender.state === 'non-uniq' && (
              <div className={styles.note}>
                This email address is already used in another organization. Please, contact us for more details in live
                chat or via email everactio@gmail.com
              </div>
            )}
            <div className={styles.action}>
              {sender.state === 'pending' && !loading && (
                <span className={styles.link} onClick={updateStatus}>
                  Update status
                </span>
              )}
              {sender.state === 'confirmed' &&
                (sender.is_default ? (
                  'Default sender'
                ) : (
                  <span className={classNames(styles.link, { [styles.disabled]: loading })} onClick={setAsDefault}>
                    Set as default
                  </span>
                ))}
            </div>
          </div>
          <div className={styles.actions}>
            {sender.state === 'pending' && (
              <CustomTooltip customText={'Resend confirmation'} direction={'top'}>
                <button className={'btn btn-icon mr-1'} onClick={resendConfirmation} disabled={loading}>
                  <IconSendMessage />
                </button>
              </CustomTooltip>
            )}
            {sender.origin === 'generated' ? (
              <CustomTooltip customText={'Change sender prefix'} direction={'left'}>
                <button className={'btn btn-icon'} onClick={setEdit} disabled={loading}>
                  <IconPencilOnPaper />
                </button>
              </CustomTooltip>
            ) : (
              <DropMenu
                show={showDelete}
                onToggle={onToggleDelete}
                node={
                  <CustomTooltip
                    customText={sender.is_default ? 'Default sender cannot be deleted' : 'Delete sender'}
                    direction={'top'}
                  >
                    <button
                      className={'btn btn-icon text-danger'}
                      disabled={sender.is_default || loading}
                      onClick={sender.state === 'non-uniq' ? deleteSender : onToggleDelete}
                    >
                      <IconTrash />
                    </button>
                  </CustomTooltip>
                }
                drop={'down'}
              >
                <Dropdown.Menu>
                  <div className={styles.confirmation}>
                    <strong>Delete sender</strong>
                    Email messages that were sent from this address will no longer be sent
                    <div className={styles.buttons}>
                      <Button onClick={onToggleDelete} btnStyle={'secondary'} withIcon={false}>
                        Cancel
                      </Button>
                      <Button onClick={deleteSender} btnStyle={'warning'} withIcon={false}>
                        Delete
                      </Button>
                    </div>
                  </div>
                </Dropdown.Menu>
              </DropMenu>
            )}
          </div>
        </>
      )}
    </div>
  );
};
