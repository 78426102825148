import React from 'react';
import { Nav } from 'react-bootstrap';

import styles from './list-filter.module.scss';

import { Filter } from '../../interfaces/filters';

interface IProps {
  activeKey: string;

  onSelect(eventKey: string | null, e: React.SyntheticEvent<unknown>): void;
}

const filterList: Filter[] = [
  { key: 'all', name: 'All' },
  { key: 'draft', name: 'Drafted' },
  { key: 'published', name: 'Published' },
  { key: 'archived', name: 'Archive' }
];

export const ListFilter = React.memo(({ activeKey, onSelect }: IProps) => {
  return (
    <div className={styles.filters}>
      <Nav activeKey={activeKey} onSelect={onSelect}>
        {filterList.map(({ key, name }, index) => {
          return (
            <Nav.Item key={index}>
              <Nav.Link eventKey={key}>{name}</Nav.Link>
            </Nav.Item>
          );
        })}
      </Nav>
    </div>
  );
});
