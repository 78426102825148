import { useEffect, useState } from 'react';
import moment from 'moment';
import { flatMapDeep } from 'lodash';

import classNames from 'classnames';
import styles from './list-item.module.scss';
import { Icon16Survey, IconCheckMark, IconReload, IconWebinar } from '../../../../../../components/UI/icons';

import { getMembers } from '../../../../../../components/course/course/CourseHelpers';
import { getCourseNodeTree } from '../../../../../../helpers';
import { ICourseNode } from '../../../../courses/store/CourseState';
import { Breadcrumbs } from '../../breadcrumps/Breadcrumbs';

interface IProps {
  lessonItem: ICourseNode;
  location: boolean;
  hierarchy?: ICourseNode[];
}

export const ListItem = ({ lessonItem, location, hierarchy }: IProps) => {
  const quizLessons = flatMapDeep(lessonItem.children, getMembers).filter((x) => x.type === 'block:quiz');
  const [treeNodes, setTreeNodes] = useState<ICourseNode[]>([]);

  useEffect(() => {
    if (hierarchy) {
      setTreeNodes(getCourseNodeTree(hierarchy, lessonItem.id, ['folder']));
    }
  }, [hierarchy, lessonItem.id]);

  return (
    <div className={classNames(styles.container, { [styles.passed]: lessonItem.is_passed })}>
      <div className={styles.wrapper}>
        {lessonItem.is_new ? (
          <div className={styles.newLabel}>New</div>
        ) : lessonItem.is_updated ? (
          <IconReload className={styles.reload} />
        ) : lessonItem.is_passed ? (
          <IconCheckMark className={styles.checkmark} />
        ) : (
          <IconWebinar className={styles.icon} />
        )}
        <div className={classNames(styles.title, { [styles.new]: lessonItem.is_new })}>
          {lessonItem.title}
          {lessonItem.is_new && (
            <div className={styles.description}>The lesson was added after the student started learning</div>
          )}
          {lessonItem.is_updated && (
            <div className={styles.description}>Content in this lesson was updated after the student completed it</div>
          )}
        </div>
        {quizLessons.length > 0 && (
          <div className={styles.quiz}>
            <Icon16Survey />
          </div>
        )}
        <div className={styles.date}>
          {lessonItem.is_passed ? moment(lessonItem.passed_at).format('DD MMM YYYY') : 'not passed'}
        </div>
      </div>
      {location && <Breadcrumbs tree={treeNodes} className={styles.breadcrumbs} />}
    </div>
  );
};
