import { useCallback, useEffect, useRef, useState } from 'react';

export const useMouseDelta = (initialWidth: number, target: Element | null, callback?: () => void) => {
  const [result, setResult] = useState(initialWidth);
  const dragging = useRef(false);
  const previousClientX = useRef(0);

  const handleMouseMove = useCallback((e) => {
    if (!dragging.current) {
      return;
    }

    setResult((result) => {
      const change = e.clientX - previousClientX.current;
      previousClientX.current = e.clientX;
      const newResult = result + change;
      return newResult >= 740 ? 740 : newResult <= 416 ? 416 : newResult;
    });
  }, []);

  const handleMouseDown = useCallback((e) => {
    previousClientX.current = e.clientX;
    dragging.current = true;
  }, []);

  const handleMouseUp = useCallback(() => {
    dragging.current = false;
    callback?.();
  }, [callback]);

  useEffect(() => {
    if (target) {
      target.addEventListener('mousedown', handleMouseDown);
      window.addEventListener('mouseup', handleMouseUp);
      window.addEventListener('mousemove', handleMouseMove);
      return () => {
        target.removeEventListener('mousedown', handleMouseDown);
        window.removeEventListener('mouseup', handleMouseUp);
        window.removeEventListener('mousemove', handleMouseMove);
      };
    }
  }, [handleMouseDown, handleMouseUp, handleMouseMove, target]);

  return result;
};
