import { reducerWithInitialState } from 'typescript-fsa-reducers';

import { commonErrorHandler, commonFirstLoadHandler } from '../../../../helpers/common-reducer-handlers';
import { LoadState, newState } from '../../../../store';
import { FieldActions } from './FieldActions';
import { FieldInitialState, IFieldsState } from './FieldState';

export const fieldReducer = reducerWithInitialState<IFieldsState>(FieldInitialState)
  .case(FieldActions.getFields.started, commonFirstLoadHandler)
  .case(FieldActions.getFields.done, (state, { result }) => {
    return newState(state, {
      fields: result,
      loadState: LoadState.allIsLoaded,
      error: null
    });
  })
  .case(FieldActions.getFields.failed, commonErrorHandler)
  .case(FieldActions.createField.started, (state) => ({ ...state, loadState: LoadState.firstLoad, error: null }))
  .case(FieldActions.createField.done, (state, { result }) => {
    return newState(state, {
      fields: [...state.fields, result],
      loadState: LoadState.allIsLoaded,
      error: null
    });
  })
  .case(FieldActions.createField.failed, (state, { error }) => ({
    ...state,
    loadState: LoadState.error,
    error
  }))
  .case(FieldActions.editField.started, (state) => ({ ...state, loadState: LoadState.firstLoad, error: null }))
  .case(FieldActions.editField.done, (state, { result }) => {
    const newFields = state.fields.map((x) => (x.id === result.id ? result : x));
    return newState(state, {
      fields: newFields,
      loadState: LoadState.allIsLoaded,
      error: null
    });
  })
  .case(FieldActions.editField.failed, (state, { error }) => ({
    ...state,
    loadState: LoadState.error,
    error
  }))
  .case(FieldActions.deleteField.started, (state) => ({ ...state, loadState: LoadState.firstLoad, error: null }))
  .case(FieldActions.deleteField.done, (state, { params }) => {
    const removingIndexElement = state.fields.filter((x) => x.id !== params.id);
    return newState(state, {
      fields: removingIndexElement,
      loadState: LoadState.allIsLoaded,
      error: null
    });
  })
  .case(FieldActions.deleteField.failed, (state, { error }) => ({
    ...state,
    loadState: LoadState.error,
    error
  }))
  .case(FieldActions.reorderField.started, (state) => ({
    ...state,
    loadStateReordering: LoadState.firstLoad,
    error: null
  }))
  .case(FieldActions.reorderField.done, (state) => {
    return newState(state, {
      ...state,
      loadStateReordering: LoadState.allIsLoaded,
      error: null
    });
  })
  .case(FieldActions.reorderField.failed, (state, { error }) => ({
    ...state,
    loadStateReordering: LoadState.error,
    error
  }));
