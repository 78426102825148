import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import styles from './canvas-condition.module.scss';

import { useNodePayload } from '../../../../../hooks/use-node-payload';
import { PaletteAutomationElementTypes } from '../../../../../interfaces';
import { TagActionAsync } from '../../../../crm/tag/store/TagActionAsync';
import { TagComponent } from '../../../../crm/tag/TagComponent';

interface IProps {
  id: string;
  nodeId: string;
}

export const CanvasConditionTag = ({ id, nodeId }: IProps) => {
  const tag = useSelector((state) => state.tags.tags.find((x) => x.id === id));
  const node = useNodePayload<PaletteAutomationElementTypes.PROCESSOR_CONDITION>(nodeId);
  const dispatch = useDispatch();
  useEffect(() => {
    if (!tag) {
      dispatch(TagActionAsync.getTags());
    }
  }, [dispatch, tag]);

  return (
    <>
      {tag && (
        <div className={styles.conditionTextWrapper}>
          <span className={styles.conditionText}>
            Tag <TagComponent bigTag={tag} isShowDetach={false} isShowEdit={false} />{' '}
            {node?.payload.action === 'add' && 'was assigned'}
            {node?.payload.action === 'remove' && 'not assigned'}
          </span>
        </div>
      )}
    </>
  );
};
