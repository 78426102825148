import { memo } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';

import styles from './field.module.scss';
import { Icon32Cross } from '../../../components/UI/icons';

import { FieldPayloadType } from './FieldPayloadType';
import { FieldSelectorType } from './FieldSelectorType';
import { IFieldSettings } from './FieldSettings';
import { FieldTitle } from './FieldTitle';

interface IProps {
  saveField: boolean;
  selected?: IFieldSettings;

  hideAdd(): void;

  onSelectedType(val: IFieldSettings): void;

  onSaveField(): void;

  onClose(): void;
}

export const FieldCreator = memo(
  ({ saveField, selected, onSelectedType, onSaveField, onClose }: IProps) => {
    const { handleSubmit } = useFormContext();

    return (
      <Row className={styles.container}>
        {!saveField && (
          <>
            <Col lg={8} className={'pr-0'}>
              <label className={styles.crateTitle}>CREATE NEW FIELD</label>
              <FieldTitle fieldName={'Field name'} placeholder={'Label'} />
              <FieldSelectorType
                val={selected}
                checkSelected={onSelectedType}
                fieldName={'Field type'}
              />
              {selected && <FieldPayloadType types={selected} />}
            </Col>
            <Col
              className={
                'd-flex flex-column justify-content-between align-items-end px-0'
              }
            >
              <div className={styles.closeIcon} onClick={onClose}>
                <Icon32Cross className={styles.icon} />
              </div>
              <button
                type="button"
                onClick={handleSubmit(onSaveField)}
                className={'btn btn-gradient-secondary btn-md'}
              >
                Save
              </button>
            </Col>
          </>
        )}
      </Row>
    );
  }
);
