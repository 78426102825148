import { memo } from 'react';

import classNames from 'classnames';
import styles from './resultQuiz.module.scss';

interface IProps {
  percent: number;
  isCorrect: boolean;
  questionCorrectRequired?: number;
  totalScore: number;
  isPassed: boolean;
  pointsCount?: number;
}

export const ResultQuizAnswerCorrect = memo(
  ({ isCorrect, percent, questionCorrectRequired, pointsCount, totalScore, isPassed }: IProps) => {
    return (
      <>
        <div className={styles.text}>YOU ANSWERED</div>
        <div
          className={classNames({
            [styles.title100]: percent > 75,
            [styles.title75]: percent > 50 && percent <= 75,
            [styles.title50]: percent > 25 && percent <= 50,
            [styles.title25]: percent <= 25
          })}
        >
          {isCorrect ? totalScore : `${totalScore} points`}
        </div>
        {isCorrect && (
          <>
            <div className={styles.description}>questions correctly from {questionCorrectRequired} required</div>
            {!isPassed && (
              <div className={styles.notification}>
                You need to get at least {questionCorrectRequired} correct answers to pass the quiz
              </div>
            )}
          </>
        )}
        {!isCorrect && (
          <>
            <div className={styles.description}>out of the required {pointsCount}</div>
            {!isPassed && (
              <div className={styles.notification}>You need to get at least {pointsCount} points to pass the quiz</div>
            )}
          </>
        )}
      </>
    );
  }
);
