import React, { FC, useEffect } from 'react';

import classNames from 'classnames';
import styles from './image-upload.module.scss';

import { imageUrl } from '../image/Image';

interface IProps {
  name: string;
  bucket_name?: string;
  object_name?: string;
  isPreloaded: boolean;
  disabled?: boolean;
  size?: { width: number; height: number };
  setIsPreloaded(e: boolean): void;
  handleImageChange(e: React.SyntheticEvent<HTMLInputElement>): void;
  setImagePreviewUrl?(url: string | null): void;
  className?: string;
}

export const ImageUpload: FC<IProps> = ({
  name,
  bucket_name,
  object_name,
  isPreloaded,
  handleImageChange,
  setImagePreviewUrl,
  setIsPreloaded,
  size = { width: 96, height: 96 },
  disabled = false,
  children,
  className
}) => {
  useEffect(() => {
    if (!isPreloaded && bucket_name && object_name) {
      setImagePreviewUrl?.(imageUrl({ bucket_name, object_name, size }));
      setIsPreloaded(false);
    } else if (!isPreloaded && (!bucket_name || !object_name)) {
      setImagePreviewUrl?.(null);
      setIsPreloaded(false);
    }
  }, [bucket_name, isPreloaded, object_name, setImagePreviewUrl, setIsPreloaded, size, size.height, size.width]);

  return (
    <div className={styles.container}>
      <input
        type="file"
        className={classNames(styles.input, className)}
        accept="image/*"
        id={name}
        name={name}
        onChange={handleImageChange}
        disabled={disabled}
      />
      {children}
    </div>
  );
};
