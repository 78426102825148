import { memo } from 'react';
import { useState } from 'react';
import { FileSelect } from 'src/app/components/file-upload/upload/file-select/FileSelect';
import { FileUpload } from 'src/app/components/file-upload/upload/file-upload/FileUpload';
import { FileUploadControl } from 'src/app/components/file-upload/upload/FileUploadControl';
import { AudioField, TypeOfUploader } from 'src/app/interfaces/fileUpload';
import { IFileUploader } from 'src/app/layout/shared-components/upload-worker/store/FileUploaderState';
import { IUploadFileError } from 'src/app/modules/lms/courses/curriculum/common/upload-invalid-file/UploadInvalidFile';

import { IconHorizontalRange } from 'src/app/components/UI/icons';

interface IProps {
  audio?: AudioField;
  fileUploaded?: IFileUploader;
  typeOfUploader: TypeOfUploader | null;
  setTypeOfUploader: (type: TypeOfUploader | null) => void;
  onCloseModal: () => void;
  onSetAudio: (audioField: AudioField) => void;
  className?: string;
  onCancel: (file: IFileUploader) => void;
  retrieveAndUpload: (file: File) => void;
}

export const AudioControl = memo(
  ({
    audio,
    fileUploaded,
    typeOfUploader,
    onSetAudio,
    setTypeOfUploader,
    onCloseModal,
    className,
    onCancel,
    retrieveAndUpload
  }: IProps) => {
    const [audioError, setAudioError] = useState<IUploadFileError | null>(null);

    const onCancelCallback = () => {
      setTypeOfUploader(null);
    };

    const clearError = () => setAudioError(null);
    const handleFile = (files: FileList | null) => {
      if (files && !files[0]?.type.includes('audio')) {
        setAudioError({
          title: 'Wrong file format',
          description: 'The file you are trying to upload is not a .mp3 audio format'
        });
        return;
      } else if (files && files[0]?.size / 1024 / 1024 >= 20) {
        setAudioError({
          title: 'Too large file',
          description: 'The maximum file size that you can upload should not exceed 20 MB'
        });
        return;
      } else if (files) {
        retrieveAndUpload(files[0]);
      }
      onCloseModal?.();
    };

    const onCancelHandle = () => {
      if (fileUploaded) {
        onCancel(fileUploaded);
        onCancelCallback();
      }
    };

    const onRetrieveHandle = (file: File | null) => {
      if (file) {
        retrieveAndUpload(file);
      }
    };

    const cancelOrError =
      fileUploaded?.uploaderData.status === 'canceled' || fileUploaded?.uploaderData.status === 'error';

    return (
      <>
        <FileUploadControl
          className={className}
          titleBrowser={'Drag and drop audio file'}
          titleLink={'Load audio from URL'}
          embedTitle={'Embed audio player'}
          descriptionBrowser={'Maximum file size 20 MB, mp3 only'}
          embedDescription={'Soundcloud, Mixcloud and others'}
          descriptionLink={'Allowed direct links to the mp3 file '}
          typeOfUploader={typeOfUploader}
          setTypeOfUploader={setTypeOfUploader}
          onSetUploadFile={onSetAudio}
          onCloseModal={onCloseModal}
          fileSelected={!cancelOrError ? fileUploaded?.file : null}
          inProcessing={false}
          uploadFile={!cancelOrError ? audio : undefined}
          completeStatus={fileUploaded?.transcodingStatus}
          format={'.mp3'}
          accept={'audio/*'}
          fileError={audioError}
          onClearError={clearError}
          viewportText={
            <>
              <IconHorizontalRange />
              Viewport width: 768 px
            </>
          }
        >
          {!cancelOrError && fileUploaded?.file ? (
            <FileUpload
              fileSize={fileUploaded?.file.size / 1024 / 1024}
              onCancelUpload={onCancelHandle}
              progress={fileUploaded.progress}
            />
          ) : (
            <FileSelect
              accept={'audio/*'}
              title={'Drag and drop audio file'}
              description={'Maximum file size 20 MB, mp3 only'}
              titleOnPreview={'Drag and drop audio file'}
              descriptionOnPreview={'Maximum file size 20 MB, mp3 only'}
              typeOfUploader={typeOfUploader}
              setTypeOfUploader={setTypeOfUploader}
              setFileSelected={onRetrieveHandle}
              closeModal={onCloseModal}
              onCancel={onCancelHandle}
              handleFile={handleFile}
            />
          )}
        </FileUploadControl>
      </>
    );
  }
);
