import { useEffect, useState } from 'react';
import { UseFormHandleSubmit } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import classnames from 'classnames';
import styles from './entity-id.module.scss';
import { Icon16NewWindow, IconCheckMark, IconPlus, IconSpinner } from '../../../../components/UI/icons';

import { Button, CustomTooltip } from '../../../../components/UI';
import { LoadState } from '../../../../store';
import { studentPaths } from '../../../lms/students/routes/StudentRoutes';
import { IStudent } from '../../../lms/students/store/StudentListState';
import { ContactListActionAsync } from '../store/ContactListActionAsync';
import { IContact, IContactField } from '../store/ContactListState';
import { EntityIdWrapper } from './EntityIdWrapper';

interface IProps {
  contactId: string;
  contactFields: Record<string, string | null> | undefined;

  handleSubmit: UseFormHandleSubmit<Record<string, any>>;
  onSuccessStudentCreate?: (contact?: IContact) => void;
  onError: (errors: IContactField) => void;
}

export const ContactId = ({ contactId, contactFields, handleSubmit, onSuccessStudentCreate, onError }: IProps) => {
  const [studentId, setStudentId] = useState<string>();
  const { contact } = useSelector((state) => state.currentContact);
  const [loading, setLoading] = useState<boolean | null>(null);
  const dispatch = useDispatch();
  const { contacts, loadState } = useSelector((state) => state.contacts);
  const fields = useSelector((state) => state.fields.fields);
  const fieldsOfContact = contacts.find((x) => x.id === contactId)?.fields;
  const fieldRelationStudent =
    fieldsOfContact &&
    fields.find(
      (x) => Object.keys(fieldsOfContact).includes(x.id) && x.type === 'relation' && x.scope === 'student_relation'
    );
  const [email] = fields.filter((field) => field.scope === 'email');
  const emailKey = Object.keys(contactFields || {}).find((key: string) => key === email.id);

  const onSuccessCallback = (student: IStudent) => {
    setLoading(false);
    setTimeout(() => {
      if (contact?.contact) {
        setStudentId(student.id);
        onSuccessStudentCreate?.(contact?.contact);
      }
      setLoading(null);
    }, 3000);
  };
  const onErrorCallback = () => {
    setLoading(false);
    setTimeout(() => {
      setLoading(null);
    }, 3000);
  };

  const loadingIcon = loading ? (
    <IconSpinner className={'spinner'} />
  ) : loading === false ? (
    <IconCheckMark />
  ) : (
    <IconPlus />
  );

  const onCreateStudent = () => {
    if (contactId) {
      setLoading(true);
      dispatch(ContactListActionAsync.createStudentFromContact(contactId, onSuccessCallback, onErrorCallback));
    }
  };

  useEffect(() => {
    if (fieldRelationStudent && contact?.contact && fieldRelationStudent?.id in contact?.contact?.fields) {
      setStudentId(contact?.contact?.fields[fieldRelationStudent.id]);
    }
  }, [contact?.contact, fieldRelationStudent]);

  return (
    <EntityIdWrapper entityId={contactId}>
      {fieldRelationStudent && (
        <Link
          className={'d-flex align-items-center ml-2'}
          to={{ pathname: studentPaths.studentList, state: { studentIdFromContact: studentId } }}
        >
          View in student list <Icon16NewWindow className={'ml-1'} />
        </Link>
      )}
      {!fieldRelationStudent && (
        <CustomTooltip
          disabled={!!(contactFields && emailKey && contactFields[emailKey])}
          customText={'First add email to create a student'}
          direction={'bottom'}
        >
          <Button
            btnProps={{
              disabled:
                !(contactFields && emailKey && contactFields[emailKey]) ||
                !(loading === null || loading) ||
                !(loadState === LoadState.allIsLoaded)
            }}
            onClick={handleSubmit(onCreateStudent, onError)}
            customIcon={loadingIcon}
            btnStyle={'secondary'}
            className={classnames('ml-2', { [styles.creating]: loading }, { [styles.created]: loading === false })}
          >
            {loading === null || loading ? 'Create student' : 'Created'}
          </Button>
        </CustomTooltip>
      )}
    </EntityIdWrapper>
  );
};
