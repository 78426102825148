import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import styles from './reset.module.scss';

import { BackReturn } from '../../../../components/back-return/BackReturn';
import { IUserAuth } from '../../../../interfaces';
import { useAuthContext } from '../../AuthContext';
import { ResetComponent } from './ResetComponent';

const schema = yup.object().shape({
  email: yup.string().max(250).email('Wrong email format').required('')
});

export const ResetPassword = () => {
  const { onAuth } = useAuthContext();

  const methods = useForm<Pick<IUserAuth, 'email'>>({
    resolver: yupResolver(schema)
  });

  return (
    <FormProvider {...methods}>
      <BackReturn text={'Back to Sign In'} onClick={onAuth} className={'mb-4'} />
      <h1 className={styles.title}>Reset your password</h1>
      <p className={styles.description}>
        Enter the email address you registered with and we will send instructions to reset your password to your inbox
      </p>
      <ResetComponent />
    </FormProvider>
  );
};
