import { ReactElement } from 'react';

import classNames from 'classnames';
import styles from './setting.module.scss';
import {
  Icon32Brush,
  Icon32Currency,
  Icon32Gear,
  Icon32Globe,
  Icon32PlugIn,
  Icon32Subscription,
  Icon32TextFile,
  Icon32User,
  IconBrush,
  IconCurrency,
  IconGear,
  IconGlobe,
  IconPlugIn,
  IconSubscription,
  IconTextFile,
  IconUser
} from '../../../components/UI/icons';

import { CustomTooltip } from '../../../components/UI';
import { SettingsPaths } from '../routes/SettingsRoutes';
import { Academy } from './academy/Academy';
import { SettingsCard } from './settings-card/SettingsCard';

export interface ISettingRoutes {
  img: ReactElement;
  link: keyof typeof SettingsPaths;
  description: string;
  title: string;
  disabled?: boolean;
  helpLink?: string;
}
export const settingsPaths = (isBig: boolean): ISettingRoutes[] => {
  return [
    {
      img: isBig ? <Icon32User /> : <IconUser />,
      link: 'account',
      title: 'Account',
      description: 'View and update personal information: contacts, password, linked accounts.'
    },
    {
      img: isBig ? <Icon32Gear /> : <IconGear />,
      link: 'general',
      title: 'General',
      description: 'View and update business and platform details: company name, industry, contacts and other.'
    },
    // {
    //   img: isBig ? <Icon32Notification /> : <IconNotification />,
    //   link: 'notifications',
    //   title: 'Notifications',
    //   description: 'Manage notifications sent to you and your customers: new sale, sale error, reports and other.',
    //   disabled: true,
    //   helpLink: 'https://feedback.everact.io/b/feedback/p/notification-settings'
    // },
    // {
    //   img: isBig ? <Icon32ToggleOn /> : <IconToggleOn />,
    //   link: 'permissions',
    //   title: 'Users & Permissions',
    //   description: 'View and manage accesses for your team: create new role, invite teammates.',
    //   disabled: true,
    //   helpLink: 'https://feedback.everact.io/b/feedback/p/user-roles-and-permissions-settings'
    // // },
    // {
    //   img: isBig ? <Icon32Tax /> : <IconTax />,
    //   link: 'taxes',
    //   title: 'Taxes',
    //   description: 'Manage how you charge taxes.',
    //   disabled: true,
    //   helpLink: 'https://feedback.everact.io/b/feedback/p/taxes-settings'
    // },
    {
      img: isBig ? <Icon32TextFile /> : <IconTextFile />,
      link: 'legal',
      title: 'Legal',
      description: 'Manage your store legal page: Terms of Services, Cookies, Confidentiality.',
    },
    {
      img: isBig ? <Icon32Subscription /> : <IconSubscription />,
      link: 'billing',
      title: 'Billing',
      description: 'Choose the best subscription for your business needs.'
    },
    {
      img: isBig ? <Icon32Globe /> : <IconGlobe />,
      link: 'domain',
      title: 'Student cabinet domain',
      description: 'Add a personal domain name for which students will access their personal account'
    },
    {
      img: isBig ? <Icon32PlugIn /> : <IconPlugIn />,
      link: 'integrations',
      title: 'Third Party Integrations',
      description: 'Connect to other applications that work hand in hand with Everact.'
    },
    {
      img: isBig ? <Icon32Currency /> : <IconCurrency />,
      link: 'payment',
      title: 'Payment Integrations',
      description: 'Collect payments on the Offers you sell in everact.io'
    },
    {
      img: isBig ? <Icon32Brush /> : <IconBrush />,
      link: 'customization',
      title: 'Theme customization',
      description: 'Apply your personal branding colors and logo across the student cabinet'
    }
  ];
};

export const Settings = () => {
  return (
    <>
      <div className={styles.title}>Settings</div>
      <div className={styles.container}>
        <div className={styles.panel}>
          <Academy />
          {/*<Plan />*/}
          {/*<Profile />*/}
        </div>
        <div className={classNames('m-0 p-4', styles.borderWrapper)}>
          <div className={styles.cardContainer}>
            {settingsPaths(true).map((x, index) =>
              x.disabled ? (
                <CustomTooltip direction={'top'} key={index}>
                  <SettingsCard
                    ImageElement={x.img}
                    title={x.title}
                    description={x.description}
                    link={x.link}
                    helpLink={x.helpLink}
                  />
                </CustomTooltip>
              ) : (
                <SettingsCard
                  key={index}
                  ImageElement={x.img}
                  title={x.title}
                  description={x.description}
                  link={x.link}
                />
              )
            )}
          </div>
        </div>
      </div>
    </>
  );
};
