import { FieldSelectPayload } from './components/FieldSelectPayload';
import { FieldTextPayload } from './components/FieldTextPayload';
import { FieldTypes } from './store/FieldState';

interface IProps {
  types: { value: FieldTypes[] } | undefined;
}

export const FieldPayloadType = ({ types }: IProps) => {
  if (types?.value) {
    if (types.value.includes('short_text')) {
      return <FieldTextPayload isShort label={'Limit to 1 row'} />;
    }
    if (types.value.includes('date')) {
      return <FieldTextPayload isShort label={'Fixed time'} />;
    }
    if (types.value.includes('select') || types.value.includes('multiselect')) {
      return <FieldSelectPayload />;
    }
  }
  return null;
};
