import { memo, SyntheticEvent } from 'react';

import classNames from 'classnames';
import styles from './enrollment-course.module.scss';

import { Button } from '../../../../../components/UI';
import { ThumbnailPlaceholderImage } from '../../../../../components/UI/image-components/thumbnail-placeholder-image/ThumbnailPlaceholderImage';
import { CourseStatus, IStudentCourse } from '../../store/StudentListState';

interface IProps {
  course: IStudentCourse;
  unEnroll: (e: SyntheticEvent<HTMLButtonElement>) => void;
  userId: string | null;
}

export const EnrollmentCourse = memo(({ course, userId, unEnroll }: IProps) => {
  const { progress, last_open_event_at, course: { settings, title } } = course;

  let status: CourseStatus = 'in_progress';
  if (progress === null && last_open_event_at === null && userId) {
    status = 'enrolled';
  }
  if (progress === null && !last_open_event_at && !userId) {
    status = 'pending';
  }
  if (progress !== null && last_open_event_at && userId) {
    status = 'started';
  }

  return (
    <div className={styles.course}>
      {settings && (
        <div className={styles.imageContainer}>
          <ThumbnailPlaceholderImage
            width={128}
            height={72}
            thumbnailCourse={settings.thumbnail}
            className={styles.image}
          />
        </div>
      )}
      <div className={styles.info}>
        <div className={classNames(styles.title, 'ellipsis')}>{title}</div>
        <div className={styles.actions}>
          <div className={classNames(styles.status, styles[status])}>{status.replace('_', ' ')}</div>

          <Button
            onClick={unEnroll}
            btnStyle={'transparent'}
            withIcon={false}
            className={classNames(styles.unEnroll, 'ml-auto')}
            btnProps={{ id: course.course_id }}
          >
            Unenroll
          </Button>
        </div>
      </div>
    </div>
  );
});
