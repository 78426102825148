import { useHistory } from 'react-router-dom';

import classNames from 'classnames';
import btnStyles from '../get-week-trial/get-week.module.scss';
import styles from './periodOverAccount.module.scss';
import { Icon32Card } from '../../../../components/UI/icons';

import { Button } from '../../../../components/UI';
import { SettingsPaths } from '../../../settings/routes/SettingsRoutes';

export const PeriodOverAccount = () => {
  const history = useHistory();

  const onClick = () => {
    history.push(SettingsPaths.billing);
  };

  const isBillingPage = history.location.pathname === SettingsPaths.billing;

  return (
    <div className={classNames(styles.container, isBillingPage && styles.billing)}>
      <div className={styles.title}>Your trial period is over</div>
      <h6 className={styles.description}>
        We will keep the changes you made in your personal account for 3 days. If you don't update your subscription
        within that time, all of your data will be deleted or you can freeze account to save it.
      </h6>
      {!isBillingPage && (
        <Button
          onClick={onClick}
          withIcon={true}
          customIcon={<Icon32Card />}
          className={classNames(btnStyles.btnContainer, styles.btn)}
          size={'lg'}
          iconPosition={'left'}
        >
          Update billing information
        </Button>
      )}
    </div>
  );
};
