import { memo, useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { Tag } from 'react-tag-autocomplete-fix';

import { CheckButton } from '../../../../../../components/UI';
import { useNodePayload } from '../../../../../../hooks/use-node-payload';
import { PaletteAutomationElementTypes } from '../../../../../../interfaces';
import { AutocompleteTag } from '../../../../../crm/tag/AutocompleteTag';
import { TagsSelector } from '../../../../../crm/tag/store/TagSelector';

interface IProps {
  nodeId: string;
}

export const TagCondition = memo(({ nodeId }: IProps) => {
  const [tags, setTags] = useState<Tag[]>([]);
  const tagMemo = useMemo(TagsSelector, []);
  const allTag = useSelector((state) => tagMemo(state));
  const node = useNodePayload<PaletteAutomationElementTypes.PROCESSOR_CONDITION>(nodeId);
  const { setValue, register, unregister } = useFormContext();

  const onSetTag = (tag: Tag[]) => {
    setTags(tag);
  };
  useEffect(() => {
    if (node) {
      const currentTag = allTag.filter((x) => node?.payload.object_id?.includes(x.id.toString()));
      setValue('action', node?.payload.action || 'add');

      if (currentTag) {
        setTags(currentTag);
      }
    }
  }, [allTag, node, nodeId, setValue]);

  useEffect(() => {
    if (tags.length > 0) {
      register('object_id');
      register('object_type');
      setValue('object_id', tags[0].id);
      setValue('object_type', 'tag');
    }
    return () => {
      unregister('object_id');
      unregister('object_type');
    };
  }, [register, setValue, tags, unregister]);

  return (
    <>
      <AutocompleteTag tags={tags} onSetTag={onSetTag} isShowEdit={false} isCreateNew={false} isSingle={true} />
      <CheckButton
        name={'action'}
        value={'add'}
        params={{ className: 'radio-button my-1', label: 'Tag has been assigned' }}
      />
      <CheckButton
        name={'action'}
        value={'remove'}
        params={{ className: 'radio-button my-1 mt-2', label: 'Was not assigned' }}
      />
    </>
  );
});
