import { memo } from 'react';

import styles from '../status-modal.module.scss';
import { Icon32ClearFormat } from '../../../../../UI/icons';

import { ICourseNode } from '../../../../../../modules/lms/courses/store/CourseState';
import { CommonModal } from '../../../../../UI';
import { LessonParentDraft } from '../lesson-state/LessonParentDraft';
import { NodeItemCourse } from '../node-item-course/NodeItemCourse';

interface IProps {
  showModal: boolean;
  onCloseModal: () => void;
  hierarchyItem: ICourseNode;
}

export const LessonWithDraftParent = memo(({ hierarchyItem, showModal, onCloseModal }: IProps) => {
  return (
    <CommonModal
      onClose={onCloseModal}
      show={showModal}
      showClose={true}
      params={{ contentClassName: styles.contentContainer }}
      closeButtonClassName={styles.closeContainer}
    >
      <div onClick={(e) => e.stopPropagation()}>
        <div className={'d-flex align-items-center'}>
          <Icon32ClearFormat className={styles.icon} />
          <div className={styles.title}>{hierarchyItem.state === 'published' ? 'Mark as draft' : 'Unmark draft'}</div>
        </div>
        <LessonParentDraft onClose={onCloseModal}>
          <NodeItemCourse state={hierarchyItem.state} title={hierarchyItem.title} type={hierarchyItem.type} />
        </LessonParentDraft>
      </div>
    </CommonModal>
  );
});
