import { DOMAttributes, FC, memo, useState } from 'react';
import { Form, FormControl, FormControlProps, FormGroup } from 'react-bootstrap';
import { Controller } from 'react-hook-form';

import classNames from 'classnames';
import styles from './password-control.module.scss';

import { IconEye, IconEyeHide } from '../icons';

interface IProps {
  name: string;
  className?: string;
  controlProps: FormControlProps;
  groupProps?: DOMAttributes<HTMLDivElement>;
  onChangeHandler?: (e: string) => void;
  placeholder?: string;
  label?: string;
}

export const PasswordControl: FC<IProps> = memo(
  ({ name, controlProps, className, children, groupProps, onChangeHandler, placeholder = 'Password', label }) => {
    const [showPassword, setShowPassword] = useState(false);

    const changeVisibilityPassword = () => {
      setShowPassword(!showPassword);
    };

    return (
      <FormGroup className={classNames('position-relative', className)} {...groupProps}>
        {label && <Form.Label>{label}</Form.Label>}
        <Controller
          render={({ field: { onChange, value } }) => (
            <FormControl
              type={showPassword ? 'text' : 'password'}
              placeholder={placeholder}
              autoComplete="off"
              onChange={(e) => {
                onChange(e);
                onChangeHandler?.(e.currentTarget.value);
              }}
              id={name}
              value={value}
              {...controlProps}
            />
          )}
          name={name}
          defaultValue={''}
        />
        <div
          className={classNames(styles.visibility, { [styles.withLabel]: label })}
          onClick={changeVisibilityPassword}
        >
          <div className={styles.tooltip}>{showPassword ? 'Hide' : 'Show'}</div>
          {showPassword ? <IconEyeHide /> : <IconEye />}
        </div>
        <div className={classNames('d-flex justify-content-between', { 'pt-1': !!children })}>{children}</div>
      </FormGroup>
    );
  }
);
