import React, { forwardRef, Ref } from 'react';

export type KebabToggleProps = {
  onClick: (e: Event) => void;
} & React.ComponentPropsWithoutRef<'button'>;

export const KebabToggle = forwardRef(({ onClick, ...props }: KebabToggleProps, ref: Ref<HTMLButtonElement>) => (
  <button
    type="button"
    ref={ref}
    className="btn p-0 dropdown-kebab-toggle"
    onClick={(e) => {
      e.stopPropagation();
      onClick(e);
    }}
  >
    {props.children}
  </button>
));
