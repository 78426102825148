import { memo, useMemo } from 'react';

import classNames from 'classnames';
import styles from './element-item.module.scss';
import { IconCross } from '../../../../../../../components/UI/icons';

import { ProductPlaceholder } from '../../../../../../../components/UI/image-components/product-placeholder/ProductPlaceholder';
import { ThumbnailPlaceholderImage } from '../../../../../../../components/UI/image-components/thumbnail-placeholder-image/ThumbnailPlaceholderImage';
import { IFile } from '../../../../../../../interfaces';
import { FieldEntity } from '../../../../../field';
import { IProductField } from '../../../../../products/store/ProductsState';
import { useCreateOfferContext } from '../../../CreateOfferContext';
import { dataViewProductItem } from '../../helper';
import { ProductBadge } from '../../product-badge/ProductBadge';

interface IProps {
  id: string;
  entity: FieldEntity;
  productFields?: IProductField;
  title?: string;
  image?: IFile | null;
  removeElement: (id: string) => void;
}

export const ElementItem = memo(({ id, entity, productFields, title, image, removeElement }: IProps) => {
  const { fields } = useCreateOfferContext();
  const data = useMemo(
    () => productFields && fields && dataViewProductItem(productFields, fields),
    [fields, productFields]
  );

  const onRemove = () => {
    removeElement(id);
  };

  return (
    <>
      <div className={styles.descriptionContainer}>
        <div className={styles.imgContainer}>
          <ProductBadge element={entity} className={styles.iconContainer} />
          {entity === 'product' && !data?.image ? (
            <ProductPlaceholder />
          ) : (
            <ThumbnailPlaceholderImage
              thumbnailCourse={data?.image || image || null}
              width={118}
              height={64}
              className={styles.image}
            />
          )}
        </div>
        <div className={styles.wrapper}>
          <div className={classNames(styles.title, entity === 'product' && styles.prod, 'ellipsis')}>
            {title || data?.title}
          </div>
          <div>
            {id && entity === 'product' && <span className={styles.text}>ID: {id}</span>}
            {data?.price && <span className={styles.text}>Price: ${data.price.amount}</span>}
            {data?.quantity && <span className={styles.text}>Quantity: {data.quantity}</span>}
          </div>
        </div>
      </div>
      <div className={styles.cross} onClick={onRemove}>
        <IconCross />
      </div>
    </>
  );
});
