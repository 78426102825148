import { ReactNode } from 'react';
import Select, { ActionMeta, FormatOptionLabelMeta, OnChangeValue } from 'react-select';
import { StateManagerProps } from 'react-select/dist/declarations/src/useStateManager';

import { IOption } from '../../../interfaces';
import { DropdownIndicator } from '../select/DropdownIndicator';
import { CustomAutocompleteSelectOption, MenuList, NoOptionsMessage } from './autocompleteComponents';

interface IProps<T> {
  options: T[];
  onSelect(value: OnChangeValue<T, false>, actionMeta?: ActionMeta<T>): void;
  optionIdSelected?: string;
  CreateNewComponent: ReactNode;
  NoOptionComponent: ReactNode;
  noOptionText: string;
  formatOptionLabel?: (option: IOption, labelMeta: FormatOptionLabelMeta<IOption>) => JSX.Element;
  props?: StateManagerProps;
}

export const AutocompleteSelect = <T extends IOption>({
  onSelect,
  optionIdSelected,
  CreateNewComponent,
  NoOptionComponent,
  noOptionText,
  formatOptionLabel,
  options,
  ...props
}: IProps<T>) => {
  return (
    <Select
      classNamePrefix={'select'}
      className={'select-container'}
      options={options}
      onChange={onSelect}
      value={options.find((option) => option.value === optionIdSelected)}
      components={{
        DropdownIndicator,
        MenuList: (menuProps) => MenuList(menuProps, CreateNewComponent),
        NoOptionsMessage: (noOptionProps) => NoOptionsMessage(noOptionProps, NoOptionComponent),
        Option: CustomAutocompleteSelectOption,
        IndicatorSeparator: () => null
      }}
      noOptionsMessage={() => noOptionText}
      formatOptionLabel={formatOptionLabel}
      {...props}
    />
  );
};
