import { memo, useCallback, useMemo } from 'react';
import Select, { components, OnChangeValue, SingleValueProps } from 'react-select';
import moment from 'moment';

import classNames from 'classnames';
import styles from './quiz-detail-attempt.module.scss';
import { IconCheckMark, IconClock, IconCross, IconEyeHide } from '../../../../../../../../components/UI/icons';

import { AnswerSessionStatusEnum, IQuizSession, IQuizSessionDetail } from '../../../../../../../../interfaces/quiz';
import { QuizStatus } from '../../quiz-status/QuizStatus';

type IOptions = {
  data: IQuizSession & { attempt: number };
  value: string;
};

const SingleValue = ({ children, ...props }: SingleValueProps<IOptions, false>) => {
  return <components.SingleValue {...props}>Attempt: {props.data.data.attempt}</components.SingleValue>;
};

interface IProps
  extends Partial<Pick<IQuizSessionDetail, 'date_create' | 'state' | 'id'>>,
    Partial<Pick<IQuizSession, 'result'>> {
  onChangeSession?: (id: string) => void;
  groupSession?: IQuizSession[];
}

export const QuizDetailAttempt = memo(({ onChangeSession, groupSession, date_create, id, result, state }: IProps) => {
  const formatOption = useCallback(({ data }: IOptions) => {
    return (
      <div className={styles.option}>
        {data.state === AnswerSessionStatusEnum.skipable && (
          <div className={classNames(styles.icon, styles.skipable)}>
            <IconEyeHide />
          </div>
        )}
        {data.state === AnswerSessionStatusEnum.success && (
          <div className={classNames(styles.icon, styles.success)}>
            <IconCheckMark />
          </div>
        )}
        {data.state === AnswerSessionStatusEnum.fail && (
          <div className={classNames(styles.icon, styles.fail)}>
            <IconCross />
          </div>
        )}
        {data.state === AnswerSessionStatusEnum.in_progress && (
          <div className={classNames(styles.icon, styles.progress)}>
            <IconClock />
          </div>
        )}
        {data.state === AnswerSessionStatusEnum.pending_moderation && (
          <div className={classNames(styles.icon, styles.progress)}>
            <IconClock />
          </div>
        )}
        <div className={styles.number}>{data.attempt}</div>•{' '}
        {moment(data.date_create).format('DD MMM yyyy, hh:mm A')}
      </div>
    );
  }, []);

  const onChange = useCallback(
    (newValue: OnChangeValue<IOptions, false>) => {
      if (newValue) {
        onChangeSession?.(newValue.data.id);
      }
    },
    [onChangeSession]
  );

  const options = useMemo(
    () =>
      (groupSession || []).map((option, index) => ({
        data: {
          ...option,
          attempt: index + 1
        },
        value: option.id
      })),
    [groupSession]
  );

  return (
    <div className={styles.info}>
      <div className={styles.attempt}>
        {groupSession && (
          <Select
            classNamePrefix={'select'}
            className={'select-container select-sm'}
            components={{
              // DropdownIndicator: DropdownIndicator,
              IndicatorSeparator: () => null,
              SingleValue
            }}
            options={options}
            isSearchable={false}
            formatOptionLabel={formatOption}
            defaultValue={options.find((option) => option.value === id) || options[0]}
            onChange={onChange}
          />
        )}
      </div>
      {groupSession && (
        <>
          <div className={'mr-2'}>{moment(date_create).format('DD MMM yyyy, hh:mm A')}</div>
          {result && '➔'}
          <div className={styles.status}>
            {state && result && <QuizStatus status={state} className={'mr-2 ml-2'} />}
            {result && moment(result.passed_at).format('DD MMM yyyy, hh:mm A')}
          </div>
        </>
      )}
    </div>
  );
});
