import { useHistory } from 'react-router-dom';

import styles from './payment-connect.module.scss';
import { IconGear } from '../../../../../../../components/UI/icons';

import { Button } from '../../../../../../../components/UI';
import { SettingsPaths } from '../../../../../../settings/routes/SettingsRoutes';

export const PaymentConnect = () => {
  const history = useHistory();
  const onClick = () => {
    history.push(SettingsPaths.payment);
  };

  return (
    <div className={styles.container}>
      <div className={styles.text}>Connect payment provider in platform settings to receive payments</div>

      <Button
        onClick={onClick}
        btnStyle={'secondary'}
        customIcon={<IconGear />}
        iconPosition={'right'}
        size={'sm'}
      >
        Payment integrations
      </Button>
    </div>
  );
};
