import { reducerWithInitialState } from 'typescript-fsa-reducers';

import { commonErrorHandler, commonFirstLoadHandler } from '../../../../helpers/common-reducer-handlers';
import { LoadState, newState } from '../../../../store';
import { ListsActions } from './ListsActions';
import { IList, IListsState, ListsInitialState } from './ListsState';

export const listsReducer = reducerWithInitialState<IListsState>(ListsInitialState)
  .case(ListsActions.getLists.started, commonFirstLoadHandler)
  .case(ListsActions.getLists.done, (state, { result }) => {
    return newState(state, {
      lists: result,
      loadState: LoadState.allIsLoaded,
      error: null
    });
  })
  .case(ListsActions.getLists.failed, commonErrorHandler)

  .case(ListsActions.getListDetail.started, commonFirstLoadHandler)
  .case(ListsActions.getListDetail.done, (state, { result }) => {
    const list = state.lists.find((list) => list.id === result.id);
    let lists: IList[] = [];
    if (list) {
      lists = state.lists.map((item) =>
        item.id === result.id
          ? {
              ...result,
              client_count: item.client_count
            }
          : item
      );
    } else {
      lists.push(result);
    }
    return newState(state, {
      lists: lists,
      loadState: list ? LoadState.allIsLoaded : LoadState.needLoad,
      error: null
    });
  })
  .case(ListsActions.getListDetail.failed, commonErrorHandler)

  .case(ListsActions.createList.started, commonFirstLoadHandler)
  .case(ListsActions.createList.done, (state, { result }) => {
    return newState(state, {
      lists: [...state.lists, { ...result, client_count: 0 }], // TODO: delete after updates from backend
      loadState: LoadState.allIsLoaded,
      error: null
    });
  })
  .case(ListsActions.createList.failed, commonErrorHandler)

  .case(ListsActions.editList.started, commonFirstLoadHandler)
  .case(ListsActions.editList.done, (state, { result }) => {
    const updatedLists = state.lists.map((list) =>
      list.id === result.id ? { ...result, client_count: list.client_count } : list
    );
    return newState(state, {
      lists: updatedLists,
      loadState: LoadState.allIsLoaded,
      error: null
    });
  })
  .case(ListsActions.editList.failed, commonErrorHandler)

  .case(ListsActions.deleteList.started, commonFirstLoadHandler)
  .case(ListsActions.deleteList.done, (state, { params }) => {
    const removingIndexElement = state.lists.filter((list) => list.id !== params.id);
    return newState(state, {
      lists: removingIndexElement,
      loadState: LoadState.allIsLoaded,
      error: null
    });
  })
  .case(ListsActions.deleteList.failed, commonErrorHandler);
