import { CommonCourseBlock, IGeneralSettings } from '../interfaces';
import { ICourseNode, ILecture, StudentLessonErrorStatus } from '../modules/lms/courses/store/CourseState';
import { IViewQuiz } from '../modules/student-cabinet/lesson-student/blocks/quiz/store/QuizState';
import { ICourseComplete, IStudentCabinet } from '../modules/student-cabinet/store/StudentCabinetState';
import { baseFetch } from './BaseFetch';

export const getStudentCabinetCourses = async (organization_id: string) => {
  return await baseFetch<{ organization_id: string }, IStudentCabinet>('/student/courses', { organization_id }, 'GET');
};

export const getListStepBlocks = async (courseId: string, stepId: string) => {
  return await baseFetch<{}, CommonCourseBlock[], StudentLessonErrorStatus>(
    `/student/courses/${courseId}/steps/${stepId}`,
    {},
    'GET'
  );
};

export const getLesson = async (courseId: string, lessonId: string) => {
  return await baseFetch<{}, ILecture[]>(`/student/courses/${courseId}/steps/${lessonId}`, {}, 'GET');
};

/**
 * Get lesson by id for course preview mode
 * @param courseId course ID
 * @param lessonId lesson ID
 */
export const getLessonPreview = async (courseId: string, lessonId: string) => {
  return await baseFetch<{}, ILecture[]>(`/courses/${courseId}/steps/${lessonId}`, {}, 'GET', true);
};

export const getStudentCourseHierarchy = async (course_id: string) => {
  return await baseFetch<{}, ICourseNode[]>(`/student/courses/${course_id}/hierarchy`, {}, 'GET');
};

export const getStatusesCourse = async (courseId: string) => {
  return await baseFetch<{}, ICourseComplete>(`/student/courses/${courseId}/progress`, {}, 'GET');
};

export const setProgressNode = async <A, P>(courseId: string, nodeId: string, action?: A, payload?: P) => {
  return await baseFetch<{ node_id: string; action?: A; payload?: P }, string>(
    `/student/courses/${courseId}/progress`,
    { node_id: nodeId, action, payload },
    'POST'
  );
};

export const getQuiz = async (courseId: string, quizId: string) => {
  return await baseFetch<{}, IViewQuiz>(`/student/courses/${courseId}/quiz/${quizId}`, {}, 'GET');
};

export const getCabinetSettings = async (host: string) => {
  return await baseFetch<{ host: string }, IGeneralSettings>('/settings', { host }, 'POST', false);
};
