import { reducerWithInitialState } from 'typescript-fsa-reducers';

import {
  commonErrorHandler,
  commonFirstLoadHandler,
  commonLoadHandler
} from '../../../../helpers/common-reducer-handlers';
import { LoadState, newState } from '../../../../store';
import { billingActions } from './BillingActions';
import { BillingInitialState, IBillingState } from './BillingState';

export const billingReducer = reducerWithInitialState<IBillingState>(BillingInitialState)
  .case(billingActions.getSubscription.started, commonFirstLoadHandler)
  .case(billingActions.getSubscription.done, (state, { result }) => {
    return newState(state, {
      subscription: result,
      error: null,
      loadState: LoadState.allIsLoaded
    });
  })
  .case(billingActions.getSubscription.failed, commonErrorHandler)

  .case(billingActions.updateSubscription.started, commonLoadHandler)
  .case(billingActions.updateSubscription.done, (state, { result }) => {
    return newState(state, {
      subscription: result,
      error: null,
      loadState: LoadState.allIsLoaded
    });
  })
  .case(billingActions.updateSubscription.failed, commonErrorHandler)

  .case(billingActions.createSubscription.started, commonLoadHandler)
  .case(billingActions.createSubscription.done, (state, { result }) => {
    return newState(state, {
      subscription: result,
      error: null,
      loadState: LoadState.allIsLoaded
    });
  })
  .case(billingActions.createSubscription.failed, commonErrorHandler)

  .case(billingActions.renewSubscription.started, commonFirstLoadHandler)
  .case(billingActions.renewSubscription.done, (state) => {
    return newState(state, {
      subscription: state.subscription,
      error: null,
      loadState: LoadState.allIsLoaded
    });
  })
  .case(billingActions.renewSubscription.failed, commonErrorHandler);
