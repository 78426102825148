import { memo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { CommonCourseBlock } from '../../../../../interfaces';
import { useLesson } from '../../../hooks/use-lesson';
import Lesson, { CustomCodeForm } from './Lesson';
import { lessonSchema } from './lessonSchema';

interface IProps {
  stepId: string;
  getCourseBlock: () => void;
  getLecture: () => void;
}

export enum LessonFieldNames {
  courseBlock = 'courseBlock',
  customCode = 'customCode'
}

export interface LessonFormValues {
  [LessonFieldNames.courseBlock]: Partial<CommonCourseBlock>[];
  [LessonFieldNames.customCode]: CustomCodeForm;
}

export const LessonBlocks = memo((props: IProps) => {
  const { getCourseBlock, getLecture, stepId } = props;
  const { customCodeInitial, initialData } = useLesson();

  const formMethods = useForm<LessonFormValues>({
    defaultValues: {
      courseBlock: initialData,
      customCode: customCodeInitial
    },
    shouldUnregister: false,
    resolver: yupResolver(lessonSchema),
    shouldFocusError: true,
    delayError: 500,
    reValidateMode: 'onChange',
    criteriaMode: 'all'
  });

  return (
    // @ts-ignore
    <FormProvider {...formMethods}>
      <Lesson stepId={stepId} getCourseBlock={getCourseBlock} getLecture={getLecture} />
    </FormProvider>
  );
});
