import * as yup from 'yup';

export const actionSchema = yup.object().shape({
  action_enroll_in_courses: yup.array().nullable().min(1, 'You must add at least one product to your offer'),
  action_redirect_after_checkout: yup
    .object()
    .nullable()
    .shape({
      url: yup.string().url('Invalid URL').nullable().required('Insert redirect link'),
      in_same_tab: yup.bool().required().typeError('Choose an option')
    })
    .default(null),
  action_add_to_list: yup.array().of(yup.string()).nullable().min(1, 'You must add at least one list to your offer')
});
