import { memo, useState } from 'react';
import moment from 'moment';

import classNames from 'classnames';
import styles from './drip-lesson.module.scss';
import { Icon32Hourglass, IconFolderSolid, IconWebinar } from '../../../../UI/icons';

import { NodeCondition } from '../../../../../modules/lms/courses/store/CourseState';
import { CommonModal } from '../../../../UI';
import { CalendarDrip } from './calendar/CalendarDrip';
import { ButtonEdit } from './components/ButtonEdit';
import { SelectDripType } from './select-drip-type/SelectDripType';

interface IProps {
  showModal: boolean;
  onCloseModal: () => void;
  title: string;
  isLesson: boolean;
  nodeId: string;
  condition: NodeCondition;
}

export type SelectOptionDrip = 'delayed' | null;

export const DripModal = memo(({ showModal, onCloseModal, isLesson, title, nodeId, condition }: IProps) => {
  const [selectedOption, setSelectedOption] = useState<NodeCondition>(condition);

  return (
    <CommonModal onClose={onCloseModal} show={showModal} showClose={true} closeButtonClassName={styles.cross}>
      <div className={styles.container}>
        <div className={'d-flex align-items-center'}>
          <Icon32Hourglass className={styles.iconDrip} />
          <span className={styles.title}>Drip settings</span>
        </div>
        <div className={classNames('px-6', styles.description)}>
          Drip content allows you to make lessons available later when they "drip" over time or after another is
          completed.
        </div>
        <div className={classNames('px-6', styles.dripOf)}>
          {isLesson ? 'Drip for lesson' : 'Drip for folder'}

          <div className={'d-flex align-items-start mt-1'}>
            <div>
              {isLesson ? (
                <IconWebinar className={styles.iconLesson} />
              ) : (
                <IconFolderSolid className={styles.iconFolder} />
              )}
            </div>
            <span className={styles.nodeTitle}>{title}</span>
          </div>
        </div>
        <div className={'px-6'}>
          <SelectDripType setSelectedOption={setSelectedOption} defaultValue={condition} />
          {selectedOption?.type === 'delayed' && (
            <CalendarDrip setSelectedOption={setSelectedOption} selectOption={selectedOption} />
          )}
          {(selectedOption?.type !== condition?.type ||
            !moment(selectedOption?.open_at).isSame(condition?.open_at)) && (
            <ButtonEdit
              condition={selectedOption}
              nodeId={nodeId}
              onClose={onCloseModal}
              setSelectedOption={setSelectedOption}
            />
          )}
          {/* <SelectDrip /> */}
        </div>
      </div>
    </CommonModal>
  );
});
