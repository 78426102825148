import React from 'react';

import styles from './course-publish-status.module.scss';

import { ItemStatus } from '../../../../../components/itemStatus/ItemStatus';
import { Button } from '../../../../../components/UI';
import { CourseState } from '../../store/CourseState';

interface IProps {
  status: CourseState;
  currentStatus: CourseState;
  icon: React.ReactElement;
  statusName: string;
  action?: (event: React.SyntheticEvent<HTMLButtonElement>) => void;
}

export const CoursePublishStatus = ({ status, currentStatus, icon, statusName, action }: IProps) => {
  return (
    <div className={styles.container}>
      <div className={styles.headerContainer}>
        <div className={'d-flex align-items-center'}>
          {<div className={styles.iconContainer}>{icon}</div>}
          <div className={styles.text}>{statusName}</div>
        </div>
        <div className={'d-flex h-100 my-auto'}>
          {currentStatus === status && status !== 'archived' && (
            <ItemStatus label={`Now in ${currentStatus.toLowerCase()}`} className={styles.status} />
          )}
          {currentStatus === 'draft' && currentStatus !== status && action && (
            <Button onClick={action} withIcon={false} size={'lg'} btnProps={{ id: 'published' }}>
              Publish course
            </Button>
          )}
          {currentStatus === 'published' && currentStatus !== status && action && (
            <Button
              onClick={action}
              withIcon={false}
              size={'lg'}
              btnStyle={'secondary'}
              btnProps={{ id: 'draft' }}
            >
              Move to draft
            </Button>
          )}
          {currentStatus === 'archived' && currentStatus === status && action && (
            <Button
              onClick={action}
              withIcon={false}
              size={'lg'}
              btnStyle={'secondary'}
              btnProps={{ id: 'draft' }}
            >
              Move to draft
            </Button>
          )}
        </div>
      </div>
      <div className={styles.description}>
        {status === 'published'
          ? 'This course will be available for purchase. For enrolled students, it will be displayed in the student cabinet.'
          : 'This course will not be available for purchase. Enrolled students will not be able to see this course in the student cabinet.'}
      </div>
    </div>
  );
};
