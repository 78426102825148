import { memo } from 'react';

import styles from './memberLoader.module.scss';
import { Icon16CheckMark } from '../../../../../components/UI/icons';

interface IProps {
  show: boolean;
}

export const MemberLoader = memo(({ show }: IProps) => {
  return (
    <>
      {show && (
        <div className={'ml-1'}>
          <Icon16CheckMark className={styles.check} />
        </div>
      )}
    </>
  );
});
