import { Link, useRouteMatch } from 'react-router-dom';

import classnames from 'classnames';
import styles from '../../../../styles/components/navbar.module.scss';
import { IconArrowLeft } from '../../../components/UI/icons';

import { CRMPaths } from '../../../modules/crm/routes/CRMRoutes';

export const ContactListHeaderPanel = () => {
  const path = useRouteMatch<{ list_id: string }>(CRMPaths.crmListId());

  return (
    <div className={`navbar navbar-expand justify-content-start ${styles.navbar}`}>
      {path?.params.list_id && (
        <Link className={classnames('btn btn-sm btn-icon-left', styles.link)} to={CRMPaths.crmLists}>
          <IconArrowLeft className={'icon'} />
          Lists
        </Link>
      )}
    </div>
  );
};
