import { FC, memo, useMemo } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Placement } from 'react-bootstrap/esm/Overlay';
import { OverlayDelay } from 'react-bootstrap/OverlayTrigger';

import { getUUID } from '../../../helpers';

interface IProps {
  direction?: Placement;
  customText?: string | JSX.Element;
  className?: string;
  disabled?: boolean;
  delay?: OverlayDelay;
}

export const CustomTooltip: FC<IProps> = memo(
  ({ direction = 'auto', customText = 'Coming soon', disabled = false, className, delay, children }) => {
    const uuid = useMemo(() => getUUID(), []);
    return disabled ? (
      <div className={className}>{children}</div>
    ) : (
      <OverlayTrigger placement={direction} overlay={<Tooltip id={uuid}>{customText}</Tooltip>} delay={delay}>
        <div className={className}>{children}</div>
      </OverlayTrigger>
    );
  }
);
