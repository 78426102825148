import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import classnames from 'classnames';
import styles from './canvas-condition.module.scss';

import { useNodePayload } from '../../../../../hooks/use-node-payload';
import { PaletteAutomationElementTypes } from '../../../../../interfaces';
import { ListItem } from '../../../../crm/contact/common/contact-card-lists/components/list-item/ListItem';
import { ListsActionAsync } from '../../../../crm/lists/store/ListsActionAsync';

interface IProps {
  id?: string;
  nodeId: string;
}

export const CanvasConditionList = ({ id, nodeId }: IProps) => {
  const list = useSelector((state) => state.lists.lists.find((x) => x.id === id));
  const dispatch = useDispatch();
  const node = useNodePayload<PaletteAutomationElementTypes.PROCESSOR_CONDITION>(nodeId);

  useEffect(() => {
    if (!list) {
      dispatch(ListsActionAsync.getLists());
    }
  }, [dispatch, list]);

  return (
    <>
      {list && id && (
        <div className={styles.conditionTextWrapper}>
          <span className={classnames(styles.conditionText, 'mr-1')}>
            {node?.payload.action === 'add' && 'Assigned to list'}
            {node?.payload.action === 'remove' && 'Not assigned to'}
          </span>
          <ListItem id={id} isSelected={true} name={list.title} isDetach={false} />
        </div>
      )}
    </>
  );
};
