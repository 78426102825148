import { memo } from 'react';

import { ButtonType } from '../../../../../../../components/UI';
import { BtnQuestion } from '../../question/BtnQuestion';
import { useViewQuizContext } from '../../ViewQuizContext';

interface IProps {
  hasChoices: boolean;
  isDisable?: boolean;
  onSaveAnswerHandler: () => void;
  type?: ButtonType;
}

export const QuizAnswerBtn = memo(({ hasChoices, isDisable, onSaveAnswerHandler, type = 'update' }: IProps) => {
  const { onNextQuestion, isLastQuestion } = useViewQuizContext();
  return (
    <>
      {!hasChoices && (
        <BtnQuestion onClick={onSaveAnswerHandler} type={type} title={'Answer'} btnProps={{ disabled: isDisable }} />
      )}

      {!isLastQuestion && hasChoices && (
        <BtnQuestion onClick={onNextQuestion} type={'arrow-right'} title={'Continue'} />
      )}
      {isLastQuestion && hasChoices && (
        <BtnQuestion onClick={onNextQuestion} type={'arrow-right'} title={'Quiz results'} />
      )}
    </>
  );
});
