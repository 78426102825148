import { SelectRowProps } from 'react-bootstrap-table-next';

import classNames from 'classnames';
import styles from './offer-list.module.scss';

import { IOffer } from '../store/OffersState';

export const columnsConfig = [
  {
    dataField: 'id',
    text: 'Id',
    hidden: false,
    classes: 'col-1',
    headerClasses: 'col-1'
  },
  {
    dataField: 'title',
    text: 'Offer name',
    hidden: false,
    classes: 'col py-2',
    headerClasses: 'col'
  },
  {
    dataField: 'price',
    text: 'Price',
    hidden: false,
    classes: classNames('col-3', styles.actions),
    headerClasses: 'col-3'
  }
];

export type RowType = IOffer & {};

export const selectRow: SelectRowProps<RowType> = {
  mode: 'radio',
  hideSelectColumn: true,
  clickToSelect: true,
  classes: styles.selectedRow
};
