import { Link } from 'react-router-dom';

import classNames from 'classnames';
import styles from '../../../../../styles/components/navbar.module.scss';
import { IconArrowLeft } from '../../../../components/UI/icons';

import { enrollmentPaths } from '../routes/EnrollmentRoutes';

export const CourseHeaderPanel = () => {
  return (
    <div className={`navbar navbar-expand justify-content-start ${styles.navbar}`}>
      <Link className={classNames('btn btn-sm btn-icon-left', styles.link)} to={enrollmentPaths.enrollment}>
        <IconArrowLeft className={'icon'} />
        Enrollments
      </Link>
    </div>
  );
};
