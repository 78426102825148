import React, { useState } from 'react';

import classnames from 'classnames';
import styles from './list-item.module.scss';
import { IconPlus } from '../../../../../../../components/UI/icons';

import { ListItemSelected } from './ListItemSelected';

interface IProps {
  name: string;
  id: string;
  isSelected: boolean;
  isDetach?: boolean;
  onDetach?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  onAttach?: (id: string) => void;
  isDisable?: boolean;
}

export const ListItem = ({ name, isSelected, id, isDetach = true, onAttach, onDetach, isDisable }: IProps) => {
  const [hover, setHover] = useState(false);
  const onMouseEnterHandler = () => {
    setHover(true);
  };
  const onMouseLeaveHandler = () => {
    setHover(false);
  };

  const onClickHandler = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    const id = event.currentTarget.dataset['id'];
    if (!isSelected && id && onAttach) {
      onAttach(id);
    }
  };
  return (
    <button
      className={isSelected ? styles.containerSelect : styles.container}
      onMouseEnter={onMouseEnterHandler}
      onMouseLeave={onMouseLeaveHandler}
      onClick={onClickHandler}
      data-id={!isSelected && id}
      disabled={isDisable}
    >
      <div className={classnames('pl-1 pr-0', isSelected ? styles.textSelect : styles.text)}>{name}</div>
      <div className={classnames('pl-1 pr-1', styles.iconContainer)}>
        {isSelected ? (
          <div data-id={id} onClick={onDetach} className={'d-flex align-items-center'}>
            <ListItemSelected hover={hover && isDetach} />
          </div>
        ) : (
          <IconPlus className={styles.iconPlus} />
        )}
      </div>
    </button>
  );
};
