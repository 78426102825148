import { flatMapDeep, map } from 'lodash';

import { ICourse, ICourseNode } from '../../../../../modules/lms/courses/store/CourseState';
import { flatten } from '../../../course/CourseHelpers';

export const checkParentFromNodes = (node: ICourseNode, course?: ICourse) => {
  const flatNods = flatMapDeep<ICourseNode, ICourseNode>(node.children, flatten).filter(
    (x) => x.type === 'lesson' || x.type === 'folder'
  );
  const childrenHasDraft: ICourseNode[] = [];
  const childrenHasPublished: ICourseNode[] = [];
  const parentHasDraft: ICourseNode[] = [];
  const parentHasPublished: ICourseNode[] = [];
  map(flatNods, (item) => {
    if (item.state === 'draft') {
      childrenHasDraft.push(item);
    }
    if (item.state === 'published') {
      childrenHasPublished.push(item);
    }
  });

  const flatCourseNods = flatMapDeep<ICourseNode, ICourseNode>(course?.children, flatten).filter(
    (x) => x.type === 'lesson' || x.type === 'folder'
  );
  let currentParentNodeId: string | null = node.parent_id;
  while (currentParentNodeId !== null) {
    // eslint-disable-next-line no-loop-func
    const parentNode = flatCourseNods.find((x) => x.id === currentParentNodeId);
    if (parentNode) {
      currentParentNodeId = parentNode.parent_id;
      if (parentNode.state === 'draft') {
        parentHasDraft.push(parentNode);
      }
      if (parentNode.state === 'published') {
        parentHasPublished.push(parentNode);
      }
    }
  }
  return { childrenHasDraft, parentHasDraft, childrenHasPublished, parentHasPublished };
};
