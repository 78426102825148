import { memo } from 'react';

import classNames from 'classnames';
import styles from './loader.module.scss';

interface IProps {
  className?: string;
}

export const Loader = memo(({ className }: IProps) => {
  return (
    <div className={classNames(styles.overlay, className)}>
      <div className={styles.loader}>
        <div />
        <div />
        <div />
        <div />
      </div>
    </div>
  );
});
