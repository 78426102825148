import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { TableTags } from '../../../../../components/table-tags/TableTags';
import { StudentSelector } from '../../store/StudentDataSelector';

interface IProps {
  id: string;
}

export const Tags = ({ id }: IProps) => {
  const studentMemo = useMemo(StudentSelector, [id]);
  const studentData = useSelector((state) => studentMemo(state, id));

  return <TableTags tags={studentData?.student?.tags} />;
};
