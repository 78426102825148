import { memo, useRef } from 'react';
import { FormControl } from 'react-bootstrap';
import { Controller, useFormContext } from 'react-hook-form';

import classnames from 'classnames';
import styles from './range.module.scss';
import { IconLock, IconTrash } from '../../../../../../components/UI/icons';

import { IRangeIntervals } from '../../../../../../interfaces/node-builder-payload/automation-processors/range';

interface IProps {
  index: number;
  field: Partial<IRangeIntervals>;
  nodeId: string;
  isError: boolean;
  onChangeFieldEnd: (e: string, index: number) => void;
  onChangeFieldStart: (e: string, index: number) => void;
  removeElement?: (index: number, idElement: string) => void;
}

export const RangeItem = memo(
  ({ field, index, nodeId, isError, removeElement, onChangeFieldEnd, onChangeFieldStart }: IProps) => {
    const { control, setValue } = useFormContext();

    const fromRef = useRef<HTMLInputElement | null>(null);
    const toRef = useRef<HTMLInputElement | null>(null);

    const clickFromHandler = () => {
      fromRef.current?.focus();
    };
    const clickToHandler = () => {
      toRef.current?.focus();
    };
    const { end, id, start } = field;

    return (
      <>
        {isError && <div className={styles.errorIndex}>{index + 1}</div>}
        <div className={classnames('d-flex form-control form-control-sm pl-1 mt-2', isError ? styles.error : '')}>
          <div className={classnames('d-flex align-items-center mr-2', styles.from)}>
            {index === 0 ? (
              <IconLock onClick={clickFromHandler} className={styles.icon} />
            ) : (
              <IconTrash onClick={() => removeElement && id && removeElement(index, id)} className={styles.iconTrash} />
            )}
            <span onClick={clickFromHandler} className={'align-items-bottom'}>
              From
            </span>
            <Controller
              render={({ field: { onChange, value } }) => {
                return (
                  <FormControl
                    ref={fromRef}
                    placeholder={'0'}
                    className={classnames(styles.input, 'mr-2')}
                    type={'number'}
                    value={value}
                    onChange={(e) => {
                      onChange(e);
                      onChangeFieldStart(e.currentTarget.value, index);
                      setValue(`range.intervals.${index}.start`, Number(e.currentTarget.value));
                      // setValue(`${nodeId}.${id}.start`, Number(e.currentTarget.value));
                    }}
                  />
                );
              }}
              defaultValue={start}
              control={control}
              name={`range.intervals.${index}.start`}
            />
          </div>
          <div className={'d-flex align-items-center'}>
            <span onClick={clickToHandler} className={'align-items-bottom'}>
              To
            </span>
            <Controller
              name={`range.intervals.${index}.end`}
              control={control}
              defaultValue={end}
              render={({ field: { onChange, value } }) => {
                return (
                  <FormControl
                    ref={toRef}
                    placeholder={'0'}
                    type={'number'}
                    className={styles.input}
                    value={value}
                    onChange={(e) => {
                      onChange(e);
                      onChangeFieldEnd(e.currentTarget.value, index);
                      setValue(`range.intervals.${index}.end`, Number(e.currentTarget.value));
                      // setValue(`${nodeId}.${id}.end`, Number(e.currentTarget.value));
                    }}
                  />
                );
              }}
            />
          </div>
        </div>
      </>
    );
  }
);
