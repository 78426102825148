import { createSelector } from 'reselect';

import { FilterState } from '../../../../interfaces/filters';
import { IAppState } from '../../../../store';

export const FunnelsSelector = () =>
  createSelector(
    (_: IAppState, activeFilter: FilterState) => activeFilter,
    ({ funnels }: IAppState) => funnels.funnels,
    (state: IAppState) => state.funnels.loadState,
    (state: IAppState) => state.funnels.error,
    (activeFilter, funnels, loadState, error) => {
      let filteredList = funnels;
      switch (activeFilter) {
        case 'all':
          filteredList = funnels.filter((funnel) => {
            return funnel.state === 1 || funnel.state === 2;
          });
          break;
        case 'draft':
          filteredList = funnels.filter((funnel) => {
            return funnel.state === 2;
          });
          break;
        case 'published':
          filteredList = funnels.filter((funnel) => {
            return funnel.state === 1;
          });
          break;
        case 'archived':
          filteredList = funnels.filter((funnel) => {
            return funnel.state === 4;
          });
          break;
      }
      return {
        filteredList,
        loadState,
        error
      };
    }
  );

export const FunnelSelector = (state: IAppState, id?: string) => {
  const funnel = state.funnels.funnels.find((x) => x.id === id);
  if (funnel) {
    return funnel;
  }
  return null;
};
