import { lazy, memo, Suspense, useCallback, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import commonOfferStyles from '../../common/commonOffer.module.scss';

import { SenderSelect } from '../../../../../../components/sender-select/SenderSelect';
import { Loader } from '../../../../../../components/UI';
import { IMessage } from '../../../../../../interfaces';
import { additionalEmailVariables, EmailActionAsync, organizationFields } from '../../../../../common/email-editor';
import { IOffer } from '../../../store/OffersState';
import { SimpleToggle } from '../../common/toggle/SimpleToggle';
import { EmailAfterPurchaseWrapper } from './EmailAfterpurchaseWrapper';

const EmailEditor = lazy(() => import('../../../../../common/email-editor/EmailEditor'));

interface IProps extends Pick<IOffer, 'action_email_after_purchase' | 'action_email_after_purchase_sender_id'> {
  isToggle: boolean;

  setIsToggle(isToggle: boolean): void;
}

export const EmailAfterPurchase = memo(
  ({ action_email_after_purchase, action_email_after_purchase_sender_id, isToggle, setIsToggle }: IProps) => {
    const dispatch = useDispatch();
    const { setValue, getValues, watch } =
      useFormContext<Pick<IOffer, 'action_email_after_purchase' | 'action_email_after_purchase_sender_id'>>();
    const [showEditor, setShowEditor] = useState(false);
    const [message, setMessage] = useState<IMessage>();

    useEffect(() => {
      if (action_email_after_purchase) {
        dispatch(
          EmailActionAsync.getMessage(action_email_after_purchase, (message) => {
            setMessage(message);
          })
        );
      } else {
        setMessage(undefined);
      }
    }, [action_email_after_purchase, dispatch]);

    const onOpenEditor = useCallback(() => {
      if (
        (!action_email_after_purchase && !getValues('action_email_after_purchase')) ||
        (!getValues('action_email_after_purchase') && !message)
      ) {
        dispatch(
          EmailActionAsync.createMessage((message) => {
            setMessage(message);
            setValue('action_email_after_purchase', message.id, {
              shouldDirty: true,
              shouldValidate: true
            });
            setShowEditor(true);
          })
        );
      } else {
        setShowEditor(true);
      }
    }, [action_email_after_purchase, dispatch, getValues, message, setValue]);

    const onCloseEditor = useCallback(() => {
      setShowEditor(false);
    }, [setShowEditor]);

    const onChangeValue = useCallback(
      (show: boolean) => {
        setIsToggle(show);
        if (!show) {
          setValue('action_email_after_purchase', null, { shouldDirty: true, shouldValidate: true });
          // setMessage(undefined);
        } else {
          if (action_email_after_purchase) {
            dispatch(
              EmailActionAsync.getMessage(action_email_after_purchase, (message) => {
                console.log('%c⇒ messages', 'color: #89DDF7', message);
                setMessage(message);
                setValue('action_email_after_purchase', message.id, {
                  shouldDirty: true,
                  shouldValidate: true
                });
              })
            );
          }
        }
      },
      [setIsToggle, setValue, action_email_after_purchase, dispatch]
    );

    const onClear = useCallback(() => {
      setValue('action_email_after_purchase', null, { shouldDirty: true, shouldValidate: true });
      setMessage(undefined);
    }, [setValue, setMessage]);

    return (
      <>
        <SimpleToggle
          isToggle={isToggle}
          label={'Send an e-mail after the purchase is made'}
          onChangeValue={onChangeValue}
        >
          <SenderSelect
            name={'action_email_after_purchase_sender_id'}
            selectedId={watch('action_email_after_purchase_sender_id') || action_email_after_purchase_sender_id}
          />
          <EmailAfterPurchaseWrapper onOpenEditor={onOpenEditor} message={message} onClear={onClear} />
        </SimpleToggle>
        {!isToggle && <div className={commonOfferStyles.text}>Use this option to send an e-mail to the customer.</div>}

        {message && (
          <Suspense fallback={<Loader />}>
            <EmailEditor
              onCloseEmailEditor={onCloseEditor}
              showEmailEditor={showEditor}
              message={message}
              excludeScopes={['student_relation', 'course_relation', 'contact_relation']}
              additionalPropertyToEntity={additionalEmailVariables}
              fieldEntities={['contact', 'student']}
              additionalVariables={organizationFields}
            />
          </Suspense>
        )}
      </>
    );
  }
);
