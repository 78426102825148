import React, { memo } from 'react';
import { Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import styles from './create-list-modal.module.scss';
import { IconCheckMark } from '../../../../../components/UI/icons';

import { ModalActionComponent } from '../../../../../components/UI';
import { CRMPaths } from '../../../routes/CRMRoutes';
import { ListsActionAsync } from '../../store/ListsActionAsync';

interface IProps {
  show: boolean;

  onCloseModal(): void;
}

export const CreateListModal = memo(({ show, onCloseModal }: IProps) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { lists } = useSelector((state) => state.lists);
  const { register, getValues, setFocus } = useForm<{ createList: string }>();
  const { ref, ...rest } = register('createList', {shouldUnregister: true});

  const onSave = () => {
    const value = getValues('createList') || `New list ${lists.length + 1}`;
    dispatch(ListsActionAsync.createList({ title: value }));
    onCloseModal();
  };

  const onSaveOpen = async () => {
    const value = getValues('createList') || `New list ${lists.length + 1}`;
    dispatch(
      ListsActionAsync.createList({ title: value }, (list) => {
        if (list.id) {
          history.push(CRMPaths.crmListId(list.id));
        }
      })
    );
    onCloseModal();
  };

  return (
    <ModalActionComponent title={'Create a new list'} onCloseModal={onCloseModal} show={show} iconType="create">
      <div className="mt-4">
        <Form.Group controlId={'create'}>
          <Form.Control
            placeholder={'List name'}
            defaultValue={`New list ${lists.length + 1}`}
            {...rest}
            ref={(e: null) => {
              ref(e);
              if (e) {
                setFocus('createList', {shouldSelect: true});
              }
            }}
          />
        </Form.Group>
      </div>
      <div className={styles.modalFooter}>
        <button type={'button'} className={'btn btn-sm'} onClick={onCloseModal}>
          Cancel
        </button>
        <button type={'button'} className={'btn btn-sm btn-gradient-secondary mx-2'} onClick={onSaveOpen}>
          Save and open
        </button>
        <button type={'button'} className={'btn btn-sm btn-icon-right btn-gradient-primary'} onClick={onSave}>
          Save
          <IconCheckMark />
        </button>
      </div>
    </ModalActionComponent>
  );
});
