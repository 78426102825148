import { memo } from 'react';
import { useFormContext } from 'react-hook-form';

import styles from './emailAfterPurchase.module.scss';

import { IMessage } from '../../../../../../interfaces';
import { IOffer } from '../../../store/OffersState';
import { EmptyEmail } from './empty/EmptyEmail';
import { PreviewEmail } from './preview-email/PreviewEmail';

interface IProps {
  message?: IMessage;

  onOpenEditor(): void;

  onClear(): void;
}

export const EmailAfterPurchaseWrapper = memo(({ message, onOpenEditor, onClear }: IProps) => {
  const { getValues } = useFormContext<Pick<IOffer, 'action_email_after_purchase'>>();
  const emailData = getValues('action_email_after_purchase');

  return (
    <>
      <div className={styles.container}>
        {!emailData && <EmptyEmail onAddEmail={onOpenEditor} />}
        {emailData && <PreviewEmail message={message} onOpenEditor={onOpenEditor} onClear={onClear} />}
      </div>
    </>
  );
});
