import { Tag } from 'react-tag-autocomplete-fix';
import actionCreatorFactory from 'typescript-fsa';

import { IContact } from '../store/ContactListState';

export const actionCreator = actionCreatorFactory();

export const currentContactActions = {
  setCurrentContact: actionCreator<{ contact: { contact: IContact; tagContact: Tag[]; lists?: string[] } | undefined }>(
    'SET_CURRENT_CONTACT'
  ),
  getListIdsOfContact: actionCreator.async<{ id: string }, string[], Error>('GET_LISTIDS_OF_CURRENT_CONTACT'),
  editContactList: actionCreator.async<{ id: string; listIds: string[] }, {}, Error>('EDIT_CURRENT_CONTACT_LIST')
};
