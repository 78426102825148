import { memo } from 'react';

import styles from './question.module.scss';

import { Button } from '../../../../../../components/UI';

interface IProps extends React.ComponentProps<typeof Button> {
  title: string;
}

export const BtnQuestion = memo(({ title, ...props }: IProps) => {
  return (
    <Button size={'lg'} withIcon={true} iconPosition={'left'} className={styles.btnSize} {...props}>
      {title}
    </Button>
  );
});
