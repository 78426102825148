import React, { memo, useCallback } from 'react';
import { FormCheck } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import styles from './step-four.module.scss';

import { Button } from '../../../../../components/UI';
import { IReason } from '../../../../../interfaces';
import { billingActionAsync } from '../../../billing/store';
import { UnsubscribeSteps } from '../CancelSubscription';

const answers = [
  { name: 'Moving to competitors product', value: '1' },
  { name: 'Missing features', value: '2' },
  { name: 'Lack of support', value: '3' },
  { name: 'Other', value: '4' }
];

interface IProps {
  onCancel(): void;

  onNext(step: UnsubscribeSteps): void;
}

export const StepFour = memo(({ onCancel, onNext }: IProps) => {
  const dispatch = useDispatch();
  const { register, getValues } = useForm<IReason>();
  const cancelCallback = useCallback(() => {
    onNext('step-5');
    dispatch(billingActionAsync.getSubscription());
  }, [dispatch, onNext]);

  const nextStep = () => {
    const reason = getValues();
    if (reason.reason) {
      dispatch(billingActionAsync.subscriptionReason(reason));
    }
    dispatch(billingActionAsync.deleteSubscription(true, cancelCallback));
  };

  const handleSubmit = () => {
    onCancel();
  };

  return (
    <div className={styles.container}>
      <div className={styles.label}>One last step</div>
      <div className={styles.title}>
        Please let us know about the reason why <br /> you are leaving us
      </div>
      {answers.map((item, index) => (
        <FormCheck custom className={'radio-button py-1'} type={'radio'} key={index}>
          <FormCheck.Input
            className="custom-control-input"
            type={'radio'}
            {...register('reason')}
            id={String(index)}
            onChange={() => {}}
            value={item.name} />
          <FormCheck.Label className="custom-control-label" htmlFor={String(index)}>
            {item.name}
          </FormCheck.Label>
        </FormCheck>
      ))}
      <textarea className={'form-control mt-1 mb-4'} {...register('comment')}></textarea>
      <Button onClick={handleSubmit} size={'lg'} withIcon={false} className={'mb-2 w-100'}>
        Nevermind, I’m staying with you
      </Button>
      <Button onClick={nextStep} size={'lg'} withIcon={false} className={'w-100'} btnStyle={'transparent'}>
        Cancel my account
      </Button>
    </div>
  );
});
