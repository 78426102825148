import { createSelector } from 'reselect';
import { find, flatMapDeep } from 'lodash';

import { flatten } from '../../../../components/course/course/CourseHelpers';
import { CourseNodeType } from '../../../../interfaces';
import { FilterState } from '../../../../interfaces/filters';
import { IAppState } from '../../../../store';
import { CourseStates, ICourseNode } from './CourseState';

export const CoursesSelector = () =>
  createSelector(
    (_: IAppState, activeFilter: FilterState) => activeFilter,
    ({ courses }: IAppState) => courses.courses,
    (state: IAppState) => state.courses.loadState,
    (state: IAppState) => state.courses.error,
    (activeFilter, courses, loadState, error) => {
      let filteredList = courses;
      switch (activeFilter) {
        case 'all':
          filteredList = courses.filter((course) => {
            return course.state === CourseStates.DRAFT || course.state === CourseStates.PUBLISHED;
          });
          break;
        case 'draft':
          filteredList = courses.filter((course) => {
            return course.state === CourseStates.DRAFT;
          });
          break;
        case 'published':
          filteredList = courses.filter((course) => {
            return course.state === CourseStates.PUBLISHED;
          });
          break;
        case 'archived':
          filteredList = courses.filter((course) => {
            return course.state === CourseStates.ARCHIVED;
          });
          break;
      }
      return {
        courses: filteredList,
        loadState,
        error
      };
    }
  );

export const CourseSelector = (courseId: string | undefined) =>
  createSelector(
    (state: IAppState) => state.courses.courses,
    (state: IAppState) => state.courses.loadState,
    (state: IAppState) => state.courses.error,
    (courses, loadState, error) => {
      return {
        course: courses?.find((course) => course.id === courseId),
        loadState,
        error
      };
    }
  );

export const CourseNodeTreeSelector = (
  hierarchy: ICourseNode[],
  nodeId: string | undefined,
  nodeType: CourseNodeType[]
) =>
  createSelector(() => {
    const treeNodes: ICourseNode[] = [];
    let node = find<ICourseNode | undefined>(flatMapDeep(hierarchy, flatten), ['id', nodeId]);
    while (node?.parent_id) {
      node = find<ICourseNode | undefined>(flatMapDeep(hierarchy, flatten), ['id', node?.parent_id]);
      if (node && nodeType.includes(node?.type)) {
        treeNodes.push(node);
      }
    }
    return {
      treeNodes
    };
  });
