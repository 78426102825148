import { useRouteMatch } from 'react-router-dom';

import classNames from 'classnames';
import styles from './sidebar-organization.module.scss';
import { Icon16CheckMark } from '../../../components/UI/icons';

import { TransparentPercentLoader } from '../../../components/UI/percent-loader/TransparentPercentLoader';
import { IFile } from '../../../interfaces';
import { OrganizationImg } from '../../../layout/shared-components/organization-img/OrganizationImg';
import { previewPaths } from '../../lms/preview/routes/PreviewRoutes';

interface IProps {
  img: IFile | null;
  themeLogo?: IFile | null;
  name: string;
  title: string;
  countLessons: number;
  passLessons: number;
  isFavorite?: boolean;
}

export const SidebarStudentInfo = ({ countLessons, passLessons, img, title, name, themeLogo }: IProps) => {
  const passPercent = passLessons && Math.round((passLessons * 100) / countLessons);
  const preview = useRouteMatch(previewPaths.previewLessons());
  return (
    <>
      <div className={classNames('d-flex align-items-center mt-3', styles.orgContainer)}>
        <OrganizationImg
          organizationImg={img}
          organizationName={name}
          themeLogo={themeLogo}
          height={24}
          width={24}
          className={classNames(styles.orgImg, { [styles.bg]: !img })}
          textClassName={styles.orgText}
        />
        <div className={classNames(styles.orgName, 'ellipsis')}>{name}</div>
      </div>
      <div className={classNames(styles.title, 'ellipsis')}>
        {title}
        {/* <IconBookmark /> */}
      </div>
      {!preview && (
        <div className={styles.lessons}>
          Completed lessons:
          <span className={styles.lessonInfo}>
            {passLessons} of {countLessons}
            <span className={styles.dot}>•</span>
            {passPercent === 100 ? (
              <div className={styles.completeContainer}>
                <Icon16CheckMark className={styles.completeIcon} />
                <span className={styles.completed}>Completed</span>
              </div>
            ) : (
              <>
                <TransparentPercentLoader value={passPercent} className={styles.percentContainer} />
                <span className={styles.passPercent}>{passPercent}%</span>
              </>
            )}
          </span>
        </div>
      )}
    </>
  );
};
