import { memo } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';

import classNames from 'classnames';
import styles from './domain-item-state.module.scss';
import { Icon16Reload, Icon16Spinner } from '../../../../../components/UI/icons';

import { ItemStatus } from '../../../../../components/itemStatus/ItemStatus';
import { Button } from '../../../../../components/UI';
import { LoadState } from '../../../../../store';
import { useDomainContext } from '../../DomainContext';

interface IProps {
  onUpdateStatus: () => void;
}

export const DomainItemState = memo(({ onUpdateStatus }: IProps) => {
  const domain = useDomainContext();
  const lastCheck = moment(domain?.last_status_check).fromNow();
  const { loadState } = useSelector((state) => state.domains);
  const loading = loadState === LoadState.needLoad;

  return (
    <>
      <div className={styles.statusContainer}>
        {domain?.state === 'done' && <ItemStatus label={'Connected'} className={'published w-auto'} />}
        {domain?.state === 'lost_connection' && <ItemStatus label={'Lost connection'} className={'archived w-auto'} />}
        {domain?.state === 'not_configured' && <ItemStatus label={'Is connecting'} className={'connecting'} />}
        <Button
          onClick={onUpdateStatus}
          customIcon={loading ? <Icon16Spinner className={'spinner'} /> : <Icon16Reload className={styles.icon} />}
          btnStyle={'transparent'}
          className={classNames('ml-2', { [styles.loading]: loading }, { [styles.loaded]: !loading })}
        >
          Update status
        </Button>
        <div className={styles.lastCheck}>Last check: {lastCheck}</div>
      </div>
    </>
  );
});
