import { memo } from 'react';
import moment from 'moment';

import classNames from 'classnames';
import canvasStyles from '../canvas-node.module.scss';
import styles from './fixed-period.module.scss';

import { NodeCanvasLoader } from '../common/NodeCanvasLoader';
import { EndpointsDelay } from './EndpointsDelay';

interface IProps {
  nodeId: string;
  days?: number;
  hours?: number;
  minutes?: number;
  noEarlier?: string;
  noLater?: string;
}

export const CanvasDelayFixedPeriod = memo(
  ({ nodeId, noEarlier, noLater, days = 1, hours = 0, minutes = 0 }: IProps) => {
    const daysData = days;
    const hoursData = hours;
    const minutesData = minutes;

    const noEarlierData = noEarlier;
    const noLaterData = noLater;

    return (
      <div className={classNames(canvasStyles.bgContainer, styles.container)}>
        <NodeCanvasLoader nodeId={nodeId}>
          <>
            <div className={styles.timeContainer}>
              <div>
                <div className={styles.title}>For a fixed period</div>
                <div className={'d-flex'}>
                  <span className={styles.dateText}>{daysData}d </span>

                  <span className={styles.dateText}>{hoursData}h </span>

                  <span className={styles.dateText}>{minutesData}m </span>
                </div>
                {(noLaterData || noEarlierData) && (
                  <>
                    <div className={styles.title}>No earlier</div>

                    <span>{noEarlierData ? moment(noEarlierData, 'HH:mm').format('hh:mm A') : '09:00 AM'}</span>

                    <div className={styles.title}>and no later</div>
                    <span>{noLaterData ? moment(noLaterData, 'HH:mm').format('hh:mm A') : '09:00 AM'}</span>
                  </>
                )}
              </div>
            </div>
          </>
        </NodeCanvasLoader>
        {/* <EndpointsDelay nodeId={nodeId} /> */}
      </div>
    );
  }
);
