import { FC, memo, SyntheticEvent } from 'react';
import { FormCheck } from 'react-bootstrap';
import { useDispatch } from 'react-redux';

import styles from './lesson-state.module.scss';

import { courseActions } from '../../../../../../modules/lms/courses/store/CourseActions';
import { Button } from '../../../../../UI';

interface IProps {
  onClose: () => void;
}

export const LessonParentDraft: FC<IProps> = memo(({ onClose, children }) => {
  const dispatch = useDispatch();
  const onChangeHandler = (e: SyntheticEvent<HTMLInputElement>) => {
    dispatch(courseActions.showPopupIfParentDraft(e.currentTarget.checked));
  };
  return (
    <div className={'px-6 pb-4'}>
      {children}
      <div className={styles.description}>
        One or more folders that contain this lesson is not published, this lesson will be visible only if all upper
        folders will be published.
      </div>
      <div className={'d-flex justify-content-between mt-4'}>
        <FormCheck className={'mb-3'} custom type={'checkbox'}>
          <FormCheck.Input
            defaultChecked={false}
            type="checkbox"
            id={'show'}
            name={'show'}
            onChange={onChangeHandler}
          />
          <FormCheck.Label htmlFor={'show'}>Do not show this next time</FormCheck.Label>
        </FormCheck>
        <Button withIcon={false} onClick={onClose}>
          Close
        </Button>
      </div>
    </div>
  );
});
