import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import styles from './payment-options.module.scss';
import { IconPlus } from '../../../../components/UI/icons';

import { CommonModal, Loader } from '../../../../components/UI';
import { IPaymentCard } from '../../../../interfaces';
import { LoadState } from '../../../../store';
import { HoorayCardAdd } from '../../../dashboard/hooray/HoorayCardAdd';
import { NoPaymentOptions } from '../../common/no-payment-options/NoPaymentOptions';
import { PaymentCard } from '../../common/payment-card/PaymentCard';
import { SettingsCollapseBlock } from '../../common/settings-collapse-block/SettingsCollapseBlock';
import { SettingCourseActionAsync } from '../../store/SettingCourseActionAsync';
import { AddCardModal } from '../add-card-modal/AddCardModal';

export const PaymentOptions = () => {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [clientSecret, setClientSecret] = useState('');
  const { paymentCards, loadState } = useSelector((state) => state.settingCourse);
  const { subscription } = useSelector((state) => state.billing);
  const { dashboard } = useSelector((state) => state);
  const [showHoorayCard, setShowHoorayCard] = useState(false);

  const dateEnd =
    subscription?.subscription_current_period_end && moment(subscription.subscription_current_period_end).toDate();
  const nowDate = moment();

  const leftDays = moment(dateEnd).diff(nowDate, 'days');

  const checkCard = useCallback(
    (cards: IPaymentCard[]) => {
      if (cards.length === 1 && leftDays >= 7 && leftDays <= 14 && subscription?.subscription_status === 'trialing') {
        setShowHoorayCard(true);
      }
    },
    [leftDays, subscription?.subscription_status]
  );

  useEffect(() => {
    if (loadState === LoadState.firstLoad) {
      setLoading(true);
    }

    if (loadState === LoadState.allIsLoaded || loadState === LoadState.error) {
      setLoading(false);
    }
  }, [checkCard, dashboard, leftDays, loadState, subscription?.subscription_status]);

  useEffect(() => {
    if (paymentCards.length === 1 && !paymentCards[0].is_default) {
      dispatch(SettingCourseActionAsync.setDefaultCard(paymentCards[0].id));
    }
  }, [dispatch, paymentCards]);

  useEffect(() => {
    dispatch(SettingCourseActionAsync.getPaymentCards());
  }, [dispatch]);

  const onCloseHoorayModal = useCallback(() => {
    setShowHoorayCard(false);
  }, []);

  const clientSecretCallback = (clientSecret: string) => {
    setClientSecret(clientSecret);
    setShowModal(true);
  };

  const addCreditCard = useCallback(() => {
    dispatch(SettingCourseActionAsync.getClientSecret(clientSecretCallback));
  }, [dispatch]);

  const closeModal = () => {
    setShowModal(false);
  };

  const onSuccess = () => {
    closeModal();
    dispatch(SettingCourseActionAsync.getPaymentCards(checkCard));
  };

  return (
    <>
      <SettingsCollapseBlock title={'Payment Options'}>
        {paymentCards.length > 0 && (
          <div className={styles.container}>
            {loading && <Loader />}
            <div className={'d-flex align-items-center'}>
              {paymentCards.map((card) => (
                <PaymentCard key={card.id} card={card} canBeRemoved={paymentCards.length > 1} className={'mb-2'} />
              ))}
              <div className={styles.addCard} onClick={addCreditCard}>
                <div className={styles.addIcon}>
                  <IconPlus />
                </div>
                <div>Add credit card</div>
              </div>
            </div>
          </div>
        )}
        {paymentCards.length === 0 && <NoPaymentOptions onCLick={addCreditCard} />}
        <CommonModal show={showModal} onClose={closeModal}>
          <AddCardModal clientSecret={clientSecret} onClose={closeModal} onSuccess={onSuccess} />
        </CommonModal>
      </SettingsCollapseBlock>
      <HoorayCardAdd showHoorayCard={showHoorayCard} onCloseModal={onCloseHoorayModal} />
    </>
  );
};
