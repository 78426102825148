import { Dispatch, SetStateAction, useCallback, useRef, useState } from 'react';

export const useDerivedState = <V>(initialValue: V, deeps: unknown[]): [V, Dispatch<SetStateAction<V>>] => {
  const refDeeps = useRef(deeps);
  const [, forceUpdate] = useState(0);
  const refValue = useRef(initialValue);
  const setValue = useCallback((newValue: V | ((v: V) => V)) => {
    const nextValue = newValue instanceof Function ? newValue(refValue.current) : newValue;
    forceUpdate((lastValue) => {
      if (refValue.current !== nextValue) {
        refValue.current = nextValue;

        return lastValue + 1;
      } else {
        return lastValue;
      }
    });
  }, []);

  if (refDeeps.current.some((item, index) => deeps[index] !== item)) {
    refDeeps.current = deeps;
    refValue.current = initialValue;

    return [refValue.current, setValue];
  } else {
    return [refValue.current, setValue];
  }
};
