import moment from 'moment';
import * as yup from 'yup';
import { find, flatMapDeep, mapValues, round } from 'lodash';

import { flatten } from '../components/course/course/CourseHelpers';
import { options } from '../components/UI/fields/date-field/DateField';
import { CourseNodeType, NodeGeometry, schema, TypeSchema } from '../interfaces';
import { FieldTypes } from '../modules/crm/field';
import { ICourseNode } from '../modules/lms/courses/store/CourseState';

export const getUUID = () => {
  return Math.random().toString(36).substr(3, 10);
};

/**
 * Convert geometry object to array
 */
export const geometryObjToArr = (geometryObj: Record<string, NodeGeometry>): NodeGeometry[] => {
  return Object.keys(geometryObj).map((item) => {
    return {
      node_id: item,
      position_x: geometryObj[item].position_x,
      position_y: geometryObj[item].position_y
    };
  });
};

/**
 * Convert array to object
 * @param array
 * @param key
 */
export const convertArrayToObject = (array: any[], key: string): Record<string, any> =>
  array.reduce((acc, curr) => {
    acc[curr[key]] = curr;
    return acc;
  }, {});

export const _id = (id: string): string => {
  return `_${id}`;
};

export const rgbToHex = (r: number, g: number, b: number) =>
  [r, g, b].map((x) => x.toString(16).padStart(2, '0')).join('');

export const hexToRGB = (h: string) => {
  let r = '';
  let g = '';
  let b = '';

  // 3 digits
  if (h.length === 4) {
    r = '0x' + h[1] + h[1];
    g = '0x' + h[2] + h[2];
    b = '0x' + h[3] + h[3];

    // 6 digits
  } else if (h.length === 7) {
    r = '0x' + h[1] + h[2];
    g = '0x' + h[3] + h[4];
    b = '0x' + h[5] + h[6];
  }

  return { r: +r, g: +g, b: +b };
};

export const middleColor = (lightColor: string, darkColor: string) => {
  const light = hexToRGB(lightColor);
  const dark = hexToRGB(darkColor);
  return `rgb(${round((dark.r + light.r) / 2)}, ${round((dark.g + light.g) / 2)}, ${round((dark.b + light.b) / 2)})`;
};

export const validationSchema = (type: FieldTypes, value: any) => {
  if (type === 'short_text' || type === 'long_text') {
    return value.toString();
  }
  if (type === 'date_time') {
    if (value.date) {
      const date = moment(value.date);
      const full = date.year() + '-' + (date.month() + 1) + '-' + date.date();
      const fullDateTime = new Date(
        `${full} ${value.time ? value.time : '12:00'}:00 ${value.zo ? value.zo : 'AM'} UTC`
      );
      return fullDateTime.toISOString();
    }
  }
  if (type === 'numeric') {
    return Number(value);
  }
  if (type === 'price') {
    return value.amount === null ? null : value;
  }
  return value;
};

export const validateEmail = (email: string) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}])|(([a-zA-Z\-\d]+\.)+[a-zA-Z]{2,}))$/
    );
};

export const arrStrForSelect = (arr: string[]) => {
  let res: { label: string; value: string }[] = [];
  for (let i = 0; i < arr.length; i++) {
    res.push({ label: arr[i], value: `${arr[i]}_${i}` });
  }
  return res;
};

export const formatTime = (date: Date) => {
  let hours = date.getUTCHours();
  let minutes = date.getUTCMinutes();
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  const newHours = hours < 10 ? '0' + hours : hours;
  const newMinutes = minutes < 10 ? '0' + minutes : minutes;
  return newHours + ':' + newMinutes;
};

export const getSeparatedDate = (value: Date) => {
  const date = new Date(value);
  const time = formatTime(date);
  return {
    date: date,
    time: time,
    zo: date.getUTCHours() >= 12 ? options[1] : options[0]
  };
};

export const lazySchema = (fields: { [x: string]: TypeSchema }) => {
  return yup.lazy((obj) =>
    yup.object(
      mapValues<typeof schema, any>(obj, (v, k: TypeSchema) => {
        // console.log(k, v, fields[k]);
        if (fields[k]) {
          return schema[fields[k]];
        }
      })
    )
  );
};

export const timestamp = new Date().getTime();

export const addDataToTable = (pageSize: number, total: number) => {
  return { page: Math.ceil((total + 1) / pageSize), total: total + 1 };
};

export const removeDataFromTable = (pageSize: number, total: number) => {
  return { page: Math.ceil((total - 1) / pageSize || 1), total: total - 1 };
};

export const selectedOption = (data: { label: string; value: string }[], value: string | null) => {
  return data[data.findIndex((x) => x.value === value)];
};

export const downloadCSV = (data: string, filename: string) => {
  const encodedUri = encodeURI('data:text/csv;charset=utf-8,' + data);
  let link = document.createElement('a');
  link.setAttribute('href', encodedUri);
  link.setAttribute('download', `${filename}.csv`);
  document.body.appendChild(link);
  link.click();
};

type Entries<T> = {
  [K in keyof T]: [K, T[K]];
}[keyof T][];

export function ObjectEntries<T>(obj: T): Entries<T> {
  return Object.entries(obj) as any;
}

export const getCourseNodeTree = (hierarchy: ICourseNode[], nodeId: string, nodeType: CourseNodeType[]) => {
  const treeNodes: ICourseNode[] = [];
  let node = find<ICourseNode | undefined>(flatMapDeep(hierarchy, flatten), ['id', nodeId]);
  while (node?.parent_id) {
    node = find<ICourseNode | undefined>(flatMapDeep(hierarchy, flatten), ['id', node?.parent_id]);
    if (node && nodeType.includes(node?.type)) {
      treeNodes.push(node);
    }
  }
  return treeNodes.reverse();
};

const calculateVh = () => {
  const vh = window.innerHeight;
  document.documentElement.style.setProperty('--vh', vh + 'px');
};

export const adaptiveHeight = () => {
  // Initial calculation
  calculateVh();

  // Re-calculate on resize
  window.addEventListener('resize', calculateVh);

  // Re-calculate on device orientation change
  window.addEventListener('orientationchange', calculateVh);
};

/* A regular expression that matches a domain name. */
export const domainPattern = /^(?!:\/\/)([a-zA-Z0-9-_]+\.)*[a-zA-Z0-9][a-zA-Z0-9-_]+\.[a-zA-Z]{2,11}?$/;
/* A regular expression that matches a subdomain name. */
export const subdomainPattern = /^[a-z0-9]+$/;
/* A regular expression that matches a custom sender email name. */
export const senderEmailPattern = /^[a-z0-9]+$/;
