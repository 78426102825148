export interface IZoomValue {
  value: number;
  isDefault: boolean;
}

export interface ISettings {
  zoomValue: IZoomValue;
  searchString: string;
  isSidebarShow: boolean;
  isPayloadShow: boolean;
  mainListId: string;
  sidebarWidth: number;
  loading: boolean;
  isCommonPayloadShow: Record<string, boolean>
}

export const SettingsInitialState: ISettings = {
  zoomValue: {
    value: 1,
    isDefault: false
  },
  searchString: '',
  isSidebarShow: false,
  isPayloadShow: false,
  mainListId: '',
  sidebarWidth: 416,
  loading: false,
  isCommonPayloadShow: {}
};
