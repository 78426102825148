import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { validationSchema } from '../../../../helpers/validationSchema';
import { LoadState } from '../../../../store';
import { FieldActionAsync, FieldScope } from '../../field';
import { FieldSelector } from '../../field/store/FieldSelector';
import { ProductList } from '../product-list/ProductList';
import { ProductsActionAsync } from '../store/ProductsActionAsync';

export const ProductListWrapper = () => {
  const dispatch = useDispatch();
  const { fields } = useSelector((state) => state.fields);
  const { productList, loadState } = useSelector((state) => state.productsConfig);
  const productFields = useMemo(
    () =>
      productList?.fields?.map((listField) => {
        listField.field = fields.find((field) => field.id === listField.field_id);
        return listField;
      }) || [],
    [fields, productList?.fields]
  );

  /**
   * Fields for sorting
   */
  const sort = useMemo(
    () =>
      productFields.reduce<Partial<Record<`${FieldScope}`, string>>>(
        (a, v) => (v.field ? { ...a, [v.field.scope]: v.field_id } : { ...a }),
        {}
      ),
    [productFields]
  );

  const sortFields = useMemo(() => {
    return sort.title ? [sort.title] : [];
  }, [sort]);

  const fieldMemo = useMemo(FieldSelector, []);
  const { products } = useSelector((state) => fieldMemo(state, 'product'));
  const schema = validationSchema(products);

  useEffect(() => {
    if (fields.length === 0) {
      dispatch(FieldActionAsync.getFields());
    }
  }, [dispatch, fields.length]);

  useEffect(() => {
    if (productList === null) {
      dispatch(ProductsActionAsync.getProductsConfig('regular'));
    }
  }, [dispatch, productList]);

  useEffect(() => {
    if (sortFields.length) {
      dispatch(
        ProductsActionAsync.getProducts(
          'regular',
          {
            page: 1,
            page_size: 25
          },
          undefined,
          sortFields
        )
      );
    }
  }, [dispatch, sortFields]);

  return loadState === LoadState.allIsLoaded ? (
    <ProductList fields={productFields} schema={schema} sortFields={sortFields} productType={'regular'} />
  ) : null;
};
