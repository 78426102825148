import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Prompt, useRouteMatch } from 'react-router-dom';

import classNames from 'classnames';
import styles from './create-offer.module.scss';

import Tabs from '../../../../components/custom-tab';
import Tab from '../../../../components/custom-tab/Tab';
import { ItemStatus } from '../../../../components/itemStatus/ItemStatus';
import { Loader } from '../../../../components/UI';
import { GeneralSettingsActionAsync } from '../../../settings/general/store/GeneralSettingsActionAsync';
import { SettingsPaths } from '../../../settings/routes/SettingsRoutes';
import { FieldActionAsync } from '../../field';
import { CRMPaths } from '../../routes/CRMRoutes';
import { NoPaymentOffer } from '../common/error-publish-offer/NoPaymentOffer';
import { NoProductsMessage } from '../common/error-publish-offer/NoProductsMessage';
import { OffersActionAsync } from '../store/OffersActionAsync';
import { OfferSelector } from '../store/OfferSelector';
import { Actions } from './actions/Actions';
import { CreateOfferContext } from './CreateOfferContext';
import { Price } from './price/Price';
import { Products } from './products/Products';
import { Publish } from './publish/Publish';
import { Shipping } from './shipping/Shipping';

interface IProps {
  offerPropsId?: string;
}

export const CreateOffer = ({ offerPropsId }: IProps) => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [showUnsavedModal, setShowUnsavedModal] = useState<boolean>(false);
  const [save, setSave] = useState(false);
  const dispatch = useDispatch();
  const path = useRouteMatch<{ id: string }>(CRMPaths.crmOffer(':id'));
  const offerId = offerPropsId || path?.params.id;
  const OfferMemo = useMemo(OfferSelector, []);
  const { offer } = useSelector((state) => OfferMemo(state, { offerId }));
  const { fields } = useSelector((state) => state.fields);
  const { paynet, stripe } = useSelector((state) => state.general.paymentAccount);

  const tabSelectingIndex = useRef(0);

  const getOffer = useCallback(() => {
    if (offerId) {
      dispatch(OffersActionAsync.getOffer(offerId));
    }
  }, [dispatch, offerId]);

  const onChangeTab = (tab: number) => {
    if (!save) {
      setSelectedTab(tab);
    } else if (save && selectedTab !== tab) {
      setShowUnsavedModal(true);
      tabSelectingIndex.current = tab;
    }
  };

  useEffect(() => {
    if (fields.length === 0) {
      dispatch(FieldActionAsync.getFields());
    }
  }, [dispatch, fields]);

  useEffect(() => {
    getOffer();
  }, [getOffer]);

  useEffect(() => {
    dispatch(GeneralSettingsActionAsync.getPaynetAccount());
    dispatch(GeneralSettingsActionAsync.getStripeAccount());
    dispatch(GeneralSettingsActionAsync.getPaypalAccount());
  }, [dispatch]);

  return (
    <CreateOfferContext.Provider
      value={{
        fields,
        offer,
        save,
        showUnsavedModal,
        setSave,
        setShowUnsavedModal,
        onNavigate: setSelectedTab,
        tabNavigationIndex: tabSelectingIndex.current
      }}
    >
      <Prompt
        when={save}
        message={JSON.stringify({
          title: 'Do you really want to leave this offer?',
          confirmText: 'Continue editing',
          messageText:
            'If you leave the offer, all changes will be discarded. You will need to make changes again to continue.',
          cancelText: 'Leave offer'
        })}
      />

      <div className={classNames(styles.container, 'createOffer')}>
        <Tabs selectedTab={selectedTab} setSelectedTab={onChangeTab} containerClassName={'rounded'}>
          <Tab title="Products" eventKey={'products'}>
            <div className={styles.title}>Customize your offer</div>
            {!offer ? (
              <Loader />
            ) : (
              <Products
                id={offer.id}
                products={offer.products}
                setting_call_to_action={offer.setting_call_to_action}
                title={offer.title}
                description={offer.description}
                getOffer={getOffer}
              />
            )}
          </Tab>
          <Tab title="Price" eventKey={'price'}>
            <NoProductsMessage products={offer?.products || []} />
            <div className={styles.priceContainer}>
              <div className={styles.title}>Set the price for your offer</div>
              {offer && (
                <Price
                  id={offer.id}
                  price={offer.price}
                  order_bump_checkbox_text={offer.order_bump_checkbox_text}
                  order_bump_description={offer.order_bump_description}
                  order_bump_headline={offer.order_bump_headline}
                  order_bump_is_active={offer.order_bump_is_active}
                  order_bump_old_price={offer.order_bump_old_price}
                  order_bump_price={offer.order_bump_price}
                  order_bump_products={offer.order_bump_products}
                  setting_price_display_overwrite={offer.setting_price_display_overwrite}
                  getOffer={getOffer}
                />
              )}
            </div>
          </Tab>
          <Tab title="Actions" eventKey={'actions'}>
            <NoProductsMessage products={offer?.products || []} />
            <div className={classNames(styles.title, 'mb-0')}>Actions after the order is placed</div>
            {offer && (
              <Actions
                id={offer.id}
                action_add_to_list={offer.action_add_to_list}
                action_email_after_purchase={offer.action_email_after_purchase}
                action_enroll_in_courses={offer.action_enroll_in_courses}
                action_redirect_after_checkout={offer.action_redirect_after_checkout}
                action_send_receipt_after_purchase={offer.action_send_receipt_after_purchase}
                action_email_after_purchase_sender_id={offer.action_email_after_purchase_sender_id}
                getOffer={getOffer}
              />
            )}
          </Tab>
          <Tab title={'Shipping'} eventKey={'shipping'}>
            <NoProductsMessage products={offer?.products || []} />
            <div className={styles.title}>Allow shipping information</div>
            {offer && (
              <Shipping getOffer={getOffer} id={offer.id} setting_enable_shipping={offer.setting_enable_shipping} />
            )}
          </Tab>
          <Tab title={'Publish'} eventKey={'publish'}>
            <NoProductsMessage products={offer?.products || []} />
            <NoPaymentOffer
              text={
                <>
                  Your paid offer can`t be published until the payout method is set up. View{' '}
                  <Link to={SettingsPaths.payment}>Payment integrations</Link>
                </>
              }
              showPaymentError={
                offer?.price?.type === 'one_time' && !offer?.price?.payment_method && stripe.state !== 'done' && !paynet
              }
            />
            <NoPaymentOffer
              showPaymentError={
                offer?.price?.type === 'one_time' &&
                !offer?.price?.payment_method &&
                !!(stripe.state === 'done' || paynet)
              }
              text={'You have no selected payout in the “Price” tab. Select one of integrated services before publish.'}
            />

            {offer && (
              <Publish
                getOffer={getOffer}
                offer={offer}
                settings_background_color={offer.settings_background_color}
                settings_background_image={offer.settings_background_image}
                settings_hide_branding={offer.settings_hide_branding}
              />
            )}
          </Tab>
        </Tabs>
        {offer && (
          <div className={styles.status}>
            <ItemStatus label={offer.state} />
          </div>
        )}
      </div>
    </CreateOfferContext.Provider>
  );
};
