import styles from './attach-answer.module.scss';

import { IQuizQuestion } from '../../../../../../../interfaces/quiz';

type Props = {
  questionData: IQuizQuestion;
};

export const AttachAnswer = ({ questionData }: Props) => {
  return <></>;
};
