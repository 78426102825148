import { IFunnel } from '../interfaces';
import { baseFetch } from './BaseFetch';

export const getFunnels = async (token?: string) => {
  return await baseFetch<{}, IFunnel[]>('/funnels', {}, 'GET', true, {
    Authorization: `Bearer ${token}`,
    'Cache-Control': 'no-cache, no-store, must-revalidate'
  });
};

export const getFunnel = async (id: string) => {
  return await baseFetch<{}, IFunnel>(`/funnels/${id}`, {}, 'GET', true);
};

export const updateFunnelState = async ({ id, state }: { id: string; state: string | null }) => {
  return baseFetch<{}, IFunnel>(`/funnels/${id}/${state?.toLowerCase()}`, {}, 'PUT', true);
};

export const createFunnel = async () => {
  return await baseFetch<{}, IFunnel>('/funnels', {}, 'POST', true);
};

export const deleteFunnel = async (funnelKey: string) => {
  return await baseFetch<{}, null>(`/funnels/${funnelKey}`, {}, 'DELETE', true);
};

export const updateFunnel = async (funnelKey: string, data: { name: string }) => {
  return await baseFetch<{}, IFunnel>(`/funnels/${funnelKey}`, { ...data }, 'PUT', true);
};
