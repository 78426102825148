import { toast, TypeOptions } from 'react-toastify';
import { ToastOptions } from 'react-toastify/dist/types';

import { ToastMessage } from './ToastMessage';

export const Toast = (type: TypeOptions, message: string, title?: string, options?: ToastOptions) => {
  return toast(<ToastMessage message={message} title={title} />, {
    type,
    ...options
  });
};
