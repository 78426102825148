import CopyToClipboard from 'react-copy-to-clipboard';

import classNames from 'classnames';
import styles from './domain-cname-item.module.scss';
import { Icon16CheckMark, Icon16Copy } from '../../../../../../../../components/UI/icons';

interface IProps {
  label: string;
  value: string;
  textCopied: string;
  onCopy: (text: string) => void;
  isCopy: boolean;
  className?: string;
}

export const DomainCnameItem = ({ label, value, isCopy, className, textCopied, onCopy }: IProps) => {
  return (
    <CopyToClipboard text={value} onCopy={onCopy}>
      <div className={className}>
        <span className={styles.container}>
          <label htmlFor={value} className={styles.label}>
            {label}
          </label>
          {isCopy && textCopied !== value && <Icon16Copy className={classNames(styles.icon)} />}
          {isCopy && textCopied === value && <Icon16CheckMark className={styles.checkmark} />}
        </span>
        <h4 className={styles.value}>{value}</h4>
      </div>
    </CopyToClipboard>
  );
};
