import { useState } from 'react';
import { Collapse } from 'react-bootstrap';

import styles from './address.module.scss';

import { ModifyField } from '../../../../modules/crm/field/store/FieldSelector';
import { IconAngleDown, IconAngleUp } from '../../icons';
import { AddressComponent } from './AddressComponent';

const Address = {
  address_2: 'Address line 2',
  city: 'City',
  state: 'State',
  country: 'Country',
  zip: 'ZIP'
};

interface IProps {
  field: ModifyField;
}

export const AddressField = ({ field }: IProps) => {
  const [open, setOpen] = useState(true);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleToggle = () => {
    setOpen((x) => !x);
  };
  return (
    <>
      <div className={styles.container}>
        <div className={styles.collapseText} onClick={handleOpen} aria-controls="collapse-text" aria-expanded={open}>
          <AddressComponent placeholder={'Address line 1'} name={'address_1'} field_id={field.id} />
        </div>
        <div onClick={handleToggle} className={styles.icon}>
          {open ? <IconAngleUp /> : <IconAngleDown />}
        </div>
      </div>
      <Collapse in={open}>
        <div id="collapse-text" className={styles.addressFields}>
          {Object.entries(Address).map((x, index) => (
            <AddressComponent key={index} placeholder={x[1]} name={x[0]} field_id={field.id} className={'pl-0'} />
          ))}
        </div>
      </Collapse>
    </>
  );
};
