import { memo, ReactNode, useMemo, useState } from 'react';
import Select from 'react-select';
import { debounce, map } from 'lodash';

import classNames from 'classnames';

import { IOption } from '../../interfaces';
import { IField } from '../../modules/crm/field';
import { filterProductsOption } from '../../modules/crm/offers/create-offer/common/helper';
import { IOptionProduct } from '../../modules/crm/offers/create-offer/common/search/ProductSearch';
import { IProduct } from '../../modules/crm/products/store/ProductsState';
import { LoadState } from '../../store';
import { formatLabel, LoadingMessage, NoOptionsMessage, SearchContainer } from './SearchContainer';

export const customOfferSelectStyles = {
  control: () => ({
    width: '100%',
    display: 'flex',
    paddingLeft: '1rem',
    paddingRight: '0.5rem'
  }),
  option: (provided: any) => ({
    ...provided,
    '&:hover': {
      color: '#0072FF'
    }
  })
};

export interface IProps {
  size?: 'sm' | 'lg';
  loadState: LoadState;
  children: ReactNode;
  elementsFiltered: IProduct[];
  fieldsElement: IField[];
  selectProducts: IProduct[];
  onSelectProduct: (e: IOptionProduct) => void;
  getElements: (searchValue?: string) => void;
}

export const SearchElement = memo(
  ({
    size,
    loadState,
    elementsFiltered,
    fieldsElement,
    selectProducts,
    onSelectProduct,
    getElements,
    children
  }: IProps) => {
    const getDebounceProducts = useMemo(() => debounce((value?: string) => getElements(value), 500), [getElements]);
    const [input, setInput] = useState('');
    const [inputSave, setSave] = useState('');
    const onChange = (e: string) => {
      setInput(e);
      getDebounceProducts(e);
    };

    const onFocusHandler = () => {
      getElements('');
      setInput(inputSave);
      setSave('');
    };

    const cancelDebounce = () => {
      setSave(input);
      getDebounceProducts.cancel();
    };
    const filterElements = () => {
      const selectProductsIds = map(selectProducts, 'id');
      return filterProductsOption(elementsFiltered, fieldsElement)?.filter(
        (item) => !selectProductsIds.includes(item.product.id)
      );
    };

    const onSelect = (e: IOptionProduct) => {
      onSelectProduct(e);
      setInput('');
    };

    return (
      <>
        <div className={'d-flex custom'}>
          <Select
            value={null}
            inputValue={input}
            blurInputOnSelect
            isClearable={false}
            onMenuClose={cancelDebounce}
            onFocus={onFocusHandler}
            onChange={onSelect as (e: IOption | null) => void}
            options={filterElements()}
            formatOptionLabel={formatLabel}
            onInputChange={onChange}
            placeholder={inputSave || 'Search product or course by name'}
            classNamePrefix={'select'}
            className={classNames(
              'select-container form-control p-0 d-flex offer mr-2',
              size === 'sm' && 'form-control-sm'
            )}
            styles={customOfferSelectStyles}
            isLoading={loadState === LoadState.firstLoad}
            components={{
              IndicatorSeparator: () => null,
              Control: SearchContainer,
              NoOptionsMessage,
              LoadingMessage
            }}
          />
          {children}
        </div>
      </>
    );
  }
);
