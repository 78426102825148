import { memo, ReactNode } from 'react';

import styles from './toggle.module.scss';

import { Toggle } from './Toggle';

interface IProps<T> {
  isActive: boolean;
  name: T;
  title: string;
  text?: string;
  children?: ReactNode;
  disable?: boolean;
}

const CommonToggle = <T extends string>({ isActive, name, title, text, children, disable }: IProps<T>) => {
  return (
    <>
      <Toggle checked={isActive} controlName={name} title={title} className={styles.wrapper} disable={disable}>
        {isActive && children}
      </Toggle>
      {!isActive && text && <div className={styles.text}>{text}</div>}
    </>
  );
};

export default memo(CommonToggle) as typeof CommonToggle;
