import React, { memo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';

import styles from '../../../common/builder/builder.module.scss';
import multipinStyle from './multipin.module.scss';
import { Icon16Trash } from '../../../../components/UI/icons';

import { Button } from '../../../../components/UI';
import { _id } from '../../../../helpers';
import { useNodePayload } from '../../../../hooks/use-node-payload';
import { PaletteElementTypes } from '../../../../interfaces';
import { LoadState } from '../../../../store';
import { useBuilderContext } from '../../../common/builder/BuilderContext';
import { builderActionAsync } from '../../../common/builder/store/BuilderActionAsync';
import { funnelPaths } from '../../routes/FunnelRoutes';

interface IProps {
  nodeId: string;
  onClose(): void;
}

export const MultiPinNode = memo(({ nodeId }: IProps) => {
  const node = useNodePayload<PaletteElementTypes>(nodeId);
  const loadState = useSelector((state) => state.builder.loadState);
  const dispatch = useDispatch();
  const path = useRouteMatch<{ id: string }>(funnelPaths.funnelId());
  const { updateNode } = useBuilderContext();

  useEffect(() => {
    if (node && updateNode && loadState === LoadState.allIsLoaded) {
      document.querySelectorAll('.group-endpoint').forEach((item) => {
        item.remove();
      });
      document.querySelectorAll('.endpoint').forEach((item) => {
        item.remove();
      });

      updateNode(node);
    }
  }, [updateNode, node, loadState]);

  const onCreateEndpointHandler = () => {
    if (path?.params.id) {
      dispatch(builderActionAsync.createEndpoints(path.params.id, nodeId));
    }
  };

  const onBlurHandler = (e: React.FocusEvent<HTMLInputElement>) => {
    const endpointId = e.currentTarget.dataset['id'];
    const endpointTitle = e.currentTarget.dataset['element'];
    const value = e.currentTarget.value;
    if (path && endpointId && endpointTitle !== value) {
      dispatch(builderActionAsync.editEndpoint(path.params.id, nodeId, endpointId, value));
    }
  };

  const handleKeyDown = async (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.currentTarget.blur();
    }
  };

  const onDeleteEndpointHandler = (e: React.SyntheticEvent<HTMLSpanElement>) => {
    const endpointId = e.currentTarget.dataset['id'];
    if (path && endpointId) {
      dispatch(builderActionAsync.deleteEndpoint(path.params.id, nodeId, endpointId));
    }
  };

  return (
    <div className={styles.group}>
      {/*<button type={'button'} className={classNames('btn', 'btn-icon', styles.backButton)} onClick={onClose}>
        <Icon32ArrowLeft />
      </button>*/}
      <div className={styles.groupTitle}>
        {node?.payload.title} <span>Outputs</span>
      </div>
      {/*<div className={styles.groupBlock}>*/}
      {node?.endpoints?.map((endpoint) => {
        return (
          !endpoint.is_input && (
            <div className={styles.outputNode} id={_id(endpoint.id)} key={endpoint.id}>
              <input
                defaultValue={endpoint.title || 'Output'}
                placeholder={endpoint.title || 'Output'}
                onBlur={onBlurHandler}
                onKeyDown={handleKeyDown}
                data-id={endpoint.id}
                data-element={endpoint.title}
                className={styles.inputElement}
              />
              <span className={multipinStyle.trash} data-id={endpoint.id} onClick={onDeleteEndpointHandler}>
                <Icon16Trash />
              </span>
            </div>
          )
        );
      })}
      <Button onClick={onCreateEndpointHandler} btnStyle={'outline'} type={'create'} className={multipinStyle.btn}>
        Create output
      </Button>
      {/*</div>*/}
    </div>
  );
});
