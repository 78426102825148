import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useTus } from 'use-tus';

import { MediaItem, MediaProvider } from '../API/video-api';
import { fileUploaderActions } from '../layout/shared-components/upload-worker/store/FileUploaderActions';
import { IFileUploader } from '../layout/shared-components/upload-worker/store/FileUploaderState';

export const useVimeoUpload = (
  video: IFileUploader,

  autoStart = true,
  autoAbort = true
) => {
  const dispatch = useDispatch();
  const { upload, setUpload, isAborted, isSuccess, error, remove } = useTus({
    autoStart,
    autoAbort,
    uploadOptions: {
      removeFingerprintOnSuccess: true
    }
  });

  const onProgress = useCallback(
    (bytesUploaded: number, bytesTotal: number) => {
      dispatch(fileUploaderActions.updateUploadFile({ ...video, progress: (bytesUploaded * 100) / bytesTotal }));
    },
    [dispatch, video]
  );
  const onCancel = useCallback(
    (data: MediaItem) => {
      dispatch(fileUploaderActions.updateUploadFile({ ...video, progress: 0, uploaderData: data }));
    },
    [dispatch, video]
  );
  const onSuccess = useCallback(
    (data: MediaItem) => {
      dispatch(fileUploaderActions.updateUploadFile({ ...video, progress: 100, uploaderData: data }));
    },
    [dispatch, video]
  );
  const onError = useCallback(
    (data: MediaItem) => {
      dispatch(fileUploaderActions.updateUploadFile({ ...video, progress: 0, uploaderData: data }));
    },
    [dispatch, video]
  );

  const handleAbort = useCallback(() => {
    if (!upload) {
      return;
    }
    upload.abort(true);
    onCancel({ ...video.uploaderData, status: 'canceled' });
    remove();
  }, [upload, onCancel, video.uploaderData, remove]);

  const handleStart = useCallback(() => {
    if (!upload) {
      return;
    }
    upload.start();
  }, [upload]);

  const handleSetUpload = useCallback(
    (uploadURI: string, vimeoId: string) => {
      if (video.file) {
        setUpload(video.file, {
          endpoint: uploadURI,
          uploadUrl: uploadURI,
          metadata: {
            filename: video.file.name,
            filetype: video.file.type
          },
          onError: () => {
            remove();
            onError({ provider: MediaProvider.VIMEO, id: vimeoId, status: 'error' });
            return;
          },
          onProgress,
          onSuccess: () => {
            onSuccess({
              provider: MediaProvider.VIMEO,
              id: vimeoId,
              status: 'complete'
            });
            remove();
            return;
          }
        });
        handleStart();
      }
    },
    [handleStart, onError, onProgress, onSuccess, remove, setUpload, video.file]
  );

  return { handleAbort, handleSetUpload, remove, isAborted, isSuccess, error };
};
