import { forwardRef, memo, ReactNode } from 'react';

import styles from './sidebar-school.module.scss';

interface IProps {
  width?: number;
  resizable?: boolean;
  children?: ReactNode;
}

export const CourseSidebar = memo(
  forwardRef<HTMLDivElement, IProps>(({ width, resizable = false, children }, targetRef) => {
    return (
      <>
        <div className={styles.container}>{children}</div>
        {resizable && width && <div className={styles.resize} ref={targetRef} style={{ left: `${width - 3}px` }} />}
      </>
    );
  })
);
