import { createContext, useContext } from 'react';

import { IPolicy, PolicyContentType } from '../../../../interfaces/policy';

type PolicyContent = {
  policy: IPolicy | null;
  onSelect(key: PolicyContentType): void;
};

export const PolicyContext = createContext<PolicyContent>({
  policy: null,
  onSelect() {}
});

export const usePolicyContext = () => useContext(PolicyContext);
