import { CustomDispatch } from 'redux';

import { actionEmail, EmailAction } from '../../../../API/action-email-api';
import {
  editMemberOrganization,
  getOrganizationMembers,
  inviteOrganizationMember,
  removeMemberFromOrganization,
  resendInvitationOrganizationMember
} from '../../../../API/organization-api';
import { IEditMember, IInviteMember } from '../../../../interfaces';
import { OrganizationActions } from './OrganizationActions';

export const OrganizationActionAsync = {
  getOrganizationMembers: () => async (dispatch: CustomDispatch) => {
    try {
      dispatch(OrganizationActions.getOrganizationMembers.started());
      const response = await getOrganizationMembers();
      if (response.data) {
        dispatch(OrganizationActions.getOrganizationMembers.done({ result: response.data }));
      }
    } catch (error) {
      dispatch(OrganizationActions.getOrganizationMembers.failed({ error }));
    }
  },
  inviteOrganizationMembers: (params: IInviteMember, callback: () => void) => async (dispatch: CustomDispatch) => {
    try {
      dispatch(OrganizationActions.inviteOrganizationMembers.started(params));
      await inviteOrganizationMember(params);
      dispatch(OrganizationActions.inviteOrganizationMembers.done({ params }));
      callback();
    } catch (error) {
      dispatch(OrganizationActions.inviteOrganizationMembers.failed({ params, error }));
    }
  },
  resendInvitationOrganizationMember: (memberId: string, callback?: () => void) => async (dispatch: CustomDispatch) => {
    try {
      dispatch(OrganizationActions.resendInvitationOrganizationMember.started({ memberId }));
      await resendInvitationOrganizationMember(memberId);
      dispatch(OrganizationActions.resendInvitationOrganizationMember.done({ params: { memberId } }));
      callback?.();
    } catch (error) {
      dispatch(OrganizationActions.resendInvitationOrganizationMember.failed({ params: { memberId }, error }));
    }
  },
  removeMemberFromOrganization: (memberId: string, callback: () => void) => async (dispatch: CustomDispatch) => {
    try {
      dispatch(OrganizationActions.removeMemberFromOrganization.started({ memberId }));
      await removeMemberFromOrganization(memberId);
      dispatch(OrganizationActions.removeMemberFromOrganization.done({ params: { memberId } }));
      callback();
    } catch (error) {
      dispatch(OrganizationActions.removeMemberFromOrganization.failed({ params: { memberId }, error }));
    }
  },
  editMemberOrganization:
    ({ memberId, params }: { memberId: string; params: IEditMember }, callback: () => void) =>
    async (dispatch: CustomDispatch) => {
      try {
        dispatch(OrganizationActions.editMemberOrganization.started({ memberId, params }));
        const response = await editMemberOrganization(memberId, params);
        if (response.data) {
          dispatch(
            OrganizationActions.editMemberOrganization.done({ params: { memberId, params }, result: response.data })
          );
          callback();
        }
      } catch (error) {
        dispatch(OrganizationActions.editMemberOrganization.failed({ params: { memberId, params }, error }));
      }
    },
  invitationEmail:
    (actionHash: string, returnData: (data: EmailAction) => void) => async (dispatch: CustomDispatch) => {
      try {
        dispatch(OrganizationActions.invitationEmail.started({ actionHash }));
        const response = await actionEmail(actionHash);
        if (response.data) {
          dispatch(OrganizationActions.invitationEmail.done({ params: { actionHash } }));
          returnData(response.data);
        }
      } catch (error) {
        returnData(error.data);
        dispatch(OrganizationActions.invitationEmail.failed({ error, params: { actionHash } }));
      }
    }
};
