import { useCallback, useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { map } from 'lodash';

import { useNodePayload } from '../../../../../../hooks/use-node-payload';
import { PaletteAutomationElementTypes } from '../../../../../../interfaces';
import { courseActionAsync } from '../../../../../lms/courses/store/CourseActionAsync';
import { CoursesSelector } from '../../../../../lms/courses/store/CourseSelector';
import { ICourse } from '../../../../../lms/courses/store/CourseState';
import { AccountSelector } from '../../../../../settings/account/store/AccountSelector';
import { EnrollInCourse } from './EnrollInCourse';
import { PayloadCourseEnroll } from './PayloadCourseEnroll';

interface IProps {
  nodeId: string;
}

export const PayloadActionCourseEnroll = ({ nodeId }: IProps) => {
  const dispatch = useDispatch();
  const [selectedCourses, setSelectedCourses] = useState<ICourse[]>([]);
  const { setValue } = useFormContext();
  const node = useNodePayload<PaletteAutomationElementTypes.ACTION_COURSE_ENROLL>(nodeId);
  const accountMemo = useMemo(AccountSelector, []);
  const { organizationId } = useSelector(accountMemo);
  const authToken = useSelector((state) => state.auth.authToken);

  const courseMemo = useMemo(CoursesSelector, []);
  const { courses } = useSelector((state) => courseMemo(state, 'published'));

  const setSelectedData = useCallback(
    (data: ICourse[]) => {
      if (node && Array.isArray(node?.payload.course_ids)) {
        const selected = node?.payload.course_ids
          .map((x) => data.find((y) => y.id === x))
          .filter((item): item is ICourse => !!item);
        setSelectedCourses(selected);
      }
    },
    [node]
  );

  const onSetCourses = useCallback(
    (data: ICourse[]) => {
      const courses = map(data, 'id');
      setValue('course_ids', courses);
      setSelectedCourses(data);
    },
    [setValue]
  );

  const getCourses = useCallback(() => {
    if (authToken && organizationId) {
      dispatch(courseActionAsync.getCourses(setSelectedData));
    }
  }, [authToken, dispatch, organizationId, setSelectedData]);

  useEffect(() => {
    if (courses.length === 0) {
      getCourses();
    } else if (Array.isArray(node?.payload.course_ids)) {
      const data = courses.filter((x) => node?.payload.course_ids.includes(x.id));
      setSelectedCourses(data);
    }
  }, [courses, node?.payload.course_ids, getCourses, setSelectedCourses]);

  return (
    <PayloadCourseEnroll courses={courses} onSetCourses={onSetCourses} selectedCourses={selectedCourses}>
      <EnrollInCourse isEnrolled={!!node?.payload.is_enrolled} textEnrolled={'Enroll'} textUnEnrolled={'Unenroll'} />
    </PayloadCourseEnroll>
  );
};
