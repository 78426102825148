import styles from './contact-card-lists.module.scss';

interface IProps {
  onCreateList?(): void;
}

export const ListEmptyCreation = ({ onCreateList = () => {} }: IProps) => {
  return (
    <div className={'note'}>
      One contact can be linked to several lists, for this you need to{' '}
      <span className={styles.textLinkInfo} onClick={onCreateList}>
        create a custom one
      </span>{' '}
      and it will appear in this section.
    </div>
  );
};
