import React, { memo, useRef, useState } from 'react';
import { DropTargetMonitor, useDrop } from 'react-dnd';
import { NativeTypes } from 'react-dnd-html5-backend';
import { Button } from 'src/app/components/UI/button/Button';
import { TypeOfUploader } from 'src/app/interfaces/fileUpload';

import classNames from 'classnames';
import commonStyles from '../common/upload-common.module.scss';
import styles from './file-select.module.scss';
import { Icon32Audio, Icon32Cross, Icon32Video } from 'src/app/components/UI/icons';

export type FileSelectFormatUpload = 'video/*' | 'audio/*';

interface IProps {
  accept: FileSelectFormatUpload;
  title: string;
  description: string;
  titleOnPreview: string;
  descriptionOnPreview: string;
  typeOfUploader?: TypeOfUploader | null;
  setTypeOfUploader: (type: TypeOfUploader | null) => void;
  setFileSelected: (x: File | null) => void;
  closeModal: () => void;
  onCancel?: () => void;
  handleFile: (files: FileList | null) => void;
}

export const FileSelect = memo(
  ({
    accept,
    title,
    description,
    titleOnPreview,
    descriptionOnPreview,
    typeOfUploader = null,
    setFileSelected,
    closeModal,
    setTypeOfUploader,
    onCancel,
    handleFile
  }: IProps) => {
    const [hover, setHover] = useState(false);
    const buttonRef = useRef<HTMLButtonElement | null>(null);
    const inputRef = useRef<HTMLInputElement | null>(null);

    const onMouseEnterHandler = () => {
      setHover(true);
    };
    const onMouseLeaveHandler = () => {
      setHover(false);
    };

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const files = e.target.files;
      handleFile(files);
    };

    const [{ canDrop, isOver }, drop] = useDrop(
      () => ({
        accept: [NativeTypes.FILE],
        collect: (monitor: DropTargetMonitor) => ({
          isOver: monitor.isOver(),
          canDrop: monitor.canDrop()
        }),
        drop: (item: any, monitor: DropTargetMonitor) => {
          if (monitor.isOver({ shallow: true })) {
            handleFile(item.files);
          }
        }
      }),
      []
    );

    const [{ isOverCancel }, dropCancel] = useDrop(
      () => ({
        accept: [NativeTypes.FILE],
        collect: (monitor: DropTargetMonitor) => ({
          isOverCancel: monitor.isOver(),
          canDropCancel: monitor.canDrop()
        }),
        drop: () => {
          setFileSelected(null);
          onCancel?.();
        }
      }),
      []
    );

    const onClickBrowse = () => {
      if (inputRef.current) inputRef.current.click();
      if (!typeOfUploader) setTypeOfUploader(null);
    };

    const onClickCard = (event: React.SyntheticEvent<HTMLDivElement>) => {
      const button = buttonRef.current;
      if (!!button && button === event.target) return;
      if (!typeOfUploader) setTypeOfUploader(TypeOfUploader.upload);
    };

    const isActive = canDrop && isOver;

    return (
      <>
        <div
          onMouseEnter={onMouseEnterHandler}
          onMouseLeave={onMouseLeaveHandler}
          onClick={onClickCard}
          className={classNames(
            [commonStyles.inputDnD, { [commonStyles.active]: isActive }],
            { [commonStyles.droppedFile]: isActive },
            { [commonStyles.inputDnDHover]: hover },
            { [commonStyles.fullWidth]: !!typeOfUploader }
          )}
          ref={drop}
        >
          {isActive && (
            <div className={classNames(styles.blurContainerUploader)}>
              <div className={styles.bulkFooter}>
                <div className={styles.dropContainer}>
                  <div className={styles.dropText}>{title}</div>
                  <div className={styles.dropTextDescribe}>{description}</div>
                </div>
              </div>
              <div className={styles.wrapper}>
                <div className={styles.cancelWrapper} ref={dropCancel}>
                  <div className={classNames(styles.cancelContainer, { [styles.isOverCancel]: isOverCancel })} />
                  <div className={styles.cancel}>
                    <Icon32Cross />
                  </div>
                </div>
                <div className={styles.cancelText}>Cancel</div>
              </div>
            </div>
          )}
          <input
            accept={accept}
            ref={inputRef}
            hidden
            type="file"
            multiple={false}
            className={commonStyles.inputFile}
            id="inputFile"
            onChange={handleFileChange}
            data-title="Drag and drop a file"
          />
          {!typeOfUploader && (
            <div className={classNames([commonStyles.content, { [commonStyles.contentHover]: hover }])}>
              <div className={commonStyles.iconWrapper}>
                {accept === 'audio/*' && <Icon32Audio className={commonStyles.icon} />}
                {accept === 'video/*' && <Icon32Video className={commonStyles.icon} />}
              </div>
              <p className={commonStyles.title}>{titleOnPreview}</p>
              <p className={commonStyles.note}>{descriptionOnPreview}</p>
            </div>
          )}
          <Button
            ref={buttonRef}
            size={'lg'}
            withIcon={false}
            className={classNames([commonStyles.browseButton, { [commonStyles.browseButtonActive]: !!typeOfUploader }])}
            onClick={onClickBrowse}
          >
            Browse
          </Button>
          {!!typeOfUploader && (
            <Button size={'lg'} withIcon={false} btnStyle={'transparent'} onClick={closeModal}>
              Cancel
            </Button>
          )}
        </div>
      </>
    );
  }
);
