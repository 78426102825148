import { memo, useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import { map } from 'lodash';

import stylesOffer from '../../common/commonOffer.module.scss';
import { IconWarning } from '../../../../../../components/UI/icons';

import { ReorderSelectedProducts } from '../../../../../../components/search-products-field/ReorderSelectedProducts';
import { InfoMessage } from '../../../../../../components/UI/infoMessage/InfoMessage';
import { IOption } from '../../../../../../interfaces';
import { ICourse } from '../../../../../lms/courses/store/CourseState';
import { SearchCourses } from '../../../common/search-courses/SearchCourses';
import { InputLabel } from '../../common/input-label/InputLabel';
import { ElementItem } from '../../common/search/element-item/ElementItem';
import { Toggle } from '../../common/toggle/Toggle';

export type IOptionCourse = IOption & { course: ICourse };

interface IProps {
  courses: ICourse[];
  selectedCourses: ICourse[];
  isShow: boolean;
  setSelectedCourses: (data: ICourse[]) => void;
  enrols: string[] | null;
}

export const ActionEnrollCourse = memo(({ courses, selectedCourses, isShow, enrols, setSelectedCourses }: IProps) => {
  const {
    setValue,

    formState: { errors }
  } = useFormContext();

  const onSetCourses = useCallback(
    (data: ICourse[]) => {
      const courses = map(data, 'id');
      setValue('action_enroll_in_courses', courses, { shouldDirty: true, shouldValidate: true });
      setSelectedCourses(data);
    },
    [setSelectedCourses, setValue]
  );

  const removeCourse = useCallback(
    (id: string) => {
      const newProducts = selectedCourses.filter((x) => x.id !== id);
      onSetCourses(newProducts);
    },
    [onSetCourses, selectedCourses]
  );

  const onChangeEnrol = (show: boolean) => {
    if (!show) {
      setValue('action_enroll_in_courses', null, { shouldDirty: true });
    } else {
      setValue('action_enroll_in_courses', enrols || [], { shouldDirty: true });
      if (!enrols) {
        setSelectedCourses([]);
      }
    }
  };

  return (
    <>
      <Toggle
        checked={!!isShow}
        title={'Enroll in the course'}
        controlName={'action_enroll_in_courses'}
        className={'mt-3'}
        onChangeValue={onChangeEnrol}
      >
        <InputLabel label={'Add course for enrollment'} classContainer={'mt-2'}>
          <SearchCourses
            placeholder={'Search course by name'}
            courses={courses}
            selectedCourses={selectedCourses}
            onSetCourses={onSetCourses}
          />
          <InfoMessage
            show={!!errors?.action_enroll_in_courses && selectedCourses.length === 0}
            text={(errors?.action_enroll_in_courses as any)?.message}
            className={stylesOffer.errorContainer}
            customIcon={<IconWarning className={stylesOffer.warningIcon} />}
          />

          <ReorderSelectedProducts
            elements={selectedCourses}
            onSetElements={onSetCourses}
            name={'action_enroll_in_courses'}
            isDragging={false}
          >
            {(element) => (
              <ElementItem
                entity={'course'}
                id={element.id}
                removeElement={removeCourse}
                title={element.title}
                image={element.settings.thumbnail}
              />
            )}
          </ReorderSelectedProducts>
        </InputLabel>
      </Toggle>
      {!isShow && <div className={stylesOffer.text}>Allows you to select the courses to enroll the students.</div>}
    </>
  );
});
