import { FC, memo, useMemo } from 'react';
import { DropTargetMonitor, useDrop } from 'react-dnd';
import { NativeTypes } from 'react-dnd-html5-backend';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { BlockType } from 'src/app/interfaces/courseBlock';
import { TypeOfUploader } from 'src/app/interfaces/fileUpload';
import { find, flatMapDeep, map, sortBy } from 'lodash';

import classNames from 'classnames';
import styles from './bulk-upload-wrapper.module.scss';
import { Icon32Cross } from '../../../../../../../components/UI/icons';

import { getVideoOrganizationUri, MediaProvider } from '../../../../../../../API/video-api';
import { flatten } from '../../../../../../../components/course/course/CourseHelpers';
import { useFileUpload } from '../../../../../../../components/file-upload/audio-uploader/use-file-upload';
import { Toast } from '../../../../../../../components/UI';
import { fileUploaderActions } from '../../../../../../../layout/shared-components/upload-worker/store/FileUploaderActions';
import { useLessonNode } from '../../../../../hooks/use-lesson-node';
import { coursePaths } from '../../../../routes/CourseRoutes';
import { courseActionAsync } from '../../../../store/CourseActionAsync';
import { CourseSelector } from '../../../../store/CourseSelector';
import { ICourseNode } from '../../../../store/CourseState';
import { useCourseContext } from '../../CourseContext';
import { BulkVideoUpload } from '../bulk-video-upload/BulkVideoUpload';

export const BulkUploadWrapper: FC = memo(({ children }) => {
  const organization = useSelector((state) => state.auth.currentOrganization);
  const dispatch = useDispatch();
  const { currentNav } = useCourseContext();

  const path = useRouteMatch<{ id: string; nodeId?: string }>([
    coursePaths.courseLessons(':id', ':nodeId'),
    coursePaths.courseLessons(':id')
  ]);

  const courseMemo = useMemo(() => CourseSelector(path?.params.id), [path?.params.id]);
  const { course } = useSelector(courseMemo);
  const { nodeFolder } = useLessonNode(course, path?.params.nodeId);
  const { getRetrieveUrl } = useFileUpload(path?.params.id, path?.params.nodeId);

  const createAudioLesson = async (file: File) => {
    if (path) {
      const respLes = await dispatch(
        courseActionAsync.createNode(
          path.params.id,
          'lesson',
          currentNav?.id || nodeFolder?.id || null,
          file.name.split('.').slice(0, -1).join('.'),
          undefined,
          undefined
        )
      );

      const hierarchy = await dispatch(courseActionAsync.getCourseHierarchy(path.params.id));
      if (hierarchy) {
        const lessonItem = find<ICourseNode | undefined>(flatMapDeep(hierarchy, flatten), ['id', respLes?.id]);
        if (lessonItem?.children) {
          getRetrieveUrl(
            file,
            (data) => {
              dispatch(
                courseActionAsync.cudCourseBlock(path?.params.id, lessonItem.children[0].id, {
                  add: [
                    {
                      type: BlockType.AUDIO,
                      title: '',
                      audio: {
                        type: TypeOfUploader.upload,
                        audio_url: data.presigned_url
                      },
                      order: 0
                    }
                  ],
                  delete: [],
                  edit: []
                })
              );

              dispatch(
                fileUploaderActions.setUploadFiles([
                  {
                    isBulk: true,
                    file: file,
                    progress: 0,
                    courseId: path.params.id,
                    parentBlockId: lessonItem.children[0].id,
                    uploaderData: {
                      id: data.presigned_url,
                      provider: MediaProvider.FILE,
                      status: 'in_progress'
                    }
                  }
                ])
              );
            },
            'audio'
          );
        }
      }
    }
  };

  /**
   * It creates a lesson, then creates a video block inside the lesson, then uploads the video to Vimeo
   * @param {File} file - File - the file that you want to upload
   */
  const createLesson = async (file: File) => {
    if (organization) {
      const vimeoData = await getVideoOrganizationUri(file, organization.organization_id);
      if (path && vimeoData) {
        const respLes = await dispatch(
          courseActionAsync.createNode(
            path.params.id,
            'lesson',
            currentNav?.id || nodeFolder?.id || null,
            file.name.split('.').slice(0, -1).join('.'),
            undefined,
            undefined
          )
        );

        const hierarchy = await dispatch(courseActionAsync.getCourseHierarchy(path.params.id));
        if (hierarchy) {
          const lessonItem = find<ICourseNode | undefined>(flatMapDeep(hierarchy, flatten), ['id', respLes?.id]);

          if (lessonItem?.children) {
            dispatch(
              courseActionAsync.cudCourseBlock(
                path?.params.id,
                lessonItem.children[0].id,
                {
                  add: [
                    {
                      type: BlockType.VIDEO,
                      title: '',
                      video: {
                        type: TypeOfUploader.upload,
                        video_url: vimeoData.link
                      },
                      order: 0
                    }
                  ],
                  delete: [],
                  edit: []
                },
                () => {
                  dispatch(
                    fileUploaderActions.setUploadFiles([
                      {
                        file: file,
                        progress: 0,
                        courseId: path.params.id,
                        parentBlockId: lessonItem.children[0].id,
                        vimeoData: vimeoData,
                        uploaderData: {
                          id: vimeoData.link,
                          provider: MediaProvider.VIMEO,
                          status: 'in_progress'
                        }
                      }
                    ])
                  );
                }
              )
            );
          }
        }
      }
    }
  };

  /**
   * It takes a list of files, checks if they are valid, and if they are, it uploads them to Vimeo
   * @param {FileList | null} files - FileList | null
   */
  const handleVideoChange = (
    validVideos: File[],
    invalidVideos: File[],
    validAudios: File[],
    invalidAudios: File[],
    otherInvalidFiles: File[]
  ) => {
    if (invalidVideos.length > 0 && validVideos.length > 0) {
      Toast(
        'warning',
        'Among the files you are trying to upload, there are inappropriate formats and they won’t be uploaded.',
        'Inappropriate file formats'
      );
    } else if (invalidVideos.some((video) => video.size / 1024 / 1024 / 1024 >= 2)) {
      Toast('warning', 'The maximum file size that you can upload should not exceed 2 GB', 'Too large file');
    }
    if (invalidAudios.length > 0 && validAudios.length > 0) {
      Toast(
        'warning',
        'Among the files you are trying to upload, there are inappropriate formats and they won’t be uploaded.',
        'Inappropriate file formats'
      );
    } else if (invalidAudios.some((audio) => audio.size / 1024 / 1024 > 20)) {
      Toast('warning', 'The maximum file size that you can upload should not exceed 20 MB', 'Too large file');
    }
    if (otherInvalidFiles.length > 0) {
      Toast('warning', 'The file you are trying to upload is not a video/audio format.', 'Wrong file format');
    }
    if (validAudios.length > 0 || validVideos.length > 0) {
      const allValidFiles = [...validAudios, ...validVideos];
      const sortValidation = sortBy(allValidFiles, 'name');
      map(sortValidation, (file) => (file.type.includes('video') ? createLesson(file) : createAudioLesson(file)));
      dispatch(fileUploaderActions.setShowUploader({ show: true }));
    }
  };

  const onDropChange = (files: FileList | null) => {
    if (files && course) {
      const validVideos: File[] = [];
      const invalidVideos: File[] = [];

      const validAudios: File[] = [];
      const invalidAudios: File[] = [];

      const otherInvalidFiles: File[] = [];

      map(files, (file) => {
        if (file.type.includes('video')) {
          if (file.size / 1024 / 1024 / 1024 >= 2) {
            invalidVideos.push(file);
          } else {
            validVideos.push(file);
          }
        } else if (file.type.includes('audio')) {
          if (file.size / 1024 / 1024 > 20) {
            invalidAudios.push(file);
          } else {
            validAudios.push(file);
          }
        } else {
          otherInvalidFiles.push(file);
        }
      });

      handleVideoChange(validVideos, invalidVideos, validAudios, invalidAudios, otherInvalidFiles);
    }
  };

  const [{ isOver, canDrop }, drop] = useDrop(
    () => ({
      accept: [NativeTypes.FILE],
      collect: (monitor: DropTargetMonitor) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop()
      }),
      drop: (item: any, monitor: DropTargetMonitor) => {
        if (item && item.files && item.files.length > 0 && monitor.isOver({ shallow: true })) {
          onDropChange(item.files);
        }
      }
    }),
    [currentNav]
  );

  const [{ isOverCancel }, dropCancel] = useDrop(
    () => ({
      accept: [NativeTypes.FILE],
      collect: (monitor: DropTargetMonitor) => ({
        isOverCancel: monitor.isOver(),
        canDropCancel: monitor.canDrop()
      })
    }),
    [currentNav]
  );

  return (
    <div className={styles.content}>
      {children}

      <div ref={drop} className={classNames({ [styles.dropped]: canDrop && isOver })}>
        {canDrop && isOver && (
          <div className={classNames(styles.blurContainer, styles.isOver)}>
            <div className={styles.bulkFooter}>
              <div className={styles.dropContainer}>
                <div className={styles.dropText}>Drop files</div>
                <div className={styles.dropTextDescribe}>
                  Maximum size of one file <br />
                  (video: 2 GB, audio: 20 Mb)
                </div>
              </div>
            </div>
            <div className={styles.wrapper}>
              <div className={styles.cancelWrapper} ref={dropCancel}>
                <div className={classNames(styles.cancelContainer, { [styles.isOverCancel]: isOverCancel })} />
                <div className={styles.cancel}>
                  <Icon32Cross />
                </div>
              </div>
              <div className={styles.cancelText}>Cancel</div>
            </div>
          </div>
        )}
        <div className={styles.panel}>
          <BulkVideoUpload
            handleVideoChange={onDropChange}
            className={classNames({ [styles.isOverPanel]: canDrop && isOver })}
          />
        </div>
      </div>
    </div>
  );
});
