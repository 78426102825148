import React, { memo, useCallback, useEffect, useState } from 'react';
import { ColumnDescription, ColumnFormatter, TableChangeState, TableChangeType } from 'react-bootstrap-table-next';
import { useDispatch, useSelector } from 'react-redux';

import modalStyles from '../../../../components/UI/global-modal/modal.module.scss';
import styles from './offer-list.module.scss';
import { IconMegaphoneOff } from '../../../../components/UI/icons';

import { ItemStatus } from '../../../../components/itemStatus/ItemStatus';
import { Button, Loader, ModalActionComponent, ModalDelete } from '../../../../components/UI';
import { RemotePagination } from '../../../../components/UI/pagination/RemotePagination';
import { LoadState } from '../../../../store';
import { EmptyOfferList } from '../common/empty-offer-list/EmptyOfferList';
import { OfferListHeader } from '../common/offer-list-header/offer-list-header';
import { RowActions } from '../common/row-actions/RowActions';
import { Congratulations } from '../create-offer/publish/congratulations/Congratulations';
import { OffersActionAsync } from '../store/OffersActionAsync';
import { IOffer, IOfferState, OfferState } from '../store/OffersState';
import { PublicOfferErrorModal } from './publick-offer-modal/PublicOfferErrorModal';
import { columnsConfig, RowType, selectRow } from './table-config';

interface IProps {
  showFilters?: boolean;
  pageSize: number;
  createOffer: () => void;
  rowClickHandler: (e: Event, column: ColumnDescription, columnIndex: number, row: RowType) => void;
  openOffer: () => void;
  getOffers: (page: number, pageSize: number, activeFilter?: IOfferState) => void;
}

export const OfferList = memo(
  ({ showFilters, pageSize, createOffer, openOffer, rowClickHandler, getOffers }: IProps) => {
    const dispatch = useDispatch();
    const [selectedOffer, setSelectedOffer] = useState<{ id: string; name: string }>();
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showCongrats, setShowCongrats] = useState<string | null>(null);
    const [unpublicOfferId, setUnpublicOfferId] = useState<string | null>(null);
    const { paymentAccount } = useSelector((state) => state.general);
    const [showPublicErrorOfferIdModal, setShowPublicErrorOfferIdModal] = useState<string | null>(null);
    const [activeFilter, setActiveFilter] = useState<IOfferState>();
    const { offers, page, page_size, loadState, total } = useSelector((state) => state.offers);
    const [offersTable, setOffersTable] = useState<{
      items: IOffer[];
      totalSize: number;
      page: number;
      sizePerPage: number;
    }>({ items: [], totalSize: total, page: 1, sizePerPage: pageSize });

    useEffect(() => {
      if (loadState === LoadState.allIsLoaded) {
        setOffersTable({
          items: offers,
          page,
          sizePerPage: page_size,
          totalSize: total
        });
      }
    }, [loadState, offers, page, page_size, total]);

    const columnFormatter = useCallback(
      (cell: string, row: RowType, index: number, formatExtraData: ColumnDescription<RowType>) => {
        if (cell === undefined) {
          return '-';
        }
        if (formatExtraData.dataField === 'title') {
          return (
            <div className={'d-flex align-items-center flex-grow-1'}>
              <div className={styles.title}>{cell}</div>
              <ItemStatus label={row.state} />
            </div>
          );
        }
        return typeof cell !== 'object' ? cell : '-';
      },
      []
    );

    const handleTableChange = (type: TableChangeType, { page, sizePerPage }: TableChangeState<unknown>) => {
      if (type === 'pagination') {
        getOffers(page, sizePerPage, activeFilter);
      }
    };

    const onClosePublicErrorModal = useCallback(() => {
      setShowPublicErrorOfferIdModal(null);
    }, [setShowPublicErrorOfferIdModal]);

    const rowActionsFormatter: ColumnFormatter<RowType> = (cell, row) => {
      return (
        <RowActions
          cell={row.price?.price ? '$' + row.price?.price?.toString() : 'Free'}
          row={row}
          restoreToDraft={restoreToDraft}
          openInNewWindow={openInNewWindow}
          duplicateOffer={createOffer}
          onDeleteOffer={onDeleteOffer}
          moveToArchive={moveToArchive}
          onPublicOffer={onPublicOffer}
          onUnpublicOffer={onUnpublicOffer}
        />
      );
    };

    const onPublicOffer = (e: React.SyntheticEvent) => {
      e.stopPropagation();
      const offer = offers.find((x) => x.id === e.currentTarget.id);
      const isNotPublish =
        !paymentAccount.paynet || paymentAccount.stripe.state !== 'done' || offer?.products.length === 0;
      if (isNotPublish && offer) {
        setShowPublicErrorOfferIdModal(e.currentTarget.id);
      } else if (!isNotPublish && offer) {
        dispatch(
          OffersActionAsync.editState(offer.id, 'published', false, () => {
            setShowCongrats(offer.id);
            getOffers(page, page_size, activeFilter);
          })
        );
      }
    };

    const onUnpublicOffer = (e: React.SyntheticEvent) => {
      e.stopPropagation();
      const offer = offers.find((x) => x.id === e.currentTarget.id);
      if (offer?.id) {
        setUnpublicOfferId(offer.id);
      }
    };

    const onConfirmUnpublicOffer = () => {
      if (unpublicOfferId) {
        dispatch(
          OffersActionAsync.editState(unpublicOfferId, 'draft', false, () => {
            setUnpublicOfferId(null);
            getOffers(page, page_size, activeFilter);
          })
        );
      }
    };

    const closeUnpublicModal = () => {
      setUnpublicOfferId(null);
    };

    const restoreToDraft = (e: React.SyntheticEvent) => {
      e.stopPropagation();
      const id = e.currentTarget.id;
      if (id) {
        editState(id, OfferState.draft);
      }
    };

    const moveToArchive = (e: React.SyntheticEvent) => {
      e.stopPropagation();
      const id = e.currentTarget.id;
      if (id) {
        editState(id, OfferState.archived);
      }
    };

    const openInNewWindow = (e: React.SyntheticEvent) => {
      e.stopPropagation();
      window.open(`${window.location.origin}/offer/${e.currentTarget.id}`);
    };

    const editState = useCallback(
      (id: string, state: IOfferState) => {
        dispatch(OffersActionAsync.editState(id, state, !!activeFilter, () => getOffers(page, pageSize, activeFilter)));
      },
      [activeFilter, dispatch, getOffers, page, pageSize]
    );

    const columnsData: ColumnDescription<RowType>[] = columnsConfig.map((column, index) => {
      const isLatestColumn = index === columnsConfig.length - 1;
      return {
        ...column,
        formatter: isLatestColumn ? rowActionsFormatter : columnFormatter,
        formatExtraData: column,
        events: {
          onClick: rowClickHandler
        }
      };
    });

    const onDeleteOffer = (e: React.SyntheticEvent) => {
      e.stopPropagation();
      const id = e.currentTarget.id;
      const currentRow = offersTable.items.find((row) => row.id === id);
      if (currentRow?.id) {
        setSelectedOffer({
          id,
          name: currentRow?.title
        });
        setShowDeleteModal(true);
      }
    };

    const onCloseCongratsOfferIdModal = useCallback(() => {
      setShowCongrats(null);
    }, []);

    const onCloseDeleteModal = useCallback(() => {
      setShowDeleteModal(false);
    }, []);

    const deleteOffer = useCallback(() => {
      if (selectedOffer) {
        editState(selectedOffer.id, OfferState.deleted);
      }
      onCloseDeleteModal();
    }, [editState, onCloseDeleteModal, selectedOffer]);

    const onChangeFilter = useCallback(
      (filter: IOfferState) => {
        const state = filter !== 'all' ? filter : undefined;
        getOffers(1, pageSize, state);
        setActiveFilter(state);
      },
      [getOffers, pageSize]
    );

    const goToAllOffers = useCallback(() => {
      onChangeFilter('all');
    }, [onChangeFilter]);

    const isShowLoader = loadState === LoadState.firstLoad || loadState === LoadState.loadingMore;
    const isShowHeader = offersTable.items.length !== 0 || activeFilter || loadState === LoadState.loadingMore;
    const isShowTable = loadState === LoadState.allIsLoaded && offersTable.items.length > 0;
    const isShowEmptyList = loadState === LoadState.allIsLoaded && offersTable.items.length === 0;

    return (
      <>
        {isShowLoader && <Loader />}
        {isShowHeader && (
          <OfferListHeader
            title={'Offers'}
            active={activeFilter}
            onClick={createOffer}
            onChangeFilter={onChangeFilter}
            showFilters={showFilters}
          />
        )}
        {isShowTable && (
          <RemotePagination
            page={offersTable.page}
            sizePerPage={offersTable.sizePerPage}
            totalSize={offersTable.totalSize}
            onTableChange={handleTableChange}
            tableProps={{
              keyField: 'id',
              columns: columnsData,
              selectRow: selectRow,
              rowClasses: styles.row,
              data: offersTable.items
            }}
          />
        )}
        {isShowEmptyList && <EmptyOfferList onClick={activeFilter ? goToAllOffers : openOffer} state={activeFilter} />}
        <PublicOfferErrorModal onClose={onClosePublicErrorModal} offerId={showPublicErrorOfferIdModal} />
        {showCongrats && (
          <Congratulations onClose={onCloseCongratsOfferIdModal} show={true} offerId={showCongrats} />
        )}
        <ModalActionComponent
          show={!!unpublicOfferId}
          onCloseModal={() => {}}
          title={
            <>
              Offer{' '}
              <span className={styles.titleModal}>
                Creativity Unleashed: Discover, Hone, and Share
                <br /> Your Voice
              </span>{' '}
              unpublished
            </>
          }
          iconType={'warning'}
          iconStyles={styles.warning}
          hideClose
        >
          <div className={modalStyles.description}>
            Automation elements that use this offer will not run. Also if you used the embedded offer on your web page,
            it will no longer work either.{' '}
          </div>

          <div className={modalStyles.buttons}>
            <Button onClick={closeUnpublicModal} withIcon={false} btnStyle={'transparent'} className={'mr-2'}>
              Cancel
            </Button>
            <Button
              onClick={onConfirmUnpublicOffer}
              withIcon={true}
              className={'btn-warning'}
              customIcon={<IconMegaphoneOff />}
            >
              Unpublish offer
            </Button>
          </div>
        </ModalActionComponent>
        <ModalDelete
          isShowed={showDeleteModal}
          title={
            <>
              Offer <span>{selectedOffer?.name}</span> will be deleted
            </>
          }
          description={'All offer settings you want to delete cannot be restored after confirming the deletion.'}
          onClose={onCloseDeleteModal}
          onDelete={deleteOffer}
          iconStyles={styles.delete}
        />
      </>
    );
  }
);
