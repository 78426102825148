import { AxiosResponse } from 'axios';
import actionCreatorFactory from 'typescript-fsa';

import { IGeneralSettings } from '../../../../interfaces';
import {
  PaymentExistingAccount,
  PaymentLink,
  PaynetAccount,
  PaynetError,
  PaypalAccount,
  PublishableKey,
  StripeAccount
} from './GeneralSettingsState';

export const actionCreator = actionCreatorFactory();
export const GeneralSettingsActions = {
  addPaynet: actionCreator.async<
    Pick<PaynetAccount, 'username' | 'password' | 'merchant_id'>,
    PaynetAccount,
    AxiosResponse<PaynetError>
  >('ADD_PAYNET'),
  getOrganization: actionCreator.async<void, IGeneralSettings, Error>('GET_ORGANIZATION'),
  editOrganization: actionCreator.async<Partial<IGeneralSettings>, IGeneralSettings, Error>('EDIt_ORGANIZATION'),
  getStripeAccount: actionCreator.async<void, StripeAccount, Error>('GET_STRIPE_ACCOUNT'),
  getPaynetAccount: actionCreator.async<void, PaynetAccount, Error>('GET_PAYNET_ACCOUNT'),
  getPaypalAccount: actionCreator.async<void, PaypalAccount, Error>('GET_PAYPAL_ACCOUNT'),
  getPublishableKey: actionCreator.async<void, PublishableKey, Error>('GET_PUBLISHABLE_KEY'),
  detachStripeAccount: actionCreator.async<void, void, Error>('DETACH_STRIPE_ACCOUNT'),
  detachPaynetAccount: actionCreator.async<void, void, Error>('DETACH_PAYNET_ACCOUNT'),
  detachPaypalAccount: actionCreator.async<void, void, Error>('DETACH_PAYPAL_ACCOUNT'),
  getStripeLink: actionCreator.async<PaymentLink, { link: string }, Error>('GET_STRIPE_LINK'),
  getPaypalLink: actionCreator.async<Pick<PaymentLink, 'return_url'>, { link: string }, Error>('GET_PAYPAL_LINK'),
  addPaypalAccount: actionCreator.async<PaypalAccount, void, Error>('ADD_PAYPAL_ACCOUNT'),
  addExistingAccount: actionCreator.async<PaymentExistingAccount, void, Error>('ADD_EXISTING_ACCOUNT')
};
