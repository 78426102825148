import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { difference, find, flatMapDeep, map, orderBy } from 'lodash';

import { flatten } from '../../../../../components/course/course/CourseHelpers';
import { useLoading } from '../../../../../hooks/use-loading';
import { BlockType, ICourseBlockQuiz } from '../../../../../interfaces';
import {
  AnswerPayloadType,
  AnswerTypeEnum,
  IQuizQuestion,
  PassingGradeTypeValues
} from '../../../../../interfaces/quiz';
import { courseActions } from '../../../../lms/courses/store/CourseActions';
import { update } from '../../../../lms/courses/store/CourseReducer';
import { ICourseNode } from '../../../../lms/courses/store/CourseState';
import { useViewCourseContext } from '../../../../lms/students/student-course/ViewCourseContext';
import { LessonLoader } from '../../lesson-block/lesson-loader/LessonLoader';
import { quizActionAsync } from './store/QuizActionAsync';
import { IViewQuiz } from './store/QuizState';
import { ViewQuiz } from './ViewQuiz';
import { ViewQuizContext } from './ViewQuizContext';

interface IProps {
  block: ICourseBlockQuiz;
  isPreview: boolean;
}

export const ViewQuizWrapper = ({ block, isPreview = false }: IProps) => {
  const [quiz, setQuiz] = useState<IViewQuiz | null>(null);
  const { loading, startLoading, stopLoading } = useLoading();
  const [selectedAnswer, setSelectedAnswer] = useState<AnswerPayloadType>([]);
  const [selectQuestion, setSelectQuestion] = useState<IQuizQuestion | null | undefined>();
  const dispatch = useDispatch();
  const { course } = useViewCourseContext();

  const isLastQuestion = useMemo(
    () =>
      selectQuestion && quiz?.quiz
        ? quiz.quiz.questions.lastIndexOf(selectQuestion) === quiz.quiz.questions.length - 1
        : true,
    [quiz?.quiz, selectQuestion]
  );

  const previewQuizResult: IViewQuiz | undefined = useMemo(
    () =>
      isPreview && block.type === BlockType.QUIZ && block.quiz && block.quiz.questions.length > 0
        ? {
            quiz: {
              ...block.quiz,
              randomize_questions: false,
              questions: block.quiz.questions.map((x) => {
                return { ...x, is_passed_with: null };
              })
            },
            result: {
              is_passed: false,
              passing_grade: block.quiz.passing_grade || null,
              total_points: map(block.quiz?.questions, 'points')?.reduce((acc, x) => acc + x, 0),
              total_score: 0
            },
            state: 'in_progress'
          }
        : undefined,
    [block.quiz, block.type, isPreview]
  );

  const onSetQuiz = useCallback(
    (quiz: IViewQuiz) => {
      const sortedQuiz: IViewQuiz = {
        ...quiz,
        quiz: {
          ...quiz.quiz,
          questions: orderBy(quiz.quiz.questions, 'order', ['asc'])
        }
      };

      setQuiz(sortedQuiz);
      if (selectQuestion) {
        const currentQuestion = sortedQuiz.quiz.questions.find((x) => x.id === selectQuestion.id);
        setSelectQuestion(currentQuestion);
      } else {
        const notAnswered = sortedQuiz.quiz.questions.filter((x) => !x.choices);
        if (notAnswered.length > 0) {
          setSelectQuestion(notAnswered[0]);
        } else if (notAnswered.length === 0 && sortedQuiz.result) {
          setSelectQuestion(null);
        } else if (notAnswered.length === 0 && !sortedQuiz.result) {
          setSelectQuestion(sortedQuiz.quiz.questions[0]);
        }
      }
    },
    [selectQuestion, setQuiz, setSelectQuestion]
  );

  const onGetQuiz = useCallback(() => {
    if (!isPreview && block.quiz_id) {
      dispatch(quizActionAsync.getQuiz(course.id, block.quiz_id, onSetQuiz));
    } else if (isPreview && previewQuizResult) {
      onSetQuiz(previewQuizResult);
    }
  }, [block?.quiz_id, course.id, dispatch, isPreview, onSetQuiz, previewQuizResult]);

  const onTryAgain = useCallback(() => {
    setSelectedAnswer([]);
    if (isPreview) {
      onGetQuiz();
    } else {
      dispatch(quizActionAsync.tryQuizAgain(course.id, block.id, 'quiz.retake', onGetQuiz));
    }
  }, [block.id, course.id, dispatch, isPreview, onGetQuiz]);

  const onSelectQuestion = useCallback(
    (question: IQuizQuestion | null) => {
      if ((Array.isArray(selectedAnswer) && selectedAnswer.length !== 0) || selectedAnswer) {
        setSelectedAnswer([]);
      }
      if (!question) {
        setSelectQuestion(null);
      } else {
        const questionIndex = quiz?.quiz.questions.findIndex((x) => x.id === question.id);
        if (questionIndex && quiz?.quiz.questions && quiz?.quiz.questions.length > 0) {
          const prevQuestion = quiz.quiz.questions[questionIndex - 1];
          if (prevQuestion.choices) {
            setSelectQuestion(question);
          }
        } else {
          setSelectQuestion(question);
        }
      }
    },
    [quiz?.quiz.questions, selectedAnswer]
  );

  const onNextQuestion = useCallback(() => {
    const currentQuestion = selectQuestion;
    if (quiz) {
      const currentQuestionIndex = quiz.quiz.questions.findIndex((x) => x.id === currentQuestion?.id);
      if (currentQuestionIndex !== -1) {
        const nextQuestion = quiz.quiz?.questions[currentQuestionIndex + 1];
        if (nextQuestion) {
          onSelectQuestion(nextQuestion);
        } else {
          setSelectQuestion(null);
        }
      }
    }
  }, [onSelectQuestion, quiz, selectQuestion]);

  const onChangeSelectAnswer = useCallback(
    (value: string[]) => {
      setSelectedAnswer(value);
    },
    [setSelectedAnswer]
  );

  const onCompleteQuizInPreview = useCallback(() => {
    const question = quiz?.quiz.questions.find((x) => x.id === selectQuestion?.id);
    const questionIndex = quiz?.quiz.questions.findIndex((x) => x.id === selectQuestion?.id);

    if (question && quiz) {
      const answers = (question.answers || []).filter((answer) => answer.is_true);

      let isPassed = true;
      if (
        question.answer_type === AnswerTypeEnum.single_select ||
        question.answer_type === AnswerTypeEnum.multiple_select
      ) {
        isPassed = difference(map(answers, 'id'), selectedAnswer as string[]).length === 0;
      }
      // if (question.answer_type === AnswerTypeEnum.audio_input) {
      //   isPassed = !!selectedAnswer;
      // }

      let totalScore = quiz.result?.total_score || 0;
      let isPassedAllQuiz = quiz.state === 'success' || false;
      if (isPassed && quiz.result?.passing_grade?.type === PassingGradeTypeValues.by_question) {
        totalScore = totalScore + 1;
        isPassedAllQuiz = totalScore >= quiz.result?.passing_grade?.questions_count;
      }
      if (isPassed && quiz.result?.passing_grade?.type === PassingGradeTypeValues.by_answer_points) {
        totalScore = totalScore + question.points;
        isPassedAllQuiz = totalScore >= quiz.result?.passing_grade?.points_count;
      }
      if (isLastQuestion) {
        const blockQuiz = find<ICourseNode | undefined>(flatMapDeep(course.children, flatten), ['id', block.id]);
        if (blockQuiz) {
          course.children.forEach(update(block.id, { ...blockQuiz, is_passed: isPassedAllQuiz }));
          dispatch(courseActions.updateCourseHierarchyForPreviewMode(course));
        }
      }
      const answerQuiz: IViewQuiz = {
        quiz: {
          ...quiz.quiz,
          questions: quiz.quiz.questions.map((x) => {
            if (x.id === question.id) {
              return { ...x, is_passed_with: isPassed, choices: selectedAnswer };
            }
            return x;
          })
        },
        result: {
          passing_grade: previewQuizResult?.result?.passing_grade || null,
          total_points: previewQuizResult?.result?.total_points || 0,
          total_score: totalScore
        },
        state: questionIndex === quiz.quiz.questions.length - 1 ? 'success' : 'in_progress'
      };
      onSetQuiz(answerQuiz);
    }
  }, [
    quiz,
    selectQuestion?.id,
    selectedAnswer,
    isLastQuestion,
    previewQuizResult?.result?.passing_grade,
    previewQuizResult?.result?.total_points,
    onSetQuiz,
    course,
    block.id,
    dispatch
  ]);

  useEffect(() => {
    if (!isPreview) {
      startLoading();
      dispatch(
        quizActionAsync.getQuiz(course.id, block.quiz_id, (data) => {
          stopLoading();
          onSetQuiz(data);
        })
      );
    } else if (isPreview && previewQuizResult) {
      onSetQuiz(previewQuizResult);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {block.id && (
        <ViewQuizContext.Provider
          value={{
            selectedAnswer,
            blockId: block.id,
            isLastQuestion,
            courseId: course.id,
            isPreview: isPreview,
            onChangeSelectAnswer,
            onGetQuiz,
            onNextQuestion,
            onSelectQuestion,
            onCompleteQuizInPreview
          }}
        >
          {loading ? (
            <LessonLoader />
          ) : (
            <ViewQuiz
              quiz={quiz}
              selectQuestion={selectQuestion}
              quiz_state={block.quiz_state}
              onTryAgain={onTryAgain}
            />
          )}
        </ViewQuizContext.Provider>
      )}
    </>
  );
};
