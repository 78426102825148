import { NavLink } from 'react-router-dom';

import classNames from 'classnames';
import styles from '../../../../styles/components/navbar.module.scss';

import { CRMPaths } from '../../../modules/crm/routes/CRMRoutes';

export const ListListsPanel = () => {
  const navigation = [
    { title: 'Contacts', path: CRMPaths.crmContacts },
    { title: 'Lists', path: CRMPaths.crmLists }
  ];

  return (
    <div className={classNames('navbar navbar-expand justify-content-end', styles.navbar)}>
      <ul className={classNames('navbar-nav mx-auto', styles['navbar-nav'])}>
        {navigation.map((item, index) => {
          return (
            <li key={index} className={classNames('nav-item', styles.item)}>
              <NavLink className={classNames('nav-link', styles.link)} to={item.path} activeClassName={styles.active}>
                {item.title}
              </NavLink>
            </li>
          );
        })}
      </ul>
    </div>
  );
};
