import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import commonStyles from '../main/setting.module.scss';
import styles from './payment.module.scss';
import { PaynetSVG, PaypalSVG, StripeSVG } from '../../../components/UI/icons';

import { useQuery } from '../../../hooks/use-query';
import { HelperWrapper } from '../common/help/HelperWrapper';
import { GeneralSettingsActionAsync } from '../general/store/GeneralSettingsActionAsync';
import { SettingsPaths } from '../routes/SettingsRoutes';
import { SettingsHeader } from '../SettingsHeader';
import { ConnectModalPaynet } from './connect-modal-paynet/ConnectModalPaynet';
import { DisconnectModal } from './disconnect/DisconnectModal';
import { PaymentMethod } from './payment-method/PaymentMethod';

export const PaymentIntegration = () => {
  const [disableStripeModal, setDisableStripeModal] = useState(false);
  const [disablePaynetModal, setDisablePaynetModal] = useState(false);
  const [disablePaypalModal, setDisablePaypalModal] = useState(false);
  const [connectPaynetModal, setConnectPaynetModal] = useState(false);
  const params = useQuery();
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    stripe: { state, display_name },
    paynet,
    paypal
  } = useSelector((state) => state.general.paymentAccount);
  const redirectToStripe = (link: string) => {
    const win = window.open(link, '_self');
    win?.focus();
  };

  const onCloseStripeModal = useCallback(() => setDisableStripeModal(false), []);
  const onClosePaynetModal = useCallback(() => setDisablePaynetModal(false), []);
  const onClosePaypalModal = useCallback(() => setDisablePaypalModal(false), []);
  const onCloseConnectPaynetModal = useCallback(() => setConnectPaynetModal(false), []);

  const onGetStripeAccountHandler = useCallback(() => {
    dispatch(GeneralSettingsActionAsync.getStripeAccount());
  }, [dispatch]);

  const onGetPaynetAccountHandler = useCallback(() => {
    dispatch(GeneralSettingsActionAsync.getPaynetAccount());
  }, [dispatch]);

  const onGetPaypalAccountHandler = useCallback(() => {
    dispatch(GeneralSettingsActionAsync.getPaypalAccount());
  }, [dispatch]);

  const onConnectPaynetModalHandle = () => {
    setConnectPaynetModal(true);
  };

  const onConnectExistingStripeAccount = useCallback(() => {
    const { href } = window.location;
    dispatch(
      GeneralSettingsActionAsync.getStripeLink({ refresh_url: href, return_url: href, oauth: true }, redirectToStripe)
    );
  }, [dispatch]);

  const onConnectPaypalAccount = useCallback(() => {
    const { href } = window.location;
    dispatch(GeneralSettingsActionAsync.getPaypalLink({ return_url: href }, redirectToStripe));
  }, [dispatch]);

  const onDisconnectStripeHandle = useCallback(() => {
    setDisableStripeModal(true);
  }, []);
  const onDisconnectPaynetHandle = useCallback(() => {
    setDisablePaynetModal(true);
  }, []);
  const onDisconnectPaypalHandle = useCallback(() => {
    setDisablePaypalModal(true);
  }, []);
  const onDisconnectStripe = useCallback(() => {
    dispatch(GeneralSettingsActionAsync.detachStripeAccount(onGetStripeAccountHandler));
    onCloseStripeModal();
  }, [dispatch, onCloseStripeModal, onGetStripeAccountHandler]);

  const onDisconnectPaynet = useCallback(() => {
    dispatch(GeneralSettingsActionAsync.detachPaynetAccount(onClosePaynetModal));
  }, [dispatch, onClosePaynetModal]);

  const onDisconnectPaypal = useCallback(() => {
    dispatch(
      GeneralSettingsActionAsync.detachPaypalAccount(() => {
        onGetPaypalAccountHandler();
        onClosePaypalModal();
      })
    );
  }, [dispatch, onClosePaypalModal, onGetPaypalAccountHandler]);

  useEffect(() => {
    onGetStripeAccountHandler();
    onGetPaynetAccountHandler();
    onGetPaypalAccountHandler();
  }, [onGetPaynetAccountHandler, onGetPaypalAccountHandler, onGetStripeAccountHandler]);

  useEffect(() => {
    const code = params.get('code');
    const state = params.get('state');
    const merchantIdInPayPal = params.get('merchantIdInPayPal');
    if (code && state) {
      dispatch(GeneralSettingsActionAsync.addExistingAccount({ code, state }, onGetStripeAccountHandler));
    }
    if (merchantIdInPayPal) {
      dispatch(
        GeneralSettingsActionAsync.addPaypalAccount({ merchant_id: merchantIdInPayPal }, onGetPaypalAccountHandler)
      );
    }
    history.push(SettingsPaths.payment);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, history, onGetPaypalAccountHandler, onGetStripeAccountHandler]);

  return (
    <>
      <SettingsHeader title={'Payment integrations'} />
      <HelperWrapper>
        <div className={commonStyles.contentContainer}>
          <PaymentMethod
            description={
              'everact.io with stripe to provide you with the easiest way to accept credit cards online and collect payments from your customers.'
            }
            title={'Stripe'}
            src={<StripeSVG />}
            displayName={display_name}
            onConnectExistingAccount={onConnectExistingStripeAccount}
            onDisconnect={onDisconnectStripeHandle}
            state={state}
            imgClassName={styles.stripeContainer}
            integrationSystem={'stripe'}
          />
          <PaymentMethod
            description={
              'If your business is based in Republic of Moldova, this is the best way to receive payments in Euro, US Dollars or Moldovan Leu'
            }
            src={<PaynetSVG />}
            title={'Paynet'}
            displayName={null}
            onConnectExistingAccount={onConnectPaynetModalHandle}
            onDisconnect={onDisconnectPaynetHandle}
            state={paynet ? 'done' : 'non_integrated'}
            imgClassName={styles.paynetContainer}
            integrationSystem={'paynet'}
          />
          <PaymentMethod
            title={'PayPal'}
            description={'Connect your account to everact.io to let your customers check out with PayPal'}
            src={<PaypalSVG />}
            displayName={null}
            integrationSystem={'paypal'}
            state={paypal ? 'done' : 'non_integrated'}
            onConnectExistingAccount={onConnectPaypalAccount}
            onDisconnect={onDisconnectPaypalHandle}
            imgClassName={styles.paypalContainer}
          />
        </div>
      </HelperWrapper>
      <DisconnectModal
        onClose={onCloseStripeModal}
        onDisconnect={onDisconnectStripe}
        showModal={disableStripeModal}
        title={
          <>
            You are trying to disable
            <br /> integration with Stripe
          </>
        }
        variableDescription={
          <>
            <p>Everact.io will no longer be able to charge customers for payments that are still in progress.</p>
            <p>Subscription will no longer be updated with statuses from Stripe.</p>
            <p>Your offers will be moved to draft also.</p>
          </>
        }
        btnDisconnectText={'Disconnect Stripe'}
      />
      <DisconnectModal
        onClose={onClosePaynetModal}
        onDisconnect={onDisconnectPaynet}
        showModal={disablePaynetModal}
        title={
          <>
            You are trying to disable
            <br /> integration with Paynet
          </>
        }
        variableDescription={
          <>
            <p>Everact.io will no longer be able to charge customers for payments that are still in progress.</p>
            <p>Subscription will no longer be updated with statuses from Paynet.</p>
            <p>Your offers will be moved to draft also.</p>
          </>
        }
        btnDisconnectText={'Disconnect Paynet'}
      />
      <DisconnectModal
        onClose={onClosePaypalModal}
        onDisconnect={onDisconnectPaypal}
        showModal={disablePaypalModal}
        title={
          <>
            You are trying to disable
            <br /> integration with Paypal
          </>
        }
        variableDescription={
          <>
            <p>Everact.io will no longer be able to charge customers for payments that are still in progress.</p>
            <p>Subscription will no longer be updated with statuses from Paypal.</p>
            <p>Your offers will be moved to draft also.</p>
          </>
        }
        btnDisconnectText={'Disconnect Paypal'}
      />
      <ConnectModalPaynet show={connectPaynetModal} onClose={onCloseConnectPaynetModal} />
    </>
  );
};
