import { useState } from 'react';
import { useDispatch } from 'react-redux';

import classNames from 'classnames';
import styles from './quiz-moderation.module.scss';
import { Icon16NewWindow } from '../../../../../components/UI/icons';

import { Button } from '../../../../../components/UI';
import { IQuizSessionDetail, QuizState } from '../../../../../interfaces/quiz';
import { answersPaths } from '../../../answers/routes/AnswersRoutes';
import { QuizSessionsActionAsync } from '../analytics/store/QuizSessionsActionAsync';
import { FailedModerationButton, PassedModerationButton } from './quiz-moderation-action/QuizModerationAction';

interface IProps {
  id: string;
  loading: boolean;
  setLoading: (x: boolean) => void;
  showRedirectToAnswer?: boolean;
  status: QuizState;
  setQuizDetail: (data: IQuizSessionDetail) => void;
  className?: string;
}

export const QuizModeration = ({
  id,
  loading,
  status,
  setLoading,
  setQuizDetail,
  className,
  showRedirectToAnswer = false
}: IProps) => {
  const dispatch = useDispatch();
  const [markPassed, setMarkPassed] = useState<boolean | null>(null);
  const onQuestionPassed = () => setMarkPassed(true);
  const onQuestionFailed = () => setMarkPassed(false);
  const onQuestionReset = () => setMarkPassed(null);

  const onSaveCallback = () => {
    dispatch(
      QuizSessionsActionAsync.getQuizSession(id, (data) => {
        setQuizDetail(data);
        setLoading(false);
      })
    );
  };

  const onSave = () => {
    setLoading(true);
    dispatch(QuizSessionsActionAsync.markPassedQuizSession(id, !!markPassed, onSaveCallback));
  };

  return (
    <div className={classNames(styles.markQuiz, className)}>
      {status === 'pending_moderation' && (
        <>
          <div className={styles.markQuizContain}>
            <span className={styles.markQuizTitle}>Mark quiz as:</span>
            <div className={styles.markQuizButtons}>
              <PassedModerationButton markPassed={markPassed} onQuestionPassed={onQuestionPassed} />
              <FailedModerationButton markPassed={markPassed} onQuestionFailed={onQuestionFailed} />
            </div>
          </div>
          {markPassed !== null && (
            <div className={styles.actionsButtons}>
              <Button onClick={onQuestionReset} withIcon={false} btnStyle={'transparent'} className={'mr-2'}>
                Cancel
              </Button>
              <Button withIcon={false} onClick={onSave} btnProps={{ disabled: loading }} type={'update'}>
                Save
              </Button>
            </div>
          )}
        </>
      )}
      {status === 'success' && (
        <PassedModerationButton markPassed={true} onQuestionPassed={() => {}} className={styles.btnDisable} />
      )}
      {status === 'fail' && (
        <FailedModerationButton markPassed={false} onQuestionFailed={() => {}} className={styles.btnDisable} />
      )}
      {showRedirectToAnswer && markPassed === null && (
        <a
          href={answersPaths.answers}
          target={'_blank'}
          className={classNames('link', styles.linkText)}
          rel="noreferrer"
        >
          Open in “Answers” <Icon16NewWindow className={styles.iconLink} />
        </a>
      )}
    </div>
  );
};
