import { SyntheticEvent } from 'react';

import classNames from 'classnames';
import styles from './course-preview.module.scss';

import { ThumbnailPlaceholderImage } from '../../../../components/UI/image-components/thumbnail-placeholder-image/ThumbnailPlaceholderImage';
import { IFile } from '../../../../interfaces';

interface IProps {
  id: string;
  image: IFile | null;
  title: string;
  onActive: (e: SyntheticEvent<HTMLDivElement>) => void;
  active: boolean;
  className?: string;
}

export const CoursePreview = ({ id, title, image, onActive, active, className }: IProps) => {
  return (
    <div
      className={classNames(styles.container, { [styles.active]: active }, className)}
      onClick={onActive}
      data-id={id}
    >
      <ThumbnailPlaceholderImage thumbnailCourse={image} width={63} height={36} className={styles.img} />
      <div className={styles.title}>{title}</div>
    </div>
  );
};
