import { memo } from 'react';

import classNames from 'classnames';
import styles from './quizStep.module.scss';

import { IViewQuiz } from '../store/QuizState';
import { useViewQuizContext } from '../ViewQuizContext';

interface IProps extends Pick<IViewQuiz, 'state'> {
  isLastQuestionCompleted: boolean;
}

export const StepResult = memo(({ state, isLastQuestionCompleted }: IProps) => {
  const { onSelectQuestion } = useViewQuizContext();
  const onClick = () => {
    if (isLastQuestionCompleted) {
      onSelectQuestion(null);
    }
  };

  return (
    <div
      onClick={onClick}
      className={classNames(styles.result, {
        [styles.active]: state === 'success' || state === 'fail' || state === 'pending_moderation'
      })}
    >
      Result
    </div>
  );
});
