import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { useIntercom } from 'react-use-intercom';

import {
  IconClick,
  IconMegaphone,
  IconMisc,
  IconMove,
  IconPage,
  IconPicture,
  IconText
} from '../../../components/UI/icons';

import { addFunnelNode, saveFunnelEdge } from '../../../API/funnel-api';
import { getFunnel } from '../../../API/funnels-api';
import palette from '../../../components/config/palette.json';
import {
  CategoryNameType,
  EdgeRequest,
  GeometryRequest,
  NodeTypes,
  PaletteElementNames,
  PaletteElementTypes
} from '../../../interfaces';
import Builder from '../../common/builder/Builder';
import { builderActionAsync } from '../../common/builder/store/BuilderActionAsync';
import { funnelPaths } from '../routes/FunnelRoutes';

interface IProps {
  isThumbnails?: boolean;
}
const paletteNavItems: { category: CategoryNameType; icon: JSX.Element }[][] = [
  [
    {
      category: 'pages',
      icon: <IconPage />
    },
    {
      category: 'actions',
      icon: <IconClick />
    },
    {
      category: 'traffic_source',
      icon: <IconMegaphone />
    },
    {
      category: 'misc',
      icon: <IconMisc />
    }
  ],
  [
    {
      category: 'move',
      icon: <IconMove />
    },
    {
      category: 'text',
      icon: <IconText />
    },
    {
      category: 'picture',
      icon: <IconPicture />
    }
  ]
];
const paletteElements = palette.palette.category;

export const FunnelBuilder = ({ isThumbnails }: IProps) => {
  const dispatch = useDispatch();
  const { update } = useIntercom();
  const path = useRouteMatch<{ id: string }>(funnelPaths.funnelId());
  const thumbnailPath = useRouteMatch<{ id: string }>(funnelPaths.thumbnailId());

  const { isPayloadShow } = useSelector((state) => state.settings);

  useEffect(() => {
    update({
      horizontalPadding: isPayloadShow ? 388 : 20
    });
  }, [isPayloadShow, update]);

  useEffect(() => {
    return () => {
      update({
        horizontalPadding: 20
      });
    };
  }, [update]);

  const addNode = (id: string, types: NodeTypes) => {
    dispatch(builderActionAsync.addNode(id, types, 'funnel'));
  };
  const deleteEdge = (id: string, edgeKey: string) => {
    dispatch(builderActionAsync.deleteEdge(id, edgeKey, 'funnel'));
  };
  const deleteNode = (id: string, nodeKey: string) => {
    dispatch(builderActionAsync.deleteNode(id, nodeKey, 'funnel'));
  };
  const saveEdge = (id: string, edge: EdgeRequest) => {
    dispatch(builderActionAsync.saveEdge(id, edge, 'funnel'));
  };
  const saveGeometry = (id: string, data: GeometryRequest) => {
    dispatch(builderActionAsync.saveGeometry(id, data, 'funnel'));
  };
  // const saveNode = (id: string, nodeKey: string, data: { [key: string]: string | undefined }) => {
  //   dispatch(builderActionAsync.saveNode(id, nodeKey, data, 'funnel'));
  // };
  // const getNode = (id: string, nodeKey: string) => {
  //   dispatch(builderActionAsync.getNode(id, nodeKey, 'funnel'));
  // };
  const getFunnelDetail = async (id: string) => {
    return await getFunnel(id);
  };
  const addFunnelNodeHandler = (id: string, nodeType: NodeTypes) => {
    return addFunnelNode(id, nodeType);
  };
  const saveFunnelEdgeHandler = (id: string, edge: EdgeRequest) => {
    return saveFunnelEdge(id, edge);
  };
  const id = path?.params.id || thumbnailPath?.params.id;

  return (
    <>
      {id && (
        <Builder
          addNode={addNode}
          deleteEdge={deleteEdge}
          deleteNode={deleteNode}
          saveEdge={saveEdge}
          saveGeometry={saveGeometry}
          getDetailData={getFunnelDetail}
          addNodeHandler={addFunnelNodeHandler}
          saveEdgeHandler={saveFunnelEdgeHandler}
          id={id}
          isThumbnails={isThumbnails}
          paletteNavItems={paletteNavItems}
          paletteElements={paletteElements}
          paletteElementNames={PaletteElementNames}
          paletteElementTypes={PaletteElementTypes}
          currentElementCategoryDefault={'pages'}
          builderType={'funnel'}
        />
      )}
    </>
  );
};
