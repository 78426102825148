import { useState } from 'react';
import { useDispatch } from 'react-redux';

import styles from './lesson-comment.module.scss';
import { Icon32ChatBubbles, IconEye, IconEyeHide } from '../../../../../../components/UI/icons';

import { Button } from '../../../../../../components/UI';
import { courseActionAsync } from '../../../store/CourseActionAsync';
import { LessonThreadModal } from './lesson-thread-modal/LessonThreadModal';

type Props = {
  lessonId: string;
  status?: boolean | null;
};

export const LessonComment = ({ status, lessonId }: Props) => {
  const [threadId, setThreadId] = useState<string>();
  const [showThread, setShowThread] = useState(false);
  const dispatch = useDispatch();
  const onShowModal = () => setShowThread(true);
  const onCallback = (id: string) => {
    setThreadId(id);
    onShowModal();
  };

  const onClick = () => {
    dispatch(courseActionAsync.getOrCreateThreadComments(lessonId, onCallback));
  };
  const onCloseModal = () => setShowThread(false);

  return (
    <div className={styles.container}>
      <div className={styles.imgContainer}>
        <Icon32ChatBubbles />
      </div>
      <div>
        <div className={styles.titleContainer}>
          <span className={styles.text}>Comments</span>
          <span className={styles.status}>
            {status ? (
              <>
                <IconEye />
                enabled
              </>
            ) : (
              <>
                <IconEyeHide />
                hidden
              </>
            )}
          </span>
        </div>
        <div className={styles.text}>
          Comments can be hidden or enabled in the lesson settings. Click on the icon to the right of the lesson name in
          the hierarchy and move the “Comments” switch to the position you need.
        </div>
        <Button onClick={onClick} withIcon={false} className={'mt-2'}>
          View comments
        </Button>
      </div>
      {threadId && (
        <LessonThreadModal
          show={showThread}
          onClose={onCloseModal}
          threadId={threadId}
          commentsShow={!!status}
          showInfoMessage={true}
        />
      )}
    </div>
  );
};
