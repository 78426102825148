import { CustomDispatch } from 'redux';

import { EmailMessagesService } from '../../../../API/email-api';
import {
  CreateSenderErrorResponse,
  EditSenderErrorResponse,
  IEmail,
  IError,
  IMessage,
  IPaginationParams,
  IPaginationResponse,
  ISender
} from '../../../../interfaces';
import { EmailActions } from './EmailActions';

export const EmailActionAsync = {
  getMessages:
    (pagination: IPaginationParams, callback?: (messages: IPaginationResponse<IMessage[]>) => void) =>
    async (dispatch: CustomDispatch) => {
      try {
        dispatch(EmailActions.getMessages.started());
        const response = await EmailMessagesService.getMessages(pagination);
        if (response.data && response.meta) {
          dispatch(EmailActions.getMessages.done({ result: {data: response.data, meta: response.meta} }));
          callback?.({
            data: response.data,
            meta: response.meta
          });
        }
      } catch (error) {
        dispatch(EmailActions.getMessages.failed({ error }));
      }
    },
  createMessage: (callback?: (message: IMessage) => void) => async (dispatch: CustomDispatch) => {
    try {
      dispatch(EmailActions.createMessage.started());
      const response = await EmailMessagesService.createMessage();
      if (response.data) {
        dispatch(EmailActions.createMessage.done({ result: response.data }));
        callback?.(response.data);
      }
    } catch (error) {
      dispatch(EmailActions.createMessage.failed({ error }));
    }
  },
  getMessage: (id: string, callback?: (message: IMessage) => void) => async (dispatch: CustomDispatch) => {
    try {
      dispatch(EmailActions.getMessage.started(id));
      const response = await EmailMessagesService.getMessage(id);
      if (response.data) {
        dispatch(EmailActions.getMessage.done({ result: response.data, params: id }));
        callback?.(response.data);
      }
    } catch (error) {
      dispatch(EmailActions.getMessage.failed({ error, params: id }));
    }
  },
  editMessage:
    (id: string, request: Pick<IMessage, 'title' | 'payload'>, callback?: (message: IMessage) => void) =>
    async (dispatch: CustomDispatch) => {
      try {
        dispatch(EmailActions.editMessage.started({id}));
        const response = await EmailMessagesService.editMessage(id, request);
        if (response.data) {
          dispatch(EmailActions.editMessage.done({ result: response.data, params: {id} }));
          callback?.(response.data);
        }
      } catch (error) {
        dispatch(EmailActions.editMessage.failed({ error, params: {id} }));
      }
    },
  duplicateMessage:
    (id: string, request: Pick<IMessage, 'title'>, callback?: (message: IMessage) => void) =>
    async (dispatch: CustomDispatch) => {
      try {
        dispatch(EmailActions.duplicateMessage.started(id));
        const response = await EmailMessagesService.duplicateMessage(id, request);
        if (response.data) {
          dispatch(EmailActions.duplicateMessage.done({ result: response.data, params: id }));
          callback?.(response.data);
        }
      } catch (error) {
        dispatch(EmailActions.duplicateMessage.failed({ error, params: id }));
      }
    },
  sendTestEmailMessageWithBody:
    (payload: IEmail, email: string, onSuccessCallback?: () => void, onErrorCallback?: () => void) =>
    async (dispatch: CustomDispatch) => {
      try {
        dispatch(EmailActions.sendTestEmailMessageWithBody.started({ email, payload }));
        const data = await EmailMessagesService.sendTestEmailMessageWithBody(payload, email);
        if (data.status === 200) {
          onSuccessCallback?.();
        }
        dispatch(EmailActions.sendTestEmailMessageWithBody.done({ params: { email, payload } }));
      } catch (error) {
        onErrorCallback?.();
        dispatch(EmailActions.sendTestEmailMessageWithBody.failed({ error, params: { email, payload } }));
      }
    },
  sendTestEmailMessageWithoutBody:
    (messageId: string, email: string, onSuccessCallback?: () => void, onErrorCallback?: () => void) =>
    async (dispatch: CustomDispatch) => {
      try {
        dispatch(EmailActions.sendTestEmailMessageWithoutBody.started({ messageId, email }));
        const data = await EmailMessagesService.sendTestEmailMessageWithoutBody(messageId, email);
        if (data.status === 200) {
          onSuccessCallback?.();
        }
        dispatch(EmailActions.sendTestEmailMessageWithoutBody.done({ params: { messageId, email } }));
      } catch (error) {
        onErrorCallback?.();
        dispatch(EmailActions.sendTestEmailMessageWithoutBody.failed({ error, params: { messageId, email } }));
      }
    },
  getSenders:
    (isConfirmed?: boolean, withoutDefault?: boolean, callback?: (senders: ISender[]) => void) =>
    async (dispatch: CustomDispatch) => {
      try {
        dispatch(EmailActions.getSenders.started({ isConfirmed, withoutDefault }));
        const response = await EmailMessagesService.getSenders(isConfirmed, withoutDefault);
        if (response.data) {
          dispatch(EmailActions.getSenders.done({ result: response.data, params: { isConfirmed, withoutDefault } }));
          callback?.(response.data);
        }
      } catch (error) {
        dispatch(EmailActions.getSenders.failed({ error, params: { isConfirmed, withoutDefault } }));
      }
    },
  createSender:
    (
      email: string,
      callback?: (sender: ISender) => void,
      onError?: (error: IError<CreateSenderErrorResponse>) => void
    ) =>
    async (dispatch: CustomDispatch) => {
      try {
        dispatch(EmailActions.createSender.started(email));
        const response = await EmailMessagesService.createSender(email);
        if (response.data) {
          dispatch(EmailActions.createSender.done({ result: response.data, params: email }));
          callback?.(response.data);
        }
      } catch (error) {
        dispatch(EmailActions.createSender.failed({ error, params: email }));
        onError?.(error);
      }
    },
  getSender: (id: string, callback?: (sender: ISender) => void) => async (dispatch: CustomDispatch) => {
    try {
      dispatch(EmailActions.getSender.started(id));
      const response = await EmailMessagesService.getSender(id);
      if (response.data) {
        dispatch(EmailActions.getSender.done({ result: response.data, params: id }));
        callback?.(response.data);
      }
    } catch (error) {
      dispatch(EmailActions.getSender.failed({ error, params: id }));
    }
  },
  deleteSender:
    (id: string, callback?: () => void, onError?: (error: IError) => void) => async (dispatch: CustomDispatch) => {
      try {
        dispatch(EmailActions.deleteSender.started(id));
        const response = await EmailMessagesService.deleteSender(id);
        if (response.status === 200) {
          dispatch(EmailActions.deleteSender.done({ result: response.data, params: id }));
          callback?.();
        }
      } catch (error) {
        dispatch(EmailActions.deleteSender.failed({ error, params: id }));
        onError?.(error);
      }
    },
  editSender:
    (
      id: string,
      params: {
        is_default?: boolean;
        email?: string;
      },
      callback?: () => void,
      onError?: (error: IError<EditSenderErrorResponse>) => void
    ) =>
    async (dispatch: CustomDispatch) => {
      try {
        dispatch(EmailActions.editSender.started({ id }));
        const response = await EmailMessagesService.editSender(id, params);
        if (response.data) {
          dispatch(EmailActions.editSender.done({ result: response.data, params: { id } }));
          callback?.();
        }
      } catch (error) {
        dispatch(EmailActions.editSender.failed({ error, params: { id } }));
        onError?.(error);
      }
    },
  resendSenderConfirmation:
    (id: string, callback?: () => void, onError?: (error: IError) => void) => async (dispatch: CustomDispatch) => {
      try {
        dispatch(EmailActions.resendSenderConfirmation.started(id));
        const response = await EmailMessagesService.resendSenderConfirmation(id);
        if (response.data) {
          dispatch(EmailActions.resendSenderConfirmation.done({ result: response.data, params: id }));
          callback?.();
        }
      } catch (error) {
        dispatch(EmailActions.resendSenderConfirmation.failed({ error, params: id }));
        onError?.(error);
      }
    }
};
