import { memo, useEffect, useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useAudioPlayer, useAudioPosition } from 'react-use-audio-player';
import getBlobDuration from 'get-blob-duration';

import classNames from 'classnames';
import styles from './audio-player.module.scss';
import { Icon32Trash } from '../UI/icons';

import { CustomTooltip, Loader } from '../UI';
import { AudioBtn } from './audio-btn/AudioBtn';
import { AudioPlaySpeed } from './audio-play-speed/AudioPlaySpeed';
import { AudioPlayerTime } from './audio-player-time/AudioPlayerTime';
import { AudioProgressBar } from './audio-progress-bar/AudioProgressBar';
import { AudioVolume } from './audio-volume/AudioVolume';
import { IAudioPlayerProps } from './AudioPlayerProvider';

export const AudioPlayer = memo(
  ({
    audioSrc,
    removeRecord,
    withDelete = false,
    hideEndTime = false,
    hideSpeedRate = false,
    hideStartTime = false,
    hideVolume = false,
    className
  }: IAudioPlayerProps) => {
    const [duration, setDuration] = useState(0);
    const componentMounted = useRef(true);

    const { ready, loading } = useAudioPlayer({
      src: audioSrc,
      format: 'mp3',
      html5: true,
      autoplay: false
    });
    const { position } = useAudioPosition({ highRefreshRate: true });

    useEffect(() => {
      (async () => {
        const seconds = await getBlobDuration(audioSrc);
        if (componentMounted.current) {
          setDuration(seconds);
        }
      })();
      return () => {
        componentMounted.current = false;
      };
    }, [audioSrc]);

    if (!ready && !loading) return <div>No audio to play</div>;
    if (loading || !ready)
      return (
        <div className={classNames(styles.container, styles.wrapper, { [styles.mobile]: isMobile }, className)}>
          <Loader />
        </div>
      );

    return (
      <div className={classNames(styles.container, isMobile && 'p-4', className)}>
        {isMobile ? (
          <div className={'d-flex flex-column w-100'}>
            <div className={'d-flex justify-content-between align-items-center mb-4'}>
              {!hideVolume && <AudioVolume isMobile />}
              <AudioBtn />
              {withDelete ? (
                <CustomTooltip customText={'Delete'} direction={'top'}>
                  <Icon32Trash onClick={removeRecord} className={styles.iconTrash} />
                </CustomTooltip>
              ) : (
                !hideSpeedRate && <AudioPlaySpeed isMobile />
              )}
            </div>
            <div className={'d-flex  justify-content-center align-items-center'}>
              {!hideStartTime && (
                <AudioPlayerTime
                  timeSecond={position}
                  className={classNames(styles.currentPosition, isMobile && 'm-0')}
                />
              )}
              <AudioProgressBar duration={duration} />
              {!hideEndTime && (
                <AudioPlayerTime timeSecond={duration} className={classNames(styles.duration, isMobile && 'm-0')} />
              )}
            </div>
          </div>
        ) : (
          <>
            <AudioBtn />
            {!hideStartTime && (
              <AudioPlayerTime
                timeSecond={position}
                className={classNames(styles.currentPosition, isMobile && 'm-0')}
              />
            )}
            <AudioProgressBar duration={duration} />
            {!hideEndTime && (
              <AudioPlayerTime timeSecond={duration} className={classNames(styles.duration, isMobile && 'm-0')} />
            )}
            {!hideVolume && <AudioVolume />}
            {withDelete ? (
              <CustomTooltip customText={'Delete'} direction={'top'}>
                <Icon32Trash onClick={removeRecord} className={styles.iconTrash} />
              </CustomTooltip>
            ) : (
              !hideSpeedRate && <AudioPlaySpeed />
            )}
          </>
        )}
      </div>
    );
  }
);
