import * as yup from 'yup';

export const generalSchema = yup.object().shape({
  formatting_date_format: yup.string().required('Date is a required'),
  formatting_time_format: yup.string().required('Time is a required'),
  formatting_contact_name: yup.string().required('Contact name is a required'),
  region_country: yup.string().required('Country is a required'),
  region_state: yup.string().required('State is a required'),
  region_timezone: yup.string().required('Time zone is a required'),
  general_email: yup.string().required('Contact e-mail is a required').email('Wrong email'),
  general_industry: yup.string().required('Industry is a required'),
  general_phone: yup.string(),
  general_title: yup
    .string()
    .required('Organization name is a required')
    .max(5000, 'You have reached your 5000 character limit')
});
