import { useHistory } from 'react-router';

import styles from './recovery-success.module.scss';
import { Icon32CheckMark } from '../../../../components/UI/icons';

import { Button } from '../../../../components/UI';
import { mainPaths } from '../../../MainRoutes';

export const RecoverySuccess = () => {
  const history = useHistory();

  const onClickHandler = () => {
    history.push(mainPaths.auth);
  };

  return (
    <div className={styles.container}>
      <Icon32CheckMark className={styles.icon} />
      <div className={styles.title}>New password created successful</div>
      <div className={styles.description}>
        You have successfully confirm your new password. Please, use your new password when logging in.
      </div>
      <Button onClick={onClickHandler} withIcon={false} className={'w-100'} size={'lg'}>
        Sign In
      </Button>
    </div>
  );
};
