import { useEffect, useMemo, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import Select, { SingleValue } from 'react-select';

import { useNodePayload } from '../../../../../../hooks/use-node-payload';
import { PaletteAutomationElementTypes } from '../../../../../../interfaces';
import { ValueType } from '../../../../../../interfaces/node-builder-payload/automation-processors/delay';
import { PayloadOnlyOnceToggle } from '../../PayloadOnlyOnceToggle';
import { FixedPeriod } from './fixed-period/FixedPeriod';
import { UntilFixed } from './until-fixed/UntilFixed';

export type OptionTypePayloadDelay = { label: string; value: ValueType };

export const optionsPayload: OptionTypePayloadDelay[] = [
  {
    label: 'Until fixed date and time',
    value: 'fixed_datetime'
  },
  {
    label: 'For a fixed period',
    value: 'fixed_period'
  }
  // {
  //   label: 'Until fixed time of the day of the week',
  //   value: 'fixed_time_of_day'
  // }
];

export const styleOptionsPayloadDelay = {
  indicatorsContainer: (styles: any) => ({
    ...styles,
    padding: '0px'
  })
};

interface IProps {
  nodeId: string;
}

export const PayloadDelay = ({ nodeId }: IProps) => {
  const { setValue, register } = useFormContext();
  const node = useNodePayload<PaletteAutomationElementTypes.PROCESSOR_DELAY>(nodeId);
  const initValue = useMemo(
    () => optionsPayload.find((x) => x.value === node?.payload?.period?.period_type) || null,
    [node?.payload?.period?.period_type]
  );
  const [typeSelect, setTypeSelect] = useState<OptionTypePayloadDelay | null>(initValue);

  useEffect(() => {
    document.querySelectorAll(`.endpoint-${nodeId}`).forEach((item) => {
      item.remove();
    });
  }, [nodeId]);

  useEffect(() => {
    if (node?.payload?.period?.period_type) {
      register('period.period_type');
      setValue('period.period_type', node?.payload?.period?.period_type);
    }
  }, [node?.payload?.period?.period_type, register, setValue]);

  const onChangePeriodType = (opt: SingleValue<OptionTypePayloadDelay>) => {
    setValue('period.period_type', opt?.value);
    setTypeSelect(opt);
  };

  return (
    <>
      <PayloadOnlyOnceToggle />
      <Form.Group>
        <Select
          value={typeSelect}
          getOptionValue={(option) => option.value}
          options={optionsPayload}
          onChange={onChangePeriodType}
          classNamePrefix={'select'}
          className={'select-container'}
          menuPlacement={'auto'}
          placeholder={<>Select</>}
          components={{
            IndicatorSeparator: () => null
          }}
          styles={styleOptionsPayloadDelay}
        />
      </Form.Group>
      {typeSelect?.value === 'fixed_datetime' && <UntilFixed nodeId={nodeId} />}
      {typeSelect?.value === 'fixed_period' && <FixedPeriod nodeId={nodeId} />}
      {/* {typeSelect?.value === 'fixed_time_of_day' && <UntilFixedTime nodeId={nodeId} />} */}
    </>
  );
};
