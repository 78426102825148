import classNames from 'classnames';
import styles from './product-type.module.scss';
import { Icon16Label, Icon16Webinar } from '../../../../../components/UI/icons';

interface IProps {
  type: 'regular' | 'course';
  className?: string;
}

export const ProductTypeLabel = ({ type, className }: IProps) => {
  return (
    <div className={classNames(styles.container, className)}>
      <div className={type}>
        {type === 'regular' ? <Icon16Label className={styles.icon} /> : <Icon16Webinar className={styles.icon} />}
        {type}
      </div>
    </div>
  );
};
