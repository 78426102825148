import { FC, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { useNodePayload } from '../../../hooks/use-node-payload';
import { payloadAutomationSchema, payloadSchema } from './payloadForm/payloadSchema';
import { BuilderType, BuilderTypeEnum } from './store/BuilderActionAsync';

interface IProps {
  builderType: BuilderType;
  nodeId?: string;
}

export const BuilderWrapper: FC<IProps> = ({ builderType, nodeId, children }) => {
  const node = useNodePayload(nodeId);

  const validationSchema = useMemo(() => {
    return builderType === BuilderTypeEnum.automation ? payloadAutomationSchema : payloadSchema;
  }, [builderType]);

  const methods = useForm({
    shouldUnregister: true,
    resolver: yupResolver(validationSchema),
    defaultValues: node?.payload
  });

  process.env.NODE_ENV === 'development' &&
    Object.keys(methods.formState.errors).length &&
    console.log(`${builderType} %validation errors: ⇒`, 'color: #FF5370', methods.formState.errors);
  //@ts-ignore
  return <FormProvider {...methods}>{children}</FormProvider>;
};
