import { useEffect } from 'react';
import { useCallback } from 'react';
import { useCountdown } from 'usehooks-ts';

import styles from './enroll-success-resend.module.scss';

import { Timer } from '../../../../../auth/common/timer/Timer';

interface IProps {
  email: string;
  setSuccessSend: (x: boolean) => void;
}

export const EnrollSuccessResend = ({ email, setSuccessSend }: IProps) => {
  const [count, { startCountdown, stopCountdown, resetCountdown }] = useCountdown({
    countStart: 60,
    intervalMs: 1000
  });

  useEffect(() => {
    startCountdown();
  }, [startCountdown]);

  const stopTimer = useCallback(() => {
    setSuccessSend(false);
    stopCountdown();
  }, [setSuccessSend, stopCountdown]);

  return (
    <div className={styles.container}>
      <div className={styles.title}>Enrollment email successfully sent to</div>
      <div className={styles.email}>{email}</div>
      <div className={styles.description}>
        You will be able to resend it again in &nbsp;{' '}
        <Timer seconds={count} onFinish={stopTimer} className={styles.timer} />
      </div>
      {/* <IconCross className={styles.icon} /> */}
    </div>
  );
};
