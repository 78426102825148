import React, { useEffect } from 'react';
import BootstrapTable, { BootstrapTableProps, TableChangeState, TableChangeType } from 'react-bootstrap-table-next';
import paginationFactory, { PaginationListStandalone, PaginationProvider } from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { InjectedSearchProps } from 'react-bootstrap-table2-toolkit';
import { useSelector } from 'react-redux';

import styles from './pagination.module.scss';

import { arrowButtons, wrapperPageButtonRenderer } from './Pagination';

interface IProps {
  page: number;
  sizePerPage: number;
  onTableChange: <T extends object>(type: TableChangeType, newState: TableChangeState<T>) => void;
  totalSize: number;
  tableProps: BootstrapTableProps;
}

export const RemotePagination = ({ page, sizePerPage, onTableChange, totalSize, tableProps }: IProps) => {
  const options = { custom: true, page, sizePerPage, totalSize };
  const pageButtonRenderer = wrapperPageButtonRenderer(options);
  const searchString = useSelector((state) => state.settings.searchString);

  let tableSearchProps: InjectedSearchProps | null = null;

  useEffect(() => {
    if (tableSearchProps) {
      tableSearchProps.onSearch(searchString.trim());
    }
  }, [searchString, tableSearchProps]);

  return (
    <>
      {tableProps.columns && (
        <ToolkitProvider search columns={tableProps.columns} keyField={'id'} data={tableProps.data}>
          {({ baseProps, searchProps }) => {
            tableSearchProps = searchProps;
            return (
              <PaginationProvider pagination={paginationFactory({ ...options, pageButtonRenderer })}>
                {({ paginationProps, paginationTableProps }) => {
                  return (
                    <>
                      <BootstrapTable
                        {...paginationTableProps}
                        classes={styles.table}
                        remote
                        hover
                        bordered={false}
                        onTableChange={onTableChange}
                        {...baseProps}
                        {...tableProps}
                      />
                      {paginationProps.sizePerPage &&
                      paginationProps.totalSize &&
                      paginationProps.sizePerPage < paginationProps.totalSize ? (
                        <div className="pagination-container">
                          {arrowButtons(paginationProps)}
                          <PaginationListStandalone {...paginationProps} bootstrap4={true} />
                        </div>
                      ) : null}
                    </>
                  );
                }}
              </PaginationProvider>
            );
          }}
        </ToolkitProvider>
      )}
    </>
  );
};
