import React, { useCallback, useEffect, useState } from 'react';
import { ColumnDescription, SelectRowProps, TableChangeState, TableChangeType } from 'react-bootstrap-table-next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import moment from 'moment';

import classNames from 'classnames';
import styles from './enrollment-list.module.scss';
import { Icon32ListView } from '../../../../components/UI/icons';

import { EmptyList } from '../../../../components/empty-list/EmptyList';
import { ItemStatus } from '../../../../components/itemStatus/ItemStatus';
import { Loader } from '../../../../components/UI';
import { RemotePagination } from '../../../../components/UI/pagination/RemotePagination';
import { LoadState } from '../../../../store';
import { coursePaths } from '../../courses/routes/CourseRoutes';
import { IStudentList } from '../../students/store/StudentListState';
import { StudentsActionAsync } from '../../students/store/StudentsActionAsync';
import { enrollmentPaths } from '../routes/EnrollmentRoutes';
import { ListHeader } from './list-header/ListHeader';

export const EnrollmentList = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const searchString = useSelector((state) => state.settings.searchString);
  const { studentsLists, loadState } = useSelector((state) => state.studentsLists);
  const [studentsListsData, setStudentsListsData] = useState<RowType[]>([]);
  const sizePerPage = 25;
  const [listsData, setListsData] = useState<{
    items: RowType[];
    totalSize: number;
    page: number;
    sizePerPage: number;
  }>({
    items: [],
    totalSize: 0,
    page: 1,
    sizePerPage: sizePerPage
  });

  useEffect(() => {
    const listsData = studentsLists.map((list) => ({
      id: list.id,
      lists: list.title,
      date_create: list.date_create,
      students: list.client_count,
      author: `${list.payload.course_author_first_name || '-'} ${list.payload.course_author_last_name || ''}`,
      data: list
    }));
    if (listsData) {
      setStudentsListsData(listsData);
    }
  }, [studentsLists]);

  useEffect(() => {
    setListsData({
      items: paginate(studentsListsData, 1, sizePerPage),
      totalSize: studentsListsData.length,
      page: 1,
      sizePerPage: sizePerPage
    });
  }, [studentsListsData]);

  useEffect(() => {
    dispatch(StudentsActionAsync.getStudentsLists());
  }, [dispatch]);

  const paginate = (array: RowType[], page: number, sizePerPage: number) => {
    return array.slice((page - 1) * sizePerPage, page * sizePerPage);
  };

  const handleTableChange = (type: TableChangeType | 'search', { page, sizePerPage }: TableChangeState<unknown>) => {
    if (type === 'pagination') {
      setListsData({
        items: paginate(studentsListsData, page, sizePerPage),
        totalSize: studentsListsData?.length || 0,
        page,
        sizePerPage
      });
    }
    if (type === 'search') {
      const result = studentsListsData.filter((row) => {
        const searchColumns = ['lists', 'author'];
        let valid = true;
        for (const dataField in searchColumns) {
          valid = row[searchColumns[dataField]].toString().toLowerCase().indexOf(searchString.toLowerCase()) > -1;
          if (valid) break;
        }
        return valid;
      });
      setListsData({
        items: paginate(result, 1, sizePerPage),
        totalSize: result?.length || 0,
        page: 1,
        sizePerPage
      });
    }
  };

  const listsColumnFormatter = useCallback((cell: string, row: RowType) => {
    return (
      <div className={'d-flex justify-content-between align-items-center'}>
        <Link className={styles.title} to={enrollmentPaths.studentListId(row.id)}>
          {cell}
        </Link>
        <ItemStatus label={row.data.payload.course_status || 'draft'} />
      </div>
    );
  }, []);

  const authorColumnFormatter = useCallback((cell: string, row: RowType) => {
    return `${row.data.payload.course_author_first_name || '-'} ${row.data.payload.course_author_last_name || ''}`;
  }, []);

  const columnsData: ColumnDescription<RowType>[] = fields.map((field) => {
    if (field.dataField === 'lists') {
      return {
        ...field,
        formatter: listsColumnFormatter,
        classes: classNames(field.classes, styles.lists),
        headerClasses: classNames(field.classes, styles.lists)
      };
    }
    if (field.dataField === 'date_create') {
      return {
        ...field,
        formatter: (cell) => (cell ? moment(cell).format('DD MMMM YYYY') : '-')
      };
    }
    if (field.dataField === 'author') {
      return {
        ...field,
        formatter: authorColumnFormatter
      };
    }
    return field;
  });

  const toCourseList = () => {
    history.push(coursePaths.courseList);
  };

  return (
    <>
      {loadState === LoadState.firstLoad && <Loader />}
      {loadState === LoadState.allIsLoaded && studentsLists.length > 0 && (
        <>
          <ListHeader />
          <RemotePagination
            page={listsData.page}
            sizePerPage={listsData.sizePerPage}
            totalSize={listsData.totalSize}
            onTableChange={handleTableChange}
            tableProps={{
              keyField: 'id',
              columns: columnsData,
              selectRow: selectRow,
              data: listsData.items
            }}
          />
        </>
      )}
      {loadState === LoadState.allIsLoaded && studentsLists.length === 0 && (
        <EmptyList
          onClick={toCourseList}
          button={'Go to course list'}
          buttonType={'arrow-right'}
          title={'No enrollment lists'}
          description={'You need to create a course to create an enrollment list.'}
          icon={<Icon32ListView width={64} height={64} />}
        />
      )}
    </>
  );
};

const fields: ColumnDescription<RowType, IStudentList>[] = [
  {
    dataField: 'id',
    text: 'Id',
    classes: 'col-1',
    headerClasses: 'col-1'
  },
  {
    dataField: 'lists',
    text: 'Course title',
    classes: 'col',
    headerClasses: 'col'
  },
  {
    dataField: 'date_create',
    text: 'Created On',
    classes: 'col-2',
    headerClasses: 'col-2',
    // filter: textFilter(),
    searchable: true
  },
  {
    dataField: 'students',
    text: 'Students',
    classes: 'col-2',
    headerClasses: 'col-2'
  },
  {
    dataField: 'author',
    text: 'Author',
    classes: 'col-2',
    headerClasses: 'col-2'
  }
];

const selectRow: SelectRowProps<any> = {
  mode: 'radio',
  clickToSelect: true,
  hideSelectColumn: true,
  classes: styles.selectedRow
};

type RowType = Record<string, any> & {
  id: string;
  lists: string;
  date_create: number;
  students: number;
  author: string;
  data: IStudentList;
};
