import { memo, ReactNode } from 'react';

import classNames from 'classnames';
import commonStyles from '../../main/setting.module.scss';
import styles from './payment-method.module.scss';
import { IconLinkDetach, IconNewWindow, IconReload } from '../../../../components/UI/icons';

import { Button } from '../../../../components/UI';
import { PaymentStatus } from '../../general/store/GeneralSettingsState';
import { PaymentState } from '../paymentState/PaymentState';

interface IProps {
  state: PaymentStatus;
  src: ReactNode;
  title: string;
  displayName: string | null;
  description: string;
  onConnectExistingAccount: () => void;
  onDisconnect: () => void;
  imgClassName?: string;
  integrationSystem: 'paynet' | 'stripe' | 'paypal';
}

export const PaymentMethod = memo(
  ({
    title,
    state,
    displayName,
    description,
    src,
    onConnectExistingAccount,
    onDisconnect,
    imgClassName,
    integrationSystem
  }: IProps) => {
    let btnText = 'Connect';
    switch (state) {
      case 'incomplete':
        btnText = 'Complete integration';
        break;
      case 'in_pending':
        btnText = 'Pending reasons';
        break;
      case 'rejected':
        btnText = 'Rejection reasons';
        break;
      case 'done':
        btnText = 'Disconnect';
        break;
      default:
        break;
    }
    return (
      <div className={commonStyles.integrationItem}>
        <div className={'d-flex align-items-center'}>
          <div className={classNames(styles.imgContainer, imgClassName)}>{src}</div>
          <div className={styles.containerText}>
            <div className={styles.title}>{title}</div>
            <PaymentState state={state} display_name={displayName} description={description} />
          </div>
        </div>
        {state === 'non_integrated' && (
          // <PaymentConnectMethod onConnect={onConnectStripe} onConnectExisting={onConnectExistingAccount} />
          <Button
            onClick={onConnectExistingAccount}
            customIcon={<IconNewWindow />}
            btnStyle={'secondary'}
            withIcon={false}
            size={'sm'}
          >
            {btnText}
          </Button>
        )}
        {state === 'incomplete' && (
          <Button onClick={onConnectExistingAccount} customIcon={<IconNewWindow />} size={'sm'}>
            {btnText}
          </Button>
        )}
        {state === 'in_pending' && (
          <Button onClick={onConnectExistingAccount} customIcon={<IconNewWindow />} size={'sm'}>
            {btnText}
          </Button>
        )}
        {state === 'rejected' && (
          <Button onClick={onConnectExistingAccount} customIcon={<IconNewWindow />} size={'sm'}>
            {btnText}
          </Button>
        )}
        {state === 'done' && (
          <div className={'d-flex'}>
            {integrationSystem === 'stripe' && (
              <Button
                onClick={onConnectExistingAccount}
                customIcon={<IconReload className={'ml-1'} />}
                btnStyle={'outline'}
                className={'mr-2'}
                size={'sm'}
              >
                Change account
              </Button>
            )}
            <Button
              onClick={onDisconnect}
              customIcon={<IconLinkDetach className={'ml-1'} />}
              btnStyle={'secondary'}
              size={'sm'}
            >
              {btnText}
            </Button>
          </div>
        )}
      </div>
    );
  }
);
