import styles from './status-sent.module.scss';
import { Icon32CheckMark } from '../../../../../../components/UI/icons';

interface IProps {
  email: string;
}

export const SuccessSent = ({ email }: IProps) => {
  return (
    <div className={styles.container}>
      <Icon32CheckMark className={styles.checkmarkIcon} />
      <div className={styles.successDescription}>The email successfully sent to:</div>
      <div className={styles.successText}>{email}</div>
    </div>
  );
};
