import { reducerWithInitialState } from 'typescript-fsa-reducers';

import { commonErrorHandler, commonFirstLoadHandler } from '../../../../helpers/common-reducer-handlers';
import { LoadState, newState } from '../../../../store';
import { domainActions } from './DomainSettingsActions';
import { DomainSettingsInitialState, IDomainSettingsState } from './DomainSettingsState';

export const domainReducer = reducerWithInitialState<IDomainSettingsState>(DomainSettingsInitialState)
  .case(domainActions.getDomains.started, commonFirstLoadHandler)
  .case(domainActions.getDomains.done, (state, { result }) => {
    return newState(state, {
      domains: result,
      error: null,
      loadState: LoadState.allIsLoaded
    });
  })
  .case(domainActions.getDomains.failed, commonErrorHandler)
  // .case(domainActions.createStudentDomain.started, commonFirstLoadHandler)
  // .case(domainActions.createStudentDomain.done, (state, { result }) => {
  //   return newState(state, {
  //     domains: [...state.domains, result],
  //     error: null,
  //     loadState: LoadState.allIsLoaded
  //   });
  // })
  .case(domainActions.createStudentDomain.failed, commonErrorHandler)
  .case(domainActions.checkDomainStatus.started, (state) => {
    return newState(state, {
      ...state,
      loadState: LoadState.needLoad
    });
  })
  .case(domainActions.checkDomainStatus.done, (state, { result }) => {
    const newDomains = state.domains.map((x) => (x.domain === result.domain ? result : x));
    return newState(state, {
      domains: newDomains,
      error: null,
      loadState: LoadState.allIsLoaded
    });
  })
  .case(domainActions.checkDomainStatus.failed, commonErrorHandler)
  .case(domainActions.editStudentDomain.started, (state) => {
    return newState(state, {
      ...state,
      loadState: LoadState.idle
    });
  })
  .case(domainActions.editStudentDomain.done, (state, { result }) => {
    const newDomains = state.domains.map((domain) => (domain.id === result.id ? result : domain));
    return newState(state, {
      ...state,
      domains: newDomains,
      error: null,
      loadState: LoadState.allIsLoaded
    });
  })
  .case(domainActions.editStudentDomain.failed, commonErrorHandler)
  .case(domainActions.removeStudentDomain.done, (state, { params }) => {
    const newDomains = state.domains.filter((domain) => domain.id !== params);
    return newState(state, {
      ...state,
      domains: newDomains,
      error: null,
      loadState: LoadState.allIsLoaded
    });
  })
  .case(domainActions.removeStudentDomain.failed, commonErrorHandler);
