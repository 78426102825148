import { IError, IMessage, IMeta, ISender } from '../../../../interfaces';
import { LoadState } from '../../../../store';

export interface IEmailState<E = Error> extends IMeta {
  error: E | null;
  loadState: LoadState;
  senders: ISender[];
  messages: IMessage[];
}

export const EmailInitialState: IEmailState<IError> = {
  error: null,
  loadState: LoadState.firstLoad,
  senders: [],
  messages: [],
  page: 1,
  page_size: 18,
  total: 18,
  total_pages: 1
};
