import React, { CSSProperties, SyntheticEvent } from 'react';

import classNames from 'classnames';
import styles from './events-popup.module.scss';
import { IconCross } from '../../../../components/UI/icons';

import palette from '../../../../components/config/paletteAutomation.json';
import { ElementThumb } from '../../../../components/illustrations/ElementThumb';
import {
  ElementAutomationType,
  PaletteAutomationElementNames,
  PaletteAutomationElementTypes
} from '../../../../interfaces';

const paletteElements = palette.palette.category;

interface IProps {
  params?: DOMRect;

  onClose(): void;

  onSelect(e: SyntheticEvent): void;
}

export const EventsPopup = ({ onClose, onSelect, params }: IProps) => {
  const elements = paletteElements['events'] as { name: ElementAutomationType; available: boolean }[];

  const style: Partial<CSSProperties> = {
    left: `calc(${params?.right}px + 1rem)`,
    top: `calc(${params?.top}px + 6px`,
    maxHeight: `calc(100vh - ${params?.top}px - 1rem)`
  };

  return (
    <div className={classNames('card palette-popup', styles.popup)} style={style}>
      <div className="scrollable-container">
        <button type="button" className="btn p-0 close" onClick={onClose}>
          <IconCross />
        </button>
        <h3 className={classNames('title', styles.title)}>{'Events'}</h3>
        <div className={styles.paletteElements}>
          {elements &&
            elements.map((element: { name: ElementAutomationType; available: boolean }, index: number) => {
              if (element.available) {
                const type = PaletteAutomationElementTypes[element.name];
                const title = PaletteAutomationElementNames[element.name];
                return (
                  <div
                    key={index}
                    className={classNames(styles.paletteElement, 'no-draggable')}
                    id={PaletteAutomationElementTypes[element.name]}
                    onClick={onSelect}
                  >
                    <div className="element-preview">
                      <ElementThumb name={type} type="thumb" builderType={'automation'} />
                    </div>
                    <div className={styles.elementName}>{title}</div>
                  </div>
                );
              }
              return null;
            })}
        </div>
      </div>
    </div>
  );
};
