import { memo } from 'react';

import classNames from 'classnames';
import styles from './error-offer.module.scss';
import { IconInfo, IconWarning } from '../../../../../components/UI/icons';

import { InfoMessage } from '../../../../../components/UI/infoMessage/InfoMessage';

interface IProps {
  text: string | JSX.Element;
  show: boolean;
  type: 'info' | 'error';
  className?: string;
}

export const ErrorOffer = memo(({ show, text, type, className }: IProps) => {
  let Icon =
    type === 'error' ? (
      <IconWarning className={classNames(styles.icon, styles.error)} />
    ) : (
      <IconInfo className={classNames(styles.icon, styles.info)} />
    );

  return (
    <InfoMessage
      show={show}
      text={text}
      customIcon={Icon}
      className={classNames(
        styles.container,
        { [styles.info]: type === 'info' },
        { [styles.error]: type === 'error' },
        className
      )}
      textClassName={styles.text}
      type={type}
    />
  );
});
