import { useDispatch } from 'react-redux';
import { AttachmentType } from 'src/app/interfaces/quiz';
import { fileUploadActionAsync } from 'src/app/layout/shared-components/upload-worker/store/FileUploadActionAsync';

import { MediaProvider } from '../../../API/video-api';
import { IFile } from '../../../interfaces';
import { fileUploaderActions } from '../../../layout/shared-components/upload-worker/store/FileUploaderActions';
import { IFileUploader } from '../../../layout/shared-components/upload-worker/store/FileUploaderState';
import { fileUrl } from '../file-helper';

export const useFileUpload = (courseId?: string, blockId?: string) => {
  const dispatch = useDispatch();

  const onSetAudioUpload = (file: File, link: string) => {
    if (courseId && blockId) {
      dispatch(
        fileUploaderActions.setUploadFiles([
          {
            courseId: courseId,
            file,
            progress: 0,
            parentBlockId: blockId,
            uploaderData: {
              id: link,
              provider: MediaProvider.FILE,
              status: 'in_progress'
            }
          }
        ])
      );
    }
  };

  const onProgressUpload = (event: any, file: File, link: string) => {
    if (courseId && blockId && event) {
      dispatch(
        fileUploaderActions.updateUploadFile({
          courseId: courseId,
          file,
          progress: Number(((event.loaded * 100) / event.total).toFixed(2)),
          parentBlockId: blockId,
          uploaderData: {
            id: link,
            provider: MediaProvider.FILE,
            status: event.loaded === event.total ? 'complete' : 'in_progress'
          }
        })
      );
      if (event.loaded === event.total) {
        dispatch(
          fileUploaderActions.setTranscodingStatus({
            file,
            status: {
              progress: 100,
              time_left: 0,
              state: 'ready'
            }
          })
        );
      }
    }
  };

  const getRetrieveUrl = (
    file: File,
    getRetrieveUrlCallback: (data: IFile & { presigned_url: string }) => void,
    type: AttachmentType
  ) => {
    dispatch(
      fileUploadActionAsync.retrieveUrl(
        file,
        (data) => {
          getRetrieveUrlCallback(data);
        },
        type
      )
    );
  };

  const uploadFileByPresignedUrl = (file: File, presigned_url: string, link: string, uploadCallback?: () => void) => {
    dispatch(
      fileUploadActionAsync.uploadFile(
        {
          file,
          presigned_url: presigned_url
        },
        (event) => onProgressUpload(event, file, link),
        uploadCallback
      )
    );
  };

  const retrieveUrlFileAndUpload = (file: File, retrieveCallback?: (link: string) => void, type?: AttachmentType) => {
    dispatch(
      fileUploadActionAsync.retrieveUrl(
        file,
        (data) => {
          if (data) {
            const link = fileUrl(data);
            onSetAudioUpload(file, link);
            retrieveCallback?.(link);
            dispatch(
              fileUploadActionAsync.uploadFile(
                {
                  file,
                  presigned_url: data.presigned_url
                },
                (event) => onProgressUpload(event, file, link),
                undefined
              )
            );
          }
        },
        type
      )
    );
  };

  const onCancelUpload = (vimeoUploadedVideo: IFileUploader, cancelCallback?: () => void) => {
    if (vimeoUploadedVideo) {
      dispatch(
        fileUploaderActions.updateUploadFile({
          ...vimeoUploadedVideo,
          uploaderData: {
            id: vimeoUploadedVideo?.uploaderData.id,
            provider: vimeoUploadedVideo?.uploaderData.provider,
            status: 'canceled'
          }
        })
      );

      vimeoUploadedVideo?.cancelToken?.cancel();
      cancelCallback?.();
    }
  };

  return {
    retrieveUrlFileAndUpload,
    onCancelUpload,
    uploadFileByPresignedUrl,
    getRetrieveUrl
  };
};
