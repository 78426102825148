import { components } from 'react-select';
import { map } from 'lodash';

import classNames from 'classnames';
import styles from './product-search.module.scss';
import { IconSearch } from '../UI/icons';

import { IOptionCourse } from '../../modules/crm/offers/create-offer/actions/enroll/ActionEnrollCourse';
import { ProductBadge } from '../../modules/crm/offers/create-offer/common/product-badge/ProductBadge';
import { IOptionProduct } from '../../modules/crm/offers/create-offer/common/search/ProductSearch';

export const SearchContainer = (props: any) => {
  return (
    components.ValueContainer && (
      <components.ValueContainer {...props}>
        {!!props.children && (
          <div className={styles.iconContainer}>
            <IconSearch className={styles.icon} />
          </div>
        )}
        <span className={'w-100 d-flex pl-3 search'}>{props.children}</span>
      </components.ValueContainer>
    )
  );
};

export const formatCourseLabel = ({ label }: IOptionCourse) => {
  return (
    <div className={'d-flex justify-content-between w-100'}>
      <span>
        <span className={styles.label}>{label}&nbsp;</span>
      </span>
    </div>
  );
};

export const formatLabel = ({ label, value, product }: IOptionProduct) => {
  const price = map(product.fields, (field) => Object.values<any>(field).map((x) => x?.amount));

  return (
    <div className={'d-flex justify-content-between w-100'}>
      <span>
        <span className={styles.label}>{label}&nbsp;</span>
        {price && product.entity === 'product' && <span className={styles.value}>{price}$</span>}
      </span>
      <span className={styles.wrapper}>
        <ProductBadge element={product.entity} className={styles.iconWrapper}>
          {product.entity === 'product' ? 'Regular' : 'Course'}
        </ProductBadge>
      </span>
    </div>
  );
};

export const NoOptionsMessage = (props: any) => {
  return (
    <div className={`${props.selectProps.classNamePrefix}__no-option-message`}>
      <div className={classNames('d-flex align-items-center justify-content-center', styles.noOptionText)}>
        {props.selectProps.inputValue !== '' && '"' + props.selectProps.inputValue + '" product not found'}
        {props.selectProps.inputValue === '' && 'You have no created products'}
      </div>
    </div>
  );
};

export const NoOptionsMessageCourse = (props: any) => {
  return (
    <div className={`${props.selectProps.classNamePrefix}__no-option-message`}>
      <div className={classNames('d-flex align-items-center justify-content-center', styles.noOptionCourse)}>
        You have no created or published courses
      </div>
    </div>
  );
};

export const LoadingMessage = (props: any) => {
  return (
    <div className={`${props.selectProps.classNamePrefix}__no-option-message`}>
      <div className={'d-flex align-items-center justify-content-center'}>{props.children}</div>
    </div>
  );
};
