import { memo } from 'react';
import { useDispatch } from 'react-redux';

import { useCourseContext } from '../../../../../../modules/lms/courses/common/sidebar/CourseContext';
import { courseActionAsync } from '../../../../../../modules/lms/courses/store/CourseActionAsync';
import { NodeCondition } from '../../../../../../modules/lms/courses/store/CourseState';
import { Button } from '../../../../../UI';

interface IProps {
  setSelectedOption: (value: NodeCondition) => void;
  onClose: () => void;
  nodeId: string;
  condition: NodeCondition;
}

export const ButtonEdit = memo(({ nodeId, condition, setSelectedOption, onClose }: IProps) => {
  const { courseId, getHierarchy } = useCourseContext();
  const dispatch = useDispatch();

  const onCancel = () => {
    setSelectedOption(null);
  };

  const onUpdate = () => {
    if (courseId) {
      dispatch(courseActionAsync.setNodeCondition(courseId, nodeId, condition, getHierarchy));
      onClose();
    }
  };

  return (
    <div className={'d-flex justify-content-end mt-4'}>
      <Button btnStyle={'transparent'} onClick={onCancel} withIcon={false}>
        Cancel
      </Button>
      <Button withIcon={false} onClick={onUpdate} className={'ml-2'}>
        Update lesson
      </Button>
    </div>
  );
});
