import axios from 'axios';
import { reducerWithInitialState } from 'typescript-fsa-reducers';

import { newState } from '../../../../store';
import { fileUploaderActions } from './FileUploaderActions';
import { FileUploaderInitialState, IFileUploader, IFileUploaderState } from './FileUploaderState';

const setCancelationForFiles = (items: IFileUploader[]) => {
  return items.map((element) => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    return { ...element, cancelToken: source };
  });
};
export const FileUploaderReducer = reducerWithInitialState<IFileUploaderState>(FileUploaderInitialState)
  .case(fileUploaderActions.setUploadFiles, (state, result) => {
    return newState(state, {
      files: [...state.files, ...result]
    });
  })
  .case(fileUploaderActions.updateUploadFile, (state, result) => {
    const updateFiles = state.files.map((file) =>
      file.uploaderData.id === result.uploaderData.id ? { ...file, ...result } : file
    );
    return newState(state, {
      ...state,
      files: updateFiles
    });
  })
  .case(fileUploaderActions.uploadFile.started, (state, result) => {
    const uploadFiles = state.files.map((file) =>
      file.file === result.file ? { ...file, cancelToken: result.cancelSource } : file
    );
    return newState(state, { ...state, files: uploadFiles });
  })
  .case(fileUploaderActions.retrieveUrlAndUploadFile.started, (state, result) => {
    const uploadFiles = state.files.map((file) =>
      file.file === result.file ? { ...file, cancelToken: result.cancelSource } : file
    );
    return newState(state, { ...state, files: uploadFiles });
  })
  .case(fileUploaderActions.clearUploadedFiles, (state) => newState(state, { files: [] }))
  .case(fileUploaderActions.setShowUploader, (state, result) =>
    newState(state, {
      ...state,
      isShowUploader: result.show
    })
  )
  .case(fileUploaderActions.setTranscodingStatus, (state, result) => {
    const updateFiles = state.files.map((file) =>
      file.file === result.file ? { ...file, transcodingStatus: result.status } : file
    );

    return newState(state, {
      ...state,
      files: updateFiles
    });
  })
  .case(fileUploaderActions.cancelAllUploading, (state, result) => {
    return newState(state, {
      ...state,
      files: state.files.map((file) =>
        result.includes(file) ? { ...file, uploaderData: { ...file.uploaderData, status: 'canceled' } } : file
      )
    });
  });
