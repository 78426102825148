import { memo, SyntheticEvent } from 'react';

import classNames from 'classnames';
import styles from './enrollment-element.module.scss';
import { IconCheckMark } from '../../../../../components/UI/icons';

import { ItemStatus } from '../../../../../components/itemStatus/ItemStatus';
import { ThumbnailPlaceholderImage } from '../../../../../components/UI/image-components/thumbnail-placeholder-image/ThumbnailPlaceholderImage';
import { IFile } from '../../../../../interfaces';
import { CourseState } from '../../../courses/store/CourseState';

interface IProps {
  id: string;
  title: string;
  active: boolean;
  selected?: boolean;
  thumbnail?: IFile | null;
  state: CourseState;

  onClick(e: SyntheticEvent<HTMLDivElement>): void;
}

export const EnrollmentElement = memo(({ id, title, onClick, active, selected, state, thumbnail }: IProps) => {
  return (
    <>
      <div
        className={classNames(styles.container, {
          [styles.active]: active,
          [styles.selected]: selected && !active,
          [styles.hide]: state !== 'published'
        })}
        onClick={active || state !== 'published' ? () => {} : onClick}
        data-id={id}
      >
        <div className={styles.imgContainer}>
          <ThumbnailPlaceholderImage width={96} height={54} thumbnailCourse={thumbnail || null} />
          <div className={styles.iconCheckmark}>
            <IconCheckMark />
          </div>
        </div>
        <div className={classNames(styles.text)}>
          <span className={classNames(styles.title, 'ellipsis')}>{title}</span>
          <div className={styles.statusContainer}>
            <ItemStatus label={state} className={styles.customSize} />
          </div>
        </div>
      </div>
    </>
  );
});
