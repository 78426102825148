import { FormControl } from 'react-bootstrap';
import { useFormContext, useWatch } from 'react-hook-form';

import classNames from 'classnames';
import styles from '../products.module.scss';

import { setEmptyOrStr } from '../../../../../../helpers/text';
import { InputLabel } from '../../common/input-label/InputLabel';

export const ProductArea = () => {
  const { register, control } = useFormContext();
  const textAreaData = useWatch({ control, name: 'description' });
  return (
    <InputLabel label={'Describe your offer'} classContainer={'mt-4'}>
      <FormControl
        placeholder={'Enter your description'}
        {...register('description', { setValueAs: setEmptyOrStr })}
        className={classNames('form-control form-control-md', styles.textarea)}
        type={'text'}
        maxLength={360}
        as={'textarea'}
        rows={3}
      />
      <div className={styles.length}>{textAreaData?.length || 0}/360</div>
    </InputLabel>
  );
};
