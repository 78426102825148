import { CustomDispatch } from 'redux';
import { AxiosResponse } from 'axios';

import {
  createOffer,
  editOffer,
  editOfferState,
  getOffer,
  getOfferProducts,
  getOffers
} from '../../../../API/offer-api';
import { IPaginationParams } from '../../../../interfaces';
import { OffersActions } from './OffersActions';
import { IOffer, IOfferState, OfferStatusResponse } from './OffersState';

export const OffersActionAsync = {
  /**
   * Organization Offer List
   * @param pagination
   * @param state
   * @param courseId
   * @param exclusiveForCourse Only offers that are exclusive for the course
   * @param callback Callback after getting Offer List
   */
  getOffers:
    (
      pagination?: IPaginationParams,
      state?: IOfferState,
      courseId?: string,
      exclusiveForCourse?: boolean,
      callback?: () => void
    ) =>
    async (dispatch: CustomDispatch) => {
      try {
        dispatch(
          OffersActions.getOffers.started({
            state
          })
        );
        let response;
        response = await getOffers(pagination, state, courseId, exclusiveForCourse);
        if (response && response.data && response.meta) {
          dispatch(
            OffersActions.getOffers.done({
              result: { data: response.data, meta: response.meta },
              params: { state }
            })
          );
          callback?.();
        }
      } catch (error) {
        dispatch(OffersActions.getOffers.failed({ error, params: { state } }));
      }
    },
  getOffer: (id: string) => async (dispatch: CustomDispatch) => {
    try {
      dispatch(OffersActions.getOffer.started({ id }));
      const response = await getOffer(id);
      if (response.data) {
        dispatch(OffersActions.getOffer.done({ params: { id }, result: response.data }));
      }
    } catch (error) {
      dispatch(OffersActions.getOffer.failed({ params: { id }, error }));
    }
  },
  getOfferProducts: (pagination?: IPaginationParams, query?: string) => async (dispatch: CustomDispatch) => {
    try {
      dispatch(OffersActions.getProductsOffer.started());
      const response = await getOfferProducts(pagination, query);
      if (response && response.data && response.meta) {
        dispatch(OffersActions.getProductsOffer.done({ result: { data: response.data, meta: response.meta } }));
      }
    } catch (error) {
      dispatch(OffersActions.getProductsOffer.failed({ error }));
    }
  },
  createOffer: (courseId?: string, callback?: (id: string) => void) => async (dispatch: CustomDispatch) => {
    try {
      dispatch(OffersActions.createOffer.started(courseId));
      const response = await createOffer(courseId);
      if (response && response.data !== null) {
        dispatch(
          OffersActions.createOffer.done({
            params: courseId,
            result: response.data
          })
        );
        if (typeof callback === 'function') {
          callback(response.data.id);
        }
      }
    } catch (error) {
      dispatch(OffersActions.createOffer.failed({ error, params: courseId }));
    }
  },
  editOffer: (id: string, offer: Partial<IOffer>, callback?: () => void) => async (dispatch: CustomDispatch) => {
    try {
      dispatch(OffersActions.editOffer.started({ id, offer }));
      const response = await editOffer(id, offer);
      if (response.data) {
        dispatch(OffersActions.editOffer.done({ params: { id, offer }, result: response.data }));
        callback?.();
      }
    } catch (error) {
      dispatch(OffersActions.editOffer.failed({ params: { id, offer }, error }));
    }
  },
  editState:
    (
      id: string,
      state: IOfferState,
      filtered: boolean,
      callbackSuccess?: () => void,
      callbackError?: (error: AxiosResponse<OfferStatusResponse>) => void
    ) =>
    async (dispatch: CustomDispatch) => {
      try {
        dispatch(OffersActions.editState.started({ id, state, filtered }));
        const response = await editOfferState(id, state);
        if (response.data) {
          dispatch(
            OffersActions.editState.done({
              result: response.data,
              params: { id, state, filtered }
            })
          );
          callbackSuccess?.();
        }
      } catch (error) {
        callbackError?.(error);
        dispatch(OffersActions.editState.failed({ error, params: { id, state, filtered } }));
      }
    }
};
