import classNames from 'classnames';
import styles from './course-tag.module.scss';

import { courseCategoryList } from '../../../modules/lms/courses/settings/course-category/CourseCategory';
import { CourseCategoriesType } from '../../../modules/lms/courses/store/CourseState';

interface IProps {
  category?: CourseCategoriesType | null;
  className?: string;
}

export const CourseTag = ({ category, className }: IProps) => {
  return category ? (
    <div className={classNames(styles.tag, className)}>
      <span className={styles.icon}>{courseCategoryList[category].icon}</span>
      {courseCategoryList[category].name}
    </div>
  ) : null;
};
