import React from 'react';
import { FormCheck, FormControlProps } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';

import './toggle-field.scss';

import { IField } from '../../../../modules/crm/field';

interface IProps {
  field: IField;
  params?: FormControlProps;
}

export const ToggleField = ({ field }: IProps) => {
  const { register } = useFormContext();

  return (
    <FormCheck className="custom-control custom-switch custom-switch-sm my-1" custom type={'switch'}>
      <FormCheck.Input
        type="checkbox"
        className="custom-control-input"
        id={field.id}
        {...register(field.id)} />
      <FormCheck.Label className="custom-control-label" htmlFor={field.id}>
        {/*Default Checked Switch*/}
      </FormCheck.Label>
    </FormCheck>
  );
};
