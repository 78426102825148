import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { settingsActions } from '../store/settings';

export const usePayload = () => {
  const dispatch = useDispatch();
  const { isPayloadShow } = useSelector((state) => state.settings);

  const showPayload = useCallback(() => {
    dispatch(settingsActions.setPayloadShowing({ isPayloadShow: true }));
  }, [dispatch]);

  const hidePayload = useCallback(() => {
    dispatch(settingsActions.setPayloadShowing({ isPayloadShow: false }));
  }, [dispatch]);

  useEffect(() => {
    return () => {
      if (isPayloadShow) {
        hidePayload();
      }
    };
  }, [hidePayload, isPayloadShow]);

  return { isPayloadShow, showPayload, hidePayload };
};
