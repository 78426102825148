import { fileUrl, formatBytes } from 'src/app/components/file-upload/file-helper';
import { downloadFile } from 'src/app/helpers/dowload';
import { ICourseBlockAttachmentFiles } from 'src/app/interfaces';
import { IconFile } from 'src/app/layout/shared-components/upload-worker/upload-item/icon-file/IconFile';

import styles from './file-student.module.scss';
import { IconDownload } from 'src/app/components/UI/icons';

type Props = {
  block?: ICourseBlockAttachmentFiles;
};

export const FileStudent = ({ block }: Props) => {
  const onDownload = (link: string, fileName: string) => {
    downloadFile(fileName, link);
  };
  return (
    <div className={styles.wrapper}>
      {block?.attachments?.map((item) => (
        <div className={styles.container} key={item.object_name}>
          <IconFile uploadStatus={'complete'} fileType={item.file_type} />
          <div className={styles.textContainer}>
            <div className={styles.title}>{item.file_name}</div>
            <div className={styles.size}>
              <div className={styles.description}>
                {formatBytes(Number(item.file_size), 0).value} {formatBytes(Number(item.file_size), 0).text}
              </div>
            </div>
          </div>
          <div
            className={styles.downloadContainer}
            onClick={() =>
              onDownload(fileUrl({ object_name: item.object_name, bucket_name: item.bucket_name }), item.file_name)
            }
          >
            <p className={styles.text}>Download</p>
            <IconDownload className={styles.icon} />
          </div>
        </div>
      ))}
    </div>
  );
};
