import { memo, useCallback } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import { fileUploadActionAsync } from 'src/app/layout/shared-components/upload-worker/store/FileUploadActionAsync';
import { yupResolver } from '@hookform/resolvers/yup';

import classNames from 'classnames';
import commonStyles from '../main/setting.module.scss';
import styles from './account.module.scss';
import { IconInfo } from '../../../components/UI/icons';

import { IProfileAccount } from '../../../interfaces';
import { ChangePassword } from '../common/change-password/ChangePassword';
import { HelperWrapper } from '../common/help/HelperWrapper';
import { SettingInput } from '../common/input/SettingInput';
import SettingsActions from '../common/settings-actions/SettingsActions';
import { SettingsHeader } from '../SettingsHeader';
import { accountSchema } from './accountSchema';
import { AccountActionAsync } from './store/AccountActionAsync';

export const Account = memo(() => {
  const dispatch = useDispatch();
  const { account } = useSelector((state) => state.account);
  const canChangePassword = account.providers.some((provider) => provider.provider === 'email');
  const methods = useForm<IProfileAccount>({ resolver: yupResolver(accountSchema) });
  methods.register('photo');

  const onCancel = useCallback(() => {
    methods.reset(account.profile);
  }, [account.profile, methods]);

  const onSave = useCallback(
    async (data: IProfileAccount) => {
      if (data.photo) {
        await dispatch(
          fileUploadActionAsync.retrieveUrlAndUploadFile(data.photo as File, (result) => {
            data.photo = result;
          })
        );
      }
      dispatch(AccountActionAsync.editMe(data));
      methods.reset();
    },
    [dispatch, methods]
  );

  return (
    <FormProvider {...methods}>
      <form autoComplete="off" className={styles.formWrapper}>
        <SettingsHeader title={'Account'} />
        <HelperWrapper>
          <div className={classNames(commonStyles.contentContainer, styles.container)}>
            <div className={'d-flex'}>
              <SettingInput label={'First name'} name={'first_name'} defaultValue={account.profile.first_name} />
              <SettingInput label={'Last name'} name={'last_name'} defaultValue={account.profile.last_name} />
            </div>
            <div className={'d-flex mt-4 mb-5'}>
              <SettingInput label={'Personal e-mail'} name={'email'} defaultValue={account.profile.email} />
              <SettingInput label={'Personal phone number'} name={'phone'} defaultValue={account.profile.phone} />
            </div>
            {canChangePassword ? (
              <ChangePassword />
            ) : (
              <div className={styles.info}>
                <IconInfo />
                You signed up with your google account so you can't edit your password.
              </div>
            )}
          </div>
          <CSSTransition in={methods.formState.isDirty} timeout={300} classNames={styles} unmountOnExit>
            <SettingsActions methods={methods} onCancel={onCancel} onSave={onSave} />
          </CSSTransition>
        </HelperWrapper>
      </form>
    </FormProvider>
  );
});
