import React, { memo } from 'react';

import classNames from 'classnames';
import styles from './empty-list.module.scss';
import { IconBulb } from '../UI/icons';

import { Button, ButtonType } from '../UI';

interface IProps {
  icon: JSX.Element;
  title: string | JSX.Element;
  description: string;
  button?: string | false;
  buttonType?: ButtonType;
  hideButtonIcon?: boolean;
  small?: boolean;
  withTemplate?: boolean;
  templateText?: {
    message: string;
    button: string;
  };
  onClick?(): void;
}

export const EmptyList = memo((props: IProps) => {
  const {
    icon,
    title,
    description,
    button = false,
    buttonType,
    hideButtonIcon = false,
    onClick = () => {},
    small = false,
    withTemplate = false,
    templateText
  } = props;
  return (
    <div className={classNames('card', styles.card, { [styles.small]: small })}>
      <div className={classNames('card-body', styles.body)}>
        <div className={styles.icon}>{icon}</div>
        <div className={styles.content}>
          <div className={classNames('card-title mb-2', styles.title, { 'mt-7': !small })}>{title}</div>
          <div className={classNames('card-subtitle', styles.subtitle, small ? 'mb-5' : 'mb-3')}>{description}</div>
          {button && (
            <Button onClick={onClick} withIcon={!small && !hideButtonIcon} type={buttonType}>
              {button}
            </Button>
          )}
        </div>
      </div>
      {!small && withTemplate && (
        <div className={classNames('card-footer d-flex justify-content-between', styles.footer)}>
          <div className="help-note d-flex align-items-center">
            <IconBulb className="mr-2" />
            {templateText ? templateText.message : 'Still not sure where to start? Try using a template'}
          </div>
          <button className="btn btn-gradient-secondary btn-sm" type="button">
            {templateText ? templateText.button : 'Use a template'}
          </button>
        </div>
      )}
    </div>
  );
});
