import { memo, MutableRefObject, useCallback, useEffect, useMemo, useRef } from 'react';
import ReactQuill, { Quill } from 'react-quill';
import { useDispatch } from 'react-redux';
import katex from 'katex';
// @ts-ignore
import mathquill4quill from 'mathquill4quill';
import { Delta, Sources } from 'quill';
import QuillImageDropAndPaste, { ImageData } from 'quill-image-drop-and-paste';
//@ts-ignore
import ImageResize from 'quill-image-resize';
import { fileUploadActionAsync } from 'src/app/layout/shared-components/upload-worker/store/FileUploadActionAsync';

import classNames from 'classnames';
import 'react-quill/dist/quill.bubble.css';
import '../bubble-quill-editor.scss';
import 'katex/dist/katex.css';
import '@edtr-io/mathquill/build/mathquill.css';
import 'mathquill4quill/mathquill4quill.css';
import styles from './simple-quill-editor.module.scss';

import '../jquery';
import '@edtr-io/mathquill/build/mathquill.js';

import { imageUrl } from '../../UI/image-components/image/Image';
import { UnprivilegedEditor } from '../BubbleQuillEditor';
import { customQuillSnowIcons, QuillElementsType } from '../customQuillSnowIcons';
import { insertImageToEditor } from '../helper';
import { Size } from '../modules/CustomFontSize';
import { CUSTOM_OPERATORS, toolbarBubbleSimple, toolbarSnowSimple } from '../toolbarOptions';

//@ts-ignore
window.katex = katex;

let Block = Quill.import('blots/block');
Block.tagName = 'DIV';
Quill.register(Block, true);
Quill.register(Size, true);
Quill.register('modules/imageResize', ImageResize);
Quill.register('modules/imageDropAndPaste', QuillImageDropAndPaste);

const Parchment = Quill.import('parchment');

class IndentAttributor extends Parchment.Attributor.Style {
  add(node: any, value: any) {
    if (value === 0) {
      this.remove(node);
      return true;
    } else {
      return super.add(node, `${value}em`);
    }
  }
}

const IndentStyle = new IndentAttributor();

Quill.register(IndentStyle, true);

const icon = Quill.import('ui/icons');
icon[QuillElementsType.undo] = customQuillSnowIcons.undo;
icon[QuillElementsType.redo] = customQuillSnowIcons.redo;
icon[QuillElementsType.clean] = customQuillSnowIcons.clean;
icon[QuillElementsType.link] = customQuillSnowIcons.link;
icon[QuillElementsType.color] = customQuillSnowIcons.color;
icon[QuillElementsType.background] = customQuillSnowIcons.background;
icon[QuillElementsType.bold] = customQuillSnowIcons.bold;
icon[QuillElementsType.italic] = customQuillSnowIcons.italic;
icon[QuillElementsType.strike] = customQuillSnowIcons.strike;
icon[QuillElementsType.underline] = customQuillSnowIcons.underline;

interface IProps {
  value?: string | Delta;
  options?: {
    placeholder?: string;
    className?: string;
    readOnly?: boolean;
    fixedToolbar?: boolean;
  };
  onChange?(content: string, delta: Delta, source: Sources, editor: UnprivilegedEditor): void;
}

export const SimpleQuillEditor = memo(({ value, onChange, options = {} }: IProps) => {
  const { placeholder, className, readOnly, fixedToolbar } = options;
  const reactQuillRef = useRef<ReactQuill | null>(null);
  const dispatch = useDispatch();

  const onUndo = useCallback(() => {
    const quill = (reactQuillRef as MutableRefObject<ReactQuill | null>).current;
    return (quill?.getEditor() as any)?.history.undo();
  }, []);

  const onRedo = useCallback(() => {
    const quill = (reactQuillRef as MutableRefObject<ReactQuill | null>).current;
    return (quill?.getEditor() as any)?.history.redo();
  }, []);

  const ImageHandler = useCallback(() => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();

    input.onchange = async () => {
      if (input.files) {
        const file = input.files[0];

        // file type is only image.
        if (/^image\//.test(file.type)) {
          await dispatch(
            fileUploadActionAsync.retrieveUrlAndUploadFile(file, (result) => {
              const url = imageUrl({
                bucket_name: result.bucket_name,
                object_name: result.object_name
              });
              insertImageToEditor(reactQuillRef as MutableRefObject<ReactQuill | null>, url);
            })
          );
        } else {
          console.warn('You could only upload images.');
        }
      }
    };
  }, [dispatch, reactQuillRef]);

  const imageHandlerDrop = async (dataUrl: string | ArrayBuffer, type?: string, imageData?: ImageData) => {
    const file = imageData?.toFile();
    if (file && /^image\//.test(file.type)) {
      await dispatch(
        fileUploadActionAsync.retrieveUrlAndUploadFile(file, (result) => {
          const url = imageUrl({
            bucket_name: result.bucket_name,
            object_name: result.object_name
          });
          insertImageToEditor(reactQuillRef as MutableRefObject<ReactQuill | null>, url);
        })
      );
    } else {
      console.warn('You could only upload images.');
    }
  };

  useEffect(() => {
    if ((reactQuillRef as MutableRefObject<ReactQuill | null>) && !readOnly) {
      // @ts-ignore
      if (reactQuillRef?.current?.editor) {
        const enableMathQuillFormulaAuthoring = mathquill4quill({ Quill, katex });
        // @ts-ignore
        enableMathQuillFormulaAuthoring(reactQuillRef?.current?.editor, { operators: CUSTOM_OPERATORS });
      }
    }
  }, [reactQuillRef, readOnly]);

  const modules = useMemo(
    () => ({
      formula: !!fixedToolbar,
      history: {
        delay: 2000,
        maxStack: 500,
        userOnly: true
      },
      toolbar: readOnly
        ? false
        : {
            container: fixedToolbar ? toolbarSnowSimple : toolbarBubbleSimple,
            handlers: {
              image: ImageHandler,
              undo: onUndo,
              redo: onRedo
            }
          },
      imageDropAndPaste: {
        handler: imageHandlerDrop
      },
      imageResize: {
        modules: ['Resize', 'DisplaySize']
      }
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <div
      className={classNames(styles.container, 'simple-editor', fixedToolbar && !readOnly ? 'snow' : 'bubble', {
        [styles.fixed]: fixedToolbar
      })}
    >
      <ReactQuill
        ref={reactQuillRef}
        theme={fixedToolbar ? 'snow' : 'bubble'}
        modules={modules}
        className={classNames(className, { readonly: readOnly })}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        readOnly={readOnly}
      />
    </div>
  );
});
