import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';

import classNames from 'classnames';
import styles from './add-payment-card.module.scss';
import { IconPlus } from '../../../../components/UI/icons';

import { CommonModal } from '../../../../components/UI';
import { AddCardModal } from '../../billing/add-card-modal/AddCardModal';
import { SettingCourseActionAsync } from '../../store/SettingCourseActionAsync';

interface IProps {
  className?: string;
}

export const AddPaymentCard = ({ className }: IProps) => {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [clientSecret, setClientSecret] = useState('');

  const clientSecretCallback = (clientSecret: string) => {
    setClientSecret(clientSecret);
    setShowModal(true);
  };

  const addCreditCard = () => {
    dispatch(SettingCourseActionAsync.getClientSecret(clientSecretCallback));
  };

  const closeModal = useCallback(() => {
    setShowModal(false);
  }, []);

  const onSuccess = useCallback(() => {
    closeModal();
    dispatch(SettingCourseActionAsync.getPaymentCards());
  }, [closeModal, dispatch]);

  return (
    <>
      <div className={classNames(styles.container, className)} onClick={addCreditCard}>
        <div className={styles.addIcon}>
          <IconPlus />
        </div>
        <div>Add credit card</div>
      </div>
      <CommonModal show={showModal} onClose={closeModal}>
        <AddCardModal clientSecret={clientSecret} onClose={closeModal} onSuccess={onSuccess} />
      </CommonModal>
    </>
  );
};
