import { IParams } from '../interfaces';
import { IProductList } from '../modules/crm/products/store/ProductListState';
import { IProduct, IProductField, ProductType } from '../modules/crm/products/store/ProductsState';
import { baseFetch } from './BaseFetch';

export const getProducts = async (type: ProductType, params: IParams) => {
  return await baseFetch<IParams | {}, Array<IProduct>>(`/products/${type}`, { ...params }, 'GET', true);
};

export const getProductDetail = async (id: string, type: ProductType = 'regular') => {
  return await baseFetch<{}, IProduct>(`/products/${type}/${id}`, {}, 'GET', true);
};

export const createProduct = async (fields: IProductField) => {
  return await baseFetch<{ fields: IProductField }, IProduct>('/products/regular', { fields }, 'POST', true);
};

export const editProduct = async (id: string, fields: IProductField) => {
  return await baseFetch<{}, IProduct>(`/contacts/${id}`, { fields }, 'PUT', true);
};

export const deleteProduct = async (id: string) => {
  return await baseFetch<{}, IProduct>(`/contacts/${id}`, {}, 'DELETE', true);
};

export const getProductsConfig = async (type: ProductType = 'regular') => {
  return await baseFetch<{}, IProductList>(`/products/${type}/config`, {}, 'GET', true);
};
