import React, { CSSProperties, memo, useCallback, useEffect, useMemo, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import ReactDOM from 'react-dom';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useLocation } from 'react-router-dom';
import { IImage } from '@demonvv/everact-widget';

import classNames from 'classnames';
import styles from '../../styles/components/sidebar.module.scss';
import { Icon32Funnel, Icon32Gear, Icon32Group, Icon32Home, Icon32Label, IconBriefcase } from '../components/UI/icons';

import { DropMenu } from '../components/UI';
import { imageUrl } from '../components/UI/image-components/image/Image';
import { UserMenu } from '../components/user-menu/UserMenu';
import { RestrictPermission } from '../interfaces/permission';
import { automationPaths } from '../modules/automations/routes/AutomationRoutes';
import { HelpCenter } from '../modules/common/help-center/HelpCenter';
import { CRMPaths } from '../modules/crm/routes/CRMRoutes';
import { SubscriptionModal } from '../modules/dashboard/common/subscribe/SubscriptionModal';
import { funnelPaths } from '../modules/funnels/routes/FunnelRoutes';
import { answersPaths } from '../modules/lms/answers/routes/AnswersRoutes';
import { coursePaths } from '../modules/lms/courses/routes/CourseRoutes';
import { enrollmentPaths } from '../modules/lms/enrollment/routes/EnrollmentRoutes';
import { studentPaths } from '../modules/lms/students/routes/StudentRoutes';
import { mainPaths } from '../modules/MainRoutes';
import { BillingPermissionSelector } from '../modules/settings/billing/store';
import { OrganizationActionAsync } from '../modules/settings/organization/store/OrganizationActionAsync';
import { DropMenuItem } from './shared-components/drop-menu-item/DropMenuItem';

type SidebarType = { name: string; icon: JSX.Element; to: string; permission?: RestrictPermission; hidden?: boolean }[];
const isPROD = process.env.REACT_APP_ENV === 'production';

const sidebarMenu: SidebarType = [
  { name: 'Dashboard', icon: <Icon32Home className={styles.navIcon} />, to: mainPaths.main },
  {
    name: 'School',
    icon: <IconBriefcase className={styles.navIcon} />,
    to: coursePaths.courseList,
    permission: 'courses'
  },
  {
    name: 'Funnels',
    icon: <Icon32Funnel className={styles.navIcon} />,
    to: funnelPaths.funnelList,
    permission: 'funnels',
    hidden: isPROD
  },
  {
    name: 'Automations',
    icon: <Icon32Gear className={styles.navIcon} />,
    to: automationPaths.automationList,
    permission: 'automations'
  },
  { name: 'Contacts', icon: <Icon32Group className={styles.navIcon} />, to: CRMPaths.crmContacts, permission: 'crm' },
  { name: 'Products', icon: <Icon32Label className={styles.navIcon} />, to: CRMPaths.crmOffers, permission: 'products' }
  // { name: 'Email', icon: <IconEnvelop className={styles.navIcon} />, to: '/email' },
  // { name: 'Marketplace', icon: <Icon32Cart className={styles.navIcon} />, to: '/marketplace' }
];

interface IProps {
  customStyles?: CSSProperties;
  showBlockAccount: boolean;
}

export const Sidebar = memo(({ customStyles, showBlockAccount }: IProps) => {
  const dispatch = useDispatch();
  const [upgradePlanModal, setUpgradePlanModal] = useState(false);
  const subscriptionMemo = useMemo(BillingPermissionSelector, []);
  const { isLoading } = useSelector(subscriptionMemo);
  const photo = useSelector((state) => state.account.account.profile.photo) as IImage;
  const { general } = useSelector((state) => state.general);
  const { currentOrganization } = useSelector((state) => state.auth);
  const [showDialog, setShowDialog] = useState(false);
  const [showHelp, setShowHelp] = useState(false);
  const { pathname } = useLocation();

  useEffect(() => {
    if (currentOrganization?.organization_id) {
      dispatch(OrganizationActionAsync.getOrganizationMembers());
    }
  }, [dispatch, currentOrganization?.organization_id]);

  const onCloseDialog = () => {
    setShowDialog((_x) => false);
  };
  const onToggleDialog = () => {
    setShowDialog((x) => !x);
  };

  const onOpenHelp = () => setShowHelp(true);
  const onCloseHelp = () => setShowHelp(false);

  const onRedirectHandle = (event: React.SyntheticEvent<HTMLAnchorElement>, permission?: RestrictPermission) => {
    //isAllowedTo(permission)
    if (permission && showBlockAccount) {
      setUpgradePlanModal(true);
      event.preventDefault();
    }
  };

  const onCloseUpgradePlan = useCallback(() => setUpgradePlanModal(false), []);
  const currentOrg =
    currentOrganization?.role === 'owner'
      ? { ...currentOrganization, organization_title: general.general_title }
      : currentOrganization;
  const avatar = imageUrl({
    bucket_name: photo?.bucket_name,
    object_name: photo?.object_name
  });

  const isActive = (index: number, to: string) => {
    if (index === 1) {
      return [
        coursePaths.courseList,
        enrollmentPaths.enrollment,
        studentPaths.studentList,
        answersPaths.answers,
        enrollmentPaths.studentListId().replace(':list_id', '')
      ].includes(pathname.replace(/[0-9]/g, ''));
    } else if (index === 3) {
      return [automationPaths.automationList, automationPaths.runs].includes(pathname.replace(/[0-9]/g, ''));
    } else if (index === 4) {
      return [CRMPaths.crmContacts, CRMPaths.crmLists, CRMPaths.crmListId().replace(':list_id', '')].includes(
        pathname.replace(/[0-9]/g, '')
      );
    } else if (index === 5) {
      return [
        CRMPaths.crmProducts,
        CRMPaths.crmOffers,
        CRMPaths.crmCourses,
        CRMPaths.crmOffer().replace(':id', ''),
        CRMPaths.payments
        // CRMPaths.crmListId().replace(':list_id', '')
      ].includes(pathname.replace(/\d/g, ''));
    } else {
      return to === pathname;
    }
  };

  return (
    <>
      <aside className="sidebar sidebar-left sidebar-small" style={customStyles}>
        <div className="navbar-vertical-container d-flex flex-column align-items-center justify-content-between">
          <ul className="navbar-nav side-menu w-100">
            {sidebarMenu.map(
              ({ name, icon, to, permission, hidden }, index) =>
                !hidden && (
                  <li className={`${styles.navItem} ${styles.navItemLogo} nav-item `} key={index}>
                    <NavLink
                      onClick={(event) => onRedirectHandle(event, permission)}
                      to={to}
                      className={classNames(styles.navLink, {
                        [styles.unActive]: isLoading && showBlockAccount && to !== mainPaths.main
                      })}
                      activeClassName={styles.active}
                      exact
                      isActive={() => isActive(index, to)}
                    >
                      {icon} {name}
                    </NavLink>
                  </li>
                )
            )}
          </ul>
          <div className={styles.user}>
            <DropMenu
              show={showDialog}
              onToggle={onToggleDialog}
              node={
                <DropMenuItem
                  avatar={avatar}
                  organizationImg={general.general_image}
                  organizationName={general.general_title}
                />
              }
            >
              <Dropdown.Menu onClick={onCloseDialog} className={styles.wrapper}>
                <UserMenu openHelp={onOpenHelp} currentOrganization={currentOrg} showBlockAccount={showBlockAccount} />
              </Dropdown.Menu>
            </DropMenu>
          </div>
        </div>
      </aside>
      {showHelp &&
        ReactDOM.createPortal(<HelpCenter onClose={onCloseHelp} />, document.querySelector('#root') as Element)}
      <SubscriptionModal closeModal={onCloseUpgradePlan} showModal={upgradePlanModal} />
    </>
  );
});
