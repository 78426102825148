import styles from './upload-invalid-file.module.scss';
import { Icon32Warning } from '../../../../../../components/UI/icons';

import { Button } from '../../../../../../components/UI';

export interface IUploadFileError {
  title: string;
  description: string;
}

interface IProps extends IUploadFileError {
  clearError: () => void;
}

export const UploadInvalidFile = ({ title, description, clearError }: IProps) => {
  return (
    <div className={styles.container}>
      <Icon32Warning className={styles.icon} />
      <div className={styles.title}>{title}</div>
      <div className={styles.description}>{description}</div>
      <Button onClick={clearError} withIcon={false} btnStyle={'secondary'} className={styles.btn}>
        Ok
      </Button>
    </div>
  );
};
