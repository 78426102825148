import { useEffect, useMemo } from 'react';
import { Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { ActionMeta, OnChangeValue } from 'react-select';
import { FormatOptionLabelMeta } from 'react-select/base';

import classNames from 'classnames';
import styles from './automation-select.module.scss';
import { IconEye, IconPencilOnPaper, IconPlus } from '../../../../../../../components/UI/icons';

import { AutocompleteSelect, Button } from '../../../../../../../components/UI';
import { IOption } from '../../../../../../../interfaces';
import { automationActionAsync } from '../../../../../../automations/store/AutomationActionAsync';
import { AutomationSelector } from '../../../../../../automations/store/AutomationSelector';

interface IProps {
  id?: string;

  onSelect(value: OnChangeValue<IOption, false>, actionMeta?: ActionMeta<IOption>): void;
}

export const AutomationSelect = ({ id, onSelect }: IProps) => {
  const token = useSelector((state) => state.auth.authToken);
  const dispatch = useDispatch();
  const automationMemo = useMemo(AutomationSelector, ['all']);
  const automationOptions: IOption[] = useSelector((state) => {
    const { automationList } = automationMemo(state, 'all');
    return automationList.map((automation) => ({ value: automation.id, label: automation.name }));
  });

  useEffect(() => {
    if (token) {
      dispatch(automationActionAsync.getAutomations(token));
    }
  }, [dispatch, token]);

  const formatOptionLabel = (option: IOption, labelMeta: FormatOptionLabelMeta<IOption>) => {
    return (
      <>
        {option.label}
        {labelMeta.context === 'menu' ? (
          <div className={'buttons'}>
            <button className={'btn btn-icon'}>
              <IconEye />
            </button>
            <button className={'btn btn-icon'}>
              <IconPencilOnPaper />
            </button>
          </div>
        ) : null}
      </>
    );
  };

  return (
    <Form.Group className={classNames(styles)}>
      <Form.Label>Automation</Form.Label>
      <AutocompleteSelect
        options={automationOptions}
        CreateNewComponent={
          <>
            <IconPlus className={styles.createIcon} />
            Create new
          </>
        }
        NoOptionComponent={
          <Button withIcon={false} className={'mt-3 mx-auto'} onClick={() => {}}>
            Create Automation
          </Button>
        }
        noOptionText={'You have no created automations'}
        onSelect={onSelect}
        optionIdSelected={id}
        formatOptionLabel={formatOptionLabel}
        props={{
          placeholder: <>Select automation</>,
          menuPlacement: 'auto'
        }}
      />
    </Form.Group>
  );
};
