import { useRef, useState } from 'react';
import { toast } from 'react-toastify';

import classNames from 'classnames';
import styles from './upload-thumbnail.module.scss';
import { IconSpinner, IconUpload } from '../../../../../../components/UI/icons';

import { IThumbnailsSize, uploadThumbnail } from '../../../../../../API/video-api';
import { ToastMessage } from '../../../../../../components/UI/toast/ToastMessage';

interface IProps {
  videoId: string | undefined;
  videoThumbnail: IThumbnailsSize | undefined;
  setVideoThumbnail: (videoThumbnail: IThumbnailsSize) => void;
  getVideoThumbnails: () => void;
}

export const UploadThumbnail = ({ videoId, videoThumbnail, setVideoThumbnail, getVideoThumbnails }: IProps) => {
  const [videoThumbnailLoading, setVideoThumbnailLoading] = useState<boolean>(false);
  const [hover, setHover] = useState(false);

  const inputRef = useRef<HTMLInputElement>(null);

  const onMouseEnterHandler = () => {
    setHover(true);
  };
  const onMouseLeaveHandler = () => {
    setHover(false);
  };

  const onClickThumbnail = () => {
    if (!videoThumbnail?.link) {
      getVideoThumbnails();
      return;
    }

    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  const handleImageChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (!files || files[0]?.size / 1024 / 1024 / 1024 >= 1 || !videoId) {
      e.target.files = null;
      return;
    }
    setVideoThumbnailLoading(true);
    let thumbnail = await uploadThumbnail(files[0], videoId.replace(/^\D+/g, ''));
    if (thumbnail) {
      setVideoThumbnail(thumbnail.sizes[0]);
      toast.success(<ToastMessage message={'The thumbnail has been uploaded successfully!'} />);
    } else {
      console.log('error', thumbnail);
      toast.error(<ToastMessage message={'The thumbnail was not uploaded successfully!'} />);
    }

    setVideoThumbnailLoading(false);
    e.target.files = null;
  };

  return (
    <div onMouseEnter={onMouseEnterHandler} onMouseLeave={onMouseLeaveHandler} className={styles.uploadThumbnail}>
      <label
        htmlFor={'thumbnail-' + videoId}
        className={classNames([styles.uploadThumbnailTitle, { [styles.uploadThumbnailTitleHover]: hover }])}
      >
        Upload thumbnail
      </label>
      <p className={classNames([styles.uploadThumbnailHints, { [styles.uploadThumbnailHintsHover]: hover }])}>
        16:9 recommended aspect ratio <br />
        or 1920px by 1080px image size
      </p>
      <button
        onClick={onClickThumbnail}
        type="button"
        disabled={videoThumbnailLoading}
        className={styles.uploadThumbnailButton}
      >
        <input
          accept={'image/*'}
          ref={inputRef}
          onChange={handleImageChange}
          multiple={false}
          type="file"
          hidden
          className={styles.uploadThumbnailInput}
          id={'thumbnail-' + videoId}
        />
        {!videoThumbnailLoading && <IconUpload className={styles.uploadThumbnailIcon} height={24} width={24} />}
        {videoThumbnailLoading && <IconSpinner className={classNames([styles.uploadThumbnailSpinner, 'spinner'])} />}
        {videoThumbnail?.link && (
          <img
            className={classNames(styles.uploadThumbnailImage, {
              [styles.uploadThumbnailImageShow]: videoThumbnail?.link && !videoThumbnailLoading
            })}
            src={videoThumbnail?.link}
            height={videoThumbnail?.height}
            width={videoThumbnail?.width}
            alt={'thumbnail-' + videoId}
          />
        )}
      </button>
    </div>
  );
};
