import { IconUser } from '../../../../components/UI/icons';

import { CustomTooltip } from '../../../../components/UI';
import { Image } from '../../../../components/UI/image-components/image/Image';
import { IMember } from '../../../../interfaces';

interface IProps {
  member: IMember;
  className?: string;
}

export const UserPhoto = ({ member, className }: IProps) => {
  return (
    <>
      {member ? (
        <CustomTooltip direction={'top'} customText={`${member.first_name} ${member.last_name}`} className={className}>
          <Image
            size={{ height: 36, width: 36 }}
            alt={'avatar'}
            bucket_name={member.photo?.bucket_name}
            object_name={member.photo?.object_name}
          />
        </CustomTooltip>
      ) : (
        <IconUser className={className} />
      )}
    </>
  );
};
