import { memo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import styles from './studentLesson.module.scss';

import { CourseNavigationView } from '../../../../components/course/course-navigation/common/tree/CourseNavigationView';
import { IFile } from '../../../../interfaces';
import { LoadState } from '../../../../store';
import { SidebarStudentInfo } from '../../../student-cabinet/sidebar-organization/SidebarStudentInfo';
import { StudentCabinetActionAsync } from '../../../student-cabinet/store/StudentCabinetActionAsync';
import { BackNavigationPanel } from '../../courses/common/sidebar/lessons-sidebar/back-navigation-panel/BackNavigationPanel';
import { StudentReturnCourse } from './return-course/StudentReturnCourse';
import { useViewCourseContext } from './ViewCourseContext';

interface IProps {
  general_title?: string;
  general_image?: IFile | null;
  theme_logo?: IFile | null;
}

export const StudentLessonSidebar = memo(({ general_title, general_image, theme_logo }: IProps) => {
  const dispatch = useDispatch();
  const { statusCourse, loadState } = useSelector((state) => state.studentCourses);
  const { course } = useViewCourseContext();

  useEffect(() => {
    if ((statusCourse.courseId !== course.id || loadState !== LoadState.allIsLoaded) && loadState !== LoadState.error) {
      dispatch(StudentCabinetActionAsync.getStatusesCourse(course.id));
    }
  }, [course.id, dispatch, loadState, statusCourse]);

  return (
    <div className={styles.wrapper}>
      <BackNavigationPanel />
      {general_title && general_image !== undefined && (
        <SidebarStudentInfo
          title={course.title}
          name={general_title}
          img={general_image}
          themeLogo={theme_logo}
          countLessons={statusCourse.total_lessons}
          passLessons={statusCourse.passed_lessons}
        />
      )}
      <CourseNavigationView />
      <div className={styles.returnCourseContainer}>
        <StudentReturnCourse />
      </div>
    </div>
  );
});
