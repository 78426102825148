import { createContext, useContext } from 'react';

import { BlockType } from '../../../../../interfaces';
import { ICourseNode } from '../../store/CourseState';

export type CourseContent = {
  onSelect: (id: string, node?: ICourseNode) => void;
  toPreviousFolder: () => void;
  getHierarchy: () => void;
  setCurrentHierarchy: (nodes: ICourseNode[]) => void;
  setCurrentNode: (node: ICourseNode) => void;
  onDeleteNode: (nodeId: string) => void;
  currentNode: ICourseNode | null;
  nodeCreateHandler: (type: keyof typeof BlockType | 'lesson' | 'folder') => void;
  currentHierarchy: ICourseNode[];
  currentNav: ICourseNode | null;
  courseId?: string;
  nodeId?: string;
};

export const CourseContext = createContext<CourseContent>({
  onSelect: () => {},
  toPreviousFolder: () => {},
  getHierarchy: () => {},
  setCurrentNode: () => {},
  setCurrentHierarchy: () => {},
  onDeleteNode: () => {},
  currentNode: null,
  nodeCreateHandler: () => {},
  currentHierarchy: [],
  currentNav: null
});
export const useCourseContext = () => useContext(CourseContext);
