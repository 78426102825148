import { FC } from 'react';
import { FormCheck } from 'react-bootstrap';

import classNames from 'classnames';
import toggleStyle from './toggle.module.scss';

import { getUUID } from '../../../../../../helpers';

interface IProps {
  label: string;
  isToggle: boolean;
  classNameContainer?: string;
  onChangeValue?: (show: boolean) => void;
}

export const SimpleToggle: FC<IProps> = ({ classNameContainer, isToggle, onChangeValue, label, children }) => {
  const id = getUUID();
  return (
    <div className={classNames('bg-white px-4 mt-3 rounded', toggleStyle.container, classNameContainer)}>
      <div className={'d-flex align-items-center'}>
        <FormCheck className={'custom-control custom-switch disable custom-switch-sm'} custom type={'switch'}>
          <FormCheck.Input
            id={id}
            type="checkbox"
            className="custom-control-input disable"
            checked={isToggle}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              onChangeValue?.(e.target.checked);
            }}
          />
          <FormCheck.Label className="custom-control-label" htmlFor={id} />
        </FormCheck>
        <div className={classNames(toggleStyle.title, { [toggleStyle.active]: isToggle })}>{label}</div>
      </div>
      {isToggle && <>{children}</>}
    </div>
  );
};
