import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';

import styles from './registration-form.module.scss';
import rocket from '../../../../assets/images/rocket.png';
import { IconSmGoogle } from '../../../components/UI/icons';

import { Button } from '../../../components/UI';
import { useLogin } from '../../../hooks/use-google-auth';
import { mainPaths } from '../../MainRoutes';
import { AccountActionAsync } from '../../settings/account/store/AccountActionAsync';
import { SignUpForm } from '../sign-up-form/SignUpForm';
import { AuthActions } from '../store/AuthActions';

export const RegistrationForm = () => {
  const dispatch = useDispatch();
  const { signIn } = useLogin();
  const history = useHistory<{ link?: string; action?: string }>();
  const { authToken } = useSelector((state) => state.auth);
  const { account } = useSelector((state) => state.account);

  useEffect(() => {
    if (authToken) {
      dispatch(
        AccountActionAsync.getMe(false, undefined, () => {
          history.push(mainPaths.main);
        })
      );
    }
  }, [authToken, dispatch, history]);

  useEffect(() => {
    if (account.organizations.length > 0) {
      const organization = account.organizations.find((org) => org.role === 'owner');
      if (organization) {
        dispatch(AuthActions.setCurrentOrganization(organization));
      }
    }
  }, [dispatch, account.organizations]);

  return (
    <>
      <div className={styles.title}>
        Let’s go
        <img src={rocket} alt={''} />
      </div>
      <Button
        onClick={() => signIn()}
        size={'lg'}
        btnStyle={'secondary'}
        iconPosition={'left'}
        customIcon={<IconSmGoogle width={32} height={32} />}
        className={'w-100 mb-4'}
      >
        Continue with Google
      </Button>
      <div className={styles.text}>or sign up using email and password</div>
      <SignUpForm className={'mb-3'} />
      <div className={'text-center mb-4'}>
        <Link to={mainPaths.auth} className={styles.link}>
          Already have an account
        </Link>
      </div>
      <div className={styles.terms}>
        By clicking “Continue with Google or Email and password”, you’re agreeing with the everact.io{' '}
        <a rel={'noreferrer'} href={'https://everact.io/policies/terms'} target={'_blank'}>
          Terms of Service
        </a>{' '}
        and{' '}
        <a rel={'noreferrer'} href={'https://everact.io/policies/privacy'} target={'_blank'}>
          Privacy Policy
        </a>
      </div>
    </>
  );
};
