import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { useIntercom } from 'react-use-intercom';

import { mainPaths } from '../../../modules/MainRoutes';

export const useLoadIntercom = () => {
  const { boot, shutdown } = useIntercom();
  const { account } = useSelector((state) => state.account);
  const { general } = useSelector((state) => state.general);
  const organizationId = useSelector((state) => state.auth.currentOrganization?.organization_id);

  const offer = useRouteMatch(mainPaths.offer());

  useEffect(() => {
    if (account && general && organizationId) {
      const customAttributes = {
        organization_id: organizationId,
        first_name: account.profile.first_name,
        last_name: account.profile.last_name,
        organization_name: general.general_title,
        industry: general.general_industry,
        country: general.region_country,
        state: general.region_state,
        timezone: general.region_timezone
      };
      boot({
        name: account.profile.first_name,
        email: account.profile.email,
        phone: account.profile.phone || undefined,
        userId: account.id,
        createdAt: new Date().toLocaleString(),
        customAttributes
      });
    }
  }, [account, boot, general, organizationId]);

  useEffect(() => {
    if (!!offer) {
      shutdown();
    }
  }, [offer, shutdown]);
};
