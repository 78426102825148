import { ChangeEvent, memo, useCallback, useState } from 'react';
import { FormControl } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { OnChangeValue } from 'react-select';

import classNames from 'classnames';
import styles from './select-member.module.scss';

import { Button } from '../../../../../components/UI';
import { validateEmail } from '../../../../../helpers';
import { IOption } from '../../../../../interfaces';
import { Role } from '../../../../../interfaces/role';
import { memberOptions, SelectRole } from '../../common/selectRole/SelectRole';
import { OrganizationActionAsync } from '../../store/OrganizationActionAsync';

interface IProps {
  getMembers: () => void;
}

export const SelectMember = memo(({ getMembers }: IProps) => {
  const dispatch = useDispatch();
  const [selected, setSelected] = useState<OnChangeValue<IOption, boolean>>(memberOptions[0]);
  const { members } = useSelector((state) => state.organization);
  const [input, setInput] = useState('');
  const onChange = (value: ChangeEvent<HTMLInputElement>) => {
    const valueData = value.currentTarget.value;
    setInput(valueData);
  };
  const existEmail = members.find((x) => x.email === input);
  const isValid = validateEmail(input) && !existEmail;
  const onInvite = useCallback(() => {
    dispatch(
      OrganizationActionAsync.inviteOrganizationMembers(
        { email: input, role: selected ? ((selected as IOption).label.toLowerCase() as Role) : undefined },
        getMembers
      )
    );
    setInput('');
  }, [dispatch, getMembers, input, selected]);

  return (
    <>
      <div className={'d-flex'}>
        <div className={classNames('form-control form-control-md p-0', styles.container, existEmail && 'invalid')}>
          <FormControl className={styles.text} value={input} placeholder={'Email'} onChange={onChange} />
          <div className={styles.select}>
            <SelectRole onChange={setSelected} />
          </div>
        </div>
        <Button
          onClick={onInvite}
          withIcon={false}
          size={'lg'}
          className={styles.btn}
          btnProps={{ disabled: !isValid }}
        >
          Invite
        </Button>
      </div>
      <div className={classNames('mb-3', { [styles.error]: existEmail })}>
        {existEmail && 'This email already exists'}
      </div>
    </>
  );
});
