import styles from './new-comments.module.scss';

interface IProps {
  commentCount: number;
}
export const NewComments = ({ commentCount }: IProps) => {
  return (
    <div className={styles.container}>
      {commentCount > 0 && (
        <div className={styles.header}>
          <div className={styles.title}>New comments</div>
          <div className={styles.count}>{commentCount}</div>
        </div>
      )}
    </div>
  );
};
