import { CustomDispatch } from 'redux';

import { editContactList, getListIdsOfContact } from '../../../../API/contact-api';
import { currentContactActions } from './CurrentContactActions';

export const CurrentContactListActionAsync = {
  getListIdsOfContacts: (id: string) => async (dispatch: CustomDispatch) => {
    try {
      dispatch(currentContactActions.getListIdsOfContact.started({ id }));
      const response = await getListIdsOfContact(id);
      if (response.data) {
        dispatch(currentContactActions.getListIdsOfContact.done({ result: response.data, params: { id } }));
      }
    } catch (error) {
      dispatch(currentContactActions.getListIdsOfContact.failed({ error, params: { id } }));
    }
  },
  editContactList: (id: string, listIds: string[]) => async (dispatch: CustomDispatch) => {
    try {
      dispatch(currentContactActions.editContactList.started({ id, listIds }));
      await editContactList(id, listIds);
      dispatch(currentContactActions.editContactList.done({ result: {}, params: { id, listIds } }));
    } catch (error) {
      dispatch(currentContactActions.editContactList.failed({ error, params: { id, listIds } }));
    }
  }
};
