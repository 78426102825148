import { PaymentMethod } from '@stripe/stripe-js';
import actionCreatorFactory from 'typescript-fsa';

import { IPaymentCard } from '../../../interfaces';
import { IPaymentHistory } from './SettingCourseState';

export const actionCreator = actionCreatorFactory();

export const settingCourseActions = {
  paymentMethod: actionCreator.async<{ returnUrl?: string }, { url: string }, Error>('PAYMENT_METHOD'),
  getZapierKey: actionCreator.async<void, { api_key: string }, Error>('GET_ZAPIER_KEY'),
  getPaymentCards: actionCreator.async<void, IPaymentCard[], Error>('GET_PAYMENT_CARDS'),
  removePaymentCard: actionCreator.async<{ id: string }, void | null, Error>('REMOVE_PAYMENT_CARD'),
  setDefaultCard: actionCreator.async<{ id: string | PaymentMethod }, void | null, Error>('SET_DEFAULT_CARD'),
  getClientSecret: actionCreator.async<void, { client_secret: string }, Error>('GET_CLIENT_SECRET'),
  getPaymentHistory: actionCreator.async<void, IPaymentHistory, Error>('GET_PAYMENT_HISTORY')
};
