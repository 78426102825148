import { FC } from 'react';

import classNames from 'classnames';
import styles from './product-badge.module.scss';
import { Icon16Label, Icon16Webinar } from '../../../../../../components/UI/icons';

import { FieldEntity } from '../../../../field';

interface IProps {
  element: FieldEntity;
  className?: string;
}

export const ProductBadge: FC<IProps> = ({ element, className, children }) => {
  return (
    <>
      {element === 'product' ? (
        <div className={classNames(styles.product, 'badge-offer', className)}>
          <Icon16Label />
          {children}
        </div>
      ) : (
        <div className={classNames(styles.course, 'badge-offer', className)}>
          <Icon16Webinar />
          {children}
        </div>
      )}
    </>
  );
};
