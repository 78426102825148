import ReactQuill, { Quill } from 'react-quill';

const Embed = Quill.import('blots/embed');

class CustomTag extends Embed {
  // static create(value: any) {
  //   console.log('value', value);
  //   let node;
  //   if (Array.isArray(this.tagName)) {
  //     if (typeof value === 'string') {
  //       value = value.toUpperCase();
  //       if (parseInt(value, 10).toString() === value) {
  //         value = parseInt(value, 10);
  //       }
  //     }
  //     if (typeof value === 'number') {
  //       node = document.createElement(this.tagName[value - 1]);
  //     } else if (this.tagName.indexOf(value) > -1) {
  //       node = document.createElement(value);
  //     } else {
  //       node = document.createElement(this.tagName[0]);
  //     }
  //   } else {
  //     node = document.createElement(this.tagName);
  //   }
  //   if (this.className) {
  //     node.classList.add(this.className);
  //   }
  //   node.innerHTML = 1;
  //   return node;
  //   //   console.log(paramValue);
  //   //   let node = super.create();
  //   //   node.innerHTML = paramValue;
  //   //   //node.setAttribute('contenteditable', 'false');
  //   //   //node.addEventListener('click', CustomTag.onClick);
  //   //   return node;
  //   // }
  //   // static value(node: Element) {
  //   //   console.log(node);
  //   //   return node.innerHTML;
  // }

  static value(_domNode: Node): any {
    console.log('domNode', _domNode);
    return _domNode;
  }
}

CustomTag.blotName = 'variable';
CustomTag.className = 'ql-variable';
CustomTag.tagName = 'SPAN';
//in case you need to inject an event from outside
/* CustomTag.onClick = function(){
     //do something
 }*/

export { CustomTag };

export const onInsertVariableHTML = (quill: ReactQuill, variable: string) => {
  const editor = quill.getEditor();
  const range = editor.getSelection(true);
  editor.insertEmbed(
    range.index, //INDEX_WHERE_YOU_TO_INSERT_THE_CONTENT,
    'variable', //THE NAME OF YOUR CUSTOM TAG
    variable // THE CONTENT YOUR TO INSERT
  );
  editor.insertText(range.index + 1, ' ', 'user');
  //Add a space after the marker
  editor.setSelection(range.index + 2, 0, 'silent');
};

export const onInsertVariableText = (quill: ReactQuill, variable: string) => {
  const editor = quill.getEditor();
  const range = editor.getSelection(true);
  editor.insertText(range.index, variable);
  //Add a space after the marker
  editor.setSelection(range.index + variable.length, 0, 'silent');
};
