import { useHistory } from 'react-router-dom';

import classNames from 'classnames';
import styles from './empty-course.module.scss';
import NoCourseImg from '../../../../../../assets/svg/empty-course.svg';
import { Icon32Books } from '../../../../../components/UI/icons';

import { Button } from '../../../../../components/UI';
import { StudentCabinetPaths } from '../../../../student-cabinet/routes/StudentCabinetRoutes';

interface IProps {
  title?: string;
}

export const EmptyCourse = ({ title }: IProps) => {
  const history = useHistory();
  const goToCourses = () => {
    history.push(StudentCabinetPaths.courses);
  };
  return (
    <div className={styles.container}>
      <img src={NoCourseImg} alt={title} className={classNames('mb-5', styles.image)} />
      <div className={styles.text}>
        It looks like the author of the "<span>{title}</span>" course hasn't added any lessons yet.
      </div>
      <div className={styles.wrapper}>
        <Button
          onClick={goToCourses}
          size={'lg'}
          iconPosition={'left'}
          customIcon={<Icon32Books />}
          className={styles.button}
        >
          Back to my courses
        </Button>
      </div>
    </div>
  );
};
