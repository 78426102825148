import { lazy, memo, Suspense, useMemo } from 'react';
import { Quill } from 'react-quill';

import classNames from 'classnames';
import 'react-quill/dist/quill.snow.css';
import '../../../../../components/quill-editor/snow-quill-editor.scss';
import styles from './text.module.scss';

import { FontFamily } from '../../../../../components/quill-editor/modules/CustomFontFamily';
import { Size } from '../../../../../components/quill-editor/modules/CustomFontSize';
import { CustomTag } from '../../../../../components/quill-editor/modules/CustomTag';
import { CustomVideo } from '../../../../../components/quill-editor/modules/CustomVideo';
import { Loader } from '../../../../../components/UI';
import { ICourseBlockText } from '../../../../../interfaces';

interface IProps {
  block: ICourseBlockText;
}

const ReactQuill = lazy(() => import('react-quill'));

Quill.register(CustomVideo, true);

Quill.register(CustomTag);
Quill.register(FontFamily, true);
Quill.register(Size, true);
Quill.debug(false);

export const Text = memo(({ block }: IProps) => {
  const modules = useMemo(
    () => ({
      toolbar: false
    }),
    []
  );

  return (
    <div className={classNames(styles.text, 'snow')}>
      <Suspense fallback={<Loader />}>
        <ReactQuill modules={modules} value={block.text} readOnly />
      </Suspense>
    </div>
  );
});
