import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { settingsActions } from '../store/settings';

export const useCommonPayload = (
  key: PayloadKeys
): [isCommonPayloadShow: boolean, showPayload: () => void, hidePayload: () => void] => {
  const dispatch = useDispatch();
  const { isCommonPayloadShow } = useSelector((state) => state.settings);

  const showPayload = useCallback(() => {
    dispatch(settingsActions.setCommonPayloadShowing({ key, isPayloadShow: true }));
  }, [dispatch, key]);

  const hidePayload = useCallback(() => {
    dispatch(settingsActions.setCommonPayloadShowing({ key, isPayloadShow: false }));
  }, [dispatch, key]);

  useEffect(() => {
    return () => {
      if (isCommonPayloadShow && isCommonPayloadShow[key]) {
        hidePayload();
      }
    };
  }, [hidePayload, isCommonPayloadShow, key]);

  return [isCommonPayloadShow[key], showPayload, hidePayload];
};

export enum PayloadType {
  runDetails = 'runDetails',
  fieldSettings = 'fieldSettings'
}

export type PayloadKeys = `${PayloadType}`;
