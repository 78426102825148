import { components, SingleValueProps } from 'react-select';

import styles from './singleValue.module.scss';

import { IOption } from '../../../../../interfaces';

export const SingleValue = ({ children, ...props }: SingleValueProps<IOption>) => (
  <components.SingleValue {...props}>
    <div className={styles.container}>{children}</div>
  </components.SingleValue>
);
