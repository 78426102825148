import { Link, useHistory } from 'react-router-dom';

import classNames from 'classnames';
import styles from '../../../../styles/components/navbar.module.scss';
import { IconArrowLeft } from '../../../components/UI/icons';

import { CRMPaths } from '../../../modules/crm/routes/CRMRoutes';

export const OfferListHeaderPanel = () => {
  const history = useHistory<{ prev: string; title: string } | undefined>();
  return (
    <div className={`navbar navbar-expand justify-content-start ${styles.navbar}`}>
      <Link
        className={classNames('btn btn-sm btn-icon-left', styles.link)}
        to={history.location.state ? history.location.state.prev : CRMPaths.crmOffers}
      >
        <IconArrowLeft className={'icon'} />
        {history.location.state?.title || 'Offers'}
      </Link>
    </div>
  );
};
