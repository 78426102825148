import React, { memo } from 'react';
import moment from 'moment';

import classNames from 'classnames';
import styles from './payment-row.module.scss';
import { IconDownload } from '../../../../components/UI/icons';

import { IInvoice } from '../../store/SettingCourseState';
import { CardIcon } from '../payment-card/CardIcon';

interface IProps {
  payment: IInvoice;
}

export const PaymentRow = memo(({ payment }: IProps) => {
  return (
    <div className={classNames(styles.container, 'row')}>
      <div className={classNames(styles.name, 'col-3')}>
        <div className={styles.title}>{payment.title}</div>
        <div className={styles.paymentDate}>
          {payment.paid_at && moment(payment.paid_at).format('DD MMM YYYY, hh:mm A')}
        </div>
      </div>
      <div className={classNames(styles.amount, 'col-1')}>
        {new Intl.NumberFormat('en-EN', {
          style: 'currency',
          currency: payment.currency,
          minimumSignificantDigits: 2
        }).format(payment.amount_due / 100)}
      </div>
      <div className={classNames(styles.card, 'col-2')}>
        {payment.card && (
          <>
            <div className={styles.icon}>
              <CardIcon subscription_payment_source={payment.card} />
            </div>
            <div className={styles.number}>
              {payment.card?.brand === 'visa' ? '****' : '××××'} {payment.card?.last4}
            </div>
          </>
        )}
      </div>
      <div className={classNames('col-1')}>
        <div className={classNames(styles.status, styles[payment.status])}>{payment.status}</div>
      </div>
      <div className={styles.download}>
        {payment.invoice_pdf && (
          <a href={payment.invoice_pdf}>
            <IconDownload />
          </a>
        )}
      </div>
    </div>
  );
});
