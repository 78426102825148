import { reducerWithInitialState } from 'typescript-fsa-reducers';

import { LoadState, newState } from '../../../../store';
import { IStudentListState, StudentListInitialState } from './StudentListState';
import { StudentsActions } from './StudentsActions';

export const commonFirstLoadHandler = <T>(state: T) => ({ ...state, loadState: LoadState.firstLoad, error: null });
export const commonErrorHandler = <T>(state: T, { error }: { error: Error }) => ({
  ...state,
  loadState: LoadState.error,
  error
});

export const studentsConfigReducer = reducerWithInitialState<IStudentListState>(StudentListInitialState)
  .case(StudentsActions.getStudentsConfig.started, commonFirstLoadHandler)
  .case(StudentsActions.getStudentsConfig.done, (state, { result }) => {
    return newState(state, {
      studentList: result,
      loadState: LoadState.allIsLoaded,
      error: null
    });
  })
  .case(StudentsActions.getStudentsConfig.failed, commonErrorHandler);
