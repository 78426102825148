import { useState } from 'react';

import classNames from 'classnames';
import styles from './lesson-title.module.scss';

import { DragSectionStudent } from '../../../lms/courses/curriculum/common/dragSection/DragSectionStudent';
import { useLessonNode } from '../../../lms/hooks/use-lesson-node';
import { useViewCourseContext } from '../../../lms/students/student-course/ViewCourseContext';

export const LessonTitle = () => {
  const { course, nodeId } = useViewCourseContext();
  const [show, setShow] = useState(false);
  const { nodeLesson } = useLessonNode(course, nodeId);

  const toggleDetails = () => {
    setShow(!show);
  };

  return (
    <div className={classNames(styles.container, nodeLesson && nodeLesson.children?.length < 2 ? 'mb-lg-3': 'mb-2 mb-md-3')}>
      <div className={styles.top}>
        <div className={classNames(styles.title, { [styles.full]: show })} onClick={toggleDetails}>{nodeLesson?.title}</div>
        {/*<Button onClick={toggleDetails} btnStyle={'transparent'} withIcon={false} className={styles.details}>
          {show ? 'Hide details' : 'View details'}
        </Button>*/}
      </div>
      <DragSectionStudent className={styles.tabs} />
    </div>
  );
};
