import { FormCheck } from 'react-bootstrap';
import { Controller, useFormContext } from 'react-hook-form';

import createOfferStyles from '../../create-offer.module.scss';
import styles from './publish-header.module.scss';

import { getUUID } from '../../../../../../helpers';
import { PublishData } from '../Publish';

interface IProps {
  toggle: boolean;
}

export const PublishHeader = ({ toggle }: IProps) => {
  const { control } = useFormContext<PublishData>();
  const id = getUUID();

  return (
    <>
      <div className={createOfferStyles.title}>Share your checkout form</div>
      <div className={styles.toggleContainer}>
        <div className={styles.toggleText}>Hide everact.io branding</div>

        <FormCheck
          className={'custom-control custom-switch disable custom-switch-md ml-2 d-block'}
          custom
          type={'switch'}
        >
          <Controller
            name={'settings_hide_branding'}
            control={control}
            render={({ field: { onChange } }) => {
              return (
                <FormCheck.Input
                  id={id}
                  type="checkbox"
                  className="custom-control-input disable"
                  checked={toggle}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    onChange(e.target.checked);
                  }}
                />
              );
            }}
          />
          <FormCheck.Label className="custom-control-label" htmlFor={id} />
        </FormCheck>
      </div>
    </>
  );
};
