import { reducerWithInitialState } from 'typescript-fsa-reducers';

import { addDataToTable } from '../../../../helpers';
import { commonErrorHandler, commonFirstLoadHandler } from '../../../../helpers/common-reducer-handlers';
import { LoadState, newState } from '../../../../store';
import { OffersActions } from './OffersActions';
import { IOffersState, OffersInitialState } from './OffersState';

export const offersReducer = reducerWithInitialState<IOffersState>(OffersInitialState)
  .case(OffersActions.getOffers.started, (state, payload) => ({
    ...state,
    loadState: payload.state
      ? LoadState.loadingMore
      : state.loadState === LoadState.allIsLoaded
      ? LoadState.loadingMore
      : LoadState.firstLoad,
    error: null
  }))
  .case(OffersActions.getOffers.done, (state, { result }) => {
    return newState(state, {
      offers: result.data,
      ...result.meta,
      loadState: LoadState.allIsLoaded,
      error: null
    });
  })
  .case(OffersActions.getOffers.failed, commonErrorHandler)
  .case(OffersActions.getProductsOffer.started, commonFirstLoadHandler)
  .case(OffersActions.getProductsOffer.done, (state, { result }) => {
    return newState(state, {
      ...result.meta,
      offerProducts: result.data,
      loadState: result.data.length > 0 ? LoadState.allIsLoaded : LoadState.idle,
      error: null
    });
  })
  .case(OffersActions.getProductsOffer.failed, commonErrorHandler)
  .case(OffersActions.getOffer.started, commonFirstLoadHandler)
  .case(OffersActions.getOffer.done, (state, { result }) => {
    const newOffers = state.offers.length === 0 ? [result] : state.offers.map((x) => (x.id === result.id ? result : x));
    return newState(state, {
      offers: newOffers,
      error: null,
      loadState: LoadState.allIsLoaded
    });
  })
  .case(OffersActions.getOffer.failed, commonErrorHandler)
  .case(OffersActions.createOffer.started, (state) => ({
    ...state,
    loadState: LoadState.firstLoad,
    error: null
  }))
  .case(OffersActions.createOffer.done, (state, { result }) => {
    const { page, total } = addDataToTable(state.page_size, state.total);
    return newState(state, {
      ...state,
      offers: [...state.offers, result],
      total: total,
      page: page,
      loadState: LoadState.allIsLoaded,
      error: null
    });
  })
  .case(OffersActions.createOffer.failed, commonErrorHandler)

  .case(OffersActions.editState.started, (state) => ({
    ...state,
    error: null
  }))
  .case(OffersActions.editState.done, (state, { result, params }) => {
    const updatedOffers = params.filtered
      ? state.offers.filter((offer) => offer.id !== params.id)
      : state.offers.map((offer) => (offer.id === result.offer.id ? result.offer : offer));

    return newState(state, {
      ...state,
      offers: updatedOffers,
      loadState: LoadState.allIsLoaded,
      error: null
    });
  })
  .case(OffersActions.editState.failed, commonErrorHandler)
  .case(OffersActions.editOffer.started, commonFirstLoadHandler)
  .case(OffersActions.editOffer.done, (state ) => {
    return newState(state, {
      ...state,
      error: null,
      loadState: LoadState.needLoad
    });
  })
  .case(OffersActions.editOffer.failed, commonErrorHandler);
