import { useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import styles from './student-comment.module.scss';

import { SpinnerLoader } from '../../../../components/UI';
import { IFile } from '../../../../interfaces';
import { LoadState } from '../../../../store';
import { CommentElement } from '../../../comment/comment-element/CommentElement';
import { CommentsEmpty } from '../../../comment/comments-empty/CommentsEmpty';
import { PostComment } from '../../../comment/post-comment/PostComment';
import { StudentCommentActionAsync } from './store/StudentCommentActionAsync';

interface IProps {
  courseId: string;
  lessonId: string;
}
const observer = new IntersectionObserver(
  ([e]) => {
    return e.target.classList.toggle(styles.isPinned, e.intersectionRatio < 1);
  },
  { threshold: [1] }
);

export const StudentComment = ({ lessonId, courseId }: IProps) => {
  const headerRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();
  const { comments, loadState } = useSelector((state) => state.studentComments);
  const { profile } = useSelector((state) => state.account.account);
  const src = {
    bucket_name: (profile.photo as IFile)?.bucket_name,
    object_name: (profile.photo as IFile)?.object_name
  };

  useEffect(() => {
    if (headerRef.current) {
      observer.observe(headerRef.current);
    }
  }, []);

  const onPostComment = useCallback(
    (value: string) => {
      if (value) {
        dispatch(
          StudentCommentActionAsync.createComment({
            content: value,
            courseId: courseId,
            nodeId: lessonId
          })
        );
        window.location.assign('#comments');
      }
    },
    [courseId, dispatch, lessonId]
  );

  return (
    <div className={styles.container} id="comments">
      {src && (
        <div className={styles.header} ref={headerRef}>
          <div className={styles.title}>
            Comments {comments.meta.total > 0 && <span>&#x2022; {comments.meta.total}</span>}
          </div>
          <div className={styles.postCommentContainer}>
            <PostComment avatar={src} onPostComment={onPostComment} />
          </div>
        </div>
      )}

      {loadState === LoadState.allIsLoaded && comments.data.length === 0 && (
        <div className={styles.emptyPadding}>
          <CommentsEmpty />
        </div>
      )}
      {(loadState === LoadState.allIsLoaded || loadState === LoadState.loadingMore) &&
        comments.data.length > 0 &&
        comments.data.map((comment, index) => (
          <div key={index}>
            {comment.user && !comment.is_hidden && (
              <CommentElement
                commentId={comment.id}
                avatar={comment.user.photo}
                content={comment.content}
                name={`${comment.user.first_name} ${comment.user.last_name}`}
                date={moment(comment.created_at).fromNow()}
              />
            )}
          </div>
        ))}
      {(loadState === LoadState.firstLoad || loadState === LoadState.loadingMore) && (
        <div className={styles.loaderContainer}>
          <SpinnerLoader variant={'primary'} />
        </div>
      )}
    </div>
  );
};
