import { IThumbnailsSize } from 'src/app/API/video-api';
import { Button } from 'src/app/components/UI/button/Button';
import { UploadThumbnail } from 'src/app/modules/lms/courses/curriculum/common/upload-thumbnail/UploadThumbnail';

import styles from './video-control.module.scss';
import { IconTrash, IconUpload } from 'src/app/components/UI/icons';

interface IProps {
  videoThumbnail: IThumbnailsSize | undefined;
  videoUrl?: string;
  setVideoThumbnail: (videoThumbnail: IThumbnailsSize) => void;
  getVideoThumbnails: () => void;
  onRemoveVideo: () => void;
  onOpenModal: () => void;
}

export const VideoControls = ({
  videoUrl,
  getVideoThumbnails,
  setVideoThumbnail,
  videoThumbnail,
  onOpenModal,
  onRemoveVideo
}: IProps) => {
  return (
    <div className={'d-flex justify-content-between mt-2'}>
      <div className={styles.videoFooter}>
        <Button onClick={onOpenModal} customIcon={<IconUpload />}>
          Upload another video
        </Button>
        <Button onClick={onRemoveVideo} customIcon={<IconTrash />} btnStyle={'secondary'} className={'ml-2'}>
          Remove
        </Button>
      </div>
      {videoUrl && (
        <UploadThumbnail
          key={videoUrl}
          videoId={videoUrl}
          videoThumbnail={videoThumbnail}
          setVideoThumbnail={setVideoThumbnail}
          getVideoThumbnails={getVideoThumbnails}
        />
      )}
    </div>
  );
};
