import { CustomDispatch } from 'redux';

import { getDashboard, setDashboardEvent } from '../../../API/dashboard-api';
import { getStudentCourseHierarchy } from '../../../API/student-cabinet-api';
import { getHowToVideos, getVideo } from '../../../API/video-api';
import { IVimeoProject, IVimeoVideo } from '../../../interfaces/vimeo';
import { ICourseNode } from '../../lms/courses/store/CourseState';
import { DashboardActions } from './DashboardActions';
import { IStatusDashboard } from './DashboardState';

export const DashboardActionAsync = {
  getDashboard: () => async (dispatch: CustomDispatch) => {
    try {
      dispatch(DashboardActions.getDashboard.started());
      const response = await getDashboard();
      if (response.data) {
        dispatch(DashboardActions.getDashboard.done({ result: response.data }));
      }
    } catch (error) {
      dispatch(DashboardActions.getDashboard.failed({ error }));
    }
  },
  setDashboardEvent: (event: IStatusDashboard) => async (dispatch: CustomDispatch) => {
    try {
      dispatch(DashboardActions.setDashboardEvent.started({ event }));
      await setDashboardEvent(event);
      dispatch(DashboardActions.setDashboardEvent.done({ params: { event } }));
    } catch (error) {
      dispatch(DashboardActions.setDashboardEvent.failed({ error, params: { event } }));
    }
  },
  getHowToVideos: (id: string, callback: (data: IVimeoProject | null) => void) => async (dispatch: CustomDispatch) => {
    try {
      dispatch(DashboardActions.getHowToVideos.started({ id }));
      const response = await getHowToVideos(id);
      dispatch(DashboardActions.getHowToVideos.done({ params: { id } }));
      callback(response);
    } catch (error) {
      dispatch(DashboardActions.getHowToVideos.failed({ error, params: { id } }));
    }
  },
  getVideo: (id: string, callback: (data: IVimeoVideo | null) => void) => async (dispatch: CustomDispatch) => {
    try {
      dispatch(DashboardActions.getVideo.started({ id }));
      const response = await getVideo(id);
      dispatch(DashboardActions.getVideo.done({ params: { id } }));
      callback(response);
    } catch (error) {
      dispatch(DashboardActions.getVideo.failed({ error, params: { id } }));
    }
  },
  getDashboardCourseHierarchy:
    (id: string, callback?: (courseHierarchy: ICourseNode[]) => void) => async (dispatch: CustomDispatch) => {
      try {
        dispatch(DashboardActions.getDashboardCourseHierarchy.started({ id }));
        const response = await getStudentCourseHierarchy(id);

        if (response.data) {
          dispatch(
            DashboardActions.getDashboardCourseHierarchy.done({
              result: response.data,
              params: { id }
            })
          );
          if (callback) {
            callback(response.data);
          }
        }
      } catch (error) {
        dispatch(DashboardActions.getDashboardCourseHierarchy.failed({ error, params: { id } }));
      }
    }
};
