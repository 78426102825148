import Select from 'react-select';
import _ from 'lodash';

import './field-selector-type.scss';
import styles from './field.module.scss';

import { FieldTypesLabels, IFieldSettings } from './FieldSettings';

interface IProps {
  fieldName: string;
  checkSelected: (x: IFieldSettings) => void;
  val: IFieldSettings | undefined;
}

const formatOptionLabel = ({ label, icon }: IFieldSettings) => (
  <>
    <div className={'icon'}>{icon}</div>
    <div className={'label'}>{label}</div>
  </>
);

export const FieldSelectorType = ({ fieldName, checkSelected, val }: IProps) => {
  const onChange = (data: IFieldSettings | null) => {
    if (data) {
      checkSelected(data);
    }
  };
  const fieldTypes = _.chain(FieldTypesLabels)
    .groupBy('label')
    .map((value, key) => value[0])
    .value();

  return (
    <div>
      <div className={styles.fieldTitle}>{fieldName}</div>
      <Select
        value={val}
        options={fieldTypes}
        formatOptionLabel={formatOptionLabel}
        onChange={(e) => onChange(e)}
        components={{ IndicatorSeparator: () => null }}
        menuPlacement={'auto'}
        classNamePrefix={'select'}
        className={'field-type-select'}
      />
    </div>
  );
};
