import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import AutosizeInput from 'react-input-autosize';
import { useDispatch, useSelector } from 'react-redux';
import { Tag } from 'react-tag-autocomplete-fix';

import classNames from 'classnames';
import styles from './student-form.module.scss';
import { IconTrash } from '../../../../components/UI/icons';

import { CustomTooltip, FormField } from '../../../../components/UI';
import { settingsActions } from '../../../../store/settings';
import { FieldSelector, ModifyField } from '../../../crm/field/store/FieldSelector';
import { AutocompleteTag } from '../../../crm/tag/AutocompleteTag';
import { StudentDelete } from '../common/student-delete/StudentDelete';
import { IStudent } from '../store/StudentListState';
import { StudentsActionAsync } from '../store/StudentsActionAsync';

interface IProps {
  onSetTag(x: Tag[]): void;

  tags: Tag[];
  student?: IStudent;
}

export const StudentForm = memo(({ onSetTag, tags, student }: IProps) => {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const { control } = useFormContext();
  const useWatchData = useWatch({
    control
  });
  const fieldMemo = useMemo(FieldSelector, []);
  const { fullName, students } = useSelector((state) => fieldMemo(state, 'student'));
  const [customFields, setCustomFields] = useState<ModifyField[]>([]);
  const [phone, setPhone] = useState<ModifyField>();
  const [email, setEmail] = useState<ModifyField>();

  const firstNameId = fullName.find((x) => x.scope === 'first_name')?.id;
  const lastNameId = fullName.find((x) => x.scope === 'last_name')?.id;

  const onShowModalDelete = useCallback(() => setShowModal(true), []);

  const onCloseModalDelete = useCallback(() => setShowModal(false), []);

  const deleteStudentHandler = useCallback(
    (deleteContact: boolean) => {
      if (student) {
        dispatch(StudentsActionAsync.deleteStudent(student.id, deleteContact));
        dispatch(settingsActions.setPayloadShowing({ isPayloadShow: false }));
      }
    },
    [dispatch, student]
  );

  useEffect(() => {
    const [email] = students.filter((field) => field.scope === 'email');
    setEmail(email);
    const [phone] = students.filter((field) => field.scope === 'phone');
    setPhone(phone);
    const fields = students.filter((field) => field.scope === 'custom');
    setCustomFields(fields);
  }, [students]);

  return (
    <>
      <div className={styles.studentForm}>
        <div className={styles.studentNameContainer}>
          <div className={classNames(styles.studentName)}>
            {fullName?.map((field) => (
              <Controller
                render={({ field: { onChange, value } }) => (
                  <AutosizeInput
                    inputClassName={classNames('form-control invisible-control', styles.formControl)}
                    maxLength={25}
                    placeholder={field.payload.title}
                    placeholderIsMinWidth
                    onChange={onChange}
                    value={value || ''}
                  />
                )}
                key={field.id}
                name={field.id}
                control={control}
                defaultValue={useWatchData[field.id] || ''}
              />
            ))}
          </div>
          {student && (
            <button
              type={'button'}
              className={classNames('btn btn-icon', styles.deleteButton)}
              onClick={onShowModalDelete}
            >
              <CustomTooltip customText={'Delete student'} direction={'top'}>
                <IconTrash />
              </CustomTooltip>
            </button>
          )}
        </div>

        <AutocompleteTag onSetTag={onSetTag} tags={tags} userEntity={student} />
        {email && <FormField field={email} />}
        {phone && <FormField field={phone} />}
        {customFields.map((field) => (
          <FormField key={field.id} field={field} />
        ))}
      </div>
      {/*{student && <StudentDelete onClose={onCloseModalDelete} show={showModal} student={student} />}*/}
      <StudentDelete
        onClose={onCloseModalDelete}
        isShow={showModal}
        onDelete={deleteStudentHandler}
        name={`${firstNameId && student?.fields[firstNameId] ? student?.fields[firstNameId] : ''} ${
          lastNameId && student?.fields[lastNameId] ? student?.fields[lastNameId] : ''
        }`}
      />
    </>
  );
});
