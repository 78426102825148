import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';

import { LoadState } from '../../../../../store';
import { CreateListModal } from '../../../lists/modals/createListModal/CreateListModal';
import { ListsActionAsync } from '../../../lists/store/ListsActionAsync';
import { CurrentContactListActionAsync } from '../../current-contact-store/CurrentContactActionAsync';
import { ContactListActionAsync } from '../../store/ContactListActionAsync';
import { ContactListsOfListSelector } from '../../store/ContactListSelector';
import { ListsAttacher } from './ListsAttacher';

interface IProps {
  listId?: string;
  contactId?: string;
}

export const ContactCardLists = memo(({ contactId, listId }: IProps) => {
  const [showCreateModal, setShowCreateModal] = useState(false);
  const dispatch = useDispatch();

  const listOfListMemo = useMemo(ContactListsOfListSelector, []);
  const contactList = useSelector((state) => listOfListMemo(state));
  const currentContactLoadState = useSelector((state) => state.currentContact.loadState);
  const { loadState } = useSelector((state) => state.lists);

  const onCloseCreateModal = () => {
    setShowCreateModal(false);
  };

  const onCreateList = useCallback(() => {
    setShowCreateModal(true);
  }, []);

  useEffect(() => {
    if (loadState !== LoadState.allIsLoaded) {
      dispatch(ListsActionAsync.getLists());
    }
  }, [dispatch, loadState]);

  useEffect(() => {
    if (currentContactLoadState === LoadState.allIsLoaded && listId) {
      dispatch(ContactListActionAsync.getContactsList(listId));
    }
    if (currentContactLoadState === LoadState.allIsLoaded && contactId) {
      dispatch(CurrentContactListActionAsync.getListIdsOfContacts(contactId));
    }
  }, [contactId, currentContactLoadState, dispatch, listId]);

  const detachListHandler = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      const id = event.currentTarget.dataset['id'];
      const newContactListOfList = contactList?.contactListOfList?.filter((x) => x.id !== id);
      const newListIds = _.map(newContactListOfList, 'id');
      if (contactId) {
        dispatch(CurrentContactListActionAsync.editContactList(contactId, newListIds));
      }
    },
    [contactId, contactList?.contactListOfList, dispatch]
  );

  const attachListHandler = useCallback(
    (id: string) => {
      if (id && contactId) {
        dispatch(
          CurrentContactListActionAsync.editContactList(contactId, [..._.map(contactList?.contactListOfList, 'id'), id])
        );
      }
    },
    [contactId, contactList?.contactListOfList, dispatch]
  );

  return (
    <div className="pt-4 mx-0 px-4">
      <CreateListModal onCloseModal={onCloseCreateModal} show={showCreateModal} />
      <ListsAttacher
        onCreateList={onCreateList}
        detachListHandler={detachListHandler}
        attachListHandler={attachListHandler}
        attachedIds={contactList?.contactListOfList || []}
      />
    </div>
  );
});
