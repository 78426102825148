export const errorLocalization = {
  unknownError: 'An error occurred, try again later',
  listErrorTitle: 'Failed to load data.\nPlease check your Internet connection',
  tryAgain: 'Try again',
  invalidEmail: 'Invalid data format',
  mandatoryField: 'Required field',
  loginError: 'Email or password is incorrect',
  tooManyLogins: 'No internet or too many login attempts, please try in 30 seconds',
  emailNotExist: 'Invalid e-mail address',
  notFound: 'Not found',
  noInternetConnection: 'No server connection. Check your Internet connection',
  error: 'Error: ',
  sessionExpired: 'Your session has expired',

  failedToConnect: 'Failed to connect to the server.',
  checkConnection: 'Check your Internet connection and try again later.',
  unexpectedServerError: 'An unexpected error on server',
  unexpectedError: 'An unexpected error occurred',
  weWorkOnIt: 'We already work on it',
  sendReport: 'Send a report and continue',
  openUrl: 'Cannot open url. Check app is installed.',

  userNotRegistered: 'User not registered',
  noAuth: 'Not enough rights',

  lostCommunication: 'Connection lost',
  networkIsNotAvailable: 'Network is not available. Check connection and try again',
  repeat: 'Try again',

  nothingWasFound: 'Unfortunately, nothing suitable was found.',

  sent: 'Error sending',
  dontHavePermission: 'You dont have permission'
};
