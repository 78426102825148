import { IEmail, IMessage, IPaginationParams, ISender } from '../interfaces';
import { baseFetch } from './BaseFetch';

export class EmailMessagesService {
  /**
   * It fetches a list of email senders from the backend
   * @param {boolean} [isConfirmed] - boolean - if true, only confirmed senders will be returned
   * @param {boolean} [withoutDefault] - boolean - if true, the default sender will not be included in
   * the response
   * @returns An array of ISender objects
   */
  static async getSenders(isConfirmed?: boolean, withoutDefault?: boolean) {
    return await baseFetch<{}, ISender[]>(
      '/email/senders',
      { confirmed: isConfirmed, defaults: withoutDefault },
      'GET',
      true
    );
  }

  /**
   * Create sender for organization
   * @param email Email
   */
  static async createSender(email: string) {
    return await baseFetch<{}, ISender>('/email/senders', { email }, 'POST', true);
  }

  /**
   * Edit sender
   * @param sender_id
   * @param params
   */
  static async editSender(sender_id: string, params: { is_default?: boolean; email?: string }) {
    return await baseFetch<{}, ISender>(`/email/senders/${sender_id}`, params, 'PUT', true);
  }

  /**
   * Resend confirmation for sender
   * @param sender_id Sender ID
   */
  static async resendSenderConfirmation(sender_id: string) {
    return await baseFetch<{}, ISender>('/email/senders/resend', { sender_id }, 'POST', true);
  }

  /**
   * Get of sender by id
   * @param sender_id Sender ID
   */
  static async getSender(sender_id: string) {
    return await baseFetch<{}, ISender>(`/email/senders/${sender_id}`, {}, 'GET', true);
  }

  /**
   * Delete of sender
   * @param sender_id Sender ID
   */
  static async deleteSender(sender_id: string) {
    return await baseFetch<{}, null>(`/email/senders/${sender_id}`, {}, 'DELETE', true);
  }

  /**
   * Get messages for organization
   */
  static async getMessages(pagination: IPaginationParams) {
    return await baseFetch<{}, IMessage[]>('/email/messages', { ...pagination }, 'GET', true);
  }

  /**
   * Create message for organization
   */
  static async createMessage() {
    return await baseFetch<{}, IMessage>('/email/messages', {}, 'POST', true);
  }

  /**
   * Send test email with body
   */
  static async sendTestEmailMessageWithBody(payload: IEmail, email: string) {
    return await baseFetch<{ email: string; message: IEmail }, {}>(
      '/email/messages/send',
      { email, message: payload },
      'POST',
      true
    );
  }

  /**
   * Send test email without body
   */
  static async sendTestEmailMessageWithoutBody(messageId: string, email: string) {
    return await baseFetch<{ email: string }, void>(`/email/messages/${messageId}/send`, { email }, 'POST', true);
  }

  /**
   * Get of message by id
   * @param messageId Message ID
   */
  static async getMessage(messageId: string) {
    return await baseFetch<{}, IMessage>(`/email/messages/${messageId}`, {}, 'GET', true);
  }

  /**
   * Edit of message
   * @param messageId Message ID
   * @param request Message data
   */
  static async editMessage(messageId: string, request: Pick<IMessage, 'title' | 'payload'>) {
    return await baseFetch<{}, IMessage>(`/email/messages/${messageId}`, { ...request }, 'PUT', true);
  }

  /**
   * Duplicate message
   * @param messageId Message ID
   * @param request Message data
   */
  static async duplicateMessage(messageId: string, request: Pick<IMessage, 'title'>) {
    return await baseFetch<{}, IMessage>(`/email/messages/${messageId}/duplicate`, { ...request }, 'POST', true);
  }
}
