import { memo, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

import classNames from 'classnames';
import commonStyles from '../modules/lesson-student-wrapper.module.scss';
import styles from './drip-student.module.scss';
import DripStudent from '../../../../../../assets/svg/drip_student.svg';
import { IconPencilOnPaper } from '../../../../../components/UI/icons';

import { InfoMessage } from '../../../../../components/UI/infoMessage/InfoMessage';
import { coursePaths } from '../../../courses/routes/CourseRoutes';
import { AvailableLesson } from '../availablelesson/AvailableLesson';
import { useViewCourseContext } from '../ViewCourseContext';
import { DripStudentDate } from './DripStudentDate';

interface IProps {
  date?: string;
  title?: string;
  viewType?: 'preview' | 'dashboard' | 'student';
}

export const DripStudentLesson = memo(({ date, title, viewType }: IProps) => {
  const history = useHistory();
  const fullDate = moment(date).format('DD MMM YYYY, hh:mm A');
  const [day, month] = fullDate.split(', ')[0].split(' ');
  const { nodeId, course } = useViewCourseContext();
  const onRedirectToCourse = useCallback(() => {
    history.push(coursePaths.courseLessons(course?.id, nodeId));
  }, [course?.id, history, nodeId]);

  return (
    <div className={classNames('mx-auto', commonStyles.wrapper, commonStyles.mainContainer)}>
      {viewType === 'preview' && (
        <InfoMessage
          show
          text={'You are on preview mode now. This is how students will see your course.'}
          btnClick={onRedirectToCourse}
          btnText={'Back to editing'}
          withIconBtn={false}
          btnIcon={<IconPencilOnPaper />}
          className={'mb-4 d-flex justify-content-between'}
        />
      )}
      <AvailableLesson>
        <div className={styles.wrapperDate}>
          <DripStudentDate day={day} month={month} />
          <img src={DripStudent} alt="drip student" className={styles.image} />
        </div>
        <p className={styles.title}>“{title}“ will be available on</p>
        <span className={styles.date}>{fullDate}</span>
      </AvailableLesson>
    </div>
  );
});
