import React from 'react';
import moment from 'moment';

import styles from './session.module.scss';
import { Icon16CalendarTime, Icon16Click, Icon16Gear } from '../../../../../components/UI/icons';

import { ElementThumb } from '../../../../../components/illustrations/ElementThumb';
import { IRunEvent } from '../../../../../interfaces';

interface IProps {
  event: IRunEvent;
}

export const Session = ({ event }: IProps) => {
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.icon}>
          <ElementThumb name={event.node_type} builderType={'automation'} size={[48, 48]} />
        </div>
        <div className={styles.id}>
          <span>ID:</span> {event.id}
        </div>
      </div>
      <div className={styles.body}>
        <div className={styles.item}>
          <div className={styles.label}>
            <Icon16Click /> Action
          </div>
          <div className={styles.value}>{event.node_title}</div>
        </div>
        <div className={styles.item}>
          <div className={styles.label}>
            <Icon16Gear /> Configuration
          </div>
          <div className={styles.value}>{event.title}</div>
        </div>
        <div className={styles.item}>
          <div className={styles.label}>
            <Icon16CalendarTime /> Occurred
          </div>
          <div className={styles.value}>{moment(event.created_at).format('DD MMM yyyy, HH:mm')}</div>
        </div>
      </div>
    </div>
  );
};
