import React, { memo, useCallback, useState } from 'react';

import { ListFilter } from '../../../../../components/listFilter/ListFilter';
import { Button } from '../../../../../components/UI';
import { PageTopPanel } from '../../../../../layout/shared-components/page-top-panel/PageTopPanel';
import { IOfferState } from '../../store/OffersState';

interface IProps {
  title: string;
  active?: IOfferState;
  showFilters?: boolean;
  onClick(): void;

  onChangeFilter(filter: IOfferState): void;
}

export const OfferListHeader = memo(
  ({ title, onClick, onChangeFilter, active = 'all', showFilters = true }: IProps) => {
    const [activeFilter, setActiveFilter] = useState<IOfferState>(active);

    const onFilter = useCallback(
      (selectedKey: string | null = 'all') => {
        if (selectedKey) {
          setActiveFilter(selectedKey as IOfferState);
          onChangeFilter(selectedKey as IOfferState);
        }
      },
      [onChangeFilter]
    );

    return (
      <PageTopPanel title={title}>
        {showFilters && <ListFilter activeKey={activeFilter} onSelect={onFilter} />}
        <Button onClick={onClick} className={'ml-4'}>
          Create offer
        </Button>
      </PageTopPanel>
    );
  }
);
