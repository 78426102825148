import { memo, useState } from 'react';
import TextareaAutosize from 'react-textarea-autosize';

import classNames from 'classnames';
import styles from './post-comment.module.scss';
import { IconSendMessage } from '../../../components/UI/icons';

import { Image } from '../../../components/UI/image-components/image/Image';
import { IFile } from '../../../interfaces';

type Props = {
  avatar: IFile;
  onPostComment: (data: string) => void;
  className?: string;
};

export const PostComment = memo(({ avatar, onPostComment, className }: Props) => {
  const [value, setValue] = useState<string>('');

  const onPost = () => {
    onPostComment(value);
    setValue('');
  };

  return (
    <div className={classNames(styles.container, className)}>
      <Image bucket_name={avatar.bucket_name} object_name={avatar.object_name} className={styles.image} />
      <TextareaAutosize
        onChange={(e) => setValue(e.currentTarget.value)}
        value={value}
        minRows={1}
        maxRows={7}
        className={styles.input}
        placeholder="Leave a comment..."
        maxLength={5000}
      />
      <div className={classNames(styles.send, { [styles.active]: !!value })} onClick={onPost}>
        <IconSendMessage />
      </div>
    </div>
  );
});
