import { memo, useLayoutEffect } from 'react';
import InnerHTML from 'dangerously-set-html-content';
import { SimpleQuillEditor } from 'src/app/components/quill-editor/simple-quill-editor/SimpleQuillEditor';
import { TypeOfUploader } from 'src/app/interfaces/fileUpload';
import { AnswerTypeEnum, IQuizQuestion } from 'src/app/interfaces/quiz';

import classNames from 'classnames';
import styles from './question.module.scss';

import { Audio } from '../../../../common/audio/Audio';
import { Video } from '../../../../common/video/Video';
import { Answers } from '../answer/answers/Answers';
import { AttachAnswer } from '../answer/attach-answer/AttachAnswer';
import { FreeAnswer } from '../answer/free-answer/FreeAnswer';
import { RecordAnswer } from '../answer/record-answer/RecordAnswer';
import { VideoAnswer } from '../answer/video-answer/VideoAnswer';
import { useViewQuizContext } from '../ViewQuizContext';

interface IProps {
  question: IQuizQuestion;
}

export const Question = memo(({ question }: IProps) => {
  const { onChangeSelectAnswer } = useViewQuizContext();
  useLayoutEffect(() => {
    onChangeSelectAnswer(question.choices || []);
  }, [onChangeSelectAnswer, question.choices]);

  const audioUrl =
    (question.attachment?.url && question.attachment.url.includes('.mp3') ? question.attachment.url : undefined) ||
    question.attachment?.audio_url;
  const videoUrl =
    (question.attachment?.url && !question.attachment.url.includes('.mp3') ? question.attachment.url : undefined) ||
    question.attachment?.video_url;

  return (
    <>
      <SimpleQuillEditor
        value={question.title || ''}
        options={{ readOnly: true, className: styles.title, fixedToolbar: false }}
      />
      <div className={classNames(styles.description, 'mb-3')}>{question.description}</div>
      <div className={styles.attach}>
        {audioUrl && <Audio audio={{ type: TypeOfUploader.link, url: audioUrl }} />}
        {videoUrl && <Video video={{ type: TypeOfUploader.link, url: videoUrl }} />}
        {question.attachment?.code && <InnerHTML html={question.attachment.code} />}
      </div>
      <div className={classNames(styles.label)}>
        {(question.answer_type === AnswerTypeEnum.text_input || question.answer_type === AnswerTypeEnum.audio_input) &&
          'Your answer:'}
        {question.answer_type === AnswerTypeEnum.single_select && 'Choose one answer option:'}
        {question.answer_type === AnswerTypeEnum.multiple_select && 'Choose one or more answer options:'}
      </div>
      {['single_select', 'multiple_select'].includes(question.answer_type) && <Answers questionData={question} />}
      {question.answer_type === AnswerTypeEnum.text_input && <FreeAnswer questionData={question} />}
      {question.answer_type === AnswerTypeEnum.audio_input && <RecordAnswer questionData={question} />}
      {question.answer_type === AnswerTypeEnum.video_input && <VideoAnswer questionData={question} />}
      {question.answer_type === AnswerTypeEnum.file_input && <AttachAnswer questionData={question} />}
    </>
  );
});
