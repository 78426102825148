import styles from '../../../../styles/components/navbar.module.scss';

import { SearchWrapper } from '../../shared-components/SearchWrapper';

export const FunnelHeaderSearch = () => {
  return (
    <div className={`navbar navbar-expand justify-content-end ${styles.navbar}`}>
      <SearchWrapper />
    </div>
  );
};
