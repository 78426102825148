import classNames from 'classnames';
import canvasStyles from '../canvas-node.module.scss';
import styles from './canvas-email.module.scss';
import { IconSlider } from '../../../../../components/UI/icons';

import { useNodePayload } from '../../../../../hooks/use-node-payload';
import { PaletteAutomationElementTypes } from '../../../../../interfaces';
import { EmptyCanvasNode } from '../EmptyCanvasNode';

interface IProps {
  nodeId: string;
}

export const CanvasNodeEmail = ({ nodeId }: IProps) => {
  const node = useNodePayload<PaletteAutomationElementTypes.ACTION_SEND_EMAIL>(nodeId);

  return (
    <>
      {node?.payload.email_id ? (
        <div className={classNames(styles.emailContainer, canvasStyles.bgContainer)}>
          <IconSlider />
          <span className={'pl-1'}>Configure element</span>
        </div>
      ) : (
        <EmptyCanvasNode text={'Double click to open the options and manage message'} />
      )}
    </>
  );
};
