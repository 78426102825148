import { useFormContext } from 'react-hook-form';

import styles from './email-item.module.scss';
import { Icon16Cross } from '../../../../../../../../components/UI/icons';

import { IEmailActionsAutomationPayload } from '../../../../../../../../interfaces/node-builder-payload/automation-actions/email';

interface IProps {
  text: string;
}

export const EmailItem = ({ text }: IProps) => {
  const { setValue, getValues } = useFormContext<IEmailActionsAutomationPayload>();
  const onRemove = () => {
    const filteredRecipient = getValues('recipient')?.filter((item) => item !== text);
    setValue('recipient', filteredRecipient || []);
  };

  return (
    <div className={styles.container}>
      <div className={styles.text}>{text}</div>
      <Icon16Cross className={styles.icon} onClick={onRemove} />
    </div>
  );
};
