import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import canvasStyles from '../canvas-node.module.scss';
import styles from './canvas-tag.module.scss';

import { _id, getUUID } from '../../../../../helpers';
import { useNodePayload } from '../../../../../hooks/use-node-payload';
import { PaletteAutomationElementTypes } from '../../../../../interfaces';
import { TagActionAsync } from '../../../../crm/tag/store/TagActionAsync';
import { TagComponent } from '../../../../crm/tag/TagComponent';
import { NodeCanvasLoader } from '../common/NodeCanvasLoader';
import { EmptyCanvasNode } from '../EmptyCanvasNode';

interface IProps {
  nodeId: string;
  tag_id?: string[];
}

export const CanvasNodeTag = ({ nodeId, tag_id }: IProps) => {
  const tags = useSelector((state) => state.tags.tags);
  const dispatch = useDispatch();

  useEffect(() => {
    if (tags.length === 0) {
      dispatch(TagActionAsync.getTags());
    }
  }, [dispatch, tags.length]);

  const node = useNodePayload<PaletteAutomationElementTypes.ACTION_TAG>(nodeId);
  const currentTags = tags.filter((x) => node?.payload.tag_id?.includes(x.id) || tag_id?.includes(x.id));

  return (
    <>
      {currentTags.length === 0 ? (
        <EmptyCanvasNode text={'Double click to open the options and manage tags'} />
      ) : (
        <div className={canvasStyles.bgContainer} data-node-id={nodeId} id={_id(nodeId)}>
          <NodeCanvasLoader nodeId={nodeId}>
            {currentTags.map((x) => (
              <span className={styles.tagWrapper} key={getUUID()}>
                <TagComponent bigTag={x} isShowDetach={false} isShowEdit={false} />
              </span>
            ))}
          </NodeCanvasLoader>
        </div>
      )}
    </>
  );
};
