import { IPaymentCard } from '../../../interfaces';
import { LoadState } from '../../../store';

export interface ISettingCourseState {
  paymentCards: IPaymentCard[];
  error: Error | null;
  loadState: LoadState;
}

export const SettingCourseInitialState: ISettingCourseState = {
  paymentCards: [],
  error: null,
  loadState: LoadState.firstLoad
};

export interface IPaymentHistory {
  invoices: IInvoice[];
  upcoming: IInvoice;
}

export interface IInvoice {
  amount_due: number;
  card: IPaymentCard;
  currency: 'usd';
  hosted_invoice_url: string;
  id: string;
  invoice_pdf: string;
  next_payment_attempt: null;
  paid_at: null;
  status: 'draft' | 'open' | 'paid' | 'void' | 'uncollectible';
  title: string;
}
