import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { capitalize } from 'lodash';

import classNames from 'classnames';
import styles from './user-menu.module.scss';
import { Icon16NewWindow } from '../UI/icons';

import { IAccountOrganization } from '../../interfaces';
import { Logout } from '../../modules/auth/Logout';
import { SubscriptionModal } from '../../modules/dashboard/common/subscribe/SubscriptionModal';
import { domainActionAsync } from '../../modules/settings/domain/store/DomainSettingsActionAsync';
import { InvitePeopleToOrganization } from '../../modules/settings/organization/invite/InvitePeopleToOrganization';
import { OrganizationList } from '../../modules/settings/organization/OrganizationList';
import { SettingsPaths } from '../../modules/settings/routes/SettingsRoutes';
import { LoadState } from '../../store';
// import { CustomTooltip } from '../tooltip/CustomTooltip';
// import { IconExit } from '../UI/icons';

interface IProps {
  currentOrganization: IAccountOrganization | null;
  showBlockAccount: boolean;
  openHelp(): void;
}

export const UserMenu = memo(({ currentOrganization, showBlockAccount, openHelp }: IProps) => {
  const dispatch = useDispatch();
  const [invitePeople, setInvitePeople] = useState(false);
  const [upgradePlanModal, setUpgradePlanModal] = useState(false);
  const onCloseInvitePeopleModal = useCallback(() => setInvitePeople(false), []);
  const { members } = useSelector((state) => state.organization);
  const owner = members.find((member) => member.role === 'owner');
  const onCloseUpgradePlan = useCallback(() => setUpgradePlanModal(false), []);
  const { domains, loadState } = useSelector((state) => state.domains);
  const currentOwnerId = useRef(owner?.id);

  const onOpenInvitePeopleModal = (event: React.SyntheticEvent<HTMLDivElement>) => {
    if (showBlockAccount) {
      setUpgradePlanModal(true);
      event.preventDefault();
    } else {
      setInvitePeople(true);
    }
  };

  const openCabinet = () => {
    if (mainDomain) {
      window.open(`https://${mainDomain.url}`);
    }
  };

  const mainDomain = useMemo(() => {
    return domains.find((x) => x.is_primary);
  }, [domains]);

  useEffect(() => {
    if (domains.length === 0 && loadState === LoadState.firstLoad) {
      dispatch(domainActionAsync.getDomains());
    } else if (owner?.id !== currentOwnerId.current) {
      dispatch(domainActionAsync.getDomains());
      currentOwnerId.current = owner?.id;
    }
  }, [dispatch, domains.length, loadState, owner]);

  // const onOpenLink = (event: React.SyntheticEvent<HTMLDivElement>) => {
  //   if (showBlockAccount) {
  //     setUpgradePlanModal(true);
  //     event.preventDefault();
  //   } else {
  //     window.open('https://feedback.everact.io/b/feedback/p/roles-and-multiple-organizations');
  //   }
  // };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.organization}>
          <div className={styles.organizationList}>
            <OrganizationList />
          </div>
          <div className={classNames(styles.organizationInfo, 'ellipsis')}>
            <div className={styles.organizationName}>
              <div className={classNames(styles.organizationText, 'ellipsis')}>
                {currentOrganization?.organization_title}
              </div>
              <div className={styles.role}>{capitalize(currentOrganization?.role)}</div>
            </div>
            <div className={styles.member}>
              <div className={styles.name}>
                {owner?.first_name} {owner?.last_name}
              </div>
              <div className={styles.email}>{owner?.email}</div>
            </div>
          </div>
          <div className={styles.cabinet}>
            <div className={styles.link} onClick={openCabinet}>
              Open student cabinet <Icon16NewWindow />
            </div>
          </div>
          {/* <CustomTooltip direction={'top'}>
              <div className={styles.leaveOrganization} onClick={onOpenLink}>
                Leave organization/settings
                <IconExit />
              </div>
            </CustomTooltip> */}
        </div>
        <div
          className={classNames(styles.item, { [styles.unActiveSubscribe]: showBlockAccount })}
          onClick={onOpenInvitePeopleModal}
        >
          Invite people
        </div>
        {/* <CustomTooltip direction={'top'}>
             <div
               className={classNames(styles.item, { [styles.unActiveSubscribe]: showBlockAccount })}
               onClick={onOpenLink}
             >
               Create organization
             </div>
           </CustomTooltip> */}
        <Link className={styles.item} to={SettingsPaths.settings}>
          Settings
        </Link>
        <div className={styles.item} onClick={openHelp}>
          Help
        </div>
        <div className={styles.separator} />
        <Logout className={classNames(styles.item, styles.logout)}>{'Log out'}</Logout>
      </div>
      <InvitePeopleToOrganization onClose={onCloseInvitePeopleModal} show={invitePeople} />
      <SubscriptionModal closeModal={onCloseUpgradePlan} showModal={upgradePlanModal} />
    </>
  );
});
