import classnames from 'classnames';
import styles from './canvas-condition.module.scss';
import { IconUser } from '../../../../../components/UI/icons';

interface IProps {
  icon: JSX.Element;
  title: string;
}

export const CanvasConditionElement = ({ icon, title }: IProps) => {
  return (
    <div className={styles.wrapper}>
      <div className={classnames(styles.text, styles.bgCondition)}>
        <span className={'mx-1'}>{icon}</span>
        {title}
      </div>
      <div className={classnames(styles.text, styles.bgCount, 'mt-1')}>
        0
        <IconUser />
        <div className={styles.round} />
      </div>
    </div>
  );
};
