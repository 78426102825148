import styles from './fields-edit.module.scss';
import { IconTrash } from '../../../components/UI/icons';

import { Button } from '../../../components/UI';

interface IProps {
  onDeleteFieldHandler(): void;

  onSave(): void;

  onClose(): void;
}

export const FieldFooterModal = ({ onClose, onDeleteFieldHandler, onSave }: IProps) => {
  return (
    <div className={styles.containerModal}>
      <button type={'button'} className={'btn btn-sm btn-icon-right'} onClick={onDeleteFieldHandler}>
        Delete globally
        <IconTrash className={'icon'} />
      </button>

      <button type="button" className={'btn btn-sm ml-auto mr-2'} onClick={onClose}>
        Cancel
      </button>

      <Button onClick={onSave} withIcon={false}>
        Save
      </Button>
    </div>
  );
};
