import React, { FC, memo } from 'react';

import classNames from 'classnames';
import styles from './actions-panel.module.scss';

import { Button } from '../button/Button';
import { IconSpinner } from '../icons';

interface IProps {
  classNameWrapper?: string;
  loading?: boolean;

  onCancel(): void;

  onSave(e?: React.BaseSyntheticEvent): void;
}

export const ActionsPanel: FC<IProps> = memo(({ onCancel, onSave, classNameWrapper, loading, children }) => (
  <div className={classNames(styles.wrapper, classNameWrapper)}>
    <div className={styles.container}>
      {children ? (
        <>{children}</>
      ) : (
        <>
          <Button
            btnStyle={'secondary'}
            withIcon={false}
            onClick={onCancel}
            className={'mr-1'}
            btnProps={{ disabled: loading }}
          >
            Cancel
          </Button>
          <Button
            withIcon={false}
            onClick={onSave}
            className={classNames({ [styles.readOnly]: loading, 'btn-loading': loading })}
          >
            {loading ? <IconSpinner className={'spinner'} /> : 'Update changes'}
          </Button>
        </>
      )}
    </div>
  </div>
));
