import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import styles from './endpoints-delay.module.scss';

import { _id,getUUID } from '../../../../../helpers';
import { builderActions } from '../../store/BuilderActions';
import { FictiveEndpoints } from './FictiveEndpoints';

const days = ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'];

interface IProps {
  nodeId: string;
}

export const EndpointsDelay = ({ nodeId }: IProps) => {
  const dispatch = useDispatch();
  const nodes = useSelector((x) => x.builder.builder.nodes);

  useEffect(() => {
    const newNode = nodes.find((x) => x.id === nodeId);
    const endpointInput = newNode?.endpoints?.filter((x) => x.is_input);
    if (newNode && endpointInput) {
      const newNodes = nodes.map((y) =>
        y.id === nodeId ? { ...y, endpoints: [...FictiveEndpoints, ...endpointInput] } : y
      );
      dispatch(
        builderActions.setNodes({
          nodes: newNodes
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, nodeId]);

  return (
    <div className={styles.container}>
      {days.map((x, index) => (
        <div key={index} className={styles.element} id={_id(getUUID())}>
          {x}
        </div>
      ))}
    </div>
  );
};
