import { memo } from 'react';

import styles from './no-payment-options.module.scss';

import { Button } from '../../../../components/UI';

interface IProps {
  onCLick(): void;
}

export const NoPaymentOptions = memo(({ onCLick }: IProps) => {
  return (
    <div className={styles.container}>
      <div className={styles.title}>You have no added payment options</div>
      <Button onClick={onCLick} iconPosition={'left'} size={'lg'}>
        Add credit card
      </Button>
    </div>
  );
});
