import { FC, memo } from 'react';
import { Modal, ModalProps } from 'react-bootstrap';

import { CloseButton } from '../close-button/CloseButton';

interface IProps {
  show: boolean;
  params?: ModalProps;
  showClose?: boolean;
  closeButtonClassName?: string;
  onClose?(): void;
}

export const CommonModal: FC<IProps> = memo(
  ({ onClose, show, params, children, showClose = false, closeButtonClassName }) => {
    return (
      <Modal
        show={show}
        onHide={onClose}
        aria-labelledby="contained-modal-title-vcenter"
        animation={false}
        centered
        {...params}
      >
        {showClose && onClose && <CloseButton onClose={onClose} className={closeButtonClassName} />}
        {children}
      </Modal>
    );
  }
);
