import { useEffect, useState } from 'react';

import { getUUID } from '../helpers';

export const useScript = (url: string, name: any) => {
  const [lib, setLib] = useState<HTMLScriptElement>();

  useEffect(() => {
    const script = document.createElement('script');
    script.src = url;
    script.defer = true;
    script.id = getUUID();
    script.onload = () => {
      setLib(script);
    };

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [name, url]);

  return lib;
};
