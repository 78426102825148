import { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import styles from './thread-item.module.scss';

import { IComment, IFile, IPaginationResponse, IThread } from '../../../../../interfaces';
import { LessonThread } from '../../../../lms/courses/curriculum/lesson/lesson-comment/lesson-thread/LessonThread';
import { courseActionAsync } from '../../../../lms/courses/store/CourseActionAsync';
import { PostComment } from '../../../post-comment/PostComment';
import { ThreadHeader } from '../thread-header/ThreadHeader';

interface IProps {
  thread: IThread;
  onRemoveThread: (id: string) => void;
}

export const ThreadItem = ({ thread, onRemoveThread }: IProps) => {
  const scrollRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();
  const [comments, setComments] = useState<IPaginationResponse<IComment[]>>({
    data: [],
    meta: { page: 0, page_size: 5, total: 0, total_pages: 1 }
  });
  const { profile } = useSelector((state) => state.account.account);
  const src = {
    bucket_name: (profile.photo as IFile)?.bucket_name,
    object_name: (profile.photo as IFile)?.object_name
  };

  const onCommentPost = (content: string) => {
    dispatch(
      courseActionAsync.postComments({ content, threadId: thread.id }, (comment: IComment) => {
        setComments((prev) => {
          return {
            data: [comment, ...prev.data],
            meta: { ...prev.meta, total: (prev.meta.total += 1) }
          };
        });
        if (scrollRef.current) {
          scrollRef.current?.scrollTo({ top: 0, behavior: 'smooth' });
        }
      })
    );
  };

  return (
    <div className={styles.container} ref={scrollRef}>
      <ThreadHeader
        courseId={thread.course_id}
        hierarchy={thread.hierarchy}
        threadId={thread.id}
        onRemoveThread={onRemoveThread}
      />
      <PostComment avatar={src} onPostComment={onCommentPost} className={styles.postContainer} />
      <LessonThread
        isModerated={false}
        pageSize={5}
        threadId={thread.id}
        comments={comments}
        setComments={setComments}
        className={styles.commentContainer}
        loadMoreFooter={true}
        showToggle={true}
      />
    </div>
  );
};
