import React, { memo } from 'react';

import styles from './confirm-modal.module.scss';
import { Icon32EyeHide, Icon32Reload } from '../../../../../../components/UI/icons';

import { Button, ModalActionComponent } from '../../../../../../components/UI';

interface IProps {
  show: boolean;
  type?: 'skip' | 'reset';
  quizName: string;

  onClose(): void;
  onConfirm(): void;
}

export const ConfirmModal = memo(({ show, type, quizName, onClose, onConfirm }: IProps) => {
  const isReset = type === 'reset';
  return (
    <ModalActionComponent
      show={show}
      title={
        isReset ? (
          <>
            Reset <span>{quizName}</span>
          </>
        ) : (
          <>
            Mark <span>{quizName}</span> as skipped
          </>
        )
      }
      onCloseModal={onClose}
      iconType={'custom'}
      icon={isReset ? <Icon32Reload /> : <Icon32EyeHide className={styles.eyeIcon} />}
    >
      <div className={styles.container}>
        {isReset
          ? 'Once confirmed, the quiz will be removed from the analytics and will appear here after the student starts retaking the quiz.'
          : 'Questions in this quiz will not be marked as solved. The student can freely try to pass the quiz on their own then the attempt will be marked in the analytics.'}
        <div className={styles.actions}>
          <Button onClick={onClose} withIcon={false} btnStyle={'transparent'} className={'mr-2'}>
            Cancel
          </Button>
          <Button onClick={onConfirm} type={'update'}>
            Confirm
          </Button>
        </div>
      </div>
    </ModalActionComponent>
  );
});
