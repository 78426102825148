import React, { Component, SyntheticEvent } from 'react';
import { connect, MapStateToProps } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { CustomDispatch } from 'redux';
import { AxiosResponse } from 'axios';
import {
  Connection,
  ConnectionMadeEventInfo,
  DragEventCallbackOptions,
  EndpointOptions,
  jsPlumb,
  Overlay
} from 'jsplumb';
import _, { map } from 'lodash';

import classnames from 'classnames';
import './builder.scss';

import { _id, geometryObjToArr } from '../../../helpers';
import {
  CategoryNameType,
  EdgeRequest,
  GeometryRequest,
  IAutomationNodeBuilder,
  IEdge,
  IFunnel,
  IFunnelNodeBuilder,
  NodeGeometry,
  NodeTypes,
  PaletteAutomationElementTypes,
  PaletteElementTypes
} from '../../../interfaces';
import { IAppState } from '../../../store';
import { settingsActions } from '../../../store/settings';
import { Events } from '../../automations/events/Events';
import { IAutomation } from '../../automations/store/AutomationState';
import { FunnelStateMessage } from '../../funnels/funnels/funnelStateMessage/FunnelStateMessage';
import { BuilderContext } from './BuilderContext';
import { BuilderWrapper } from './BuilderWrapper';
import { CanvasNode } from './canvasNode/CanvasNode';
import { EndpointsLocation } from './EndpointsLocation';
import { commonEndpoint, defaultsConfig, groupEndpoint } from './jsPlumbConfig';
import { PaletteNav } from './palette/PaletteNav';
import { PalettePopup } from './palette/PalettePopup';
import { PayloadData } from './payloadForm/payloadData/PayloadData';
import { PayloadForm } from './payloadForm/PayloadForm';
import { BuilderType } from './store/BuilderActionAsync';
import { builderActions } from './store/BuilderActions';
import { TransformBuilder } from './TransformBuilder';

interface State {
  showPalette: boolean;
  isLoading?: boolean;
  canvasMoved?: number;
  selectedNode: IFunnelNodeBuilder<PaletteElementTypes> | IAutomationNodeBuilder<PaletteAutomationElementTypes> | null;
}

interface IMapDispatchToProps {
  setNodes(
    nodes: Array<IFunnelNodeBuilder<PaletteElementTypes> | IAutomationNodeBuilder<PaletteAutomationElementTypes>>
  ): void;

  setNode(node: IFunnelNodeBuilder<PaletteElementTypes> | IAutomationNodeBuilder<PaletteAutomationElementTypes>): void;

  setPayloadShowing(isPayloadShow: boolean): void;
}

export interface IMapStateToProps {
  nodes: Array<IFunnelNodeBuilder<PaletteElementTypes> | IAutomationNodeBuilder<PaletteAutomationElementTypes>>;
  isPayloadShow: boolean;
}

interface Props extends IMapDispatchToProps, IMapStateToProps, RouteComponentProps<any> {
  isThumbnails?: boolean;
  id: string;
  builderType: BuilderType;
  paletteNavItems: { category: CategoryNameType; icon: JSX.Element }[][];
  paletteElements: Record<string, { name: string; available: boolean }[]>;
  paletteElementTypes: Record<string, string>;
  paletteElementNames: Record<string, string>;
  currentElementCategoryDefault: CategoryNameType;
  addNode: (id: string, types: NodeTypes) => void;
  deleteEdge: (id: string, edgeKey: string) => void;
  deleteNode: (id: string, nodeKey: string) => void;
  saveEdge: (id: string, edge: EdgeRequest) => void;
  saveGeometry: (id: string, data: GeometryRequest) => void;
  getDetailData: (id: string) => Promise<AxiosResponse<IFunnel | IAutomation | null>>;
  addNodeHandler: (
    id: string,
    nodeType: NodeTypes
  ) => Promise<
    AxiosResponse<
      IFunnelNodeBuilder<PaletteElementTypes> | IAutomationNodeBuilder<PaletteAutomationElementTypes> | null
    >
  >;
  saveEdgeHandler: (id: string, edge: EdgeRequest) => Promise<AxiosResponse<IEdge | null>>;
}

let canvasOptions = {
  positionX: 0,
  positionY: 0,
  scale: 1
};

class Builder extends Component<Props, State> {
  nodes: Record<
    string,
    IFunnelNodeBuilder<PaletteElementTypes> | IAutomationNodeBuilder<PaletteAutomationElementTypes>
  > = {};
  allNodes: Record<
    string,
    IFunnelNodeBuilder<PaletteElementTypes> | IAutomationNodeBuilder<PaletteAutomationElementTypes>
  > = {};
  geometry: Record<string, NodeGeometry> = {};
  edges: Record<string, IEdge> = {};
  jsPlumb = jsPlumb.getInstance(defaultsConfig);
  connections: any[] = [];
  private nodeDragged = false;
  private currentNodeId: string = '';
  private selectedConnection: Connection | null = null;
  private currentElementCategory: CategoryNameType = this.props.currentElementCategoryDefault;

  constructor(props: Props) {
    super(props);
    this.state = {
      showPalette: false,
      isLoading: true,
      canvasMoved: 0,
      selectedNode: null
    };
  }

  async componentDidMount() {
    try {
      const resp = await this.props.getDetailData(this.props.id);
      if (resp && resp.data) {
        if (resp.data.nodes) {
          this.props.setNodes(resp.data.nodes);
        }
        const eventNodes = _.filter(
          resp.data.nodes,
          (item: IAutomationNodeBuilder<PaletteAutomationElementTypes>) => item.type.search(/^event\./) !== -1
        );
        const nodes = _.without(
          resp.data.nodes as IAutomationNodeBuilder<PaletteAutomationElementTypes>[],
          ...eventNodes
        ) as IAutomationNodeBuilder<PaletteAutomationElementTypes>[];
        this.nodes = _.keyBy(nodes || [], 'id');
        this.geometry = _.keyBy(resp.data.geometry || [], 'node_id');
        this.edges = _.keyBy(resp.data.edges || [], 'id');

        // @ts-ignore
        if (this.props.location.state?.fromPageBuilder) {
          // @ts-ignore
          this.currentNodeId = this.props.location.state.nodeId;
          console.log('%c⇒ this.currentNodeId', 'color: #89DDF7', this.currentNodeId);
          this.props.setPayloadShowing(true);
        }
        this.setState(
          {
            isLoading: false
          },
          () => {
            setTimeout(() => {
              this.addConnection();
            }, 300);
          }
        );
      }
    } catch (e) {
      console.log('%c⇒ e', 'color: #FF5370', e);
    }

    this.addEndpoints();

    this.jsPlumb.bind('connection', (info) => {
      this.saveConnection(info);
    });

    this.jsPlumb.bind('connectionDetached', async (info) => {
      try {
        if (Object.keys(this.edges).includes(info.connection.id)) {
          this.props.deleteEdge(this.props.id, info.connection.id);

          delete this.edges[info.connection.id];
          this.selectedConnection = null;
        }
      } catch (e) {
        console.log('%c⇒ e', 'color: #FF5370', e);
      }
    });

    this.jsPlumb.bind('connectionMoved', async (info) => {
      try {
        if (Object.keys(this.edges).includes(info.connection.id)) {
          this.props.deleteEdge(this.props.id, info.connection.id);
          delete this.edges[info.connection.id];
          this.selectedConnection = null;
        }
      } catch (e) {
        console.log('%c⇒ e', 'color: #FF5370', e);
      }
    });

    this.jsPlumb.bind('beforeDrop', (info) => {
      if (!Object.keys(this.edges).length) return true;
      const conn = info.connection;
      // @ts-ignore
      const target = info.dropEndpoint.getUuid();
      let source: string = '';
      conn.endpoints.forEach((endpoint) => {
        // @ts-ignore
        if (endpoint.isSource) {
          // @ts-ignore
          source = endpoint.getUuid();
        }
      });
      const isConnectionExist = Object.keys(this.edges).some((id) => {
        return _id(this.edges[id].from) === source && _id(this.edges[id].to) === target;
      });
      return !isConnectionExist;
    });

    this.jsPlumb.bind('click', async (conn: Connection, originalEvent: Event) => {
      this.removeSelection();
      this.selectedConnection = conn;
      const id = conn?.getParameter('id');
      if (originalEvent.target instanceof HTMLButtonElement) {
        try {
          this.props.deleteEdge(this.props.id, id);
          delete this.edges[id];
          this.jsPlumb.deleteConnection(conn);
          this.selectedConnection = null;
        } catch (e) {
          console.log('%c⇒ e', 'color: #FF5370', e);
        }
      } else {
        // @ts-ignore
        conn?.canvas.classList.add('selected');
        const overlay: Overlay = conn.getOverlay(`overlay-${id}`);
        // @ts-ignore
        overlay?.setVisible(true);
      }
    });
  }

  onSetCanvasOption = (data: { scale: number; positionX: number; positionY: number }) => {
    canvasOptions = data;
  };

  addElement = (nodeType: NodeTypes, position: { x: number; y: number }, event?: SyntheticEvent) => {
    this.props.addNodeHandler(this.props.id, nodeType).then(({ data }) => {
      if (data) {
        this.props.setNode(data);
        const node = data;
        const nodeId = node.id;
        this.nodes[nodeId] = node;
        this.geometry[nodeId] = {
          node_id: nodeId,
          position_x: (position.x - canvasOptions.positionX) / canvasOptions.scale,
          position_y: (position.y - canvasOptions.positionY) / canvasOptions.scale
        };
        this.forceUpdate(() => {
          this.initNode(this.nodes[nodeId]);
        });
        this.jsPlumb.fire('nodeAdded', { node_id: nodeId }, event as unknown as Event);
        this.saveGeometry();
      }
    });
  };

  initNode = (
    node: IFunnelNodeBuilder<PaletteElementTypes> | IAutomationNodeBuilder<PaletteAutomationElementTypes>
  ) => {
    const isMultiPinNode = /pages\./.test(node.type) && node.endpoints && node.endpoints?.length > 2;
    if (node.type === PaletteAutomationElementTypes.CONTAINER_EVENTS) {
      let endpointOptions: EndpointOptions[] = [];
      if (node.endpoints) {
        let i = 0;
        try {
          this.removeSelection();
          Object.keys(this.nodes).map((id) =>
            this.jsPlumb.getEndpoint(id) ? this.jsPlumb.deleteEndpoint(`endpoint-${id}`) : null
          );
        } catch (error) {
          console.log('error', error);
        }

        node.endpoints.forEach((endpoint) => {
          if (!endpoint.is_input) {
            endpointOptions.push({
              ...commonEndpoint,
              isSource: !endpoint.is_input,
              isTarget: endpoint.is_input,
              cssClass: `endpoint endpoint-${node.id}`,
              anchor: EndpointsLocation(endpoint, node, i, this.props.builderType),
              uuid: _id(endpoint.id)
            });
          }
        });
        try {
          this.jsPlumb.addEndpoints(_id(node.id), endpointOptions);
        } catch (error) {
          console.log(error);
        }
      }
    } else {
      this.jsPlumb.draggable(_id(node.id), {
        start: (_params: DragEventCallbackOptions) => {
          this.nodeDragged = true;
          if (this.props.isPayloadShow) {
            this.closePayload();
          }
        },
        stop: (params: DragEventCallbackOptions) => {
          this.geometry[node.id] = {
            node_id: node.id,
            position_x: params.pos[0],
            position_y: params.pos[1]
          };
          this.saveGeometry();
        }
      });

      let endpointOptions: EndpointOptions[] = [];
      if (node.endpoints && !isMultiPinNode) {
        let i = 0;
        try {
          this.removeSelection();
          Object.keys(this.nodes).map((id) =>
            this.jsPlumb.getEndpoint(id) ? this.jsPlumb.deleteEndpoint(`endpoint-${id}`) : null
          );
        } catch (error) {
          console.log('error', error);
        }

        node.endpoints.forEach((endpoint) => {
          const isSmallEndpoint = node.type === PaletteAutomationElementTypes['PROCESSOR_RANGE'] && !endpoint.is_input;
          const config = isSmallEndpoint ? groupEndpoint : commonEndpoint;
          if (endpoint.type === 'simple') {
            endpointOptions.push({
              ...config,
              isSource: !endpoint.is_input,
              isTarget: endpoint.is_input,
              cssClass: `endpoint-${node.id} ${isSmallEndpoint ? 'group-endpoint' : 'endpoint'}`,
              anchor: EndpointsLocation(endpoint, node, i, this.props.builderType),
              uuid: _id(endpoint.id)
            });
            !endpoint.is_input && i++;
          } else if (endpoint.type !== 'simple' && (node.payload as any).object_type) {
            endpointOptions.push({
              ...config,
              isSource: !endpoint.is_input,
              isTarget: endpoint.is_input,
              cssClass: `endpoint endpoint-${node.id}`,
              anchor: EndpointsLocation(endpoint, node, i, this.props.builderType),
              uuid: _id(endpoint.id)
            });
            !endpoint.is_input && i++;
          }
        });

        const id = _id(node.id);
        if (document.getElementById(id)) {
          this.jsPlumb.addEndpoints(id, endpointOptions);
        }
      } else {
        let i = 0;
        node.endpoints?.forEach((endpoint) => {
          if (endpoint.is_input) {
            this.jsPlumb.addEndpoint(_id(node.id), {
              ...commonEndpoint,
              isTarget: endpoint.is_input,
              anchor: ['Left'],
              uuid: _id(endpoint.id)
            });
          } else {
            this.jsPlumb.addEndpoint(_id(node.id), {
              ...groupEndpoint,
              anchor: EndpointsLocation(endpoint, node, i, this.props.builderType),
              isSource: true,
              uuid: _id(endpoint.id)
            });
            i++;
          }
        });
      }
    }
  };

  addEndpoints = () => {
    for (let nodeId in this.nodes) {
      this.initNode(this.nodes[nodeId]);
    }
  };

  createOverlayButton = () => {
    const button = document.createElement('button');
    button.classList.add('btn', 'btn-delete-connection', 'btn-icon-shadow');
    button.innerHTML =
      '<span class="icon"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><g fill="none" fill-rule="evenodd"><g fill="currentColor"><path d="M13 3c1.598 0 2.904 1.249 2.995 2.824L16 6v1h3c.552 0 1 .448 1 1 0 .513-.386.936-.883.993L19 9h-1v9c0 1.598-1.249 2.904-2.824 2.995L15 21H9c-1.598 0-2.904-1.249-2.995-2.824L6 18V9H5c-.552 0-1-.448-1-1 0-.513.386-.936.883-.993L5 7h3V6c0-1.598 1.249-2.904 2.824-2.995L11 3h2zm3 6H8v9c0 .513.386.936.883.993L9 19h6c.513 0 .936-.386.993-.883L16 18V9zm-3-4h-2c-.513 0-.936.386-.993.883L10 6v1h4V6c0-.513-.386-.936-.883-.993L13 5z" /></g></g></svg></span>';
    return button;
  };

  addConnection = () => {
    this.jsPlumb.deleteEveryConnection();
    for (let edgeId in this.edges) {
      const connection = this.jsPlumb.connect({
        uuids: [_id(this.edges[edgeId].from), _id(this.edges[edgeId].to)],
        overlays: [
          [
            'Custom',
            {
              create: this.createOverlayButton,
              id: `overlay-${edgeId}`
            }
          ]
        ]
      });
      connection?.setParameter('id', edgeId);
    }
    const elementDone = document.createElement('div');
    elementDone.id = 'allDone';
    document.body.appendChild(elementDone);
  };

  saveConnection = async (connectionInfo: ConnectionMadeEventInfo) => {
    // @ts-ignore
    const sourceUuid = connectionInfo.sourceEndpoint.getUuid();
    // @ts-ignore
    const targetUuid = connectionInfo.targetEndpoint.getUuid();
    const isConnectionExist = Object.keys(this.edges).some((id) => {
      return _id(this.edges[id].from) === sourceUuid && _id(this.edges[id].to) === targetUuid;
    });
    if (!isConnectionExist) {
      try {
        const newEdge = await this.props.saveEdgeHandler(this.props.id, {
          from: sourceUuid.replace('_', ''),
          to: targetUuid.replace('_', '')
        });
        if (newEdge.data) {
          const edgeId = newEdge.data.id;
          connectionInfo.connection.setParameter('id', edgeId);
          connectionInfo.connection.addOverlay([
            'Custom',
            {
              create: this.createOverlayButton,
              id: `overlay-${edgeId}`
            }
          ]);
          this.edges[edgeId] = { from: sourceUuid.replace('_', ''), to: targetUuid.replace('_', ''), id: edgeId };
        }
      } catch (e) {
        console.log('%c⇒ e', 'color: #FF5370', e);
      }
    }
  };

  saveGeometry = () => {
    const geometryData = {
      geometry: geometryObjToArr(this.geometry)
    };
    this.props.saveGeometry(this.props.id, geometryData);
  };

  togglePopup = (category: CategoryNameType) => {
    this.setState((state) => {
      let showPopup;
      if (state.showPalette && this.currentElementCategory === category) {
        showPopup = false;
      } else if (state.showPalette && this.currentElementCategory !== category) {
        showPopup = true;
      } else {
        showPopup = !state.showPalette;
      }
      this.currentElementCategory = category;

      return {
        showPalette: showPopup
      };
    });
  };

  closePayload = () => {
    this.props.setPayloadShowing(false);
    this.setState({ selectedNode: null });
  };

  updateNode = (
    node: IFunnelNodeBuilder<PaletteElementTypes> | IAutomationNodeBuilder<PaletteAutomationElementTypes>
  ) => {
    if (node.type.search(/^event\./) === -1) {
      this.nodes[node.id] = node;
      this.forceUpdate();
    }
    this.jsPlumb.deleteEndpoint(`endpoint-${node.id}`);
    this.initNode(node);
  };

  dropElement = (elementName: NodeTypes, x: number, y: number, width: number, height: number) => {
    const headerOffsetY = 64;
    this.addElement(elementName, {
      x: x - (width / 2) * canvasOptions.scale,
      y: y - (height / 2) * canvasOptions.scale - headerOffsetY
    });
  };

  openEventPayload = (id: string) => {
    console.log('%c⇒ id', 'color: #89DDF7', id);
    this.currentNodeId = id;
    this.forceUpdate();
    this.props.setPayloadShowing(true);
  };

  handleDoubleClick = (event: React.SyntheticEvent) => {
    const nodeId = (event.currentTarget as Element).getAttribute('data-node-id');
    if (nodeId !== null) {
      this.currentNodeId = nodeId;
      this.forceUpdate();
      this.props.setPayloadShowing(true);
      this.setState({ selectedNode: this.nodes[nodeId] });
    }
  };

  handleClick = (event: React.SyntheticEvent) => {
    event.preventDefault();
    const nodeId = (event.currentTarget as Element).getAttribute('data-node-id');
    if (this.props.isPayloadShow) {
      this.props.setPayloadShowing(false);
    }
    if (!this.nodeDragged && nodeId) {
      this.removeSelection();
      // @ts-ignore
      jsPlumb.addClass(document.getElementById(_id(nodeId)), 'selected');
      this.jsPlumb.getEndpoints(_id(nodeId)).forEach((endpoint) => {
        // @ts-ignore
        endpoint.canvas.classList.add('selected');
        endpoint.connections?.forEach((conn) => {
          conn.endpoints.forEach((endpoint) => {
            // @ts-ignore
            endpoint.canvas.classList.add('selected');
          });
          // @ts-ignore
          conn.canvas.classList.add('selected');
        });
      });
      this.currentNodeId = nodeId;
    }
    this.nodeDragged = false;
  };

  handleNodeHover = (event: React.SyntheticEvent) => {
    const nodeId = (event.target as Element).getAttribute('data-node-id');

    if (nodeId) {
      document.querySelectorAll(`.endpoint-${nodeId}`).forEach((item) => {
        event.type === 'mouseenter' && item.classList.add('node-hover');
        event.type === 'mouseleave' && item.classList.remove('node-hover');
      });
    }
  };

  handleDeleteNode = async (id: string, event: React.SyntheticEvent) => {
    event.stopPropagation();
    this.closePayload();
    try {
      this.props.deleteNode(this.props.id, id);
      this.jsPlumb.getEndpoints(_id(id)).forEach((endpoint) => {
        endpoint.connections?.forEach((conn) => {
          const edgeId = conn.getParameter('id');
          delete this.edges[edgeId];
        });
      });
      this.selectedConnection = null;
      this.removeSelection();
      this.jsPlumb.remove(_id(id));
      // delete this.nodes[id];
      this.forceUpdate();
    } catch (e) {
      console.log('%c⇒ e', 'color: #FF5370', e);
    }
  };

  removeSelection = () => {
    // @ts-ignore
    this.selectedConnection?.getOverlay(`overlay-${this.selectedConnection?.getParameter('id')}`)?.setVisible(false);
    [].forEach.call(document.querySelectorAll('.selected'), (el: Element) => {
      el.classList.remove('selected');
    });
  };

  onCanvasClick = (event: React.SyntheticEvent) => {
    event.preventDefault();
    if ((event.target as Element).id === 'funnel-canvas') {
      this.removeSelection();
      if (this.state.showPalette) {
        this.setState({
          showPalette: false
        });
      }
    }
  };

  plumbZoom = (scale: number) => {
    this.jsPlumb.setZoom(scale);
  };

  isMoved = () => {
    this.setState((state) => ({ canvasMoved: state.canvasMoved !== undefined ? state.canvasMoved + 1 : 0 }));
  };

  update = (node: IFunnelNodeBuilder<PaletteElementTypes> | IAutomationNodeBuilder<PaletteAutomationElementTypes>) => {
    this.initNode(node);
    this.jsPlumb.getEndpoint(node.id) && this.jsPlumb.deleteEndpoint(`endpoint-${node.id}`);
    setTimeout(() => {
      this.jsPlumb.repaintEverything();
    }, 200);
  };

  updateContextNode = (
    node: IFunnelNodeBuilder<PaletteElementTypes> | IAutomationNodeBuilder<PaletteAutomationElementTypes>
  ) => {
    try {
      this.nodes[node.id] = node;
      let allEndpoints: string[] = [];
      Object.values(this.nodes).forEach((x) => {
        if (x.endpoints) {
          allEndpoints.push(...map(x.endpoints, 'id'));
        }
      });

      for (const [key, value] of Object.entries(this.edges)) {
        if (!allEndpoints.includes(value.from)) {
          delete this.edges[key];
        }
      }

      this.addEndpoints();
      this.initNode(this.nodes[node.id]);
      this.addConnection();
    } catch (error) {}
  };

  render() {
    const { isLoading, showPalette, canvasMoved } = this.state;
    const { isThumbnails = false, deleteNode, nodes, isPayloadShow } = this.props;
    this.allNodes = _.keyBy(nodes || [], 'id');

    return (
      <BuilderContext.Provider
        value={{
          selectedNode: this.state.selectedNode,
          updateNode: this.updateContextNode
        }}
      >
        <BuilderWrapper builderType={this.props.builderType} nodeId={this.currentNodeId}>
          <div className={classnames('card h-100', { full: isThumbnails })}>
            <div className="card-body p-0 dotted">
              {!isThumbnails && (
                <>
                  <PaletteNav
                    onClick={this.togglePopup}
                    showStatus={this.state.showPalette}
                    paletteNavItems={this.props.paletteNavItems}
                    id={this.props.id}
                  />
                  {showPalette && (
                    <PalettePopup
                      closePopup={this.togglePopup}
                      onDropElement={this.dropElement}
                      category={this.currentElementCategory}
                      elements={this.props.paletteElements}
                      builderType={this.props.builderType}
                      paletteElementTypes={this.props.paletteElementTypes}
                      paletteElementNames={this.props.paletteElementNames}
                    />
                  )}
                </>
              )}
              <TransformBuilder
                isLoading={isLoading}
                canvasOptions={canvasOptions}
                plumbZoom={this.plumbZoom}
                onCanvasClick={this.onCanvasClick}
                isMoved={this.isMoved}
                onSetCanvasOption={this.onSetCanvasOption}
              >
                {Object.keys(this.nodes).map((id) => {
                  if (this.nodes[id].type === PaletteAutomationElementTypes.CONTAINER_EVENTS) {
                    return (
                      <Events
                        key={id}
                        automationId={this.props.id}
                        hidePopup={canvasMoved}
                        deleteNode={deleteNode}
                        payload={this.openEventPayload}
                        node={this.nodes[id] as IAutomationNodeBuilder<PaletteAutomationElementTypes>}
                        update={this.update}
                        onHover={this.handleNodeHover}
                      />
                    );
                  }
                  return (
                    <CanvasNode
                      nodeId={id}
                      key={id}
                      left={this.geometry[id]?.position_x}
                      top={this.geometry[id]?.position_y}
                      builderType={this.props.builderType}
                      onClick={this.handleClick}
                      onDoubleClick={this.handleDoubleClick}
                      onHover={this.handleNodeHover}
                      onDelete={this.handleDeleteNode}
                    />
                  );
                })}
              </TransformBuilder>
            </div>
          </div>
          <FunnelStateMessage builderType={this.props.builderType} id={this.props.id} />
          {isPayloadShow && this.allNodes[this.currentNodeId] ? (
            <PayloadForm
              onClose={this.closePayload}
              onNodeUpdate={this.updateNode}
              initNode={this.initNode}
              node={this.allNodes[this.currentNodeId]}
              id={this.props.id}
              builderType={this.props.builderType}
            >
              <PayloadData
                initNode={this.initNode}
                node={this.allNodes[this.currentNodeId]}
                builderType={this.props.builderType}
                id={this.props.id}
              />
            </PayloadForm>
          ) : null}
        </BuilderWrapper>
      </BuilderContext.Provider>
    );
  }
}

const mapStateToProps: MapStateToProps<IMapStateToProps, {}, IAppState> = (state: IAppState) => ({
  nodes: state.builder.builder.nodes,
  isPayloadShow: state.settings.isPayloadShow
});

const mapDispatchToProps = (dispatch: CustomDispatch) => ({
  setNodes(
    nodes: Array<IFunnelNodeBuilder<PaletteElementTypes> | IAutomationNodeBuilder<PaletteAutomationElementTypes>>
  ) {
    return dispatch(builderActions.setNodes({ nodes }));
  },
  setNode(node: IFunnelNodeBuilder<PaletteElementTypes> | IAutomationNodeBuilder<PaletteAutomationElementTypes>) {
    return dispatch(builderActions.setNode({ node }));
  },
  setPayloadShowing(isPayloadShow: boolean) {
    return dispatch(settingsActions.setPayloadShowing({ isPayloadShow }));
  }
});

export default withRouter<any, any>( // TODO: describe types
  connect<IMapStateToProps, IMapDispatchToProps>(mapStateToProps, mapDispatchToProps)(Builder) as any
);
