import React, { useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Table } from '@tanstack/react-table';

import classNames from 'classnames';
import styles from './table-pagination.module.scss';

import { DOTS, usePagination } from '../../../../hooks/use-pagination';
import { useQueryParams } from '../../../../hooks/use-query-params';
import { Icon32AngleLeft, Icon32AngleRight } from '../../icons';

type Props<T> = {
  table: Table<T>;
};

export const TablePagination = <T extends unknown>({ table }: Props<T>) => {
  const params = useQueryParams();
  const { search } = useLocation();
  const history = useHistory();
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);
  // const itemsCount = useMemo(() => table.getPageCount(), [table]);
  const pageIndex = useMemo(() => Number(params.page), [params.page]);

  const totalPages = useMemo(() => table.getPageCount(), [table]);
  const paginationRange = usePagination({
    currentPage: pageIndex,
    totalCount: totalPages
  });

  if (totalPages === 1) {
    return null;
  }

  const onChangePage = (pageNumber: number) => {
    const page = pageNumber ? Number(pageNumber) : 0;
    table.setPageIndex(page - 1);
    searchParams.set('page', page.toString());
    history.replace({ search: searchParams.toString() });
  };

  /*const onChangePageInput = (e: React.SyntheticEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value && parseInt(e.currentTarget.value);
    if (value && !isNaN(value) && value <= itemsCount) {
      onChangePage(value);
    }
  };*/
  const onNextPage = () => onChangePage(pageIndex + 1);
  const onPrevPage = () => onChangePage(pageIndex - 1);

  return (
    <div className={styles.container}>
      <div className={styles.pagination}>
        <div
          className={classNames(styles.arrow, {
            [styles.inactive]: !table.getCanPreviousPage()
          })}
          onClick={onPrevPage}
        >
          <Icon32AngleLeft />
        </div>

        {paginationRange?.map((pageNumber, index) => {
          if (pageNumber === DOTS) {
            return (
              <div key={index} className={styles.dots}>
                {DOTS}
              </div>
            );
          }
          return (
            <div
              key={index}
              className={classNames(styles.button, {
                [styles.active]: pageNumber === pageIndex
              })}
              onClick={() => onChangePage(pageNumber as number)}
            >
              {pageNumber}
            </div>
          );
        })}

        <div
          className={classNames(styles.arrow, styles.arrowRight, {
            [styles.inactive]: !table.getCanNextPage()
          })}
          onClick={onNextPage}
        >
          <Icon32AngleRight />
        </div>
      </div>
    </div>
  );
};
