import classNames from 'classnames';
import styles from './quiz-status.module.scss';
import { Icon16CheckMark, Icon16Clock, Icon16Cross, Icon16EyeHide } from '../../../../../../../components/UI/icons';

import { AnswerSessionStatusEnum, QuizState } from '../../../../../../../interfaces/quiz';

interface IProps {
  status: QuizState;
  className?: string;
}

export const QuizStatus = ({ status, className }: IProps) => {
  let statusClass = 'fail';
  if (status === AnswerSessionStatusEnum.success) {
    statusClass = 'passed';
  }
  if (status === AnswerSessionStatusEnum.in_progress || status === AnswerSessionStatusEnum.pending_moderation) {
    statusClass = 'progress';
  }
  if (status === AnswerSessionStatusEnum.skipable) {
    statusClass = 'skipable';
  }

  return (
    <div className={classNames(styles.container, styles[statusClass], className)}>
      {status === AnswerSessionStatusEnum.in_progress && (
        <>
          In progress <Icon16Clock />
        </>
      )}
      {status === AnswerSessionStatusEnum.pending_moderation && (
        <>
          Pending moderation{' '}
          <div className={styles.icon}>
            <Icon16Clock />
          </div>
        </>
      )}
      {status === AnswerSessionStatusEnum.success && (
        <>
          Passed{' '}
          <div className={styles.icon}>
            <Icon16CheckMark />
          </div>
        </>
      )}
      {status === AnswerSessionStatusEnum.fail && (
        <>
          Fail{' '}
          <div className={styles.icon}>
            <Icon16Cross />
          </div>
        </>
      )}
      {status === AnswerSessionStatusEnum.skipable && (
        <>
          Skipped{' '}
          <div className={styles.icon}>
            <Icon16EyeHide />
          </div>
        </>
      )}
    </div>
  );
};
