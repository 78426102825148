import { LoadState } from '../../../store';
import { ICourse } from '../../lms/courses/store/CourseState';

export interface IAnalyticsCRM {
  contact_count: number;
  lists_count: number;
}
export interface IAnalyticsSchool {
  course_count: number;
  student_count: number;
}

export type IStatusDashboard = 'welcome_video' | 'payment_source_added' | 'onboarding_markquiz';

export interface IDashboard {
  'analytics.crm': IAnalyticsCRM;
  'analytics.school': IAnalyticsSchool;
  'events.payment_source_added_event': {
    have_card: boolean;
    is_done: boolean;
  };
  'events.welcome_video_event': {
    is_done: boolean;
    video: string;
  };
  'onboarding.markquiz': {
    is_done?: boolean;
  };
  courses: ICourse[];
}

export interface IDashboardState extends IDashboard {
  loadState: LoadState;
  error: Error | null;
}

export const DashboardInitialState: IDashboardState = {
  error: null,
  loadState: LoadState.firstLoad,
  'analytics.crm': {
    contact_count: 0,
    lists_count: 0
  },
  'analytics.school': {
    course_count: 0,
    student_count: 0
  },
  'events.payment_source_added_event': {
    have_card: true,
    is_done: true
  },
  'events.welcome_video_event': {
    is_done: false,
    video: ''
  },
  'onboarding.markquiz': {
    is_done: undefined
  },
  courses: []
};
