import React, { memo, useCallback, useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import ReactQuill from 'react-quill';
import { Delta, Sources } from 'quill';

import classNames from 'classnames';
import styles from './add-content.module.scss';

import { BubbleQuillEditor, UnprivilegedEditor } from '../../../../../components/quill-editor/BubbleQuillEditor';
import { PolicyContentTypeEnum } from '../../../../../interfaces/policy';
import { usePolicyContext } from '../../policy-item/PolicyContext';
import { LegalSettings } from '../legal-settings/LegalSettings';

interface IProps {
  isActive: boolean;
}

export const AddContent = memo(({ isActive }: IProps) => {
  const { setValue, register, watch } = useFormContext();
  const { onSelect } = usePolicyContext();

  const reactQuillRef = useRef<ReactQuill | null>(null);

  const onCLick = () => {
    onSelect('local');
  };

  const value = watch('content.delta');

  const onContentChange = useCallback(
    (content: string, delta: Delta, source: Sources, editor: UnprivilegedEditor) => {
      setValue('content.delta', editor.getContents(), { shouldDirty: true });
    },
    [setValue]
  );

  return (
    <div className={classNames(styles.container, isActive ? styles.active : styles.inactive)} onClick={onCLick}>
      <div className={styles.title}>
        <div className={'flex-grow-1'}>Add content</div>
        {isActive && <LegalSettings type={PolicyContentTypeEnum.local} />}
      </div>
      <div className={styles.description}>
        You can also paste your text to publish it in the student cabinet and wherever
      </div>

      {isActive && (
        <>
          <input type="hidden" {...register('content.delta', { shouldUnregister: true })} />
          <BubbleQuillEditor
            placeholder={'Description'}
            handleChange={onContentChange}
            ref={reactQuillRef}
            classNameContainer={styles.content}
            value={value}
          />
        </>
      )}
    </div>
  );
});
