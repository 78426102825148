import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Frame from 'react-frame-component';
import InnerHTML from 'dangerously-set-html-content';

import classNames from 'classnames';
import styles from './embed.module.scss';

import { getHTMLDocument } from '../../../../../helpers/dom-parser';
import { ICourseBlockEmbed } from '../../../../../interfaces';

interface IProps {
  block: ICourseBlockEmbed;
}
export const Embed = memo(({ block }: IProps) => {
  const embedIFrame = useRef<HTMLIFrameElement | null>(null);
  const [isNotVisibleContent, setIsNotVisibleContent] = useState(false);
  const [contentHeight, setContentHeight] = useState(0);

  const isHasIframe = useMemo(() => {
    if (block.code) {
      const document = getHTMLDocument(block.code);
      return !!document.querySelector('iframe');
    }
    return false;
  }, [block.code]);

  const setIframeHeight = useCallback(() => {
    const iframeRef = embedIFrame.current;
    if (iframeRef) {
      const div = iframeRef?.contentWindow?.document.querySelector('.embed-code') as HTMLElement;
      if (div) {
        const height = div.getBoundingClientRect().height || 0;
        setIsNotVisibleContent(height === 0);
        setContentHeight(height);
      }

      if (isHasIframe) {
        const innerIframe = iframeRef?.contentWindow?.document.querySelector('iframe') as HTMLIFrameElement;
        innerIframe?.addEventListener('load', () => {
          const height = div?.getBoundingClientRect().height || 0;
          setContentHeight(height);
          if (embedIFrame.current !== null) {
            const iframe = embedIFrame.current;
            iframe.style.height = `${height}px`;
          }
        });
      }
    }
  }, [isHasIframe]);

  const contentDidMount = useCallback(() => {
    setTimeout(() => {
      setIframeHeight();
    }, 300);
  }, [setIframeHeight]);

  return (
    <div className={styles.container}>
      <Frame
        className={classNames(styles.iframe, { [styles.h0]: isNotVisibleContent })}
        style={{ height: `${contentHeight}px`, overflow: 'hidden' }}
        initialContent={
          '<!DOCTYPE html><html lang="en"><head><title></title></head><body style="margin: 0;"><div></div></body></html>'
        }
        ref={embedIFrame}
        contentDidMount={contentDidMount}
      >
        <InnerHTML
          html={block.code || ''}
          className={'embed-code'}
          style={{ display: 'flex', flexDirection: 'column', width: '100%' }}
        />
      </Frame>
    </div>
  );
});
