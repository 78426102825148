import React from 'react';

import classNames from 'classnames';
import styles from './thumb-placeholder.module.scss';

interface IProps {
  title: string;
  icon: React.ReactElement;
}

export const ThumbPlaceholder = ({ title, icon }: IProps) => {
  return (
    <div className={styles.placeholder}>
      <div className={styles.circle}>{icon}</div>
      <div className={classNames(styles.title, 'ellipsis')}>{title}</div>
    </div>
  );
};
