import { CSSProperties, FC, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

import classNames from 'classnames';
import styles from './common-auth.module.scss';

import { OrganizationImg } from '../../../../layout/shared-components/organization-img/OrganizationImg';
import { StudentCabinetSelector } from '../../store/StudentCabinetSelector';

interface IProps {
  themeImage?: string | null;
  loaded?: boolean;
}

export const CommonAuth: FC<IProps> = ({ children, themeImage, loaded = true }) => {
  const cabinetSettingsMemo = useMemo(StudentCabinetSelector, []);
  const { cabinetSettings } = useSelector((state) => cabinetSettingsMemo(state));

  const stylesBGImage = useCallback((): CSSProperties | undefined => {
    if (!themeImage || !loaded) {
      return undefined;
    }

    return { backgroundImage: `url(${themeImage})` };
  }, [loaded, themeImage]);

  return (
    <div
      style={stylesBGImage()}
      className={classNames(styles.container, styles.bg, { [styles.bgDefault]: loaded && !themeImage })}
    >
      {loaded && cabinetSettings?.general_image && (
        <div className={styles.organization}>
          <OrganizationImg
            organizationImg={cabinetSettings?.general_image || null}
            organizationName={cabinetSettings?.general_title || ''}
            themeLogo={cabinetSettings?.theme_logo || null}
            height={32}
            width={32}
          />
          {cabinetSettings?.general_title}
        </div>
      )}
      <div className={classNames(styles.wrapper, { [styles.aligned]: loaded && cabinetSettings?.general_image })}>
        {children}
      </div>
    </div>
  );
};
