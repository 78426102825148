import React from 'react';

import classNames from 'classnames';
import styles from './page-top-panel.module.scss';

interface IProps {
  title?: string | JSX.Element;
  className?: string;
}

export const PageTopPanel: React.FC<IProps> = ({ title, className, children }) => {
  return (
    <div className={classNames('page-top mb-4', styles.container, className)}>
      <h2 className={styles.title}>{title}</h2>
      <div className={'d-flex mr-auto justify-content-end flex-grow-1 align-items-center'}>{children}</div>
    </div>
  );
};
