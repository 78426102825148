import React, { memo, useEffect, useMemo, useState } from 'react';
import { Form, FormControl } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import { AccountSelector } from '../../../../settings/account/store/AccountSelector';
import { courseActionAsync } from '../../store/CourseActionAsync';
import { ICourse } from '../../store/CourseState';

interface IProps {
  course?: ICourse;
}

export const CourseTitle = memo(({ course }: IProps) => {
  const [value, setValue] = useState(course?.title);
  const accountMemo = useMemo(AccountSelector, []);

  const { organizationId } = useSelector(accountMemo);
  const dispatch = useDispatch();

  useEffect(() => {
    if (course) {
      setValue(course.title);
    }
  }, [course]);

  const onSaveHandler = () => {
    if (course?.id && value) {
      dispatch(courseActionAsync.editCourse(course.id, value));
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.currentTarget.blur();
    }
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.currentTarget.value);
  };

  return (
    <Form.Group className={'w-100 mb-3'}>
      <Form.Label>Course name</Form.Label>
      <FormControl
        className={'form-control'}
        type={'text'}
        defaultValue={value}
        onBlur={onSaveHandler}
        onKeyDown={handleKeyDown}
        onChange={onChange}
      />
    </Form.Group>
  );
});
