import { memo } from 'react';
import moment from 'moment';

import classNames from 'classnames';
import styles from './unableSubscription.module.scss';

import { Button } from '../../../../components/UI';
import { ISubscription } from '../../../../interfaces';
import { CardIcon } from '../../../settings/common/payment-card/CardIcon';
import { Block } from '../block/Block';

interface IProps extends Pick<ISubscription, 'subscription_payment_source' | 'next_payment_attempt'> {
  onUpgradeHandler: () => void;
  nowDate: moment.Moment;
}

export const UnableSubscription = memo(
  ({ subscription_payment_source, next_payment_attempt, nowDate, onUpgradeHandler }: IProps) => {
    return (
      <Block className={classNames('p-4', styles.container)}>
        <div className={styles.wrapper}>
          <div className={styles.title}>We were unable to renew your subscription</div>
          <div className={styles.description}>
            Please check your credit card details or add new. <br /> We will try to renew the subscription after{' '}
            {next_payment_attempt && moment(next_payment_attempt).diff(nowDate, 'days')} days again.
          </div>

          <Button onClick={onUpgradeHandler} withIcon={false} className={styles.btn}>
            Update payment options
          </Button>
        </div>

        <div className={styles.cardContainer}>
          <div className={styles.cardWrapper}>
            <div className={styles.text}>
              Check your card <br /> information
            </div>
            <div className={styles.iconCard}>
              <CardIcon subscription_payment_source={subscription_payment_source} />
            </div>
          </div>
          <div className={styles.cardNumber}>×××× ×××× ×××× {subscription_payment_source?.last4}</div>
          <div className={styles.bgEllipse1} />
          <div className={styles.bgEllipse2} />
        </div>
      </Block>
    );
  }
);
