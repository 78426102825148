import { DeepRequired, FieldError, FieldErrorsImpl, Merge } from 'react-hook-form';

import classNames from 'classnames';
import styles from './error.module.scss';

interface IProps<T> {
  fieldName?: string | FieldError | Merge<FieldError, FieldErrorsImpl<DeepRequired<T>>> | undefined;
  customStyles?: string;
}

export const Error = <T extends string>({ fieldName, customStyles }: IProps<T>) => {
  return <div className={classNames(styles.error, customStyles)}>{fieldName}</div>;
};
