import { memo } from 'react';
import { IOffer as IOfferWidget,Widget } from '@demonvv/everact-widget';

import { useOfferWidget } from '../widget/use-offer-widget';

interface IProps {
  containerClassNames: string;
  wrapperClassName: string;
  widgetClassName: string;
  img: string;
  imgClassName: string;
  offerId: string;
  offer: any;
}

export const PreviewItem = memo(
  ({ containerClassNames, img, imgClassName, widgetClassName, wrapperClassName, offer, offerId }: IProps) => {
    const offerWidget = useOfferWidget(offer, null);

    return (
      <div className={containerClassNames}>
        <div className={wrapperClassName}>
          <div className={widgetClassName}>
            {offerWidget && (
              <Widget
                showBrand={false}
                widgetPlacement={'page'}
                offerId={offerId}
                offerData={offerWidget as IOfferWidget}
                activeStep={'cart'}
                setActiveStep={() => {}}
              />
            )}
          </div>
        </div>
        <img src={img} alt={'device'} className={imgClassName} />
      </div>
    );
  }
);
