import React from 'react';

import classNames from 'classnames';
import styles from './row-actions.module.scss';
import {
  IconArchive,
  IconDuplicate,
  IconMegaphone,
  IconMegaphoneOff,
  IconNewWindow,
  IconReload,
  IconTrash
} from '../../../../../components/UI/icons';

import { CustomTooltip } from '../../../../../components/UI';
import { IOffer, OfferState } from '../../store/OffersState';

interface IProps {
  cell: string;
  row: IOffer;
  restoreToDraft: (e: React.SyntheticEvent) => void;
  openInNewWindow: (e: React.SyntheticEvent) => void;
  duplicateOffer: (e: React.SyntheticEvent) => void;
  onDeleteOffer: (e: React.SyntheticEvent) => void;
  moveToArchive: (e: React.SyntheticEvent) => void;
  onPublicOffer: (e: React.SyntheticEvent) => void;
  onUnpublicOffer: (e: React.SyntheticEvent) => void;
}

export const RowActions = ({
  cell,
  row,
  restoreToDraft,
  openInNewWindow,
  duplicateOffer,
  moveToArchive,
  onDeleteOffer,
  onPublicOffer,
  onUnpublicOffer
}: IProps) => {
  let value: string = cell;
  // if (formatExtraData?.dataField === 'price') {
  //   const price = cell as unknown as { amount: string; currency: string };
  //   value = price.amount ? `${price.amount} ${price.currency}` : '0';
  // }
  const { state } = row;
  return (
    <div className={'d-flex align-items-center h-100 flex-grow-1'}>
      <div className="value">{value}</div>
      <div className={styles.buttons}>
        {state === OfferState.archived && (
          <CustomTooltip customText={'Restore to draft'} direction={'top'}>
            <button type={'button'} id={row.id} className={'btn btn-icon'} onClick={restoreToDraft}>
              <IconReload />
            </button>
          </CustomTooltip>
        )}
        {state === OfferState.draft && (
          <CustomTooltip customText={'Publish offer'} direction={'top'}>
            <button type={'button'} id={row.id} className={'btn btn-icon'} onClick={onPublicOffer}>
              <IconMegaphone />
            </button>
          </CustomTooltip>
        )}
        {state === OfferState.published && (
          <CustomTooltip customText={'Unpublish offer'} direction={'top'}>
            <button type={'button'} id={row.id} className={'btn btn-icon'} onClick={onUnpublicOffer}>
              <IconMegaphoneOff />
            </button>
          </CustomTooltip>
        )}
        {['published'].includes(state) && (
          <CustomTooltip customText={'Open in new window'} direction={'top'}>
            <button type={'button'} id={row.id} className={'btn btn-icon'} onClick={openInNewWindow}>
              <IconNewWindow />
            </button>
          </CustomTooltip>
        )}
        <CustomTooltip customText={'Duplicate'} direction={'top'}>
          <button type={'button'} id={row.id} className={'btn btn-icon'} onClick={duplicateOffer}>
            <IconDuplicate />
          </button>
        </CustomTooltip>
        {state === OfferState.archived && (
          <div>
            <CustomTooltip customText={'Remove'} direction={'top'}>
              <button
                type={'button'}
                id={row.id}
                className={classNames('btn btn-icon', styles.delete)}
                onClick={onDeleteOffer}
              >
                <IconTrash />
              </button>
            </CustomTooltip>
          </div>
        )}
        {['published', 'draft'].includes(state) && (
          <div>
            <CustomTooltip
              customText={state === OfferState.published ? 'Only drafted offers can be archived' : 'Archive'}
              direction={'top'}
            >
              <button
                type={'button'}
                id={row.id}
                className={classNames('btn btn-icon', styles.archive)}
                onClick={moveToArchive}
                disabled={state === OfferState.published}
              >
                <IconArchive />
              </button>
            </CustomTooltip>
          </div>
        )}
      </div>
    </div>
  );
};
