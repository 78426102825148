import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getVideoOrganizationUri, MediaProvider } from 'src/app/API/video-api';
import { VideoUploader } from 'src/app/components/file-upload/video-uploader/VideoUploader';
import { TypeOfUploader, VideoField } from 'src/app/interfaces/fileUpload';
import { fileUploaderActions } from 'src/app/layout/shared-components/upload-worker/store/FileUploaderActions';

import { AnswerTypeEnum, IAudioQuizAnswer, IQuizQuestion } from '../../../../../../../interfaces/quiz';
import { useQuizPayload } from '../../quiz-hooks/useQuizPayload';
import { quizActionAsync } from '../../store/QuizActionAsync';
import { useViewQuizContext } from '../../ViewQuizContext';
import { QuizAnswerBtn } from '../quiz-answer-btn/QuizAnswerBtn';

type Props = {
  questionData: IQuizQuestion;
};

export const VideoAnswer = ({ questionData }: Props) => {
  const { blockId, courseId, isPreview, onGetQuiz, onCompleteQuizInPreview, onChangeSelectAnswer } =
    useViewQuizContext();
  const data = useSelector((state) => state.fileUpload.files);
  const uploadedFile = data?.find((x) => x.courseId === courseId && x.parentBlockId === blockId);
  const { question } = useQuizPayload<AnswerTypeEnum.video_input>(questionData);
  const [disableBtn, setDisableBtn] = useState(!questionData.choices);

  const [uploadedVideo, setUploadedVideo] = useState<VideoField | undefined>(
    uploadedFile
      ? { type: TypeOfUploader.upload, video_url: uploadedFile.uploaderData.id }
      : questionData.choices
      ? { type: TypeOfUploader.upload, video_url: (questionData.choices as IAudioQuizAnswer).object_name }
      : undefined
  );
  const dispatch = useDispatch();
  const { studentCourses } = useSelector((state) => state.studentCourses);

  const onSave = (videoUrl: string) => {
    const video = {
      bucket_name: 'video',
      type: 'video',
      object_name: videoUrl
    };
    if (question.id && blockId && !isPreview) {
      dispatch(quizActionAsync.completeQuiz(courseId, blockId, question.id, 'quiz.answer', video, onGetQuiz));
    } else if (isPreview) {
      onChangeSelectAnswer(video);
      onCompleteQuizInPreview();
    }
  };

  const setVideoUploadsData = async (fileSelected: File | null) => {
    const selectedCourse = studentCourses.courses.find((course) => course.id === courseId);
    if (courseId && selectedCourse?.organization_id && fileSelected) {
      const vimeoData = await getVideoOrganizationUri(fileSelected, selectedCourse.organization_id);

      if (blockId && vimeoData) {
        setUploadedVideo({ type: TypeOfUploader.upload, video_url: vimeoData.link });
        dispatch(
          fileUploaderActions.setUploadFiles([
            {
              courseId: courseId,
              file: fileSelected,
              vimeoData,
              progress: 0,
              parentBlockId: blockId,
              uploaderData: {
                id: vimeoData.link,
                provider: MediaProvider.VIMEO,
                status: 'in_progress'
              }
            }
          ])
        );
      }
    }
  };

  const onRemoveVideo = () => {
    setUploadedVideo(undefined);
    setDisableBtn(true);
  };

  const onSetVideo = (video: VideoField) => {
    if (video.url) {
      setDisableBtn(false);
    }
  };

  useEffect(() => {
    if (uploadedFile?.uploaderData.status === 'complete') {
      setDisableBtn(false);
    }
  }, [uploadedFile?.uploaderData.status]);

  return (
    <>
      <VideoUploader
        setVideoUploadsDataOptional={setVideoUploadsData}
        video={uploadedVideo}
        onRemoveVideoOptional={onRemoveVideo}
        onSetVideoOptional={onSetVideo}
      />
      <div className={'px-0 mt-2'}>
        <QuizAnswerBtn
          isDisable={disableBtn}
          hasChoices={!!question.choices}
          onSaveAnswerHandler={() => uploadedFile && onSave(uploadedFile?.uploaderData.id)}
          type={'save'}
        />
      </div>
    </>
  );
};
