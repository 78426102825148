import { components, DropdownIndicatorProps } from 'react-select';

import { IOption } from '../../../interfaces';
import { IconAngleDown } from '../icons';

export const DropdownIndicatorLg = (props: DropdownIndicatorProps<IOption, false>) => {
  return (
    <components.DropdownIndicator {...props}>
      <IconAngleDown />
    </components.DropdownIndicator>
  );
};

export const DropdownIndicatorLgMulti = (props: DropdownIndicatorProps<IOption, true>) => {
  return (
    <components.DropdownIndicator {...props}>
      <IconAngleDown />
    </components.DropdownIndicator>
  );
};
