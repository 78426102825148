import React, { useEffect, useRef, useState } from 'react';
import { Col, FormGroup, FormLabel, Row } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';

import classNames from 'classnames';
import styles from './form-field.module.scss';

import { ModifyField } from '../../../modules/crm/field/store/FieldSelector';
import { CustomTooltip } from '../tooltip/CustomTooltip';
import { AddressField } from './address-field/AddressField';
import { DateField } from './date-field/DateField';
import { InputText } from './input-text/InputText';
import { PriceField } from './price-field/PriceField';
import { SelectField } from './select-field/SelectField';
import { TextMultiField } from './text-multi-field/TextMultiField';
import { ToggleField } from './toggle-field/ToggleField';

interface IProps {
  field: ModifyField;
}

export const FormField = ({ field }: IProps) => {
  const {
    formState: {
      errors,
    },
  } = useFormContext();
  const label = useRef<HTMLDivElement | null>(null);
  const [isShowTooltip, setIsShowTooltip] = useState(false);

  let control: JSX.Element = (
    <InputText
      name={field.id}
      placeholder={field.payload.title}
      params={{ disabled: field.payload.readonly }}
      maxLength={250}
      className={classNames({ disabled: field.payload.readonly })}
    />
  );

  const isHalfWidth = ['quantity', 'sku', 'price'].includes(field.scope);

  switch (field.type) {
    case 'long_text':
      control = <TextMultiField field={field} maxLength={50000} />;
      break;
    case 'url':
      control = <InputText name={field.id} placeholder={field.payload.title} />;
      break;
    case 'address':
      control = <AddressField field={field} />;
      break;
    case 'date':
      control = <DateField field={field} />;
      break;
    case 'date_time':
      control = <DateField field={field} />;
      break;
    case 'numeric':
      control = (
        <InputText name={field.id} placeholder={field.payload.title} params={{ type: 'number' }} maxLength={256} />
      );
      break;
    case 'toggle':
      control = <ToggleField field={field} />;
      break;
    case 'select':
      control = <SelectField field={field} />;
      break;
    case 'multiselect':
      control = <SelectField field={field} isMulti={true} />;
      break;
    case 'price':
      control = <PriceField field={field} />;
      break;
  }

  const isShowIcon = () => {
    return field.scope === 'custom' && field.typeElement?.showIcon;
  };

  useEffect(() => {
    if (label && label.current) {
      setIsShowTooltip(label.current?.scrollWidth > label.current?.offsetWidth);
    }
  }, [isShowTooltip]);

  return (
    <FormGroup as={Row}>
      <FormLabel column={'sm'} sm={4} className={styles.label}>
        <div ref={label} className={classNames(styles.label, styles.hide)}>
          {field.payload.title}
        </div>
        <CustomTooltip
          direction={'bottom-start'}
          customText={field.payload.title}
          className={styles.label}
          disabled={!isShowTooltip}
        >
          {field.payload.title}
          {field.type === 'price' ? (field.payload.currency ? `, ${field.payload.currency}` : '') : null}
        </CustomTooltip>
      </FormLabel>
      <Col sm={isHalfWidth ? 4 : 8} className={classNames({ 'has-icon': isShowIcon() })}>
        {isShowIcon() && <div className={styles.icon}>{field.typeElement?.icon}</div>}
        {control}
      </Col>
    </FormGroup>
  );
};
