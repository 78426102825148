import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import classNames from 'classnames';
import styles from './academy.module.scss';
import { IconInviteUser, IconPencilOnPaper } from '../../../../components/UI/icons';

import { getUUID } from '../../../../helpers';
import { OrganizationImg } from '../../../../layout/shared-components/organization-img/OrganizationImg';
import { InvitePeopleToOrganization } from '../../organization/invite/InvitePeopleToOrganization';
import { OrganizationActionAsync } from '../../organization/store/OrganizationActionAsync';
import { SettingsPaths } from '../../routes/SettingsRoutes';
import { UserPhoto } from './UserPhoto';

export const Academy = () => {
  const dispatch = useDispatch();
  const [invitePeople, setInvitePeople] = useState(false);
  const { general } = useSelector((state) => state.general);
  const { members } = useSelector((state) => state.organization);

  const onCloseInvitePeopleModal = useCallback(() => setInvitePeople(false), []);
  const onOpenInvitePeopleModal = useCallback(() => setInvitePeople(true), []);

  useEffect(() => {
    dispatch(OrganizationActionAsync.getOrganizationMembers());
  }, [dispatch]);

  const filterMembers = members.filter((x) => x.type !== 'invitation');

  return (
    <>
      <div className={styles.container}>
        <Link className={styles.editIcon} to={SettingsPaths.general}>
          <IconPencilOnPaper />
        </Link>

        <OrganizationImg
          organizationImg={general.general_image}
          organizationName={general.general_title}
          height={96}
          width={96}
          className={styles.containerImage}
          textClassName={styles.containerText}
        />
        <div className={classNames(styles.title, 'ellipsis')}>{general.general_title}</div>
        {general.region_country && general.region_state && (
          <div className={styles.location}>
            {general.region_country}, {general.region_state}
          </div>
        )}

        <div className={styles.iconContainer}>
          {filterMembers.map((member) => (
            <UserPhoto key={getUUID()} member={member} className={styles.user} />
          ))}

          <div className={styles.addUser}>
            {filterMembers.length > 5 && filterMembers.length < 10 && (
              <div className={styles.addUserText} onClick={onOpenInvitePeopleModal}>
                +{filterMembers.length - 5}
              </div>
            )}
            {filterMembers.length <= 5 && (
              <div onClick={onOpenInvitePeopleModal}>
                <IconInviteUser />
              </div>
            )}
            {filterMembers.length > 10 && (
              <div className={styles.addUserText} onClick={onOpenInvitePeopleModal}>
                9+
              </div>
            )}
          </div>
        </div>
      </div>
      <InvitePeopleToOrganization onClose={onCloseInvitePeopleModal} show={invitePeople} />
    </>
  );
};
