import { memo } from 'react';

import styles from './run-details.module.scss';

import { CloseButton, Loader } from '../../../../components/UI';
import { PayloadType, useCommonPayload } from '../../../../hooks/useCommonPayload';
import { Session } from '../common/session/Session';
import { useRunDetails } from '../libs/useRunDetails';

interface IProps {
  contactId: string;
}

export const RunDetails = memo(({ contactId }: IProps) => {
  const [, , hideDetails] = useCommonPayload(PayloadType.runDetails);
  const { loading, runEvents } = useRunDetails(contactId);

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        Details
        <CloseButton onClose={hideDetails} />
      </div>
      <div className={styles.list}>
        {loading ? <Loader /> : runEvents?.map((event) => <Session key={event.id} event={event} />)}
      </div>
    </div>
  );
});
