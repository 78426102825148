import { useHistory } from 'react-router-dom';

import classNames from 'classnames';
import styles from './course-item.module.scss';
import { IconChevronRight, IconCircle } from '../../../../components/UI/icons';

import { ThumbnailPlaceholderImage } from '../../../../components/UI/image-components/thumbnail-placeholder-image/ThumbnailPlaceholderImage';
import { IGeneralSettings } from '../../../../interfaces';
import { OrganizationImg } from '../../../../layout/shared-components/organization-img/OrganizationImg';
import { courseCategoryList } from '../../../lms/courses/settings/course-category/CourseCategory';
import { ICourse, ICourseSettings } from '../../../lms/courses/store/CourseState';
import { StudentCabinetPaths } from '../../routes/StudentCabinetRoutes';
import { CourseItemInfo } from './course-count-item/CourseItemInfo';
import { CourseItemBtn } from './course-item-btn/CourseItemBtn';

interface IProps
  extends Pick<ICourse, 'last_lesson' | 'title' | 'id' | 'lessons_pass' | 'lessons_counts'>,
    Pick<IGeneralSettings, 'general_image' | 'general_title' | 'theme_logo'>,
    Pick<ICourseSettings, 'category' | 'thumbnail'> {}

export const CourseItem = (props: IProps) => {
  const {
    id,
    thumbnail,
    lessons_counts,
    lessons_pass,
    last_lesson,
    general_title,
    general_image,
    theme_logo,
    category,
    title
  } = props;
  const history = useHistory<{ state: string }>();

  const onLinkToCourse = () => {
    history.push(StudentCabinetPaths.courseStudentLessons(id), {
      state: id
    });
  };

  return (
    <div className={styles.container} onClick={onLinkToCourse}>
      <div className={styles.inner}>
        <div className={styles.imgContainer}>
          <ThumbnailPlaceholderImage width={288} height={162} thumbnailCourse={thumbnail || null} />
          {category && (
            <div className={styles.category}>
              {courseCategoryList[category].icon}
              <span className={'d-none d-sm-flex'}>{courseCategoryList[category].name}</span>
            </div>
          )}
        </div>
        <div className={styles.info}>
          <CourseItemInfo countLesson={lessons_counts} passedLesson={lessons_pass} />
          <div className={classNames(styles.name, 'ellipsis')}>{title}</div>
          <div className={styles.organization}>
            {general_image || theme_logo ? (
              <OrganizationImg
                organizationImg={general_image}
                organizationName={general_title}
                themeLogo={theme_logo}
                height={32}
                width={32}
                textClassName={styles.orgText}
                className={styles.orgImg}
              />
            ) : (
              <IconCircle height={32} width={32} />
            )}
            <div className={styles.orgName}>{general_title}</div>
          </div>
          <CourseItemBtn
            countLesson={lessons_counts}
            passedLesson={lessons_pass}
            lastLessonTitle={last_lesson?.title}
          />
        </div>
        <div className={classNames('d-sm-none', styles.nextBtn)}>
          <IconChevronRight />
        </div>
      </div>
    </div>
  );
};
