import { Tag } from 'react-tag-autocomplete-fix';
import { createSelector } from 'reselect';
import _ from 'lodash';

import { IAppState } from '../../../../store';
import { ITag } from './TagState';

export const iTagToTag = (tags: ITag[]) => {
  let tagsOmit = _.map(
    tags,
    _.partial(_.omit, _, ['background_color', 'foreground_color', 'date_create', 'date_update', 'organization_id'])
  ) as { id: string | number; title: string }[];

  return _.map(tagsOmit, ({ id, title }) => ({ id, name: title }));
};

export const TagsSelector = () =>
  createSelector(
    (state: IAppState) => state.tags.tags,
    (tags) => {
      return iTagToTag(tags) as Tag[];
    }
  );

export const TagByIdSelector = () =>
  createSelector(
    (_: IAppState, tag: Tag | undefined) => tag,
    (state: IAppState) => state.tags.tags,
    (tag, tags) => {
      return tag && tags.find((x) => x.id === tag.id);
    }
  );
