import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { createColumnHelper } from '@tanstack/react-table';
import moment from 'moment';

import classNames from 'classnames';
import styles from '../runs-list/runs-list.module.scss';
import { IconChevronRight } from '../../../../components/UI/icons';

import { ElementThumb } from '../../../../components/illustrations/ElementThumb';
import { ItemStatus } from '../../../../components/itemStatus/ItemStatus';
import { PayloadType, useCommonPayload } from '../../../../hooks/useCommonPayload';
import { usePayload } from '../../../../hooks/usePayload';
import { IRun } from '../../../../interfaces';
import { ContactListActionAsync } from '../../../crm/contact/store/ContactListActionAsync';
import { automationPaths } from '../../routes/AutomationRoutes';

const columnHelper = createColumnHelper<IRun>();

export const useRunsTable = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { showPayload } = usePayload();
  const [, showDetails, hideDetails] = useCommonPayload(PayloadType.runDetails);
  const [contactId, setContactId] = useState<string>();

  const openContactCard = useCallback(
    (run: IRun) => {
      if (!run.contact.is_deleted) {
        dispatch(
          ContactListActionAsync.getContact(run.contact.id, () => {
            setContactId(run.contact.id);
          })
        );
        hideDetails();
        showPayload();
      }
    },
    [dispatch, hideDetails, showPayload]
  );

  const openDetails = useCallback(
    (run: IRun) => {
      setContactId(run.contact.id);
      showDetails();
    },
    [showDetails]
  );

  const onOpenWorkflow = useCallback(
    (id: string) => {
      history.push(automationPaths.automationListId(id));
    },
    [history]
  );

  const columns = useMemo(
    () => [
      columnHelper.accessor((row) => row.contact.scoped_fields.email, {
        id: 'contact',
        header: 'Contact name',
        cell: (props) =>
          props.row.original.contact.is_deleted ? (
            <>
              <div className={styles.deleted}>{props.getValue()}</div>
              <div className={styles.red}>Deleted contact</div>
            </>
          ) : (
            <div onClick={() => openContactCard(props.row.original)} className={styles.contact}>
              <div className={styles.email}>{props.getValue()}</div>
              <div>{`${props.row.original.contact.scoped_fields.first_name} ${props.row.original.contact.scoped_fields.last_name}`}</div>
            </div>
          )
      }),
      columnHelper.accessor((row) => row.automation.title, {
        id: 'workflow',
        header: 'Workflow',
        cell: (props) => (
          <div className={styles.workflow} onClick={() => onOpenWorkflow(props.row.original.automation.id)}>
            {props.getValue()}
          </div>
        )
      }),
      columnHelper.accessor((row) => row.created_at, {
        id: 'date',
        header: 'Started date',
        cell: (props) => moment(props.getValue()).format('DD MMM yyyy, HH:mm')
      }),
      columnHelper.accessor((row) => row.status, {
        id: 'status',
        header: 'Status',
        cell: (props) => (
          <ItemStatus
            label={props.getValue()}
            className={classNames(
              { published: props.getValue() === 'done' },
              { draft: props.getValue() === 'running' },
              { archived: props.getValue() === 'failed' },
              styles.status
            )}
          />
        )
      }),
      columnHelper.accessor((row) => row.last_event.created_at, {
        id: 'last',
        header: 'Last actions',
        cell: (props) => (
          <div className={styles.lastActions} onClick={() => openDetails(props.row.original)}>
            <div className={styles.icon}>
              <ElementThumb name={props.row.original.last_event.node_type} builderType={'automation'} size={[48, 48]} />
            </div>
            {moment(props.getValue()).format('DD MMM yyyy, HH:mm')}
            <IconChevronRight className={styles.chevron} />
          </div>
        )
      })
    ],
    [onOpenWorkflow, openContactCard, openDetails]
  );

  return { columns, contactId, setContactId };
};
