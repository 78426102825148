import { memo } from 'react';

import styles from './create-policy.module.scss';
import { Icon32Plus } from '../../../../../components/UI/icons';

interface IProps {
  onCLick(): void;
}

export const CreatePolicy = memo(({ onCLick }: IProps) => {
  return (
    <div className={styles.container} onClick={onCLick}>
      <div className={styles.icon}>
        <Icon32Plus />
      </div>
      Create new legal section
    </div>
  );
});
