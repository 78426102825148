import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import styles from './payment-history.module.scss';

import { PaymentRow } from '../../common/payment-row/PaymentRow';
import { SettingsCollapseBlock } from '../../common/settings-collapse-block/SettingsCollapseBlock';
import { SettingCourseActionAsync } from '../../store/SettingCourseActionAsync';
import { IPaymentHistory } from '../../store/SettingCourseState';

export const PaymentHistory = () => {
  const dispatch = useDispatch();
  const [paymentHistory, setPaymentHistory] = useState<IPaymentHistory>();
  useEffect(() => {
    dispatch(
      SettingCourseActionAsync.getPaymentHistory((history) => {
        setPaymentHistory(history);
      })
    );
  }, [dispatch]);

  return (
    <SettingsCollapseBlock title={'Payment History'}>
      <div className={styles.container}>
        {paymentHistory?.upcoming && <PaymentRow payment={paymentHistory?.upcoming} />}
        {paymentHistory?.invoices?.map((invoice) => (
          <PaymentRow payment={invoice} key={invoice.id} />
        ))}
      </div>
    </SettingsCollapseBlock>
  );
};
