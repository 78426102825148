import { reducerWithInitialState } from 'typescript-fsa-reducers';
import _, { find, flatMapDeep } from 'lodash';

import { LoadState, newState } from '../../../../store';
import { courseActions } from './CourseActions';
import { CourseInitialState, ICourseNode, ICourseState } from './CourseState';

const flatten = (item: ICourseNode): any => {
  return [item, _.flatMapDeep(item.children, flatten)];
};

export const update = (id: string, node: ICourseNode) => (obj: ICourseNode) => {
  if (obj.id === id) {
    return (obj.is_passed = node.is_passed);
  } else if (obj.children.length > 0) {
    return obj.children.forEach(update(id, node));
  }
};

const commonFirstLoadHandler = (state: ICourseState) => ({ ...state, loadState: LoadState.firstLoad, error: null });
const commonErrorHandler = (state: ICourseState, { error }: { error: Error }) => ({
  ...state,
  loadState: LoadState.error,
  error
});

export const courseReducer = reducerWithInitialState<ICourseState>(CourseInitialState)
  .case(courseActions.getCourses.started, commonFirstLoadHandler)
  .case(courseActions.getCourses.done, (state, { result }) => {
    return newState(state, {
      courses: result,
      loadState: LoadState.allIsLoaded,
      error: null
    });
  })
  .case(courseActions.getCourses.failed, commonErrorHandler)

  .case(courseActions.createCourse.started, commonFirstLoadHandler)
  .case(courseActions.createCourse.done, (state, { result }) => {
    return newState(state, {
      loadState: LoadState.allIsLoaded,
      error: null,
      courses: [...state.courses, { ...result }]
    });
  })
  .case(courseActions.createCourse.failed, commonErrorHandler)

  .case(courseActions.getCourseHierarchy.started, commonFirstLoadHandler)
  .case(courseActions.getCourseHierarchy.done, (state, { result, params }) => {
    const updatedCourse = state.courses.map((course) => {
      if (course.id === params.course_id) {
        course.children = result;
      }
      return course;
    });

    return newState(state, {
      courses: updatedCourse,
      loadState: LoadState.allIsLoaded,
      error: null
    });
  })
  .case(courseActions.getCourseHierarchy.failed, commonErrorHandler)

  .case(courseActions.createNode.started, commonFirstLoadHandler)
  .case(courseActions.createNode.done, (state) => {
    return newState(state, {
      loadState: LoadState.needLoad,
      error: null
    });
  })
  .case(courseActions.createNode.failed, commonErrorHandler)

  // .case(courseActions.editNode.started, (state) => ({
  //   ...state,
  //   error: null,
  //   reorderLoadState: LoadState.firstLoad
  // }))
  .case(courseActions.editNode.done, (state, { result }) => {
    const newLectures = state.lectures.map((lecture) => (lecture.id === result.id ? result : lecture));
    return newState(state, {
      ...state,
      lectures: newLectures,
      reorderLoadState: LoadState.allIsLoaded
    });
  })
  .case(courseActions.editNode.failed, (state: ICourseState, { error }: { error: Error }) => ({
    ...state,
    reorderLoadState: LoadState.error,
    error
  }))

  .case(courseActions.deleteNode.started, commonFirstLoadHandler)
  .case(courseActions.deleteNode.done, (state) => {
    return newState(state, {
      ...state,
      loadState: LoadState.needLoad
    });
  })
  .case(courseActions.deleteNode.failed, commonErrorHandler)

  .case(courseActions.getPayloadBlock.started, commonFirstLoadHandler)
  .case(courseActions.getPayloadBlock.done, (state, { result }) => {
    return newState(state, {
      ...state,
      payload: result,
      loadState: LoadState.allIsLoaded,
      error: null
    });
  })
  .case(courseActions.getPayloadBlock.failed, commonErrorHandler)

  .case(courseActions.getLesson.started, commonFirstLoadHandler)
  .case(courseActions.getLesson.done, (state, { result }) => {
    return newState(state, {
      ...state,
      lectures: result,
      loadState: LoadState.allIsLoaded,
      error: null
    });
  })
  .case(courseActions.getLesson.failed, commonErrorHandler)

  .case(courseActions.editCourseSettings.started, (state) => ({
    ...state,
    error: null,
    reorderLoadState: LoadState.firstLoad
  }))
  .case(courseActions.editCourseSettings.done, (state, { result }) => {
    const updatedCourses = state.courses.map((x) => (x.id === result.id ? result : x));
    return newState(state, {
      courses: [...updatedCourses],
      error: null,
      loadState: LoadState.allIsLoaded
    });
  })
  .case(courseActions.editCourseSettings.failed, (state: ICourseState, { error }: { error: Error }) => ({
    ...state,
    reorderLoadState: LoadState.error,
    error
  }))
  .case(courseActions.updateCourseState.started, commonFirstLoadHandler)
  .case(courseActions.updateCourseState.done, (state, { result, params }) => {
    let newStateElements;
    if (params.state === 'deleted') {
      newStateElements = state.courses.filter((x) => x.id !== params.courseId);
    } else {
      newStateElements = state.courses.map((x) => (x.id === result.id ? { ...x, state: result.state } : x));
    }

    return newState(state, {
      loadState: params.state === 'published' ? LoadState.idle : LoadState.allIsLoaded,
      error: null,
      courses: newStateElements
    });
  })
  .case(courseActions.updateCourseState.failed, commonErrorHandler)
  .case(courseActions.showPopupIfParentDraft, (state, result) => {
    return newState(state, {
      ...state,
      showPopupIfParentDraft: result
    });
  })
  .case(courseActions.updateCourseHierarchyForPreviewMode, (state, result) => {
    const updatedCourses = state.courses.map((x) => (x.id === result.id ? result : x));
    return newState(state, {
      ...state,
      courses: updatedCourses
    });
  })
  .case(courseActions.setStatusNodePreview, (state, result) => {
    const lesson = find<ICourseNode | undefined>(flatMapDeep(result.course.children, flatten), ['id', result.lessonId]);
    if (lesson) {
      result.course.children.forEach(update(lesson.id, { ...lesson, is_passed: true }));
    }
    const updatedCourses = state.courses.map((x) => (x.id === result.course.id ? result.course : x));
    return newState(state, {
      ...state,
      courses: updatedCourses
    });
  });
