import classNames from 'classnames';
import styles from './error-file-select.module.scss';
import { Icon32Warning } from '../UI/icons';

import { Button } from '../UI';

interface IProps {
  onOK: () => void;
  title: string;
  description: string;
  className?: string;
}

export const ErrorFileSelect = ({ title, description, onOK, className }: IProps) => {
  return (
    <div className={classNames(styles.container, className)}>
      <Icon32Warning className={styles.icon} />
      <div className={styles.title}>{title}</div>
      <div className={styles.description}>{description}</div>
      <Button onClick={onOK} btnStyle={'secondary'} withIcon={false} className={'px-7 mt-3'}>
        Ok
      </Button>
    </div>
  );
};
