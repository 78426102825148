import { ReactNode } from 'react';
import { ModalProps } from 'react-bootstrap';

import classNames from 'classnames';
import styles from './modal.module.scss';

import { Button } from '../button/Button';
import { ModalActionComponent } from './ModalActionComponent';

interface IProps {
  isShowed: boolean;
  title: ReactNode;
  description: string | ReactNode;
  modalParams?: ModalProps;
  text: string;
  onClose(): void;

  onUnpublish(): void;
}

export const UnpublishModal = ({ onClose, isShowed, onUnpublish, description, title, modalParams, text }: IProps) => {
  return (
    <ModalActionComponent
      iconType={'warning'}
      show={isShowed}
      title={title}
      onCloseModal={onClose}
      modalParams={modalParams}
      iconStyles={'text-warning'}
    >
      <div className={styles.description}>{description}</div>
      <div className={styles.buttons}>
        <button type="button" className={classNames('btn btn-md ml-auto', styles.cancel)} onClick={onClose}>
          Cancel
        </button>
        <Button onClick={onUnpublish} withIcon={false}>
          {text}
        </Button>
      </div>
    </ModalActionComponent>
  );
};
