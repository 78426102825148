import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { useIntercom } from 'react-use-intercom';

import { IconClick, IconInfo, IconProcessor, IconText } from '../../../components/UI/icons';

import { addAutomationNode, saveAutomationEdge } from '../../../API/automation-api';
import { getAutomation } from '../../../API/automations-api';
import palette from '../../../components/config/paletteAutomation.json';
import {
  CategoryNameType,
  EdgeRequest,
  GeometryRequest,
  NodeTypes,
  PaletteAutomationElementNames,
  PaletteAutomationElementTypes
} from '../../../interfaces';
import Builder from '../../common/builder/Builder';
import { builderActionAsync } from '../../common/builder/store/BuilderActionAsync';
import { automationPaths } from '../routes/AutomationRoutes';

const paletteNavItems: { category: CategoryNameType; icon: JSX.Element }[][] = [
  [
    {
      category: 'actions',
      icon: <IconClick />
    },
    {
      category: 'processors',
      icon: <IconProcessor />
    }
  ],
  [
    {
      category: 'text',
      icon: <IconText />
    },
    {
      category: 'info',
      icon: <IconInfo />
    }
  ]
];
const paletteElements = palette.palette.category;

export const AutomationBuilder = () => {
  const dispatch = useDispatch();
  const { update } = useIntercom();
  const path = useRouteMatch<{ id: string }>(automationPaths.automationListId());

  const { isPayloadShow } = useSelector((state) => state.settings);

  useEffect(() => {
    update({
      horizontalPadding: isPayloadShow ? 388 : 20
    });
  }, [isPayloadShow, update]);

  useEffect(() => {
    return () => {
      update({
        horizontalPadding: 20
      });
    };
  }, [update]);

  const addNode = (id: string, types: NodeTypes) => {
    dispatch(builderActionAsync.addNode(id, types, 'automation'));
  };
  const deleteEdge = (id: string, edgeKey: string) => {
    dispatch(builderActionAsync.deleteEdge(id, edgeKey, 'automation'));
  };
  const deleteNode = (id: string, nodeKey: string) => {
    dispatch(builderActionAsync.deleteNode(id, nodeKey, 'automation'));
  };
  const saveEdge = (id: string, edge: EdgeRequest) => {
    dispatch(builderActionAsync.saveEdge(id, edge, 'automation'));
  };
  const saveGeometry = (id: string, data: GeometryRequest) => {
    dispatch(builderActionAsync.saveGeometry(id, data, 'automation'));
  };
  const getAutomationDetail = async (id: string) => {
    return await getAutomation(id);
  };
  const addAutomationNodeHandler = (id: string, nodeType: NodeTypes) => {
    return addAutomationNode(id, nodeType);
  };
  const saveAutomationEdgeHandler = (id: string, edge: EdgeRequest) => {
    return saveAutomationEdge(id, edge);
  };

  return (
    <>
      {path?.params.id && (
        <Builder
          id={path.params.id}
          currentElementCategoryDefault={'actions'}
          paletteNavItems={paletteNavItems}
          paletteElements={paletteElements}
          paletteElementTypes={PaletteAutomationElementTypes}
          paletteElementNames={PaletteAutomationElementNames}
          addNode={addNode}
          addNodeHandler={addAutomationNodeHandler}
          saveEdgeHandler={saveAutomationEdgeHandler}
          deleteEdge={deleteEdge}
          deleteNode={deleteNode}
          getDetailData={getAutomationDetail}
          saveEdge={saveEdge}
          saveGeometry={saveGeometry}
          builderType={'automation'}
        />
      )}
    </>
  );
};
