import { memo } from 'react';
import { useDispatch } from 'react-redux';

import styles from './step-three.module.scss';
import { Icon32Warning } from '../../../../../components/UI/icons';

import { Button } from '../../../../../components/UI';
import { billingActionAsync } from '../../../billing/store';
import { UnsubscribeSteps } from '../CancelSubscription';

interface IProps {
  onCancel(): void;

  onNext(step: UnsubscribeSteps): void;
}

export const StepThree = memo(({ onCancel, onNext }: IProps) => {
  const dispatch = useDispatch();
  const nextStep = () => {
    onNext('step-4');
  };

  const downgradeToFree = () => {
    dispatch(
      billingActionAsync.updateSubscription('everact_month_free', () => {
        onCancel();
      })
    );
  };

  return (
    <>
      <div className={styles.title}>
        <Icon32Warning width={64} height={64} />
        Caution all your information will be deleted
      </div>
      <div className={styles.container}>
        <div className={styles.text}>
          Did you know that you can downgrade your account and it will prevent your information from being deleted?
        </div>
        <Button onClick={downgradeToFree} size={'lg'} withIcon={false} className={'mb-2 w-100'}>
          Downgrade my account to a Free Plan
        </Button>
        <Button onClick={nextStep} size={'lg'} withIcon={false} className={'w-100'} btnStyle={'transparent'}>
          Not interested, cancel my account
        </Button>
      </div>
    </>
  );
});
