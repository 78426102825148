import { memo, useCallback, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import classNames from 'classnames';
import styles from './recovery-form-wrapper.module.scss';
import CodeLockImage from '../../../../../assets/images/codeLock.png';

import { Button, Toast } from '../../../../components/UI';
import { useAction } from '../../../../hooks/use-action';
import { IRecoveryPassword } from '../../../../interfaces';
import { StudentCabinetPaths } from '../../../student-cabinet/routes/StudentCabinetRoutes';
import { AuthActionAsync } from '../../store/AuthActionAsync';
import { RecoveryForm } from '../recovery-form/RecoveryForm';

const schema = yup.object().shape({
  password: yup.string().max(250).min(8).matches(/[A-Z]/, 'uppercase').matches(/[0-9]/, 'number').required(''),
  repeatPassword: yup
    .string()
    .oneOf([yup.ref('password'), null], 'Passwords must match')
    .required('')
});

interface IProps {
  onSuccessScreen: () => void;
}

export const RecoveryFormWrapper = memo(({ onSuccessScreen }: IProps) => {
  const [loading, setLoading] = useState(false);
  const [showRules, setShowRules] = useState(false);
  const dispatch = useDispatch();
  const { actionHash, action } = useAction();
  const history = useHistory();

  const methods = useForm<IRecoveryPassword>({
    resolver: yupResolver(schema),
    mode: 'onSubmit',
    reValidateMode: 'onSubmit'
  });
  const { handleSubmit, getValues } = methods;

  const onError = () => {
    Toast('error', 'Something went wrong, please try again');
  };

  const onUpdate = () => {
    setLoading(true);
    if (action === 'password_recovery' && actionHash) {
      const { password } = getValues();

      dispatch(
        AuthActionAsync.resetPassword(actionHash, password, onSuccessScreen, (error) => {
          if (error.data?.status === 'error.action_already_used') {
            history.push(StudentCabinetPaths.auth);
          }
        })
      );
    } else {
      onError();
    }
  };

  const onFocusHandler = useCallback(() => {
    setShowRules(true);
  }, []);

  const onBlurHandler = useCallback(() => {
    setShowRules(false);
  }, []);

  return (
    <FormProvider {...methods}>
      <div className={styles.container}>
        <img src={CodeLockImage} alt={'code lock'} className={styles.img} />
        <div className={styles.text}>Create a new password</div>
      </div>
      <RecoveryForm
        isLoading={loading}
        showRules={showRules}
        onBlurHandler={onBlurHandler}
        onFocusHandler={onFocusHandler}
      />
      <Button
        withIcon={false}
        onClick={handleSubmit(onUpdate)}
        size={'lg'}
        className={classNames('w-100 mt-4', { 'btn-loading': loading })}
        loading={loading}
      >
        Update password
      </Button>
    </FormProvider>
  );
});
