import styles from './drip-student.module.scss';

interface IProps {
  month: string;
  day: string;
}
export const DripStudentDate = ({ day, month }: IProps) => {
  return (
    <div className={styles.containerDate}>
      <div className={styles.containerCalendar}>
        <div className={styles.month}>{month}</div>
        <div className={styles.day}>{day}</div>
      </div>
    </div>
  );
};
