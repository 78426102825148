import classNames from 'classnames';
import styles from '../common/upload-common.module.scss';

import { PercentLoader } from '../../../UI/percent-loader/PercentLoader';

interface IProps {
  progress: number;
  onCancelUpload: () => void;
  fileSize: number;
  className?: string;
}

export const FileUpload = ({ onCancelUpload, progress, fileSize, className }: IProps) => {
  return (
    <div className={classNames(styles.inputDnD, className)}>
      <PercentLoader percent={progress} onCancel={onCancelUpload} />
      <div className={styles.uploadText}>
        Uploaded {(fileSize * (progress / 100)).toFixed(2)} MB of {fileSize.toFixed(2)} MB
      </div>
    </div>
  );
};
