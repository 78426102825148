import React, { memo } from 'react';
import { FormControl } from 'react-bootstrap';

import classNames from 'classnames';
import styles from '../../../styles/components/search.module.scss';
import { Icon16Cross, IconPlus, IconSearch } from '../UI/icons';

interface IProps {
  placeholder?: string;
  isPage?: boolean;
  className?: string;
  showClear?: boolean;
  value: string;
  inputClassName?: 'form-control-sm' | 'form-control-md';
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onClear?: () => void;
}

export const Search = memo(
  ({
    className,
    isPage,
    placeholder,
    value,
    showClear,
    inputClassName = 'form-control-sm',
    onChange,
    onClear
  }: IProps) => {
    return (
      <div className={classNames(styles.search, className)}>
        <div className="input-group">
          {isPage && (
            <div className={classNames(styles.prepend, inputClassName)}>
              <button className={classNames('btn', styles.button)} type="button">
                <IconSearch />
              </button>
            </div>
          )}
          <FormControl
            type="text"
            className={classNames(
              'form-control',
              styles.searchInput,
              { [styles.leftButton]: isPage },
              { [styles.bordered]: isPage },
              { [styles.filled]: isPage && showClear },
              inputClassName
            )}
            placeholder={placeholder}
            onChange={onChange}
            value={value}
            autoComplete={'search'}
          />
          {!isPage && (
            <div className={classNames('input-group-append', styles.append)}>
              <button className={classNames('btn', styles.button)} type="button">
                <IconSearch />
              </button>
            </div>
          )}

          {showClear && (
            <button
              className={classNames('btn', styles.clear, { [styles.leftButton]: isPage })}
              type="button"
              onClick={onClear}
            >
              <Icon16Cross />
            </button>
          )}
        </div>
      </div>
    );
  }
);
