import { actionCreatorFactory } from 'typescript-fsa';

import { EmailAction } from '../../../API/action-email-api';
import {
  ChangePasswordResponse,
  ConfirmEmailRequest,
  ConfirmEmailResponse,
  IAccountOrganization,
  IChangePassword,
  ITokenRequest,
  RegisterUserRequest,
  RegisterUserResponse,
  ResetPasswordRequest
} from '../../../interfaces';

export const actionCreator = actionCreatorFactory();

export const AuthActions = {
  auth: actionCreator.async<{ request?: ITokenRequest }, string | undefined, Error>('AUTH'),
  getGoogleUrl: actionCreator.async<
    { state: string | null; redirect_url: string; scopes?: string[] },
    { url: string },
    Error
  >('GET_GOOGLE_URL'),
  authSetToken: actionCreator<string>('SET_AUTH_TOKEN'),
  authTokenDelete: actionCreator('DELETE'),
  setCurrentOrganization: actionCreator<IAccountOrganization | null>('SET_CURRENT_ORGANIZATION'),
  registerUser: actionCreator.async<RegisterUserRequest, RegisterUserResponse, Error>('REGISTER_USER'),
  confirmEmailByCode: actionCreator.async<{ actionHash: string; code: string }, EmailAction, Error>(
    'CONFIRM_EMAIL_BY_CODE'
  ),
  resendConfirmEmail: actionCreator.async<ConfirmEmailRequest, ConfirmEmailResponse, Error>('RESEND_CONFIRM_EMAIL'),
  congratulationEmail: actionCreator.async<{ actionHash: string }, EmailAction, Error>('CONGRATULATION_EMAIL'),
  resetPassword: actionCreator.async<{ actionHash: string; password: string }, EmailAction, Error>('FORGOT_EMAIL'),
  changePassword: actionCreator.async<IChangePassword, ChangePasswordResponse, Error>('CHANGE_PASSWORD'),
  forgotEmail: actionCreator.async<ResetPasswordRequest, void, Error>('RESET_PASSWORD')
};
