import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import styles from './course-navigation-tree-view.module.scss';

import { CourseNodeTreeSelector } from '../../../../../modules/lms/courses/store/CourseSelector';
import { useViewCourseContext } from '../../../../../modules/lms/students/student-course/ViewCourseContext';
import { Breadcrumbs } from '../breadcrumbs/Breadcrumbs';
import { CourseNavigationTitleView } from '../title-view/CourseNavigationTitleView';
import { CourseNavigationTreeView } from './CourseNavigationTreeView';

export const CourseNavigationView = () => {
  const { course, currentNav } = useViewCourseContext();
  const nodeTreeMemo = useMemo(
    () => CourseNodeTreeSelector(course.children, currentNav?.id, ['folder']),
    [course, currentNav?.id]
  );
  const { treeNodes } = useSelector((state) => nodeTreeMemo(state));

  return (
    <div className={styles.container}>
      <CourseNavigationTitleView />
      {currentNav && <Breadcrumbs treeNodes={treeNodes} />}

      <CourseNavigationTreeView />
    </div>
  );
};
