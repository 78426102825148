import { FormControl } from 'react-bootstrap';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import classNames from 'classnames';
import styles from './publicLink.module.scss';

import { Button } from '../../../../../../components/UI';
import { useCopy } from '../../../../../../hooks/use-copy';

interface IProps {
  offerId: string;
}

export const PublicLink = ({ offerId }: IProps) => {
  const { copy, onCopy } = useCopy();

  const text = `${window.location.origin}/offer/${offerId}`;
  return (
    <div className={'d-flex'}>
      <FormControl disabled className={classNames('control-from control-from-md mr-2', styles.link)} value={text} />
      <CopyToClipboard text={text}>
        <Button onClick={onCopy} withIcon={false} size={'lg'} className={styles.btn}>
          {copy ? 'Link copied!' : 'Copy link'}
        </Button>
      </CopyToClipboard>
    </div>
  );
};
