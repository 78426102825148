import React, { memo } from 'react';
import { FormControl } from 'react-bootstrap';
import { Controller, useFormContext } from 'react-hook-form';

import classNames from 'classnames';
import styles from './add-link.module.scss';

import { PolicyContent, PolicyContentTypeEnum } from '../../../../../interfaces/policy';
import { usePolicyContext } from '../../policy-item/PolicyContext';
import { LegalSettings } from '../legal-settings/LegalSettings';

interface IProps {
  isActive: boolean;
}

interface IFormInputs {
  content: PolicyContent<PolicyContentTypeEnum.external> | null;
  show_in_cabinet: boolean;
}

export const AddLink = memo(({ isActive }: IProps) => {
  const {
    control,
    formState: { errors }
  } = useFormContext<IFormInputs>();
  const { onSelect } = usePolicyContext();

  const onCLick = () => {
    onSelect('external');
  };

  return (
    <div
      className={classNames(styles.container, isActive ? styles.active : styles.inactive, 'mb-2', {
        [styles.invalid]: (errors.content as any)?.link
      })}
      onClick={onCLick}
    >
      <div className={styles.title}>
        <div className={'flex-grow-1'}>Add link to external page or document</div>
        {isActive && <LegalSettings />}
      </div>
      <div className={styles.description}>
        In case if you already have published legal information, you can add a link that will redirect user to your page
      </div>
      {isActive && (
        <Controller
          render={({ field: { onChange, value }, formState: { errors } }) => {
            return (
              <>
                <FormControl
                  type={'text'}
                  placeholder={'Link to the page or document'}
                  disabled={false}
                  isInvalid={!!(errors.content as any)?.link}
                  value={value || ''}
                  onChange={onChange}
                />
                {(errors.content as any)?.link && <div className={'error'}>{(errors.content as any).link.message}</div>}
              </>
            );
          }}
          defaultValue={''}
          control={control}
          name={'content.link'}
        />
      )}
    </div>
  );
});
