import { ChangeEvent, memo } from 'react';
import { FormCheck, FormCheckProps } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';

import { getUUID } from '../../../helpers';

interface IProps {
  name: string;
  value: string | undefined;
  checked?: boolean;
  onCheckRadio?: (e: ChangeEvent<HTMLInputElement>) => void;
  type?: 'radio' | 'checkbox';
  params?: FormCheckProps;
}

export const CheckButton = memo(({ name, value, checked, onCheckRadio, params, type = 'radio' }: IProps) => {
  const { register } = useFormContext();

  return (
    <FormCheck
      custom
      type={type}
      id={getUUID()}
      label={value}
      {...register(name)}
      checked={checked}
      onChange={onCheckRadio}
      value={value}
      {...params} />
  );
});
