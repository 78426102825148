import { FormControl } from 'react-bootstrap';
import CopyToClipboard from 'react-copy-to-clipboard';

import classNames from 'classnames';
import styles from './script.module.scss';
import { Icon32Cart, IconCopy } from '../../../../../../components/UI/icons';

import { Button } from '../../../../../../components/UI';
import { useCopy } from '../../../../../../hooks/use-copy';

interface IProps {
  showHeader?: boolean;
  offerId: string;
  textButton?: string;
}

export const Script = ({ offerId, showHeader = true, textButton }: IProps) => {
  const { copy, onCopy } = useCopy();
  const isDev = process.env.REACT_APP_ENV !== 'production';
  const copyData = `<div class="everact_widget" data-offer="${offerId}"${
    isDev ? ' data-env="dev"' : ''
  }></div><script src="https://s3.dev.wisent.work/widgets-public/everact-checkout-widget-dev.js" defer></script>`;
  return (
    <>
      {showHeader && (
        <div className={styles.titleContainer}>
          <div className={styles.title}>
            Copy the script and paste it into your website
            <br /> to insert this call to action button
          </div>
          <Button
            onClick={() => {}}
            customIcon={<Icon32Cart className={'icon'} />}
            iconPosition={'left'}
            size={'lg'}
            className={styles.callToAction}
          >
            {textButton}
          </Button>
        </div>
      )}
      <CopyToClipboard text={copyData} onCopy={onCopy}>
        <div className={styles.contentContainer}>
          <FormControl
            value={copyData}
            readOnly
            name={'order_bump_description'}
            className={classNames('form-control form-control-md resize-none', styles.textScript, {
              [styles.hideHeader]: !showHeader
            })}
            type={'text'}
            as={'textarea'}
            rows={6}
          />
          <Button
            customIcon={<IconCopy className={'icon'} />}
            onClick={onCopy}
            btnStyle={'secondary'}
            className={styles.copyBtn}
            withIcon={!copy}
          >
            {copy ? 'Script copied!' : 'Copy script'}
          </Button>
        </div>
      </CopyToClipboard>
    </>
  );
};
