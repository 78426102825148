import { memo, ReactElement, ReactNode } from 'react';
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';
import { useFormContext } from 'react-hook-form';
import { map } from 'lodash';

const reorder = <T extends object>(list: T[], startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

interface IProps<T> {
  isDragElementClassName?: string;
  dragElementClassName?: string;
  children: ReactNode;
  elements: T[];
  name?: string;
  setElements: (elements: T[]) => void;
}

const NewReorderComponent: <T extends object>(p: IProps<T>) => ReactElement<IProps<T>> = ({
  dragElementClassName,
  isDragElementClassName,
  children,
  name,
  elements,
  setElements
}) => {
  const { setValue } = useFormContext();
  const onDragEnd = (result: DropResult): void => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(elements, result.source.index, result.destination.index);
    if (name) {
      setValue(name, map(items, 'id'), { shouldDirty: true });
    }
    setElements(items);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided, snapshot): JSX.Element => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            className={snapshot.isDraggingOver ? isDragElementClassName : dragElementClassName}
          >
            {children}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default memo(NewReorderComponent) as typeof NewReorderComponent;
