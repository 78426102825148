import { useEffect, useMemo } from 'react';
import { Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';

import styles from '../course-settings.module.scss';

import { Button } from '../../../../../components/UI';
import { AccountSelector } from '../../../../settings/account/store/AccountSelector';
import { coursePaths } from '../../routes/CourseRoutes';
import { courseActionAsync } from '../../store/CourseActionAsync';
import { CourseSelector } from '../../store/CourseSelector';
import { ICourse, ICourseSettings } from '../../store/CourseState';
import { courseSettingsPaths } from '../routes/CourseSettingsRoutes';

export const CourseCustomCode = () => {
  const dispatch = useDispatch();
  const { handleSubmit, getValues, register, formState, reset, setValue } = useForm<{ custom_code: string }>();
  const path = useRouteMatch<{ id: string }>([courseSettingsPaths.general(), coursePaths.courseSettings()]);
  const id = path?.params.id;
  const accountMemo = useMemo(AccountSelector, []);
  const { organizationId } = useSelector(accountMemo);
  const courseMemo = useMemo(() => CourseSelector(id), [id]);
  const { course } = useSelector(courseMemo);
  const { isDirty } = formState;

  useEffect(() => {
    if (course && course.settings.custom_code) {
      setValue('custom_code', course.settings.custom_code);
    }
  }, [course, setValue]);

  const onCourseCallback = (course?: ICourse) => {
    reset({ custom_code: course?.settings.custom_code || '' });
  };

  const onCancel = () => {
    reset({ custom_code: course?.settings.custom_code || '' });
  };

  const onSave = () => {
    const data = getValues();
    let settings: Partial<ICourseSettings> = {};
    if (data.custom_code) {
      settings.custom_code = data.custom_code;
    } else if (isDirty) {
      settings.custom_code = null;
    }
    if (organizationId && id) {
      dispatch(courseActionAsync.editCourseSettings(id, settings, onCourseCallback));
    }
  };

  return (
    <div className={'d-flex flex-column h-100'}>
      <h2 className={styles.title}>Page footer code</h2>
      <div className={styles.description}>
        Code will be placed on the landing page of this course. Javascript code should be placed within &lt;script&gt;
        tags.
      </div>
      <Form.Control as="textarea" {...register('custom_code')} className={`${styles.textarea} mb-1`} />
      {/* <div className={styles.note}>
        Available variables: &#123;&#123; name &#125;&#125;, &#123;&#123; price &#125;&#125;, &#123;&#123; product_id
        &#125;&#125;, &#123;&#123; course_id &#125;&#125;
      </div> */}
      <CSSTransition in={isDirty} timeout={300} classNames={styles} unmountOnExit>
        <div className={styles.actions}>
          <Button btnStyle={'secondary'} withIcon={false} onClick={onCancel} className={'mr-1'}>
            Cancel
          </Button>
          <Button btnStyle={'primary'} withIcon={false} onClick={handleSubmit(onSave)}>
            Update changes
          </Button>
        </div>
      </CSSTransition>
    </div>
  );
};
