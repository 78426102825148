import { Controller } from 'react-hook-form';
import AutosizeInput from 'react-input-autosize';

import classnames from 'classnames';
import classNames from 'classnames';
import styles from './range-canvas.module.scss';

interface IProps {
  index: number;
  start: number;
  end: number;
  id: string;
  nodeId: string;
}

export const RangeCanvasItem = ({ index, end, start, id, nodeId }: IProps) => {
  const letter = String.fromCharCode('A'.charCodeAt(0) + index);
  return (
    <div className={styles.container}>
      <div className={classNames(styles.range, styles.label)}>{letter}</div>
      <div className={styles.inputContainer}>
        <Controller
          name={`${nodeId}.${id}.start`}
          defaultValue={start}
          render={({ field: { onChange, value } }) => {
            return (
              <AutosizeInput
                disabled
                inputClassName={classNames(styles.input, styles.label)}
                onChange={onChange}
                value={value}
              />
            );
          }}
        />
        <span>-</span>
        <Controller
          name={`${nodeId}.${id}.end`}
          defaultValue={end}
          render={({ field: { onChange, value } }) => {
            return (
              <AutosizeInput
                disabled
                inputClassName={classNames(styles.input, styles.label)}
                onChange={onChange}
                value={value}
              />
            );
          }}
        />
        <div className={classnames(styles.endpointView, styles.endpointCSS)} />
      </div>
    </div>
  );
};
