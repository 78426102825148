import styles from './loader-video-status-button.module.scss';
import { IconCross, IconMinus } from '../../../../../components/UI/icons';

import { CustomTooltip } from '../../../../../components/UI';

interface IProps {
  onCollapse: () => void;
  onCancel: () => void;
  isUpload: boolean;
}

export const LoaderVideoStatusButton = ({ onCancel, onCollapse, isUpload }: IProps) => {
  return (
    <div className={styles.container}>
      <CustomTooltip disabled={false} direction={'top'} customText={isUpload ? 'Cancel upload' : 'Close'}>
        <IconCross className={styles.icon} onClick={onCancel} />
      </CustomTooltip>
      <CustomTooltip disabled={false} direction={'top'} customText={'Collapse'}>
        <IconMinus className={styles.icon} onClick={onCollapse} />
      </CustomTooltip>
    </div>
  );
};
