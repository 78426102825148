import { CustomDispatch } from 'redux';

import { createOrganization, editMe, getMe } from '../../../../API/account-api';
import { IAccount, IProfileAccount } from '../../../../interfaces';
import { AccountActions } from './AccountActions';

export const AccountActionAsync = {
  getMe:
    (isStudent?: boolean, token?: Nullable<string>, callback?: (data: IAccount) => void) =>
    async (dispatch: CustomDispatch) => {
      try {
        dispatch(AccountActions.getMe.started({ isStudent }));
        const response = await getMe(token);
        if (response.data) {
          dispatch(AccountActions.getMe.done({ result: response.data, params: { isStudent } }));
          callback?.(response.data);
          return response.data;
        }
      } catch (error: any) {
        dispatch(AccountActions.getMe.failed({ error, params: { isStudent } }));
      }
    },
  editMe:
    (profile: Partial<IProfileAccount>, token?: string, callback?: () => void) => async (dispatch: CustomDispatch) => {
      try {
        dispatch(AccountActions.editMe.started(profile));
        const response = await editMe(profile, token);
        if (response.data) {
          dispatch(AccountActions.editMe.done({ result: response.data, params: profile }));
          callback?.();
        }
      } catch (error: any) {
        dispatch(AccountActions.editMe.failed({ error, params: profile }));
      }
    },
  createOrganization: (token: Nullable<string>, callback?: () => void) => async (dispatch: CustomDispatch) => {
    try {
      dispatch(AccountActions.createOrganization.started(token));
      const response = await createOrganization(token);
      if (response.status === 200) {
        dispatch(AccountActions.createOrganization.done({ result: response.data, params: token }));
        callback?.();
      }
    } catch (error: any) {
      dispatch(AccountActions.createOrganization.failed({ error, params: token }));
    }
  }
};
