import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, Switch, useLocation, useRouteMatch } from 'react-router-dom';

import classNames from 'classnames';

import { Authentication } from '../../modules/auth/Authentication';
import { CommonAuth } from '../../modules/auth/common/common-auth/CommonAuth';
import { Confirmation } from '../../modules/auth/confirmation/Confirmation';
import { GoogleOAuth } from '../../modules/auth/google-oauth/GoogleOAuth';
import { RecoveryPassword } from '../../modules/auth/recovery-password/RecoveryPassword';
import { SignUp } from '../../modules/auth/sign-up/SignUp';
import { CommentModeration } from '../../modules/comment/comment-moderation/CommentModeration';
import { commentsPaths } from '../../modules/comment/routes/CommentRoutes';
import { CreateOrganization } from '../../modules/common/create-organization/CreateOrganization';
import { Offer } from '../../modules/crm/offers/offer/Offer';
import { FunnelBuilder } from '../../modules/funnels/funnel-builder/FunnelBuilder';
import { funnelPaths } from '../../modules/funnels/routes/FunnelRoutes';
import { mainPaths, MainRoutes, PrivateRoutes } from '../../modules/MainRoutes';
import { BillingPermissionSelector } from '../../modules/settings/billing/store';

export const AppRouter = () => {
  const location = useLocation();
  const { authToken } = useSelector((state) => state.auth);
  const subscriptionMemo = useMemo(BillingPermissionSelector, []);
  const { isBlockAccount, isLoading } = useSelector(subscriptionMemo);
  const organizationId = useSelector((state) => state.auth.currentOrganization?.organization_id);
  const googleOAuthPath = useRouteMatch(mainPaths.googleOAuth);

  return (
    <Switch>
      <Route path={mainPaths.offer()} exact={true} children={<Offer />} />
      <Route path={mainPaths.updateAccount} exact={false} children={<CreateOrganization />} />
      <Route path={mainPaths.googleOAuth} exact={false} children={<GoogleOAuth />} />
      <Route path={commentsPaths.comments} exact={false} children={<CommentModeration />} />
      {authToken && (
        <>
          {[...MainRoutes, ...PrivateRoutes(isBlockAccount)].map((route) => (
            <Route
              key={route.path}
              path={route.path}
              exact={route.exact}
              children={
                <div className={classNames(isLoading && isBlockAccount && 'pt-6')}>
                  <route.main />
                </div>
              }
            />
          ))}
          {!!location.search?.length && !location.search.includes('page') && (
            <Redirect
              to={{
                pathname: mainPaths.main,
                search: location.search
              }}
            />
          )}
        </>
      )}
      <Route path={mainPaths.auth} exact={false} children={<Authentication />} />
      <Route path={mainPaths.signUp} exact={false} children={<SignUp />} />
      <Route
        path={mainPaths.confirmation}
        exact={false}
        children={
          <CommonAuth>
            <Confirmation />
          </CommonAuth>
        }
      />
      <Route path={mainPaths.recovery} exact={false} children={<RecoveryPassword />} />
      <Route path={funnelPaths.thumbnailId()} children={<FunnelBuilder isThumbnails={true} key={organizationId} />} />
      {!authToken && !googleOAuthPath && <Redirect to={mainPaths.auth} />}
      {isLoading && !googleOAuthPath && <Redirect to={mainPaths.notFound} />}
    </Switch>
  );
};
