import { useContext } from 'react';

import { RestrictPermission } from '../../interfaces/permission';
import PermissionContext from './PermissionContext';

export const usePermission = (permission: RestrictPermission) => {
  const { isAllowedTo } = useContext(PermissionContext);

  return [isAllowedTo(permission)];
};
