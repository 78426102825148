import { CustomDispatch } from 'redux';

import {
  deleteQuizSession,
  getQuizSession,
  getQuizSessions,
  markPassedQuizSession,
  markSkipableQuizSession,
  QuizSessionsParams
} from '../../../../../../API/quiz-api';
import { IQuizSession, IQuizSessionDetail } from '../../../../../../interfaces/quiz';
import { QuizSessionsActions } from './QuizSessionsActions';

export const QuizSessionsActionAsync = {
  getQuizSessions:
    (params: QuizSessionsParams, callback?: (data: IQuizSession[]) => void, onError?: () => {}) =>
    async (dispatch: CustomDispatch) => {
      try {
        dispatch(QuizSessionsActions.getQuizSessions.started(params));
        const response = await getQuizSessions(params);
        if (response.data) {
          dispatch(QuizSessionsActions.getQuizSessions.done({ result: response.data, params }));
          callback?.(response.data);
        }
      } catch (error) {
        dispatch(QuizSessionsActions.getQuizSessions.failed({ error, params }));
        onError?.();
      }
    },
  getQuizSession:
    (id: string, callback?: (data: IQuizSessionDetail) => void, onError?: () => {}) =>
    async (dispatch: CustomDispatch) => {
      try {
        dispatch(QuizSessionsActions.getQuizSession.started(id));
        const response = await getQuizSession(id);
        if (response.data) {
          dispatch(QuizSessionsActions.getQuizSession.done({ result: response.data, params: id }));
          callback?.(response.data);
        }
      } catch (error) {
        dispatch(QuizSessionsActions.getQuizSession.failed({ error, params: id }));
        onError?.();
      }
    },
  deleteQuizSession: (id: string, callback?: () => void, onError?: () => {}) => async (dispatch: CustomDispatch) => {
    try {
      dispatch(QuizSessionsActions.deleteQuizSession.started(id));
      const response = await deleteQuizSession(id);
      if (response.status === 200) {
        dispatch(QuizSessionsActions.deleteQuizSession.done({ result: null, params: id }));
        callback?.();
      }
    } catch (error) {
      dispatch(QuizSessionsActions.deleteQuizSession.failed({ error, params: id }));
      onError?.();
    }
  },
  markSkipableQuizSession:
    (id: string, callback?: () => void, onError?: () => {}) => async (dispatch: CustomDispatch) => {
      try {
        dispatch(QuizSessionsActions.markSkipableQuizSession.started(id));
        const response = await markSkipableQuizSession(id);
        if (response.status === 200) {
          dispatch(QuizSessionsActions.markSkipableQuizSession.done({ result: response.data, params: id }));
          callback?.();
        }
      } catch (error) {
        dispatch(QuizSessionsActions.markSkipableQuizSession.failed({ error, params: id }));
        onError?.();
      }
    },
  markPassedQuizSession:
    (id: string, isPassed: boolean, callback?: () => void, onError?: () => {}) => async (dispatch: CustomDispatch) => {
      try {
        dispatch(QuizSessionsActions.markPassedQuizSession.started({ id, is_passed: isPassed }));
        const response = await markPassedQuizSession(id, isPassed);
        if (response.status === 200) {
          dispatch(
            QuizSessionsActions.markPassedQuizSession.done({
              result: response.data,
              params: { id, is_passed: isPassed }
            })
          );
          callback?.();
        }
      } catch (error) {
        dispatch(QuizSessionsActions.markPassedQuizSession.failed({ error, params: { id, is_passed: isPassed } }));
        onError?.();
      }
    }
};
