import React, { memo, useState } from 'react';
import Slider, { Settings } from 'react-slick';

import classNames from 'classnames';
import '../../../../../styles/slider/slick.scss';
import styles from './university.module.scss';
import UniversityBook from '../../../../../assets/images/dashboard-books.png';
import { IconChevronLeft, IconChevronRight } from '../../../../components/UI/icons';

import { Logo } from '../../../../layout/shared-components/Logo';
import { ICourse } from '../../../lms/courses/store/CourseState';
import { Block } from '../block/Block';
import { UniversityItem } from './university-item/UniversityItem';

interface IProps {
  courses: ICourse[];
}

export const University = memo(({ courses }: IProps) => {
  const [slider, setSlider] = useState<Slider | null>();
  const [currentSlide, setCurrentSlide] = useState(0);

  const sliderSettings: Settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 2,
    variableWidth: true,
    slidesToScroll: 1,
    accessibility: true,
    focusOnSelect: true,
    arrows: false,
    afterChange: (currentSlide) => {
      setCurrentSlide(currentSlide);
    }
  };

  const onPrev = () => {
    slider?.slickPrev();
  };

  const onNext = () => {
    slider?.slickNext();
  };

  return (
    <Block>
      <div className={styles.container}>
        <div className={styles.top}>
          <div className={styles.title}>
            <Logo width={32} height={32} customStylesContainer={styles.logo} />
            Everact University
          </div>
          {courses.length > 2 && (
            <div className={'nav'}>
              <button
                className={classNames('btn btn-icon', styles.button)}
                onClick={onPrev}
                disabled={currentSlide === 0}
              >
                <IconChevronLeft />
              </button>
              <button
                className={classNames('btn btn-icon', styles.button)}
                onClick={onNext}
                disabled={currentSlide === courses.length - 2}
              >
                <IconChevronRight />
              </button>
            </div>
          )}
        </div>
        {courses.length === 1 ? (
          <div className={'d-flex'}>
            <UniversityItem key={courses[0].id} course={courses[0]} />
            <img className={styles.booksIllustration} alt={'university illustration'} src={UniversityBook} />
          </div>
        ) : (
          <div className={styles.carousel}>
            <Slider ref={(c) => setSlider(c)} {...sliderSettings}>
              {courses.map((course) => (
                <UniversityItem key={course.id} course={course} />
              ))}
            </Slider>
          </div>
        )}
      </div>
    </Block>
  );
});
