import React, { memo, useCallback, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';

import styles from './payload-condition.module.scss';

import { CheckButton } from '../../../../../../components/UI';
import { useNodePayload } from '../../../../../../hooks/use-node-payload';
import { PaletteAutomationElementTypes } from '../../../../../../interfaces';
import { ListsAttacher } from '../../../../../crm/contact/common/contact-card-lists/ListsAttacher';
import { IList } from '../../../../../crm/lists/store/ListsState';

interface IProps {
  nodeId: string;
}

export const ListCondition = memo(({ nodeId }: IProps) => {
  const [lists, setLists] = useState<IList[]>([]);
  const allLists = useSelector((state) => state.lists.lists);
  const node = useNodePayload<PaletteAutomationElementTypes.PROCESSOR_CONDITION>(nodeId);
  const { setValue, register, unregister } = useFormContext();

  const onAttach = useCallback(
    (id: string) => {
      const list = allLists.find((x) => x.id === id);
      if (list) {
        setLists((x) => [...x, list]);
      }
    },
    [allLists]
  );

  const onDetach = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      const id = event.currentTarget.dataset['id'];
      const newCurrentLists = lists.filter((x) => x.id !== id);
      setLists(newCurrentLists);
    },
    [lists]
  );

  useEffect(() => {
    if (node) {
      const currentList = allLists.filter((x) => node?.payload.object_id?.includes(x.id.toString()));
      setValue('action', node?.payload.action || 'add');

      if (currentList) {
        setLists(currentList);
      }
    }
  }, [allLists, node, nodeId, setValue]);

  useEffect(() => {
    if (lists.length > 0) {
      register('object_id');
      register('object_type');
      setValue('object_id', lists[0].id);
      setValue('object_type', 'list');
    }
    return () => {
      unregister('object_id');
      unregister('object_type');
    };
  }, [register, setValue, lists, unregister]);

  return (
    <>
      <div className={'mt-3 mb-4'}>
        <ListsAttacher
          isShowCreate={false}
          attachedIds={lists}
          titleStyles={styles.titleListAttach}
          attachListHandler={onAttach}
          detachListHandler={onDetach}
          isDisable={true}
        />
      </div>
      <CheckButton
        name={'action'}
        value={'add'}
        params={{ className: 'radio-button my-1', label: 'Contact has been assigned to the list' }}
      />
      <CheckButton
        name={'action'}
        value={'remove'}
        params={{ className: 'radio-button my-1 mt-2', label: 'Was not assigned to the list' }}
      />
    </>
  );
});
