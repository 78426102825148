import React from 'react';

import styles from './start-block.module.scss';
import folder from '../../../../../../../assets/svg/folder.svg';
import lesson from '../../../../../../../assets/svg/lesson.svg';
import { IconPlus } from '../../../../../../components/UI/icons';

import { useCourseContext } from '../../../common/sidebar/CourseContext';

export const StartBlock = () => {
  const { nodeCreateHandler } = useCourseContext();

  const onClick = (event: React.SyntheticEvent<HTMLDivElement>) => {
    const type = event.currentTarget.dataset['id'] as 'lesson' | 'folder';
    if (type) {
      nodeCreateHandler(type);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.title}>Start by adding content</div>
      <div className={styles.text}>Choose what you would like to add</div>
      <div className="d-flex">
        <div className={styles.create} data-id={'folder'} onClick={onClick}>
          <div className={styles.image}>
            <img src={folder} alt={''} />
          </div>
          <div className={styles.label}>
            Create new folder
            <div className={styles.icon}>
              <IconPlus />
            </div>
          </div>
        </div>
        <div className={styles.create} data-id={'lesson'} onClick={onClick}>
          <div className={styles.image}>
            <img src={lesson} alt={''} />
          </div>
          <div className={styles.label}>
            Add new lesson
            <div className={styles.icon}>
              <IconPlus />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
