import styles from '../../courses/student-courses.module.scss';
import { Icon32Webinar } from '../../../../components/UI/icons';

export const EmptyCourse = () => {
  return (
    <div className={styles.emptyContainer}>
      <div>
        <Icon32Webinar className={styles.emptyIcon} />
      </div>
      Here will be the list of all courses
    </div>
  );
};
