import { memo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { OnChangeValue } from 'react-select';
import { capitalize } from 'lodash';

import classNames from 'classnames';
import styles from './member-item.module.scss';
import { Icon32PendingUser, Icon32User } from '../../../../../components/UI/icons';

import { IMember, IOption } from '../../../../../interfaces';
import { MemberAvatar } from '../../common/memberAvatar/MemberAvatar';
import { MemberLoader } from '../../common/memberLoader/MemberLoader';
import { SelectRole } from '../../common/selectRole/SelectRole';
import { OrganizationActionAsync } from '../../store/OrganizationActionAsync';

interface IProps {
  member: IMember;
  getMembers: () => void;
}

export const MemberItem = memo(({ getMembers, member }: IProps) => {
  const [showMark, setShowMark] = useState(false);
  const { organizations } = useSelector((state) => state.account.account);
  const dispatch = useDispatch();
  const [selected, setSelected] = useState<OnChangeValue<IOption, boolean>>({
    label: capitalize(member?.role),
    value: member?.role
  });

  const removeMember = () => {
    dispatch(OrganizationActionAsync.removeMemberFromOrganization(member.id, getMembers));
  };

  const resendInvitation = () => {
    setShowMark(true);
    if (!showMark) {
      dispatch(OrganizationActionAsync.resendInvitationOrganizationMember(member.id));
    }
  };

  const name = member.first_name || member.last_name ? member.first_name + ' ' + member.last_name : member.email;

  const you = organizations.find((x) => x.id === member.id);
  return (
    <div className={styles.container}>
      <div className={'d-flex align-items-center'}>
        {member.photo && <MemberAvatar image={member.photo} />}
        {!member.photo && member.type === 'user' && (
          <MemberAvatar>
            <Icon32User className={styles.imageUser} />
          </MemberAvatar>
        )}
        {!member.photo && member.type === 'invitation' && (
          <MemberAvatar>
            <div className={styles.imageInvitation}>
              <Icon32PendingUser />
            </div>
          </MemberAvatar>
        )}
        <div className={classNames('ml-3', styles.name)}>
          {name}
          {you && <span className={styles.label}>You</span>}
          {name !== member.email && <div className={styles.email}>{member.email}</div>}
          {member.type === 'invitation' && (
            <div className={'d-flex'}>
              <div className={styles.invitationText}>Invitation sent •</div>
              <div className={styles.textResend} onClick={resendInvitation} id={member.id}>
                Resend
                <MemberLoader show={showMark} />
              </div>
            </div>
          )}
        </div>
      </div>
      <div className={classNames('d-flex align-items-center', styles.name)}>
        {member.role === 'owner' && <span className={styles.plainText}>Owner</span>}
        {you && member.role !== 'owner' && <span className={styles.plainText}>{capitalize(member.role)}</span>}
        {!you && member.role !== 'owner' && (
          <SelectRole onChange={setSelected} removeMember={removeMember} id={member.id} name={name} />
        )}
      </div>
    </div>
  );
});
