import { memo, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import InnerHTML from 'dangerously-set-html-content';
import { orderBy } from 'lodash';

import styles from './lesson-student.module.scss';
import { Icon32Webinar, IconPencilOnPaper } from '../../../components/UI/icons';

import { EmptyList } from '../../../components/empty-list/EmptyList';
import { InfoMessage } from '../../../components/UI/infoMessage/InfoMessage';
import { LoadState } from '../../../store';
import { coursePaths } from '../../lms/courses/routes/CourseRoutes';
import { StudentLessonErrorStatus } from '../../lms/courses/store/CourseState';
import { useLessonNode } from '../../lms/hooks/use-lesson-node';
import { StudentComment } from '../../lms/students/student-comment/StudentComment';
import { useViewCourseContext } from '../../lms/students/student-course/ViewCourseContext';
import { EnrollAccess } from '../common/lesson-modal/EnrollAccess';
import { RestrictAccess } from '../common/lesson-modal/RestrictAccess';
import { LessonTitle } from '../common/lesson-title/LessonTitle';
import { StudentCabinetPaths } from '../routes/StudentCabinetRoutes';
import { StudentCabinetActionAsync } from '../store/StudentCabinetActionAsync';
import { LessonLoader } from './lesson-block/lesson-loader/LessonLoader';
import { LessonBlock } from './lesson-block/LessonBlock';
import { PreviewCommentBlock } from './preview-comment-block/PreviewCommentBlock';

interface IProps {
  viewType?: 'preview' | 'dashboard' | 'student';
}

export const LessonStudent = memo(({ viewType }: IProps) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [showRestrictModal, setShowRestrictModal] = useState(false);
  const { course, nodeId, flatLessons } = useViewCourseContext();
  const { studentCourses, loadState } = useSelector((state) => state.studentCourses);
  const { nodeLesson, nodeStep } = useLessonNode(course, nodeId);
  const [showNotEnrolledModal, setShowNotEnrolledModal] = useState(false);

  const onCloseRestrictModal = useCallback(() => {
    setShowRestrictModal(false);
  }, []);

  const onShowErrorModal = useCallback(
    (error: StudentLessonErrorStatus) => {
      if (error === 'fail.course.curriculum.drafted_parent') {
        setShowRestrictModal(true);
      }
      if (error === 'fail.course.not_enrolled') {
        setShowNotEnrolledModal(true);
      }
      if (error === 'fail.course.node_not_found') {
        //TODO: Will be fixed on backend
        history.push(StudentCabinetPaths.courseStudentLessons(course.id, flatLessons?.allSteps[0].id));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [course.id, history]
  );

  const lessonBlocks = studentCourses.payload.filter((block) => {
    return !(
      (block.type === 'block:text' && block.title === '' && block.text === '<p><br></p>') ||
      (block.type === 'block:video' && !block.video)
    );
  });

  useEffect(() => {
    if (course?.id && nodeId) {
      dispatch(
        StudentCabinetActionAsync.getListStepBlocks(course?.id, nodeId, onShowErrorModal, viewType === 'preview')
      );
    }
  }, [course?.id, nodeId, dispatch, viewType, onShowErrorModal]);

  const onRedirectToCourse = useCallback(() => {
    history.push(coursePaths.courseLessons(course?.id, nodeId));
  }, [course?.id, history, nodeId]);

  return (
    <>
      {viewType === 'preview' && (
        <InfoMessage
          show
          text={'You are on preview mode now. This is how students will see your course.'}
          btnClick={onRedirectToCourse}
          btnText={'Back to editing'}
          withIconBtn={false}
          btnIcon={<IconPencilOnPaper />}
          className={'mb-4 d-flex justify-content-between'}
        />
      )}
      <LessonTitle />

      {loadState === LoadState.firstLoad ? (
        <LessonLoader />
      ) : (
        <>
          {lessonBlocks.length === 0 && (
            <EmptyList
              title={'This lesson is currently empty'}
              icon={<Icon32Webinar width={64} height={64} className={styles.icon} />}
              description={'Perhaps the administrator has not yet added content for this lesson'}
              button={false}
            />
          )}
          {lessonBlocks && orderBy(lessonBlocks, 'order').map((block) => <LessonBlock key={block.id} block={block} />)}
          {lessonBlocks.length > 0 && nodeStep?.setting_custom_code_enabled && (
            <InnerHTML html={`${nodeStep.setting_custom_code}`} />
          )}

          {course.settings.custom_code && <InnerHTML html={`${course.settings.custom_code}`} />}
          {(!viewType || viewType === 'student') && nodeLesson && course && (
            <StudentComment courseId={course.id} lessonId={nodeLesson.id} />
          )}

          {viewType === 'preview' && nodeLesson && <PreviewCommentBlock showComments={nodeLesson.show_comments} />}
          <RestrictAccess onClose={onCloseRestrictModal} show={showRestrictModal} title={nodeLesson?.title} />
          <EnrollAccess show={showNotEnrolledModal} />
        </>
      )}
    </>
  );
});
