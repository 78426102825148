import { useEffect } from 'react';
import { useSelector } from 'react-redux';

export const usePendo = () => {
  const { authToken } = useSelector((state) => state.auth);
  const { account } = useSelector((state) => state.account);

  useEffect(() => {
    if (authToken) {
      (window as any)?.pendo?.initialize({
        visitor: {
          id: account.id, // Required if user is logged in
          email: account.profile.email, // Recommended if using Pendo Feedback, or NPS Email
          full_name: account.profile.first_name + ' ' + account.profile.last_name // Recommended if using Pendo Feedback
        },
        account: {
          id: account.id, // Required if using Pendo Feedback
          name: account.profile.first_name + ' ' + account.profile.last_name // Optional
        }
      });
    }
  }, [account.id, account.profile.email, account.profile.first_name, account.profile.last_name, authToken]);
}; 
