import { useMemo, useState } from 'react';
import ReactQuill from 'react-quill';
import moment from 'moment';
import { orderBy } from 'lodash';

import classNames from 'classnames';
import styles from './quiz-question.module.scss';
import {
  IconAngleDown,
  IconAngleUp,
  IconCheckMark,
  IconClock,
  IconCross,
  IconMinus,
  IconStarFilled
} from '../../../../../../../components/UI/icons';

import { AnswerTypeEnum, IQuizQuestion, IQuizSession, IQuizSessionDetail } from '../../../../../../../interfaces/quiz';
import { AttachmentBlocks } from '../../../../../courses/curriculum/quiz/question/attachment-blocks/AttachmentBlocks';
import { QuizAnswer } from '../quiz-answer/QuizAnswer';
import { QuizAudioAnswer } from '../quiz-audio-answer/QuizAudioAnswer';
import { QuizFreeAnswer } from '../quiz-free-answer/QuizFreeAnswer';

interface IProps {
  question: IQuizQuestion;
  quizDetail: IQuizSessionDetail | IQuizSession;
  index: number;

  customStyles?: string;
}

export const QuizQuestion = ({ question, quizDetail, index, customStyles }: IProps) => {
  const [showAnswers, setShowAnswers] = useState(false);
  const modules = useMemo(
    () => ({
      toolbar: false
    }),
    []
  );
  const toggleAnswers = () => {
    setShowAnswers(!showAnswers);
  };

  const result = (quizDetail.answers || []).find((answer) => answer.question_dump.id === question.id);

  return (
    <div className={classNames(styles.container, customStyles)}>
      <div
        className={classNames(
          styles.state,
          result && result.is_true !== null && (result?.is_true ? styles.success : styles.fail)
        )}
      >
        <div className={styles.result}>
          <div className={styles.icon}>
            {result && result.is_true !== null ? (
              result.is_true ? (
                <IconCheckMark />
              ) : (
                <IconCross />
              )
            ) : result?.is_true === null ? (
              <IconClock />
            ) : (
              <IconMinus />
            )}
          </div>
          Question {index + 1}
        </div>
        <div className={styles.points}>
          <span>•</span>
          {result ? (
            <>
              <IconStarFilled className={styles.star} /> {result?.total_score}
            </>
          ) : (
            'No answer'
          )}
        </div>
        {result?.date_create && (
          <div className={styles.date}>
            <span>•</span> {moment(result.date_create).format('DD MMM yyyy, hh:mm A')}
          </div>
        )}
      </div>
      <div className={styles.question}>
        <ReactQuill modules={modules} value={question.title} readOnly />
        {question.attachment && <AttachmentBlocks attachmentType={null} data={question.attachment} />}
      </div>
      {(question.answer_type !== AnswerTypeEnum.audio_input || result) && (
        <button onClick={toggleAnswers} className={styles.toggle}>
          Answer option
          {showAnswers ? <IconAngleUp /> : <IconAngleDown />}
        </button>
      )}
      {showAnswers && (
        <div className={styles.answers}>
          {(question.answer_type === AnswerTypeEnum.multiple_select ||
            question.answer_type === AnswerTypeEnum.single_select) &&
            orderBy(question.answers, ['order'], ['asc']).map((answer) => (
              <QuizAnswer key={answer.id} answer={answer} question={question} result={result} />
            ))}
          {question.answer_type === AnswerTypeEnum.text_input && <QuizFreeAnswer result={result} />}
          {result && question.answer_type === AnswerTypeEnum.audio_input && <QuizAudioAnswer result={result.answers} />}
        </div>
      )}
    </div>
  );
};
