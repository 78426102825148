import classNames from 'classnames';
import styles from './copyright.module.scss';

interface IProps {
  customStyles?: string;
  organization?: string
}

export const Copyright = ({ customStyles, organization = 'everact.io' }: IProps) => {
  return <span className={classNames(styles.text, customStyles)}>©{new Date().getFullYear()} {organization}</span>;
};
