import { useEffect, useState } from 'react';
import { IOffer as IOfferWidget, IOfferProduct, IOfferProductFields } from '@demonvv/everact-widget';
import { map } from 'lodash';

import { IOffer } from '../../../store/OffersState';
import { dataViewProductItem } from '../../common/helper';
import { useCreateOfferContext } from '../../CreateOfferContext';

export const useOfferWidget = (offer: IOffer, settings_background_color: string | null) => {
  const [offerWidget, setOfferWidget] =
    useState<Omit<IOfferWidget, 'account_id' | 'entity' | 'student_domain_url' | 'paypal_merchant_id'>>();
  const { fields } = useCreateOfferContext();

  useEffect(() => {
    const newOffer = { ...offer };
    try {
      const productFields = map(newOffer.products, (product) => {
        return {
          fields: dataViewProductItem({ ...product.fields }, fields) as IOfferProductFields,
          entity: product.entity as Omit<typeof product.entity, 'contact'>
        };
      });
      // console.log(newOffer.products, productFields);
      setOfferWidget({
        action_redirect_after_checkout: newOffer.action_redirect_after_checkout,
        description: newOffer.description || '',
        price: newOffer.price,
        title: newOffer.title,
        setting_call_to_action: newOffer.setting_call_to_action,
        settings_background_color: settings_background_color,
        settings_background_image: newOffer.settings_background_image,
        setting_enable_shipping: newOffer.setting_enable_shipping,
        setting_price_display_overwrite: newOffer.setting_price_display_overwrite || '',
        settings_hide_branding: newOffer.settings_hide_branding,
        state: newOffer.state as 'draft' | 'published',
        order_bump_checkbox_text: newOffer.order_bump_checkbox_text,
        order_bump_description: newOffer.order_bump_description,
        order_bump_headline: newOffer.order_bump_headline,
        order_bump_is_active: newOffer.order_bump_is_active,
        order_bump_old_price: newOffer.order_bump_old_price,
        order_bump_price: newOffer.order_bump_price,
        products: productFields as IOfferProduct[]
      });
    } catch (error) {
      console.log('error', error);
    }
  }, [settings_background_color, fields, offer, offer.products]);

  return offerWidget;
};
