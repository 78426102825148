import { reducerWithInitialState } from 'typescript-fsa-reducers';

import { newState } from '../common/newState';
import { settingsActions } from './SettingsActions';
import { ISettings, SettingsInitialState } from './SettingsState';

export const settingsReducer = reducerWithInitialState<ISettings>(SettingsInitialState)
  .case(settingsActions.setZoomValue, (state, { value, isDefault }) => {
    return newState(state, { zoomValue: { value, isDefault } });
  })
  .case(settingsActions.commonSearch, (state, { searchString }) => {
    return newState(state, { searchString });
  })
  .case(settingsActions.setSidebarShowing, (state, { isSidebarShow }) => {
    return newState(state, { isSidebarShow });
  })
  .case(settingsActions.setPayloadShowing, (state, { isPayloadShow }) => {
    return newState(state, { isPayloadShow });
  })
  .case(settingsActions.setMainListId, (state, { mainListId }) => {
    return newState(state, { mainListId });
  })
  .case(settingsActions.setSidebarWidth, (state, { sidebarWidth }) => {
    return newState(state, { sidebarWidth });
  })
  .case(settingsActions.setCommonPayloadShowing, (state, { key, isPayloadShow }) => {
    return newState(state, { isCommonPayloadShow: { ...state.isCommonPayloadShow, [key]: isPayloadShow } });
  });
