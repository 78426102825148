import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import * as Sentry from '@sentry/react';
import { Breadcrumbs } from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

import './styles/index.scss';

import App from './app/App';
import { UserConfirmation } from './app/modules/crm/offers/common/unsaved/CustomPrompt';
import { persistor, store } from './app/store';
import * as serviceWorker from './serviceWorker';

Sentry.init({
  dsn: 'https://5d9de02652bd480cbb14ce19573e2bad@sentry.wisent.work/3',
  integrations: [new BrowserTracing({}), new Breadcrumbs({ console: false })],
  environment: process.env.REACT_APP_ENV,
  allowUrls: [window.location.origin],
  sendClientReports: false,
  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
  enabled: process.env.REACT_APP_ENV !== 'development'
});

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <Router getUserConfirmation={(message, callback) => UserConfirmation(message, callback)}>
        <App />
      </Router>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
