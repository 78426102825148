import { Context, createContext, useContext } from 'react';

import { AnswerPayloadType, AnswerTypeEnum, IQuizQuestion } from '../../../../../interfaces/quiz';

type ViewQuizContent<T = AnswerTypeEnum> = {
  blockId: string;
  selectedAnswer: AnswerPayloadType<T> | null;
  isLastQuestion: boolean;
  isPreview: boolean;
  courseId: string;
  onGetQuiz(): void;
  onNextQuestion(): void;
  onChangeSelectAnswer(value: AnswerPayloadType<T>): void;
  onSelectQuestion(question: IQuizQuestion | null): void;
  onCompleteQuizInPreview(): void;
};

export const ViewQuizContext = createContext<ViewQuizContent<AnswerTypeEnum>>({
  blockId: '',
  isLastQuestion: false,
  selectedAnswer: [],
  isPreview: false,
  courseId: '',
  onChangeSelectAnswer: () => {},
  onGetQuiz: () => {},
  onNextQuestion: () => {},
  onSelectQuestion: () => {},
  onCompleteQuizInPreview: () => {}
});
export const useViewQuizContext = <T extends AnswerTypeEnum>() =>
  useContext<ViewQuizContent<T>>(ViewQuizContext as unknown as Context<ViewQuizContent<T>>);
