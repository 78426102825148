import { createContext, useContext } from 'react';

import { IField } from '../../field';
import { IOffer } from '../store/OffersState';

export type OfferTabs = 'products' | 'price' | 'actions' | 'shipping' | 'publish';

export type CreateOfferContent = {
  fields: IField[];
  offer?: IOffer;
  setSave: (val: boolean) => void;
  save: boolean;
  showUnsavedModal: boolean;
  setShowUnsavedModal: (val: boolean) => void;
  onNavigate: (indexTab: number) => void;
  tabNavigationIndex: number;
};
export const CreateOfferContext = createContext<CreateOfferContent>({
  fields: [],
  offer: undefined,
  save: false,
  showUnsavedModal: false,
  setSave: () => {},
  setShowUnsavedModal: () => {},
  onNavigate: (indexTab: number) => {},
  tabNavigationIndex: 0
});
export const useCreateOfferContext = () => useContext(CreateOfferContext);
