import { IGeneralSettings } from '../../../../interfaces';
import { LoadState } from '../../../../store';

export type PaymentStatus = 'non_integrated' | 'rejected' | 'incomplete' | 'in_pending' | 'done';

export type PaymentExistingAccount = {
  code: string;
  state: string;
};

export type StripeAccount = {
  state: PaymentStatus;
  display_name: string | null;
  account_id: string | null;
  email: string | null;
};

export type PaynetAccount = {
  username: string;
  password: string;
  merchant_id: string;
  integration: 'paynet';
  id: string;
};

export type PaypalAccount = {
  merchant_id: string;
};

export type PaynetErrorStatuses =
  | 'error.integrations.paynet.wrong_credentials'
  | 'error.integrations.paynet.wrong_merchant_id'
  | 'error.integrations.paynet.already_exits';

export type PaynetError = {
  status: PaynetErrorStatuses;
  payload: null;
};

export type PublishableKey = {
  pub_key: string;
};

export type PaymentLink = {
  return_url: string;
  refresh_url: string;
  oauth?: boolean;
};

export interface IGeneralSettingsState {
  loadState: LoadState;
  error: Error | null;
  general: IGeneralSettings;
  paymentAccount: {
    stripe: StripeAccount;
    paynet: PaynetAccount | null;
    paypal: PaypalAccount | null;
  };
}

export const GeneralSettingsInitialState: IGeneralSettingsState = {
  error: null,
  loadState: LoadState.firstLoad,
  paymentAccount: {
    stripe: {
      state: 'non_integrated',
      display_name: null,
      email: null,
      account_id: null
    },
    paynet: null,
    paypal: null
  },
  general: {
    id: '',
    formatting_contact_name: '',
    formatting_date_format: '',
    formatting_time_format: '',
    general_email: '',
    general_image: {
      bucket_name: '',
      object_name: ''
    },
    general_industry: '',
    general_phone: '',
    general_title: '',
    region_country: '',
    region_state: '',
    region_timezone: '',
    theme_brand_color: '',
    theme_background_color: '',
    theme_logo: null,
    theme_sign_in: null,
    theme_sign_up: null
  }
};
