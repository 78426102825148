import React, { memo, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { find, flatMapDeep } from 'lodash';

import classNames from 'classnames';
import styles from './adminCourse.module.scss';

import { flatten } from '../../../../components/course/course/CourseHelpers';
import { BlockType } from '../../../../interfaces';
import { LoadState } from '../../../../store';
import { CourseContext } from '../common/sidebar/CourseContext';
import { CourseSidebar } from '../common/sidebar/CourseSidebar';
import { coursePaths } from '../routes/CourseRoutes';
import { courseActionAsync } from '../store/CourseActionAsync';
import { CourseSelector } from '../store/CourseSelector';
import { ICourseNode } from '../store/CourseState';
import { LessonSelector } from '../store/LessonSelector';
import { AdminCourseWrapper } from './AdminCourseWrapper';

interface IProps {
  customStyles?: string;
  customContainerStyle?: string;
  sidebar: ReactNode;
}

export const AdminCourse: React.FC<IProps> = memo(({ sidebar, children, customContainerStyle, customStyles }) => {
  const dispatch = useDispatch();
  const history = useHistory<{ preview?: boolean }>();
  const path = useRouteMatch<{ id: string; nodeId: string }>([coursePaths.courseLessons(':id', ':nodeId')]);
  const pathCurriculum = useRouteMatch<{ id: string }>([
    coursePaths.courseLessons(),
    coursePaths.courseSettings(':id')
  ]);
  const courseId = path?.params.id || pathCurriculum?.params.id;
  const [currentNav, setCurrentNav] = useState<ICourseNode | null>(null);
  const [currentHierarchy, setCurrentHierarchy] = useState<ICourseNode[]>([]);
  const nodeId = path?.params.nodeId;
  const nodeMemo = useMemo(
    () => LessonSelector(courseId, currentNav?.id || nodeId),
    [courseId, currentNav?.id, nodeId]
  );
  const node = useSelector(nodeMemo);
  const courseMemo = useMemo(() => CourseSelector(courseId), [courseId]);
  const { course } = useSelector(courseMemo);

  const addedLessonRef = useRef<string | null>(null);
  const [currentNode, setCurrentNode] = useState<ICourseNode | null>(null);

  const getHierarchy = useCallback(() => {
    if (courseId) {
      dispatch(courseActionAsync.getCourseHierarchy(courseId));
    }
  }, [courseId, dispatch]);

  const onDeleteNode = (nodeId: string) => {
    if (courseId) {
      dispatch(courseActionAsync.deleteNode(courseId, nodeId));
    }
  };

  const onRedirectHandler = useCallback(
    (redirectPath: string) => {
      history.push(redirectPath);
    },
    [history]
  );

  const onSelect = useCallback(
    (id: string, redirectNode?: ICourseNode) => {
      const node = currentHierarchy.find((node) => node.id === id);

      if (redirectNode) {
        setCurrentNav(redirectNode);
        setCurrentHierarchy(redirectNode.children || []);

        onRedirectHandler(coursePaths.courseLessons(courseId, id));
      }
      if (node?.type === 'lesson' && !redirectNode) {
        if (node?.children?.length > 0) {
          onRedirectHandler(coursePaths.courseLessons(courseId, node.children[0].id));
        }
      }
      if (node?.type === 'folder' && !redirectNode) {
        setCurrentNav(node);
        setCurrentHierarchy(node?.children || []);
      }
      if (!node && course && !redirectNode) {
        setCurrentNav(null);
        setCurrentHierarchy(course.children);
        // onRedirectHandler(coursePaths.courseLessons(courseId));
      }
    },
    [course, courseId, currentHierarchy, onRedirectHandler]
  );

  const onLessonCreated = useCallback((courseNode: ICourseNode) => {
    if (courseNode?.type === 'lesson') {
      addedLessonRef.current = courseNode.id;
    }
  }, []);

  const nodeCreateHandler = useCallback(
    (type: keyof typeof BlockType | 'lesson' | 'folder') => {
      if (courseId) {
        if (type === 'folder') {
          const parentId = currentNav && currentNav.type === 'folder' ? currentNav.id : null;
          dispatch(courseActionAsync.createNode(courseId, 'folder', parentId));
        } else {
          dispatch(
            courseActionAsync.createNode(
              courseId,
              'lesson',
              currentNav ? currentNav.id : null,
              undefined,
              undefined,
              onLessonCreated
            )
          );
        }
      }
    },
    [courseId, currentNav, dispatch, onLessonCreated]
  );

  const toPreviousFolder = useCallback(() => {
    if (course) {
      const previous = find<ICourseNode | undefined>(flatMapDeep(course.children, flatten), [
        'id',
        currentNav?.parent_id
      ]);
      if (currentNav?.parent_id === null) {
        setCurrentNav(null);
        setCurrentHierarchy(course.children);
      } else if (previous) {
        setCurrentNav(previous);
        setCurrentHierarchy(previous.children);
      }
    }
  }, [course, currentNav]);

  useEffect(() => {
    if (addedLessonRef.current && !nodeId) {
      const lesson = find<ICourseNode | undefined>(flatMapDeep(course?.children, flatten), [
        'id',
        addedLessonRef.current
      ]);

      if (lesson) {
        onRedirectHandler(coursePaths.courseLessons(courseId, lesson.children[0].id));
        addedLessonRef.current = null;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addedLessonRef.current, currentHierarchy?.length]);

  useEffect(() => {
    if (!course) {
      dispatch(courseActionAsync.getCourses());
    }
  }, [course, dispatch]);

  useEffect(() => {
    if (!node || node?.loadState === LoadState.needLoad) {
      getHierarchy();
    }
  }, [courseId, getHierarchy, node]);

  useEffect(() => {
    if (node?.loadState === LoadState.allIsLoaded) {
      if (node.lesson?.type === 'folder') {
        setCurrentNav(node.lesson);
        setCurrentHierarchy(node.lesson.children);
      }

      if (node.lesson && node.lesson?.type === 'step') {
        const lesson = find<ICourseNode | undefined>(flatMapDeep(course?.children, flatten), [
          'id',
          node.lesson?.parent_id
        ]);

        const folder = find<ICourseNode | undefined>(flatMapDeep(course?.children, flatten), [
          'id',
          currentHierarchy.length > 0 ? currentHierarchy[0].parent_id : lesson?.parent_id
        ]);
        setCurrentNav(folder || null);
        setCurrentHierarchy(folder?.children || course?.children || []);
      }

      if ((!node.lesson || node.lesson.type === 'lesson') && course) {
        setCurrentHierarchy(course.children);
      }
      // if (node.lesson?.type === 'lesson') {
      //   history.replace(coursePaths.courseLessons(courseId, node.lesson.children[0].id));
      // }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [course, node?.loadState]);

  return (
    <>
      {course && (
        <CourseContext.Provider
          value={{
            onSelect,
            toPreviousFolder,
            getHierarchy,
            currentNode,
            setCurrentNode,
            setCurrentHierarchy,
            onDeleteNode,
            nodeCreateHandler,
            currentHierarchy,
            currentNav,
            courseId,
            nodeId
          }}
        >
          <div className={classNames(styles.container, customContainerStyle)}>
            <CourseSidebar>{sidebar}</CourseSidebar>
            {course && (
              <AdminCourseWrapper course={course} nodeId={nodeId}>
                <div className={classNames('mx-auto', styles.wrapper, customStyles)}>{children}</div>
              </AdminCourseWrapper>
            )}
          </div>
        </CourseContext.Provider>
      )}
    </>
  );
});
