import classNames from 'classnames';
import styles from './quiz-moderation-action.module.scss';
import { IconCheckMark } from '../../../../../../components/UI/icons';

import { Button } from '../../../../../../components/UI';

interface IProps extends React.ComponentPropsWithoutRef<'button'> {
  markPassed: boolean | null;
  className?: string;
}

interface IFailedProps extends IProps {
  onQuestionFailed: () => void;
}

interface IPassedProps extends IProps {
  onQuestionPassed: () => void;
}
export const PassedModerationButton = ({ markPassed, onQuestionPassed, className, ...props }: IPassedProps) => {
  return (
    <Button
      btnStyle={'outline'}
      iconPosition={'left'}
      customIcon={<IconCheckMark className={styles.markQuizIcon} />}
      onClick={onQuestionPassed}
      className={classNames(
        styles.markQuizButton,
        styles.markQuizButtonSuccess,
        {
          [styles.markQuizButtonSelected]: markPassed === true
        },
        className
      )}
      btnProps={{ ...props }}
    >
      Passed
    </Button>
  );
};

export const FailedModerationButton = ({ markPassed, onQuestionFailed, className, ...props }: IFailedProps) => {
  return (
    <Button
      btnStyle={'outline'}
      iconPosition={'left'}
      customIcon={<IconCheckMark className={styles.markQuizIcon} />}
      onClick={onQuestionFailed}
      className={classNames(
        styles.markQuizButton,
        styles.markQuizButtonFailed,
        {
          [styles.markQuizButtonSelected]: markPassed === false
        },
        className
      )}
      btnProps={{ ...props }}
    >
      Failed
    </Button>
  );
};
