import { memo, SyntheticEvent, useMemo, useState } from 'react';

import classNames from 'classnames';
import styles from './quiz-filter.module.scss';
import { Icon16CheckMark, Icon16Cross, IconReload } from '../../../../../components/UI/icons';

import { Button } from '../../../../../components/UI';
import { QuizState } from '../../../../../interfaces/quiz';
import { IAnswersCourses } from '../answers-curses/answersCurses';

export interface IChips {
  readonly title: string;
  readonly value: QuizState | null;
  selected: boolean;
}

export const chipsDefault: IChips[] = [
  { title: 'All', value: null, selected: true },
  { title: 'Moderated', value: 'moderated', selected: false },
  { title: 'Pending moderation', value: 'pending_moderation', selected: false },
  { title: 'Passed', value: 'success', selected: false },
  { title: 'Failed', value: 'fail', selected: false }
];

interface IProps {
  onSelectChips(chips: IChips[]): void;
  resetCourses(): void;
  coursesList: IAnswersCourses[];
}

export const QuizFilter = memo(({ onSelectChips, resetCourses, coursesList }: IProps) => {
  const [chips, setData] = useState<IChips[]>(chipsDefault);

  const resetFilter = () => {
    const updatedChips = chips.map((chip) => {
      chip.selected = !chip.value;
      return chip;
    });

    setData(updatedChips);
    resetCourses();
  };

  const changeStateChip = (
    e: SyntheticEvent<HTMLButtonElement | HTMLSpanElement, Event> | undefined,
    selectedChip: IChips,
    remove = false
  ) => {
    e?.stopPropagation();
    if (selectedChip.selected && !remove) {
      return;
    }

    selectedChip.selected = !remove;

    const hasEverySelected = chips.filter((chip) => chip.value).every((chip) => chip.selected);
    const hasSomeSelected = chips.filter((chip) => chip.value).some((chip) => chip.selected);

    const updatedChips = chips.map((chip) => {
      if (!chip.value) {
        if (!hasEverySelected) {
          chip.selected = false;
        }

        if (hasEverySelected || (remove && !hasSomeSelected)) {
          chip.selected = true;
        }
      }

      if (!selectedChip.value) {
        chip.selected = true;
      }

      return chip;
    });

    setData(updatedChips);
    onSelectChips(updatedChips);
  };

  const chipsStateChanged = useMemo(() => {
    return (
      chips.filter((chip) => chip.value).some((chip) => chip.selected) ||
      coursesList.filter((course) => course.id).some((course) => course.selected)
    );
  }, [chips, coursesList]);

  return (
    <>
      <h6 className={styles.sidebarTitle}>
        Filter quizzes
        {chipsStateChanged && (
          <Button
            withIcon={false}
            onClick={resetFilter}
            btnProps={{ className: classNames('btn-icon btn', styles.reload) }}
          >
            <IconReload />
          </Button>
        )}
      </h6>
      <div className={styles.quizFilter}>
        <span className={styles.quizFilterTitle}>Quiz state</span>
        <div className={styles.chipsList}>
          {chips.map((chip) => (
            <span
              key={chip.value || chip.title}
              onClick={(e) => changeStateChip(e, chip)}
              className={classNames(styles.chip, { [styles.chipSelected]: chip.selected })}
            >
              {chip.selected && chip.value ? (
                <Button withIcon={false} onClick={(e) => changeStateChip(e, chip, true)} className={styles.chipRemove}>
                  {<Icon16Cross />}
                </Button>
              ) : (
                <Icon16CheckMark className={styles.chipCheckIcon} />
              )}
              {chip.title}
            </span>
          ))}
        </div>
      </div>
    </>
  );
});
