import { Form } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';

import classNames from 'classnames';
import styles from './custom-code.module.scss';

interface IProps {
  value?: string | null | undefined;
}

export const CustomCode = ({ value }: IProps) => {
  const { register } = useFormContext();

  return (
    <Form.Control
      as="textarea"
      {...register('customCode.code', { setValueAs: (value: string) => (value === '' ? null : value) })}
      defaultValue={value || ''}
      className={classNames(styles.code, 'resize-none')}
      placeholder={'Insert your custom code'}
    />
  );
};
