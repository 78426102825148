import { ReactElement } from 'react';
import { Form, FormControl, FormControlProps } from 'react-bootstrap';
import { FieldValues, useFormContext } from 'react-hook-form';

import classNames from 'classnames';
import styles from './setting-input.module.scss';

import { Error } from '../../../../components/UI/error/Error';

interface IProps<T> {
  label: string;
  name: T;
  defaultValue?: string | null;
  props?: FormControlProps;
  className?: string;
  children?: ReactElement;
}

export const SettingInput = <T extends string>({
  label,
  name,
  defaultValue,
  className,
  props,
  children
}: IProps<T>) => {
  const {
    register,

    formState: { errors }
  } = useFormContext<FieldValues>();

  return (
    <Form.Group className={classNames(styles.inputContainer, className)}>
      <Form.Label className={styles.label}>{label}</Form.Label>
      <FormControl
        className={classNames(styles.input, (errors[name] as any) && 'invalid')}
        type={'text'}
        {...register(name)}
        {...props}
        defaultValue={defaultValue}
      />
      <Error fieldName={(errors[name] as any)?.message} />
      {children}
    </Form.Group>
  );
};
