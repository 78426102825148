import { FC, memo, useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import classNames from 'classnames';
import styles from './subscriptionModal.module.scss';
import Attention from '../../../../../assets/images/attention.png';
import { Icon32ArrowRight, Icon32Cross } from '../../../../components/UI/icons';

import { Button, CommonModal } from '../../../../components/UI';
import { SettingsPaths } from '../../../settings/routes/SettingsRoutes';

interface IProps {
  showModal: boolean;
  closeModal: () => void;
}

export const SubscriptionModal: FC<IProps> = memo(({ closeModal, showModal }) => {
  const history = useHistory();

  const onClick = useCallback(() => {
    history.push(SettingsPaths.billing);
    closeModal();
  }, [closeModal, history]);

  return (
    <>
      <CommonModal onClose={closeModal} show={showModal}>
        <div className={classNames(styles.container, styles.wrapper)}>
          <img src={Attention} alt={'attention'} className={styles.icon} />
          <div className={styles.title}>Upgrade your plan!</div>
        </div>
        <div className={styles.container}>
          <div className={styles.description}>
            All the features are available starting with <br />
            Free Subscription Plan
          </div>
          <Button
            onClick={onClick}
            className={styles.btn}
            withIcon={true}
            customIcon={<Icon32ArrowRight />}
            iconPosition={'left'}
          >
            View available pricing plans
          </Button>
        </div>
        <Icon32Cross onClick={closeModal} className={styles.cross} />
      </CommonModal>
    </>
  );
});
