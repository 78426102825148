import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import querystring from 'query-string';

import classNames from 'classnames';
import styles from './login-student.module.scss';
import { IconSmGoogle } from '../../../../components/UI/icons';

import { Copyright } from '../../../../components/copyright/Copyright';
import { Button } from '../../../../components/UI';
import { useLogin } from '../../../../hooks/use-google-auth';
import { OrganizationImg } from '../../../../layout/shared-components/organization-img/OrganizationImg';
import { LoadState } from '../../../../store';
import { AuthContext } from '../../../auth/AuthContext';
import { ResetPassword } from '../../../auth/common/reset/ResetPassword';
import { EmailLogin } from '../../../auth/email-login/EmailLogin';
import { StudentCabinetPaths } from '../../routes/StudentCabinetRoutes';
import { StudentCabinetSelector } from '../../store/StudentCabinetSelector';
import { SidebarWrapper } from '../sidebar-wrapper/SidebarWrapper';

export const LoginStudent = () => {
  const { signIn } = useLogin();
  const history = useHistory<{ link?: string; course_id?: string }>();
  const params = querystring.parseUrl(window.location.href).query as {
    course_id?: string;
    email?: string;
    link?: string;
    action?: string;
  };

  const [loading, setLoading] = useState(false);
  const [isReset, setIsReset] = useState(false);

  const cabinetSettingsMemo = useMemo(StudentCabinetSelector, []);
  const { cabinetSettings, loadCabinetSettingsState } = useSelector((state) => cabinetSettingsMemo(state));

  const onForgot = () => {
    setIsReset(true);
  };

  const onAuth = () => {
    setIsReset(false);
  };

  const openSignUp = () => {
    history.push(StudentCabinetPaths.signUp);
  };

  const loaded = loadCabinetSettingsState === LoadState.allIsLoaded || loadCabinetSettingsState === LoadState.error;

  return (
    <AuthContext.Provider value={{ onForgot, onAuth }}>
      <SidebarWrapper loaded={loaded} themeSignIn={cabinetSettings?.theme_sign_in}>
        {loaded && (
          <div className={styles.organization}>
            <OrganizationImg
              organizationImg={cabinetSettings?.general_image || null}
              organizationName={cabinetSettings?.general_title || ''}
              themeLogo={cabinetSettings?.theme_logo || null}
              height={32}
              width={32}
            />
            {cabinetSettings?.general_title}
          </div>
        )}
        <div className={styles.form}>
          {isReset ? (
            <div className={styles.intro}>
              <ResetPassword />
            </div>
          ) : (
            <>
              <div className={classNames(styles.title, { [styles.resume]: params.email })}>
                {params.email ? 'Welcome back' : 'Sign in'}
              </div>
              {params.email && (
                <div className={styles.resumeAccessText}>
                  Use <b>{params.email}</b> to resume access to the course
                </div>
              )}
              <div className={params.email && 'd-flex flex-column flex-column-reverse '}>
                <Button
                  onClick={signIn}
                  size={'lg'}
                  btnStyle={'secondary'}
                  iconPosition={'left'}
                  customIcon={<IconSmGoogle width={32} height={32} />}
                  className={'w-100 mb-3'}
                  btnProps={{ disabled: loading }}
                >
                  Sign in with Google
                </Button>
                {!params.email && <div className={styles.label}>or using email and password</div>}
                <EmailLogin
                  defaultEmail={params.email}
                  loading={loading}
                  setLoading={setLoading}
                  isAdmin={false}
                  className={'mb-2'}
                />
              </div>
              {!params.email && (
                <Button onClick={openSignUp} btnStyle={'transparent'} withIcon={false} size={'lg'} className={'w-100'}>
                  Create new account
                </Button>
              )}
            </>
          )}
        </div>
        <div className={styles.copy}>
          <Copyright organization={cabinetSettings?.general_title} />
          <Link to={StudentCabinetPaths.policies('terms')} className={styles.link}>
            Terms and Conditions
          </Link>
        </div>
      </SidebarWrapper>
    </AuthContext.Provider>
  );
};
