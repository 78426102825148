import React, { memo } from 'react';
import { Dropdown } from 'react-bootstrap';

import classNames from 'classnames';
import styles from '../../course-navigation/course-navigation.module.scss';
import editDropStyles from './edit-drop-menu.module.scss';
import {
  IconChatBubbles,
  IconClearFormat,
  IconDuplicate,
  IconHourglass,
  IconKebabMenu,
  IconReply,
  IconTrash
} from '../../../../UI/icons';

import { SimpleToggle } from '../../../../../modules/crm/offers/create-offer/common/toggle/SimpleToggle';
import { CourseDropMenu } from '../../../../../modules/lms/courses/common/dropmenu/CourseDropMenu';
import { DropMove } from '../../../../../modules/lms/courses/common/dropMove/DropMove';
import { CurriculumState } from '../../../../../modules/lms/courses/store/CourseState';
import { DropMenu } from '../../../../UI';

interface IProps {
  showDialogNode: boolean;
  isActiveLesson: boolean;
  statusNode: CurriculumState;
  isMove: boolean;
  index: number;
  showComment: boolean | null;
  onToggleComments: () => void;
  onToggleDialogNode: (isOpen: boolean, event: React.SyntheticEvent<Dropdown, Event>) => void;
  onMoveHandler: (e: React.SyntheticEvent<HTMLDivElement>) => void;
  onCloseDialogNode: (e?: React.SyntheticEvent<HTMLDivElement | HTMLButtonElement>) => void;
  onDeleteConfirm: (e: React.SyntheticEvent<HTMLDivElement>) => void;
  onDuplicate: () => void;
  onDraftHandler: () => void;
  onDripHandler: () => void;
}

export const EditDropMenu = memo(
  ({
    isActiveLesson,
    isMove,
    showDialogNode,
    index,
    statusNode,
    showComment,
    onDeleteConfirm,
    onDuplicate,
    onCloseDialogNode,
    onMoveHandler,
    onToggleDialogNode,
    onDraftHandler,
    onToggleComments,
    onDripHandler
  }: IProps) => {
    return (
      <DropMenu
        show={showDialogNode}
        onToggle={onToggleDialogNode}
        node={
          <button
            type={'button'}
            className={classNames('btn btn-icon', styles.kebab, isActiveLesson && styles.activeKebab)}
          >
            <IconKebabMenu />
          </button>
        }
        drop={index < 3 ? 'down' : 'left'}
      >
        <Dropdown.Menu
          onClick={onCloseDialogNode}
          popperConfig={{
            strategy: 'fixed'
          }}
          className={'sm'}
        >
          {isMove ? (
            <DropMove onClose={onCloseDialogNode} />
          ) : (
            <>
              <CourseDropMenu
                icon={<IconClearFormat />}
                title={statusNode === 'draft' ? 'Unmark draft' : 'Mark as draft'}
                onClick={onDraftHandler}
              />
              <CourseDropMenu icon={<IconDuplicate />} title={'Duplicate'} onClick={onDuplicate} />
              {/* <CourseDropMenu icon={<IconCopy />} title={'Duplicate'} /> */}
              <CourseDropMenu icon={<IconReply />} title={'Move to folder'} onClick={onMoveHandler} />
              <CourseDropMenu icon={<IconHourglass />} title={'Drip settings'} onClick={onDripHandler} />
              <CourseDropMenu
                icon={<IconChatBubbles />}
                title={
                  <span className={editDropStyles.toggleContainer}>
                    Comments
                    <SimpleToggle isToggle={!!showComment} label="" classNameContainer={editDropStyles.container} />
                  </span>
                }
                onClick={onToggleComments}
              />
              <CourseDropMenu
                icon={<IconTrash />}
                title={'Delete'}
                containerStyles={'iconRed'}
                onClick={onDeleteConfirm}
              />
            </>
          )}
        </Dropdown.Menu>
      </DropMenu>
    );
  }
);
