import { Fragment, memo, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { orderBy } from 'lodash';

import classNames from 'classnames';
import styles from './answers.module.scss';

import { InfoMessage } from '../../../../../../../components/UI/infoMessage/InfoMessage';
import { AnswerTypeEnum, IQuizQuestion } from '../../../../../../../interfaces/quiz';
import { useQuizPayload } from '../../quiz-hooks/useQuizPayload';
import { quizActionAsync } from '../../store/QuizActionAsync';
import { useViewQuizContext } from '../../ViewQuizContext';
import { Answer } from '../answer/Answer';
import { AnswerInfo } from '../answer-info/AnswerInfo';
import { QuizAnswerBtn } from '../quiz-answer-btn/QuizAnswerBtn';

interface IProps {
  questionData: IQuizQuestion;
}

export const Answers = memo(({ questionData }: IProps) => {
  const dispatch = useDispatch();
  const { blockId, selectedAnswer, courseId, isPreview, onChangeSelectAnswer, onGetQuiz, onCompleteQuizInPreview } =
    useViewQuizContext<AnswerTypeEnum.multiple_select | AnswerTypeEnum.single_select>();
  const { question } = useQuizPayload<AnswerTypeEnum.single_select | AnswerTypeEnum.multiple_select>(questionData);

  const onSaveAnswerHandler = useCallback(() => {
    if (question.id && blockId && !isPreview && selectedAnswer) {
      dispatch(quizActionAsync.completeQuiz(courseId, blockId, question.id, 'quiz.answer', selectedAnswer, onGetQuiz));
    } else if (isPreview) {
      onCompleteQuizInPreview();
    }
  }, [blockId, courseId, dispatch, isPreview, onCompleteQuizInPreview, onGetQuiz, question.id, selectedAnswer]);

  return (
    <>
      <div className={'mb-4'}>
        {orderBy(question.answers, 'order', ['asc']).map((answer, index) => (
          <Fragment key={index}>
            {question.id && answer.id && (
              <Answer
                value={answer.id}
                attachment={answer.attachment}
                title={answer.title}
                answer_type={question.answer_type}
                questionId={question.id}
                selected={selectedAnswer as string[]}
                isPassed={question?.is_passed_with}
                onChangeSelect={onChangeSelectAnswer}
                isSuccess={answer.is_true}
              />
            )}
          </Fragment>
        ))}
      </div>
      <div className={classNames('row', styles.actions)}>
        {question.is_passed_with !== null && (
          <div className={'col'}>
            <AnswerInfo type={question.is_passed_with ? 'success' : 'wrong'} />
          </div>
        )}
        <div className={'col'}>
          <QuizAnswerBtn
            hasChoices={!!question.choices}
            isDisable={selectedAnswer === null || selectedAnswer.length === 0}
            onSaveAnswerHandler={onSaveAnswerHandler}
          />
        </div>
      </div>
      {question.explanation && (
        <div className={styles.explanationContainer}>
          <InfoMessage
            show={question.is_passed_with === false}
            text={'Explanation'}
            textClassName={styles.explanationTitle}
          >
            <div className={styles.explanationDescription}>{question.explanation}</div>
          </InfoMessage>
        </div>
      )}
    </>
  );
});
