import { KeyboardEvent, memo, SyntheticEvent, useRef, useState } from 'react';
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd';
import AutosizeInput from 'react-input-autosize';
import { useDispatch } from 'react-redux';

import classNames from 'classnames';
import styles from '../course-navigation/course-navigation.module.scss';
import { Icon16Draggable, Icon16PencilOnPaper, IconFolder, IconWebinar } from '../../../UI/icons';

import { CourseNodeType } from '../../../../interfaces';
import { useCourseContext } from '../../../../modules/lms/courses/common/sidebar/CourseContext';
import { courseActionAsync } from '../../../../modules/lms/courses/store/CourseActionAsync';
import { CurriculumState } from '../../../../modules/lms/courses/store/CourseState';
import { ItemStatus } from '../../../itemStatus/ItemStatus';

interface IProps {
  isActiveLesson: boolean;
  dragHandle: DraggableProvidedDragHandleProps | undefined;
  title: string;
  id: string;
  type: CourseNodeType;
  state: CurriculumState;
}

export const CourseNavigationItemRow = memo(({ id, title, state, type, isActiveLesson, dragHandle }: IProps) => {
  const dispatch = useDispatch();
  const [value, setValue] = useState(title);
  const [isReadonly, setIsReadonly] = useState(true);
  const { getHierarchy, courseId } = useCourseContext();
  const inputRef = useRef<any | null>(null);

  const onChange = (e: SyntheticEvent<HTMLInputElement>) => {
    setValue(e.currentTarget.value);
  };

  const onSaveHandler = () => {
    if (courseId && value !== title) {
      setIsReadonly(true);
      dispatch(courseActionAsync.editNode(courseId, id, { title: value }, getHierarchy));
    }
    setIsReadonly(true);
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      onSaveHandler();
    }
  };

  const onRenameHandler = (e: SyntheticEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    if (inputRef.current) {
      setIsReadonly(false);
      inputRef.current.focus();
    }
  };

  const isFolder = type === 'folder';
  const isLesson = !isFolder;

  return (
    <>
      <div className={styles.wrapper}>
        <div className={'d-flex w-100'}>
          <div className={styles.drag} {...dragHandle}>
            <Icon16Draggable />
          </div>
          <div className={styles.title}>
            {isFolder && <IconFolder className={styles.icon} />}
            {isLesson && <IconWebinar className={styles.icon} />}

            <AutosizeInput
              value={value || ''}
              ref={inputRef}
              maxLength={256}
              readOnly={isReadonly}
              onChange={onChange}
              onKeyDown={handleKeyDown}
              onBlur={onSaveHandler}
              inputClassName={classNames(styles.titleInput, isActiveLesson ? styles.active : null)}
              className={styles.inputContainer}
              onClick={() => !isReadonly}
              placeholder={isLesson ? 'Lesson name' : 'Folder name'}
            />
            <button type={'button'} className={classNames('btn btn-icon mr-1', styles.edit)} onClick={onRenameHandler}>
              <Icon16PencilOnPaper />
            </button>
          </div>
        </div>
        {state === 'draft' && <ItemStatus label={'Draft'} className={'mr-1'} />}
      </div>
    </>
  );
});
