import { FormCheck } from 'react-bootstrap';

import classNames from 'classnames';
import styles from './payload-once.module.scss';

export const PayloadOnlyOnceToggle = () => {
  return (
    <div className={classNames(styles.container, 'mb-3')}>
      <FormCheck className={'custom-control custom-switch-md my-1 switch-right'} custom type={'switch'}>
        <FormCheck.Label className="custom-control-label">Perform only once for an object</FormCheck.Label>
        <FormCheck.Input type="checkbox" className="custom-control-input" />
      </FormCheck>
    </div>
  );
};
