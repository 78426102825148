import { useCallback, useMemo, useState } from 'react';
import Fade from 'react-bootstrap/Fade';
import { useDispatch, useSelector } from 'react-redux';

import classNames from 'classnames';
import styles from './upload-worker.module.scss';
import { IconCross } from '../../../components/UI/icons';

import { ConfirmDeletedModal } from './ConfirmDeletedModal';
import { fileUploaderActions } from './store/FileUploaderActions';
import { FileUploadSelector } from './store/FileUploaderSelector';
import { IFileUploader } from './store/FileUploaderState';
import { LoaderVideoStatus } from './upload-header/loader-video-status/LoaderVideoStatus';
import { UploadHeader } from './upload-header/UploadHeader';
import { UploadItem } from './upload-item/UploadItem';
import { UploadVideoLoader } from './upload-video-loader/UploadVideoLoader';

export const UploadWorker = () => {
  const dispatch = useDispatch();
  const { files, isShowUploader } = useSelector((state) => state.fileUpload);
  const [showConfirm, setShowConfirm] = useState(false);
  const { isPayloadShow } = useSelector((state) => state.settings);
  const fileUploaderMemo = useMemo(FileUploadSelector, []);
  const { totalUploadingPercent, uploading, isComplete, isProcessing, isUpload, canceling, error } =
    useSelector(fileUploaderMemo);

  const onCloseModal = () => {
    setShowConfirm(false);
  };

  const onClearUploaded = () => {
    dispatch(fileUploaderActions.clearUploadedFiles());
    onCloseModal();
  };

  const confirmDeleted = () => {
    if (isUpload) {
      setShowConfirm(true);
    } else if (isProcessing) {
      dispatch(fileUploaderActions.setShowUploader({ show: false }));
    } else {
      onClearUploaded();
      dispatch(fileUploaderActions.setShowUploader({ show: false }));
    }
  };

  const onOpenUploader = () => {
    dispatch(fileUploaderActions.setShowUploader({ show: true }));
  };

  const onCancelVideos = () => {
    dispatch(fileUploaderActions.cancelAllUploading(uploading));
    onCloseModal();
  };

  const onCancel = useCallback(
    (file: IFileUploader) => {
      dispatch(
        fileUploaderActions.updateUploadFile({
          ...file,
          progress: 0,
          uploaderData: { ...file.uploaderData, status: 'canceled' }
        })
      );
      file?.cancelToken?.cancel();
    },
    [dispatch]
  );

  return (
    <div className={classNames(styles.wrapper, { [styles.moved]: isPayloadShow })}>
      <div className={styles.container} onClick={onOpenUploader}>
        <UploadVideoLoader
          uploading={uploading}
          isComplete={isComplete}
          isProcessing={isProcessing}
          isUpload={isUpload}
          totalUploadingPercent={totalUploadingPercent}
          showUploader={isShowUploader}
        />
      </div>
      <Fade in={isShowUploader} appear>
        <div>
          <div className={classNames(styles.containerCollapsed, { [styles.active]: isShowUploader })}>
            {showConfirm && <ConfirmDeletedModal onClose={onCloseModal} onDelete={onCancelVideos} />}
            <UploadHeader>
              <LoaderVideoStatus
                isComplete={isComplete}
                isProcessing={isProcessing}
                isUpload={isUpload}
                totalUploadingPercent={totalUploadingPercent}
                remainingCount={uploading.length}
                canceling={canceling}
                error={error}
                onCancelHandler={confirmDeleted}
              />
            </UploadHeader>
            <div className={styles.containerCollapsedBody}>
              <div className={styles.collapseContainer}>
                {files.map((file, index) => (
                  <UploadItem key={index} file={file} onCancel={onCancel} />
                ))}
              </div>
            </div>
          </div>
        </div>
      </Fade>
      {isComplete && (
        <IconCross
          className={classNames(styles.iconCross, !isShowUploader && styles.active)}
          onClick={onClearUploaded}
        />
      )}
    </div>
  );
};
