import { memo } from 'react';

import styles from './quiz-passing-grade.module.scss';
import { IconStarFilled } from '../../../../../../../../components/UI/icons';

import { PassingGradeTypeValues } from '../../../../../../../../interfaces/quiz';

interface IProps {
  total: number;
  required: number;
  scored: number;
  type: PassingGradeTypeValues;
}

export const QuizPassingGrade = memo(({ required, scored, total, type }: IProps) => {
  return (
    <div className={styles.container}>
      <div className={'d-flex align-items-end'}>
        <span className={styles.text}>
          {type === PassingGradeTypeValues.by_answer_points ? 'Collected points:' : 'Correct answers:'}&nbsp;
        </span>
        <div className={'d-flex align-items-end'}>
          {type === PassingGradeTypeValues.by_answer_points && <IconStarFilled className={styles.iconStar} />}

          <span className={styles.score}>
            {scored} of required {required}
          </span>
        </div>
      </div>
      <div className={styles.totalText}>
        {type === PassingGradeTypeValues.by_answer_points ? (
          <>
            Total points in this quiz: <span className={styles.totalValue}>{total}</span>
          </>
        ) : (
          <>
            Total questions in this quiz: <span className={styles.totalValue}>{total}</span>
          </>
        )}
      </div>
    </div>
  );
});
