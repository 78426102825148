import {
  ChangePasswordResponse,
  ConfirmEmailRequest,
  ConfirmEmailResponse,
  IChangePassword,
  ITokenRequest,
  ITokenResponse,
  RegisterUserRequest,
  RegisterUserResponse,
  ResetPasswordRequest,
  ResetPasswordResponse
} from '../interfaces';
import { baseFetch } from './BaseFetch';

export const googleOAuthScopes = [
  'https://www.googleapis.com/auth/userinfo.email',
  'https://www.googleapis.com/auth/userinfo.profile',
  'openid'
];

export const registerByEmail = async (params: RegisterUserRequest) => {
  return await baseFetch<RegisterUserRequest, RegisterUserResponse>('/users/email/register', params, 'POST');
};

/**
 * Login
 * @param request
 */
export const login = async (request: ITokenRequest) => {
  return await baseFetch<{}, ITokenResponse>(
    '/users/auth/login',
    {
      ...request,
      payload: {
        ...request.payload,
        scopes: googleOAuthScopes
      }
    },
    'POST'
  );
};

/**
 * It takes a state and a redirect_url, and returns a URL that you can use to redirect the user to
 * Google's OAuth page
 * @param {string | null} state - This is a subdomain string.
 * @param {string} redirect_url - The URL that the user will be redirected to after they have
 * authenticated with Google.
 * @returns The URL to redirect the user to for Google OAuth
 */
export const googleAuthUrl = async (state: string | null, redirect_url: string) => {
  return await baseFetch<{ state: string | null; redirect_url: string; scopes: string[] }, { url: string }>(
    '/users/auth/google/url',
    {
      state,
      redirect_url,
      scopes: googleOAuthScopes
    },
    'POST'
  );
};

/**
 * Resend email confirmation
 * @param request
 */
export const confirmEmail = async (request: ConfirmEmailRequest) => {
  return await baseFetch<{ email: string }, ConfirmEmailResponse>('/users/email/confirm', request, 'POST');
};

/**
 * Recover password
 * @param request
 */
export const recoverPassword = async (request: ResetPasswordRequest) => {
  return await baseFetch<{ email: string }, ResetPasswordResponse>('/users/email/recover', request, 'POST');
};

/**
 * Edit password for current user
 * @param request
 */
export const editPassword = async (request: IChangePassword) => {
  return await baseFetch<IChangePassword, ChangePasswordResponse>('/users/email/password', { ...request }, 'PUT');
};
