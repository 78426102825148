import { memo } from 'react';
import ReactDatePicker from 'react-datepicker';
import moment from 'moment';

import './calendar-drip.scss';
import styles from './calendar-drip.module.scss';

import { CustomInput } from '../../../../../../modules/common/builder/payloadForm/payloadData/payload-delay/until-fixed/CustomInput';
import { NodeCondition } from '../../../../../../modules/lms/courses/store/CourseState';

interface IProps {
  setSelectedOption: (value: NodeCondition) => void;
  selectOption: NodeCondition;
}

export const CalendarDrip = memo(({ setSelectedOption, selectOption }: IProps) => {
  const onChange = (date: Date | string | null) => {
    if (date && typeof date !== 'string') {
      setSelectedOption({ type: 'delayed', open_at: date.toISOString() });
    }
  };

  return (
    <div className={'mt-2'}>
      <ReactDatePicker
        calendarClassName={'date-field'}
        className={'form-control form-control px-6'}
        dateFormat="dd MMMM, yyyy, hh:mm a"
        popperPlacement={'top'}
        onChange={onChange}
        selected={selectOption?.open_at ? moment(selectOption.open_at).toDate() : moment().toDate()}
        showTimeInput
        customInput={<CustomInput iconclassname={styles.iconDrip} utcclassname={styles.utcDrip} />}
      />
    </div>
  );
});
