import { createSelector } from 'reselect';
import { filter } from 'lodash';

import { IAutomationNodeBuilder, PaletteAutomationElementTypes } from '../../../interfaces';
import { IAppState } from '../../../store';

export const EventNodesSelector = () =>
  createSelector(
    ({ builder }: IAppState) => builder.builder,
    (state: { builder: { loadState: any } }) => state.builder.loadState,
    (state: { builder: { error: any } }) => state.builder.error,
    (builder) => {
      return {
        nodes: filter(
          builder.nodes,
          (node) => node.type.search(/^event\./) !== -1
        ) as IAutomationNodeBuilder<PaletteAutomationElementTypes>[]
      };
    }
  );
