import { useSelector } from 'react-redux';

import { Loader } from '../../../components/UI';
import { LoadState } from '../../../store';
import { PeriodTimes } from '../../dashboard/PeriodTimes/PeriodTimes';
import { SettingsHeader } from '../SettingsHeader';
import { AvailablePlans } from './available-plans/AvailablePlans';
import { BusinessAddress } from './business-address/BusinessAddress';
import { PaymentHistory } from './payment-history/PaymentHistory';
import { PaymentOptions } from './payment-options/PaymentOptions';

export const Billing = () => {
  const { subscription, loadState } = useSelector((state) => state.billing);

  return (
    <>
      <SettingsHeader title={'Billing'} />
      {loadState === LoadState.firstLoad ? (
        <Loader />
      ) : (
        <>
          {subscription && loadState === LoadState.allIsLoaded && (
            <PeriodTimes subscription={subscription} fromBilling={true} />
          )}
          <AvailablePlans />
          <PaymentOptions />
          <PaymentHistory />
          <BusinessAddress />
        </>
      )}
    </>
  );
};
