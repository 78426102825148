import { reducerWithInitialState } from 'typescript-fsa-reducers';

import { commonErrorHandler, commonFirstLoadHandler } from '../../../../helpers/common-reducer-handlers';
import { LoadState, newState } from '../../../../store';
import { GeneralSettingsActions } from './GeneralSettingsActions';
import { GeneralSettingsInitialState, IGeneralSettingsState } from './GeneralSettingsState';

export const generalSettingsReducer = reducerWithInitialState<IGeneralSettingsState>(GeneralSettingsInitialState)
  .case(GeneralSettingsActions.editOrganization.started, commonFirstLoadHandler)
  .case(GeneralSettingsActions.editOrganization.done, (state, { result }) => {
    return newState(state, {
      error: null,
      loadState: LoadState.allIsLoaded,
      general: result
    });
  })
  .case(GeneralSettingsActions.editOrganization.failed, commonErrorHandler)
  .case(GeneralSettingsActions.getOrganization.started, commonFirstLoadHandler)
  .case(GeneralSettingsActions.getOrganization.done, (state, { result }) => {
    return newState(state, {
      error: null,
      loadState: LoadState.allIsLoaded,
      general: result
    });
  })
  .case(GeneralSettingsActions.getOrganization.failed, commonErrorHandler)
  .case(GeneralSettingsActions.getStripeAccount.started, commonFirstLoadHandler)
  .case(GeneralSettingsActions.getStripeAccount.done, (state, { result }) => {
    return newState(state, {
      error: null,
      loadState: LoadState.allIsLoaded,
      general: state.general,
      paymentAccount: {
        ...state.paymentAccount,
        stripe: result
      }
    });
  })
  .case(GeneralSettingsActions.getStripeAccount.failed, commonErrorHandler)
  .case(GeneralSettingsActions.getPaypalAccount.started, commonFirstLoadHandler)
  .case(GeneralSettingsActions.getPaypalAccount.done, (state, { result }) => {
    return newState(state, {
      error: null,
      loadState: LoadState.allIsLoaded,
      general: state.general,
      paymentAccount: {
        ...state.paymentAccount,
        paypal: result
      }
    });
  })
  .case(GeneralSettingsActions.getPaypalAccount.failed, (state) => {
    return newState(state, {
      ...state,
      paymentAccount: {
        ...state.paymentAccount,
        paypal: null
      }
    });
  })

  .case(GeneralSettingsActions.addPaynet.started, commonFirstLoadHandler)
  .case(GeneralSettingsActions.addPaynet.done, (state, { result }) => {
    return newState(state, {
      error: null,
      loadState: LoadState.allIsLoaded,
      general: state.general,
      paymentAccount: {
        ...state.paymentAccount,
        paynet: result
      }
    });
  })
  .case(GeneralSettingsActions.addPaynet.failed, commonErrorHandler)
  .case(GeneralSettingsActions.getPaynetAccount.started, commonFirstLoadHandler)
  .case(GeneralSettingsActions.getPaynetAccount.done, (state, { result }) => {
    return newState(state, {
      error: null,
      loadState: LoadState.allIsLoaded,
      general: state.general,
      paymentAccount: {
        ...state.paymentAccount,
        paynet: result
      }
    });
  })
  .case(GeneralSettingsActions.getPaynetAccount.failed, commonErrorHandler)
  .case(GeneralSettingsActions.detachPaynetAccount.started, commonFirstLoadHandler)
  .case(GeneralSettingsActions.detachPaynetAccount.done, (state) => {
    return newState(state, {
      ...state,
      error: null,
      loadState: LoadState.allIsLoaded,
      paymentAccount: {
        ...state.paymentAccount,
        paynet: null
      }
    });
  })
  .case(GeneralSettingsActions.detachPaynetAccount.failed, commonErrorHandler);
