import plans from '../modules/settings/billing/available-plans/plans-data.json';
import { stripePlansDev, stripePlansProd, StripeSubscriptionPlans } from '../modules/settings/billing/store';

export const getPlans = () => {
  const planIds = process.env.REACT_APP_ENV === 'production' ? stripePlansProd : stripePlansDev;
  return plans.map((plan) => {
    const planID = plan.id as `${StripeSubscriptionPlans}`;
    if (planID) {
      plan = {
        ...plan,
        id: planIds[planID]?.id,
        month: {
          ...plan.month,
          id: planIds[planID]?.month
        },
        year: {
          ...plan.year,
          id: planIds[planID]?.year
        }
      };
    }
    return plan;
  });
};
