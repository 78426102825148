import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactTags, { SuggestionComponentProps, Tag, TagComponentProps } from 'react-tag-autocomplete-fix';
import _ from 'lodash';

import './autocompletetags.scss';
import styles from './autocompleteTag.module.scss';

import { ColorItems } from '../../../interfaces';
import { IStudent } from '../../lms/students/store/StudentListState';
import { IContact } from '../contact/store/ContactListState';
import { TagActionAsync } from './store/TagActionAsync';
import { TagsSelector } from './store/TagSelector';
import { SuggestionElement } from './SuggestionElement';
import { CreateNewTag } from './suggestions/CreateNewTag';
import { TagElement } from './TagElement';

interface IProps {
  tags: Tag[];
  userEntity?: IContact | IStudent;
  isShowDetach?: boolean;
  isShowEdit?: boolean;
  isCreateNew?: boolean;
  isSingle?: boolean;

  onSetTag(x: Tag[]): void;
}

export const AutocompleteTag = memo(
  ({ onSetTag, tags, userEntity, isShowDetach, isShowEdit, isCreateNew = true, isSingle = false }: IProps) => {
    const [inputText, setInputText] = useState('');
    const dispatch = useDispatch();
    const tagMemo = useMemo(TagsSelector, []);
    const smallTag = useSelector((state) => tagMemo(state));
    const suggestionRef = useRef<number | null>(null);

    useEffect(() => {
      if (smallTag.length === 0) {
        dispatch(TagActionAsync.getTags());
      }
    }, [dispatch, smallTag.length]);

    useEffect(() => {
      if (userEntity && userEntity.tags && userEntity.tags.length > 0) {
        let tagsOmit = _.map(
          userEntity.tags,
          _.partial(_.omit, _, [
            'background_color',
            'foreground_color',
            'date_create',
            'date_update',
            'organization_id'
          ])
        ) as { id: string; title: string }[];

        const newObj: Tag[] = _.map(tagsOmit, ({ id, title }) => ({ id, name: title }));
        const likeTags = _.differenceWith(newObj, smallTag, (o1, o2) => o1['id'] === o2['id']);

        onSetTag([...tags, ...likeTags]);
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userEntity]);

    const handleDetachTagByIndex = useCallback(
      (i: number) => {
        onSetTag(tags.filter((_, index) => index !== i));
      },
      [onSetTag, tags]
    );

    const onCreateTagCallback = useCallback(
      (newTag: Tag) => {
        if (newTag) {
          onSetTag([...tags, newTag]);
          setInputText('');
        }
      },
      [onSetTag, tags]
    );

    const handleAddition = useCallback(
      (tag?: Tag) => {
        if (inputText.length === 0 && !tag) {
          return;
        }
        if (tags.find((x) => x.name === inputText)) {
          return;
        }

        if (inputText.length > 0 && tag && tag.id === -1) {
          const newColor = _.shuffle(ColorItems)[0];
          dispatch(TagActionAsync.createTag(inputText, newColor.background, newColor.color, onCreateTagCallback));
        } else if (tag && tag.name !== '') {
          onSetTag([...tags, tag]);
        }
        setInputText('');
      },
      [dispatch, inputText, onCreateTagCallback, onSetTag, tags]
    );

    const handleDetachTag = useCallback(
      (tag: Tag) => {
        onSetTag(tags.filter((x) => x.id !== tag.id));
      },
      [onSetTag, tags]
    );

    const onInput = useCallback((e: string) => {
      setInputText(e);
    }, []);

    const classNames = {
      root: styles.container,
      rootFocused: '',
      search: isSingle && tags.length > 0 ? styles.hideAdd : styles.showAdd,
      searchInput: inputText.length > 0 ? styles.searchInputFill : styles.searchInput,
      selected: styles.selected,
      selectedTag: styles.selectedTag,
      selectedTagName: '',
      suggestionActive: styles.suggestionActive,
      suggestionDisabled: styles.suggestionDisabled,
      suggestions: styles.suggestions
    };

    const onSuggestionsTransform = (query: string, suggestions: Tag[]) => {
      const sugAllFullTextEqual = suggestions.filter((sug) => sug.name === query);
      const sugAll = suggestions.filter((tag) => tag.name.includes(query));
      const res = _.differenceBy(sugAll, tags, 'id');
      suggestionRef.current = res.length;

      if ((sugAllFullTextEqual.length === 0 && query.length > 0) || res.length === 0) {
        return isCreateNew ? [...res, { id: -1, name: '' }] : res;
      }
      return res;
    };

    const suggestionComponent = ({ item, query }: SuggestionComponentProps) => {
      return <SuggestionElement item={item} query={query} onAdd={handleAddition} />;
    };

    const tagComponent = ({ tag, classNames }: TagComponentProps) => (
      <TagElement
        onDelete={() => {}}
        removeButtonText={''}
        classNames={classNames}
        tag={tag}
        onDetach={handleDetachTag}
        isShowDetach={isShowDetach}
        isShowEdit={isShowEdit}
      />
    );

    return (
      // <div onKeyDown={(e) => handleAddition()}>
      <ReactTags
        classNames={classNames}
        placeholderText={'Add tag'}
        minQueryLength={0}
        maxSuggestionsLength={20}
        allowBackspace
        autofocus
        autoresize
        tags={tags}
        suggestions={_.differenceBy(smallTag, tags, 'id')}
        onDelete={handleDetachTagByIndex}
        onAddition={handleAddition}
        onInput={onInput}
        suggestionsTransform={onSuggestionsTransform}
        suggestionComponent={suggestionComponent}
        tagComponent={tagComponent}
        inputAttributes={{ maxLength: 250 }}
        noSuggestionsText={(<CreateNewTag query={inputText} />) as any}
        allowNew
      />
      // </div>
    );
  }
);
