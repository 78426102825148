import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import { IRedirectCheckout } from '../../../store/OffersState';
import { ActionRedirect } from './ActionRedirect';

interface IProps {
  redirectToCustom?: IRedirectCheckout | null;
  isToggle: boolean;
  setIsToggle: (show: boolean) => void;
}

export const ActionRedirectWrapper = ({ isToggle, setIsToggle, redirectToCustom }: IProps) => {
  const { setValue } = useFormContext();

  const onChangeValue = (show: boolean) => {
    setIsToggle(show);
    if (!show) {
      setValue('action_redirect_after_checkout', null, { shouldDirty: true });
    }
  };

  useEffect(() => {
    if (redirectToCustom) {
      setValue('action_redirect_after_checkout.in_same_tab', String(redirectToCustom.in_same_tab));
    }
    // disable lint need to fix maximum redirect
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <ActionRedirect isToggle={isToggle} onChangeValue={onChangeValue} />;
};
