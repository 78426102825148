import { useCallback, useEffect, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { CSSTransition } from 'react-transition-group';

import styles from './customization.module.scss';
import { IconImageGallery, IconReload } from '../../../components/UI/icons';

import { ImageBGUpload } from '../../../components/image-bg-upload/ImageBGUpload';
import { ColorSelector } from '../../../components/UI/color-selector/ColorSelector';
import { IGeneralSettings } from '../../../interfaces';
import { fileUploadActionAsync } from '../../../layout/shared-components/upload-worker/store/FileUploadActionAsync';
import { LoadState } from '../../../store';
import { HelperWrapper } from '../common/help/HelperWrapper';
import Footer from '../common/settings-actions/SettingsActions';
import { UploadLogo } from '../common/upload-logo/UploadLogo';
import { GeneralSettingsActionAsync } from '../general/store/GeneralSettingsActionAsync';
import { SettingsHeader } from '../SettingsHeader';

export const Customization = () => {
  const dispatch = useDispatch();
  const { general, loadState } = useSelector((state) => state.general);
  const [imageChanged, setImageChanged] = useState(false);
  const [remove, setRemove] = useState(false);
  const methods = useForm<Partial<IGeneralSettings>>({
    shouldUnregister: false,
    defaultValues: { ...general }
  });

  useEffect(() => {
    methods.setValue('theme_logo', general.theme_logo);
    methods.setValue('theme_brand_color', general.theme_brand_color);
    methods.setValue('theme_background_color', general.theme_background_color);
    methods.setValue('theme_sign_in', general.theme_sign_in);
    methods.setValue('theme_sign_up', general.theme_sign_up);
  }, [
    general.theme_background_color,
    general.theme_brand_color,
    general.theme_logo,
    general.theme_sign_in,
    general.theme_sign_up,
    methods
  ]);

  const onImageChange = useCallback(() => {
    setImageChanged(true);
  }, []);

  const onSaveCallback = useCallback(
    (data: IGeneralSettings) => {
      methods.reset({
        theme_logo: data.theme_logo,
        theme_brand_color: data.theme_brand_color,
        theme_background_color: data.theme_background_color,
        theme_sign_in: data.theme_sign_in,
        theme_sign_up: data.theme_sign_up
      });
    },
    [methods]
  );

  const restore = useCallback(() => {
    methods.reset({
      theme_brand_color: general.theme_brand_color,
      theme_background_color: general.theme_background_color
    });
  }, [general.theme_background_color, general.theme_brand_color, methods]);

  const onSave = useCallback(async () => {
    const data: Partial<IGeneralSettings> & { image?: FileList | null } = methods.getValues();

    try {
      if (data.theme_logo && data.theme_logo?.file) {
        await dispatch(
          fileUploadActionAsync.retrieveUrlAndUploadFile(data.theme_logo?.file, (result) => {
            data.theme_logo = result;
          })
        );
      }
      if (data.image?.length) {
        await dispatch(
          fileUploadActionAsync.retrieveUrlAndUploadFile(data.image[0], (result) => {
            data.theme_sign_up = result;
            data.theme_sign_in = result;
          })
        );
      }

      if (imageChanged && (data?.image?.length === 0 || data?.image === null)) {
        data.theme_sign_up = null;
        data.theme_sign_in = null;
      }
      setImageChanged(false);
      setRemove(false);
      dispatch(
        GeneralSettingsActionAsync.editOrganization(
          {
            ...general,
            theme_logo: data.theme_logo,
            theme_brand_color: data.theme_brand_color,
            theme_background_color: data.theme_background_color,
            theme_sign_in: data.theme_sign_in,
            theme_sign_up: data.theme_sign_up
          },
          onSaveCallback
        )
      );
    } catch (e) {
      console.log('%c⇒ e', 'color: #FF5370', e);
    }
  }, [dispatch, general, imageChanged, methods, onSaveCallback]);

  const onCancel = useCallback(() => {
    methods.reset({ ...general }, { keepDirtyValues: false });
    setImageChanged(false);
    setRemove(true);
  }, [general, methods]);

  const dirtyColorFields =
    methods.formState.dirtyFields.theme_background_color || methods.formState.dirtyFields.theme_brand_color;

  return (
    <FormProvider {...methods}>
      <SettingsHeader title={'Theme customization'} />
      <HelperWrapper>
        {loadState === LoadState.allIsLoaded && (
          <div className={styles.container}>
            <div className={styles.intro}>
              In this section, you can change the color theme of the student's personal dashboard, upload a logo and
              customize authentication page background image. All the changes will be applied automatically on student’s
              dashboard after you save it.
            </div>
            <div className={styles.bar}>
              <div className={styles.colors}>
                <ColorSelector
                  title={'Brand color'}
                  name={'theme_brand_color'}
                  initialColor={methods.watch().theme_brand_color || 'rgba(0, 91, 204, 1)'}
                />
                <ColorSelector
                  title={'Background'}
                  name={'theme_background_color'}
                  initialColor={methods.watch().theme_background_color || 'rgba(248,247,248,1)'}
                />
                {dirtyColorFields && (
                  <div className={styles.restore} onClick={restore}>
                    Restore <IconReload />
                  </div>
                )}
              </div>

              <UploadLogo className={'pr-2'} />
            </div>
            <DndProvider backend={HTML5Backend}>
              <ImageBGUpload
                image={methods.watch().theme_sign_in}
                height={1080}
                width={1920}
                fileLimit={2 * 1024 * 1024}
                customization={true}
                onChange={onImageChange}
                remove={remove}
              >
                <IconImageGallery className={styles.icon} />
                <p className={styles.text}>
                  Drag and drop an image, or <span className={styles.textPrimary}> Browse</span>
                </p>
                <p className={styles.textSecond}>background image for Sign In and Sign Up pages</p>
                <p className={styles.note}>
                  Recommended minimum size 1920×1080 px or higher <br />
                  Maximum file size 2 MB
                </p>
              </ImageBGUpload>
            </DndProvider>
            <div className={styles.disclaimer}>
              Follow this steps to see how the course will look like from the student's side with the changed color
              scheme: <br />
              1. Go to the "School" section
              <br />
              2. Create and select the course or open an existing one
              <br />
              3. Create a lesson and add and fill a content block
              <br />
              4. Update changes
              <br />
              5. In the upper right corner of the curriculum, click the "Preview" button
            </div>
          </div>
        )}
        <CSSTransition in={methods.formState.isDirty || imageChanged} timeout={300} classNames={styles} unmountOnExit>
          <Footer methods={methods} onCancel={onCancel} onSave={onSave} />
        </CSSTransition>
      </HelperWrapper>
    </FormProvider>
  );
};
