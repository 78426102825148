import { Spinner, SpinnerProps } from 'react-bootstrap';

import classNames from 'classnames';
import './spinner.scss';

interface IProps {
  spinnerProps?: Partial<SpinnerProps>;
  text?: string;
  textStyle?: string;
  variant?: 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info' | 'dark' | 'light';
  size?: 'sm' | 'md' | 'lg';
}

export const SpinnerLoader = ({ spinnerProps, text, textStyle, variant, size }: IProps) => {
  return (
    <>
      <Spinner
        animation="border"
        role={'status'}
        variant={variant}
        {...spinnerProps}
        className={classNames(`spinner-${size}`, spinnerProps?.className)}
      />
      <div className={textStyle}>{text}</div>
    </>
  );
};
