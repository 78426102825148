import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { matchPath } from 'react-router';
import { Redirect } from 'react-router-dom';

import styles from './custom-styles.module.scss';

import { IRoutes } from '../../../../interfaces';
import { CourseHeaderPanel } from '../../../../layout/header/courseHeaderPanel/CourseHeaderPanel';
import { SchoolHeaderPanel } from '../../../../layout/header/schoolHeaderPanel/SchoolHeaderPanel';
import { AdminCourse } from '../admin-course/AdminCourse';
import { BulkUploadWrapper } from '../common/sidebar/lessons-sidebar/bulk-upload-wrapper/BulkUploadWrapper';
import { LessonsSidebar } from '../common/sidebar/lessons-sidebar/LessonsSidebar';
import { SettingsSidebar } from '../common/sidebar/settings-sidebar/SettingsSidebar';
import { CourseList } from '../course-list/CourseList';
import { LessonWrapper } from '../curriculum/lesson/LessonWrapper';
import { Pricing } from '../pricing/Pricing';
import { CoursePublish } from '../publish/CoursePublish';
import { courseSettingsPaths } from '../settings/routes/CourseSettingsRoutes';

export const coursePaths = {
  courseList: '/courses',
  courseLessons: (id = ':id', nodeId = '') => `/courses/${id}/curriculum/${nodeId}`,
  courseSettings: (id = ':id') => `/courses/${id}/settings`,
  coursePrice: (id = ':id', offerId = '') => `/courses/${id}/price/${offerId}`,
  coursePublish: (id = ':id') => `/courses/${id}/publish`
};

const getCourseId = () => {
  const path = matchPath<{ id: string }>(window.location.pathname, {
    path: [coursePaths.courseSettings(), courseSettingsPaths.general()]
  });
  return path?.params.id;
};

export const CourseRoutes: IRoutes[] = [
  {
    path: coursePaths.courseList,
    exact: true,
    main: () => (
      <div className={'content'}>
        <CourseList />
      </div>
    ),
    header: () => <SchoolHeaderPanel />
  },
  {
    path: coursePaths.courseLessons(),
    exact: false,
    main: () => (
      <AdminCourse
        customStyles={styles.container}
        sidebar={
          <DndProvider backend={HTML5Backend}>
            <BulkUploadWrapper>
              <LessonsSidebar />
            </BulkUploadWrapper>
          </DndProvider>
        }
      >
        <LessonWrapper />
      </AdminCourse>
    ),
    header: () => <CourseHeaderPanel />
  },
  {
    path: coursePaths.courseSettings(),
    exact: true,
    main: () => <Redirect to={courseSettingsPaths.general(getCourseId())} />,
    sidebar: () => <SettingsSidebar />,
    header: () => <CourseHeaderPanel />
  },
  {
    path: coursePaths.coursePublish(),
    exact: true,
    main: () => <CoursePublish />,
    header: () => <CourseHeaderPanel />
  },
  {
    path: coursePaths.coursePrice(),
    main: () => <Pricing />,
    header: () => <CourseHeaderPanel />
  }
];
