import classNames from 'classnames';
import styles from './title-view.module.scss';
import { IconChevronLeft } from '../../../../UI/icons';

import { useViewCourseContext } from '../../../../../modules/lms/students/student-course/ViewCourseContext';

export const CourseNavigationTitleView = () => {
  const { currentNav, toPreviousFolder } = useViewCourseContext();
  return (
    <div className={classNames(styles.container, 'px-2 py-3')}>
      {currentNav && (
        <button type={'button'} className={'btn btn-icon'} onClick={toPreviousFolder}>
          <IconChevronLeft className={styles.icon} />
        </button>
      )}
      <div className={classNames(styles.title, 'ellipsis')}>{currentNav?.title || 'Home'}</div>
    </div>
  );
};
