import { memo, useCallback, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { omit, omitBy } from 'lodash';

import styles from './action.module.scss';

import { ICourse } from '../../../../lms/courses/store/CourseState';
import { IList } from '../../../lists/store/ListsState';
import { OfferUnsavedChanges } from '../../common/unsaved/OfferUnsavedChanges';
import { IOffer } from '../../store/OffersState';
import CommonToggle from '../common/toggle/CommonToggle';
import UpdateChange from '../common/update-change/UpdateChange';
import { useCreateOffer } from '../use-create-offer';
import { actionSchema } from './actionSchema';
import { ActionAddToList } from './addToList/ActionAddToList';
import { EmailAfterPurchase } from './email/EmailAfterPurchase';
import { ActionEnrollWrapper } from './enroll/ActionEnrollWrapper';
import { ActionRedirectWrapper } from './redirect/ActionRedirectWrapper';

export type ActionOffer = Pick<
  IOffer,
  | 'id'
  | 'action_add_to_list'
  | 'action_email_after_purchase'
  | 'action_enroll_in_courses'
  | 'action_redirect_after_checkout'
  | 'action_send_receipt_after_purchase'
  | 'action_email_after_purchase_sender_id'
>;

interface IProps extends ActionOffer {
  getOffer: () => void;
}

export const Actions = memo((props: IProps) => {
  const methods = useForm<Omit<ActionOffer, 'id'>>({
    defaultValues: props,
    shouldUnregister: true,
    resolver: yupResolver(actionSchema),
    reValidateMode: 'onChange'
  });
  const [selectedCourses, setSelectedCourses] = useState<ICourse[]>([]);
  const [selectedLists, setSelectedLists] = useState<Pick<IList, 'id' | 'title'>[]>([]);

  const redirectToCustom = methods.watch('action_redirect_after_checkout');

  const [isToggleRedirect, setIsToggleRedirect] = useState(!!redirectToCustom);
  const [isToggleEmailPurchase, setIsToggleEmailPurchase] = useState(!!props.action_email_after_purchase);

  const isActivePurchase = methods.watch('action_send_receipt_after_purchase');

  const onReset = useCallback(() => {
    methods.reset({
      ...omit(props, ['id', 'getOffer']),
      action_redirect_after_checkout: props.action_redirect_after_checkout
        ? {
            in_same_tab: String(props.action_redirect_after_checkout?.in_same_tab),
            url: props.action_redirect_after_checkout?.url
          }
        : (null as any)
    });
  }, [methods, props]);

  const onCancelHandler = useCallback(() => {
    setIsToggleRedirect(!!props.action_redirect_after_checkout);
    setIsToggleEmailPurchase(!!props.action_email_after_purchase);
  }, [props.action_email_after_purchase, props.action_redirect_after_checkout]);

  const onSaveHandler = (data: Partial<Omit<ActionOffer, 'id'>>) => {
    const propsData = omit(props, ['id', 'getOffer']);
    const touchFields = omitBy(data, (value: any, key: keyof Omit<ActionOffer, 'id'>) => {
      return propsData[key] === value;
    });
    onSave(touchFields);
  };

  const { onCancel, onClose, onSave, save, showUnsavedModal, loadState } = useCreateOffer(
    props.id,
    props.getOffer,
    onReset,
    methods.formState.isDirty,
    onCancelHandler
  );

  return (
    <FormProvider {...methods}>
      <div className={styles.description}>
        Specify the actions to be performed after your customer makes a purchase.
      </div>
      <CommonToggle
        isActive={!!isActivePurchase}
        name={'action_send_receipt_after_purchase'}
        title={'Send receipt after purchase'}
        text={'Activate this option to send the customer a payment confirmation receipt by mail.'}
      />
      <ActionEnrollWrapper
        enrols={props.action_enroll_in_courses}
        selectedCourses={selectedCourses}
        setSelectedCourses={setSelectedCourses}
      />
      <ActionAddToList list={props.action_add_to_list} selected={selectedLists} setSelected={setSelectedLists} />
      <ActionRedirectWrapper
        setIsToggle={setIsToggleRedirect}
        isToggle={isToggleRedirect}
        redirectToCustom={redirectToCustom}
      />
      <EmailAfterPurchase
        action_email_after_purchase={props.action_email_after_purchase}
        action_email_after_purchase_sender_id={props.action_email_after_purchase_sender_id}
        isToggle={isToggleEmailPurchase}
        setIsToggle={setIsToggleEmailPurchase}
      />
      <UpdateChange
        showUpdate={methods.formState.isDirty}
        loadState={loadState}
        methods={methods}
        onCancel={onCancel}
        onSave={onSaveHandler}
      />
      <OfferUnsavedChanges
        onClose={onClose}
        onCancel={onCancel}
        onSave={onSaveHandler}
        show={showUnsavedModal && save}
        methods={methods}
      />
    </FormProvider>
  );
});
