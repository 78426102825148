import { memo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import classNames from 'classnames';
import styles from './reset.module.scss';
import MailPicture from '../../../../../assets/images/confirmation.png';

import { EmailActionStatusEnum } from '../../../../API/action-email-api';
import { IUserAuth } from '../../../../interfaces';
import { AuthActionAsync } from '../../store/AuthActionAsync';
import { Timer } from '../timer/Timer';

interface IProps {
  stopCountdown: () => void;
  resetCountdown: () => void;
  count: number;
}
export const ResetSuccess = memo(({ count, stopCountdown, resetCountdown }: IProps) => {
  const dispatch = useDispatch();
  const { getValues } = useFormContext<Pick<IUserAuth, 'email'>>();
  const { email } = getValues();

  const onResend = () => {
    dispatch(
      AuthActionAsync.forgotEmail(
        {
          email,
          callback_url: `${window.location.origin}/?link={hash}&action=${EmailActionStatusEnum.password_recovery}`
        },
        resetCountdown
      )
    );
  };

  return (
    <div className={styles.successContainer}>
      <div className={styles.successText}>
        If your email address <span className={styles.email}>{email}</span> exists in our database, you will receive a
        password recovery link at your inbox in a few minutes.
      </div>
      <div className={styles.timerContainer}>
        <div className={classNames(styles.conformationText, !count && styles.active)} onClick={onResend}>
          Resend confirmation instructions
        </div>
        {count > 0 && <Timer seconds={count} onFinish={stopCountdown} className={styles.textTimer} />}
      </div>
      <img width={80} height={80} src={MailPicture} alt={'mail'} className={styles.mailPicture} />
    </div>
  );
});
