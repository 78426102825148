import { actionCreatorFactory } from 'typescript-fsa';

import {
  BlockType,
  CommonCourseBlock,
  CourseBlockOperation,
  IComment,
  ICommentRequest,
  ICourseNodePayload,
  IPaginationResponse,
  IThread
} from '../../../../interfaces';
import {
  CourseState,
  CurriculumState,
  ICourse,
  ICourseNode,
  ICourseSettings,
  ILecture,
  IRequestCohort,
  NodeCondition
} from './CourseState';

const actionCreator = actionCreatorFactory();

export const courseActions = {
  getCourses: actionCreator.async<void, ICourse[], Error>('GET_COURSES'),
  getCourseHierarchy: actionCreator.async<{ course_id: string }, ICourseNode[], Error>('GET_COURSE_HIERARCHY'),
  createCourse: actionCreator.async<void, ICourse, Error>('CREATE_COURSE'),
  createCohort: actionCreator.async<IRequestCohort, ICourse, Error>('CREATE_COHORT'),
  toggleComments: actionCreator.async<{ lessonId: string; courseId: string; isHidden: boolean }, IComment, Error>(
    'TOGGLE_COMMENTS'
  ),
  toggleHideShowComment: actionCreator.async<{ commentId: string; isHidden: boolean }, IComment, Error>(
    'TOGGLE_SHOW_COMMENT'
  ),
  markAsModeratedComment: actionCreator.async<{ threadId: string }, IComment, Error>('MARK_AS_MODERATED'),
  postComment: actionCreator.async<Pick<ICommentRequest, 'content' & { thread_id: string }>, IComment, Error>(
    'POST_COMMENT'
  ),
  getComments: actionCreator.async<
    { params: { threadId?: string; isModerated?: boolean } & Pick<ICommentRequest, 'sort'> },
    IPaginationResponse<IComment[]>,
    Error
  >('GET_COMMENTS'),
  getThreads: actionCreator.async<
    { params: { courseId?: string; has_unmoderated_comments?: boolean } & Pick<ICommentRequest, 'sort'> },
    IPaginationResponse<IThread[]>,
    Error
  >('GET_THREADS'),
  getOrCreateThreadComments: actionCreator.async<{ lessonId: string }, IThread, Error>('GET_OR_CREATE_THREAD_COMMENTS'),
  createNode: actionCreator.async<
    { course_id: string; type: string; parent_id: string | null; title?: string; embed?: `${BlockType}` },
    ICourseNode,
    Error
  >('CREATE_NODE'),
  editNode: actionCreator.async<
    { courseId: string; nodeId: string; data: ICourseNodePayload },
    Omit<ICourseNode, 'children'>,
    Error
  >('EDIT_NODE'),
  setNodeCondition: actionCreator.async<{ courseId: string; nodeId: string; condition: NodeCondition }, void, Error>(
    'SET_NODE_CONDITION'
  ),
  editStatusNode: actionCreator.async<
    { courseId: string; nodeId: string; state: CurriculumState; modifyChildren: boolean },
    Omit<ICourseNode, 'children'>,
    Error
  >('EDIT_NODE_STATUS'),
  duplicateNode: actionCreator.async<{ courseId: string; nodeId: string }, void, Error>('DUPLICATE_NODE'),
  editCourse: actionCreator.async<{ courseId: string; title: string }, void, Error>('EDIT_COURSE'),
  deleteNode: actionCreator.async<{ courseId: string; nodeId: string }, void, Error>('DELETE_COURSE_NODE'),
  getPayloadBlock: actionCreator.async<{ courseId: string; stepId: string }, CommonCourseBlock[], Error>(
    'GET_COURSE_BLOCK'
  ),
  getLesson: actionCreator.async<{ courseId: string; lessonId: string }, ILecture[], Error>('GET_LESSON'),
  getNode: actionCreator.async<{ courseId: string; nodeId: string }, ICourseNode, Error>('GET_NODE'),
  cudPayloadBlock: actionCreator.async<
    {
      courseId: string;
      stepId: string;
      payload: Record<CourseBlockOperation, Partial<CommonCourseBlock>[]>;
    },
    void,
    Error
  >('CUD_COURSE_PAYLOAD'),
  editCourseSettings: actionCreator.async<{ courseId: string; settings: Partial<ICourseSettings> }, ICourse, Error>(
    'EDIT_COURSE_SETTINGS'
  ),
  updateCourseState: actionCreator.async<{ courseId: string; state: CourseState }, ICourse, Error>(
    'UPDATE_COURSE_STATE'
  ),
  showPopupIfParentDraft: actionCreator<boolean>('SHOW_POPUP_IF_PARENT_DRAFT'),
  updateCourseHierarchyForPreviewMode: actionCreator<ICourse>('UPDATE_COURSE_HIERARCHY_FOR_PREVIEW_MODE'),
  setStatusNodePreview: actionCreator<{ course: ICourse; lessonId: string }>('SET_STATUS_NODE_PREVIEW')
};
