import { useCallback, useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import styles from './payload-course-enroll.module.scss';

import { useNodePayload } from '../../../../../../hooks/use-node-payload';
import { PaletteAutomationElementTypes } from '../../../../../../interfaces';
import { courseActionAsync } from '../../../../../lms/courses/store/CourseActionAsync';
import { CoursesSelector } from '../../../../../lms/courses/store/CourseSelector';
import { ICourse } from '../../../../../lms/courses/store/CourseState';
import { AccountSelector } from '../../../../../settings/account/store/AccountSelector';
import { EnrollInCourse } from './EnrollInCourse';
import { PayloadCourseEnroll } from './PayloadCourseEnroll';

interface IProps {
  nodeId: string;
}

export const PayloadEventCourseEnroll = ({ nodeId }: IProps) => {
  const dispatch = useDispatch();
  const [selectedCourse, setSelectedCourse] = useState<ICourse[]>([]);
  const { setValue } = useFormContext();
  const node = useNodePayload<PaletteAutomationElementTypes.EVENT_COURSE_ENROLMENT>(nodeId);
  const accountMemo = useMemo(AccountSelector, []);
  const { organizationId } = useSelector(accountMemo);
  const authToken = useSelector((state) => state.auth.authToken);

  const courseMemo = useMemo(CoursesSelector, []);
  const { courses } = useSelector((state) => courseMemo(state, 'published'));

  const setSelectedData = useCallback(
    (data: ICourse[]) => {
      const selected = data.find((y) => y.id === node?.payload.course_id);
      setSelectedCourse(selected ? [selected] : []);
    },
    [node]
  );

  const onSetCourses = useCallback(
    (data: ICourse[]) => {
      setValue('course_id', data.length ? data[data.length - 1].id : null);
      setSelectedCourse(data.length ? [data[data.length - 1]] : []);
    },
    [setValue]
  );

  const getCourses = useCallback(() => {
    if (authToken && organizationId) {
      dispatch(courseActionAsync.getCourses(setSelectedData));
    }
  }, [authToken, dispatch, organizationId, setSelectedData]);

  useEffect(() => {
    if (courses.length === 0) {
      getCourses();
    } else {
      const data = courses.find((x) => node?.payload.course_id === x.id);
      setSelectedCourse(data ? [data] : []);
    }
  }, [courses, node?.payload.course_id, getCourses, setSelectedCourse]);

  return (
    <PayloadCourseEnroll courses={courses} onSetCourses={onSetCourses} selectedCourses={selectedCourse}>
      <div className={styles.text}>Perform this automation when student is:</div>
      <EnrollInCourse
        isEnrolled={!!node?.payload.is_enrolled}
        textEnrolled={'Enrolled in the course'}
        textUnEnrolled={'Unenrolled'}
      />
    </PayloadCourseEnroll>
  );
};
