import { ReactNode } from 'react';
import { components, MenuListProps, NoticeProps, OptionProps } from 'react-select';

import styles from './autocomplete-select.module.scss';

import { IOption } from '../../../interfaces';

export const MenuList = <T extends IOption>(props: MenuListProps<T, false>, componentNode: ReactNode) => {
  return (
    <components.MenuList {...props}>
      {props.children}
      {Array.isArray(props.children) ? (
        <div className={`${props.selectProps.classNamePrefix}__option text-primary ${styles.create}`}>
          {componentNode}
        </div>
      ) : null}
    </components.MenuList>
  );
};

export const NoOptionsMessage = <T extends IOption>(props: NoticeProps<T, false>, componentNode: ReactNode) => {
  return (
    <div className={`${props.selectProps.classNamePrefix}__no-option-message`}>
      <components.NoOptionsMessage {...props} children={props.children} />
      {componentNode}
    </div>
  );
};

export const CustomAutocompleteSelectOption = <T extends IOption>(props: OptionProps<T, false>) => {
  const { children, className, cx, isDisabled, isFocused, isSelected, innerRef, innerProps } = props;
  return (
    <div
      className={cx(
        {
          option: true,
          'option--is-disabled': isDisabled,
          'option--is-focused': isFocused,
          'option--is-selected': isSelected
        },
        className
      )}
      ref={innerRef}
      {...innerProps}
    >
      {children}
    </div>
  );
};
