import { memo } from 'react';
import { DraggableProvided } from 'react-beautiful-dnd';
import { FormControl, InputGroup } from 'react-bootstrap';
import { Controller, FieldValues, useFormContext } from 'react-hook-form';

import classnames from 'classnames';
import styleField from '../field.module.scss';
import styles from './fieldPayload.module.scss';
import { IconDraggable, IconTrash } from '../../../../components/UI/icons';

import { IOption } from '../../../../interfaces';

interface IProps {
  handleDelete: (i: number) => void;
  value?: string;
  index: number;
  classNames?: string;
  provided: DraggableProvided;
}

export const FieldSelectorDragPayload = memo(({ handleDelete, index, value, classNames, provided }: IProps) => {
  const {
    control,
    formState: { errors }
  } = useFormContext<FieldValues & { options: IOption[] }>();

  return (
    <div className={styles.optionWrapper} ref={provided.innerRef} {...provided.draggableProps}>
      <div {...provided.dragHandleProps} className={styles.dragIcon}>
        <IconDraggable />
      </div>
      <InputGroup>
        <Controller
          name={`options[${index}].options`}
          defaultValue={value || ''}
          control={control}
          rules={{
            maxLength: 128,
            minLength: 1,
            required: true
          }}
          render={({ field: { onChange, value } }) => (
            <FormControl
              onChange={onChange}
              type={'text'}
              size={'sm'}
              value={value}
              className={classnames('form-control-underline', styleField.bgContainer, styles.defaultText, classNames, {
                'is-invalid': errors.options && errors?.options[index]
              })}
            />
          )}
        />

        <InputGroup.Append onClick={() => handleDelete(index)} className={styles.append}>
          <InputGroup.Text className={styles.optionTrash}>
            <IconTrash />
          </InputGroup.Text>
        </InputGroup.Append>
      </InputGroup>
    </div>
  );
});
