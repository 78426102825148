import { TypeOfUploader } from 'src/app/interfaces/fileUpload';

import commonStyles from '../common/upload-common.module.scss';
import { Icon32Code, Icon32Link } from '../../../UI/icons';

import { SelectAddFileCard } from '../common/select-add-file-card/SelectAddFileCard';

interface IProps {
  titleLink: string;
  descriptionLink: string | JSX.Element;
  embedTitle: string;
  embedDescription: string;
  tooltip?: string;
  handleChangeTypeOfUploader: (type: TypeOfUploader) => void;
}

export const FileNotSelected = ({
  handleChangeTypeOfUploader,
  descriptionLink,
  titleLink,
  embedDescription,
  embedTitle,
  tooltip
}: IProps) => {
  return (
    <>
      <SelectAddFileCard
        icon={<Icon32Link className={commonStyles.icon} />}
        title={titleLink}
        note={<div className={commonStyles.note}>{descriptionLink}</div>}
        textButton={titleLink}
        textTooltip={tooltip}
        onClick={() => handleChangeTypeOfUploader(TypeOfUploader.link)}
      />
      <SelectAddFileCard
        icon={<Icon32Code className={commonStyles.icon} />}
        title={embedTitle}
        note={<p className={commonStyles.note}>{embedDescription}</p>}
        textButton={embedTitle}
        onClick={() => handleChangeTypeOfUploader(TypeOfUploader.embed)}
      />
    </>
  );
};
