import { memo } from 'react';
import ReactDatePicker from 'react-datepicker';
import { Controller, useFormContext } from 'react-hook-form';
import moment from 'moment';

import { useNodePayload } from '../../../../../../../hooks/use-node-payload';
import { PaletteAutomationElementTypes } from '../../../../../../../interfaces';
import { CustomInput } from './CustomInput';

interface IProps {
  nodeId: string;
}
export const UntilFixed = memo(({ nodeId }: IProps) => {
  const { control } = useFormContext();
  const node = useNodePayload<PaletteAutomationElementTypes.PROCESSOR_DELAY>(nodeId);

  return (
    <Controller
      control={control}
      name={'period.when'}
      defaultValue={
        node?.payload?.period?.when ? moment(node?.payload?.period?.when).toDate() : moment().add(5, 'days').toDate()
      }
      render={({ field: { onChange, value } }) => {
        return (
          <ReactDatePicker
            value={value}
            selected={value}
            customInput={<CustomInput />}
            calendarClassName={'date-field'}
            className={'form-control form-control-sm mt-2'}
            dateFormat="do MMM yyyy, hh:mm aa"
            popperPlacement={'bottom'}
            showTimeInput
            onChange={(date) => {
              onChange(moment(date).toDate());
            }}
          />
        );
      }}
    />
  );
});
