import actionCreatorFactory from 'typescript-fsa';

import {
  EdgeRequest,
  GeometryRequest,
  GeometryResponse,
  IAutomationNodeBuilder,
  IEdge,
  IFunnelNodeBuilder,
  NodeEndpoint,
  NodeTypes,
  PaletteAutomationElementTypes,
  PaletteElementTypes
} from '../../../../interfaces';

export const actionCreator = actionCreatorFactory();

export const builderActions = {
  addNode: actionCreator.async<
    { id: string; types: NodeTypes },
    IFunnelNodeBuilder<PaletteElementTypes> | IAutomationNodeBuilder<PaletteAutomationElementTypes>,
    Error
  >('ADD_NODE'),
  getNode: actionCreator.async<
    { id: string; nodeKey: string },
    IFunnelNodeBuilder<PaletteElementTypes> | IAutomationNodeBuilder<PaletteAutomationElementTypes>,
    Error
  >('GET_NODE'),
  saveNode: actionCreator.async<
    { id: string; nodeKey: string; data: Record<string, string | undefined> },
    IFunnelNodeBuilder<PaletteElementTypes> | IAutomationNodeBuilder<PaletteAutomationElementTypes>,
    Error
  >('SAVE_NODE'),
  deleteNode: actionCreator.async<
    { id: string; nodeKey: string },
    IFunnelNodeBuilder<PaletteElementTypes> | IAutomationNodeBuilder<PaletteAutomationElementTypes> | null,
    Error
  >('DELETE_NODE'),
  saveGeometry: actionCreator.async<{ id: string; data: GeometryRequest }, GeometryResponse, Error>('SAVE_GEOMETRY'),
  saveEdge: actionCreator.async<{ id: string; edge: EdgeRequest }, IEdge, Error>('SAVE_EDGE'),
  deleteEdge: actionCreator.async<{ id: string; edgeKey: string }, IEdge, Error>('DELETE_EDGE'),
  createEndpoint: actionCreator.async<{ funnelId: string; nodeId: string; title?: string }, NodeEndpoint, Error>(
    'CREATE_ENDPOINT'
  ),
  getEndpoints: actionCreator.async<{ funnelId: string; nodeId: string }, NodeEndpoint[], Error>('GET_ENDPOINT'),
  editEndpoint: actionCreator.async<
    { funnelId: string; nodeId: string; endpointId: string; title: string },
    NodeEndpoint,
    Error
  >('EDIT_ENDPOINT'),
  deleteEndpoint: actionCreator.async<{ funnelId: string; nodeId: string; endpointId: string }, NodeEndpoint, Error>(
    'DELETE_ENDPOINT'
  ),
  setNodes: actionCreator<{
    nodes: Array<IFunnelNodeBuilder<PaletteElementTypes> | IAutomationNodeBuilder<PaletteAutomationElementTypes>>;
  }>('SET_NODES'),
  setNode: actionCreator<{
    node: IFunnelNodeBuilder<PaletteElementTypes> | IAutomationNodeBuilder<PaletteAutomationElementTypes>;
  }>('SET_NODE'),
  editNode: actionCreator<{
    node: IFunnelNodeBuilder<PaletteElementTypes> | IAutomationNodeBuilder<PaletteAutomationElementTypes>;
  }>('EDIT_NODE')
};
