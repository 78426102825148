import { useEffect } from 'react';
import { useIntercom } from 'react-use-intercom';

export const useIntercomUpdate = (
  isPayloadShow: boolean,
  horizontalPaddingDefault = 20,
  horizontalPaddingSet = 532
) => {
  const { update } = useIntercom();

  useEffect(() => {
    update({
      horizontalPadding: isPayloadShow ? horizontalPaddingSet : horizontalPaddingDefault
    });
  }, [horizontalPaddingDefault, horizontalPaddingSet, isPayloadShow, update]);

  useEffect(() => {
    return () => {
      update({
        horizontalPadding: horizontalPaddingDefault
      });
    };
  }, [horizontalPaddingDefault, update]);
  return;
};
