import { Suspense, useEffect, useMemo, useState } from 'react';
import { useBeforeunload } from 'react-beforeunload';
import { isMobile } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';

import classNames from 'classnames';
import styles from './content.module.scss';

import { MobileRestrictDialog } from '../../components/mobile-restrict-dialog/MobileRestrictDialog';
import { Loader } from '../../components/UI';
import { setDefaultFavicon, setDefaultTitle } from '../../components/UI/image-components/image/Image';
import { Queue } from '../../helpers/queue';
import { SubscribeHeader } from '../../modules/dashboard/common/subscribe/SubscribeHeader';
import { funnelPaths } from '../../modules/funnels/routes/FunnelRoutes';
import { mainPaths } from '../../modules/MainRoutes';
import { billingActionAsync, BillingPermissionSelector } from '../../modules/settings/billing/store';
import { GeneralSettingsActionAsync } from '../../modules/settings/general/store/GeneralSettingsActionAsync';
import PermissionProvider from '../../providers/permission/PermissionProvider';
import { permissionActionAsync } from '../../providers/permission/store/PermissionActionAsync';
import { AppRouter } from '../../providers/router/AppRouter';
import { LoadState } from '../../store';
import { AppContext } from '../AppContext';
import { Header } from '../header/Header';
import { Sidebar } from '../Sidebar';
import { useActionAdminQueue } from '../useActionAdminQueue';
import { useLoadIntercom } from './lib/useLoadIntercom';
import { usePendo } from './lib/usePendo';
import { useShowLayoutParts } from './lib/useShowLayoutParts';

const queue = new Queue<(params: any) => unknown>();

export const Content = () => {
  const dispatch = useDispatch();
  const { authToken } = useSelector((state) => state.auth);
  const { account } = useSelector((state) => state.account);
  const { permissions } = useSelector((state) => state.permissions);
  const organizationId = useSelector((state) => state.auth.currentOrganization?.organization_id);
  const { showSidebar, showHeader, paddingStyle } = useShowLayoutParts();
  const commonPath = useRouteMatch([
    mainPaths.signUp,
    mainPaths.confirmation,
    mainPaths.auth,
    mainPaths.recovery,
    mainPaths.updateAccount
  ]);
  const thumbnails = useRouteMatch(funnelPaths.thumbnails);
  const [showRestrictMobile, setShowRestrictMobile] = useState(isMobile);
  const { files } = useSelector((state) => state.fileUpload);

  const subscriptionMemo = useMemo(BillingPermissionSelector, []);
  const { isBlockAccount, isLoading, loadState } = useSelector(subscriptionMemo);

  const isUpdateAccountPage = mainPaths.updateAccount === commonPath?.path;

  const ErrorEmailModal = useActionAdminQueue(queue);

  useLoadIntercom();

  const offerRouteMatch = useRouteMatch(mainPaths.offer());

  useEffect(() => {
    setDefaultFavicon();
    setDefaultTitle();
  }, []);

  useEffect(() => {
    if (organizationId) {
      dispatch(GeneralSettingsActionAsync.getOrganization());
      dispatch(billingActionAsync.getSubscription());
      if (authToken) {
        dispatch(GeneralSettingsActionAsync.getStripeAccount());
      }
    }
  }, [account.organizations.length, authToken, dispatch, organizationId]);

  useEffect(() => {
    if (organizationId) {
      dispatch(permissionActionAsync.getPermission());
    }
  }, [dispatch, organizationId]);

  usePendo();

  const onCloseMobileRestrict = () => {
    setShowRestrictMobile(false);
  };

  const blockAccountTop = { top: isBlockAccount && isLoading ? '40px' : undefined };

  useBeforeunload((event) => {
    if (files.some((file) => file.uploaderData.status === 'in_progress')) {
      event.preventDefault();
    }
  });

  const mainClassName = useMemo(
    () => classNames(['main', paddingStyle, thumbnails || !showHeader || 'hasNavbar']),
    [paddingStyle, showHeader, thumbnails]
  );

  const loading = loadState === LoadState.firstLoad && authToken && !isUpdateAccountPage;

  return (
    <PermissionProvider restrictPermissions={permissions?.restrictions || []}>
      <AppContext.Provider value={{ queue }}>
        <main className={mainClassName} style={blockAccountTop}>
          {loading && <Loader className={styles.loader} />}
          {showSidebar && (
            <Sidebar
              showBlockAccount={isBlockAccount}
              customStyles={{
                marginTop: isBlockAccount && isLoading ? '100px' : undefined
              }}
            />
          )}
          {showHeader && (
            <>
              {isBlockAccount && isLoading && <SubscribeHeader />}
              <Header customStyles={blockAccountTop} showBlockAccount={isBlockAccount} />
            </>
          )}
          <Suspense fallback={<Loader className={styles.loader} />}>
            <AppRouter />
          </Suspense>
        </main>
        {isMobile && !offerRouteMatch && (
          <MobileRestrictDialog show={showRestrictMobile} onClose={onCloseMobileRestrict} />
        )}
        {ErrorEmailModal}
      </AppContext.Provider>
    </PermissionProvider>
  );
};
