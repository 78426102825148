import { memo, useState } from 'react';
import { Dropdown } from 'react-bootstrap';

import styles from './send-test.module.scss';
import { Icon32Cross } from '../../../../../components/UI/icons';

import { Button, DropMenu } from '../../../../../components/UI';
import { StatusEmailSent } from '../EditorHeader';
import { ErrorSent } from './status-sent/ErrorSent';
import { InitialSent } from './status-sent/InitialSent';
import { SuccessSent } from './status-sent/SuccessSent';

interface IProps {
  onSendTest(): void;
  setEmail: (data: string) => void;
  onEmptyStatus: () => void;
  email: string;
  loading: boolean;
  status: StatusEmailSent | undefined;
}

export const SendTest = memo(({ onSendTest, setEmail, onEmptyStatus, email, loading, status }: IProps) => {
  const [showDialog, setShowDialog] = useState(false);

  const onCloseDialog = () => {
    setShowDialog(false);
    onEmptyStatus();
  };
  const onShowDialog = () => {
    setShowDialog(true);
  };

  return (
    <div>
      <DropMenu
        show={showDialog}
        onToggle={onShowDialog}
        node={
          <Button onClick={onShowDialog} withIcon={false} btnStyle={'transparent'}>
            Send Test
          </Button>
        }
        drop={'down'}
      >
        <Dropdown.Menu className={styles.popoverContainer}>
          <div className={styles.container}>
            {status === 'error' && <ErrorSent tryAgain={onEmptyStatus} />}
            {status === 'success' && <SuccessSent email={email} />}
            {!status && <InitialSent email={email} loading={loading} onSendTest={onSendTest} setEmail={setEmail} />}
            <Icon32Cross className={styles.cross} onClick={onCloseDialog} />
          </div>
        </Dropdown.Menu>
      </DropMenu>
    </div>
  );
});
