import { memo } from 'react';
import moment from 'moment';

import styles from './cancelPeriod.module.scss';
import { Icon32Reload, IconWarning } from '../../../../components/UI/icons';

import { Button } from '../../../../components/UI';
import { Block } from '../block/Block';

interface IProps {
  dateEnd: string;
  onRenew: () => void;
}

export const CancelPeriod = memo(({ dateEnd, onRenew }: IProps) => {
  return (
    <Block className={styles.container}>
      <div className={styles.title}>
        <div className={styles.warning}>
          <IconWarning />
        </div>
        Your subscription has been canceled
      </div>
      <div className={'d-flex justify-content-between align-items-center'}>
        <div className={styles.description}>
          You will have access to your data until {moment(dateEnd).format('MMMM DD, YYYY')}. <br /> To avoid data loss,
          you can renew your subscription.
        </div>
        <Button
          onClick={onRenew}
          withIcon={true}
          iconPosition={'left'}
          customIcon={<Icon32Reload className={'mr-2'} />}
          className={styles.btn}
        >
          Renew subscription
        </Button>
      </div>
    </Block>
  );
});
