import actionCreatorFactory from 'typescript-fsa';

import { IComment, ICommentRequest, IPaginationResponse } from '../../../../../interfaces';

export const actionCreator = actionCreatorFactory();
export const StudentCommentActions = {
  getComments: actionCreator.async<
    Pick<ICommentRequest, 'courseId' | 'nodeId' | 'sort'>,
    IPaginationResponse<IComment[]>,
    Error
  >('GET_STUDENT_COMMENTS'),
  createComment: actionCreator.async<Pick<ICommentRequest, 'courseId' | 'nodeId' | 'content'>, IComment, Error>(
    'CREATE_STUDENT_COMMENTS'
  ),
  removeComments: actionCreator('REMOVE_COMMENTS')
};
