import { CustomDispatch } from 'redux';

import {
  getComments,
  getOrCreateThreadComments,
  getThreads,
  markAsModeratedComment,
  postComment,
  toggleCommentShow,
  toggleHideShowComment
} from '../../../../API/comments';
import {
  courseBlockAdd,
  courseBlockGet,
  createCohort,
  createCourse,
  createNode,
  deleteNode,
  duplicateNode,
  editCourse,
  editCourseSettings,
  editNode,
  editStateNode,
  getCourseHierarchy,
  getCourses,
  getNode,
  setNodeCondition,
  updateCourseStatus
} from '../../../../API/courses-api';
import {
  BlockType,
  CommonCourseBlock,
  CourseBlockOperation,
  CourseNodeType,
  IComment,
  ICommentRequest,
  ICourseNodePayload,
  IPaginationParams,
  IPaginationResponse,
  IThread
} from '../../../../interfaces';
import { courseActions } from './CourseActions';
import {
  CourseState,
  CourseTemplatesType,
  CurriculumState,
  ICourse,
  ICourseNode,
  ICourseSettings,
  ILecture,
  IRequestCohort,
  NodeCondition
} from './CourseState';

export const courseActionAsync = {
  getCourses: (callback?: (data: ICourse[]) => void) => async (dispatch: CustomDispatch) => {
    try {
      dispatch(courseActions.getCourses.started());
      const response = await getCourses();

      if (response.data) {
        dispatch(courseActions.getCourses.done({ result: response.data }));
        if (typeof callback === 'function') {
          callback(response.data);
        }
      }
    } catch (error) {
      dispatch(courseActions.getCourses.failed({ error }));
    }
  },
  getCourseHierarchy:
    (course_id: string, callback?: (hierarchy: ICourseNode[]) => void) => async (dispatch: CustomDispatch) => {
      try {
        dispatch(courseActions.getCourseHierarchy.started({ course_id }));
        const response = await getCourseHierarchy(course_id);

        if (response.data) {
          dispatch(courseActions.getCourseHierarchy.done({ result: response.data, params: { course_id } }));
          if (callback) {
            callback(response.data);
          }
          return response.data;
        }
      } catch (error) {
        dispatch(courseActions.getCourseHierarchy.failed({ error, params: { course_id } }));
      }
    },

  createCourse:
    (template: CourseTemplatesType, callbackCreated: (id: string) => void) => async (dispatch: CustomDispatch) => {
      try {
        dispatch(courseActions.createCourse.started());
        const response = await createCourse(template);
        if (response.data) {
          dispatch(courseActions.createCourse.done({ result: response.data }));
          callbackCreated(response.data.id);
        }
      } catch (error) {
        dispatch(courseActions.createCourse.failed({ error }));
      }
    },
  createCohort:
    (data: IRequestCohort, callbackCreated: (id: string) => void, onError?: () => void) =>
    async (dispatch: CustomDispatch) => {
      try {
        dispatch(courseActions.createCohort.started(data));
        const response = await createCohort(data);
        if (response.data) {
          dispatch(courseActions.createCohort.done({ result: response.data, params: data }));
          callbackCreated(response.data.id);
        }
      } catch (error) {
        dispatch(courseActions.createCohort.failed({ error, params: data }));
        onError?.();
      }
    },
  createNode:
    (
      course_id: string,
      type: CourseNodeType,
      parent_id: string | null = null,
      title?: string,
      embed?: `${BlockType}`,
      callback?: (courseNode: ICourseNode) => void
    ) =>
    async (dispatch: CustomDispatch) => {
      try {
        dispatch(courseActions.createNode.started({ course_id, type, parent_id, title, embed }));
        const response = await createNode(course_id, type, parent_id, title, embed);
        if (response.data) {
          dispatch(
            courseActions.createNode.done({
              result: response.data,
              params: { course_id, type, parent_id, title, embed }
            })
          );
          if (typeof callback === 'function') {
            callback(response.data);
          }
          return response.data;
        }
      } catch (error) {
        dispatch(courseActions.createNode.failed({ error, params: { course_id, type, parent_id, title, embed } }));
      }
    },
  editNode:
    (courseId: string, nodeId: string, data: ICourseNodePayload, callbackEdit?: () => void) =>
    async (dispatch: CustomDispatch) => {
      try {
        dispatch(courseActions.editNode.started({ courseId, nodeId, data }));
        const response = await editNode(courseId, nodeId, data);
        if (response.data) {
          dispatch(courseActions.editNode.done({ result: response.data, params: { courseId, nodeId, data } }));
          if (callbackEdit) {
            callbackEdit();
          }
        }
      } catch (error) {
        dispatch(courseActions.editNode.failed({ error, params: { courseId, nodeId, data } }));
      }
    },
  editNodeStatus:
    (courseId: string, nodeId: string, state: CurriculumState, modifyChildren: boolean, callbackEdit?: () => void) =>
    async (dispatch: CustomDispatch) => {
      try {
        dispatch(courseActions.editStatusNode.started({ courseId, nodeId, state, modifyChildren }));
        const response = await editStateNode(courseId, nodeId, state, modifyChildren);
        if (response.data) {
          dispatch(
            courseActions.editStatusNode.done({
              result: response.data,
              params: { courseId, nodeId, state, modifyChildren }
            })
          );
          callbackEdit?.();
        }
      } catch (error) {
        dispatch(courseActions.editStatusNode.failed({ error, params: { courseId, nodeId, state, modifyChildren } }));
      }
    },
  duplicateNode:
    (courseId: string, nodeId: string, type: string, callback: (type: string) => void) =>
    async (dispatch: CustomDispatch) => {
      try {
        dispatch(courseActions.duplicateNode.started({ courseId, nodeId }));
        await duplicateNode(courseId, nodeId);
        dispatch(courseActions.duplicateNode.done({ params: { courseId, nodeId } }));
        callback(type);
      } catch (error) {
        dispatch(courseActions.duplicateNode.failed({ error, params: { courseId, nodeId } }));
      }
    },
  getNode:
    (courseId: string, nodeId: string, callback?: (data: ICourseNode) => void) => async (dispatch: CustomDispatch) => {
      try {
        dispatch(courseActions.getNode.started({ courseId, nodeId: nodeId }));
        const response = await getNode(courseId, nodeId);
        if (response.data) {
          dispatch(courseActions.getNode.done({ result: response.data, params: { courseId, nodeId: nodeId } }));
          if (typeof callback === 'function') {
            callback(response.data);
          }
        }
      } catch (error) {
        dispatch(courseActions.getNode.failed({ error, params: { courseId, nodeId: nodeId } }));
      }
    },

  deleteNode: (courseId: string, nodeId: string, callback?: () => void) => async (dispatch: CustomDispatch) => {
    try {
      dispatch(courseActions.deleteNode.started({ courseId, nodeId }));
      await deleteNode(courseId, nodeId);
      dispatch(courseActions.deleteNode.done({ params: { courseId, nodeId } }));
      if (callback) {
        callback();
      }
    } catch (error) {
      dispatch(courseActions.deleteNode.failed({ params: { courseId, nodeId }, error }));
    }
  },

  editCourse: (courseId: string, title: string) => async (dispatch: CustomDispatch) => {
    try {
      dispatch(courseActions.editCourse.started({ courseId, title }));
      const response = await editCourse(courseId, title);
      if (response.data) {
        dispatch(courseActions.editCourse.done({ params: { courseId, title } }));
      }
    } catch (error) {
      dispatch(courseActions.editCourse.failed({ params: { courseId, title }, error }));
    }
  },

  getCourseBlock:
    (courseId: string, stepId: string, callback?: (data: CommonCourseBlock[]) => void) =>
    async (dispatch: CustomDispatch) => {
      try {
        dispatch(courseActions.getPayloadBlock.started({ courseId, stepId }));
        const response = await courseBlockGet(courseId, stepId);
        if (response.data) {
          dispatch(
            courseActions.getPayloadBlock.done({
              result: response.data as CommonCourseBlock[],
              params: { courseId, stepId }
            })
          );
          callback?.(response.data as CommonCourseBlock[]);
        }
      } catch (error) {
        dispatch(courseActions.getPayloadBlock.failed({ error, params: { courseId, stepId } }));
      }
    },
  toggleComments:
    (courseId: string, lessonId: string, isHidden: boolean, callback: () => void) =>
    async (dispatch: CustomDispatch) => {
      try {
        dispatch(courseActions.toggleComments.started({ lessonId, courseId, isHidden }));
        const response = await toggleCommentShow(courseId, lessonId, isHidden);
        if (response.data) {
          dispatch(
            courseActions.toggleComments.done({ params: { isHidden, courseId, lessonId }, result: response.data })
          );
          callback();
        }
      } catch (error) {
        dispatch(courseActions.toggleComments.failed({ params: { isHidden, courseId, lessonId }, error }));
      }
    },
  toggleHideShowComment: (commentId: string, isHidden: boolean) => async (dispatch: CustomDispatch) => {
    try {
      dispatch(courseActions.toggleHideShowComment.started({ commentId, isHidden }));
      const response = await toggleHideShowComment(commentId, isHidden);
      if (response.data) {
        dispatch(courseActions.toggleHideShowComment.done({ params: { isHidden, commentId }, result: response.data }));
      }
    } catch (error) {
      dispatch(courseActions.toggleHideShowComment.failed({ params: { isHidden, commentId }, error }));
    }
  },
  markAsModeratedComment: (threadId: string) => async (dispatch: CustomDispatch) => {
    try {
      dispatch(courseActions.markAsModeratedComment.started({ threadId }));
      const response = await markAsModeratedComment(threadId);
      if (response.data) {
        dispatch(courseActions.markAsModeratedComment.done({ params: { threadId }, result: response.data }));
      }
    } catch (error) {
      dispatch(courseActions.markAsModeratedComment.failed({ params: { threadId }, error }));
    }
  },
  getOrCreateThreadComments:
    (lessonId: string, callback: (threadId: string) => void) => async (dispatch: CustomDispatch) => {
      try {
        dispatch(courseActions.getOrCreateThreadComments.started({ lessonId }));
        const response = await getOrCreateThreadComments(lessonId);
        if (response.data) {
          dispatch(courseActions.getOrCreateThreadComments.done({ params: { lessonId }, result: response.data }));
          callback(response.data.id);
        }
      } catch (error) {
        dispatch(courseActions.getOrCreateThreadComments.failed({ params: { lessonId }, error }));
      }
    },
  getComments:
    (
      params: { threadId?: string; isModerated?: boolean } & Pick<ICommentRequest, 'sort'>,
      callback: (data: IPaginationResponse<IComment[]>) => void,
      pagination: IPaginationParams
    ) =>
    async (dispatch: CustomDispatch) => {
      try {
        dispatch(courseActions.getComments.started({ params }));
        const response = await getComments(params, pagination);
        if (response && response.data && response.meta) {
          dispatch(
            courseActions.getComments.done({
              result: { data: response.data, meta: response.meta },
              params: { params }
            })
          );
          callback({ data: response.data, meta: response.meta });
        }
      } catch (error: any) {
        dispatch(courseActions.getComments.failed({ error, params: { params } }));
      }
    },
  getThreads:
    (
      params: { courseId?: string; has_unmoderated_comments?: boolean } & Pick<ICommentRequest, 'sort'>,
      callback: (data: IPaginationResponse<IThread[]>) => void,
      pagination = { page: 1, page_size: 3 }
    ) =>
    async (dispatch: CustomDispatch) => {
      try {
        dispatch(courseActions.getThreads.started({ params }));
        const response = await getThreads(params, pagination);
        if (response && response.data && response.meta) {
          dispatch(
            courseActions.getThreads.done({
              result: { data: response.data, meta: response.meta },
              params: { params }
            })
          );
          callback({ data: response.data, meta: response.meta });
        }
      } catch (error: any) {
        dispatch(courseActions.getThreads.failed({ error, params: { params } }));
      }
    },
  postComments:
    (params: Pick<ICommentRequest, 'content'> & { threadId: string }, callback: (data: IComment) => void) =>
    async (dispatch: CustomDispatch) => {
      try {
        dispatch(courseActions.postComment.started(params));
        const response = await postComment(params.content, params.threadId);
        if (response && response.data) {
          dispatch(
            courseActions.postComment.done({
              result: response.data,
              params: params
            })
          );
          callback(response.data);
        }
      } catch (error: any) {
        dispatch(courseActions.postComment.failed({ error, params: params }));
      }
    },
  getLesson:
    (courseId: string, lessonId: string, callback?: (lesson: ILecture[]) => void) =>
    async (dispatch: CustomDispatch) => {
      try {
        dispatch(courseActions.getLesson.started({ courseId, lessonId }));
        const response = await courseBlockGet(courseId, lessonId);
        if (response.data) {
          dispatch(
            courseActions.getLesson.done({ result: response.data as ILecture[], params: { courseId, lessonId } })
          );
          if (typeof callback === 'function') {
            callback(response.data as ILecture[]);
          }
        }
      } catch (error) {
        dispatch(courseActions.getLesson.failed({ error, params: { courseId, lessonId } }));
      }
    },

  // getStudentCabinetCourseBlock: (courseId: string, stepId: string) => async (dispatch: CustomDispatch) => {
  //   try {
  //     dispatch(courseActions.getPayloadBlock.started({ courseId, stepId }));
  //     const response = await getStudentCabinetCourseBlock(courseId, stepId);
  //     if (response.data) {
  //       dispatch(courseActions.getPayloadBlock.done({ result: response.data, params: { courseId, stepId } }));
  //     }
  //   } catch (error) {
  //     dispatch(courseActions.getPayloadBlock.failed({ error, params: { courseId, stepId } }));
  //   }
  // },

  cudCourseBlock:
    (
      courseId: string,
      stepId: string,
      payload: Record<CourseBlockOperation, Partial<CommonCourseBlock>[]>,
      callback?: () => void
    ) =>
    async (dispatch: CustomDispatch) => {
      try {
        dispatch(courseActions.cudPayloadBlock.started({ courseId, stepId, payload }));
        await courseBlockAdd(courseId, stepId, payload);
        dispatch(courseActions.cudPayloadBlock.done({ params: { courseId, stepId, payload } }));
        if (callback) {
          callback();
        }
      } catch (error) {
        dispatch(courseActions.cudPayloadBlock.failed({ error, params: { courseId, stepId, payload } }));
      }
    },

  editCourseSettings:
    (courseId: string, settings: Partial<ICourseSettings>, callback?: (response?: ICourse) => void) =>
    async (dispatch: CustomDispatch) => {
      try {
        dispatch(courseActions.editCourseSettings.started({ courseId, settings }));
        const response = await editCourseSettings(courseId, settings);
        if (response.data) {
          dispatch(
            courseActions.editCourseSettings.done({
              result: response.data,
              params: { courseId, settings }
            })
          );
          if (callback) {
            callback(response.data);
          }
        }
      } catch (error) {
        dispatch(courseActions.editCourseSettings.failed({ params: { courseId, settings }, error }));
      }
    },
  updateCourseState: (courseId: string, state: CourseState) => async (dispatch: CustomDispatch) => {
    try {
      dispatch(courseActions.updateCourseState.started({ courseId, state }));
      const response = await updateCourseStatus(courseId, state);

      if (response.data) {
        dispatch(courseActions.updateCourseState.done({ result: response.data, params: { courseId, state } }));
      }
    } catch (error) {
      dispatch(courseActions.updateCourseState.failed({ error, params: { courseId, state } }));
    }
  },
  setNodeCondition:
    (courseId: string, nodeId: string, condition: NodeCondition, callback?: () => void) =>
    async (dispatch: CustomDispatch) => {
      try {
        dispatch(courseActions.setNodeCondition.started({ courseId, nodeId, condition }));
        const response = await setNodeCondition(courseId, nodeId, condition);
        if (response.data) {
          dispatch(courseActions.setNodeCondition.done({ params: { courseId, nodeId, condition } }));
          callback?.();
        }
      } catch (error) {
        dispatch(courseActions.setNodeCondition.failed({ params: { courseId, nodeId, condition }, error }));
      }
    }
};
