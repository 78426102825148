import { FC, memo, useRef } from 'react';
import { DropTargetMonitor, useDrag, useDrop, XYCoord } from 'react-dnd';

import classNames from 'classnames';
import styles from './draggableElement.module.scss';

export interface IDragItem {
  index: number;
  id: string;
  type: string;
}

interface IProps {
  index: number;
  moveField: (dragIndex: number, hoverIndex: number) => void;
  onSetDrag?: (x: boolean) => void;
  id: string;
  ItemTypes: string;
  canDrag?: boolean;
  containerStyles?: string;
}

export const DraggableElement: FC<IProps> = memo(
  ({ id, index, moveField, ItemTypes, containerStyles, onSetDrag, children, canDrag = true }) => {
    const ref = useRef<HTMLDivElement>(null);
    const [{ isDragging }, drag] = useDrag({
      item: { type: ItemTypes, id, index },
      canDrag: () => canDrag,
      collect: (monitor: any) => ({
        isDragging: monitor.isDragging()
      }),
      type: ItemTypes
    });
    const [{ handlerId }, drop] = useDrop({
      accept: ItemTypes,
      collect(monitor) {
        return {
          handlerId: monitor.getHandlerId()
        };
      },
      hover(item: IDragItem, monitor: DropTargetMonitor) {
        if (!ref.current) {
          return;
        }

        const dragIndex = item.index;
        const hoverIndex = index;

        // Don't replace items with themselves
        if (dragIndex === hoverIndex) {
          return;
        }

        // Determine rectangle on screen
        const hoverBoundingRect = ref.current?.getBoundingClientRect();

        // Get vertical middle
        const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

        // Determine mouse position
        const clientOffset = monitor.getClientOffset();

        // Get pixels to the top
        const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

        // Only perform the move when the mouse has crossed half of the items height
        // When dragging downwards, only move when the cursor is below 50%
        // When dragging upwards, only move when the cursor is above 50%

        // Dragging downwards
        if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
          return;
        }

        // Dragging upwards
        if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
          return;
        }

        // Time to actually perform the action
        moveField(dragIndex, hoverIndex);
        // if (movedCallback) {
        //   movedCallback(item);
        // }
        // Note: we're mutating the monitor item here!
        // Generally it's better to avoid mutations,
        // but it's good here for the sake of performance
        // to avoid expensive index searches.
        item.index = hoverIndex;
      }
      // drop(item: IDragItem, monitor: DropTargetMonitor) {
      //   if (movedCallback) {
      //     movedCallback(item);
      //   }
      // }
    });

    const onDragStart = () => {
      onSetDrag && onSetDrag(true);
    };

    const onDragEnd = () => {
      onSetDrag && onSetDrag(false);
    };

    const opacity = isDragging ? 0 : 1;
    drag(drop(ref));

    return (
      <div
        ref={ref}
        style={{ opacity }}
        className={classNames(styles.container, containerStyles)}
        data-handler-id={handlerId}
        onDragStart={onDragStart}
        onDragEnd={onDragEnd}
        id={id}
      >
        {children}
      </div>
    );
  }
);
