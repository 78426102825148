import { memo } from 'react';
import { AudioPlayerProvider as AudioPlayerProviderWrapper } from 'react-use-audio-player';

import { AudioPlayer } from './AudioPlayer';

export interface IAudioPlayerProps {
  audioSrc: string;
  removeRecord?: () => void;
  withDelete?: boolean;
  className?: string;
  hideVolume?: boolean;
  hideSpeedRate?: boolean;
  hideStartTime?: boolean;
  hideEndTime?: boolean;
}

export const AudioPlayerProvider = memo(
  ({
    audioSrc,
    className,
    hideEndTime,
    hideSpeedRate,
    hideStartTime,
    hideVolume,
    withDelete,
    removeRecord
  }: IAudioPlayerProps) => {
    return (
      <AudioPlayerProviderWrapper>
        <AudioPlayer
          audioSrc={audioSrc}
          hideEndTime={hideEndTime}
          hideSpeedRate={hideSpeedRate}
          hideStartTime={hideStartTime}
          hideVolume={hideVolume}
          withDelete={withDelete}
          removeRecord={removeRecord}
          className={className}
        />
      </AudioPlayerProviderWrapper>
    );
  }
);
