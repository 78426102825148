import React from 'react';

import classNames from 'classnames';
import styles from './custom-toggle.module.scss';

type CustomToggleProps = {
  children?: React.ReactNode;
  onClick?: (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void;
  className?: string;
};

export const CustomToggle = React.forwardRef((props: CustomToggleProps, ref: React.Ref<HTMLSpanElement>) => (
  <span
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      props.onClick && props.onClick(e);
    }}
    className={classNames(styles.toggleElement, props.className)}
  >
    {props.children}
  </span>
));
