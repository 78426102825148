import { memo } from 'react';

import styles from './quizPreview.module.scss';
import quizPreview from '../../../../../../../assets/images/quiz-preview.png';

import { Button } from '../../../../../../components/UI';

interface IProps {
  setRunQuiz: (x: boolean) => void;
}

export const QuizPreview = memo(({ setRunQuiz }: IProps) => {
  return (
    <div className={'d-flex align-items-center flex-column pb-7 mx-3'}>
      <div>
        <img src={quizPreview} alt={'quiz preview'} className={'my-7'} />
        <div className={'d-flex align-items-center flex-column'}>
          <div className={styles.text}>It's time to take a quiz</div>
          <Button
            onClick={() => setRunQuiz(true)}
            size={'lg'}
            withIcon={true}
            type={'arrow-right'}
            iconPosition={'left'}
            className={styles.btn}
          >
            Run quiz
          </Button>
        </div>
      </div>
    </div>
  );
});
