export enum Ranges {
  'range',
  'amount'
}

export interface IRangeIntervals {
  id: string;
  start: number;
  end: number;
}

export type IRangeProcessorAutomationPayload = {
  range: {
    type: keyof typeof Ranges;
    end?: number;
    intervals?: IRangeIntervals[];
  };
};
