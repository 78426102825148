import { useMemo, useState } from 'react';
import {
  ColumnDef,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  PaginationState,
  useReactTable
} from '@tanstack/react-table';

import styles from './table.module.scss';

import { useQueryParams } from '../../../hooks/use-query-params';
import { ColumnClassFunction, commonColumnClass } from './common/helper';
import { TableBody } from './table-body/TableBody';
import { TableHeader } from './table-header/TableHeader';
import { TablePagination } from './table-pagination/TablePagination';

interface IProps<T, V> {
  data: T[];
  columns: ColumnDef<T, V>[];
  pageCount?: number;
  tableStyles?: ColumnClassFunction;
}

export const Table = <T extends unknown, V extends unknown>({
  data,
  columns,
  pageCount,
  tableStyles = commonColumnClass
}: IProps<T, V>) => {
  const params = useQueryParams();

  const getClassName = useMemo(() => tableStyles(), [tableStyles]);

  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: params.page - 1,
    pageSize: params.page_size - 1
  });
  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize
    }),
    [pageIndex, pageSize]
  );

  const table = useReactTable({
    data,
    columns,
    pageCount: pageCount ?? -1,
    state: {
      pagination
    },
    manualPagination: true,
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel()
  });

  return (
    <div className={styles.container}>
      <table className={'table table-scrolled'}>
        <TableHeader table={table} getClassName={getClassName} />
        <TableBody table={table} getClassName={getClassName} />
      </table>
      <TablePagination table={table} />
    </div>
  );
};
