import { memo, ReactNode } from 'react';

import styles from './reorder-selected-products.module.scss';
import { IconDraggable } from '../UI/icons';

import { getUUID } from '../../helpers';
import { NewDraggableElement } from '../draggable/NewDraggableElement';
import NewReorderComponent from '../draggable/NewReorderComponent';

interface IProps<T> {
  onSetElements: (data: T[]) => void;
  elements: T[];
  name?: string;
  children: (data: T) => ReactNode;
  isDragging?: boolean;
}

export const ReorderSelectedProducts = <T extends object & { id: string }>({
  elements,
  onSetElements,
  name,
  children,
  isDragging
}: IProps<T>) => {
  return (
    <NewReorderComponent elements={elements} setElements={onSetElements} name={name}>
      {elements.map((element, index) => (
        <NewDraggableElement
          id={element.id}
          index={index}
          key={getUUID()}
          handleElement={<IconDraggable />}
          draggableContainerClassName={styles.container}
          handleClassName={styles.handleIcon}
          isDraggingHandleClassName={styles.draggingHandleIcon}
          isDraggingClassName={styles.dragging}
          isDragging={isDragging}
        >
          {children(element)}
        </NewDraggableElement>
      ))}
    </NewReorderComponent>
  );
};

export default memo(ReorderSelectedProducts) as typeof ReorderSelectedProducts;
