import classNames from 'classnames';
import styles from './complete-steps.module.scss';

import everactNS from '../../../../../../../documentation/everact-nameservers.json';
import { DomainCname } from './domain-cname/DomainCname';
import { FollowingNameServers } from './ns-servers/FollowingNameServers';

export interface IDomainCompleteStep {
  title: string;
  link?: {
    url: string | null;
    hyperlink_name: string;
  };
  external_ns?: boolean;
  ['everact-nameservers']?: boolean;
  cname?: {
    type: string;
    'Name/Host': string;
    'Target/Value': string;
    TTL: string;
  };
  trouble?: string[];
}

interface IProps {
  isDomain: boolean;
  className?: string;
}

export const CompleteSteps = ({ isDomain, className }: IProps) => {
  return isDomain ? (
    <div className={classNames(styles.text, className)}>
      <div className={styles.listTitle}>Complete these four steps:</div>
      <ol className={styles.list}>
        <li>Log in to your domain provider</li>
        <li>Go to the DNS settings for domain provider</li>
        <li>Remove the default nameservers</li>
        <li> Now add these everact.io nameservers:</li>
      </ol>
      <FollowingNameServers nsServers={everactNS} />
    </div>
  ) : (
    <div className={classNames(styles.text, className)}>
      <div className={styles.listTitle}>Complete these last three steps:</div>
      <ol className={styles.list}>
        <li>Log in to your domain provider</li>
        <li>Go to the DNS settings for domain provider to add a new CNAME record</li>
        <li>Add the following CNAME to your DNS Records, starting from the “Type” column:</li>
      </ol>
      <DomainCname
        cname={{
          type: 'CNAME',
          'Name/Host': 'your-school-subdomain',
          'Target/Value': 'domain.myeveract.io',
          TTL: 'Auto'
        }}
        trouble={[
          'Make sure the CNAME is entered correctly before you save.',
          'If you have existing records, do not delete or replace them instead create a new record where you will input all of the entries in step 3.',
          'These changes usually process within 24 hours.',
          'You may need to refresh the page to populate the new records.',
          'If required, set the Time to live (TTL) to the shortest amount offered.',
          'Visit the domain registrar\'s help center for more support.'
        ]}
      />
    </div>
  );
};
