import React, { memo, useMemo, useState } from 'react';
import { Dropdown, FormCheck } from 'react-bootstrap';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';

import classNames from 'classnames';
import styles from './legal-settings.module.scss';
import { IconKebabMenu, IconLink, IconNewWindow, IconUnlock } from '../../../../../components/UI/icons';

import { KebabToggle } from '../../../../../components/UI/kebabToggle/KebabToggle';
import { PolicyContentType, PolicyContentTypeEnum, PolicyDefinedLinkEnum } from '../../../../../interfaces/policy';
import { usePolicyContext } from '../../policy-item/PolicyContext';

interface IFormInputs {
  show_in_cabinet: boolean;
}

export const LegalSettings = memo(({ type }: { type?: PolicyContentType }) => {
  const { watch, setValue } = useFormContext<IFormInputs>();
  const { policy } = usePolicyContext();
  const { domains } = useSelector((state) => state.domains);
  const [isOpened, setIsOpened] = useState(false);

  const url = useMemo(() => {
    const domain = domains.find((domain) => domain.is_primary);
    if (domain && policy?.id) {
      let id = policy.id;
      if (policy.document_type === 'privacy_policy') {
        id = PolicyDefinedLinkEnum.privacy;
      }
      if (policy.document_type === 'terms_of_service') {
        id = PolicyDefinedLinkEnum.terms;
      }
      return `https://${domain.subdomain ? domain.url : domain.domain}/policies/${id}`;
    }
    return null;
  }, [domains, policy?.document_type, policy?.id]);

  const openPage = () => {
    if (url) {
      window?.open(url, '_blank')?.focus();
    }
  };

  const onToggle = (isOpen: boolean) => {
    setIsOpened(isOpen);
  };

  return (
    <Dropdown className={styles.menu} onToggle={onToggle}>
      <Dropdown.Toggle as={KebabToggle} id="dropdown-basic">
        <IconKebabMenu />
      </Dropdown.Toggle>
      <Dropdown.Menu align="right" className={classNames('sm selector', styles.dropdown)} renderOnMount={false}>
        {type === PolicyContentTypeEnum.local && url && (
          <>
            <div className={classNames(styles.dropdownItem, styles.link)} onClick={openPage}>
              <IconNewWindow className={'flex-shrink-0 text-primary'} />
              <div className={styles.dropdownName}>Open page</div>
            </div>
            <CopyToClipboard text={url}>
              <div className={classNames(styles.dropdownItem, styles.link)}>
                <IconLink className={'flex-shrink-0 text-primary'} />
                <div className={styles.dropdownInfo}>
                  <div className={styles.dropdownName}>Copy link to the page</div>
                  <div className={classNames(styles.dropdownDescription, styles.url)}>{url}</div>
                </div>
              </div>
            </CopyToClipboard>
          </>
        )}
        {isOpened && (
          <div className={styles.dropdownItem}>
            <IconUnlock className={'flex-shrink-0 text-primary'} />
            <div>
              <div className={styles.dropdownName}>Show link in the student cabinet</div>
              <div className={styles.dropdownDescription}>
                When this option is enabled, the link to the page is displayed in the footer
              </div>
            </div>
            <FormCheck className={'custom-switch-sm align-self-start'} custom type={'switch'} id={'show_in_cabinet'}>
              <FormCheck.Input
                type="checkbox"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setValue('show_in_cabinet', e.target.checked, { shouldDirty: true });
                }}
                checked={watch('show_in_cabinet')}
              />
              <FormCheck.Label className={styles.label} />
            </FormCheck>
          </div>
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
});
