import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import styles from './sign-up.module.scss';

import { imageUrl } from '../../../../components/UI/image-components/image/Image';
import { LoadState } from '../../../../store';
import { RegistrationForm } from '../../../auth/sign-up/RegistraionForm';
import { StudentCabinetSelector } from '../../store/StudentCabinetSelector';
import { CommonAuth } from '../common-auth/CommonAuth';

export const SignUp = () => {
  const cabinetSettingsMemo = useMemo(StudentCabinetSelector, []);
  const { cabinetSettings, loadCabinetSettingsState } = useSelector((state) => cabinetSettingsMemo(state));

  const themeSignUpBgImage = imageUrl({
    bucket_name: cabinetSettings?.theme_sign_up?.bucket_name,
    object_name: cabinetSettings?.theme_sign_up?.object_name
  });

  const loaded = loadCabinetSettingsState === LoadState.allIsLoaded || loadCabinetSettingsState === LoadState.error;

  return (
    <CommonAuth themeImage={themeSignUpBgImage} loaded={loaded}>
      <div
        className={styles.container}
      >
        <RegistrationForm />
      </div>
    </CommonAuth>
  );
};
