import classNames from 'classnames';
import styles from './image-upload.module.scss';

import { Icon16Trash } from '../../icons';
import { Image } from '../image/Image';
import { ImagePlaceholder } from '../image-placeholder/ImagePlaceholder';

interface IProps {
  imagePreviewUrl: string | null;
  setImagePreviewUrl: (e: string | null) => void;
  customStyles?: string;
  className?: string;
  imageSize?: { width: number; height: number };
  disabled?: boolean;

  onDeleteImage(): void;
}

export const ImagePreview = ({
  imagePreviewUrl,
  onDeleteImage,
  imageSize = { width: 96, height: 96 },
  className,
  disabled,
  customStyles
}: IProps) => {
  return (
    <div className={classNames(styles.imagePreview, className)}>
      {imagePreviewUrl ? (
        <>
          <Image size={imageSize} className={classNames(styles.image, customStyles)} url={imagePreviewUrl} />
          {!disabled && (
            <button className={classNames('btn btn-icon-shadow btn-delete', styles.delete)} onClick={onDeleteImage}>
              <Icon16Trash />
            </button>
          )}
        </>
      ) : (
        <ImagePlaceholder className={customStyles} />
      )}
    </div>
  );
};
