import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import classNames from 'classnames';
import styles from './list-header.module.scss';
import { Icon16NewWindow, IconSpinner, IconUpload } from '../../../../../components/UI/icons';

import { Button } from '../../../../../components/UI';
import { downloadCSV } from '../../../../../helpers';
import { PageTopPanel } from '../../../../../layout/shared-components/page-top-panel/PageTopPanel';
import { SearchWrapper } from '../../../../../layout/shared-components/SearchWrapper';
import { CourseShareableLink } from '../../../courses/common/course-shareable-link/CourseShareableLink';
import { coursePaths } from '../../../courses/routes/CourseRoutes';
import { courseActionAsync } from '../../../courses/store/CourseActionAsync';
import { CourseSelector } from '../../../courses/store/CourseSelector';
import { IStudentList } from '../../store/StudentListState';
import { StudentsActionAsync } from '../../store/StudentsActionAsync';

interface IProps {
  list: Partial<IStudentList>;
  totalCount: number;
}

export const ListHeader = ({ list, totalCount }: IProps) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [showShareable, setShowShareable] = useState(false);
  const courseMemo = useMemo(() => CourseSelector(list.payload?.course_id), [list.payload?.course_id]);
  const { course } = useSelector(courseMemo);
  const { students } = useSelector((state) => state.students);

  useEffect(() => {
    if (!course) {
      dispatch(courseActionAsync.getCourses());
    }
  }, [course, dispatch]);

  const exportStudents = () => {
    if (list.id) {
      setLoading(true);
      dispatch(StudentsActionAsync.exportStudentsFromList(list.id, exportStudentsCallback));
    }
  };

  const exportStudentsCallback = (data: string) => {
    downloadCSV(data, 'students');
    setLoading(false);
  };

  const title = (
    <div className={styles.title}>
      {list.title}
      <Link to={coursePaths.courseLessons(list.payload?.course_id)}>
        Open course
        <Icon16NewWindow />
      </Link>
    </div>
  );

  const openShareableLink = () => {
    setShowShareable(true);
  };

  const closeShareableLink = () => {
    setShowShareable(false);
  };

  return (
    <>
      <PageTopPanel title={title} className={'align-items-center'}>
        <div className={styles.count}>{totalCount}</div>
        <Button onClick={openShareableLink} btnStyle={'transparent'} type={'link'} className={'mr-2'}>
          Get the link
        </Button>
        <SearchWrapper placeholder={'Search by id, student name, email...'} isPage className={'mr-2'} />
        <Button
          onClick={exportStudents}
          customIcon={loading ? <IconSpinner className={'spinner'} /> : <IconUpload />}
          btnStyle={'secondary'}
          className={classNames('mr-2', styles.export, { [styles.exporting]: loading })}
          btnProps={{ disabled: loading || students.length === 0 }}
        >
          {loading ? 'Exporting...' : 'Export'}
        </Button>
      </PageTopPanel>
      {course && <CourseShareableLink course={course} show={showShareable} onClose={closeShareableLink} />}
    </>
  );
};
