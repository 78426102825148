import { memo } from 'react';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import { useFieldArray, useFormContext } from 'react-hook-form';

import classnames from 'classnames';
import styles from './fieldPayload.module.scss';
import { Icon32Plus } from '../../../../components/UI/icons';

import { CheckButton } from '../../../../components/UI';
import { FieldSelectorDragPayload } from './FieldSelectorDragPayload';

interface IProps {
  containerStyles?: string;
  btnStyles?: string;
  classNames?: string;
}

export const FieldSelectPayload = memo(({ containerStyles, btnStyles, classNames }: IProps) => {
  const { control } = useFormContext();
  const { fields, append, remove, move } = useFieldArray({
    control,
    name: 'options'
  });

  const handleAdd = () => {
    if (fields.length < 1000) {
      append({ options: `Option ${fields.length + 1}` });
    }
  };

  const handleDelete = (index: number) => {
    if (fields.length !== 2) {
      remove(index);
    }
  };

  const handleDrag = (result: DropResult) => {
    if (result.destination) {
      move(result.source.index, result.destination.index);
    }
  };

  return (
    <div className={classnames(styles.selectContainer, containerStyles)}>
      <div>
        <div className={'d-flex'}>
          <CheckButton name={'display_options'} value={'Dropdown'} params={{ className: 'radio-button my-1' }} />
          <CheckButton
            name={'display_options'}
            value={'Display options'}
            params={{ className: 'radio-button my-1 ml-3' }}
          />
        </div>
        <DragDropContext onDragEnd={handleDrag}>
          <Droppable droppableId="option-items">
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {fields.map((item: any, index) => (
                  <Draggable key={item.id} draggableId={item.id!} index={index}>
                    {(provided) => (
                      <FieldSelectorDragPayload
                        provided={provided}
                        handleDelete={handleDelete}
                        index={index}
                        classNames={classNames}
                        value={item.options}
                      />
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
      <button onClick={handleAdd} className={classnames(styles.addOption, 'btn btn-icon-right', btnStyles)}>
        Add option
        <Icon32Plus className={styles.icon} />
      </button>
    </div>
  );
});
