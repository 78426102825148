import Frame from 'react-frame-component';
import InnerHTML from 'dangerously-set-html-content';
import { AudioPlayerProvider } from 'src/app/components/audio-player/AudioPlayerProvider';
import { AudioField, TypeOfUploader } from 'src/app/interfaces/fileUpload';

import styles from './audio.module.scss';

interface IProps {
  audio?: AudioField;
}

export const Audio = ({ audio }: IProps) => {
  const audioSrc = audio?.audio_url || audio?.url;
  return (
    <div className={styles.audio}>
      {(audio?.type === TypeOfUploader.upload || audio?.type === TypeOfUploader.link) && audioSrc && (
        <AudioPlayerProvider audioSrc={audioSrc} />
      )}
      {audio?.code && audio?.type === TypeOfUploader.embed && (
        <div className={styles.audioWrapper}>
          <Frame
            scrolling={'no'}
            className={styles.iframe}
            initialContent={
              // eslint-disable-next-line quotes
              "<!DOCTYPE html><html lang='en'><head><style>iframe {width: 100%;height: 100%;min-height: 432px;}</style><title></title></head><body style='margin: 0'><div></div></body></html>"
            }
          >
            <InnerHTML html={audio.code} />
          </Frame>
        </div>
      )}
    </div>
  );
};
