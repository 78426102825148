import { memo } from 'react';

import classNames from 'classnames';
import styles from './audio-player-time.module.scss';

const formatTime = (seconds: number) => {
  const floored = Math.floor(seconds);
  let from = 14;
  let length = 5;
  // Display hours only if necessary.
  if (floored >= 3600) {
    from = 11;
    length = 8;
  }

  return new Date(floored * 1000).toISOString().substr(from, length);
};

interface IProps {
  timeSecond: number;
  className?: string;
}

export const AudioPlayerTime = memo(({ timeSecond, className }: IProps) => {
  const elapsed = typeof timeSecond === 'number' && isFinite(timeSecond) ? timeSecond : 0;
  return <div className={classNames(styles.time, className)}>{formatTime(elapsed)}</div>;
});
