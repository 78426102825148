import { useDispatch } from 'react-redux';
import { map } from 'lodash';

import styles from './enrollment-resend.module.scss';
import SendMessage from '../../../../../../../assets/images/send-message.png';

import { IStudentCourse } from '../../../store/StudentListState';
import { StudentsActionAsync } from '../../../store/StudentsActionAsync';

interface IProps {
  studentId: string;
  email: string;
  courseList: IStudentCourse[];
  setSuccessSend: (x: boolean) => void;
}

export const EnrollmentResendNotConfirmed = ({ email, studentId, courseList, setSuccessSend }: IProps) => {
  const dispatch = useDispatch();

  const onResend = () => {
    dispatch(
      StudentsActionAsync.enrollResendStudent(
        studentId,
        {
          course_ids: map(courseList, 'course_id'),
          email,
          send_email: true
        },
        () => setSuccessSend(true)
      )
    );
  };

  return (
    <>
      <div>
        <div className={styles.title}>
          If the student has not confirmed enrollment in a course after some time, you can{' '}
          <span className={styles.link} onClick={onResend}>
            resend it again
          </span>
        </div>
        <div className={styles.description}>The student will receive an email at</div>
        <div className={styles.email}>{email}</div>
      </div>
      <img src={SendMessage} alt={'resend'} className={styles.img} />
    </>
  );
};
