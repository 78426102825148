import { IAutomationNodeBuilder, IEdge, NodeGeometry, PaletteAutomationElementTypes } from '../../../interfaces';
import { LoadState } from '../../../store';

export type AutomationStatusResponse = 'error.workflow.loop_detected';

export type AutomationType = 'automation';

export enum AutomationState {
  published = 1,
  draft = 2,
  deleted = 3,
  archived = 4
}

export enum AutomationRequestState {
  published = 'publish',
  draft = 'draft',
  archived = 'archive'
}

export type AutomationRequestStatus = keyof typeof AutomationRequestState;
export type AutomationStatus = keyof typeof AutomationState;

export interface IAutomation {
  created_at: string;
  updated_at: string;
  id: string;
  name: string;
  state: number;
  type: AutomationType;
  edges?: IEdge[];
  geometry?: NodeGeometry[];
  nodes?: IAutomationNodeBuilder<PaletteAutomationElementTypes>[];
}

export interface IAutomationState {
  error: Error | null;
  loadState: LoadState;
  automations: IAutomation[];
}

export const AutomationInitialState: IAutomationState = {
  error: null,
  loadState: LoadState.firstLoad,
  automations: []
};
