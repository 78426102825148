import styles from './connect-modal-header.module.scss';
import { ReactComponent as Logotype } from '../../../../../../assets/svg/everact_logo_sign.svg';
import { PaynetSVG } from '../../../../../components/UI/icons';

export const ConnectModalHeader = () => {
  return (
    <div className={styles.header}>
      <PaynetSVG className={styles.paynet} />
      <div className={styles.divider} />
      <Logotype width={48} height={48} />
    </div>
  );
};
