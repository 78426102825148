import { ReactNode } from 'react';

import styles from './status-connection.module.scss';

interface IProps {
  children: ReactNode;
}

export const StatusConnection = ({ children }: IProps) => {
  return <div className={styles.container}>{children}</div>;
};
