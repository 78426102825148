import { memo } from 'react';
import { FormControl } from 'react-bootstrap';
import { Controller, useFormContext } from 'react-hook-form';

import commonOfferStyles from '../../common/commonOffer.module.scss';
import styles from './actionRedirect.module.scss';
import { IconLink } from '../../../../../../components/UI/icons';

import { IRedirectCheckout } from '../../../store/OffersState';
import { RadioButtonElement } from '../../common/radio-button/RadioButtonElement';
import { SimpleToggle } from '../../common/toggle/SimpleToggle';

interface IProps {
  isToggle: boolean;

  onChangeValue(show: boolean): void;
}

export const ActionRedirect = memo(({ isToggle, onChangeValue }: IProps) => {
  const {
    control,

    formState: { errors }
  } = useFormContext<{ action_redirect_after_checkout: IRedirectCheckout }>();

  return (
    <SimpleToggle
      classNameContainer={styles.wrapper}
      isToggle={isToggle}
      label={'Redirect to a custom page after checkout'}
      onChangeValue={onChangeValue}
    >
      <div className={styles.container}>
        <Controller
          control={control}
          name={'action_redirect_after_checkout.url'}
          render={({ field: { onChange, value } }) => (
            <FormControl
              placeholder={'Enter your redirecting link'}
              name={'action_redirect_after_checkout.url'}
              className={'form-control form-control-sm mt-3 pl-5'}
              onChange={onChange}
              value={value === null ? '' : value}
            />
          )}
        />
        <IconLink className={styles.icon} />
      </div>
      {errors.action_redirect_after_checkout?.url && (
        <div className={commonOfferStyles.errorTitle}>{errors.action_redirect_after_checkout.url.message}</div>
      )}
      <RadioButtonElement
        name={'action_redirect_after_checkout.in_same_tab'}
        label={'Open in the same tab'}
        value={'true'}
        className={'mt-2'}
      />
      <RadioButtonElement
        name={'action_redirect_after_checkout.in_same_tab'}
        label={'Open in a new tab'}
        value={'false'}
      />
      {errors.action_redirect_after_checkout?.in_same_tab && (
        <div className={commonOfferStyles.errorTitle}>{errors.action_redirect_after_checkout.in_same_tab.message}</div>
      )}
    </SimpleToggle>
  );
});
