import { Form, FormControl } from 'react-bootstrap';
import { FieldValues, useFormContext } from 'react-hook-form';

import classnames from 'classnames';
import styles from './fixedPeriod.module.scss';

import { IDelayProcessorAutomationPayload } from '../../../../../../../interfaces';

interface IProps {
  label: string;
  name: string;
  data?: number;
  isError?: boolean;
}

export const DatePeriodItem = ({ label, name, data, isError }: IProps) => {
  const { register, setFocus } = useFormContext<FieldValues & IDelayProcessorAutomationPayload>();
  const onFocus = () => {
    setFocus(name);
  };

  return (
    <Form.Group className={classnames(styles.container, isError && 'form-control p-0 invalid')}>
      <Form.Label className={classnames(styles.label, 'm-0')} onClick={onFocus}>
        {label}
      </Form.Label>
      <FormControl
        className={styles.input}
        placeholder={'00'}
        defaultValue={data?.toString()}
        type={'number'}
        {...register(name)}
      />
    </Form.Group>
  );
};
