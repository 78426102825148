import { FormControl, FormControlProps } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import { RegisterOptions } from 'react-hook-form/dist/types/validator';

import classNames from 'classnames';
import styles from './input-text.module.scss';

interface IProps {
  placeholder: string;
  name: string;
  params?: FormControlProps;
  maxLength?: number;
  className?: string;
  showError?: boolean;
  rules?: RegisterOptions;
}

export const InputText = ({
  name,
  placeholder,
  maxLength = 255,
  params,
  rules,
  className,
  showError = false
}: IProps) => {
  const {
    register,
    formState: { errors }
  } = useFormContext();

  return (
    <>
      <FormControl
        maxLength={maxLength}
        size={'sm'}
        className={classNames('form-control-underline', className)}
        placeholder={placeholder}
        type={'text'}
        isInvalid={!!errors[name]}
        {...register(name, rules)}
        {...params}
      />
      {showError && !!errors[name] && <div className={styles.error}>{errors[name]?.message}</div>}
    </>
  );
};
