import { FC } from 'react';

import styles from './available-lesson.module.scss';

export const AvailableLesson: FC = ({ children }) => {
  return (
    <div className={styles.container}>
      <div className={styles.textContainer}>{children}</div>
    </div>
  );
};
