import { round } from 'lodash';

interface IRGBColor {
  r: number;
  g: number;
  b: number;
  a?: number;
}

export const RGBToHSL = ({ r, g, b }: IRGBColor) => {
  r /= 255;
  g /= 255;
  b /= 255;
  const l = Math.max(r, g, b);
  const s = l - Math.min(r, g, b);
  const h = s ? (l === r ? (g - b) / s : l === g ? 2 + (b - r) / s : 4 + (r - g) / s) : 0;
  return [
    60 * h < 0 ? 60 * h + 360 : 60 * h,
    100 * (s ? (l <= 0.5 ? s / (2 * l - s) : s / (2 - (2 * l - s))) : 0),
    (100 * (2 * l - s)) / 2
  ];
};

export const HSLToRGB = (h: number, s: number, l: number) => {
  s /= 100;
  l /= 100;
  const k = (n: number) => (n + h / 30) % 12;
  const a = s * Math.min(l, 1 - l);
  const f = (n: number) => l - a * Math.max(-1, Math.min(k(n) - 3, Math.min(9 - k(n), 1)));
  return { r: round(255 * f(0)), g: round(255 * f(8)), b: round(255 * f(4)) };
};

export const HSLToHEX = (h: number, s: number, l: number) => {
  l /= 100;
  const a = (s * Math.min(l, 1 - l)) / 100;
  const f = (n: number) => {
    const k = (n + h / 30) % 12;
    const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
    return Math.round(255 * color)
      .toString(16)
      .padStart(2, '0'); // convert to Hex and prefix "0" if needed
  };
  return `#${f(0)}${f(8)}${f(4)}`;
};

// export const RGBToHSB = (r: number, g: number, b: number) => {
//   r /= 255;
//   g /= 255;
//   b /= 255;
//   const v = Math.max(r, g, b),
//     n = v - Math.min(r, g, b);
//   const h = n === 0 ? 0 : n && v === r ? (g - b) / n : v === g ? 2 + (b - r) / n : 4 + (r - g) / n;
//   return [60 * (h < 0 ? h + 6 : h), v && (n / v) * 100, v * 100];
// };

// export const HSBToRGB = (h: number, s: number, b: number): IRGBColor => {
//   s /= 100;
//   b /= 100;
//   const k = (n: number) => (n + h / 60) % 6;
//   const f = (n: number) => b * (1 - s * Math.max(0, Math.min(k(n), 4 - k(n), 1)));
//   // return [255 * f(5), 255 * f(3), 255 * f(1)];
//   return {
//     r: 255 * f(5),
//     g: 255 * f(3),
//     b: 255 * f(1)
//   };
// };

export const generatePalette = (h: number, s: number, l: number) => {
  // console.log('%c⇒ l', 'color: #89DDF7', l);
  const normalLightness = l > 40 && l < 60 ? l : 50;
  const gradient1 = HSLToHEX(h, s, normalLightness);
  const gradient2 = HSLToHEX(h, s, 72);

  return {
    '--primary-100': `hsl(${h}deg, ${s}%, 96%)`,
    '--primary-200': `hsl(${h}deg, ${s}%, 87%)`,
    '--primary-300': `hsl(${h}deg, ${s}%, 80%)`,
    '--primary-400': `hsl(${h}deg, ${s}%, 72%)`,
    '--primary-500': `hsl(${h}deg, ${s}%, ${normalLightness}%)`,
    '--primary-600': `hsl(${h}deg, ${s}%, 41%)`,
    '--primary-700': `hsl(${h}deg, ${s}%, 29%)`,
    '--primary-800': `hsl(${h}deg, ${s}%, 18%)`,
    '--primary-900': `hsl(${h}deg, ${s}%, 6%)`,
    '--gradient-main': `linear-gradient(270deg, ${gradient2} 0%, ${gradient1} 100%)`,
    '--button-background': gradient2,
    '--button-text-color': colorYIQ(HSLToRGB(h, s, normalLightness), `hsl(${h}deg, ${s}%, 18%)`, '#fff')
  };
};

export const addBgColor = (rgba: string) => {
  return {
    '--bg-color': `${rgba}`
  };
};

export const colorYIQ = (color: IRGBColor, dark: string, light: string) => {
  const yiqContrastedThreshold = 150;
  const { r, g, b } = color;

  const yiq = (r * 299 + g * 587 + b * 114) / 1000;

  return yiq >= yiqContrastedThreshold ? dark : light;
};

// export const RGBtoCSS = (color: IRGBColor) => {
//   return `rgb(${color.r}, ${color.g}, ${color.b})`;
// };

// const luminance = ({ r, g, b }: IRGBColor) => {
//   const a = [r, g, b].map(function (v) {
//     v /= 255;
//     return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4);
//   });
//   return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
// };

// const contrast = (rgb1: IRGBColor, rgb2: IRGBColor) => {
//   const lum1 = luminance(rgb1);
//   const lum2 = luminance(rgb2);
//   const brightest = Math.max(lum1, lum2);
//   const darkest = Math.min(lum1, lum2);
//   return (brightest + 0.05) / (darkest + 0.05);
// };

// export const generatePaletteByHSB = (color: IRGBColor) => {
//   const [h, s, b] = RGBToHSB(color.r, color.g, color.b);
//   const exportPalette = {
//     '--primary-100': RGBtoCSS(HSBToRGB(h, s - 74, b)),
//     '--primary-200': RGBtoCSS(HSBToRGB(h, s - 55, b)),
//     '--primary-300': RGBtoCSS(HSBToRGB(h, s - 42, b)),
//     '--primary-400': RGBtoCSS(HSBToRGB(h, s - 25, b)),
//     '--primary-500': RGBtoCSS(HSBToRGB(h, s, b)),
//     '--primary-600': RGBtoCSS(HSBToRGB(h, s, b - 30)),
//     '--primary-700': RGBtoCSS(HSBToRGB(h, s, b - 51)),
//     '--primary-800': RGBtoCSS(HSBToRGB(h, s, b - 70)),
//     '--primary-900': RGBtoCSS(HSBToRGB(h, s, b - 90)),
//     '--gradient-main': `linear-gradient(270deg, ${RGBtoCSS(HSBToRGB(h, s - 25, b))} 0%, ${RGBtoCSS(
//       HSBToRGB(h, s, b)
//     )} 100%)`
//   };
//   return exportPalette;
// };

export const rgbToObj = (rgb: string) => {
  let colors = ['r', 'g', 'b', 'a'];
  let colorArr = rgb.replace(/[^\d,]/g, '').split(',');
  let rgbObj: any = {};
  colorArr.forEach((k, i) => {
    rgbObj[colors[i]] = k;
  });
  return rgbObj;
};

export const applyTheme = (theme: Record<string, string>) => {
  for (let item in theme) {
    document.documentElement.style.setProperty(item, theme[item]);
  }
};
