import { IFile } from 'src/app/interfaces/general';
import { AttachmentType } from 'src/app/interfaces/quiz';

export const fileUrl = (data: Pick<IFile, 'bucket_name' | 'object_name'>) => {
  return `${process.env.REACT_APP_AUDIO_FILE}/${data.bucket_name}/${data.object_name}`;
};

export const urlToBucket = (url: string) => {
  const bucket_name = url.substring(url.indexOf('work/') + 5, url.lastIndexOf('/'));
  const object_name = url.substring(url.lastIndexOf('/') + 1);
  return {
    object_name,
    bucket_name
  };
};

export type AttachmentTypeByFileType = 'video' | 'image' | 'audio' | 'docs';

export const getAttachmentTypeByFileType = (fileType: string): AttachmentTypeByFileType => {
  if (fileType.includes('video')) return 'video';
  else if (fileType.includes('image')) return 'image';
  else if (fileType.includes('audio')) return 'audio';
  return 'docs';
};

const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
const k = 1024;
export const formatBytes = (bytes: number, decimals = 2) => {
  if (!+bytes)
    return {
      value: 0,
      text: sizes[0]
    };

  const dm = decimals < 0 ? 0 : decimals;

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return { value: parseFloat((bytes / Math.pow(k, i)).toFixed(dm)), text: sizes[i] };
};
