import { useDispatch, useSelector } from 'react-redux';
import { VideoUploader } from 'src/app/components/file-upload/video-uploader/VideoUploader';

import { getVideoOrganizationUri, MediaProvider } from '../../../../../../../../API/video-api';
import { TypeOfUploader, VideoField } from '../../../../../../../../interfaces/fileUpload';
import { AttachmentType, IAttachment } from '../../../../../../../../interfaces/quiz';
import { fileUploaderActions } from '../../../../../../../../layout/shared-components/upload-worker/store/FileUploaderActions';
import { useCourseContext } from '../../../../../common/sidebar/CourseContext';

type Props = {
  quizQuestionAttachment?: string;
  video?: VideoField;
  onSetAttachment?: (data: Partial<IAttachment> | null, attachmentType: AttachmentType | 'video' | null) => void;
};

export const AttachmentVideo = ({ quizQuestionAttachment, video, onSetAttachment }: Props) => {
  const dispatch = useDispatch();
  const organization = useSelector((state) => state.auth.currentOrganization);

  const { courseId } = useCourseContext();

  const onSetVideo = (videoField: VideoField) => {
    onSetAttachment?.(videoField, 'video');
  };

  const onRemoveVideo = () => {
    onSetAttachment?.(null, 'video');
  };

  const setVideoUploadsData = async (fileSelected: File | null) => {
    if (courseId && organization && fileSelected) {
      const vimeoData = await getVideoOrganizationUri(fileSelected, organization.organization_id);
      if (vimeoData && quizQuestionAttachment) {
        onSetAttachment?.({ type: TypeOfUploader.upload, video_url: vimeoData.link }, 'video');

        dispatch(
          fileUploaderActions.setUploadFiles([
            {
              courseId: courseId,
              file: fileSelected,
              vimeoData,
              progress: 0,
              parentBlockId: quizQuestionAttachment,
              uploaderData: {
                id: vimeoData.link,
                provider: MediaProvider.VIMEO,
                status: 'in_progress'
              }
            }
          ])
        );
      }
    }
  };

  return (
    <VideoUploader
      onCancelOptional={onRemoveVideo}
      onErrorOptional={onRemoveVideo}
      onRemoveVideoOptional={onRemoveVideo}
      onSetVideoOptional={onSetVideo}
      video={video}
      setVideoUploadsDataOptional={setVideoUploadsData}
    />
  );
};
