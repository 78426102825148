import { memo, UIEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { find, flatMapDeep } from 'lodash';

import classNames from 'classnames';
import styles from './viewCourse.module.scss';

import { flatten } from '../../../../components/course/course/CourseHelpers';
import { haveNotPassed } from '../../../../components/course/course-navigation/common/tree/helper';
import { CommonModal } from '../../../../components/UI';
import { useMediaQuery } from '../../../../hooks/use-media-query';
import { useMouseDelta } from '../../../../hooks/use-mouse-delta';
import { IFile } from '../../../../interfaces';
import { LoadState } from '../../../../store';
import { settingsActions } from '../../../../store/settings';
import { TopBar } from '../../../student-cabinet/common/TopBar';
import { NavigationActions } from '../../../student-cabinet/store/NavigationActions';
import { CourseSidebar } from '../../courses/common/sidebar/CourseSidebar';
import { CourseNodeTreeSelector } from '../../courses/store/CourseSelector';
import { ICourse, ICourseNode } from '../../courses/store/CourseState';
import { useLessonNode } from '../../hooks/use-lesson-node';
import { StudentCommentActionAsync } from '../student-comment/store/StudentCommentActionAsync';
import { StudentCommentActions } from '../student-comment/store/StudentCommentActions';
import { DripStudentLesson } from './drip-student-lesson/DripStudentLesson';
import { LessonAvailableComplete } from './lesson-available-complete/LessonAvailableComplete';
import { LessonStudentWrapper } from './modules/LessonStudentWrapper';
import { StudentLessonSidebar } from './StudentLessonSidebar';
import { FlatLesson, ViewCourseContext } from './ViewCourseContext';

interface IProps {
  course: ICourse;
  nodeId?: string;
  flatLessons: FlatLesson;
  currentHierarchy: ICourseNode[];
  general_image?: IFile | null;
  theme_logo?: IFile | null;
  general_title?: string;
  viewType?: 'preview' | 'dashboard' | 'student';
  lastCompletionLesson?: ICourseNode;
  onRedirectLessonHandler(nodeId?: string | null): void;
  setCurrentHierarchy(hierarchy: ICourseNode[]): void;
  getHierarchy(): void;
}

export const ViewCourse = memo(
  ({
    course,
    nodeId,
    flatLessons,
    currentHierarchy,
    general_title,
    general_image,
    theme_logo,
    lastCompletionLesson,
    setCurrentHierarchy,
    onRedirectLessonHandler,
    getHierarchy,
    viewType
  }: IProps) => {
    const dispatch = useDispatch();
    const isPageWide = useMediaQuery('(max-width: 991px)');
    const [showSidebar, setShowSidebar] = useState(false);
    const [currentNode, setCurrentNode] = useState<ICourseNode | null>(null);
    const [currentNav, setCurrentNav] = useState<ICourseNode | null>(null);
    const { nodeLesson } = useLessonNode(course, nodeId);
    const { sidebarWidth } = useSelector((state) => state.settings);
    const nodeTreeMemo = useMemo(() => CourseNodeTreeSelector(course.children, nodeId, ['folder']), [course, nodeId]);
    const lessonTreeMemo = useMemo(() => CourseNodeTreeSelector(course.children, nodeId, ['lesson']), [course, nodeId]);
    const { treeNodes } = useSelector(nodeTreeMemo);
    const lessonNode = useSelector(lessonTreeMemo);
    const { storedCourses } = useSelector((state) => state.navigation);

    const haveNotPass = useMemo(
      () => nodeLesson && haveNotPassed(nodeLesson, course.settings.progress_sequential_flow, flatLessons.all),
      [course.settings.progress_sequential_flow, flatLessons.all, nodeLesson]
    );

    const toPreviousFolder = useCallback(() => {
      if (course) {
        const previous = find<ICourseNode | undefined>(flatMapDeep(course.children, flatten), [
          'id',
          currentNav?.parent_id
        ]);

        if (currentNav?.parent_id === null) {
          setCurrentNav(null);
          setCurrentHierarchy(course.children);
        } else {
          if (previous) {
            setCurrentNav(previous);
            setCurrentHierarchy(previous.children);
          }
        }
      }
    }, [course, currentNav?.parent_id, setCurrentHierarchy]);
    const targetRef = useRef<HTMLDivElement>(null);

    const resizeCallback = useCallback(() => {
      dispatch(settingsActions.setSidebarWidth({ sidebarWidth: width }));
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    const width = useMouseDelta(sidebarWidth, targetRef.current, resizeCallback);

    const onSelect = useCallback(
      (id: string, redirectNode?: ICourseNode, isBack?: boolean) => {
        const node = find<ICourseNode | undefined>(flatMapDeep(course.children, flatten), ['id', id]);

        if (redirectNode) {
          setCurrentNav(null);
          setCurrentHierarchy(course.children);
          onRedirectLessonHandler();
        }
        if (node?.type === 'lesson' && !redirectNode) {
          if (node?.children?.length > 0) {
            if (isBack) {
              setCurrentNav(null);
              setCurrentHierarchy(course.children);
            }
            onRedirectLessonHandler(node.children[0].id);
          }
        }
        if (node?.type === 'folder' && !redirectNode) {
          setCurrentNav(node);
          setCurrentHierarchy(node?.children || []);
        }
        if (!node && course && !redirectNode) {
          setCurrentNav(null);
          setCurrentHierarchy(course.children);
        }
      },
      [course, onRedirectLessonHandler, setCurrentHierarchy]
    );
    const onCloseSidebar = useCallback(() => {
      setShowSidebar(false);
    }, []);

    const onShowSidebar = useCallback(() => {
      setShowSidebar(true);
    }, []);

    const { comments, loadState: loadStateComments } = useSelector((state) => state.studentComments);

    const getComments = useCallback(
      (page = 1, pageSize = 25) => {
        if (lessonNode && lessonNode.treeNodes && lessonNode.treeNodes.length > 0 && course) {
          dispatch(
            StudentCommentActionAsync.getComments(
              {
                courseId: course.id,
                nodeId: lessonNode.treeNodes[0].id,
                sort: ['-created_at']
              },
              {
                page: page,
                page_size: pageSize
              }
            )
          );
        }
      },
      [course, dispatch, lessonNode]
    );

    useEffect(() => {
      dispatch(StudentCommentActions.removeComments());
      getComments();
    }, [dispatch, getComments]);

    const handleScroll = (e: UIEvent<HTMLDivElement>) => {
      const scrollHeight = e.currentTarget.scrollHeight;
      const scrollTop = e.currentTarget.scrollTop + 1;
      const clientHeight = e.currentTarget.clientHeight;

      const bottom = scrollHeight - scrollTop - 180 < clientHeight;
      const moreItems = comments.meta.page * comments.meta.page_size < comments.meta.total;
      const nextPage = comments.meta.page + 1;

      if (moreItems && bottom && loadStateComments === LoadState.allIsLoaded) {
        getComments(nextPage);
      }
    };

    useEffect(() => {
      if (flatLessons.all.length) {
        const passedSteps = flatLessons?.allSteps.filter((x) => x.is_passed);
        const lastPassedStep = passedSteps[passedSteps.length - 1];
        const lastPassedLesson = flatLessons?.all.find((x) => x.id === lastPassedStep?.parent_id);
        const currentStep = flatLessons.allSteps.find((x) => x.id === nodeId);
        const currentLesson = flatLessons.all.find((x) => x.id === currentStep?.parent_id);

        const firstLesson = nodeId ? currentLesson : lastPassedLesson || flatLessons?.all[0];

        const folder = firstLesson?.parent_id
          ? find<ICourseNode | undefined>(flatMapDeep(course?.children, flatten), ['id', firstLesson.parent_id])
          : null;

        if (storedCourses && storedCourses[course.id]?.lastNode && viewType !== 'preview') {
          onRedirectLessonHandler(storedCourses[course.id]?.lastNode);
        } else {
          if (folder && !nodeId) {
            onRedirectLessonHandler(lastCompletionLesson?.children[0].id || firstLesson?.children[0]?.id);
          } else if (folder && nodeId) {
            if (currentLesson?.id) {
              onSelect(currentLesson.id);
            }
            setCurrentNav(folder);
            // setCurrentHierarchy(folder?.children || []);
          } else {
            onRedirectLessonHandler(nodeId || lastCompletionLesson?.children[0].id || firstLesson?.children[0]?.id);
          }
        }
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [course?.children, flatLessons?.all.length]);

    useEffect(() => {
      if (course && nodeId) {
        dispatch(NavigationActions.setLastNode({ courseId: course.id, lastNode: nodeId }));
      }
    }, [course, dispatch, nodeId]);

    const delayedParent = useMemo(
      () =>
        treeNodes.find(
          (folder) => folder.condition?.type === 'delayed' && moment().diff(moment(folder.condition.open_at)) < 0
        ),
      [treeNodes]
    );

    const dripIsOff = useMemo(
      () => nodeLesson?.condition && moment().diff(moment(nodeLesson?.condition.open_at)) < 0,
      [nodeLesson?.condition]
    );

    return (
      <ViewCourseContext.Provider
        value={{
          currentHierarchy,
          currentNav,
          currentNode,
          getHierarchy,
          setCurrentNode,
          toPreviousFolder,
          onSelect,
          nodeId,
          closeSidebar: onCloseSidebar,
          course,
          flatLessons,
          onRedirectLessonHandler
        }}
      >
        {course && (
          <div
            className={classNames(styles.container)}
            id={'course-container'}
            style={{ gridTemplateColumns: `${width}px auto` }}
          >
            {isPageWide ? (
              <CommonModal
                show={showSidebar}
                onClose={onCloseSidebar}
                params={{
                  dialogClassName: styles.modalDialog,
                  contentClassName: styles.modalContent,
                  backdrop: true
                }}
              >
                <CourseSidebar>
                  <StudentLessonSidebar
                    general_image={general_image}
                    general_title={general_title}
                    theme_logo={theme_logo}
                  />
                </CourseSidebar>
              </CommonModal>
            ) : (
              <CourseSidebar width={width} resizable ref={targetRef}>
                <StudentLessonSidebar
                  general_image={general_image}
                  general_title={general_title}
                  theme_logo={theme_logo}
                />
              </CourseSidebar>
            )}

            <>
              <div className={classNames('content course', 'student')} onScroll={handleScroll}>
                {isPageWide &&
                  ReactDOM.createPortal(
                    <TopBar openMenu={onShowSidebar} />,
                    document.querySelector('#root') as Element
                  )}
                {dripIsOff || delayedParent ? (
                  <DripStudentLesson
                    viewType={viewType}
                    date={nodeLesson?.condition?.open_at || delayedParent?.condition?.open_at}
                    title={nodeLesson?.title}
                  />
                ) : !!haveNotPass ? (
                  <LessonAvailableComplete
                    hasPassedId={haveNotPass.children[0].id}
                    hasPassedTitle={haveNotPass.title}
                  />
                ) : (
                  <LessonStudentWrapper nodeLesson={nodeLesson} viewType={viewType} />
                )}
              </div>
            </>
          </div>
        )}
      </ViewCourseContext.Provider>
    );
  }
);
