import classNames from 'classnames';
import styles from './answerInfo.module.scss';
import { Icon32CheckMark, Icon32Cross, IconClock } from '../../../../../../../components/UI/icons';

import { useMediaQuery } from '../../../../../../../hooks/use-media-query';

interface IProps {
  type: 'wrong' | 'success' | 'waiting';
}

export const AnswerInfo = ({ type }: IProps) => {
  const isMobile = useMediaQuery('(max-width: 767px)');
  const title = type === 'success' ? (isMobile ? 'Correct' : 'Correct answer') : isMobile ? 'Wrong' : 'Wrong answer';

  const classes = {
    [styles.success]: type === 'success',
    [styles.wrong]: type === 'wrong',
    [styles.waiting]: type === 'waiting'
  };

  return (
    <div className={classNames(styles.container, classes)}>
      {type === 'success' && <Icon32CheckMark className={styles.iconSuccess} />}
      {type === 'wrong' && <Icon32Cross className={styles.iconWrong} />}
      {type === 'waiting' && <IconClock width={32} height={32} />}
      <div className={classNames(styles.text, classes)}>{type === 'waiting' ? 'Waiting for moderation' : title}</div>
    </div>
  );
};
