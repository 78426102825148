import { memo } from 'react';
import { Link } from 'react-router-dom';

import classNames from 'classnames';
import styles from './analytics.module.scss';
import { Icon32ArrowRight, IconPlus } from '../../../../components/UI/icons';

interface IProps {
  link: string;
  title: string;
  firstColumn: {
    title: string;
    count: number;
    action: () => void;
  };
  secondColumn: {
    title: string;
    count: number;
    action: () => void;
  };
  className?: string;
}

export const Analytics = memo(({ firstColumn, link, secondColumn, title, className }: IProps) => {
  return (
    <div className={classNames(styles.container, className)}>
      <Link to={link} className={styles.title}>
        {title}
        <Icon32ArrowRight />
      </Link>
      <div className={classNames('d-flex p-4', styles.wrapper)}>
        <div className={'w-100'}>
          <div onClick={firstColumn.action} className={styles.create}>
            {firstColumn.title} <IconPlus />
          </div>
          <div className={styles.count}>{firstColumn.count}</div>
        </div>
        <div className={'w-100'}>
          <div onClick={secondColumn.action} className={styles.create}>
            {secondColumn.title} <IconPlus />
          </div>
          <div className={styles.count}>{secondColumn.count}</div>
        </div>
      </div>
    </div>
  );
});
