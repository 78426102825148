import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Controller, useFormContext } from 'react-hook-form';

import styles from './payload-traffic-source.module.scss';

import { Button } from '../../../../../../components/UI';

interface IProps {
  nodeId: string;
}

type FormValues = {
  medium: string;
  source: string;
  campaign: string;
  content: string;
  term: string;
  link?: string;
  host?: string;
};

export const PayloadTrafficSource = ({ nodeId }: IProps) => {
  const { register, watch, setValue } = useFormContext<FormValues>();
  const [copied, setCopied] = useState(false);
  const [url, setUrl] = useState('');
  const [host, setHost] = useState<string>();
  // const nodes = useSelector<DefaultRootState, NodeBuilder<FormValues>[]>((state) => state.builder.builder.nodes);
  const data: Partial<FormValues> = watch();

  // useEffect(() => {
  //   const node: NodeBuilder<FormValues> | undefined = nodes.find((x) => x.id === nodeId);
  //   if (node) {
  //     setValue('medium', node.payload.medium);
  //     setValue('source', node.payload.source);
  //     setValue('campaign', node.payload.campaign);
  //     setValue('content', node.payload.content);
  //     setValue('term', node.payload.term);
  //     setHost(node.payload.host);
  //   }
  // }, [nodeId, nodes, setValue]);

  const onCopy = (text: string, result: boolean) => {
    setCopied(result);
  };

  useEffect(() => {
    const links = [];
    data.source && links.push(`utm_source=${encodeURI(data.source)}`);
    data.medium && links.push(`utm_medium=${encodeURI(data.medium)}`);
    data.campaign && links.push(`utm_campaign=${encodeURI(data.campaign)}`);
    data.content && links.push(`utm_content=${encodeURI(data.content)}`);
    data.term && links.push(`utm_term=${encodeURI(data.term)}`);
    const link = links.length ? `${host}?${links.join('&')}` : host || '';
    setUrl(link);
    setValue('link', link);
    setCopied(false);
  }, [data.campaign, data.content, data.medium, data.source, data.term, host, setValue]);

  return (
    <>
      <h3 className={styles.title}>UTM Builder</h3>
      <Form.Group className={'mb-3'}>
        <Form.Label>Medium</Form.Label>
        <Form.Control {...register('medium')} size={'sm'} />
      </Form.Group>
      <Form.Group className={'mb-3'}>
        <Form.Label>Source</Form.Label>
        <Form.Control {...register('source')} size={'sm'} />
      </Form.Group>
      <Form.Group className={'mb-3'}>
        <Form.Label>Campaign</Form.Label>
        <Form.Control {...register('campaign')} size={'sm'} />
      </Form.Group>
      <Form.Group className={'mb-3'}>
        <Form.Label>Content</Form.Label>
        <Form.Control {...register('content')} size={'sm'} />
      </Form.Group>
      <Form.Group className={'mb-3'}>
        <Form.Label>Term</Form.Label>
        <Form.Control {...register('term')} size={'sm'} />
      </Form.Group>
      <Form.Group className={'mb-3'}>
        <Form.Label>Generated link</Form.Label>
        <Controller
          render={({ field: { value } }) => <Form.Control as="textarea" rows={4} size={'sm'} value={value} disabled />}
          name={'link'}
          defaultValue={''}
        />
        <CopyToClipboard onCopy={onCopy} text={url}>
          <Button withIcon={false} onClick={() => {}} btnStyle={'outline'} className={'mt-2 w-100'}>
            {copied ? 'Link copied!' : 'Copy link'}
          </Button>
        </CopyToClipboard>
      </Form.Group>
    </>
  );
};
