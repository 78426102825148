import React from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment/moment';

import classNames from 'classnames';
import styles from './contact-session.module.scss';
import { Icon16CalendarTime, Icon16Click } from '../../../../../components/UI/icons';

import { ElementThumb } from '../../../../../components/illustrations/ElementThumb';
import { ItemStatus } from '../../../../../components/itemStatus/ItemStatus';
import { IRun } from '../../../../../interfaces';
import { automationPaths } from '../../../routes/AutomationRoutes';

interface IProps {
  session: IRun;
}

export const ContactSession = ({ session }: IProps) => {
  const history = useHistory();

  const openWorkflow = (id: string) => {
    history.push(automationPaths.automationListId(id));
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.top}>
          Workflow name
          <ItemStatus
            label={session.status}
            className={classNames(
              { published: session.status === 'done' },
              { draft: session.status === 'running' },
              { archived: session.status === 'failed' },
              styles.status
            )}
          />
        </div>
        <div className={styles.title} onClick={() => openWorkflow(session.automation.id)}>
          {session.automation.title}
        </div>
      </div>
      <div className={styles.body}>
        <div className={styles.item}>
          <div className={styles.label}>
            <Icon16CalendarTime /> Started date
          </div>
          <div className={styles.value}>{moment(session.created_at).format('DD MMM yyyy, HH:mm')}</div>
        </div>
        <div className={classNames(styles.item, 'd-flex')}>
          <div className={'flex-grow-1'}>
            <div className={styles.label}>
              <Icon16Click /> Last action
            </div>
            <div className={styles.value}>{moment(session.last_event.created_at).format('DD MMM yyyy, HH:mm')}</div>
          </div>
          <div className={styles.icon}>
            <ElementThumb name={session.last_event.node_type} builderType={'automation'} size={[48, 48]} />
          </div>
        </div>
      </div>
    </div>
  );
};
