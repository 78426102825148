import { memo, useEffect } from 'react';

import classNames from 'classnames';
import styles from './timer.module.scss';

interface IProps {
  seconds: number;
  onFinish(): void;
  className?: string;
}

export const Timer = memo(({ seconds, onFinish, className }: IProps) => {
  useEffect(() => {
    if (seconds <= 0) {
      onFinish();
    }
  }, [seconds, onFinish]);

  return <div className={classNames(styles.container, className)}>00:{seconds < 10 ? `0${seconds}` : seconds}</div>;
});
