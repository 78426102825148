import { useState } from 'react';
import { Collapse } from 'react-bootstrap';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';

import styles from './change-password.module.scss';

import { Button, Toast } from '../../../../components/UI';
import { PasswordControl } from '../../../../components/UI/password-control/PasswordControl';
import { RecoveryForm } from '../../../auth/recovery-password/recovery-form/RecoveryForm';
import { AuthActionAsync } from '../../../auth/store/AuthActionAsync';
import { passwordSchema } from './passwordSchema';

interface IChangePassword {
  currentPassword: string;
  password: string;
  repeatPassword: string;
}

export const ChangePassword = () => {
  const dispatch = useDispatch();
  const methods = useForm<IChangePassword>({ resolver: yupResolver(passwordSchema) });
  const {
    handleSubmit,
    formState: { errors, isDirty },
    reset,
    setError
  } = methods;
  const [togglePasswordForm, setTogglePasswordForm] = useState(false);
  const [loading, setLoading] = useState(false);

  const onToggleForm = () => {
    setTogglePasswordForm(!togglePasswordForm);
  };

  const onSave = (data: IChangePassword) => {
    if (data.password !== data.repeatPassword) {
      setError('repeatPassword', { type: 'mismatchPasswords', message: 'Passwords do not match' });
    } else {
      setLoading(true);
      dispatch(
        AuthActionAsync.changePassword(
          {
            current_password: data.currentPassword,
            new_password: data.password
          },
          () => {
            setLoading(false);
            reset();
            Toast('success', 'Password successfully changed');
          },
          (error) => {
            setLoading(false);
            if (error.data.status === 'error.user.password_doesnt_match') {
              setError('currentPassword', {
                type: 'error.user.password_doesnt_match',
                message: 'Current password does not match'
              });
            }
          }
        )
      );
    }
  };

  const onCancel = () => {
    setTogglePasswordForm(false);
    reset();
  };

  return (
    <FormProvider {...methods}>
      <div className={styles.container}>
        <div className={styles.toggle} onClick={onToggleForm}>
          Change password
        </div>
        <Collapse in={togglePasswordForm}>
          <div>
            <PasswordControl
              name={'currentPassword'}
              controlProps={{
                isInvalid: !!errors.currentPassword,
                disabled: loading
              }}
              placeholder={''}
              label={'Current password'}
              className={'mb-4'}
            >
              {errors.currentPassword?.type === 'error.user.password_doesnt_match' && (
                <div className={'error'}>{errors.currentPassword.message}</div>
              )}
            </PasswordControl>
            <RecoveryForm
              isLoading={loading}
              onBlurHandler={() => {}}
              onFocusHandler={() => {}}
              showRules={true}
              params={{
                passwordPlaceholder: '',
                repeatPlaceholder: '',
                passwordLabel: 'New password',
                repeatLabel: 'Repeat new password',
                rulesClassName: styles.rules,
                passwordClassName: 'mb-2'
              }}
            />
            <div className={styles.actions}>
              <Button
                onClick={handleSubmit(onSave)}
                withIcon={false}
                className={'mr-2'}
                btnProps={{ disabled: loading || !isDirty }}
              >
                Update password
              </Button>
              <Button onClick={onCancel} withIcon={false} btnStyle={'secondary'}>
                Cancel
              </Button>
            </div>
          </div>
        </Collapse>
      </div>
    </FormProvider>
  );
};
