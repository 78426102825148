import classNames from 'classnames';
import stylesCategory from '../../settings/course-category/course-category.module.scss';
import styles from './info-course.module.scss';

import { ItemStatus } from '../../../../../components/itemStatus/ItemStatus';
import { ThumbnailPlaceholderImage } from '../../../../../components/UI/image-components/thumbnail-placeholder-image/ThumbnailPlaceholderImage';
import { courseCategoryList } from '../../settings/course-category/CourseCategory';
import { ICourse } from '../../store/CourseState';

interface IProps {
  course: ICourse;
}

export const InfoCourse = ({ course }: IProps) => {
  const { category } = course.settings;
  return (
    <div className={styles.container}>
      <div className={'d-flex align-items-center'}>
        <div className={'mt-4 mr-3 mb-4 ml-4'}>
          <ThumbnailPlaceholderImage
            width={200}
            height={112}
            thumbnailCourse={course.settings.thumbnail}
            className={styles.image}
          />
        </div>

        <div>
          <div className={classNames(styles.title, 'ellipsis')}>{course.title}</div>
          <div className={'d-flex align-items-center h-100'}>
            {category && (
              <label htmlFor={category} key={category} className={classNames(stylesCategory.label, 'mb-0')}>
                <input
                  type={'radio'}
                  name={'category'}
                  id={category}
                  value={category}
                  className={stylesCategory.input}
                />
                <div className={classNames(stylesCategory.category, stylesCategory.active)}>
                  {courseCategoryList[category].icon}
                  {courseCategoryList[category].name}
                </div>
              </label>
            )}
            <ItemStatus label={course.state} />
          </div>
        </div>
      </div>
    </div>
  );
};
