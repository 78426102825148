import { ChangeEvent, useState } from 'react';
import { useAudioPlayer } from 'react-use-audio-player';

import classNames from 'classnames';
import styles from './audio-volume.module.scss';
import { IconVolumeMax, IconVolumeMin, IconVolumeOff } from '../../UI/icons';

const minVolume = 0;
const maxVolume = 100;

interface IProps {
  isMobile?: boolean;
}

export const AudioVolume = ({ isMobile = false }: IProps) => {
  const { volume } = useAudioPlayer();
  const [value, setValue] = useState(maxVolume);

  const handleChangeVolume = (slider: ChangeEvent<HTMLInputElement>) => {
    const volValue = parseFloat((Number(slider.target.value) / 100).toFixed(2));
    return volume(volValue);
  };

  const getBackgroundSize = () => {
    return { backgroundSize: `${(value * 100) / maxVolume}% 100%` };
  };

  const percentVolume = value > 0 ? value / maxVolume : minVolume;

  return (
    <div className={styles.container}>
      {percentVolume < 0.5 && percentVolume > 0 && <IconVolumeMin className={styles.icon} />}
      {percentVolume > 0.5 && <IconVolumeMax className={styles.icon} />}
      {percentVolume === 0 && <IconVolumeOff className={styles.icon} />}
      {isMobile ? (
        <input
          style={getBackgroundSize()}
          type="hidden"
          min={minVolume}
          max={maxVolume}
          onChange={(e) => {
            handleChangeVolume(e);
            setValue(Number(e.target.value));
          }}
          value={value}
        />
      ) : (
        <input
          style={getBackgroundSize()}
          type="range"
          min={minVolume}
          max={maxVolume}
          onChange={(e) => {
            handleChangeVolume(e);
            setValue(Number(e.target.value));
          }}
          value={value}
        />
      )}
    </div>
  );
};
