import React, { memo, useCallback, useMemo, useState } from 'react';
import { DraggableProvided } from 'react-beautiful-dnd';
import { Dropdown } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { find, flatMapDeep } from 'lodash';

import classNames from 'classnames';
import '../common/status-modal/lesson-state/lesson-popup.scss';
import styles from '../course-navigation/course-navigation.module.scss';

import { fileUploaderActions } from '../../../../layout/shared-components/upload-worker/store/FileUploaderActions';
import { useCourseContext } from '../../../../modules/lms/courses/common/sidebar/CourseContext';
import { coursePaths } from '../../../../modules/lms/courses/routes/CourseRoutes';
import { courseActionAsync } from '../../../../modules/lms/courses/store/CourseActionAsync';
import { ICourseNode } from '../../../../modules/lms/courses/store/CourseState';
import { ModalDelete } from '../../../UI';
import { ToastMessage } from '../../../UI/toast/ToastMessage';
import { flatten, getMembers } from '../../course/CourseHelpers';
import { AvailableNode } from '../common/available/AvailableNode';
import { DripModal } from '../common/drip/DripModal';
import { EditDropMenu } from '../common/edit-drop-menu/EditDropMenu';
import { ConfirmLessonDraftModal } from '../common/status-modal/confirm-draft/ConfirmLessonDraftModal';
import { FolderDraftChildren } from '../common/status-modal/folder-draft-children/FolderDraftChildren';
import { checkParentFromNodes } from '../common/status-modal/helper';
import { LessonWithDraftParent } from '../common/status-modal/lesson-parent-draft/LessonWithDraftParent';
import { LessonNotificationDraftPopup } from '../common/status-modal/lesson-state/LessonNotificationDraftPopup';
import { CourseNavigationItemRow } from '../course-navigation-item-row/CourseNavigationItemRow';

interface IProps {
  hierarchyItem: ICourseNode;
  index: number;
  setIsMove: (x: boolean) => void;
  isMove: boolean;
  provided: DraggableProvided;
}

export const CourseNavigationItem = memo(({ hierarchyItem, index, isMove, provided, setIsMove }: IProps) => {
  const history = useHistory();
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();
  const [showDialogNode, setShowDialogNode] = useState(false);
  const { onSelect, onDeleteNode, nodeId, courseId, setCurrentNode, getHierarchy } = useCourseContext();
  const course = useSelector((state) => state.courses.courses.find((course) => course.id === courseId));
  const [isPublishInner, setIsPublishInner] = useState(true);
  const { showPopupIfParentDraft } = useSelector((state) => state.courses);
  const { files } = useSelector((state) => state.fileUpload);
  const [showModalFolderWithDraftChildren, setShowModalFolderWithDraftChildren] = useState(false);
  const [showModalLessonWithDraftParent, setShowModalLessonWithDraftParent] = useState(false);
  const [showModalIsPublish, setShowModalIsPublish] = useState(false);
  const [showModalDrip, setShowModalDrip] = useState(false);

  const isActiveLesson = useMemo(
    () => !!(hierarchyItem.type === 'lesson' && hierarchyItem.children?.find((x) => x.id === nodeId)),
    [hierarchyItem.children, hierarchyItem.type, nodeId]
  );
  const itemId = useMemo(() => hierarchyItem.id, [hierarchyItem.id]);
  const itemState = useMemo(() => hierarchyItem.state, [hierarchyItem.state]);
  const itemType = useMemo(() => hierarchyItem.type, [hierarchyItem.type]);
  const itemTitle = useMemo(() => hierarchyItem.title, [hierarchyItem.title]);
  const children = useMemo(() => hierarchyItem.children, [hierarchyItem.children]);

  const steps = useMemo(
    () => flatMapDeep<ICourseNode, ICourseNode>(children, getMembers).filter((x) => x.type === 'step'),
    [children]
  );

  const videoByItemNavigation = files.filter((uploadFile) => {
    return !!steps.find(
      (lectureTab) => lectureTab.id === uploadFile.parentBlockId && uploadFile.uploaderData.status === 'in_progress'
    );
  });

  const percent = useMemo(() => {
    const percent =
      videoByItemNavigation.reduce((acc, currentValue) => acc + currentValue.progress, 0) /
        videoByItemNavigation.length || 0;
    return percent === 100 ? 0 : percent;
  }, [videoByItemNavigation]);

  const data = useMemo(() => checkParentFromNodes(hierarchyItem, course), [hierarchyItem, course]);

  const onCloseModalFolderWithDraftChildren = useCallback(() => {
    setShowModalFolderWithDraftChildren(false);
  }, []);
  const onCloseModalLessonWithDraftParent = useCallback(() => {
    setShowModalLessonWithDraftParent(false);
  }, []);
  const onCloseModalIsPublish = () => {
    setShowModalIsPublish(false);
  };
  const onChangePublishInner = useCallback((isPublishInner: boolean) => {
    setIsPublishInner(isPublishInner);
  }, []);

  const onDuplicateCallback = useCallback(
    (type: string) => {
      getHierarchy();
      toast.success(<ToastMessage message={(type === 'lesson' ? 'Lesson' : 'Folder') + ' duplicated successfully!'} />);
    },
    [getHierarchy]
  );

  const onCloseModalDrip = useCallback(() => {
    setShowModalDrip(false);
  }, []);

  const onCloseModal = useCallback(() => {
    setShowModal(false);
  }, []);

  const onDuplicate = useCallback(() => {
    if (courseId) {
      dispatch(courseActionAsync.duplicateNode(courseId, hierarchyItem.id, hierarchyItem.type, onDuplicateCallback));
    }
  }, [courseId, dispatch, hierarchyItem.id, hierarchyItem.type, onDuplicateCallback]);

  const onToggleComments = () => {
    if (hierarchyItem && courseId) {
      dispatch(
        courseActionAsync.toggleComments(courseId, hierarchyItem.id, !hierarchyItem.show_comments, getHierarchy)
      );
    }
  };

  const onStateConfirm = useCallback(
    (e: React.SyntheticEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      if (courseId) {
        dispatch(
          courseActionAsync.editNodeStatus(
            courseId,
            hierarchyItem.id,
            hierarchyItem.state === 'draft' ? 'published' : 'draft',
            isPublishInner,
            getHierarchy
          )
        );
        if (showModalFolderWithDraftChildren) {
          onCloseModalFolderWithDraftChildren();
        }
        if (showModalIsPublish) {
          onCloseModalIsPublish();
        }
      }
    },
    [
      courseId,
      dispatch,
      getHierarchy,
      hierarchyItem.id,
      hierarchyItem.state,
      isPublishInner,
      onCloseModalFolderWithDraftChildren,
      showModalFolderWithDraftChildren,
      showModalIsPublish
    ]
  );

  const onItemClick = () => {
    onSelect(hierarchyItem.id);
  };

  const onStateHandler = useCallback(() => {
    const isDraftLessonWithDontShowPopup =
      hierarchyItem.type === 'lesson' &&
      showPopupIfParentDraft &&
      data.parentHasDraft.length > 0 &&
      hierarchyItem.state === 'draft';
    const isFolderWithDraftChildren = hierarchyItem.type === 'folder' && data.childrenHasDraft.length > 0;
    const isDraftFolderWithPublicChildren =
      hierarchyItem.type === 'folder' && data.childrenHasPublished.length > 0 && hierarchyItem.state === 'published';
    const isPublishLesson = hierarchyItem.type === 'lesson' && hierarchyItem.state === 'published';
    const isDraftLessonWithDraftParentAndShowPopup =
      hierarchyItem.type === 'lesson' &&
      !showPopupIfParentDraft &&
      data.parentHasDraft.length > 0 &&
      hierarchyItem.state === 'draft';

    if (isDraftLessonWithDontShowPopup) {
      toast.warn(<LessonNotificationDraftPopup />, {
        autoClose: 5000,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
      });
    }
    if (isDraftLessonWithDraftParentAndShowPopup) {
      setShowModalLessonWithDraftParent(true);
    }
    if (isFolderWithDraftChildren || isDraftFolderWithPublicChildren) {
      setShowModalFolderWithDraftChildren(true);
    } else if (isPublishLesson) {
      setShowModalIsPublish(true);
    } else if (courseId) {
      dispatch(
        courseActionAsync.editNodeStatus(
          courseId,
          hierarchyItem.id,
          hierarchyItem.state === 'draft' ? 'published' : 'draft',
          isPublishInner,
          getHierarchy
        )
      );
    }
  }, [
    courseId,
    data.childrenHasDraft.length,
    data.childrenHasPublished.length,
    data.parentHasDraft.length,
    dispatch,
    getHierarchy,
    hierarchyItem.id,
    hierarchyItem.state,
    hierarchyItem.type,
    isPublishInner,
    showPopupIfParentDraft
  ]);

  const onDripHandler = useCallback(() => {
    setShowModalDrip(true);
  }, []);

  const onDeleteNodeHandler = useCallback(
    (e: React.SyntheticEvent<HTMLButtonElement>) => {
      onCloseModal();
      e.stopPropagation();
      const res = find<ICourseNode | undefined>(flatMapDeep(hierarchyItem.children, flatten), ['id', nodeId]);
      files.forEach((uploadFile) => {
        const res = find<ICourseNode | undefined>(flatMapDeep(hierarchyItem.children, flatten), [
          'id',
          uploadFile.parentBlockId
        ]);
        if (res) {
          dispatch(
            fileUploaderActions.updateUploadFile({
              ...uploadFile,
              uploaderData: {
                ...uploadFile.uploaderData,
                status: 'canceled'
              }
            })
          );
        }
      });
      if (courseId) {
        if (hierarchyItem.children.find((x) => x.id === nodeId) || res) {
          history.push(coursePaths.courseLessons(courseId, hierarchyItem.parent_id || ''));
        }

        onDeleteNode(hierarchyItem.id);
      }
    },
    [courseId, dispatch, hierarchyItem, history, nodeId, onDeleteNode, files, onCloseModal]
  );

  const onCloseDialogNode = useCallback(
    (e?: React.SyntheticEvent<HTMLDivElement | HTMLButtonElement>) => {
      e?.stopPropagation();
      setShowDialogNode(false);
      setIsMove(false);
    },
    [setIsMove]
  );

  const onDeleteConfirm = useCallback(
    (e: React.SyntheticEvent<HTMLDivElement>) => {
      e.stopPropagation();
      setShowModal(true);
      onCloseDialogNode(e);
    },
    [onCloseDialogNode]
  );

  const onToggleDialogNode = useCallback(
    (isOpen: boolean, event: React.SyntheticEvent<Dropdown, Event>) => {
      event.stopPropagation();
      if (!isOpen) {
        setIsMove(false);
      }
      setShowDialogNode((x) => !x);
    },
    [setIsMove]
  );

  const onMoveHandler = useCallback(
    (e: React.SyntheticEvent<HTMLDivElement>) => {
      e.stopPropagation();
      setIsMove(true);
      setCurrentNode(hierarchyItem);
    },
    [hierarchyItem, setCurrentNode, setIsMove]
  );

  return (
    <>
      <div
        ref={provided.innerRef}
        {...provided.draggableProps}
        onClick={onItemClick}
        className={classNames(styles.itemContainer, {
          [styles.active]: isActiveLesson
        })}
      >
        <div
          className={classNames(styles.item, styles.teacher, styles[itemType === 'folder' ? 'folder' : 'lesson'])}
          style={{
            background: `linear-gradient(90deg, rgba(0, 28, 51, 0.07) ${percent}%, rgba(248, 247, 248, 0) ${percent}%)`
          }}
        >
          <div className={styles.itemRow}>
            <CourseNavigationItemRow
              id={itemId}
              state={itemState}
              title={itemTitle}
              type={itemType}
              isActiveLesson={isActiveLesson}
              dragHandle={provided.dragHandleProps}
            />
            <div className={styles.actions}>
              <EditDropMenu
                showComment={hierarchyItem.show_comments}
                statusNode={itemState}
                index={index}
                isActiveLesson={isActiveLesson}
                isMove={isMove}
                onDraftHandler={onStateHandler}
                onDripHandler={onDripHandler}
                onCloseDialogNode={onCloseDialogNode}
                onDeleteConfirm={onDeleteConfirm}
                onDuplicate={onDuplicate}
                onMoveHandler={onMoveHandler}
                onToggleDialogNode={onToggleDialogNode}
                onToggleComments={onToggleComments}
                showDialogNode={showDialogNode}
              />
            </div>
          </div>

          {hierarchyItem.condition && (
            <AvailableNode openAt={hierarchyItem.condition.open_at} isSelected={isActiveLesson} />
          )}
        </div>
      </div>
      <ModalDelete
        isShowed={showModal}
        title={
          <span className={classNames(styles.droDeleteText, 'ellipsis')}>
            Delete&nbsp; <span>{itemTitle}</span>&nbsp; ?
          </span>
        }
        iconType={'warning'}
        description={'All materials from this lesson will be permanently deleted.'}
        onClose={onCloseModal}
        textDelete={'Yes, delete'}
        iconStyles={styles.dropDeleteModal}
        onDelete={onDeleteNodeHandler}
      />
      <LessonWithDraftParent
        hierarchyItem={hierarchyItem}
        onCloseModal={onCloseModalLessonWithDraftParent}
        showModal={showModalLessonWithDraftParent}
      />
      <FolderDraftChildren
        hierarchyItem={hierarchyItem}
        isDraftParent={data.parentHasDraft.length > 0}
        isDraftChildren={data.childrenHasDraft.length > 0}
        onChangePublishInner={onChangePublishInner}
        onCloseModal={onCloseModalFolderWithDraftChildren}
        showModal={showModalFolderWithDraftChildren}
        onStateConfirm={onStateConfirm}
      />
      <ConfirmLessonDraftModal
        hierarchyItem={hierarchyItem}
        onStateConfirm={onStateConfirm}
        onCloseModal={onCloseModalIsPublish}
        showModal={showModalIsPublish}
      />
      <DripModal
        nodeId={hierarchyItem.id}
        isLesson={hierarchyItem.type === 'lesson'}
        onCloseModal={onCloseModalDrip}
        showModal={showModalDrip}
        title={hierarchyItem.title}
        condition={hierarchyItem.condition}
      />
    </>
  );
});
