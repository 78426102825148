import { ChangeEvent } from 'react';
import { Form, FormCheck } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';

import { IEmailActionsAutomationPayload } from '../../../../../../../../interfaces/node-builder-payload/automation-actions/email';

interface IProps extends Partial<Pick<IEmailActionsAutomationPayload, 'recipient'>> {
  setSendToValue: (value: boolean) => void;
  sendToValue: boolean;
}

export const SendToRadio = ({ sendToValue, setSendToValue }: IProps) => {
  const { setValue, reset, getValues } = useFormContext<IEmailActionsAutomationPayload>();
  const onChangeValue = (e: ChangeEvent<HTMLInputElement> | null) => {
    const isPerson = e?.currentTarget.value === 'person';
    setSendToValue(!isPerson);
    setValue('recipient', isPerson ? null : []);
  };

  return (
    <>
      <Form.Group controlId={'send-to'}>
        <FormCheck custom className={'radio-button'} type={'radio'}>
          <FormCheck.Input
            className="custom-control-input"
            type={'radio'}
            id={'person'}
            onChange={onChangeValue}
            value={'person'}
            checked={!sendToValue}
          />
          <FormCheck.Label className="custom-control-label" htmlFor={'person'}>
            To the person
          </FormCheck.Label>
        </FormCheck>
        <FormCheck custom className={'radio-button'} type={'radio'}>
          <FormCheck.Input
            className="custom-control-input"
            type={'radio'}
            id={'team'}
            onChange={onChangeValue}
            value={'team'}
            checked={sendToValue}
          />
          <FormCheck.Label className="custom-control-label" htmlFor={'team'}>
            To my team
          </FormCheck.Label>
        </FormCheck>
      </Form.Group>
    </>
  );
};
