import React, { memo, MutableRefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FormControl } from 'react-bootstrap';
import { FormProvider, useForm } from 'react-hook-form';
import ReactQuill from 'react-quill';
import { useDispatch } from 'react-redux';
import { Delta, Sources } from 'quill';
import { debounce, pick } from 'lodash';

import classNames from 'classnames';
import styles from './emailEditor.module.scss';
import { IconCross, IconPlus } from '../../../components/UI/icons';

import { BubbleQuillEditor, UnprivilegedEditor } from '../../../components/quill-editor/BubbleQuillEditor';
import { onInsertVariable } from '../../../components/quill-editor/modules/variables/Variables';
import { CommonModal, Loader } from '../../../components/UI';
import { useLoading } from '../../../hooks/use-loading';
import { IMessage } from '../../../interfaces';
import { FieldEntity, FieldScope } from '../../crm/field';
import { EditorHeader } from './editor-header/EditorHeader';
import { EmailActionAsync } from './store/EmailActionAsync';
import { VariableEmailLoader } from './variable/VariableEmailLoader';
import { IEmailVariable } from './variable/variables';

interface IProps {
  message: IMessage;
  showEmailEditor: boolean;
  filterScopes?: FieldScope[];
  excludeScopes?: FieldScope[];
  fieldEntities?: FieldEntity[];
  additionalVariables?: IEmailVariable[];
  additionalPropertyToEntity?: IEmailVariable[];
  onCloseEmailEditor: () => void;
  getEmailCallback?: (emailId: string) => void;
  preview?: boolean;
}

const EmailEditor = memo((props: IProps) => {
  const {
    onCloseEmailEditor,
    getEmailCallback,
    showEmailEditor,
    message,
    additionalVariables,
    excludeScopes,
    fieldEntities,
    filterScopes,
    additionalPropertyToEntity,
    preview = false
  } = props;
  const dispatch = useDispatch();
  const [showVariables, setShowVariables] = useState(true);
  const { loading, startLoading, stopLoading } = useLoading();
  const methods = useForm<Pick<IMessage, 'title' | 'payload'>>({
    shouldUnregister: true,
    defaultValues: {
      title: message.title,
      payload: {
        body: message.payload?.body,
        subject: message.payload?.subject
      }
    }
  });
  const {
    setValue,
    getValues,
    register,
    formState: { isDirty, errors },
    reset,
    watch
  } = methods;
  const reactQuillMessageRef = useRef<ReactQuill | null>(null);
  const [currentRef, setCurrentRef] = useState<MutableRefObject<ReactQuill | null>>(reactQuillMessageRef);
  const isActive = !reactQuillMessageRef.current?.getEditor().getText().trim() && !isDirty;
  const [isEditMode, setEditMode] = useState<boolean>();

  useEffect(() => {
    setEditMode(!preview);
  }, [preview, message]);

  const onSelectVariable = useCallback(
    (data: React.SyntheticEvent<HTMLDivElement>) => {
      const key = data.currentTarget.dataset?.key;
      const name = data.currentTarget.dataset?.name;

      if (currentRef && currentRef.current && key && name) {
        onInsertVariable(currentRef.current, {
          name,
          key
        });
      }
    },
    [currentRef]
  );

  const changeHandler = useCallback(
    (content: string, delta: Delta, source: Sources, editor: UnprivilegedEditor) => {
      setValue('payload.body', editor.getContents(), { shouldDirty: true });
    },
    [setValue]
  );

  useEffect(() => {
    setValue('payload.body', message.payload?.body);
    setValue('payload.subject', message.payload?.subject || '');
    setValue('title', message.title);
  }, [message, setValue]);

  const debouncedChangeHandler = useMemo(() => debounce(changeHandler, 300), [changeHandler]);

  const editorChangeHandler = useCallback(debouncedChangeHandler, [debouncedChangeHandler]);

  const onFocusEditor = useCallback((editor: MutableRefObject<ReactQuill>) => {
    setCurrentRef(editor);
  }, []);

  const onOpenVariables = () => {
    setShowVariables((x) => !x);
  };

  const onSaveEmail = useCallback(() => {
    startLoading();
    const email = getValues();
    const emailBody = {
      title: email.title,
      payload: {
        subject: email.payload?.subject || '',
        body: reactQuillMessageRef.current?.getEditor().getContents() || ''
      }
    };

    dispatch(
      EmailActionAsync.editMessage(message.id, emailBody, (message) => {
        setTimeout(() => {
          reset(pick(message, ['title', 'payload']));
          stopLoading();
          // onCloseEmailEditor();
          getEmailCallback?.(message.id);
        }, 3000);
      })
    );
  }, [dispatch, getEmailCallback, getValues, message.id, reset, startLoading, stopLoading]);

  const onCancel = useCallback(() => {
    reset();
    onCloseEmailEditor();
  }, [onCloseEmailEditor, reset]);

  const subjectClassName = classNames('form-control-underline form-control-lg', {
    invalid: errors?.payload?.subject
  });

  return (
    <>
      <CommonModal
        onClose={onCloseEmailEditor}
        show={showEmailEditor}
        params={{
          dialogClassName: styles.container,
          contentClassName: classNames(styles.content, 'app'),
          backdrop: 'static'
        }}
      >
        {loading && <Loader />}
        <FormProvider {...methods}>
          <EditorHeader
            onCancel={onCancel}
            onSave={onSaveEmail}
            active={isActive}
            message={message}
            body={reactQuillMessageRef.current?.getEditor().getContents()}
            preview={!isEditMode}
            changeMode={setEditMode}
          />
          <div className={styles.wrapper}>
            <div className={styles.body}>
              <div className={styles.subject}>
                <FormControl
                  className={subjectClassName}
                  placeholder={'Subject:'}
                  type={'text'}
                  readOnly={!isEditMode}
                  {...register('payload.subject', { required: 'Add a subject line' })}
                />
                {errors.payload?.subject && <div className={'error'}>{errors.payload.subject.message}</div>}
              </div>
              <input type="hidden" {...register('payload.body')} />
              <BubbleQuillEditor
                onFocus={onFocusEditor}
                handleChange={editorChangeHandler}
                value={message.payload?.body || watch().payload?.body}
                placeholder={'Write your message'}
                classNameContainer={classNames('email-editor-message', styles.message)}
                ref={reactQuillMessageRef}
                readonly={!isEditMode}
              />
            </div>
            {isEditMode && (
              <button className={classNames(styles.insert, 'btn-variables')} onClick={onOpenVariables}>
                {showVariables ? <IconCross className={styles.icon} /> : <IconPlus className={styles.icon} />}
                Insert variables
              </button>
            )}
            {showVariables && isEditMode && (
              <VariableEmailLoader
                onSelectVariable={onSelectVariable}
                excludeScopes={excludeScopes}
                fieldEntities={fieldEntities}
                additionalVariables={additionalVariables}
                filterScopes={filterScopes}
                additionalPropertyToEntity={additionalPropertyToEntity}
              />
            )}
          </div>
        </FormProvider>
      </CommonModal>
    </>
  );
});

export default EmailEditor;
