import React from 'react';

interface IProps {
  size?: {
    width: number;
    height: number;
  };
  bucket_name?: string;
  object_name?: string;
  url?: string | null;
  alt?: string;
  className?: string;
  imgProps?: React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>;
}

export const imageUrl = ({ bucket_name, object_name, size }: Pick<IProps, 'bucket_name' | 'object_name' | 'size'>) =>
  `${process.env.REACT_APP_API_IMAGE_URL}/AfrOrF3gWeDA6VOlDG4TzxMv39O7MXnF4CXpKUwGqRM/rs:auto${
    size ? `:${size.width}:${size.height}` : ':0'
  }/g:sm/q:95/plain/s3://${bucket_name}/${object_name}`;

export const Image = ({ bucket_name, object_name, size, url, alt = '', className, imgProps }: IProps) => {
  const src = url ? url : imageUrl({ bucket_name, object_name, size });

  return url || bucket_name ? <img src={src} className={className} alt={alt} {...imgProps} /> : null;
};

export const setDefaultFavicon = () => {
  const favicon = document.getElementById('favicon');

  if (favicon) {
    favicon.setAttribute('href', './favicon.ico');
  }
};

export const setDefaultTitle = () => {
  document.title = 'Everact';
};
