import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import classNames from 'classnames';
import styles from './domain.module.scss';
import { IconQuestion } from '../../../components/UI/icons';

import { Loader } from '../../../components/UI';
import { LoadState } from '../../../store';
import { HelperWrapper } from '../common/help/HelperWrapper';
import { SettingsHeader } from '../SettingsHeader';
import { DomainInstruction } from './connect-new-domain/components/domain-instruction/DomainInstruction';
import { ConnectNewDomain } from './connect-new-domain/ConnectNewDomain';
import { DomainItem } from './domain-item/DomainItem';
import { domainActionAsync } from './store/DomainSettingsActionAsync';

export const Domain = () => {
  const [showInstruction, setShowInstructions] = useState(true);

  const dispatch = useDispatch();
  const { domains, loadState } = useSelector((state) => state.domains);

  useEffect(() => {
    dispatch(domainActionAsync.getDomains());
  }, [dispatch]);

  const onHideInstruction = () => {
    setShowInstructions(false);
  };

  const onShowInstruction = () => {
    setShowInstructions(true);
  };

  return (
    <>
      <div className={styles.headerContainer}>
        <SettingsHeader title={'Student cabinet domain'} />
        <IconQuestion className={classNames('btn btn-icon', styles.iconQuestion)} onClick={onShowInstruction} />
      </div>
      {loadState === LoadState.firstLoad ? (
        <Loader />
      ) : (
        <div className={styles.content}>
          <HelperWrapper className={styles.container}>
            <div className={'p-4'}>
              {domains
                .sort((a, b) => Number(b.is_primary) - Number(a.is_primary))
                .map((domain, index) => (
                  <div className={styles.wrapper} key={index}>
                    <DomainItem
                      key={domain.id}
                      id={domain.id}
                      current_ns_servers={domain.current_ns_servers}
                      domain={domain.domain}
                      is_default={domain.is_default}
                      state={domain.state}
                      last_status_check={domain.last_status_check}
                      subdomain={domain.subdomain}
                      registrar={domain.registrar}
                      is_primary={domain.is_primary}
                      organization_id={domain.organization_id}
                      url={domain.url}
                    />
                  </div>
                ))}
              {domains.length < 2 && loadState === LoadState.allIsLoaded && <ConnectNewDomain />}
            </div>
          </HelperWrapper>
          {showInstruction && <DomainInstruction onHideInstruction={onHideInstruction} />}
        </div>
      )}
    </>
  );
};
