import { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import classnames from 'classnames';
import styles from './range-checker.module.scss';

import { useNodePayload } from '../../../../../../hooks/use-node-payload';
import { PaletteAutomationElementTypes } from '../../../../../../interfaces';
import { Ranges } from '../../../../../../interfaces/node-builder-payload/automation-processors/range';

interface IProps {
  nodeId: string;
}

export const RangeChecker = ({ nodeId }: IProps) => {
  const { control, setValue } = useFormContext();
  const payload = useNodePayload<PaletteAutomationElementTypes.PROCESSOR_RANGE>(nodeId);

  const onChangeHandler = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    // setValue(`${nodeId}.type`, e.currentTarget.value);
  };

  useEffect(() => {
    if (payload?.payload?.range?.type) {
      setValue('range.type', payload.payload?.range?.type);
    }
    document.querySelectorAll(`.endpoint-${nodeId}`).forEach((item) => {
      item.remove();
    });
  }, [nodeId, payload, payload?.payload?.range?.type, setValue]);

  return (
    <Controller
      name={'range.type'}
      control={control}
      defaultValue={Ranges[Ranges.range]}
      render={({ field: { onChange, value } }) => {
        const activeRange = value === Ranges[Ranges.range];
        return (
          <div className={styles.container}>
            <button
              className={classnames(styles.btn, activeRange && styles.select)}
              onClick={(e) => {
                onChange(e);
                onChangeHandler(e);
              }}
              value={Ranges[Ranges.range]}
            >
              Range
            </button>
            <button
              className={classnames(styles.btn, !activeRange && styles.select)}
              onClick={(e) => {
                onChange(e);
                onChangeHandler(e);
              }}
              value={Ranges[Ranges.amount]}
            >
              Amount
            </button>
          </div>
        );
      }}
    />
  );
};
