import { errorLocalization } from './constants';

export enum ExceptionType {
  Connection = 'ConnectionError',
  NoAuth = 'NoAuthError',
  Cancel = 'CancelError',
  Server = 'ServerError',
  UrlOpen = 'UrlOpenError',
  BadParams = 'BadParamsError',
  AuthFailed = 'AuthFailedError',
  NoPermission = 'NoPermission',
  UnknownError = 'UnknownError'
}

export enum ParamNames {}

export abstract class CustomError extends Error {
  //@ts-ignore
  readonly name: ExceptionType;

  constructor(readonly messageKey?: ErrorKeys, message: string = '') {
    super(message);
  }
}

export type ErrorKeys = keyof typeof errorLocalization;
export type IBadParamsData = {
  [key in ParamNames]?: ErrorKeys | null;
};

export class BadParamsError extends CustomError {
  readonly name = ExceptionType.BadParams;
  readonly messageKey = undefined;

  constructor(public data: IBadParamsData, message?: string) {
    super(undefined, message);
  }
}

export class UnknownError extends CustomError {
  readonly name = ExceptionType.UnknownError;
  readonly messageKey = 'unexpectedError';

  constructor(message: string) {
    super(undefined, message);
  }
}

export class NoAuthError extends CustomError {
  readonly name = ExceptionType.NoAuth;
  readonly messageKey = 'noAuth';
}

export class AuthFailedError extends CustomError {
  readonly name = ExceptionType.AuthFailed;

  constructor(readonly messageKey: ErrorKeys = 'unexpectedError') {
    super(messageKey);
  }
}

export class NoPermissionError extends CustomError {
  readonly name = ExceptionType.NoPermission;

  constructor(readonly messageKey: ErrorKeys = 'dontHavePermission') {
    super(messageKey);
  }
}

export class UrlOpenError extends CustomError {
  readonly name = ExceptionType.UrlOpen;
  readonly messageKey = 'openUrl';
}

export class CancelError extends CustomError {
  readonly name = ExceptionType.Cancel;

  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor() {
    super();
  }
}

export class ServerError extends CustomError {
  readonly name = ExceptionType.Server;
  readonly messageKey = 'unexpectedServerError';

  constructor(public data?: any, message?: string) {
    super(undefined, message);
  }
}

export class ConnectionError extends CustomError {
  readonly name = ExceptionType.Connection;
  readonly messageKey = 'noInternetConnection';

  constructor(public innerError?: Error, public url?: string) {
    super();
  }
}
