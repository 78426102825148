import { useCallback, useState } from 'react';
import { Collapse, FormControl, FormGroup } from 'react-bootstrap';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import classNames from 'classnames';
import styles from './sign-up-form.module.scss';

import { EmailActionStatusEnum } from '../../../API/action-email-api';
import { Button } from '../../../components/UI';
import { PasswordControl } from '../../../components/UI/password-control/PasswordControl';
import { validation } from '../../../helpers/password-validation';
import { IError, IHistoryLocationState, RegisterUserResponse } from '../../../interfaces';
import { mainPaths } from '../../MainRoutes';
import { RuleItem } from '../recovery-password/rules-matcher/RuleItem';
import { AuthActionAsync } from '../store/AuthActionAsync';

interface IProps {
  className?: string;
}

interface IFieldValues {
  email: string;
  password: string;
}

const schema = yup.object().shape({
  email: yup.string().max(250).email('Wrong email format').required(''),
  password: yup
    .string()
    .max(250)
    .concat(yup.string().min(8).matches(/[A-Z]/, { name: 'uppercase' }).matches(/[0-9]/, { name: 'numeric' }))
    .required('')
});

const isStudent = window.location.host.split('.')[0] !== 'app';

export const SignUpForm = ({ className }: IProps) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory<IHistoryLocationState>();
  const methods = useForm<IFieldValues>({
    resolver: yupResolver(schema)
  });
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors }
  } = methods;
  const [loading, setLoading] = useState(false);
  const [showRules, setShowRules] = useState(false);
  const [errorData, setErrorData] = useState({
    minimum: false,
    uppercase: false,
    numeric: false
  });

  const onSubmit = (credentials: IFieldValues) => {
    setLoading(true);
    dispatch(
      AuthActionAsync.registerUser(
        {
          ...credentials,
          callback_url: `${window.location.origin}/?link={hash}&action=${EmailActionStatusEnum.email_confirmation}`
        },
        (data) => {
          onRegisterSuccess(data, credentials);
        },
        onRegisterError
      )
    );
  };

  const onRegisterSuccess = (data: RegisterUserResponse, credentials: IFieldValues) => {
    setLoading(false);
    if (data?.action_hash) {
      history.push(
        { pathname: mainPaths.confirmation, search: location.search },
        { action_hash: data.action_hash, credentials }
      );
    }
  };

  const onRegisterError = (error: IError) => {
    setLoading(false);
    if (error.data.status === 'error.user.register.email_already_exist') {
      setError('email', { type: error.data.state, message: 'User with this email is already registered' });
    }
  };

  const onFocusHandler = useCallback(() => {
    setShowRules(true);
  }, []);

  const onChangeHandler = useCallback((e: string) => {
    const data = validation(e);
    setErrorData(data);
  }, []);

  return (
    <FormProvider {...methods}>
      <div className={classNames(styles.container, className)}>
        <FormGroup className={'mb-2'}>
          <FormControl
            type={'text'}
            placeholder={'Email address'}
            className={'mb-1'}
            disabled={loading}
            isInvalid={!!errors.email}
            {...register('email')}
          />
          {errors.email && <div className={'error'}>{errors.email.message}</div>}
        </FormGroup>
        <PasswordControl
          name={'password'}
          controlProps={{ disabled: loading, isInvalid: !!errors.password }}
          className={showRules ? 'mb-2' : 'mb-4'}
          groupProps={{ onFocus: onFocusHandler }}
          onChangeHandler={onChangeHandler}
        />
        <Collapse in={showRules} timeout={0}>
          <div className={classNames(styles.wrapper, { 'mb-4': showRules })}>
            <RuleItem text={'minimum 8 symbols'} isActive={errorData.minimum} error={errors.password?.type === 'min'} />
            <RuleItem
              text={'at least 1 upper case'}
              isActive={errorData.uppercase}
              error={errors.password?.type === 'uppercase'}
            />
            <RuleItem
              text={'numeric character'}
              isActive={errorData.numeric}
              error={errors.password?.type === 'numeric'}
            />
          </div>
        </Collapse>

        <Button
          onClick={handleSubmit(onSubmit)}
          withIcon={false}
          size={'lg'}
          className={classNames('w-100', { 'btn-loading': loading })}
          loading={loading}
        >
          {isStudent ? 'Continue' : 'Play with everact.io'}
        </Button>
      </div>
    </FormProvider>
  );
};
