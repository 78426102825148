import classNames from 'classnames';
import styles from '../events.module.scss';
import { Icon16Trash } from '../../../../components/UI/icons';

import { ElementThumb } from '../../../../components/illustrations/ElementThumb';
import { IAutomationNodeBuilder, PaletteAutomationElementTypes } from '../../../../interfaces';

interface IProps {
  node: IAutomationNodeBuilder<PaletteAutomationElementTypes>;
  selected?: string;

  onSelectNode(id: string): void;

  onDelete(id: string): void;

  onDoubleClick(id: string): void;
}

export const EventNode = ({ node, selected, onSelectNode, onDelete, onDoubleClick }: IProps) => {
  const deleteHandler = () => {
    onDelete(node.id);
  };

  const selectNode = () => {
    onSelectNode(node.id);
  };

  const onOpenPayload = () => {
    onDoubleClick(node.id);
  };

  return (
    <div
      className={classNames(styles.eventNode, { [styles.selected]: node.id === selected })}
      onClick={selectNode}
      onDoubleClick={onOpenPayload}
    >
      <div className={styles.nodeName}>{node.payload.title}</div>
      <div className={styles.nodePreview}>
        <ElementThumb name={node.type} builderType={'automation'} />
      </div>
      <button
        id={node.id}
        className={classNames('btn btn-icon-shadow btn-delete', styles.delete)}
        onClick={deleteHandler}
      >
        <Icon16Trash />
      </button>
    </div>
  );
};
