import { memo, useEffect, useMemo, useState } from 'react';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import { useDispatch, useSelector } from 'react-redux';
import update from 'immutability-helper';

import styles from './course-navigation.module.scss';

import { useCourseContext } from '../../../../modules/lms/courses/common/sidebar/CourseContext';
import { courseActionAsync } from '../../../../modules/lms/courses/store/CourseActionAsync';
import { ICourseNode } from '../../../../modules/lms/courses/store/CourseState';
import { LessonSelector } from '../../../../modules/lms/courses/store/LessonSelector';
import { CourseNavigationBackItem } from '../course-navigation-back-item/CourseNavigationBackItem';
import { CourseNavigationItem } from '../course-navigation-item/CourseNavigationItem';

interface IProps {
  currentNav: ICourseNode | null;
  hierarchy: ICourseNode[];
}

export const CourseNavigation = memo(({ currentNav, hierarchy }: IProps) => {
  const [isMove, setIsMove] = useState(false);

  const [navState, setNavState] = useState<ICourseNode[]>([]);
  const { onSelect, toPreviousFolder, courseId, nodeId, currentHierarchy, getHierarchy } = useCourseContext();
  const nodeMemo = useMemo(() => LessonSelector(courseId, nodeId), [courseId, nodeId]);
  const node = useSelector((state) => nodeMemo(state));
  const parentNodeMemo = useMemo(
    () => LessonSelector(courseId, node?.lesson?.parent_id),
    [courseId, node?.lesson?.parent_id]
  );
  const parentNode = useSelector((state) => parentNodeMemo(state));
  const course = useSelector((state) => state.courses.courses.find((x) => x.id === courseId));
  const dispatch = useDispatch();

  useEffect(() => {
    if (hierarchy) {
      setNavState(hierarchy?.sort((a, b) => a.order - b.order) || []);
    } else if (parentNode?.lesson?.children) {
      setNavState(parentNode?.lesson?.children?.sort((a, b) => a.order - b.order) || []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hierarchy]);

  useEffect(() => {
    if (node?.lesson?.type === 'folder' && node?.lesson?.children) {
      setNavState(node.lesson.children.filter((x) => x.type !== 'step'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [node?.lesson?.children]);

  const handleDrag = (result: DropResult) => {
    const dragNode = navState[result.source.index];
    if (result.destination && courseId) {
      setNavState(
        update(navState, {
          $splice: [
            [result.source.index, 1],
            [result.destination?.index, 0, dragNode]
          ]
        })
      );
      const newOrder = navState[result.destination.index].order;
      dispatch(courseActionAsync.editNode(courseId, dragNode.id, { order: newOrder }, getHierarchy));
    }
  };

  return (
    <div className={styles.container}>
      {currentNav && course && (
        <CourseNavigationBackItem
          title={currentNav.title}
          course={course}
          currentNav={currentNav}
          currentHierarchy={currentHierarchy}
          onSelect={onSelect}
          toPreviousFolder={toPreviousFolder}
        />
      )}
      <DragDropContext onDragEnd={handleDrag}>
        <Droppable droppableId="node-items">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {navState.map((item, index) => (
                <Draggable key={item.id} draggableId={item.id} index={index}>
                  {(provided) => (
                    <CourseNavigationItem
                      hierarchyItem={item}
                      index={index}
                      isMove={isMove}
                      setIsMove={setIsMove}
                      provided={provided}
                    />
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
});
