import { memo } from 'react';

import classNames from 'classnames';
import styles from './create-list-item.module.scss';

import { Icon32Plus } from '../icons';

interface IProps {
  text: string;
  subtext: string;
  className?: string;
  createItem(): void;
}

export const CreateListItem = memo(({ text, subtext, className, createItem }: IProps) => {
  return (
    <div className={classNames('col-lg-3 col-md-4', className, styles.container)}>
      <div className={classNames(styles.create)} onClick={createItem}>
        <Icon32Plus className={styles.icon} width={64} height={64} />
        <div className={styles.text}>
          Create <span className="text-primary">{text}</span>
        </div>
        <p className={styles.textMuted}>{subtext}</p>
      </div>
    </div>
  );
});
