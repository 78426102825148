import { ReactNode } from 'react';

import classNames from 'classnames';
import styles from './quiz-header.module.scss';
import { Icon16ChevronRight } from '../../../../../../components/UI/icons';

import { ArrowScroll } from '../../../../../../components/UI/arrowScroll/ArrowScroll';
import { IQuiz, IQuizQuestion } from '../../../../../../interfaces/quiz';
import { QuizStepItem } from '../step/QuizStepItem';
import { StepResult } from '../step/StepResult';
import { IViewQuiz } from '../store/QuizState';

interface IProps extends Pick<IViewQuiz, 'result'>, Pick<IQuiz, 'questions' | 'moderate_answers'> {
  selectQuestion?: Nullable<IQuizQuestion>;
  children: ReactNode;
}

export const QuizHeader = ({ moderate_answers, questions, result, selectQuestion, children }: IProps) => {
  return (
    <div className={classNames('bg-white px-4 py-2 d-flex flex-column', styles.header)}>
      <ArrowScroll sectionsLength={questions.length} nodeId={selectQuestion?.id} className={styles.steps}>
        {questions?.map((item, index) => (
          <div className={styles.step} key={index} id={item.id}>
            <QuizStepItem
              question={item}
              selectQuestion={selectQuestion}
              index={index}
              isModeration={moderate_answers}
              result={result}
            />
            <Icon16ChevronRight className={styles.icon} />
          </div>
        ))}
        {children}
      </ArrowScroll>
    </div>
  );
};
