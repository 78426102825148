import { AnswerPayloadType, AnswerTypeEnum, IQuizQuestion } from '../../../../../../interfaces/quiz';
import { useViewQuizContext } from '../ViewQuizContext';

export const useQuizPayload = <T extends AnswerTypeEnum>(question?: IQuizQuestion) => {
  const { selectedAnswer } = useViewQuizContext<T>();
  return {
    question,
    selectedAnswer
  } as { question: IQuizQuestion<T>; selectedAnswer: AnswerPayloadType<T> | null };
};
