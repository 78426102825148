import { memo, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import classNames from 'classnames';
import styles from './topbar.module.scss';
import { Icon32BurgerMenu, IconHome, IconWebinar } from '../../../components/UI/icons';

import { useLessonNode } from '../../lms/hooks/use-lesson-node';
import { useViewCourseContext } from '../../lms/students/student-course/ViewCourseContext';
import { StudentCabinetPaths } from '../routes/StudentCabinetRoutes';

interface IProps {
  openMenu(): void;
}

export const TopBar = memo(({ openMenu }: IProps) => {
  const { course, nodeId, flatLessons } = useViewCourseContext();
  const [title, setTitle] = useState('');
  const { nodeStep } = useLessonNode(course, nodeId);

  useEffect(() => {
    const lesson = flatLessons?.all.find((lesson) => lesson.id === nodeStep?.parent_id);
    if (lesson) {
      setTitle(lesson.title);
    }
  }, [flatLessons?.all, nodeStep?.parent_id]);

  return (
    <div className={styles.container}>
      <div className={styles.left}>
        <button className={classNames('btn btn-icon', styles.menu)} onClick={openMenu}>
          <Icon32BurgerMenu />
        </button>
      </div>
      <div className={styles.center}>
        <IconWebinar className={'mr-2 flex-shrink-0'} />
        <div className={classNames(styles.title, 'ellipsis')}>{title}</div>
      </div>
      <div className={styles.right}>
        <Link to={StudentCabinetPaths.courses} className={classNames('btn btn-icon', styles.home)}>
          <IconHome />
        </Link>
        {/*<button className={classNames('btn btn-icon btn-transparent mr-3 mr-md-2')}>
          <IconBell />
        </button>
        <button className={classNames('btn btn-icon btn-transparent')}>
          <IconChatBubbles />
        </button>*/}
      </div>
    </div>
  );
});
