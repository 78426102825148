import { IFile } from './general';
import { AttachmentType } from './quiz';

export interface IFileUploadResponse extends IFile {
  presigned_url: string;
}

export enum TypeOfUploader {
  upload = 'upload',
  link = 'link',
  embed = 'embed'
}

export interface VideoField {
  type: TypeOfUploader;
  video_url?: string;
  url?: string;
  code?: string;
}

export interface AudioField {
  type: TypeOfUploader;
  audio_url?: string;
  url?: string;
  code?: string;
}

export interface AttachmentField extends Pick<IFile, 'bucket_name' | 'object_name'> {
  file_name: string;
  file_size: string;
  file_type: AttachmentType;
}
