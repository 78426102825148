import { memo, useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { flatMapDeep } from 'lodash';

import classNames from 'classnames';
import styles from './lesson-navigation.module.scss';
import { IconArrowLeft, IconArrowRight, IconCheckMark } from '../../../../components/UI/icons';

import { getMembers } from '../../../../components/course/course/CourseHelpers';
import { Button } from '../../../../components/UI';
import { CommonCourseBlock, ICourseBlockQuiz } from '../../../../interfaces';
import { dashboardPaths } from '../../../dashboard/routes/DashboardRoutes';
import { courseActions } from '../../../lms/courses/store/CourseActions';
import { FlattenLessons, ICourseNode } from '../../../lms/courses/store/CourseState';
import { previewPaths } from '../../../lms/preview/routes/PreviewRoutes';
import { useViewCourseContext } from '../../../lms/students/student-course/ViewCourseContext';
import { StudentCabinetPaths } from '../../routes/StudentCabinetRoutes';
import { StudentCabinetActionAsync } from '../../store/StudentCabinetActionAsync';
import { QuizNotPassedModal } from '../quiz-not-passed-modal/QuizNotPassedModal';

interface IProps {
  navigation: FlattenLessons;
  isLastStep: boolean;
  lessonId: string;
  isPassedLesson: boolean;
}

export const LessonNavigation = memo(({ navigation, isLastStep, lessonId, isPassedLesson }: IProps) => {
  const [unfinishedQuizModal, setUnfinishedQuizModal] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const { getHierarchy, course, nodeId, flatLessons } = useViewCourseContext();
  const fromPreview = !!useRouteMatch(previewPaths.previewLessons());
  const fromDashboard = !!useRouteMatch(dashboardPaths.dashboardLessons());

  const currentLesson = useMemo(
    () => flatLessons?.all.find((lesson) => lesson.id === lessonId),
    [flatLessons?.all, lessonId]
  );

  const quizLessons = useMemo(
    () =>
      flatMapDeep<ICourseNode, ICourseBlockQuiz>(currentLesson?.children, getMembers).filter(
        (x: CommonCourseBlock) => x.type === 'block:quiz' && !x.is_passed
      ) || [],
    [currentLesson?.children]
  );

  const onCloseUnfinishedQuiz = useCallback(() => {
    setUnfinishedQuizModal(false);
  }, []);

  const onNextLesson = useCallback(
    () => navigation.next && history.push(navigation.next.children[0].id),
    [history, navigation.next]
  );

  const onNext = useCallback(() => {
    if (navigation.nextStep) {
      if (fromPreview) {
        history.push(previewPaths.previewLessons(course.id, navigation.nextStep.id));
      } else if (fromDashboard) {
        history.push(dashboardPaths.dashboardLessons(course.id, navigation.nextStep.id));
      } else {
        history.push(StudentCabinetPaths.courseStudentLessons(course.id, navigation.nextStep.id));
      }
    }
  }, [course.id, fromDashboard, fromPreview, history, navigation.nextStep]);

  const onPrev = () => {
    if (navigation.prevStep) {
      if (fromPreview) {
        history.push(previewPaths.previewLessons(course.id, navigation.prevStep.id));
      } else if (fromDashboard) {
        history.push(dashboardPaths.dashboardLessons(course.id, navigation.prevStep.id));
      } else {
        history.push(StudentCabinetPaths.courseStudentLessons(course.id, navigation.prevStep.id));
      }
    }
  };
  const onCompleteCallback = useCallback(() => {
    dispatch(StudentCabinetActionAsync.getStatusesCourse(course.id));
    getHierarchy();
    onNextLesson();
  }, [course.id, dispatch, getHierarchy, onNextLesson]);

  const onCheckLessonsCallback = useCallback((courseHierarchy: ICourseNode[]) => {
    const updatedCurrentLesson = courseHierarchy.find(lesson => lesson.id === lessonId);
    const updatedQuizLessons = flatMapDeep<ICourseNode, ICourseBlockQuiz>(
      updatedCurrentLesson?.children,
      getMembers
    ).filter(x => x.type === 'block:quiz' && !x.is_passed) || [];

    if (updatedQuizLessons.length > 0) {
      setUnfinishedQuizModal(true);
    } else {
      setUnfinishedQuizModal(false);
      if (nodeId) {
        dispatch(StudentCabinetActionAsync.setStatusNode(course.id, lessonId, onCompleteCallback));
      }
    }
  }, [course.id, dispatch, lessonId, nodeId, onCompleteCallback]);

  const onLessonComplete = useCallback(() => {
    if (fromPreview) {
      dispatch(courseActions.setStatusNodePreview({ course, lessonId }));
    } else {
      dispatch(StudentCabinetActionAsync.getStudentCourseHierarchy(course.id, onCheckLessonsCallback));
    }
  }, [course, dispatch, fromPreview, lessonId, onCheckLessonsCallback]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.buttons}>
          <button
            className={classNames('btn btn-icon', styles.button)}
            onClick={onPrev}
            disabled={!navigation.prevStep}
          >
            <IconArrowLeft />
          </button>
          <button
            className={classNames('btn btn-icon', styles.button)}
            onClick={onNext}
            disabled={!navigation.nextStep}
          >
            <IconArrowRight />
          </button>
        </div>
        <div className={styles.name}>{navigation.currentStep?.title}</div>
        {isPassedLesson && isLastStep && !!navigation.next && (
          <Button
            onClick={onNextLesson}
            btnStyle={'outline'}
            className={classNames('ml-auto', styles.completedBtn)}
            type={'arrow-right'}
          >
            Next lesson
          </Button>
        )}
        {isPassedLesson && isLastStep && !navigation.next && (
          <Button
            onClick={() => { }}
            btnStyle={'outline'}
            className={classNames('ml-auto', styles.completedBtn)}
            btnProps={{ disabled: true }}
            customIcon={<IconCheckMark className={'icon'} />}
          >
            Completed
          </Button>
        )}
        {isLastStep && !isPassedLesson && !!navigation.next && (
          <Button onClick={onLessonComplete} withIcon={false} btnStyle={'secondary'} className={'ml-auto'}>
            Complete & continue
          </Button>
        )}
        {isLastStep && !isPassedLesson && !navigation.next && (
          <Button onClick={onLessonComplete} withIcon={false} btnStyle={'secondary'} className={'ml-auto'}>
            Complete
          </Button>
        )}
      </div>
      <QuizNotPassedModal onClose={onCloseUnfinishedQuiz} show={unfinishedQuizModal} unfinishedQuizzes={quizLessons} />
    </div>
  );
});
