import { useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { Link, NavLink } from 'react-router-dom';
import moment from 'moment';

import classNames from 'classnames';
import styles from './course-list-item.module.scss';
import { Icon32KebabMenu } from '../../../../../components/UI/icons';

import { ItemStatus } from '../../../../../components/itemStatus/ItemStatus';
import { CourseTag } from '../../../../../components/UI/course-tag/CourseTag';
import { ThumbnailPlaceholderImage } from '../../../../../components/UI/image-components/thumbnail-placeholder-image/ThumbnailPlaceholderImage';
import { KebabToggle } from '../../../../../components/UI/kebabToggle/KebabToggle';
import { coursePaths } from '../../routes/CourseRoutes';
import { ICourse } from '../../store/CourseState';
import { CourseModals } from '../course-modals/CourseModals';
import { GetDropdownItems } from '../dropdown-items/DropDownItems';

interface IProps {
  course: ICourse;
}

export const CourseListItem = ({ course }: IProps) => {
  const [showDelete, setShowDelete] = useState(false);
  const [showUnpublish, setShowUnpublish] = useState(false);
  const [showShareable, setShowShareable] = useState(false);

  const onChangeState = (_eventKey: string | null) => {};

  const onCloseDelete = () => {
    setShowDelete(false);
  };

  const onCloseUnpublish = () => {
    setShowUnpublish(false);
  };

  const onCloseShareable = () => {
    setShowShareable(false);
  };

  const onOpenDelete = () => {
    setShowDelete(true);
  };

  const onOpenUnpublish = () => {
    setShowUnpublish(true);
  };

  const onOpenShareable = () => {
    setShowShareable(true);
  };

  return (
    <>
      <div className={classNames(styles.container)}>
        <div className={styles.item}>
          <Link to={coursePaths.courseLessons(course.id)} className={styles.preview}>
            <ThumbnailPlaceholderImage width={288} height={162} thumbnailCourse={course.settings.thumbnail} />

            <div className={styles.labels}>
              <CourseTag className={'mr-2'} category={course.settings.category} />
              <ItemStatus label={course.state} />
            </div>
          </Link>
          <div className={styles.info}>
            <div className={'d-flex flex-column'}>
              <h3 className={styles.title}>
                <NavLink to={coursePaths.courseLessons(course.id)} className={'ellipsis'}>
                  {course.title}
                </NavLink>
              </h3>
              <div className={styles.date}>{moment(course.date_update).fromNow()}</div>
            </div>
            <Dropdown onSelect={onChangeState} className={styles.nonePosition}>
              <Dropdown.Toggle as={KebabToggle} id="dropdown-basic">
                <Icon32KebabMenu className={styles.kebab} />
              </Dropdown.Toggle>
              <Dropdown.Menu align="right" className={'sm'}>
                {
                  <GetDropdownItems
                    courseId={course.id}
                    itemState={course.state}
                    onOpenDelete={onOpenDelete}
                    onOpenUnpublish={onOpenUnpublish}
                    onOpenShareable={onOpenShareable}
                  />
                }
              </Dropdown.Menu>
            </Dropdown>

            {/*<div className={styles.additionInfo}>
          {course.student_count && (
            <div className={styles.students}>
              <IconFeed className={styles.icon} />
              {course.student_count}
            </div>
          )}
          {course.comment_count && (
            <div className={styles.comments}>
              <IconChatBubbles className={styles.icon} />
              {course.comment_count}
            </div>
          )}

          <div className={styles.reviews}>
            <IconStarFilled className={styles.icon} />
            {course.rating ? course.rating : <span className={'text-muted'}>No reviews</span>}
          </div>
        </div>*/}
          </div>
        </div>
      </div>

      <CourseModals
        course={course}
        onCloseDelete={onCloseDelete}
        onCloseUnpublish={onCloseUnpublish}
        showDelete={showDelete}
        showUnpublish={showUnpublish}
        onCloseShareable={onCloseShareable}
        showShareable={showShareable}
      />
    </>
  );
};
