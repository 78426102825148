import { useEffect, useMemo, useState } from 'react';
import { Form, FormControl } from 'react-bootstrap';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useDispatch, useSelector } from 'react-redux';

import classNames from 'classnames';
import styles from './copy-link-block.module.scss';

import { Button } from '../../../../../components/UI';
import { LoadState } from '../../../../../store';
import { domainActionAsync } from '../../../../settings/domain/store/DomainSettingsActionAsync';
import { StudentCabinetPaths } from '../../../../student-cabinet/routes/StudentCabinetRoutes';

interface IProps {
  courseId: string;
  classNameContainer?: string;
  btn?: string;
}

export const CopyLinkBlock = ({ courseId, classNameContainer, btn }: IProps) => {
  const dispatch = useDispatch();
  const { domains, loadState } = useSelector((state) => state.domains);

  useEffect(() => {
    if (domains.length === 0 && loadState === LoadState.firstLoad) {
      dispatch(domainActionAsync.getDomains());
    }
  }, [dispatch, domains.length, loadState]);

  const [copied, setCopied] = useState(false);
  const onCopy = () => {
    setCopied(true);
  };

  const mainDomain = useMemo(() => {
    return domains.find((x) => x.is_primary);
  }, [domains]);

  const link = useMemo(() => {
    if (mainDomain) {
      return `https://${mainDomain.url}${StudentCabinetPaths.courseStudentLessons(courseId)}`;
    } else {
      return `${window.location.origin.replace('app', 'student')}${StudentCabinetPaths.courseStudentLessons(courseId)}`;
    }
  }, [courseId, mainDomain]);

  return (
    <Form.Group className={classNames('mb-0 d-flex mt-2', classNameContainer)}>
      <FormControl size={'lg'} value={link} disabled className={styles.input} />
      <CopyToClipboard text={link} onCopy={onCopy}>
        <Button withIcon={false} onClick={onCopy} btnStyle={'primary'} className={classNames(styles.button, btn)}>
          {copied ? 'Copied!' : 'Copy link'}
        </Button>
      </CopyToClipboard>
    </Form.Group>
  );
};
