import actionCreatorFactory from 'typescript-fsa';

import { IPaginationResponse } from '../../../../interfaces';
import { IStudentEnrolParams } from '../../../../interfaces/student';
import { IStudent, IStudentAnalytics, IStudentField, IStudentList } from './StudentListState';

export const actionCreator = actionCreatorFactory();

export const StudentsActions = {
  getStudentsConfig: actionCreator.async<string | void, IStudentList, Error>('GET_STUDENTS_CONFIG'),
  getStudent: actionCreator.async<string, IStudent, Error>('GET_STUDENT'),
  getStudentDetail: actionCreator.async<string, IStudent, Error>('GET_STUDENT_DETAIL'),
  getStudentsList: actionCreator.async<void, IPaginationResponse<IStudent[]>, Error>('GET_STUDENTS'),
  createStudent: actionCreator.async<{ fields: IStudentField; tags?: string[] }, IStudent, Error>('CREATE_STUDENT'),
  editStudent: actionCreator.async<{ id: string; fields: IStudentField; tags?: string[] }, IStudent, Error>(
    'EDIT_STUDENT'
  ),
  deleteStudent: actionCreator.async<{ id: string }, void, Error>('DELETE_STUDENT'),
  getStudentsLists: actionCreator.async<void, IStudentList[], Error>('GET_STUDENTS_LISTS'),
  getStudentsOfList: actionCreator.async<string, IPaginationResponse<IStudent[]>, Error>('GET_STUDENTS_OF_LIST'),
  enrolStudent: actionCreator.async<{ id: string; params: IStudentEnrolParams }, null, Error>('ENROL_STUDENT'),
  enrollResendStudent: actionCreator.async<{ id: string; params: IStudentEnrolParams }, null, Error>(
    'ENROLL_RESEND_STUDENT'
  ),
  unEnrolStudent: actionCreator.async<
    { id: string; courseId: string; sendNotificationEmail: boolean },
    IStudent,
    Error
  >('UNENROL_STUDENT'),
  enrollmentEmail: actionCreator.async<{ actionHash: string }, void, Error>('ENROLLMENT_EMAIL'),
  getStudentAnalytics: actionCreator.async<{ id: string; courseId: string }, IStudentAnalytics, Error>(
    'GET_STUDENT_ANALYTICS'
  ),
  exportStudents: actionCreator.async<void, string, Error>('EXPORT_STUDENTS'),
  exportStudentsFromList: actionCreator.async<{ list_key: string }, string, Error>('EXPORT_STUDENTS_FROM_LIST')
};
