import { IPolicy } from '../../../../interfaces/policy';
import { LoadState } from '../../../../store';

export interface IPolicyState {
  policyList: IPolicy[];
  loadState: LoadState;
  error: Error | null;
}

export const PolicyInitialState: IPolicyState = {
  policyList: [],
  loadState: LoadState.firstLoad,
  error: null
};
