import { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import querystring from 'query-string';

import { fileUploaderActions } from '../../layout/shared-components/upload-worker/store/FileUploaderActions';
import { RestrictLogout } from './common/restrict-logout/RestrictLogout';
import { AuthActions } from './store/AuthActions';

interface IProps {
  className?: string;
  logoutRedirectParams?: Record<string, string | undefined>;
  optionsSuccessAction?: () => void;
}

export const Logout: FC<IProps> = ({ children, className, logoutRedirectParams, optionsSuccessAction }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { account } = useSelector((state) => state.account);
  const loggedByEmail = account.providers.some((provider) => provider.provider === 'email');
  const { files } = useSelector((state) => state.fileUpload);
  const [showLogoutRestrict, setShowLogoutRestrict] = useState(false);
  const videosInUploadProgress = files.filter((file) => file.uploaderData.status === 'in_progress');

  const onLogoutSuccess = () => {
    console.log('Logged out Success');
    optionsSuccessAction?.();
    dispatch(AuthActions.authTokenDelete());
    dispatch(AuthActions.setCurrentOrganization(null));
    dispatch(fileUploaderActions.cancelAllUploading([]));
    dispatch(fileUploaderActions.setShowUploader({ show: false }));
    localStorage.setItem('token', '');
    const url = querystring.stringifyUrl({
      url: '/login',
      query: logoutRedirectParams
    });
    history.push(url);
  };

  const logoutHandle = () => {
    dispatch(fileUploaderActions.setShowUploader({ show: false }));
    dispatch(fileUploaderActions.clearUploadedFiles());
    if (loggedByEmail) {
      onLogoutSuccess();
    } else {
      // logout();
    }
  };

  const onLogout = () => {
    if (videosInUploadProgress.length) {
      setShowLogoutRestrict(true);
    } else {
      logoutHandle();
    }
  };

  const onClose = () => {
    setShowLogoutRestrict(false);
  };

  const onConfirmLogoutIfUploading = () => {
    dispatch(fileUploaderActions.cancelAllUploading(files));
    logoutHandle();
  };

  return (
    <>
      <div className={className} onClick={onLogout}>
        {children}
      </div>
      <RestrictLogout show={showLogoutRestrict} onClose={onConfirmLogoutIfUploading} onStay={onClose} />
    </>
  );
};
