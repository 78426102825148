import { CustomDispatch } from 'redux';

import {
  createContact,
  createContactInList,
  createStudentFromContact,
  deleteContact,
  editContact,
  exportContacts,
  exportContactsFromList,
  getContact,
  getContactsConfig,
  getContactsList,
  getListOfContacts
} from '../../../../API/contact-api';
import { IParams } from '../../../../interfaces';
import { IStudent } from '../../../lms/students/store/StudentListState';
import { ContactListActions } from './ContactListActions';
import { IContact, IContactField } from './ContactListState';

export const ContactListActionAsync = {
  getContact: (id: string, callback?: (contact?: IContact) => void ) => async (dispatch: CustomDispatch) => {
    try {
      dispatch(ContactListActions.getContact.started(id));
      const response = await getContact(id);
      if (response.data) {
        dispatch(ContactListActions.getContact.done({ result: response.data, params: id }));
        callback?.(response.data);
      }
    } catch (error) {
      dispatch(ContactListActions.getContact.failed({ error, params: id }));
    }
  },
  getContactsList:
    // (id: Nullable<string>, pagination?: IPaginationParams, query?: Nullable<string>, callback?: () => void) =>
    (id: Nullable<string>, params?: IParams, callback?: () => void) => async (dispatch: CustomDispatch) => {
      try {
        dispatch(ContactListActions.getContactsList.started(id));
        let response;
        if (id) {
          response = await getContactsList(id, params);
        } else {
          response = await getListOfContacts(params);
        }
        if (response && response.data && response.meta) {
          dispatch(
            ContactListActions.getContactsList.done({
              result: { data: response.data, meta: response.meta },
              params: id
            })
          );
          if (typeof callback === 'function') {
            callback();
          }
        }
      } catch (error) {
        dispatch(ContactListActions.getContactsList.failed({ error, params: id }));
      }
    },
  getContactsConfig: () => async (dispatch: CustomDispatch) => {
    try {
      dispatch(ContactListActions.getContactsConfig.started());
      const response = await getContactsConfig();
      if (response.data) {
        dispatch(ContactListActions.getContactsConfig.done({ result: response.data }));
      }
    } catch (error) {
      dispatch(ContactListActions.getContactsConfig.failed({ error }));
    }
  },
  createContactInList:
    (id: string, fields: IContactField, tags?: string[], callback?: (contact: IContact) => void) =>
    async (dispatch: CustomDispatch) => {
      try {
        dispatch(ContactListActions.createContactInList.started({ id, fields, tags }));
        const response = await createContactInList(id, fields, tags);
        if (response.data) {
          dispatch(
            ContactListActions.createContactInList.done({ result: response.data, params: { id, fields, tags } })
          );
          if (typeof callback === 'function') {
            callback(response.data);
          }
        }
      } catch (error) {
        dispatch(ContactListActions.createContactInList.failed({ error, params: { id, fields, tags } }));
      }
    },
  createContact:
    (fields: IContactField, tags?: string[], callback?: (contact: IContact) => void) =>
    async (dispatch: CustomDispatch) => {
      try {
        dispatch(ContactListActions.createContact.started({ fields, tags }));
        const response = await createContact(fields, tags);
        if (response.data) {
          dispatch(ContactListActions.createContact.done({ result: response.data, params: { fields, tags } }));
          if (typeof callback === 'function') {
            callback(response.data);
          }
        }
      } catch (error) {
        dispatch(ContactListActions.createContact.failed({ error, params: { fields, tags } }));
      }
    },
  editContact:
    (id: string, fields: IContactField, tags?: string[], callback?: () => void) => async (dispatch: CustomDispatch) => {
      try {
        dispatch(ContactListActions.editContact.started({ id, fields, tags }));
        const response = await editContact(id, fields, tags);
        if (response.data) {
          dispatch(ContactListActions.editContact.done({ result: response.data, params: { id, fields, tags } }));
          if (callback) {
            callback();
          }
        }
      } catch (error) {
        dispatch(ContactListActions.editContact.failed({ error, params: { id, fields, tags } }));
      }
    },
  deleteContact: (id: string) => async (dispatch: CustomDispatch) => {
    try {
      dispatch(ContactListActions.deleteContact.started({ id }));
      await deleteContact(id);
      dispatch(ContactListActions.deleteContact.done({ params: { id } }));
    } catch (error) {
      dispatch(ContactListActions.deleteContact.failed({ error, params: { id } }));
    }
  },
  exportContacts: (callback?: (data: string) => void) => async (dispatch: CustomDispatch) => {
    try {
      dispatch(ContactListActions.exportContacts.started());
      const response = await exportContacts();
      if (response.data) {
        dispatch(ContactListActions.exportContacts.done({ result: response.data }));
        callback?.(response.data);
      }
    } catch (error) {
      dispatch(ContactListActions.exportContacts.failed({ error }));
    }
  },
  exportContactsFromList: (id: string, callback?: (data: string) => void) => async (dispatch: CustomDispatch) => {
    try {
      dispatch(ContactListActions.exportContactsFromList.started({ list_key: id }));
      const response = await exportContactsFromList(id);
      if (response.data) {
        dispatch(ContactListActions.exportContactsFromList.done({ result: response.data, params: { list_key: id } }));
        callback?.(response.data);
      }
    } catch (error) {
      dispatch(ContactListActions.exportContactsFromList.failed({ error, params: { list_key: id } }));
    }
  },
  createStudentFromContact:
    (contactId: string, callbackSuccess?: (data: IStudent) => void, callbackError?: () => void) =>
    async (dispatch: CustomDispatch) => {
      try {
        dispatch(ContactListActions.createStudentFromContact.started({ contactId }));
        const response = await createStudentFromContact(contactId);
        if (response.data) {
          dispatch(ContactListActions.createStudentFromContact.done({ result: response.data, params: { contactId } }));
          callbackSuccess?.(response.data);
        }
      } catch (error) {
        callbackError?.();
        dispatch(ContactListActions.createStudentFromContact.failed({ error, params: { contactId } }));
      }
    }
};
