import { Link, useRouteMatch } from 'react-router-dom';

import classNames from 'classnames';
import styles from '../../../../styles/components/navbar.module.scss';
import { IconArrowLeft } from '../../../components/UI/icons';

import { pageBuilderPaths } from '../../../modules/common/page-builder/routes/PageBuilderRoutes';
import { funnelPaths } from '../../../modules/funnels/routes/FunnelRoutes';

export const PageBuilderPanel = () => {
  const path = useRouteMatch<{ funnelId: string; id: string }>(pageBuilderPaths.pageBuilder());
  const funnelId = path?.params.funnelId;
  const nodeId = path?.params.id;

  return (
    <div className={`navbar navbar-expand justify-content-between ${styles.navbar}`}>
      <Link
        className={classNames('btn btn-sm btn-icon-left', styles.link)}
        to={{
          pathname: funnelPaths.funnelId(funnelId),
          search: `node_id=${nodeId}`,
          state: { fromPageBuilder: true, nodeId }
        }}
      >
        <IconArrowLeft className={'icon'} />
        Return to Funnel
      </Link>
    </div>
  );
};
