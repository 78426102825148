import { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import styles from './excludeOrganizationModal.module.scss';

import { Button, ModalActionComponent } from '../../../../components/UI';
import { AuthActions } from '../../../auth/store/AuthActions';
import { mainPaths } from '../../../MainRoutes';

interface IProps {
  organizationName: string;
  show: boolean;
  onCloseModal: () => void;
}

export const ExcludeOrganizationModal = memo(({ organizationName, show, onCloseModal }: IProps) => {
  const { account } = useSelector((state) => state.account);
  const history = useHistory();
  const dispatch = useDispatch();
  const onChangeOrganization = () => {
    if (account.organizations.length > 0) {
      const organization = account.organizations.find((org) => org.role === 'owner');
      if (organization) {
        dispatch(AuthActions.setCurrentOrganization(organization));
        onCloseModal();
        history.push(mainPaths.main);
      }
    }
  };
  return (
    <ModalActionComponent
      iconType={'warning'}
      iconStyles={styles.icon}
      title={
        <span className={styles.title}>
          You have no access to <span className={styles.orgName}>{organizationName}</span> organization
        </span>
      }
      show={show}
      onCloseModal={onChangeOrganization}
    >
      <div>
        <div className={styles.description}>
          The owner of the organization has restricted your access. If this happened by mistake, contact the owner.
        </div>
        <Button onClick={onChangeOrganization} type={'update'} className={'ml-auto mt-3'}>
          Ok
        </Button>
      </div>
    </ModalActionComponent>
  );
});
