import { useEffect, useState } from 'react';
import { Dropdown } from 'react-bootstrap';

import classNames from 'classnames';
import styles from './color-selector.module.scss';

import { CustomColorPicker } from '../../../modules/crm/offers/create-offer/publish/widget/color-picker/ColorPickerWrapped';
import { DropMenu } from '../dropmenu/DropMenu';

interface IProps {
  name: string;
  initialColor: string;
  title?: string;
}

export const ColorSelector = ({ name, initialColor, title = 'Color' }: IProps) => {
  const [show, setShow] = useState(false);
  const [color, setColor] = useState<any>(initialColor);

  useEffect(() => {
    setColor(initialColor);
  }, [initialColor]);

  const toggleShow = () => {
    setShow(!show);
  };

  return (
    <DropMenu
      show={show}
      onToggle={toggleShow}
      node={
        <div className={classNames(styles.container, { [styles.active]: show })}>
          <div className={styles.title}>{title}</div>
          <div className={styles.preview} style={{ backgroundColor: initialColor }} />
        </div>
      }
      drop={'down'}
    >
      <Dropdown.Menu className={styles.wrapper}>
        <CustomColorPicker color={color} setColor={setColor} controlName={name} />
      </Dropdown.Menu>
    </DropMenu>
  );
};
