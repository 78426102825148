import React, { memo, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Elements } from '@stripe/react-stripe-js';
import { SetupIntent } from '@stripe/stripe-js';
import { loadStripe } from '@stripe/stripe-js/pure';

import classNames from 'classnames';
import styles from './add-card.module.scss';
import { Icon32Cross } from '../../../../components/UI/icons';

import { GeneralSettingsActionAsync } from '../../../settings/general/store/GeneralSettingsActionAsync';
import { CardForm } from '../card-form/CardForm';
import { GetOneWeekTrial } from '../get-one-week-trial/GetOneWeekTrial';

interface IProps {
  clientSecret: string;

  onSuccess(setupIntent: SetupIntent): void;

  onClose(): void;
}

export const AddCard = memo(({ clientSecret, onSuccess, onClose }: IProps) => {
  const dispatch = useDispatch();
  const [publishableKey, setPublishableKey] = useState<string>();
  const stripePromise = useMemo(() => {
    return publishableKey ? loadStripe(publishableKey, { locale: 'en' }) : null;
  }, [publishableKey]);

  useEffect(() => {
    dispatch(
      GeneralSettingsActionAsync.getPublishableKey((pub_key) => {
        setPublishableKey(pub_key);
      })
    );
  }, [dispatch]);

  return (
    <div className={styles.container}>
      <button onClick={onClose} className={classNames('btn btn-icon', styles.close)}>
        <Icon32Cross />
      </button>
      <GetOneWeekTrial />
      <div className={styles.content}>
        <Elements stripe={stripePromise} options={{ locale: 'en', clientSecret }}>
          <div className={styles.title}>Add billing information</div>
          <CardForm onSuccess={onSuccess} />
        </Elements>
      </div>
    </div>
  );
});
