import { CustomDispatch } from 'redux';

import {
  createFunnel,
  deleteFunnel,
  getFunnel,
  getFunnels,
  updateFunnel,
  updateFunnelState
} from '../../../../API/funnels-api';
import { funnelActions } from './FunnelActions';

export const funnelActionAsync = {
  getFunnel: (id: string) => async (dispatch: CustomDispatch) => {
    try {
      dispatch(funnelActions.getFunnel.started(id));
      const response = await getFunnel(id);
      if (response.data) {
        dispatch(funnelActions.getFunnel.done({ result: response.data, params: id }));
      }
    } catch (error) {
      dispatch(funnelActions.getFunnel.failed({ error, params: id }));
    }
  },
  getFunnels: (token: string) => async (dispatch: CustomDispatch) => {
    try {
      dispatch(funnelActions.getFunnels.started({ token }));
      const response = await getFunnels(token);

      if (response.data) {
        dispatch(funnelActions.getFunnels.done({ result: response.data, params: { token } }));
      }
    } catch (error) {
      dispatch(funnelActions.getFunnels.failed({ error, params: { token } }));
    }
  },
  updateFunnel: (id: string, data: { name: string }) => async (dispatch: CustomDispatch) => {
    try {
      dispatch(funnelActions.updateFunnel.started({ funnelKey: id, data }));

      const response = await updateFunnel(id, data);
      if (response.data) {
        dispatch(funnelActions.updateFunnel.done({ result: response.data, params: { funnelKey: id, data } }));
      }
    } catch (error) {
      dispatch(funnelActions.updateFunnel.failed({ error, params: { funnelKey: id, data } }));
    }
  },
  updateFunnelState: (id: string, state: string) => async (dispatch: CustomDispatch) => {
    try {
      dispatch(funnelActions.updateFunnelState.started({ id, state }));
      const response = await updateFunnelState({ id, state });

      if (response.data) {
        dispatch(funnelActions.updateFunnelState.done({ result: response.data, params: { id, state } }));
      }
    } catch (error) {
      dispatch(funnelActions.updateFunnelState.failed({ error, params: { id, state } }));
    }
  },
  createFunnel: (callbackCreated: (id: string) => void) => async (dispatch: CustomDispatch) => {
    try {
      dispatch(funnelActions.createFunnel.started());
      const response = await createFunnel();
      if (response.data) {
        dispatch(funnelActions.createFunnel.done({ result: response.data }));
        callbackCreated(response.data.id);
      }
    } catch (error) {
      dispatch(funnelActions.createFunnel.failed({ error }));
    }
  },
  deleteFunnel: (id: string) => async (dispatch: CustomDispatch) => {
    try {
      dispatch(funnelActions.deleteFunnel.started({ funnelKey: id }));
      const response = await deleteFunnel(id);
      dispatch(funnelActions.deleteFunnel.done({ result: response.data, params: { funnelKey: id } }));
    } catch (error) {
      dispatch(funnelActions.deleteFunnel.failed({ error, params: { funnelKey: id } }));
    }
  }
};
