import { memo, useCallback, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { AxiosResponse } from 'axios';

import classNames from 'classnames';
import styles from './public.module.scss';
import { Icon32Megaphone, Icon32MegaphoneOff, Icon32Reload } from '../../../../../components/UI/icons';

import { Button } from '../../../../../components/UI';
import { OfferUnsavedChanges } from '../../common/unsaved/OfferUnsavedChanges';
import { OffersActionAsync } from '../../store/OffersActionAsync';
import { IOffer, OfferStatusResponse } from '../../store/OffersState';
import UpdateChange from '../common/update-change/UpdateChange';
import { useCreateOffer } from '../use-create-offer';
import { Congratulations } from './congratulations/Congratulations';
import { PreviewOffer } from './previewOffer/PreviewOffer';
import { PublicLink } from './public-link/PublicLink';
import { PublishHeader } from './publishHeader/PublishHeader';
import { Script } from './script/Script';
import { OfferWidget } from './widget/OfferWidget';

export type PublishData = Pick<
  IOffer,
  'settings_background_color' | 'settings_background_image' | 'settings_hide_branding'
>;

interface IProps extends PublishData {
  offer: IOffer;
  getOffer: () => void;
}

export const Publish = memo(
  ({ offer, settings_background_color, settings_background_image, settings_hide_branding, getOffer }: IProps) => {
    const dispatch = useDispatch();
    const [showCongrats, setShowCongrats] = useState(false);
    const { paynet, stripe } = useSelector((state) => state.general.paymentAccount);

    const methods = useForm<PublishData>({
      defaultValues: {
        settings_background_color,
        settings_background_image,
        settings_hide_branding
      },
      shouldUnregister: false
    });
    const showBrand = methods.watch('settings_hide_branding');

    const onCloseModal = () => {
      setShowCongrats(false);
    };

    const onRestoreState = useCallback(() => {
      dispatch(OffersActionAsync.editState(offer.id, 'draft', false, getOffer));
    }, [dispatch, getOffer, offer.id]);

    const onEditOfferStatusCallback = useCallback((error: AxiosResponse<OfferStatusResponse>) => {
      console.log(error);
    }, []);

    const onPublish = useCallback(() => {
      dispatch(OffersActionAsync.editState(offer.id, 'published', false, getOffer, onEditOfferStatusCallback));
      setShowCongrats(true);
    }, [dispatch, getOffer, offer.id, onEditOfferStatusCallback]);

    const onUnpublish = useCallback(() => {
      dispatch(OffersActionAsync.editState(offer.id, 'draft', false, getOffer));
    }, [dispatch, getOffer, offer.id]);

    const onReset = useCallback(() => {
      methods.reset({ settings_background_color, settings_background_image, settings_hide_branding });
    }, [methods, settings_background_color, settings_background_image, settings_hide_branding]);

    const { onCancel, onClose, onSave, showUnsavedModal, loadState } = useCreateOffer(
      offer.id,
      getOffer,
      onReset,
      methods.formState.isDirty
    );

    const priceOfferIsNotChoose = !offer.price?.type;
    const isOneTimeOfferOk = !!offer?.price?.payment_method && (stripe.state === 'done' || !!paynet);

    const isNotPublish =
    (offer.price?.type === 'one_time' && !isOneTimeOfferOk) ||
      offer.products.length === 0 ||
      priceOfferIsNotChoose;

    return (
      <FormProvider {...methods}>
        <div className={'d-flex justify-content-between align-items-center'}>
          <PublishHeader toggle={!!showBrand} />
        </div>
        <div className="mb-3">
          <OfferWidget offerId={offer.id} showBrand={!!showBrand} offer={offer} />
        </div>
        <div className={'bg-white rounded mb-6'}>
          <PreviewOffer offerId={offer.id} offer={offer} />
        </div>
        {offer.state === 'archived' && (
          <Button
            onClick={onRestoreState}
            customIcon={<Icon32Reload className={'mr-2'} />}
            iconPosition={'left'}
            className={'mt-5 w-100 d-flex justify-content-center'}
            size={'lg'}
          >
            Restore to drafts
          </Button>
        )}
        {(stripe.state === 'done' || !!paynet) && offer.state === 'published' && (
          <div className={'p-4 rounded bg-white mt-6'}>
            <Script offerId={offer.id} textButton={offer.setting_call_to_action} />
          </div>
        )}

        {offer.state === 'published' && (
          <div className={'py-3 px-4 bg-white mt-3 rounded'}>
            <div className={styles.titleLink}>Public link to cart</div>
            <PublicLink offerId={offer.id} />
          </div>
        )}
        <div className={'pb-5'}>
          {offer.state !== 'published' && offer.state !== 'archived' && (
            <Button
              onClick={onPublish}
              withIcon={!isNotPublish}
              customIcon={<Icon32Megaphone className={'mr-2'} />}
              iconPosition={'left'}
              className={classNames('mt-6 w-100 d-flex justify-content-center', styles.btn)}
              size={'lg'}
              btnProps={{ disabled: isNotPublish }}
            >
              {isNotPublish
                ? 'This offer can’t be published'
                : priceOfferIsNotChoose
                ? 'Set up your price option'
                : 'Publish'}
            </Button>
          )}

          {offer.state === 'published' && (
            <Button
              onClick={onUnpublish}
              customIcon={<Icon32MegaphoneOff className={'mr-2'} />}
              iconPosition={'left'}
              btnStyle={'secondary'}
              className={'mt-3 w-100 d-flex justify-content-center'}
              size={'lg'}
            >
              Unpublish
            </Button>
          )}
        </div>

        <Congratulations onClose={onCloseModal} show={showCongrats} offerId={offer.id} />
        <UpdateChange
          showUpdate={methods.formState.isDirty}
          loadState={loadState}
          methods={methods}
          onCancel={onCancel}
          onSave={onSave}
        />

        <OfferUnsavedChanges
          onClose={onClose}
          onCancel={onCancel}
          onSave={onSave}
          show={showUnsavedModal}
          methods={methods}
        />
      </FormProvider>
    );
  }
);
