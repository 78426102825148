import { useEffect } from 'react';
import { FormControl, FormGroup } from 'react-bootstrap';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import querystring from 'query-string';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import classNames from 'classnames';
import styles from './email-login.module.scss';
import { Icon32Spinner } from '../../../components/UI/icons';

import { Button } from '../../../components/UI';
import { PasswordControl } from '../../../components/UI/password-control/PasswordControl';
import { useAction } from '../../../hooks/use-action';
import { IError, IHistoryLocationState, IUserAuth } from '../../../interfaces';
import { mainPaths } from '../../MainRoutes';
import { AccountActionAsync } from '../../settings/account/store/AccountActionAsync';
import { StudentCabinetPaths } from '../../student-cabinet/routes/StudentCabinetRoutes';
import { useAuthContext } from '../AuthContext';
import { AuthActionAsync } from '../store/AuthActionAsync';

interface IFieldValues extends IUserAuth {}

interface IProps {
  isAdmin: boolean;
  loading: boolean;
  className?: string;
  defaultEmail?: string;
  setLoading(value: boolean): void;
  textEnter?: string;
}

const schema = yup.object().shape({
  email: yup.string().max(250).email('Wrong email format').required(''),
  password: yup.string().max(250).required('')
});

export const EmailLogin = ({
  isAdmin,
  loading,
  defaultEmail,
  setLoading,
  className,
  textEnter = 'Sign In'
}: IProps) => {
  const dispatch = useDispatch();
  const { onForgot } = useAuthContext();
  const history = useHistory<Omit<IHistoryLocationState, 'action_hash'>>();
  const { action, actionHash } = useAction();
  const methods = useForm<IFieldValues>({
    resolver: yupResolver(schema),
    defaultValues: {
      email: defaultEmail // Initial setup, but might not be enough if `defaultEmail` changes after component mounts.
    }
  });
  const {
    register,
    handleSubmit,
    setValue,
    setError,
    getValues,
    formState: { errors }
  } = methods;

  useEffect(() => {
    if (defaultEmail) {
      setValue('email', defaultEmail, { shouldValidate: true }); // Programmatically set email value and trigger validation
     }
  }, [defaultEmail, setValue]);

  const onSignInError = (error: IError) => {
    switch (error.data?.status) {
      case 'error.user.auth.invalid_email':
        setError('email', { type: error.data.status, message: 'User with this email does not exist' });
        break;
      case 'error.user.auth.not_confirmed':
        // setError('email', { type: error.data.status, message: 'This account is not verified' });
        history.push(mainPaths.confirmation, { resendCodeNow: true, credentials: getValues() });
        break;
      case 'error.user.auth.invalid_password':
        setError('password', { type: error.data.status, message: 'Wrong password' });
        break;
    }
  };

  const onSuccessLoginCallback = (token?: string) => {
    if (isAdmin) {
      dispatch(
        AccountActionAsync.getMe(false, token, (account) => {
          if (account.organizations.length === 0) {
            history.push(mainPaths.updateAccount);
          } else if (action && actionHash) {
            history.push(
              querystring.stringifyUrl({
                url: mainPaths.main,
                query: {
                  action,
                  link: actionHash
                }
              })
            );
          } else {
            history.push(mainPaths.main);
          }
        })
      );
    } else {
      dispatch(
        AccountActionAsync.getMe(true, token, () =>
          history.push({
            pathname: StudentCabinetPaths.courses,
            search: window.location.search
          })
        )
      );
    }
  };

  const onSubmit = async (data: IFieldValues) => {
    setLoading(true);
    await dispatch(
      AuthActionAsync.auth(
        {
          provider: 'email',
          payload: data,
          is_admin: isAdmin
        },
        onSignInError,
        onSuccessLoginCallback
      )
    );
    setLoading(false);
  };

  return (
    //@ts-ignore
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <div className={classNames(styles.container, className)}>
          <FormGroup className={'mb-2'}>
            <FormControl
              type={'text'}
              placeholder={'Email address'}
              className={'mb-1'}
              disabled={loading}
              isInvalid={!!errors.email}
              defaultValue={defaultEmail}
              {...register('email')}
            />
            {errors.email && <div className={styles.error}>{errors.email.message}</div>}
          </FormGroup>
          <PasswordControl
            name={'password'}
            controlProps={{ disabled: loading, isInvalid: !!errors.password }}
            className={'mb-4'}
          >
            {errors.password && <div className={styles.error}>{errors.password.message}</div>}
            <div className={styles.forgot} onClick={onForgot}>
              Forgot password
            </div>
          </PasswordControl>
          <Button
            btnProps={{ type: 'submit' }}
            onClick={handleSubmit(onSubmit)}
            withIcon={false}
            size={'lg'}
            className={classNames('w-100', { [styles.loading]: loading })}
          >
            {loading ? <Icon32Spinner className={'spinner'} /> : textEnter}
          </Button>
        </div>
      </form>
    </FormProvider>
  );
};
