import styles from './upload-worker.module.scss';

import { Button } from '../../../components/UI';

interface IProps {
  onDelete: () => void;
  onClose: () => void;
}

export const ConfirmDeletedModal = ({ onClose, onDelete }: IProps) => {
  return (
    <div className={styles.modalContainer}>
      <div className={styles.content}>
        <div className={styles.title}>
          Confirm uploading <br />
          cancellation
        </div>
        <div className={styles.description}>
          Already uploaded videos
          <br /> will remain in the lessons
        </div>
        <div className={styles.btnContainer}>
          <Button onClick={onClose} withIcon={false} btnStyle={'secondary'} className={'w-100'}>
            Cancel
          </Button>
          <Button onClick={onDelete} type={'update'} withIcon={false} className={'btn-warning w-100'}>
            Confirm
          </Button>
        </div>
      </div>
    </div>
  );
};
