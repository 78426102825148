import styles from './lesson-state.module.scss';
import { IconCross, IconInfo } from '../../../../../UI/icons';

export const LessonNotificationDraftPopup = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.iconContainer}>
          <IconInfo className={styles.icon} />
        </div>
        <div className={styles.title}>You cannot unmark this lesson</div>
        <IconCross className={styles.cross} />
      </div>
      <div className={styles.text}>
        One or more folders that contain this lesson is not published, this lesson will be visible only if all upper
        folders will be published.
      </div>
    </div>
  );
};
