import * as yup from 'yup';

export const priceSchema = yup.object().shape({
  setting_price_display_overwrite: yup.string(),
  price: yup.object({
    type: yup
      .string()
      .nullable()
      .required('Choose a price setting option')
      .oneOf(['free', 'one_time', 'recurring', 'split']),
    price: yup.number().when('type', {
      is: (val?: string) => val === 'one_time',
      then: yup
        .number()
        .typeError('Price must be a number')
        .required('Fill in this field')
        .positive('Error value')
        .moreThan(0.99, 'Price must be more then 1$')
    }),
    old_price: yup.number().when('type', {
      is: (val?: string) => val === 'one_time',
      then: yup
        .number()
        .nullable()
        .transform((value: string, originalValue: string) => {
          return originalValue === '' || isNaN(Number.parseFloat(value)) ? null : value;
        })
    }),
    payment_method: yup
      .mixed()
      .nullable()
      .when('price.type', {
        is: 'free',
        then: yup
          .string()
          .nullable()
          .notRequired()
          .transform((value: string, originalValue: string) => {
            return originalValue === '' ? null : value;
          })
      })
      .when('price.type', {
        is: 'one_time',
        then: yup.string().oneOf(['stripe', 'paynet', 'paypal']).notRequired()
      })
  }),
  order_bump_is_active: yup.bool().required(),
  order_bump_price: yup
    .number()
    .when('order_bump_is_active', {
      is: (val: boolean) => val,
      then: yup.number().typeError('Price must be a number').required('Fill in this field').positive('Error value'),
      otherwise: yup.number().notRequired().nullable()
    })
    .default(undefined),
  order_bump_headline: yup
    .string()
    .when('order_bump_is_active', {
      is: (val: boolean) => val,
      then: yup.string().nullable().required('Fill in this field'),
      otherwise: yup.string().notRequired().nullable()
    })
    .default(undefined),
  order_bump_products: yup
    .array()
    .when('order_bump_is_active', {
      is: (val: boolean) => val,
      then: yup.array().min(1, 'You must add at least one product to your offer'),
      otherwise: yup.array().notRequired().nullable()
    })
    .default(undefined),
  order_bump_checkbox_text: yup
    .string()
    .when('order_bump_is_active', {
      is: (val: boolean) => val,
      then: yup.string().nullable().required('Fill in this field'),
      otherwise: yup.string().notRequired().nullable()
    })
    .default(undefined),
  order_bump_old_price: yup
    .number()
    .nullable()
    .transform((value: string, originalValue: string) => {
      return originalValue === '' ? null : value;
    })
});
