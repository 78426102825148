import { useEffect } from 'react';

export const useOutsideClick = (ref: any, callback: () => void, excludeClass?: string[]) => {
  const handleClick = (e: MouseEvent) => {
    let noIncluded = true;
    excludeClass?.forEach((cls) => {
      if (cls && (e.target instanceof Element || e.target instanceof SVGElement)) {
        noIncluded = noIncluded && !e.target.closest(`.${cls}`);
      }
    });

    if (ref.current && !ref.current.contains(e.target) && noIncluded) {
      callback();
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  });
};
