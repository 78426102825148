import { useIntercom } from 'react-use-intercom';

import classNames from 'classnames';
import styles from './help-center.module.scss';
import community from '../../../../assets/images/community.png';
import {
  Icon32BookClosed,
  Icon32Bug,
  Icon32ChatBubbles,
  Icon32Cross,
  IconArrowRight,
  IconChevronRight,
  IconEnvelop
} from '../../../components/UI/icons';

const links = {
  doc: 'http://help.everact.io',
  bugReport: 'https://feedback.everact.io/b/Bugs-Fixes',
  community: 'https://feedback.everact.io/b/feedback'
};

interface IProps {
  onClose(): void;
}

export const HelpCenter = ({ onClose }: IProps) => {
  const { show } = useIntercom();

  const onOpenChat = () => {
    show();
    onClose();
  };

  return (
    <div className={styles.container}>
      <button onClick={onClose} className={classNames('btn btn-icon', styles.close)}>
        <Icon32Cross />
      </button>
      <div className={styles.title}>Help center</div>
      <div className={styles.description}>Here you will find useful information on working with the service</div>
      <div className={styles.nav}>
        <div
          className={styles.item}
          onClick={() => {
            window.open(links.doc);
          }}
        >
          <Icon32BookClosed className={'mr-3'} />
          Documentation
          <div className={styles.arrow}>
            <IconChevronRight className={styles.chevron} />
            <IconArrowRight className={styles.hovered} />
          </div>
        </div>
        <div className={styles.item} onClick={onOpenChat}>
          <Icon32ChatBubbles className={'mr-3'} />
          Technical support
          <div className={styles.arrow}>
            <IconChevronRight className={styles.chevron} />
            <IconArrowRight className={styles.hovered} />
          </div>
        </div>
        <div
          className={styles.item}
          onClick={() => {
            window.open(links.bugReport);
          }}
        >
          <Icon32Bug className={'mr-3'} />
          Bug report
          <div className={styles.arrow}>
            <IconChevronRight className={styles.chevron} />
            <IconArrowRight className={styles.hovered} />
          </div>
        </div>
      </div>
      <div
        className={styles.join}
        onClick={() => {
          window.open(links.community);
        }}
      >
        Join our community
        <img src={community} alt={''} />
      </div>
      <div className={styles.email}>
        <div className={styles.text}>
          <span>You can contact us by email</span>
          everactio@gmail.com
        </div>
        <a href={'mailto: everactio@gmail.com'} className={classNames('btn btn-icon', styles.button)}>
          <IconEnvelop />
        </a>
      </div>
    </div>
  );
};
