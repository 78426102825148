import { CustomDispatch } from 'redux';

import AutomationsService, {
  createAutomation,
  deleteAutomation,
  getAutomation,
  getAutomations,
  updateAutomation,
  updateAutomationState
} from '../../../API/automations-api';
import { IPaginationParams, IPaginationResponse, IRun, IRunEvent } from '../../../interfaces';
import { automationActions } from './AutomationActions';
import { AutomationRequestState, AutomationStatusResponse } from './AutomationState';

export const automationActionAsync = {
  getAutomation: (id: string) => async (dispatch: CustomDispatch) => {
    try {
      dispatch(automationActions.getAutomation.started(id));
      const response = await getAutomation(id);
      if (response.data) {
        dispatch(automationActions.getAutomation.done({ result: response.data, params: id }));
      }
    } catch (error) {
      dispatch(automationActions.getAutomation.failed({ error, params: id }));
    }
  },
  getAutomations: (token: string) => async (dispatch: CustomDispatch) => {
    try {
      dispatch(automationActions.getAutomations.started({ token }));
      const response = await getAutomations(token);

      if (response.data) {
        dispatch(automationActions.getAutomations.done({ result: response.data, params: { token } }));
      }
    } catch (error) {
      dispatch(automationActions.getAutomations.failed({ error, params: { token } }));
    }
  },
  updateAutomation: (id: string, data: { name: string }) => async (dispatch: CustomDispatch) => {
    try {
      dispatch(automationActions.updateAutomation.started({ id, data }));

      const response = await updateAutomation(id, data);
      if (response.data) {
        dispatch(automationActions.updateAutomation.done({ result: response.data, params: { id, data } }));
      }
    } catch (error) {
      dispatch(automationActions.updateAutomation.failed({ error, params: { id, data } }));
    }
  },
  updateAutomationState:
    (
      id: string,
      state: AutomationRequestState,
      updateStateCallback?: () => void,
      errorCallback?: (data?: AutomationStatusResponse) => void
    ) =>
    async (dispatch: CustomDispatch) => {
      try {
        dispatch(automationActions.updateAutomationState.started({ id, state }));
        const response = await updateAutomationState({ id: id, state });

        if (response.data) {
          dispatch(automationActions.updateAutomationState.done({ result: response.data, params: { id, state } }));
          updateStateCallback?.();
        }
      } catch (error: any) {
        errorCallback?.(error.data.status);
        dispatch(automationActions.updateAutomationState.failed({ error, params: { id, state } }));
      }
    },
  createAutomation: (callbackCreated: (id: string) => void) => async (dispatch: CustomDispatch) => {
    try {
      dispatch(automationActions.createAutomation.started());
      const response = await createAutomation();
      if (response.data) {
        dispatch(automationActions.createAutomation.done({ result: response.data }));
        callbackCreated(response.data.id);
      }
    } catch (error) {
      dispatch(automationActions.createAutomation.failed({ error }));
    }
  },
  deleteAutomation:
    (id: string, deleteCallback: () => void, errorCallback: () => void) => async (dispatch: CustomDispatch) => {
      try {
        dispatch(automationActions.deleteAutomation.started({ id }));
        const response = await deleteAutomation(id);
        dispatch(automationActions.deleteAutomation.done({ result: response.data, params: { id: id } }));
        deleteCallback();
      } catch (error) {
        dispatch(automationActions.deleteAutomation.failed({ error, params: { id: id } }));
        errorCallback();
      }
    },
  getListAutomationRuns:
    (
      pagination: IPaginationParams,
      params?: {
        contact_id: string;
      },
      callback?: (data: IPaginationResponse<IRun[]> | null) => void
    ) =>
    async (dispatch: CustomDispatch) => {
      try {
        dispatch(automationActions.getListAutomationRuns.started());
        const response = await AutomationsService.getListAutomationRuns(pagination, params);
        if (response && response.data && response.meta) {
          dispatch(automationActions.getListAutomationRuns.done({ result: response.data }));
          callback?.({
            data: response.data,
            meta: response.meta
          });
        }
      } catch (error) {
        dispatch(automationActions.getListAutomationRuns.failed({ error }));
      }
    },
  getListAutomationEventsRuns:
    (
      params: {
        session_id?: string;
        contact_id?: string;
      },
      callback?: (data: IRunEvent[] | null) => void
    ) =>
    async (dispatch: CustomDispatch) => {
      try {
        dispatch(automationActions.getListAutomationEventsRuns.started());
        const response = await AutomationsService.getListAutomationEventsRuns(params);
        if (response && response.data) {
          dispatch(automationActions.getListAutomationEventsRuns.done({ result: response.data }));
          callback?.(response.data);
        }
      } catch (error) {
        dispatch(automationActions.getListAutomationEventsRuns.failed({ error }));
      }
    }
};
