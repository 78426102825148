import { ReactNode } from 'react';

import styles from './attachment-remove.module.scss';
import { IconTrash } from '../../../../../../../../components/UI/icons';

import { AttachmentType, IAttachment } from '../../../../../../../../interfaces/quiz';

type Props = {
  onSetAttachment?: (data: Partial<IAttachment> | null, attachmentType: AttachmentType | null) => void;
  title: string;
  children: ReactNode;
};

export const AttachmentRemove = ({ onSetAttachment, title, children }: Props) => {
  const onRemove = () => {
    onSetAttachment?.(null, null);
  };
  return (
    <>
      <div onClick={onRemove} className={styles.trashContainer}>
        <IconTrash />
        {title}
      </div>
      {children}
    </>
  );
};
