import React, { useCallback, useEffect, useState } from 'react';
import { FormCheck } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { groupBy, orderBy } from 'lodash';

import styles from './quiz-results.module.scss';

import { CommonModal, Loader } from '../../../../../../../components/UI';
import { IQuizSession } from '../../../../../../../interfaces/quiz';
import { EmptyStatistics } from '../../empty-statistics/EmptyStatistics';
import { QuizSessionsActionAsync } from '../../store/QuizSessionsActionAsync';
import { QuizDetail } from '../quiz-detail/QuizDetail';
import { QuizResultItem } from '../quiz-result-item/QuizResultItem';

interface IProps {
  studentId: string;
  courseId: string;
}

export const QuizResults = ({ studentId, courseId }: IProps) => {
  const dispatch = useDispatch();
  const [quizLocation, setQuizLocation] = useState<boolean>(false);
  const [showDetail, setShowDetail] = useState(false);
  const [quizSessions, setQuizSessions] = useState<(IQuizSession & { attempt: number })[]>([]);
  const [sessionGroups, setSessionGroups] = useState<Record<string, IQuizSession[]>>();
  const [sessionGroup, setSessionGroup] = useState<IQuizSession[]>([]);
  const [loading, setLoading] = useState(false);
  const [selectedSessionId, setSelectedSessionId] = useState<Nullable<string>>(null);

  const getQuizSessions = useCallback(() => {
    setLoading(true);
    dispatch(
      QuizSessionsActionAsync.getQuizSessions(
        {
          student_id: studentId,
          course_id: courseId,
          include_hierarchy: true,
          include_questionnaire: true,
          include_answers: false,
          include_student: false
        },
        getQuizSessionsCallback
      )
    );
  }, [courseId, dispatch, studentId]);

  useEffect(() => {
    getQuizSessions();
  }, [getQuizSessions]);

  const getQuizSessionsCallback = (data: IQuizSession[]) => {
    const sessionGroup = groupBy(data, (value) => value.questionnaire.id);
    setSessionGroups(sessionGroup);
    const quizArr = Object.keys(sessionGroup).map((key) => {
      const sorted = orderBy(sessionGroup[key], (value) => value.date_create, 'desc');
      return {
        ...sorted[0],
        attempt: sorted.length
      };
    });
    setQuizSessions(quizArr);
    setLoading(false);
  };

  const openDetail = useCallback(
    (e?: React.SyntheticEvent<HTMLDivElement>, id?: string, groupId?: string | null) => {
      if (e) {
        id = e.currentTarget.id;
        groupId = e.currentTarget.getAttribute('data-group');
      }
      if (id && groupId && sessionGroups) {
        setSessionGroup(sessionGroups[groupId]);
        setSelectedSessionId(id);
        setShowDetail(true);
      }
    },
    [sessionGroups]
  );

  const closeDetail = useCallback(() => {
    setShowDetail(false);
  }, []);

  const onChange = (value: boolean) => {
    setQuizLocation(value);
  };

  const onChangeSession = useCallback((id: string) => {
    setSelectedSessionId(id);
  }, []);

  return (
    <>
      {loading ? (
        <Loader />
      ) : quizSessions.length > 0 ? (
        <div className={styles.container}>
          <FormCheck className={'custom-control custom-switch custom-switch-sm mb-1'} custom type={'switch'}>
            <FormCheck.Input
              type="checkbox"
              id={'location'}
              className="custom-control-input"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                onChange(e.target.checked);
              }}
              checked={quizLocation}
            />
            <FormCheck.Label className="custom-control-label" htmlFor={'location'}>
              Quiz location in hierarchy
            </FormCheck.Label>
          </FormCheck>
          {quizSessions.map((session) => (
            <QuizResultItem
              key={session.id}
              quizSession={session}
              courseId={courseId}
              onClick={openDetail}
              quizLocation={quizLocation}
              updateSessions={getQuizSessions}
            />
          ))}
        </div>
      ) : (
        <EmptyStatistics type={'quiz'} className={'mt-6 pt-6'} />
      )}
      {selectedSessionId && (
        <CommonModal
          show={showDetail}
          onClose={closeDetail}
          showClose
          params={{ dialogClassName: styles.modal, contentClassName: styles.containerModal }}
        >
          <QuizDetail
            courseId={courseId}
            id={selectedSessionId}
            sessions={quizSessions}
            groupSession={sessionGroup}
            onChangeSession={onChangeSession}
            showStudentDetail={false}
          />
        </CommonModal>
      )}
    </>
  );
};
