import { FormControl } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';

import classNames from 'classnames';
import commonOfferStyles from '../../common/commonOffer.module.scss';
import styles from '../products.module.scss';

import { setEmptyOrStr } from '../../../../../../helpers/text';

export const ProductTitle = () => {
  const {
    register,

    formState: { errors }
  } = useFormContext();
  return (
    <>
      <FormControl
        placeholder={'Offer name'}
        {...register('title', { setValueAs: setEmptyOrStr })}
        className={classNames('form-control form-control-md', {
          [styles.inputError]: errors && errors.title
        })}
        maxLength={256}
      />
      {errors.title && <div className={commonOfferStyles.errorTitle}>{errors.title?.message}</div>}
    </>
  );
};
