import { useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { Controller, useFormContext } from 'react-hook-form';
import Select from 'react-select';

import classnames from 'classnames';
import commonStyles from '../../common/input/setting-input.module.scss';

import { Error } from '../../../../components/UI/error/Error';
import { DropdownIndicatorLg } from '../../../../components/UI/select/DropdownIndicatorLg';
import { IOption } from '../../../../interfaces';

interface IProps {
  label: string;
  name: string;
  options: IOption[];
  defaultValue?: IOption;
  props?: any;
  containerStyles?: string;
}

export const SettingSelect = ({ label, name, options, containerStyles, defaultValue, props }: IProps) => {
  const {
    control,
    setValue,

    formState: { errors }
  } = useFormContext();
  const dataDefault = options.find((x) => x.value === defaultValue?.value);

  useEffect(() => {
    setValue(name, dataDefault?.value);
  }, [dataDefault, name, setValue]);

  return (
    <Form.Group className={classnames(commonStyles.inputContainer, containerStyles)}>
      <Form.Label className={commonStyles.label}>{label}</Form.Label>
      <Controller
        render={({ field: { onChange, value } }) => (
          <Select
            options={options}
            placeholder={'Select'}
            classNamePrefix={'select'}
            className={classnames('select-container select-lg', {
              invalid: errors[name] as any
            })}
            components={{
              DropdownIndicator: DropdownIndicatorLg,
              IndicatorSeparator: () => null
            }}
            onChange={(item: IOption) => onChange(item?.value)}
            value={options.find((item) => item.value === value)}
            {...props}
          />
        )}
        defaultValue={''}
        name={name}
        control={control}
      />
      <Error fieldName={(errors[name] as any)?.message} />
    </Form.Group>
  );
};
