import { Delta } from 'quill';

export interface IPolicy<T = PolicyContentTypeEnum> {
  content: PolicyContent<T> | null;
  created_at: string;
  document_type: DocumentType;
  id: string;
  organization_id: string;
  show_in_cabinet: boolean;
  title: string;
  updated_at: string;
}

enum DocumentTypeEnum {
  terms_of_service = 'terms_of_service',
  privacy_policy = 'privacy_policy',
  custom = 'custom'
}

type DocumentType = `${DocumentTypeEnum}`;

export enum PolicyContentTypeEnum {
  local = 'local',
  external = 'external'
}

export type PolicyContentType = `${PolicyContentTypeEnum}`;

type ExtractActionParameters<A, T> = A extends { type: T } ? A : never;

export type PolicyContent<T> = ExtractActionParameters<PolicyContentData, T>;

export type PolicyContentData =
  | {
      type: PolicyContentTypeEnum.local;
      delta: Delta;
    }
  | {
      type: PolicyContentTypeEnum.external;
      link: string;
    };

export interface ICreatePolicy {
  title: string;
  content: PolicyContent<PolicyContentTypeEnum>;
  show_in_cabinet: boolean;
  document_type: 'custom';
}

export interface IEditPolicy {
  title?: string;
  content?: PolicyContentData;
  show_in_cabinet?: boolean;
}

export enum PolicyDefinedLinkEnum {
  terms = 'terms',
  privacy = 'privacy'
}
