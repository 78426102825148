import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import styles from './dashboard.module.scss';
import { IconGraduationHat } from '../../components/UI/icons';

import { Button } from '../../components/UI';
import { useInterval } from '../../hooks/use-interval';
import { PageTopPanel } from '../../layout/shared-components/page-top-panel/PageTopPanel';
import { settingsActions } from '../../store/settings';
import { CRMPaths } from '../crm/routes/CRMRoutes';
import { coursePaths } from '../lms/courses/routes/CourseRoutes';
import { courseActionAsync } from '../lms/courses/store/CourseActionAsync';
import { studentPaths } from '../lms/students/routes/StudentRoutes';
import { BillingPermissionSelector } from '../settings/billing/store';
import { Analytics } from './blocks/analytics/Analytics';
import { HowToVideos } from './blocks/how-to-videos/HowToVideos';
import { University } from './blocks/university/University';
import { SubscriptionModal } from './common/subscribe/SubscriptionModal';
import { PeriodTimes } from './PeriodTimes/PeriodTimes';
import { DashboardActionAsync } from './store/DashboardActionAsync';

export const Dashboard = () => {
  const [upgradePlanModal, setUpgradePlanModal] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory<{ showPayload?: boolean }>();
  const { dashboard } = useSelector((state) => state);
  const { account, loadState: loadStateAccount } = useSelector((state) => state.account);
  const subscriptionMemo = useMemo(BillingPermissionSelector, []);
  const { isBlockAccount, isLoading, subscription } = useSelector(subscriptionMemo);
  const getDashboard = useCallback(() => dispatch(DashboardActionAsync.getDashboard()), [dispatch]);
  const onCloseUpgradePlan = useCallback(() => setUpgradePlanModal(false), []);

  useEffect(() => {
    if (account.organizations.length > 0) {
      getDashboard();
    }
  }, [getDashboard, account.organizations.length, loadStateAccount]);

  useInterval(() => {
    getDashboard();
  }, 90 * 1000);

  const onCourseCreated = useCallback(
    (id: string) => {
      history.push(coursePaths.courseLessons(id));
    },
    [history]
  );

  const createCourseHandler = useCallback(() => {
    if (isBlockAccount) {
      setUpgradePlanModal(true);
    } else {
      dispatch(courseActionAsync.createCourse('custom', onCourseCreated));
    }
  }, [dispatch, isBlockAccount, onCourseCreated]);

  const openStudentCard = useCallback(() => {
    if (isBlockAccount) {
      setUpgradePlanModal(true);
    } else {
      history.push(studentPaths.studentList, { showPayload: true });
      dispatch(settingsActions.setPayloadShowing({ isPayloadShow: true }));
    }
  }, [dispatch, history, isBlockAccount]);

  const openContactCard = useCallback(() => {
    if (isBlockAccount) {
      setUpgradePlanModal(true);
    } else {
      history.push(CRMPaths.crmContacts, { showPayload: true });
      dispatch(settingsActions.setPayloadShowing({ isPayloadShow: true }));
    }
  }, [dispatch, history, isBlockAccount]);

  const openLists = useCallback(() => {
    if (isBlockAccount) {
      setUpgradePlanModal(true);
    } else {
      history.push(CRMPaths.crmLists);
    }
  }, [history, isBlockAccount]);

  return (
    <>
      <div className={styles.container}>
        <PageTopPanel title={'Dashboard'} className={styles.top}>
          <Button
            onClick={() => {
              window.open('http://help.everact.io/');
            }}
            btnStyle={'secondary'}
            customIcon={<IconGraduationHat className={'icon'} />}
          >
            Knowledge base
          </Button>
        </PageTopPanel>
        {isLoading && <PeriodTimes subscription={subscription} fromBilling={false} />}
        {dashboard.courses.length !== 0 && <University courses={dashboard.courses} />}
        {process.env.REACT_APP_ENV !== 'production' && <HowToVideos />}
        <Analytics
          firstColumn={{
            count: dashboard['analytics.school'].course_count,
            title: 'Courses',
            action: createCourseHandler
          }}
          secondColumn={{
            count: dashboard['analytics.school'].student_count,
            title: 'Students',
            action: openStudentCard
          }}
          title={'School'}
          link={coursePaths.courseList}
          className={'mb-3'}
        />
        <Analytics
          firstColumn={{
            count: dashboard['analytics.crm'].contact_count,
            title: 'Contacts',
            action: openContactCard
          }}
          secondColumn={{ count: dashboard['analytics.crm'].lists_count, title: 'Lists', action: openLists }}
          title={'CRM'}
          link={CRMPaths.crmContacts}
        />
        {/* {dashboard['events.welcome_video_event'] && dashboard.loadState === LoadState.allIsLoaded && (
          <WelcomeVideo
            isShow={dashboard['events.welcome_video_event'].is_done}
            video={dashboard['events.welcome_video_event'].video}
            courses={dashboard.courses}
          />
        )} */}
      </div>
      <SubscriptionModal closeModal={onCloseUpgradePlan} showModal={upgradePlanModal} />
    </>
  );
};
