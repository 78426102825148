import { reducerWithInitialState } from 'typescript-fsa-reducers';

import { LoadState, newState } from '../../../../store';
import { currentContactActions } from './CurrentContactActions';
import { CurrentContactInitialState, ICurrentContactState } from './CurrentContactState';

export const currentContactReducer = reducerWithInitialState<ICurrentContactState>(CurrentContactInitialState)
  .case(currentContactActions.setCurrentContact, (state, data) => {
    return newState(state, data);
  })

  .case(currentContactActions.getListIdsOfContact.started, (state) => ({
    ...state,
    loadState: LoadState.firstLoad,
    error: null
  }))
  .case(currentContactActions.getListIdsOfContact.done, (state, { result }) => {
    // const newContact = { ...state.contact, lists: result };
    if (state.contact?.contact && state.contact?.tagContact) {
      return newState(state, {
        contact: { contact: state.contact?.contact, tagContact: state.contact?.tagContact, lists: result },
        loadState: LoadState.idle,
        error: null
      });
    }
    return state;
  })
  .case(currentContactActions.getListIdsOfContact.failed, (state, { error }) => ({
    ...state,
    loadState: LoadState.error,
    error
  }))

  .case(currentContactActions.editContactList.started, (state) => ({
    ...state,
    loadState: LoadState.firstLoad,
    error: null
  }))
  .case(currentContactActions.editContactList.done, (state) => {
    return newState(state, {
      ...state,
      loadState: LoadState.allIsLoaded,
      error: null
    });
  })
  .case(currentContactActions.editContactList.failed, (state, { error }) => ({
    ...state,
    loadState: LoadState.error,
    error
  }));
