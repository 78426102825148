import { ReactNode } from 'react';
import { CommonModal } from 'src/app/components/UI/modal/CommonModal';

import classNames from 'classnames';
import styles from './file-edit-in-modal.module.scss';
import { Icon32Cross, Icon32PencilOnPaper } from 'src/app/components/UI/icons';

interface IProps {
  showModal: boolean;
  onCloseModal: () => void;
  children: ReactNode;
}

export const FileEditInModal = ({ showModal, children, onCloseModal }: IProps) => {
  return (
    <CommonModal onClose={onCloseModal} show={showModal} params={{ dialogClassName: styles.container }}>
      <div className={styles.commonModal}>
        <div className={'d-flex justify-content-between'}>
          <div className={'d-flex align-items-center'}>
            <Icon32PencilOnPaper className={styles.icon} />
            <h3 className={styles.title}>Change upload</h3>
          </div>
          <button aria-label={'close'} type={'button'} className={classNames('btn btn-icon')} onClick={onCloseModal}>
            <Icon32Cross />
          </button>
        </div>
        {children}
      </div>
    </CommonModal>
  );
};
