import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import classNames from 'classnames';
import styles from './organization-list.module.scss';
import { IconPlus } from '../../../components/UI/icons';

import { CustomTooltip } from '../../../components/UI';
import { getUUID } from '../../../helpers';
import { OrganizationImg } from '../../../layout/shared-components/organization-img/OrganizationImg';
import { AuthActions } from '../../auth/store/AuthActions';
import { mainPaths } from '../../MainRoutes';

export const OrganizationList = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { organizations } = useSelector((state) => state.account.account);

  const { currentOrganization } = useSelector((state) => state.auth);
  const onSelectOrganization = (e: React.SyntheticEvent<HTMLDivElement>) => {
    const id = e.currentTarget.dataset['id'];
    if (id) {
      const organization = organizations.find((org) => org.organization_id === id);
      if (organization) {
        dispatch(AuthActions.setCurrentOrganization(organization));
        history.push(mainPaths.main);
      }
    }
  };

  return (
    <>
      {organizations.map((organization) => (
        <div
          className={classNames(styles.organizationIconWrapper, {
            [styles.selected]: organization.organization_id === currentOrganization?.organization_id
          })}
          key={getUUID()}
          onClick={onSelectOrganization}
          data-id={organization.organization_id}
        >
          <CustomTooltip
            customText={organization.organization_title}
            direction={'top'}
            disabled={organization.organization_id === currentOrganization?.organization_id}
          >
            <OrganizationImg
              organizationImg={organization.photo}
              organizationName={organization.organization_title}
              className={styles.organizationImgContainer}
              textClassName={styles.organizationImgText}
              height={32}
              width={32}
            />
          </CustomTooltip>
        </div>
      ))}

      <CustomTooltip direction={'top'}>
        <div className={styles.plusContainer}>
          <div
            className={styles.organizationIcon}
            onClick={() => {
              window.open('https://feedback.everact.io/b/feedback/p/roles-and-multiple-organizations');
            }}
          >
            <IconPlus />
          </div>
        </div>
      </CustomTooltip>
    </>
  );
};
