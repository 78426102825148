import { createSelector } from 'reselect';
import _ from 'lodash';

import { IAppState } from '../../../../store';
import { IList } from '../../lists/store/ListsState';
import { iTagToTag } from '../../tag/store/TagSelector';
import { ITag } from '../../tag/store/TagState';
import { IContact } from './ContactListState';

export const ContactListSelector = (contacts: IContact[], field_id: string) => {
  for (let i = 0; i < contacts.length; i++) {
    if (contacts[i].fields[field_id]) {
      return {
        data: contacts[i].fields[field_id]
      };
    }
  }
};

export const ContactSelector = () =>
  createSelector(
    (_: IAppState, contactId: string | undefined) => contactId,
    (state: IAppState) => state.contacts.contacts,
    (state: IAppState) => state.tags.tags,
    (contactId, contacts, tags) => {
      const contact = contacts.find((x) => x.id === contactId);
      if (contact) {
        let tagContact = iTagToTag(_.difference(contact.tags as ITag[], tags));
        if (contact.tags?.every((tag) => typeof tag === 'string')) {
          const ids = contact.tags.map((tag) => String(tag));
          tagContact = iTagToTag(tags.filter((tag) => ids.includes(tag.id)));
        }
        return { contact: contact, tagContact: tagContact };
      }
    }
  );

export const ContactListsOfListSelector = () =>
  createSelector(
    (state: IAppState) => state.currentContact.contact,
    (state: IAppState) => state.lists.lists,
    (contact, lists) => {
      if (contact?.lists) {
        const contactLists = lists.filter((x) => contact.lists?.includes(x.id));
        const contactListOfList = _.map(contactLists, (o) => _.pick(o, ['id', 'title']));

        return { contactListOfList };
      }
    }
  );

export const ContactListOfListsAllSelector = () =>
  createSelector(
    (_: IAppState, props: { search: string; attachedIds: Pick<IList, 'id' | 'title'>[] | undefined }) => props,
    (state: IAppState) => state.lists.lists,
    (props, lists) => {
      const findLists = lists.filter(
        (x) => x.title.trim().toLowerCase().includes(props.search.trim().toLowerCase()) && x.type !== 'main'
      );
      const contactListOfList = _.map(findLists, (o) => _.pick(o, ['id', 'title']));
      if (props && props.attachedIds) {
        const removeElements = _.differenceWith(
          contactListOfList,
          props.attachedIds,
          ({ id }, attach) => id === attach.id
        );

        return { allLists: removeElements };
      }
    }
  );
