import { forwardRef, memo, MutableRefObject, useCallback, useMemo } from 'react';
import ReactQuill, { Quill } from 'react-quill';
import { useDispatch } from 'react-redux';
import katex from 'katex';
import { BoundsStatic, Delta, DeltaStatic, RangeStatic, Sources } from 'quill';
import QuillImageDropAndPaste, { ImageData } from 'quill-image-drop-and-paste';
//@ts-ignore
import ImageResize from 'quill-image-resize';
import { fileUploadActionAsync } from 'src/app/layout/shared-components/upload-worker/store/FileUploadActionAsync';

import classNames from 'classnames';
import 'react-quill/dist/quill.bubble.css';
import './bubble-quill-editor.scss';
import 'katex/dist/katex.css';
import styles from './quill.module.scss';

import { imageUrl } from '../UI/image-components/image/Image';
import { customQuillSnowIcons, QuillElementsType } from './customQuillSnowIcons';
import { insertImageToEditor } from './helper';
import { FontFamily } from './modules/CustomFontFamily';
import { Size } from './modules/CustomFontSize';
import { CustomTag } from './modules/CustomTag';
import { Variables } from './modules/variables/Variables';
import { toolbarBubble } from './toolbarOptions';

const Block = Quill.import('blots/block');
Block.tagName = 'DIV';
Quill.register(Block, true);

Quill.register('modules/imageResize', ImageResize);
Quill.register('modules/imageDropAndPaste', QuillImageDropAndPaste);

Quill.register(CustomTag);
Quill.register(Variables);
Quill.register(FontFamily, true);
Quill.register(Size, true);
Quill.debug(false);

const Parchment = Quill.import('parchment');

//@ts-ignore
window.katex = katex;

class IndentAttributor extends Parchment.Attributor.Style {
  add(node: any, value: any) {
    if (value === 0) {
      this.remove(node);
      return true;
    } else {
      return super.add(node, `${value}em`);
    }
  }
}

const IndentStyle = new IndentAttributor();

Quill.register(IndentStyle, true);

const icon = Quill.import('ui/icons');
icon[QuillElementsType.undo] = customQuillSnowIcons.undo;
icon[QuillElementsType.redo] = customQuillSnowIcons.redo;
icon[QuillElementsType.clean] = customQuillSnowIcons.clean;
icon[QuillElementsType.link] = customQuillSnowIcons.link;
icon[QuillElementsType.image] = customQuillSnowIcons.image;
icon[QuillElementsType.color] = customQuillSnowIcons.color;
icon[QuillElementsType.background] = customQuillSnowIcons.background;
icon[QuillElementsType.bold] = customQuillSnowIcons.bold;
icon[QuillElementsType.italic] = customQuillSnowIcons.italic;
icon[QuillElementsType.strike] = customQuillSnowIcons.strike;
icon[QuillElementsType.code] = customQuillSnowIcons.code;
icon[QuillElementsType.underline] = customQuillSnowIcons.underline;
icon[QuillElementsType.align] = {
  '': customQuillSnowIcons.align,
  center: customQuillSnowIcons.center,
  right: customQuillSnowIcons.right,
  justify: customQuillSnowIcons.justify
};

export interface UnprivilegedEditor {
  getLength(): number;
  getText(index?: number, length?: number): string;
  getHTML(): string;
  getBounds(index: number, length?: number): BoundsStatic;
  getSelection(focus?: boolean): RangeStatic;
  getContents(index?: number, length?: number): DeltaStatic;
}

interface IProps {
  placeholder: string;
  handleChange(content: string, delta: Delta, source: Sources, editor: UnprivilegedEditor): void;
  onFocus?(ref: MutableRefObject<ReactQuill>): void;
  value?: string | Delta;
  classNameContainer?: string;
  showToolbar?: boolean;
  bounds?: string;
  readonly?: boolean;
}

export const BubbleQuillEditor = memo(
  forwardRef<ReactQuill | null, IProps>(
    ({ handleChange, onFocus, value, placeholder, classNameContainer, showToolbar = true, bounds, readonly = false }, reactQuillRef) => {
      const dispatch = useDispatch();

      const ImageHandler = useCallback(() => {
        const input = document.createElement('input');
        input.setAttribute('type', 'file');
        input.setAttribute('accept', 'image/*');
        input.click();

        input.onchange = async () => {
          if (input.files) {
            const file = input.files[0];

            // file type is only image.
            if (/^image\//.test(file.type)) {
              await dispatch(
                fileUploadActionAsync.retrieveUrlAndUploadFile(file, (result) => {
                  const url = imageUrl({
                    bucket_name: result.bucket_name,
                    object_name: result.object_name
                  });
                  insertImageToEditor(reactQuillRef as MutableRefObject<ReactQuill | null>, url);
                })
              );
            } else {
              console.warn('You could only upload images.');
            }
          }
        };
      }, [dispatch, reactQuillRef]);

      const imageHandlerDrop = async (dataUrl: string | ArrayBuffer, type?: string, imageData?: ImageData) => {
        const file = imageData?.toFile();
        if (file && /^image\//.test(file.type)) {
          await dispatch(
            fileUploadActionAsync.retrieveUrlAndUploadFile(file, (result) => {
              const url = imageUrl({
                bucket_name: result.bucket_name,
                object_name: result.object_name
              });
              insertImageToEditor(reactQuillRef as MutableRefObject<ReactQuill | null>, url);
            })
          );
        } else {
          console.warn('You could only upload images.');
        }
      };

      const myUndo = useCallback(() => {
        const quill = (reactQuillRef as MutableRefObject<ReactQuill | null>).current;
        return (quill?.getEditor() as any)?.history.undo();
      }, [reactQuillRef]);

      const myRedo = useCallback(() => {
        const quill = (reactQuillRef as MutableRefObject<ReactQuill | null>).current;
        return (quill?.getEditor() as any)?.history.redo();
      }, [reactQuillRef]);

      const modules = useMemo(
        () => ({
          formula: true,
          toolbar: showToolbar && {
            container: toolbarBubble,
            handlers: {
              image: ImageHandler,
              undo: myUndo,
              redo: myRedo
            }
          },
          imageResize: {
            modules: ['Resize', 'Toolbar']
          },
          imageDropAndPaste: {
            handler: imageHandlerDrop
          }
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
      );

      return (
        <div className={classNames(classNameContainer, 'bubble')}>
          <ReactQuill
            ref={reactQuillRef}
            modules={modules}
            theme={'bubble'}
            className={styles.container}
            value={value}
            onChange={handleChange}
            bounds={bounds}
            placeholder={placeholder}
            onFocus={() => onFocus?.(reactQuillRef as MutableRefObject<ReactQuill>)}
            readOnly={readonly}
          ></ReactQuill>
        </div>
      );
    }
  )
);
