import styles from './product-placeholder.module.scss';
import placeholder from '../../../../../assets/images/product-placeholder.png';

export const ProductPlaceholder = () => {
  return (
    <div className={styles.container}>
      <img src={placeholder} alt={''} />
    </div>
  );
};
