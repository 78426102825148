import { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { Delta, DeltaStatic, Sources } from 'quill';

import classNames from 'classnames';
import styles from './free-answer.module.scss';

import { UnprivilegedEditor } from '../../../../../../../components/quill-editor/BubbleQuillEditor';
import { SimpleQuillEditor } from '../../../../../../../components/quill-editor/simple-quill-editor/SimpleQuillEditor';
import { AnswerTypeEnum, IQuizQuestion } from '../../../../../../../interfaces/quiz';
import { useQuizPayload } from '../../quiz-hooks/useQuizPayload';
import { quizActionAsync } from '../../store/QuizActionAsync';
import { useViewQuizContext } from '../../ViewQuizContext';
import { AnswerInfo } from '../answer-info/AnswerInfo';
import { QuizAnswerBtn } from '../quiz-answer-btn/QuizAnswerBtn';

interface IProps {
  questionData: IQuizQuestion;
}

interface IFormInputs {
  text: string | DeltaStatic;
}

export const FreeAnswer = ({ questionData }: IProps) => {
  const dispatch = useDispatch();
  const {
    formState: { isDirty },
    watch,
    handleSubmit,
    setValue
  } = useForm<IFormInputs>();
  const { question } = useQuizPayload<AnswerTypeEnum.text_input>(questionData);

  useEffect(() => {
    setValue('text', question.choices || '');
  }, [question.choices, setValue]);

  const { blockId, courseId, isPreview, onGetQuiz, onCompleteQuizInPreview } = useViewQuizContext();

  const onSave = useCallback(
    (data: IFormInputs) => {
      if (question.id && blockId && !isPreview) {
        dispatch(quizActionAsync.completeQuiz(courseId, blockId, question.id, 'quiz.answer', data.text, onGetQuiz));
      } else if (isPreview) {
        onCompleteQuizInPreview();
      }
    },
    [blockId, courseId, dispatch, isPreview, onCompleteQuizInPreview, onGetQuiz, question.id]
  );

  const changeHandler = useCallback(
    (content: string, delta: Delta, source: Sources, editor: UnprivilegedEditor) => {
      setValue('text', editor.getContents(), { shouldDirty: true });
    },
    [setValue]
  );

  return (
    <div className={styles.container}>
      <SimpleQuillEditor
        value={watch('text')}
        onChange={changeHandler}
        options={{
          className: styles.text,
          placeholder: 'Type something...',
          readOnly: !!question.is_passed_with,
          fixedToolbar: true
        }}
      />
      <div className={classNames('row no-gutters', styles.actions)}>
        {question.is_passed_with !== null && (
          <div className={'col'}>
            <AnswerInfo type={question.choices ? (question.is_passed_with ? 'success' : 'wrong') : 'waiting'} />
          </div>
        )}
        <div className={'col'}>
          <QuizAnswerBtn
            isDisable={!isDirty || watch('text') === null}
            hasChoices={!!question.choices}
            onSaveAnswerHandler={handleSubmit(onSave)}
            type={'save'}
          />
        </div>
      </div>
    </div>
  );
};
