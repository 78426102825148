import styles from './quiz-to-moderation.module.scss';
import toModeration from '../../../../../../../../assets/images/toModeration.png';

export const ResultQuizToModeration = () => {
  return (
    <>
      <img className={styles.icon} alt={'to moderation'} src={toModeration} />
      <div className={styles.title}>The results will be known after moderation</div>
      <div className={styles.description}>
        The course administrator will mark the quiz as passed or failed after checking the answers
      </div>
    </>
  );
};
