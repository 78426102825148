import { memo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import styles from './student-delete.module.scss';
import { Icon32UserDeclined } from '../../../../../components/UI/icons';

import { Button, CheckButton, ModalActionComponent } from '../../../../../components/UI';

interface IProps {
  name: string | undefined;
  isShow: boolean;

  onClose(): void;

  onDelete(deleteContact: boolean): void;
}

export const StudentDelete = memo(({ name, isShow, onDelete, onClose }: IProps) => {
  const methods = useForm<{ deleteContact: boolean }>({
    defaultValues: { deleteContact: false }
  });

  const onSubmit = (data: { deleteContact: boolean }) => {
    onDelete(data.deleteContact);
  };

  return (
    <ModalActionComponent
      iconType={'custom'}
      show={isShow}
      title={
        <>
          Student <span>{name}</span> will be deleted
        </>
      }
      onCloseModal={onClose}
      iconStyles={styles.icon}
      icon={<Icon32UserDeclined />}
    >
      <div className={styles.description}>
        This student will no longer have access to the courses in which he <br /> is enrolled. All data from analytics
        will also be deleted along with all information about the student.
      </div>
      <FormProvider {...methods}>
        <CheckButton
          name={'deleteContact'}
          type={'checkbox'}
          value={undefined}
          params={{
            label: 'Also delete from contacts',
            className: 'checkbox-button ml-6 mb-3'
          }}
        />
      </FormProvider>
      <div className={styles.buttons}>
        <Button onClick={onClose} btnStyle={'transparent'} withIcon={false} className={'mr-2'}>
          Cancel
        </Button>
        <Button onClick={methods.handleSubmit(onSubmit)} type={'delete'} btnStyle={'warning'}>
          Delete
        </Button>
      </div>
    </ModalActionComponent>
  );
});
