import React, { ReactComponentElement } from 'react';

import { ReactComponent as ActionsCourseEnroll } from '../../../assets/illustrations/automations/actions/actions-course-enroll.svg';
import { ReactComponent as ActionsZapier } from '../../../assets/illustrations/automations/actions/actions-zapier.svg';
import { ReactComponent as ActionsContactField } from '../../../assets/illustrations/automations/actions/illustrations-automations-actions-change-contact-field.svg';
import { ReactComponent as ActionsList } from '../../../assets/illustrations/automations/actions/illustrations-automations-actions-list.svg';
import { ReactComponent as ActionsRequests } from '../../../assets/illustrations/automations/actions/illustrations-automations-actions-post-get-request.svg';
import { ReactComponent as ActionsAnotherAutomation } from '../../../assets/illustrations/automations/actions/illustrations-automations-actions-run-another-automation.svg';
import { ReactComponent as ActionsSendEmail } from '../../../assets/illustrations/automations/actions/illustrations-automations-actions-send-e-mail.svg';
import { ReactComponent as ActionsTag } from '../../../assets/illustrations/automations/actions/illustrations-automations-actions-tag-action.svg';
import { ReactComponent as EventsCourseEnrollment } from '../../../assets/illustrations/automations/events/events-course-enrollment.svg';
import { ReactComponent as EventsOfferPurchased } from '../../../assets/illustrations/automations/events/events-offer-purchased.svg';
import { ReactComponent as EventsZapier } from '../../../assets/illustrations/automations/events/events-zapier.svg';
import { ReactComponent as EventsApiRequestEventThumb } from '../../../assets/illustrations/automations/events/illustrations-automations-events-api-request-event.svg';
import { ReactComponent as EventsCustomFieldThumb } from '../../../assets/illustrations/automations/events/illustrations-automations-events-custom-field.svg';
import { ReactComponent as EventsListsThumb } from '../../../assets/illustrations/automations/events/illustrations-automations-events-lists.svg';
import { ReactComponent as EventsOpenEmailThumb } from '../../../assets/illustrations/automations/events/illustrations-automations-events-open-e-mail.svg';
import { ReactComponent as EventsPageVisitedThumb } from '../../../assets/illustrations/automations/events/illustrations-automations-events-page-visited.svg';
import { ReactComponent as EventsPaymentsThumb } from '../../../assets/illustrations/automations/events/illustrations-automations-events-payments.svg';
import { ReactComponent as EventsTagThumb } from '../../../assets/illustrations/automations/events/illustrations-automations-events-tag.svg';
import { ReactComponent as ProcessorABTest } from '../../../assets/illustrations/automations/processors/illustrations-automations-processors-ab-test.svg';
import { ReactComponent as ProcessorCondition } from '../../../assets/illustrations/automations/processors/illustrations-automations-processors-condition.svg';
import { ReactComponent as ProcessorFilter } from '../../../assets/illustrations/automations/processors/illustrations-automations-processors-filter.svg';
import { ReactComponent as ProcessorRange } from '../../../assets/illustrations/automations/processors/illustrations-automations-processors-range.svg';
import { ReactComponent as ProcessorStop } from '../../../assets/illustrations/automations/processors/illustrations-automations-processors-stop-automation.svg';
import { ReactComponent as ActionsAbTestThumb } from '../../../assets/illustrations/funnels/actions/illustrations-funnel-actions-ab-test.svg';
import { ReactComponent as ActionsAutomationThumb } from '../../../assets/illustrations/funnels/actions/illustrations-funnel-actions-automation.svg';
import { ReactComponent as ActionsSendEmailThumb } from '../../../assets/illustrations/funnels/actions/illustrations-funnel-actions-send-email.svg';
import {
  ReactComponent as ActionsWaitThumb,
  ReactComponent as ProcessorDelay
} from '../../../assets/illustrations/funnels/actions/illustrations-funnel-actions-wait.svg';
import { ReactComponent as PagesCalendarThumb } from '../../../assets/illustrations/funnels/pages/illustrations-funnel-pages-calendar.svg';
import { ReactComponent as PagesCheckoutThumb } from '../../../assets/illustrations/funnels/pages/illustrations-funnel-pages-checkout.svg';
import { ReactComponent as PagesConfirmationPageThumb } from '../../../assets/illustrations/funnels/pages/illustrations-funnel-pages-confirmation-page.svg';
import { ReactComponent as PagesCrossSellThumb } from '../../../assets/illustrations/funnels/pages/illustrations-funnel-pages-cross-sell.svg';
import { ReactComponent as PagesDoubleOptInThumb } from '../../../assets/illustrations/funnels/pages/illustrations-funnel-pages-double-opt-in.svg';
import { ReactComponent as PagesDownSellThumb } from '../../../assets/illustrations/funnels/pages/illustrations-funnel-pages-down-sell.svg';
import { ReactComponent as PagesDownloadThumb } from '../../../assets/illustrations/funnels/pages/illustrations-funnel-pages-download.svg';
import { ReactComponent as PagesExternalThumb } from '../../../assets/illustrations/funnels/pages/illustrations-funnel-pages-external.svg';
import { ReactComponent as PagesLandingPageThumb } from '../../../assets/illustrations/funnels/pages/illustrations-funnel-pages-landing-page.svg';
import { ReactComponent as PagesMembersAreaThumb } from '../../../assets/illustrations/funnels/pages/illustrations-funnel-pages-members-area.svg';
import { ReactComponent as PagesOptInThumb } from '../../../assets/illustrations/funnels/pages/illustrations-funnel-pages-opt-in.svg';
import { ReactComponent as PagesPopUpThumb } from '../../../assets/illustrations/funnels/pages/illustrations-funnel-pages-pop-up.svg';
import { ReactComponent as PagesProductPageThumb } from '../../../assets/illustrations/funnels/pages/illustrations-funnel-pages-product-page.svg';
import { ReactComponent as PagesSalesThumb } from '../../../assets/illustrations/funnels/pages/illustrations-funnel-pages-sales-page.svg';
import { ReactComponent as PagesSalesVideoThumb } from '../../../assets/illustrations/funnels/pages/illustrations-funnel-pages-sales-video.svg';
import { ReactComponent as PagesSurveyThumb } from '../../../assets/illustrations/funnels/pages/illustrations-funnel-pages-survey.svg';
import { ReactComponent as PagesUpSellThumb } from '../../../assets/illustrations/funnels/pages/illustrations-funnel-pages-up-sell.svg';
import { ReactComponent as PagesWebinarThumb } from '../../../assets/illustrations/funnels/pages/illustrations-funnel-pages-webinar.svg';
import { ReactComponent as PagesWebinarReplayThumb } from '../../../assets/illustrations/funnels/pages/illustrations-funnel-pages-webinar-replay.svg';
import { ReactComponent as TrafficAdwordsThumb } from '../../../assets/illustrations/funnels/traffic/illustrations-funnel-traffic-adwords.svg';
import { ReactComponent as TrafficChatBotThumb } from '../../../assets/illustrations/funnels/traffic/illustrations-funnel-traffic-chat-bot.svg';
import { ReactComponent as TrafficEmailThumb } from '../../../assets/illustrations/funnels/traffic/illustrations-funnel-traffic-email.svg';
import { ReactComponent as TrafficFacebookThumb } from '../../../assets/illustrations/funnels/traffic/illustrations-funnel-traffic-facebook.svg';
import { ReactComponent as TrafficInstagramThumb } from '../../../assets/illustrations/funnels/traffic/illustrations-funnel-traffic-instagram.svg';
import { ReactComponent as TrafficInstagramAdsThumb } from '../../../assets/illustrations/funnels/traffic/illustrations-funnel-traffic-instagram-ads.svg';
import { ReactComponent as TrafficMessengerThumb } from '../../../assets/illustrations/funnels/traffic/illustrations-funnel-traffic-messenger.svg';
import { ReactComponent as TrafficOtherThumb } from '../../../assets/illustrations/funnels/traffic/illustrations-funnel-traffic-other.svg';
import { ReactComponent as TrafficRetargetingThumb } from '../../../assets/illustrations/funnels/traffic/illustrations-funnel-traffic-retargeting.svg';
import { ReactComponent as TrafficTelegramThumb } from '../../../assets/illustrations/funnels/traffic/illustrations-funnel-traffic-telegram.svg';
import { ReactComponent as TrafficViberThumb } from '../../../assets/illustrations/funnels/traffic/illustrations-funnel-traffic-viber.svg';
import { ReactComponent as TrafficWhatsappThumb } from '../../../assets/illustrations/funnels/traffic/illustrations-funnel-traffic-whatsapp.svg';
import { ReactComponent as TrafficYoutubeThumb } from '../../../assets/illustrations/funnels/traffic/illustrations-funnel-traffic-youtube.svg';

import { PaletteAutomationElementTypes, PaletteElementTypes, PaletteThumbType } from '../../interfaces';
import { BuilderType } from '../../modules/common/builder/store/BuilderActionAsync';

interface ElementThumbProps {
  name: string;
  type?: PaletteThumbType;
  builderType: BuilderType;
  size?: [width: number, height: number];
}

export const ElementThumb = ({ name, type, builderType, size }: ElementThumbProps) => {
  const foundCategory = name && name.match(/(\w*)\./);
  const category = foundCategory && foundCategory[1];
  let SVGProps: React.SVGProps<SVGSVGElement> = {};
  if (type === 'thumb') {
    SVGProps = {
      width: 64,
      height: 96
    };
    if (category !== 'pages') {
      SVGProps.height = 64;
    }
    if (['action', 'processor', 'condition'].includes(category || '')) {
      SVGProps.height = 48;
      SVGProps.width = 48;
    }
  }

  if (size) {
    const [width, height] = size;
    SVGProps = {
      width,
      height
    };
  }

  const automationTemplates: Record<string, ReactComponentElement<any>> = {
    [PaletteAutomationElementTypes['ACTION_TAG']]: <ActionsTag {...SVGProps} />,
    [PaletteAutomationElementTypes['ACTION_SEND_EMAIL']]: <ActionsSendEmail {...SVGProps} />,
    [PaletteAutomationElementTypes['ACTION_REQUEST']]: <ActionsRequests {...SVGProps} />,
    [PaletteAutomationElementTypes['ACTION_CHANGE_CONTACT_FIELD']]: <ActionsContactField {...SVGProps} />,
    [PaletteAutomationElementTypes['ACTION_RUN_ANOTHER']]: <ActionsAnotherAutomation {...SVGProps} />,
    [PaletteAutomationElementTypes['ACTION_LIST']]: <ActionsList {...SVGProps} />,
    [PaletteAutomationElementTypes['ACTION_COURSE_ENROLL']]: <ActionsCourseEnroll {...SVGProps} />,
    [PaletteAutomationElementTypes['ACTION_ZAPIER']]: <ActionsZapier {...SVGProps} />,

    [PaletteAutomationElementTypes['PROCESSOR_CONDITION']]: <ProcessorCondition {...SVGProps} />,
    [PaletteAutomationElementTypes['PROCESSOR_ABTEST']]: <ProcessorABTest {...SVGProps} />,
    [PaletteAutomationElementTypes['PROCESSOR_DELAY']]: <ProcessorDelay {...SVGProps} />,
    [PaletteAutomationElementTypes['PROCESSOR_FILTER']]: <ProcessorFilter {...SVGProps} />,
    [PaletteAutomationElementTypes['PROCESSOR_RANGE']]: <ProcessorRange {...SVGProps} />,
    [PaletteAutomationElementTypes['PROCESSOR_STOP']]: <ProcessorStop {...SVGProps} />,

    [PaletteAutomationElementTypes['EVENT_TAG_MODIFIED']]: <EventsTagThumb {...SVGProps} />,
    [PaletteAutomationElementTypes['EVENT_PAYMENTS']]: <EventsPaymentsThumb {...SVGProps} />,
    [PaletteAutomationElementTypes['EVENT_CUSTOM_FIELD']]: <EventsCustomFieldThumb {...SVGProps} />,
    [PaletteAutomationElementTypes['EVENT_OPEN_EMAIL']]: <EventsOpenEmailThumb {...SVGProps} />,
    [PaletteAutomationElementTypes['EVENT_API_REQUEST_EVENT']]: <EventsApiRequestEventThumb {...SVGProps} />,
    [PaletteAutomationElementTypes['EVENT_LIST_MODIFIED']]: <EventsListsThumb {...SVGProps} />,
    [PaletteAutomationElementTypes['EVENT_PAGE_VISITED']]: <EventsPageVisitedThumb {...SVGProps} />,

    [PaletteAutomationElementTypes['EVENT_COURSE_ENROLMENT']]: <EventsCourseEnrollment {...SVGProps} />,
    [PaletteAutomationElementTypes['EVENT_OFFER_PURCHASED']]: <EventsOfferPurchased {...SVGProps} />,
    [PaletteAutomationElementTypes['EVENT_ZAPIER_TRIGGER_AUTOMATION']]: <EventsZapier {...SVGProps} />,
  };

  const templates: Record<string, ReactComponentElement<any>> = {
    [PaletteElementTypes['PAGES_CALENDAR']]: <PagesCalendarThumb {...SVGProps} />,
    [PaletteElementTypes['PAGES_CHECKOUT']]: <PagesCheckoutThumb {...SVGProps} />,
    [PaletteElementTypes['PAGES_CONFIRMATION']]: <PagesConfirmationPageThumb {...SVGProps} />,
    [PaletteElementTypes['PAGES_CROSS_SELL']]: <PagesCrossSellThumb {...SVGProps} />,
    [PaletteElementTypes['PAGES_DOUBLE_OPT_IN']]: <PagesDoubleOptInThumb {...SVGProps} />,
    [PaletteElementTypes['PAGES_DOWN_SELL']]: <PagesDownSellThumb {...SVGProps} />,
    [PaletteElementTypes['PAGES_DOWNLOAD']]: <PagesDownloadThumb {...SVGProps} />,
    [PaletteElementTypes['PAGES_MEMBERS_AREA']]: <PagesMembersAreaThumb {...SVGProps} />,
    [PaletteElementTypes['PAGES_OPT_IN']]: <PagesOptInThumb {...SVGProps} />,
    [PaletteElementTypes['PAGES_EXTERNAL']]: <PagesExternalThumb {...SVGProps} />,
    [PaletteElementTypes['PAGES_POP_UP']]: <PagesPopUpThumb {...SVGProps} />,
    [PaletteElementTypes['PAGES_PRODUCT']]: <PagesProductPageThumb {...SVGProps} />,
    [PaletteElementTypes['PAGES_SALES']]: <PagesSalesThumb {...SVGProps} />,
    [PaletteElementTypes['PAGES_SALES_VIDEO']]: <PagesSalesVideoThumb {...SVGProps} />,
    [PaletteElementTypes['PAGES_SURVEY']]: <PagesSurveyThumb {...SVGProps} />,
    [PaletteElementTypes['PAGES_UP_SELL']]: <PagesUpSellThumb {...SVGProps} />,
    [PaletteElementTypes['PAGES_WEBINAR']]: <PagesWebinarThumb {...SVGProps} />,
    [PaletteElementTypes['PAGES_WEBINAR_REPLY']]: <PagesWebinarReplayThumb {...SVGProps} />,
    [PaletteElementTypes['PAGES_LANDING']]: <PagesLandingPageThumb {...SVGProps} />,

    [PaletteElementTypes['ACTION_AB']]: <ActionsAbTestThumb {...SVGProps} />,
    [PaletteElementTypes['ACTION_EMAIL']]: <ActionsSendEmailThumb {...SVGProps} />,
    [PaletteElementTypes['ACTION_DELAY']]: <ActionsWaitThumb {...SVGProps} />,
    [PaletteElementTypes['ACTION_AUTOMATION']]: <ActionsAutomationThumb {...SVGProps} />,

    [PaletteElementTypes['TRAFFIC_SOURCE_ADWORDS']]: <TrafficAdwordsThumb {...SVGProps} />,
    [PaletteElementTypes['TRAFFIC_SOURCE_TWITTER']]: <TrafficChatBotThumb {...SVGProps} />,
    [PaletteElementTypes['TRAFFIC_SOURCE_EMAIL']]: <TrafficEmailThumb {...SVGProps} />,
    [PaletteElementTypes['TRAFFIC_SOURCE_FACEBOOK']]: <TrafficFacebookThumb {...SVGProps} />,
    [PaletteElementTypes['TRAFFIC_SOURCE_INSTAGRAM']]: <TrafficInstagramThumb {...SVGProps} />,
    [PaletteElementTypes['TRAFFIC_SOURCE_INSTAGRAM_AD']]: <TrafficInstagramAdsThumb {...SVGProps} />,
    [PaletteElementTypes['TRAFFIC_SOURCE_MESSENGER']]: <TrafficMessengerThumb {...SVGProps} />,
    [PaletteElementTypes['TRAFFIC_SOURCE_GENERIC']]: <TrafficOtherThumb {...SVGProps} />,
    [PaletteElementTypes['TRAFFIC_SOURCE_RETARGETING']]: <TrafficRetargetingThumb {...SVGProps} />,
    [PaletteElementTypes['TRAFFIC_SOURCE_TELEGRAM']]: <TrafficTelegramThumb {...SVGProps} />,
    [PaletteElementTypes['TRAFFIC_SOURCE_VIBER']]: <TrafficViberThumb {...SVGProps} />,
    [PaletteElementTypes['TRAFFIC_SOURCE_WHATSAPP']]: <TrafficWhatsappThumb {...SVGProps} />,
    [PaletteElementTypes['TRAFFIC_SOURCE_YOUTUBE']]: <TrafficYoutubeThumb {...SVGProps} />
  };
  if (
    builderType === 'funnel' ? Object.keys(templates).includes(name) : Object.keys(automationTemplates).includes(name)
  ) {
    return builderType === 'funnel' ? templates[name] : automationTemplates[name];
  }
  return null;
};
