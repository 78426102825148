import { IRoutes } from '../../../../interfaces';
import { CourseHeaderPanel } from '../../../../layout/header/courseHeaderPanel/CourseHeaderPanel';
import { PreviewCourse } from '../PreviewCourse';

export const previewPaths = {
  previewLessons: (id = ':id', nodeId = '') => `/preview/courses/${id}/curriculum/${nodeId}`
};

export const PreviewRoutes: IRoutes[] = [
  {
    path: previewPaths.previewLessons(),
    main: () => <PreviewCourse />,
    header: () => <CourseHeaderPanel />
  }
];
