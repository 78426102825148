import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import axios from 'axios';
import { fileUrl } from 'src/app/components/file-upload/file-helper';
import { TypeOfUploader } from 'src/app/interfaces/fileUpload';

import styles from './upload-item.module.scss';

import { checkVideoTranscoding, MediaProvider } from '../../../../API/video-api';
import { useFileUpload } from '../../../../components/file-upload/audio-uploader/use-file-upload';
import { ToastMessage } from '../../../../components/UI/toast/ToastMessage';
import { useInterval } from '../../../../hooks/use-interval';
import { useVimeoUpload } from '../../../../hooks/use-video-upload';
import { BlockType } from '../../../../interfaces';
import { IVimeoVideo } from '../../../../interfaces/vimeo';
import { courseActionAsync } from '../../../../modules/lms/courses/store/CourseActionAsync';
import { CourseSelector } from '../../../../modules/lms/courses/store/CourseSelector';
import { fileUploaderActions } from '../store/FileUploaderActions';
import { IFileUploader } from '../store/FileUploaderState';
import { IconFile } from './icon-file/IconFile';
import { StatusLoaderFile } from './status-loader-file/StatusLoaderFile';

interface IProps {
  file: IFileUploader;
  onCancel: (file: IFileUploader) => void;
}

export const UploadItem = memo(({ file, onCancel }: IProps) => {
  const { handleSetUpload, handleAbort } = useVimeoUpload(file);
  const { onCancelUpload: onCancelUploadFile, uploadFileByPresignedUrl } = useFileUpload(
    file.courseId,
    file.parentBlockId
  );
  const [hover, setHover] = useState(false);
  const onMouseEnterHandler = () => {
    setHover(true);
  };
  const onMouseLeaveHandler = () => {
    setHover(false);
  };

  const dispatch = useDispatch();
  const courseMemo = useMemo(() => CourseSelector(file.courseId), [file.courseId]);
  const { course } = useSelector(courseMemo);

  const onCancelUpload = useCallback(() => {
    onCancel(file);
  }, [onCancel, file]);

  useEffect(() => {
    if (file.uploaderData?.status === 'canceled' && file.file.type.includes('video')) {
      onCancel(file);
      handleAbort();
    }
    if (file.uploaderData?.status === 'canceled' && !file.file.type.includes('video')) {
      onCancelUploadFile(file);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file.uploaderData?.status]);

  const setAndStartVimeoUpload = (vimeoData: IVimeoVideo) => {
    const uploadURI = vimeoData.upload.upload_link;
    handleSetUpload(uploadURI, vimeoData.link);
  };

  const onUploadAudioCallback = async () => {
    const indexStart = file.uploaderData.id.indexOf('/audio/') + 7;
    const indexEnd = file.uploaderData.id.indexOf('.mp3') + 4;
    const audioLink = fileUrl({
      bucket_name: 'audio',
      object_name: file.uploaderData.id.slice(indexStart, indexEnd)
    });

    dispatch(
      courseActionAsync.getCourseBlock(file.courseId, file.parentBlockId, (blocks) => {
        dispatch(
          courseActionAsync.cudCourseBlock(
            file.courseId,
            file.parentBlockId,
            {
              add: [],
              delete: [],
              edit: [
                {
                  id: blocks.find((block) => block.type === BlockType.AUDIO)?.id,
                  type: BlockType.AUDIO,
                  title: '',
                  audio: {
                    type: TypeOfUploader.upload,
                    audio_url: audioLink
                  },
                  order: 0
                }
              ]
            },
            () => dispatch(courseActionAsync.getCourseBlock(file.courseId, file.parentBlockId))
          )
        );
      })
    );
  };

  useEffect(() => {
    const getFileUrlAndCreateLessonIfNeed = async () => {
      if (file.uploaderData.provider === MediaProvider.VIMEO && file.vimeoData) {
        setAndStartVimeoUpload(file.vimeoData);
      }
      if (file.uploaderData.provider === MediaProvider.FILE && file.isBulk) {
        uploadFileByPresignedUrl(file.file, file.uploaderData.id, file.uploaderData.id, onUploadAudioCallback);
      }
    };
    getFileUrlAndCreateLessonIfNeed();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkStatusVideo = async (videoId: string) => {
    try {
      const result = await checkVideoTranscoding(videoId);
      dispatch(fileUploaderActions.setTranscodingStatus({ file: file.file, status: result }));
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error?.response?.status === 404) {
          toast.error(<ToastMessage message={'Video is not exist'} />, { autoClose: 5000 });
        } else {
          toast.error(<ToastMessage message={error?.response?.statusText || 'Something error'} />);
        }
      }
      Promise.reject(error);
    }
  };

  useInterval(() => {
    if (file.uploaderData.status === 'complete' && file.transcodingStatus?.state !== 'ready') {
      const vimeoId = file.uploaderData.id.split('/').slice(-1)[0];
      checkStatusVideo(vimeoId);
    }
  }, 5 * 1000);

  return (
    <div className={styles.container} onMouseEnter={onMouseEnterHandler} onMouseLeave={onMouseLeaveHandler}>
      <IconFile uploadStatus={file.uploaderData.status} fileType={file.file.type} />
      <div className={styles.titleContainer}>
        <div className={styles.fileName}>{file.file.name}</div>
        <div className={styles.title}>{course?.title}</div>
      </div>
      <div className={styles.loaderContainer}>
        <div className={styles.loader}>
          <StatusLoaderFile
            hover={hover}
            onCancelHandler={onCancelUpload}
            uploadStatus={file.uploaderData.status}
            processingStatus={file.transcodingStatus}
            progress={file.progress}
          />
        </div>
      </div>
    </div>
  );
});
