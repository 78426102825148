import { BlockType, CommonCourseBlock, CourseBlockOperation, CourseNodeType, ICourseNodePayload } from '../interfaces';
import {
  CourseState,
  CourseTemplatesType,
  CurriculumState,
  ICourse,
  ICourseNode,
  ICourseSettings,
  ILecture,
  IRequestCohort,
  NodeCondition,
  StudentLessonErrorStatus
} from '../modules/lms/courses/store/CourseState';
import { baseFetch } from './BaseFetch';

export const getCourses = async () => {
  return await baseFetch<{}, ICourse[]>('/courses', {}, 'GET', true);
};

export const editCourse = async (courseId: string, title: string) => {
  return await baseFetch<{ title: string }, void>(`/courses/${courseId}`, { title }, 'PUT', true);
};

export const getCourseHierarchy = async (course_id: string) => {
  return await baseFetch<{}, ICourseNode[]>(`/courses/${course_id}/hierarchy`, {}, 'GET', true);
};

export const createCourse = async (template: CourseTemplatesType) => {
  return await baseFetch<{}, ICourse>('/courses', { template }, 'POST', true);
};

export const createNode = async (
  course_id: string,
  type: CourseNodeType,
  parent_id: string | null,
  title?: string,
  embed?: `${BlockType}`
) => {
  return await baseFetch<{}, ICourseNode>(
    `/courses/${course_id}/nodes`,
    { type, parent_id, title, embed },
    'POST',
    true
  );
};

export const editNode = async (course_id: string, nodeId: string, data: ICourseNodePayload) => {
  return await baseFetch<ICourseNodePayload, Omit<ICourseNode, 'children'>>(
    `/courses/${course_id}/nodes/${nodeId}`,
    data,
    'PUT',
    true
  );
};

export const setNodeCondition = async (course_id: string, nodeId: string, condition: NodeCondition) => {
  return await baseFetch<{ condition: NodeCondition }, Omit<ICourseNode, 'children'>>(
    `/courses/${course_id}/nodes/${nodeId}`,
    { condition: condition },
    'PUT',
    true
  );
};

export const editStateNode = async (
  courseId: string,
  nodeId: string,
  state: CurriculumState,
  modifyChildren: boolean
) => {
  return await baseFetch<{ state: CurriculumState; modify_children: boolean }, ICourseNode>(
    `/courses/${courseId}/nodes/${nodeId}/state`,
    { state, modify_children: modifyChildren },
    'PUT',
    true
  );
};

export const duplicateNode = async (courseId: string, nodeId: string) => {
  return await baseFetch<{}, void>(`/courses/${courseId}/nodes/${nodeId}/duplicate`, {}, 'POST', true);
};

export const getNode = async (course_id: string, nodeId: string) => {
  return await baseFetch<{}, ICourseNode>(`/courses/${course_id}/nodes/${nodeId}`, {}, 'GET', true);
};

export const deleteNode = async (courseId: string, nodeId: string) => {
  return await baseFetch<{}, void>(`/courses/${courseId}/nodes/${nodeId}`, {}, 'DELETE', true);
};

export const courseBlockGet = async (courseId: string, steId: string) => {
  return await baseFetch<{}, CommonCourseBlock[] | ILecture[]>(`/courses/${courseId}/steps/${steId}`, {}, 'GET', true);
};

export const courseBlockAdd = async (
  courseId: string,
  steId: string,
  payload: Record<CourseBlockOperation, Partial<CommonCourseBlock>[]>
) => {
  return await baseFetch<Record<CourseBlockOperation, Partial<CommonCourseBlock>[]>, void>(
    `/courses/${courseId}/steps/${steId}`,
    payload,
    'PUT',
    true
  );
};

export const editCourseSettings = async (courseId: string, settings: Partial<ICourseSettings>) => {
  return await baseFetch<Partial<ICourseSettings>, void>(`/courses/${courseId}/settings`, { ...settings }, 'PUT', true);
};

export const updateCourseStatus = async (courseId: string, state: CourseState) => {
  return await baseFetch<{}, ICourse>(`/courses/${courseId}/${state}`, {}, 'PUT', true);
};

export const getPreviewListStepBlocks = async (courseId: string, stepId: string) => {
  return await baseFetch<{}, CommonCourseBlock[], StudentLessonErrorStatus>(
    `/courses/${courseId}/steps/${stepId}`,
    {},
    'GET',
    true
  );
};

export const createCohort = async (data: IRequestCohort) => {
  return await baseFetch<{}, ICourse>('/cohort', data, 'POST', true);
};
