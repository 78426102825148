import { PaymentMethod } from '@stripe/stripe-js';
import querystring from 'query-string';

import {
  ICoupon,
  IPaginationParams,
  IPayment,
  IPaymentCard,
  IReason,
  ISubscription,
  Plans,
  SubscriptionStatusResponse
} from '../interfaces';
import { IPaymentHistory } from '../modules/settings/store/SettingCourseState';
import { baseFetch } from './BaseFetch';

export const paymentMethod = async (returnUrl?: string) => {
  return await baseFetch<{ return_url?: string }, { url: string }>(
    '/billing/session',
    { return_url: returnUrl },
    'POST',
    true
  );
};

export const getPaymentCards = async () => {
  return await baseFetch<{}, IPaymentCard[]>('/billing/sources', {}, 'GET', true);
};

export const removePaymentCard = async (id: string) => {
  return await baseFetch<{ source: string }, void>('/billing/sources', { source: id }, 'DELETE', true);
};

export const setDefaultCard = async (id: string | PaymentMethod) => {
  return await baseFetch<{ source: string | PaymentMethod; set_as_default: boolean }, void>(
    '/billing/sources',
    {
      source: id,
      set_as_default: true
    },
    'PUT',
    true
  );
};

export const getClientSecret = async () => {
  return await baseFetch<{}, { client_secret: string }>('/billing/sources', {}, 'POST', true);
};

export const getPaymentHistory = async () => {
  return await baseFetch<{}, IPaymentHistory>('/billing/history', {}, 'GET', true);
};

export const getSubscriptionStatus = async () => {
  return await baseFetch<{}, ISubscription, SubscriptionStatusResponse>('/billing/subscription', {}, 'GET', true);
};

export const updateSubscription = async (plan: Plans) => {
  return await baseFetch<{ plan: Plans }, ISubscription>('/billing/subscription', { plan }, 'PUT', true);
};

export const createSubscription = async (plan: Plans) => {
  return await baseFetch<{ plan: Plans }, ISubscription>('/billing/subscription', { plan }, 'POST', true);
};

export const subscriptionReason = async (reason: IReason) => {
  return await baseFetch<IReason, string>('/billing/subscription/reason', reason, 'POST', true);
};

export const cancelSubscription = async (at_period_end: boolean) => {
  return await baseFetch<{ at_period_end: boolean }, ISubscription>(
    '/billing/subscription',
    { at_period_end },
    'DELETE',
    true
  );
};

export const applySubscriptionDiscount = async (coupon: string) => {
  return await baseFetch<{ coupon: string }, ISubscription>('/billing/subscription/discount', { coupon }, 'POST', true);
};

export const getCoupons = async () => {
  return await baseFetch<{}, ICoupon>('/billing/coupons', {}, 'GET', true);
};

export const getTransactions = async (pagination?: IPaginationParams) => {
  const queryParams = querystring.stringifyUrl({
    url: '/transactions',
    query: {
      page: pagination?.page,
      page_size: pagination?.page_size
    }
  });
  return await baseFetch<{}, IPayment[]>(queryParams, {}, 'GET', true);
};
