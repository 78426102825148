import { createSelector } from 'reselect';

import { IAppState } from '../../../../store';

export const AccountSelector = () =>
  createSelector(
    (state: IAppState) => state.account.account.organizations,
    (organizations) => {
      return {
        organizationId: organizations.find((x) => x.role === 'owner')?.organization_id,
        organizations
      };
    }
  );
