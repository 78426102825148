import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Search } from '../../components/search/Search';
import { settingsActions } from '../../store/settings';

interface IProps {
  placeholder?: string;
  isPage?: boolean;
  className?: string;
}

export const SearchWrapper = ({ placeholder = 'Search', isPage, className }: IProps) => {
  const [showClear, setShowClear] = useState(false);
  const [value, setValue] = useState('');
  const dispatch = useDispatch();

  const onChange = (e: React.FormEvent<HTMLInputElement>) => {
    setShowClear(e.currentTarget.value.trim() !== '');
    setValue(e.currentTarget.value);
    dispatch(settingsActions.commonSearch({ searchString: e.currentTarget.value }));
  };

  const onClear = useCallback(() => {
    setValue('');
    setShowClear(false);
    dispatch(settingsActions.commonSearch({ searchString: '' }));
  }, [dispatch]);

  useEffect(() => {
    onClear();
  }, [onClear]);

  return (
    <>
      <Search
        onChange={onChange}
        onClear={onClear}
        value={value}
        showClear={showClear}
        isPage={isPage}
        className={className}
        placeholder={placeholder}
      />
    </>
  );
};
