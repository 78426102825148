import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import classNames from 'classnames';

import { CustomTooltip } from '../../../../components/UI';
import { CategoryNameType } from '../../../../interfaces';
import { funnelStates } from '../../../funnels/funnels/funnel-item/FunnelItem';
import { FunnelSelector } from '../../../funnels/funnels/store/FunnelSelector';

interface IProps {
  id: string;
  showStatus: boolean;
  paletteNavItems: { category: CategoryNameType; icon: JSX.Element }[][];
  onClick: OmitThisParameter<(category: CategoryNameType, event: any) => void>;
}

export const PaletteNav = ({ onClick, paletteNavItems, showStatus, id }: IProps) => {
  const [active, setActive] = useState<Record<string, boolean>>({});
  const [disabled, setDisabled] = useState(false);
  const funnel = useSelector((state) => FunnelSelector(state, id));

  useEffect(() => {
    if (funnel && funnelStates[funnel.state].action === 'publish') {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [funnel]);

  const getPaletteItem = (item: { category: CategoryNameType; icon: JSX.Element }, index: number) => {
    return (
      <div className="palette-nav-item" key={index}>
        {item.category === 'pages' ||
        item.category === 'actions' ||
        item.category === 'traffic_source' ||
        // item.category === 'info' ||
        item.category === 'processors' ? (
          <button
            type="button"
            className={classNames('btn p-0 icon', {
              active: active ? showStatus && active[item.category] : false
            })}
            onClick={(e) => {
              setActive(() => ({ [item.category]: true }));
              onClick(item.category, e);
            }}
          >
            {item.icon}
          </button>
        ) : (
          <CustomTooltip direction="top">
            <button type="button" className="btn p-0 icon">
              {item.icon}
            </button>
          </CustomTooltip>
        )}
      </div>
    );
  };

  return (
    <div className={classNames('palette', { disabled: disabled })}>
      {paletteNavItems.map((navItems, index) => {
        return (
          <div className="palette-nav mb-3" key={index}>
            {navItems.map((item, index) => {
              return getPaletteItem(item, index);
            })}
          </div>
        );
      })}
    </div>
  );
};
