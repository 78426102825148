import { memo } from 'react';
import { useEffect } from 'react';
import { useCountdown } from 'usehooks-ts';

import classNames from 'classnames';
import styles from './audio-recorder.module.scss';
import { Icon32MultimediaStopSolid } from '../../UI/icons';

interface IProps {
  removeRecord: () => void;
  isRecording: boolean;
  stop: () => void;
  start: () => void;
  className?: string;
}

export const AudioRecorder = memo(({ removeRecord, start, stop, isRecording, className }: IProps) => {
  const [count, { startCountdown, stopCountdown }] = useCountdown({
    countStart: 1200,
    intervalMs: 1000
  });

  useEffect(() => {
    if (count === 0) {
      stop();
    }
  }, [count, stop]);

  const onToggleRecord = () => {
    if (isRecording) {
      stop();
      stopCountdown();
    } else {
      removeRecord();
      start();
      startCountdown();
    }
  };

  const minutes = Math.floor(count / 60);
  const seconds = count - minutes * 60;

  return (
    <div className={classNames(styles.container, { [styles.active]: isRecording }, className)}>
      <div className={styles.btnContainer} onClick={onToggleRecord}>
        <div className={classNames({ [styles.stop]: isRecording }, { [styles.play]: !isRecording })}>
          {isRecording && <Icon32MultimediaStopSolid className={styles.iconStop} />}
        </div>
        {isRecording ? (
          <div className={styles.stopText}>{isRecording && `Remaining time — ${minutes}m ${seconds}s`}</div>
        ) : (
          <div className={styles.playText}>Start recording</div>
        )}
      </div>
    </div>
  );
});
