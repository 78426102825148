import { createContext, useContext } from 'react';

import {
  IAutomationNodeBuilder,
  IFunnelNodeBuilder,
  PaletteAutomationElementTypes,
  PaletteElementTypes
} from '../../../interfaces';

export type BuilderContentType = {
  selectedNode: IAutomationNodeBuilder<PaletteAutomationElementTypes> | IFunnelNodeBuilder<PaletteElementTypes> | null;
  updateNode?: (
    node: IAutomationNodeBuilder<PaletteAutomationElementTypes> | IFunnelNodeBuilder<PaletteElementTypes>
  ) => void;
};
export const BuilderContext = createContext<BuilderContentType>({
  selectedNode: null
});
export const useBuilderContext = () => useContext(BuilderContext);
