import React, { memo, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import classNames from 'classnames';
import styles from './create-course-modal.module.scss';
import { ReactComponent as Simple } from '../../../../../../assets/icons/create-course/1pic_simple.svg';
import { ReactComponent as Advanced } from '../../../../../../assets/icons/create-course/2pic_advanced.svg';
import { ReactComponent as Coaching } from '../../../../../../assets/icons/create-course/3pic_coaching.svg';
import { ReactComponent as Drip } from '../../../../../../assets/icons/create-course/4pic_drip.svg';
import { ReactComponent as Cohort } from '../../../../../../assets/icons/create-course/5pic_cohort.svg';
import { ReactComponent as Custom } from '../../../../../../assets/icons/create-course/6pix_custom.svg';
import { IconQuestion } from '../../../../../components/UI/icons';

import { CommonModal } from '../../../../../components/UI';
import { coursePaths } from '../../routes/CourseRoutes';
import { courseActionAsync } from '../../store/CourseActionAsync';
import { CourseTemplatesType } from '../../store/CourseState';

interface IProps {
  show: boolean;

  onClose(): void;

  onCreateCohort(): void;
}

const templates = [
  { id: 'simple', title: 'Simple', text: 'One-level course with 7 lessons', icon: <Simple />, help: true },
  {
    id: 'advanced',
    title: 'Advanced',
    text: 'Lessons combined with sessions and topics',
    icon: <Advanced />,
    help: true
  },
  { id: 'coaching', title: 'Coaching', text: 'Predefined course with 7 lessons', icon: <Coaching />, help: true },
  { id: 'drip', title: 'Drip', text: '7 lessons with gradual access over time', icon: <Drip />, help: true },
  { id: 'cohort', title: 'Cohort', text: 'Designed to increase the level of knowledge', icon: <Cohort />, help: true },
  { id: 'custom', title: 'Custom', text: 'Blank course for self-define structure', icon: <Custom />, help: true }
];

export const CreateCourseModal = memo(({ show, onClose, onCreateCohort }: IProps) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const onCourseCreated = useCallback(
    (id: string) => {
      setLoading(false);
      history.push(coursePaths.courseLessons(id));
    },
    [history]
  );

  const createCourseHandler = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      const id = e.currentTarget.id as CourseTemplatesType;
      if (id) {
        if (id === 'cohort') {
          onCreateCohort();
          onClose();
        } else {
          setLoading(true);
          dispatch(courseActionAsync.createCourse(id, onCourseCreated));
        }
      }
    },
    [dispatch, onClose, onCourseCreated, onCreateCohort]
  );

  const onHelpClick = (e: React.SyntheticEvent<HTMLDivElement>) => {
    e.stopPropagation();
  };

  return (
    <CommonModal onClose={onClose} show={show} showClose params={{ className: styles.modal }}>
      <div className={styles.container}>
        <div className={styles.title}>Create new course product</div>
        <div className={styles.description}>Choose one of the suggested templates</div>
        <div className={styles.buttons}>
          {templates.map((button) => (
            <div
              className={classNames(styles.button, { [styles.loading]: loading })}
              onClick={createCourseHandler}
              key={button.id}
              id={button.id}
            >
              <div className={styles.name}>{button.title}</div>
              <div className={styles.text}>{button.text}</div>
              {button.icon}
              {button.help && (
                <div className={styles.help} onClick={onHelpClick}>
                  Help <IconQuestion />
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </CommonModal>
  );
});
