import { FieldEntity, IField } from '../modules/crm/field';
import { IFieldCreateRequest, IFieldEditRequest } from '../modules/crm/field/store/FieldActions';
import { baseFetch } from './BaseFetch';

export const createField = async ({ type, payload, entity }: IFieldCreateRequest) => {
  return await baseFetch<IFieldCreateRequest, IField>('/fields', { type, payload, entity }, 'POST', true);
};

export const editField = async ({ id, payload }: IFieldEditRequest) => {
  return await baseFetch<Pick<IFieldEditRequest, 'payload'>, IField>(`/fields/${id}`, { payload }, 'PUT', true);
};

export const deleteField = async (id: string) => {
  await baseFetch<{}, IField>(`/fields/${id}`, {}, 'DELETE', true);
};

export const reorderField = async (id: string, order: number) => {
  return await baseFetch<{ order: number }, IField>(`/fields/${id}`, { order }, 'PUT', true);
};

export const getFields = async (entity?: FieldEntity) => {
  return await baseFetch<{}, IField[]>('/fields', { entity }, 'GET', true);
};
