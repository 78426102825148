import { memo } from 'react';
import { FormCheck } from 'react-bootstrap';

import { CurriculumState } from '../../../../../../modules/lms/courses/store/CourseState';

interface IProps {
  onChange: (e: boolean) => void;
  state: CurriculumState;
}

export const FolderState = memo(({ onChange, state }: IProps) => {
  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e.currentTarget.value === 'state');
    e.stopPropagation();
  };

  return (
    <>
      <FormCheck custom className={'radio-button py-1'} type={'radio'} key={'state'}>
        <FormCheck.Input
          className="custom-control-input"
          type={'radio'}
          name={'state'}
          id={'state'}
          onChange={onChangeHandler}
          defaultChecked
          value={'state'}
        />
        <FormCheck.Label className="custom-control-label" htmlFor={'state'}>
          {state === 'draft' ? 'Publish all inner folders and lessons' : 'Draft all inner folders and lessons'}
        </FormCheck.Label>
      </FormCheck>
      <FormCheck custom className={'radio-button py-1'} type={'radio'} key={'state_left'}>
        <FormCheck.Input
          className="custom-control-input"
          type={'radio'}
          name={'state'}
          id={'state_left'}
          onChange={onChangeHandler}
          value={'state_left'}
        />
        <FormCheck.Label className="custom-control-label" htmlFor={'state_left'}>
          {state === 'draft'
            ? 'Left the current status of folders and lessons'
            : 'Left the current status of folders and lessons'}
        </FormCheck.Label>
      </FormCheck>
    </>
  );
});
