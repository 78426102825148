import { FC, ReactNode } from 'react';
import { ModalProps } from 'react-bootstrap';

import classNames from 'classnames';
import styles from './modal.module.scss';

import { Icon32ListView, Icon32PencilOnPaper, Icon32Trash, IconWarning } from '../icons';
import { CommonModal } from '../index';

interface IProps {
  show: boolean;
  title: string | ReactNode;
  iconType: 'edit' | 'delete' | 'create' | 'warning' | 'custom';
  icon?: JSX.Element;
  modalParams?: ModalProps;
  iconStyles?: string;
  titleClassName?: string;
  className?: string;
  hideClose?: boolean;
  onCloseModal(): void;
}

export const ModalActionComponent: FC<IProps> = ({
  onCloseModal,
  show,
  title,
  children,
  iconType,
  icon,
  modalParams,
  iconStyles,
  titleClassName,
  className,
  hideClose = false
}) => {
  let titleIcon;
  switch (iconType) {
    case 'edit':
      titleIcon = <Icon32PencilOnPaper className={classNames(styles.icon, iconStyles)} />;
      break;
    case 'delete':
      titleIcon = <Icon32Trash className={classNames(styles.icon, iconStyles)} />;
      break;
    case 'create':
      titleIcon = <Icon32ListView className={classNames(styles.icon, iconStyles)} />;
      break;
    case 'warning':
      titleIcon = <IconWarning className={classNames(styles.icon, iconStyles)} />;
      break;
    case 'custom':
      titleIcon = <div className={classNames(styles.icon, iconStyles)}>{icon}</div>;
      break;
  }

  return (
    <CommonModal onClose={onCloseModal} show={show} params={modalParams} showClose={!hideClose}>
      <div className={classNames(styles.container, className)}>
        <div className={classNames(styles.header, titleClassName)}>
          {titleIcon}
          <div className={styles.title}>{title}</div>
        </div>
        {children}
      </div>
    </CommonModal>
  );
};
