import {
  EdgeRequest,
  GeometryRequest,
  GeometryResponse,
  IAutomationNodeBuilder,
  IEdge,
  NodeTypes,
  PaletteAutomationElementTypes
} from '../interfaces';
import { baseFetch } from './BaseFetch';

export const addAutomationNode = (automationKey: string, type: NodeTypes) => {
  return baseFetch<{}, IAutomationNodeBuilder<PaletteAutomationElementTypes>>(
    `/automations/${automationKey}/nodes`,
    { type },
    'POST',
    true
  );
};

export const getAutomationNode = (automationKey: string, nodeKey: string) => {
  return baseFetch<{}, IAutomationNodeBuilder<PaletteAutomationElementTypes>>(
    `/automations/${automationKey}/nodes/${nodeKey}`,
    {},
    'GET',
    true
  );
};

export const saveAutomationNode = (
  automationKey: string,
  nodeKey: string,
  data: Record<string, string | undefined>
) => {
  return baseFetch<{}, IAutomationNodeBuilder<PaletteAutomationElementTypes>>(
    `/automations/${automationKey}/nodes/${nodeKey}`,
    { ...data },
    'PUT',
    true
  );
};

export const deleteAutomationNode = (automationKey: string, nodeKey: string) => {
  return baseFetch<{}, IAutomationNodeBuilder<PaletteAutomationElementTypes>>(
    `/automations/${automationKey}/nodes/${nodeKey}`,
    {},
    'DELETE',
    true
  );
};

export const saveAutomationGeometry = (automationKey: string, data: GeometryRequest) => {
  return baseFetch<{}, GeometryResponse>(`/automations/${automationKey}/geometry`, { ...data }, 'PUT', true);
};

export const saveAutomationEdge = (automationKey: string, edge: EdgeRequest) => {
  return baseFetch<{}, IEdge>(`/automations/${automationKey}/edges`, { ...edge }, 'POST', true);
};

export const deleteAutomationEdge = (automationKey: string, edgeKey: string) => {
  return baseFetch<{}, IEdge>(`/automations/${automationKey}/edges/${edgeKey}`, {}, 'DELETE', true);
};
