import React, { useState } from 'react';
import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';

import styles from './checkout-form.module.scss';

import { Button, Loader } from '../../../../components/UI';

interface IProps {
  onSuccess(): void;
}

export const CheckoutForm = ({ onSuccess }: IProps) => {
  const stripe = useStripe();
  const elements = useElements();
  const [disabled, setDisabled] = useState(true);
  const [loading, setLoading] = useState(true);

  const [errorMessage, setErrorMessage] = useState(null);

  const handleSubmit = async () => {
    if (!stripe || !elements) {
      return;
    }
    setLoading(true);
    const { error, setupIntent } = await stripe.confirmSetup({
      //`Elements` instance that was used to create the Payment Element
      elements,
      redirect: 'if_required'
    });

    setLoading(false);
    if (error) {
      // @ts-ignore
      setErrorMessage(error.message);
    } else {
      onSuccess();
    }
  };

  const onReady = () => {
    setDisabled(false);
    setLoading(false);
  };

  return (
    <>
      {loading && <Loader />}
      <div className={styles.container}>
        <PaymentElement onReady={onReady} />
        {errorMessage && <div>{errorMessage}</div>}
      </div>
      <Button
        onClick={handleSubmit}
        size={'lg'}
        className={'w-100'}
        withIcon={false}
        btnProps={{ disabled: disabled || !stripe }}
      >
        Save card
      </Button>
    </>
  );
};
