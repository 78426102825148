import { FC, useEffect, useState } from 'react';
import { Collapse, Form } from 'react-bootstrap';
import { FieldValues, useFormContext } from 'react-hook-form';
import { FieldErrors } from 'react-hook-form/dist/types/errors';
import { useDispatch } from 'react-redux';

import classNames from 'classnames';
import './payload-form.scss';
import styles from './payload-form.module.scss';
import { Icon32Cross, IconPlus, IconReload, IconTrash } from '../../../../components/UI/icons';

import { Button, ModalActionComponent } from '../../../../components/UI';
import {
  IAutomationNodeBuilder,
  IFunnelNodeBuilder,
  PaletteAutomationElementTypes,
  PaletteElementTypes
} from '../../../../interfaces';
import { IRangeProcessorAutomationPayload } from '../../../../interfaces/node-builder-payload/automation-processors/range';
import { builderActionAsync, BuilderType } from '../store/BuilderActionAsync';

interface IProps {
  id: string;
  node: IFunnelNodeBuilder<PaletteElementTypes> | IAutomationNodeBuilder<PaletteAutomationElementTypes>;
  builderType: BuilderType;

  onClose(): void;

  initNode(node: IFunnelNodeBuilder<PaletteElementTypes> | IAutomationNodeBuilder<PaletteAutomationElementTypes>): void;

  onNodeUpdate(
    node: IFunnelNodeBuilder<PaletteElementTypes> | IAutomationNodeBuilder<PaletteAutomationElementTypes>
  ): void;
}

export const PayloadForm: FC<IProps> = ({ id, node, builderType, onClose, onNodeUpdate, initNode, children }) => {
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors }
  } = useFormContext<FieldValues & IRangeProcessorAutomationPayload>();
  const [showModal, setShowModal] = useState(false);
  const [open, setOpen] = useState(!!node.payload.note);
  const dispatch = useDispatch();

  useEffect(() => {
    setValue('title', node.payload.title);
    setValue('note', node.payload.note);
  }, [node.payload.note, node.payload.title, setValue]);

  const onGetNodeCallback = (
    node: IFunnelNodeBuilder<PaletteElementTypes> | IAutomationNodeBuilder<PaletteAutomationElementTypes>
  ) => {
    onNodeUpdate(node);
  };
  const onSaveNodeCallback = () => {
    onClose();
    dispatch(builderActionAsync.getNode(id, node.id, builderType, onGetNodeCallback));
  };

  const onSaveHandler = (data: Record<string, any>) => {
    dispatch(builderActionAsync.saveNode(id, node.id, data, builderType, onSaveNodeCallback));
  };

  const onError = (errors: FieldErrors) => {
    console.log('%c⇒ error', 'color: #FF5370', errors);
  };

  const toggleNotes = () => {
    setOpen((open) => !open);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const onClosePayload = () => {
    try {
      reset();
    } catch (e) {
      console.log('%c⇒ e', 'color: #89DDF7', e);
    }
    onClose();
    initNode(node);
  };

  const deleteNotes = () => {
    setValue('note', '');
  };

  return (
    <>
      <div className="payload">
        <div className={styles.form}>
          <div className={styles.header}>
            <Form.Group className={'mb-2'}>
              <Form.Label>Name</Form.Label>
              <Icon32Cross className={styles.iconClose} onClick={onClosePayload} />
              <Form.Control placeholder={'Insert a differentiating title'} {...register('title')} size={'sm'} />
            </Form.Group>
            <Form.Group className={'mb-0'}>
              <Form.Label
                onClick={toggleNotes}
                aria-expanded={open}
                className={classNames('text-primary', styles.notes, open ? 'mb-1' : 'mb-0')}
              >
                {open ? <IconTrash /> : <IconPlus />}
                <span className={'ml-1'}>Notes</span>
              </Form.Label>
              <Collapse in={open} onExited={deleteNotes}>
                <Form.Control
                  as="textarea"
                  rows={4}
                  placeholder={'Describe what’s important about this element'}
                  {...register('note')}
                  size={'sm'}
                />
              </Collapse>
            </Form.Group>
          </div>
          <div className={styles.body}>{children}</div>
        </div>
        <div className={classNames(styles.actionButtons, 'action-buttons')}>
          <button type="button" className="btn btn-sm mr-3 btn-gradient-secondary" onClick={onClosePayload}>
            Cancel
          </button>
          {/* TODO: pass to another component */}
          <button
            type="button"
            className={'btn btn-sm btn-gradient-primary'}
            onClick={handleSubmit(onSaveHandler, onError)}
            disabled={
              (errors?.range as any)?.intervals &&
              Array.isArray((errors?.range as any)?.intervals) &&
              (errors?.range as any)?.intervals?.length > 0
            }
          >
            Update settings
          </button>
        </div>
      </div>
      <ModalActionComponent
        onCloseModal={closeModal}
        show={showModal}
        iconType={'warning'}
        title={'Closing the window will discard all changes'}
        iconStyles={styles.iconModal}
      >
        <div className={styles.description}>This item will be kept in its previous state before you edited it.</div>
        <div className={styles.btnContainer}>
          <button className={styles.btnSave} onClick={handleSubmit(onSaveHandler)}>
            Save changes
          </button>
          <Button onClick={onClosePayload} withIcon={false}>
            <>
              Discard
              <IconReload />
            </>
          </Button>
        </div>
      </ModalActionComponent>
    </>
  );
};
