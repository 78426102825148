import { memo } from 'react';

import { ReactComponent as IconMasterCard } from '../../../../../assets/icons/4-icon-payment-mastercard.svg';
import { ReactComponent as IconVisa } from '../../../../../assets/icons/4-icon-payment-visa.svg';

import { ISubscription } from '../../../../interfaces';

export const CardIcon = memo(({ subscription_payment_source }: Pick<ISubscription, 'subscription_payment_source'>) => {
  return (
    <>
      {subscription_payment_source?.brand === 'visa' && <IconVisa />}
      {subscription_payment_source?.brand === 'mastercard' && <IconMasterCard />}
    </>
  );
});
