import {
  ColumnDescription,
  ColumnFormatter,
  ColumnSortCaret,
  SelectRowProps,
  SortOrder
} from 'react-bootstrap-table-next';

import styles from '../list-lists.module.scss';
import { Icon16ArrowDown, Icon16ArrowUp } from '../../../../../components/UI/icons';

import { IList } from '../../store/ListsState';

export type RowType = Record<string, any> & {
  id: string;
  listName: string;
  createdOn: string;
  numberOfContacts: number;
  data: IList;
};

export type FormatterType<T> = ColumnFormatter<T, any, string>;

export const sortCaret: ColumnSortCaret = (order) => {
  if (!order) {
    return null;
  } else if (order === 'asc') {
    return (
      <span className={'sorting'}>
        <Icon16ArrowDown />
      </span>
    );
  } else if (order === 'desc') {
    return (
      <span className={'sorting'}>
        <Icon16ArrowUp width={16} height={16} />
      </span>
    );
  }
  return null;
};

export const defaultSorted: [{ dataField: any; order: SortOrder }] = [
  {
    dataField: 'numberOfContacts',
    order: 'desc'
  }
];

export const columnsData: ColumnDescription[] = [
  {
    dataField: 'listName',
    text: 'List Name',
    classes: styles.listName
  },
  {
    dataField: 'createdOn',
    text: 'Created on',
    searchable: false
  },
  {
    dataField: 'numberOfContacts',
    text: 'Number of contacts',
    searchable: false
  }
];

export const selectRow: SelectRowProps<any> = {
  mode: 'radio',
  hideSelectColumn: true,
  clickToSelect: true,
  classes: styles.selectedRow
};
