import { reducerWithInitialState } from 'typescript-fsa-reducers';

import { newState } from '../../../store';
import { NavigationActions } from './NavigationActions';
import { INavigationState, NavigationInitialState } from './NavigationState';

export const navigationReducer = reducerWithInitialState<INavigationState>(NavigationInitialState)
  .case(NavigationActions.setLastNode, (state, { courseId, lastNode }) => {
    return newState(state, {
      storedCourses: { ...state.storedCourses, [courseId]: { lastNode } }
    });
  })
  .case(NavigationActions.deleteLastNode, (state, { courseId }) => {
    const updatedCourses = { ...state.storedCourses };
    if (updatedCourses) {
      delete updatedCourses[courseId];
    }
    return newState(state, {
      storedCourses: updatedCourses
    });
  });
