import { useHistory } from 'react-router';

import styles from './unavailable.module.scss';

import { Button, ModalActionComponent } from '../../../../../components/UI';
import { StudentCabinetPaths } from '../../../../student-cabinet/routes/StudentCabinetRoutes';

export const UnavailableCourse = () => {
  const history = useHistory();
  const onRedirect = () => {
    history.push(StudentCabinetPaths.courses);
  };

  return (
    <ModalActionComponent
      iconType={'warning'}
      show
      title={'The course is not available'}
      onCloseModal={() => {}}
      iconStyles={styles.icon}
      className={styles.hideClose}
    >
      <div className={styles.description}>
        You cannot view the content of this course as it is a private course. Click the button to close this page.
      </div>
      <div className={styles.btnContainer}>
        <Button onClick={onRedirect} withIcon={false}>
          Back to courses
        </Button>
      </div>
    </ModalActionComponent>
  );
};
