import { createSelector } from 'reselect';

import { IPolicy, PolicyDefinedLinkEnum } from '../../../../interfaces/policy';
import { IAppState } from '../../../../store';

export interface IPoliciesSelector {
  terms?: IPolicy;
  privacy?: IPolicy;
  custom?: IPolicy[];
  policyById?: IPolicy;
}

export const PoliciesSelector = () =>
  createSelector(
    (_: IAppState, policyId?: string | undefined) => policyId,
    (state: IAppState) => state.policy.policyList,
    (policyId, policyList): IPoliciesSelector => {
      const terms = policyList.find((x) => x.document_type === 'terms_of_service');
      const privacy = policyList.find((x) => x.document_type === 'privacy_policy');
      const custom = policyList.filter((x) => x.document_type === 'custom');
      const policyById =
        policyId && !([PolicyDefinedLinkEnum.privacy, PolicyDefinedLinkEnum.terms] as string[]).includes(policyId)
          ? policyList.find((x) => x.id === policyId)
          : undefined;
      return {
        terms,
        privacy,
        custom,
        policyById
      };
    }
  );
