import { CommonCourseBlock, IGeneralSettings } from '../../../interfaces';
import { LoadState } from '../../../store';
import { ICourse, ILecture } from '../../lms/courses/store/CourseState';

export type StatusCompleteType = 'complete';
export interface INodeStatusComplete {
  node_id: string;
  action: StatusCompleteType;
}

export interface IEnrollment {
  progress: number | null;
  course_id: string;
  created_at: number;
}

export interface IStudentCabinet {
  organizations: { id: string; settings: IGeneralSettings }[];
  courses: ICourse[];
  enrolment: IEnrollment[];
  payload: CommonCourseBlock[];
  lectures: ILecture[];
}

export interface ICourseComplete {
  total_lessons: number;
  passed_lessons: number;
  last_lesson: {
    title: string;
    id: string;
  } | null;
  progress: number;
  courseId?: string;
}

export interface IStudentCabinetState {
  studentCourses: IStudentCabinet;
  statusCourse: ICourseComplete;
  cabinetSettings: IGeneralSettings | null;
  loadState: LoadState;
  loadCabinetSettingsState: LoadState;
  error: Error | null;
}

export const StudentCabinetInitialState: IStudentCabinetState = {
  error: null,
  loadState: LoadState.firstLoad,
  loadCabinetSettingsState: LoadState.firstLoad,
  cabinetSettings: null,
  statusCourse: {
    last_lesson: null,
    total_lessons: 0,
    passed_lessons: 0,
    progress: 0
  },
  studentCourses: {
    courses: [],
    enrolment: [],
    organizations: [],
    payload: [],
    lectures: []
  }
};
