import {
  PaymentExistingAccount,
  PaymentLink,
  PaynetAccount,
  PaypalAccount,
  PublishableKey,
  StripeAccount
} from '../modules/settings/general/store/GeneralSettingsState';
import { baseFetch } from './BaseFetch';

export const getZapierKey = async () => {
  return await baseFetch<{}, { api_key: string }>('/integrations/zapier', {}, 'GET', true);
};

export const getStripeAccount = async () => {
  return await baseFetch<{}, StripeAccount>('/integrations/stripe', {}, 'GET', true);
};

export const getPaypalAccount = async () => {
  return await baseFetch<{}, PaypalAccount>('/integrations/paypal', {}, 'GET', true);
};

export const getPaynetAccount = async () => {
  return await baseFetch<{}, PaynetAccount>('/integrations/paynet', {}, 'GET', true);
};

export const getStripeLink = async (stripeLink: PaymentLink) => {
  return await baseFetch<PaymentLink, { link: string }>('/integrations/stripe', stripeLink, 'POST', true);
};

export const getPaypalLink = async (paypalLink: Pick<PaymentLink, 'return_url'>) => {
  return await baseFetch<Pick<PaymentLink, 'return_url'>, { link: string }>(
    '/integrations/paypal/init',
    paypalLink,
    'GET',
    true
  );
};

export const addExistingAccountStripe = async (stripeParams: PaymentExistingAccount) => {
  return await baseFetch<PaymentExistingAccount, void>('/integrations/stripe/oauth', stripeParams, 'POST', true);
};
export const addPaypalAccount = async (paypal: PaypalAccount) => {
  return await baseFetch<{ merchant_id: string }, void>('/integrations/paypal', paypal, 'POST', true);
};

export const addPaynetAccount = async (paynetParams: Pick<PaynetAccount, 'username' | 'password' | 'merchant_id'>) => {
  return await baseFetch<Pick<PaynetAccount, 'username' | 'password' | 'merchant_id'>, void>(
    '/integrations/paynet',
    paynetParams,
    'POST',
    true
  );
};

export const detachStripeAccount = async () => {
  return await baseFetch<{}, void>('/integrations/stripe', {}, 'DELETE', true);
};
export const detachPaynetAccount = async () => {
  return await baseFetch<{}, void>('/integrations/paynet', {}, 'DELETE', true);
};
export const detachPaypalAccount = async () => {
  return await baseFetch<{}, void>('/integrations/paypal', {}, 'DELETE', true);
};

export const getStripePublishableKey = async () => {
  return await baseFetch<{}, PublishableKey>('/integrations/stripe/secret', {}, 'GET', false, {}, null);
};
