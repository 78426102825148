import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { Loader } from '../../../../components/UI';
import { LoadState } from '../../../../store';
import { StudentCabinetPaths } from '../../../student-cabinet/routes/StudentCabinetRoutes';
import { StudentCabinetActionAsync } from '../../../student-cabinet/store/StudentCabinetActionAsync';
import { ICourseNode } from '../../courses/store/CourseState';
import { EmptyCourse } from '../common/empty-course/EmptyCourse';
import { StudentCourseSelector } from '../store/StudentCourseSelector';
import { StudentLectureNavigation, StudentLessonSelector } from '../store/StudentLessonSelector';
import { ViewCourse } from './ViewCourse';

export const StudentCourse = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { authToken } = useSelector((state) => state.auth);
  const path = useRouteMatch<{ id: string; nodeId?: string }>([
    StudentCabinetPaths.courseStudentLessons(':id', ':nodeId'),
    StudentCabinetPaths.courseStudentLessons()
  ]);
  const courseId = useMemo(() => path?.params.id, [path?.params.id]);
  const nodeId = useMemo(() => path?.params.nodeId, [path?.params.nodeId]);
  const nodeMemo = useMemo(() => StudentLessonSelector(courseId, nodeId), [courseId, nodeId]);
  const flatLessonMemo = useMemo(() => StudentLectureNavigation(courseId, nodeId), [courseId, nodeId]);
  const flatLessons = useSelector(flatLessonMemo);
  const courseMemo = useMemo(() => StudentCourseSelector(courseId), [courseId]);
  const { course, loadState } = useSelector(courseMemo);
  const [currentHierarchy, setCurrentHierarchy] = useState<ICourseNode[]>([]);
  const { studentCourses, cabinetSettings } = useSelector((state) => state.studentCourses);
  const courseSettings = studentCourses.organizations?.find((x) => x.id === course?.organization_id)?.settings;

  const [loadingHierarchy, setLoadingHierarchy] = useState(true);
  const [loadingCourses, setLoadingCourses] = useState(false);

  const courseUnavailableHandler = useCallback(() => {
    history.push(StudentCabinetPaths.unavailableCourse(courseId));
  }, [courseId, history]);

  const getHierarchyCallback = useCallback(
    (courseHierarchy: ICourseNode[]) => {
      setCurrentHierarchy(courseHierarchy);
      setLoadingHierarchy(false);

      if (Array.isArray(courseHierarchy) && courseHierarchy[0]?.id && courseId) {
        const getCourseStatusOpened = sessionStorage.getItem(courseId);
        if (getCourseStatusOpened !== 'opened') {
          dispatch(
            StudentCabinetActionAsync.setCourseOpened(
              courseId,
              courseHierarchy[0]?.id,
              {
                action: 'node.opened'
              },
              () => {
                sessionStorage.setItem(courseId, 'opened');
              }
            )
          );
        }
      }
    },
    [courseId, dispatch]
  );

  const getHierarchy = useCallback(() => {
    if (courseId) {
      dispatch(StudentCabinetActionAsync.getStudentCourseHierarchy(courseId, getHierarchyCallback));
    }
  }, [courseId, dispatch, getHierarchyCallback]);

  const onRedirectLessonHandler = useCallback(
    (nodeId?: string) => {
      if (course?.id) {
        history.push(StudentCabinetPaths.courseStudentLessons(course.id, nodeId));
      }
    },
    [course?.id, history]
  );

  useEffect(() => {
    if (!course && cabinetSettings?.id && authToken && loadState !== LoadState.error) {
      setLoadingCourses(true);
      dispatch(
        StudentCabinetActionAsync.getStudentCabinetCourses(
          cabinetSettings.id,
          courseId,
          courseUnavailableHandler,
          () => {
            setLoadingCourses(false);
          }
        )
      );
    }
  }, [authToken, cabinetSettings?.id, course, courseId, courseUnavailableHandler, dispatch, loadState]);

  useEffect(() => {
    if (course) {
      getHierarchy();
    }
  }, [course, getHierarchy]);

  return (
    <>
      {loadingHierarchy || loadingCourses ? (
        <Loader />
      ) : course && nodeMemo && flatLessons.all.length > 0 && currentHierarchy.length > 0 ? (
        <ViewCourse
          general_image={courseSettings?.general_image}
          general_title={courseSettings?.general_title}
          theme_logo={courseSettings?.theme_logo}
          course={course}
          flatLessons={flatLessons}
          nodeId={nodeId}
          currentHierarchy={currentHierarchy}
          lastCompletionLesson={flatLessons.all.find((x) => x.id === course?.last_lesson?.id)}
          onRedirectLessonHandler={onRedirectLessonHandler}
          setCurrentHierarchy={setCurrentHierarchy}
          getHierarchy={getHierarchy}
        />
      ) : (
        <EmptyCourse title={course?.title} />
      )}
    </>
  );
};
