import { SyntheticEvent } from 'react';

import classNames from 'classnames';
import styles from './publish-course-modal.module.scss';
import { Icon32Megaphone } from '../../../../../components/UI/icons';

import { Button, ModalActionComponent } from '../../../../../components/UI';
import { ThumbnailPlaceholderImage } from '../../../../../components/UI/image-components/thumbnail-placeholder-image/ThumbnailPlaceholderImage';
import { ICourse } from '../../store/CourseState';

interface IProps {
  course: ICourse;
  showPublishModal: boolean;

  onClosePublishModal(): void;

  statusChangeHandler(event: SyntheticEvent<HTMLButtonElement>): void;
}

export const PublishCourseModal = ({ course, onClosePublishModal, showPublishModal, statusChangeHandler }: IProps) => {
  return (
    <ModalActionComponent
      show={showPublishModal}
      title={'Publish course'}
      onCloseModal={onClosePublishModal}
      icon={<Icon32Megaphone />}
      iconType={'custom'}
      titleClassName={'pt-0'}
      modalParams={{ dialogClassName: styles.modal }}
    >
      <div className={styles.container}>
        <div className={'d-flex align-items-center mb-3'}>
          <ThumbnailPlaceholderImage
            width={96}
            height={54}
            thumbnailCourse={course.settings.thumbnail}
            className={styles.thumbnail}
          />
          <div className={classNames(styles.title, 'ellipsis')}>{course.title}</div>
        </div>
        <div className={styles.description}>
          This course will be published after your confirmation and will be available for watching by invitation or
          shared link.
        </div>
        <div className={styles.buttons}>
          <Button onClick={onClosePublishModal} btnStyle={'transparent'} size={'lg'} withIcon={false}>
            Cancel
          </Button>
          <Button onClick={statusChangeHandler} withIcon={false} size={'lg'} btnProps={{ id: 'published' }}>
            Confirm and publish
          </Button>
        </div>
      </div>
    </ModalActionComponent>
  );
};
