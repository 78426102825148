import { IDashboard, IStatusDashboard } from '../modules/dashboard/store/DashboardState';
import { baseFetch } from './BaseFetch';

export const getDashboard = async () => {
  return await baseFetch<{}, IDashboard>('/dashboard', {}, 'GET', true);
};

export const setDashboardEvent = async (event: IStatusDashboard) => {
  return await baseFetch<{ event: IStatusDashboard }, void>('/events', { event }, 'POST', true);
};
