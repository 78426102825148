import { IconPaynet, IconPayPal, IconStripe } from '../../../../../components/UI/icons/payment';

import { PaymentMethod } from '../../../../../interfaces';

interface IProps {
  paymentMethod: PaymentMethod;
}

export const PaymentIcon = ({ paymentMethod }: IProps) => {
  switch (paymentMethod) {
    case 'paynet':
      return <IconPaynet />;
    case 'stripe':
      return <IconStripe />;
    case 'paypal':
      return <IconPayPal />;
    case 'free':
      break;
  }
  return null;
};
