import { IRoutes } from '../../../interfaces';
import { BuilderHeaderPanel } from '../../../layout/header/funnelHeaderPanel/BuilderHeaderPanel';
import { FunnelHeaderSearch } from '../../../layout/header/funnelHeaderPanel/FunnelHeaderSearch';
import { Funnels } from '../../common/coming-soon/Funnels';
import { FunnelBuilder } from '../funnel-builder/FunnelBuilder';
import { FunnelList } from '../funnels/FunnelList';

export const funnelPaths = {
  funnelList: '/funnels',
  funnelId: (id = ':id') => `/funnels/${id}`,
  thumbnails: '/thumbnails',
  thumbnailId: (id = ':id') => `/thumbnails/${id}`
};

export const FunnelRoutes: IRoutes[] = [
  {
    path: funnelPaths.funnelList,
    exact: true,
    header: () => <FunnelHeaderSearch />,
    main: () => (
      <div className={'content'}>
        {process.env.REACT_APP_API_BASE_URL === 'https://api.dev.wisent.work' ? <FunnelList /> : <Funnels />}
        {/* <FunnelList /> */}
      </div>
    )
  },
  {
    path: funnelPaths.funnelId(),
    header: () => <BuilderHeaderPanel route={funnelPaths.funnelId()} name={'funnel'} />,
    main: () => (
      <div className={'content p-0'}>
        <FunnelBuilder />
      </div>
    )
  }
];
