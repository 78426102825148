import './style.scss';

import { Icon32Cross } from '../icons';

interface IProps {
  percent: number;
  onCancel?: () => void;
}

export const PercentLoader = ({ percent, onCancel }: IProps) => {
  return (
    <div className="set-size">
      <div className="pie-wrapper progress">
        <div
          className="pie"
          style={{
            clip: percent > 50 ? 'rect(auto, auto, auto, auto)' : 'rect(0, 1em, 1em, 0.5em)'
          }}
        >
          <div className="half-circle" style={{ transform: `rotate(${percent * 3.6}deg)` }}></div>
          <div
            className={'half-circle'}
            style={{
              display: percent <= 50 ? 'none' : 'flex',
              transform: percent > 50 ? 'rotate(180deg)' : 'unset'
            }}
          ></div>
        </div>
        <div className="shadow">
          {onCancel ? (
            <div onClick={onCancel} className={'cancelContainer'}>
              <Icon32Cross className={'cancel'} />
            </div>
          ) : (
            <div className={'percent'}>{percent}%</div>
          )}
        </div>
      </div>
    </div>
  );
};
