import { IGeneralSettings } from '../interfaces';
import { baseFetch } from './BaseFetch';

export const editSettings = async (params: Partial<IGeneralSettings>) => {
  return await baseFetch<Partial<IGeneralSettings>, IGeneralSettings>('/settings', params, 'PUT', true);
};

export const getSettings = async () => {
  return await baseFetch<{}, IGeneralSettings>('/settings', {}, 'GET', true);
};
