import { baseFetch } from './BaseFetch';

export type ActionStatusResponse =
  | 'success.redirect_to_course'
  | 'success.redirect_to_organization'
  | 'success.authenticate_user'
  | 'error.authentication_required'
  | 'error.email_doesnt_match'
  | 'error.action_hash_not_found'
  | 'error.action_already_used'
  | 'error.confirmation_code_doesnt_match'
  | 'success.user_confirmation_done'
  | 'error.new_password_missed'
  | 'success.password_change_done';

export enum EmailActionStatusEnum {
  enroll = 'enroll',
  invite = 'invite',
  redirect = 'redirect',
  email_confirmation = 'email_confirmation',
  password_recovery = 'password_recovery'
}

export type EmailActionStatus = `${EmailActionStatusEnum}`;

export type EmailAction =
  | {
      status: 'error.email_doesnt_match';
      payload: {
        email: string;
        message: string;
        user_exists: boolean;
      };
    }
  | {
      status: 'success.redirect_to_course';
      payload: { course_id: string };
    }
  | {
      status: 'success.authenticate_user';
      payload: {
        token: string;
        course_id?: string;
      };
    }
  | {
      status: 'success.user_confirmation_done';
      payload: {
        token: string;
      };
    }
  | {
      status: 'error.action_already_used';
      payload: null;
    }
  | {
      status: 'error.authentication_required';
      payload: {
        email: string;
      };
    }
  | {
      status: Exclude<
        ActionStatusResponse,
        | 'error.email_doesnt_match'
        | 'success.redirect_to_course'
        | 'success.user_confirmation_done'
        | 'success.authenticate_user'
        | 'error.action_already_used'
        | 'error.authentication_required'
      >;
      payload: {};
    };

export const actionEmail = async (actionHash: string, code?: Record<string, string>) => {
  return await baseFetch<{}, EmailAction>(`/action/${actionHash}`, code ? code : {}, 'GET');
};
