import styles from './warning-message.module.scss';
import { IconWarning } from '../../../../../components/UI/icons';

export const WarningMessage = () => {
  return (
    <div className={styles.container}>
      <IconWarning className={'flex-shrink-0 icon'} />
      Email messages sent from a custom sender may be received in spam or other incorrect mail client tabs. We recommend
      that you use the provided sender as it ensures proper placement in the Inbox tab.
    </div>
  );
};
