import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IImage } from '@demonvv/everact-widget';

import cn from 'classnames';
import styles from './user-impersonation.module.scss';
import { IconArrowLeft, IconArrowRight } from '../../../components/UI/icons';

import { getMe, getUsers } from '../../../API/account-api';
import { Image } from '../../../components/UI/image-components/image/Image';
import { IAccount, IAccountOrganization } from '../../../interfaces';
import { AuthActions } from '../../../modules/auth/store/AuthActions';
import { AccountActionAsync } from '../../../modules/settings/account/store/AccountActionAsync';
import { ImpersonationActionsAsync } from './store/ImpersonationActionsAsync';

const UserImpersonation = () => {
  const dispatch = useDispatch();
  const { authToken } = useSelector((state) => state.auth);
  const { account } = useSelector((state) => state.account);
  const { payload } = useSelector((state) => state.impersonation);
  const [hidden, setHidden] = useState(true);
  const [isAdmin, setIsAdmin] = useState(false);
  const [userSearch, setUserSearch] = useState<string>('');
  const [users, setUsers] = useState<IAccount[]>([]);
  const [filteredUsers, setFilteredUsers] = useState<IAccount[]>([]);

  const impersonateUser = async (id: string) => {
    await dispatch(
      ImpersonationActionsAsync.setPayload({
        rootToken: payload?.rootToken || (authToken as string),
        rootUserId: payload?.rootUserId || account.id,
        userId: id
      })
    );
    await dispatch(AccountActionAsync.getMe());
    window?.location?.reload();
  };

  const depersonateUser = async () => {
    if (payload?.rootToken) {
      await dispatch(ImpersonationActionsAsync.reset(payload?.rootToken));
      await dispatch(AccountActionAsync.getMe());
      window?.location?.reload();
    }
  };

  useEffect(() => {
    // if (payload?.rootToken || account.is_admin) {
    setIsAdmin(Boolean(account?.is_admin));
    if (authToken && account?.is_admin) {
      setIsAdmin(true);
      getUsers(authToken).then(({ data }) => {
        if (data) {
          setUsers(data);
          setFilteredUsers(data);
        }
      });
    }
    // }

    if (payload?.rootToken) {
      getMe(payload?.rootToken).then((me) => {
        setIsAdmin(Boolean(me.data?.is_admin));
      });
    }
  }, [payload?.rootToken, account.is_admin, authToken]);

  useEffect(() => {
    const org = account.organizations.find((o) => o.role === 'owner') as IAccountOrganization;
    if (org) {
      dispatch(AuthActions.setCurrentOrganization(org));
    }
  }, [dispatch, account.organizations]);

  useEffect(() => {
    setFilteredUsers(
      users.filter((user) =>
        new RegExp(userSearch, 'i').test(
          user.id + user.profile.email + user.profile.last_name + user.profile.first_name
        )
      )
    );
  }, [userSearch, users]);

  if (!isAdmin) {
    return <></>;
  }

  return (
    <div
      onClick={() => hidden && setHidden(false)}
      role={hidden ? 'button' : 'alertdialog'}
      className={styles.impersonationButton}
    >
      <div className="d-flex align-items-center cursor-pointer">
        {hidden ? (
          <IconArrowLeft className={'h-2 icon mr-2'} />
        ) : (
          <IconArrowRight className={'icon mr-2'} onClick={() => setHidden(true)} />
        )}
        {!hidden && (
          <input
            className={styles.searchInput}
            placeholder="Search user..."
            type="text"
            onChange={(event) => setUserSearch(event.target.value)}
          />
        )}
        {!hidden && payload?.userId && (
          <button className={'ml-4 btn-outline'} onClick={() => depersonateUser()}>
            back to me
          </button>
        )}
      </div>
      {!hidden && (
        <div className={styles.usersContainer}>
          {filteredUsers.map((user) => (
            <ImpersonationTenant
              selectUser={(id) => impersonateUser(id)}
              selected={payload?.userId === user.id}
              key={user.id}
              user={user}
            />
          ))}
        </div>
      )}

      {!hidden && !filteredUsers.length && <p className="m-0 mt-3 text-align-center">No users found...</p>}
    </div>
  );
};

const ImpersonationTenant: React.FC<{ user: IAccount; selected: boolean; selectUser: (id: string) => void }> = ({
  user,
  selected,
  selectUser
}) => {
  return (
    <div
      className={cn(styles.userListItem, { [styles.userSelectedListItem]: selected })}
      onClick={() => selectUser(user.id)}
    >
      <div className={'d-flex'}>
        <Image
          imgProps={{ height: 100, width: 100 }}
          size={{ height: 100, width: 100 }}
          bucket_name={(user?.profile?.photo as IImage)?.bucket_name}
          object_name={(user?.profile?.photo as IImage)?.object_name}
          className={styles.avatar}
        />

        <div className={'ml-2'}>
          <p className="m-0">
            <strong>id: </strong>
            {user.id}
          </p>
          <p className="m-0">
            <strong>email: </strong>
            {user.profile.email}
          </p>
          <p className="m-0">
            <strong>name: </strong>
            {user.profile.first_name} {user.profile.last_name}
          </p>
        </div>
      </div>
    </div>
  );
};

export default UserImpersonation;
