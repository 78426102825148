import { useCallback, useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useDispatch } from 'react-redux';

import classNames from 'classnames';
import styles from './payload-zapier.module.scss';
import { IconCopy } from '../../../../../../components/UI/icons';

import { SettingCourseActionAsync } from '../../../../../settings/store/SettingCourseActionAsync';

interface IProps {
  nodeId: string;
}

export const PayloadZapier = ({ nodeId }: IProps) => {
  const dispatch = useDispatch();
  const [zapierKey, setZapierKey] = useState<string>('');
  const [copied, setCopied] = useState(false);

  const getKey = useCallback(() => {
    dispatch(SettingCourseActionAsync.getZapierKey(zapierCallback));
  }, [dispatch]);

  const zapierCallback = (key: string) => {
    setZapierKey(key);
  };

  const onCopy = () => {
    setCopied(true);
  };

  useEffect(() => {
    getKey();
  }, [getKey]);

  useEffect(() => {
    if (copied) {
      setTimeout(() => {
        setCopied(false);
      }, 3000);
    }
  }, [copied]);

  return (
    <>
      <Form.Group>
        <Form.Label className={classNames(styles.label)}>
          Use the generated key to connect to Zapier. It will help you use third-party services to interact with the
          platform and automate business processes.
        </Form.Label>
        <Form.Control size={'sm'} readOnly defaultValue={zapierKey} />
        <CopyToClipboard text={zapierKey} onCopy={onCopy}>
          <button type={'button'} disabled={copied} className={classNames('btn btn-gradient-secondary', styles.button)}>
            {copied ? (
              'Copied'
            ) : (
              <>
                Copy
                <IconCopy className={'ml-2'} />
              </>
            )}
          </button>
        </CopyToClipboard>
      </Form.Group>
    </>
  );
};
