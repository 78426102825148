import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import styles from './course-category.module.scss';
import {
  Icon16Briefcase,
  Icon16Brush,
  Icon16CategoryArt,
  Icon16CategoryFood,
  Icon16CategoryGaming,
  Icon16CategoryMusic,
  Icon16Code,
  Icon16Eye,
  Icon16Feed,
  Icon16Home,
  Icon16Megaphone,
  Icon16Processor,
  Icon16Text
} from '../../../../../components/UI/icons';

import { CourseCategories, CourseCategoriesType } from '../../store/CourseState';

export const courseCategoryList: Record<CourseCategories, { name: string; icon: JSX.Element }> = {
  art_and_entertainment: { name: 'Art & Entertainment', icon: <Icon16CategoryArt className={styles.icon} /> },
  business: { name: 'Business', icon: <Icon16Briefcase className={styles.icon} /> },
  community: { name: 'Community', icon: <Icon16Feed className={styles.icon} /> },
  design_and_style: { name: 'Design & Style', icon: <Icon16Brush className={styles.icon} /> },
  development: { name: 'Development', icon: <Icon16Code className={styles.icon} /> },
  food: { name: 'Food', icon: <Icon16CategoryFood className={styles.icon} /> },
  writing: { name: 'Writing', icon: <Icon16Text className={styles.icon} /> },
  gaming_and_sports: { name: 'Gaming & Sports', icon: <Icon16CategoryGaming className={styles.icon} /> },
  home_and_lifestyle: { name: 'Home & Lifestyle', icon: <Icon16Home className={styles.icon} /> },
  marketing: { name: 'Marketing', icon: <Icon16Megaphone className={styles.icon} /> },
  music: { name: 'Music', icon: <Icon16CategoryMusic className={styles.icon} /> },
  science_and_tech: { name: 'Science & Tech', icon: <Icon16Processor className={styles.icon} /> },
  wellness_and_beauty: { name: 'Wellness & Beauty', icon: <Icon16Eye className={styles.icon} /> }
};

interface IProps {
  category?: CourseCategoriesType | null;
}

export const CourseCategory = ({ category }: IProps) => {
  const { setValue, register } = useFormContext();

  useEffect(() => {
    if (category) {
      setValue('category', category);
    }
  }, [category, setValue]);

  return (
    <div className={styles.courseCategory}>
      <h3 className={styles.title}>Course category</h3>
      <div className={styles.container}>
        {Object.entries(courseCategoryList).map(([key, value]) => (
          <label htmlFor={key} key={key} className={styles.label}>
            <input type={'radio'} {...register('category')} id={key} value={key} className={styles.input} />
            <div className={styles.category}>
              {value.icon}
              {value.name}
            </div>
          </label>
        ))}
      </div>
    </div>
  );
};
