import styles from './range-error.module.scss';

interface IProps {
  message: string;
  index: number;
}

export const RangeError = ({ message, index }: IProps) => {
  return (
    <div className={'d-flex mt-2'}>
      <div className={styles.number}>{index}</div>
      <div className={styles.text}>{message}</div>
    </div>
  );
};
