import { memo, ReactNode, useCallback } from 'react';

import '../../../../../../modules/crm/offers/create-offer/common/search/customSelect.scss';

import ReorderSelectedProducts from '../../../../../../components/search-products-field/ReorderSelectedProducts';
import { SearchCourses } from '../../../../../crm/offers/common/search-courses/SearchCourses';
import { ElementItem } from '../../../../../crm/offers/create-offer/common/search/element-item/ElementItem';
import { ICourse } from '../../../../../lms/courses/store/CourseState';
import { EnrollInCourse } from './EnrollInCourse';

interface IProps {
  selectedCourses: ICourse[];
  courses: ICourse[];
  onSetCourses: (courses: ICourse[]) => void;
  children: ReactNode;
}

export const PayloadCourseEnroll = memo(({ selectedCourses, courses, onSetCourses, children }: IProps) => {
  const removeCourse = useCallback(
    (id: string) => {
      const newProducts = selectedCourses.filter((x) => x.id !== id);
      onSetCourses(newProducts);
    },
    [onSetCourses, selectedCourses]
  );

  return (
    <div className={'custom'}>
      <SearchCourses
        placeholder={'Select courses'}
        courses={courses}
        onSetCourses={onSetCourses}
        selectedCourses={selectedCourses}
        size={'sm'}
      />
      <ReorderSelectedProducts elements={selectedCourses} onSetElements={onSetCourses} isDragging={false}>
        {(element) => (
          <ElementItem
            entity={'course'}
            id={element.id}
            removeElement={removeCourse}
            title={element.title}
            image={element.settings.thumbnail}
          />
        )}
      </ReorderSelectedProducts>
      <div className="mt-3">{children}</div>
    </div>
  );
});
