import { flexRender, Table } from '@tanstack/react-table';

import classNames from 'classnames';

import { ClassNameFunction } from '../common/helper';

type Props<T> = {
  table: Table<T>;
  getClassName: ClassNameFunction;
};
export const TableBody = <T extends unknown>({ table, getClassName }: Props<T>) => {
  return (
    <tbody>
      {table.getRowModel().rows.map((row) => (
        <tr key={row.id}>
          {row.getVisibleCells().map((cell) => (
            <td key={cell.id} className={classNames('center-cell', getClassName(cell.column.id))}>
              {flexRender(cell.column.columnDef.cell, cell.getContext())}
            </td>
          ))}
        </tr>
      ))}
    </tbody>
  );
};
