import React, { memo, PropsWithChildren, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Tag, TagComponentProps } from 'react-tag-autocomplete-fix';

import styles from './tagElement.module.scss';

import { ModalDelete } from '../../../components/UI';
import { TagEditGlobal } from './modal/TagEditGlobal';
import { TagActionAsync } from './store/TagActionAsync';
import { TagByIdSelector } from './store/TagSelector';
import { ITag } from './store/TagState';
import { TagComponent } from './TagComponent';

interface IProps extends PropsWithChildren<TagComponentProps> {
  onDetach: (t: Tag) => void;
  isShowDetach?: boolean;
  isShowEdit?: boolean;
}

export const TagElement = memo(({ tag, onDetach, isShowDetach, isShowEdit }: IProps) => {
  const [show, setShow] = useState(false);
  const [popup, setPopup] = useState(false);
  const [newTag, setNewTag] = useState<ITag | null>(null);
  const tagMemo = useMemo(TagByIdSelector, []);
  const dispatch = useDispatch();
  const bigTag = useSelector((state) => tagMemo(state, tag));

  useEffect(() => {
    bigTag && setNewTag(bigTag);
  }, [bigTag]);

  const handleDelete = useCallback(() => onDetach(tag), [onDetach, tag]);

  const onOpenModal = useCallback(() => setShow(true), []);

  const onCloseModal = useCallback(() => setShow(false), []);

  const onChangeText = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      newTag && setNewTag({ ...newTag, title: event.target.value });
    },
    [newTag]
  );

  const onChangeColor = useCallback(
    (background: string, color: string) =>
      newTag && setNewTag({ ...newTag, background_color: background, foreground_color: color }),
    [newTag]
  );

  const onDeleteTagHandler = useCallback(() => {
    onCloseModal();
    setPopup(true);
  }, [onCloseModal]);

  const onDeleteTag = useCallback(
    (tag: Tag) => {
      dispatch(TagActionAsync.deleteTag(tag.id.toString()));
    },
    [dispatch]
  );

  const onClosePopup = useCallback(() => setPopup(false), []);

  const onDeleteTagGlobal = useCallback(() => {
    onDeleteTag(tag);
    onClosePopup();
  }, [onClosePopup, onDeleteTag, tag]);

  return (
    <>
      <TagEditGlobal
        show={show}
        newTag={newTag}
        bigTag={bigTag}
        onChangeColor={onChangeColor}
        onChangeText={onChangeText}
        onCloseModal={onCloseModal}
        onDeleteTagHandler={onDeleteTagHandler}
      />
      {bigTag && (
        <ModalDelete
          onClose={onClosePopup}
          isShowed={popup}
          onDelete={onDeleteTagGlobal}
          title={
            <>
              Are you sure you want to delete <TagComponent bigTag={bigTag} isShowDetach={false} isShowEdit={false} />{' '}
              tag?
            </>
          }
          description={
            'Deleting the tag will detach all the contacts that are linked to this tag. This will turn all the automations that work with this tag inactive.'
          }
        />
      )}
      {bigTag && (
        <span className={styles.wrapperTag}>
          <TagComponent
            bigTag={bigTag}
            handleDelete={handleDelete}
            onOpenModal={onOpenModal}
            isShowDetach={isShowDetach}
            isShowEdit={isShowEdit}
          />
        </span>
      )}
    </>
  );
});
