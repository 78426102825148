import { Link } from 'react-router-dom';

import classNames from 'classnames';
import styles from '../../../../styles/components/navbar.module.scss';
import { IconArrowLeft } from '../../../components/UI/icons';

import { SettingsPaths } from '../../../modules/settings/routes/SettingsRoutes';

export const SettingHeaderPanel = () => {
  return (
    <div className={`navbar navbar-expand justify-content-between ${styles.navbar}`}>
      <Link
        className={classNames('btn btn-sm btn-icon-left', styles.link)}
        to={{
          pathname: SettingsPaths.settings
        }}
      >
        <IconArrowLeft className={'icon'} />
        Settings
      </Link>
    </div>
  );
};
