import styles from './progress.module.scss';

import { IStudentAnalytics } from '../../store/StudentListState';
import { PassingGrade } from '../passing-grade/PassingGrade';

interface IProps {
  analytics?: IStudentAnalytics;
}

export const Progress = ({ analytics }: IProps) => {
  const percentages = analytics ? Math.round((analytics?.passed_lessons * 100) / analytics?.total_lessons) : 0;

  return (
    <div className={styles.container}>
      <div className={styles.text}>
        {analytics?.passed_lessons} of {analytics?.total_lessons}
      </div>
      <div className={styles.dot}>•</div>
      <PassingGrade progress={percentages} />
    </div>
  );
};
