import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { InferType, object, string } from 'yup';

import styles from './connect-modal-paynet.module.scss';

import { CommonModal } from '../../../../components/UI';
import { ConnectGuide } from './connect-modal-body/connect-guide/ConnectGuide';
import { ConnectModalBody } from './connect-modal-body/ConnectModalBody';
import { ConnectModalHeader } from './connect-modal-header/ConnectModalHeader';

interface IProps {
  show: boolean;
  onClose: () => void;
}
const schema = object({
  username: string().required(),
  password: string().required(),
  merchant_id: string().required()
});

export type ConnectPaynetType = InferType<typeof schema>;

export const ConnectModalPaynet = ({ onClose, show }: IProps) => {
  const [showGuide, setShowGuide] = useState(false);
  const methods = useForm<ConnectPaynetType>({
    resolver: yupResolver(schema),
    shouldFocusError: true,
    mode: 'all'
  });

  const onShowGuide = () => setShowGuide(true);

  const onCloseGuide = () => {
    setShowGuide(false);
  };

  return (
    <FormProvider {...methods}>
      <CommonModal show={show} showClose onClose={onClose} params={{ contentClassName: styles.modalDialog }}>
        {showGuide ? (
          <ConnectGuide onBackToForm={onCloseGuide} />
        ) : (
          <div>
            <ConnectModalHeader />
            <ConnectModalBody onCloseModal={onClose} onShowGuide={onShowGuide} />
          </div>
        )}
      </CommonModal>
    </FormProvider>
  );
};
