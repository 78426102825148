import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useEffectOnce } from 'usehooks-ts';

import { useLoading } from '../../../../hooks/use-loading';
import { IRunEvent } from '../../../../interfaces';
import { automationActionAsync } from '../../store/AutomationActionAsync';

export const useRunDetails = (contactId: string) => {
  const dispatch = useDispatch();
  const { loading, startLoading, stopLoading } = useLoading();
  const [runEvents, setRunEvents] = useState<IRunEvent[]>();

  const getListAutomationEventsRuns = useCallback(() => {
    dispatch(
      automationActionAsync.getListAutomationEventsRuns(
        {
          contact_id: contactId
        },
        (data) => {
          if (data) {
            setRunEvents(data);
            stopLoading();
          }
        }
      )
    );
  }, [contactId, dispatch, stopLoading]);

  useEffectOnce(() => {
    startLoading();
    getListAutomationEventsRuns();
  });

  return { runEvents, loading };
};
