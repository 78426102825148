import React, { memo, useState } from 'react';
import { FormCheck } from 'react-bootstrap';
import { xor } from 'lodash';

import classNames from 'classnames';
import styles from './answer.module.scss';

import { Image } from '../../../../../../../components/UI/image-components/image/Image';
import { AnswerType, IQuizAnswer } from '../../../../../../../interfaces/quiz';
import { SelectImagePreview } from '../../../../../../lms/courses/curriculum/common/select-image-preview/SelectImagePreview';

interface IProps {
  value: string;
  title: string;
  answer_type: AnswerType;
  questionId: string;
  selected: string | string[];
  isPassed?: boolean | null;
  isSuccess?: boolean;
  attachment: IQuizAnswer[Extract<keyof IQuizAnswer, 'attachment'>];
  onChangeSelect: (value: string[]) => void;
}

export const Answer = memo(
  ({ answer_type, questionId, title, value, selected, isSuccess, isPassed, attachment, onChangeSelect }: IProps) => {
    const [showPreview, setShowPreview] = useState(false);
    const onChange = (e: React.SyntheticEvent<HTMLDivElement>) => {
      e.preventDefault();
      if (!disable) {
        if (answer_type === 'single_select') {
          onChangeSelect([value]);
        } else {
          onChangeSelect(xor(selected, [value]));
        }
      }
    };
    const disable = isPassed !== null;
    const success = disable && isSuccess;
    const wrong = disable && !isSuccess;
    const onClosePreview = () => setShowPreview(false);
    const onShowPreview = () => setShowPreview(true);
    return (
      <div
        className={classNames(styles.container, { [styles.isCorrect]: success }, { [styles.isPassed]: disable })}
        onClick={onChange}
      >
        {disable && answer_type === 'multiple_select' && isPassed === false && (
          <div className={styles.wrapper}>
            <div
              className={classNames(
                { [styles.isCorrectMultiTooltip]: success },
                { [styles.isIncorrectMultiTooltip]: wrong }
              )}
            >
              {success && 'This answer is correct'}
              {!success && selected.includes(value) && 'This answer is incorrect'}
            </div>
            {selected.includes(value) && <>&nbsp;• You answered</>}
          </div>
        )}
        <FormCheck
          checked={selected.includes(value) ? true : undefined}
          value={value}
          custom
          disabled={disable}
          id={value}
          label={title}
          type={answer_type === 'multiple_select' ? 'checkbox' : 'radio'}
          className={classNames(
            answer_type === 'multiple_select'
              ? classNames(
                  'checkbox-button my-1',
                  success && selected.includes(value) && styles.successCheck,
                  success && !selected.includes(value) && styles.successCheck,
                  wrong && selected.includes(value) && styles.wrongCheck
                )
              : classNames(
                  'radio-button my-1',
                  success && styles.successRadio,
                  wrong && selected.includes(value) && styles.wrongRadio
                ),
            { [styles.isCheck]: selected.includes(value) || success }
          )}
          name={isSuccess ? value : questionId}
        />
        {attachment && (
          <div onClick={onShowPreview} className={'m-auto'}>
            <Image
              bucket_name={attachment.bucket_name}
              object_name={attachment.object_name}
              alt={attachment.object_name}
              imgProps={{
                className: styles.selectImage
              }}
              className={styles.imgPreview}
            />
          </div>
        )}
        {attachment && <SelectImagePreview show={showPreview} onClose={onClosePreview} img={attachment} />}
      </div>
    );
  }
);
