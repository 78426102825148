import { FC, memo } from 'react';

import styles from './folder-state.module.scss';
import { IconWarning } from '../../../../../UI/icons';

import { CurriculumState } from '../../../../../../modules/lms/courses/store/CourseState';
import { Button } from '../../../../../UI';
import { FolderState } from './FolderState';

interface IProps {
  state: CurriculumState;
  onCloseModal: () => void;
  onStateConfirm: (e: React.SyntheticEvent<HTMLButtonElement>) => void;
  onChangePublishInner: (e: boolean) => void;
  isDraftParent?: boolean;
  isDraftChildren?: boolean;
}

export const FolderContentModal: FC<IProps> = memo(
  ({
    state,
    onChangePublishInner,
    onCloseModal,
    onStateConfirm,
    isDraftParent = false,
    isDraftChildren = false,
    children
  }) => {
    return (
      <>
        <div className={'px-6'}>
          {children}

          {isDraftChildren ? (
            <div className={styles.description}>
              This folder contains other lessons or folders that are in the draft. <br />
              When unmark draft, you want to:
            </div>
          ) : (
            <div className={styles.description}>
              This folder contains other lessons or folders that are published. When
              <br /> moving this folder to draft, you want to:
            </div>
          )}
          <FolderState onChange={onChangePublishInner} state={state} />
        </div>
        <div className={'d-flex justify-content-between pt-5 px-6'}>
          {isDraftParent && (
            <div className={'d-flex align-items-center'}>
              <IconWarning className={styles.warning} />
              <div className={styles.text}>
                The parent in which this folder <br /> is located will not be published
              </div>
            </div>
          )}
          <div className={'d-flex align-items-center ml-auto'}>
            <Button onClick={onCloseModal} btnStyle={'transparent'} withIcon={false}>
              Cancel
            </Button>
            <Button onClick={onStateConfirm} withIcon={false} className={'ml-2'}>
              Apply changes
            </Button>
          </div>
        </div>
      </>
    );
  }
);
