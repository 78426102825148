import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useEffectOnce } from 'usehooks-ts';

import styles from './payments.module.scss';

import { Loader } from '../../../components/UI';
import { useLoading } from '../../../hooks/use-loading';
import { useQueryParams } from '../../../hooks/use-query-params';
import { IPaginationResponse, IPayment } from '../../../interfaces';
import { billingActionAsync } from '../../settings/billing/store';
import { ContactListActionAsync } from '../contact/store/ContactListActionAsync';
import { FieldActionAsync } from '../field';
import { PaymentsList } from './payment-view/PaymentsList';

export const Payments = () => {
  const [data, setData] = useState<IPaginationResponse<IPayment[]>>();
  const params = useQueryParams();
  const { loading, startLoading, stopLoading } = useLoading();

  const dispatch = useDispatch();

  const getTransactions = useCallback(
    (callBack?: () => void) => {
      dispatch(
        billingActionAsync.getTransactions(
          {
            page: params.page,
            page_size: params.page_size
          },
          (data) => {
            callBack?.();
            setData(data);
            stopLoading();
          }
        )
      );
    },
    [dispatch, params.page, params.page_size, stopLoading]
  );

  const getInitialTransactions = () => {
    startLoading();
    getTransactions();
  };

  useEffect(() => {
    if (
      data &&
      (Number(params.page) !== Number(data?.meta.page) || Number(params.page_size) !== Number(data?.meta.page_size))
    ) {
      getTransactions();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.meta.page, data?.meta.page_size, getTransactions, params.page, params.page_size]);

  useEffectOnce(() => {
    getInitialTransactions();
  });

  useEffect(() => {
    dispatch(FieldActionAsync.getFields());
    dispatch(ContactListActionAsync.getContactsConfig());
  }, [dispatch]);

  return <div className={styles.container}>{loading ? <Loader /> : data && <PaymentsList data={data} />}</div>;
};
