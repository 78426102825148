import React, { useEffect, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import { Controller, useFormContext } from 'react-hook-form';
import InputMask from 'react-input-mask';
import Select from 'react-select';
import moment from 'moment';

import classNames from 'classnames';
import 'react-datepicker/dist/react-datepicker.css';
import './date-field.scss';

import { IOption } from '../../../../interfaces';
import { IField } from '../../../../modules/crm/field';

interface IProps {
  field: IField;
}

export const options = [
  { label: 'AM', value: 'AM' },
  { label: 'PM', value: 'PM' }
];

const formatLabel = (data: IOption) => {
  return <div className={'timeTitle'}>{data.label}</div>;
};

export const DateField = ({ field }: IProps) => {
  const { control, watch, setValue } = useFormContext();
  const [startDate, setStartDate] = useState<string | Date | null>(null);
  const dateTime = watch(`${field.id}`);
  const setDate = (date: string | Date | null) => {
    setStartDate(date);
  };

  useEffect(() => {
    if (dateTime === null) {
      setValue(`${field.id}.time`, null);
      setValue(field.type === 'date' ? field.id : `${field.id}.date`, null);
    }
  }, [dateTime, field.id, field.type, setValue]);

  return (
    <>
      <Controller
        control={control}
        name={field.type === 'date' ? field.id : `${field.id}.date`}
        defaultValue={startDate}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <ReactDatePicker
            calendarClassName={'date-field'}
            className={classNames('form-control-underline form-control form-control-sm', { 'is-invalid': error })}
            dateFormat="EEEE, do 'of' MMMM yyyy"
            popperPlacement={'bottom'}
            onChange={(e) => {
              const value = field.type === 'date' ? moment(e).format('YYYY-MM-DD') : e;
              onChange(value);
              setDate(value);
            }}
            selected={value === null ? null : field.type === 'date' ? moment(value, 'YYYY-MM-DD').toDate() : value}
          />
        )}
      />
      {field.type === 'date_time' && (
        <>
          <div className={'timeTitle mt-1'}>Time</div>
          <div className={'time-container form-control-underline'}>
            <Controller
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <InputMask
                  mask={'99:99'}
                  placeholder="00:00"
                  className={classNames('time timeTitle', { invalid: error })}
                  onChange={onChange}
                  value={value === null ? '12:00' : value}
                />
              )}
              defaultValue={'12:00'}
              name={`${field.id}.time`}
              control={control}
            />
            <Controller
              render={({ field: { value, onChange } }) => (
                <Select
                  options={options}
                  placeholder={''}
                  classNamePrefix={'select'}
                  className={'field-type-select selectContainer'}
                  components={{ IndicatorSeparator: () => null }}
                  onChange={onChange}
                  formatOptionLabel={formatLabel}
                  isSearchable={false}
                  value={value ? value : options[0]}
                />
              )}
              defaultValue={options[0]}
              name={`${field.id}.zo`}
              control={control}
            />
          </div>
        </>
      )}
    </>
  );
};
