import * as yup from 'yup';
import { SchemaOf } from 'yup';

import { phoneRegExp } from '../interfaces';
import { ModifyField } from '../modules/crm/field/store/FieldSelector';

export type ValidationSchema = SchemaOf<Record<string, Nullable<string | undefined>>>;

export const validationSchema = (fields: ModifyField[]): ValidationSchema => {
  const validationObject: Record<string, any> = {};

  fields.forEach((field) => {
    switch (field.type) {
      case 'url':
        validationObject[field.id] = yup
          .string()
          .url(`${field.payload.title} must be a valid URL`)
          .notRequired()
          .nullable();
        break;
      case 'toggle':
        validationObject[field.id] = yup.bool().nullable();
        break;
      case 'short_text':
      case 'long_text':
      case 'tax_id':
        validationObject[field.id] = yup
          .string()
          .max(128, `${field.payload.title} must be less 128 characters`)
          .notRequired()
          .nullable();
        break;
      case 'date_time':
        validationObject[field.id] = yup
          .object({
            date: yup.date().label('Date').typeError('Invalid Date').nullable().notRequired(),
            time: yup
              .string()
              .matches(/^(0?[1-9]|1[0-2]):[0-5][0-9]$/, 'Time is not valid')
              .nullable(),
            zo: yup
              .string()
              .transform((data: { label: string; value: string }) => {
                return data ? data.label : null;
              })
              .nullable()
          })
          .nullable();
        break;
    }

    switch (field.scope) {
      case 'email':
        validationObject[field.id] = yup
          .string()
          .required(`${field.payload.title} is a required field`)
          .email(`${field.payload.title} must be valid`)
          .concat(validationObject[field.id]);
        break;
      case 'phone':
        validationObject[field.id] = yup
          .string()
          .matches(phoneRegExp, { message: 'Phone number must be valid', excludeEmptyString: true })
          .nullable()
          .concat(validationObject[field.id]);
        break;
    }
  });

  return yup.object().shape(validationObject);
};

/*export const useYupValidationResolver = (validationSchema) => //TODO: will be checked and implement
  useCallback(
    async (data) => {
      try {
        const values = await validationSchema.validate(data, {
          abortEarly: false
        });

        return {
          values,
          errors: {}
        };
      } catch (errors) {
        return {
          values: {},
          errors: errors.inner.reduce(
            (allErrors, currentError) => ({
              ...allErrors,
              [currentError.path]: {
                type: currentError.type ?? "validation",
                message: currentError.message
              }
            }),
            {}
          )
        };
      }
    },
    [validationSchema]
  );*/

