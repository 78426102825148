import React, { memo, ReactNode, useCallback, useEffect, useState } from 'react';
import { DropTargetMonitor, useDrop } from 'react-dnd';
import { NativeTypes } from 'react-dnd-html5-backend';
import { useFormContext } from 'react-hook-form';

import classNames from 'classnames';
import styles from './image-bg-upload.module.scss';
import { Icon32Cross, IconUpload } from '../UI/icons';

import { IFile } from '../../interfaces';
import { Button, Loader, Toast } from '../UI';
import { Image, imageUrl } from '../UI/image-components/image/Image';

interface IProps {
  image?: IFile | null;
  height: number;
  width: number;
  customization?: boolean;
  fileLimit: number;
  remove?: boolean;
  children: ReactNode;

  onChange(): void;
}

export const ImageBGUpload = memo(
  ({ image, height, width, fileLimit, customization = false, remove = false, children, onChange }: IProps) => {
    const { setValue, register } = useFormContext();
    const [imagePreviewUrl, setImagePreviewUrl] = useState<string | null>(null);
    const [isPreloaded, setIsPreloaded] = useState(false);
    const [thumbIsLoaded, setThumbIsLoaded] = useState(false);

    const [{ isOver, canDrop }, drop] = useDrop(
      () => ({
        accept: [NativeTypes.FILE],
        collect: (monitor: DropTargetMonitor) => ({
          isOver: monitor.isOver(),
          canDrop: monitor.canDrop()
        }),
        drop: (item: any, monitor: DropTargetMonitor) => {
          if (item && item.files && item.files.length > 0 && monitor.isOver({ shallow: true })) {
            handleImageDrop(item.files);
          }
        }
      }),
      []
    );

    const [{ isOverCancel }, dropCancel] = useDrop(
      () => ({
        accept: [NativeTypes.FILE],
        collect: (monitor: DropTargetMonitor) => ({
          isOverCancel: monitor.isOver(),
          canDropCancel: monitor.canDrop()
        })
      }),
      []
    );

    const handleImageDrop = useCallback(
      async (files: FileList) => {
        let file = files && files[0];
        let reader = new FileReader();

        if (!file) return;
        if (!file.type.includes('image')) {
          Toast('warning', 'The file you are trying to upload is not a image format.', 'Wrong file format');
          return;
        }
        if (file?.size > fileLimit) {
          Toast('warning', 'File size is too large!', undefined, {
            autoClose: 5000
          });
          return;
        }

        reader.onloadend = () => {
          setIsPreloaded(true);
          setImagePreviewUrl(reader.result as string);
          setValue('image', files, { shouldDirty: true });
          onChange();
        };
        reader.readAsDataURL(file);
      },
      [fileLimit, onChange, setValue]
    );

    const handleChangeImage = (e: React.SyntheticEvent<HTMLInputElement>) => {
      e.preventDefault();
      let reader = new FileReader();
      if (e.currentTarget) {
        let file = e.currentTarget.files && e.currentTarget.files[0];
        if (file) {
          if (!file.type.includes('image')) {
            Toast('warning', 'The file you are trying to upload is not a image format.', 'Wrong file format');
            e.currentTarget.files = null;
            return;
          }
          if (file?.size > fileLimit) {
            Toast('warning', 'File size is too large!', undefined, {
              autoClose: 5000
            });
            e.currentTarget.files = null;
            return;
          }
          reader.onloadend = () => {
            setIsPreloaded(true);
            setImagePreviewUrl(reader.result as string);
            onChange();
          };
          reader.readAsDataURL(file);
        }
      }
    };

    const onRemove = () => {
      setImagePreviewUrl(null);
      setValue('image', null, { shouldDirty: true });
      onChange();
    };

    useEffect(() => {
      if (image === null && remove) {
        setImagePreviewUrl(null);
        setValue('image', null, { shouldDirty: false });
      } else if (!isPreloaded && image?.object_name && image?.bucket_name) {
        setImagePreviewUrl(
          imageUrl({
            bucket_name: image.bucket_name,
            object_name: image.object_name,
            size: {
              width,
              height
            }
          })
        );
        setIsPreloaded(false);
      } else if (!isPreloaded && (!image?.bucket_name || !image?.object_name)) {
        setImagePreviewUrl(null);
        setIsPreloaded(false);
      }
    }, [height, image, isPreloaded, remove, setValue, width]);

    const onImageLoad = () => {
      setThumbIsLoaded(true);
    };

    const onError = () => {
      setImagePreviewUrl(null);
      setThumbIsLoaded(true);
    };

    const isActive = canDrop && isOver;

    return (
      <>
        <div className={'mb-4 position-relative'}>
          <div
            className={classNames(styles.imageContainer, styles.inputDnD, {
              'p-0 mb-3': imagePreviewUrl || isActive,
              [styles.imageContainerCustomization]: customization
            })}
            ref={drop}
          >
            {isActive && (
              <div className={classNames(styles.blurContainerUploader)}>
                <div className={styles.bulkFooter}>
                  <div className={styles.dropContainer}>
                    <div className={styles.dropText}>Drop files</div>
                    <div className={styles.dropTextDescribe}>Maximum size of one file {fileLimit / 1024 / 1024} GB</div>
                  </div>
                </div>
                <div className={styles.wrapper}>
                  <div className={styles.cancelWrapper} ref={dropCancel}>
                    <div className={classNames(styles.cancelContainer, { [styles.isOverCancel]: isOverCancel })} />
                    <div className={styles.cancel}>
                      <Icon32Cross />
                    </div>
                  </div>
                  <div className={styles.cancelText}>Cancel</div>
                </div>
              </div>
            )}
            <input
              type="file"
              className={styles.input}
              accept="image/*"
              id={'image'}
              {...register('image')}
              onChange={handleChangeImage}
            />
            {thumbIsLoaded || !imagePreviewUrl ? null : <Loader />}
            {imagePreviewUrl ? (
              <Image
                size={{ width, height }}
                url={imagePreviewUrl}
                className={classNames(styles.image, {
                  [styles.loaded]: thumbIsLoaded,
                  [styles.imageCustomization]: customization
                })}
                imgProps={{
                  onLoad: onImageLoad,
                  onError: onError
                }}
              />
            ) : (
              <label
                htmlFor={'image'}
                className={classNames(styles.empty, { [styles.emptyCustomization]: customization })}
              >
                {children}
              </label>
            )}
          </div>
          {imagePreviewUrl && (
            <div className={styles.imageActions}>
              <label htmlFor={'image'} className={'btn btn-sm btn-gradient-primary btn-icon-right mb-0 mr-2'}>
                Upload new image
                <IconUpload className={'icon'} />
              </label>
              <Button onClick={onRemove} btnStyle={'secondary'} type={'delete'}>
                Remove
              </Button>
            </div>
          )}
        </div>
      </>
    );
  }
);
