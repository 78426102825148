import styles from './preview-comment-block.module.scss';
import { Icon32ChatBubbles, Icon32Eye, Icon32EyeHide } from '../../../../components/UI/icons';

interface IProps {
  showComments: boolean | null;
}

export const PreviewCommentBlock = ({ showComments }: IProps) => {
  return (
    <div className={styles.container}>
      <div className={styles.imgWrapper}>
        <div className={styles.commentImg}>
          <Icon32ChatBubbles />
        </div>

        {showComments ? (
          <div className={styles.eyeImg}>
            <Icon32Eye />
          </div>
        ) : (
          <div className={styles.eyeImg}>
            <Icon32EyeHide />
          </div>
        )}
      </div>
      <div className={styles.text}>
        {showComments
          ? 'Students will see a comment block at the bottom, after the lesson content'
          : 'Comments in this lesson have been hidden'}
      </div>
    </div>
  );
};
