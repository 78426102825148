import querystring from 'query-string';

import { IComment, ICommentRequest, IPaginationParams } from '../interfaces';
import { baseFetch } from './BaseFetch';

export const getStudentComments = async (
  params: Pick<ICommentRequest, 'courseId' | 'nodeId' | 'sort'>,
  pagination?: IPaginationParams
) => {
  const queryParams = querystring.stringifyUrl({
    url: `/student/courses/${params.courseId}/comments`,
    query: {
      node_id: params.nodeId,
      sort: params.sort,
      page: pagination?.page,
      page_size: pagination?.page_size
    }
  });
  return await baseFetch<{}, IComment[]>(queryParams, {}, 'GET', false);
};

export const createStudentComment = async (params: Pick<ICommentRequest, 'courseId' | 'nodeId' | 'content'>) => {
  return await baseFetch<Pick<ICommentRequest, 'content' & { node_id: string }>, IComment>(
    `/student/courses/${params.courseId}/comments`,
    { content: params.content, node_id: params.nodeId },
    'POST',
    false
  );
};
