import { memo } from 'react';

import styles from './file-processing.module.scss';

import { ITranscodingStatus } from '../../../../../API/video-api';
import { SpinnerLoader } from '../../../../UI';

interface IProps {
  completeStatus?: ITranscodingStatus;
}

export const FileProcessing = memo(({ completeStatus }: IProps) => {
  return (
    <div className={styles.container}>
      <div className={'d-flex flex-column align-items-center'}>
        <SpinnerLoader
          text={completeStatus?.state ? `Processing... ${completeStatus.progress}%` : 'Processing...'}
          spinnerProps={{ className: styles.spinnerSize }}
          textStyle={styles.spinnerText}
        />
      </div>
    </div>
  );
});
