import { memo, SyntheticEvent, useMemo } from 'react';
import { useSelector } from 'react-redux';

import classNames from 'classnames';
import styles from './answers-curses.module.scss';
import { IconCheckMark } from '../../../../../components/UI/icons';

import { Loader } from '../../../../../components/UI';
import { ThumbnailPlaceholderImage } from '../../../../../components/UI/image-components/thumbnail-placeholder-image/ThumbnailPlaceholderImage';
import { IFile } from '../../../../../interfaces';
import { LoadState } from '../../../../../store';
import { CoursesSelector } from '../../../courses/store/CourseSelector';

export type IAnswersCourses = {
  title: string;
  id: string | null;
  selected: boolean;
  thumbnail?: IFile | null;
};

interface IProps {
  coursesList: IAnswersCourses[],
  setCoursesList(courses: IAnswersCourses[]): void;
  onSelectCourses(courses: IAnswersCourses[]): void;
}

export const AnswersCurses = memo(({ onSelectCourses, coursesList, setCoursesList }: IProps) => {
  const courseMemo = useMemo(CoursesSelector, []);
  const { courses, loadState } = useSelector((state) => courseMemo(state, 'all'));

  const changeStateCourseList = (
    e: SyntheticEvent<HTMLDivElement | HTMLSpanElement, Event> | undefined,
    selectedCourse: IAnswersCourses
  ) => {
    e?.stopPropagation();

    if (selectedCourse.selected && !selectedCourse.id) {
      return;
    }

    selectedCourse.selected = !selectedCourse.selected;

    const hasEverySelected = coursesList.filter((course) => course.id).every((chip) => chip.selected);
    const hasSomeSelected = coursesList.filter((course) => course.id).some((chip) => chip.selected);

    const updatedCourse = coursesList.map((course) => {
      if (!course.id) {
        if (!hasEverySelected) {
          course.selected = false;
        }

        if (hasEverySelected || !hasSomeSelected) {
          course.selected = true;
        }
      }

      if (!selectedCourse.id) {
        course.selected = true;
      }

      return course;
    });

    setCoursesList(updatedCourse);
    onSelectCourses(updatedCourse);
  };

  return (
    <div className={classNames(styles.answersCurses)}>
      <h5 className={classNames(styles.answersCursesTitle)}>Courses</h5>
      <div className={classNames(styles.answersCursesList)}>
        {loadState === LoadState.firstLoad ? (
          <Loader />
        ) : courses.length ? (
          <>
            {coursesList.map((course) => (
              <div
                key={course.id || course.title}
                onClick={(e) => changeStateCourseList(e, course)}
                className={classNames(styles.answersCursesItem, {
                  [styles.answersCursesItemDefault]: !course.id,
                  [styles.answersCursesItemSelected]: course.selected,
                  [styles.answersCursesItemDefaultDisabled]: !course.id && course.selected
                })}
              >
                {course.id && (
                  <ThumbnailPlaceholderImage
                    className={classNames(styles.answersCursesItemImage)}
                    width={64}
                    height={36}
                    thumbnailCourse={course.thumbnail || null}
                  />
                )}
                <p className={classNames(styles.answersCursesItemTitle)}> {course.title}</p>
                {!course.id && <IconCheckMark className={classNames(styles.answersCursesItemIcon)} />}
              </div>
            ))}
          </>
        ) : (
          <p className={classNames(styles.answersCursesListEmpty)}>You have no created courses</p>
        )}
      </div>
    </div>
  );
});
