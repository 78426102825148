import { IQueue } from '../interfaces/queue';

abstract class Collection<T> {
  protected storage: T[] = [];

  size(): number {
    return this.storage.length;
  }
  abstract isFull(): boolean;
}

export class Queue<T> extends Collection<T> implements IQueue<T> {
  constructor(private capacity: number = Infinity) {
    super();
  }
  enqueue(item: T, reverse = false): void {
    if (this.isFull()) {
      throw Error('Queue has reached max capacity, you cannot add more items');
    }
    // In the derived class, we can access protected properties of the abstract class
    if (reverse) {
      this.storage.unshift(item);
    } else {
      this.storage.push(item);
    }
  }
  dequeue(): T | undefined {
    return this.storage.shift();
  }

  // Implementation of the abstract method
  isFull(): boolean {
    return this.capacity === this.size();
  }

  clear() {
    this.storage = [];
  }
}
