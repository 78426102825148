import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useRouteMatch } from 'react-router-dom';

import styles from './node-automation.module.scss';
import { ReactComponent as ActionsOtherAutomationThumb } from '../../../../../../assets/illustrations/funnels/actions/illustrations-funnel-actions-other-automation.svg';

import { useNodePayload } from '../../../../../hooks/use-node-payload';
import { PaletteElementTypes } from '../../../../../interfaces';
import { automationPaths } from '../../../../automations/routes/AutomationRoutes';
import { automationActionAsync } from '../../../../automations/store/AutomationActionAsync';
import { funnelPaths } from '../../../../funnels/routes/FunnelRoutes';
import { EmptyCanvasNode } from '../EmptyCanvasNode';

interface IProps {
  nodeId: string;
}

export const CanvasNodeAutomation = ({ nodeId }: IProps) => {
  const dispatch = useDispatch();
  const authToken = useSelector((state) => state.auth.authToken);
  const automations = useSelector((state) => state.automations.automations);
  const node = useNodePayload<PaletteElementTypes.ACTION_AUTOMATION>(nodeId);
  const path = useRouteMatch<{ id: string }>(funnelPaths.funnelId());
  const funnelId = path?.params.id;

  useEffect(() => {
    if (automations.length === 0 && authToken) {
      dispatch(automationActionAsync.getAutomations(authToken));
    }
  }, [authToken, automations.length, dispatch]);

  const currentAutomation = automations.find((automation) => automation.id === node?.payload?.automation_id);

  return currentAutomation ? (
    <div className={styles.container}>
      <Link
        className={styles.title}
        to={{
          pathname: automationPaths.automationListId(currentAutomation.id),
          search: `?funnel_id=${funnelId}`,
          state: { fromFunnel: true }
        }}
      >
        <ActionsOtherAutomationThumb className={styles.icon} />
        {currentAutomation.name}
      </Link>
    </div>
  ) : (
    <EmptyCanvasNode text={'Double click to open the options and manage automations'} />
  );
};
