import { useState } from 'react';

import classNames from 'classnames';
import styles from './quiz-answer.module.scss';
import { IconCheckMark } from '../../../../../../../components/UI/icons';

import { Image } from '../../../../../../../components/UI/image-components/image/Image';
import { IQuizAnswer, IQuizQuestion, IQuizSessionAnswer } from '../../../../../../../interfaces/quiz';

interface IProps {
  answer: IQuizAnswer;
  question: IQuizQuestion;
  result?: IQuizSessionAnswer;
}

export const QuizAnswer = ({ answer, question, result }: IProps) => {
  return (
    <>
      <div className={styles.container}>
        {answer?.id && Array.isArray(result?.answers) && result?.answers?.includes(answer.id) && (
          <div className={styles.choice}>
            <div className={classNames(styles.label, answer.is_true ? styles.correct : styles.wrong)}>
              {answer.is_true ? 'Correct' : 'Wrong'}
            </div>
            <div className={styles.student}>student choice</div>
          </div>
        )}
        <div className={styles.answer}>
          <div
            className={classNames(
              styles.button,
              question.answer_type === 'single_select' ? styles.radio : styles.check,
              {
                [styles.selected]: result && answer.is_true
              }
            )}
          >
            {result && question.answer_type === 'multiple_select' && answer.is_true && (
              <IconCheckMark className={styles.checkmark} />
            )}
          </div>
          <div className={styles.text}>{answer.title}</div>
        </div>
        {answer.attachment && (
          <div className={styles.selectImage}>
            <Image
              bucket_name={answer.attachment.bucket_name}
              object_name={answer.attachment.object_name}
              alt={answer.attachment.object_name}
              imgProps={{
                width: '89px',
                height: '56px'
              }}
              size={{
                height: 56,
                width: 89
              }}
              className={styles.imgPreview}
            />
          </div>
        )}
      </div>
    </>
  );
};
