import actionCreatorFactory from 'typescript-fsa';

import { IAccount, IProfileAccount } from '../../../../interfaces';

export const actionCreator = actionCreatorFactory();
export const AccountActions = {
  getMe: actionCreator.async<{ isStudent?: boolean }, IAccount, Error>('GET_ME'),
  editMe: actionCreator.async<Partial<IProfileAccount>, IProfileAccount, Error>('EDIT_ME'),
  deleteMe: actionCreator('DELETE_ME'),
  deleteOrganization: actionCreator('DELETE_ORGANIZATION'),
  createOrganization: actionCreator.async<Nullable<string>, null, Error>('CREATE_ORGANIZATION')
};
