import { Tag } from 'react-tag-autocomplete-fix';

import { LoadState } from '../../../../store';
import { IContact } from '../store/ContactListState';

export interface ICurrentContactState {
  contact:
    | {
        contact: IContact;
        tagContact: Tag[];
        lists?: string[];
      }
    | undefined;
  loadState: LoadState;
  error: Error | null;
}

export const CurrentContactInitialState: ICurrentContactState = {
  contact: undefined,
  error: null,
  loadState: LoadState.firstLoad
};
