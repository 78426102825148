import { IMeta } from '../../../../interfaces';
import { LoadState } from '../../../../store';
import { FieldEntity } from '../../field';

export interface IProduct {
  date_create: number;
  date_update: number;
  fields: IProductField;
  id: string;
  organization_id: string;
  payload: {};
  title: string;
  entity: FieldEntity;
}

export interface IProductField {
  [id: string]: any;
}

export type ProductType = 'regular' | 'course';

export interface IProductsState extends IMeta {
  loadState: LoadState;
  error: Error | null;
  products: IProduct[];
}

export const ProductsInitialState: IProductsState = {
  loadState: LoadState.needLoad,
  error: null,
  products: [],
  page: 1,
  page_size: 25,
  total: 25,
  total_pages: 1
};

export interface ICreateProductRequest {
  fields: IProductField[];
}
