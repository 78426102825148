import { lazy, Suspense, useMemo } from 'react';
import Quill from 'quill';

import styles from './quize-free-answer.module.scss';

import { FontFamily } from '../../../../../../../components/quill-editor/modules/CustomFontFamily';
import { Size } from '../../../../../../../components/quill-editor/modules/CustomFontSize';
import { CustomTag } from '../../../../../../../components/quill-editor/modules/CustomTag';
import { CustomVideo } from '../../../../../../../components/quill-editor/modules/CustomVideo';
import { Loader } from '../../../../../../../components/UI';
import { IQuizSessionAnswer } from '../../../../../../../interfaces/quiz';

interface IProps {
  result?: IQuizSessionAnswer;
}

export const QuizFreeAnswer = ({ result }: IProps) => {
  const ReactQuill = lazy(() => import('react-quill'));

  Quill.register(CustomVideo, true);

  Quill.register(CustomTag);
  Quill.register(FontFamily, true);
  Quill.register(Size, true);
  Quill.debug(false);

  const modules = useMemo(
    () => ({
      toolbar: false
    }),
    []
  );

  return (
    <div className={styles.container}>
      <div className={'d-flex mb-2'}>
        <div className={styles.student}>student answer</div>
      </div>
      <div className={styles.answer}>
        <Suspense fallback={<Loader />}>
          <ReactQuill modules={modules} value={result?.answers} readOnly />
        </Suspense>
      </div>
    </div>
  );
};
