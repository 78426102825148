import { memo, useCallback, useState } from 'react';

import styles from './session-expired.module.scss';
import { Icon32Info, IconReload } from '../UI/icons';

import { Button, ModalActionComponent } from '../UI';

interface IProps {
  showModal: boolean;
}
const link = `${window.location.origin}`;

export const SessionExpired = memo(({ showModal }: IProps) => {
  const [show, setShow] = useState(showModal);
  const onRedirect = useCallback(() => {
    setShow(false);
    localStorage.clear();
    window.location.href = link;
  }, []);

  return (
    <ModalActionComponent
      iconType={'custom'}
      title={
        <span className={styles.title}>
          Your session has <span className={styles.expiredText}>expired</span>
        </span>
      }
      hideClose={true}
      onCloseModal={() => {}}
      icon={<Icon32Info />}
      show={show}
    >
      <div>
        <div className={styles.description}>
          Please refresh the page. Don’t worry, we keep all of your files and breakdowns in place.
        </div>
        <Button onClick={onRedirect} customIcon={<IconReload />} iconPosition={'right'} className={styles.btn}>
          Refresh
        </Button>
      </div>
    </ModalActionComponent>
  );
});
