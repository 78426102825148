import { Button } from '../../../../../components/UI';
import { PageTopPanel } from '../../../../../layout/shared-components/page-top-panel/PageTopPanel';
import { SearchWrapper } from '../../../../../layout/shared-components/SearchWrapper';

interface IProps {
  title: string;

  onClick(): void;
}

export const ProductListHeader = ({ title, onClick }: IProps) => {
  return (
    <PageTopPanel title={title}>
      <SearchWrapper placeholder={'Search by id, product name, SKU...'} isPage className={'mr-2'} />
      <Button onClick={onClick}>Add Product</Button>
    </PageTopPanel>
  );
};
