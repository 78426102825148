import { useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import classNames from 'classnames';
import commonOfferStyles from '../../common/commonOffer.module.scss';
import styles from './addToList.module.scss';

import { ListsActionAsync } from '../../../../lists/store/ListsActionAsync';
import { IList } from '../../../../lists/store/ListsState';
import { Toggle } from '../../common/toggle/Toggle';
import { ActionAddToListWrapper } from './ActionAddToListWrapper';

interface IProps {
  list: string[] | null;
  setSelected: (data: Pick<IList, 'id' | 'title'>[]) => void;
  selected: Pick<IList, 'id' | 'title'>[];
}

export const ActionAddToList = ({ list, selected, setSelected }: IProps) => {
  const { control, setValue, formState } = useFormContext<{ action_add_to_list: string[] | null }>();

  const { errors } = formState;

  const { lists } = useSelector((state) => state.lists);
  const dispatch = useDispatch();
  const addToList = useWatch({
    control: control,
    name: 'action_add_to_list'
  });

  useEffect(() => {
    if (lists.length === 0) {
      dispatch(ListsActionAsync.getLists());
    }
  }, [dispatch, lists.length]);

  const onChangeEnrol = (show: boolean) => {
    if (!show) {
      setValue('action_add_to_list', null, { shouldDirty: true });
    } else {
      setValue('action_add_to_list', list || [], { shouldDirty: true });
    }
  };

  return (
    <>
      <Toggle
        className={'mt-3'}
        onChangeValue={onChangeEnrol}
        checked={!!addToList}
        controlName={'action_add_to_list'}
        title={'Add to custom list'}
      >
        <ActionAddToListWrapper selectedLists={addToList} lists={lists} selected={selected} setSelected={setSelected} />
        {errors.action_add_to_list && (
          <div className={commonOfferStyles.errorTitle}>You must add at least one list to your offer</div>
        )}
      </Toggle>

      {!addToList && (
        <div className={classNames(commonOfferStyles.text, styles.textContainer)}>
          By default, the customer will be added to the master contact list in the CRM system. You can specify any other
          list where you would like to add it.
        </div>
      )}
    </>
  );
};
