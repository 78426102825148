import { FieldValues, useFormContext } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { MediaProvider } from 'src/app/API/video-api';
import { AudioUploader } from 'src/app/components/file-upload/audio-uploader/AudioUploader';
import { useFileUpload } from 'src/app/components/file-upload/audio-uploader/use-file-upload';
import { AudioField, TypeOfUploader } from 'src/app/interfaces/fileUpload';
import { AttachmentType, IAttachment } from 'src/app/interfaces/quiz';
import { fileUploaderActions } from 'src/app/layout/shared-components/upload-worker/store/FileUploaderActions';
import { useCourseContext } from 'src/app/modules/lms/courses/common/sidebar/CourseContext';

import { LessonFormValues } from '../../../../lesson/LessonBlocks';

type Props = {
  quizQuestionAttachment?: string;
  audio?: AudioField;
  onSetAttachment?: (data: Partial<IAttachment> | null, attachmentType: AttachmentType | null) => void;
};

export const AttachmentAudio = ({ quizQuestionAttachment, audio, onSetAttachment }: Props) => {
  const dispatch = useDispatch();
  const methods = useFormContext<FieldValues & LessonFormValues>();
  const { courseId, nodeId } = useCourseContext();
  const { onCancelUpload, retrieveUrlFileAndUpload } = useFileUpload(courseId, nodeId);

  const onSetAudio = (audioField: AudioField) => {
    if (quizQuestionAttachment) {
      onSetAttachment?.(audioField, 'audio');
    }
  };

  const onRetrieveCallback = (audioLink: string) => {
    if (quizQuestionAttachment && methods) {
      methods.register(quizQuestionAttachment);
      methods.setValue(
        quizQuestionAttachment,
        { type: TypeOfUploader.upload, audio_url: audioLink },
        { shouldDirty: true }
      );
    }
  };

  const retrieveAndUpload = (file: File) => {
    retrieveUrlFileAndUpload(file, onRetrieveCallback, 'audio');
  };
  const onRemoveAudio = () => {
    onSetAttachment?.(null, 'audio');
  };

  return (
    <AudioUploader
      audio={audio}
      onSetAudioOptional={onSetAudio}
      retrieveAndUpload={retrieveAndUpload}
      onRemoveAudioOptional={onRemoveAudio}
      onCancel={onCancelUpload}
    />
  );
};
