import actionCreatorFactory from 'typescript-fsa';

import { IPaginationResponse } from '../../../../interfaces';
import { IStudent } from '../../../lms/students/store/StudentListState';
import { IList } from '../../lists/store/ListsState';
import { IContact, IContactField } from './ContactListState';

export const actionCreator = actionCreatorFactory();

export const ContactListActions = {
  getContactsConfig: actionCreator.async<string | void, IList, Error>('GET_CONTACTS_CONFIG'),
  getContact: actionCreator.async<string, IContact, Error>('GET_CONTACT_DETAIL'),
  getContactsList: actionCreator.async<string | null, IPaginationResponse<IContact[]>, Error>('GET_CONTACTS'),
  createContactInList: actionCreator.async<{ id: string; fields: IContactField; tags?: string[] }, IContact, Error>(
    'CREATE_CONTACT_IN_LIST'
  ),
  createContact: actionCreator.async<{ fields: IContactField; tags?: string[] }, IContact, Error>('CREATE_CONTACT'),
  editContact: actionCreator.async<{ id: string; fields: IContactField; tags?: string[] }, IContact, Error>(
    'EDIT_CONTACT'
  ),
  deleteContact: actionCreator.async<{ id: string }, void, Error>('DELETE_CONTACT'),
  exportContacts: actionCreator.async<void, string, Error>('EXPORT_CONTACTS'),
  exportContactsFromList: actionCreator.async<{ list_key: string }, string, Error>('EXPORT_CONTACTS_FROM_LIST'),
  createStudentFromContact: actionCreator.async<{ contactId: string }, IStudent, Error>('CREATE_STUDENT_FROM_CONTACT')
};
