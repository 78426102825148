import { UIEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import classNames from 'classnames';
import styles from './thread-list.module.scss';
import moderationEmpty from '../../../../../assets/images/moderation-empty.png';
import { Icon32Mark } from '../../../../components/UI/icons';

import { useLoading } from '../../../../hooks/use-loading';
import { useQuery } from '../../../../hooks/use-query';
import { IPaginationResponse, IThread } from '../../../../interfaces';
import { courseActionAsync } from '../../../lms/courses/store/CourseActionAsync';
import { NewComments } from '../new-comments/NewComments';
import { ThreadItem } from './thread-item/ThreadItem';

export const ThreadList = () => {
  const query = useQuery();
  const dispatch = useDispatch();
  const { loading, startLoading, stopLoading } = useLoading();
  const { courses } = useSelector((state) => state.courses);
  const [threads, setThreads] = useState<IPaginationResponse<IThread[]>>({
    data: [],
    meta: { page: 1, page_size: 3, total: 0, total_pages: 1 }
  });
  const courseId = query.get('course_id');

  const onRemoveThread = (threadId: string) => {
    const filteredData = threads.data.filter((thread) => thread.id !== threadId);
    setThreads({
      data: filteredData,
      meta: threads.meta
    });
  };

  const onSetThreads = (data: IPaginationResponse<IThread[]>, isInitial?: boolean) => {
    setThreads((prev) => {
      return {
        data: isInitial ? data.data : [...prev.data, ...data.data],
        meta: isInitial ? data.meta : { ...prev.meta, ...data.meta }
      };
    });

    stopLoading();
  };

  const onGetThreads = (page: number, page_size: number, isInitial?: boolean) => {
    startLoading();

    dispatch(
      courseActionAsync.getThreads(
        { courseId: courseId ? courseId : undefined, sort: ['-created_at'], has_unmoderated_comments: true },
        (data) => onSetThreads(data, isInitial),
        {
          page,
          page_size
        }
      )
    );
  };

  useEffect(() => {
    startLoading();
    onGetThreads(1, 3, true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [courseId]);

  const handleScroll = (e: UIEvent<HTMLDivElement>) => {
    const scrollHeight = e.currentTarget.scrollHeight;
    const scrollTop = e.currentTarget.scrollTop + 1;
    const clientHeight = e.currentTarget.clientHeight;
    const bottom = scrollHeight - scrollTop - 180 < clientHeight;
    const moreItems = threads.meta.page * threads.meta.page_size < threads.meta.total ? true : false;
    const nextPage = threads.meta.page + 1;

    if (moreItems && bottom && !loading) {
      onGetThreads(nextPage, 3);
    }
  };

  return (
    <div className={classNames(styles.content, 'col')} onScroll={handleScroll}>
      <div className={styles.wrapper}>
        {courses.length > 0 && threads.data.length > 0 && threads.meta.total > 0 && (
          <>
            <NewComments commentCount={threads.meta.total} />
            {threads.data.map((thread) => (
              <ThreadItem key={thread.id} thread={thread} onRemoveThread={onRemoveThread} />
            ))}
          </>
        )}
        {courses.length > 0 && threads.data.length === 0 && (
          <div className={styles.emptyContainer}>
            <Icon32Mark />
            You've looked at all the unresolved comments
          </div>
        )}
        {courses.length === 0 && threads.data.length === 0 && !loading && (
          <div className={styles.emptyContainer}>
            <img src={moderationEmpty} alt="empty courses" className={styles.emptyCoursesImg} />
            <div className={styles.emptyTitle}>No unresolved comments yet</div>
            <div className={styles.emptyDescription}>
              If you have enabled comments in the lesson settings, any new comments that have not yet been resolved
              will appear on this page
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
