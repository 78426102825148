import React, { memo, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { SetupIntent } from '@stripe/stripe-js';

import styles from './add-billing-information.module.scss';
import { ReactComponent as IconVisa } from '../../../../../assets/icons/4-icon-payment-visa.svg';

import { HoorayModal } from '../../../../components/hooray/HoorayModal';
import { CommonModal } from '../../../../components/UI';
import { billingActionAsync } from '../../../settings/billing/store';
import { SettingCourseActionAsync } from '../../../settings/store/SettingCourseActionAsync';
import { AddCard } from '../add-card/AddCard';

interface IProps {
  show: boolean;
  clientSecret: string;
  onClose(): void;
}

export const AddBillingInformation = memo(({ show, clientSecret, onClose }: IProps) => {
  const dispatch = useDispatch();
  const [showSuccess, setShowSuccess] = useState(false);

  const closeModal = useCallback(() => {
    onClose();
  }, [onClose]);

  const getSubscriptions = useCallback(() => {
    dispatch(billingActionAsync.getSubscription());
  }, [dispatch]);

  const onSuccess = (setupIntent: SetupIntent) => {
    closeModal();
    if (setupIntent.payment_method) {
      dispatch(
        SettingCourseActionAsync.setDefaultCard(setupIntent.payment_method as string, () => {
          setShowSuccess(true);
          getSubscriptions();
        })
      );
      dispatch(SettingCourseActionAsync.getPaymentCards());
    }
  };

  const onCloseSuccess = () => {
    setShowSuccess(false);
  };

  return (
    <>
      <CommonModal show={show} onClose={closeModal}>
        <AddCard clientSecret={clientSecret} onClose={closeModal} onSuccess={onSuccess} />
      </CommonModal>
      <HoorayModal
        show={showSuccess}
        onCloseModal={onCloseSuccess}
        title={
          <>
            You got one more week <br />
            of trial period
          </>
        }
        className={styles.hooray}
      >
        <div className={styles.payment}>First payment $19.99 on 21 Sep, 2021</div>
        <div className={styles.card}>
          <IconVisa /> ×××× 5547
        </div>
        <div className={styles.note}>
          At the end of the trial period your card will be charged. You can cancel your account anytime before the end
          of your trial.
        </div>
      </HoorayModal>
    </>
  );
});
