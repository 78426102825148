import { memo } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { FieldErrors } from 'react-hook-form/dist/types/errors';

import classNames from 'classnames';
import styles from './settings-actions.module.scss';

import { Button } from '../../../../components/UI';

interface IProps<T> {
  methods: UseFormReturn<T>;
  onSave: (data: T) => void;
  onError?: (errors: FieldErrors<T>) => void;
  onCancel: () => void;
  className?: string;
}

const SettingsActions = <T extends object>({ methods, onSave, onCancel, onError = () => {}, className }: IProps<T>) => {
  return (
    <div className={classNames(styles.container, className)}>
      <div className={styles.inner}>
        <Button
          onClick={methods.handleSubmit(onSave, onError)}
          btnStyle={'primary'}
          className={'mr-2'}
          withIcon={false}
        >
          Save changes
        </Button>
        <Button onClick={onCancel} btnStyle={'secondary'} withIcon={false}>
          Cancel
        </Button>
      </div>
    </div>
  );
};

export default memo(SettingsActions) as typeof SettingsActions;
