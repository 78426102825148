import { memo, useCallback, useEffect, useRef, useState } from 'react';
import ReactPlayer, { ReactPlayerProps } from 'react-player';

interface IProps {
  url: string | undefined;
  videoWidth?: number;
  videoHeight?: number;
  playerColor?: string;
  props?: ReactPlayerProps;
  onError?: (error: Error) => void;
}
export const VideoPlayer = memo(
  ({ url, videoWidth = 768, videoHeight = 432, playerColor = '1e71e7', onError, ...props }: IProps) => {
    const [height, setHeight] = useState<number>(videoHeight);
    const [urlVideo, setUrlVideo] = useState(url);
    const videoRef = useRef<ReactPlayer>(null);

    const onReady = () => {
      const playerHeight = videoRef.current?.getInternalPlayer()?.clientHeight;
      if (playerHeight) {
        setHeight(playerHeight);
      }

      setUrlVideo(url);

      const HTML5VideoElement = videoRef.current?.getInternalPlayer() as HTMLVideoElement & {
        webkitSupportsPresentationMode?: (mode: string) => boolean;
        webkitSetPresentationMode?: (mode: string) => void;
        webkitPresentationMode?: string;
      };

      if (
        HTML5VideoElement?.webkitSupportsPresentationMode?.('inline') &&
        typeof HTML5VideoElement.webkitSetPresentationMode === 'function'
      ) {
        HTML5VideoElement.webkitSetPresentationMode(
          HTML5VideoElement.webkitPresentationMode === 'fullscreen' ? 'inline' : 'fullscreen'
        );
      }
    };

    const handleError = useCallback(
      (error: Error) => {
        onError?.(error);
      },
      [onError]
    );

    useEffect(() => {
      const HTML5VideoElement = videoRef.current?.getInternalPlayer() as HTMLVideoElement & {
        webkitSupportsPresentationMode?: (mode: string) => boolean;
        webkitSetPresentationMode?: (mode: string) => void;
        webkitPresentationMode?: string;
      };

      if (
        HTML5VideoElement?.webkitSupportsPresentationMode?.('inline') &&
        typeof HTML5VideoElement.webkitSetPresentationMode === 'function'
      ) {
        HTML5VideoElement.webkitSetPresentationMode('inline');
      }
    }, []);

    return (
      <>
        {urlVideo && (
          <ReactPlayer
            ref={videoRef}
            style={{ backgroundColor: '#0D0D0D', borderRadius: '0.25rem', overflow: 'hidden' }}
            url={urlVideo}
            width={'100%'}
            height={height}
            controls
            playsinline
            onReady={onReady}
            onError={handleError}
            config={{
              wistia: {
                options: { playerColor, videoWidth, videoHeight }
              },
              vimeo: {
                playerOptions: {
                  color: playerColor,
                  width: videoWidth,
                  height: videoHeight,
                  playsinline: 1
                }
              }
            }}
            {...props}
          />
        )}
      </>
    );
  }
);
