import { useState } from 'react';
import { Form, FormControl } from 'react-bootstrap';

import commonStyles from '../common/upload-common.module.scss';
import { IconCheckMark } from '../../../UI/icons';

import { Button } from '../../../UI';

interface IProps {
  placeholder: string;
  onSave(value: string): void;
  onCancel: () => void;
  defaultFileLink?: string;
  format?: string;
}

export const FileLink = ({ defaultFileLink, placeholder, onSave, onCancel, format }: IProps) => {
  const [value, setValue] = useState('');

  return (
    <Form.Group className={commonStyles.linkContainer}>
      <FormControl
        autoFocus
        defaultValue={defaultFileLink}
        placeholder={placeholder}
        onChange={(e) => setValue(e.currentTarget.value)}
      />
      <div className={commonStyles.linkActions}>
        <Button
          btnProps={{ disabled: value.length === 0 || !!(format && !value.includes(format)) }}
          customIcon={<IconCheckMark />}
          onClick={() => onSave(value)}
          className={commonStyles.linkButton}
        >
          Save
        </Button>
        <Button btnStyle={'secondary'} withIcon={false} className={commonStyles.linkButton} onClick={onCancel}>
          Cancel
        </Button>
      </div>
    </Form.Group>
  );
};
