import { flatMapDeep } from 'lodash';

import { ICourseNode } from '../../../modules/lms/courses/store/CourseState';

export const flatten = (item: ICourseNode): any => {
  return [item, flatMapDeep(item.children, flatten)];
};

export const getMembers = (member: ICourseNode): any => {
  if (!member.children || !member.children.length) {
    return member;
  }
  return [member, flatMapDeep(member.children, getMembers)];
};
