import { reducerWithInitialState } from 'typescript-fsa-reducers';
import _ from 'lodash';

import { LoadState, newState } from '../../../../store';
import { funnelActions } from './FunnelActions';
import { FunnelInitialState, IFunnelState } from './FunnelState';

export const funnelReducer = reducerWithInitialState<IFunnelState>(FunnelInitialState)
  .case(funnelActions.getFunnels.started, (state) => ({ ...state, loadState: LoadState.firstLoad, error: null }))
  .case(funnelActions.getFunnels.done, (state, { result }) => {
    return newState(state, {
      loadState: LoadState.allIsLoaded,
      error: null,
      funnels: _.orderBy(result, ['updated_at'], ['desc'])
    });
  })
  .case(funnelActions.getFunnels.failed, (state, { error }) => ({
    ...state,
    loadState: LoadState.error,
    error
  }))

  .case(funnelActions.getFunnel.started, (state) => ({ ...state, loadState: LoadState.firstLoad, error: null }))
  .case(funnelActions.getFunnel.done, (state, { result }) => {
    let values = state.funnels.map((item) => (item.id === result.id ? result : item));

    return newState(state, {
      loadState: LoadState.allIsLoaded,
      error: null,
      funnels: values
    });
  })
  .case(funnelActions.getFunnel.failed, (state, { error }) => ({
    ...state,
    loadState: LoadState.error,
    error
  }))

  .case(funnelActions.createFunnel.started, (state) => ({ ...state, loadState: LoadState.firstLoad, error: null }))
  .case(funnelActions.createFunnel.done, (state, { result }) => {
    return newState(state, {
      loadState: LoadState.firstLoad,
      error: null,
      funnels: [
        ...state.funnels,
        {
          ...result,
          nodes: [],
          edges: [],
          geometry: []
        }
      ]
    });
  })
  .case(funnelActions.createFunnel.failed, (state, { error }) => ({
    ...state,
    loadState: LoadState.error,
    error
  }))

  .case(funnelActions.deleteFunnel.started, (state) => ({ ...state, loadState: LoadState.firstLoad, error: null }))
  .case(funnelActions.deleteFunnel.done, (state, { params }) => {
    const removingIndexElement = state.funnels.findIndex((x) => x.id === params.funnelKey);
    state.funnels.splice(removingIndexElement, 1);

    return newState(state, {
      loadState: LoadState.allIsLoaded,
      error: null,
      funnels: state.funnels
    });
  })
  .case(funnelActions.deleteFunnel.failed, (state, { error }) => ({
    ...state,
    loadState: LoadState.error,
    error
  }))

  .case(funnelActions.updateFunnel.started, (state) => ({ ...state, loadState: LoadState.firstLoad, error: null }))
  .case(funnelActions.updateFunnel.done, (state, { result }) => {
    const newElement = state.funnels.map((x) => (x.id === result.id ? result : x));
    return newState(state, {
      loadState: LoadState.allIsLoaded,
      error: null,
      funnels: newElement
    });
  })
  .case(funnelActions.updateFunnel.failed, (state, { error }) => ({
    ...state,
    loadState: LoadState.error,
    error
  }))

  .case(funnelActions.updateFunnelState.started, (state) => ({ ...state, loadState: LoadState.idle, error: null }))
  .case(funnelActions.updateFunnelState.done, (state, { result }) => {
    const newStateElement = state.funnels.map((x) =>
      x.id === result.id ? { ...x, name: x.name, state: result.state } : x
    );

    return newState(state, {
      loadState: LoadState.allIsLoaded,
      error: null,
      funnels: newStateElement
    });
  })
  .case(funnelActions.updateFunnelState.failed, (state, { error }) => ({
    ...state,
    loadState: LoadState.error,
    error
  }));
