/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { useCountdown } from 'usehooks-ts';

import classNames from 'classnames';
import styles from './confirmation.module.scss';
import confirmation from '../../../../assets/images/confirmation.png';
import { Icon32Spinner } from '../../../components/UI/icons';

import { EmailAction, EmailActionStatusEnum } from '../../../API/action-email-api';
import { useAction } from '../../../hooks/use-action';
import { IError, IHistoryLocationState } from '../../../interfaces';
import { mainPaths } from '../../MainRoutes';
import { AccountActionAsync } from '../../settings/account/store/AccountActionAsync';
import { OrganizationActionAsync } from '../../settings/organization/store/OrganizationActionAsync';
import { Timer } from '../common/timer/Timer';
import { VerificationCode } from '../common/verification-code/VerificationCode';
import { AuthActionAsync } from '../store/AuthActionAsync';
import { AuthActions } from '../store/AuthActions';

const isAdmin = window.location.origin === process.env.REACT_APP_HOST;

export const Confirmation = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const path = useLocation<IHistoryLocationState>();
  const [loading, setLoading] = useState(false);
  // const [token, setToken] = useState<string>();
  const [wrongCode, setWrongCode] = useState(false);
  const { action, actionHash, returnEmailInvitationData } = useAction();
  const [count, { startCountdown, stopCountdown, resetCountdown }] = useCountdown({
    countStart: 30,
    intervalMs: 1000
  });

  const successConfirmEmail = useCallback(
    (data: EmailAction) => {
      if (data.status === 'success.authenticate_user') {
        dispatch(AuthActions.authSetToken(data.payload.token));
        // setToken(data.payload.token);

        if (action && actionHash) {
          dispatch(OrganizationActionAsync.invitationEmail(actionHash, returnEmailInvitationData));
        } else {
          dispatch(
            AccountActionAsync.getMe(!isAdmin, data.payload.token, (account) => {
              setLoading(false);
              if (!isAdmin) {
                dispatch(AuthActions.auth.done({ result: data.payload.token, params: {} }));
                history.push({ pathname: mainPaths.main, search: location.search });
              } else if (!(account.profile.first_name && account.profile.last_name)) {
                history.push(mainPaths.updateAccount);
              }
            })
          );
        }
      } else if (data.status === 'error.confirmation_code_doesnt_match') {
        setLoading(false);
        setWrongCode(true);
      }
    },
    [action, dispatch, history, actionHash, location.search, returnEmailInvitationData]
  );

  const onChange = useCallback(
    (value: string) => {
      setWrongCode(false);
      if (value.length === 4 && path.state?.action_hash) {
        setLoading(true);
        dispatch(AuthActionAsync.confirmEmailByCode(path.state.action_hash, value, successConfirmEmail, onError));
      }
    },
    [path.state?.action_hash, dispatch, successConfirmEmail]
  );

  const onError = (error: IError) => {
    setLoading(false);
    if (error.data.status === 'error.confirmation_code_doesnt_match') {
      setWrongCode(true);
    }
  };

  useEffect(() => {
    startCountdown();
  }, [startCountdown]);

  const resendCode = useCallback(() => {
    if (path.state.credentials) {
      dispatch(
        AuthActionAsync.resendConfirmEmail(
          {
            email: path.state.credentials.email,
            callback_url: `${window.location.origin}/?link={hash}&action=${EmailActionStatusEnum.email_confirmation}`
          },
          (data) => {
            resetCountdown();
            startCountdown();
            setLoading(false);
            if (data?.action_hash) {
              history.push({
                pathname: mainPaths.confirmation,
                state: {
                  ...path.state,
                  action_hash: data.action_hash
                }
              });
            }
          }
        )
      );
    }
  }, [dispatch, history, path.state, resetCountdown, startCountdown]);

  // const onSaveCallback = useCallback(() => {
  //   if (token) {
  //     dispatch(
  //       AccountActionAsync.createOrganization(token, () => {
  //         dispatch(AuthActions.auth.done({ result: token, params: {} }));
  //         dispatch(
  //           AccountActionAsync.getMe(!isAdmin, token, () => {
  //             history.push(mainPaths.main);
  //           })
  //         );
  //       })
  //     );
  //   }
  // }, [dispatch, history, token]);

  useEffect(() => {
    if (path.state.resendCodeNow) {
      resendCode();
    }
  }, [resendCode]);

  return (
    <div className={styles.form}>
      <div className={styles.img}>
        <img src={confirmation} alt={''} />
      </div>
      <div className={styles.title}>Check your inbox</div>
      <div className={styles.message}>Please enter the code we emailed you at {path.state?.credentials?.email}</div>
      <VerificationCode onChange={onChange} disabled={loading} className={styles.digits} isWrong={wrongCode} />
      <div className={styles.skeleton}>
        {loading ? (
          <Icon32Spinner className={'spinner text-primary'} />
        ) : (
          wrongCode && <div className={'error'}>Wrong verification code</div>
        )}
      </div>
      <div className={classNames(styles.resend, { [styles.active]: !count && !loading })} onClick={resendCode}>
        Resend code
      </div>
      <div className={styles.timer}>{!!count && <Timer seconds={count} onFinish={stopCountdown} />}</div>
    </div>
  );
};
