import { FormCheck } from 'react-bootstrap';
import { Controller, useFormContext } from 'react-hook-form';
import Select from 'react-select';

import styles from './select-field.module.scss';

import { arrStrForSelect, getUUID } from '../../../../helpers';
import { ModifyField } from '../../../../modules/crm/field/store/FieldSelector';

interface IProps {
  field: ModifyField;
  isMulti?: boolean;
}

const styleOptions = {
  control: (styles: any) => ({
    ...styles,
    backgroundColor: 'white',
    ':hover': { borderBottom: '1px solid #0072ff' },
    ':focus': { borderBottom: '1px solid #005bcc' }
  }),
  multiValue: (styles: any) => ({
    ...styles,
    alignItems: 'center',
    backgroundColor: '#E0EEFF'
  }),
  multiValueRemove: (styles: any) => ({
    ...styles,
    width: '20px',
    height: '20px',
    marginRight: '3px',
    marginTop: '1px',
    borderRadius: '20px',
    cursor: 'pointer',
    color: '#8a9aa8',
    ':hover': {
      backgroundColor: '#0072ff',
      color: 'white'
    }
  })
};

export const SelectField = ({ field, isMulti = false }: IProps) => {
  const { register } = useFormContext();

  const opts = field.payload.options && arrStrForSelect(field.payload.options);

  return (
    <>
      {field.payload.display_options ? (
        <div className={styles.btnContainer}>
          {field.payload.options?.map((x, index) => (
            <FormCheck
              key={index}
              value={x}
              custom
              id={getUUID()}
              label={x}
              type={isMulti ? 'checkbox' : 'radio'}
              className={isMulti ? 'checkbox-button my-1' : 'radio-button my-1'}
              {...register(field.id)}
            />
          ))}
        </div>
      ) : (
        <Controller
          render={({ field }) => (
            <Select
              {...field}
              isMulti={isMulti}
              options={opts}
              className={'select-field-underline'}
              classNamePrefix={'select-field'}
              menuPlacement={'auto'}
              styles={styleOptions}
            />
          )}
          defaultValue={null}
          name={field.id}
        />
      )}
    </>
  );
};
