import { ReactNode } from 'react';
import { useSelector } from 'react-redux';

import { Loader } from '../../../../../components/UI';
import { LoadState } from '../../../../../store';
import { useBuilderContext } from '../../BuilderContext';

interface IProps {
  nodeId: string;
  children: ReactNode;
}

export const NodeCanvasLoader = ({ nodeId, children }: IProps) => {
  const { loadState } = useSelector((state) => state.builder);
  const { selectedNode } = useBuilderContext();
  return <>{loadState === LoadState.needLoad && selectedNode?.id === nodeId ? <Loader /> : children}</>;
};
