import { map, pick } from 'lodash';

import { IList } from '../../../../lists/store/ListsState';

export const filterLists = (listsIds: string[], lists: IList[]) => {
  const data = lists.filter((x) => listsIds?.includes(x.id));
  return map(data, (item) => pick(item, ['id', 'title']));
};

export const addToList = (selectedList: Pick<IList, 'id' | 'title'>[], lists: IList[], listId: string) => {
  const data = filterLists([listId], lists);
  return [...selectedList, ...data];
};

export const removeFromList = (selectedList: Pick<IList, 'id' | 'title'>[], listId: string) => {
  return selectedList.filter((x) => x.id !== listId);
};
