import { memo } from 'react';

import styles from './status-sent.module.scss';
import { Icon32Warning, IconReload } from '../../../../../../components/UI/icons';

import { Button } from '../../../../../../components/UI';

interface IProps {
  tryAgain: () => void;
}

export const ErrorSent = memo(({ tryAgain }: IProps) => {
  return (
    <div className={styles.container}>
      <Icon32Warning className={styles.warningErrorIcon} />
      <div className={styles.errorText}>An error has occurred!</div>
      <Button
        onClick={tryAgain}
        btnStyle={'transparent'}
        withIcon={false}
        iconPosition={'right'}
        customIcon={<IconReload />}
        size={'sm'}
      >
        Try again
      </Button>
    </div>
  );
});
