import { memo, useCallback, useEffect, useMemo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import { courseActionAsync } from '../../../../../lms/courses/store/CourseActionAsync';
import { CoursesSelector } from '../../../../../lms/courses/store/CourseSelector';
import { ICourse } from '../../../../../lms/courses/store/CourseState';
import { AccountSelector } from '../../../../../settings/account/store/AccountSelector';
import { ActionEnrollCourse } from './ActionEnrollCourse';

interface IProps {
  enrols: string[] | null;
  setSelectedCourses: (data: ICourse[]) => void;
  selectedCourses: ICourse[];
}

export const ActionEnrollWrapper = memo(({ enrols, selectedCourses, setSelectedCourses }: IProps) => {
  const dispatch = useDispatch();
  const { control } = useFormContext();
  const accountMemo = useMemo(AccountSelector, []);
  const { organizationId } = useSelector(accountMemo);
  const authToken = useSelector((state) => state.auth.authToken);
  const courseMemo = useMemo(CoursesSelector, []);
  const { courses } = useSelector((state) => courseMemo(state, 'published'));
  const enrollCourseIds = useWatch({
    control: control,
    name: 'action_enroll_in_courses'
  });

  const setSelectedData = useCallback(
    (data: ICourse[]) => {
      if (enrollCourseIds && Array.isArray(enrollCourseIds)) {
        const selected = enrollCourseIds
          .map((x) => data.find((y) => y.id === x))
          .filter((item): item is ICourse => !!item);
        setSelectedCourses(selected);
      }
    },
    [enrollCourseIds, setSelectedCourses]
  );

  const getCourses = useCallback(() => {
    if (authToken && organizationId) {
      dispatch(courseActionAsync.getCourses(setSelectedData));
    }
  }, [authToken, dispatch, organizationId, setSelectedData]);

  useEffect(() => {
    getCourses();
  }, [getCourses]);

  useEffect(() => {
    if (Array.isArray(enrollCourseIds)) {
      const data = courses.filter((x) => enrollCourseIds.includes(x.id));
      setSelectedCourses(data);
    }
  }, [courses, enrollCourseIds, getCourses, setSelectedCourses]);

  const isShow = Array.isArray(enrollCourseIds) || (typeof enrollCourseIds === 'boolean' && enrollCourseIds === true); //TODO: Check and refactor condition

  return (
    <ActionEnrollCourse
      enrols={enrols}
      isShow={isShow}
      courses={courses}
      selectedCourses={selectedCourses}
      setSelectedCourses={setSelectedCourses}
    />
  );
});
