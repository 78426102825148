import { CustomDispatch } from 'redux';

import { createField, deleteField, editField, getFields, reorderField } from '../../../../API/field-api';
import { FieldActions, IFieldCreateRequest, IFieldEditRequest } from './FieldActions';

export const FieldActionAsync = {
  getFields: (callback?: () => void) => async (dispatch: CustomDispatch) => {
    try {
      dispatch(FieldActions.getFields.started());
      const response = await getFields();
      if (response.data) {
        dispatch(FieldActions.getFields.done({ result: response.data }));
        callback?.();
      }
    } catch (error: any) {
      dispatch(FieldActions.getFields.failed({ error }));
    }
  },
  createField:
    ({ type, payload, entity }: IFieldCreateRequest) =>
    async (dispatch: CustomDispatch) => {
      try {
        dispatch(FieldActions.createField.started({ type, payload, entity }));
        const response = await createField({ type, payload, entity });
        if (response.data) {
          dispatch(FieldActions.createField.done({ result: response.data, params: { type, payload, entity } }));
        }
      } catch (error) {
        dispatch(FieldActions.createField.failed({ error, params: { type, payload, entity } }));
      }
    },
  editField:
    ({ id, payload }: IFieldEditRequest) =>
    async (dispatch: CustomDispatch) => {
      try {
        dispatch(FieldActions.editField.started({ id, payload }));
        const response = await editField({ id, payload });
        if (response.data) {
          dispatch(FieldActions.editField.done({ params: { id, payload }, result: response.data }));
        }
      } catch (error) {
        dispatch(FieldActions.editField.failed({ params: { id, payload }, error }));
      }
    },
  deleteField: (id: string) => async (dispatch: CustomDispatch) => {
    try {
      dispatch(FieldActions.deleteField.started({ id }));
      await deleteField(id);
      dispatch(FieldActions.deleteField.done({ params: { id } }));
    } catch (error) {
      dispatch(FieldActions.deleteField.failed({ error, params: { id } }));
    }
  },
  reorderField: (id: string, index: number, callback?: () => void) => async (dispatch: CustomDispatch) => {
    try {
      dispatch(FieldActions.reorderField.started({ id, index }));
      const result = await reorderField(id, index);
      if (result.data) {
        dispatch(FieldActions.reorderField.done({ params: { id, index }, result: result.data }));
        callback?.();
      }
    } catch (error) {
      dispatch(FieldActions.reorderField.failed({ error, params: { id, index } }));
    }
  }
};
