import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { OffersActionAsync } from '../store/OffersActionAsync';
import { useCreateOfferContext } from './CreateOfferContext';

export const useCreateOffer = <T extends unknown>(
  offerId: string,
  getOffer: () => void,
  onReset: () => void,
  isDirty?: boolean,
  onCancelOption?: () => void
) => {
  const dispatch = useDispatch();
  const { loadState } = useSelector((state) => state.offers);
  const { setSave, setShowUnsavedModal, save, showUnsavedModal, tabNavigationIndex, onNavigate } =
    useCreateOfferContext();
  const [showUpdate, setShowUpdate] = useState<boolean | undefined>(undefined);

  const onCancel = useCallback(() => {
    onCancelOption?.();
    onReset();
    setShowUnsavedModal(false);
    showUnsavedModal && onNavigate(tabNavigationIndex);
  }, [onCancelOption, onNavigate, onReset, setShowUnsavedModal, showUnsavedModal, tabNavigationIndex]);

  const onClose = useCallback(() => {
    setSave(false);
    setShowUnsavedModal(false);
  }, [setSave, setShowUnsavedModal]);

  const onSave = useCallback(
    (data: Partial<T>) => {
      dispatch(OffersActionAsync.editOffer(offerId, data, getOffer));
      setShowUpdate(false);
      setSave(false);
      setShowUnsavedModal(false);
      showUnsavedModal && onNavigate(tabNavigationIndex);
    },
    [dispatch, getOffer, offerId, onNavigate, setSave, setShowUnsavedModal, showUnsavedModal, tabNavigationIndex]
  );

  useEffect(() => {
    setSave(!!isDirty);
  }, [isDirty, setSave]);

  return { showUpdate, save, showUnsavedModal, loadState, onCancel, onClose, onSave };
};
