import { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';

import classnames from 'classnames';

interface IProps {
  isShow: boolean;
  fullHeight?: boolean;
  className?: string;
}

export const PopupCard: FC<IProps> = ({ isShow, fullHeight = false, className, children }) => {
  const isFieldSettingsShow = useSelector((state) => state.settings.isSidebarShow);

  useEffect(() => {
    const content = document.getElementsByClassName('content')[0];
    if (isFieldSettingsShow) {
      content?.classList.add('overflow-unset');
    } else {
      content?.classList.remove('overflow-unset');
    }
  }, [isFieldSettingsShow]);

  return isShow ? (
    <>
      <div className={classnames('sidebar sidebar-right', { 'full-height': fullHeight }, className)}>
        <div className="sidebar-container flex-column">{children}</div>
      </div>
      {fullHeight ? <div className="overlay" /> : null}
    </>
  ) : null;
};
