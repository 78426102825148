import { SkeletonTheme } from 'react-loading-skeleton';
import { ToastContainer } from 'react-toastify';
import { IntercomProvider } from 'react-use-intercom';

import 'rc-tree/assets/index.css';
import '../styles/index.scss';
import styles from '../styles/components/sidebar.module.scss';
import { Icon32Books, Icon32Cart, Icon32Feed } from './components/UI/icons';

import { Content } from './layout/content/Content';
import UserImpersonation from './layout/shared-components/impersonation/UserImpersonation';
import { UploadWorker } from './layout/shared-components/upload-worker/UploadWorker';
import { StudentCabinetApp } from './layout/student-cabinet/StudentCabinetApp';
import { StudentCabinetPaths } from './modules/student-cabinet/routes/StudentCabinetRoutes';

export const sidebarStudentCabinetMenu = [
  {
    name: 'Feed',
    icon: <Icon32Feed className={styles.navIcon} />,
    to: StudentCabinetPaths.feed
  },
  {
    name: 'My courses',
    icon: <Icon32Books className={styles.navIcon} />,
    to: StudentCabinetPaths.courses
  },
  {
    name: 'Marketplace',
    icon: <Icon32Cart className={styles.navIcon} />,
    to: StudentCabinetPaths.marketplace
  }
];

const intercomAppID = process.env.REACT_APP_INTERCOM_APP_ID!;
const isAdmin = window.location.host.split('.')[0] === 'app';
const isLocalhost = window.location.host.split('.')[0] === 'localhost:3000';

const App = () => {
  return (
    <SkeletonTheme borderRadius={4}>
      {/* {isAdmin ? ( */}
      {isAdmin || isLocalhost ? (
        <IntercomProvider appId={intercomAppID} autoBoot={true}>
          <Content />
          <UploadWorker />
        </IntercomProvider>
      ) : (
        <StudentCabinetApp />
      )}

      <UserImpersonation />

      <ToastContainer
        position="bottom-left"
        autoClose={2000}
        hideProgressBar={true}
        newestOnTop={false}
        closeButton={false}
      />
      <div id="error-root"></div>
    </SkeletonTheme>
  );
};

export default App;
