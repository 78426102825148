import { createSelector } from 'reselect';
import { intersectionWith, map } from 'lodash';

import { IAppState } from '../../../../store';
import { FieldScope } from '../../field';

export const ProductDataSelector = () =>
  createSelector(
    (state: IAppState) => state.products,
    (state: IAppState) => state.products.loadState,
    (state: IAppState) => state.products.error,
    (products, loadState, error) => {
      const productData = products.products.map((product) => {
        return { ...product.fields, id: product.id, data: product };
      });

      return {
        page: products.page,
        page_size: products.page_size,
        total: products.total,
        total_pages: products.total_pages,
        productData,
        loadState,
        error
      };
    }
  );

export const ProductSelector = () =>
  createSelector(
    (_: IAppState, productId: string | undefined) => productId,
    (state: IAppState) => state.products.products,

    (productId, products) => {
      const product = products.find((x) => x.id === productId);
      if (product) {
        return { product };
      }
    }
  );

export const ProductDataSearch = () =>
  createSelector(
    (_: IAppState, scopes: FieldScope[]) => scopes,
    (state: IAppState) => state.fields.fields,
    (state: IAppState) => state.products.products,
    (state: IAppState) => state.products.loadState,
    (state: IAppState) => state.products.error,
    (scopes, fields, products, loadState, error) => {
      const filteredField = intersectionWith(fields, scopes, (field, scope) => {
        return field.scope === scope && field.entity === 'product';
      });

      const filterProduct = map(products, (product) => {
        const field = filteredField.map((x) => {
          return { [x.id]: product.fields[x.id] };
        });
        return { ...product, fields: field.flat(1) };
      });

      return { productsFiltered: filterProduct, fields, loadState, error };
    }
  );
