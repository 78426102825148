import { memo, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import classNames from 'classnames';
import styles from '../student-courses.module.scss';
import navbarStyles from './course-navbar.module.scss';
// import { Icon32Bell, IconBurgerMenu, IconCross } from '../../../../components/UI/icons';
import Logotype from '../../../../../assets/svg/logo.svg';

import { imageUrl } from '../../../../components/UI/image-components/image/Image';
// import { Button } from '../../../../components/UI/button/Button';
import { SidebarAccount } from '../../../../layout/student-cabinet/sidebar-account/SidebarAccount';
import { LoadState } from '../../../../store';
import { StudentCabinetPaths } from '../../routes/StudentCabinetRoutes';
import { StudentCabinetSelector } from '../../store/StudentCabinetSelector';

interface IProps {
  onToggleCollapse: () => void;
  showMenuBtn: boolean;
}

export const CourseNavbar = memo(({ showMenuBtn }: IProps) => {
  const cabinetSettingsMemo = useMemo(StudentCabinetSelector, []);
  const { cabinetSettings, loadCabinetSettingsState } = useSelector((state) => cabinetSettingsMemo(state));
  const logo = useMemo(
    () =>
      cabinetSettings?.theme_logo
        ? imageUrl({
            bucket_name: cabinetSettings.theme_logo.bucket_name,
            object_name: cabinetSettings.theme_logo.object_name,
            size: { width: 130, height: 130 }
          })
        : Logotype,
    [cabinetSettings]
  );

  const loaded = loadCabinetSettingsState === LoadState.allIsLoaded || loadCabinetSettingsState === LoadState.error;

  return (
    <>
      <div className={classNames(styles.navbarIconContainer, !showMenuBtn ? styles.extend : '')}>
        {/*<div className={styles.bellContainer}>
          <Icon32Bell />
           <div className={styles.new}></div>
        </div>*/}
        <SidebarAccount dropPosition={'down'} className={'d-lg-none align-self-center'} />
      </div>
      <div className={classNames('d-lg-none', styles.logo)}>
        <Link to={StudentCabinetPaths.courses} className={navbarStyles.logo}>
          {loaded && <img src={logo} alt={cabinetSettings?.general_title ?? ''} className={navbarStyles.icon} />}
        </Link>
        {/*<Button
          customIcon={showMenuBtn ? <IconBurgerMenu /> : <IconCross />}
          withIcon={true}
          btnStyle={'secondary'}
          onClick={onToggleCollapse}
          className={'btn-icon-right'}
        >
          Menu
        </Button>*/}
      </div>
    </>
  );
});
