import { memo, useCallback, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { object, string } from 'yup';

import { CreateCohortModal, ICohortForm } from '../create-cohort-modal/CreateCohortModal';
import { PreparingCohortModal } from '../preparing-cohort-modal/PreparingCohortModal';

const schema = object().shape({
  suffix: string().required()
});

interface IProps {
  onClose(): void;
}

export const CreateCohort = memo(({ onClose }: IProps) => {
  const methods = useForm<ICohortForm>({
    resolver: yupResolver(schema),
    shouldUnregister: false
  });
  const [showPreparingCohort, setShowPreparingCohort] = useState(false);
  const [showCohort, setShowCohort] = useState(true);
  const [cohortData, setCohortData] = useState<ICohortForm>();

  const openPreparingCohort = useCallback((data: ICohortForm) => {
    setShowPreparingCohort(true);
    setShowCohort(false);
    setCohortData(data);
  }, []);

  const closePreparingCohort = useCallback(() => {
    setShowPreparingCohort(false);
  }, []);

  const backToCohort = useCallback(() => {
    closePreparingCohort();
    setShowCohort(true);
  }, [closePreparingCohort]);

  return (
    <>
      <FormProvider {...methods}>
        <CreateCohortModal show={showCohort} onClose={onClose} onContinue={openPreparingCohort} />
        {cohortData && (
          <PreparingCohortModal
            show={showPreparingCohort}
            onClose={closePreparingCohort}
            goBack={backToCohort}
            cohortData={cohortData}
          />
        )}
      </FormProvider>
    </>
  );
});
