import { ProgressBar } from 'react-bootstrap';
import { useDispatch } from 'react-redux';

import classNames from 'classnames';
import styles from './loader-video-status.module.scss';
import { Icon32CheckMark, Icon32Info } from '../../../../../components/UI/icons';

import { fileUploaderActions } from '../../store/FileUploaderActions';
import { IFileUploader } from '../../store/FileUploaderState';
import { LoaderVideoStatusButton } from '../loader-video-status-button/LoaderVideoStatusButton';

interface IProps {
  isUpload: boolean;
  isProcessing: boolean;
  isComplete: boolean;
  remainingCount: number;
  totalUploadingPercent: number;
  canceling: IFileUploader[];
  error: IFileUploader[];
  onCancelHandler: () => void;
}

const renderElementByStatus = (
  props: Omit<IProps, 'onCancelHandler'>,
  isCanceling: boolean,
  isError: boolean,
  onlyFailed: boolean
) => {
  const countFail = props.canceling.length + props.error.length;

  if (props.isUpload) {
    return (
      <div>
        <div className={styles.title}>Uploading &#8226; {props.totalUploadingPercent}%</div>
        <div className={styles.description}>Remaining files: {props.remainingCount}</div>
      </div>
    );
  } else if (props.isProcessing) {
    return (
      <div>
        <div className={styles.title}>Processing...</div>
        <div className={styles.description}>It takes a few minutes</div>
      </div>
    );
  } else if (props.isComplete) {
    return (
      <div className={styles.finishContainer}>
        <Icon32CheckMark className={styles.finishIcon} />
        <div>
          <div className={styles.title}>Upload completed</div>
          {countFail > 0 && (
            <div className={styles.failText}>
              Not uploaded: {countFail} file{countFail > 1 && 's'}
            </div>
          )}
        </div>
      </div>
    );
  } else if (isCanceling) {
    return (
      <div>
        <div className={styles.title}>Upload has been canceled</div>
      </div>
    );
  } else if (isError) {
    return (
      <div className={styles.finishContainer}>
        <Icon32Info className={styles.finishIcon} />
        <div>
          <div className={styles.title}>An error has occurred</div>
          <div className={styles.failText}>Failed to complete upload</div>
        </div>
      </div>
    );
  } else if (onlyFailed) {
    return (
      <div className={styles.finishContainer}>
        <Icon32CheckMark className={styles.finishIcon} />
        <div>
          <div className={styles.title}>Upload completed</div>
          {countFail > 0 && (
            <div className={styles.failText}>
              Not uploaded: {countFail} file{countFail > 1 && 's'}
            </div>
          )}
        </div>
      </div>
    );
  }
};

export const LoaderVideoStatus = (props: IProps) => {
  const dispatch = useDispatch();
  const onCollapseHandler = () => {
    dispatch(fileUploaderActions.setShowUploader({ show: false }));
  };

  const isCanceling =
    !props.isUpload && !props.isProcessing && !props.isComplete && props.canceling && props.error.length === 0;

  const isError =
    !props.isUpload && !props.isProcessing && !props.isComplete && props.error && props.canceling.length === 0;

  const onlyFailed = !props.isUpload && !props.isProcessing && !props.isComplete && !!props.error && !!props.canceling;

  return (
    <>
      <div className={styles.container}>
        {renderElementByStatus(props, isCanceling, isError, onlyFailed)}
        <LoaderVideoStatusButton
          onCancel={props.onCancelHandler}
          onCollapse={onCollapseHandler}
          isUpload={props.isUpload}
        />
      </div>
      <ProgressBar
        now={props.totalUploadingPercent}
        className={classNames(
          styles.progress,
          { [styles.success]: props.isProcessing || props.isComplete || onlyFailed },
          { [styles.notLoad]: isError || isCanceling }
        )}
        animated
      />
    </>
  );
};
