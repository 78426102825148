import { reducerWithInitialState } from 'typescript-fsa-reducers';

import { commonErrorHandler, commonFirstLoadHandler } from '../../../../helpers/common-reducer-handlers';
import { LoadState, newState } from '../../../../store';
import { PolicyActions } from './PolicyActions';
import { IPolicyState, PolicyInitialState } from './PolicyState';

export const policyReducer = reducerWithInitialState<IPolicyState>(PolicyInitialState)
  .case(PolicyActions.getPolicyList.started, commonFirstLoadHandler)
  .case(PolicyActions.getPolicyList.done, (state, { result }) => {
    return newState(state, {
      ...state,
      error: null,
      loadState: LoadState.allIsLoaded,
      policyList: result
    });
  })
  .case(PolicyActions.getPolicyList.failed, commonErrorHandler)

  .case(PolicyActions.createPolicy.started, commonFirstLoadHandler)
  .case(PolicyActions.createPolicy.done, (state, { result }) => {
    return newState(state, {
      error: null,
      loadState: LoadState.allIsLoaded,
      policyList: [...state.policyList, result]
    });
  })
  .case(PolicyActions.createPolicy.failed, commonErrorHandler)

  .case(PolicyActions.editPolicy.started, commonFirstLoadHandler)
  .case(PolicyActions.editPolicy.done, (state, { result }) => {
    const updatedPolicyList = state.policyList.map((x) => (x.id === result.id ? result : x));
    return newState(state, {
      ...state,
      error: null,
      loadState: LoadState.allIsLoaded,
      policyList: updatedPolicyList
    });
  })
  .case(PolicyActions.editPolicy.failed, commonErrorHandler)

  .case(PolicyActions.deletePolicy.started, commonFirstLoadHandler)
  .case(PolicyActions.deletePolicy.done, (state, { params }) => {
    return newState(state, {
      error: null,
      loadState: LoadState.allIsLoaded,
      policyList: state.policyList.filter((x) => x.id !== params.id)
    });
  })
  .case(PolicyActions.deletePolicy.failed, commonErrorHandler);
