import { useHistory } from 'react-router-dom';

import styles from './blockAccount.module.scss';
import { Icon32Reload, IconLock } from '../../../../components/UI/icons';

import { Button } from '../../../../components/UI';
import { SettingsPaths } from '../../../settings/routes/SettingsRoutes';
import { Block } from '../block/Block';

export const BlockAccount = () => {
  const history = useHistory();
  const onRedirect = () => {
    history.push(SettingsPaths.billing);
  };
  return (
    <Block className={'p-4'}>
      <div>
        <div className={styles.title}>
          Your account is blocked
          <IconLock className={styles.icon} />
        </div>
        <div className={styles.description}>To unlock the access you need to renew subscription.</div>
        <div className={'d-flex justify-content-between mt-4'}>
          <Button
            onClick={onRedirect}
            withIcon={true}
            iconPosition={'left'}
            customIcon={<Icon32Reload className={'mr-2'} />}
            className={styles.btn}
          >
            Renew subscription
          </Button>
          <div className={styles.textContainer}>
            <div className={styles.dayLeftTxt}>3 days left</div>
            <div className={styles.text}>to prevent data deletion</div>
          </div>
        </div>
      </div>
    </Block>
  );
};
