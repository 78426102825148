import { memo } from 'react';

import styles from './quiz-mot-passed-modal.module.scss';
import { Icon32Info } from '../../../../components/UI/icons';

import { Button, CommonModal } from '../../../../components/UI';
import { ICourseBlockQuiz } from '../../../../interfaces';
import { QuizItemModal } from './QuizItemModal';

interface IProps {
  show: boolean;
  onClose: () => void;
  unfinishedQuizzes: ICourseBlockQuiz[];
}

export const QuizNotPassedModal = memo(({ show, unfinishedQuizzes, onClose }: IProps) => {
  return (
    <CommonModal onClose={onClose} show={show} showClose={true} params={{ contentClassName: styles.modalSize }}>
      <div className={styles.wrapper}>
        <div className={styles.container}>
          <Icon32Info className={styles.iconInfo} />
          <div className={styles.title}>You need to pass a quiz</div>
        </div>
        <div className={styles.descriptionContainer}>
          <div className={styles.description}>
            Quizzes must be completed in order to mark <br /> this lesson as completed
          </div>
          {unfinishedQuizzes.map((quiz) => (
            <QuizItemModal
              key={quiz.id}
              title={quiz.title || ''}
              nodeIdWithQuiz={quiz.parent_id}
              onCloseModal={onClose}
            />
          ))}

          <Button
            onClick={onClose}
            btnStyle={'secondary'}
            className={styles.btn}
            withIcon={false}
            size={'lg'}
          >
            Close
          </Button>
        </div>
      </div>
    </CommonModal>
  );
});
