import {
  EdgeRequest,
  GeometryRequest,
  GeometryResponse,
  IEdge,
  IFunnelNodeBuilder,
  NodeEndpoint,
  NodeTypes,
  PaletteElementTypes
} from '../interfaces';
import { baseFetch } from './BaseFetch';

export const addFunnelNode = (funnelKey: string, type: NodeTypes) => {
  return baseFetch<{}, IFunnelNodeBuilder<PaletteElementTypes>>(`/funnels/${funnelKey}/nodes`, { type }, 'POST', true);
};

export const getFunnelNode = (funnelKey: string, nodeKey: string) => {
  return baseFetch<{}, IFunnelNodeBuilder<PaletteElementTypes>>(
    `/funnels/${funnelKey}/nodes/${nodeKey}`,
    {},
    'GET',
    true
  );
};

export const saveFunnelNode = (funnelKey: string, nodeKey: string, data: Record<string, string | undefined>) => {
  return baseFetch<{}, IFunnelNodeBuilder<PaletteElementTypes>>(
    `/funnels/${funnelKey}/nodes/${nodeKey}`,
    { ...data },
    'PUT',
    true
  );
};

export const deleteFunnelNode = (funnelKey: string, nodeKey: string) => {
  return baseFetch<{}, IFunnelNodeBuilder<PaletteElementTypes>>(
    `/funnels/${funnelKey}/nodes/${nodeKey}`,
    {},
    'DELETE',
    true
  );
};

export const saveFunnelGeometry = (funnelKey: string, data: GeometryRequest) => {
  return baseFetch<{}, GeometryResponse>(`/funnels/${funnelKey}/geometry`, { ...data }, 'PUT', true);
};

export const saveFunnelEdge = (funnelKey: string, edge: EdgeRequest) => {
  return baseFetch<{}, IEdge>(`/funnels/${funnelKey}/edges`, { ...edge }, 'POST', true);
};

export const deleteFunnelEdge = (funnelKey: string, edgeKey: string) => {
  return baseFetch<{}, IEdge>(`/funnels/${funnelKey}/edges/${edgeKey}`, {}, 'DELETE', true);
};

export const getFunnelEndpoints = (funnelId: string, nodeId: string) => {
  return baseFetch<{}, NodeEndpoint[]>(`/funnels/${funnelId}/nodes/${nodeId}/endpoints`, {}, 'GET', true);
};

export const createFunnelEndpoint = (funnelId: string, nodeId: string, title?: string) => {
  return baseFetch<{ title?: string }, NodeEndpoint>(
    `/funnels/${funnelId}/nodes/${nodeId}/endpoints`,
    { title },
    'POST',
    true
  );
};

export const editFunnelEndpoint = (funnelId: string, nodeId: string, endpointId: string, title: string) => {
  return baseFetch<{ title: string }, NodeEndpoint>(
    `/funnels/${funnelId}/nodes/${nodeId}/endpoints/${endpointId}`,
    { title },
    'PUT',
    true
  );
};

export const deleteFunnelEndpoint = (funnelId: string, nodeId: string, endpointId: string) => {
  return baseFetch<{}, NodeEndpoint>(
    `/funnels/${funnelId}/nodes/${nodeId}/endpoints/${endpointId}`,
    {},
    'DELETE',
    true
  );
};
