import React from 'react';
import { useSelector } from 'react-redux';

import styles from './runs-list.module.scss';
import { Icon32MultimediaPlayFilled } from '../../../../components/UI/icons';

import { EmptyList } from '../../../../components/empty-list/EmptyList';
import { Table } from '../../../../components/UI';
import { PopupCard } from '../../../../components/UI/popup-card/PopupCard';
import { useIntercomUpdate } from '../../../../hooks/use-intercom-update';
import { PayloadType, useCommonPayload } from '../../../../hooks/useCommonPayload';
import { usePayload } from '../../../../hooks/usePayload';
import { IPaginationResponse, IRun } from '../../../../interfaces';
import { PageTopPanel } from '../../../../layout/shared-components/page-top-panel/PageTopPanel';
import { ContactCard } from '../../../crm/contact/contact-card/ContactCard';
import { FieldsEdit } from '../../../crm/fields/FieldsEdit';
import { useRunsTable } from '../libs/useRunsTable';
import { RunDetails } from '../run-details/RunDetails';

interface IProps {
  data: IPaginationResponse<IRun[]>;
}

export const RunsList = ({ data: { data, meta } }: IProps) => {
  const { isPayloadShow } = usePayload();
  const [isShowDetails] = useCommonPayload(PayloadType.runDetails);
  const { isSidebarShow: isShowFieldSettings } = useSelector((state) => state.settings);
  const { columns, contactId, setContactId } = useRunsTable();

  useIntercomUpdate(isPayloadShow);
  useIntercomUpdate(isShowDetails, 20, 372);

  return data.length === 0 ? (
    <EmptyList
      title={'No running workflow logs'}
      icon={<Icon32MultimediaPlayFilled width={64} height={64} />}
      description={'The list of logs will appear after the first clients will be processed through the workflow'}
    />
  ) : (
    <>
      <PageTopPanel title={'Runs'} />
      <Table columns={columns} data={data} pageCount={meta.total_pages} />
      <PopupCard isShow={isShowDetails} className={styles.details}>
        {contactId && <RunDetails contactId={contactId} />}
      </PopupCard>
      <PopupCard isShow={isPayloadShow}>
        <ContactCard setContactId={setContactId} contactId={contactId} />
      </PopupCard>
      <PopupCard isShow={isShowFieldSettings} fullHeight={true}>
        <FieldsEdit
          entity={'contact'}
          title={'Edit contact fields'}
          description={
            'Change the order and the content for the working fields. The settings are applied to all contacts.'
          }
        />
      </PopupCard>
    </>
  );
};
