import { CommonCourseBlock, CourseNodeType, IFile } from '../../../../interfaces';
import { LoadState } from '../../../../store';

export enum CourseStates {
  DRAFT = 'draft',
  ARCHIVED = 'archived',
  PUBLISHED = 'published',
  DELETED = 'deleted'
}

export type CurriculumState = 'draft' | 'published';

export type CourseState = `${CourseStates}`;

export interface ICourseNode {
  children: ICourseNode[];
  condition: NodeCondition;
  course_id: string;
  created_at: number;
  id: string;
  is_new: boolean;
  is_passed: boolean;
  is_updated: boolean;
  order: number;
  organization_id: string;
  passed_at: string;
  parent_id: string;
  setting_custom_code: string | null;
  setting_custom_code_enabled: boolean;
  state: CurriculumState;
  title: string;
  show_comments: boolean | null;
  type: CourseNodeType;
  updated_at: number;
}

export interface ILecture extends Omit<ICourseNode, 'children'> {}

export type StudentLessonErrorStatus =
  | 'fail.course.curriculum.drafted_parent'
  | 'fail.course.not_enrolled'
  | 'fail.course.violated_condition'
  | 'fail.course.node_not_found';
export type StudentLessonResponse = ILecture[] | NodeCondition;

export interface FlattenLessons {
  currentStep?: ICourseNode;
  nextStep?: ICourseNode;
  prevStep?: ICourseNode;
  next: ICourseNode;
  prev: ICourseNode;
  all: ICourseNode[];
  allSteps: ICourseNode[];
}

export type NodeCondition = {
  type: 'delayed';
  open_at: string;
} | null;

export interface IRequestCohort {
  course_id: string;
  offers?: string[];
  suffix?: string;
}

export enum CourseCategories {
  ART_AND_ENTERTAINMENT = 'art_and_entertainment',
  BUSINESS = 'business',
  COMMUNITY = 'community',
  DESIGN_AND_STYLE = 'design_and_style',
  DEVELOPMENT = 'development',
  FOOD = 'food',
  WRITING = 'writing',
  GAMING_AND_SPORTS = 'gaming_and_sports',
  HOME_AND_LIFESTYLE = 'home_and_lifestyle',
  MARKETING = 'marketing',
  MUSIC = 'music',
  SCIENCE_AND_TECH = 'science_and_tech',
  WELLNESS_AND_BEAUTY = 'wellness_and_beauty'
}

export type CourseCategoriesType = `${CourseCategories}`;

enum CourseTemplates {
  simple = 'simple',
  advanced = 'advanced',
  coaching = 'coaching',
  drip = 'drip',
  cohort = 'cohort',
  custom = 'custom'
}

export type CourseTemplatesType = `${CourseTemplates}`;

export interface ICourseSettings {
  category: CourseCategoriesType | null;
  custom_code: string | null;
  thumbnail: IFile | null;
  progress_sequential_flow: boolean;
}

export interface ICourse {
  comment_count: number;
  date_create: number;
  id: string;
  industry: CourseCategoriesType;
  organization_id: string;
  rating: number;
  state: CourseState;
  student_count: number;
  title: string;
  date_update: number;
  lessons_counts: number;
  lessons_pass: number;
  last_lesson: {
    title: string;
    id: string;
  } | null;
  children: ICourseNode[];
  settings: ICourseSettings;
}

export interface ICourseState {
  error: Error | null;
  loadState: LoadState;
  reorderLoadState: LoadState;
  courses: ICourse[];
  lectures: ILecture[];
  payload: CommonCourseBlock[];
  showPopupIfParentDraft: boolean;
}

export const CourseInitialState: ICourseState = {
  error: null,
  loadState: LoadState.firstLoad,
  reorderLoadState: LoadState.firstLoad,
  lectures: [],
  courses: [],
  payload: [],
  showPopupIfParentDraft: false
};
