import { ReactNode, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import styles from './lesson-thread-modal.module.scss';
import { IconWarning } from '../../../../../../../components/UI/icons';

import { CommonModal } from '../../../../../../../components/UI';
import { InfoMessage } from '../../../../../../../components/UI/infoMessage/InfoMessage';
import { IComment, IFile, IPaginationResponse } from '../../../../../../../interfaces';
import { PostComment } from '../../../../../../comment/post-comment/PostComment';
import { courseActionAsync } from '../../../../store/CourseActionAsync';
import { LessonThread } from '../lesson-thread/LessonThread';

type Props = {
  show: boolean;
  threadId: string;
  commentsShow?: boolean;
  showInfoMessage?: boolean;
  onClose: () => void;
  title?: JSX.Element;
  children?: ReactNode;
};

export const LessonThreadModal = ({
  onClose,
  title,
  show,
  threadId,
  commentsShow,
  showInfoMessage,
  children
}: Props) => {
  const dispatch = useDispatch();
  const headerRef = useRef<HTMLDivElement>(null);
  const scrollRef = useRef<HTMLDivElement>(null);
  const { profile } = useSelector((state) => state.account.account);
  const src = {
    bucket_name: (profile.photo as IFile)?.bucket_name,
    object_name: (profile.photo as IFile)?.object_name
  };
  const [comments, setComments] = useState<IPaginationResponse<IComment[]>>({
    data: [],
    meta: { page: 0, page_size: 25, total: 0, total_pages: 1 }
  });

  const onCommentPost = (content: string) => {
    dispatch(
      courseActionAsync.postComments({ content, threadId }, (comment: IComment) => {
        setComments((prev) => {
          return {
            data: [comment, ...prev.data],
            meta: { ...prev.meta, total: (prev.meta.total += 1) }
          };
        });
        if (scrollRef.current) {
          scrollRef.current?.scrollTo({ top: 0, behavior: 'smooth' });
        }
      })
    );
  };

  return (
    <CommonModal
      show={show}
      onClose={onClose}
      showClose={true}
      params={{
        size: 'lg',
        dialogClassName: styles.modal,
        contentClassName: styles.modalContent,
        className: styles.modalContain
      }}
      closeButtonClassName={styles.closeBtn}
    >
      <LessonThread
        threadId={threadId}
        comments={comments}
        setComments={setComments}
        ref={scrollRef}
        showToggle={false}
      >
        <div className={styles.header} ref={headerRef}>
          <div className={styles.comments}>
            {title ? (
              <>{title}</>
            ) : (
              <>Comments {comments.meta.total > 0 && <span>&#x2022; {comments.meta.total}</span>}</>
            )}
          </div>
          {children}

          <div className={styles.postCommentContainer}>
            <PostComment avatar={src} onPostComment={onCommentPost} />
          </div>
        </div>
        {showInfoMessage && (
          <InfoMessage
            show={!commentsShow}
            text={'The sections with comments has been hidden from public access'}
            customIcon={<IconWarning className={styles.warningColor} />}
            className={styles.info}
            textClassName={styles.textWarning}
          />
        )}
      </LessonThread>
    </CommonModal>
  );
};
