import { useEffect } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';
import AutosizeInput from 'react-input-autosize';
import { useDispatch } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { object, string } from 'yup';
import { capitalize } from 'lodash';

import classNames from 'classnames';
import styles from './domain-edit.module.scss';

import { Button, SpinnerLoader } from '../../../../../components/UI';
import { subdomainPattern } from '../../../../../helpers';
import { useLoading } from '../../../../../hooks/use-loading';
import { ICustomDomainFormValues } from '../../connect-new-domain/ConnectNewDomain';
import { useDomainContext } from '../../DomainContext';
import { domainActionAsync } from '../../store/DomainSettingsActionAsync';

interface IProps {
  onEditEnd: () => void;
}

const schema = object().shape({
  subdomain: string().required().matches(subdomainPattern, {
    message: 'Allowed symbols: a-z, 0-9.',
    excludeEmptyString: true
  })
});

export const DomainEdit = ({ onEditEnd }: IProps) => {
  const { loading, startLoading, stopLoading } = useLoading();
  const domain = useDomainContext();
  const dispatch = useDispatch();

  const methods = useForm<Pick<ICustomDomainFormValues, 'subdomain'>>({
    resolver: yupResolver(schema),
    shouldFocusError: true,
    mode: 'all',
    defaultValues: {
      subdomain: domain?.subdomain || undefined
    }
  });
  const subdomainData = useWatch<Pick<ICustomDomainFormValues, 'subdomain'>>({
    control: methods.control,
    name: 'subdomain'
  });

  const onStopEditing = () => {
    stopLoading();
    onEditEnd();
  };
  const onSave = () => {
    if (domain && domain.subdomain && subdomainData && subdomainData !== domain.subdomain) {
      startLoading();
      dispatch(
        domainActionAsync.editStudentDomain(
          { domainId: domain.id, subdomain: subdomainData },
          onStopEditing,
          stopLoading
        )
      );
    }
  };

  const onClick = () => {
    methods.setFocus('subdomain', {
      shouldSelect: true
    });
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && methods.formState.isValid) {
      onSave();
    }
  };

  useEffect(() => {
    methods.setFocus('subdomain', {
      shouldSelect: true
    });
  }, [methods]);

  return (
    <div className={styles.container}>
      {loading ? (
        <div className={'d-flex justify-content-center w-100'}>
          <SpinnerLoader variant={'primary'} />
        </div>
      ) : (
        <>
          <div>
            <div
              className={classNames(
                'form-control form-control-sm',
                styles.inputContainer,
                methods.formState.errors.subdomain && 'invalid'
              )}
              onClick={onClick}
            >
              <Controller
                render={({ field: { onChange, value, ref } }) => (
                  <AutosizeInput
                    autoFocus
                    onKeyDown={handleKeyDown}
                    className={styles.autosize}
                    onChange={onChange}
                    value={value || ''}
                    ref={ref}
                  />
                )}
                name={'subdomain'}
                control={methods.control}
                defaultValue={domain?.subdomain || undefined}
              />

              <div onClick={onClick} className={styles.suffix}>
                .{domain?.domain}
              </div>
            </div>
            {methods.formState.errors.subdomain && (
              <div className={'error'}>{capitalize(methods.formState.errors.subdomain.message)}</div>
            )}
          </div>
          <div className={styles.btnContainer}>
            <Button onClick={methods.handleSubmit(onSave)} type={'update'} className={'mx-2'} size={'sm'}>
              Save
            </Button>
            <Button onClick={onEditEnd} withIcon={false} btnStyle={'transparent'} size={'sm'}>
              Cancel
            </Button>
          </div>
        </>
      )}
    </div>
  );
};
