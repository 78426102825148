import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { find, flatMapDeep } from 'lodash';

import classNames from 'classnames';
import styles from './dropmove.module.scss';
import { IconFolder, IconFolderNew } from '../../../../../components/UI/icons';

import { flatten } from '../../../../../components/course/course/CourseHelpers';
import { Button } from '../../../../../components/UI';
import { coursePaths } from '../../routes/CourseRoutes';
import { courseActionAsync } from '../../store/CourseActionAsync';
import { ICourseNode } from '../../store/CourseState';
import { CourseDropMenu } from '../dropmenu/CourseDropMenu';
import { useCourseContext } from '../sidebar/CourseContext';
import { DropTitle } from './DropTitle';
import { EmptyDrop } from './EmptyDrop';

interface IProps {
  onClose: (e?: React.SyntheticEvent<HTMLDivElement | HTMLButtonElement>) => void;
}

export const DropMove = memo(({ onClose }: IProps) => {
  const pathCurriculum = useRouteMatch<{ id: string }>(coursePaths.courseLessons());
  const path = useRouteMatch<{ id: string; nodeId: string }>(coursePaths.courseLessons(':id', ':nodeId'));
  const course = useSelector((state) => state.courses.courses.find((x) => x.id === pathCurriculum?.params.id));
  const [currentHierarchyDrop, setCurrentHierarchyDrop] = useState<ICourseNode[]>([]);
  const [currentNav, setCurrentNav] = useState<ICourseNode | null>(null);
  const [isCreate, setIsCreate] = useState(false);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const history = useHistory();
  const dispatch = useDispatch();
  const { currentNode, getHierarchy, setCurrentHierarchy } = useCourseContext();

  const setInitial = useCallback(() => {
    const folders = course?.children.filter((x) => x.type === 'folder');
    setCurrentHierarchyDrop(folders || []);
    setCurrentNav(null);
  }, [course?.children]);

  const setCurrent = useCallback(
    (id?: string) => {
      const node = find<ICourseNode | undefined>(flatMapDeep(course?.children, flatten), ['id', id]);
      if (node) {
        setCurrentNav(node);
        const folders = node.children?.filter((x) => x.type === 'folder');
        setCurrentHierarchyDrop(folders || []);
      } else {
        setInitial();
      }
    },
    [course?.children, setInitial]
  );

  useEffect(() => {
    setCurrent(currentNav?.id);
  }, [currentNav?.id, setCurrent]);

  const onCreate = () => {
    setIsCreate(true);
  };

  useEffect(() => {
    inputRef.current?.focus();
  }, [isCreate]);

  const onHideCreation = () => {
    setIsCreate(false);
  };

  const onHandlerMove = () => {
    if (pathCurriculum?.params.id && currentNode?.id) {
      const result = find<ICourseNode | undefined>(flatMapDeep(course?.children, flatten), ['id', currentNode?.id]);
      dispatch(
        courseActionAsync.editNode(
          pathCurriculum.params.id,
          currentNode.id,
          { parent_id: currentNav ? currentNav.id : null },
          () => {
            getHierarchy();
            onClose();
          }
        )
      );
      if (result) {
        const newPath = find<ICourseNode | undefined>(flatMapDeep(course?.children, flatten), ['id', currentNav?.id]);
        if (newPath?.children && currentNode.id === path?.params.nodeId && currentNode.type === 'lesson') {
          setCurrentHierarchy(newPath.children);
          history.push(coursePaths.courseLessons(pathCurriculum?.params.id, currentNav?.id));
        }
      }
    }
  };

  const toPrevFolder = () => {
    setCurrent(currentNav?.parent_id);
  };

  const toNextFolder = (e: React.SyntheticEvent<HTMLDivElement>) => {
    const id = e.currentTarget.dataset['id'];
    setCurrent(id);
  };

  return (
    <div className={classNames('d-flex flex-column', styles.container)} onClick={(e) => e.stopPropagation()}>
      <DropTitle
        onHideCreation={onHideCreation}
        currentHierarchy={currentHierarchyDrop}
        currentNav={currentNav}
        onClose={onClose}
        toPrevFolder={toPrevFolder}
        isCreate={isCreate}
        ref={inputRef}
      />
      <div className={styles.scrollContainer}>
        {currentHierarchyDrop.length > 0 &&
          !isCreate &&
          currentHierarchyDrop.map((x, index) => (
            <CourseDropMenu
              containerStyles={styles.paddingContainer}
              key={index}
              icon={<IconFolder />}
              title={x.title}
              id={x.id}
              onClick={toNextFolder}
              isDisable={currentNode?.id === x.id}
            />
          ))}
        {currentHierarchyDrop.length === 0 && !isCreate && <EmptyDrop text={'This folder is empty'} />}
        {isCreate && <EmptyDrop text={`Create folder in “${currentNav?.title || 'course'}”`} />}
      </div>
      <div className={classNames('d-flex justify-content-between align-items-center mt-auto', styles.footerContainer)}>
        <div className={classNames('d-flex align-items-center pl-2', styles.newFolderContainer)}>
          <Button onClick={onCreate} btnStyle={'outline'} className={styles.btnCreate} withIcon={false}>
            <span className={styles.newFolderText}>New folder</span>
            <IconFolderNew />
          </Button>
        </div>

        <Button withIcon={false} btnStyle={'secondary'} onClick={onHandlerMove} className={styles.btn}>
          Move to this folder
        </Button>
      </div>
    </div>
  );
});
