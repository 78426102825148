import { memo, ReactNode, useEffect, useRef, useState } from 'react';

import classNames from 'classnames';
import styles from './arrowScroll.module.scss';

import { Icon16ChevronLeft, Icon16ChevronRight } from '../icons';

interface IProps {
  sectionsLength: number;
  children: ReactNode;
  nodeId?: string;
  className?: string;
}

export const ArrowScroll = memo(({ sectionsLength, nodeId, className, children }: IProps) => {
  const scrollStep = (sectionsLength / 3) * 80;
  const draggableRef = useRef<HTMLDivElement>(null);
  const [showArrows, setShowArrows] = useState(false);

  const scroll = (scrollOffset: number) => {
    if (draggableRef.current) {
      draggableRef.current.scrollLeft += scrollOffset;
    }
  };
  const leftScroll = () => {
    scroll(-scrollStep);
  };

  const rightScroll = () => {
    scroll(scrollStep);
  };

  useEffect(() => {
    setTimeout(() => {
      if (draggableRef.current) {
        setShowArrows(draggableRef.current.clientWidth < draggableRef.current.scrollWidth);
      }
      const scrollRect = draggableRef.current?.getBoundingClientRect();
      if (draggableRef.current?.children) {
        const currentNode = Array.from(draggableRef.current.getElementsByTagName('div')).find((x) => x.id === nodeId);

        const activeRect = currentNode?.getBoundingClientRect();
        if (activeRect && scrollRect) {
          scroll(activeRect.left - scrollRect.left - scrollRect.width / 2 + activeRect.width / 2);
        }
      }
    }, 50);
  }, [nodeId, sectionsLength]);

  return (
    <>
      <div className={styles.dragContainer}>
        <div className={classNames(className, styles.draggableContainer)} ref={draggableRef}>
          {children}
        </div>
      </div>
      {showArrows && (
        <div className={classNames(styles.arrowContainer)}>
          <div className={styles.iconArrowScroll} onClick={leftScroll}>
            <Icon16ChevronLeft className={styles.icon} />
          </div>
          <div className={styles.iconArrowScroll}>
            <Icon16ChevronRight className={styles.icon} onClick={rightScroll} />
          </div>
        </div>
      )}
    </>
  );
});
