import { memo } from 'react';

import classNames from 'classnames';
import styles from './trial-billet.module.scss';
import { IconArrowRight } from '../../../../components/UI/icons';

import { Block } from '../block/Block';

interface IProps {
  left: number;
  dateEnd: string;
  onUpgradeHandler: () => void;
  haveCard: boolean;
  fromBilling?: boolean;
}

export const TrialVersionBillet = memo(({ dateEnd, left, haveCard, fromBilling = false, onUpgradeHandler }: IProps) => {
  return (
    <Block className={classNames({ [styles.fixedHeight]: fromBilling }, 'p-4')}>
      <div className={'d-flex justify-content-between'}>
        <h4>You are on trial version</h4>
        {!haveCard && !fromBilling && (
          <div onClick={onUpgradeHandler} className={'btn btn-sm btn-icon-right btn-transparent'}>
            Upgrade your account
            <IconArrowRight className={'icon'} />
          </div>
        )}
      </div>
      <div className={styles.trialDate}>The trial ends on {dateEnd}</div>
      <h2 className={styles.daysLeft}>{left} days left</h2>
    </Block>
  );
});
