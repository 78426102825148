import React, { memo, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import classNames from 'classnames';
import styles from './plan-item.module.scss';

import { billingActionAsync, IPlanDataOption, IPlanPrice } from '../../store';
import { ChoosePlanBtn } from './choose-plan/ChoosePlanBtn';
import { OptionItemPlan } from './option-item/OptionItemPlan';

interface IProps {
  id: string;
  isPopular: boolean;
  title: string;
  isCanceled: boolean;
  monthly: boolean;
  options: IPlanDataOption[];
  month: IPlanPrice;
  year: IPlanPrice;

  cancelSubscription: () => void;
  changePlan: (e: React.MouseEvent<HTMLButtonElement>) => void;
  isCurrent: (id: string) => boolean;
}

export const PlanItem = memo(
  ({
    id,
    isPopular,
    title,
    isCanceled,
    monthly,
    options,
    month,
    year,
    changePlan,
    cancelSubscription,
    isCurrent
  }: IProps) => {
    const dispatch = useDispatch();

    const getSubscription = useCallback(() => {
      dispatch(billingActionAsync.getSubscription());
    }, [dispatch]);

    const onRenew = useCallback(() => {
      dispatch(billingActionAsync.renewSubscription(false, getSubscription));
    }, [dispatch, getSubscription]);

    return (
      <div className={classNames('col', styles.planContainer)}>
        <div
          className={classNames(
            styles.plan,
            { [styles.popular]: isPopular && !isCurrent(id) },
            { [styles.current]: isCurrent(id) }
          )}
        >
          <div className={styles.title}>
            {title}
            {isCurrent(id) ? (
              <button
                className={classNames('btn btn-transparent', styles.cancel)}
                onClick={isCanceled ? onRenew : cancelSubscription}
              >
                {isCanceled ? 'Renew subscription' : 'Cancel subscription'}
              </button>
            ) : (
              isPopular && <div className={styles.label}>Most popular</div>
            )}
          </div>
          <div className={styles.price}>
            <div className={styles.month}>{month.price && monthly ? `$${month.price}` : `$${year.price} `}</div>
            <div className={styles.year}>
              {Number(month.price) > 0 && (monthly ? `$${Number(month.price) * 12} year` : `$${(Math.round(Number(year.price) / 12 * 10))/10}/mo`)}
            </div>
          </div>
          <ChoosePlanBtn
            changePlan={changePlan}
            id={id}
            isCanceled={isCanceled}
            isCurrentPlan={isCurrent(id)}
            isPopular={isPopular}
            className={'w-100 mb-5'}
          />

          {options.map((option, index) => (
            <OptionItemPlan option={option} key={index} isPopular={isPopular} />
          ))}
          <ChoosePlanBtn
            changePlan={changePlan}
            id={id}
            isCanceled={isCanceled}
            isCurrentPlan={isCurrent(id)}
            isPopular={isPopular}
            className={'w-100 mt-auto'}
          />
        </div>
      </div>
    );
  }
);
