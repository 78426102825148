import { LoadState } from '../../../../store';

export type FieldGroup = 'contact' | 'filter' | 'list' | 'tab';

export type FieldTypes =
  | 'short_text'
  | 'long_text'
  | 'numeric'
  | 'toggle'
  | 'date'
  | 'date_time'
  | 'address'
  | 'short_address'
  | 'tax_id'
  | 'url'
  | 'select'
  | 'image'
  | 'price'
  | 'relation'
  | 'multiselect';

export type FieldScope =
  | 'first_name'
  | 'last_name'
  | 'title'
  | 'email'
  | 'responsible_user'
  | 'phone'
  | 'custom'
  | 'quantity'
  | 'sku'
  | 'description'
  | 'image'
  | 'state'
  | 'course_relation'
  | 'student_relation'
  | 'contact_relation'
  | 'price';

export type FieldEntity = 'contact' | 'company' | 'product' | 'student' | 'course';

export type FieldSchema = Pick<IField, 'id' | 'payload' | 'type'>;

export type SelectPayload = Pick<IPayload, 'options' | 'display_options'>;

export declare type PayloadFields = Record<string, any>;

export type IPayload<T extends PayloadFields = PayloadFields> = T & {
  title: string;
  readonly: boolean;
  options?: string[];
  display_options?: boolean;
  value?: boolean;
};

export interface IField {
  date_create: string;
  date_update: string;
  entity: FieldEntity;
  group: FieldGroup;
  id: string;
  organization_id: string;
  payload: IPayload;
  scope: FieldScope;
  type: FieldTypes;
  order: number;
  is_deleted: boolean;
}

export interface IFieldsState {
  error: Error | null;
  fields: IField[];
  loadState: LoadState;
  loadStateReordering: LoadState;
}

export const FieldInitialState: IFieldsState = {
  loadState: LoadState.firstLoad,
  loadStateReordering: LoadState.allIsLoaded,
  error: null,
  fields: []
};
