import { useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';

import { automationPaths } from '../../../modules/automations/routes/AutomationRoutes';
import { pageBuilderPaths } from '../../../modules/common/page-builder/routes/PageBuilderRoutes';
import { dashboardPaths } from '../../../modules/dashboard/routes/DashboardRoutes';
import { funnelPaths } from '../../../modules/funnels/routes/FunnelRoutes';
import { previewPaths } from '../../../modules/lms/preview/routes/PreviewRoutes';
import { mainPaths } from '../../../modules/MainRoutes';

export const useShowLayoutParts = () => {
  const { authToken } = useSelector((state) => state.auth);
  const coursePath = useRouteMatch<{ id: string }>('/courses/:id');
  const buildersPath = useRouteMatch<{ id: string }>([
    funnelPaths.funnelId(),
    automationPaths.automationListId(),
    pageBuilderPaths.pageBuilder()
  ]);
  const dashboard = useRouteMatch(dashboardPaths.dashboardLessons());
  const preview = useRouteMatch(previewPaths.previewLessons());
  const offer = useRouteMatch(mainPaths.offer());
  const commonPath = useRouteMatch([
    mainPaths.signUp,
    mainPaths.confirmation,
    mainPaths.auth,
    mainPaths.recovery,
    mainPaths.updateAccount
  ]);
  const notFoundPath = useRouteMatch(mainPaths.notFound);
  const googleOAuthPath = useRouteMatch(mainPaths.googleOAuth);
  const pageBuilderPath = useRouteMatch<{ id: string }>(pageBuilderPaths.pageBuilder());
  const thumbnails = useRouteMatch(funnelPaths.thumbnails);

  const showSidebar =
    authToken &&
    !buildersPath?.params.id &&
    !coursePath &&
    !dashboard &&
    !preview &&
    !offer &&
    !(
      commonPath &&
      [mainPaths.auth, mainPaths.signUp, mainPaths.confirmation, mainPaths.recovery, mainPaths.updateAccount].includes(
        commonPath.path
      )
    ) &&
    !notFoundPath &&
    !googleOAuthPath;

  const isUpdateAccountPage = mainPaths.updateAccount === commonPath?.path;

  const showHeader =
    !pageBuilderPath?.params.id &&
    !dashboard &&
    !preview &&
    !notFoundPath &&
    !offer &&
    authToken &&
    !isUpdateAccountPage &&
    !googleOAuthPath;

  const paddingStyle = showSidebar && !thumbnails ? 'hasSidebar' : '';

  return { showSidebar, showHeader, paddingStyle };
};
