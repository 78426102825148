import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import Tree, { TreeNodeProps } from 'rc-tree';
import { DataNode, Key } from 'rc-tree/lib/interface';

import 'rc-tree/assets/index.css';
import './course-navigation-tree-view.scss';
import styles from './course-navigation-tree-view.module.scss';
import { IconMinus, IconPlus } from '../../../../UI/icons';

import { useMediaQuery } from '../../../../../hooks/use-media-query';
import { CourseNodeTreeSelector } from '../../../../../modules/lms/courses/store/CourseSelector';
import { ICourseNode } from '../../../../../modules/lms/courses/store/CourseState';
import { useLessonNode } from '../../../../../modules/lms/hooks/use-lesson-node';
import { useViewCourseContext } from '../../../../../modules/lms/students/student-course/ViewCourseContext';
import { EmptyFolder } from '../empty-folder/EmptyFolder';
import { ICourseHierarchySidebar, toTreeData } from './helper';

const motion = {
  motionName: 'node-motion',
  motionAppear: false,
  onAppearStart: () => ({ height: 0 }),
  onAppearActive: (node: any) => ({ height: node.scrollHeight, transitionDelay: '0.1s' }),
  onLeaveStart: (node: any) => ({ height: node.offsetHeight }),
  onLeaveActive: () => ({ height: 0, transitionDelay: '0.1s' })
};

export const CourseNavigationTreeView = () => {
  const { onSelect, closeSidebar, currentHierarchy, course, nodeId, flatLessons } = useViewCourseContext();
  const { nodeLesson, nodeStep } = useLessonNode(course, nodeId);
  const isTabledSize = useMediaQuery('(max-width: 991px)');

  const onSelectHandler = (
    selectedKeys: Key[],
    info: {
      event: 'select';
      selected: boolean;
      node: ICourseHierarchySidebar;
      selectedNodes: DataNode[];
      nativeEvent: MouseEvent;
    }
  ) => {
    if (info?.node?.type === 'lesson' && selectedKeys[0]) {
      onSelect(selectedKeys[0]?.toString());
      closeSidebar();
    } else if (isTabledSize) {
      closeSidebar();
    }
  };

  const onCheckFolder = (e: React.SyntheticEvent<HTMLSpanElement>): void => {
    const folderId = e.currentTarget.getElementsByClassName('click-navigation')[0]?.id;

    if (folderId) {
      onSelect(folderId);
    }
  };

  const switcherIcon = (obj: TreeNodeProps & Pick<ICourseNode, 'type'>) => {
    if (obj.type === 'folder') {
      if (obj.expanded) {
        return (
          <span onDoubleClick={onCheckFolder}>
            <IconMinus className={styles.iconSwitch} />
          </span>
        );
      }
      return (
        <span onDoubleClick={onCheckFolder}>
          <IconPlus className={styles.iconSwitch} />
        </span>
      );
    }
  };

  const currentHierarchyTree = nodeLesson
    ? toTreeData(currentHierarchy, course.settings.progress_sequential_flow, flatLessons?.all, course)
    : [];

  const nodeTreeMemo = useMemo(() => CourseNodeTreeSelector(course.children, nodeId, ['folder']), [course, nodeId]);
  const { treeNodes } = useSelector((state) => nodeTreeMemo(state));

  const lastNodeTreeMemo = useMemo(
    () => CourseNodeTreeSelector(course.children, course.last_lesson?.id, ['folder']),
    [course]
  );

  const { treeNodes: lastTreeNodes } = useSelector((state) => lastNodeTreeMemo(state));

  const defaultExpandedKeys = useMemo(() => {
    if (nodeLesson?.parent_id) {
      return treeNodes.length > 0
        ? treeNodes.map((x) => x.id)
        : course.last_lesson?.id
        ? lastTreeNodes.map((x) => x.id)
        : [];
    } else {
      return [];
    }
  }, [course.last_lesson?.id, lastTreeNodes, nodeLesson?.parent_id, treeNodes]);

  return (
    <div className="animation">
      {currentHierarchyTree?.length > 0 ? (
        <Tree
          onDoubleClick={onCheckFolder}
          onSelect={onSelectHandler}
          switcherIcon={switcherIcon}
          showLine
          selectedKeys={nodeStep?.parent_id ? [nodeStep?.parent_id] : []}
          expandAction={'click'}
          motion={motion}
          treeData={currentHierarchyTree}
          // autoExpandParent={false}
          defaultExpandedKeys={defaultExpandedKeys}
        />
      ) : (
        <EmptyFolder />
      )}
    </div>
  );
};
