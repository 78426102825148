import actionCreatorFactory from 'typescript-fsa';

import { IEditMember, IInviteMember, IMember } from '../../../../interfaces';

export const actionCreator = actionCreatorFactory();
export const OrganizationActions = {
  getOrganizationMembers: actionCreator.async<void, IMember[], Error>('GET_ORGANIZATION_MEMBERS'),
  inviteOrganizationMembers: actionCreator.async<IInviteMember, void, Error>('INVITE_ORGANIZATION_MEMBERS'),
  resendInvitationOrganizationMember: actionCreator.async<{ memberId: string }, void, Error>(
    'RESEND_INVITATION_ORGANIZATION_MEMBERS'
  ),
  removeMemberFromOrganization: actionCreator.async<{ memberId: string }, void, Error>('REMOVE_ORGANIZATION_MEMBERS'),
  editMemberOrganization: actionCreator.async<{ memberId: string; params: IEditMember }, IMember, Error>(
    'EDIT_ORGANIZATION_MEMBERS'
  ),
  invitationEmail: actionCreator.async<{ actionHash: string }, void, Error>('INVITATION_EMAIL')
};
