import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js/pure';

import classNames from 'classnames';
import styles from './add-card-modal.module.scss';
import { Icon32Cross } from '../../../../components/UI/icons';

import { GeneralSettingsActionAsync } from '../../general/store/GeneralSettingsActionAsync';
import { CheckoutForm } from '../checkout-form/CheckoutForm';

interface IProps {
  clientSecret: string;

  onSuccess(): void;

  onClose(): void;
}

export const AddCardModal = ({ clientSecret, onSuccess, onClose }: IProps) => {
  const dispatch = useDispatch();
  const [publishableKey, setPublishableKey] = useState<string>();
  const stripePromise = useMemo(() => {
    return publishableKey ? loadStripe(publishableKey, { locale: 'en' }) : null;
  }, [publishableKey]);

  useEffect(() => {
    dispatch(
      GeneralSettingsActionAsync.getPublishableKey((pub_key) => {
        setPublishableKey(pub_key);
      })
    );
  }, [dispatch]);

  return (
    <div className={styles.container}>
      <button onClick={onClose} className={classNames('btn btn-icon', styles.close)}>
        <Icon32Cross />
      </button>
      <Elements stripe={stripePromise} options={{ locale: 'en', clientSecret }}>
        <div className={styles.title}>Add credit card</div>
        <CheckoutForm onSuccess={onSuccess} />
      </Elements>
    </div>
  );
};
