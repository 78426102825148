import { useEffect, useMemo, useState } from 'react';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import { useDispatch, useSelector } from 'react-redux';
import update from 'immutability-helper';

import { LoadState } from '../../../store';
import { FieldActionAsync, FieldEntity, FieldSchema } from '../field';
import { IFieldSettings } from '../field/FieldSettings';
import { FieldSchemaSelector } from '../field/store/FieldSelector';
import { FieldDelete } from './FieldDelete';
import { FieldItemElement, IEditFieldElement } from './FieldItemElement';
import { FieldPayloadModal } from './FieldPayloadModal';

interface IProps {
  entity: FieldEntity;
}

export const Fields = ({ entity }: IProps) => {
  const [show, setShow] = useState(false);
  const [popup, setPopup] = useState(false);
  const [field, setField] = useState<FieldSchema & { typeElement: IFieldSettings | undefined }>();
  const [fieldsState, setFieldsState] = useState<IEditFieldElement[]>([]);
  const dispatch = useDispatch();
  const fieldSchemaMemo = useMemo(FieldSchemaSelector, []);
  const { fieldsSchema, loadStateReordering } = useSelector((state) => fieldSchemaMemo(state));

  const onClose = () => {
    setShow(false);
  };
  const onClosePopup = () => {
    setPopup(false);
  };
  const onDeleteFieldHandler = () => {
    onClose();
    setPopup(true);
  };
  const handleClick = (fieldSchema: FieldSchema & { typeElement: IFieldSettings | undefined }) => {
    setField(fieldSchema);
    setShow(true);
  };

  const handleDrag = (result: DropResult) => {
    const dragField = fieldsState[result.source.index];
    if (result.destination) {
      const destinationField = fieldsState[result.destination.index];
      setFieldsState(
        update(fieldsState, {
          $splice: [
            [result.source.index, 1],
            [result.destination.index, 0, dragField]
          ]
        })
      );
      dispatch(FieldActionAsync.reorderField(dragField.id, destinationField.order));
    }
  };

  useEffect(() => {
    if (fieldsSchema.length !== fieldsState.length) {
      setFieldsState(
        fieldsSchema.filter((x) => x.scope === 'custom' && x.entity === entity).sort((a, b) => a.order - b.order)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldsSchema.length]);

  useEffect(() => {
    if (loadStateReordering === LoadState.allIsLoaded) {
      dispatch(FieldActionAsync.getFields());
    }
  }, [dispatch, loadStateReordering]);

  const onDeleteField = () => {
    if (field) {
      dispatch(FieldActionAsync.deleteField(field.id));
      onClosePopup();
    }
  };

  return (
    <>
      <div className={'mb-3'}>
        {fieldsSchema
          .filter((x) => x.scope !== 'custom' && x.scope !== 'student_relation' && x.entity === entity)
          .sort((a, b) => a.order - b.order)
          .map((field) => (
            <FieldItemElement
              field={field}
              handleClick={handleClick}
              key={field.id}
              isCustom={field.scope === 'custom'}
            />
          ))}

        <DragDropContext onDragEnd={handleDrag}>
          <Droppable droppableId="option-items">
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {fieldsState.map((field, index) => (
                  <Draggable key={field.id} draggableId={field.id!} index={index}>
                    {(provided) => (
                      <FieldItemElement
                        field={field}
                        handleClick={handleClick}
                        key={field.id}
                        isCustom={field.scope === 'custom'}
                        provided={provided}
                      />
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
      <FieldDelete
        popup={popup}
        onClosePopup={onClosePopup}
        onDeleteField={onDeleteField}
        label={field?.typeElement?.label}
        entity={entity}
      />
      <FieldPayloadModal
        fieldId={field?.id}
        typeElement={field?.typeElement}
        onClose={onClose}
        onDeleteFieldHandler={onDeleteFieldHandler}
        show={show}
      />
    </>
  );
};
