import { CSSProperties, ReactNode, useMemo } from 'react';
import { useSelector } from 'react-redux';

import classNames from 'classnames';
import styles from './student-cabinet-bg.module.scss';

import { imageUrl } from '../../../../components/UI/image-components/image/Image';
import { LoadState } from '../../../../store';
import { StudentCabinetSelector } from '../../store/StudentCabinetSelector';

interface IProps {
  className?: string;
  children?: ReactNode;
}

export const StudentCabinetBg = ({ className, children }: IProps) => {
  const cabinetSettingsMemo = useMemo(StudentCabinetSelector, []);
  const { cabinetSettings, loadCabinetSettingsState } = useSelector((state) => cabinetSettingsMemo(state));
  const themeSignIn = cabinetSettings?.theme_sign_in;
  const loaded = loadCabinetSettingsState === LoadState.allIsLoaded || loadCabinetSettingsState === LoadState.error;

  const stylesBGImage = (): CSSProperties | undefined => {
    if ((!themeSignIn?.bucket_name && !themeSignIn?.object_name) || !loaded) {
      return undefined;
    }

    const themeSignInBgImage = imageUrl({
      bucket_name: themeSignIn?.bucket_name,
      object_name: themeSignIn?.object_name
    });

    return { backgroundImage: `url(${themeSignInBgImage})` };
  };

  return (
    <div
      style={stylesBGImage()}
      className={classNames(styles.container, styles.bg, { [styles.bgDefault]: loaded && !themeSignIn }, className)}
    >
      {children}
    </div>
  );
};
