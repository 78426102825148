import styles from './quiz-to-moderation.module.scss';
import Cup from '../../../../../../../../assets/images/cup.png';

export const ResultQuizModeratedSuccess = () => {
  return (
    <>
      <img className={styles.icon} alt={'cup'} src={Cup} />
      <div className={styles.title}>
        You have successfully completed
        <br /> the quiz
      </div>
      <div className={styles.description}>The course moderator marked the quiz as passed</div>
    </>
  );
};
