import { memo } from 'react';
import { useHistory } from 'react-router-dom';

import styles from './public-offer-modal.module.scss';
import { Icon32Warning } from '../../../../../components/UI/icons';

import { Button, CommonModal } from '../../../../../components/UI';
import { CRMPaths } from '../../../routes/CRMRoutes';

interface IProps {
  onClose: () => void;
  offerId: string | null;
}

export const PublicOfferErrorModal = memo(({ onClose, offerId }: IProps) => {
  const history = useHistory();
  const onSetupOffer = () => {
    if (offerId) {
      history.push(CRMPaths.crmOffer(offerId));
    }
  };
  return (
    <CommonModal
      onClose={onClose}
      show={offerId !== null}
      showClose={true}
      params={{ contentClassName: styles.content }}
    >
      <>
        <div className={styles.container}>
          <Icon32Warning className={styles.icon} />
          <div className={styles.title}>This offer cannot be published</div>
        </div>

        <div className={styles.wrapper}>
          <div className={styles.description}>
            Looks like you haven't finished customizing the offer. Check out adding products and setting up pricing
            options.
          </div>
          <Button onClick={onSetupOffer} withIcon={false} className={'mt-4 mb-2 w-100'} size={'lg'}>
            Continue setup
          </Button>
          <Button onClick={onClose} btnStyle={'transparent'} withIcon={false} size={'lg'} className={'w-100'}>
            Cancel
          </Button>
        </div>
      </>
    </CommonModal>
  );
});
