import React, { useCallback, useEffect, useState } from 'react';

import classNames from 'classnames';
import styles from './canvas-node.module.scss';
import { Icon16Trash } from '../../../../components/UI/icons';

import { Button } from '../../../../components/UI';
import { _id } from '../../../../helpers';
import { useNodePayload } from '../../../../hooks/use-node-payload';
import {
  IAutomationNodeBuilder,
  IFunnelNodeBuilder,
  PaletteAutomationElementTypes,
  PaletteElementTypes
} from '../../../../interfaces';
import { CanvasNodeAutomationItem } from '../../../automations/automation-builder/canvasNode/CanvasNodeAutomationItem';
import { CanvasNodeFunnelItem } from '../../../funnels/funnel-builder/canvasNode/CanvasNodeFunnelItem';
import { MultiPinNode } from '../../../funnels/funnel-builder/multiPinNode/MultiPinNode';
import { BuilderType } from '../store/BuilderActionAsync';
import { CanvasNodeCondition } from './canvas-condition/CanvasNodeCondition';
import { CanvasNodeEmail } from './canvas-email/CanvasNodeEmail';
import { CanvasNodeList } from './canvas-list/CanvasNodeList';
import { CanvasNodeRange } from './canvas-range/CanvasNodeRange';
import { CanvasNodeTag } from './canvas-tag/CanvasNodeTag';

interface NodeProps {
  left: number;
  top: number;
  nodeId: string;
  builderType: BuilderType;

  onClick(event: React.SyntheticEvent): void;

  onDoubleClick(event: React.SyntheticEvent): void;

  onHover(event: React.SyntheticEvent): void;

  onDelete(id: string, event: React.SyntheticEvent): void;

  onCloseMultiView?(
    node: IAutomationNodeBuilder<PaletteAutomationElementTypes> | IFunnelNodeBuilder<PaletteElementTypes>
  ): void;
}

export const CanvasNode = ({
  builderType,
  left,
  nodeId,
  onClick,
  onDelete,
  onDoubleClick,
  onHover,
  top,
  onCloseMultiView
}: NodeProps) => {
  const style = {
    left: left + 'px',
    top: top + 'px'
  } as React.CSSProperties;

  const [multiPinView, setMultiPinView] = useState(false);
  const node = useNodePayload<PaletteAutomationElementTypes | PaletteElementTypes>(nodeId);
  const isCircle = node && /traffic_source\./.test(node.type);
  const isCustomPage = node && /pages\./.test(node.type);
  const instagramAdClass = node?.type === PaletteElementTypes.TRAFFIC_SOURCE_INSTAGRAM_AD ? 'instagramAd' : null;

  const isMultiPin = isCustomPage && node && node.endpoints!.length > 2;

  const [title, setTitle] = useState(node?.payload.title);
  useEffect(() => {
    setTitle(title);
  }, [title]);

  useEffect(() => {
    if (isCustomPage && node && node.endpoints && node.endpoints.filter((endpoint) => !endpoint.is_input).length > 0) {
      setMultiPinView(true);
    }
  }, [isCustomPage, node, node?.endpoints]);

  const onOutputsClick = () => {
    // setMultiPinView(true);
    if (onCloseMultiView && node) {
      onCloseMultiView(node);
    }
  };

  const onClose = useCallback(() => {
    // setMultiPinView(false);
    if (onCloseMultiView && node) {
      onCloseMultiView(node);
      setMultiPinView(false);
    }
  }, [node, onCloseMultiView]);

  const setMultiView = () => {
    setMultiPinView(true);
  };

  return multiPinView ? (
    <div
      id={_id(nodeId)}
      className={classNames('element-node', { automation: builderType === 'automation' })}
      style={style}
      onClick={onClick}
      onDoubleClick={onDoubleClick}
      data-node-id={nodeId}
    >
      <MultiPinNode onClose={onClose} nodeId={nodeId} />
      <button className="btn btn-icon-shadow btn-delete" onClick={(e) => onDelete(nodeId, e)}>
        <Icon16Trash />
      </button>
    </div>
  ) : (
    <div
      id={_id(nodeId)}
      className={classNames(
        'element-node',
        { automation: builderType === 'automation' },
        { [styles.circle]: isCircle },
        instagramAdClass
      )}
      style={style}
      onClick={onClick}
      onDoubleClick={onDoubleClick}
      onMouseEnter={onHover}
      onMouseLeave={onHover}
      data-node-id={node?.id}
    >
      {builderType === 'funnel' && node && (
        <CanvasNodeFunnelItem
          nodeId={nodeId}
          title={node.payload.title}
          type={node.type}
          builderType={builderType}
          setMultiView={setMultiView}
        />
      )}
      {builderType === 'automation' && node && (
        <div className={styles.wrapper} data-node-id={node.id} onClick={onClick} id={_id(nodeId)}>
          <div
            className={classNames('d-flex align-items-center p-2', styles.container)}
            data-node-id={node.id}
            onClick={onClick}
            id={_id(nodeId)}
          >
            {<CanvasNodeAutomationItem title={node.payload.title} type={node.type} />}
          </div>
          {node.type === PaletteAutomationElementTypes['ACTION_TAG'] && (
            <CanvasNodeTag nodeId={nodeId} tag_id={node.payload.tag_id} />
          )}
          {node.type === PaletteAutomationElementTypes['ACTION_LIST'] && (
            <CanvasNodeList list_id={node.payload.list_id} nodeId={nodeId} />
          )}
          {node.type === PaletteAutomationElementTypes['ACTION_SEND_EMAIL'] && <CanvasNodeEmail nodeId={nodeId} />}
          {node.type === PaletteAutomationElementTypes['PROCESSOR_CONDITION'] && (
            <CanvasNodeCondition nodeId={nodeId} />
          )}
          {node.type === PaletteAutomationElementTypes['PROCESSOR_RANGE'] && <CanvasNodeRange nodeId={nodeId} />}
          {node.type === PaletteAutomationElementTypes['PROCESSOR_DELAY'] && (
            <CanvasNodeFunnelItem
              nodeId={nodeId}
              title={node.payload.title}
              type={node.type}
              builderType={builderType}
            />
          )}
        </div>
      )}
      {isMultiPin && (
        <Button onClick={onOutputsClick} withIcon={false} btnStyle={'outline'} className={styles.outputs}>
          Outputs
        </Button>
      )}
      <button className="btn btn-icon-shadow btn-delete" onClick={(e) => onDelete(nodeId, e)}>
        <Icon16Trash />
      </button>
    </div>
  );
};
