import { ChangeEvent, useEffect, useState } from 'react';
import { Form, FormCheck } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';

import styles from './payload-offer-purchased.module.scss';

import { DropdownIndicator } from '../../../../../../components/UI/select/DropdownIndicator';
import { getUUID } from '../../../../../../helpers';
import { useNodePayload } from '../../../../../../hooks/use-node-payload';
import { IOption, PaletteAutomationElementTypes } from '../../../../../../interfaces';
import { IOfferPurchasedEventsAutomationPayload } from '../../../../../../interfaces/node-builder-payload/automation-events/offer-purchased';
import { LoadState } from '../../../../../../store';
import { OffersActionAsync } from '../../../../../crm/offers/store/OffersActionAsync';

interface IProps {
  nodeId: string;
}

export const PayloadOfferPurchased = ({ nodeId }: IProps) => {
  const dispatch = useDispatch();
  const { setValue, register, unregister } = useFormContext<IOfferPurchasedEventsAutomationPayload>();
  const { offers, loadState } = useSelector((state) => state.offers);
  const options: IOption[] = offers.map((offer) => ({ value: offer.id, label: offer.title }));
  const node = useNodePayload<PaletteAutomationElementTypes.EVENT_OFFER_PURCHASED>(nodeId);

  const [selectedOfferId, setSelectedOfferId] = useState<Nullable<string>>(node?.payload.offer_id || null);
  const [isSuccess, setIsSuccess] = useState(!!node?.payload.is_success);

  useEffect(() => {
    if (loadState !== LoadState.allIsLoaded) {
      dispatch(OffersActionAsync.getOffers());
    }
  }, [dispatch, loadState]);

  useEffect(() => {
    setSelectedOfferId(node?.payload.offer_id || null);
    setIsSuccess(!!node?.payload.is_success);
  }, [node]);

  useEffect(() => {
    register('offer_id');
    setValue('offer_id', selectedOfferId);

    return () => {
      unregister('offer_id');
    };
  }, [register, selectedOfferId, setValue, unregister, nodeId]);

  const onCheckRadio = (e: ChangeEvent<HTMLInputElement>) => {
    setIsSuccess(e.target.value === 'successful');
  };

  useEffect(() => {
    setValue('is_success', isSuccess);
  }, [isSuccess, setValue]);

  return (
    <div className={styles.container}>
      <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Label>Offer</Form.Label>
        <Select
          value={options.find((option) => option.value === selectedOfferId) || null}
          classNamePrefix={'select'}
          className={'select-container'}
          placeholder={'Select an offer'}
          options={options}
          onChange={(e) => {
            setSelectedOfferId(e?.value || null);
          }}
          components={{
            DropdownIndicator,
            IndicatorSeparator: () => null
          }}
        />
      </Form.Group>
      <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Label>Payment status:</Form.Label>
        <FormCheck
          name={'is_success'}
          custom
          type={'radio'}
          id={getUUID()}
          label={'Successful'}
          checked={isSuccess}
          onChange={onCheckRadio}
          value={'successful'}
          className={'radio-button my-1'}
        />
        <FormCheck
          name={'is_success'}
          custom
          type={'radio'}
          id={getUUID()}
          label={'Error'}
          checked={!isSuccess}
          onChange={onCheckRadio}
          value={'error'}
          className={'radio-button my-1'}
        />
      </Form.Group>
    </div>
  );
};
