const checkUppercase = (str: string) => {
  for (let i = 0; i < str.length; i++) {
    if (str.charAt(i) === str.charAt(i).toUpperCase() && str.charAt(i).match(/[a-z]/i)) {
      return true;
    }
  }
  return false;
};

const checkNumber = (str: string) => /\d/.test(str);

export const validation = (password: string) => {
  const atLeastOneUpper = checkUppercase(password);
  const atLeastOneNumber = checkNumber(password);
  const minSymbols = !(password.length < 8);

  return {
    minimum: minSymbols,
    uppercase: atLeastOneUpper,
    numeric: atLeastOneNumber
  };
};
