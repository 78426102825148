import { CustomDispatch } from 'redux';

import { createStudentComment, getStudentComments } from '../../../../../API/student-comment';
import { ICommentRequest, IPaginationParams } from '../../../../../interfaces';
import { StudentCommentActions } from './StudentCommentActions';

export const StudentCommentActionAsync = {
  getComments:
    (params: Pick<ICommentRequest, 'courseId' | 'nodeId' | 'sort'>, pagination?: IPaginationParams) =>
    async (dispatch: CustomDispatch) => {
      try {
        dispatch(StudentCommentActions.getComments.started(params));
        const response = await getStudentComments(params, pagination);
        if (response && response.data && response.meta) {
          dispatch(
            StudentCommentActions.getComments.done({
              result: { data: response.data, meta: response.meta },
              params: params
            })
          );
        }
      } catch (error: any) {
        dispatch(StudentCommentActions.getComments.failed({ error, params: params }));
      }
    },
  createComment:
    (params: Pick<ICommentRequest, 'courseId' | 'nodeId' | 'content'>) => async (dispatch: CustomDispatch) => {
      try {
        dispatch(StudentCommentActions.createComment.started(params));
        const response = await createStudentComment(params);
        if (response.data) {
          dispatch(StudentCommentActions.createComment.done({ result: response.data, params: params }));
        }
      } catch (error: any) {
        dispatch(StudentCommentActions.createComment.failed({ error, params: params }));
      }
    }
};
