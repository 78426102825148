import styles from './breadcrumbs.module.scss';

import { ICourseNode } from '../../../../../modules/lms/courses/store/CourseState';
import { BreadcrumbsItem } from './BreadcrumbsItem';

interface IProps {
  treeNodes: ICourseNode[];
}

export const Breadcrumbs = ({ treeNodes }: IProps) => {
  return (
    <>
      <span className={styles.wrapper}>
        <span className={styles.container}>
          <BreadcrumbsItem title={'Home'} nodeId={null} showChevron={true} />
          {treeNodes.reverse().map((node, i) => (
            <BreadcrumbsItem key={i} title={node.title} nodeId={node?.id} showChevron={treeNodes.length - 1 !== i} />
          ))}
        </span>
        <span className={styles.containerDisappear} />
      </span>
    </>
  );
};
