import { IRoutes } from '../../../../interfaces';
import { SchoolHeaderPanel } from '../../../../layout/header/schoolHeaderPanel/SchoolHeaderPanel';
import { StudentList } from '../student-list/StudentList';

export const studentPaths = {
  studentList: '/students'
};

export const StudentRoutes: IRoutes[] = [
  {
    path: studentPaths.studentList,
    exact: true,
    main: () => (
      <div className={'content'}>
        <StudentList />
      </div>
    ),
    header: () => <SchoolHeaderPanel />
  }
];
