import styles from './comments-empty.module.scss';
import ConversationComment from '../../../../assets/images/Conversation.svg';

export const CommentsEmpty = () => {
  return (
    <div className={styles.container}>
      <img src={ConversationComment} alt="placeholder" />
      <div className={styles.title}>Leave your comment</div>
      <div className={styles.description}>Ask questions and discuss the topic with other course participants</div>
    </div>
  );
};
