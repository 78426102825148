import { memo } from 'react';

import classNames from 'classnames';
import styles from './expand-button-plans.module.scss';
import { IconChevronDown, IconChevronUp } from '../../../../../components/UI/icons';

import { Button } from '../../../../../components/UI';

interface IProps {
  isExpanded: boolean;
  onExpend: () => void;
}
export const ExpandPlansButton = memo(({ isExpanded, onExpend }: IProps) => {
  return (
    <div className={classNames(styles.shadowBlock, { [styles.open]: isExpanded })}>
      <div>
        <Button
          onClick={onExpend}
          className={styles.expand}
          customIcon={isExpanded ? <IconChevronUp className={'icon'} /> : <IconChevronDown className={'icon'} />}
        >
          {isExpanded ? 'Collapse' : 'Expand'}
        </Button>
      </div>
    </div>
  );
});
