import { Form } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';

import styles from '../connect-new-domain.module.scss';

import { ICustomDomainFormValues } from '../ConnectNewDomain';
import { ErrorDomainHandler } from '../utils/error-domain-handler';

interface IProps {
  domain: boolean;
}

export const DomainInput = ({ domain }: IProps) => {
  const { register, formState } = useFormContext<ICustomDomainFormValues>();
  const { error } = useSelector((state) => state.domains);
  const errorMessage = error && ErrorDomainHandler(error.data.status, error.data.payload);
  const errorValidationDomain = formState.errors?.domain?.message;
  return (
    <Form.Group className={'mt-3'}>
      <Form.Label className={styles.label}>{domain ? 'Add your domain name' : 'Enter your domain name'}</Form.Label>
      <Form.Control
        placeholder={'Ex: mywebsite.com'}
        {...register('domain')}
        className={(errorMessage || errorValidationDomain) && 'invalid'}
      />
      {(errorMessage || errorValidationDomain) && (
        <div className={'error'}>{errorMessage || errorValidationDomain}</div>
      )}
    </Form.Group>
  );
};
