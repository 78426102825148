import { FC, SyntheticEvent, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import debounce from 'lodash/debounce';

import { Loader } from '../../../components/UI';
import { settingsActions } from '../../../store/settings';
import { ITransformWrapper } from '../../../types/transform-wrapper';

interface IProps {
  plumbZoom: (scale: number) => void;
  onCanvasClick: (event: SyntheticEvent) => void;
  isLoading?: boolean;
  canvasOptions: { positionX: number; positionY: number; scale: number };
  isMoved(): void;
  onSetCanvasOption: (data: { scale: number; positionX: number; positionY: number }) => void;
}

let zoomOption: Partial<ITransformWrapper> = {};

export const TransformBuilder: FC<IProps> = ({
  isLoading,
  onCanvasClick,
  plumbZoom,
  canvasOptions,
  isMoved,
  onSetCanvasOption,
  children
}) => {
  const zoomValue = useSelector((state) => state.settings.zoomValue);
  const dispatch = useDispatch();

  const onPanningStop = (data: { positionX: number; positionY: number }) => {
    if (zoomOption.scale) {
      onSetCanvasOption({ positionX: data.positionX, positionY: data.positionY, scale: zoomOption.scale });
    }
  };
  const onPanningStart = (_data: { positionX: number; positionY: number }) => {
    isMoved();
  };

  const onZoomChange = (data: { scale: number; positionX: number; positionY: number }) => {
    onSetCanvasOption(data);
    plumbZoom(data.scale);
    delayedQuery();
  };

  const setZoomValue = () => {
    isMoved();
    const zoomScale = Number(zoomValue.value.toFixed(1));
    dispatch(settingsActions.setZoomValue({ value: zoomOption.scale || zoomScale, isDefault: false }));
  };

  const delayedQuery = debounce(() => setZoomValue(), 20);

  useEffect(() => {
    return delayedQuery.cancel();
  }, [delayedQuery]);

  useEffect(() => {
    if (zoomOption.scale !== zoomValue.value) {
      if (zoomValue.isDefault && zoomOption.resetTransform) {
        zoomOption.resetTransform();
      } else if (!zoomValue.isDefault && zoomOption.setScale) {
        zoomOption.setScale(zoomValue.value);
      }
    }
  }, [canvasOptions.scale, zoomValue.isDefault, zoomValue]);

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <TransformWrapper
          options={{
            centerContent: true,
            minScale: 0.5,
            maxScale: 2,
            limitToBounds: false
          }}
          onZoomChange={onZoomChange}
          wheel={{ step: 80 }}
          doubleClick={{ disabled: true }}
          onPanningStop={onPanningStop}
          onPanningStart={onPanningStart}
        >
          {(rest: ITransformWrapper) => {
            zoomOption = rest;
            return (
              <TransformComponent>
                <div id="funnel-canvas" className="funnel-canvas" onClick={onCanvasClick}>
                  {children}
                </div>
              </TransformComponent>
            );
          }}
        </TransformWrapper>
      )}
    </>
  );
};
