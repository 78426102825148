import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import classNames from 'classnames';
import styles from './list-header.module.scss';
import { IconSpinner, IconUpload } from '../../../../../components/UI/icons';

import { Button } from '../../../../../components/UI';
import { downloadCSV } from '../../../../../helpers';
import { PageTopPanel } from '../../../../../layout/shared-components/page-top-panel/PageTopPanel';
import { SearchWrapper } from '../../../../../layout/shared-components/SearchWrapper';
import { StudentsActionAsync } from '../../store/StudentsActionAsync';

interface IProps {
  totalCount: number;
  hideSearch?: boolean;

  onClick(): void;
}

export const ListHeader = ({ totalCount, hideSearch, onClick }: IProps) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { students } = useSelector((state) => state.students);

  const exportStudents = () => {
    setLoading(true);
    dispatch(StudentsActionAsync.exportStudents(exportStudentsCallback));
  };

  const exportStudentsCallback = (data: string) => {
    downloadCSV(data, 'students');
    setLoading(false);
  };

  return (
    <PageTopPanel title={'Students'}>
      {!hideSearch && (
        <>
          <div className={styles.count}>{totalCount}</div>
          <SearchWrapper placeholder={'Search by id, student name, email...'} isPage className={'mr-2'} />
          <Button
            onClick={exportStudents}
            customIcon={loading ? <IconSpinner className={'spinner'} /> : <IconUpload />}
            btnStyle={'secondary'}
            className={classNames('mr-2', styles.export, { [styles.exporting]: loading })}
            btnProps={{ disabled: loading || students.length === 0 }}
          >
            {loading ? 'Exporting...' : 'Export'}
          </Button>
        </>
      )}
      <Button onClick={onClick} type={'create-student'}>
        Add student
      </Button>
    </PageTopPanel>
  );
};
