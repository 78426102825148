import { memo, ReactNode, useCallback } from 'react';
import { AudioField, TypeOfUploader, VideoField } from 'src/app/interfaces/fileUpload';

import classNames from 'classnames';
import commonStyles from './common/upload-common.module.scss';

import { ITranscodingStatus } from '../../../API/video-api';
import {
  IUploadFileError,
  UploadInvalidFile
} from '../../../modules/lms/courses/curriculum/common/upload-invalid-file/UploadInvalidFile';
import { FileProcessing } from './common/file-processing/FileProcessing';
import { FileEmbed } from './file-embed/FileEmbed';
import { FileLink } from './file-link/FileLink';
import { FileNotSelected } from './file-not-select/FileNotSelected';
import { FileSelectFormatUpload } from './file-select/FileSelect';
import { FileSelectInModal } from './file-select-in-modal/FileSelectInModal';

interface IProps {
  accept: FileSelectFormatUpload;
  titleBrowser: string;
  descriptionBrowser: string;
  titleLink: string;
  descriptionLink: string | JSX.Element;
  embedTitle: string;
  embedDescription: string;
  tooltip?: string;
  inProcessing: boolean;
  completeStatus?: ITranscodingStatus;
  uploadFile?: VideoField | AudioField;
  fileSelected?: File | null;
  cardBordered?: boolean;
  typeOfUploader?: TypeOfUploader | null;
  viewportText: string | JSX.Element;
  onSetUploadFile: (uploadFile: VideoField | AudioField) => void;
  onCloseModal?: () => void;
  onClearError: () => void;
  setTypeOfUploader: (type: TypeOfUploader | null) => void;
  format?: string;
  fileError: IUploadFileError | null;
  children: ReactNode;
  className?: string;
}

export const FileUploadControl = memo(
  ({
    accept,
    titleBrowser,
    descriptionBrowser,
    titleLink,
    descriptionLink,
    embedTitle,
    embedDescription,
    inProcessing,
    completeStatus,
    fileSelected,
    uploadFile,
    tooltip,
    viewportText,
    fileError,
    cardBordered = false,
    typeOfUploader = null,
    onSetUploadFile,
    setTypeOfUploader,
    onCloseModal,
    onClearError,
    format,
    children,
    className
  }: IProps) => {
    const setFile = useCallback(
      (type: TypeOfUploader, data: string) => {
        if (type === TypeOfUploader.link) {
          onSetUploadFile({ type: type, url: data });
        }
        if (type === TypeOfUploader.embed) {
          onSetUploadFile({ type: type, code: data });
        }
        onCloseModal?.();
      },
      [onCloseModal, onSetUploadFile]
    );

    const onCancel = useCallback(() => {
      setTypeOfUploader(null);
      onCloseModal?.();
    }, [onCloseModal, setTypeOfUploader]);

    const handleChangeTypeOfUploader = useCallback(
      (type: TypeOfUploader | null) => {
        setTypeOfUploader(type);
      },
      [setTypeOfUploader]
    );

    return (
      <>
        <div className={classNames(commonStyles.container, className)}>
          {fileError && (
            <div className={classNames(commonStyles.card, commonStyles.invalidForm)}>
              <UploadInvalidFile
                description={fileError.description}
                title={fileError.title}
                clearError={onClearError}
              />
            </div>
          )}
          {inProcessing && !fileError && <FileProcessing completeStatus={completeStatus} />}
          {!inProcessing && !fileError && typeOfUploader === null && (
            <>
              <div className={commonStyles.inputDnDContainer}>
                <>{children}</>
              </div>
              {!fileSelected && (
                <FileNotSelected
                  handleChangeTypeOfUploader={handleChangeTypeOfUploader}
                  descriptionLink={descriptionLink}
                  embedDescription={embedDescription}
                  embedTitle={embedTitle}
                  titleLink={titleLink}
                  tooltip={tooltip}
                />
              )}
            </>
          )}
          {!inProcessing && !fileError && typeOfUploader !== null && (
            <div
              className={classNames([
                commonStyles.card,
                commonStyles.typeOfUpload,
                { [commonStyles.cardTypeOfUpload]: cardBordered }
              ])}
            >
              <FileSelectInModal
                handleChangeTypeOfUploader={handleChangeTypeOfUploader}
                typeOfUploader={typeOfUploader}
                accept={accept}
              />
              <h3 className={commonStyles.typeOfUploadTitle}>
                {typeOfUploader === TypeOfUploader.upload && titleBrowser}
                {typeOfUploader === TypeOfUploader.link && titleLink}
                {typeOfUploader === TypeOfUploader.embed && embedTitle}
              </h3>
              <div className={commonStyles.typeOfUploadNote}>
                {typeOfUploader === TypeOfUploader.upload && descriptionBrowser}
                {typeOfUploader === TypeOfUploader.link && descriptionLink}
                {typeOfUploader === TypeOfUploader.embed && embedDescription}
              </div>
              {typeOfUploader === TypeOfUploader.upload && <>{children}</>}
              {typeOfUploader === TypeOfUploader.link && (
                <FileLink
                  defaultFileLink={
                    uploadFile?.type === TypeOfUploader.link && uploadFile?.url ? uploadFile.url : undefined
                  }
                  placeholder={titleLink}
                  onSave={(url) => setFile(TypeOfUploader.link, url)}
                  onCancel={onCancel}
                  format={format}
                />
              )}
              {typeOfUploader === TypeOfUploader.embed && (
                <FileEmbed
                  defaultFileEmbed={
                    uploadFile?.type === TypeOfUploader.embed && uploadFile?.code ? uploadFile.code : undefined
                  }
                  onSave={(code) => setFile(TypeOfUploader.embed, code)}
                  placeholder={embedTitle}
                  viewportText={viewportText}
                  onCancel={onCancel}
                />
              )}
            </div>
          )}
        </div>
      </>
    );
  }
);
