import { memo, useState } from 'react';
import { Dropdown } from 'react-bootstrap';

import classNames from 'classnames';
import styles from './drop-menu.module.scss';
import { IconCross, IconEye, IconKebabMenu, IconLoop } from '../../../../../../../../../components/UI/icons';

import { DropMenu } from '../../../../../../../../../components/UI';

interface IProps {
  onOpen: () => void;
  onOpenRemoveModal: () => void;
}

export const DropEmailMenu = memo(({ onOpen, onOpenRemoveModal }: IProps) => {
  const [showDialog, setShowDialog] = useState(false);

  const onCloseDialog = () => {
    setShowDialog((_x) => false);
  };
  const onToggleDialog = () => {
    setShowDialog((x) => !x);
  };

  const onEdit = () => {
    onOpen();
  };
  const onView = () => {
    onOpen();
  };

  return (
    <div className={styles.kebabContainer}>
      <DropMenu
        show={showDialog}
        onToggle={onToggleDialog}
        node={<IconKebabMenu className={styles.iconKebab} />}
        className={classNames(styles.dropdown, 'sm selector')}
        drop={'down'}
      >
        <Dropdown.Menu onClick={onCloseDialog}>
          <Dropdown.Item eventKey={'setDefault'} className={styles.itemMenu} onClick={onView}>
            <IconEye />
            <span className={styles.textMenu}>View message</span>
          </Dropdown.Item>
          <Dropdown.Divider className={styles.itemDivider} />
          <Dropdown.Item eventKey={'setDefault'} className={styles.itemMenu} onClick={onEdit}>
            <IconLoop />
            <span className={styles.textMenu}>Select another</span>
          </Dropdown.Item>
          <Dropdown.Divider className={styles.itemDivider} />
          <Dropdown.Item eventKey={'Remove'} className={styles.itemMenu} onClick={onOpenRemoveModal}>
            <IconCross className={styles.removeIcon} /> <span className={styles.textMenu}>Remove</span>
          </Dropdown.Item>
        </Dropdown.Menu>
      </DropMenu>
    </div>
  );
});
