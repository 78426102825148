import { memo } from 'react';
import { FormCheck } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';

import classNames from 'classnames';
import './radio.scss';

import { getUUID } from '../../../../../../helpers';

interface IProps {
  name: string;
  label: string;
  value: string;
  className?: string;
  disable?: boolean;
}

export const RadioButtonElement = memo(({ name, label, value, className, disable = false }: IProps) => {
  const { register } = useFormContext();
  return (
    <FormCheck
      className={classNames('radio-button offer-radio', disable && 'disable', className)}
      custom
      value={value}
      label={label}
      {...register(name)}
      type={'radio'}
      id={getUUID()}
      disabled={disable} />
  );
});
