import { memo } from 'react';

import classNames from 'classnames';
import styles from './header-plans.module.scss';

interface IHeaderPlansProps {
  changePeriod: () => void;
  monthly: boolean;
}

export const HeaderPlans = memo(({ monthly, changePeriod }: IHeaderPlansProps) => {
  return (
    <>
      <div className={styles.blockTitle}>Pricing plans</div>
      <div className={styles.selector}>
        <div className={classNames({ [styles.active]: monthly })} onClick={changePeriod}>
          Monthly
        </div>
        <div className={classNames({ [styles.active]: !monthly })} onClick={changePeriod}>
          Annual
        </div>
      </div>
    </>
  );
});
