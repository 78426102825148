import React, { useCallback, useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useDispatch } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';

import classNames from 'classnames';
import commonStyles from '../main/setting.module.scss';
import styles from './integartions.module.scss';
import { Icon32PlugIn, Icon32ZapierColor, IconCopy } from '../../../components/UI/icons';

import { Button, ModalActionComponent } from '../../../components/UI';
import { UnavailableBtnPlan } from '../../../components/unvailable-button-plan/UnvaliableBtnPlan';
import Restricted from '../../../providers/permission/Restricted';
import { HelperWrapper } from '../common/help/HelperWrapper';
import { SettingsPaths } from '../routes/SettingsRoutes';
import { SettingsHeader } from '../SettingsHeader';
import { SettingCourseActionAsync } from '../store/SettingCourseActionAsync';

export const Integrations = () => {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [copied, setCopied] = useState(false);
  const [key, setKey] = useState<string>('');
  const path = useRouteMatch<{ id: string }>(SettingsPaths.zapier());

  const getKey = useCallback(() => {
    dispatch(SettingCourseActionAsync.getZapierKey(zapierCallback));
  }, [dispatch]);

  useEffect(() => {
    if (path?.params.id === 'zapier') {
      getKey();
      setShowModal(true);
    }
  }, [getKey, path?.params.id]);

  const zapierCallback = (key: string) => {
    setKey(key);
  };

  const onOpen = () => {
    getKey();
    setShowModal(true);
  };

  const onClose = () => {
    setShowModal(false);
    setCopied(false);
  };

  // const onReload = () => {
  //   getKey();
  //   setCopied(false);
  // };

  const onCopy = () => {
    setCopied(true);
  };

  return (
    <>
      <SettingsHeader title={'Integrations'} />
      <HelperWrapper>
        <div className={commonStyles.contentContainer}>
          <div className={commonStyles.integrationItem}>
            <div className={styles.title}>
              <Icon32ZapierColor width={64} height={64} className={styles.icon} />
              Zapier
            </div>
            <Restricted
              to={'integration.zapier'}
              fallback={
                <Button onClick={onOpen} withIcon={false} btnStyle={'secondary'} size={'sm'}>
                  Get the key
                </Button>
              }
            >
              <UnavailableBtnPlan
                titleTooltip={'Zapier integration'}
                descriptionTooltip={
                  'To integrate Zapier, you need to upgrade your subscription plan. This option isn’t included in your current plan.'
                }
              />
            </Restricted>
          </div>
        </div>
      </HelperWrapper>
      <ModalActionComponent
        title={'Integrate with Zapier'}
        show={showModal}
        onCloseModal={onClose}
        iconType={'custom'}
        icon={<Icon32PlugIn />}
      >
        <div className="mt-3">
          <div className={styles.description}>
            Use the generated key to connect to{' '}
            <a href={'https://zapier.com/'} target={'_blank'} rel={'noreferrer'}>
              Zapier
            </a>
            . It will help you use third-party services to interact with the platform and automate business processes.
          </div>
          <Form.Group controlId={'key'} className={'d-flex'}>
            <div className={styles.inputWrapper}>
              <Form.Control name={'key'} defaultValue={key} disabled className={styles.key} />
              {/* <button className={classNames('btn btn-icon', styles.reload)} onClick={onReload}>
                <IconReload />
              </button> */}
            </div>
            <CopyToClipboard text={key} onCopy={onCopy}>
              <button className={classNames('ml-2 btn btn-gradient-primary d-flex align-items-center', styles.button)}>
                {copied ? (
                  'Copied'
                ) : (
                  <>
                    <IconCopy className={'mr-2'} />
                    Copy
                  </>
                )}
              </button>
            </CopyToClipboard>
          </Form.Group>
        </div>
      </ModalActionComponent>
    </>
  );
};
