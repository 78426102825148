import { memo } from 'react';
import { useHistory } from 'react-router-dom';

import classNames from 'classnames';
import styles from './university-item.module.scss';
import { IconChevronRight } from '../../../../../components/UI/icons';

import { Button } from '../../../../../components/UI';
import { CourseTag } from '../../../../../components/UI/course-tag/CourseTag';
import { ThumbnailPlaceholderImage } from '../../../../../components/UI/image-components/thumbnail-placeholder-image/ThumbnailPlaceholderImage';
import { ICourse } from '../../../../lms/courses/store/CourseState';
import { dashboardPaths } from '../../../routes/DashboardRoutes';

interface IProps {
  course: ICourse;
}

export const UniversityItem = memo(({ course }: IProps) => {
  const history = useHistory();

  const onPreviewClick = () => {
    if (course) {
      history.push(dashboardPaths.dashboardLessons(course.id));
    }
  };

  return (
    <div className={styles.block}>
      <div className={styles.thumbnail}>
        <ThumbnailPlaceholderImage width={288} height={162} thumbnailCourse={course.settings.thumbnail} />
        <CourseTag category={course.settings.category} className={styles.tag} />
        <div className={styles.progressbar}>
          <div className={styles.progress} style={{ width: '0%' }} />
        </div>
      </div>
      <div className={styles.info}>
        <div className={styles.progressText}>0% passed</div>
        <div className={classNames(styles.title, 'ellipsis')}>{course.title}</div>
        <Button onClick={onPreviewClick} className={styles.button} customIcon={<IconChevronRight className={'icon'} />}>
          Begin the course
        </Button>
      </div>
    </div>
  );
});
