import { CommonSignUp } from '../common/common-signup/CommonSignUp';
import { RegistrationForm } from './RegistraionForm';

export const SignUp = () => {
  return (
    <CommonSignUp>
      <RegistrationForm />
    </CommonSignUp>
  );
};
