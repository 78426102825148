import React, { FC, memo, ReactNode } from 'react';
import { Dropdown, DropdownProps } from 'react-bootstrap';

import { CustomToggle } from './CustomToggle';

type IProps = {
  show: boolean;
  onToggle(isOpen: boolean, event: React.SyntheticEvent<Dropdown, Event>): void;
  node: ReactNode;
  // props?: DropdownProps;
}

export const DropMenu: FC<DropdownProps & IProps> = memo(({ show, onToggle, children, node, ...props }) => {
  return (
    <Dropdown show={show} drop={'right'} onToggle={onToggle} alignRight={true} {...props}>
      <Dropdown.Toggle as={CustomToggle}>{node}</Dropdown.Toggle>
      {children}
    </Dropdown>
  );
});
