import React, { memo } from 'react';

import classNames from 'classnames';
import styles from './breadcrumbs.module.scss';
import { Icon16ChevronRight, Icon16Folder, Icon16Home, Icon16Webinar } from '../../../../../components/UI/icons';

import { CustomTooltip } from '../../../../../components/UI';
import { ICourseNode } from '../../../courses/store/CourseState';

interface IProps {
  maxLengthItems?: number;
  tree: ICourseNode[];
  className?: string;
}

export const Breadcrumbs = memo(({ tree, className, maxLengthItems = 2 }: IProps) => {
  const items = tree.length > maxLengthItems ? tree.slice(Math.max(tree.length - maxLengthItems, 0)) : tree;

  const fullPathTooltip = () => {
    let path = 'Home';
    tree.forEach((item) => {
      path += ` > ${item.title}`;
    });
    return path;
  };

  return (
    <CustomTooltip
      customText={fullPathTooltip()}
      direction={'auto-start'}
      className={classNames(styles.container, className)}
      disabled={tree.length < (maxLengthItems + 1)}
    >
      <div className={styles.item}>
        {tree.length > maxLengthItems ? (
          '...'
        ) : (
          <>
            <Icon16Home className={styles.home} />
            Home
          </>
        )}
      </div>
      {items.map((item) => (
        <div key={item.id} className={styles.item}>
          <Icon16ChevronRight />
          {item.type === 'folder' && <Icon16Folder className={'mr-1'} />}
          {item.type === 'lesson' && <Icon16Webinar className={'mr-1'} />}
          <div className={classNames(styles.title, { [styles.step]: item.type === 'step' })}>{item.title}</div>
        </div>
      ))}
    </CustomTooltip>
  );
});
