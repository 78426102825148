export type PaletteThumbType = 'full' | 'thumb';

export enum PaletteAutomationElementTypes {
  ACTION_TAG = 'action.tag',
  ACTION_SEND_EMAIL = 'action.email',
  ACTION_REQUEST = 'action.request',
  ACTION_CHANGE_CONTACT_FIELD = 'action.edit_field',
  ACTION_RUN_ANOTHER = 'action.automation',
  ACTION_LIST = 'action.list',
  ACTION_COURSE_ENROLL = 'action.course_enroll',
  ACTION_ZAPIER = 'action.zapier',

  PROCESSOR_CONDITION = 'condition.simple',
  PROCESSOR_FILTER = 'condition.filter',
  PROCESSOR_RANGE = 'action.range',
  PROCESSOR_ABTEST = 'action.ab',
  PROCESSOR_DELAY = 'action.delay',
  PROCESSOR_STOP = 'action.stop_automation',

  CONTAINER_EVENTS = 'container.events',
  EVENT_TAG_MODIFIED = 'event.tag_modified',
  EVENT_LIST_MODIFIED = 'event.list_modified',
  EVENT_COURSE_ENROLMENT = 'event.course_enrolment',
  EVENT_OFFER_PURCHASED = 'event.offer_purchased',
  EVENT_ZAPIER_TRIGGER_AUTOMATION = 'event.zapier_trigger_automation',

  EVENT_PAYMENTS = 'event.payments',
  EVENT_CUSTOM_FIELD = 'event.custom_field',
  EVENT_OPEN_EMAIL = 'event.opened_email',
  EVENT_API_REQUEST_EVENT = 'event.api_request_event',
  EVENT_PAGE_VISITED = 'event.page_visited'
}

export enum PaletteElementTypes {
  ACTION_AB = 'action.ab',
  ACTION_DELAY = 'action.delay',
  ACTION_EMAIL = 'action.email',
  ACTION_AUTOMATION = 'action.automation',

  PAGES_CALENDAR = 'pages.calendar',
  PAGES_CHECKOUT = 'pages.checkout',
  PAGES_CONFIRMATION = 'pages.confirmation',
  PAGES_CROSS_SELL = 'pages.cross_sell',
  PAGES_DOUBLE_OPT_IN = 'pages.double_opt_in',
  PAGES_DOWNLOAD = 'pages.download',
  PAGES_DOWN_SELL = 'pages.down_sell',
  PAGES_LANDING = 'pages.landing',
  PAGES_MEMBERS_AREA = 'pages.members_area',
  PAGES_OPT_IN = 'pages.opt_in',
  PAGES_EXTERNAL = 'pages.external',
  PAGES_POP_UP = 'pages.pop_up',
  PAGES_PRODUCT = 'pages.product',
  PAGES_SALES = 'pages.sales',
  PAGES_SALES_VIDEO = 'pages.sales_video',
  PAGES_SURVEY = 'pages.survey',
  PAGES_UP_SELL = 'pages.up_sell',
  PAGES_WEBINAR = 'pages.webinar',
  PAGES_WEBINAR_REPLY = 'pages.webinar_replay',
  TRAFFIC_SOURCE_ADWORDS = 'traffic_source.adwords',
  TRAFFIC_SOURCE_EMAIL = 'traffic_source.email',
  TRAFFIC_SOURCE_FACEBOOK = 'traffic_source.facebook',
  TRAFFIC_SOURCE_GENERIC = 'traffic_source.generic',
  TRAFFIC_SOURCE_INSTAGRAM = 'traffic_source.instagram',
  TRAFFIC_SOURCE_INSTAGRAM_AD = 'traffic_source.instagram_ad',
  TRAFFIC_SOURCE_MESSENGER = 'traffic_source.messenger',
  TRAFFIC_SOURCE_RETARGETING = 'traffic_source.retargeting',
  TRAFFIC_SOURCE_TELEGRAM = 'traffic_source.telegram',
  TRAFFIC_SOURCE_TWITTER = 'traffic_source.twitter',
  TRAFFIC_SOURCE_VIBER = 'traffic_source.viber',
  TRAFFIC_SOURCE_WHATSAPP = 'traffic_source.whatsapp',
  TRAFFIC_SOURCE_YOUTUBE = 'traffic_source.youtube'
}

export type ElementType = keyof typeof PaletteElementTypes;
export type ElementAutomationType = keyof typeof PaletteAutomationElementTypes;

export enum PaletteAutomationElementNames {
  ACTION_TAG = 'Assign or Remove tags',
  ACTION_SEND_EMAIL = 'Send e-mail',
  ACTION_REQUEST = 'Post/Get request',
  ACTION_CHANGE_CONTACT_FIELD = 'Change contact field',
  ACTION_RUN_ANOTHER = 'Run another automation',
  ACTION_LIST = 'Change contact list',
  ACTION_COURSE_ENROLL = 'Course enrollment',
  ACTION_ZAPIER = 'Zapier',

  PROCESSOR_CONDITION = 'Condition',
  PROCESSOR_FILTER = 'Custom filter',
  PROCESSOR_RANGE = 'Range & Amount',
  PROCESSOR_ABTEST = 'A/B Test',
  PROCESSOR_DELAY = 'Delay',
  PROCESSOR_STOP = 'Stop automation',

  CONTAINER_EVENTS = '',
  EVENT_PAYMENTS = 'Payments',
  EVENT_CUSTOM_FIELD = 'Custom field changed',
  EVENT_OPEN_EMAIL = 'E-mail opened',
  EVENT_API_REQUEST_EVENT = 'API Request event',
  EVENT_LIST_MODIFIED = 'Added/Removed from lists',
  EVENT_PAGE_VISITED = 'Added/Page visited',
  EVENT_TAG_MODIFIED = 'Tags',
  EVENT_COURSE_ENROLMENT = 'Course enrollment',
  EVENT_OFFER_PURCHASED = 'Offer purchased',
  EVENT_ZAPIER_TRIGGER_AUTOMATION = 'Zapier'
}

export enum PaletteElementNames {
  ACTION_AB = 'A/B Testing',
  ACTION_DELAY = 'Delay',
  ACTION_EMAIL = 'Email',
  ACTION_AUTOMATION = 'Automation',

  PAGES_CALENDAR = 'Calendar',
  PAGES_CHECKOUT = 'Checkout',
  PAGES_CONFIRMATION = 'Confirmation',
  PAGES_CROSS_SELL = 'Cross Sell',
  PAGES_DOUBLE_OPT_IN = 'Double Opt In',
  PAGES_DOWNLOAD = 'Download',
  PAGES_DOWN_SELL = 'Down Sell',
  PAGES_LANDING = 'Landing',
  PAGES_MEMBERS_AREA = 'Memb. Area',
  PAGES_OPT_IN = 'Opt In',
  PAGES_EXTERNAL = 'External',
  PAGES_POP_UP = 'Pop Up',
  PAGES_PRODUCT = 'Product',
  PAGES_SALES = 'Sales Page',
  PAGES_SALES_VIDEO = 'Sales Video',
  PAGES_SURVEY = 'Survey',
  PAGES_UP_SELL = 'Up Sell',
  PAGES_WEBINAR = 'Webinar',
  PAGES_WEBINAR_REPLY = 'Replay',
  TRAFFIC_SOURCE_ADWORDS = 'Adwords',
  TRAFFIC_SOURCE_EMAIL = 'Email',
  TRAFFIC_SOURCE_FACEBOOK = 'Facebook',
  TRAFFIC_SOURCE_GENERIC = 'Generic',
  TRAFFIC_SOURCE_INSTAGRAM = 'Instagram',
  TRAFFIC_SOURCE_INSTAGRAM_AD = 'Inst. ad',
  TRAFFIC_SOURCE_MESSENGER = 'Messenger',
  TRAFFIC_SOURCE_RETARGETING = 'Retargeting',
  TRAFFIC_SOURCE_TELEGRAM = 'Telegram',
  TRAFFIC_SOURCE_TWITTER = 'Twitter',
  TRAFFIC_SOURCE_VIBER = 'Viber',
  TRAFFIC_SOURCE_WHATSAPP = 'Whatsapp',
  TRAFFIC_SOURCE_YOUTUBE = 'Youtube'
}

export enum PaletteCategoryNames {
  pages = 'Pages',
  actions = 'Actions',
  traffic_source = 'Traffic Source',
  misc = 'Misc',
  move = 'Move',
  text = 'Text',
  picture = 'Picture',
  test = 'Test',
  processors = 'Processors',
  info = 'Info'
}

export type CategoryNameType = keyof typeof PaletteCategoryNames;
