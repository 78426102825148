import { reducerWithInitialState } from 'typescript-fsa-reducers';

import { getUUID } from '../../../../helpers';
import { commonErrorHandler, commonFirstLoadHandler } from '../../../../helpers/common-reducer-handlers';
import { IError, ISender } from '../../../../interfaces';
import { LoadState, newState } from '../../../../store';
import { EmailActions } from './EmailActions';
import { EmailInitialState, IEmailState } from './EmailState';

export const emailReducer = reducerWithInitialState<IEmailState<IError>>(EmailInitialState)
  .case(EmailActions.getSenders.started, commonFirstLoadHandler)
  .case(EmailActions.getSenders.done, (state, { result }) => {
    return newState(state, {
      senders: result,
      loadState: LoadState.allIsLoaded,
      error: null
    });
  })
  .case(EmailActions.getSenders.failed, commonErrorHandler)

  .case(EmailActions.createSender.started, commonFirstLoadHandler)
  .case(EmailActions.createSender.done, (state, { result }) => {
    return newState(state, {
      senders: [...state.senders, result],
      loadState: LoadState.allIsLoaded,
      error: null
    });
  })
  .case(EmailActions.createSender.failed, (state, { error, params }) => {
    let senders = state.senders;
    if (error.data.detail?.status === 'error.sender.sender_email_already_used') {
      const errorSender: ISender = {
        id: getUUID(),
        email: params,
        state: 'non-uniq',
        is_default: false,
        origin: 'custom'
      };
      senders = [...senders, errorSender];
    }
    return {
      ...state,
      senders,
      loadState: LoadState.error,
      error
    };
  })

  .case(EmailActions.deleteSender.started, commonFirstLoadHandler)
  .case(EmailActions.deleteSender.done, (state, { params }) => {
    const updatedSenders = state.senders.filter((sender) => sender.id !== params);
    return newState(state, {
      senders: [...updatedSenders],
      loadState: LoadState.allIsLoaded,
      error: null
    });
  })
  .case(EmailActions.deleteSender.failed, commonErrorHandler)

  .case(EmailActions.deleteErrorSender, (state, payload) => {
    const updatedSenders = state.senders.filter((sender) => sender.id !== payload);
    return newState(state, {
      senders: [...updatedSenders],
      loadState: LoadState.allIsLoaded,
      error: null
    });
  })

  .case(EmailActions.editSender.started, commonFirstLoadHandler)
  .case(EmailActions.editSender.done, (state, { result, params }) => {
    const updatedSenders = state.senders.map((sender) => {
      return sender.id === params.id
        ? { ...sender, is_default: result.is_default, email: result.email }
        : {
            ...sender,
            is_default: !result.is_default
          };
    });
    return newState(state, {
      senders: [...updatedSenders],
      loadState: LoadState.allIsLoaded,
      error: null
    });
  })
  .case(EmailActions.editSender.failed, commonErrorHandler)

  .case(EmailActions.getSender.started, commonFirstLoadHandler)
  .case(EmailActions.getSender.done, (state, { result, params }) => {
    const updated = state.senders.map((sender) => (sender.id === params ? result : sender));
    return newState(state, {
      senders: [...updated],
      loadState: LoadState.allIsLoaded,
      error: null
    });
  })
  .case(EmailActions.getSender.failed, commonErrorHandler)

  .case(EmailActions.getMessages.started, (state) => {
    return {
      ...state,
      loadState: state.loadState === LoadState.allIsLoaded ? LoadState.allIsLoaded : LoadState.firstLoad,
      error: null
    };
  })
  .case(EmailActions.getMessages.done, (state, { result }) => {
    return newState(state, {
      ...result.meta,
      messages: result.data,
      loadState: LoadState.allIsLoaded,
      error: null
    });
  })
  .case(EmailActions.getMessages.failed, commonErrorHandler)

  .case(EmailActions.editMessage.started, state => state)
  .case(EmailActions.editMessage.done, (state, { result, params }) => {
    console.log('%c⇒ result', 'color: #89DDF7', result);
    const updatedMessages = state.messages.map((message) => {
      return message.id === params.id ? {...message, ...result} : message;
    });
    return newState(state, {
      messages: updatedMessages,
      loadState: LoadState.allIsLoaded,
      error: null
    });
  })
  .case(EmailActions.duplicateMessage.failed, commonErrorHandler);
