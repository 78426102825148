import { IAccount } from '../../../../interfaces';
import { LoadState } from '../../../../store';

export interface IProfileAccountState {
  loadState: LoadState;
  error: Error | null;
  account: IAccount;
}

export const ProfileAccountInitialState: IProfileAccountState = {
  error: null,
  loadState: LoadState.firstLoad,
  account: {
    id: '',
    is_admin: false,
    is_active: false,
    profile: {
      email: '',
      first_name: '',
      last_name: '',
      phone: '',
      photo: null
    },
    providers: [{ provider: 'google' }],
    organizations: []
  }
};
