import { FC, memo } from 'react';
import { Form } from 'react-bootstrap';

import styles from './input-label.module.scss';

interface IProps {
  label: string;
  classContainer?: string;
}

export const InputLabel: FC<IProps> = memo(({ label, classContainer, children }) => {
  return (
    <Form.Group className={classContainer}>
      <Form.Label className={styles.label}>{label}</Form.Label>
      {children}
    </Form.Group>
  );
});
