import { useEffect, useRef, useState } from 'react';

export const useCopy = (delay = 2000) => {
  const timeoutIdRef = useRef<NodeJS.Timeout>();
  const [copy, setCopy] = useState(false);
  const onCopy = () => {
    setCopy(true);
  };

  useEffect(() => {
    if (copy) {
      timeoutIdRef.current = setTimeout(() => {
        setCopy(false);
      }, delay);
    }
    return () => timeoutIdRef.current && clearTimeout(timeoutIdRef.current);
  }, [copy, delay]);

  return { copy, onCopy };
};
