import { useCallback, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import querystring from 'query-string';

import { EmailAction, EmailActionStatus } from '../API/action-email-api';
import { AuthActions } from '../modules/auth/store/AuthActions';
import { mainPaths } from '../modules/MainRoutes';
import { AccountActionAsync } from '../modules/settings/account/store/AccountActionAsync';
import { SettingsPaths } from '../modules/settings/routes/SettingsRoutes';
import { StudentCabinetPaths } from '../modules/student-cabinet/routes/StudentCabinetRoutes';
import { useQuery } from './use-query';

export const useAction = () => {
  const dispatch = useDispatch();
  const [actionEmail, setActionEmail] = useState<string | undefined>();
  const query = useQuery();
  const history = useHistory<{ link?: string; course_id?: string; action?: string }>();
  const state = query.get('state');
  const { authToken } = useSelector((state) => state.auth);
  const authPath = useRouteMatch(mainPaths.auth);

  const parseState = (state && querystring.parse(state)) as
    | { link?: string; action?: string; course_id?: string; domain?: string }
    | undefined;
  const action = (query.get('action') || parseState?.action) as EmailActionStatus;
  const actionHash = query.get('link') || parseState?.link;
  const courseId = query.get('course_id') || parseState?.course_id;

  const payloadRef = useRef<EmailAction | null>(null);

  const onCloseModal = useCallback(() => {
    setActionEmail(undefined);
  }, []);

  const returnForgotEmail = useCallback((data: EmailAction) => {
    payloadRef.current = data;
    if (data.status === 'error.new_password_missed') {
      setActionEmail('Password is missed');
    }
  }, []);

  const returnEmailInvitationData = useCallback(
    (data: EmailAction) => {
      payloadRef.current = data;
      if (data.status === 'error.email_doesnt_match') {
        setActionEmail(data.payload.email);
      } else if (data.status === 'error.authentication_required' && actionHash) {
        history.push(
          querystring.stringifyUrl({
            url: mainPaths.auth,
            query: {
              action,
              link: actionHash
            }
          })
        );
      } else if (data.status === 'success.redirect_to_organization') {
        dispatch(
          AccountActionAsync.getMe(false, undefined, (account) => {
            if (account.organizations.length > 0) {
              dispatch(AuthActions.setCurrentOrganization(account.organizations[0]));
            }
          })
        );
        history.push(mainPaths.main);
      } else if (data.status === 'error.action_already_used') {
        history.push(mainPaths.main);
      }
    },
    [action, actionHash, dispatch, history]
  );

  const returnEmailEnrollData = useCallback(
    (data: EmailAction) => {
      payloadRef.current = data;
      if (data.status === 'error.email_doesnt_match') {
        setActionEmail(data.payload.email);
      }

      if (data.status === 'success.authenticate_user') {
        dispatch(AuthActions.authSetToken(data.payload.token));
        dispatch(
          AccountActionAsync.getMe(true, data.payload.token, () => {
            history.push({
              pathname: StudentCabinetPaths.courseStudentLessons(data.payload.course_id),
              search: window.location.search
            });
          })
        );
      }

      if (data.status === 'error.authentication_required' && actionHash && courseId) {
        const urlWithParams = querystring.stringifyUrl({
          url: StudentCabinetPaths.auth,
          query: {
            link: actionHash,
            course_id: courseId,
            action: 'enroll',
            email: data.payload.email
          }
        });
        history.push(urlWithParams);
      }
      if (data.status === 'success.redirect_to_course' && courseId) {
        history.push(StudentCabinetPaths.courseStudentLessons(courseId));
      }
    },
    [actionHash, courseId, dispatch, history]
  );

  const redirectHandler = useCallback(() => {
    if (authToken) {
      history.push(SettingsPaths.billing);
    }

    if (!authPath && !authToken && !state) {
      const url = querystring.stringifyUrl({
        url: mainPaths.auth,
        query: {
          link: actionHash,
          action: 'redirect'
        }
      });
      history.push(url);
    }
  }, [actionHash, authPath, authToken, history, state]);

  return {
    onCloseModal,
    actionEmail,
    action,
    actionHash,
    data: payloadRef.current,
    returnEmailEnrollData,
    returnEmailInvitationData,
    redirectHandler,
    returnForgotEmail
  };
};
