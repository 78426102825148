import { useCallback, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { useDispatch } from 'react-redux';

import classNames from 'classnames';
import styles from './payment-card.module.scss';
import { Icon16Trash, IconKebabMenu } from '../../../../components/UI/icons';

import { KebabToggle } from '../../../../components/UI/kebabToggle/KebabToggle';
import { IPaymentCard } from '../../../../interfaces';
import { billingActionAsync } from '../../billing/store';
import { SettingCourseActionAsync } from '../../store/SettingCourseActionAsync';
import { RemoveLastCard } from '../remove-last-card/RemoveLastCard';
import { CardIcon } from './CardIcon';

interface IProps {
  card: IPaymentCard;
  canBeRemoved: boolean;
  className?: string;
}

export const PaymentCard = ({ card, canBeRemoved, className }: IProps) => {
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showRemoveLast, setShowRemoveLast] = useState(false);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const onChangeState = (eventKey: string | null) => {
    switch (eventKey) {
      case 'Remove':
        if (!canBeRemoved && card.is_default) {
          setShowRemoveLast(true);
        } else {
          setShowConfirmation(true);
        }
        break;
      case 'setDefault':
        dispatch(SettingCourseActionAsync.setDefaultCard(card.id));
        break;
    }
  };

  const deleteCard = () => {
    setShowConfirmation(false);
    dispatch(SettingCourseActionAsync.removePaymentCard(card.id));
  };

  const onCancel = () => {
    setShowConfirmation(false);
  };

  const onCLoseRemoveLast = useCallback(() => {
    setShowRemoveLast(false);
  }, []);

  const onRemoveLastCard = useCallback(() => {
    setLoading(true);
    dispatch(
      SettingCourseActionAsync.removePaymentCard(card.id, () => {
        dispatch(
          billingActionAsync.deleteSubscription(true, () => {
            setLoading(false);
            onCLoseRemoveLast();
            dispatch(billingActionAsync.getSubscription());
          })
        );
      })
    );
  }, [card.id, dispatch, onCLoseRemoveLast]);

  return (
    <>
      <div className={classNames(styles.container, className, { [styles.default]: card.is_default })}>
        {card.is_default && (
          <div className={styles.defaultLabel}>
            Default payment <br /> method
          </div>
        )}
        {/*<div className={styles.expired}>*/}
        {/*  Expired {card.exp_month}/{card.exp_year}*/}
        {/*</div>*/}
        <Dropdown onSelect={onChangeState} className={styles.menu}>
          <Dropdown.Toggle as={KebabToggle} id="dropdown-basic">
            <IconKebabMenu />
          </Dropdown.Toggle>
          <Dropdown.Menu align="right" className={classNames('sm selector', styles.dropdown)}>
            <Dropdown.Item eventKey={'setDefault'} className={styles.menuItem} disabled={card.is_default}>
              Set as default
            </Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item
              eventKey={'Remove'}
              className={styles.remove}
              disabled={(!canBeRemoved || card.is_default) && !(!canBeRemoved && card.is_default)}
            >
              <Icon16Trash /> Remove
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        <div className={styles.cardInfo}>
          <div className="number">×××× {card.last4}</div>
          <div className="icon">
            <CardIcon subscription_payment_source={card} />
          </div>
        </div>
        {showConfirmation && (
          <div className={styles.confirmation}>
            <div className={styles.confirmationTitle}>Remove this card?</div>
            <div className={styles.buttons}>
              <button className={'btn'} onClick={onCancel}>
                Cancel
              </button>
              <button className={'btn'} onClick={deleteCard}>
                Confirm
              </button>
            </div>
          </div>
        )}
      </div>
      <RemoveLastCard
        show={showRemoveLast}
        onClose={onCLoseRemoveLast}
        onRemove={onRemoveLastCard}
        card={card}
        loading={loading}
      />
    </>
  );
};
