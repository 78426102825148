import { FC, memo } from 'react';

import classNames from 'classnames';
import commonStyles from '../price.module.scss';
import styles from './free.module.scss';

import { UnavailableBtnPlan } from '../../../../../../components/unvailable-button-plan/UnvaliableBtnPlan';

interface IProps {
  isActive: boolean;
  isFreePlan: boolean;
  className?: string;
}

export const Free: FC<IProps> = memo(({ isActive, children, className, isFreePlan }) => {
  const onUpgradePlan = () => {};
  return (
    <div className={classNames(commonStyles.priceContainerElement, { [commonStyles.active]: isActive }, className)}>
      <div className="d-flex align-items-center">
        {children}
        {isFreePlan && (
          <UnavailableBtnPlan
            titleTooltip={'Price settings'}
            descriptionTooltip={'To allow the free option, you need to upgrade your account to a paid plan.'}
            size={'small'}
            className={styles.unavailableBtnPlan}
          />
        )}
      </div>
      {isActive && <div className={styles.text}>Consumer will receive the products for free</div>}
    </div>
  );
});
