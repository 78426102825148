import { Link } from 'react-router-dom';

import styles from './subscribeHeader.module.scss';

import { SettingsPaths } from '../../../settings/routes/SettingsRoutes';

export const SubscribeHeader = () => {
  return (
    <div className={'block-account'}>
      <div className={styles.container}>
        🔒 Your account is blocked.&nbsp;
        <Link to={SettingsPaths.billing} className={styles.link}>
          Renew your subscription →
        </Link>
      </div>
    </div>
  );
};
