import styles from './connect-guide.module.scss';
import { Icon16NewWindow, Icon32ArrowLeft } from '../../../../../../components/UI/icons';

import paynetGuide from '../../../../../../../documentation/paynet-guide.json';
import { Button } from '../../../../../../components/UI';

interface IProps {
  onBackToForm: () => void;
}
export const ConnectGuide = ({ onBackToForm }: IProps) => {
  return (
    <div className={styles.container}>
      <div className={styles.title}>{paynetGuide.title}</div>
      {paynetGuide.steps.map((step, index) => (
        <div key={index} className={styles.text}>
          <span>{step.title.slice(0, step.title.indexOf('{{'))}</span>
          {step.link?.url ? (
            <a href={step.link?.url} target={'_blank'} rel="noreferrer">
              {step.link.hyperlink_name}
              <Icon16NewWindow className={'ml-1'} />
            </a>
          ) : (
            step.link?.hyperlink_name
          )}
          <span>{step.title.substring(step.title.indexOf('}}') + 2)}</span>
        </div>
      ))}
      <Button
        onClick={onBackToForm}
        customIcon={<Icon32ArrowLeft />}
        className={'mt-5 w-100'}
        btnStyle={'secondary'}
        size={'lg'}
      >
        Back
      </Button>
    </div>
  );
};
