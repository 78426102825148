import classnames from 'classnames';
import styles from './colorItem.module.scss';

import { ITag } from '../../../modules/crm/tag/store/TagState';

interface IProps {
  background: string;
  color: string;
  tag: ITag | undefined;
  onChangeColor?: (background: string, color: string) => void;
}

export const ColorItem = ({ background, color, tag, onChangeColor }: IProps) => {
  return (
    <div
      className={classnames(styles.container, tag && tag.background_color === background ? styles.selectedItem : '')}
      style={{ backgroundColor: background }}
      onClick={() => onChangeColor && onChangeColor(background, color)}
    >
      <div className={styles.text} style={{ color }}>
        T
      </div>
    </div>
  );
};
