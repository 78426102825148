import { memo } from 'react';

import styles from './emptyEmail.module.scss';
import { Icon32Plus } from '../../../../../../../components/UI/icons';

interface IProps {
  onAddEmail: () => void;
}

export const EmptyEmail = memo(({ onAddEmail }: IProps) => {
  return (
    <div className={styles.container} onClick={onAddEmail}>
      <div className={'mr-2'}>
        <Icon32Plus className={styles.text} />
      </div>
      <div className={styles.text}>Add email message</div>
    </div>
  );
});
