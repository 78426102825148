import moment from 'moment';
import { DataNode } from 'rc-tree/lib/interface';
import { find, flatMapDeep, omit } from 'lodash';

import classNames from 'classnames';
import styles from './course-navigation-tree-view.module.scss';
import {
  Icon16MultimediaPlayFilled,
  IconCheckMark,
  IconFolderSolid,
  IconFolderSolidReplay,
  IconWebinar,
  IconWebinarReplay
} from '../../../../UI/icons';

import { ICourse, ICourseNode } from '../../../../../modules/lms/courses/store/CourseState';
import { flatten } from '../../../course/CourseHelpers';
import { AvailableNode } from './available-node/AvailableNode';

export interface ICourseHierarchySidebar extends DataNode, Omit<ICourseNode, 'children' | 'title'> {}

const filter = (
  array: ICourseNode[],
  isProgressSequential: boolean,
  flatLessons: ICourseNode[] | undefined,
  course: ICourse
) => {
  return array?.reduce((acc: ICourseHierarchySidebar[], currentValue) => {
    // console.log('currentValue', currentValue);

    const dripIsOff = currentValue.condition && moment().diff(moment(currentValue.condition.open_at)) < 0;
    if (currentValue.children && (currentValue.type === 'folder' || currentValue.type === 'lesson')) {
      if (currentValue.type === 'lesson') {
        const { haveFolderDrip } = haveParentDrip(currentValue.id, course);

        const haveNotPass = haveNotPassed(currentValue, isProgressSequential, flatLessons);
        const icon =
          (currentValue.is_passed && <IconCheckMark className={styles.iconCompleted} />) ||
          ((currentValue.condition === null || !dripIsOff) && (
            <IconWebinar
              className={classNames(dripIsOff && styles.iconDripLesson, {
                [styles.haveHotPassed]: haveNotPass,
                [styles.iconDisable]: haveFolderDrip
              })}
            />
          )) ||
          (dripIsOff && <IconWebinarReplay className={classNames(styles.iconDrip)} />);
        acc.push({
          ...omit(currentValue, 'children'),
          key: currentValue.id,
          className: classNames('rc-tree-node-content-lesson', styles.titleLesson),
          icon: icon,
          isLeaf: false,
          title: (
            <div className={classNames(styles.progressContainer, 'selected-lesson')} id={currentValue.id}>
              <Icon16MultimediaPlayFilled />
              {/* <TransparentPercentLoader />  */}
              <div className={classNames(styles.itemTitle, { [styles.drip]: dripIsOff })}>
                {currentValue.title}
                {dripIsOff && currentValue.condition && <AvailableNode date={currentValue.condition.open_at} />}
              </div>
            </div>
          )
        });
      } else {
        // console.log(acc, currentValue);
        acc.push({
          ...currentValue,
          children: filter(currentValue.children, isProgressSequential, flatLessons, course),
          key: currentValue.id,
          id: currentValue.id,
          selectable: false,
          icon: dripIsOff ? <IconFolderSolidReplay className={styles.iconDrip} /> : <IconFolderSolid />,
          // isLeaf: currentValue.children.length > 0,
          title: (
            <div
              className={classNames(styles.progressContainer, dripIsOff && 'drip-folder', 'click-navigation')}
              id={currentValue.id}
            >
              {/* <TransparentPercentLoader />  */}
              <div className={styles.itemTitle}>
                {currentValue.title}
                {dripIsOff && currentValue.condition && <AvailableNode date={currentValue.condition?.open_at} />}
              </div>
            </div>
          ),
          className: classNames('rc-tree-node-content-folder', styles.title)
        });
      }
    }

    return acc;
  }, []);
};

export const toTreeData = (
  data: ICourseNode[],
  isProgressSequential: boolean,
  flatLessons: ICourseNode[] | undefined,
  course: ICourse
) => {
  return filter(data, isProgressSequential, flatLessons, course);
};

export const haveNotPassed = (lesson: ICourseNode, isProgressSequential: boolean, flatLessons?: ICourseNode[]) => {
  if (flatLessons && isProgressSequential && !lesson.is_passed) {
    const currentLessonIndex = flatLessons?.findIndex((x) => x.id === lesson.id);
    const prevLessons = flatLessons?.slice(0, currentLessonIndex);

    return prevLessons.find((x) => x.is_passed === false);
  }
};

const haveParentDrip = (lessonId: string, course: ICourse) => {
  const hierarchy = course.children;
  const treeNodes: ICourseNode[] = [];
  let node = find<ICourseNode | undefined>(flatMapDeep(hierarchy, flatten), ['id', lessonId]);
  while (node?.parent_id) {
    node = find<ICourseNode | undefined>(flatMapDeep(hierarchy, flatten), ['id', node?.parent_id]);
    if (node && node?.type === 'folder') {
      treeNodes.push(node);
    }
  }
  return {
    haveFolderDrip: treeNodes.find((x) => x.condition && moment().diff(moment(x.condition.open_at)) < 0)
  };
};
