import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { CommonAuth } from '../../auth/common/common-auth/CommonAuth';
import { IntroduceForm } from '../../auth/common/introduce-form/IntroduceForm';
import { mainPaths } from '../../MainRoutes';
import { AccountActionAsync } from '../../settings/account/store/AccountActionAsync';

export const CreateOrganization = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const onSaveCallback = useCallback(() => {
      dispatch(
        AccountActionAsync.createOrganization(null, () => {
          dispatch(
            AccountActionAsync.getMe(false, undefined, () => {
              history.push(mainPaths.main);
            })
          );
        })
      );
  }, [dispatch, history]);

  const onSave = useCallback((data: { firstName: string; lastName: string }) => {
    dispatch(
      AccountActionAsync.editMe(
        {
          first_name: data.firstName,
          last_name: data.lastName
        },
        undefined,
        onSaveCallback
      )
    );
  }, [dispatch, onSaveCallback]);

  return (
    <CommonAuth>
      <IntroduceForm onSave={onSave} />
    </CommonAuth>
  );
};
