import { memo, useState } from 'react';

import styles from './cancel-subscription.module.scss';
import { Icon32Cross } from '../../../../components/UI/icons';

import { CommonModal } from '../../../../components/UI';
import { StepFive } from './step-five/StepFive';
import { StepFour } from './step-four/StepFour';
import { StepOne } from './step-one/StepOne';
import { StepThree } from './step-three/StepThree';
import { StepTwo } from './step-two/StepTwo';

export type UnsubscribeSteps = 'step-1' | 'step-2' | 'step-3' | 'step-4' | 'step-5';

interface IProps {
  show: boolean;
  onClose(): void;
}

export const CancelSubscription = memo(({ show, onClose }: IProps) => {
  const [activeStep, setActiveStep] = useState<UnsubscribeSteps>('step-1');

  const changeStep = (step: UnsubscribeSteps) => {
    setActiveStep(step);
  };

  const onCancel = () => {
    onClose();
    setActiveStep('step-1');
  };

  return (
    <CommonModal show={show} onClose={onCancel} params={{ size: 'sm', className: styles.unsubscribe }}>
      <button onClick={onCancel} className={'btn btn-icon btn-modal-close'}>
        <Icon32Cross />
      </button>
      {activeStep === 'step-1' && <StepOne onCancel={onCancel} onNext={changeStep} />}
      {activeStep === 'step-2' && <StepTwo onCancel={onCancel} onNext={changeStep} />}
      {activeStep === 'step-3' && <StepThree onCancel={onCancel} onNext={changeStep} />}
      {activeStep === 'step-4' && <StepFour onCancel={onCancel} onNext={changeStep} />}
      {activeStep === 'step-5' && <StepFive onCancel={onCancel} onNext={changeStep} />}
    </CommonModal>
  );
});
