import { NavLink } from 'react-router-dom';

import classNames from 'classnames';
import styles from '../../../../../styles/components/navbar.module.scss';

import { automationPaths } from '../../routes/AutomationRoutes';

export const AutomationsHeader = () => {
  const navigation = [
    { title: 'Workflows', path: automationPaths.automationList },
    { title: 'Messages', path: automationPaths.messages },
    { title: 'Runs', path: automationPaths.runs },
  ];

  return (
    <div className={classNames('navbar navbar-expand justify-content-end', styles.navbar)}>
      <ul className={classNames('navbar-nav mx-auto', styles['navbar-nav'])}>
        {navigation.map((item, index) => (
          <li key={index} className={classNames('nav-item', styles.item)}>
            <NavLink className={classNames('nav-link', styles.link)} to={item.path} activeClassName={styles.active}>
              {item.title}
            </NavLink>
          </li>
        ))}
      </ul>
    </div>
  );
};
