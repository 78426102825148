import { memo } from 'react';

import styles from './preview-offer.module.scss';

interface IProps {
  index: number;
}

export const SliderTitle = memo(({ index }: IProps) => {
  return (
    <>
      {index === 0 && <div className={styles.text}>Desktop </div>}
      {index === 1 && <div className={styles.text}>Tablet </div>}
      {index === 2 && <div className={styles.text}>Mobile </div>}
    </>
  );
});
