import styles from './list-item.module.scss';
import { IconCheckMark, IconCross } from '../../../../../../../components/UI/icons';

interface IProps {
  hover: boolean;
}

export const ListItemSelected = ({ hover }: IProps) => (
  <>
    {hover ? (
      <div className={styles.closeContainer}>
        <IconCross className={styles.close} />
      </div>
    ) : (
      <IconCheckMark className={styles.iconCheck} />
    )}
  </>
);
