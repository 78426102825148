import { useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';

import classNames from 'classnames';
import styles from './course-shareable-link.module.scss';
import { Icon16CheckMark, Icon16Copy, Icon32Link } from '../../../../../components/UI/icons';

import { ModalActionComponent } from '../../../../../components/UI';
import { ThumbnailPlaceholderImage } from '../../../../../components/UI/image-components/thumbnail-placeholder-image/ThumbnailPlaceholderImage';
import { ICourse } from '../../store/CourseState';
import { CopyLinkBlock } from '../copy-link-block/CopyLinkBlock';

interface IProps {
  course: ICourse;
  show: boolean;
  onClose(): void;
}

export const CourseShareableLink = ({ course, show, onClose }: IProps) => {
  const [copiedId, setCopiedId] = useState(false);

  const onCopyId = () => {
    setCopiedId(true);
  };

  return (
    <ModalActionComponent
      show={show}
      title={<span className={styles.title}>Get {<span className={styles.main}>shareable link</span>}</span>}
      onCloseModal={onClose}
      icon={<Icon32Link />}
      iconStyles={styles.iconLink}
      iconType={'custom'}
      titleClassName={'pt-0'}
      className={styles.containerModal}
    >
      <div className={styles.sharableDescription}>
        <div className={'d-flex align-items-center'}>
          <ThumbnailPlaceholderImage width={96} height={54} thumbnailCourse={course.settings.thumbnail} />
          <div className={classNames(styles.shareableTitle, 'ellipsis')}>{course.title}</div>
        </div>
        <div className={styles.sharedText}>Share this link with your consumers to invite them to the course</div>
        <CopyToClipboard text={course.id} onCopy={onCopyId}>
          <div className={styles.iconCopy}>
            ID: {course.id} {copiedId ? <Icon16CheckMark /> : <Icon16Copy />}
          </div>
        </CopyToClipboard>

        <CopyLinkBlock courseId={course.id} />
      </div>
    </ModalActionComponent>
  );
};
