import { reducerWithInitialState } from 'typescript-fsa-reducers';

import { commonErrorHandler, commonFirstLoadHandler } from '../../../helpers/start-error-loader';
import { LoadState, newState } from '../../../store';
import { permissionActions } from './PermissionActions';
import { IPermissionState, PermissionInitialState } from './PermissionState';

export const permissionReducer = reducerWithInitialState<IPermissionState>(PermissionInitialState)
  .case(permissionActions.getPermissions.started, commonFirstLoadHandler)
  .case(permissionActions.getPermissions.done, (state, { result }) => {
    return newState(state, {
      permissions: result,
      error: null,
      loadState: LoadState.allIsLoaded
    });
  })
  .case(permissionActions.getPermissions.failed, commonErrorHandler);
