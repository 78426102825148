import { CustomDispatch } from 'redux';
import { PaymentMethod } from '@stripe/stripe-js';

import {
  getClientSecret,
  getPaymentCards,
  getPaymentHistory,
  paymentMethod,
  removePaymentCard,
  setDefaultCard
} from '../../../API/payment-api';
import { getZapierKey } from '../../../API/settings-api';
import { IPaymentCard } from '../../../interfaces';
import { settingCourseActions } from './SettingCourseActions';
import { IPaymentHistory } from './SettingCourseState';

export const SettingCourseActionAsync = {
  paymentMethod: (returnUrl?: string, callback?: (url: string) => void) => async (dispatch: CustomDispatch) => {
    try {
      dispatch(settingCourseActions.paymentMethod.started({ returnUrl }));
      const response = await paymentMethod(returnUrl);
      if (response.data) {
        dispatch(settingCourseActions.paymentMethod.done({ params: { returnUrl }, result: response.data }));
        callback?.(response.data.url);
      }
    } catch (error) {
      dispatch(settingCourseActions.paymentMethod.failed({ error, params: { returnUrl } }));
    }
  },
  getZapierKey: (callback: (api_key: string) => void) => async (dispatch: CustomDispatch) => {
    try {
      dispatch(settingCourseActions.getZapierKey.started());
      const response = await getZapierKey();
      if (response.data) {
        dispatch(settingCourseActions.getZapierKey.done({ result: response.data }));
        callback?.(response.data.api_key);
      }
    } catch (error) {
      dispatch(settingCourseActions.getZapierKey.failed({ error }));
    }
  },
  getPaymentCards: (callback?: (cards: IPaymentCard[]) => void) => async (dispatch: CustomDispatch) => {
    try {
      dispatch(settingCourseActions.getPaymentCards.started());
      const response = await getPaymentCards();
      if (response.data) {
        dispatch(settingCourseActions.getPaymentCards.done({ result: response.data }));
        callback?.(response.data);
      }
    } catch (error) {
      dispatch(settingCourseActions.getPaymentCards.failed({ error }));
    }
  },
  removePaymentCard: (id: string, callback?: () => void) => async (dispatch: CustomDispatch) => {
    try {
      dispatch(settingCourseActions.removePaymentCard.started({ id }));
      const response = await removePaymentCard(id);
      dispatch(settingCourseActions.removePaymentCard.done({ result: response.data, params: { id } }));
      callback?.();
    } catch (error) {
      dispatch(settingCourseActions.removePaymentCard.failed({ error, params: { id } }));
    }
  },
  setDefaultCard: (id: string | PaymentMethod, callback?: () => void) => async (dispatch: CustomDispatch) => {
    try {
      dispatch(settingCourseActions.setDefaultCard.started({ id }));
      const response = await setDefaultCard(id);
      dispatch(settingCourseActions.setDefaultCard.done({ result: response.data, params: { id } }));
      callback?.();
    } catch (error) {
      dispatch(settingCourseActions.setDefaultCard.failed({ error, params: { id } }));
    }
  },
  getPaymentHistory: (callback?: (history: IPaymentHistory) => void) => async (dispatch: CustomDispatch) => {
    try {
      dispatch(settingCourseActions.getPaymentHistory.started());
      const response = await getPaymentHistory();
      if (response.data) {
        dispatch(settingCourseActions.getPaymentHistory.done({ result: response.data }));
        callback?.(response.data);
      }
    } catch (error) {
      dispatch(settingCourseActions.getPaymentHistory.failed({ error }));
    }
  },
  getClientSecret: (callback: (clientSecret: string) => void) => async (dispatch: CustomDispatch) => {
    try {
      dispatch(settingCourseActions.getClientSecret.started());
      const response = await getClientSecret();
      if (response.data) {
        dispatch(settingCourseActions.getClientSecret.done({ result: response.data }));
        callback?.(response.data.client_secret);
      }
    } catch (error) {
      dispatch(settingCourseActions.getClientSecret.failed({ error }));
    }
  }
};
