import { useCallback, useState } from 'react';

import { CommonSignUp } from '../common/common-signup/CommonSignUp';
import { RecoveryFormWrapper } from './recovery-form-wrapper/RecoveryFormWrapper';
import { RecoverySuccess } from './recovery-success/RecoverySuccess';

export const RecoveryPassword = () => {
  const [isFormRecovery, setIsFormRecovery] = useState(true);

  const onSuccessScreen = useCallback(() => {
    setIsFormRecovery(false);
  }, []);

  return (
    <CommonSignUp>
      {isFormRecovery ? <RecoveryFormWrapper onSuccessScreen={onSuccessScreen} /> : <RecoverySuccess />}
    </CommonSignUp>
  );
};
