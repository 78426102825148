import React, { ButtonHTMLAttributes, forwardRef, memo, ReactNode, Ref } from 'react';

import classNames from 'classnames';

import {
  Icon32ArrowRight,
  Icon32CheckMark,
  Icon32Plus,
  Icon32Spinner,
  IconArrowLeft,
  IconArrowRight,
  IconCheckMark,
  IconInviteUser,
  IconLink,
  IconPlus,
  IconSpinner,
  IconTrash
} from '../icons';

export type ButtonType =
  | 'create'
  | 'save'
  | 'delete'
  | 'update'
  | 'create-student'
  | 'arrow-right'
  | 'arrow-left'
  | 'link';

enum ButtonStyle {
  primary = 'btn-gradient-primary',
  secondary = 'btn-gradient-secondary',
  outline = 'btn-outline',
  transparent = 'btn-transparent',
  warning = 'btn-warning'
}

type ButtonStyleType = keyof typeof ButtonStyle;

interface IProps {
  type?: ButtonType;
  size?: 'sm' | 'lg';
  withIcon?: boolean;
  customIcon?: JSX.Element;
  className?: string;
  btnStyle?: ButtonStyleType;
  btnProps?: ButtonHTMLAttributes<HTMLButtonElement>;
  iconPosition?: 'left' | 'right';
  loading?: boolean;
  children?: ReactNode;
  onClick?(e?: React.SyntheticEvent<HTMLButtonElement>): void;
}

export const Button = memo(
  forwardRef(
    (
      {
        type = 'create',
        size = 'sm',
        btnStyle = 'primary',
        withIcon = true,
        iconPosition = 'right',
        loading,
        customIcon,
        className,
        onClick,
        children,
        btnProps
      }: IProps,
      ref: Ref<HTMLButtonElement>
    ) => {
      const classes = classNames(
        'btn',
        { 'btn-sm': size === 'sm' },
        { 'btn-lg': size === 'lg' },
        { [iconPosition === 'right' ? 'btn-icon-right' : 'btn-icon-left']: withIcon },
        { inverse: iconPosition === 'left' },
        { 'btn-loading': loading },
        className,
        ButtonStyle[btnStyle]
      );

      const loader = size === 'lg' ? <Icon32Spinner className={'spinner'} /> : <IconSpinner className={'spinner'} />;

      let icon = size === 'lg' ? <Icon32Plus className={'icon'} /> : <IconPlus className={'icon'} />;
      if (customIcon) {
        icon = customIcon;
      } else {
        switch (type) {
          case 'save':
          case 'update':
            icon = size === 'lg' ? <Icon32CheckMark className={'icon'} /> : <IconCheckMark className={'icon'} />;
            break;
          case 'delete':
            icon = <IconTrash className={'icon'} />;
            break;
          case 'create-student':
            icon = <IconInviteUser className={'icon'} />;
            break;
          case 'arrow-right':
            icon = size === 'lg' ? <Icon32ArrowRight className={'icon'} /> : <IconArrowRight className={'icon'} />;
            break;
          case 'arrow-left':
            icon = <IconArrowLeft className={'icon'} />;
            break;
          case 'link':
            icon = <IconLink className={'icon'} />;
            break;
        }
      }

      return (
        <button type={'button'} className={classes} onClick={onClick} ref={ref} {...btnProps}>
          {loading ? (
            <>{loader}</>
          ) : (
            <>
              {children}
              {withIcon && icon}
            </>
          )}
        </button>
      );
    }
  )
);
