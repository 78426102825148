import { CSSProperties, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';

import { commentsPaths } from '../../modules/comment/routes/CommentRoutes';
import { ProductListPanel } from '../../modules/crm/offers/common/product-list-panel/ProductListPanel';
import { CRMPaths } from '../../modules/crm/routes/CRMRoutes';
import { MainRoutes, PrivateRoutes } from '../../modules/MainRoutes';
import { BillingPermissionSelector } from '../../modules/settings/billing/store';
import { StudentCabinetRoutes } from '../../modules/student-cabinet/routes/StudentCabinetRoutes';
import { Logo } from '../shared-components/Logo';
import { SchoolHeaderPanel } from './schoolHeaderPanel/SchoolHeaderPanel';

interface IProps {
  customStyles?: CSSProperties;
  showBlockAccount: boolean;
}

export const Header = ({ customStyles, showBlockAccount }: IProps) => {
  const subscriptionMemo = useMemo(BillingPermissionSelector, []);
  const { isLoading } = useSelector(subscriptionMemo);

  return (
    <header className="header" style={customStyles}>
      <Logo />
      <Switch>
        {isLoading &&
          [...MainRoutes, ...PrivateRoutes(showBlockAccount)].map((route, index) => (
            <Route key={index} path={route.path} exact={route.exact} children={route.header && <route.header />} />
          ))}
        {isLoading && <Route path={CRMPaths.payments} exact={false} children={<ProductListPanel />} />}
        {isLoading && <Route path={commentsPaths.comments} exact={false} children={<SchoolHeaderPanel />} />}
        {[...StudentCabinetRoutes].map((route, index) => (
          <Route key={index} path={route.path} exact={route.exact} children={route.header && <route.header />} />
        ))}
      </Switch>
    </header>
  );
};
