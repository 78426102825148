import { CustomDispatch } from 'redux';

import { createPolicy, deletePolicy, editPolicy, getPolicyList } from '../../../../API/policy-api';
import { IError } from '../../../../interfaces';
import { ICreatePolicy, IEditPolicy, IPolicy } from '../../../../interfaces/policy';
import { PolicyActions } from './PolicyActions';

export const PolicyActionAsync = {
  getPolicyList: (organization_id?: string, callBack?: (data: IPolicy[]) => void) => async (dispatch: CustomDispatch) => {
    try {
      dispatch(PolicyActions.getPolicyList.started());
      const response = await getPolicyList(organization_id);
      if (response.data) {
        dispatch(PolicyActions.getPolicyList.done({ result: response.data }));
        callBack?.(response.data);
      }
    } catch (error) {
      dispatch(PolicyActions.getPolicyList.failed({ error }));
    }
  },
  createPolicy: (request: ICreatePolicy, callBack?: (data: IPolicy) => void) => async (dispatch: CustomDispatch) => {
    try {
      dispatch(PolicyActions.createPolicy.started(request));
      const response = await createPolicy(request);
      if (response.data) {
        dispatch(PolicyActions.createPolicy.done({ result: response.data, params: request }));
        callBack?.(response.data);
      }
    } catch (error) {
      dispatch(PolicyActions.createPolicy.failed({ error, params: request }));
    }
  },
  editPolicy:
    (id: string, request: IEditPolicy, callBack?: (data: IPolicy) => void, onError?: (error: IError) => void) =>
    async (dispatch: CustomDispatch) => {
      try {
        dispatch(PolicyActions.editPolicy.started({ id, data: request }));
        const response = await editPolicy(id, request);
        if (response.data) {
          dispatch(PolicyActions.editPolicy.done({ result: response.data, params: { id, data: request } }));
          callBack?.(response.data);
        }
      } catch (error) {
        dispatch(PolicyActions.editPolicy.failed({ error, params: { id, data: request } }));
        onError?.(error);
      }
    },
  deletePolicy:
    (id: string, callBack?: () => void, onError?: (error: IError) => void) => async (dispatch: CustomDispatch) => {
      try {
        dispatch(PolicyActions.deletePolicy.started({ id }));
        const response = await deletePolicy(id);
        if (response.status === 200) {
          dispatch(PolicyActions.deletePolicy.done({ result: response.data, params: { id } }));
          console.log(response);
          callBack?.();
        }
      } catch (error) {
        dispatch(PolicyActions.deletePolicy.failed({ error, params: { id } }));
        onError?.(error);
      }
    }
};
