import { UseFormReturn } from 'react-hook-form';

import classNames from 'classnames';
import styles from '../../../../lms/courses/curriculum/lesson/lesson-unsaved.module.scss';
import { Icon32Cross, Icon32Warning } from '../../../../../components/UI/icons';

import { Button, CommonModal } from '../../../../../components/UI';

interface IProps<T> {
  show: boolean;
  methods: UseFormReturn<T>;
  onSave: (data: T) => void;
  onClose: () => void;
  onCancel: () => void;
}

export const OfferUnsavedChanges = <T extends object>({ show, methods, onSave, onClose, onCancel }: IProps<T>) => {
  return (
    <CommonModal show={show} onClose={onClose} params={{ dialogClassName: styles.modalContainer }}>
      <div className={styles.modalTitle} id={'offer-unsaved-change'}>
        <Icon32Warning className={styles.iconWarning} />
        <h2 className={styles.title}>You have unsaved changes</h2>
      </div>
      <div className={styles.description}>All changes will be deleted unless you save them</div>
      <div className={styles.wrapper}>
        <Button onClick={methods.handleSubmit(onSave)} withIcon={false} className={styles.btn}>
          Save changes
        </Button>
        <Button
          onClick={onCancel}
          withIcon={false}
          btnStyle={'transparent'}
          className={classNames(styles.btn, 'mt-2')}
        >
          Continue without saving
        </Button>
      </div>
      <Icon32Cross className={styles.iconClose} onClick={onClose} />
    </CommonModal>
  );
};
