import { useState } from 'react';

import styles from './enrollment-resend.module.scss';

import { IStudentCourse } from '../../store/StudentListState';
import { EnrollmentResendNotConfirmed } from './enroll-resend-not-confirmed/EnrollmentResendNotConfirmed';
import { EnrollSuccessResend } from './enroll-success-resend/EnrollSuccessResend';

interface IProps {
  studentId: string;
  email: string;
  courseList: IStudentCourse[];
  userId: string | null;
}

export const EnrollmentResend = ({ email, studentId, courseList, userId }: IProps) => {
  const [successSend, setSuccessSend] = useState(false);
  return (
    <>
      {!userId && courseList.length > 0 && !successSend && (
        <div className={styles.container}>
          <EnrollmentResendNotConfirmed
            email={email}
            studentId={studentId}
            courseList={courseList}
            setSuccessSend={setSuccessSend}
          />
        </div>
      )}
      {!userId && courseList.length > 0 && successSend && (
        <div className={styles.container}>
          <EnrollSuccessResend email={email} setSuccessSend={setSuccessSend} />
        </div>
      )}
    </>
  );
};
