import classnames from 'classnames';
import styles from './canvas-node-auto.module.scss';

import { ElementThumb } from '../../../../components/illustrations/ElementThumb';
import { NodeTypes } from '../../../../interfaces';

interface IProps {
  type: NodeTypes;
  title: string;
}

export const CanvasNodeAutomationItem = ({ title, type }: IProps) => {
  return (
    <>
      <div className={styles.icon}>
        <ElementThumb name={type} builderType={'automation'} />
      </div>
      <div className={classnames('title pl-2 pr-4 mb-0', styles.title)}>{title}</div>
    </>
  );
};
