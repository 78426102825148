import { memo } from 'react';

import styles from './paymentState.module.scss';

import { StripeAccount } from '../../general/store/GeneralSettingsState';
import { PaymentStatus } from './PaymentStatus';

export const PaymentState = memo(
  ({ state, display_name, description }: Pick<StripeAccount, 'display_name' | 'state'> & { description: string }) => {
    return (
      <div>
        {state === 'non_integrated' && <div className={styles.description}>{description}</div>}
        {state === 'incomplete' && (
          <PaymentStatus className={styles.incomplete} statusText={'Not complete'} display_name={display_name} />
        )}
        {state === 'in_pending' && (
          <PaymentStatus className={styles.pending} statusText={'Pending'} display_name={display_name} />
        )}
        {state === 'rejected' && (
          <PaymentStatus className={styles.rejected} statusText={'Rejected'} display_name={display_name} />
        )}
        {state === 'done' && (
          <PaymentStatus className={styles.done} statusText={'Connected'} display_name={display_name} />
        )}
      </div>
    );
  }
);
