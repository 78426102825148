import { NavLink, useRouteMatch } from 'react-router-dom';

import styles from './settings-sidebar.module.scss';
import { Icon32CheckMark, Icon32Code, Icon32Gear } from '../../../../../../components/UI/icons';

import { coursePaths } from '../../../routes/CourseRoutes';
import { courseSettingsPaths } from '../../../settings/routes/CourseSettingsRoutes';

export const SettingsSidebar = () => {
  const navigation = [
    {
      title: 'General',
      icon: Icon32Gear,
      path: courseSettingsPaths.general
    },
    // {
    //   title: 'Theme',
    //   icon: Icon32Brush,
    //   path: courseSettingsPaths.theme
    // },
    {
      title: 'Completion',
      icon: Icon32CheckMark,
      path: courseSettingsPaths.completion
    },
    // {
    //   title: 'SEO',
    //   icon: Icon32Magnifier,
    //   path: courseSettingsPaths.seo
    // },
    {
      title: 'Custom code',
      icon: Icon32Code,
      path: courseSettingsPaths.customCode
    }
  ];
  const path = useRouteMatch<{ id: string }>([coursePaths.courseLessons(), coursePaths.courseSettings()]);
  return (
    <>
      <h3 className={styles.title}>Settings</h3>
      <ul className={styles.navigation}>
        {navigation.map((nav, index) => (
          <li className={styles.navItem} key={index}>
            <NavLink className={styles.navLink} to={nav.path(path?.params.id)} activeClassName={styles.active}>
              <nav.icon className={styles.icon} />
              {nav.title}
            </NavLink>
          </li>
        ))}
      </ul>
    </>
  );
};
