import { GroupBase } from 'react-select';
import { map } from 'lodash';

import { IFile, IOption } from '../../../../../interfaces';
import { ICourse } from '../../../../lms/courses/store/CourseState';
import { IField } from '../../../field';
import { IProduct, IProductField } from '../../../products/store/ProductsState';
import { IOptionCourse } from '../actions/enroll/ActionEnrollCourse';

const dataForSelect = (item: any, product: IProduct, fields?: IField[]) => {
  let data: IOption & { product: IProduct } = { label: '', value: '', product: product };
  return Object.entries<string>(item).map(([key, val]) => {
    const scope = fields?.find((x) => x.id === key)?.scope;

    if (scope) {
      if (scope === 'title') {
        data = { value: key, label: val, product: product };
      }
      if (scope === 'price' && val) {
        data = { value: key, label: (val as any).amount.toString(), product: product };
      }
      if (scope === 'image' && val) {
        return { value: key, label: (val as any).object_name, product: product };
      }
      if (scope === 'quantity') {
        data = { value: key, label: val, product: product };
      }
    }

    return data;
  });
};

const getAndFlatData = (
  scopes: string[],
  data:
    | (IOption & {
        product: IProduct;
      })[]
    | undefined
) => {
  return data?.filter((y) => scopes.includes(y.value));
};

export const filterProductsOption = (productsFiltered?: IProduct[], fields?: IField[]) => {
  const ids = map(
    fields?.filter((x) => x.scope === 'title'),
    'id'
  );

  const data = productsFiltered?.map((x) =>
    Object.values<Record<string, { amount: number; currency: string } | string | IFile>>(x.fields).map((item) =>
      dataForSelect(item, x, fields)
    )
  );

  const flatData = data?.flat(2);
  return getAndFlatData(ids, flatData);
};

export interface IProductViewItem {
  price: { amount: number; currency: string } | undefined;
  image?: IFile | undefined;
  title: string;
  quantity: number;
}

export const dataViewProductItem = (productFields: IProductField, fields: IField[]) => {
  let data: Record<string, any>;

  if (productFields && !Array.isArray(productFields)) {
    const idProducts = Object.keys(productFields);
    const dataArray = map(idProducts, (idProduct) => {
      const scope = fields.find((x) => x.id === idProduct)?.scope;
      if (scope) {
        return { [scope]: productFields[idProduct] };
      }
    });
    data = Object.assign({}, ...dataArray);
  } else {
    data = Object.assign(
      {},
      ...map(productFields, (productField) => {
        const idProduct = Object.keys(productField)[0];
        const scope = fields.find((x) => x.id === idProduct)?.scope;
        if (scope) {
          return { [scope]: productField[idProduct] };
        }
      })
    );
  }

  return data;
};

export const courseForSelect = (courses: ICourse[]): readonly (IOptionCourse | GroupBase<IOptionCourse>)[] => {
  return map(courses, (x) => {
    return { label: x['title'], value: x['id'], course: x };
  });
};
