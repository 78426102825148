import { AxiosResponse } from 'axios';
import actionCreatorFactory from 'typescript-fsa';

import { ICreateDomain, IDomain } from '../../../../interfaces/domain';
import { ErrorDomainStatus } from '../connect-new-domain/utils/error-domain-handler';

export const actionCreator = actionCreatorFactory();

export const domainActions = {
  getDomains: actionCreator.async<
    void,
    IDomain[],
    AxiosResponse<{ status: ErrorDomainStatus; payload: unknown | null }>
  >('GET_DOMAINS'),
  createStudentDomain: actionCreator.async<
    ICreateDomain,
    IDomain,
    AxiosResponse<{ status: ErrorDomainStatus; payload: unknown | null }>
  >('CREATE_STUDENT_DOMAIN'),
  checkDomainStatus: actionCreator.async<
    string,
    IDomain,
    AxiosResponse<{ status: ErrorDomainStatus; payload: unknown | null }>
  >('CHECK_DOMAIN_STATUS'),
  editStudentDomain: actionCreator.async<
    { domainId: string; subdomain: string },
    IDomain,
    AxiosResponse<{ status: ErrorDomainStatus; payload: unknown | null }>
  >('EDIT_STUDENT_DOMAIN'),
  removeStudentDomain: actionCreator.async<
    string,
    void,
    AxiosResponse<{ status: ErrorDomainStatus; payload: unknown | null }>
  >('REMOVE_STUDENT_DOMAIN')
};
