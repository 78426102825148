import actionCreatorFactory from 'typescript-fsa';

import { ICreateListRequest, IList } from './ListsState';

export const actionCreator = actionCreatorFactory();

export const ListsActions = {
  getLists: actionCreator.async<void, IList[], Error>('GET_LISTS'),
  getListDetail: actionCreator.async<string, IList, Error>('GET_LIST_DETAIL'),
  createList: actionCreator.async<ICreateListRequest, IList, Error>('CREATE_LIST'),
  editList: actionCreator.async<ICreateListRequest, IList, Error>('EDIT_LIST'),
  deleteList: actionCreator.async<{ id: string }, void, Error>('DELETE_LIST')
};
