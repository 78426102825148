import { CustomDispatch } from 'redux';
import axios from 'axios';
import { retrieveNewURL, uploadFile } from 'src/app/API/file-upload-api';
import { IFileUploadResponse } from 'src/app/interfaces/fileUpload';
import { IFile } from 'src/app/interfaces/general';

import { fileUploaderActions } from './FileUploaderActions';

export const fileUploadActionAsync = {
  retrieveUrlAndUploadFile:
    (
      file: File,
      uploadCallback: (result: IFile) => void,
      onUploadProgress?: (event: any) => void,
      bucket_name = 'images'
    ) =>
    async (dispatch: CustomDispatch) => {
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();
      try {
        dispatch(fileUploaderActions.retrieveUrlAndUploadFile.started({ file, cancelSource: source }));
        const fileExtension = file.name.match(/\.([0-9a-z]+)(?:[?#]|$)/i);
        const { presigned_url, object_name }: IFileUploadResponse = await retrieveNewURL(
          fileExtension && fileExtension[1],
          bucket_name
        );
        const response = await uploadFile(file, presigned_url, source, onUploadProgress);
        if (response.status === 200) {
          dispatch(
            fileUploaderActions.retrieveUrlAndUploadFile.done({
              result: response,
              params: { file, cancelSource: source }
            })
          );
          uploadCallback({ object_name, bucket_name });
        }
      } catch (error) {
        dispatch(
          fileUploaderActions.retrieveUrlAndUploadFile.failed({ error, params: { file, cancelSource: source } })
        );
      }
    },
  retrieveUrl:
    (file: File, retrieveCallback: (result: IFile & { presigned_url: string }) => void, bucket_name = 'images') =>
    async (dispatch: CustomDispatch) => {
      try {
        dispatch(fileUploaderActions.retrieveUrl.started({ file, bucket_name }));
        const fileExtension = file.name.match(/\.([0-9a-z]+)(?:[?#]|$)/i);
        const { presigned_url, object_name }: IFileUploadResponse = await retrieveNewURL(
          fileExtension && fileExtension[1],
          bucket_name
        );
        if (presigned_url && object_name && bucket_name) {
          dispatch(fileUploaderActions.retrieveUrl.done({ params: { file, bucket_name }, result: {} }));
          retrieveCallback({ object_name, bucket_name, presigned_url });
        }
      } catch (error) {
        dispatch(fileUploaderActions.retrieveUrl.failed({ error, params: { file, bucket_name } }));
      }
    },
  uploadFile:
    (
      { presigned_url, file }: Required<Pick<IFile, 'file'>> & { presigned_url: string },
      onUploadProgress?: (event: any) => void,
      onUploadCallback?: () => void
    ) =>
    async (dispatch: CustomDispatch) => {
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();
      try {
        dispatch(fileUploaderActions.uploadFile.started({ presigned_url, file, cancelSource: source }));
        const response = await uploadFile(file, presigned_url, source, onUploadProgress);
        if (response.status === 200) {
          dispatch(
            fileUploaderActions.uploadFile.done({
              params: { file, presigned_url, cancelSource: source },
              result: {}
            })
          );
          onUploadCallback?.();
        }
      } catch (error) {
        dispatch(
          fileUploaderActions.uploadFile.failed({ error, params: { file, presigned_url, cancelSource: source } })
        );
      }
    }
};
