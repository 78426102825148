import { IPaginationResponse, IPayment, ISubscription, SubscriptionStatusResponse } from '../../../../interfaces';
import { LoadState } from '../../../../store';

export interface ISubscriptionResponse {
  data: ISubscription;
  status: SubscriptionStatusResponse;
}

export interface IBillingState {
  transactions: IPaginationResponse<IPayment[]>;
  subscription: ISubscription | null;
  error: Error | null;
  loadState: LoadState;
}

export enum StripePlansDev {
  MONTH_FREE = 'price_1Kj1R7AJVXWexDwiR4gf4e59',
  YEAR_MEDIUM = 'price_1MHoadAJVXWexDwiekGbD6wB',
  MONTH_MEDIUM = 'price_1MHoadAJVXWexDwikyBUbshf'
}

export enum StripePlansProd {
  MONTH_FREE = 'everact_month_free',
  YEAR_MEDIUM = 'everact-basic-year',
  MONTH_MEDIUM = 'everact-basic-month'
}

export enum StripeSubscriptionPlans {
  everactFree = 'everact_free',
  everactPlus = 'everact_plus'
  // everactPro = 'everact_pro'
}

export const stripePlansDev = {
  [StripeSubscriptionPlans.everactFree]: {
    id: 'everact_free',
    month: StripePlansDev.MONTH_FREE,
    year: StripePlansDev.MONTH_FREE
  },
  [StripeSubscriptionPlans.everactPlus]: {
    id: 'prod_N1sLu6u6iGfH2H',
    month: StripePlansDev.MONTH_MEDIUM,
    year: StripePlansDev.YEAR_MEDIUM
  }
  // [StripeSubscriptionPlans.everactPro]: {
  //   id: StripeSubscriptionPlans.everactPro,
  //   month: StripePlansDev.MONTH_PREMIUM,
  //   year: StripePlansDev.YEAR_PREMIUM
  // }
};
export const stripePlansProd = {
  [StripeSubscriptionPlans.everactFree]: {
    id: 'prod_M0sy8SwYIk9MrJ',
    month: StripePlansProd.MONTH_FREE,
    year: StripePlansProd.MONTH_FREE
  },
  [StripeSubscriptionPlans.everactPlus]: {
    id: 'prod_N1sCxBUBO8QYGm',
    month: StripePlansProd.MONTH_MEDIUM,
    year: StripePlansProd.YEAR_MEDIUM
  }
  // [StripeSubscriptionPlans.everactPro]: {
  //   id: 'prod_LKYnXt4Q7frCij', //the premium plan id is not correct now, it's blank fot future
  //   month: StripePlansProd.MONTH_PREMIUM,
  //   year: StripePlansProd.YEAR_PREMIUM
  // }
};

export interface IPlanPrice {
  id: string;
  price: string;
}

export interface IPlanData {
  id: `${StripeSubscriptionPlans}`;
  title: string;
  month: IPlanPrice;
  year: IPlanPrice;
  description: string;
  options: IPlanDataOption[];
}

export interface IPlanDataOption {
  list: {
    isSoon: boolean;
    value: string;
  }[];
}

export const BillingInitialState: IBillingState = {
  transactions: {
    data: [],
    meta: {
      page: 1,
      page_size: 12,
      total: 1,
      total_pages: 1
    }
  },
  subscription: null,
  error: null,
  loadState: LoadState.needLoad
};
