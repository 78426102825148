import { LoadState } from '../../../../store';

export interface ITag {
  background_color: string;
  foreground_color: string;
  date_create: string;
  date_update: string;
  id: string;
  organization_id: string;
  title: string;
}

export interface ITagState {
  error: Error | null;
  loadState: LoadState;
  tags: ITag[];
}

export const TagInitialState: ITagState = {
  error: null,
  loadState: LoadState.firstLoad,
  tags: []
};
