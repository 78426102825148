import { Controller, useFormContext } from 'react-hook-form';

import styles from './amount-canvas.module.scss';

import { useNodePayload } from '../../../../../hooks/use-node-payload';
import { PaletteAutomationElementTypes } from '../../../../../interfaces';

interface IProps {
  id: string;
}

export const AmountNodeItem = ({ id }: IProps) => {
  const { control, watch } = useFormContext();
  const payload = useNodePayload<PaletteAutomationElementTypes.PROCESSOR_RANGE>(id);
  const end = watch(`${id}.end`);

  return (
    <>
      <Controller
        render={() => {
          return (
            <div className={styles.wrapper}>
              <div className={styles.container}>
                <div className={styles.first}>Reach only {end || payload?.payload?.range?.end || 1} contact</div>
                <div className={styles.endpointCSS}>
                  <div className={styles.elipse1}></div>
                </div>
              </div>
              <div className={styles.container}>
                <div className={styles.text}>Above the limit</div>
                <div className={styles.endpointCSS}>
                  <div className={styles.elipse1}></div>
                </div>
              </div>
            </div>
          );
        }}
        defaultValue={payload?.payload?.range?.end || 1}
        name={`${id}.end`}
        control={control}
      />
    </>
  );
};
