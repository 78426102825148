import { CustomDispatch } from 'redux';

import { EmailAction } from '../../../../API/action-email-api';
import {
  actionEmail,
  createStudent,
  deleteStudent,
  editStudent,
  enrollResendStudent,
  enrolStudent,
  exportStudents,
  exportStudentsFromList,
  getEnrollmentList,
  getStudent,
  getStudentAnalytics,
  getStudentsConfig,
  getStudentsList,
  getStudentsLists,
  getStudentsOfList,
  unEnrolStudent
} from '../../../../API/student-api';
import { IPaginationParams } from '../../../../interfaces';
import { IStudentEnrolParams } from '../../../../interfaces/student';
import { IStudent, IStudentAnalytics, IStudentField } from './StudentListState';
import { StudentsActions } from './StudentsActions';

export const StudentsActionAsync = {
  getStudentsConfig: (id?: string) => async (dispatch: CustomDispatch) => {
    try {
      dispatch(StudentsActions.getStudentsConfig.started());
      let response;
      if (id) {
        response = await getEnrollmentList(id);
      } else {
        response = await getStudentsConfig();
      }
      if (response.data) {
        dispatch(StudentsActions.getStudentsConfig.done({ result: response.data }));
      }
    } catch (error) {
      dispatch(StudentsActions.getStudentsConfig.failed({ error }));
    }
  },
  getStudent: (id: string, callback?: (student: IStudent) => void) => async (dispatch: CustomDispatch) => {
    try {
      dispatch(StudentsActions.getStudent.started(id));
      const response = await getStudent(id);
      if (response.data) {
        dispatch(StudentsActions.getStudent.done({ result: response.data, params: id }));
        callback?.(response.data);
      }
    } catch (error) {
      dispatch(StudentsActions.getStudent.failed({ error, params: id }));
    }
  },
  getStudentDetail: (id: string, callback: (student: IStudent) => void) => async (dispatch: CustomDispatch) => {
    try {
      dispatch(StudentsActions.getStudentDetail.started(id));
      const response = await getStudent(id);
      if (response.data) {
        dispatch(StudentsActions.getStudentDetail.done({ result: response.data, params: id }));
        callback(response.data);
      }
    } catch (error) {
      dispatch(StudentsActions.getStudentDetail.failed({ error, params: id }));
    }
  },
  getStudentsList:
    (pagination?: IPaginationParams, query?: string, callback?: () => void) => async (dispatch: CustomDispatch) => {
      try {
        dispatch(StudentsActions.getStudentsList.started());
        const response = await getStudentsList(pagination, query);
        if (response && response.data && response.meta) {
          dispatch(
            StudentsActions.getStudentsList.done({
              result: { data: response.data, meta: response.meta }
            })
          );
          callback?.();
        }
      } catch (error) {
        dispatch(StudentsActions.getStudentsList.failed({ error }));
      }
    },
  createStudent:
    (fields: IStudentField, tags?: string[], callback?: (student: IStudent) => void) =>
    async (dispatch: CustomDispatch) => {
      try {
        dispatch(StudentsActions.createStudent.started({ fields, tags }));
        const response = await createStudent(fields, tags);
        if (response.data) {
          dispatch(StudentsActions.createStudent.done({ result: response.data, params: { fields, tags } }));
          if (typeof callback === 'function') {
            callback(response.data);
          }
        }
      } catch (error) {
        dispatch(StudentsActions.createStudent.failed({ error, params: { fields, tags } }));
      }
    },
  editStudent:
    (id: string, fields: IStudentField, tags?: string[], callback?: () => void) => async (dispatch: CustomDispatch) => {
      try {
        dispatch(StudentsActions.editStudent.started({ id, fields, tags }));
        const response = await editStudent(id, fields, tags);
        if (response.data) {
          dispatch(StudentsActions.editStudent.done({ result: response.data, params: { id, fields, tags } }));
          if (callback) {
            callback();
          }
        }
      } catch (error) {
        dispatch(StudentsActions.editStudent.failed({ error, params: { id, fields, tags } }));
      }
    },
  deleteStudent: (id: string, deleteContact?: boolean) => async (dispatch: CustomDispatch) => {
    try {
      dispatch(StudentsActions.deleteStudent.started({ id }));
      await deleteStudent(id, deleteContact);
      dispatch(StudentsActions.deleteStudent.done({ params: { id } }));
    } catch (error) {
      dispatch(StudentsActions.deleteStudent.failed({ error, params: { id } }));
    }
  },
  getStudentsLists: () => async (dispatch: CustomDispatch) => {
    try {
      dispatch(StudentsActions.getStudentsLists.started());
      const response = await getStudentsLists();
      if (response && response.data) {
        dispatch(
          StudentsActions.getStudentsLists.done({
            result: response.data
          })
        );
      }
    } catch (error) {
      dispatch(StudentsActions.getStudentsLists.failed({ error }));
    }
  },
  getStudentsOfList:
    (id: string, pagination?: IPaginationParams, query?: string, callback?: () => void) =>
    async (dispatch: CustomDispatch) => {
      try {
        dispatch(StudentsActions.getStudentsOfList.started(id));
        const response = await getStudentsOfList(id, pagination, query);
        if (response && response.data && response.meta) {
          dispatch(
            StudentsActions.getStudentsOfList.done({
              result: { data: response.data, meta: response.meta },
              params: id
            })
          );
          callback?.();
        }
      } catch (error) {
        dispatch(StudentsActions.getStudentsOfList.failed({ error, params: id }));
      }
    },
  enrolStudent:
    (id: string, params: IStudentEnrolParams, callback?: () => void, onError?: () => void) =>
    async (dispatch: CustomDispatch) => {
      try {
        dispatch(StudentsActions.enrolStudent.started({ id, params }));
        const response = await enrolStudent(id, params);
        if (response) {
          dispatch(
            StudentsActions.enrolStudent.done({
              result: null,
              params: { id, params }
            })
          );
          if (callback) {
            callback();
          }
        }
      } catch (error) {
        dispatch(StudentsActions.enrolStudent.failed({ error, params: { id, params } }));
        if (onError) {
          onError();
        }
      }
    },
  enrollResendStudent:
    (id: string, params: IStudentEnrolParams, callback?: () => void, onError?: () => void) =>
    async (dispatch: CustomDispatch) => {
      try {
        dispatch(StudentsActions.enrolStudent.started({ id, params }));
        const response = await enrollResendStudent(id, params);
        if (response) {
          dispatch(
            StudentsActions.enrolStudent.done({
              result: null,
              params: { id, params }
            })
          );
          if (callback) {
            callback();
          }
        }
      } catch (error) {
        dispatch(StudentsActions.enrolStudent.failed({ error, params: { id, params } }));
        if (onError) {
          onError();
        }
      }
    },
  unEnrolStudent:
    (id: string, courseId: string, sendNotificationEmail: boolean, callback?: () => void, callbackError?: () => void) =>
    async (dispatch: CustomDispatch) => {
      try {
        dispatch(StudentsActions.unEnrolStudent.started({ id, courseId, sendNotificationEmail }));
        const response = await unEnrolStudent(id, courseId, sendNotificationEmail);
        if (response.data) {
          dispatch(
            StudentsActions.unEnrolStudent.done({
              result: response.data,
              params: { id, courseId, sendNotificationEmail }
            })
        );
          callback?.();
        }
      } catch (error) {
        callbackError?.();
        dispatch(StudentsActions.unEnrolStudent.failed({ error, params: { id, courseId, sendNotificationEmail } }));
      }
    },
  enrollmentEmail:
    (actionHash: string, courseId: string, returnData: (data: EmailAction) => void) =>
    async (dispatch: CustomDispatch) => {
      try {
        dispatch(StudentsActions.enrollmentEmail.started({ actionHash }));
        const response = await actionEmail(actionHash, courseId);
        if (response.data) {
          dispatch(StudentsActions.enrollmentEmail.done({ params: { actionHash } }));
          returnData(response.data);
        }
      } catch (error) {
        returnData(error.data);
        dispatch(StudentsActions.enrollmentEmail.failed({ error, params: { actionHash } }));
      }
    },
  getStudentAnalytics:
    (id: string, courseId: string, callback?: (analytics: IStudentAnalytics) => void) =>
    async (dispatch: CustomDispatch) => {
      try {
        dispatch(StudentsActions.getStudentAnalytics.started({ id, courseId }));
        const response = await getStudentAnalytics(id, courseId);
        if (response.data) {
          dispatch(StudentsActions.getStudentAnalytics.done({ result: response.data, params: { id, courseId } }));
          callback?.(response.data);
        }
      } catch (error) {
        dispatch(StudentsActions.getStudentAnalytics.failed({ error, params: { id, courseId } }));
      }
    },
  exportStudents: (callback?: (data: string) => void) => async (dispatch: CustomDispatch) => {
    try {
      dispatch(StudentsActions.exportStudents.started());
      const response = await exportStudents();
      if (response.data) {
        dispatch(StudentsActions.exportStudents.done({ result: response.data }));
        callback?.(response.data);
      }
    } catch (error) {
      dispatch(StudentsActions.exportStudents.failed({ error }));
    }
  },
  exportStudentsFromList: (id: string, callback?: (data: string) => void) => async (dispatch: CustomDispatch) => {
    try {
      dispatch(StudentsActions.exportStudentsFromList.started({ list_key: id }));
      const response = await exportStudentsFromList(id);
      if (response.data) {
        dispatch(StudentsActions.exportStudentsFromList.done({ result: response.data, params: { list_key: id } }));
        callback?.(response.data);
      }
    } catch (error) {
      dispatch(StudentsActions.exportStudentsFromList.failed({ error, params: { list_key: id } }));
    }
  }
};
