import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import update from 'immutability-helper';

import classNames from 'classnames';
import styles from './drag-section.module.scss';
import { Icon16ChevronRight, Icon16Plus } from '../../../../../../components/UI/icons';

import { ArrowScroll } from '../../../../../../components/UI/arrowScroll/ArrowScroll';
import { StudentCabinetPaths } from '../../../../../student-cabinet/routes/StudentCabinetRoutes';
import { useCourseContext } from '../../../common/sidebar/CourseContext';
import { coursePaths } from '../../../routes/CourseRoutes';
import { courseActionAsync } from '../../../store/CourseActionAsync';
import { ICourseNode } from '../../../store/CourseState';
import { LessonSelector } from '../../../store/LessonSelector';
import { Lecture } from '../../lecture/Lecture';
import { useLessonContext } from '../../lesson/LessonContext';

interface IProps {
  className?: string;
}

export const DragSection = memo(({ className }: IProps) => {
  const { onDelete } = useLessonContext();
  const { courseId, nodeId } = useCourseContext();
  const path = useRouteMatch<{ id: string; nodeId: string }>([
    coursePaths.courseLessons(':id', ':nodeId'),
    StudentCabinetPaths.courseStudentLessons(':id', ':nodeId')
  ]);
  const dispatch = useDispatch();
  const history = useHistory();
  const stepsMemo = useMemo(() => LessonSelector(courseId, nodeId), [courseId, nodeId]);
  const step = useSelector((state) => stepsMemo(state));
  const lessonsMemo = useMemo(
    () => LessonSelector(courseId, step?.lesson?.parent_id),
    [courseId, step?.lesson?.parent_id]
  );
  const lesson = useSelector((state) => lessonsMemo(state));

  const [sectionState, setSectionState] = useState<ICourseNode[]>([]);

  useEffect(() => {
    const orderedLessons = lesson?.lesson?.children?.sort((a, b) => a.order - b.order);
    if (orderedLessons && orderedLessons.length > 0) {
      setSectionState(orderedLessons || []);
    }
  }, [lesson?.lesson?.children]);

  const onCallback = useCallback(() => {
    if (courseId) {
      dispatch(courseActionAsync.getCourseHierarchy(courseId));
    }
  }, [dispatch, courseId]);

  const addSectionCallback = useCallback(
    (courseNode: ICourseNode) => {
      if (courseId) {
        setSectionState((prevState) => [...prevState, courseNode]);
        history.push(coursePaths.courseLessons(courseId, courseNode.id));
      }
    },
    [courseId, history]
  );

  const onAddSection = useCallback(() => {
    if (courseId && lesson?.lesson?.id) {
      dispatch(
        courseActionAsync.createNode(courseId, 'step', lesson?.lesson?.id, undefined, undefined, addSectionCallback)
      );
    }
  }, [courseId, lesson?.lesson?.id, dispatch, addSectionCallback]);

  const onActiveHandler = useCallback(
    (event: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
      const id = event.currentTarget.dataset['id'];
      const clickedNode = sectionState.find((x) => x.id === id);
      if (clickedNode && courseId) {
        history.push(coursePaths.courseLessons(courseId, clickedNode.id));
      }
    },
    [sectionState, courseId, history]
  );

  const removeElement = useCallback(
    (id: string) => {
      if (id) {
        const lectures = sectionState.filter((x) => x.id !== id);
        if (id === nodeId) {
          history.push(coursePaths.courseLessons(courseId, lesson?.lesson?.children.filter((x) => x.id !== id)[0].id));
          onDelete(false);
        }
        if (courseId && lectures.length > 0) {
          dispatch(courseActionAsync.deleteNode(courseId, id, onCallback));
          setSectionState(lectures);
        }
      }
    },
    [courseId, dispatch, history, lesson?.lesson?.children, nodeId, onCallback, onDelete, sectionState]
  );

  const handleDrag = (result: DropResult) => {
    const dragNode = sectionState[result.source.index];
    if (result.destination && courseId) {
      setSectionState(
        update(sectionState, {
          $splice: [
            [result.source.index, 1],
            [result.destination?.index, 0, dragNode]
          ]
        })
      );
      const newOrder = sectionState[result.destination.index].order;
      dispatch(courseActionAsync.editNode(courseId, dragNode.id, { order: newOrder }, onCallback));
    }
  };

  return (
    <div className={classNames(styles.container, className)}>
      <div className={styles.addContainer} onClick={onAddSection}>
        <Icon16Plus className={styles.icon} />
      </div>
      <div className={styles.arrow}>
        <Icon16ChevronRight />
      </div>
      {nodeId && (
        <ArrowScroll sectionsLength={sectionState.length} nodeId={nodeId}>
          <DragDropContext onDragEnd={handleDrag}>
            <Droppable droppableId={nodeId} direction={'horizontal'}>
              {(provided) => (
                <div {...provided.droppableProps} ref={provided.innerRef} className={'d-flex align-items-center'}>
                  {sectionState.map((element, elementIndex) => (
                    <Draggable key={element.id} draggableId={element.id} index={elementIndex}>
                      {(provided) => (
                        <div
                          id={element.id}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          className={'d-flex align-items-center'}
                        >
                          <Lecture
                            onActive={onActiveHandler}
                            item={element}
                            isActive={element.id === nodeId}
                            onDeleteItem={removeElement}
                            provided={provided}
                          />
                          {sectionState.length - 1 !== elementIndex && <Icon16ChevronRight />}
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </ArrowScroll>
      )}
    </div>
  );
});
