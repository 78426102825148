import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { ICourseNode } from '../lms/courses/store/CourseState';
import { ViewCourse } from '../lms/students/student-course/ViewCourse';
import { AccountSelector } from '../settings/account/store/AccountSelector';
import { dashboardPaths } from './routes/DashboardRoutes';
import { DashboardActionAsync } from './store/DashboardActionAsync';
import { DashboardCourseSelector, DashboardLectureNavigation } from './store/DashboardSelector';

export const DashboardCourse = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const path = useRouteMatch<{ id: string; nodeId?: string }>([
    dashboardPaths.dashboardLessons(':id', ':nodeId'),
    dashboardPaths.dashboardLessons()
  ]);
  const courseId = path?.params.id;
  const nodeId = path?.params.nodeId;
  const flatLessons = useSelector((state) => DashboardLectureNavigation(state, { courseId: courseId, nodeId }));
  const courseMemo = useMemo(DashboardCourseSelector, []);
  const { course } = useSelector((state) => courseMemo(state, { courseId }));
  const [currentHierarchy, setCurrentHierarchy] = useState<ICourseNode[]>([]);
  const accountMemo = useMemo(AccountSelector, []);
  const { organizationId } = useSelector(accountMemo);

  const getHierarchy = useCallback(() => {
    if (course) {
      dispatch(DashboardActionAsync.getDashboardCourseHierarchy(course.id, setCurrentHierarchy));
    }
  }, [course, dispatch]);

  const onRedirectLessonHandler = useCallback(
    (nodeId?: string, courseId: string | undefined = course?.id) => {
      if (courseId) {
        history.push(dashboardPaths.dashboardLessons(courseId, nodeId));
      }
    },
    [course?.id, history]
  );

  useEffect(() => {
    if (!course) {
      dispatch(DashboardActionAsync.getDashboard());
    }
  }, [course, dispatch, organizationId]);

  useEffect(() => {
    getHierarchy();
  }, [getHierarchy]);

  return (
    <>
      {course && (
        <ViewCourse
          course={course}
          flatLessons={flatLessons}
          nodeId={nodeId}
          onRedirectLessonHandler={onRedirectLessonHandler}
          setCurrentHierarchy={setCurrentHierarchy}
          currentHierarchy={currentHierarchy}
          getHierarchy={getHierarchy}
          general_image={null}
          general_title={'Everact University'}
          theme_logo={null}
        />
      )}
    </>
  );
};
