import { reducerWithInitialState } from 'typescript-fsa-reducers';

import { addDataToTable, removeDataFromTable } from '../../../../helpers';
import { commonErrorHandler, commonFirstLoadHandler } from '../../../../helpers/common-reducer-handlers';
import { LoadState, newState } from '../../../../store';
import { ProductsActions } from './ProductsActions';
import { IProductsState, ProductsInitialState } from './ProductsState';

export const productsReducer = reducerWithInitialState<IProductsState>(ProductsInitialState)
  .case(ProductsActions.getProducts.started, commonFirstLoadHandler)
  .case(ProductsActions.getProducts.done, (state, { result }) => {
    return newState(state, {
      ...result.meta,
      products: result.data,
      loadState: result.data.length > 0 ? LoadState.allIsLoaded : LoadState.idle,
      error: null
    });
  })
  .case(ProductsActions.getProducts.failed, commonErrorHandler)

  .case(ProductsActions.createProduct.started, commonFirstLoadHandler)
  .case(ProductsActions.createProduct.done, (state, { result }) => {
    const { page, total } = addDataToTable(state.page_size, state.total);
    return newState(state, {
      ...state,
      total: total,
      page: page,
      products: [...state.products, result],
      loadState: LoadState.allIsLoaded,
      error: null
    });
  })
  .case(ProductsActions.createProduct.failed, commonErrorHandler)

  // TODO: check response
  // .case(ProductsActions.editProduct.started, commonFirstLoadHandler)
  // .case(ProductsActions.editProduct.done, (state, { result }) => {
  //   const updatedProducts = state.products.map((product) => (product.id === result.id ? result : product));
  //   return newState(state, {
  //     ...state,
  //     products: updatedProducts,
  //     loadState: LoadState.allIsLoaded,
  //     error: null
  //   });
  // })
  // .case(ProductsActions.editProduct.failed, commonErrorHandler)

  .case(ProductsActions.getProduct.started, commonFirstLoadHandler)
  .case(ProductsActions.getProduct.done, (state, { result }) => {
    const updatedProducts = state.products.map((product) => (product.id === result.id ? result : product));
    return newState(state, {
      ...state,
      products: updatedProducts,
      loadState: LoadState.allIsLoaded,
      error: null
    });
  })
  .case(ProductsActions.getProduct.failed, commonErrorHandler)

  .case(ProductsActions.deleteProduct.started, commonFirstLoadHandler)
  .case(ProductsActions.deleteProduct.done, (state, { params }) => {
    const removingIndexElement = state.products.filter((product) => product.id !== params.id);
    const { page, total } = removeDataFromTable(state.page_size, state.total);
    return newState(state, {
      ...state,
      total: total,
      page: page,
      products: removingIndexElement,
      loadState: LoadState.allIsLoaded,
      error: null
    });
  })
  .case(ProductsActions.deleteProduct.failed, commonErrorHandler);
