import { assignWith, isEqual } from 'lodash';

const replaceEmptyStringToNull = <T>(data: T) => assignWith({}, data, (_, value) => (value === '' ? null : value));

export const ValidationData = <T>(data: T, dataValidate: T) => {
  const prepareData = replaceEmptyStringToNull(data);
  const prepareDataValidate = replaceEmptyStringToNull(dataValidate);

  return isEqual(prepareData, prepareDataValidate);
};
