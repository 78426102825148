import classNames from 'classnames';
import styles from './item-status.module.scss';

interface IProps {
  label: string;
  className?: string;
}
export const ItemStatus = ({ label, className }: IProps) => {
  return <div className={classNames(styles.label, 'state', label?.toLowerCase(), className)}>{label}</div>;
};
