import React, { memo } from 'react';
import AutosizeInput from 'react-input-autosize';

import classnames from 'classnames';
import styles from './lecture.module.scss';

import { CustomTooltip } from '../../../../../components/UI';
import { ICourseNode } from '../../store/CourseState';

interface IProps {
  isActive: boolean;
  item: ICourseNode;
  onActive: (e: React.MouseEvent<HTMLInputElement, MouseEvent>) => void;
}

export const LectureStudent = memo(({ item, isActive, onActive }: IProps) => (
  <div className={classnames(styles.container, isActive && styles.active)}>
    <CustomTooltip customText={item.title} direction={'top'} delay={{ show: 300, hide: 0 }}>
      <AutosizeInput
        value={item.title || ''}
        inputClassName={classnames(styles.input, isActive && styles.active)}
        className={styles.input}
        maxLength={256}
        readOnly={true}
        data-id={item.id}
        onClick={onActive}
      />
    </CustomTooltip>
  </div>
));
