import { createSelector } from 'reselect';

import { IAppState } from '../../../../store';

export const MembersSelector = () =>
  createSelector(
    (state: IAppState) => state.organization.members,
    (state: IAppState) => state.organization.loadState,
    (state: IAppState) => state.organization.error,
    (members, loadState, error) => {
      const owner = members.filter((x) => x.role === 'owner');
      const invitation = members.filter((x) => x.type === 'invitation');
      const other = members.filter((x) => x.type !== 'invitation' && x.role !== 'owner');

      return {
        owner,
        invitation,
        other,
        loadState,
        error
      };
    }
  );
