import styles from './domain-instruction.module.scss';

import Instruction from '../../../../../../../documentation/domain.json';
import { CloseButton } from '../../../../../../components/UI';

interface IProps {
  onHideInstruction: () => void;
}
export const DomainInstruction = ({ onHideInstruction }: IProps) => {
  return (
    <div className={styles.container}>
      <div className={styles.title}>{Instruction.title}</div>
      <div className={styles.description}>{Instruction.description}</div>
      <CloseButton onClose={onHideInstruction} />
    </div>
  );
};
