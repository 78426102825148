import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import axios from 'axios';
import querystring from 'query-string';

import { SessionExpired } from '../components/session-expired/SessionExpired';
import { IMeta } from '../interfaces';
import { errorLocalization, ServerError, store } from '../store';

export type IHttpMethods = 'GET' | 'POST' | 'PUT' | 'DELETE';

export const baseFetch = async <P, R, S = void | number>(
  url: string,
  params: P,
  method: IHttpMethods = 'GET',
  organization: boolean = false,
  headers: Record<string, string> = {},
  api: string | null = '1.0'
): Promise<{ data: R | null; status?: S; meta?: IMeta }> => {
  const state = store.getState();
  try {
    const organizationID = state.auth.currentOrganization?.organization_id;
    const hasParams = method === 'GET' && Object.keys(params).length > 0;
    const queryParams = `${hasParams ? '?' : ''}${hasParams ? querystring.stringify(params as any) : ''}`;
    const organizationUrl = organization && organizationID ? `/organizations/${organizationID}` : '';
    const apiVersion = api === null ? '' : `/api/${api}`;
    //check if exists token, if token is empty - not set Authorization
    const modifyHeader = state.auth.authToken
      ? { ...headers, Authorization: `Bearer ${state.auth.authToken}` }
      : headers;
    const client = axios.create({
      method,
      baseURL: process.env.REACT_APP_API_BASE_URL + apiVersion + organizationUrl + url + queryParams,
      headers: {
        // Authorization: `Bearer ${state.auth.authToken}`,
        'Cache-Control': 'no-cache',
        ...modifyHeader
      }
    });
    const response = await client.request<R, any>({ headers, data: params }); //.then((res) => res.data?.data ? { ...res, ...res.data } : res);
    return response?.data?.data ? { ...response, ...response.data } : response;
  } catch (error: any) {
    const status = error.response?.status;
    if (status === 401) {
      // throw new NoAuthError();
      const errorPortal = document.getElementById('error-root');
      if (errorPortal) {
        ReactDOM.render(<SessionExpired showModal={true} />, errorPortal);
      }
    } else if (status === 404) {
      throw new ServerError({ message: errorLocalization.notFound, data: await error.response.data });
    } else if (!status || status < 200 || status >= 300) {
      throw error.response;
    }

    process.env.NODE_ENV === 'development' && console.log(`%c⇒ ${error}`, 'color: #FF5370');
    // if (error instanceof NoAuthError) {
    //   error.message = errorLocalization.sessionExpired;
    //   const errorPortal = document.getElementById('error-root');
    //   if (errorPortal) {
    //     ReactDOM.render(<SessionExpired showModal={true} />, errorPortal);
    //   }
    // }
    Sentry.captureEvent({ message: error.name }, error);
    Sentry.setTag('userId', state.account.account.id);
    Sentry.setTag('organizationId', state.auth.currentOrganization?.organization_id);
    throw error;
  }
};
