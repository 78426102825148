import moment from 'moment';

import styles from './quiz-detail-header.module.scss';
import { Icon32User } from '../../../../../../../../components/UI/icons';

import { IStudentQuiz } from '../../../../../../../../interfaces/quiz';

interface IProps extends Omit<IStudentQuiz, 'contact_relation' | 'phone'> {
  date_create?: string;
  courseTitle?: string;
}
export const QuizDetailHeader = ({ first_name, last_name, email, date_create, courseTitle }: IProps) => {
  return (
    <div className={styles.student}>
      <div className={styles.studentIcon}>
        <Icon32User width={32} height={32} />
      </div>
      <div className={styles.studentContent}>
        <div className={styles.studentHeader}>
          <h6 className={styles.studentName}>{first_name && last_name ? `${first_name} ${last_name}` : email}</h6>
          <div className={styles.studentDot}></div>
          <span className={styles.studentDate}>{moment(date_create).format('DD MMM yyyy, hh:mm A')}</span>
        </div>
        <p className={styles.studentTitle}>{courseTitle}</p>
      </div>
    </div>
  );
};
