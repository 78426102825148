import React, { memo, ReactNode } from 'react';
import { ModalProps } from 'react-bootstrap';

import styles from './modal.module.scss';

import { Button } from '../button/Button';
import { ModalActionComponent } from './ModalActionComponent';

interface IProps {
  isShowed: boolean;
  title: ReactNode;
  description: string | ReactNode;
  modalParams?: ModalProps;
  textDelete?: string;
  iconStyles?: string;
  iconType?: 'edit' | 'delete' | 'create' | 'warning' | 'custom';
  icon?: JSX.Element;
  onClose(): void;

  onDelete(e: React.SyntheticEvent<HTMLButtonElement>): void;
}

export const ModalDelete = memo(
  ({
    onClose,
    isShowed,
    onDelete,
    description,
    title,
    modalParams,
    icon,
    textDelete = 'Delete',
    iconType = 'delete',
    iconStyles
  }: IProps) => {
    return (
      <ModalActionComponent
        iconType={iconType}
        show={isShowed}
        title={title}
        onCloseModal={onClose}
        modalParams={modalParams}
        iconStyles={iconStyles}
        icon={icon}
      >
        <div className={styles.description}>{description}</div>
        <div className={styles.buttons}>
          <Button onClick={onClose} btnStyle={'transparent'} withIcon={false} className={styles.cancel}>Cancel</Button>
          <Button onClick={onDelete} type={'delete'} btnStyle={'warning'}>
            {textDelete}
          </Button>
        </div>
      </ModalActionComponent>
    );
  }
);
