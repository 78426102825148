import { useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useEffectOnce } from 'usehooks-ts';

import styles from './answers.module.scss';
import EmptyAnswers from '../../../../../assets/svg/empty-answers.svg';

import { DropdownToggle, Loader } from '../../../../components/UI';
import { useLoading } from '../../../../hooks/use-loading';
import { IQuizSession } from '../../../../interfaces/quiz';
import { QuizDetail } from '../../students/common/analytics/quiz/quiz-detail/QuizDetail';
import { QuizSessionsActionAsync } from '../../students/common/analytics/store/QuizSessionsActionAsync';
import { AnswersSidebar, IParamSidebar } from '../common/answers-sidebar/AnswersSidebar';

export const Answers = () => {
  const dispatch = useDispatch();
  const { loading, startLoading, stopLoading } = useLoading();
  const [quizSessions, setQuizSessions] = useState<IQuizSession[]>([]);
  const [sortDesc, setDescSort] = useState<boolean>(false);

  const getQuizSessionsCallback = (data: IQuizSession[]) => {
    setQuizSessions(data);
    stopLoading();
  };

  const onSelectParam = (params?: IParamSidebar) => {
    getQuizSessions(params);
  };

  const onChangeSort = (eventKey: string | null) => {
    setDescSort(eventKey !== 'false');
  };

  const getQuizSessions = (params?: IParamSidebar) => {
    startLoading();

    dispatch(
      QuizSessionsActionAsync.getQuizSessions(
        {
          include_answers: true,
          include_student: true,
          include_course: true,
          sort: `${sortDesc ? '' : '-'}date_create`,
          course_ids: params?.coursesId?.length ? params.coursesId : undefined,
          states: params?.statesId?.length ? params.statesId : undefined,
          moderate_answers: !!params?.moderateAnswers
        },
        getQuizSessionsCallback
      )
    );
  };

  useEffectOnce(getQuizSessions);

  return (
    <>
      <div className={styles.container}>
        <AnswersSidebar onSelectParam={onSelectParam} />
        <div className={styles.content}>
          <>
            {loading ? (
              <Loader />
            ) : quizSessions.length > 0 ? (
              <div className={styles.quizSessions}>
                <div className={styles.quizSessionsHeader}>
                  <div className={styles.quizSessionsHeaderLeft}>
                    <h2 className={styles.quizSessionsTitle}>Submitted</h2>
                    <div className={styles.quizSessionsCount}>{quizSessions.length}</div>
                  </div>
                  <div className={styles.quizSessionsHeaderRight}>
                    Show first:
                    <Dropdown onSelect={onChangeSort}>
                      <Dropdown.Toggle as={DropdownToggle} className={styles.quizSessionsSortDropDown}>
                        <span className={styles.quizSessionsSort}>{sortDesc ? 'old' : 'new'}</span>
                      </Dropdown.Toggle>
                      <Dropdown.Menu align="right" className={'sm'}>
                        <Dropdown.Item eventKey="false">
                          <div className={styles.quizSessionsSortItem}>New</div>
                        </Dropdown.Item>
                        <Dropdown.Item eventKey="true">
                          <div className={styles.quizSessionsSortItem}>Old</div>
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
                <div className={styles.quizSessionsList}>
                  {quizSessions.map((session) => (
                    <div key={session.id} className={styles.quizSessionsItem}>
                      <QuizDetail
                        id={session.id}
                        courseId={session.course?.id}
                        sessionDetail={session}
                        showStudentDetail={true}
                      />
                    </div>
                  ))}
                </div>
              </div>
            ) : (
              <>
                <img height={468} width={512} src={EmptyAnswers} alt={'Empty Answers'} className={styles.imageEmpty} />
              </>
            )}
          </>
        </div>
      </div>
    </>
  );
};
