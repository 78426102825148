import { useDispatch, useSelector } from 'react-redux';

import styles from './course-modals.module.scss';
import { Icon32Warning, IconMegaphoneOff } from '../../../../../components/UI/icons';

import { Button, ModalActionComponent } from '../../../../../components/UI';
import { fileUploaderActions } from '../../../../../layout/shared-components/upload-worker/store/FileUploaderActions';
import { courseActionAsync } from '../../store/CourseActionAsync';
import { ICourse } from '../../store/CourseState';
import { CourseShareableLink } from '../course-shareable-link/CourseShareableLink';

interface IProps {
  course: ICourse;
  onCloseUnpublish: () => void;
  onCloseDelete: () => void;
  onCloseShareable: () => void;
  showDelete: boolean;
  showUnpublish: boolean;
  showShareable: boolean;
}

export const CourseModals = ({
  course,
  onCloseDelete,
  onCloseUnpublish,
  onCloseShareable,
  showDelete,
  showUnpublish,
  showShareable
}: IProps) => {
  const dispatch = useDispatch();
  const { files } = useSelector((state) => state.fileUpload);
  const onDelete = () => {
    files.forEach((file) => {
      if (file.courseId === course.id) {
        dispatch(
          fileUploaderActions.updateUploadFile({
            ...file,
            uploaderData: {
              ...file.uploaderData,
              status: 'canceled'
            }
          })
        );
      }
    });
    dispatch(courseActionAsync.updateCourseState(course.id, 'deleted'));
  };

  const onConfirmUnpublish = () => {
    dispatch(courseActionAsync.updateCourseState(course.id, 'draft'));
  };

  return (
    <>
      <ModalActionComponent
        show={showDelete}
        iconType={'delete'}
        title={
          <span className={styles.title}>
            Course {<span className={styles.main}>“{course.title}”</span>} will be deleted
          </span>
        }
        iconStyles={styles.iconDelete}
        onCloseModal={onCloseDelete}
        titleClassName={styles.customTitle}
      >
        <span className={styles.description}>
          All course data you want to delete cannot be restored after confirming the deletion.
        </span>
        <div className={styles.actions}>
          <Button onClick={onCloseDelete} withIcon={false} btnStyle={'transparent'}>
            Cancel
          </Button>
          <Button onClick={onDelete} type={'delete'}>
            Delete
          </Button>
        </div>
      </ModalActionComponent>
      <ModalActionComponent
        show={showUnpublish}
        iconType={'custom'}
        title={
          <span className={styles.title}>
            Course {<span className={styles.main}>“{course.title}”</span>} will be unpublished
          </span>
        }
        iconStyles={styles.iconUnpublish}
        icon={<Icon32Warning />}
        onCloseModal={onCloseUnpublish}
        titleClassName={styles.customTitle}
      >
        <span className={styles.description}>
          Once a course is unpublished and moved to draft, students will no longer have access to it.
        </span>
        <div className={styles.actions}>
          <Button onClick={onCloseUnpublish} withIcon={false} btnStyle={'transparent'}>
            Cancel
          </Button>
          <Button onClick={onConfirmUnpublish} customIcon={<IconMegaphoneOff />} btnStyle={'warning'}>
            Confirm and unpublish
          </Button>
        </div>
      </ModalActionComponent>
      <CourseShareableLink course={course} show={showShareable} onClose={onCloseShareable} />
    </>
  );
};
