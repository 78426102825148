import styles from '../status-modal.module.scss';
import { Icon32ClearFormat } from '../../../../../UI/icons';

import { ICourseNode } from '../../../../../../modules/lms/courses/store/CourseState';
import { CommonModal } from '../../../../../UI';
import { LessonContentModal } from '../lesson-state/LessonContentModal';
import { NodeItemCourse } from '../node-item-course/NodeItemCourse';

interface IProps {
  showModal: boolean;
  onCloseModal: () => void;
  onStateConfirm: (e: React.SyntheticEvent<HTMLButtonElement>) => void;
  hierarchyItem: ICourseNode;
}

export const ConfirmLessonDraftModal = ({ hierarchyItem, showModal, onCloseModal, onStateConfirm }: IProps) => {
  return (
    <CommonModal
      onClose={onCloseModal}
      show={showModal}
      showClose={true}
      params={{ contentClassName: styles.contentContainer }}
      closeButtonClassName={styles.closeContainer}
    >
      <div onClick={(e) => e.stopPropagation()}>
        <div className={'d-flex align-items-center'}>
          <Icon32ClearFormat className={styles.icon} />
          <div className={styles.title}>{hierarchyItem.state === 'published' ? 'Mark as draft' : 'Unmark draft'}</div>
        </div>
        <LessonContentModal onCloseModal={onCloseModal} onStateConfirm={onStateConfirm}>
          <NodeItemCourse state={hierarchyItem.state} title={hierarchyItem.title} type={hierarchyItem.type} />
        </LessonContentModal>
      </div>
    </CommonModal>
  );
};
