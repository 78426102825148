import { FC, memo } from 'react';

import styles from './lesson-state.module.scss';

import { Button } from '../../../../../UI';

interface IProps {
  onCloseModal: () => void;
  onStateConfirm: (e: React.SyntheticEvent<HTMLButtonElement>) => void;
}

export const LessonContentModal: FC<IProps> = memo(({ onCloseModal, onStateConfirm, children }) => {
  return (
    <>
      <div className={'px-6'}>
        {children}
        <div className={styles.description}>
          While the lesson is in draft, the student will not have access to it, and you will be able to edit it and
          republish it.
        </div>
      </div>
      <div className={'d-flex justify-content-end pt-5 px-6'}>
        <Button onClick={onCloseModal} btnStyle={'transparent'} withIcon={false}>
          Cancel
        </Button>
        <Button onClick={onStateConfirm} type={'update'} iconPosition={'right'} className={'ml-2'}>
          Confirm
        </Button>
      </div>
    </>
  );
});
