import React from 'react';

import { RestrictPermission } from '../../interfaces/permission';
import PermissionContext from './PermissionContext';

type Props = {
  // fetchPermission: (p: RestrictPermission) => void;
  restrictPermissions: RestrictPermission[];
};

// type PermissionCache = {
//   [key: string]: boolean;
// };

// This provider is intended to be surrounding the whole application.
// It should receive the users permissions as parameter
const PermissionProvider: React.FunctionComponent<Props> = ({ restrictPermissions, children }) => {
  // const cache: PermissionCache = {};

  // Creates a method that returns whether the requested permission is available in the list of permissions
  // passed as parameter
  const isAllowedTo = (permission: RestrictPermission) => {
    return restrictPermissions.includes(permission);
  };

  // This component will render its children wrapped around a PermissionContext's provider whose
  // value is set to the method defined above
  return <PermissionContext.Provider value={{ isAllowedTo }}>{children}</PermissionContext.Provider>;
};

export default PermissionProvider;
