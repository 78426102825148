import { DefaultRootState, useSelector } from 'react-redux';

import { IAutomationNodeBuilder, IFunnelNodeBuilder } from '../interfaces';

export const useNodePayload = <T>(nodeId?: string) => {
  return useSelector<DefaultRootState, IAutomationNodeBuilder<T> | IFunnelNodeBuilder<T> | undefined>(
    (state) =>
      state.builder.builder.nodes.find((x) => x.id === nodeId) as IAutomationNodeBuilder<T> | IFunnelNodeBuilder<T>
  );
};
