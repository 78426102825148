import { IconFunnel, IconLock,IconUser } from '../../../../../components/UI/icons';

import { ListTypes } from '../../store/ListsState';

interface IProps {
  type: ListTypes;
  className?: string;
}

export const ListTypeIcon = ({ type, className }: IProps) => {
  switch (type) {
    case 'main':
      return <IconLock className={className} />;
    case 'manual':
      return <IconUser className={className} />;
    case 'filter':
      return <IconFunnel className={className} />;
  }
  return null;
};
