import { memo, useState } from 'react';
import { FormControl } from 'react-bootstrap';
import { useFormContext, useWatch } from 'react-hook-form';

import classNames from 'classnames';
import commonOfferStyle from '../../common/commonOffer.module.scss';
import styles from './order-bump.module.scss';

import { setEmptyOrStr } from '../../../../../../helpers/text';
import { IProduct } from '../../../../products/store/ProductsState';
import { InputLabel } from '../../common/input-label/InputLabel';
import { InputPrice } from '../../common/inputPrice/InputPrice';
import { ProductSearch } from '../../common/search/ProductSearch';
import { Toggle } from '../../common/toggle/Toggle';
import { PriceData } from '../Price';

interface IProps {
  dataPrice: PriceData;
  isActiveOrderBump: boolean;
  setSelectProducts: (product: IProduct[]) => void;
  selectProducts: IProduct[];
}

export const OrderBump = memo(({ isActiveOrderBump, dataPrice, selectProducts, setSelectProducts }: IProps) => {
  const {
    register,
    control,
    setValue,
    formState: { errors }
  } = useFormContext<Omit<PriceData, 'price'>>();
  const textAreaData = useWatch({ control, name: 'order_bump_description' });

  const onChangeValue = () => {
    if (!isActiveOrderBump && dataPrice.order_bump_products) {
      setValue('order_bump_products', dataPrice.order_bump_products);
    }
  };

  return (
    <div>
      <Toggle
        title={'Order bump'}
        controlName={'order_bump_is_active'}
        checked={isActiveOrderBump}
        onChangeValue={onChangeValue}
      >
        {isActiveOrderBump && (
          <div className={'mt-4'}>
            <ProductSearch
              name={'order_bump_products'}
              size={'sm'}
              productsData={dataPrice.order_bump_products}
              errorMessage={(errors.order_bump_products as any)?.message}
              isError={!!errors.order_bump_products}
              selectProducts={selectProducts}
              setSelectProducts={setSelectProducts}
            />

            <InputPrice
              label={'Price, USD'}
              name={'order_bump_price'}
              isOptionField={false}
              inputContainerStyles={'mt-3'}
              inputStyles={styles.priceInput}
              errorNode={
                errors.order_bump_price && (
                  <div className={commonOfferStyle.errorTitle}>{errors.order_bump_price?.message}</div>
                )
              }
            />

            <InputPrice
              label={'Compare to'}
              name={'order_bump_old_price'}
              isOptionField={true}
              inputContainerStyles={'mt-3'}
              inputStyles={styles.priceInput}
            >
              <div className={styles.description}>This price will be displayed as crossed out</div>
            </InputPrice>

            <InputLabel label={'Headline'} classContainer={'mt-3'}>
              <FormControl
                {...register('order_bump_headline')}
                placeholder={'Give it a name'}
                className={classNames('form-control form-control-sm', styles.describeInput)}
                type={'text'}
              />
            </InputLabel>
            {errors.order_bump_headline && (
              <div className={commonOfferStyle.errorTitle}>{errors.order_bump_headline?.message}</div>
            )}
            <InputLabel label={'Description'} classContainer={'mt-3'}>
              <div className={styles.describeInput}>
                <FormControl
                  placeholder={'Describe your order bump offer'}
                  {...register('order_bump_description', { setValueAs: setEmptyOrStr })}
                  className={'form-control form-control-sm resize-none'}
                  type={'text'}
                  maxLength={200}
                  as={'textarea'}
                  rows={3}
                />
                <div className={styles.length}>{textAreaData?.length}/200</div>
              </div>
            </InputLabel>
            <InputLabel label={'Checkbox label'}>
              <FormControl
                {...register('order_bump_checkbox_text', { setValueAs: setEmptyOrStr })}
                className={classNames('form-control form-control-sm', styles.describeInput)}
                type={'text'}
              />
            </InputLabel>
            {errors.order_bump_checkbox_text && (
              <div className={commonOfferStyle.errorTitle}>{errors.order_bump_checkbox_text?.message}</div>
            )}
          </div>
        )}
      </Toggle>
    </div>
  );
});
