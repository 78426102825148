import classNames from 'classnames';
import styles from './course-item-info.module.scss';

interface IProps {
  countLesson: number;
  passedLesson: number;
}

export const CourseItemInfo = ({ countLesson, passedLesson }: IProps) => {
  return (
    <div className={'d-flex align-items-center justify-content-between'}>
      <div className={classNames(styles.count, 'd-none d-sm-block')}>
        {passedLesson && Math.round((passedLesson * 100) / countLesson)}% passed
      </div>
      <div className={classNames(styles.count, 'd-none d-sm-block')}>{countLesson} lessons</div>
    </div>
  );
};
