import { memo, useEffect, useRef, useState } from 'react';

import classNames from 'classnames';
import styles from './tagElement.module.scss';
import { Icon32Cross, IconPencilOnPaper } from '../../../components/UI/icons';

import { CustomTooltip } from '../../../components/UI';
import { ITag } from './store/TagState';

interface IProps {
  bigTag: ITag;
  isShowDetach?: boolean;
  isShowEdit?: boolean;

  onOpenModal?(): void;

  handleDelete?(): void;
}

export const TagComponent = memo(
  ({ bigTag, handleDelete, onOpenModal, isShowDetach = true, isShowEdit = true }: IProps) => {
    const tagName = useRef<HTMLDivElement | null>(null);
    const [isShowTooltip, setIsShowTooltip] = useState(false);

    useEffect(() => {
      if (tagName && tagName.current) {
        setIsShowTooltip(tagName.current?.scrollWidth > tagName.current?.offsetWidth);
      }
    }, [isShowTooltip]);

    return (
      <button
        className={styles.searchInput}
        style={{ backgroundColor: bigTag.background_color, color: bigTag.foreground_color }}
      >
        <div className={styles.container}>
          {isShowEdit && (
            <span onClick={onOpenModal} className={styles.tagActions}>
              <IconPencilOnPaper className={styles.icon} />
            </span>
          )}
          {isShowDetach && (
            <span onClick={handleDelete} className={styles.tagActions}>
              <Icon32Cross className={styles.icon} />
            </span>
          )}
        </div>

        <div className={classNames(styles.tagName, styles.hidden, isShowDetach && isShowEdit && 'show')} ref={tagName}>
          {bigTag.title}
        </div>
        <CustomTooltip
          customText={bigTag.title}
          direction={'bottom-start'}
          className={styles.tagName}
          disabled={!isShowTooltip}
        >
          {bigTag.title}
          <div
            className={styles.shadow}
            style={{
              backgroundImage: `linear-gradient(to right, rgba(0,0,0, 0), ${bigTag.background_color} 93%)`
            }}
          />
        </CustomTooltip>
      </button>
    );
  }
);
