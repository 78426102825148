import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  ColumnDescription,
  RowEventHandlerProps,
  SelectRowProps,
  TableChangeState,
  TableChangeType
} from 'react-bootstrap-table-next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import { debounce } from 'lodash';

import classNames from 'classnames';
import styles from './student-list.module.scss';
import { Icon32Feed, IconChatBubbles, IconTrash } from '../../../../components/UI/icons';

import { EmptyList } from '../../../../components/empty-list/EmptyList';
import { NoSearchResult } from '../../../../components/no-search-result/NoSearchResult';
import { CustomTooltip, Loader } from '../../../../components/UI';
import { RemotePagination } from '../../../../components/UI/pagination/RemotePagination';
import { PopupCard } from '../../../../components/UI/popup-card/PopupCard';
import { useIntercomUpdate } from '../../../../hooks/use-intercom-update';
import { usePayload } from '../../../../hooks/usePayload';
import { RowType } from '../../../../interfaces/tables';
import { LoadState } from '../../../../store';
import { FieldActionAsync } from '../../../crm/field';
import { FieldsEdit } from '../../../crm/fields/FieldsEdit';
import { FormatterType } from '../../../crm/lists/listLists/config/listTableConfig';
import { IListField } from '../../../crm/lists/store/ListsState';
import { StudentDelete } from '../common/student-delete/StudentDelete';
import { Tags } from '../common/table-tags/Tags';
import { StudentDataSelector } from '../store/StudentDataSelector';
import { IStudentListField, StudentListRow } from '../store/StudentListState';
import { StudentsActionAsync } from '../store/StudentsActionAsync';
import { StudentCard } from '../student-card/StudentCard';
import { ListHeader } from './list-header/ListHeader';

type Row = RowType<StudentListRow>;

const idColumn = {
  dataField: 'id',
  text: 'ID',
  classes: 'col-1',
  headerClasses: 'col-1'
};

const coursesColumn = {
  dataField: 'courses_count',
  text: 'Courses',
  classes: 'col-3',
  headerClasses: 'col-3',
  formatter: (cell: number) => {
    return cell === 0 ? 'Not enrolled' : cell;
  }
};

const selectRow: SelectRowProps<any> = {
  mode: 'radio',
  clickToSelect: true,
  hideSelectColumn: true,
  classes: styles.selectedRow
};

export const StudentList = () => {
  const dispatch = useDispatch();
  const { isPayloadShow, showPayload, hidePayload } = usePayload();
  const { searchString, isSidebarShow: isFieldSettingsShow } = useSelector((state) => state.settings);
  const tableDataMemo = useMemo(StudentDataSelector, []);
  const { studentData, page, page_size, total, loadState } = useSelector((state) => tableDataMemo(state));
  const { fields } = useSelector((state) => state.fields);
  const { studentList } = useSelector((state) => state.studentsConfig);
  const sizePerPage = 25;
  const [studentId, setStudentId] = useState<string>();
  const [studentsTable, setStudentsTable] = useState<{
    items: Row[];
    totalSize: number;
    page: number;
    sizePerPage: number;
  }>({ items: studentData, totalSize: 0, page: 1, sizePerPage: sizePerPage });
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedStudent, setSelectedStudent] = useState<string>();
  const [selectedStudentName, setSelectedStudentName] = useState<string>();
  const location = useLocation<{ showPayload?: boolean; studentIdFromContact?: string }>();

  const [isPageChanged, setIsPageChanged] = useState(true);
  const [isSearch, setIsSearch] = useState(false);

  useIntercomUpdate(isPayloadShow);

  const studentFields = useMemo(
    () =>
      studentList.fields?.map((listField) => {
        listField.field = fields.find((field) => field.id === listField.field_id);
        return listField;
      }),
    [fields, studentList.fields]
  );

  const changeHandler = useCallback(
    (searchString: string) => {
      if (!isPageChanged) {
        setIsSearch(searchString !== '');
        dispatch(
          StudentsActionAsync.getStudentsList(
            {
              page: 1,
              page_size: sizePerPage
            },
            searchString,
            () => {
              setIsPageChanged(false);
            }
          )
        );
      }
    },
    [dispatch, isPageChanged]
  );

  const debouncedSearch = useMemo(() => debounce(changeHandler, 300), [changeHandler]);

  const debouncedChangeHandler = useCallback(debouncedSearch, [debouncedSearch]);

  useEffect(() => {
    debouncedChangeHandler(searchString);
  }, [debouncedChangeHandler, searchString]);

  useEffect(() => {
    dispatch(
      FieldActionAsync.getFields(() => {
        dispatch(StudentsActionAsync.getStudentsConfig());
        dispatch(
          StudentsActionAsync.getStudentsList({
            page: 1,
            page_size: sizePerPage
          })
        );
      })
    );
  }, [dispatch]);

  useEffect(() => {
    if (loadState === LoadState.allIsLoaded) {
      setStudentsTable({ items: studentData, page: page, sizePerPage: page_size, totalSize: total });
    }
  }, [loadState, page, page_size, studentData, total]);

  useEffect(() => {
    if (location.state?.studentIdFromContact && loadState === LoadState.allIsLoaded) {
      setStudentId(location.state.studentIdFromContact);
      showPayload();
      window.history.replaceState({}, document.title);
    }
  }, [loadState, location?.state?.studentIdFromContact, showPayload]);

  useEffect(() => {
    if (!location.state?.showPayload) {
      hidePayload();
    }
  }, [hidePayload, location.state?.showPayload]);

  useEffect(() => {
    if (!isPayloadShow) {
      setStudentId(undefined);
    }
  }, [isPayloadShow]);

  useEffect(() => {
    if (studentId) {
      showPayload();
    }
  }, [showPayload, studentId]);

  const handleTableChange = (type: TableChangeType | 'search', { page, sizePerPage }: TableChangeState<unknown>) => {
    if (type === 'search') {
      setIsPageChanged(false);
    }
    if (type === 'pagination') {
      setIsPageChanged(true);
      dispatch(StudentsActionAsync.getStudentsList({ page: page, page_size: sizePerPage }, searchString));
    }
  };

  const columnFormatter = useCallback(
    (cell: string, row: Row, index: number, data: IListField) => {
      if (cell === undefined && data.field?.scope !== 'first_name') {
        return '-';
      }
      if (data.field?.scope === 'first_name') {
        const lastNameField: IStudentListField | undefined = studentFields?.find(
          (field) => field.field?.scope === 'last_name'
        );
        const lastName = (lastNameField && row[lastNameField.field_id]) || '';
        return (
          <div className={styles.name}>
            <div className={styles.link}>{`${cell || '-'} ${lastName}`}</div>
            <Tags id={row.id} />
          </div>
        );
      }
      if (data.field?.scope === 'email') {
        return <div className={styles.email}>{cell}</div>;
      }

      return cell;
    },
    [studentFields]
  );

  const onDeleteStudent = (e: React.SyntheticEvent) => {
    e.stopPropagation();
    const id = e.currentTarget.id;
    const currentRow = studentsTable.items.find((row) => row.id === id);
    if (currentRow) {
      const nameFields = studentFields
        ?.map((field) => {
          if (field.field && ['first_name', 'last_name'].includes(field.field.scope)) {
            return currentRow[field.field_id];
          }
          return null;
        })
        .filter((field) => field);
      setSelectedStudent(id);
      setSelectedStudentName(nameFields?.join(' '));
      setShowDeleteModal(true);
    }
  };

  const dateColumnFormatter: FormatterType<Row> = (cell: string, row: Row) => {
    return (
      <div className={'d-flex align-items-center h-100'}>
        <div className="value">{moment(cell).format('DD MMMM YYYY')}</div>
        <div className={styles.buttons}>
          <CustomTooltip customText={'Coming soon'} direction={'top'}>
            <button
              type={'button'}
              id={row.id}
              className={'btn btn-icon'}
              onClick={(e) => {
                e.stopPropagation();
                window.open('https://feedback.everact.io/b/feedback/p/multichannel-communication');
              }}
            >
              <IconChatBubbles />
            </button>
          </CustomTooltip>
          <CustomTooltip customText={'Delete student'} direction={'top'}>
            <button
              type={'button'}
              id={row.id}
              className={classNames('btn btn-icon', styles.delete)}
              onClick={onDeleteStudent}
            >
              <IconTrash />
            </button>
          </CustomTooltip>
        </div>
      </div>
    );
  };

  const createDateColumn = {
    dataField: 'date_create',
    text: 'Creation date',
    classes: classNames('col', styles.actions),
    headerClasses: 'col',
    formatter: dateColumnFormatter
  };

  const columnsData: ColumnDescription<Row>[] = useMemo(
    () =>
      studentFields
        ?.filter((field) => field.field && ['first_name', 'email'].includes(field.field?.scope))
        .map((field) => {
          const columnTitle = field.field?.payload.title || '-';
          return {
            dataField: field.field_id,
            text: field.field?.scope === 'first_name' ? 'Full name' : columnTitle,
            formatter: columnFormatter,
            formatExtraData: field,
            classes: 'col',
            headerClasses: 'col'
          };
        }) || [],
    [columnFormatter, studentFields]
  );

  const openStudentCard = () => {
    setStudentId(undefined);
    showPayload();
  };

  const onCloseDeletePopup = useCallback(() => {
    setShowDeleteModal(false);
  }, []);

  const deleteStudentHandler = useCallback(
    (deleteContact: boolean) => {
      if (selectedStudent) {
        setShowDeleteModal(false);
        dispatch(StudentsActionAsync.deleteStudent(selectedStudent, deleteContact));
      }
    },
    [dispatch, selectedStudent]
  );

  const rowEvents: RowEventHandlerProps<Row> = {
    onClick: (e, row) => {
      setStudentId(row.id);
    }
  };

  const updateStudentId = (id: string) => {
    setStudentId(id);
  };

  const onCreateStudent = () => {
    dispatch(StudentsActionAsync.getStudentsList({ page, page_size }, searchString));
  };

  return (
    <>
      {((loadState === LoadState.firstLoad && !isPayloadShow) || columnsData.length === 0) && <Loader />}
      <ListHeader onClick={openStudentCard} totalCount={total} hideSearch={!isSearch && loadState === LoadState.idle} />
      {loadState === LoadState.allIsLoaded && columnsData.length > 0 && (
        <RemotePagination
          page={studentsTable.page}
          sizePerPage={studentsTable.sizePerPage}
          totalSize={studentsTable.totalSize}
          onTableChange={handleTableChange}
          tableProps={{
            keyField: 'id',
            columns: [idColumn, ...columnsData, coursesColumn, createDateColumn],
            selectRow: { ...selectRow, selected: isPayloadShow ? (studentId ? [studentId] : undefined) : [] },
            rowClasses: styles.row,
            rowEvents: rowEvents,
            data: studentsTable.items
          }}
        />
      )}
      {loadState === LoadState.idle &&
        (isSearch ? (
          <NoSearchResult searchString={searchString} />
        ) : (
          <EmptyList
            onClick={openStudentCard}
            button={'Add students'}
            title={'No added students'}
            description={'Lists of students will be created after adding and publishing courses.'}
            icon={<Icon32Feed width={64} height={64} />}
          />
        ))}
      <PopupCard isShow={isPayloadShow}>
        <StudentCard
          studentId={studentId || location.state?.studentIdFromContact}
          setStudentId={updateStudentId}
          onCreate={onCreateStudent}
        />
      </PopupCard>
      <PopupCard isShow={isFieldSettingsShow} fullHeight={true}>
        <FieldsEdit
          entity={'student'}
          title={'Edit student fields'}
          description={
            'Change the order and the content for the working fields. The settings are applied to all students.'
          }
        />
      </PopupCard>
      <StudentDelete
        onClose={onCloseDeletePopup}
        isShow={showDeleteModal}
        onDelete={deleteStudentHandler}
        name={selectedStudentName}
      />
    </>
  );
};
