import { memo } from 'react';

import classNames from 'classnames';
import styles from './course-item-btn.module.scss';
import { IconChevronRight, IconMultimediaPlayFilled } from '../../../../../components/UI/icons';

import { Button } from '../../../../../components/UI';

interface IProps {
  countLesson: number;
  passedLesson: number;
  lastLessonTitle?: string;
}

export const CourseItemBtn = memo(({ countLesson, passedLesson, lastLessonTitle }: IProps) => {
  const onClickCourse = () => {};

  return (
    <div className={styles.container}>
      {passedLesson === 0 && (
        <Button
          onClick={onClickCourse}
          withIcon={true}
          customIcon={<IconChevronRight />}
          className={classNames(styles.btn, 'd-none d-sm-block')}
        >
          Begin the course
        </Button>
      )}
      {passedLesson < countLesson && passedLesson > 0 && (
        <Button
          onClick={onClickCourse}
          withIcon={true}
          customIcon={<IconMultimediaPlayFilled className={styles.iconPlay} />}
          className={styles.btn}
          btnStyle={'secondary'}
        >
          <span className={classNames('ellipsis', styles.textEllipsis)}>{lastLessonTitle}</span>
        </Button>
      )}
      {passedLesson === countLesson && countLesson > 0 && (
        <Button
          onClick={onClickCourse}
          withIcon={true}
          type={'update'}
          className={classNames(styles.btn, 'd-none d-sm-block')}
          btnStyle={'outline'}
        >
          Complete
        </Button>
      )}
    </div>
  );
});
