import { FC, memo } from 'react';

import classNames from 'classnames';
import styles from './adminCourse.module.scss';

import { useLessonNode } from '../../hooks/use-lesson-node';
import { ICourse } from '../store/CourseState';

interface IProps {
  course: ICourse;
  nodeId?: string;
}

export const AdminCourseWrapper: FC<IProps> = memo(({ course, nodeId, children }) => {
  const { nodeLesson } = useLessonNode(course, nodeId);
  return (
    <div className={classNames('content course', nodeLesson?.state === 'draft' && styles.draftLesson)}>{children}</div>
  );
});
