import { FC, memo } from 'react';

import styles from './memberAvatar.module.scss';

import { Image } from '../../../../../components/UI/image-components/image/Image';
import { IFile } from '../../../../../interfaces';

interface IProps {
  image?: IFile;
}

export const MemberAvatar: FC<IProps> = memo(({ image, children }) => {
  return (
    <>
      {image ? (
        <Image
          alt={'avatar'}
          bucket_name={image.bucket_name}
          object_name={image.object_name}
          size={{ height: 40, width: 40 }}
          className={styles.container}
        />
      ) : (
        <div className={styles.container}>{children}</div>
      )}
    </>
  );
});
