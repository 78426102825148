import { useCallback, useEffect, useState } from 'react';
import { FieldValues, useFieldArray, useFormContext, useWatch } from 'react-hook-form';

import styles from './payload-range.module.scss';
import { Icon16Plus } from '../../../../../../components/UI/icons';

import { Button } from '../../../../../../components/UI';
import { getUUID } from '../../../../../../helpers';
import { useNodePayload } from '../../../../../../hooks/use-node-payload';
import { PaletteAutomationElementTypes } from '../../../../../../interfaces';
import {
  IRangeProcessorAutomationPayload,
  Ranges
} from '../../../../../../interfaces/node-builder-payload/automation-processors/range';
import { AmountItem } from './AmountItem';
import { RangeChecker } from './RangeChecker';
import { RangeError } from './RangeError';
import { RangeItem } from './RangeItem';

interface IProps {
  nodeId: string;
}

export const PayloadRange = ({ nodeId }: IProps) => {
  const {
    control,
    setValue,
    getValues,
    setError,
    clearErrors,

    formState: { errors }
  } = useFormContext<FieldValues & IRangeProcessorAutomationPayload>();
  const payload = useNodePayload<PaletteAutomationElementTypes.PROCESSOR_RANGE>(nodeId);
  const [amount, setAmount] = useState<number>(0);
  const rangeTypes = useWatch({ name: 'range.type' });
  const { fields, append, remove } = useFieldArray({
    name: 'range.intervals',
    control,
    keyName: 'key'
  });

  const addRangeHandler = useCallback(() => {
    const lastField = fields[fields.length - 1];
    const start = lastField && lastField.end !== undefined && lastField.end + 1;
    const end = lastField && lastField.end !== undefined && lastField.end * 10;
    const interval_id = getUUID();
    const newRange = {
      id: interval_id,
      start: start || 1,
      end: end || 100
    };
    append(newRange);

    const ranges = getValues(nodeId);
    if (ranges) {
      ranges[newRange.id] = newRange;
      setValue(nodeId, ranges);
    } else {
      setValue(nodeId, newRange);
    }
  }, [append, fields, getValues, nodeId, setValue]);

  useEffect(() => {
    if (payload && payload.payload?.range?.type === Ranges[Ranges.range]) {
      setValue('range.intervals', fields.length > 0 ? fields : payload.payload.range?.intervals);
    } else if (payload && payload.payload.range?.type === Ranges[Ranges.amount] && payload.payload?.range?.end) {
      setAmount(payload.payload.range?.end);
      setValue('range.end', payload?.payload.range?.end);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fields.length, payload, setValue]);

  const onRemoveHandler = useCallback(
    (index: number, idElement: string) => {
      //@ts-ignore
      const newFields = fields.filter((x) => x.id !== idElement);
      // setValue(nodeId, keyBy(newFields, 'id'));
      remove(index);
    },
    [fields, remove]
  );

  const onChangeFieldEnd = useCallback(
    (e: string, index: number) => {
      clearErrors(`range.intervals.${index}`);

      //@ts-ignore
      const nextStart = fields[index + 1]?.start;
      //@ts-ignore
      const start = fields[index]?.start;
      if (start && Number(e) <= start) {
        setError(`range.intervals.${index}`, {
          type: 'manual',
          message: 'The range should be ascending.'
        });
      }
      if (index > 0 && nextStart) {
        if (nextStart <= Number(e)) {
          setError(`range.intervals.${index}`, {
            type: 'manual',
            message: 'Change the minimum value so your ranges don’t overlap.'
          });
        }
      }
    },
    [fields, setError, clearErrors]
  );

  const onChangeFieldStart = useCallback(
    (e: string, index: number) => {
      clearErrors(`range.intervals.${index}`);

      //@ts-ignore
      const prevEnd = fields[index - 1]?.end;
      //@ts-ignore
      const end = fields[index]?.end;
      if (end && Number(e) >= end) {
        setError(`range.intervals.${index}`, {
          type: 'manual',
          message: 'The range should be ascending.'
        });
      }
      if (index > 0 && prevEnd) {
        if (prevEnd >= Number(e)) {
          setError(`range.intervals.${index}`, {
            type: 'manual',
            message: 'Change the maximum value so the ranges don’t overlap.'
          });
        }
      }
    },
    [fields, setError, clearErrors]
  );
  // useEffect(() => {
  //   fields.map((x, index) => {
  //     if (x.start && x.end) {
  //       onChangeFieldStart(x.start.toString(), index);
  //       onChangeFieldEnd(x.end.toString(), index);
  //     }
  //   });
  // }, [errors?.range?.intervals]);

  // console.log('errors:', errors);

  return (
    <div className={'h-100 d-flex flex-column justify-content-between'}>
      <div>
        <RangeChecker nodeId={nodeId} />
        {rangeTypes && rangeTypes === 'amount' ? (
          <AmountItem onChangeVal={setAmount} val={amount} />
        ) : (
          <div className={'mt-4'}>
            {fields.map((field, index) => (
              //@ts-ignore
              <div key={field.id} className={styles.relative}>
                <RangeItem
                  //@ts-ignore
                  field={field}
                  isError={
                    (errors?.range as any)?.intervals &&
                    Array.isArray((errors?.range as any)?.intervals) &&
                    (errors?.range as any)?.intervals[index]
                  }
                  index={index}
                  nodeId={nodeId}
                  onChangeFieldEnd={onChangeFieldEnd}
                  onChangeFieldStart={onChangeFieldStart}
                  removeElement={onRemoveHandler}
                />
              </div>
            ))}
            <Button withIcon={false} onClick={addRangeHandler} btnStyle={'secondary'} className={styles.addRangeBtn}>
              <Icon16Plus />
              Add range
            </Button>
          </div>
        )}
      </div>
      {(errors?.range as any)?.intervals &&
        Array.isArray((errors?.range as any)?.intervals) &&
        (errors?.range as any)?.intervals?.length > 0 && (
          <div className={styles.containerError}>
            <div className={styles.errorTitle}>Correct field errors:</div>
            {(errors?.range as any)?.intervals?.map((x: any, index: number) => (
              <div key={getUUID()}>
                <RangeError index={index + 1} message={x.message} />
              </div>
            ))}
          </div>
        )}
    </div>
  );
};
