import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { toast } from 'react-toastify';

import classNames from 'classnames';
import styles from './upload-logo.module.scss';
import { IconPlus, IconTrash, IconUpload } from '../../../../components/UI/icons';

import { IImageFieldValue } from '../../../../components/UI/fields/image-field/ImageField';
import { Image } from '../../../../components/UI/image-components/image/Image';
import { ImageUpload } from '../../../../components/UI/image-components/image-upload/ImageUpload';
import { ToastMessage } from '../../../../components/UI/toast/ToastMessage';

interface IProps {
  className?: string;
}

export const UploadLogo = memo(({ className }: IProps) => {
  const { setValue, getValues, register, unregister } = useFormContext();
  const [isPreloaded, setIsPreloaded] = useState(false);
  const [previewImage, setPreviewImage] = useState<string | null>(null);
  const value: IImageFieldValue = getValues('theme_logo');
  const imagePreviewUrlRef = useRef<string | null>(null);

  useEffect(() => {
    if (value === null) {
      setPreviewImage(null);
      setIsPreloaded(false);
      imagePreviewUrlRef.current = null;
    }
  }, [isPreloaded, value]);

  useEffect(() => {
    return () => {
      unregister('theme_logo');
      unregister('general_image');
    };
  }, [register, unregister]);

  const setImagePreviewUrl = useCallback((url: string | null) => {
    // console.log('%c⇒ url', 'color: #89DDF7', url);
    setPreviewImage(url);
    imagePreviewUrlRef.current = url;
    setIsPreloaded(!!url);
  }, []);

  const handleImageChange = (e: React.SyntheticEvent<HTMLInputElement>) => {
    e.preventDefault();
    const reader = new FileReader();

    if (e.currentTarget) {
      const file = e.currentTarget.files && e.currentTarget.files[0];

      if (file) {
        if (file.size <= 5242880) {
          reader.onloadend = () => {
            imagePreviewUrlRef.current = reader.result as string;
            // console.log(reader.result);
            setValue('theme_logo', { file }, { shouldDirty: true });
            setValue('general_image', { file }, { shouldDirty: true });

            setImagePreviewUrl(reader.result as string);
          };
          reader.readAsDataURL(file);
        } else {
          toast.warning(<ToastMessage message={'File size should not be more than 5Mb'} />);
        }
      }
    }
  };

  const deleteImage = () => {
    setImagePreviewUrl(null);
    setIsPreloaded(false);
    imagePreviewUrlRef.current = null;

    // register('theme_logo');
    setValue('general_image', null, { shouldDirty: true });
    setValue('theme_logo', null, { shouldDirty: true });
  };

  return (
    <div className={classNames(className, styles.container)}>
      <div>
        <div className={styles.title}>Upload your logo mark</div>
        <div className={styles.tips}>Recommended size 130×130 px</div>
      </div>
      <ImageUpload
        handleImageChange={handleImageChange}
        name={'logo'}
        isPreloaded={isPreloaded}
        setImagePreviewUrl={setImagePreviewUrl}
        setIsPreloaded={setIsPreloaded}
        bucket_name={value?.bucket_name}
        object_name={value?.object_name}
        size={{ width: 130, height: 130 }}
        disabled={false}
      >
        <div className={classNames(styles.imagePreview, { [styles.bg]: imagePreviewUrlRef.current || previewImage })}>
          {imagePreviewUrlRef.current || previewImage ? (
            <>
              <Image
                size={{ width: 46, height: 46 }}
                className={classNames(styles.image)}
                url={imagePreviewUrlRef.current}
              />
              <div className={styles.overlay}>
                <label htmlFor={'logo'} className={classNames('btn btn-icon m-0', styles.btn)}>
                  <IconUpload />
                </label>
                <div className={styles.separator} />
                <button className={classNames('btn btn-icon', styles.btn)} onClick={deleteImage}>
                  <IconTrash />
                </button>
              </div>
            </>
          ) : (
            <label htmlFor={'logo'} className={styles.button}>
              <IconPlus />
            </label>
          )}
        </div>
      </ImageUpload>
    </div>
  );
});
