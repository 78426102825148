import React, { memo, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import moment from 'moment';

import classNames from 'classnames';
import styles from './how-to-videos.module.scss';
import { Icon32Cross, IconMultimediaPlayFilled } from '../../../../components/UI/icons';

import { CommonModal } from '../../../../components/UI';
import { VideoPlayer } from '../../../../components/video/VideoPlayer';
import { IVimeoProject, IVimeoProjectMedia } from '../../../../interfaces/vimeo';
import { DashboardActionAsync } from '../../store/DashboardActionAsync';
import { Block } from '../block/Block';

export const HowToVideos = memo(() => {
  const dispatch = useDispatch();
  const [medias, setMedias] = useState<IVimeoProjectMedia[]>();
  const [selectedMedia, setSelectedMedia] = useState<IVimeoProjectMedia>();
  const [showModal, setShowModal] = useState(false);
  const projectId = process.env.REACT_APP_VIMEO_HOW_TO_VIDEOS;

  useEffect(() => {
    if (projectId) {
      dispatch(DashboardActionAsync.getHowToVideos(projectId, getProjectCallback));
    }
  }, [dispatch, projectId]);

  const getProjectCallback = (data: IVimeoProject | null) => {
    setMedias(data?.data);
    setSelectedMedia(data?.data[0]);
  };

  const onSelect = (e: React.SyntheticEvent) => {
    const id = e.currentTarget.id;
    const selected = medias?.find((media) => media.link === id);
    if (selected) {
      setSelectedMedia(selected);
    }
  };

  const onOpenVideo = () => {
    if (selectedMedia?.link) {
      setShowModal(true);
    }
  };

  const onCloseModal = () => {
    setShowModal(false);
  };

  return (
    <Block>
      <Row className={'h-100'}>
        <Col className={'h-100 pr-0'}>
          <div className={styles.left}>
            <div className={styles.title}>How to videos</div>
            <div className={styles.list}>
              {medias?.map((media, index) => (
                <div
                  key={media.link}
                  className={classNames(styles.item, { [styles.active]: selectedMedia?.link === media.link })}
                  id={media.link}
                  onClick={onSelect}
                >
                  <div className={styles.marker}>
                    {selectedMedia?.link === media.link ? <IconMultimediaPlayFilled /> : index + 1}
                  </div>
                  <div className={styles.name}>{media.name}</div>
                </div>
              ))}
              {/*<div className={classNames(styles.item, styles.passed)}>
                  <div className={styles.marker}>
                    <IconCheckMark />
                  </div>
                  <div className={styles.name}>Hierarchy and navigation</div>
                </div>*/}
            </div>
          </div>
        </Col>
        <Col className={'pl-0'}>
          <div className={styles.right}>
            <div className={styles.video}>
              <img className={styles.thumbnail} src={selectedMedia?.pictures?.sizes[3]?.link} alt={''} />
              <div className={styles.overlay} onClick={onOpenVideo}>
                <div className={styles.play}>
                  <IconMultimediaPlayFilled width={48} height={48} />
                </div>
              </div>
            </div>
            <div className={styles.time}>
              {selectedMedia?.duration && moment.utc(Math.round(selectedMedia?.duration) * 1000).format('m[m] ss[s]')}
            </div>
            <div
              className={styles.description}
              dangerouslySetInnerHTML={{ __html: selectedMedia?.description || '' }}
            />
          </div>
        </Col>
      </Row>
      <CommonModal onClose={onCloseModal} show={showModal} params={{ contentClassName: styles.videoModal }}>
        {selectedMedia && <VideoPlayer url={selectedMedia?.link} videoHeight={432} videoWidth={500} />}
        <button className={classNames('btn btn-icon', styles.close)} onClick={onCloseModal}>
          <Icon32Cross />
        </button>
      </CommonModal>
    </Block>
  );
});
