import { ChangeEvent } from 'react';
import { Form, FormCheck } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';

import classNames from 'classnames';
import styles from './toggle-connection-domain.module.scss';

interface IProps {
  domain: boolean;
  onToggleDomain: (x: boolean) => void;
}
export const ToggleConnectionDomain = ({ domain, onToggleDomain }: IProps) => {
  const { reset } = useFormContext();
  const onCheckRadio = (e: ChangeEvent<HTMLInputElement>) => {
    onToggleDomain(e.target.value === 'domain');
    reset();
  };

  return (
    <Form.Group controlId={'domain'}>
      <FormCheck
        custom
        className={classNames(styles.container, 'radio-button', domain && styles.active)}
        type={'radio'}
      >
        <FormCheck.Input type={'radio'} checked={domain} onChange={onCheckRadio} value={'domain'} id={'domain'} />
        <FormCheck.Label className="custom-control-label" htmlFor={'domain'}>
          Domain
          <div>
            I want my student cabinet URL to look like: <br />
            mywebsite.com
          </div>
        </FormCheck.Label>
      </FormCheck>
      <FormCheck
        custom
        className={classNames(styles.container, 'radio-button mt-1', !domain && styles.active)}
        type={'radio'}
      >
        <FormCheck.Input
          type={'radio'}
          checked={!domain}
          onChange={onCheckRadio}
          value={'subdomain'}
          id={'subdomain'}
        />
        <FormCheck.Label className="custom-control-label" htmlFor={'subdomain'}>
          Subdomain
          <div>
            I want my student cabinet URL to look like: <br />
            courses.mywebsite.com
          </div>
        </FormCheck.Label>
      </FormCheck>
    </Form.Group>
  );
};
