import { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import TextareaAutosize, { TextareaHeightChangeMeta } from 'react-textarea-autosize';

import styles from './comment-element.module.scss';
import { IconInfo } from '../../../components/UI/icons';

import { Image } from '../../../components/UI/image-components/image/Image';
import { InfoMessage } from '../../../components/UI/infoMessage/InfoMessage';
import { IFile } from '../../../interfaces';
import { SimpleToggle } from '../../crm/offers/create-offer/common/toggle/SimpleToggle';
import { courseActionAsync } from '../../lms/courses/store/CourseActionAsync';

type Props = {
  commentId: string;
  avatar: IFile;
  name: string;
  date: string;
  content: string;
  showToggle?: boolean;
  isShowComment?: boolean;
};

export const CommentElement = ({ avatar, content, name, date, showToggle, isShowComment, commentId }: Props) => {
  const dispatch = useDispatch();
  const [isShow, setShow] = useState(isShowComment || false);
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const [showMore, setShowMore] = useState<boolean>();
  const [visibleBtn, setVisibleBtn] = useState<boolean>();

  const onChangeHeight = (height: number, meta: TextareaHeightChangeMeta) => {
    if (height > meta.rowHeight * 6 && height !== inputRef.current?.scrollHeight) {
      setVisibleBtn(true);
    }
  };

  const onChangeHide = (show: boolean) => {
    dispatch(courseActionAsync.toggleHideShowComment(commentId, show));
    setShow(show);
  };

  const onClick = () => {
    if (inputRef.current) {
      if (!inputRef.current.style.height || inputRef.current.style.height === '0px') {
        inputRef.current.style.height =
          Array.prototype.reduce.call(
            inputRef.current.childNodes,
            function (p, c) {
              return p + (c.offsetHeight || 0);
            },
            0
          ) + 'px';
      }
    }
    setShowMore(!showMore);
  };

  return (
    <div className={styles.container}>
      <Image bucket_name={avatar.bucket_name} object_name={avatar.object_name} className={styles.image} />
      <div>
        <div className={styles.authorContainer}>
          <div>
            <span className={styles.name}>{name}</span>
            <span className={styles.date}>{date}</span>
          </div>
          {showToggle && (
            <div className={styles.toggleContainer}>
              Hide comment
              <SimpleToggle
                isToggle={isShow}
                label=""
                classNameContainer={styles.customizationToggle}
                onChangeValue={onChangeHide}
              />
            </div>
          )}
        </div>
        <InfoMessage
          show={isShow}
          text={'This comment is hidden from public view'}
          textClassName={styles.info}
          className={styles.infoContainer}
          customIcon={<IconInfo className={styles.infoIcon} />}
        />
        <TextareaAutosize
          value={content}
          readOnly
          onHeightChange={onChangeHeight}
          className={styles.input}
          maxRows={showMore ? undefined : 6}
          ref={inputRef}
        />
        {visibleBtn && (
          <button onClick={onClick} className={styles.more}>
            {showMore ? 'Show less' : 'Show more'}
          </button>
        )}
      </div>
    </div>
  );
};
