import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useEffectOnce } from 'usehooks-ts';

import classNames from 'classnames';
import styles from './comment-moderation.module.scss';

import { courseActionAsync } from '../../lms/courses/store/CourseActionAsync';
import { mainPaths } from '../../MainRoutes';
import { FilterComments } from '../common/filter-comments/FilterComments';
import { ThreadList } from '../common/thread/ThreadList';

export const CommentModeration = () => {
  const history = useHistory<{ course_id?: string }>();
  const dispatch = useDispatch();
  const { courses } = useSelector((state) => state.courses);
  const { authToken } = useSelector((state) => state.auth);

  useEffectOnce(() => {
    if (!authToken) {
      history.replace(mainPaths.auth);
    }
  });

  useEffectOnce(() => {
    if (!courses.length) {
      dispatch(courseActionAsync.getCourses());
    }
  });

  return (
    <div className={styles.container}>
      <aside className={styles.sidebar}>
        <FilterComments />
      </aside>

      <ThreadList />
    </div>
  );
};
