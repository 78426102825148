import { memo } from 'react';

import styles from './variablesInfo.module.scss';
import { IconInfo } from '../../../../../components/UI/icons';

import { Button } from '../../../../../components/UI';

interface IProps {
  show: boolean;
  onToggle: () => void;
}
export const VariablesInfo = memo(({ onToggle, show }: IProps) => {
  return (
    <>
      {show ? (
        <div className={styles.InfoWrapper}>
          <div className={styles.infoIcon}>
            <IconInfo />
          </div>
          <div className={styles.description}>
            Variables allow you to personalize emails without having to type out personal information for each of your
            recipients. For example, you can include an individual's name, city and organization in your email text —
            even when sending to a group!
          </div>
          <Button onClick={onToggle} withIcon={false}>
            Ok
          </Button>
        </div>
      ) : (
        <div className={styles.infoContainer} onClick={onToggle}>
          <div className={styles.info}>
            <IconInfo />
            How to use variables
          </div>
        </div>
      )}
    </>
  );
});
