import { CustomDispatch } from 'redux';
import { AxiosResponse } from 'axios';

import { editSettings, getSettings } from '../../../../API/general-api';
import {
  addExistingAccountStripe,
  addPaynetAccount,
  addPaypalAccount,
  detachPaynetAccount,
  detachPaypalAccount,
  detachStripeAccount,
  getPaynetAccount,
  getPaypalAccount,
  getPaypalLink,
  getStripeAccount,
  getStripeLink,
  getStripePublishableKey
} from '../../../../API/settings-api';
import { IGeneralSettings } from '../../../../interfaces';
import { GeneralSettingsActions } from './GeneralSettingsActions';
import {
  PaymentExistingAccount,
  PaymentLink,
  PaynetAccount,
  PaynetError,
  PaypalAccount,
  StripeAccount
} from './GeneralSettingsState';

export const GeneralSettingsActionAsync = {
  addPaynet:
    (
      paynetAccount: Pick<PaynetAccount, 'username' | 'password' | 'merchant_id'>,
      callback?: () => void,
      errorCallback?: (error: AxiosResponse<PaynetError>) => void
    ) =>
    async (dispatch: CustomDispatch) => {
      try {
        dispatch(GeneralSettingsActions.addPaynet.started(paynetAccount));
        const response = await addPaynetAccount(paynetAccount);
        if (response.data) {
          dispatch(GeneralSettingsActions.addPaynet.done({ params: paynetAccount, result: response.data }));
          callback?.();
        }
      } catch (error) {
        errorCallback?.(error);
        dispatch(GeneralSettingsActions.addPaynet.failed({ params: paynetAccount, error }));
      }
    },
  editOrganization:
    (params: Partial<IGeneralSettings>, callback?: (data: IGeneralSettings) => void) =>
    async (dispatch: CustomDispatch) => {
      try {
        dispatch(GeneralSettingsActions.editOrganization.started(params));
        const response = await editSettings(params);
        if (response.data) {
          dispatch(GeneralSettingsActions.editOrganization.done({ result: response.data, params }));
          callback?.(response.data);
        }
      } catch (error) {
        dispatch(GeneralSettingsActions.editOrganization.failed({ error, params }));
      }
    },
  getOrganization: () => async (dispatch: CustomDispatch) => {
    try {
      dispatch(GeneralSettingsActions.getOrganization.started());
      const response = await getSettings();
      if (response.data) {
        dispatch(GeneralSettingsActions.getOrganization.done({ result: response.data }));
      }
    } catch (error) {
      dispatch(GeneralSettingsActions.getOrganization.failed({ error }));
    }
  },
  getStripeAccount: (callback?: (data: StripeAccount | null) => void) => async (dispatch: CustomDispatch) => {
    try {
      dispatch(GeneralSettingsActions.getStripeAccount.started());
      const response = await getStripeAccount();
      if (response.data) {
        dispatch(GeneralSettingsActions.getStripeAccount.done({ result: response.data }));
        callback?.(response.data);
      }
    } catch (error) {
      dispatch(GeneralSettingsActions.getStripeAccount.failed({ error }));
    }
  },
  getPaypalAccount: (callback?: (paypal: PaypalAccount) => void) => async (dispatch: CustomDispatch) => {
    try {
      dispatch(GeneralSettingsActions.getPaypalAccount.started());
      const response = await getPaypalAccount();
      if (response.data) {
        dispatch(GeneralSettingsActions.getPaypalAccount.done({ result: response.data }));
        callback?.(response.data);
      }
    } catch (error) {
      dispatch(GeneralSettingsActions.getPaypalAccount.failed({ error }));
    }
  },
  getPaynetAccount: (callback?: (data: PaynetAccount | null) => void) => async (dispatch: CustomDispatch) => {
    try {
      dispatch(GeneralSettingsActions.getPaynetAccount.started());
      const response = await getPaynetAccount();
      if (response.data) {
        dispatch(GeneralSettingsActions.getPaynetAccount.done({ result: response.data }));
        callback?.(response.data);
      }
    } catch (error) {
      dispatch(GeneralSettingsActions.getPaynetAccount.failed({ error }));
    }
  },
  getPublishableKey: (callback: (pub_key: string) => void) => async (dispatch: CustomDispatch) => {
    try {
      dispatch(GeneralSettingsActions.getPublishableKey.started());
      const response = await getStripePublishableKey();
      if (response.data) {
        dispatch(GeneralSettingsActions.getPublishableKey.done({ result: response.data }));
        callback?.(response.data.pub_key);
      }
    } catch (error) {
      dispatch(GeneralSettingsActions.getPublishableKey.failed({ error }));
    }
  },
  detachPaynetAccount: (callback?: () => void) => async (dispatch: CustomDispatch) => {
    try {
      dispatch(GeneralSettingsActions.detachPaynetAccount.started());
      await detachPaynetAccount();
      dispatch(GeneralSettingsActions.detachPaynetAccount.done({}));
      callback?.();
    } catch (error) {
      dispatch(GeneralSettingsActions.detachPaynetAccount.failed({ error }));
    }
  },
  detachStripeAccount: (callback: () => void) => async (dispatch: CustomDispatch) => {
    try {
      dispatch(GeneralSettingsActions.detachStripeAccount.started());
      await detachStripeAccount();
      dispatch(GeneralSettingsActions.detachStripeAccount.done({}));
      callback();
    } catch (error) {
      dispatch(GeneralSettingsActions.detachStripeAccount.failed({ error }));
    }
  },
  detachPaypalAccount: (callback: () => void) => async (dispatch: CustomDispatch) => {
    try {
      dispatch(GeneralSettingsActions.detachPaypalAccount.started());
      await detachPaypalAccount();
      dispatch(GeneralSettingsActions.detachPaypalAccount.done({}));
      callback();
    } catch (error) {
      dispatch(GeneralSettingsActions.detachPaypalAccount.failed({ error }));
    }
  },
  getStripeLink: (stripeAccount: PaymentLink, callback: (link: string) => void) => async (dispatch: CustomDispatch) => {
    try {
      dispatch(GeneralSettingsActions.getStripeLink.started(stripeAccount));
      const response = await getStripeLink(stripeAccount);
      if (response.data) {
        dispatch(GeneralSettingsActions.getStripeLink.done({ params: stripeAccount, result: response.data }));
        callback(response.data.link);
      }
    } catch (error) {
      dispatch(GeneralSettingsActions.getStripeLink.failed({ params: stripeAccount, error }));
    }
  },
  getPaypalLink:
    (paypalAccount: Pick<PaymentLink, 'return_url'>, callback: (link: string) => void) =>
    async (dispatch: CustomDispatch) => {
      try {
        dispatch(GeneralSettingsActions.getPaypalLink.started(paypalAccount));
        const response = await getPaypalLink(paypalAccount);
        if (response.data) {
          dispatch(GeneralSettingsActions.getPaypalLink.done({ params: paypalAccount, result: response.data }));
          callback(response.data.link);
        }
      } catch (error) {
        dispatch(GeneralSettingsActions.getPaypalLink.failed({ params: paypalAccount, error }));
      }
    },
  addExistingAccount:
    (stripeExistingAccount: PaymentExistingAccount, callback: () => void) => async (dispatch: CustomDispatch) => {
      try {
        dispatch(GeneralSettingsActions.addExistingAccount.started(stripeExistingAccount));
        await addExistingAccountStripe(stripeExistingAccount);
        dispatch(GeneralSettingsActions.addExistingAccount.done({ params: stripeExistingAccount }));
        callback();
      } catch (error) {
        dispatch(GeneralSettingsActions.addExistingAccount.failed({ params: stripeExistingAccount, error }));
      }
    },
  addPaypalAccount: (paypal: PaypalAccount, callback: () => void) => async (dispatch: CustomDispatch) => {
    try {
      dispatch(GeneralSettingsActions.addPaypalAccount.started(paypal));
      await addPaypalAccount(paypal);
      dispatch(GeneralSettingsActions.addPaypalAccount.done({ params: paypal }));
      callback();
    } catch (error) {
      dispatch(GeneralSettingsActions.addPaypalAccount.failed({ params: paypal, error }));
    }
  }
};
