import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { OnChangeValue } from 'react-select';

import { Button } from '../../../../../../components/UI';
import { useNodePayload } from '../../../../../../hooks/use-node-payload';
import { IOption, PaletteElementTypes } from '../../../../../../interfaces';
import { automationPaths } from '../../../../../automations/routes/AutomationRoutes';
import { PayloadOnlyOnceToggle } from '../../PayloadOnlyOnceToggle';
import { AutomationSelect } from './automation-select/AutomationSelect';

interface IProps {
  nodeId: string;
  funnelId: string;
}

export const PayloadAutomation = ({ nodeId, funnelId }: IProps) => {
  const { register, setValue, unregister } = useFormContext();
  const [automationId, setAutomationId] = useState<string>();
  const node = useNodePayload<PaletteElementTypes.ACTION_AUTOMATION>(nodeId);
  const history = useHistory();

  useEffect(() => {
    if (node) {
      setAutomationId(node.payload.automation_id);
    }
  }, [node]);

  const onSelect = (value: OnChangeValue<IOption, false>) => {
    if (value) {
      register('automation_id');
      setValue('automation_id', value.value);
      setAutomationId(value.value);

      return () => {
        unregister('automation_id');
      };
    }
  };

  const openAutomation = () => {
    history.push(`${automationPaths.automationListId(automationId)}?funnel_id=${funnelId}`, {
      fromFunnel: true
    });
  };
  // console.log(getValues());
  // payloadSchema.validate(getValues(), { abortEarly: false }).catch(function (err) {
  //   err.inner.forEach((e: any) => {
  //     console.log(e);
  //   });
  // });
  return (
    <>
      <PayloadOnlyOnceToggle />
      <AutomationSelect onSelect={onSelect} id={automationId} />
      {automationId && (
        <Button onClick={openAutomation} withIcon={false} btnStyle={'secondary'} className={'w-100'}>
          Open automation
        </Button>
      )}
    </>
  );
};
