import moment from 'moment';

import styles from './available-node.module.scss';

interface IProps {
  date: string;
}

export const AvailableNode = ({ date }: IProps) => {
  return <div className={styles.text}>Available on: {moment(date).format('DD MMMM, yyyy, hh:mm A')}</div>;
};
