import moment from 'moment';

import classNames from 'classnames';
import styles from './cardTrial.module.scss';
import { ReactComponent as IconMasterCard } from '../../../../../assets/icons/4-icon-payment-mastercard.svg';
import { ReactComponent as IconVisa } from '../../../../../assets/icons/4-icon-payment-visa.svg';
import DashboardSource from '../../../../../assets/images/dashboard_source.png';

import { ISubscription } from '../../../../interfaces';

export const CardTrial = ({
  next_payment,
  subscription_payment_source,
  subscription_current_period_end,
  plan
}: Pick<
  ISubscription,
  'next_payment' | 'subscription_payment_source' | 'plan' | 'subscription_current_period_end'
>) => {
  const formatter =
    plan &&
    new Intl.NumberFormat('en-US', {
      style: 'currency',
      notation: 'compact',
      currency: plan.currency
    });

  return (
    <div className={styles.container}>
      <div className={styles.periodContainer}>
        <div className={styles.endTrialTxt}>
          Your trial ends {moment(subscription_current_period_end).format('DD MMM, YYYY')}
        </div>
        <div className={'d-flex align-items-center'}>
          <div className={styles.cardTxt}>
            First payment {plan && formatter?.format(plan.amount / 100)} on{' '}
            {moment(next_payment).format('DD MMM, YYYY')}
          </div>
          <div className={'d-flex ml-3'}>
            <div>
              {subscription_payment_source?.brand === 'visa' && <IconVisa />}
              {subscription_payment_source?.brand === 'mastercard' && <IconMasterCard />}
            </div>
            <div className={classNames('number ml-2', styles.cardTxt)}>×××× {subscription_payment_source?.last4}</div>
          </div>
        </div>
      </div>
      <img src={DashboardSource} alt={'source'} className={styles.img} />
    </div>
  );
};
