import { SyntheticEvent } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import querystring from 'query-string';

import classNames from 'classnames';
import styles from './filter-list.module.scss';
import { IconCheckMark } from '../../../../components/UI/icons';

import { useQuery } from '../../../../hooks/use-query';
import { LoadState } from '../../../../store';
import { commentsPaths } from '../../routes/CommentRoutes';
import { CoursePreview } from '../course-preview/CoursePreview';

export const FilterList = () => {
  const history = useHistory<{ course_id?: string }>();
  const courseFilter = useQuery();
  const { courses, loadState } = useSelector((state) => state.courses);

  const onActiveClick = (e: SyntheticEvent<HTMLDivElement>) => {
    const id = e.currentTarget.dataset['id'];
    let url = commentsPaths.comments;
    if (id) {
      url = querystring.stringifyUrl({
        url: commentsPaths.comments,
        query: {
          course_id: id
        }
      });
    }

    history.push(url);
  };
  const activeCourseId = courseFilter.get('course_id');
  return (
    <div className={styles.container}>
      <div className={styles.title}>Courses</div>
      <div className={styles.list}>
        {courses.length > 0 && (
          <div className={classNames(styles.all, { [styles.active]: !activeCourseId })} onClick={onActiveClick}>
            All courses <IconCheckMark className={styles.icon} />
          </div>
        )}
        {courses.length > 0 &&
          courses.map((course) => (
            <CoursePreview
              key={course.id}
              id={course.id}
              image={course.settings.thumbnail}
              title={course.title}
              active={activeCourseId === course.id}
              onActive={onActiveClick}
            />
          ))}
        {courses.length === 0 && loadState === LoadState.allIsLoaded && (
          <div className={styles.emptyCourses}>You have no created courses</div>
        )}
      </div>
    </div>
  );
};
