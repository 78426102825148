import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import styles from './contact-delete.module.scss';
import { Icon32UserDeclined } from '../../../../../components/UI/icons';

import { ModalDelete } from '../../../../../components/UI';
import { settingsActions } from '../../../../../store/settings';
import { FieldSchemaSelector } from '../../../field/store/FieldSelector';
import { ContactListActionAsync } from '../../store/ContactListActionAsync';
import { IContact } from '../../store/ContactListState';

interface IProps {
  show: boolean;
  contact: IContact;

  onClose(): void;
}

export const ContactDelete = ({ onClose, show, contact }: IProps) => {
  const dispatch = useDispatch();
  const fieldSchemaMemo = useMemo(FieldSchemaSelector, []);
  const { fieldsSchema } = useSelector((state) => fieldSchemaMemo(state));
  const firstNameId = fieldsSchema.find((x) => x.scope === 'first_name')?.id;
  const lastNameId = fieldsSchema.find((x) => x.scope === 'last_name')?.id;

  const onDeleteContact = () => {
    dispatch(ContactListActionAsync.deleteContact(contact.id));
    dispatch(settingsActions.setPayloadShowing({ isPayloadShow: false }));
    onClose();
  };

  return (
    <ModalDelete
      onClose={onClose}
      isShowed={show}
      icon={<Icon32UserDeclined />}
      iconType={'custom'}
      iconStyles={styles.icon}
      textDelete={'Delete globally'}
      title={
        <>
          <span>
            {`${firstNameId && contact.fields[firstNameId] ? contact.fields[firstNameId] : ''}
            ${lastNameId && contact.fields[lastNameId] ? contact.fields[lastNameId] : ''}`}{' '}
          </span>
          will be deleted globally
        </>
      }
      description={
        <>It means that if this contact was created as a student also, after deletion it will be unenrolled and deleted from your courses.<br/><br/>
        You will not be able to recover his data after confirmation.</>
      }
      onDelete={onDeleteContact}
    />
  );
};
