import * as yup from 'yup';

export const payloadAutomationSchema = yup.object().shape({
  title: yup.string().max(250),
  html: yup.string(),
  note: yup.string().max(2000),
  tag_id: yup.array(yup.string()),
  list_id: yup.array(yup.string()),
  object_id: yup.string(),
  object_type: yup
    .string()
    .nullable()
    .transform((x) => (x === null ? undefined : x)),
  recipient: yup.array(yup.string()).nullable(),
  sender_id: yup.string(),
  reply_to: yup
    .string()
    .email()
    .transform((value: string, originalValue: string) => (originalValue === '' ? null : value))
    .nullable(),
  email_id: yup.string().nullable(),
  action: yup.string(),
  range: yup.object().shape({
    type: yup.string(),
    end: yup.number().when('type', {
      is: 'amount',
      then: yup.number().required()
    }),
    intervals: yup.array().when('type', {
      is: 'range',
      then: yup
        .array()
        .required()
        .min(1)
        .of(
          yup.object().shape({
            start: yup.number(),
            end: yup.number()
          })
        )
    })
  }),
  period: yup.object().shape(
    {
      days: yup
        .number()
        .transform((value: string, originalValue: string) => (originalValue === '' ? 0 : value))
        .when(['hours', 'minutes'], {
          is: (hours: string, minutes: string) => !hours && !minutes,
          then: yup.number()
        }),
      hours: yup
        .number()
        .min(0, 'xin value is 0')
        .max(24, 'max value is 24')
        .transform((value: string, originalValue: string) => (originalValue === '' ? 0 : value)),
      minutes: yup
        .number()
        .min(0, 'xin value is 0')
        .max(60, 'max value is 60')
        .transform((value: string, originalValue: string) => (originalValue === '' ? 0 : value)),
      no_earlier: yup
        .string()
        .nullable()
        .transform((value: string, originalValue: string) => (originalValue === '' ? null : value)),
      no_later: yup
        .string()
        .nullable()
        .transform((value: string, originalValue: string) => (originalValue === '' ? null : value)),
      type: yup.string()
    },
    [
      ['hours', 'minutes'],
      ['minutes', 'days'],
      ['hours', 'days']
    ]
  )
});

export const payloadSchema = yup.object().shape({
  title: yup.string(),
  html: yup.string(),
  note: yup.string(),
  recipient: yup.array(yup.string().email()).nullable(),
  sender: yup.string().email().nullable(),
  reply_to: yup
    .string()
    .email()
    .transform((value: string, originalValue: string) => (originalValue === '' ? null : value))
    .nullable(),
  email_id: yup.string().nullable(),
  automation_id: yup.string(),
  period: yup.object().shape(
    {
      days: yup
        .number()
        .transform((value: string, originalValue: string) => (originalValue === '' ? 0 : value))
        .when(['hours', 'minutes'], {
          is: (hours: string, minutes: string) => !hours && !minutes,
          then: yup.number()
        }),
      hours: yup
        .number()
        .min(0, 'xin value is 0')
        .max(24, 'max value is 24')
        .transform((value: string, originalValue: string) => (originalValue === '' ? 0 : value)),
      minutes: yup
        .number()
        .min(0, 'xin value is 0')
        .max(60, 'max value is 60')
        .transform((value: string, originalValue: string) => (originalValue === '' ? 0 : value)),
      no_earlier: yup
        .string()
        .nullable()
        .transform((value: string, originalValue: string) => (originalValue === '' ? null : value)),
      no_later: yup
        .string()
        .nullable()
        .transform((value: string, originalValue: string) => (originalValue === '' ? null : value)),
      type: yup.string()
    },
    [
      ['hours', 'minutes'],
      ['minutes', 'days'],
      ['hours', 'days']
    ]
  )
});
