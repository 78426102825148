import { memo } from 'react';

import classNames from 'classnames';
import styles from './congratulations.module.scss';
import Hooray from '../../../../../../../assets/images/hooray.png';
import { Icon32Cross } from '../../../../../../components/UI/icons';

import { CommonModal } from '../../../../../../components/UI';
import { PublicLink } from '../public-link/PublicLink';
import { Script } from '../script/Script';

interface IProps {
  onClose: () => void;
  show: boolean;
  offerId: string;
}

export const Congratulations = memo(({ onClose, show, offerId }: IProps) => {
  return (
    <CommonModal onClose={onClose} show={show}>
      <div className={styles.container}>
        <img src={Hooray} alt={'hooray'} className={styles.img} />
        <div className={styles.title}>Your offer is now published</div>
      </div>
      <div className={styles.wrapper}>
        <div className={styles.text}>Public link</div>
        <PublicLink offerId={offerId} />
        <div className={classNames(styles.text, 'mt-4')}>Use this code to add it to your page</div>
        <Script showHeader={false} offerId={offerId} />
      </div>
      <Icon32Cross className={styles.crossIcon} onClick={onClose} />
    </CommonModal>
  );
});
