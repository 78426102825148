import { memo, useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { map } from 'lodash';

import styles from './invite-people.module.scss';

import { CommonModal } from '../../../../components/UI';
import { OrganizationActionAsync } from '../store/OrganizationActionAsync';
import { MembersSelector } from '../store/OrganizationSelector';
import { InvitePeopleHeader } from './header/InvitePeopleHeader';
import { MemberItem } from './memberItem/MemberItem';
import { SelectMember } from './selectMember/SelectMember';

interface IProps {
  show: boolean;
  onClose: () => void;
}

export const InvitePeopleToOrganization = memo(({ onClose, show }: IProps) => {
  const dispatch = useDispatch();
  const membersMemo = useMemo(MembersSelector, []);
  const { owner, other, invitation } = useSelector(membersMemo);

  const getMembers = useCallback(() => {
    dispatch(OrganizationActionAsync.getOrganizationMembers());
  }, [dispatch]);

  useEffect(() => {
    if (show) {
      getMembers();
    }
  }, [getMembers, show]);

  return (
    <CommonModal show={show} onClose={onClose}>
      <InvitePeopleHeader onClose={onClose} />
      <div className={styles.wrapper}>
        <SelectMember getMembers={getMembers} />

        {owner.length > 0 && <MemberItem member={owner[0]} getMembers={getMembers} />}
        {other.length > 0 &&
          map(other, (member, index) => <MemberItem key={index} member={member} getMembers={getMembers} />)}
        {invitation.length > 0 &&
          map(invitation, (member, index) => <MemberItem key={index} member={member} getMembers={getMembers} />)}
      </div>
    </CommonModal>
  );
});
