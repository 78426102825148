import { useRef } from 'react';

import styles from './audio-record-upload.module.scss';
import { Icon32Upload } from '../../UI/icons';

import { ErrorFileSelect } from '../../error-file-select/ErrorFileSelect';
import { FileUpload } from '../../file-upload/upload/file-upload/FileUpload';
import { Button } from '../../UI';

interface IProps {
  onCancelUpload: () => void;
  selectedFile: File | null;
  setFileSelected: (file: File | null) => void;
  setRecord: (url: string) => void;
  setError: (x: boolean) => void;
  error: boolean;
  isRunningRecord: boolean;
  progress: number;
  record: string | null;
  className?: string;
  fileCallback?: (file: File) => void;
}

export const AudioRecordUpload = ({
  selectedFile,
  setFileSelected,
  onCancelUpload,
  setRecord,
  setError,
  isRunningRecord,
  progress,
  record,
  error,
  className,
  fileCallback
}: IProps) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const onClickBrowse = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;

    if (!files || files[0]?.size / 1024 / 1024 >= 20 || !files[0].type.includes('audio')) {
      setError(true);
    } else {
      if (error) {
        setError(false);
      }
      fileCallback?.(files[0]);
      setRecord(window.URL.createObjectURL(files[0]));
      setFileSelected(files[0]);
    }
  };

  const showUploadBtn = !selectedFile && !isRunningRecord && !record;

  if (error) {
    return (
      <div className={className}>
        <ErrorFileSelect
          onOK={() => setError(false)}
          title={'Too large file'}
          description={'The maximum file size that you can upload should not exceed 20 MB'}
        />
      </div>
    );
  }

  return (
    <div>
      {progress > 0 && selectedFile && (
        <div className={className}>
          <FileUpload
            fileSize={selectedFile.size / 1024 / 1024}
            onCancelUpload={onCancelUpload}
            progress={progress}
            className={styles.progressContainer}
          />
        </div>
      )}
      {showUploadBtn && (
        <>
          <Button
            onClick={onClickBrowse}
            size={'lg'}
            className={'w-100 mt-1'}
            btnStyle={'secondary'}
            customIcon={<Icon32Upload />}
          >
            Browse mp3 file: max. 20 MB
          </Button>
          <input
            accept={'audio/*'}
            ref={inputRef}
            hidden
            type="file"
            multiple={false}
            className={styles.inputFile}
            id="inputFile"
            onChange={handleFileChange}
          />
        </>
      )}
    </div>
  );
};
