import { reducerWithInitialState } from 'typescript-fsa-reducers';

import { LoadState, newState } from '../../../../store';
import { IProductListState, ProductListInitialState } from './ProductListState';
import { ProductsActions } from './ProductsActions';

export const commonFirstLoadHandler = <T>(state: T) => ({ ...state, loadState: LoadState.firstLoad, error: null });
export const commonErrorHandler = <T>(state: T, { error }: { error: Error }) => ({
  ...state,
  loadState: LoadState.error,
  error
});

export const productsConfigReducer = reducerWithInitialState<IProductListState>(ProductListInitialState)
  .case(ProductsActions.getProductsConfig.started, commonFirstLoadHandler)
  .case(ProductsActions.getProductsConfig.done, (state, { result, params }) => {
    return newState(state, {
      productList: params.type === 'regular' ? result : state.productList,
      courseList: params.type === 'course' ? result : state.courseList,
      loadState: LoadState.allIsLoaded,
      error: null
    });
  })
  .case(ProductsActions.getProductsConfig.failed, commonErrorHandler);
