import styles from './paymentState.module.scss';

import { StripeAccount } from '../../general/store/GeneralSettingsState';

interface IProps extends Pick<StripeAccount, 'display_name'> {
  className: string;
  statusText: string;
}

export const PaymentStatus = ({ display_name, className, statusText }: IProps) => {
  return (
    <div className={styles.container}>
      <div className={className}>{statusText}</div>
      <div>
        <div className={styles.text}>{display_name}</div>
        <div></div>
      </div>
    </div>
  );
};
