import { IRoutes } from '../../../interfaces';
import { SchoolHeaderPanel } from '../../../layout/header/schoolHeaderPanel/SchoolHeaderPanel';
import { CommentModeration } from '../comment-moderation/CommentModeration';

export const commentsPaths = {
  comments: '/comments'
};

export const CommentsRoutes: IRoutes[] = [
  {
    path: commentsPaths.comments,
    main: () => <CommentModeration />,
    header: () => <SchoolHeaderPanel />
  }
];
