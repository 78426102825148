import styles from './mobile-restrict-dialog.module.scss';

import { Button, CommonModal } from '../UI';

interface IProps {
  show: boolean;

  onClose(): void;
}

export const MobileRestrictDialog = ({ show, onClose }: IProps) => {
  return (
    <CommonModal onClose={onClose} show={show} params={{ className: styles.modal }}>
      <div className={styles.container}>
        <div className={styles.image} />
        <div className={styles.title}>This is a desktop application</div>
        <div className={styles.text}>
          This application is made for desktop devices. Trying to use it on a mobile device may ruin your experience, so
          please log back in from your <br /> desktop device.
        </div>
        <div className={styles.buttons}>
          <Button onClick={onClose} withIcon={false} size={'lg'} className={'mb-2'}>
            Continue anyway
          </Button>
        </div>
      </div>
    </CommonModal>
  );
};
