import { IFile, IMeta } from '../../../../interfaces';
import { LoadState } from '../../../../store';
import { IProduct } from '../../products/store/ProductsState';

export type PaymentMethod = 'free' | 'one_time' | 'recurring' | 'split';

export interface IRedirectCheckout {
  in_same_tab: boolean;
  url: string;
}

export type OfferPaymentMethod = 'stripe' | 'paynet' | 'paypal';

export enum OfferState {
  published = 'published',
  draft = 'draft',
  archived = 'archived',
  deleted = 'deleted',
  all = 'all'
}

export type OfferStatus = ''; //TODO: need error statuses

export type OfferStatusResponse = { payload: null; status: OfferStatus };

export interface IPrice {
  type: PaymentMethod;
  price?: number;
  old_price?: number;
  payment_method: OfferPaymentMethod | null;
}

export type IOfferState = keyof typeof OfferState;

export interface IOffer {
  id: string;
  organization_id?: string;
  state: IOfferState;
  title: string;
  description?: string | null;
  products: IProduct[];
  setting_call_to_action: string;
  price: IPrice | null;
  order_bump_checkbox_text?: string;
  order_bump_description?: string | null;
  order_bump_headline?: string | null;
  order_bump_is_active?: boolean;
  order_bump_old_price?: number | null;
  order_bump_price?: number | null;
  order_bump_products?: IProduct[];
  setting_price_display_overwrite?: string | null;
  action_add_to_list: string[] | null;
  action_email_after_purchase: string | null;
  action_email_after_purchase_sender_id: string | null;
  action_enroll_in_courses: string[] | null;
  action_redirect_after_checkout: IRedirectCheckout | null;
  action_send_receipt_after_purchase: boolean;
  setting_enable_shipping: boolean;
  settings_background_color: string | null;
  settings_background_image: IFile | null;
  settings_hide_branding: boolean;
}

export interface IOffersState extends IMeta {
  loadState: LoadState;
  error: Error | null;
  offers: IOffer[];
  offerProducts: IProduct[];
}

export const OffersInitialState: IOffersState = {
  loadState: LoadState.needLoad,
  error: null,
  offers: [],
  offerProducts: [],
  page: 1,
  page_size: 15,
  total: 0,
  total_pages: 1
};
