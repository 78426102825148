import { CustomDispatch } from 'redux';

import { actionEmail, EmailAction } from '../../../API/action-email-api';
import {
  confirmEmail,
  editPassword,
  googleAuthUrl,
  login,
  recoverPassword,
  registerByEmail
} from '../../../API/auth-api';
import {
  ChangePasswordResponse,
  ConfirmEmailRequest,
  IActionHash,
  IChangePassword,
  IError,
  ITokenRequest,
  RegisterUserRequest,
  RegisterUserResponse,
  ResetPasswordRequest,
  ResetPasswordResponse
} from '../../../interfaces';
import { AuthActions } from './AuthActions';

export const AuthActionAsync = {
  getGoogleAuth:
    (state: string | null, redirect_url: string, onSuccess?: (url: string) => void, onError?: () => void) =>
    async (dispatch: CustomDispatch) => {
      try {
        dispatch(AuthActions.getGoogleUrl.started({ state, redirect_url }));

        const response = await googleAuthUrl(state, redirect_url);
        if (response.data) {
          dispatch(
            AuthActions.getGoogleUrl.done({
              result: { url: response.data.url },
              params: { state, redirect_url }
            })
          );
          onSuccess?.(response.data.url);
        }
      } catch (error) {
        onError?.();
        dispatch(AuthActions.getGoogleUrl.failed(error));
      }
    },

  auth:
    (request: ITokenRequest, onError?: (error: IError) => void, onSuccess?: (response?: string) => void) =>
    async (dispatch: CustomDispatch) => {
      try {
        dispatch(AuthActions.auth.started({ request }));
        const response = await login(request);
        const token = response.data?.token;
        dispatch(AuthActions.auth.done({ result: token, params: { request } }));
        onSuccess?.(token);
        return token ? token : null;
      } catch (error) {
        onError?.(error);
        dispatch(AuthActions.auth.failed(error));
      }
    },
  registerUser:
    (params: RegisterUserRequest, callback?: (data: RegisterUserResponse) => void, onError?: (error: IError) => void) =>
    async (dispatch: CustomDispatch) => {
      try {
        dispatch(AuthActions.registerUser.started(params));
        const response = await registerByEmail(params);
        if (response.data) {
          dispatch(AuthActions.registerUser.done({ result: response.data, params }));
          callback?.(response.data);
        }
      } catch (error: any) {
        onError?.(error);
        dispatch(AuthActions.registerUser.failed(error));
      }
    },
  confirmEmailByCode:
    (actionHash: string, code: string, callback?: (data: EmailAction) => void, onError?: (error: IError) => void) =>
    async (dispatch: CustomDispatch) => {
      try {
        dispatch(AuthActions.confirmEmailByCode.started({ actionHash, code }));
        const response = await actionEmail(actionHash, { code: code });
        if (response.data) {
          dispatch(AuthActions.confirmEmailByCode.done({ result: response.data, params: { actionHash, code } }));
          callback?.(response.data);
        }
      } catch (error: any) {
        dispatch(AuthActions.confirmEmailByCode.failed({ error, params: { actionHash, code } }));
        onError?.(error);
      }
    },
  resendConfirmEmail:
    (request: ConfirmEmailRequest, callback?: (data: IActionHash) => void, onError?: (error: IError) => void) =>
    async (dispatch: CustomDispatch) => {
      try {
        dispatch(AuthActions.resendConfirmEmail.started(request));
        const response = await confirmEmail(request);
        if (response.data) {
          dispatch(AuthActions.resendConfirmEmail.done({ result: response.data, params: request }));
          callback?.(response.data);
        }
      } catch (error: any) {
        dispatch(AuthActions.resendConfirmEmail.failed({ error, params: request }));
        onError?.(error);
      }
    },
  forgotEmail:
    (request: ResetPasswordRequest, onSuccess?: () => void, onError?: (error: IError) => void) =>
    async (dispatch: CustomDispatch) => {
      try {
        dispatch(AuthActions.forgotEmail.started(request));
        await recoverPassword(request);
        dispatch(AuthActions.forgotEmail.done({ params: request }));
        onSuccess?.();
      } catch (error: any) {
        onError?.(error);
        dispatch(AuthActions.forgotEmail.failed(error));
      }
    },
  congratulationEmail:
    (actionHash: string, callback?: (data: EmailAction) => void, onError?: (error: IError) => void) =>
    async (dispatch: CustomDispatch) => {
      try {
        dispatch(AuthActions.congratulationEmail.started({ actionHash }));
        const response = await actionEmail(actionHash);
        if (response.data) {
          dispatch(AuthActions.congratulationEmail.done({ params: { actionHash }, result: response.data }));
          callback?.(response.data);
        }
      } catch (error: any) {
        onError?.(error);
        dispatch(AuthActions.congratulationEmail.failed({ error, params: { actionHash } }));
      }
    },
  resetPassword:
    (
      actionHash: string,
      password: string,
      onSuccess: () => void,
      onError: (error: IError<ResetPasswordResponse>) => void
    ) =>
    async (dispatch: CustomDispatch) => {
      try {
        dispatch(AuthActions.resetPassword.started({ actionHash, password }));
        const response = await actionEmail(actionHash, { password: password });
        if (response.data) {
          onSuccess();
          dispatch(AuthActions.resetPassword.done({ params: { actionHash, password }, result: response.data }));
        }
      } catch (error) {
        onError(error);
        dispatch(AuthActions.resetPassword.failed({ error, params: { actionHash, password } }));
      }
    },
  changePassword:
    (passwords: IChangePassword, onSuccess: () => void, onError: (error: IError<ChangePasswordResponse>) => void) =>
    async (dispatch: CustomDispatch) => {
      try {
        dispatch(AuthActions.changePassword.started(passwords));
        const response = await editPassword(passwords);
        if (response.data) {
          onSuccess();
          dispatch(AuthActions.changePassword.done({ params: passwords, result: response.data }));
        }
      } catch (error) {
        onError(error);
        dispatch(AuthActions.changePassword.failed({ error, params: passwords }));
      }
    }
};
