import { FormControl } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';

import classNames from 'classnames';
import commonOfferStyles from '../../common/commonOffer.module.scss';
import styles from '../products.module.scss';

import { setEmptyOrStr } from '../../../../../../helpers/text';
import { InputLabel } from '../../common/input-label/InputLabel';

export const ProductInput = () => {
  const {
    register,
    formState: { errors }
  } = useFormContext();
  return (
    <>
      <InputLabel label={'Call to action'}>
        <FormControl
          {...register('setting_call_to_action', { setValueAs: setEmptyOrStr })}
          className={classNames('form-control form-control-md', styles.action)}
          maxLength={128}
        />
      </InputLabel>
      {errors.setting_call_to_action && (
        <div className={commonOfferStyles.errorTitle}>{errors.setting_call_to_action?.message}</div>
      )}
    </>
  );
};
