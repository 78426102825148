import InnerHTML from 'dangerously-set-html-content';

import styles from './attachment-blocks.module.scss';

import { AttachmentType, IAttachment } from '../../../../../../../interfaces/quiz';
import { AttachmentAudio } from './attachment-audio/AttachmentAudio';
import { AttachmentRemove } from './attachment-remove/AttachmentRemove';
import { AttachmentVideo } from './attachment-video/AttachmentVideo';

interface IProps {
  attachmentType: AttachmentType | 'video' | null;
  onSetAttachment?: (data: Partial<IAttachment> | null, attachmentType: AttachmentType | 'video' | null) => void;
  questionAttachment?: string;
  data: IAttachment;
}

export const AttachmentBlocks = ({ attachmentType, questionAttachment, data, onSetAttachment }: IProps) => {
  const isVideo = attachmentType === 'video' || (data?.url && !data.url?.includes('.mp3')) || data?.video_url;
  const isAudio = attachmentType === 'audio' || data?.url?.includes('.mp3') || data?.audio_url?.includes('.mp3');

  return (
    <div className={styles.attach}>
      {isVideo && (
        <AttachmentRemove onSetAttachment={onSetAttachment} title="Video">
          <AttachmentVideo quizQuestionAttachment={questionAttachment} video={data} onSetAttachment={onSetAttachment} />
        </AttachmentRemove>
      )}
      {isAudio && (
        <AttachmentRemove onSetAttachment={onSetAttachment} title="Audio">
          <AttachmentAudio quizQuestionAttachment={questionAttachment} audio={data} onSetAttachment={onSetAttachment} />
        </AttachmentRemove>
      )}
      {data?.code && <InnerHTML html={data.code} />}
      {/* {attachmentType === 'image' && (
        <AttachmentRemove onSetAttachment={onSetAttachment} title="Image">
          <AttachmentImage quizQuestionAttachment={questionAttachment} image={blockWatch} />
        </AttachmentRemove>
      )}
      {attachmentType === 'file' && (
        <AttachmentRemove onSetAttachment={onSetAttachment} title="Attachments">
          <AttachmentFile quizQuestionAttachment={questionAttachment} file={blockWatch} />
        </AttachmentRemove>
      )} */}
    </div>
  );
};
