import { useState } from 'react';
import { Collapse } from 'react-bootstrap';

import classNames from 'classnames';
import styles from './domain-cname.module.scss';
import { Icon16ChevronDown, Icon16ChevronUp } from '../../../../../../../components/UI/icons';

import { IDomainCompleteStep } from '../CompleteSteps';
import { DomainCnameItem } from './domain-cname-item/DomainCnameItem';

interface IProps extends Required<Pick<IDomainCompleteStep, 'cname'>>, Pick<IDomainCompleteStep, 'trouble'> {
  className?: string;
}

export const DomainCname = ({ cname, trouble, className }: IProps) => {
  const [troubleExpend, setTroubleExpend] = useState(false);
  const [copeText, setCopyText] = useState('');
  const onCopy = (text: string) => {
    setCopyText(text);
  };

  const onTroubleToggle = () => setTroubleExpend(!troubleExpend);

  return (
    <>
      <div className={classNames(styles.container, className)}>
        <div className={styles.content}>
          <div>
            <DomainCnameItem label={'Type'} value={cname.type} isCopy={false} onCopy={onCopy} textCopied={copeText} />
            <DomainCnameItem
              label={'TTL'}
              value={cname.TTL}
              isCopy={false}
              className={'mt-3'}
              onCopy={onCopy}
              textCopied={copeText}
            />
          </div>
          <div>
            <DomainCnameItem
              label={'Name/Host'}
              value={cname['Name/Host']}
              isCopy={true}
              onCopy={onCopy}
              textCopied={copeText}
            />
            <DomainCnameItem
              label={'Target/Value'}
              value={cname['Target/Value']}
              isCopy={true}
              className={'mt-3'}
              onCopy={onCopy}
              textCopied={copeText}
            />
          </div>
        </div>
      </div>
      {trouble && (
        <div
          onClick={onTroubleToggle}
          className={styles.trouble}
          aria-controls="collapse-text"
          aria-expanded={troubleExpend}
        >
          Having trouble {troubleExpend ? <Icon16ChevronUp /> : <Icon16ChevronDown />}{' '}
        </div>
      )}
      <Collapse in={troubleExpend}>
        <div id="collapse-text">
          <ul className={styles.troubleText}>
            {trouble?.map((trouble, index) => (
              <li key={index}>{trouble}</li>
            ))}
          </ul>
        </div>
      </Collapse>
    </>
  );
};
