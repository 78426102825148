import { NavLink } from 'react-router-dom';

import classNames from 'classnames';
import styles from '../../../../../../styles/components/navbar.module.scss';

import { CRMPaths } from '../../../routes/CRMRoutes';

export const ProductListPanel = () => {
  // const { paynet, stripe } = useSelector((state) => state.general.paymentAccount);
  // const [defaultPayment, setDefaultPayment] = useState<string | null>('Paynet');
  // const [showPaymentDialog, setShowPaymentDialog] = useState(false);
  const navigation = [
    { title: 'Offers', path: CRMPaths.crmOffers },
    { title: 'Products', path: CRMPaths.crmProducts },
    { title: 'Courses', path: CRMPaths.crmCourses },
    { title: 'Payments', path: CRMPaths.payments }
  ];

  /*const onToggleDialog = () => {
    setShowPaymentDialog((x) => !x);
  };*/

  return (
    <div className={classNames('navbar navbar-expand justify-content-end', styles.navbar)}>
      <ul className={classNames('navbar-nav mx-auto', styles['navbar-nav'])}>
        {navigation.map((item, index) => (
          <li key={index} className={classNames('nav-item', styles.item)}>
            <NavLink className={classNames('nav-link', styles.link)} to={item.path} activeClassName={styles.active}>
              {item.title}
            </NavLink>
          </li>
        ))}
      </ul>
      {/*<ChoosePaymentSystem showPaymentDialog={showPaymentDialog} setPaymentDialogShow={setShowPaymentDialog}>
        <Button
          onClick={onToggleDialog}
          customIcon={showPaymentDialog ? <IconChevronUp /> : <IconChevronDown />}
          iconPosition={'right'}
          btnStyle={'secondary'}
        >
          Payments
        </Button>
      </ChoosePaymentSystem>*/}
    </div>
  );
};
