import { createSelector } from 'reselect';

import { IAppState, LoadState } from '../../../../store';

export const BillingPermissionSelector = () =>
  createSelector(
    (state: IAppState) => state.billing.subscription,
    (state: IAppState) => state.billing.loadState,
    (state: IAppState) => state.billing.error,
    (subscription, loadState, error) => {
      return {
        isBlockAccount:
          subscription === null ||
          subscription?.subscription_status === 'past_due' ||
          subscription?.subscription_status === 'canceled' ||
          subscription?.subscription_status === 'unpaid',
        subscription: subscription,
        loadState,
        isLoading: loadState === LoadState.allIsLoaded || loadState === LoadState.error,
        error: error
      };
    }
  );
