import { FC } from 'react';
import { Col } from 'react-bootstrap';

import classNames from 'classnames';
import styles from './main-auth.module.scss';
import backgroundImage from '../../../../../assets/svg/block-login.svg';
import logo from '../../../../../assets/svg/logo.svg';

export const MainAuthComponent: FC = ({ children }) => {
  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <Col sm={6} md={5} lg={4} className={styles.left}>
          <div className={styles.logo}>
            <img src={logo} alt={'everact'} />
          </div>
          {children}
          <div className={classNames(styles.copy, 'd-flex justify-content-between')}>
            <div>© {new Date().getFullYear()} everact.io</div>
            <a href={'https://everact.io/policies/terms'} target={'_blank'} rel="noreferrer" className={'link'}>Terms and Conditions</a>
          </div>
        </Col>
        <Col className={classNames(styles.imageContainer)}>
          <img className={styles.image} src={backgroundImage} alt={'everact'} />
        </Col>
      </div>
    </div>
  );
};
