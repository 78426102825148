import { memo, useCallback, useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable, DropResult, ResponderProvided } from 'react-beautiful-dnd';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Prompt } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import { differenceWith, isEqual, maxBy, omit } from 'lodash';

import styles from './lesson.module.scss';

import { ActionsPanel } from '../../../../../components/UI';
import { getUUID } from '../../../../../helpers';
import { BlockType } from '../../../../../interfaces';
import { LoadState } from '../../../../../store';
import { ValidationData } from '../../../../crm/offers/create-offer/common/ValidationData';
import { useLesson } from '../../../hooks/use-lesson';
import { useCourseContext } from '../../common/sidebar/CourseContext';
import { courseActionAsync } from '../../store/CourseActionAsync';
import { Blocks } from './blocks/Blocks';
import { LessonFieldNames, LessonFormValues } from './LessonBlocks';
import { useLessonContext } from './LessonContext';
import { LessonFooter } from './LessonFooter';

export interface CustomCodeForm {
  code: string | null | undefined;
  enabled: boolean | undefined;
}

interface IProps {
  stepId: string;
  getCourseBlock: () => void;
  getLecture: () => void;
}

const Lesson = (props: IProps) => {
  const dispatch = useDispatch();
  const { isDelete } = useLessonContext();
  const { loadState } = useSelector((state) => state.courses);
  const [loading, setLoading] = useState(false);
  const { nodeId, courseId } = useCourseContext();
  const { formState, setValue, reset, handleSubmit, control, getValues } = useFormContext<LessonFormValues>();
  const { customCodeInitial, initialData } = useLesson();

  // console.log('%c⇒ formState', 'color: #89DDF7', formState);

  const {
    fields: blocks,
    append,
    move,
    remove
    // @ts-ignore
  } = useFieldArray<LessonFormValues, LessonFieldNames.courseBlock, 'key'>({
    control: control,
    name: LessonFieldNames.courseBlock,
    keyName: 'key'
  });

  const handleSubmitForm = useCallback(
    (data: LessonFormValues) => {
      const { courseBlock, customCode } = data;
      const editData = courseBlock?.map((block, index) => {
        return { ...omit(blocks[index], 'key'), ...block, order: index };
      });
      const diffData = differenceWith(editData, initialData, isEqual);
      const editBlocks = diffData.filter((x) => initialData.find((y) => y.id === x.id));
      const addData = editData?.filter((editItem) => editItem?.id?.includes('generated'));
      const deleteData = initialData.filter((x) => !editData?.find((y) => y.id === x.id));
      const dataAvailable = (addData && addData?.length > 0) || editBlocks.length > 0 || deleteData.length > 0;
      if (dataAvailable && courseId) {
        setLoading(true);
        dispatch(
          courseActionAsync.cudCourseBlock(
            courseId,
            props.stepId,
            { add: addData || [], delete: deleteData, edit: editBlocks },
            () => {
              props.getCourseBlock();
            }
          )
        );
      }
      if (!ValidationData(customCode, customCodeInitial) && props?.stepId && courseId) {
        setLoading(true);
        dispatch(
          courseActionAsync.editNode(courseId, props?.stepId, {
            setting_custom_code_enabled: data.customCode.enabled,
            setting_custom_code: data.customCode.code
          })
        );
      }
    },
    [initialData, courseId, customCodeInitial, props, blocks, dispatch]
  );

  const onCreateBlock = useCallback(
    (value: BlockType) => {
      const maxOrder = maxBy(blocks, 'order')?.order;
      append({
        id: getUUID() + '-generated',
        title: '',
        type: value,
        order: maxOrder !== undefined ? maxOrder + 1 : 0,
        is_hidden: false
      });
    },
    [append, blocks]
  );

  const onCancel = useCallback(() => {
    try {
      setValue(LessonFieldNames.courseBlock, initialData);
      setValue(LessonFieldNames.customCode, customCodeInitial);
    } catch (error) {
      console.log(error);
    } finally {
      reset({ courseBlock: initialData, customCode: customCodeInitial });
    }
    // @ts-ignore
  }, [customCodeInitial, initialData, reset, setValue]);

  const removeBlock = useCallback(
    (index: number) => {
      remove(index);
    },
    [remove]
  );

  const showActionsPanel =
    !!formState.dirtyFields?.[LessonFieldNames.courseBlock]?.length ||
    formState.dirtyFields.customCode?.code ||
    formState.dirtyFields.customCode?.enabled;

  const showUpdate = Object.keys(formState.dirtyFields).length > 0;

  useEffect(() => {
    // need for useFieldArray to work
    if (loadState === LoadState.allIsLoaded) {
      if (!showUpdate || isDelete || props.stepId === nodeId || initialData.length === 0) {
        setLoading(false);
        reset({ courseBlock: initialData, customCode: customCodeInitial });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialData, customCodeInitial, loadState]);

  const handleDrag = (result: DropResult, _provided: ResponderProvided) => {
    if (result.destination) {
      move(result.source.index, result.destination.index);
    }

    const isInitialEqual = ValidationData(initialData, getValues(`${LessonFieldNames.courseBlock}`));
    const isInitialEqualCode = ValidationData(customCodeInitial, getValues(`${LessonFieldNames.customCode}`));

    if (isInitialEqual && isInitialEqualCode) {
      reset(getValues());
    }
  };

  const onInvalid = (error: any) => {
    console.log('%c⇒ error', 'color: #FF5370', error);
  };

  return (
    <>
      <Prompt
        when={!isDelete && showUpdate}
        message={JSON.stringify({
          title: 'You have unsaved changes',
          messageText: 'All changes will be deleted unless you save them',
          confirmText: 'Continue editing',
          cancelText: 'Continue without saving'
        })}
      />
      {blocks.length > 0 && (
        <div className={styles.wrapperContent}>
          <DragDropContext onDragEnd={handleDrag}>
            <Droppable droppableId="courseBlock-items">
              {(provided) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {blocks.map((_item, index) => (
                    <Draggable
                      key={`${LessonFieldNames.courseBlock}.${index}`}
                      draggableId={`courseBlock-${index}`}
                      index={index}
                    >
                      {(provided) => <Blocks index={index} provided={provided} removeBlock={removeBlock} />}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      )}
      <LessonFooter initialEnable={customCodeInitial.enabled || false} onCreateBlock={onCreateBlock} />
      <CSSTransition in={showActionsPanel} timeout={300} classNames={styles} unmountOnExit>
        <ActionsPanel
          onCancel={onCancel}
          onSave={handleSubmit(handleSubmitForm, onInvalid)}
          loading={loadState === LoadState.firstLoad || formState.isSubmitting || formState.isValidating || loading}
        />
      </CSSTransition>
    </>
  );
};

export default memo(Lesson, lessonPropsAreEqual);

function lessonPropsAreEqual(prevLesson: IProps, nextLesson: IProps) {
  return prevLesson.stepId === nextLesson.stepId;
}
