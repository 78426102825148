import React, { RefObject, useCallback, useEffect, useRef } from 'react';
import EmailEditor from 'react-email-editor';
import { useDispatch } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { pick } from 'lodash';

import classNames from 'classnames';
import './page-builder.scss';
import styles from './page-builder.module.scss';

import { useNodePayload } from '../../../hooks/use-node-payload';
import { PaletteElementTypes } from '../../../interfaces';
import { builderActionAsync } from '../builder/store/BuilderActionAsync';
import { pageBuilderPaths } from './routes/PageBuilderRoutes';

export const PageBuilder = () => {
  const dispatch = useDispatch();
  const path = useRouteMatch<{ funnelId: string; id: string }>(pageBuilderPaths.pageBuilder());
  const funnelId = path?.params.funnelId;
  const nodeId = path?.params.id;
  // const node = useSelector((state) => state.builder.builder.nodes.find((x) => x.id === nodeId));
  const node = useNodePayload<
    | PaletteElementTypes.PAGES_CALENDAR
    | PaletteElementTypes.PAGES_CHECKOUT
    | PaletteElementTypes.PAGES_CONFIRMATION
    | PaletteElementTypes.PAGES_CONFIRMATION
    | PaletteElementTypes.PAGES_CROSS_SELL
    | PaletteElementTypes.PAGES_DOUBLE_OPT_IN
    | PaletteElementTypes.PAGES_DOWNLOAD
    | PaletteElementTypes.PAGES_DOWN_SELL
    | PaletteElementTypes.PAGES_EXTERNAL
    | PaletteElementTypes.PAGES_LANDING
    | PaletteElementTypes.PAGES_MEMBERS_AREA
    | PaletteElementTypes.PAGES_OPT_IN
    | PaletteElementTypes.PAGES_POP_UP
    | PaletteElementTypes.PAGES_PRODUCT
    | PaletteElementTypes.PAGES_SALES
    | PaletteElementTypes.PAGES_SALES_VIDEO
    | PaletteElementTypes.PAGES_SURVEY
    | PaletteElementTypes.PAGES_UP_SELL
    | PaletteElementTypes.PAGES_WEBINAR
    | PaletteElementTypes.PAGES_WEBINAR_REPLY
  >(nodeId || '');
  const emailEditorRef = useRef<{ editor: EmailEditor } | null>(null);
  // const [designData, setDesignData] = useState<{}>({ test: 'true' });
  const designData = useRef<{
    json_: any;
    title?: string | undefined;
    note?: string | undefined;
    html?: string | null | undefined;
  } | null>(null);

  const callbackDone = useCallback(() => {
    if (funnelId && nodeId) {
      dispatch(builderActionAsync.getEndpoints(funnelId, nodeId));
    }
  }, [dispatch, funnelId, nodeId]);

  const onSave = useCallback(() => {
    const iframe = document.querySelector('iframe');
    if (emailEditorRef.current) {
      emailEditorRef.current.editor.exportHtml((data: any) => {
        const { design } = data;
        const requestData = {
          ...node?.payload,
          json_: design
          // html
        };
        delete requestData['html'];
        designData.current = requestData;
        // setDesignData(requestData);
        if (iframe && iframe.contentWindow) {
          iframe.contentWindow.postMessage(JSON.stringify({ ...requestData }), '*');
        }
        // console.log('%c⇒ designData', 'color: #89DDF7', designData.current);
        if (funnelId && nodeId) {
          //@ts-ignore
          dispatch(builderActionAsync.saveNode(funnelId, nodeId, requestData, 'funnel', callbackDone));
        }
      });
    }
  }, [callbackDone, dispatch, funnelId, node?.payload, nodeId]);

  useEffect(() => {
    emailEditorRef.current?.editor?.addEventListener('design:updated', (data) => {
      onSave();
    });
  }, [onSave]);

  // useEffect(() => {
  //   // emailEditorRef.current?.editor?.addEventListener('design:loaded', () => {
  //   const node = nodes.find((node) => node.id === nodeId);

  //   if (node && !isLoad) {
  //     const templateJson = node.payload.json;
  //     console.log(emailEditorRef.current);
  //     if (emailEditorRef.current) {
  //       emailEditorRef.current?.editor?.loadDesign(templateJson);

  //       window.addEventListener(
  //         'message',
  //         (event) => {
  //           // Do we trust the sender of this message?  (might be
  //           // different from what we originally opened, for example).
  //           if (event.origin !== 'http://example.com') return;
  //           console.log(event);

  //           // event.source is popup
  //           // event.data is "hi there yourself!  the secret response is: rheeeeet!"
  //         },
  //         false
  //       );
  //     }
  //     setIsload(true);
  //   }
  //   // });
  // }, [isLoad, nodeId, nodes]);

  const onDesignLoad = (data: any) => {};

  const onLoad = useCallback(() => {
    emailEditorRef.current?.editor.addEventListener('onDesignLoad', onDesignLoad);
  }, []);

  useEffect(() => {
    if (node) {
      const templateJson: any = node.payload.json_;

      if (emailEditorRef.current?.editor) {
        emailEditorRef.current.editor.loadDesign(templateJson);
      } else {
        setTimeout(() => emailEditorRef.current?.editor.loadDesign(templateJson), 3000);
      }
    }
  }, [node]);

  useEffect(() => {
    if (!node && funnelId && nodeId) {
      dispatch(builderActionAsync.getNode(funnelId, nodeId, 'funnel'));
    }
  }, [dispatch, funnelId, node, nodeId]);

  // console.log(pick(node?.endpoints, keys('title')));
  const endpoints =
    node?.endpoints &&
    JSON.stringify(
      node?.endpoints
        // eslint-disable-next-line array-callback-return
        ?.map((x) => {
          const current = pick(x, ['title', 'id']);
          if (current.title && current.id) {
            return { label: current.title, value: current.id };
          }
        })
        .filter((x) => x)
    );
  console.log(endpoints);

  // TODO: fix route after returned to funnel
  return (
    <div className={styles.pageBuilder}>
      <button onClick={onSave} className={classNames('btn btn-gradient-primary', styles.save)}>
        SAVE
      </button>
      {node?.endpoints && (
        <EmailEditor
          ref={emailEditorRef as RefObject<EmailEditor>}
          projectId={20881}
          onLoad={onLoad}
          options={{
            customCSS: [window.location.origin + '/builder/custom.css'],
            customJS: [
              window.location.origin + '/builder/custom.js',
              `
            const container = document.querySelector('.actions-container > div');
            const a = document.createElement('a');
            const link = document.createTextNode('Return to funnel');
            a.appendChild(link);
            a.title = 'This is Link';
            a.classList.add('return-link');
            a.addEventListener('click', () => window.history.back())
            // a.href = '${window.location.origin}/funnel/' + ${path?.params.funnelId};
            container?.prepend(a);
            `,
              `
            const btn = document.createElement('button');
            const btnElement = document.createTextNode('Save changes');
            btn.appendChild(btnElement);
            btn.title = 'This is Link';
            btn.classList.add('btnSave');
            btn.addEventListener('click', () => {
              console.log('%c⇒ designData', 'color: #89DDF7', ${JSON.stringify(designData.current)});
            })
            container?.prepend(btn);
            `,
              `unlayer.setLinkTypes([
                {
                  name: 'endpoint_link_type',
                  label: 'As Endpoint',
                  attrs: {
                    href: 'https://client.dev.wisent.work/{{endpoint_key}}/next',
                    target: '_blank'
                  },
                  fields: [${
                    endpoints !== '[]'
                      ? `{
                    name: 'endpoint_key',
                    label: 'Endpoints',
                    defaultValue: '[]',
                    inputType: null,
                    placeholderText: null,
                    options: ${endpoints},
                  },`
                      : ''
                  }
                    
                  ],
                }
            ]);`
            ],
            displayMode: 'web',
            appearance: {
              panels: {
                tools: {
                  dock: 'left'
                }
              }
            },
            mergeTags: {
              first_name: {
                name: 'Product Name',
                value: 'test name'
              },
              price: {
                name: 'Price',
                value: 'test price'
              },
              products: {
                name: 'Products',
                rules: {
                  repeat: {
                    name: 'Repeat for Each Product',
                    before: '{{#products}}',
                    after: '{{/products}}'
                  }
                }
              }
            } as any
          }}
          style={{ height: 'calc(100vh)' }}
        />
      )}
    </div>
  );
};
