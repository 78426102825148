import { IRoutes } from '../../../interfaces';
import { SettingHeaderPanel } from '../../../layout/header/settingHeaderPanel/SettingHeaderPanel';
import { Account } from '../account/Account';
import { Billing } from '../billing/Billing';
import { Customization } from '../customization/Customization';
import { Domain } from '../domain/Domain';
import { General } from '../general/General';
import { Integrations } from '../integrations/Integrations';
import { Legal } from '../legal/Legal';
import { Settings } from '../main/Settings';
import { Notification } from '../notification/Notifications';
import { PaymentIntegration } from '../payment/PaymentIntegration';
import { Permission } from '../permission/Permission';
import { Tax } from '../tax/Tax';

export const SettingsPaths = {
  settings: '/settings',
  account: '/account',
  permissions: '/permissions',
  domain: '/domain',
  general: '/general',
  notifications: '/notifications',
  taxes: '/taxes',
  legal: '/legal',
  billing: '/billing',
  integrations: '/integrations',
  payment: '/payment',
  customization: '/customization',
  zapier: (id = ':id') => `/integrations/${id}`
};

export const SettingsRoutes: IRoutes[] = [
  {
    path: SettingsPaths.settings,
    exact: true,
    main: () => (
      <div className={'content'}>
        <Settings />
      </div>
    )
  },
  {
    path: SettingsPaths.general,
    exact: true,
    main: () => (
      <div className={'content pb-0'}>
        <General />
      </div>
    ),
    header: () => <SettingHeaderPanel />
  },
  {
    path: SettingsPaths.notifications,
    exact: true,
    main: () => (
      <div className={'content'}>
        <Notification />
      </div>
    ),
    header: () => <SettingHeaderPanel />
  },
  {
    path: SettingsPaths.permissions,
    exact: true,
    main: () => (
      <div className={'content'}>
        <Permission />
      </div>
    ),
    header: () => <SettingHeaderPanel />
  },
  {
    path: SettingsPaths.taxes,
    exact: true,
    main: () => (
      <div className={'content'}>
        <Tax />
      </div>
    ),
    header: () => <SettingHeaderPanel />
  },
  {
    path: SettingsPaths.legal,
    exact: true,
    main: () => (
      <div className={'content'}>
        <Legal />
      </div>
    ),
    header: () => <SettingHeaderPanel />
  },
  {
    path: SettingsPaths.billing,
    exact: true,
    main: () => (
      <div className={'content'}>
        <Billing />
      </div>
    ),
    header: () => <SettingHeaderPanel />
  },
  {
    path: SettingsPaths.domain,
    exact: true,
    main: () => (
      <div className={'content'}>
        <Domain />
      </div>
    ),
    header: () => <SettingHeaderPanel />
  },
  {
    path: SettingsPaths.account,
    exact: true,
    main: () => (
      <div className={'content pb-0'}>
        <Account />
      </div>
    ),
    header: () => <SettingHeaderPanel />
  },
  {
    path: SettingsPaths.integrations,
    exact: true,
    main: () => (
      <div className={'content pb-0'}>
        <Integrations />
      </div>
    ),
    header: () => <SettingHeaderPanel />
  },
  {
    path: SettingsPaths.zapier(),
    exact: true,
    main: () => (
      <div className={'content pb-0'}>
        <Integrations />
      </div>
    ),
    header: () => <SettingHeaderPanel />
  },
  {
    path: SettingsPaths.payment,
    main: () => (
      <div className={'content pb-0'}>
        <PaymentIntegration />
      </div>
    ),
    header: () => <SettingHeaderPanel />
  },
  {
    path: SettingsPaths.customization,
    exact: true,
    main: () => (
      <div className={'content pb-0'}>
        <Customization />
      </div>
    ),
    header: () => <SettingHeaderPanel />
  }
];
