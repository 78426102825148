import { LoadState } from '../../../../store';
import { IField } from '../../field';

export enum ListTypesEnum {
  main = 'main',
  manual = 'manual',
  filter = 'filter'
}

export type ListTypes = keyof typeof ListTypesEnum;

export interface IListField {
  field_id: string;
  order: number;
  field?: IField;
}

export interface IList {
  date_create: string;
  date_update: string;
  client_count?: number;
  fields: IListField[];
  id: string;
  organization_id: string;
  // payload: {};
  title: string;
  type: ListTypes;
}

export interface IListsState {
  loadState: LoadState;
  error: Error | null;
  lists: IList[];
}

export const ListsInitialState: IListsState = {
  loadState: LoadState.firstLoad,
  error: null,
  lists: []
};

export interface ICreateListRequest {
  title: string;
}
