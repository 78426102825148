import { ICreateListRequest, IList } from '../modules/crm/lists/store/ListsState';
import { baseFetch } from './BaseFetch';

export const getLists = async (authToken?: string) => {
  return await baseFetch<{}, Array<IList>>('/contacts/lists', {}, 'GET', true, {
    ...(authToken ? { Authorization: `Bearer ${authToken}` } : {})
  });
};

export const createList = async (data: ICreateListRequest) => {
  return await baseFetch<{}, IList>('/contacts/lists', { ...data }, 'POST', true);
};

export const getListDetail = async (id: string) => {
  return await baseFetch<{}, IList>(`/contacts/lists/${id}`, {}, 'GET', true);
};

export const editList = async (id: string, data: ICreateListRequest) => {
  return await baseFetch<{}, IList>(`/contacts/lists/${id}`, { ...data }, 'PUT', true);
};

export const deleteList = async (id: string) => {
  return await baseFetch<{}, IList>(`/contacts/lists/${id}`, {}, 'DELETE', true);
};
