import { IPaginationParams } from '../interfaces';
import { IStudentEnrolParams } from '../interfaces/student';
import {
  IStudent,
  IStudentAnalytics,
  IStudentField,
  IStudentList
} from '../modules/lms/students/store/StudentListState';
import { EmailAction } from './action-email-api';
import { baseFetch } from './BaseFetch';

export const createStudent = async (fields: IStudentField, tags?: string[]) => {
  return await baseFetch<{ fields: IStudentField; tags?: string[] }, IStudent>(
    '/students',
    { fields, tags },
    'POST',
    true
  );
};

export const getStudent = async (id: string) => {
  return await baseFetch<{}, IStudent>(`/students/${id}`, {}, 'GET', true);
};

export const getStudentsList = async (pagination?: IPaginationParams, query?: string) => {
  const search = query ? { q: query } : {};
  return await baseFetch<IPaginationParams | {}, IStudent[]>('/students', { ...pagination, ...search }, 'GET', true);
};

export const getStudentsLists = async () => {
  return await baseFetch<{}, IStudentList[]>('/students/lists', {}, 'GET', true);
};

export const getStudentsOfList = async (id: string, pagination?: IPaginationParams, query?: string) => {
  const search = query ? { q: query } : {};
  return await baseFetch<IPaginationParams | {}, IStudent[]>(
    `/students/lists/${id}/students`,
    { ...pagination, ...search },
    'GET',
    true
  );
};

export const getEnrollmentList = async (id: string) => {
  return await baseFetch<IPaginationParams | {}, IStudentList>(`/students/lists/${id}`, {}, 'GET', true);
};

export const editStudent = async (id: string, fields: IStudentField, tags?: string[]) => {
  return await baseFetch<{ fields: IStudentField; tags?: string[] }, IStudent>(
    `/students/${id}`,
    { fields, tags },
    'PUT',
    true
  );
};

export const deleteStudent = async (id: string, deleteContact?: boolean) => {
  await baseFetch<{}, {}>(`/students/${id}`, { ...{ delete_contact: deleteContact } }, 'DELETE', true);
};

export const editStudentList = async (studentId: string, listIds: string[]) => {
  return await baseFetch<{ lists: string[] }, IStudent>(
    `/students/${studentId}/lists`,
    { lists: listIds },
    'PUT',
    true
  );
};

export const getStudentsConfig = async () => {
  return await baseFetch<{}, IStudentList>('/students/config', {}, 'GET', true);
};

export const enrolStudent = async (studentId: string, params: IStudentEnrolParams) => {
  return await baseFetch<IStudentEnrolParams, null>(`/students/${studentId}/courses`, params, 'PUT', true);
};

export const enrollResendStudent = async (studentId: string, params: IStudentEnrolParams) => {
  return await baseFetch<IStudentEnrolParams, null>(`/students/${studentId}/courses/resend`, params, 'POST', true);
};

export const unEnrolStudent = async (studentId: string, courseId: string, sendNotification: boolean) => {
  return await baseFetch<{ course_id: string; send_notification_email: boolean }, IStudent>(
    `/students/${studentId}/courses`,
    { course_id: courseId, send_notification_email: !!sendNotification },
    'DELETE',
    true
  );
};

export const actionEmail = async (actionHash: string, courseId: string) => {
  return await baseFetch<{ course_id: string }, EmailAction>(`/action/${actionHash}`, { course_id: courseId }, 'GET');
};

export const getStudentAnalytics = async (studentId: string, courseId: string) => {
  return await baseFetch<{ course_key: string }, IStudentAnalytics>(
    `/students/${studentId}/analytics`,
    { course_key: courseId },
    'GET',
    true
  );
};

export const exportStudents = async () => {
  return await baseFetch<{}, string>('/students/export', {}, 'POST', true);
};

export const exportStudentsFromList = async (list_key: string) => {
  return await baseFetch<{}, string>(`/students/lists/${list_key}/export`, {}, 'POST', true);
};
