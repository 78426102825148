import { Link } from 'react-router-dom';

import classNames from 'classnames';
import styles from './page-not-found.module.scss';
import { ReactComponent as Logo } from '../../../assets/svg/logo.svg';
import { Icon32Home } from '../../components/UI/icons';

import { mainPaths } from '../../modules/MainRoutes';

export const PageNotFound = () => {
  return (
    <div className={styles.container}>
      <Logo />
      <div className={'mt-auto'}>
        <div className={styles.title}>Page not found</div>
        <div className={styles.subtitle}>Error 404</div>
        <div className={styles.text}>
          This page no longer exists. If it was important to you, send us an email and we will try to help you get over
          this loss.
        </div>
      </div>
      <div className={'d-flex justify-content-center justify-content-sm-between align-items-center flex-wrap'}>
        <div className={classNames(styles.buttons, 'flex-wrap justify-content-center justify-content-sm-start')}>
          <Link
            to={mainPaths.main}
            className={'btn btn-sm btn-gradient-secondary d-flex align-items-center mr-2 mb-2 mb-sm-0'}
          >
            <Icon32Home className={'mr-2'} />
            Go to main page
          </Link>
          <a href={'mailto:everactio@gmail.com'} className={'btn btn-sm'}>
            everactio@gmail.com
          </a>
        </div>
        <div className={styles.copy}>© {new Date().getFullYear()} everact.io</div>
      </div>
    </div>
  );
};
