import { IImage } from '@demonvv/everact-widget';
import { Delta, DeltaStatic } from 'quill';

import { ICourse, ICourseNode } from '../modules/lms/courses/store/CourseState';
import { AudioField, VideoField } from './fileUpload';
import { IFile } from './general';

export type AttachmentType = 'image' | 'audio' | 'docs';
export interface IAttachment extends VideoField, AudioField, IImage {}
export interface IQuiz {
  id: string;
  passing_grade: PassingGradeType | null;
  questions: IQuizQuestion[];
  randomize_questions: boolean;
  title?: string;
  type: 'quiz';
  general_question_is_visible: boolean;
  general_question: string | Delta | null;
  moderate_answers: boolean;
  organization_id: string;
}

export interface IStudentQuiz {
  contact_relation?: string;
  email: string;
  phone?: string;
  last_name?: string;
  first_name?: string;
}

export interface IQuizSession {
  answers: Nullable<IQuizSessionAnswer[]>;
  date_update: string;
  date_create: string;
  result: Nullable<IQuizSessionResult>;
  hierarchy: ICourseNode;
  id: string;
  state: QuizState;
  passing_grade: PassingGradeType | null;
  questionnaire: IQuiz;
  quiz_dump: IQuiz;
  student?: IStudentQuiz | null;
  course?: ICourse | null;
}

interface IQuizSessionResult extends Pick<IQuiz, 'passing_grade'> {
  is_passed: boolean;
  total_points: number;
  total_score: number;
  passed_at: string;
  answer_count: number;
  question_count: number;
}

export enum AnswerSessionStatusEnum {
  done = 'done',
  in_progress = 'in_progress',
  skipable = 'skipable',
  pending_moderation = 'pending_moderation',
  fail = 'fail',
  success = 'success',
  moderated = 'moderated'
}

export type QuizState = `${AnswerSessionStatusEnum}`;

export interface IQuizSessionDetail extends Pick<IQuiz, 'passing_grade'> {
  id: string;
  date_create: string;
  date_update: string;
  state: QuizState;
  quiz_dump: IQuiz;
  hierarchy: ICourseNode;
  result: IQuizSessionResult;
  answers: IQuizSessionAnswer[];
  student?: IStudentQuiz;
  questionnaire: IQuiz;
  course?: ICourse | null;
}

export interface IAudioQuizAnswer extends Pick<IFile, 'object_name'> {
  type: string;
  bucket_name: string;
}

export interface IQuizSessionAnswer<A = any> {
  question_dump: IQuizQuestion;
  answers: A;
  is_true: boolean;
  score: number;
  total_score: number;
  date_create: string;
  date_update: string;
}

interface IQuizSessionResult extends Pick<IQuiz, 'passing_grade'> {
  is_passed: boolean;
  total_points: number;
  total_score: number;
}

export enum PassingGradeTypeValues {
  by_question = 'by_question',
  by_answer_points = 'by_answer_points'
}

export enum AnswerTypeEnum {
  single_select = 'single_select',
  multiple_select = 'multiple_select',
  text_input = 'text_input',
  audio_input = 'audio_input',
  video_input = 'video_input',
  image_input = 'image_input',
  file_input = 'file_input'
}

export type AnswerType = `${AnswerTypeEnum}`;

export type AnswerPayloadType<T = AnswerType> = T extends AnswerTypeEnum.text_input
  ? string | DeltaStatic
  : T extends AnswerTypeEnum.audio_input
  ? IAudioQuizAnswer
  : string[];

export type IQuizQuestion<T = AnswerTypeEnum> = {
  id?: string;
  title?: string;
  order: number;
  description: string | null;
  attachment: IAttachment | null;
  points: number;
  explanation: string | null;
  type?: 'quiz.question';
  answers?: IQuizAnswer[];
  is_passed_with?: boolean | null; //null - is moderation, true-false - is correct/incorrect
} & QuestionChoiceData<T>;

type ExtractActionParameters<A, T> = A extends { answer_type: T } ? A : never;

export type QuestionChoiceData<T = AnswerTypeEnum> = ExtractActionParameters<ChoicesTypes<T>, T>;

type ChoicesTypes<T> =
  | {
      answer_type: AnswerTypeEnum.multiple_select;
      choices?: AnswerPayloadType<T>; //string[] | null
    }
  | {
      answer_type: AnswerTypeEnum.single_select;
      choices?: AnswerPayloadType<T>; //string[] | null
    }
  | {
      answer_type: AnswerTypeEnum.text_input;
      choices: AnswerPayloadType<T>; //string | null;
    }
  | {
      answer_type: AnswerTypeEnum.audio_input;
      choices: AnswerPayloadType<T>; // IAudioQuizAnswer | null;
    }
  | {
      answer_type: AnswerTypeEnum.video_input;
      choices: AnswerPayloadType<T>; // IAudioQuizAnswer | null;
    }
  | {
      answer_type: AnswerTypeEnum.file_input;
      choices: AnswerPayloadType<T>; // IAudioQuizAnswer | null;
    };

export interface IQuizAnswer {
  id?: string;
  title: string;
  order: number;
  is_true: boolean;
  attachment: IAttachment | null;
  type: 'quiz.question.answer';
}

export enum PassingGradeOptions {
  type = 'type',
  questions_count = 'questions_count',
  points_count = 'points_count'
}

export enum QuizSettingsEnum {
  passingGrade = 'passing_grade',
  generalQuestionIsVisible = 'general_question_is_visible',
  generalQuestion = 'general_question',
  moderate_answers = 'moderate_answers'
}

export type PassingGradeType =
  | {
      type: PassingGradeTypeValues.by_question;
      questions_count: number;
    }
  | {
      type: PassingGradeTypeValues.by_answer_points;
      points_count: number;
    };

export enum LessonQuizQuestions {
  questions = 'questions'
}

export enum LessonQuizAnswers {
  answers = 'answers'
}

export type QuizStatusProgress = 'in_progress' | 'finished' | 'not_started' | 'skipable';
