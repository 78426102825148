export const ColorItems: { background: string; color: string }[] = [
  {
    background: '#e7ffe6',
    color: '#0ab300'
  },
  {
    background: '#fffacc',
    color: '#c78605'
  },
  {
    background: '#ffcdbd',
    color: '#b04203'
  },
  {
    background: '#ffb3cd',
    color: '#ae0440'
  },
  {
    background: '#eeb3fe',
    color: '#7e0a9e'
  },
  {
    background: '#e0eeff',
    color: '#004499'
  },
  {
    background: '#b8ffea',
    color: '#09aa6d'
  }
];
