import { createContext, useContext } from 'react';

export type LessonContent = {
  onDelete: (x: boolean) => void;
  isDelete: boolean;
};

export const LessonContext = createContext<LessonContent>({
  isDelete: false,
  onDelete: () => {}
});
export const useLessonContext = () => useContext(LessonContext);
