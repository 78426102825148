import { createSelector } from 'reselect';

import { FilterState } from '../../../interfaces/filters';
import { IAppState } from '../../../store';
import { AutomationState } from './AutomationState';

export const AutomationSelector = () =>
  createSelector(
    (_: IAppState, activeFilter: FilterState) => activeFilter,
    ({ automations }: IAppState) => automations.automations,
    (state: IAppState) => state.automations.loadState,
    (state: IAppState) => state.automations.error,
    (activeFilter, automations, loadState, error) => {
      let filteredList = automations;
      switch (activeFilter) {
        case 'all':
          filteredList = automations.filter((automation) => {
            return automation.state === AutomationState.published || automation.state === AutomationState.draft;
          });
          break;
        case 'draft':
          filteredList = automations.filter((automation) => {
            return automation.state === AutomationState.draft;
          });
          break;
        case 'published':
          filteredList = automations.filter((automation) => {
            return automation.state === AutomationState.published;
          });
          break;
        case 'archived':
          filteredList = automations.filter((automation) => {
            return automation.state === AutomationState.archived;
          });
          break;
      }
      return {
        automationList: filteredList,
        loadState,
        error
      };
    }
  );
