import { memo } from 'react';

import styles from './course-block.module.scss';

import { IGeneralSettings } from '../../../../interfaces';
import { ICourse } from '../../../lms/courses/store/CourseState';
import { CourseItem } from '../course-item/CourseItem';

interface IProps {
  title: string;
  course: Array<
    Pick<
      ICourse,
      | 'id'
      | 'industry'
      | 'lessons_counts'
      | 'lessons_pass'
      | 'last_lesson'
      | 'organization_id'
      | 'settings'
      | 'state'
      | 'title'
    > & {
      organization: IGeneralSettings;
    }
  >;
}

export const CourseBlock = memo(({ course, title }: IProps) => {
  return (
    <>
      <div className={styles.status}>{title}</div>
      <div className={styles.list}>
        {course.map((x, i) => (
          <CourseItem
            key={i}
            id={x.id}
            category={x.settings.category}
            thumbnail={x.settings.thumbnail}
            lessons_counts={x.lessons_counts}
            lessons_pass={x.lessons_pass}
            last_lesson={x.last_lesson}
            title={x.title}
            general_image={x.organization.general_image}
            general_title={x.organization.general_title}
            theme_logo={x.organization.theme_logo}
          />
        ))}
      </div>
    </>
  );
});
