import React, { useEffect, useRef, useState } from 'react';
import Draggable, { ControlPosition, DraggableData, DraggableEvent } from 'react-draggable';

import classNames from 'classnames';
import './palette-popup.scss';
import styles from './palette-popup.module.scss';
import { IconCross } from '../../../../components/UI/icons';

import { ElementThumb } from '../../../../components/illustrations/ElementThumb';
import { CategoryNameType, ElementAutomationType, PaletteCategoryNames } from '../../../../interfaces';
import { BuilderType } from '../store/BuilderActionAsync';
import { PaletteInfo } from './PaletteInfo';

interface IProps {
  category: CategoryNameType;
  elements: Record<string, { name: string; available: boolean }[]>;
  builderType: BuilderType;
  paletteElementNames: Record<string, string>;
  paletteElementTypes: Record<string, string>;

  closePopup(category: string, event: any): void;

  onDropElement(elementName: string, x: number, y: number, width: number, height: number): void;
}

export const PalettePopup = ({
  category,
  closePopup,
  elements,
  onDropElement,
  builderType,
  paletteElementTypes,
  paletteElementNames
}: IProps) => {
  const draggableEl = useRef(null);
  const categoryElements = elements[category];
  const [draggableElement, setDraggableElement] = useState<any | null>(null);
  const [clickPosition, setClickPosition] = useState<any | null>(null);

  const onStart = (_e: DraggableEvent, _data: DraggableData) => {};

  useEffect(() => {
    if (clickPosition !== null) {
      let event = new MouseEvent('mousedown', {
        ...clickPosition,
        bubbles: true
      });
      // @ts-ignore
      draggableEl.current?.dispatchEvent(event); // TODO: Describe the type
    }
  }, [draggableEl, clickPosition]);

  const onStop = (e: DraggableEvent, data: DraggableData) => {
    new Promise((resolve) => {
      const paletteContainerData = document.querySelector('.palette-popup')?.getBoundingClientRect();
      const elementData = data.node.getBoundingClientRect();
      const isOnPalette =
        paletteContainerData!.top - elementData?.height < elementData?.top &&
        paletteContainerData!.left - elementData?.width < elementData?.left &&
        paletteContainerData!.bottom + elementData?.height > elementData?.bottom &&
        paletteContainerData!.right + elementData?.width > elementData?.right;
      if (!isOnPalette) {
        const clientX = (e as React.MouseEvent<HTMLElement | SVGElement>).clientX;
        const clientY = (e as React.MouseEvent<HTMLElement | SVGElement>).clientY;
        const elementName = data.node.id;
        const pageElementWidth = 140;
        const pageElementHeight = 204;
        const allElementsSide = 76;
        const elWidth = category === 'pages' ? pageElementWidth : allElementsSide;
        const elHeight = category === 'pages' ? pageElementHeight : allElementsSide;
        onDropElement(elementName, clientX, clientY, elWidth, elHeight);
      }
      resolve(true);
    }).then(() => {
      setDraggableElement(null);
    });
  };

  const onMouseDown = (e: React.MouseEvent) => {
    e.preventDefault();
    const node = e.currentTarget as HTMLElement;
    const isAvailable = node.getAttribute('data-available') === 'true';
    if (isAvailable) {
      const elementData = node.getBoundingClientRect();
      const paletteContainerData = document.querySelector('.palette-popup')?.getBoundingClientRect();
      const elName = node.id as ElementAutomationType;
      const position = {
        x: elementData.x - paletteContainerData!.x,
        y: elementData.y - paletteContainerData!.y
      };
      setClickPosition({
        clientX: e.clientX,
        clientY: e.clientY,
        screenX: e.screenX,
        screenY: e.screenY
      });
      setDraggableElement(dragElement(elName, position));
    }
  };

  const onClick = (e: React.MouseEvent) => {
    e.preventDefault();
    // temporary disabled the redirection to feedback sys, this will be performed with pendo.io
    // e.preventDefault();
    // const node = e.currentTarget as HTMLElement;
    // const isAvailable = node.getAttribute('data-available') === 'true';
    // if (!isAvailable) {
    //   window.open('https://feedback.everact.io/b/feedback/p/automation-builder');
    // }
  };

  const popupTitle = PaletteCategoryNames[category];
  const isFunnel = builderType === 'funnel';
  const elementNameStyles = classNames('element-name', { 'element-name-automation': !isFunnel });

  const dragElement = (elementName: ElementAutomationType, position: ControlPosition) => {
    const type = paletteElementTypes[elementName];
    const title = paletteElementNames[elementName];

    return (
      <Draggable onStop={onStop} onStart={onStart} position={position} nodeRef={draggableEl}>
        <div
          className={classNames(isFunnel ? 'palette-element' : 'palette-element-automation', elementName)}
          id={type}
          ref={draggableEl}
        >
          <div className={elementNameStyles}>{title}</div>
          <div className="element-preview">
            <ElementThumb name={type} type="thumb" builderType={builderType} />
          </div>
        </div>
      </Draggable>
    );
  };

  return (
    <div className="card palette-popup">
      {draggableElement}
      <div className="scrollable-container">
        <button
          type="button"
          className="btn p-0 close"
          onClick={(event) => {
            closePopup(category, event);
          }}
        >
          <IconCross />
        </button>
        <h3 className="title">{popupTitle}</h3>
        <div className={isFunnel ? 'palette-elements' : ''}>
          {categoryElements ? (
            categoryElements.map((element, index) => {
              if (((isFunnel && element.available) || !isFunnel) && category !== 'info') {
                const type = paletteElementTypes[element.name];
                const title = paletteElementNames[element.name];

                return (
                  <div
                    key={index}
                    className={classNames(isFunnel ? 'palette-element' : 'palette-element-automation', {
                      [styles.disabled]: !element.available
                    })}
                    id={element.name}
                    data-available={element.available}
                    onMouseDown={onMouseDown}
                    onClick={onClick}
                  >
                    <div className={elementNameStyles}>{title}</div>
                    <div className="element-preview">
                      <ElementThumb name={type} type="thumb" builderType={builderType} />
                    </div>
                  </div>
                );
              }
              return null;
            })
          ) : (
            <PaletteInfo />
          )}
        </div>
      </div>
    </div>
  );
};
