import React, { memo, SyntheticEvent, useCallback, useMemo, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import classNames from 'classnames/bind';
import styles from './enrollment.module.scss';
import enrollmentManipulateStyles from './enrollment-manipulate/enrollment-manipulate.module.scss';
import { Icon32Cross, IconCourses } from '../../../../components/UI/icons';

import { Button, CommonModal } from '../../../../components/UI';
import { useDerivedState } from '../../../../helpers/use-derived-state';
import { FieldByScopeSelector } from '../../../crm/field/store/FieldSelector';
import { CoursesSelector } from '../../courses/store/CourseSelector';
import { IStudent } from '../store/StudentListState';
import { StudentsActionAsync } from '../store/StudentsActionAsync';
import { EnrollFooter } from './enroll-footer/EnrollFooter';
import { EnrollmentCourse } from './enrollment-course/EnrollmentCourse';
import { EnrollmentManipulate } from './enrollment-manipulate/EnrollmentManipulate';
import { EnrollmentResend } from './enrollment-resend/EnrollmentResend';
import { UnenrollModal } from './unenroll-modal/unenroll-modal';

interface IProps {
  student?: IStudent;
}

interface CourseData {
  courseId: string | undefined;
  courseTitle: string | undefined;
}

export const Enrollment = memo(({ student }: IProps) => {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [unenrollModalData, setUnenrollModalData] = useState<CourseData | null>(null);
  const [studentCourses, _] = useDerivedState(student?.courses, [student?.courses]);
  const courseMemo = useMemo(CoursesSelector, []);
  const { courses } = useSelector((state) => courseMemo(state, 'all'));
  const [selectedCourses, setSelectedCourses] = useState<string[]>([]);
  const [onLoad, setOnLoad] = useState(false);
  const [sendInvitation, setSendInvitation] = useState<boolean>(true);
  const FirstNameFieldScopeMemo = useMemo(() => FieldByScopeSelector('first_name', 'student'), []);
  const LastNameFieldScopeMemo = useMemo(() => FieldByScopeSelector('last_name', 'student'), []);
  const EmailFieldScopeMemo = useMemo(() => FieldByScopeSelector('email', 'student'), []);
  const fieldFirstNameId = useSelector(FirstNameFieldScopeMemo);
  const fieldLastNameId = useSelector(LastNameFieldScopeMemo);
  const fieldEmailId = useSelector(EmailFieldScopeMemo);
  const studentName = useMemo(
    () =>
      student
        ? fieldFirstNameId && fieldLastNameId && student.fields[fieldFirstNameId] && student.fields[fieldLastNameId]
          ? `${student.fields[fieldFirstNameId]} ${student.fields[fieldLastNameId]}`
          : fieldEmailId && student.fields[fieldEmailId]
          ? `${student.fields[fieldEmailId]}`
          : null
        : null,
    [fieldEmailId, fieldFirstNameId, fieldLastNameId, student]
  );

  const addCourse = useCallback(() => {
    setShowModal(true);
  }, []);

  const onCloseModal = useCallback(() => {
    setShowModal(false);
    setSelectedCourses([]);
  }, []);

  const onShowUnenrollModal = useCallback(
    (courseId: string | undefined, courseTitle: string | undefined) => setUnenrollModalData({ courseId, courseTitle }),
    []
  );

  const onCloseUnenrollModal = useCallback(() => setUnenrollModalData(null), []);

  const onEnrolCallback = useCallback(() => {
    if (student) {
      dispatch(StudentsActionAsync.getStudent(student.id));
    }
    setOnLoad(false);
    onCloseModal();
    onCloseUnenrollModal();
  }, [dispatch, onCloseModal, onCloseUnenrollModal, student]);

  const unEnroll = useCallback(
    (e: React.SyntheticEvent<HTMLButtonElement>) => {
      const courseId = e.currentTarget.id;
      const foundCourse = (studentCourses || []).find((x) => x.course_id === courseId);

      if (student && foundCourse) {
        onShowUnenrollModal(foundCourse.course_id, foundCourse.course.title);
      }
    },
    [onShowUnenrollModal, student, studentCourses]
  );

  const onUnenrollHandler = useCallback(
    (courseId: string | undefined, sendNotification: boolean) => {
      if (student && courseId) {
        setOnLoad(true);
        dispatch(StudentsActionAsync.unEnrolStudent(student.id, courseId, sendNotification, onEnrolCallback));
      }
    },
    [dispatch, onEnrolCallback, student]
  );

  const onSave = useCallback(() => {
    if (student && selectedCourses.length) {
      setOnLoad(true);
      dispatch(
        StudentsActionAsync.enrolStudent(
          student.id,
          {
            course_ids: selectedCourses,
            send_email: sendInvitation
          },
          onEnrolCallback,
          onError
        )
      );
    }
  }, [dispatch, onEnrolCallback, selectedCourses, sendInvitation, student]);

  const onError = () => {
    setOnLoad(false);
  };

  const onChangeSendingInvitation = (e: SyntheticEvent<HTMLInputElement>) => {
    setSendInvitation(e.currentTarget.checked);
  };

  return (
    <>
      <div className={styles.enrollment}>
        {student && student.user_id !== undefined && (
          <div className={styles.courses}>
            {fieldEmailId && studentCourses && (
              <EnrollmentResend
                email={student.fields[fieldEmailId]}
                userId={student.user_id}
                studentId={student.id}
                courseList={studentCourses}
              />
            )}
            {studentCourses?.map((course) => (
              <EnrollmentCourse key={course.course_id} course={course} userId={student.user_id} unEnroll={unEnroll} />
            ))}
          </div>
        )}
        <div className={styles.addCourse}>
          <Button onClick={addCourse} btnStyle={'transparent'} className={'mx-auto'}>
            Add course
          </Button>
        </div>
      </div>
      <CommonModal onClose={onCloseModal} show={showModal}>
        <div className={enrollmentManipulateStyles.container}>
          <div onClick={onCloseModal} className={enrollmentManipulateStyles.closeContainer}>
            <div className={classNames(enrollmentManipulateStyles.title, enrollmentManipulateStyles.black)}>
              <span className={classNames(enrollmentManipulateStyles.title, 'mr-2')}>
                <IconCourses />
              </span>
              Add <span className={enrollmentManipulateStyles.title}>course</span>
            </div>
            <Icon32Cross className={enrollmentManipulateStyles.close} />
          </div>
          <Modal.Body className={'p-0 mt-3'}>
            <EnrollmentManipulate
              courses={courses}
              activeCourses={studentCourses}
              selectedCourses={selectedCourses}
              setSelected={setSelectedCourses}
            />
          </Modal.Body>
          <Modal.Footer className={'p-0 d-flex justify-content-between mt-4'}>
            <EnrollFooter
              onCloseModal={onCloseModal}
              onLoad={onLoad}
              onSave={onSave}
              onChange={onChangeSendingInvitation}
              checked={sendInvitation}
            />
          </Modal.Footer>
        </div>
      </CommonModal>
      {student && (
        <UnenrollModal
          onClose={onCloseUnenrollModal}
          isShow={unenrollModalData !== null}
          onUnenroll={onUnenrollHandler}
          studentName={studentName}
          courseId={unenrollModalData?.courseId}
          courseTitle={unenrollModalData?.courseTitle}
        />
      )}
    </>
  );
});
