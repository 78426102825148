import { reducerWithInitialState } from 'typescript-fsa-reducers';

import { LoadState, newState } from '../../../../store';
import { IStudentsListsState, StudentsListsInitialState } from './StudentListState';
import { StudentsActions } from './StudentsActions';

export const commonFirstLoadHandler = <T>(state: T) => ({ ...state, loadState: LoadState.firstLoad, error: null });
export const commonErrorHandler = <T>(state: T, { error }: { error: Error }) => ({
  ...state,
  loadState: LoadState.error,
  error
});

export const studentsListsReducer = reducerWithInitialState<IStudentsListsState>(StudentsListsInitialState)
  .case(StudentsActions.getStudentsLists.started, commonFirstLoadHandler)
  .case(StudentsActions.getStudentsLists.done, (state, { result }) => {
    return newState(state, {
      studentsLists: result,
      loadState: LoadState.allIsLoaded,
      error: null
    });
  })
  .case(StudentsActions.getStudentsLists.failed, commonErrorHandler);
