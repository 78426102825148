import { Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import styles from '../createListModal/create-list-modal.module.scss';
import { IconCheckMark } from '../../../../../components/UI/icons';

import { ModalActionComponent, Toast } from '../../../../../components/UI';
import { ListsActionAsync } from '../../store/ListsActionAsync';

interface IProps {
  show: boolean;
  listId?: string;
  onCloseModal(): void;
}

export const RenameListModal = ({ show, listId, onCloseModal }: IProps) => {
  const dispatch = useDispatch();
  const { lists } = useSelector((state) => state.lists);
  const { register, watch } = useForm<{ renameList: string }>();
  const listName = watch('renameList');
  const currentList = lists.find((list) => list.id === listId);

  const onSave = () => {
    if (listName && listId) {
      dispatch(
        ListsActionAsync.editList(listId, { title: listName }, () => {
          Toast('success', 'List renamed successfully!');
        })
      );
      onCloseModal();
    }
  };

  return (
    <ModalActionComponent title={'Rename list'} onCloseModal={onCloseModal} show={show} iconType="edit">
      <div className="mt-3">
        <Form.Group controlId={'renameList'}>
          <Form.Control
            placeholder={currentList?.title || 'List name'}
            defaultValue={currentList?.title}
            {...register('renameList')}
          />
        </Form.Group>
      </div>
      <div className={styles.modalFooter}>
        <button type={'button'} className={'btn btn-sm mr-2'} onClick={onCloseModal}>
          Cancel
        </button>
        <button
          type={'button'}
          className={'btn btn-sm btn-icon-right btn-gradient-primary'}
          onClick={onSave}
          disabled={!listName || listName === currentList?.title}
        >
          Save
          <IconCheckMark />
        </button>
      </div>
    </ModalActionComponent>
  );
};
