import { reducerWithInitialState } from 'typescript-fsa-reducers';

import { commonErrorHandler, commonFirstLoadHandler } from '../../../helpers/common-reducer-handlers';
import { LoadState, newState } from '../../../store';
import { settingCourseActions } from './SettingCourseActions';
import { ISettingCourseState, SettingCourseInitialState } from './SettingCourseState';

export const settingCourseReducer = reducerWithInitialState<ISettingCourseState>(SettingCourseInitialState)
  .case(settingCourseActions.paymentMethod.started, (state) => ({
    ...state,
    loadState: LoadState.needLoad,
    error: null
  }))
  .case(settingCourseActions.paymentMethod.done, (state) => {
    return newState(state, {
      error: null,
      loadState: LoadState.allIsLoaded
    });
  })
  .case(settingCourseActions.paymentMethod.failed, commonErrorHandler)

  .case(settingCourseActions.getPaymentCards.started, commonFirstLoadHandler)
  .case(settingCourseActions.getPaymentCards.done, (state, { result }) => {
    return newState(state, {
      paymentCards: result,
      error: null,
      loadState: LoadState.allIsLoaded
    });
  })
  .case(settingCourseActions.getPaymentCards.failed, commonErrorHandler)

  .case(settingCourseActions.removePaymentCard.started, commonFirstLoadHandler)
  .case(settingCourseActions.removePaymentCard.done, (state, { params }) => {
    const removingIndexElement = state.paymentCards.findIndex((x) => x.id === params.id);
    state.paymentCards.splice(removingIndexElement, 1);

    return newState(state, {
      paymentCards: [...state.paymentCards],
      loadState: LoadState.allIsLoaded,
      error: null
    });
  })
  .case(settingCourseActions.removePaymentCard.failed, commonErrorHandler)

  .case(settingCourseActions.setDefaultCard.started, commonFirstLoadHandler)
  .case(settingCourseActions.setDefaultCard.done, (state, { params }) => {
    const changedCards = state.paymentCards.map((x) =>
      x.id === params.id ? { ...x, is_default: true } : { ...x, is_default: false }
    );
    return newState(state, {
      paymentCards: changedCards,
      loadState: LoadState.allIsLoaded,
      error: null
    });
  })
  .case(settingCourseActions.setDefaultCard.failed, commonErrorHandler);
