import { memo, UIEvent } from 'react';
import { useSelector } from 'react-redux';

import classNames from 'classnames';
import styles from './lesson-student-wrapper.module.scss';

import { UploadWorker } from '../../../../../layout/shared-components/upload-worker/UploadWorker';
import { LessonNavigation } from '../../../../student-cabinet/common/lesson-navigation/LessonNavigation';
import { LessonStudent } from '../../../../student-cabinet/lesson-student/LessonStudent';
import { ICourseNode } from '../../../courses/store/CourseState';
import { useViewCourseContext } from '../ViewCourseContext';

interface IProps {
  viewType?: 'preview' | 'dashboard' | 'student';
  nodeLesson?: ICourseNode;
}

export const LessonStudentWrapper = memo(({ viewType, nodeLesson }: IProps) => {
  const { flatLessons, nodeId } = useViewCourseContext();

  return (
    <>
      <div className={classNames('mx-auto', styles.wrapper, styles.mainContainer)}>
        <LessonStudent viewType={viewType} />
      </div>

      {flatLessons?.currentStep && nodeLesson?.id && (
        <LessonNavigation
          navigation={flatLessons}
          isLastStep={nodeLesson?.children[nodeLesson?.children.length - 1]?.id === nodeId}
          lessonId={nodeLesson?.id}
          isPassedLesson={nodeLesson?.is_passed}
        />
      )}
      <UploadWorker />
    </>
  );
});
