import { IRoutes } from '../../../../interfaces';
import { SchoolHeaderPanel } from '../../../../layout/header/schoolHeaderPanel/SchoolHeaderPanel';
import { Answers } from '../answers/Answers';

export const answersPaths = {
  answers: '/answers'
};

export const AnswersRoutes: IRoutes[] = [
  {
    path: answersPaths.answers,
    exact: true,
    main: () => <Answers />,
    header: () => <SchoolHeaderPanel />
  }
];
