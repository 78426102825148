import { reducerWithInitialState } from 'typescript-fsa-reducers';

import { commonErrorHandler, commonFirstLoadHandler } from '../../../helpers/common-reducer-handlers';
import { LoadState, newState } from '../../../store';
import { DashboardActions } from './DashboardActions';
import { DashboardInitialState, IDashboardState } from './DashboardState';

export const dashboardReducer = reducerWithInitialState<IDashboardState>(DashboardInitialState)
  .case(DashboardActions.getDashboard.started, commonFirstLoadHandler)
  .case(DashboardActions.getDashboard.done, (state, { result }) => {
    return newState(state, {
      ...result,
      loadState: LoadState.allIsLoaded,
      error: null
    });
  })
  .case(DashboardActions.getDashboard.failed, commonErrorHandler)

  .case(DashboardActions.getDashboardCourseHierarchy.started, commonFirstLoadHandler)
  .case(DashboardActions.getDashboardCourseHierarchy.done, (state, { result, params }) => {
    const updatedCourse = state.courses.map((course) => {
      if (course.id === params.id) {
        course.children = result;
      }
      return course;
    });
    return newState(state, {
      courses: [...updatedCourse],
      loadState: LoadState.allIsLoaded,
      error: null
    });
  })
  .case(DashboardActions.getDashboardCourseHierarchy.failed, commonErrorHandler);
