import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import { DraggableProvided } from 'react-beautiful-dnd';
import { Dropdown } from 'react-bootstrap';
import AutosizeInput from 'react-input-autosize';
import { useDispatch } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';

import classnames from 'classnames';
import styles from './lecture.module.scss';
import { Icon16CheckMark, Icon16ChevronDown, Icon16Draggable, Icon16Trash } from '../../../../../components/UI/icons';

import { DropMenu, ModalDelete } from '../../../../../components/UI';
import { useCourseContext } from '../../common/sidebar/CourseContext';
import { coursePaths } from '../../routes/CourseRoutes';
import { courseActionAsync } from '../../store/CourseActionAsync';
import { ICourseNode } from '../../store/CourseState';
import { useLessonContext } from '../lesson/LessonContext';

interface IProps {
  isActive: boolean;
  item: ICourseNode;
  provided: DraggableProvided;
  onDeleteItem: (id: string) => void;
  onActive: (e: React.MouseEvent<HTMLInputElement, MouseEvent>) => void;
}

export const Lecture = memo(({ item, isActive, provided, onDeleteItem, onActive }: IProps) => {
  const { onDelete } = useLessonContext();
  const [showDialog, setShowDialog] = useState(false);
  const [isReadonly, setIsReadonly] = useState(true);
  const [value, setValue] = useState(item.title);
  const inputRef = useRef<any | null>(null);
  const dispatch = useDispatch();
  const path = useRouteMatch<{ id: string; lessonId: string }>(coursePaths.courseLessons());
  const [showConformation, setShowConformation] = useState(false);
  const { getHierarchy } = useCourseContext();

  const onToggleDialog = () => {
    setShowDialog((x) => !x);
  };
  const onCloseDialog = () => {
    setShowDialog((_x) => false);
  };

  const onChange = useCallback((e: React.SyntheticEvent<HTMLInputElement>) => {
    setValue(e.currentTarget.value);
  }, []);

  const onSaveHandler = useCallback(() => {
    if (path?.params.id && value !== item.title) {
      setIsReadonly(true);
      dispatch(courseActionAsync.editNode(path.params.id, item.id, { title: value }, getHierarchy));
    }
    setIsReadonly(true);
  }, [dispatch, item.id, item.title, path?.params.id, value, getHierarchy]);

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        onSaveHandler();
      }
    },
    [onSaveHandler]
  );

  const onRename = () => {
    if (inputRef.current) {
      setIsReadonly(false);
      inputRef.current.focus();
    }
  };

  useEffect(() => {
    onCloseDialog();
  }, []);

  const onCloseDelete = useCallback(() => {
    setShowConformation(false);
  }, []);

  const onClickDelete = useCallback(() => {
    onDeleteItem(item.id);
    onCloseDelete();
  }, [item.id, onCloseDelete, onDeleteItem]);

  const showModal = () => {
    onDelete(true);
    setShowConformation(true);
  };

  return (
    <>
      <div className={classnames(styles.container, isActive && styles.active)}>
        <div {...provided.dragHandleProps}>
          <Icon16Draggable className={styles.icon} />
        </div>
        <AutosizeInput
          value={value || ''}
          inputClassName={classnames(styles.input, isActive && styles.active, !isReadonly && styles.edit)}
          className={styles.input}
          onChange={onChange}
          onKeyDown={handleKeyDown}
          onBlur={onSaveHandler}
          maxLength={256}
          readOnly={isReadonly}
          ref={inputRef}
          data-id={item.id}
          onClick={onActive}
        />
        {!isReadonly && (
          <span className={styles.checkMark} onClick={onSaveHandler}>
            <Icon16CheckMark />
          </span>
        )}
        <DropMenu
          show={showDialog}
          onToggle={onToggleDialog}
          node={isReadonly && <Icon16ChevronDown className={classnames(styles.chevron, isActive && styles.active)} />}
          className={styles.dropContainer}
          drop={'down'}
        >
          <Dropdown.Menu onClick={onCloseDialog} className={styles.containerMenu}>
            <div className={styles.contentMenu}>
              <div className={styles.dropMenuTextRename} onClick={onRename}>
                Rename lecture
              </div>
              <div className={styles.dropMenuTextDelete} onClick={showModal}>
                <Icon16Trash />
                <div>Delete</div>
              </div>
            </div>
          </Dropdown.Menu>
        </DropMenu>
      </div>
      <ModalDelete
        isShowed={showConformation}
        title={
          <span className={styles.titleTextModal}>
            Delete <span>{item.title}</span> ?
          </span>
        }
        iconType={'warning'}
        description={'All materials from this lecture will be permanently deleted.'}
        onClose={onCloseDelete}
        textDelete={'Yes, delete'}
        iconStyles={styles.dropDeleteModal}
        onDelete={onClickDelete}
      />
    </>
  );
});
