import { IRoutes } from '../../../../interfaces';
import { SchoolHeaderPanel } from '../../../../layout/header/schoolHeaderPanel/SchoolHeaderPanel';
import { StudentsOfList } from '../../students/students-of-list/StudentsOfList';
import { EnrollmentList } from '../enrollment-list/EnrollmentList';
import { CourseHeaderPanel } from '../header-panel/ListOfStudentsHeader';

export const enrollmentPaths = {
  enrollment: '/enrollment',
  studentListId: (id = ':list_id') => `/enrollment/list/${id}`
};

export const EnrollmentRoutes: IRoutes[] = [
  {
    path: enrollmentPaths.enrollment,
    exact: true,
    main: () => (
      <div className={'content'}>
        <EnrollmentList />
      </div>
    ),
    header: () => <SchoolHeaderPanel />
  },
  {
    path: enrollmentPaths.studentListId(),
    main: () => (
      <div className={'content'}>
        <StudentsOfList />
      </div>
    ),
    header: () => <CourseHeaderPanel />
  }
];
