import { IMember } from '../../../../interfaces';
import { LoadState } from '../../../../store';

export interface IOrganizationState {
  members: IMember[];
  loadState: LoadState;
  error: Error | null;
}

export const OrganizationInitialState: IOrganizationState = {
  error: null,
  loadState: LoadState.firstLoad,
  members: []
};
