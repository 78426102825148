import React, { memo, useEffect, useMemo, useState } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import AutosizeInput from 'react-input-autosize';
import { useSelector } from 'react-redux';
import { Tag } from 'react-tag-autocomplete-fix';

import classNames from 'classnames';
import styles from './contact-form.module.scss';
import { IconTrash } from '../../../../components/UI/icons';

import { CustomTooltip, FormField } from '../../../../components/UI';
import { FieldSelector, ModifyField } from '../../field/store/FieldSelector';
import { AutocompleteTag } from '../../tag/AutocompleteTag';
import { ContactDelete } from '../common/contact-delete/ContactDelete';
import { IContact } from '../store/ContactListState';

interface IProps {
  onSetTag: (x: Tag[]) => void;
  tags: Tag[];
  contact?: IContact;
}

export const ContactForm = memo(({ onSetTag, tags, contact }: IProps) => {
  const [showModal, setShowModal] = useState(false);
  const {
    control,
    formState: { errors }
  } = useFormContext();
  const useWatchData = useWatch({
    control
  });
  const fieldMemo = useMemo(FieldSelector, []);
  const { fullName, contacts } = useSelector((state) => fieldMemo(state, 'contact'));
  const [customFields, setCustomFields] = useState<ModifyField[]>([]);
  const [phone, setPhone] = useState<ModifyField>();
  const [email, setEmail] = useState<ModifyField>();

  useEffect(() => {
    const [phone] = contacts.filter((field) => field.scope === 'phone');
    setPhone(phone);
    const [email] = contacts.filter((field) => field.scope === 'email');
    setEmail(email);
    const fields = contacts.filter((field) => field.scope === 'custom');
    setCustomFields(fields);
  }, [contacts]);

  const onShowModalDelete = () => setShowModal(true);
  const onCloseModalDelete = () => setShowModal(false);

  return (
    <>
      <div className={styles.contactForm}>
        <div className={styles.contactNameContainer}>
          <div className={classNames(styles.contactName)}>
            {fullName.map((field) => (
              <Controller
                render={({ field: { onChange, value } }) => (
                  <AutosizeInput
                    inputClassName={classNames('form-control invisible-control', styles.formControl, {
                      'is-invalid': !!errors[field.id]
                    })}
                    maxLength={25}
                    placeholder={field.payload.title}
                    placeholderIsMinWidth
                    onChange={onChange}
                    value={value || ''}
                  />
                )}
                key={field.id}
                name={field.id}
                control={control}
                defaultValue={useWatchData[field.id] || ''}
              />
            ))}
          </div>
          <button
            type={'button'}
            className={classNames('btn btn-icon', styles.deleteContactButton)}
            onClick={onShowModalDelete}
          >
            <CustomTooltip customText={'Delete contact'} direction={'top'}>
              <IconTrash />
            </CustomTooltip>
          </button>
        </div>

        <AutocompleteTag onSetTag={onSetTag} tags={tags} userEntity={contact} />
        {email && <FormField field={email} />}
        {phone && <FormField field={phone} />}
        {customFields.map((field) => (
          <FormField key={field.id} field={field} />
        ))}
      </div>
      {contact && <ContactDelete onClose={onCloseModalDelete} show={showModal} contact={contact} />}
    </>
  );
});
