import classnames from 'classnames';
import styles from './canvas-node.module.scss';

interface IProps {
  text: string;
}

export const EmptyCanvasNode = ({ text }: IProps) => {
  return <div className={classnames('d-flex text-center', styles.textContainer)}>{text}</div>;
};
