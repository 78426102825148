import { ColumnDescription, ColumnFormatter, SelectRowProps } from 'react-bootstrap-table-next';

import styles from '../automation-list.module.scss';

import { IAutomation } from '../../store/AutomationState';

export type RowType = {
  id: string;
  name: string;
  date_create: string;
  state: number;
  data: IAutomation;
};

export type FormatterType = ColumnFormatter<RowType, any, string | number>;

export type DataField = 'name' | 'date_create' | 'state';

export type ColumnDescriptionType = Omit<ColumnDescription<RowType>, 'dataField'> & { dataField: DataField };

export const selectRow: SelectRowProps<any> = {
  mode: 'radio',
  hideSelectColumn: true,
  clickToSelect: true,
  classes: styles.selectedRow
};

export const columnsData: ColumnDescriptionType[] = [
  {
    dataField: 'name',
    text: 'Name',
    classes: 'name'
  },
  {
    dataField: 'date_create',
    text: 'Creation date',
    searchable: false
  },
  {
    dataField: 'state',
    text: 'Status',
    searchable: false
  }
];
