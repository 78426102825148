import { memo } from 'react';

import styles from '../status-modal.module.scss';
import { Icon32ClearFormat } from '../../../../../UI/icons';

import { ICourseNode } from '../../../../../../modules/lms/courses/store/CourseState';
import { CommonModal } from '../../../../../UI';
import { FolderContentModal } from '../folder-state/FolderContentModal';
import { NodeItemCourse } from '../node-item-course/NodeItemCourse';

interface IProps {
  showModal: boolean;
  onCloseModal: () => void;
  onStateConfirm: (e: React.SyntheticEvent<HTMLButtonElement>) => void;
  onChangePublishInner: (e: boolean) => void;
  hierarchyItem: ICourseNode;
  isDraftParent: boolean;
  isDraftChildren: boolean;
}

export const FolderDraftChildren = memo(
  ({
    hierarchyItem,
    showModal,
    isDraftParent,
    isDraftChildren,
    onCloseModal,
    onStateConfirm,
    onChangePublishInner
  }: IProps) => {
    return (
      <>
        <CommonModal
          onClose={onCloseModal}
          show={showModal}
          showClose={true}
          params={{ contentClassName: styles.contentContainer }}
          closeButtonClassName={styles.closeContainer}
        >
          <div onClick={(e) => e.stopPropagation()}>
            <div className={'d-flex align-items-center'}>
              <Icon32ClearFormat className={styles.icon} />
              <div className={styles.title}>
                {hierarchyItem.state === 'published' ? 'Mark as draft' : 'Unmark draft'}
              </div>
            </div>
            <FolderContentModal
              onChangePublishInner={onChangePublishInner}
              onCloseModal={onCloseModal}
              onStateConfirm={onStateConfirm}
              state={hierarchyItem.state}
              isDraftParent={isDraftParent}
              isDraftChildren={isDraftChildren}
            >
              <NodeItemCourse state={hierarchyItem.state} title={hierarchyItem.title} type={hierarchyItem.type} />
            </FolderContentModal>
          </div>
        </CommonModal>
      </>
    );
  }
);
