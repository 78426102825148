import styles from './shipping.module.scss';
import Shipping from '../../../../../../assets/images/shipping.png';

export const ShippingContent = () => {
  return (
    <div className={styles.container}>
      <div className={styles.text}>Your customer will have to fill in these fields before placing the order</div>
      <img className={styles.image} src={Shipping} alt={'shipping'} />
    </div>
  );
};
