import React, { memo } from 'react';
import { Dropdown } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import {
  IconArchive,
  IconEye,
  IconLink,
  IconMegaphone,
  IconMegaphoneOff,
  IconReply,
  IconTrash
} from '../../../../../components/UI/icons';

import { previewPaths } from '../../../preview/routes/PreviewRoutes';
import { courseActionAsync } from '../../store/CourseActionAsync';
import { CourseState, CourseStates } from '../../store/CourseState';

interface IProps {
  itemState: CourseState;
  courseId: string;
  onOpenUnpublish: () => void;
  onOpenDelete: () => void;
  onOpenShareable: () => void;
}

interface IDropMenu {
  name: string;
  icon: JSX.Element;
  eventKey: string;

  onSelect(eventKey?: string | null): void;

  className?: string;
}

export const GetDropdownItems = memo(
  ({ courseId, itemState, onOpenDelete, onOpenUnpublish, onOpenShareable }: IProps) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const onChangeStatus = (eventKey: string | null) => {
      dispatch(courseActionAsync.updateCourseState(courseId, eventKey as CourseState));
    };

    const onPreview = () => {
      history.push(previewPaths.previewLessons(courseId));
    };

    let menu: IDropMenu[] = [];

    switch (itemState) {
      case 'draft':
        menu = [
          { name: 'Publish', icon: <IconMegaphone />, eventKey: CourseStates.PUBLISHED, onSelect: onChangeStatus },
          { name: 'Get shareable link', icon: <IconLink />, eventKey: '2', onSelect: onOpenShareable },
          { name: 'Preview', icon: <IconEye />, eventKey: '2', onSelect: onPreview },
          {
            name: 'Archive',
            icon: <IconArchive />,
            eventKey: CourseStates.ARCHIVED,
            onSelect: onChangeStatus,
            className: 'iconRed'
          }
        ];
        break;
      case 'published':
        menu = [
          { name: 'Unpublish', icon: <IconMegaphoneOff />, eventKey: '1', onSelect: onOpenUnpublish },
          { name: 'Get shareable link', icon: <IconLink />, eventKey: '2', onSelect: onOpenShareable },
          { name: 'Preview', icon: <IconEye />, eventKey: '2', onSelect: onPreview }
        ];
        break;
      case 'archived':
        menu = [
          { name: 'Restore to drafts', icon: <IconReply />, eventKey: CourseStates.DRAFT, onSelect: onChangeStatus },
          { name: 'Preview', icon: <IconEye />, eventKey: '2', onSelect: onPreview },
          { name: 'Delete', icon: <IconTrash />, eventKey: '2', onSelect: onOpenDelete, className: 'iconRed' }
        ];
        break;
    }

    return (
      <>
        {menu.map((item, index) => (
          <Dropdown.Item key={index} eventKey={item.eventKey} onSelect={item.onSelect} className={item.className}>
            {item.icon} {item.name}
          </Dropdown.Item>
        ))}
      </>
    );
  }
);
