import { SyntheticEvent, useState } from 'react';

import classNames from 'classnames';
import commonStyles from '../upload-common.module.scss';

import { CustomTooltip } from '../../../../UI';

interface IProps {
  icon: JSX.Element;
  title: string;
  note: JSX.Element;
  textButton: string;
  textTooltip?: string;

  onClick(e: SyntheticEvent<HTMLDivElement>): void;
}

export const SelectAddFileCard = ({ icon, title, note, textButton, textTooltip, onClick }: IProps) => {
  const [hover, setHover] = useState(false);

  const onMouseEnterHandler = () => {
    setHover(true);
  };
  const onMouseLeaveHandler = () => {
    setHover(false);
  };

  return (
    <div
      onClick={onClick}
      onMouseEnter={onMouseEnterHandler}
      onMouseLeave={onMouseLeaveHandler}
      className={commonStyles.card}
    >
      <div className={classNames([commonStyles.content, { [commonStyles.contentHover]: hover }])}>
        <div className={commonStyles.iconWrapper}>{icon}</div>
        <p className={commonStyles.title}>{title}</p>
        <CustomTooltip customText={textTooltip} direction={'bottom'} disabled={!textTooltip}>
          {note}
        </CustomTooltip>
      </div>
      <button type={'button'} className={commonStyles.redirectButton}>
        {textButton}
      </button>
    </div>
  );
};
