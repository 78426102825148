import React, { useCallback, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { Controller, useFormContext } from 'react-hook-form';
import AutosizeInput from 'react-input-autosize';
import { useDispatch, useSelector } from 'react-redux';
import { debounce } from 'lodash';

import classNames from 'classnames';
import styles from '../../styles/components/navbar.module.scss';
import { Icon32Plus, IconEye, IconMinus } from '../components/UI/icons';

import { Button, CustomTooltip, Toast, UnpublishModal } from '../components/UI';
import { IFunnel } from '../interfaces';
import { automationActionAsync } from '../modules/automations/store/AutomationActionAsync';
import { AutomationRequestState, IAutomation } from '../modules/automations/store/AutomationState';
import { funnelStates } from '../modules/funnels/funnels/funnel-item/FunnelItem';
import { funnelActionAsync } from '../modules/funnels/funnels/store/FunnelActionAsync';
import { settingsActions } from '../store/settings';

interface IProps {
  funnel: IFunnel | null;
  automation?: IAutomation;
  handleBlur: (e: React.FormEvent<HTMLInputElement>) => void;
}

export const Navbar: React.FC<IProps> = React.memo(({ funnel, automation, handleBlur, children }) => {
  const [isUnpublish, setIsUnpublish] = useState(false);
  const dispatch = useDispatch();
  const { zoomValue } = useSelector((state) => state.settings);
  const { control } = useFormContext();

  const onZoomIn = () => {
    let newZoom = Math.round((Math.round(zoomValue.value * 10) / 10) * 100 + 10) / 100;
    if (newZoom > 2) {
      newZoom = 2;
    }
    debouncedChangeHandler(newZoom);
  };

  const onZoomOut = () => {
    let newZoom = Math.round((Math.round(zoomValue.value * 10) / 10) * 100 - 10) / 100;
    if (newZoom < 0.5) {
      newZoom = 0.5;
    }
    debouncedChangeHandler(newZoom);
  };

  const changeHandler = useCallback(
    (newZoom: number) => {
      dispatch(settingsActions.setZoomValue({ value: newZoom, isDefault: false }));
    },
    [dispatch]
  );

  const debouncedZoom = React.useMemo(() => debounce(changeHandler, 100), [changeHandler]);

  const debouncedChangeHandler = useCallback(debouncedZoom, [debouncedZoom]);

  const setDefaultZoom = useCallback(() => {
    dispatch(settingsActions.setZoomValue({ value: 1, isDefault: true }));
  }, [dispatch]);

  useEffect(() => {
    setDefaultZoom();
  }, [setDefaultZoom]);

  const handleKeyDown = async (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.currentTarget.blur();
    }
  };

  const onChangeStatus = () => {
    if (funnel) {
      switch (funnel.state) {
        case 1:
          setIsUnpublish(true);
          break;
        case 4:
          dispatch(funnelActionAsync.updateFunnelState(funnel.id, funnelStates[2].action));
          break;
        case 2:
          dispatch(funnelActionAsync.updateFunnelState(funnel.id, funnelStates[1].action));
          break;
      }
    }
    if (automation) {
      switch (automation.state) {
        case 1:
          setIsUnpublish(true);
          break;
        case 4:
          dispatch(automationActionAsync.updateAutomationState(automation.id, AutomationRequestState.draft));
          break;
        case 2:
          dispatch(
            automationActionAsync.updateAutomationState(
              automation.id,
              AutomationRequestState.published,
              undefined,
              (data) => {
                Toast(
                  'error',
                  data ? 'This automation can not be published because was detected a loop' : 'Something went wrong!'
                );
              }
            )
          );
          break;
      }
    }
  };

  const buttonText = () => {
    const state = funnel || automation;
    if (state) {
      switch (state.state) {
        case 1:
          return 'Unpublish';
        case 2:
          return 'Publish';
        case 4:
          return 'Restore';
      }
    }
    return 'Publish';
  };

  const onUnpublish = () => {
    if (funnel) {
      dispatch(funnelActionAsync.updateFunnelState(funnel.id, funnelStates[2].action));
      onClose();
    }
    if (automation) {
      dispatch(automationActionAsync.updateAutomationState(automation.id, AutomationRequestState.draft));
      onClose();
    }
  };

  const onClose = () => {
    setIsUnpublish(false);
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.editName}>
          <div className={classNames('d-flex border-left pl-4', styles.editWrapper)}>
            <CustomTooltip direction="bottom" customText={'Rename'}>
              <Controller
                render={({ field: { onChange, value } }) => (
                  <AutosizeInput
                    inputClassName={classNames('form-control', styles.funnelName)}
                    defaultValue={value || ''}
                    onChange={onChange}
                    onKeyDown={handleKeyDown}
                    onBlur={handleBlur}
                    maxLength={50}
                  />
                )}
                defaultValue={''}
                name={'navbarName'}
                control={control}
              />
            </CustomTooltip>
            {children}
          </div>
        </div>
        <div className={styles.funnelActionsToolbar}>
          {/*<div className={classNames('px-4 border-left-0', styles.actionItem)}>
            <CustomTooltip direction="bottom">
              <button type={'button'} className={classNames('btn btn-icon', styles.icon)}>
                <IconGear />
              </button>
            </CustomTooltip>
          </div>
          <div className={classNames('px-4', styles.actionItem)}>
            <CustomTooltip direction="bottom">
              <button type={'button'} className={classNames('btn btn-icon', styles.icon)}>
                <IconPlay />
              </button>
            </CustomTooltip>
          </div>*/}
          <div className={classNames('px-4', styles.actionItem)}>
            <span className={classNames('mr-3', styles.zoomContainer)}>
              <IconMinus onClick={onZoomOut} className={styles.zoomBtn} />
              <input
                className={styles.input}
                onChange={() => {}}
                value={`${zoomValue ? Math.round((Math.round(zoomValue.value * 100) / 100) * 100) : 100}%`}
                readOnly
                disabled
              />
              <Icon32Plus onClick={onZoomIn} className={styles.zoomBtn} />
            </span>
            <button type={'button'} className={classNames('btn btn-icon', styles.icon)}>
              <IconEye onClick={setDefaultZoom} />
            </button>
          </div>
          {/*<div className={classNames('px-4', styles.actionItem)}>
            <CustomTooltip direction="bottom">
              <button type={'button'} className={classNames('btn btn-icon', styles.icon)}>
                <IconLink />
              </button>
            </CustomTooltip>
          </div>*/}
          <div className={classNames('pl-2 border-left-0', styles.actionItem)}>
            <Button onClick={onChangeStatus} withIcon={false}>
              {buttonText()}
            </Button>
          </div>
        </div>
      </div>
      {ReactDOM.createPortal(
        <UnpublishModal
          description={`By editing published ${
            funnel ? 'funnel' : 'automation'
          } it will move to drafts and all processes will be stopped.`}
          onUnpublish={onUnpublish}
          title={
            <>
              {funnel ? 'Funnel' : 'Automation'}{' '}
              <span>
                {funnel?.name}
                {automation?.name}
              </span>{' '}
              will be unpublished
            </>
          }
          onClose={onClose}
          isShowed={isUnpublish}
          text={funnel ? 'Unpublish funnel' : 'Unpublish automation'}
        />,
        document.querySelector('#root') as Element
      )}
    </>
  );
});
