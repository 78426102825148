import { useCallback, useState } from 'react';

import styles from './recovery-password.module.scss';

import { RecoveryFormWrapper } from '../../../auth/recovery-password/recovery-form-wrapper/RecoveryFormWrapper';
import { RecoverySuccess } from '../../../auth/recovery-password/recovery-success/RecoverySuccess';
import { StudentCabinetBg } from '../../common/student-cabinet-bg/StudentCabinetBg';

export const RecoveryPassword = () => {
  const [isFormRecovery, setIsFormRecovery] = useState(true);

  const onSuccessScreen = useCallback(() => {
    setIsFormRecovery(false);
  }, []);

  return (
    <StudentCabinetBg>
      <div className={styles.container}>
        {isFormRecovery ? <RecoveryFormWrapper onSuccessScreen={onSuccessScreen} /> : <RecoverySuccess />}
      </div>
    </StudentCabinetBg>
  );
};
