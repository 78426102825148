import { createSelector } from 'reselect';
import { map } from 'lodash';

import { IGeneralSettings } from '../../../interfaces';
import { IAppState } from '../../../store';
import { ICourse } from '../../lms/courses/store/CourseState';

export const StudentCabinetSelector = () =>
  createSelector(
    (state: IAppState) => state.studentCourses.studentCourses,
    (state: IAppState) => state.studentCourses.loadState,
    (state: IAppState) => state.studentCourses.cabinetSettings,
    (state: IAppState) => state.studentCourses.loadCabinetSettingsState,
    (studentCourses, loadState, cabinetSettings, loadCabinetSettingsState) => {
      let res: Array<
        Pick<
          ICourse,
          | 'id'
          | 'industry'
          | 'lessons_counts'
          | 'lessons_pass'
          | 'last_lesson'
          | 'organization_id'
          | 'settings'
          | 'state'
          | 'title'
        > & {
          organization: IGeneralSettings;
        }
      > = [];

      map(studentCourses.courses, (x) => {
        const organizationSettings = studentCourses.organizations.find((y) => y.id === x.organization_id)?.settings;
        if (organizationSettings) {
          res.push({ ...x, organization: organizationSettings });
        }
      });
      const newCourses = res.filter((course) => course.lessons_pass === 0);
      const inProgressCourses = res.filter(
        (course) => course.lessons_pass > 0 && course.lessons_pass < course.lessons_counts
      );
      const completedCourses = res.filter(
        (course) => course.lessons_pass > 0 && course.lessons_pass >= course.lessons_counts
      );

      return {
        courses: {
          new: newCourses,
          inProgress: inProgressCourses,
          completed: completedCourses
        },
        loadState,
        cabinetSettings,
        loadCabinetSettingsState
      };
    }
  );
