import classNames from 'classnames';
import styles from './image-placeholder.module.scss';

import { Icon32Picture } from '../../icons';

interface IProps {
  className?: string;
}

export const ImagePlaceholder = ({ className }: IProps) => {
  return (
    <div className={classNames(styles.imagePlaceholder, className)}>
      <Icon32Picture width={64} height={64} />
    </div>
  );
};
