import { IPaginationParams, IRun, IRunEvent } from '../interfaces';
import { AutomationStatusResponse, IAutomation } from '../modules/automations/store/AutomationState';
import { baseFetch } from './BaseFetch';

export const getAutomation = async (id: string) => {
  return await baseFetch<{}, IAutomation>(`/automations/${id}`, {}, 'GET', true);
};

export const getAutomations = async (token?: string) => {
  return await baseFetch<{}, IAutomation[]>('/automations', {}, 'GET', true, {
    Authorization: `Bearer ${token}`
  });
};

export const updateAutomationState = async ({ id, state }: { id: string; state: string | null }) => {
  return baseFetch<{}, IAutomation, AutomationStatusResponse>(`/automations/${id}/${state}`, {}, 'PUT', true);
};

export const createAutomation = async () => {
  return await baseFetch<{}, IAutomation>('/automations', {}, 'POST', true);
};

export const deleteAutomation = async (id: string) => {
  return await baseFetch<{}, null>(`/automations/${id}`, {}, 'DELETE', true);
};

export const updateAutomation = async (id: string, data: { name: string }) => {
  return await baseFetch<{}, IAutomation>(`/automations/${id}`, { ...data }, 'PUT', true);
};

export default class AutomationsService {
  static async getAutomation(id: string) {
    return await baseFetch<{}, IAutomation>(`/automations/${id}`, {}, 'GET', true);
  }

  static async getAutomations(token?: string) {
    return await baseFetch<{}, IAutomation[]>('/automations', {}, 'GET', true, {
      Authorization: `Bearer ${token}`
    });
  }

  static async updateAutomationState({ id, state }: { id: string; state: string | null }) {
    return baseFetch<{}, IAutomation, AutomationStatusResponse>(`/automations/${id}/${state}`, {}, 'PUT', true);
  }

  static async createAutomation() {
    return await baseFetch<{}, IAutomation>('/automations', {}, 'POST', true);
  }

  static async deleteAutomation(id: string) {
    return await baseFetch<{}, null>(`/automations/${id}`, {}, 'DELETE', true);
  }

  static async updateAutomation(id: string, data: { name: string }) {
    return await baseFetch<{}, IAutomation>(`/automations/${id}`, { ...data }, 'PUT', true);
  }

  static async getListAutomationRuns(
    pagination: IPaginationParams,
    request?: {
      automation_id?: string;
      contact_id?: string;
    }
  ) {
    return await baseFetch<{}, IRun[]>('/automations/runs', { ...pagination, ...request }, 'GET', true);
  }

  static async getListAutomationEventsRuns(request?: { session_id?: string; contact_id?: string }) {
    return await baseFetch<{}, IRunEvent[]>('/automations/events', { ...request }, 'GET', true);
  }
}
