import { memo, useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import AutosizeInput from 'react-input-autosize';

import classNames from 'classnames';
import styles from './editable-title.module.scss';

interface IProps {
  title?: string;
}

export const EditableTitle = memo(({ title }: IProps) => {
  const { control, setValue } = useFormContext();

  useEffect(() => {
    if (title) {
      setValue('title', title);
    }
  }, [setValue, title]);

  return (
    <Controller
      render={({ field: { onChange, value, onBlur } }) => (
        <div className={styles.container}>
          <AutosizeInput
            inputClassName={classNames(styles.input, 'form-control')}
            onChange={onChange}
            maxLength={5000}
            placeholder={'New Legal Section'}
            placeholderIsMinWidth
            onClick={(e) => e.stopPropagation()}
            value={value}
            onBlur={onBlur}
          />
        </div>
      )}
      defaultValue={title || 'New Legal Section'}
      name={'title'}
      control={control}
    />
  );
});
