import { AnchorSpec } from 'jsplumb';

import {
  IAutomationNodeBuilder,
  IFunnelNodeBuilder,
  NodeEndpoint,
  PaletteAutomationElementTypes,
  PaletteElementTypes
} from '../../../interfaces';
import { BuilderType, BuilderTypeEnum } from './store/BuilderActionAsync';

export const EndpointsLocation = (
  endpoint: NodeEndpoint,
  node: IAutomationNodeBuilder<PaletteAutomationElementTypes> | IFunnelNodeBuilder<PaletteElementTypes>,
  index: number,
  builderType: BuilderType
): AnchorSpec => {
  if (
    [
      PaletteElementTypes['ACTION_EMAIL'],
      PaletteAutomationElementTypes['ACTION_TAG'],
      PaletteAutomationElementTypes['ACTION_LIST'],
      PaletteAutomationElementTypes['PROCESSOR_DELAY'],
      PaletteElementTypes['ACTION_DELAY']
    ].includes(node.type)
  ) {
    return endpoint.is_input ? [0, 0, 0, 0, 0, 38] : [1, 0, 0, 0, 0, 38];
  }
  if (endpoint.is_input) {
    return ['Left'];
  } else if (node.type === PaletteElementTypes.ACTION_AB) {
    return builderType === BuilderTypeEnum.funnel
      ? [0.75, index === 0 ? 0.25 : 0.75, 0, 0]
      : [1, index === 0 ? 0.3 : 0.7, 0, 0];
  } else if (node.type === PaletteAutomationElementTypes['PROCESSOR_CONDITION']) {
    return [1, 1, 0, 0, -34, endpoint.type === 'true' ? -56 : -28];
    // } else if (
    //   node.type === PaletteAutomationElementTypes.PROCESSOR_DELAY &&
    //   node?.payload?.period?.period_type &&
    //   ['fixed_time_of_day', 'fixed_period'].includes(node?.payload?.period?.period_type)
    // ) {
    //   return [1, 0, 0, 0, 0, 83 + 30 * index];
  } else if (node.type === PaletteAutomationElementTypes['PROCESSOR_RANGE']) {
    return [1, 0, 0, 0, -35, 94 + 36 * index];
  } else if (/pages\./.test(node.type) && node.endpoints && node.endpoints.length > 2) {
    return [1, 0, 0, 0, -28, 68 + 35 * index];
  } else {
    return ['Right'];
  }
};
