import styles from './filter-comments.module.scss';

import { FilterList } from '../filter-list/FilterList';

export const FilterComments = () => {
  return (
    <div className={styles.container}>
      <div className={styles.title}>Filter comments</div>
      <FilterList />
    </div>
  );
};
