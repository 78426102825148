import { reducerWithInitialState } from 'typescript-fsa-reducers';

import { addDataToTable, removeDataFromTable } from '../../../../helpers';
import { LoadState, newState } from '../../../../store';
import { IStudentsState, StudentsInitialState } from './StudentListState';
import { StudentsActions } from './StudentsActions';

export const studentsReducer = reducerWithInitialState<IStudentsState>(StudentsInitialState)
  .case(StudentsActions.getStudentsList.started, (state) => ({
    ...state,
    loadState: LoadState.firstLoad,
    error: null
  }))
  .case(StudentsActions.getStudentsList.done, (state, { result }) => {
    return newState(state, {
      ...result.meta,
      students: result.data,
      loadState: result.data.length > 0 ? LoadState.allIsLoaded : LoadState.idle,
      error: null
    });
  })
  .case(StudentsActions.getStudentsList.failed, (state, { error }) => ({
    ...state,
    loadState: LoadState.error,
    error
  }))

  .case(StudentsActions.getStudent.started, (state) => ({
    ...state,
    error: null
  }))
  .case(StudentsActions.getStudent.done, (state, { result }) => {
    const newStudents = state.students.map((x) =>
      x.id === result.id
        ? {
            ...x,
            ...result,
            courses_count: result.courses.length
          }
        : x
    );
    return newState(state, {
      students: newStudents,
      error: null,
      loadState: LoadState.allIsLoaded
    });
  })
  .case(StudentsActions.getStudent.failed, (state, { error }) => ({
    ...state,
    loadState: LoadState.error,
    error
  }))

  .case(StudentsActions.createStudent.started, (state) => ({
    ...state,
    loadState: LoadState.firstLoad,
    error: null
  }))
  .case(StudentsActions.createStudent.done, (state, { result }) => {
    const { page, total } = addDataToTable(state.page_size, state.total);
    return newState(state, {
      ...state,
      total: total,
      page: page,
      loadState: LoadState.allIsLoaded,
      error: null,
      students: [...state.students, result]
    });
  })
  .case(StudentsActions.createStudent.failed, (state, { error }) => ({
    ...state,
    loadState: LoadState.error,
    error
  }))

  .case(StudentsActions.editStudent.started, (state) => ({
    ...state,
    loadState: LoadState.firstLoad,
    error: null
  }))
  .case(StudentsActions.editStudent.done, (state, { result }) => {
    const updateStudents = state.students.map((x) => (x.id === result.id ? { ...x, ...result } : x));
    return newState(state, {
      ...state,
      students: updateStudents,
      loadState: LoadState.allIsLoaded,
      error: null
    });
  })
  .case(StudentsActions.editStudent.failed, (state, { error }) => ({
    ...state,
    loadState: LoadState.error,
    error
  }))

  .case(StudentsActions.deleteStudent.started, (state) => ({
    ...state,
    loadState: LoadState.firstLoad,
    error: null
  }))
  .case(StudentsActions.deleteStudent.done, (state, { params }) => {
    const newStudentList = state.students.filter((x) => x.id !== params.id);
    const { page, total } = removeDataFromTable(state.page_size, state.total);
    return newState(state, {
      ...state,
      total: total,
      page: page,
      students: newStudentList,
      loadState: LoadState.allIsLoaded,
      error: null
    });
  })
  .case(StudentsActions.deleteStudent.failed, (state, { error }) => ({
    ...state,
    loadState: LoadState.error,
    error
  }))

  .case(StudentsActions.getStudentsOfList.started, (state) => ({
    ...state,
    loadState: LoadState.firstLoad,
    error: null
  }))
  .case(StudentsActions.getStudentsOfList.done, (state, { result }) => {
    return newState(state, {
      ...result.meta,
      students: result.data,
      loadState: result.data.length > 0 ? LoadState.allIsLoaded : LoadState.idle,
      error: null
    });
  })
  .case(StudentsActions.getStudentsOfList.failed, (state, { error }) => ({
    ...state,
    loadState: LoadState.error,
    error
  }));
