import React, { memo, useCallback, useEffect } from 'react';
import { FieldErrors, FormProvider, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import classNames from 'classnames';
import styles from './policy-item.module.scss';
import { IconTrash } from '../../../../components/UI/icons';

import { IPolicy, PolicyContent, PolicyContentTypeEnum } from '../../../../interfaces/policy';
import SettingsActions from '../../common/settings-actions/SettingsActions';
import { SettingsCollapseBlock } from '../../common/settings-collapse-block/SettingsCollapseBlock';
import { AddContent } from '../common/add-content/AddContent';
import { AddLink } from '../common/add-link/AddLink';
import { EditableTitle } from '../common/editable-title/EditableTitle';
import { PolicyActionAsync } from '../store/PolicyActionAsync';
import { PolicyContext } from './PolicyContext';

export interface IFormInputs {
  content: PolicyContent<PolicyContentTypeEnum> | null;
  title: string;
  show_in_cabinet: boolean;
}

const schema = yup.object().shape({
  content: yup.object().shape({
    type: yup.string(),
    link: yup
      .string()
      .nullable()
      .when('type', {
        is: (val: string) => val === 'external',
        then: yup.string().url('Must be a valid URL').notRequired().nullable()
      }),
    delta: yup.object()
  })
});

interface IProps {
  policy: IPolicy | null;
  open?: boolean;
  onDelete?(): void;
  onCreate?(): void;
}

export const PolicyItem = memo(({ policy, open, onDelete, onCreate }: IProps) => {
  const dispatch = useDispatch();
  const methods = useForm<IFormInputs>({
    resolver: yupResolver(schema),
    defaultValues: {
      content: policy?.content || null,
      show_in_cabinet: policy?.show_in_cabinet || false,
      title: policy?.title
    },
    reValidateMode: 'onChange',
    shouldUnregister: true
  });

  const select = useCallback(
    (key: PolicyContentTypeEnum) => {
      methods.setValue('content.type', key, { shouldDirty: true });
    },
    [methods]
  );

  const onSaveCallback = useCallback(
    (data: IPolicy) => {
      methods.reset({ content: data.content, title: data.title, show_in_cabinet: data.show_in_cabinet });
    },
    [methods]
  );
  // console.log('%c⇒ methods.formState.defaultValues', 'color: #C3E88D', methods.formState.defaultValues);
  // console.log('%c⇒ methods.formState.dirtyFields', 'color: #89DDF7', methods.formState.dirtyFields);
  // console.table(methods.watch());

  const onSave = useCallback(
    (data: IFormInputs) => {
      if (policy === null && data.content) {
        dispatch(
          PolicyActionAsync.createPolicy(
            {
              show_in_cabinet: data.show_in_cabinet,
              document_type: 'custom',
              title: data.title,
              content: data.content
            },
            onCreate
          )
        );
      } else {
        if (policy && data.content?.type === PolicyContentTypeEnum.local) {
          dispatch(
            PolicyActionAsync.editPolicy(
              policy.id,
              {
                content: {
                  type: data.content.type,
                  delta: data.content.delta
                },
                title: data.title,
                show_in_cabinet: data.show_in_cabinet
              },
              onSaveCallback
            )
          );
        }
        if (policy && data.content?.type === PolicyContentTypeEnum.external) {
          dispatch(
            PolicyActionAsync.editPolicy(
              policy.id,
              {
                content: {
                  type: data.content.type,
                  link: data.content.link
                },
                title: data.title,
                show_in_cabinet: data.show_in_cabinet
              },
              onSaveCallback
            )
          );
        }
      }
    },
    [dispatch, onCreate, onSaveCallback, policy]
  );

  const onCancel = useCallback(() => {
    policy ? methods.reset() : onDelete?.();
  }, [methods, onDelete, policy]);

  const onError = (errors: FieldErrors<IFormInputs>) => {
    console.log('%c⇒ errors', 'color: #89DDF7', errors);
  };

  useEffect(() => {
    methods.setValue('content', policy?.content || null);
  }, [methods, policy?.content, policy?.show_in_cabinet]);

  const deletePolicy = (e: React.SyntheticEvent) => {
    e.stopPropagation();
    if (policy) {
      dispatch(PolicyActionAsync.deletePolicy(policy?.id));
    } else {
      onDelete?.();
    }
  };

  return (
    <PolicyContext.Provider value={{ policy, onSelect: select }}>
      <FormProvider {...methods}>
        <div className={styles.container}>
          <input type={'hidden'} {...methods.register('content.type')} />
          <input type={'hidden'} {...methods.register('show_in_cabinet')} />
          <SettingsCollapseBlock
            title={
              policy && policy.document_type !== 'custom' ? policy?.title : <EditableTitle title={policy?.title} />
            }
            open={open}
            headerChildren={
              !policy || policy?.document_type === 'custom' ? (
                <button className={classNames('btn btn-icon btn-warning', styles.delete)} onClick={deletePolicy}>
                  <IconTrash />
                </button>
              ) : undefined
            }
            className={'overflow-hidden'}
          >
            <AddLink isActive={methods.watch('content.type') === 'external'} />
            <AddContent isActive={methods.watch('content.type') === 'local'} />
            {methods.formState.errors.content?.type && (
              <div className={classNames(styles.error, 'error')}>Choose one of the options</div>
            )}
            <CSSTransition in={methods.formState.isDirty || !policy} timeout={300} classNames={styles} unmountOnExit>
              <SettingsActions
                methods={methods}
                onCancel={onCancel}
                onSave={onSave}
                onError={onError}
                className={styles.actions}
              />
            </CSSTransition>
          </SettingsCollapseBlock>
        </div>
      </FormProvider>
    </PolicyContext.Provider>
  );
});
