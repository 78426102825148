import { memo, useCallback, useEffect, useState } from 'react';

import styles from './viewQuiz.module.scss';

import { SimpleQuillEditor } from '../../../../../components/quill-editor/simple-quill-editor/SimpleQuillEditor';
import { useDerivedState } from '../../../../../helpers/use-derived-state';
import { IQuizQuestion, QuizStatusProgress } from '../../../../../interfaces/quiz';
import { QuizPreview } from './preview/QuizPreview';
import { Question } from './question/Question';
import { QuizHeader } from './quiz-header/QuizHeader';
import { ResultQuiz } from './resultQuiz/ResultQuiz';
import { ResultQuizModeratedFailed } from './resultQuiz/to-moderation/ResultQuizModeratedFailed';
import { ResultQuizModeratedSuccess } from './resultQuiz/to-moderation/ResultQuizModeratedSuccess';
import { ResultQuizToModeration } from './resultQuiz/to-moderation/ResultQuizToModeration';
import { SkippedQuiz } from './skipped-quiz/SkippedQuiz';
import { StepResult } from './step/StepResult';
import { IViewQuiz } from './store/QuizState';

interface IProps {
  quiz_state: QuizStatusProgress;
  quiz: Nullable<IViewQuiz>;
  selectQuestion?: Nullable<IQuizQuestion>;

  onTryAgain(): void;
}

export const ViewQuiz = memo(({ quiz, quiz_state, selectQuestion, onTryAgain }: IProps) => {
  const [showSkipped, setShowSkipped] = useState(false);
  const isAnswered = quiz?.quiz.questions.find((question) => question.choices !== null);
  const notStarted = !isAnswered && !showSkipped;
  const readyQuiz =
    isAnswered && !notStarted && !showSkipped && (quiz_state === 'finished' || quiz_state === 'in_progress');
  const [runQuiz, setRunQuiz] = useDerivedState(false, [quiz_state]);

  useEffect(() => {
    setRunQuiz(!!readyQuiz);
  }, [readyQuiz, setRunQuiz]);

  useEffect(() => {
    if (quiz?.state === 'skipable') {
      setShowSkipped(true);
    }
  }, [quiz?.state]);

  const onRetakeQuiz = useCallback(() => {
    onTryAgain();
    setShowSkipped(false);
  }, [onTryAgain]);

  const continueQuiz = useCallback(() => {
    setShowSkipped(false);
  }, []);

  const isLastQuestionCompleted = !!quiz?.quiz.questions[quiz?.quiz.questions.length - 1].choices;

  if (notStarted && !readyQuiz && !runQuiz) {
    return (
      <div className={'bg-white'}>
        <QuizPreview setRunQuiz={setRunQuiz} />
      </div>
    );
  } else if (showSkipped) {
    return (
      <div className={'bg-white'}>
        <SkippedQuiz onClick={quiz?.result ? onRetakeQuiz : continueQuiz} result={quiz?.result} />
      </div>
    );
  } else if (quiz) {
    return (
      <>
        {quiz.quiz.general_question_is_visible && (
          <div className={styles.general}>
            <SimpleQuillEditor
              value={quiz.quiz.general_question || ''}
              options={{ readOnly: true, className: 'p-0', fixedToolbar: false }}
            />
          </div>
        )}
        <QuizHeader
          selectQuestion={selectQuestion}
          moderate_answers={quiz.quiz.moderate_answers}
          questions={quiz.quiz.questions}
          result={quiz.result}
        >
          <StepResult isLastQuestionCompleted={isLastQuestionCompleted} state={quiz.state} />
        </QuizHeader>
        {selectQuestion && (
          <div className={'bg-white p-4'}>
            <Question question={selectQuestion} />
          </div>
        )}
        {selectQuestion === null && quiz.result && !quiz.quiz.moderate_answers && (
          <div className={styles.container}>
            <ResultQuiz
              result={quiz.result}
              questions={quiz.quiz.questions}
              onTryAgain={onTryAgain}
              state={quiz.state}
            />
          </div>
        )}

        {selectQuestion === null &&
          quiz.quiz.moderate_answers &&
          (quiz.state === 'pending_moderation' || quiz.state === 'in_progress') && (
            <div className={styles.container}>
              <ResultQuizToModeration />
            </div>
          )}
        {selectQuestion === null && quiz.quiz.moderate_answers && quiz.state === 'fail' && (
          <div className={styles.container}>
            <ResultQuizModeratedFailed onTryAgain={onTryAgain} />
          </div>
        )}
        {selectQuestion === null && quiz.quiz.moderate_answers && quiz.state === 'success' && (
          <div className={styles.container}>
            <ResultQuizModeratedSuccess />
          </div>
        )}
      </>
    );
  }

  return <></>;
});
