import { memo, useCallback, useRef } from 'react';
import { useAudioPosition } from 'react-use-audio-player';

import styles from './audio-progress-bar.module.scss';

interface IProps {
  duration: number;
}

export const AudioProgressBar = memo(({ duration }: IProps) => {
  const { seek, position } = useAudioPosition({
    highRefreshRate: true
  });

  const seekBarElem = useRef<HTMLDivElement>(null);

  const goToPosition = useCallback(
    (event: React.MouseEvent) => {
      if (seekBarElem.current) {
        const elementWidth = seekBarElem.current.clientWidth;
        const percent = 1 - (elementWidth - event.nativeEvent.offsetX) / elementWidth;
        seek(percent * duration);
      }
    },
    [duration, seek]
  );

  const barWidth = (position / duration) * (seekBarElem.current?.clientWidth || 0);

  return (
    <div className={'w-100'}>
      <div className={styles.audioSeekBar} ref={seekBarElem} onClick={goToPosition}>
        <div style={{ width: Number.isNaN(barWidth) ? 0 : barWidth }} className={styles.tick} />
      </div>
    </div>
  );
});
