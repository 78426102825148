import { useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { SelectCallback } from 'react-bootstrap/helpers';
import { useHistory } from 'react-router-dom';

import styles from './settings-header.module.scss';
import { IconChevronDown, IconChevronUp } from '../../components/UI/icons';

import { CustomToggle } from '../../components/UI/dropmenu/CustomToggle';
import { settingsPaths } from './main/Settings';

interface IProps {
  title: string;
}

export const SettingsHeader = ({ title }: IProps) => {
  // const dispatch = useDispatch();
  const history = useHistory();
  // const { organizations } = useSelector((state) => state.account.account);
  const [showDialog, setShowDialog] = useState(false);
  // const [loading, setLoading] = useState(false);

  const onCloseDialog = () => {
    setShowDialog((_x) => false);
  };
  const onToggleDialog = () => {
    setShowDialog((x) => !x);
  };

  // const paymentCallback = (path: string) => {
  //   window.location.assign(path);
  //   setLoading(false);
  // };

  // const onOpenBilling = () => {
  //   if (organizations.length > 0) {
  //     setLoading(true);
  //     dispatch(SettingCourseActionAsync.paymentMethod(window.location.href, paymentCallback));
  //   }
  // };

  const onSelect: SelectCallback = (eventKey) => {
    if (eventKey) {
      const selected = settingsPaths(false).find((path) => path.link === eventKey);
      if (selected?.helpLink) {
        window.open(selected.helpLink);
        // } else if (eventKey === 'billing') {
        //   onOpenBilling();
      } else {
        history.push(eventKey);
      }
    }
  };

  return (
    <>
      <Dropdown show={showDialog} drop={'down'} onToggle={onToggleDialog} alignRight={true} className={'mb-4'}>
        <Dropdown.Toggle as={CustomToggle}>
          <span className={styles.title}>{title}</span>
          {showDialog ? <IconChevronUp className={styles.arrow} /> : <IconChevronDown className={styles.arrow} />}
        </Dropdown.Toggle>
        <Dropdown.Menu onClick={onCloseDialog}>
          <div className={styles.container}>
            {settingsPaths(false).map((x, index) => (
              <Dropdown.Item
                key={index}
                onSelect={onSelect}
                eventKey={x.link}
                bsPrefix={styles.item}
                active={x.title === title}
              >
                <span className={styles.icon}>{x.img}</span>
                <span className={styles.name}>{x.title}</span>
              </Dropdown.Item>
            ))}
          </div>
        </Dropdown.Menu>
      </Dropdown>
      {/*{loading && <Loader />}*/}
    </>
  );
};
