import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import classNames from 'classnames';
import styles from './product-form-title.module.scss';
import { Icon16NewWindow } from '../../../../../components/UI/icons';

import { ItemStatus } from '../../../../../components/itemStatus/ItemStatus';
import { coursePaths } from '../../../../lms/courses/routes/CourseRoutes';
import { IProduct, ProductType } from '../../store/ProductsState';
import { ProductTypeLabel } from '../product-type/ProductTypeLabel';

interface IProps {
  product?: IProduct;
  type?: ProductType;
}

export const ProductFormTitle = ({ product, type }: IProps) => {
  const { fields } = useSelector((state) => state.fields);
  const courseRelationFiled = fields.filter((field) => field.scope === 'course_relation');
  const stateField = fields.filter((field) => field.scope === 'state');

  return (
    <div className={styles.container}>
      <div className={'d-flex align-items-center'}>
        {type === 'regular' ? (
          <>
            <h3 className={classNames(styles.title, 'mr-2')}>{product ? 'Product preview' : 'Add product'}</h3>
            <ProductTypeLabel type={'regular'} />
          </>
        ) : (
          <>
            <h3 className={classNames(styles.title, 'mr-2')}>Course preview</h3>
            <ItemStatus label={product?.fields[stateField[0].id]} />
            <Link
              to={coursePaths.courseLessons(product?.fields[courseRelationFiled[0].id])}
              className={styles.open}
              target={'_blank'}
            >
              Open
              <Icon16NewWindow className={'ml-1'} />
            </Link>
          </>
        )}
      </div>
    </div>
  );
};
