import { lazy } from 'react';

import { IRoutes } from '../../../interfaces';
import { AutomationsHeader } from '../../automations/common/automations-header/AutomationsHeader';

const MessagesList = lazy(() => import('../messages-list/MessagesList'));

export const messagesPaths = {
  messages: '/messages'
};

export const MessagesRoutes: IRoutes[] = [
  {
    path: messagesPaths.messages,
    exact: true,
    header: () => <AutomationsHeader />,
    main: () => (
      <div className={'content'}>
        <MessagesList />
      </div>
    )
  }
];
