import React, { useEffect, useState } from 'react';
import BootstrapTable, { ColumnFormatter, HeaderFormatter, RowEventHandlerProps } from 'react-bootstrap-table-next';
import ToolkitProvider, { InjectedSearchProps } from 'react-bootstrap-table2-toolkit';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import moment from 'moment';

import classNames from 'classnames';
import styles from './list-lists.module.scss';
import { Icon32ListView, IconPencilOnPaper, IconTrash } from '../../../../components/UI/icons';

import { EmptyList } from '../../../../components/empty-list/EmptyList';
import { NoSearchResult } from '../../../../components/no-search-result/NoSearchResult';
import { CustomTooltip, Loader, ModalDelete } from '../../../../components/UI';
import { ToastMessage } from '../../../../components/UI/toast/ToastMessage';
import { getUUID } from '../../../../helpers';
import { LoadState } from '../../../../store';
import { CRMPaths } from '../../routes/CRMRoutes';
import { CreateListModal } from '../modals/createListModal/CreateListModal';
import { RenameListModal } from '../modals/renameListModal/RenameListModal';
import { ListsActionAsync } from '../store/ListsActionAsync';
import { columnsData, defaultSorted, FormatterType, RowType, selectRow, sortCaret } from './config/listTableConfig';
import { ListTypeIcon } from './listTypeIcon/ListTypeIcon';
import { TopPanel } from './topPanel/TopPanel';

export const ListLists = () => {
  const history = useHistory();
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedLists, setSelectedLists] = useState<string[]>([]);
  const [selectedList, setSelectedList] = useState<string>();
  const [tableData, setTableData] = useState<RowType[]>([]);

  const searchString = useSelector((state) => state.settings.searchString);
  const dispatch = useDispatch();
  const { lists, loadState } = useSelector((state) => state.lists);

  let tableSearchProps: InjectedSearchProps | null = null;

  useEffect(() => {
    const data: RowType[] = lists.map((list) => ({
      id: list.id,
      listName: list.title,
      createdOn: moment(list.date_create).format('MMM DD, YYYY'),
      numberOfContacts: list.client_count || 0,
      data: list
    }));
    setTableData(data);
  }, [lists]);

  useEffect(() => {
    dispatch(ListsActionAsync.getLists());
  }, [dispatch]);

  useEffect(() => {
    if (tableSearchProps) {
      tableSearchProps.onSearch(searchString.trim());
    }
  }, [searchString, tableSearchProps]);

  const onCreateList = () => {
    setShowCreateModal(true);
  };

  const onDeleteList = (e: React.SyntheticEvent) => {
    e.stopPropagation();
    const id = e.currentTarget.id;
    const currentRow = tableData.find((row) => row.id === id);
    if (currentRow?.listName) {
      setSelectedLists([currentRow.listName]);
      setSelectedList(id);
      setShowDeleteModal(true);
    }
  };

  const deleteListHandler = () => {
    if (selectedList) {
      setShowDeleteModal(false);
      dispatch(
        ListsActionAsync.deleteList(selectedList, () => {
          toast.success(<ToastMessage message={'List successfully deleted!'} />);
        })
      );
    }
  };

  const onEditList = (e: React.SyntheticEvent) => {
    e.stopPropagation();
    const id = e.currentTarget.id;
    setSelectedList(id);
    setShowEditModal(true);
  };

  const columnFormatter: FormatterType<RowType> = (cell, _row, _index) => {
    if (cell === '') {
      return '-';
    }
    return cell;
  };

  const nameFormatter: FormatterType<RowType> = (cell, row) => {
    return (
      <div className={styles.link}>
        <ListTypeIcon type={row.data.type} className={styles.icon} />
        {cell}
      </div>
    );
  };

  const headerFormatter: HeaderFormatter = (column, colIndex, { sortElement }) => {
    return (
      <>
        {column.text}
        {sortElement}
      </>
    );
  };

  const rowActionsFormatter: FormatterType<RowType> = (cell, row) => {
    const isMainList = row.data.type === 'main';
    return (
      <div className={'d-flex align-items-center h-100'}>
        <div className="value">{cell}</div>
        <div className={styles.buttons}>
          <button type={'button'} id={row.id} className={'btn btn-icon'} onClick={onEditList}>
            <IconPencilOnPaper />
          </button>
          {/*<button*/}
          {/*  type={'button'}*/}
          {/*  className={'btn btn-icon'}*/}
          {/*  onClick={(e) => {*/}
          {/*    e.stopPropagation();*/}
          {/*  }}*/}
          {/*>*/}
          {/*  <IconStarOutline />*/}
          {/*</button>*/}
          <CustomTooltip customText={'This list can’t be deleted'} disabled={!isMainList} direction={'top'}>
            <button type={'button'} id={row.id} className={'btn btn-icon'} onClick={onDeleteList} disabled={isMainList}>
              <IconTrash />
            </button>
          </CustomTooltip>
        </div>
      </div>
    );
  };

  const extendsColumns = columnsData.map((item, index) => {
    let formatter: ColumnFormatter<RowType, any, string> = columnFormatter;
    const isLatestColumn = columnsData.length === index + 1;
    if (item.dataField === 'listName') {
      formatter = nameFormatter;
    }
    if (isLatestColumn) {
      formatter = rowActionsFormatter;
    }
    return {
      ...item,
      formatter,
      classes: classNames('col-4', item.classes, { [styles.actions]: isLatestColumn }),
      headerClasses: 'col-4',
      headerFormatter: headerFormatter,
      sort: true,
      sortCaret: sortCaret
    };
  });

  const onCloseCreateModal = () => {
    setShowCreateModal(false);
  };

  const onCloseEditModal = () => {
    setShowEditModal(false);
  };

  const onCloseDeletePopup = () => {
    setShowDeleteModal(false);
  };

  const selectedListsPreview = () => {
    return (
      <div className={styles.selectedLists}>
        <ul className={'list-unstyled mb-0'}>
          {selectedLists.map((list) => (
            <li key={getUUID()}>&middot; {list}</li>
          ))}
        </ul>
      </div>
    );
  };

  const noDataIndication = () => {
    return <NoSearchResult searchString={searchString} />;
  };

  const rowEvents: RowEventHandlerProps<RowType> = {
    onClick: (e, row) => {
      history.push(CRMPaths.crmListId(row.id));
    }
  };

  return (
    <>
      {loadState !== LoadState.allIsLoaded && <Loader />}
      {tableData.length !== 0 && (
        <>
          <TopPanel onCreateList={onCreateList} />
          <ToolkitProvider search columns={extendsColumns} keyField={'id'} data={tableData}>
            {({ baseProps, searchProps }) => {
              tableSearchProps = searchProps;
              return (
                <BootstrapTable
                  {...baseProps}
                  classes={styles.table}
                  rowClasses={styles.row}
                  headerClasses={styles.row}
                  selectRow={selectRow}
                  hover
                  bordered={false}
                  rowEvents={rowEvents}
                  defaultSorted={defaultSorted}
                  defaultSortDirection={'asc'}
                  noDataIndication={noDataIndication}
                />
              );
            }}
          </ToolkitProvider>
        </>
      )}
      {loadState === LoadState.allIsLoaded && tableData.length === 0 && (
        <EmptyList
          onClick={onCreateList}
          button={'Create new list'}
          title={'Your contacts can be divided into lists'}
          description={'First, you need to create a new contact list'}
          icon={<Icon32ListView width={64} height={64} />}
        />
      )}

      <CreateListModal onCloseModal={onCloseCreateModal} show={showCreateModal} />
      <RenameListModal onCloseModal={onCloseEditModal} show={showEditModal} listId={selectedList} />
      <ModalDelete
        onClose={onCloseDeletePopup}
        isShowed={showDeleteModal}
        onDelete={deleteListHandler}
        title={`You are trying to delete ${selectedLists.length} list:`}
        description={
          <>
            {selectedListsPreview()}
            <div className={styles.text}>
              All the contacts from the selected list will be kept in the "Main list". If you would like to remove
              contacts as well, please remove the contacts first.
            </div>
          </>
        }
        modalParams={{ size: 'sm' }}
      />
    </>
  );
};
