import { IMeta } from '../../../../interfaces';
import { LoadState } from '../../../../store';
import { IField, IPayload } from '../../../crm/field';
import { ITag } from '../../../crm/tag/store/TagState';
import { ICourse, ICourseNode, ICourseSettings } from '../../courses/store/CourseState';

interface IStudentListPayload {
  course_author_first_name?: string;
  course_author_last_name?: string;
  course_id?: string;
  course_status?: 'published';
}

export type StudentEnrollmentStatus = 'success.redirect_to_course' | 'error.email_doesnt_match';

export type StudentEnrollment =
  | {
      payload: {
        email: string;
        message: string;
      };
      status: Exclude<StudentEnrollmentStatus, 'success.redirect_to_course'>;
    }
  | {
      payload: {
        course_id: string;
      };
      status: Exclude<StudentEnrollmentStatus, 'error.email_doesnt_match'>;
    };

export interface IStudentList {
  date_create: number;
  date_update: number;
  entity: 'student';
  fields: IStudentListField[];
  id: string;
  is_deleted?: false;
  organization_id: string;
  payload: IPayload<IStudentListPayload>;
  title: string;
  type: 'student_main' | 'enrolment_list';
  client_count: number;
}

export interface IStudentListField {
  field_id: string;
  order: number;
  field?: IField;
}

export interface IStudentListState {
  loadState: LoadState;
  error: Error | null;
  studentList: Partial<IStudentList>;
}

export const StudentListInitialState: IStudentListState = {
  loadState: LoadState.firstLoad,
  error: null,
  studentList: {}
};

export type CourseStatus = 'pending' | 'enrolled' | 'started' | 'in_progress' | 'draft';

export interface IStudent {
  courses_count: number;
  date_create: number;
  date_update: number;
  entity: 'student';
  fields: IStudentField;
  id: string;
  is_deleted: boolean;
  organization_id: string;
  tags?: ITag[];
  enrolment_date?: number;
  progress?: number;
  courses: IStudentCourse[];
  user_id: string | null;
}

export interface IStudentCourse {
  course: ICourse;
  course_id: string;
  enrolment_date: number;
  progress: number | null;
  title?: string;
  settings?: ICourseSettings;
  last_open_event_at: string | null;
}

export interface IStudentField {
  [id: string]: any;
}

export interface IStudentsState extends IMeta {
  students: IStudent[];
  loadState: LoadState;
  error: Error | null;
}

export const StudentsInitialState: IStudentsState = {
  loadState: LoadState.firstLoad,
  error: null,
  students: [],
  page: 1,
  page_size: 25,
  total: 0,
  total_pages: 1
};

export interface IStudentsListsState {
  studentsLists: IStudentList[];
  loadState: LoadState;
  error: Error | null;
}

export const StudentsListsInitialState: IStudentsListsState = {
  studentsLists: [],
  loadState: LoadState.firstLoad,
  error: null
};

export type StudentListRow = {
  id: string;
  courses_count: number;
  date_create: number;
  progress?: number;
  enrolment_date?: number;
  status?: string;
  data: IStudent;
};

export interface IStudentAnalytics {
  hierarchy: ICourseNode[];
  total_lessons: 0;
  passed_lessons: 0;
}
