import { memo, useCallback, useState } from 'react';
import { FormControl } from 'react-bootstrap';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { map, omit } from 'lodash';

import stylesOffer from '../common/commonOffer.module.scss';
import styles from './price.module.scss';
import { IconChevronDown, IconChevronUp, IconGear, IconWarning } from '../../../../../components/UI/icons';

import { InfoMessage } from '../../../../../components/UI/infoMessage/InfoMessage';
import { setEmptyOrStr } from '../../../../../helpers/text';
import { StripeSubscriptionPlans } from '../../../../settings/billing/store';
import { IProduct } from '../../../products/store/ProductsState';
import { OfferUnsavedChanges } from '../../common/unsaved/OfferUnsavedChanges';
import { IOffer, OfferPaymentMethod } from '../../store/OffersState';
import { InputLabel } from '../common/input-label/InputLabel';
import { RadioButtonElement } from '../common/radio-button/RadioButtonElement';
import UpdateChange from '../common/update-change/UpdateChange';
import { useCreateOffer } from '../use-create-offer';
import { Free } from './free/Free';
import { OneTime } from './oneTime/OneTime';
import { OrderBump } from './orderbump/OrderBump';
import { PaymentConnect } from './price-payment/payment-connect/PaymentConnect';
import { ChoosePaymentSystem } from './price-payment/price-payout/ChoosePaymentSystem';
import { priceSchema } from './priceSchema';

export type PriceData = Pick<
  IOffer,
  | 'setting_price_display_overwrite'
  | 'order_bump_checkbox_text'
  | 'order_bump_description'
  | 'order_bump_headline'
  | 'order_bump_is_active'
  | 'order_bump_old_price'
  | 'order_bump_price'
  | 'order_bump_products'
  | 'price'
  | 'id'
>;

interface IProps extends PriceData {
  getOffer: () => void;
}

export const Price = memo((props: IProps) => {
  const { stripe, paynet, paypal } = useSelector((state) => state.general.paymentAccount);
  const [showPaymentDialog, setShowPaymentDialog] = useState(false);

  const { subscription } = useSelector((state) => state.billing);
  const [selectProducts, setSelectProducts] = useState<IProduct[]>(props.order_bump_products || []);

  const methods = useForm<Omit<PriceData, 'order_bump_products'> & { order_bump_products: string[] }>({
    defaultValues: {
      ...props,
      order_bump_products: map(props.order_bump_products, 'id')
    },
    shouldUnregister: true,
    resolver: yupResolver(priceSchema)
  });

  const setPaymentSystem = (payment: OfferPaymentMethod | null) => {
    if (payment) {
      methods.setValue('price.payment_method', payment, { shouldDirty: true, shouldValidate: true });
    }
  };

  const onReset = useCallback(
    (omitFields: IProps[] = []) => {
      const omitData = omit<IProps, any>(props, omitFields);
      if (omitFields.length > 0) {
        methods.reset(omitData);
      } else {
        methods.reset({
          ...omitData,
          order_bump_products: map(props.order_bump_products, 'id')
        });
      }
    },
    [methods, props]
  );

  const onCancelOptional = useCallback(() => {
    setSelectProducts(props.order_bump_products || []);
  }, [props.order_bump_products]);

  const { onCancel, onClose, onSave, showUnsavedModal, loadState } = useCreateOffer(
    props.id,
    props.getOffer,
    onReset,
    methods.formState.isDirty,
    onCancelOptional
  );

  const watchDataPriceType = useWatch({ control: methods.control, name: 'price.type' });
  const isActiveOrderBump = useWatch({
    control: methods.control,
    name: 'order_bump_is_active'
  });

  const isFreePlan = subscription?.plan?.product === StripeSubscriptionPlans.everactFree;

  return (
    //@ts-ignore
    <FormProvider {...methods}>
      {stripe.state === 'non_integrated' && !paypal && !paynet && watchDataPriceType !== 'free' && <PaymentConnect />}
      {(stripe.state !== 'non_integrated' || paynet || paypal) && watchDataPriceType !== 'free' && (
        <div className={styles.paymentContainer}>
          <ChoosePaymentSystem
            showPaymentDialog={showPaymentDialog}
            setPaymentDialogShow={setShowPaymentDialog}
            setPaymentSystem={setPaymentSystem}
          >
            <div className={'d-flex align-items-center'}>
              <IconGear className={styles.iconSetting} />
              Receive payments to: &nbsp;
              <div className={styles.selectedContainer}>
                <input
                  className={styles.payment}
                  readOnly
                  onClick={(e) => e.preventDefault()}
                  placeholder={'Select...'}
                  {...methods.register('price.payment_method', {
                    setValueAs: setEmptyOrStr
                  })}
                />
                {showPaymentDialog ? (
                  <IconChevronUp className={styles.iconChevron} />
                ) : (
                  <IconChevronDown className={styles.iconChevron} />
                )}
              </div>
            </div>
          </ChoosePaymentSystem>
        </div>
      )}
      <div className={styles.container}>
        <div className={styles.title}>Price settings</div>
        <Free isActive={watchDataPriceType === 'free'} isFreePlan={isFreePlan}>
          <RadioButtonElement name={'price.type'} label={'Free'} value={'free'} disable={isFreePlan} />
        </Free>
        <OneTime isActive={watchDataPriceType === 'one_time'} className={'mt-2'}>
          <RadioButtonElement name={'price.type'} label={'One time payment'} value={'one_time'} />
        </OneTime>
        <RadioButtonElement
          name={'price.type'}
          label={'Recurring'}
          value={'recurring'}
          className={styles.disableRadio}
          disable={true}
        />
        <RadioButtonElement
          name={'price.type'}
          label={'Split'}
          value={'split'}
          className={styles.disableRadio}
          disable={true}
        />
        <InfoMessage
          show={!!methods.formState.errors.price?.type}
          text={(methods.formState.errors.price?.type as any)?.message}
          className={stylesOffer.errorContainer}
          customIcon={<IconWarning className={stylesOffer.warningIcon} />}
        />
        <InputLabel label={'Price display override'} classContainer={'pt-4'}>
          <FormControl
            placeholder={'Customize the display of the price on the order form'}
            {...methods.register('setting_price_display_overwrite')}
            className={'form-control form-control-md'}
            type={'text'}
          />
        </InputLabel>
      </div>
      <div className={'mt-3'}>
        <OrderBump
          isActiveOrderBump={!!isActiveOrderBump}
          dataPrice={props}
          selectProducts={selectProducts}
          setSelectProducts={setSelectProducts}
        />
      </div>
      <UpdateChange
        showUpdate={methods.formState.isDirty}
        loadState={loadState}
        methods={methods}
        onCancel={onCancel}
        onSave={onSave}
      />

      <OfferUnsavedChanges
        onClose={onClose}
        onCancel={onCancel}
        onSave={onSave}
        show={showUnsavedModal}
        methods={methods}
      />
    </FormProvider>
  );
});
