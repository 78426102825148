import { createSelector } from 'reselect';

import { IAppState } from '../../../../store';

export const ListSelector = () =>
  createSelector(
    (_: IAppState, listId: string) => listId,
    (state: IAppState) => state.lists.lists,
    (state: IAppState) => state.lists.loadState,
    (state: IAppState) => state.lists.error,
    (listId, lists, loadState, error) => {
      return {
        list: lists.find((list) => list.id === listId),
        loadState,
        error
      };
    }
  );
