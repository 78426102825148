import { memo, ReactNode } from 'react';
import { Draggable, DraggingStyle, NotDraggingStyle } from 'react-beautiful-dnd';

import classNames from 'classnames';

import { getUUID } from '../../helpers';

const getItemStyle = (draggableStyle?: DraggingStyle | NotDraggingStyle, isDraggable = true): React.CSSProperties => ({
  marginTop: '0.5rem',
  paddingLeft: !isDraggable ? '0.5rem' : 0,
  userSelect: 'none',
  ...draggableStyle
});

interface IProps {
  id: string;
  index: number;
  handleElement?: ReactNode;
  children: ReactNode;
  draggableContainerClassName?: string;
  isDraggingClassName?: string;
  handleClassName?: string;
  isDraggingHandleClassName?: string;
  isDragging?: boolean;
}

export const NewDraggableElement = memo(
  ({
    id,
    index,
    handleElement,
    draggableContainerClassName,
    handleClassName,
    isDraggingHandleClassName,
    isDraggingClassName,
    children,
    isDragging = true
  }: IProps) => {
    return (
      <Draggable key={id} draggableId={getUUID()} index={index} isDragDisabled={!isDragging}>
        {(provided, snapshot): JSX.Element => (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...(handleElement ? undefined : provided.dragHandleProps)}
            style={getItemStyle(provided.draggableProps.style, isDragging)}
            className={classNames(
              draggableContainerClassName,
              isDraggingClassName && { [isDraggingClassName]: snapshot.isDragging }
            )}
          >
            {isDragging && (
              <div
                {...provided.dragHandleProps}
                className={classNames(
                  handleClassName,
                  isDraggingHandleClassName && { [isDraggingHandleClassName]: snapshot.isDragging }
                )}
              >
                {handleElement}
              </div>
            )}
            {children}
          </div>
        )}
      </Draggable>
    );
  }
);
