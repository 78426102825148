import classNames from 'classnames';
import styles from './organization-img.module.scss';

import { Image } from '../../../components/UI/image-components/image/Image';
import { IFile } from '../../../interfaces';

interface IProps {
  organizationImg: IFile | null;
  themeLogo?: IFile | null;
  organizationName: string;
  width?: number;
  height?: number;
  className?: string;
  textClassName?: string;
}

export const OrganizationImg = ({
  organizationImg,
  organizationName,
  themeLogo = null,
  className,
  textClassName,
  width = 48,
  height = 48
}: IProps) => {
  return (
    <div className={classNames(styles.container, className)}>
      <Image
        imgProps={{ height, width }}
        size={{ height, width }}
        bucket_name={themeLogo?.bucket_name || organizationImg?.bucket_name}
        object_name={themeLogo?.object_name || organizationImg?.object_name}
        className={styles.image}
      />
      {!themeLogo && <div className={classNames(styles.name, textClassName)}>{organizationName.substring(0, 1)}</div>}
    </div>
  );
};
