import { IPaginationParams, IParams } from '../interfaces';
import { IContact, IContactField } from '../modules/crm/contact/store/ContactListState';
import { IList } from '../modules/crm/lists/store/ListsState';
import { IStudent } from '../modules/lms/students/store/StudentListState';
import { baseFetch } from './BaseFetch';

export const createContactInList = async (id: string, fields: IContactField, tags?: string[]) => {
  return await baseFetch<{ fields: IContactField; tags?: string[] }, IContact>(
    `/contacts/lists/${id}/contacts`,
    { fields, tags },
    'POST',
    true
  );
};

export const createContact = async (fields: IContactField, tags?: string[]) => {
  return await baseFetch<{ fields: IContactField; tags?: string[] }, IContact>(
    '/contacts',
    { fields, tags },
    'POST',
    true
  );
};

export const getContact = async (id: string) => {
  return await baseFetch<{}, IContact>(`/contacts/${id}`, {}, 'GET', true);
};

export const getListOfContacts = async (params?: IParams) => {
  return await baseFetch<IParams, IContact[]>('/contacts', params ?? {}, 'GET', true);
};

export const getContactsList = async (id: string, params?: IParams) => {
  return await baseFetch<IPaginationParams | {}, IContact[]>(
    `/contacts/lists/${id}/contacts`,
    params ?? {},
    'GET',
    true
  );
};

export const getListIdsOfContact = async (id: string) => {
  return await baseFetch<{}, string[]>(`/contacts/${id}/lists`, {}, 'GET', true);
};

export const editContact = async (id: string, fields: IContactField, tags?: string[]) => {
  return await baseFetch<{ fields: IContactField; tags?: string[] }, IContact>(
    `/contacts/${id}`,
    { fields, tags },
    'PUT',
    true
  );
};

export const deleteContact = async (id: string) => {
  await baseFetch<{}, {}>(`/contacts/${id}`, {}, 'DELETE', true);
};

export const editContactList = async (contactId: string, listIds: string[]) => {
  return await baseFetch<{ lists: string[] }, IContact>(
    `/contacts/${contactId}/lists`,
    { lists: listIds },
    'PUT',
    true
  );
};

export const getContactsConfig = async () => {
  return await baseFetch<{}, IList>('/contacts/config', {}, 'GET', true);
};

export const exportContacts = async () => {
  return await baseFetch<{}, string>('/contacts/export', {}, 'POST', true);
};

export const exportContactsFromList = async (list_key: string) => {
  return await baseFetch<{}, string>(`/contacts/lists/${list_key}/export`, {}, 'POST', true);
};

export const createStudentFromContact = async (contactId: string) => {
  return await baseFetch<{}, IStudent>(`/contacts/${contactId}/student`, {}, 'POST', true);
};
