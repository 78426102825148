import { AttachmentField, AudioField, VideoField } from './fileUpload';
import { IFile } from './general';
import { IQuiz, QuizStatusProgress } from './quiz';

export interface ICourseBlock {
  id: string;
  is_hidden: boolean;
  order: number;
  parent_id: string;
  title?: string;
  setting_custom_code?: string;
  setting_custom_code_enabled?: boolean;
  is_passed: boolean;
}

export interface ICourseBlockText extends ICourseBlock {
  text?: string;
  type: BlockType.TEXT;
}

export interface ICourseBlockEmbed extends ICourseBlock {
  code?: string;
  type: BlockType.EMBED;
}

export interface ICourseBlockAttachmentFiles extends ICourseBlock {
  attachments?: AttachmentField[];
  type: BlockType.BLOCK_ATTACHMENTS;
}

export interface ICourseBlockVideo extends ICourseBlock {
  type: BlockType.VIDEO;
  video?: VideoField;
}

export interface ICourseBlockAudio extends ICourseBlock {
  type: BlockType.AUDIO;
  audio?: AudioField;
}

export interface ICourseBlockPlaylist extends ICourseBlock {
  type: BlockType.PLAYLIST;
}

export interface ICourseBlockPresentation extends ICourseBlock {
  type: BlockType.PRESENTATION;
}

export interface ICourseBlockSurvey extends ICourseBlock {
  type: BlockType.SURVEY;
}

export interface ICourseBlockQuiz extends ICourseBlock {
  quiz_id: string;
  quiz_state: QuizStatusProgress;
  quiz?: IQuiz;
  type: BlockType.QUIZ;
}

export enum LessonTypeBlock {
  quiz = 'quiz',
  text = 'text',
  video = 'video',
  playlist = 'playlist',
  survey = 'survey',
  presentation = 'presentation',
  attachments = 'attachments'
}

export type CommonCourseBlock =
  | ICourseBlock &
      (
        | {
            type: BlockType.QUIZ;
            quiz_id: string;
            quiz_state: QuizStatusProgress;
            quiz?: IQuiz;
          }
        | {
            type: BlockType.TEXT;
            text?: string;
          }
        | {
            type: BlockType.EMBED;
            code?: string;
          }
        | {
            type: BlockType.VIDEO;
            video?: VideoField;
          }
        | {
            type: BlockType.PLAYLIST;
          }
        | {
            type: BlockType.PRESENTATION;
          }
        | {
            type: BlockType.SURVEY;
          }
        | {
            type: BlockType.BLOCK_ATTACHMENTS;
            attachments: AttachmentField[];
          }
        | { type: BlockType.AUDIO; audio?: AudioField }
      );

export type CourseNodeType = 'folder' | 'lesson' | 'step';

export enum BlockType {
  VIDEO = 'block:video',
  PLAYLIST = 'block:playlist',
  QUIZ = 'block:quiz',
  SURVEY = 'block:survey',
  PRESENTATION = 'block:presentation',
  TEXT = 'block:text',
  EMBED = 'block:embed',
  AUDIO = 'block:audio',
  BLOCK_ATTACHMENTS = 'block:attachments'
}

export type CourseBlockOperation = 'add' | 'edit' | 'delete';

export interface ICourseBlockRequest {
  id: string;
  type: `${BlockType}`;
  title?: string;
  order?: number;
}

export interface ICourseBlockCUD extends ICourseBlockRequest {
  text?: string;
  is_hidden?: boolean;
  quiz?: IQuiz;
}

export interface ICourseBlockDelete extends Pick<ICourseBlockRequest, 'id' | 'type'> {}

export interface ICourseNodePayload {
  title?: string;
  order?: number;
  parent_id?: string | null;
  setting_custom_code?: string | null | undefined;
  setting_custom_code_enabled?: boolean;
}
