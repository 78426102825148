import { ReactNode } from 'react';
import { Dropdown } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import styles from './choose-payment-system.module.scss';
import { PaynetSVG, PaypalShortSVG, StripeShortSVG } from '../../../../../../../components/UI/icons';

import { DropMenu } from '../../../../../../../components/UI';
import { SettingsPaths } from '../../../../../../settings/routes/SettingsRoutes';
import { OfferPaymentMethod } from '../../../../store/OffersState';
import { PricePaymentItem } from './price-payment-item/PricePaymentItem';

interface IProps {
  setPaymentSystem?: (payment: OfferPaymentMethod) => void;
  showPaymentDialog: boolean;
  setPaymentDialogShow: (x: boolean) => void;
  children: ReactNode;
}

export const ChoosePaymentSystem = ({
  showPaymentDialog,
  setPaymentSystem,
  setPaymentDialogShow,
  children
}: IProps) => {
  const history = useHistory();
  const { paynet, stripe, paypal } = useSelector((state) => state.general.paymentAccount);
  const onCloseDialog = () => {
    setPaymentDialogShow(false);
  };
  const onToggleDialog = () => {
    setPaymentDialogShow(!showPaymentDialog);
  };

  const onSelect = (key: OfferPaymentMethod) => {
    if (key === 'paynet' && paynet && !setPaymentSystem) {
      window.open('https://paynet.md/');
    }
    if (key === 'paynet' && paynet && setPaymentSystem) {
      setPaymentSystem('paynet');
    }
    if (key === 'paynet' && !paynet) {
      history.push(SettingsPaths.payment);
    }
    if (key === 'stripe' && stripe.state === 'done' && !setPaymentSystem) {
      window.open('https://dashboard.stripe.com/payments');
    }
    if (key === 'stripe' && stripe.state === 'done' && setPaymentSystem) {
      setPaymentSystem('stripe');
    }
    if (key === 'stripe' && stripe.state !== 'done') {
      history.push(SettingsPaths.payment);
    }
    if (key === 'paypal' && paypal && setPaymentSystem) {
      setPaymentSystem('paypal');
    }
    if (key === 'paypal' && !paypal) {
      history.push(SettingsPaths.payment);
    }
    if (key === 'paypal' && paypal && !setPaymentSystem) {
      window.open('https://www.paypal.com/');
    }
  };

  return (
    <DropMenu show={showPaymentDialog} drop={'down'} onToggle={onToggleDialog} node={children}>
      <Dropdown.Menu className={styles.selected} onClick={onCloseDialog}>
        <Dropdown.Item
          className={styles.dropItem}
          eventKey={'stripe'}
          onSelect={(key) => onSelect(key as OfferPaymentMethod)}
        >
          <PricePaymentItem
            logoSVG={<StripeShortSVG />}
            title={'Stripe'}
            statusConnect={stripe.state === 'done' ? 'connected' : 'notConnected'}
          />
        </Dropdown.Item>
        <Dropdown.Item
          className={styles.dropItem}
          eventKey={'paynet'}
          onSelect={(key) => onSelect(key as OfferPaymentMethod)}
        >
          <PricePaymentItem
            logoSVG={<PaynetSVG className={styles.paynet} />}
            title={'Paynet'}
            statusConnect={paynet ? 'connected' : 'notConnected'}
          />
        </Dropdown.Item>
        <Dropdown.Item
          className={styles.dropItem}
          eventKey={'paypal'}
          onSelect={(key) => onSelect(key as OfferPaymentMethod)}
        >
          <PricePaymentItem
            logoSVG={<PaypalShortSVG />}
            title={'Paypal'}
            statusConnect={paypal ? 'connected' : 'notConnected'}
          />
        </Dropdown.Item>
      </Dropdown.Menu>
    </DropMenu>
  );
};
