import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { map, pick } from 'lodash';

import { useCourseContext } from '../courses/common/sidebar/CourseContext';
import { LessonSelector } from '../courses/store/LessonSelector';

export const useLesson = () => {
  const { courseId, nodeId } = useCourseContext();
  const lessonMemo = useMemo(() => LessonSelector(courseId, nodeId), [courseId, nodeId]);
  const lesson = useSelector((state) => lessonMemo(state));
  const lecture = useSelector((state) => state.courses.lectures.find((o) => o.id === nodeId));

  const initialData = useMemo(
    () =>
      map(lesson?.payload || [], (o) => {
        return pick(o, [
          'id',
          'title',
          'order',
          'is_hidden',
          'text',
          'type',
          'video',
          'quiz',
          'code',
          'audio',
          'attachments'
        ]);
      }).sort((a, b) => a.order! - b.order!),
    [lesson?.payload]
  );

  const customCodeInitial = useMemo(
    () => ({
      code: lecture?.setting_custom_code,
      enabled: lecture?.setting_custom_code_enabled
    }),
    [lecture?.setting_custom_code, lecture?.setting_custom_code_enabled]
  );

  return { initialData, customCodeInitial };
};
