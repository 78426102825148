import { ChangeEvent, useState } from 'react';
import { FormCheck, FormGroup } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';

interface IProps {
  isEnrolled: boolean;
  textEnrolled: string;
  textUnEnrolled: string;
}

export const EnrollInCourse = ({ isEnrolled, textEnrolled, textUnEnrolled }: IProps) => {
  const [isChecked, setIsChecked] = useState(isEnrolled);
  const { setValue } = useFormContext();
  const onChangeValue = (e: ChangeEvent<HTMLInputElement> | null) => {
    setIsChecked(e?.currentTarget.value === 'enroll');
    setValue('is_enrolled', e?.currentTarget.value === 'enroll');
  };

  return (
    <FormGroup controlId="enroll-in-course">
      <FormCheck custom className={'radio-button py-1'} type={'radio'}>
        <FormCheck.Input
          className="custom-control-input"
          type={'radio'}
          id={'enroll'}
          onChange={onChangeValue}
          value={'enroll'}
          checked={isChecked}
        />
        <FormCheck.Label className="custom-control-label" htmlFor={'enroll'}>
          {textEnrolled}
        </FormCheck.Label>
      </FormCheck>
      <FormCheck custom className={'radio-button py-1'} type={'radio'}>
        <FormCheck.Input
          className="custom-control-input"
          type={'radio'}
          id={'unenroll'}
          onChange={onChangeValue}
          value={'unenroll'}
          checked={!isChecked}
        />
        <FormCheck.Label className="custom-control-label" htmlFor={'unenroll'}>
          {textUnEnrolled}
        </FormCheck.Label>
      </FormCheck>
    </FormGroup>
  );
};
