import { imageUrl, setDefaultFavicon, setDefaultTitle } from '../components/UI/image-components/image/Image';
import { IError, IGeneralSettings } from '../interfaces';
import { addBgColor, applyTheme, generatePalette, RGBToHSL, rgbToObj } from './theme';

export const getSettingsCallback = (settings: IGeneralSettings, onStopLoading: () => void) => {
  onStopLoading();
  if (settings.theme_brand_color) {
    const { r, g, b } = rgbToObj(settings.theme_brand_color);
    const [h, s, l] = RGBToHSL({ r, g, b });
    applyTheme(generatePalette(h, s, l));

    const themeColor = document.getElementById('theme-color');

    if (themeColor) {
      themeColor.setAttribute('content', settings.theme_brand_color);
    }
  }

  if (settings.theme_background_color) {
    applyTheme(addBgColor(settings.theme_background_color));
  }

  if (settings.general_title) {
    document.title = settings.general_title;
  } else {
    setDefaultTitle();
  }

  if (settings.theme_logo) {
    const favicon = document.getElementById('favicon');
    const url = imageUrl({
      bucket_name: settings.theme_logo.bucket_name,
      object_name: settings.theme_logo.object_name,
      size: {
        width: 16,
        height: 16
      }
    });
    if (favicon) {
      favicon.setAttribute('href', url);
    }
  } else {
    setDefaultFavicon();
  }
};

export const getSettingsError = (error: IError, onStopLoading: () => void) => {
  setDefaultFavicon();
  setDefaultTitle();
  onStopLoading();
  console.log('%c⇒ error', 'color: #89DDF7', error);
};
