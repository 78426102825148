import { memo } from 'react';

import styles from './domain-item-modal.module.scss';
import { IconUnlink } from '../../../../../components/UI/icons';

import { Button, ModalActionComponent } from '../../../../../components/UI';

interface IProps {
  title: string;
  description: string;
  show: boolean;
  onClose: () => void;
  onDisconnect: () => void;
}

export const DomainRemoveModal = memo(({ onClose, onDisconnect, show, description, title }: IProps) => {
  return (
    <ModalActionComponent
      iconType={'warning'}
      iconStyles={styles.iconRemove}
      show={show}
      onCloseModal={onClose}
      title={title}
    >
      <div className={styles.description}>{description}</div>
      <div className={styles.container}>
        <Button btnStyle={'transparent'} onClick={onClose} className={'mr-3'} withIcon={false}>
          Cancel
        </Button>
        <Button btnStyle={'warning'} className={styles.disconnect} onClick={onDisconnect} withIcon={false}>
          Disconnect <IconUnlink className={'ml-1'} />
        </Button>
      </div>
    </ModalActionComponent>
  );
});
