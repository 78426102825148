import { reducerWithInitialState } from 'typescript-fsa-reducers';

import { commonErrorHandler, commonFirstLoadHandler } from '../../../../helpers/common-reducer-handlers';
import { LoadState, newState } from '../../../../store';
import { ImpersonationActions } from './ImpersonationActions';
import { IImpersonationState, ImpersonationInitialState } from './ImpersonationState';

export const impersonationReducer = reducerWithInitialState<IImpersonationState>(ImpersonationInitialState)
  .case(ImpersonationActions.setPayload.started, commonFirstLoadHandler)
  .case(ImpersonationActions.setPayload.failed, commonErrorHandler)
  .case(ImpersonationActions.setPayload.done, (state, { result }) => {
    return newState(state, {
      payload: {
        ...state.payload,
        ...result
      },
      error: null,
      loadState: LoadState.idle
    });
  })
  .case(ImpersonationActions.reset.started, commonFirstLoadHandler)
  .case(ImpersonationActions.reset.failed, commonErrorHandler)
  .case(ImpersonationActions.reset.done, (state) => {
    return newState(state, {
      payload: {},
      error: null,
      loadState: LoadState.idle
    });
  });
