import { useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { keyBy } from 'lodash';

import classnames from 'classnames';
import styles from './range-canvas.module.scss';

import { useNodePayload } from '../../../../../hooks/use-node-payload';
import { PaletteAutomationElementTypes } from '../../../../../interfaces';
import { IRangeIntervals } from '../../../../../interfaces/node-builder-payload/automation-processors/range';
import { RangeCanvasItem } from './RangeCanvasItem';

interface IProps {
  id: string;
}

export const RangeNodeItem = ({ id }: IProps) => {
  const node = useNodePayload<PaletteAutomationElementTypes.PROCESSOR_RANGE>(id);
  const { setValue, register, unregister } = useFormContext();
  const watchData = useWatch<Record<string, IRangeIntervals>>({ name: id });

  useEffect(() => {
    register(id);
    setValue(id, keyBy(node?.payload?.range?.intervals, 'id'));
    return () => {
      unregister(id);
    };
  }, [id, node?.payload?.range?.intervals, register, setValue, unregister]);

  return (
    <div className={styles.wrapper}>
      {watchData &&
        Object.values(watchData).map(
          (x, index) =>
            x.id && <RangeCanvasItem index={index} key={index} start={x.start} end={x.end} id={x.id} nodeId={id} />
        )}
      <div className={styles.notInRange}>
        Not in range
        <div className={classnames(styles.endpointView, styles.endpointCSS, styles.notIn)} />
      </div>
    </div>
  );
};
