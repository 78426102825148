import { FieldValues, useFormContext } from 'react-hook-form';

import classNames from 'classnames';
import styles from './fixedPeriod.module.scss';

import { useNodePayload } from '../../../../../../../hooks/use-node-payload';
import { PaletteAutomationElementTypes } from '../../../../../../../interfaces';
import { IDelayProcessorAutomationPayload } from '../../../../../../../interfaces/node-builder-payload/automation-processors/delay';
import { DatePeriodItem } from './DatePeriodItem';

interface IProps {
  nodeId: string;
}

export const FixedPeriod = ({ nodeId }: IProps) => {
  const { formState } = useFormContext<FieldValues & IDelayProcessorAutomationPayload>();
  const node = useNodePayload<PaletteAutomationElementTypes.PROCESSOR_DELAY>(nodeId);

  return (
    <>
      <div className={'d-flex justify-content-between'}>
        <DatePeriodItem
          label={'days'}
          name={'period.days'}
          isError={!!formState.errors.period?.days}
          data={Number.isInteger(node?.payload.period?.days) ? node?.payload.period?.days : 1}
        />
        <DatePeriodItem
          label={'hours'}
          name={'period.hours'}
          isError={!!formState.errors.period?.hours}
          data={node?.payload.period?.hours}
        />
        <DatePeriodItem
          label={'min'}
          name={'period.minutes'}
          isError={!!formState.errors.period?.minutes}
          data={node?.payload.period?.minutes}
        />
      </div>
      {formState.errors.period &&
        Object.values(formState.errors.period).map((error: any, index) => (
          <div key={index} className={classNames('form-control invisible-control', styles.errorText)}>
            {error?.message}
          </div>
        ))}
      {/* <Form.Label className={styles.description}>Increase the countdown timer so that the timer works</Form.Label>
      <div className="d-flex justify-content-between">
        <TimeWorkItem
          title={'no earlier'}
          time={node?.payload.period?.no_earlier || '09:00'}
          name={'period.no_earlier'}
        />
        <TimeWorkItem
          title={'and no later'}
          time={node?.payload.period?.no_later || '09:00'}
          name={'period.no_later'}
        />
        <TimeWorkItem title={'timezone'} time={`UTC ${moment().format('Z')}`} isUtc name={'period.timezone'} />
      </div> */}
    </>
  );
};
