import { memo } from 'react';
import { useHistory } from 'react-router-dom';

import styles from './lesson-available-complete.module.scss';
import StudentAvailableLesson from '../../../../../../assets/svg/available-student-lesson.svg';

import { Button } from '../../../../../components/UI';
import { StudentCabinetPaths } from '../../../../student-cabinet/routes/StudentCabinetRoutes';
import { AvailableLesson } from '../availablelesson/AvailableLesson';
import { useViewCourseContext } from '../ViewCourseContext';

interface IProps {
  hasPassedTitle: string;
  hasPassedId: string;
}

export const LessonAvailableComplete = memo(({ hasPassedId, hasPassedTitle }: IProps) => {
  const history = useHistory();
  const { course } = useViewCourseContext();
  const onHandleClick = () => {
    history.push(StudentCabinetPaths.courseStudentLessons(course.id, hasPassedId));
  };

  return (
    <AvailableLesson>
      <div className={styles.container}>
        <img className={styles.image} src={StudentAvailableLesson} alt="student lesson available" />
        <div className={styles.description}>Complete the lesson:</div>
        <div className={styles.title}>{hasPassedTitle}</div>
        <Button type={'arrow-right'} onClick={onHandleClick} size={'lg'} className={'mx-auto mt-3'}>
          Go to lesson
        </Button>
      </div>
    </AvailableLesson>
  );
});
