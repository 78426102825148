import styles from './breadcrumbs.module.scss';
import stylesItem from './breadcrumbsItem.module.scss';
import { Icon16ChevronRight, Icon16Folder, Icon16Home } from '../../../../UI/icons';

import { useViewCourseContext } from '../../../../../modules/lms/students/student-course/ViewCourseContext';

interface IProps {
  title: string;
  nodeId: string | null;
  showChevron: boolean;
}

export const BreadcrumbsItem = ({ nodeId, title, showChevron }: IProps) => {
  const { onSelect } = useViewCourseContext();
  const onNavigateFolder = () => {
    if (nodeId) {
      onSelect(nodeId);
    } else {
      onSelect('');
    }
  };
  return (
    <span className={styles.containerItem} onClick={onNavigateFolder}>
      <div className={stylesItem.icon}>{nodeId ? <Icon16Folder /> : <Icon16Home />}</div>
      {<span className={styles.text}>{title}</span>}
      {showChevron && <Icon16ChevronRight className={stylesItem.iconChevron} />}
    </span>
  );
};
