import { reducerWithInitialState } from 'typescript-fsa-reducers';

import { commonErrorHandler, commonFirstLoadHandler } from '../../../../helpers/common-reducer-handlers';
import { LoadState, newState } from '../../../../store';
import { builderActions } from './BuilderActions';
import { BuilderInitialState, IBuilderState } from './BuilderState';

export const builderReducer = reducerWithInitialState<IBuilderState>(BuilderInitialState)
  .case(builderActions.addNode.started, (state) => ({ ...state, loadState: LoadState.firstLoad, error: null }))
  .case(builderActions.addNode.done, (state, { result }) => {
    return newState(state, {
      builder: { ...state.builder, nodes: [...state.builder.nodes, result] },
      loadState: LoadState.allIsLoaded,
      error: null
    });
  })
  .case(builderActions.addNode.failed, (state, { error }) => ({
    ...state,
    loadState: LoadState.error,
    error
  }))
  .case(builderActions.getNode.started, (state) => ({ ...state, loadState: LoadState.firstLoad, error: null }))
  .case(builderActions.getNode.done, (state, { result }) => {
    const node = state.builder.nodes.find((x) => x.id === result.id);
    let fullNode;
    if (node) {
      fullNode = state.builder.nodes.map((item) => (item.id === result.id ? { ...item, ...result } : item));
    } else {
      fullNode = [...state.builder.nodes, result];
    }

    return newState(state, {
      loadState: LoadState.allIsLoaded,
      error: null,
      builder: {
        ...state.builder,
        nodes: fullNode
      }
    });
  })
  .case(builderActions.getNode.failed, (state, { error }) => ({
    ...state,
    loadState: LoadState.error,
    error
  }))
  .case(builderActions.deleteNode.started, (state) => ({ ...state, loadState: LoadState.firstLoad, error: null }))
  .case(builderActions.deleteNode.done, (state, { params }) => {
    return newState(state, {
      builder: { ...state.builder, nodes: state.builder.nodes.filter((x) => x.id !== params.nodeKey) },
      loadState: LoadState.allIsLoaded,
      error: null
    });
  })
  .case(builderActions.deleteNode.failed, (state, { error }) => ({
    ...state,
    loadState: LoadState.error,
    error
  }))
  .case(builderActions.saveNode.started, (state) => ({ ...state, loadState: LoadState.needLoad, error: null }))
  .case(builderActions.saveNode.done, (state, { result }) => {
    const fullNode =
      state.builder.nodes.length === 0
        ? [result]
        : state.builder.nodes.map((item) => (item.id === result.id ? { ...result, endpoints: item.endpoints } : item));
    return newState(state, {
      builder: {
        ...state.builder,
        nodes: [...fullNode]
      },
      loadState: LoadState.idle,
      error: null
    });
  })
  .case(builderActions.saveNode.failed, (state, { error }) => ({
    ...state,
    loadState: LoadState.error,
    error
  }))
  .case(builderActions.setNodes, (state, { nodes }) => {
    return newState(state, { builder: { ...state.builder, nodes } });
  })
  .case(builderActions.setNode, (state, { node }) => {
    return newState(state, { builder: { ...state.builder, nodes: [...state.builder.nodes, node] } });
  })
  .case(builderActions.editNode, (state, { node }) => {
    const updateNodes = state.builder.nodes.map((x) => (x.id === node.id ? node : x));
    return newState(state, { builder: { ...state.builder, nodes: updateNodes } });
  })
  .case(builderActions.saveEdge.started, commonFirstLoadHandler)
  .case(builderActions.saveEdge.done, (state, { result }) => {
    const fullEdges =
      state.builder.edges.length === 0
        ? [result]
        : state.builder.edges.map((item) => (item.id === result.id ? result : item));
    return newState(state, {
      builder: {
        ...state.builder,
        edges: [...fullEdges]
      },
      loadState: LoadState.allIsLoaded,
      error: null
    });
  })
  .case(builderActions.saveEdge.failed, commonErrorHandler)
  .case(builderActions.getEndpoints.started, commonFirstLoadHandler)
  .case(builderActions.getEndpoints.done, (state, { result, params }) => {
    const nodes = state.builder.nodes.map((x) => (x.id === params.nodeId ? { ...x, endpoints: result } : x));
    if (nodes) {
      return newState(state, {
        ...state.builder,
        builder: { ...state.builder, nodes: nodes },
        error: null,
        loadState: LoadState.allIsLoaded
      });
    }
    return state;
  })
  .case(builderActions.getEndpoints.failed, commonErrorHandler)
  .case(builderActions.createEndpoint.started, commonFirstLoadHandler)
  .case(builderActions.createEndpoint.done, (state, { result, params }) => {
    const nodes = state.builder.nodes.map((x) =>
      x.id === params.nodeId ? { ...x, endpoints: x.endpoints ? [...x.endpoints, result] : [result] } : x
    );

    if (nodes) {
      return newState(state, {
        ...state.builder,
        builder: { ...state.builder, nodes: nodes },
        error: null,
        loadState: LoadState.allIsLoaded
      });
    }
    return state;
  })
  .case(builderActions.createEndpoint.failed, commonErrorHandler)
  .case(builderActions.editEndpoint.started, commonFirstLoadHandler)
  .case(builderActions.editEndpoint.done, (state, { result, params }) => {
    const node = state.builder.nodes.find((x) => x.id === params.nodeId);
    const indexNode = state.builder.nodes.findIndex((x) => x.id === params.nodeId);
    const endpoints = node?.endpoints?.map((x) => (x.id === params.endpointId ? result : x));
    if (node && endpoints) {
      node.endpoints = [...endpoints];
      state.builder.nodes[indexNode] = node;

      return newState(state, {
        builder: {
          ...state.builder
        },
        error: null,
        loadState: LoadState.allIsLoaded
      });
    }
    return state;
  })
  .case(builderActions.editEndpoint.failed, commonErrorHandler)
  .case(builderActions.deleteEndpoint.started, commonFirstLoadHandler)
  .case(builderActions.deleteEndpoint.done, (state, { params }) => {
    const node = state.builder.nodes.find((x) => x.id === params.nodeId);
    const indexNode = state.builder.nodes.findIndex((x) => x.id === params.nodeId);
    const endpoints = node?.endpoints?.filter((x) => x.id !== params.endpointId);
    if (node && endpoints) {
      node.endpoints = [...endpoints];
      state.builder.nodes[indexNode] = node;

      return newState(state, {
        builder: {
          ...state.builder
        },
        error: null,
        loadState: LoadState.allIsLoaded
      });
    }
    return state;
  })
  .case(builderActions.deleteEndpoint.failed, commonErrorHandler);
