import axios, { AxiosResponse, CancelToken } from 'axios';

import { IVimeoProject, IVimeoVideo } from '../interfaces/vimeo';

export enum MediaProvider {
  VIMEO = 'Vimeo',
  FILE = 's3'
  // here you may include any other providers
}

export type MediaItem = {
  provider: MediaProvider;
  id: string;
  status: StatusUpload;
};

export interface IUploadProps {
  fileSelected: File;
  config: {
    cancelToken?: CancelToken;
    organizationId: string;
  };
  setProgress?: (x: number) => void;
}

export type StatusUpload = 'complete' | 'error' | 'in_progress' | 'canceled';

interface IThumbnailsList {
  total: number;
  page: number;
  per_page: number;
  paging: {
    next?: string | null;
    previous?: string | null;
    first: string;
    last: string;
  };
  data: IThumbnailsData[];
}

export interface IThumbnailsSize {
  width: number;
  height: number;
  link: string;
  link_with_play_button: string;
}

export interface IThumbnailsData {
  uri: string;
  active: boolean;
  type: string;
  base_link: string;
  sizes: IThumbnailsSize[];
  resource_key: string;
  default_picture: boolean;
}

export interface ITranscodingStatus {
  progress: number;
  state: 'finishing' | 'starting' | 'standby' | 'upload_complete' | 'active' | 'ready';
  time_left: number;
}

const headerGet = {
  Authorization: `bearer ${process.env.REACT_APP_VIMEO_ACCESS_TOKEN}`
};
const headerPost = {
  Accept: 'application/vnd.vimeo.*+json;version=3.4',
  Authorization: `bearer ${process.env.REACT_APP_VIMEO_ACCESS_TOKEN}`,
  'Content-Type': 'application/json'
};

export const checkVideoTranscoding = async (videoId: string) => {
  const result = await axios.get<ITranscodingStatus>(`https://api.vimeo.com/videos/${videoId}/status`, {
    headers: headerGet
  });
  return result.data;
};

export const videoThumbnails = async (videoId: string) => {
  const result = await axios.get<IThumbnailsList>(`https://api.vimeo.com/videos/${videoId}/pictures`, {
    headers: headerGet
  });
  return result.data;
};

export const uploadThumbnail = async (image: File, videoId: string) => {
  const client = axios.create({
    baseURL: 'https://api.vimeo.com',
    headers: { ...headerPost }
  });
  try {
    const { data } = await client.post(`/videos/${videoId}/pictures`);
    await axios.put(data.link, image, {
      // custom headers for the request
      headers: { Accept: 'application/vnd.vimeo.*+json;version=3.4', 'Content-type': 'image' }
    });
    const result = await client.patch<IThumbnailsData>(data.uri, { active: true });
    return result.data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getHowToVideos = async (id: string): Promise<IVimeoProject | null> => {
  if (process.env.REACT_APP_VIMEO_ACCESS_TOKEN) {
    const response = await axios.get(`https://api.vimeo.com/me/projects/${id}/videos`, {
      headers: headerGet
    });
    return await response.data;
  }
  return null;
};

export const getVideo = async (videoId: string): Promise<IVimeoVideo | null> => {
  if (process.env.REACT_APP_VIMEO_ACCESS_TOKEN) {
    const response = await axios.get(`https://api.vimeo.com/videos/${videoId}`, {
      headers: headerGet
    });
    return await response.data;
  }
  return null;
};

export const getOrCreateVimeoFolder = async (organizationId: string) => {
  const myFolders = await axios.get(`https://api.vimeo.com/me/projects?query=${organizationId}`, {
    headers: headerGet
  });
  const uri = await myFolders.data.data?.find((item: any) => item.name === organizationId)?.uri;

  if (uri) {
    return await uri;
  } else {
    const createFolder = await axios({
      url: 'https://api.vimeo.com/me/projects',
      method: 'POST',
      headers: headerPost,
      data: { name: organizationId }
    });
    return await createFolder.data?.uri;
  }
};

export const getVideoOrganizationUri = async (file: File, organizationId: string) => {
  const uri = await getOrCreateVimeoFolder(organizationId);
  if (uri) {
    const response = await axios.post<void, AxiosResponse<IVimeoVideo>>(
      'https://api.vimeo.com/me/videos',
      {
        folder_uri: uri,
        name: file.name,
        upload: {
          approach: 'tus',
          size: file.size
        }
      },
      {
        method: 'post',
        headers: headerPost
      }
    );

    return response.data;
  }
};
