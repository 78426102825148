import { memo, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';

import styles from './quiz-item-modal.module.scss';
import { Icon32Survey, IconArrowRight, IconChevronRight } from '../../../../components/UI/icons';

import { dashboardPaths } from '../../../dashboard/routes/DashboardRoutes';
import { previewPaths } from '../../../lms/preview/routes/PreviewRoutes';
import { useViewCourseContext } from '../../../lms/students/student-course/ViewCourseContext';
import { StudentCabinetPaths } from '../../routes/StudentCabinetRoutes';

interface IProps {
  title: string;
  nodeIdWithQuiz: string;
  onCloseModal: () => void;
}

export const QuizItemModal = memo(({ title, nodeIdWithQuiz, onCloseModal }: IProps) => {
  const [showIcon, setShowIcon] = useState(false);
  const { course } = useViewCourseContext();
  const history = useHistory();
  const isPreview = useRouteMatch(previewPaths.previewLessons());
  const isDashboard = useRouteMatch(dashboardPaths.dashboardLessons());

  const onMouseEnter = () => {
    setShowIcon(true);
  };

  const onMouseLeave = () => {
    setShowIcon(false);
  };

  const onRedirectToStepQuiz = () => {
    if (!!isPreview) {
      history.push(previewPaths.previewLessons(course.id, nodeIdWithQuiz));
    } else if (!!isDashboard) {
      history.push(dashboardPaths.dashboardLessons(course.id, nodeIdWithQuiz));
    } else {
      history.push(StudentCabinetPaths.courseStudentLessons(course.id, nodeIdWithQuiz));
    }
    onCloseModal();
  };

  return (
    <div
      className={styles.container}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={onRedirectToStepQuiz}
    >
      <div className={styles.itemContainer}>
        <Icon32Survey className={styles.iconSurvey} />
        <div className={styles.title}>{title}</div>
      </div>
      {showIcon ? <IconArrowRight className={styles.iconArrow} /> : <IconChevronRight className={styles.iconChevron} />}
    </div>
  );
});
