import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  ColumnDescription,
  RowEventHandlerProps,
  SelectRowProps,
  TableChangeState,
  TableChangeType
} from 'react-bootstrap-table-next';
import { useDispatch, useSelector } from 'react-redux';
import { debounce } from 'lodash';

import styles from './contact-list.module.scss';
import { Icon32User } from '../../../../components/UI/icons';

import { EmptyList } from '../../../../components/empty-list/EmptyList';
import { NoSearchResult } from '../../../../components/no-search-result/NoSearchResult';
import { Loader } from '../../../../components/UI';
import { RemotePagination } from '../../../../components/UI/pagination/RemotePagination';
import { PopupCard } from '../../../../components/UI/popup-card/PopupCard';
import { useIntercomUpdate } from '../../../../hooks/use-intercom-update';
import { usePayload } from '../../../../hooks/usePayload';
import { LoadState } from '../../../../store';
import { FieldActionAsync, FieldScope } from '../../field';
import { FieldsEdit } from '../../fields/FieldsEdit';
import { ListsActionAsync } from '../../lists/store/ListsActionAsync';
import { ListSelector } from '../../lists/store/ListSelector';
import { IListField } from '../../lists/store/ListsState';
import { Tags } from '../../tag/table-tags/Tags';
import { ContactCard } from '../contact-card/ContactCard';
import { ContactListActionAsync } from '../store/ContactListActionAsync';
import { ListHeader } from './ListHeader';

export type RowType = Record<string, string> & { id: string; date_create: string };

type ItemType = {
  id: string;
  date_create: number;
};

const createDateColumn = { dataField: 'date_create', text: 'Date create', sort: true, hidden: true };

const selectRow: SelectRowProps<any> = {
  mode: 'radio',
  clickToSelect: true,
  hideSelectColumn: true,
  classes: styles.selectedRow
};

interface IProps {
  listId: string;
}

export const ContactList = memo(({ listId }: IProps) => {
  const dispatch = useDispatch();
  const { isPayloadShow, showPayload } = usePayload();
  const isFieldSettingsShow = useSelector((state) => state.settings.isSidebarShow);
  const listMemo = useMemo(ListSelector, []);
  const { list } = useSelector((state) => listMemo(state, listId));
  const { contacts, page, page_size, total, loadState } = useSelector((state) => state.contacts);
  const tableData = useMemo(() => {
    return contacts.map((contact) => ({
      ...contact.fields,
      id: contact.id,
      date_create: contact.date_create
    }));
  }, [contacts]);

  const [contactsTable, setContactsTable] = useState<{
    items: ItemType[];
    totalSize: number;
    page: number;
    sizePerPage: number;
  }>({ items: tableData, totalSize: 0, page: 1, sizePerPage: 25 });
  const [contactId, setContactId] = useState<string>();

  useIntercomUpdate(isPayloadShow);

  const columnFormatter = useCallback(
    (cell: string, row: RowType, index: number, data: IListField) => {
      if (cell === undefined && data.field?.scope !== 'first_name') {
        return '-';
      }
      if (data.field?.scope === 'first_name') {
        const lastNameField: IListField | undefined = list?.fields.find((field) => field.field?.scope === 'last_name');

        const lastName = (lastNameField && row[lastNameField.field_id]) || '';
        return (
          <div className={styles.name}>
            <div className={styles.link}>{`${cell || '-'} ${lastName}`}</div>
            <Tags contactId={row.id} />
          </div>
        );
      }
      if (data.field?.scope === 'email') {
        return <div className={styles.email}>{cell}</div>;
      }

      return cell;
    },
    [list?.fields]
  );

  /**
   * Fields for sorting
   */
  const sortFields = useMemo(() => {
    return list?.fields.reduce<Partial<Record<`${FieldScope}`, string>>>(
      (a, v) => (v.field ? { ...a, [v.field.scope]: v.field_id } : { ...a }),
      {}
    );
  }, [list?.fields]);

  const initialCount = useRef<number>(0);
  const searchString = useSelector((state) => state.settings.searchString);
  const [isPageChanged, setIsPageChanged] = useState(true);
  const [isSearch, setIsSearch] = useState(false);

  const changeHandler = useCallback(
    (searchString: string) => {
      if (!isPageChanged) {
        setIsSearch(searchString !== '');
        if (sortFields?.first_name && sortFields?.last_name) {
          dispatch(
            ContactListActionAsync.getContactsList(
              null,
              {
                page: 1,
                page_size: 25,
                q: searchString,
                sort: [sortFields.first_name, sortFields.last_name]
              },
              () => {
                setIsPageChanged(false);
              }
            )
          );
        }
      }
    },
    [dispatch, isPageChanged, sortFields?.first_name, sortFields?.last_name]
  );

  const debouncedSearch = useMemo(() => debounce(changeHandler, 300), [changeHandler]);

  const debouncedChangeHandler = useCallback(debouncedSearch, [debouncedSearch]);

  useEffect(() => {
    debouncedChangeHandler(searchString);
  }, [debouncedChangeHandler, searchString]);

  useEffect(() => {
    if (loadState === LoadState.allIsLoaded) {
      initialCount.current = tableData.length;
      setContactsTable({ items: tableData, page: page, sizePerPage: page_size, totalSize: total });
    }
  }, [loadState, page, page_size, tableData, total]);

  useEffect(() => {
    if (!isPayloadShow) {
      setContactId(undefined);
    }
  }, [isPayloadShow]);

  useEffect(() => {
    dispatch(FieldActionAsync.getFields());
    dispatch(ContactListActionAsync.getContactsConfig());
  }, [dispatch]);

  useEffect(() => {
    dispatch(ListsActionAsync.getListDetail(listId));
  }, [dispatch, listId]);

  useEffect(() => {
    if (sortFields?.first_name && sortFields?.last_name) {
      dispatch(
        ContactListActionAsync.getContactsList(listId, {
          page,
          page_size,
          q: searchString,
          sort: [sortFields.first_name, sortFields.last_name]
        })
      );
    }
  }, [dispatch, listId, page, page_size, searchString, sortFields?.first_name, sortFields?.last_name]);

  const openContactCard = useCallback(() => {
    showPayload();
  }, [showPayload]);

  const handleTableChange = (type: TableChangeType | 'search', { page, sizePerPage }: TableChangeState<unknown>) => {
    if (type === 'search') {
      setIsPageChanged(false);
    }
    if (type === 'pagination') {
      if (sortFields?.first_name && sortFields?.last_name) {
        setIsPageChanged(true);
        dispatch(
          ContactListActionAsync.getContactsList(listId, {
            page: page,
            page_size: sizePerPage,
            q: searchString,
            sort: [sortFields.first_name, sortFields.last_name]
          })
        );
      }
    }
  };

  const columnsData: ColumnDescription<RowType>[] = useMemo(
    () =>
      list?.fields
        .filter((field) => field.field && ['first_name', 'email', 'phone'].includes(field.field?.scope))
        .map((field) => {
          return {
            dataField: field.field_id,
            text: field.field?.scope === 'first_name' ? 'Full Name' : field.field?.payload.title || '-',
            formatter: columnFormatter,
            formatExtraData: field,
            classes: 'col-4',
            headerClasses: 'col-4'
          };
        }) || [],
    [columnFormatter, list?.fields]
  );

  const rowEvents: RowEventHandlerProps<RowType> = {
    onClick: (e, row) => {
      setContactId(row.id);
      showPayload();
    }
  };

  const updateContactId = useCallback((id: string) => {
    setContactId(id);
  }, []);

  const onCreateContact = useCallback(() => {
    if (sortFields?.first_name && sortFields?.last_name) {
      dispatch(
        ContactListActionAsync.getContactsList(listId, {
          page,
          page_size,
          sort: [sortFields.first_name, sortFields.last_name]
        })
      );
    }
  }, [dispatch, listId, page, page_size, sortFields?.first_name, sortFields?.last_name]);

  return (
    <>
      {loadState === LoadState.firstLoad && <Loader />}
      {initialCount.current > 0 && <ListHeader list={list} onClick={openContactCard} />}
      {loadState === LoadState.allIsLoaded && contactsTable.items.length > 0 && columnsData.length > 0 && (
        <RemotePagination
          page={contactsTable.page}
          sizePerPage={contactsTable.sizePerPage}
          totalSize={contactsTable.totalSize}
          onTableChange={handleTableChange}
          tableProps={{
            keyField: 'id',
            columns: [...columnsData, createDateColumn],
            selectRow: selectRow,
            data: contactsTable.items,
            rowEvents
          }}
        />
      )}

      {loadState === LoadState.idle &&
        (isSearch ? (
          <NoSearchResult searchString={searchString} />
        ) : (
          <EmptyList
            onClick={openContactCard}
            button={'Add contact'}
            title={
              <>
                Contact list <span className={'text-primary'}>"{list?.title}"</span> is empty
              </>
            }
            description={'Contacts can be added manually or from other created lists'}
            icon={<Icon32User width={64} height={64} />}
          />
        ))}

      <PopupCard isShow={isPayloadShow}>
        <ContactCard listId={listId} contactId={contactId} setContactId={updateContactId} onCreate={onCreateContact} />
      </PopupCard>
      <PopupCard isShow={isFieldSettingsShow} fullHeight={true}>
        <FieldsEdit
          entity={'contact'}
          title={'Edit contact fields'}
          description={
            'Change the order and the content for the working fields. The settings are applied to all contacts.'
          }
        />
      </PopupCard>
    </>
  );
});
