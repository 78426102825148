import { TypeOfUploader } from 'src/app/interfaces/fileUpload';

import classNames from 'classnames';
import styles from './file-select-in-modal.module.scss';
import { Icon32Audio, Icon32Code, Icon32Link, Icon32Video } from '../../../UI/icons';

import { FileSelectFormatUpload } from '../file-select/FileSelect';

interface IProps {
  accept: FileSelectFormatUpload;
  typeOfUploader: TypeOfUploader | null;
  handleChangeTypeOfUploader: (type: TypeOfUploader) => void;
}

export const FileSelectInModal = ({ typeOfUploader, accept, handleChangeTypeOfUploader }: IProps) => {
  return (
    <div className={styles.typeOfUploadButtons}>
      <button
        aria-label={TypeOfUploader.upload}
        type={'button'}
        className={classNames('btn btn-icon', styles.typeOfUploadButton, {
          'btn-gradient-primary': typeOfUploader === TypeOfUploader.upload,
          'btn-gradient-secondary': typeOfUploader !== TypeOfUploader.upload
        })}
        onClick={() => handleChangeTypeOfUploader(TypeOfUploader.upload)}
      >
        {accept === 'audio/*' && <Icon32Audio />}
        {accept === 'video/*' && <Icon32Video />}
      </button>
      <button
        aria-label={TypeOfUploader.link}
        type={'button'}
        className={classNames('btn btn-icon', styles.typeOfUploadButton, {
          'btn-gradient-primary': typeOfUploader === TypeOfUploader.link,
          'btn-gradient-secondary': typeOfUploader !== TypeOfUploader.link
        })}
        onClick={() => handleChangeTypeOfUploader(TypeOfUploader.link)}
      >
        <Icon32Link />
      </button>
      <button
        aria-label={TypeOfUploader.embed}
        type={'button'}
        className={classNames('btn btn-icon', styles.typeOfUploadButton, {
          'btn-gradient-primary': typeOfUploader === TypeOfUploader.embed,
          'btn-gradient-secondary': typeOfUploader !== TypeOfUploader.embed
        })}
        onClick={() => handleChangeTypeOfUploader(TypeOfUploader.embed)}
      >
        <Icon32Code />
      </button>
    </div>
  );
};
