import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { EventKey } from 'react-bootstrap/esm/types';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Tag } from 'react-tag-autocomplete-fix';
import { yupResolver } from '@hookform/resolvers/yup';
import { chain, difference, map } from 'lodash';

import classNames from 'classnames';
import styleSidebar from '../../../../../styles/components/sidebar.module.scss';
import styles from './contact-card.module.scss';
import { IconPencilOnPaper } from '../../../../components/UI/icons';

import { Button, CloseButton, CustomTooltip, Toast } from '../../../../components/UI';
import { arrStrForSelect, getSeparatedDate, getUUID, lazySchema, validationSchema } from '../../../../helpers';
import { validationSchema as commonValidationSchema } from '../../../../helpers/validationSchema';
import { LoadState } from '../../../../store';
import { settingsActions } from '../../../../store/settings';
import { FieldSchemaSelector, FieldSelector } from '../../field/store/FieldSelector';
import { ContactCardLists } from '../common/contact-card-lists/ContactCardLists';
import { WorkflowSessions } from '../common/workflow-sessions/WorkflowSessions';
import { ContactForm } from '../contact-form/ContactForm';
import { CurrentContactListActionAsync } from '../current-contact-store/CurrentContactActionAsync';
import { currentContactActions } from '../current-contact-store/CurrentContactActions';
import { ContactId } from '../entity-id/ContactId';
import { ContactListActionAsync } from '../store/ContactListActionAsync';
import { ContactSelector } from '../store/ContactListSelector';
import { IContact, IContactField } from '../store/ContactListState';

interface IProps {
  listId?: string;
  contactId?: string;
  setContactId(id: string): void;
  onCreate?(): void;
}

export const ContactCard = memo(({ listId, contactId, setContactId, onCreate }: IProps) => {
  const fieldMemo = useMemo(FieldSelector, []);
  const { contacts } = useSelector((state) => fieldMemo(state, 'contact'));
  const schema = commonValidationSchema(contacts);
  const methods = useForm<Record<string, any>>({
    // defaultValues: {
    //   tags: []
    // },
    shouldUnregister: true,
    resolver: yupResolver(schema)
  });

  const { control, setValue, formState, reset, handleSubmit } = methods;

  const [tags, setTags] = useState<Tag[]>([]);
  const [activeSave, setActiveSave] = useState(false);
  const dispatch = useDispatch();
  const watchAllFields = useWatch({ control: control });

  const [activeTabKey, setActiveTabKey] = useState<EventKey>('contact-info');
  const fieldSchemaMemo = useMemo(FieldSchemaSelector, []);
  const { fieldsSchema } = useSelector((state) => fieldSchemaMemo(state));
  const contactMemo = useMemo(ContactSelector, [contactId]);
  const contactData = useSelector((state) => contactMemo(state, contactId), shallowEqual);
  const { contact, loadState } = useSelector((state) => state.currentContact);

  const onSetTag = useCallback((tag: Tag[]) => {
    setTags(tag);
  }, []);

  const onSuccess = (contact?: IContact) => {
    if (contactId) {
      dispatch(ContactListActionAsync.getContact(contactId));
    } else if (contact) {
      setContactId(contact.id);
    }
    onCreate?.();
  };

  const onSave = async (data: IContactField) => {
    const fields = chain(fieldsSchema.filter((x) => x.entity === 'contact'))
      .keyBy('id')
      .mapValues((v) => (v.scope !== 'custom' ? v.scope : v.type))
      .value();
    try {
      const dataValidation = await lazySchema(fields).validate(data, { abortEarly: false });
      const res: Record<string, string | string[]> = {};
      for (const [key, value] of Object.entries(dataValidation)) {
        const field = fieldsSchema.find((x) => x.id === key);
        if (value !== null && field) {
          res[key] = validationSchema(field.type, value);
        }
      }
      const tagNames = map(tags, 'id') as string[];
      if (Object.keys(res).length !== 0 && !contactId && listId) {
        dispatch(ContactListActionAsync.createContactInList(listId, res, tagNames, onSuccess));
      }
      if (Object.keys(res).length !== 0 && !contactId && !listId) {
        dispatch(ContactListActionAsync.createContact(res, tagNames, onSuccess));
      }
      if (Object.keys(res).length !== 0 && contactId) {
        dispatch(ContactListActionAsync.editContact(contactId, res, tagNames, onSuccess));
        onClose();
      }
      if (Object.keys(res).length === 0) {
        Toast('error', 'At least one field must be filled');
      }
    } catch (error) {
      console.log('%c⇒ error', 'color: #89DDF7', error);
      Toast('error', 'Error data validation');
    }
  };

  const onClose = () => {
    dispatch(currentContactActions.setCurrentContact({ contact: undefined }));
    dispatch(settingsActions.setPayloadShowing({ isPayloadShow: false }));
  };

  const onError = (errors: IContactField) => {
    console.log('%c⇒ errors', 'color: #89DDF7', errors);
    for (let error in errors) {
      Toast('error', errors[error].message || errors[error].time?.message);
    }
  };

  const openEditFields = () => {
    dispatch(settingsActions.setSidebarShowing({ isSidebarShow: true }));
  };

  useEffect(() => {
    if (contactData) {
      dispatch(currentContactActions.setCurrentContact({ contact: contactData }));
      dispatch(CurrentContactListActionAsync.getListIdsOfContacts(contactData.contact.id));
    }
    if (!contactId) {
      reset();
      dispatch(currentContactActions.setCurrentContact({ contact: undefined }));
      setActiveSave(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contactId, dispatch, listId]);

  useEffect(() => {
    let tagStatus = false;
    if (contact?.contact.fields) {
      tagStatus =
        difference(contact.tagContact, tags).length > 0 ||
        difference(tags, contact.tagContact).length > 0 ||
        (contact.tagContact.length > 0 && tags.length === 0);
    }
    setActiveSave(formState.isDirty || tagStatus);
  }, [contactId, contact, fieldsSchema, formState.isDirty, tags, watchAllFields]);

  const setFormData = useCallback(() => {
    const selects = fieldsSchema.filter((x) => x.type === 'select' || x.type === 'multiselect');
    const dateTimes = fieldsSchema.filter((x) => x.type === 'date_time');
    for (let index = 0; index < fieldsSchema.length; index++) {
      setValue(fieldsSchema[index].id, null);
    }

    if (contact && contact.contact?.fields) {
      for (const [key, value] of Object.entries(contact?.contact?.fields)) {
        if (selects.find((x) => x.id === key && x.payload.display_options === false)) {
          setValue(key, Array.isArray(value) ? arrStrForSelect(value) : { label: value, value: getUUID() });
        } else if (dateTimes.find((x) => x.id === key) && !Array.isArray(value)) {
          const dateRes = getSeparatedDate(value);
          setValue(key, dateRes);
          setValue(`${key}.zo`, dateRes.zo);
          setValue(`${key}.time`, dateRes.time);
        } else {
          setValue(key, value);
        }
      }
    }
  }, [contact, fieldsSchema, setValue]);

  useEffect(() => {
    // reset();
    setFormData();
  }, [setFormData]);

  useEffect(() => {
    if (contactId && contact?.tagContact && loadState === LoadState.idle) {
      onSetTag(contact?.tagContact);
    }
  }, [contact?.tagContact, contactId, loadState, onSetTag]);

  return (
    <FormProvider {...methods}>
      <CloseButton onClose={onClose} className={styleSidebar.closeSidebar} />
      <div className={styles.contactId}>
        {contactId ? (
          <ContactId
            contactId={contactId}
            contactFields={watchAllFields}
            handleSubmit={handleSubmit}
            onSuccessStudentCreate={onSuccess}
            onError={onError}
          />
        ) : null}
      </div>
      <Tabs
        defaultActiveKey={activeTabKey}
        id="contact-card"
        className={classNames('tabs', styles.tabs)}
        activeKey={activeTabKey}
        onSelect={(k) => setActiveTabKey(k as string)}
      >
        <Tab eventKey="contact-info" title="Contact info">
          <ContactForm onSetTag={onSetTag} tags={tags} contact={contact?.contact} />
        </Tab>
        <Tab
          eventKey="analytics"
          title={
            <CustomTooltip
              direction={'top'}
              customText={'Save information to enable this option'}
              disabled={!!contactId}
            >
              Lists
            </CustomTooltip>
          }
          disabled={!contactId}
          mountOnEnter
          unmountOnExit
        >
          {contactId && <ContactCardLists contactId={contactId} listId={listId} />}
        </Tab>
        <Tab eventKey="sessions" title="Workflow sessions" mountOnEnter unmountOnExit className={styles.sessions}>
          <WorkflowSessions contactId={contactId} />
        </Tab>
      </Tabs>

      {activeTabKey === 'contact-info' && (
        <div className={classNames('action-buttons', styles.actionButtons)}>
          <Button
            onClick={openEditFields}
            btnStyle={'transparent'}
            customIcon={<IconPencilOnPaper />}
            className={'mr-auto'}
          >
            Edit fields
          </Button>
          <Button onClick={onClose} btnStyle={'secondary'} withIcon={false}>
            Cancel
          </Button>
          <Button
            onClick={handleSubmit(onSave, onError)}
            withIcon={false}
            btnProps={{ disabled: !activeSave }}
            className={styles.save}
          >
            Save
          </Button>
        </div>
      )}
    </FormProvider>
  );
});
