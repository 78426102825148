import { createSelector } from 'reselect';

import { IAppState } from '../../../../store';

/**
 * It takes the state of the app and returns an object with the number of files in each state, the
 * total upload progress, and a few booleans to help us determine what state the upload is in
 */
export const FileUploadSelector = () =>
  createSelector(
    (state: IAppState) => state.fileUpload,
    (fileUpload) => {
      /* Filtering the files array to find the files that are in the completed state. */
      const completed = fileUpload.files.filter(
        (file) => file.uploaderData.status === 'complete' || file.transcodingStatus?.state === 'ready'
      );

      /* Filtering the files array to find the files that are in the processing state. */
      const processing = fileUpload.files.filter(
        (file) =>
          file.uploaderData.status === 'complete' &&
          (file.transcodingStatus?.state === 'upload_complete' ||
            file.transcodingStatus?.state === 'starting' ||
            file.transcodingStatus?.state === 'active')
      );

      /* Filtering the files array to find the files that are in the uploading state. */
      const uploading = fileUpload.files.filter(
        (file) => file.uploaderData.status === 'in_progress' && !file.transcodingStatus?.state
      );

      /* Filtering the files array to find the files that are in the canceling and error state. */
      const canceling = fileUpload.files.filter((file) => file.uploaderData.status === 'canceled');
      const error = fileUpload.files.filter((file) => file.uploaderData.status === 'error');

      /* Calculating the total upload progress of all the files. */
      const totalUploadingPercent =
        ((completed.length + processing.length) * 100 +
          uploading.reduce((acc, currentValue) => {
            return acc + currentValue.progress;
          }, 0)) /
          (processing.length + completed.length + uploading.length) || 0;

      /* Checking if the file is ready to be transcoded. */
      const isTranscodingReady = !fileUpload.files.find((file) => file.transcodingStatus?.state !== 'ready');

      /* This is checking if the file is in the uploading state. */
      const isUpload = uploading.length > 0 || fileUpload.files.length === 0;

      /* Checking if the file is in the processing state. */
      const isProcessing =
        (uploading.length === 0 && processing.length > 0) ||
        (uploading.length === 0 &&
          processing.length === 0 &&
          completed.length > 0 &&
          !isTranscodingReady &&
          canceling.length === 0 &&
          error.length === 0);

      /* Checking if the file is in the complete state. */
      const isComplete =
        (uploading.length === 0 && processing.length === 0 && completed.length > 0 && isTranscodingReady) ||
        (uploading.length === 0 &&
          processing.length === 0 &&
          completed.length > 0 &&
          (canceling.length > 0 || error.length > 0));

      return {
        completed,
        processing,
        uploading,
        canceling,
        error,
        totalUploadingPercent: Math.round(totalUploadingPercent),
        isTranscodingReady,
        isUpload,
        isProcessing,
        isComplete
      };
    }
  );

/**
 * It takes the state of the fileUpload reducer and returns the file that matches the videoUrl
 * @param {string} [fileUrl] - The url of the file you want to get the upload status of.
 */
export const FileUploadByUrlSelector = (fileUrl?: string) =>
  createSelector(
    (state: IAppState) => state.fileUpload.files,
    (files) => {
      const file = files.find((video) => {
        return video.uploaderData.id === fileUrl;
      });
      return {
        fileUploaded: file
      };
    }
  );
