import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import AutosizeInput from 'react-input-autosize';
import { useDispatch } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { object, string } from 'yup';

import classNames from 'classnames';
import styles from './sender-edit.module.scss';

import { Button } from '../../../../../components/UI';
import { senderEmailPattern } from '../../../../../helpers';
import { useLoading } from '../../../../../hooks/use-loading';
import { EmailActionAsync } from '../../../../common/email-editor';

const schema = object().shape({
  customSenderName: string().required('Enter a prefix, ex: your organization name').matches(senderEmailPattern, {
    message: 'Allowed symbols: a-z, 0-9.',
    excludeEmptyString: true
  })
});

interface IProps {
  email: string;
  setEditing(isEditing: boolean): void;
  id: string;
}

export const SenderEdit = ({ email, id, setEditing }: IProps) => {
  const dispatch = useDispatch();
  const { loading, startLoading, stopLoading } = useLoading();

  const methods = useForm<{ customSenderName: string }>({
    resolver: yupResolver(schema),
    shouldFocusError: true,
    mode: 'all',
    defaultValues: {
      customSenderName: email.split('@')[0] || undefined
    }
  });

  const customSenderName = methods.watch('customSenderName');

  const onSave = () => {
    startLoading();
    dispatch(
      EmailActionAsync.editSender(
        id,
        { email: `${customSenderName}@${email.split('@')[1]}` },
        () => {
          stopLoading();
          onStopEditing();
        },
        (error) => {
          if (error.data?.detail?.status === 'error.sender.sender_email_already_used') {
            methods.setError('customSenderName', {message: 'This prefix is already used by another organization'});
          } else {
            methods.setError('customSenderName', {message: 'Something wrong'});
          }
          stopLoading();
        }
      )
    );
  };

  const onStopEditing = () => {
    setEditing(false);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && methods.formState.isValid) {
      onSave();
    }
  };

  const onClick = () => {
    methods.setFocus('customSenderName', {
      shouldSelect: true
    });
  };

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
      <div className={classNames(styles.input, {[styles.invalid]: methods.formState.errors.customSenderName})} onClick={onClick}>
        <Controller
          render={({ field: { onChange, value, ref } }) => (
            <AutosizeInput
              autoFocus
              onKeyDown={handleKeyDown}
              inputClassName={styles.autosize}
              onChange={onChange}
              value={value || ''}
              ref={ref}
            />
          )}
          name={'customSenderName'}
          control={methods.control}
          defaultValue={customSenderName}
        />
        {`@${email.split('@')[1]}`}
      </div>
      <Button onClick={methods.handleSubmit(onSave)} type={'update'} btnProps={{ disabled: loading }}>
        Save
      </Button>
      <Button onClick={onStopEditing} withIcon={false} btnStyle={'transparent'} btnProps={{ disabled: loading }}>
        Cancel
      </Button>
      </div>
      <div className='error w-100'>
        {methods.formState.errors && methods.formState.errors.customSenderName?.message}
      </div>
    </div>
  );
};
