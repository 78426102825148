import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';

import classNames from 'classnames';
import styles from './domain-item.module.scss';
import { Icon32Trash, Icon32Unlink } from '../../../../components/UI/icons';

import { Button, SpinnerLoader } from '../../../../components/UI';
import { useLoading } from '../../../../hooks/use-loading';
import { IDomain } from '../../../../interfaces/domain';
import { DomainContext } from '../DomainContext';
import { domainActionAsync } from '../store/DomainSettingsActionAsync';
import { DomainDefault } from './domain-default/DomainDefault';
import { DomainEdit } from './domain-edit/DomainEdit';
import { DomainRemoveModal } from './domain-item-modal/DomainRemoveModal';
import { DomainItemState } from './domain-item-state/DomainItemState';
import { LostConnection } from './status-connection/lost-connection/LostConnection';
import { PendingConnection } from './status-connection/pending-connection/PendingConnection';
import { StatusConnection } from './status-connection/StatusConnection';

export const DomainItem = (props: IDomain) => {
  const dispatch = useDispatch();
  const [showRemove, setShowRemove] = useState(false);
  const { loading, startLoading, stopLoading } = useLoading();
  const [isEdit, setIsEdit] = useState(false);

  const onUpdateStatus = useCallback(() => {
    dispatch(domainActionAsync.checkDomainStatus(props.id));
  }, [dispatch, props.id]);

  const onEditStart = () => setIsEdit(true);
  const onEditEnd = () => setIsEdit(false);
  const onShowModal = useCallback(() => setShowRemove(true), []);

  const onCloseModal = useCallback(() => setShowRemove(false), []);

  const onRemoveDomain = useCallback(() => {
    startLoading();
    onCloseModal();
    dispatch(domainActionAsync.removeStudentDomain(props.id, stopLoading, stopLoading));
  }, [dispatch, props.id, onCloseModal, startLoading, stopLoading]);

  const modalTitle = props.subdomain
    ? 'Your custom subdomain will be disconnected'
    : 'Your custom domain will be disconnected';
  const modalDescription = props.subdomain
    ? `After after disconnecting your custom subdomain, the ${props.subdomain}.${props.domain} subdomain will be used as default subdomain for your school.`
    : `After after disconnecting your custom domain, the ${props.domain} subdomain will be used as default subdomain for your school.`;

  return (
    <DomainContext.Provider value={props}>
      <div className={styles.wrapper}>
        {loading ? (
          <SpinnerLoader variant={'primary'} spinnerProps={{ className: 'mx-auto' }} />
        ) : (
          <div className={styles.container}>
            <>
              {!isEdit && (
                <div className={classNames(styles.name, { [styles.lost]: props.state === 'lost_connection' })}>
                  {props.subdomain ? `${props.subdomain}.${props.domain}` : props.domain}
                </div>
              )}
              {isEdit && <DomainEdit onEditEnd={onEditEnd} />}
              {props.is_default && !isEdit && <DomainDefault onEditStart={onEditStart} />}
              {!props.is_default && !isEdit && (
                <>
                  <DomainItemState onUpdateStatus={onUpdateStatus} />
                  {props.state !== 'done' && (
                    <Button
                      onClick={onShowModal}
                      btnStyle={'transparent'}
                      customIcon={<Icon32Trash />}
                      className={classNames('p-0 m-0', styles.remove)}
                    />
                  )}
                  {props.state === 'done' && (
                    <Button
                      onClick={onShowModal}
                      btnStyle={'transparent'}
                      customIcon={<Icon32Unlink />}
                      className={classNames('p-0 m-0', styles.unlink)}
                    />
                  )}
                </>
              )}
              {props.state === 'not_configured' && (
                <StatusConnection>
                  <PendingConnection isDomain={!props.subdomain} />
                </StatusConnection>
              )}
              {props.state === 'lost_connection' && (
                <StatusConnection>
                  <LostConnection />
                </StatusConnection>
              )}
            </>
          </div>
        )}
        <DomainRemoveModal
          onClose={onCloseModal}
          show={showRemove}
          onDisconnect={onRemoveDomain}
          description={modalDescription}
          title={modalTitle}
        />
      </div>
    </DomainContext.Provider>
  );
};
