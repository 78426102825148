import React, { memo, useMemo, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import classNames from 'classnames';
import styles from '../course-navigation/course-navigation.module.scss';
import { IconArrowLeft, IconFolder, IconHome, IconMisc } from '../../../UI/icons';

import { CourseDropMenu } from '../../../../modules/lms/courses/common/dropmenu/CourseDropMenu';
import { CourseNodeTreeSelector } from '../../../../modules/lms/courses/store/CourseSelector';
import { ICourse, ICourseNode } from '../../../../modules/lms/courses/store/CourseState';
import { DropMenu } from '../../../UI';

interface IProps {
  title: string;
  course: ICourse;
  currentNav: ICourseNode | null;
  currentHierarchy: ICourseNode[];
  toPreviousFolder: () => void;
  onSelect: (id: string, node?: ICourseNode) => void;
}

export const CourseNavigationBackItem = memo(
  ({ title, course, currentNav, currentHierarchy, toPreviousFolder, onSelect }: IProps) => {
    const nodeId = currentHierarchy.length > 0 ? currentHierarchy[0].parent_id : currentNav?.id;
    const nodeTreeMemo = useMemo(() => CourseNodeTreeSelector(course.children, nodeId, ['folder']), [course, nodeId]);
    const { treeNodes } = useSelector((state) => nodeTreeMemo(state));

    const [showDialogNavigate, setShowDialogNavigate] = useState(false);
    const onToggleDialogNavigate = () => {
      setShowDialogNavigate((x) => !x);
    };
    const onCloseDialogNavigate = () => {
      setShowDialogNavigate((_x) => false);
    };

    const onClick = (e: React.SyntheticEvent<HTMLDivElement>) => {
      const id = e.currentTarget.dataset['id'];
      const node = treeNodes.find((x) => x.id === id);
      if (node && id) {
        onSelect(id, node);
      } else {
        onSelect('');
      }
    };

    return (
      <div className={classNames(styles.folderNavigationItem)}>
        <div className={styles.folderNavigation}>
          <div className={styles.buttons}>
            <button type={'button'} className={classNames('btn btn-icon')} onClick={toPreviousFolder}>
              <IconArrowLeft />
            </button>
            <DropMenu
              show={showDialogNavigate}
              onToggle={onToggleDialogNavigate}
              node={
                <button type={'button'} className={classNames('btn btn-icon')}>
                  <IconMisc />
                </button>
              }
              className={styles.dropdown}
              drop={'down'}
            >
              <Dropdown.Menu onClick={onCloseDialogNavigate} className={styles.dropdownMenu}>
                <CourseDropMenu icon={<IconHome />} title={'Home'} id={''} onClick={onClick} />
                {treeNodes.reverse().map((x, index) => (
                  <CourseDropMenu key={index} icon={<IconFolder />} title={x.title} id={x.id} onClick={onClick} />
                ))}
              </Dropdown.Menu>
            </DropMenu>
          </div>
        </div>
        <div className={styles.title}>{title}</div>

        {/* TODO: discuss duplicates and deletions */}
        {/* <div className={styles.actions}>
        <button type={'button'} className={classNames('btn btn-icon')}>
          <IconKebabMenu />
        </button>
      </div> */}
      </div>
    );
  }
);
