import { LoadState } from '../../../../store';
import { FieldEntity, IField } from '../../field';

export interface IProductList {
  client_count?: number;
  date_create: number;
  date_update: number;
  entity: FieldEntity;
  fields: IProductListField[];
  id: string;
  organization_id: string;
  payload: {};
  title: string;
  type: 'product_main';
}

export interface IProductListField {
  field_id: string;
  order: number;
  field?: IField;
}

export interface IProductListState {
  loadState: LoadState;
  error: Error | null;
  productList: Nullable<IProductList>;
  courseList: Nullable<IProductList>;
}

export const ProductListInitialState: IProductListState = {
  loadState: LoadState.needLoad,
  error: null,
  productList: null,
  courseList: null
};
