import { Link, NavLink, useHistory, useRouteMatch } from 'react-router-dom';

import classNames from 'classnames';
import styles from '../../../../styles/components/navbar.module.scss';
import { IconArrowLeft, IconArrowRight, IconEye } from '../../../components/UI/icons';

import { Button } from '../../../components/UI';
import { coursePaths } from '../../../modules/lms/courses/routes/CourseRoutes';
import { previewPaths } from '../../../modules/lms/preview/routes/PreviewRoutes';
import { StudentCabinetPaths } from '../../../modules/student-cabinet/routes/StudentCabinetRoutes';

export const CourseHeaderPanel = () => {
  const history = useHistory<{ preview: boolean }>();
  const path = useRouteMatch<{ id: string; offerId?: string; nodeId?: string }>([
    coursePaths.courseLessons(':id', ':nodeId'),
    coursePaths.courseLessons(':id'),
    coursePaths.courseSettings(),
    StudentCabinetPaths.courseStudentLessons(),
    coursePaths.coursePublish(),
    coursePaths.coursePrice(':id', ':offerId'),
    coursePaths.coursePrice(':id')
  ]);
  const lessonPath = useRouteMatch<{ id: string }>(coursePaths.courseLessons());
  const settingsPath = useRouteMatch<{ id: string }>(coursePaths.courseSettings());
  const publishPath = useRouteMatch<{ id: string }>(coursePaths.coursePublish());
  const pricingPath = useRouteMatch<{ id: string }>(coursePaths.coursePrice());

  const navigation = [
    { title: 'Curriculum', path: coursePaths.courseLessons },
    { title: 'Settings', path: coursePaths.courseSettings },
    { title: 'Course offers', path: coursePaths.coursePrice },
    { title: 'Publish', path: coursePaths.coursePublish, disabled: false }
  ];

  const onPreviewClick = () => {
    history.push(previewPaths.previewLessons(path?.params.id, path?.params.nodeId));
  };

  const onContinueClick = () => {
    if (lessonPath) {
      history.push(coursePaths.courseSettings(lessonPath.params.id));
    }
    if (settingsPath) {
      history.push(coursePaths.coursePrice(settingsPath.params.id));
    }
    if (pricingPath) {
      history.push(coursePaths.coursePublish(pricingPath.params.id));
    }
  };

  return (
    <div className={`navbar navbar-expand justify-content-end ${styles.navbar}`}>
      <Link
        className={classNames('btn btn-sm btn-icon-left', styles.link)}
        to={path?.params.offerId ? coursePaths.coursePrice(path.params.id) : coursePaths.courseList}
      >
        <IconArrowLeft className={'icon'} />
        {path?.params.offerId ? 'Course offers' : 'School'}
      </Link>
      <ul className={`navbar-nav mx-auto ${styles['navbar-nav']}`}>
        {navigation.map((item, index) =>
          (
            <li key={index} className={`nav-item ${styles.item}`}>
              <NavLink
                className={`nav-link ${styles.link}`}
                to={item.path(path?.params.id)}
                activeClassName={styles.active}
                onClick={(e) => {
                  item.disabled && e.preventDefault();
                }}
              >
                {item.title}
              </NavLink>
            </li>
          ))}
      </ul>
      <Button onClick={onPreviewClick} btnStyle={'secondary'} customIcon={<IconEye className={'icon'} />}>
        Preview
      </Button>
      <Button
        onClick={onContinueClick}
        className={'ml-2'}
        customIcon={<IconArrowRight className={'icon'} />}
        btnProps={{ disabled: !!publishPath }}
      >
        Continue
      </Button>
    </div>
  );
};
