import { memo, useCallback, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { useAudioPlayer } from 'react-use-audio-player';

import classNames from 'classnames';
import styles from './audio-play-speed.module.scss';
import { IconPlaybackSpeed } from '../../UI/icons';

import { CustomTooltip, DropMenu } from '../../UI';

interface IProps {
  isMobile?: boolean;
}

export const AudioPlaySpeed = memo(({ isMobile = false }: IProps) => {
  const { player } = useAudioPlayer({ highRefreshRate: true });
  const [showDialog, setShowDialog] = useState(false);

  const onCloseDialog = () => {
    setShowDialog((_x) => false);
  };
  const onToggleDialog = () => {
    setShowDialog((x) => !x);
  };

  const beautifulRate = player._rate.toString().length === 1 ? `${player._rate}.0` : player._rate;
  const onChangeRate = useCallback(
    (eventKey: string | null) => {
      player.rate(eventKey);
    },
    [player]
  );

  return (
    <CustomTooltip disabled={isMobile} customText={'Playback speed'} direction={'top'}>
      <DropMenu
        show={showDialog}
        onToggle={onToggleDialog}
        node={
          <>
            {isMobile ? (
              <IconPlaybackSpeed className={classNames(styles.icon)} />
            ) : (
              <div className={styles.container}>
                <IconPlaybackSpeed className={styles.icon} />
                <div className={styles.text}>{beautifulRate}×</div>
              </div>
            )}
          </>
        }
        drop={'down'}
      >
        <Dropdown.Menu className={styles.dropContainer} onClick={onCloseDialog}>
          <Dropdown.Item className={styles.dropItem} eventKey={2} onSelect={onChangeRate}>
            2.0×
          </Dropdown.Item>
          <Dropdown.Item className={styles.dropItem} eventKey={1.5} onSelect={onChangeRate}>
            1.5×
          </Dropdown.Item>
          <Dropdown.Item className={styles.dropItem} eventKey={1.25} onSelect={onChangeRate}>
            1.25×
          </Dropdown.Item>
          <Dropdown.Item className={styles.dropItem} eventKey={1} onSelect={onChangeRate}>
            1.0×
          </Dropdown.Item>
          <Dropdown.Item className={styles.dropItem} eventKey={0.5} onSelect={onChangeRate}>
            0.5×
          </Dropdown.Item>
        </Dropdown.Menu>
      </DropMenu>
    </CustomTooltip>
  );
});
