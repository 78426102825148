import { createSelector } from 'reselect';
import { difference, groupBy, pick, sortBy } from 'lodash';

import { IAppState } from '../../../../store';
import { FieldTypesLabels, IFieldSettings } from '../FieldSettings';
import { FieldEntity, FieldScope, IField } from './FieldState';

export type ModifyField = IField & { typeElement: IFieldSettings | undefined };

export const FieldSelector = () =>
  createSelector(
    (_: IAppState, entity: FieldEntity) => entity,
    (state: IAppState) => state.fields.fields,
    (state: IAppState) => state.fields.loadStateReordering,
    (entity, fields, loadStateReordering) => {
      const sortedFields = sortBy(fields, 'order');
      const fieldModify: ModifyField[] = sortedFields.map((field) => ({
        ...field,
        typeElement: FieldTypesLabels.find((x) => x.value.includes(field.type))
      }));

      const sortedFieldModified = sortBy(fieldModify, 'order');
      const nameFields = sortedFieldModified.filter((fieldModify) =>
        ['first_name', 'last_name'].includes(fieldModify.scope)
      );
      const fullName = groupBy(sortBy(nameFields, 'scope'), 'entity');
      const productImage = sortedFieldModified.filter(
        (fieldModify) => fieldModify.scope === 'image' && fieldModify.entity === entity
      );
      const responsibleUser = sortedFieldModified.filter((fieldModify) =>
        ['responsible_user'].includes(fieldModify.scope)
      );
      const hiddenFields = sortedFieldModified.filter((fieldModify) =>
        ['course_relation', 'state'].includes(fieldModify.scope)
      );
      const entityGroups = groupBy(
        difference(fieldModify, nameFields, productImage, responsibleUser, hiddenFields),
        'entity'
      );

      return {
        fullName: fullName[entity] || [],
        productImage,
        products: entityGroups[entity] || [],
        students: entityGroups['student'] || [],
        contacts: entityGroups['contact'] || [],
        company: entityGroups['company'] || [],
        loadStateReordering
      };
    }
  );

export const FieldByScopeSelector = (scope: FieldScope, entity: FieldEntity) =>
  createSelector(
    (state: IAppState) => state.fields.fields,
    (fields) => {
      const fieldScope = fields.find((field) => field.scope === scope && field.entity === entity);
      return fieldScope?.id;
    }
  );

export const FieldSchemaSelector = () =>
  createSelector(
    (state: IAppState) => state.fields.fields,
    (state: IAppState) => state.fields.loadState,
    (state: IAppState) => state.fields.loadStateReordering,
    (state: IAppState) => state.fields.error,
    (fields, loadState, loadStateReordering, error) => {
      const fieldsSchema = fields.map((field) => {
        let order = field.order;
        if (field.entity === 'contact' || field.entity === 'student') {
          switch (field.scope) {
            case 'first_name':
              order = 0;
              break;
            case 'last_name':
              order = 1;
              break;
            case 'email':
              order = 2;
              break;
            case 'phone':
              order = 3;
              break;
          }
        }
        return {
          ...pick(field, ['id', 'payload', 'type', 'scope', 'order', 'entity']),
          order,
          typeElement: FieldTypesLabels.find((x) => x.value.includes(field.type))
        };
      });
      const responsibleUser = fieldsSchema.filter((field) => ['responsible_user'].includes(field.scope));
      const selects = fieldsSchema.filter((x) => x.type === 'select' || x.type === 'multiselect');
      const dateTimes = fieldsSchema.filter((x) => x.type === 'date_time');
      const modifiedFields = difference(fieldsSchema, responsibleUser);

      return {
        fieldsSchema: modifiedFields,
        selects,
        dateTimes,
        loadState,
        loadStateReordering,
        error
      };
    }
  );
