import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { courseActionAsync } from '../courses/store/CourseActionAsync';
import { CourseSelector } from '../courses/store/CourseSelector';
import { ICourseNode } from '../courses/store/CourseState';
import { LectureNavigation } from '../courses/store/LessonSelector';
import { ViewCourse } from '../students/student-course/ViewCourse';
import { previewPaths } from './routes/PreviewRoutes';

export const PreviewCourse = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const path = useRouteMatch<{ id: string; nodeId?: string }>([
    previewPaths.previewLessons(':id', ':nodeId'),
    previewPaths.previewLessons()
  ]);
  const courseId = path?.params.id;
  const nodeId = path?.params.nodeId;
  const flatLessons = useSelector((state) => LectureNavigation(state, { courseId: courseId, nodeId }));
  const courseMemo = useMemo(() => CourseSelector(courseId), [courseId]);
  const { course } = useSelector(courseMemo);
  const [currentHierarchy, setCurrentHierarchy] = useState<ICourseNode[]>([]);
  const { general } = useSelector((state) => state.general);

  const getHierarchy = useCallback(() => {
    if (course) {
      dispatch(courseActionAsync.getCourseHierarchy(course.id, setCurrentHierarchy));
    }
  }, [course, dispatch]);

  const onRedirectLessonHandler = useCallback(
    (nodeId?: string, courseId: string | undefined = course?.id) => {
      if (courseId) {
        history.push(previewPaths.previewLessons(courseId, nodeId));
      }
    },
    [course?.id, history]
  );

  useEffect(() => {
    if (!course) {
      dispatch(courseActionAsync.getCourses());
    }
  }, [course, dispatch]);

  useEffect(() => {
    getHierarchy();
  }, [getHierarchy]);

  return (
    <>
      {course && (
        <ViewCourse
          course={course}
          flatLessons={flatLessons}
          nodeId={nodeId}
          onRedirectLessonHandler={onRedirectLessonHandler}
          setCurrentHierarchy={setCurrentHierarchy}
          currentHierarchy={currentHierarchy}
          getHierarchy={getHierarchy}
          general_image={general?.general_image}
          general_title={general?.general_title}
          theme_logo={general?.theme_logo}
          viewType={'preview'}
        />
      )}
    </>
  );
};
