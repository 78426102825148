import { useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { Tag } from 'react-tag-autocomplete-fix';
import _ from 'lodash';

import { CheckButton } from '../../../../../../components/UI';
import { useNodePayload } from '../../../../../../hooks/use-node-payload';
import { PaletteAutomationElementTypes } from '../../../../../../interfaces';
import { AutocompleteTag } from '../../../../../crm/tag/AutocompleteTag';
import { TagsSelector } from '../../../../../crm/tag/store/TagSelector';

interface IProps {
  automationId: string;
  nodeId: string;
}

export const PayloadTag = ({ nodeId }: IProps) => {
  const tagMemo = useMemo(TagsSelector, []);
  const allTag = useSelector((state) => tagMemo(state));
  const [tags, setTags] = useState<Tag[]>([]);
  const { register, setValue, unregister } = useFormContext();
  const node = useNodePayload<PaletteAutomationElementTypes.ACTION_TAG>(nodeId);

  const onSetTag = (tag: Tag[]) => {
    setTags(tag);
  };

  useEffect(() => {
    register('tag_id');
    setValue('tag_id', _.map(tags, 'id'));
    return () => {
      unregister('tag_id');
    };
  }, [register, setValue, tags, unregister]);

  useEffect(() => {
    if (node) {
      const currentTag = allTag.filter((x) => node?.payload.tag_id?.includes(x.id.toString()));
      setValue('action', node?.payload.action || 'add');

      if (currentTag) {
        setTags(currentTag);
      }
    }
  }, [allTag, nodeId, node, setValue]);

  return (
    <>
      <CheckButton name={'action'} value={'add'} params={{ className: 'radio-button my-1', label: 'Assign tags' }} />
      <CheckButton
        name={'action'}
        value={'remove'}
        params={{ className: 'radio-button my-1 mt-2', label: 'Remove tags' }}
      />
      <div className={'mt-2 mb-4'}>
        <AutocompleteTag tags={tags} onSetTag={onSetTag} isShowEdit={false} isCreateNew={false} />
      </div>
    </>
  );
};
