import { useMemo, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useSelector } from 'react-redux';
import { TypeOfUploader, VideoField } from 'src/app/interfaces/fileUpload';
import { FileUploadByUrlSelector } from 'src/app/layout/shared-components/upload-worker/store/FileUploaderSelector';

import { FileEditInModal } from './file-edit-in-modal/FileEditInModal';
import { ReadyVideo } from './ready-video/ReadyVideo';
import { VideoControl } from './video-control/VideoControl';

interface IProps {
  video?: VideoField;
  setVideoUploadsDataOptional?: (fileSelected: File | null) => void;
  onSetVideoOptional?: (videoField: VideoField) => void;
  onRemoveVideoOptional?: () => void;
  onErrorOptional?: () => void;
  onCancelOptional?: () => void;
  className?: string;
}
export const VideoUploader = ({
  video,
  onErrorOptional,
  onRemoveVideoOptional,
  onSetVideoOptional,
  setVideoUploadsDataOptional,
  onCancelOptional,
  className
}: IProps) => {
  const [showModal, setShowModal] = useState(false);
  const [typeOfUploader, setTypeOfUploader] = useState<TypeOfUploader | null>(null);
  const [videoData, setVideoData] = useState<VideoField | undefined>(video);
  const fileUploaderMemo = useMemo(() => FileUploadByUrlSelector(video?.video_url), [video?.video_url]);
  const { fileUploaded } = useSelector(fileUploaderMemo);

  const onSetVideo = (videoField: VideoField) => {
    setVideoData(videoField);
    onSetVideoOptional?.(videoField);
  };
  const onRemoveVideo = () => {
    setVideoData(undefined);
    onRemoveVideoOptional?.();
  };
  const setVideoUploadsData = (fileSelected: File | null) => {
    setVideoUploadsDataOptional?.(fileSelected);
  };

  const onCloseModal = () => {
    setTypeOfUploader(null);
    setShowModal(false);
  };

  const onOpenModal = () => {
    setTypeOfUploader(video?.type || TypeOfUploader.upload);
    setShowModal(true);
  };

  const onErrorAdditional = () => {
    onErrorOptional?.();
  };

  const statusShowVideo =
    (!fileUploaded &&
      (video?.video_url || video?.url || video?.code || videoData?.video_url || videoData?.url || videoData?.code)) ||
    (fileUploaded?.uploaderData.status === 'complete' && fileUploaded?.transcodingStatus?.state === 'ready');

  return (
    <DndProvider backend={HTML5Backend}>
      <>
        {statusShowVideo &&
          !(fileUploaded?.uploaderData.status === 'canceled' || fileUploaded?.uploaderData.status === 'error') &&
          (video || videoData) && (
            <ReadyVideo
              onOpenModal={onOpenModal}
              onRemoveVideo={onRemoveVideo}
              video={video || videoData}
              onErrorAdditional={onErrorAdditional}
            />
          )}

        {!statusShowVideo && (
          <VideoControl
            className={className}
            onCancel={onCancelOptional}
            onCloseModal={onCloseModal}
            onSetVideo={onSetVideo}
            setTypeOfUploader={setTypeOfUploader}
            setVideoUploadsData={setVideoUploadsData}
            typeOfUploader={typeOfUploader}
            file={video}
            fileUploaded={fileUploaded}
          />
        )}

        <FileEditInModal onCloseModal={onCloseModal} showModal={showModal}>
          <VideoControl
            className={className}
            onCancel={onCancelOptional}
            onCloseModal={onCloseModal}
            onSetVideo={onSetVideo}
            setTypeOfUploader={setTypeOfUploader}
            setVideoUploadsData={setVideoUploadsData}
            typeOfUploader={typeOfUploader}
            file={video}
            fileUploaded={fileUploaded}
          />
        </FileEditInModal>
      </>
    </DndProvider>
  );
};
