import { IImpersonationPayload } from '../../../../interfaces/impersonation';
import { LoadState } from '../../../../store';

export interface IImpersonationState {
  loadState: LoadState;
  error: Error | null;
  payload?: IImpersonationPayload;
}

export const ImpersonationInitialState: IImpersonationState = {
  error: null,
  loadState: LoadState?.firstLoad,
  payload: {}
};
