import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import classNames from 'classnames';
import { Icon32Archive, Icon32Funnel } from '../../../components/UI/icons';

import { EmptyList } from '../../../components/empty-list/EmptyList';
import { ListFilter } from '../../../components/listFilter/ListFilter';
import { Loader } from '../../../components/UI';
import { CreateListItem } from '../../../components/UI/create-list-item/CreateListItem';
import { FilterState } from '../../../interfaces/filters';
import { PageTopPanel } from '../../../layout/shared-components/page-top-panel/PageTopPanel';
import { LoadState } from '../../../store';
import { funnelPaths } from '../routes/FunnelRoutes';
import { FunnelItem } from './funnel-item/FunnelItem';
import { funnelActionAsync } from './store/FunnelActionAsync';
import { FunnelsSelector } from './store/FunnelSelector';

export const FunnelList = React.memo(() => {
  const [activeFilter, setActiveFilter] = useState<FilterState>('all');
  const authToken = useSelector((state) => state.auth.authToken);
  const history = useHistory();
  const dispatch = useDispatch();
  const funnelMemo = useMemo(FunnelsSelector, [activeFilter]);
  const searchString = useSelector((state) => state.settings.searchString);
  const { filteredList, loadState } = useSelector((state) => funnelMemo(state, activeFilter));

  const isArchiveFilter = activeFilter === 'archived';

  const onFunnelCreated = useCallback(
    (id: string) => {
      history.push(funnelPaths.funnelId(id));
    },
    [history]
  );

  const addFunnelHandler = useCallback(async () => {
    dispatch(funnelActionAsync.createFunnel(onFunnelCreated));
  }, [dispatch, onFunnelCreated]);

  useEffect(() => {
    const fetcher = async () => {
      if (authToken) {
        dispatch(funnelActionAsync.getFunnels(authToken));
      }
    };
    fetcher().then();
  }, [authToken, history, dispatch]);

  const onFilter = useCallback((selectedKey: string | null = 'all') => {
    if (selectedKey) {
      setActiveFilter(selectedKey as FilterState);
    }
  }, []);

  return (
    <>
      <PageTopPanel title={'Your funnels'}>
        <ListFilter activeKey={activeFilter} onSelect={onFilter} />
      </PageTopPanel>
      <div className={classNames('row', { 'justify-content-center h-100': !filteredList.length })}>
        {loadState === LoadState.firstLoad ? (
          <Loader />
        ) : filteredList.length ? (
          <>
            {activeFilter !== 'archived' && (
              <CreateListItem
                createItem={addFunnelHandler}
                text={'new funnel'}
                subtext={'Increase your sales by starting things out'}
              />
            )}

            {filteredList
              .filter((x) => x.name.includes(searchString))
              .map((funnel, index) => {
                return (
                  <div className="col-lg-3 col-md-4 mb-5" key={index}>
                    <FunnelItem funnelItem={funnel} />
                  </div>
                );
              })}
          </>
        ) : (
          <EmptyList
            title={'It looks like it’s empty here'}
            description={
              isArchiveFilter ? 'You don’t have any funnels to your archive' : 'Start by creating your first funnel'
            }
            button={isArchiveFilter ? 'Go to All Funnels' : 'Create new funnel'}
            onClick={isArchiveFilter ? onFilter : addFunnelHandler}
            icon={isArchiveFilter ? <Icon32Archive width={64} height={64} /> : <Icon32Funnel width={64} height={64} />}
            small={isArchiveFilter}
          />
        )}
      </div>
    </>
  );
});
