import { CustomDispatch } from 'redux';

import {
  applySubscriptionDiscount,
  cancelSubscription,
  createSubscription,
  getCoupons,
  getSubscriptionStatus,
  getTransactions,
  subscriptionReason,
  updateSubscription
} from '../../../../API/payment-api';
import {
  ICoupon,
  IPaginationParams,
  IPaginationResponse,
  IPayment,
  IReason,
  ISubscription,
  Plans
} from '../../../../interfaces';
import { billingActions } from './BillingActions';

export const billingActionAsync = {
  getSubscription: () => async (dispatch: CustomDispatch) => {
    try {
      dispatch(billingActions.getSubscription.started());
      const response = await getSubscriptionStatus();
      dispatch(billingActions.getSubscription.done({ result: response.data }));
    } catch (error) {
      dispatch(billingActions.getSubscription.failed({ error }));
    }
  },
  updateSubscription: (plan: Plans, callback?: (data: ISubscription) => void) => async (dispatch: CustomDispatch) => {
    try {
      dispatch(billingActions.updateSubscription.started({ plan }));
      const response = await updateSubscription(plan);
      if (response.data) {
        dispatch(billingActions.updateSubscription.done({ result: response.data, params: { plan } }));
        callback?.(response.data);
      }
    } catch (error) {
      dispatch(billingActions.updateSubscription.failed({ error, params: { plan } }));
    }
  },
  createSubscription: (plan: Plans, callback?: (data: ISubscription) => void) => async (dispatch: CustomDispatch) => {
    try {
      dispatch(billingActions.createSubscription.started({ plan }));
      const response = await createSubscription(plan);
      if (response.data) {
        dispatch(billingActions.createSubscription.done({ result: response.data, params: { plan } }));
        callback?.(response.data);
      }
    } catch (error) {
      dispatch(billingActions.createSubscription.failed({ error, params: { plan } }));
    }
  },
  deleteSubscription:
    (atPeriodEnd: boolean, callback?: (data: ISubscription) => void) => async (dispatch: CustomDispatch) => {
      try {
        dispatch(billingActions.deleteSubscription.started({ atPeriodEnd }));
        const response = await cancelSubscription(atPeriodEnd);
        if (response.data) {
          dispatch(billingActions.deleteSubscription.done({ result: response.data, params: { atPeriodEnd } }));
          callback?.(response.data);
        }
      } catch (error) {
        dispatch(billingActions.deleteSubscription.failed({ error, params: { atPeriodEnd } }));
      }
    },
  subscriptionReason: (reason: IReason, callback?: (data: string) => void) => async (dispatch: CustomDispatch) => {
    try {
      dispatch(billingActions.subscriptionReason.started({ reason }));
      const response = await subscriptionReason(reason);
      if (response.data) {
        dispatch(billingActions.subscriptionReason.done({ result: response.data, params: { reason } }));
        callback?.(response.data);
      }
    } catch (error) {
      dispatch(billingActions.subscriptionReason.failed({ error, params: { reason } }));
    }
  },
  renewSubscription:
    (atPeriodEnd: boolean, callback?: (data: ISubscription) => void) => async (dispatch: CustomDispatch) => {
      try {
        dispatch(billingActions.renewSubscription.started({ atPeriodEnd }));
        const response = await cancelSubscription(atPeriodEnd);
        if (response.data) {
          dispatch(billingActions.renewSubscription.done({ result: response.data, params: { atPeriodEnd } }));
          callback?.(response.data);
        }
      } catch (error) {
        dispatch(billingActions.renewSubscription.failed({ error, params: { atPeriodEnd } }));
      }
    },
  applyDiscount: (coupon: string, callback?: (data: ISubscription) => void) => async (dispatch: CustomDispatch) => {
    try {
      dispatch(billingActions.applyDiscount.started({ coupon }));
      const response = await applySubscriptionDiscount(coupon);
      if (response.data) {
        dispatch(billingActions.applyDiscount.done({ result: response.data, params: { coupon } }));
        callback?.(response.data);
      }
    } catch (error) {
      dispatch(billingActions.applyDiscount.failed({ error, params: { coupon } }));
    }
  },
  getCoupons: (callback?: (data: ICoupon) => void) => async (dispatch: CustomDispatch) => {
    try {
      dispatch(billingActions.getCoupons.started());
      const response = await getCoupons();
      if (response.data) {
        dispatch(billingActions.getCoupons.done({ result: response.data }));
        callback?.(response.data);
      } else {
        dispatch(billingActions.getSubscription.done({ result: null }));
      }
    } catch (error) {
      dispatch(billingActions.getCoupons.failed({ error }));
    }
  },
  getTransactions:
    (pagination?: IPaginationParams, callback?: (data: IPaginationResponse<IPayment[]>) => void) =>
    async (dispatch: CustomDispatch) => {
      try {
        dispatch(billingActions.getTransactions.started());
        const response = await getTransactions(pagination);
        if (response && response.data && response.meta) {
          dispatch(
            billingActions.getTransactions.done({
              result: { data: response.data, meta: response.meta }
            })
          );
          callback?.({
            data: response.data,
            meta: response.meta
          });
        }
      } catch (error) {
        dispatch(billingActions.getTransactions.failed({ error }));
      }
    }
};
