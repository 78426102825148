import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import Select, { SingleValue } from 'react-select';

import classnames from 'classnames';
import styles from './payload-condition.module.scss';

import { useNodePayload } from '../../../../../../hooks/use-node-payload';
import { IOption, PaletteAutomationElementTypes } from '../../../../../../interfaces';
import { ListCondition } from './ListCondition';
import { TagCondition } from './TagCondition';

const options = [
  { value: 'tag', label: 'Tag' },
  { value: 'list', label: 'List' }
];
const styleOptions = {
  control: (styles: any) => ({
    ...styles,
    padding: '0.25rem 0',
    border: '1px solid #99c7ff',
    ':hover': { border: '1px solid #99c7ff' },
    ':focus': { border: '1px solid #0072ff' }
  }),
  menu: (styles: any) => ({
    ...styles,
    zIndex: 100
  })
};
interface IProps {
  automationId: string;
  nodeId: string;
}

export const PayloadCondition = ({ nodeId }: IProps) => {
  const [selected, setSelected] = useState<{ value: string; label: string } | null>(null);
  const node = useNodePayload<PaletteAutomationElementTypes.PROCESSOR_CONDITION>(nodeId);
  const { setValue, control } = useFormContext();
  useEffect(() => {
    if (node && node.payload.object_type) {
      const objSelect = node.payload.object_type === 'tag' ? options[0] : options[1];
      setSelected(objSelect);
      setValue('object_type', objSelect);
    }
  }, [node, setValue]);

  const onSelectHandler = (opt: SingleValue<IOption>) => {
    setValue('object_type', opt?.value);
    setSelected(opt);
  };

  return (
    <>
      {/* <Controller
        render={({ field: { onChange } }) => ( */}
      <Select
        value={selected}
        options={options}
        components={{ IndicatorSeparator: () => null }}
        onChange={onSelectHandler}
        placeholder={'Select condition'}
        className={classnames('mt-4', styles.text)}
        styles={styleOptions}
      />
      {/* )}
        defaultValue={null}
        name={'object_type'}
        control={control}
      /> */}
      <div className={'mt-4'}>
        {selected?.value === 'tag' && <TagCondition nodeId={nodeId} />}
        {selected?.value === 'list' && <ListCondition nodeId={nodeId} />}
      </div>
    </>
  );
};
