import React from 'react';

import styles from './restrict-logout.module.scss';

import { Button, ModalActionComponent } from '../../../../components/UI';

interface IProps {
  show: boolean;
  onClose(): void;
  onStay(): void;
}

export const RestrictLogout = ({ show, onClose, onStay }: IProps) => {
  return (
    <ModalActionComponent show={show} title={'You have videos that are still uploading'} onCloseModal={onClose} iconType={'warning'}>
      <div className={styles.container}>In case of force logout from your account the uploading videos will be canceled automatically.</div>
      <div className={styles.actions}>
        <Button onClick={onClose} btnStyle={'warning'} withIcon={false} className={styles.cancel}>Log out anyway</Button>
        <Button onClick={onStay} withIcon={false}>
          Stay to complete upload
        </Button>
      </div>
    </ModalActionComponent>
  );
};
