export enum ErrorDomainStatus {
  ALREADY_USED = 'error.integrations.organization_already_have_custom_domain',
  ALREADY_REGISTERED = 'error.integrations.domain_already_registered',
  WRONG_ACCOUNT = 'error.integrations.domain_wrong_account',
  IS_NOT_DEFAULT = 'error.integrations.domain_is_not_default'
}

export const ErrorDomainAction = {
  [ErrorDomainStatus.ALREADY_REGISTERED]: {
    message: 'This domain is already used'
  },
  [ErrorDomainStatus.ALREADY_USED]: {
    message: 'You already have a domain'
  },
  [ErrorDomainStatus.WRONG_ACCOUNT]: {
    message:
      'We are not able to create a Cloudflare Zone for this domain. Please check your domain for any typos. If you recently purchased this domain, it may take a few minutes to show up on the registry. If problems persist, please contact support.'
  },
  [ErrorDomainStatus.IS_NOT_DEFAULT]: {
    message: 'Domain is not default'
  }
};

export const ErrorDomainHandler = (error: ErrorDomainStatus, payload: unknown | null) => {
  return ErrorDomainAction[error].message;
};
