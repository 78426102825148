import { IQuizSession, IQuizSessionDetail } from '../interfaces/quiz';
import { baseFetch } from './BaseFetch';

export interface QuizSessionsParams {
  student_id?: string;
  course_id?: Nullable<string>;
  include_hierarchy?: Nullable<boolean>;
  include_questionnaire?: Nullable<boolean>;
  include_answers?: Nullable<boolean>;
  include_student?: Nullable<boolean>;
  include_course?: Nullable<boolean>;
  moderate_answers?: Nullable<boolean>;
  sort?: string;
  course_ids?: string[];
  states?: string[];
}

/**
 * Get Organizations Quiz Sessions
 * @param params
 */
export const getQuizSessions = async (params: QuizSessionsParams) => {
  return await baseFetch<{}, IQuizSession[]>('/quiz/sessions', params, 'GET', true);
};

/**
 * Get Quiz Session By Key
 * @param session_key Quiz Session Key
 */
export const getQuizSession = async (session_key: string) => {
  return await baseFetch<{}, IQuizSessionDetail>(`/quiz/sessions/${session_key}`, {}, 'GET', true);
};

/**
 * Quiz Answer Session Delete
 * @param session_key Quiz Session Key
 */
export const deleteQuizSession = async (session_key: string) => {
  return await baseFetch<{}, null>(`/quiz/sessions/${session_key}`, {}, 'DELETE', true);
};

/**
 * Mark Quiz Answer Sessions Skipable
 * @param session_key Quiz Session Key
 */
export const markSkipableQuizSession = async (session_key: string) => {
  return await baseFetch<{}, null>(`/quiz/sessions/${session_key}/skip`, {}, 'PUT', true);
};

/**
 * Mark Quiz Answer Sessions Passed
 * @param session_key Quiz Session Key
  * @param is_passed Quiz is_passed Key
 */
export const markPassedQuizSession = async (session_key: string, is_passed: boolean) => {
  return await baseFetch<{}, null>(`/quiz/sessions/${session_key}/moderate`, { is_passed }, 'POST', true);
};
