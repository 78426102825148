import { getUUID, timestamp } from '../../../../../helpers';
import { NodeEndpoint } from '../../../../../interfaces';

export const FictiveEndpoints: NodeEndpoint[] = [
  { id: getUUID(), created_at: timestamp, updated_at: timestamp, is_input: false, title: '', type: 'simple' },
  { id: getUUID(), created_at: timestamp, updated_at: timestamp, is_input: false, title: '', type: 'simple' },
  { id: getUUID(), created_at: timestamp, updated_at: timestamp, is_input: false, title: '', type: 'simple' },
  { id: getUUID(), created_at: timestamp, updated_at: timestamp, is_input: false, title: '', type: 'simple' },
  { id: getUUID(), created_at: timestamp, updated_at: timestamp, is_input: false, title: '', type: 'simple' },
  { id: getUUID(), created_at: timestamp, updated_at: timestamp, is_input: false, title: '', type: 'simple' },
  { id: getUUID(), created_at: timestamp, updated_at: timestamp, is_input: false, title: '', type: 'simple' }
];
