import { FC, memo } from 'react';
import { useSelector } from 'react-redux';

import classNames from 'classnames';
import itemStyles from '../../sidebar-common.module.scss';
import styles from '../sidebar-school.module.scss';
import { Icon16Folder, Icon16Webinar } from '../../../../../../components/UI/icons';

import { CourseNavigation } from '../../../../../../components/course/course-navigation/course-navigation/CourseNavigation';
import { BlockType } from '../../../../../../interfaces';
import { useCourseContext } from '../CourseContext';
import { CourseTitle } from '../CourseTitle';
import { CreateLessonElement } from '../CreateLessonElement';

export const LessonsSidebar: FC = memo(({ children }) => {
  const { courseId } = useCourseContext();
  const course = useSelector((state) => state.courses.courses.find((x) => x.id === courseId));
  const { nodeCreateHandler, currentHierarchy, currentNav } = useCourseContext();

  const buttons = () => {
    const folder = { folder: { title: 'Folder', icon: <Icon16Folder className={'icon'} />, css: itemStyles.folderContainer } };
    const lesson = { lesson: { title: 'Lesson', icon: <Icon16Webinar className={'icon'} />, css: itemStyles.lessonContainer } };
    return { ...folder, ...lesson };
  };

  const createLessonElement = () => {
    return ([key, value]: any, index: number) => (
      <CreateLessonElement
        key={index}
        className={value.css}
        img={value.icon}
        onCreate={nodeCreateHandler}
        title={value.title}
        blockType={key as keyof typeof BlockType}
      />
    );
  };

  return (
    <div className={styles.wrapper}>
      <CourseTitle course={course} />
      <CourseNavigation currentNav={currentNav} hierarchy={currentHierarchy} />
      <div className={classNames(styles.title, 'text-center')}>Select lesson page</div>
      <div className={classNames('d-flex mt-1', itemStyles.additional, styles.bottom)}>
        {Object.entries(buttons()).map(createLessonElement())}
      </div>
      {children}
    </div>
  );
});
