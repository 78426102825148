import { CustomDispatch } from 'redux';

import {
  addAutomationNode,
  deleteAutomationEdge,
  deleteAutomationNode,
  getAutomationNode,
  saveAutomationEdge,
  saveAutomationGeometry,
  saveAutomationNode
} from '../../../../API/automation-api';
import {
  addFunnelNode,
  createFunnelEndpoint,
  deleteFunnelEdge,
  deleteFunnelEndpoint,
  deleteFunnelNode,
  editFunnelEndpoint,
  getFunnelEndpoints,
  getFunnelNode,
  saveFunnelEdge,
  saveFunnelGeometry,
  saveFunnelNode
} from '../../../../API/funnel-api';
import {
  EdgeRequest,
  GeometryRequest,
  IAutomationNodeBuilder,
  IFunnelNodeBuilder,
  NodeTypes,
  PaletteAutomationElementTypes,
  PaletteElementTypes
} from '../../../../interfaces';
import { builderActions } from './BuilderActions';

export enum BuilderTypeEnum {
  funnel = 'funnel',
  automation = 'automation'
}

export type BuilderType = keyof typeof BuilderTypeEnum;

export const builderActionAsync = {
  addNode:
    (
      id: string,
      types: NodeTypes,
      builderType: BuilderType,
      appendCallback?: (
        node: IFunnelNodeBuilder<PaletteElementTypes> | IAutomationNodeBuilder<PaletteAutomationElementTypes>
      ) => void
    ) =>
    async (dispatch: CustomDispatch) => {
      try {
        dispatch(builderActions.addNode.started({ id, types }));

        const response = builderType === 'funnel' ? await addFunnelNode(id, types) : await addAutomationNode(id, types);
        if (response.data) {
          dispatch(builderActions.addNode.done({ result: response.data, params: { id, types } }));
          if (appendCallback) {
            appendCallback(response.data);
          }
        }
      } catch (error) {
        dispatch(builderActions.addNode.failed({ error, params: { id, types } }));
      }
    },
  getNode:
    (
      id: string,
      nodeKey: string,
      builderType: BuilderType,
      callbackDone?: (
        node: IFunnelNodeBuilder<PaletteElementTypes> | IAutomationNodeBuilder<PaletteAutomationElementTypes>
      ) => void
    ) =>
    async (dispatch: CustomDispatch) => {
      try {
        dispatch(builderActions.getNode.started({ id, nodeKey }));
        const response =
          builderType === 'funnel' ? await getFunnelNode(id, nodeKey) : await getAutomationNode(id, nodeKey);
        if (response.data) {
          dispatch(builderActions.getNode.done({ result: response.data, params: { id, nodeKey } }));
          if (callbackDone) {
            callbackDone(response.data);
          }
        }
      } catch (error) {
        dispatch(builderActions.getNode.failed({ error, params: { id, nodeKey } }));
      }
    },
  saveNode:
    (
      id: string,
      nodeKey: string,
      data: Record<string, string | undefined>,
      builderType: BuilderType,
      callbackDone?: () => void
    ) =>
    async (dispatch: CustomDispatch) => {
      try {
        dispatch(builderActions.saveNode.started({ id, data, nodeKey }));
        const response =
          builderType === 'funnel'
            ? await saveFunnelNode(id, nodeKey, data)
            : await saveAutomationNode(id, nodeKey, data);
        if (response.data) {
          dispatch(builderActions.saveNode.done({ result: response.data, params: { id, nodeKey, data } }));
          if (callbackDone) {
            callbackDone();
          }
        }
      } catch (error) {
        dispatch(builderActions.saveNode.failed({ error, params: { id, nodeKey, data } }));
      }
    },
  deleteNode: (id: string, nodeKey: string, builderType: BuilderType) => async (dispatch: CustomDispatch) => {
    try {
      dispatch(builderActions.deleteNode.started({ id, nodeKey }));
      const response =
        builderType === 'funnel' ? await deleteFunnelNode(id, nodeKey) : await deleteAutomationNode(id, nodeKey);
      if (response.data === null) {
        dispatch(builderActions.deleteNode.done({ result: response.data, params: { id, nodeKey } }));
      }
    } catch (error) {
      dispatch(builderActions.deleteNode.failed({ error, params: { id, nodeKey } }));
    }
  },
  saveGeometry: (id: string, data: GeometryRequest, builderType: BuilderType) => async (dispatch: CustomDispatch) => {
    try {
      dispatch(builderActions.saveGeometry.started({ id, data }));
      const response =
        builderType === 'funnel' ? await saveFunnelGeometry(id, data) : await saveAutomationGeometry(id, data);
      if (response.data) {
        dispatch(builderActions.saveGeometry.done({ result: response.data, params: { id, data } }));
      }
    } catch (error) {
      dispatch(builderActions.saveGeometry.failed({ error, params: { id, data } }));
    }
  },
  saveEdge: (id: string, edge: EdgeRequest, builderType: BuilderType) => async (dispatch: CustomDispatch) => {
    try {
      dispatch(builderActions.saveEdge.started({ id, edge }));
      const response = builderType === 'funnel' ? await saveFunnelEdge(id, edge) : await saveAutomationEdge(id, edge);
      if (response.data) {
        dispatch(builderActions.saveEdge.done({ result: response.data, params: { id, edge } }));
      }
    } catch (error) {
      dispatch(builderActions.saveEdge.failed({ error, params: { id, edge } }));
    }
  },
  deleteEdge: (id: string, edgeKey: string, builderType: BuilderType) => async (dispatch: CustomDispatch) => {
    try {
      dispatch(builderActions.deleteEdge.started({ id, edgeKey }));
      const response =
        builderType === 'funnel' ? await deleteFunnelEdge(id, edgeKey) : await deleteAutomationEdge(id, edgeKey);
      if (response.data) {
        dispatch(builderActions.deleteEdge.done({ result: response.data, params: { id, edgeKey } }));
      }
    } catch (error) {
      dispatch(builderActions.deleteEdge.failed({ error, params: { id, edgeKey } }));
    }
  },
  getEndpoints: (funnelId: string, nodeId: string) => async (dispatch: CustomDispatch) => {
    try {
      dispatch(builderActions.getEndpoints.started({ funnelId, nodeId }));
      const response = await getFunnelEndpoints(funnelId, nodeId);
      if (response.data) {
        dispatch(builderActions.getEndpoints.done({ result: response.data, params: { funnelId, nodeId } }));
      }
    } catch (error) {
      dispatch(builderActions.getEndpoints.failed({ error, params: { funnelId, nodeId } }));
    }
  },
  createEndpoints: (funnelId: string, nodeId: string, title?: string) => async (dispatch: CustomDispatch) => {
    try {
      dispatch(builderActions.createEndpoint.started({ funnelId, nodeId }));
      const response = await createFunnelEndpoint(funnelId, nodeId, title);
      if (response.data) {
        dispatch(builderActions.createEndpoint.done({ result: response.data, params: { funnelId, nodeId, title } }));
      }
    } catch (error) {
      dispatch(builderActions.createEndpoint.failed({ error, params: { funnelId, nodeId, title } }));
    }
  },
  editEndpoint:
    (funnelId: string, nodeId: string, endpointId: string, title: string) => async (dispatch: CustomDispatch) => {
      try {
        dispatch(builderActions.editEndpoint.started({ funnelId, nodeId, endpointId, title }));
        const response = await editFunnelEndpoint(funnelId, nodeId, endpointId, title);
        if (response.data) {
          dispatch(
            builderActions.editEndpoint.done({ result: response.data, params: { funnelId, nodeId, endpointId, title } })
          );
        }
      } catch (error) {
        dispatch(builderActions.editEndpoint.failed({ error, params: { endpointId, funnelId, nodeId, title } }));
      }
    },
  deleteEndpoint: (funnelId: string, nodeId: string, endpointId: string) => async (dispatch: CustomDispatch) => {
    try {
      dispatch(builderActions.deleteEndpoint.started({ funnelId, nodeId, endpointId }));
      const response = await deleteFunnelEndpoint(funnelId, nodeId, endpointId);
      if (response.data) {
        dispatch(
          builderActions.deleteEndpoint.done({ result: response.data, params: { funnelId, nodeId, endpointId } })
        );
      }
    } catch (error) {
      dispatch(builderActions.deleteEndpoint.failed({ error, params: { funnelId, nodeId, endpointId } }));
    }
  }
};
