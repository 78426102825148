import styles from './passing-grade.module.scss';

import { CircleProgressbar } from '../../../../../components/UI/circle-progressbar/CircleProgressbar';

interface IProps {
  progress: number;
}

export const PassingGrade = ({ progress }: IProps) => {
  let color = '#f20d5d';
  switch (true) {
    case progress >= 25 && progress < 75:
      color = '#c78605';
      break;
    case progress >= 75 && progress < 100:
      color = '#0072ff';
      break;
    case progress === 100:
      color = '#34d900';
      break;
  }

  return (
    <div className={styles.container}>
      <CircleProgressbar value={progress} props={{ className: 'mr-2' }} />
      <span style={{ color }}>
        {progress && progress !== 0 ? (progress < 100 ? `${progress}%` : 'Completed') : 'Not started'}
      </span>
    </div>
  );
};
