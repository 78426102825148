import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useRouteMatch } from 'react-router-dom';

import styles from './course-publish.module.scss';
import { Icon32Megaphone, Icon32PencilOnPaper } from '../../../../components/UI/icons';

import { HoorayModal } from '../../../../components/hooray/HoorayModal';
import { InfoMessage } from '../../../../components/UI/infoMessage/InfoMessage';
import { LoadState } from '../../../../store';
import { enrollmentPaths } from '../../enrollment/routes/EnrollmentRoutes';
import { CopyLinkBlock } from '../common/copy-link-block/CopyLinkBlock';
import { PublishCourseModal } from '../common/publish-course-modal/PublishCourseModal';
import { coursePaths } from '../routes/CourseRoutes';
import { courseActionAsync } from '../store/CourseActionAsync';
import { CourseState } from '../store/CourseState';
import { CoursePublishStatus } from './course-publish-status/CoursePublishStatus';
import { InfoCourse } from './info-course/InfoCourse';

export const CoursePublish = () => {
  const dispatch = useDispatch();
  const [showPublishModal, setShowPublishModal] = useState(false);
  const [showPublishHoorayModal, setShowPublishHoorayModal] = useState(false);
  // const [showNotification, setShowNotification] = useState(true);

  const path = useRouteMatch<{ id: string }>(coursePaths.coursePublish());
  const course = useSelector((state) => state.courses.courses.find((x) => x.id === path?.params.id));
  const { loadState } = useSelector((state) => state.courses);

  useEffect(() => {
    if (!course) {
      dispatch(courseActionAsync.getCourses());
    }
  }, [course, dispatch]);

  const statusChangeHandler = (event: React.SyntheticEvent<HTMLButtonElement>) => {
    const status = event.currentTarget.id as CourseState;
    if (course?.id && status) {
      dispatch(courseActionAsync.updateCourseState(course.id, status));
      onClosePublishModal();
      if (status === 'published') {
        onShowPublishHoorayModal();
      }
      if (status === 'draft') {
        // setShowNotification(true);
      }
    }
  };

  useEffect(() => {
    if (showPublishModal && loadState === LoadState.idle) {
      setShowPublishHoorayModal(true);
    }
  }, [loadState, showPublishModal]);

  const onClosePublishModal = () => {
    setShowPublishModal(false);
  };
  const onShowPublishModal = () => {
    setShowPublishModal(true);
  };
  const onClosePublishHoorayModal = () => {
    setShowPublishHoorayModal(false);
  };
  const onShowPublishHoorayModal = () => {
    setShowPublishHoorayModal(true);
  };
  let block;

  switch (course?.state) {
    case 'published':
      block = (
        <>
          <CoursePublishStatus
            currentStatus={'published'}
            status={'draft'}
            icon={<Icon32PencilOnPaper />}
            statusName={'Draft'}
            action={statusChangeHandler}
          />
          <CoursePublishStatus
            currentStatus={'published'}
            status={'published'}
            icon={<Icon32Megaphone />}
            statusName={'Publish'}
            action={statusChangeHandler}
          />
        </>
      );
      break;
    case 'draft':
      block = (
        <>
          <CoursePublishStatus
            currentStatus={'draft'}
            status={'draft'}
            icon={<Icon32PencilOnPaper />}
            statusName={'Draft'}
            action={statusChangeHandler}
          />
          <CoursePublishStatus
            currentStatus={'draft'}
            status={'published'}
            icon={<Icon32Megaphone />}
            statusName={'Publish'}
            action={onShowPublishModal}
          />
        </>
      );
      break;
    case 'archived':
      block = (
        <>
          <CoursePublishStatus
            currentStatus={'archived'}
            status={'archived'}
            icon={<Icon32PencilOnPaper />}
            statusName={'Draft'}
            action={statusChangeHandler}
          />
          <CoursePublishStatus
            currentStatus={'archived'}
            status={'published'}
            icon={<Icon32Megaphone />}
            statusName={'Publish'}
            action={statusChangeHandler}
          />
        </>
      );
      break;
    default:
      break;
  }

  return (
    <>
      <div className={styles.container}>
        <div className={styles.chapter}>Course publish settings</div>
        {course?.state === 'archived' && (
          <InfoMessage
            text={'This is an archived course. To publish it, you need to move it to the draft'}
            className={'mb-3'}
            show
          />
        )}
        {course && (
          <>
            <InfoCourse course={course} />
            {block}
            {/* {course.state === 'draft' && (
              <InfoMessage
                text={'If you have any lessons in the draft stage, they will not be displayed in the published course.'}
                className={'mt-3 d-flex justify-content-between'}
                btnText={'All clear'}
                btnClick={() => setShowNotification(false)}
                show={showNotification}
              />
            )} */}
          </>
        )}
      </div>
      {course && (
        <PublishCourseModal
          course={course}
          onClosePublishModal={onClosePublishModal}
          showPublishModal={showPublishModal}
          statusChangeHandler={statusChangeHandler}
        />
      )}
      {course && (
        <HoorayModal
          title={'Your course was successfully published'}
          show={showPublishHoorayModal}
          onCloseModal={onClosePublishHoorayModal}
        >
          <div className={styles.descriptionText}>
            You can now <Link to={enrollmentPaths.enrollment}>enroll students</Link> to the course or share the link.
          </div>
          <CopyLinkBlock courseId={course.id} classNameContainer={styles.wrapper} btn={styles.btn} />
        </HoorayModal>
      )}
    </>
  );
};
