import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { TableTags } from '../../../../components/table-tags/TableTags';
import { ContactSelector } from '../../contact/store/ContactListSelector';

interface IProps {
  contactId: string;
}

export const Tags = ({ contactId }: IProps) => {
  const contactMemo = useMemo(ContactSelector, [contactId]);
  const contactData = useSelector((state) => contactMemo(state, contactId));

  return <TableTags tags={contactData?.contact.tags} />;
};
