import { memo } from 'react';
import { FormControl } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { AxiosResponse } from 'axios';

import classNames from 'classnames';

import { EmailActionStatusEnum } from '../../../../API/action-email-api';
import { Button } from '../../../../components/UI';
import { IUserAuth, ResetPasswordStatus } from '../../../../interfaces';
import { AuthActionAsync } from '../../store/AuthActionAsync';

interface IProps {
  setSuccess: () => void;
  setLoading: (x: boolean) => void;
  loading: boolean;
}

export const ResetInput = memo(({ loading, setLoading, setSuccess }: IProps) => {
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setError
  } = useFormContext<Pick<IUserAuth, 'email'>>();

  const onResetPasswordError = (error: AxiosResponse<{ status: ResetPasswordStatus; state: string }>) => {
    setLoading(false);
    if (error.data.status === 'error.user.email_provider_not_found') {
      setError('email', { type: error.data.state, message: 'User with this email does not exist' });
    }
  };

  const onResetHandler = () => {
    const { email } = getValues();
    setLoading(true);
    dispatch(
      AuthActionAsync.forgotEmail(
        {
          email,
          callback_url: `${window.location.origin}/?link={hash}&action=${EmailActionStatusEnum.password_recovery}`
        },
        setSuccess,
        onResetPasswordError
      )
    );
  };

  return (
    <form onSubmit={handleSubmit(onResetHandler)}>
      <FormControl
        placeholder={'example@mail.com'}
        className={'form-control'}
        disabled={loading}
        isInvalid={!!errors.email}
        {...register('email')}
      />
      {errors.email && <div className={'error'}>{errors.email.message}</div>}
      <Button
        onClick={handleSubmit(onResetHandler)}
        btnProps={{ type: 'submit' }}
        withIcon={false}
        size={'lg'}
        className={classNames('w-100 mt-3', { 'btn-loading': loading })}
        loading={loading}
      >
        Reset password
      </Button>
    </form>
  );
});
