import { ICreatePolicy, IEditPolicy, IPolicy } from '../interfaces/policy';
import { baseFetch } from './BaseFetch';

/**
 * Get Organization Policy List
 * @returns IPolicy[]
 */
export const getPolicyList = async (organization_id?: string) => {
  const url = organization_id ? `/organizations/${organization_id}/policy` : '/policy';
  return await baseFetch<{}, IPolicy[]>(url, {}, 'GET', !organization_id);
};
/**
 * Organization Policy Create
 * @param {ICreatePolicy} data
 * @returns {IPolicy}
 */
export const createPolicy = async (data: ICreatePolicy) => {
  return await baseFetch<ICreatePolicy, IPolicy>('/policy', { ...data }, 'POST', true);
};

/**
 * Organization Policy Edit
 * @param {string} id
 * @param {IEditPolicy} data
 * @returns {Promise<{data: IPolicy | null, status?: void | number, meta?: IMeta}>}
 */
export const editPolicy = async (id: string, data: IEditPolicy) => {
  return await baseFetch<IEditPolicy, IPolicy>(`/policy/${id}`, { ...data }, 'PUT', true);
};

/**
 * Organization Policy Delete
 * @param {string} id
 * @returns {Promise<{data: IPolicy | null, status?: void | number, meta?: IMeta}>}
 */
export const deletePolicy = async (id: string) => {
  return await baseFetch<{}, null>(`/policy/${id}`, {}, 'DELETE', true);
};
