import { CustomDispatch } from 'redux';

import { getQuiz, setProgressNode } from '../../../../../../API/student-cabinet-api';
import { AnswerPayloadType, AnswerTypeEnum } from '../../../../../../interfaces/quiz';
import { QuizActions } from './QuizAction';
import { IViewQuiz, QuizTypeAction } from './QuizState';

export const quizActionAsync = {
  getQuiz:
    (courseId: string, quizId: string, callback: (quiz: IViewQuiz) => void) => async (dispatch: CustomDispatch) => {
      try {
        dispatch(QuizActions.getQuiz.started({ courseId, quizId }));
        const response = await getQuiz(courseId, quizId);
        if (response.data) {
          dispatch(QuizActions.getQuiz.done({ params: { courseId, quizId }, result: response.data }));
          callback(response.data);
        }
      } catch (error) {
        dispatch(QuizActions.getQuiz.failed({ params: { courseId, quizId }, error }));
      }
    },
  completeQuiz:
    <T extends AnswerTypeEnum>(
      courseId: string,
      blockId: string,
      questionId: string,
      action: QuizTypeAction,
      answers: AnswerPayloadType<T>,
      callback: () => void
    ) =>
    async (dispatch: CustomDispatch) => {
      try {
        dispatch(QuizActions.completeAnswer.started({ courseId, blockId, questionId, action, answers }));
        const response = await setProgressNode<
          QuizTypeAction,
          {
            question_id: string;
            answers: AnswerPayloadType<T>;
          }
        >(courseId, blockId, action, { answers: answers, question_id: questionId });
        if (response.data) {
          dispatch(QuizActions.completeAnswer.done({ params: { courseId, blockId, questionId, action, answers } }));
          callback();
        }
      } catch (error) {
        dispatch(
          QuizActions.completeAnswer.failed({
            params: { courseId, blockId, questionId, action, answers },
            error
          })
        );
      }
    },
  tryQuizAgain:
    (courseId: string, blockId: string, action: QuizTypeAction, callback: () => void) =>
    async (dispatch: CustomDispatch) => {
      try {
        dispatch(QuizActions.tryAgain.started({ action, blockId, courseId }));
        const response = await setProgressNode<
          QuizTypeAction,
          {
            question_id: string;
          }
        >(courseId, blockId, action);
        if (response.data) {
          dispatch(QuizActions.tryAgain.done({ params: { action, blockId, courseId } }));
          callback();
        }
      } catch (error) {
        dispatch(QuizActions.tryAgain.failed({ error, params: { action, blockId, courseId } }));
      }
    }
};
