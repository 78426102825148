import { IAccount, IProfileAccount } from '../interfaces';
import { baseFetch } from './BaseFetch';

export const getMe = async (token?: Nullable<string>) => {
  return await baseFetch<{}, IAccount>('/users/me', {}, 'GET', false, {
    ...(token ? { Authorization: `Bearer ${token}` } : {})
  });
};

export const getUsers = async (token?: string) => {
  return await baseFetch<{}, IAccount[]>('/users', {}, 'GET', false, {
    ...(token ? { Authorization: `Bearer ${token}` } : {})
  });
};

export const editMe = async (params: Partial<IProfileAccount>, token?: string) => {
  return await baseFetch<Partial<IProfileAccount>, IProfileAccount>('/users/me', params, 'PUT', false, {
    ...(token ? { Authorization: `Bearer ${token}` } : {})
  });
};

/**
 * Create default organization
 * @param token
 */
export const createOrganization = async (token?: Nullable<string>) => {
  return await baseFetch<{}, null>('/organizations/default', {}, 'POST', false, {
    ...(token ? { Authorization: `Bearer ${token}` } : {})
  });
};
