import { flatMapDeep } from 'lodash';

import { getMembers } from '../../../../../../components/course/course/CourseHelpers';
import { getCourseNodeTree } from '../../../../../../helpers';
import { ICourseNode } from '../../../../courses/store/CourseState';

export const getPathToQuizNode = (hierarchy: ICourseNode) => {
  if (hierarchy) {
    const quizBlock = flatMapDeep(hierarchy.children, getMembers).find((x) => x.type === 'block:quiz');
    const resultHierarchy = getCourseNodeTree([hierarchy], quizBlock.id, ['folder', 'lesson', 'step']);
    if (quizBlock) {
      return resultHierarchy;
    }
  }
};
