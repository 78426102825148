import { memo } from 'react';
import { CSSTransition } from 'react-transition-group';

import styles from '../course-settings.module.scss';

import { Button } from '../../../../../components/UI';

interface IProps {
  showPanel: boolean;
  onCancel: () => void;
  onSave: () => void;
}

export const GeneralForm = memo(({ showPanel, onCancel, onSave }: IProps) => {
  return (
    <>
      <CSSTransition in={showPanel} timeout={300} classNames={styles} unmountOnExit>
        <div className={styles.actions}>
          <Button btnStyle={'secondary'} withIcon={false} onClick={onCancel} className={'mr-1'}>
            Cancel
          </Button>
          <Button btnStyle={'primary'} withIcon={false} onClick={onSave}>
            Update changes
          </Button>
        </div>
      </CSSTransition>
    </>
  );
});
