import actionCreatorFactory from 'typescript-fsa';

import { CommonCourseBlock, IGeneralSettings } from '../../../interfaces';
import { ICourseNode, ILecture } from '../../lms/courses/store/CourseState';
import { ICourseComplete, IStudentCabinet } from './StudentCabinetState';

export const actionCreator = actionCreatorFactory();
export const StudentCabinetActions = {
  getStudentCabinetSettings: actionCreator.async<string, IGeneralSettings, Error>('GET_STUDENT_CABINET_SETTINGS'),
  getStudentCabinetCourses: actionCreator.async<void, IStudentCabinet, Error>('GET_STUDENT_CABINET_COURSES'),
  getStudentCabinetCourseHierarchy: actionCreator.async<{ course_id: string }, ICourseNode[], Error>(
    'GET_STUDENT_CABINET_COURSE_HIERARCHY'
  ),
  setStatusNode: actionCreator.async<{ courseId: string; nodeId: string }, string, Error>('SET_STATUS_NODE'),
  getListStepBlocks: actionCreator.async<{ courseId: string; stepId: string }, CommonCourseBlock[], Error>(
    'GET_LIST_STEP_BLOCKS'
  ),
  getLesson: actionCreator.async<{ courseId: string; lessonId: string }, ILecture[], Error>('GET_STUDENT_LESSON'),
  getStatusesCourse: actionCreator.async<{ courseId: string }, ICourseComplete, Error>('GET_STATUSES_COURSE'),
  setCourseOpened: actionCreator.async<{ courseId: string; lessonId: string; action: string }, void, Error>(
    'SET_COURSE_OPENED'
  )
};
