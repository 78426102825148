import { memo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { fileUrl } from 'src/app/components/file-upload/file-helper';

import {
  AudioControlHandle,
  AudioRecorderControl
} from '../../../../../../../components/audio-recorder-control/AudioRecorderControl';
import { AnswerTypeEnum, IQuizQuestion } from '../../../../../../../interfaces/quiz';
import { useQuizPayload } from '../../quiz-hooks/useQuizPayload';
import { quizActionAsync } from '../../store/QuizActionAsync';
import { useViewQuizContext } from '../../ViewQuizContext';
import { QuizAnswerBtn } from '../quiz-answer-btn/QuizAnswerBtn';

interface IProps {
  questionData: IQuizQuestion;
}

export const RecordAnswer = memo(({ questionData }: IProps) => {
  const [activeBtn, setActiveBtn] = useState(false);
  const audioRef = useRef<AudioControlHandle>(null);
  const dispatch = useDispatch();
  const { blockId, courseId, isPreview, onGetQuiz, onCompleteQuizInPreview, onChangeSelectAnswer } =
    useViewQuizContext<AnswerTypeEnum.audio_input>();
  const { question } = useQuizPayload<AnswerTypeEnum.audio_input>(questionData);
  const onActiveFile = (file?: File) => {
    setActiveBtn(!!file);
  };
  const onSave = (fileUrl: string) => {
    const audio = {
      bucket_name: 'audio',
      type: 'audio',
      object_name: fileUrl
    };
    if (question.id && blockId && !isPreview) {
      dispatch(quizActionAsync.completeQuiz(courseId, blockId, question.id, 'quiz.answer', audio, onGetQuiz));
    } else if (isPreview) {
      onChangeSelectAnswer(audio);
      onCompleteQuizInPreview();
    }
  };

  const audioSrc = question.choices ? fileUrl(question.choices) : null;

  return (
    <>
      {question.id && (
        <AudioRecorderControl
          removeCallback={onActiveFile}
          fileCallback={onActiveFile}
          ref={audioRef}
          withDelete={!question.choices}
          uploadCallback={onSave}
          audioSrc={audioSrc}
          id={question.id}
        />
      )}
      {audioRef.current && !audioRef.current?.errorFile && (
        <div className={'mt-3'}>
          <QuizAnswerBtn
            hasChoices={!!audioSrc}
            isDisable={!activeBtn}
            onSaveAnswerHandler={audioRef.current?.onUpload}
            type={'save'}
          />
        </div>
      )}
    </>
  );
});
