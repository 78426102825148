import { memo, useCallback, useEffect, useMemo } from 'react';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { map } from 'lodash';

import './customSelect.scss';
import stylesOffer from '../../common/commonOffer.module.scss';
import { IconWarning } from '../../../../../../components/UI/icons';

import { ReorderSelectedProducts } from '../../../../../../components/search-products-field/ReorderSelectedProducts';
import { SearchElement } from '../../../../../../components/search-products-field/SearchElement';
import { Button } from '../../../../../../components/UI';
import { InfoMessage } from '../../../../../../components/UI/infoMessage/InfoMessage';
import { PopupCard } from '../../../../../../components/UI/popup-card/PopupCard';
import { useIntercomUpdate } from '../../../../../../hooks/use-intercom-update';
import { IOption } from '../../../../../../interfaces';
import { settingsActions } from '../../../../../../store/settings';
import { FieldScope } from '../../../../field';
import { FieldsEdit } from '../../../../fields/FieldsEdit';
import { ProductCard } from '../../../../products/product-card/ProductCard';
import { IProduct } from '../../../../products/store/ProductsState';
import { OffersActionAsync } from '../../../store/OffersActionAsync';
import { ProductOfferDataSearch } from '../../../store/OfferSelector';
import { ElementItem } from './element-item/ElementItem';

export type IOptionProduct = IOption & { product: IProduct };

interface IProps {
  name: string;
  productsData?: IProduct[];
  errorMessage?: string;
  isError: boolean;
  size?: 'sm' | 'lg';
  setSelectProducts: (data: IProduct[]) => void;
  selectProducts: IProduct[];
}

const scope: FieldScope[] = ['title', 'price', 'quantity', 'image'];

export const ProductSearch = memo(
  ({ name, productsData = [], isError, errorMessage, size = 'lg', selectProducts, setSelectProducts }: IProps) => {
    const { setValue } = useFormContext();
    const dispatch = useDispatch();
    const { isPayloadShow, isSidebarShow } = useSelector((state) => state.settings);
    const productMemo = useMemo(ProductOfferDataSearch, []);
    const products = useSelector((state) => productMemo(state, scope));

    useIntercomUpdate(isPayloadShow);
    const methods = useForm({
      defaultValues: {},
      shouldUnregister: false
    });

    useEffect(() => {
      if (productsData.length > 0) {
        const storeProduct = map(productsData, (product) => {
          if (typeof product === 'string') {
            const item = products.productsFiltered.find((x) => x.id === product);
            if (item) {
              return item;
            }
          }
          return product;
        });

        setSelectProducts(storeProduct);
        setValue(name, map(storeProduct, 'id'));
      }
      //necessary so that the element does not disappear during saving
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [name, productsData, setSelectProducts, setValue]);

    useEffect(() => {
      if (productsData.length > 0) {
        setSelectProducts(productsData);
        setValue(name, map(productsData, 'id'));
      }
    }, [name, productsData, setSelectProducts, setValue]);

    const getProducts = useCallback(
      (inputValue?: string) => {
        dispatch(OffersActionAsync.getOfferProducts(undefined, inputValue));
      },
      [dispatch]
    );

    const onCreateProduct = () => {
      dispatch(settingsActions.setPayloadShowing({ isPayloadShow: true }));
    };

    const onSetProducts = useCallback(
      (data: IProduct[]) => {
        const products = map(data, 'id');
        setValue(name, products, { shouldDirty: true, shouldValidate: true });
        setSelectProducts(data);
      },
      [name, setSelectProducts, setValue]
    );

    const onSelectProduct = useCallback(
      (e: IOptionProduct) => {
        const isExists = selectProducts.find((x) => x.id === e?.product.id);

        if (e?.product && !isExists) {
          const data = [...selectProducts, e.product];

          onSetProducts(data);
        }
      },
      [onSetProducts, selectProducts]
    );

    const removeProduct = useCallback(
      (id: string) => {
        const newProducts = selectProducts.filter((x) => x.id !== id);
        onSetProducts(newProducts);
      },
      [onSetProducts, selectProducts]
    );

    return (
      <>
        <FormProvider {...methods}>
          <PopupCard isShow={isPayloadShow} fullHeight={true}>
            <ProductCard productId={undefined} type={'regular'} isDelete={false} />
          </PopupCard>
        </FormProvider>
        <PopupCard isShow={isSidebarShow} fullHeight={true}>
          <FieldsEdit
            entity={'product'}
            title={'Edit product fields'}
            description={
              'Change the order and the content for the working fields. The settings are applied to all products.'
            }
          />
        </PopupCard>
        <SearchElement
          selectProducts={selectProducts}
          elementsFiltered={products.productsFiltered}
          fieldsElement={products.fields}
          getElements={getProducts}
          loadState={products.loadState}
          size={size}
          onSelectProduct={onSelectProduct}
        >
          <Button
            onClick={onCreateProduct}
            className={'px-5'}
            size={size}
            btnStyle={'secondary'}
            withIcon={false}
          >
            Create
          </Button>
        </SearchElement>
        <InfoMessage
          show={isError && selectProducts.length === 0}
          text={errorMessage || ''}
          className={stylesOffer.errorContainer}
          customIcon={<IconWarning className={stylesOffer.warningIcon} />}
        />
        <ReorderSelectedProducts elements={selectProducts} onSetElements={setSelectProducts} name={name}>
          {(element) => (
            <ElementItem
              title={element.title}
              entity={element.entity}
              id={element.id}
              productFields={element.fields}
              removeElement={removeProduct}
            />
          )}
        </ReorderSelectedProducts>
      </>
    );
  }
);
