import { memo } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';

import styles from './step-five.module.scss';
import { Icon32Info } from '../../../../../components/UI/icons';

import { Button } from '../../../../../components/UI';
import { UnsubscribeSteps } from '../CancelSubscription';

interface IProps {
  onCancel(): void;

  onNext(step: UnsubscribeSteps): void;
}

export const StepFive = memo(({ onCancel, onNext }: IProps) => {
  const { subscription } = useSelector((state) => state.billing);

  const confirmCancellation = () => {
    onCancel();
    onNext('step-1');
  };

  return (
    <>
      <div className={styles.title}>
        <Icon32Info width={64} height={64} />
        Your profile will be canceled on
        {subscription?.cancel_at && <span>{moment(subscription?.cancel_at).format('DD MMM YYYY')}</span>}
      </div>
      <div className={styles.container}>
        <div className={styles.text}>
          Until that date you will be able to restore your subscription and continue using the platform.
        </div>
        <Button onClick={confirmCancellation} size={'lg'} withIcon={false} className={'w-100'}>
          Ok
        </Button>
      </div>
    </>
  );
});
