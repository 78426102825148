/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import querystring from 'query-string';

import { EmailAction, EmailActionStatus } from '../API/action-email-api';
import { Queue } from '../helpers/queue';
import { useAction } from '../hooks/use-action';
import { useGoogleAuth, useLogin } from '../hooks/use-google-auth';
import { useInterval } from '../hooks/use-interval';
import { useQuery } from '../hooks/use-query';
import { IAccount, IError } from '../interfaces';
import { AuthActionAsync } from '../modules/auth/store/AuthActionAsync';
import { AuthActions } from '../modules/auth/store/AuthActions';
import { EmailActionsModal } from '../modules/lms/students/enrollment/email-actions-modal/EmailActionsModal';
import { mainPaths } from '../modules/MainRoutes';
import { AccountActionAsync } from '../modules/settings/account/store/AccountActionAsync';
import {
  ExcludeOrganizationModal
} from '../modules/settings/organization/exclude-organization-modal/ExcludeOrganizationModal';
import { OrganizationActionAsync } from '../modules/settings/organization/store/OrganizationActionAsync';

export const useActionAdminQueue = (queue: Queue<(params: any) => unknown>) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { authToken, currentOrganization } = useSelector((state) => state.auth);

  const history = useHistory();
  const [showExcludeOrganization, setShowExcludeOrganization] = useState(false);
  const { organizations, profile } = useSelector((state) => state.account.account);
  const urlParams = querystring.parseUrl(window.location.href);

  const query = useQuery();
  const code = query.get('code');

  useGoogleAuth();
  const { signIn } = useLogin();
  const { onCloseModal, actionEmail, action, actionHash, data, returnEmailInvitationData, redirectHandler } =
    useAction();

  const onCheckFirstLastName = useCallback(
    (account?: IAccount) => {
      if (
        !(profile.first_name && profile.last_name) ||
        (account && !(account.profile.first_name && account.profile.last_name))
      ) {
        history.push(mainPaths.updateAccount);
      } else {
        history.push({ pathname: mainPaths.main });
      }
    },
    [history, profile.first_name, profile.last_name]
  );

  const successConfirmEmail = useCallback(
    (data: EmailAction) => {
      if (data.status === 'success.user_confirmation_done') {
        dispatch(AuthActions.authSetToken(data.payload.token));
        dispatch(
          AccountActionAsync.getMe(false, data.payload.token, (account) => {
            onCheckFirstLastName(account);
          })
        );
      }
    },
    [dispatch, onCheckFirstLastName]
  );

  const errorConfirmEmail = useCallback(
    (error: IError) => {
      if (error.data.status === 'error.action_already_used') {
        onCheckFirstLastName();
      }
    },
    [onCheckFirstLastName]
  );

  const meCallbackHandler = useCallback(() => {
    const organization = organizations.find((org) => org.organization_id === currentOrganization?.organization_id);

    if (organizations.length > 0 && !organization && !showExcludeOrganization) {
      setShowExcludeOrganization(true);
    }

    if (!(profile.first_name && profile.last_name) && authToken) {
      history.push(mainPaths.updateAccount);
    }
  }, [
    authToken,
    currentOrganization?.organization_id,
    history,
    organizations,
    profile.first_name,
    profile.last_name,
    showExcludeOrganization
  ]);

  useInterval(() => {
    if (authToken) {
      dispatch(AccountActionAsync.getMe(false, undefined, meCallbackHandler));
    }
  }, 3600 * 1000);

  useEffect(() => {
    if (action === 'invite' && actionHash) {
      dispatch(OrganizationActionAsync.invitationEmail(actionHash, returnEmailInvitationData));
    }
    if (urlParams?.query?.action === 'redirect' && urlParams?.query?.link === 'billing') {
      redirectHandler();
    }

    if (action === 'email_confirmation' && actionHash) {
      queue.enqueue(() =>
        dispatch(AuthActionAsync.congratulationEmail(actionHash, successConfirmEmail, errorConfirmEmail))
      );
    }
    if (action === 'password_recovery' && actionHash) {
      queue.enqueue(() => history.push(`${mainPaths.recovery}/?link=${actionHash}&action=${action}`));
    }

    if (authToken && !code) {
      queue.enqueue(() => dispatch(AccountActionAsync.getMe(false, undefined, meCallbackHandler)));
    }
  }, [
    action,
    actionHash,
    authToken,
    code,
    dispatch,
    errorConfirmEmail,
    history,
    meCallbackHandler,
    queue,
    redirectHandler,
    returnEmailInvitationData,
    successConfirmEmail
  ]);

  const onDequeue = useCallback(() => {
    queue.dequeue()?.apply(null, ['']) as { action: EmailActionStatus; actionHash: string };
  }, [queue]);

  useEffect(() => {
    onDequeue();
  }, [onDequeue]);

  const onSwitch = () => {
    queue.clear();
  };

  const onSwitchAccount = () => {
    onSwitch();
    onCloseModal();
    const url = querystring.stringifyUrl({
      url: mainPaths.auth,
      query: {
        link: actionHash,
        action: action
      }
    });

    history.push(url);
  };

  const closeExcludeModal = () => {
    setShowExcludeOrganization(false);
  };

  return (
    <>
      <ExcludeOrganizationModal
        show={showExcludeOrganization}
        organizationName={currentOrganization?.organization_title || ''}
        onCloseModal={closeExcludeModal}
      />

      {data?.status === 'error.email_doesnt_match' && (
        <EmailActionsModal
          onClose={onCloseModal}
          actionEmail={actionEmail}
          loading={loading}
          setLoading={setLoading}
          onSwitchAccount={onSwitchAccount}
          signIn={signIn}
          isAdmin={true}
          logoutRedirectParams={{
            action,
            link: actionHash
          }}
          description={
            <>
              To access the organization, you need to log in with another profile using <b>{data.payload.email}</b>{' '}
              email
            </>
          }
          data={data}
        />
      )}
    </>
  );
};
