import { CancelTokenSource } from 'axios';
import actionCreatorFactory from 'typescript-fsa';

import { ITranscodingStatus } from '../../../../API/video-api';
import { IFileUploader } from './FileUploaderState';

export const actionCreator = actionCreatorFactory();

export const fileUploaderActions = {
  setUploadFiles: actionCreator<IFileUploader[]>('SET_FILE_UPLOAD'),
  updateUploadFile: actionCreator<IFileUploader>('UPDATE_FILE_UPLOAD'),
  clearUploadedFiles: actionCreator('CLEAR_UPLOADED_FILE_UPLOAD'),
  setShowUploader: actionCreator<{ show: boolean }>('TOGGLE_SHOW_UPLOADER'),
  setTranscodingStatus: actionCreator<{ status: ITranscodingStatus; file: File }>('SET_TRANSCODING_STATUS'),
  cancelAllUploading: actionCreator<IFileUploader[]>('CANCEL_ALL_UPLOADING_FILES'),
  retrieveUrlAndUploadFile: actionCreator.async<{ file: File; cancelSource: CancelTokenSource }, {}, Error>(
    'RETRIEVE_URL_AND_UPLOAD_FILE'
  ),
  retrieveUrl: actionCreator.async<{ file: File; bucket_name: string }, {}, Error>('RETRIEVE_URL'),
  uploadFile: actionCreator.async<{ file: File; presigned_url: string; cancelSource: CancelTokenSource }, {}, Error>(
    'UPLOAD_FILE'
  )
};
