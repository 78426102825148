import styles from './remove-modal.module.scss';

import { Button } from '../../../../../../../../components/UI';

interface IProps {
  closeModal: () => void;
  onConfirm: () => void;
}

export const RemoveModal = ({ closeModal, onConfirm }: IProps) => {
  return (
    <div className={styles.container}>
      <div className={styles.containerModal}>
        <div className={styles.title}>
          By removing this message, <br /> this action will not be performed
        </div>
        <div className={styles.btnContainer}>
          <Button onClick={closeModal} withIcon={false} btnStyle={'secondary'} className={'w-100'}>
            Cancel
          </Button>
          <Button onClick={onConfirm} type={'update'} withIcon={false} className={'btn-warning w-100'}>
            Confirm
          </Button>
        </div>
      </div>
    </div>
  );
};
