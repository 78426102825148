import { FC, memo, ReactNode } from 'react';
import { FormControl } from 'react-bootstrap';
import { Controller, useFormContext } from 'react-hook-form';

import classNames from 'classnames';

import { InputLabel } from '../input-label/InputLabel';

interface IProps {
  name: string;
  label: string;
  isOptionField?: boolean;
  inputStyles?: string;
  inputContainerStyles?: string;
  errorNode?: ReactNode;
}

export const InputPrice: FC<IProps> = memo(
  ({ name, label, inputStyles, inputContainerStyles, isOptionField, errorNode, children }) => {
    const { control } = useFormContext();

    return (
      <>
        <InputLabel label={label} classContainer={inputContainerStyles}>
          <div className={classNames(isOptionField && 'd-flex align-items-center')}>
            <Controller
              control={control}
              name={name}
              defaultValue={isOptionField ? null : ''}
              render={({ field: { onChange, value } }) => (
                <FormControl
                  placeholder={'0'}
                  className={classNames('form-control form-control-sm', inputStyles)}
                  maxLength={9}
                  type={'number'}
                  onChange={(e) => e && onChange(Number.parseFloat(e.currentTarget.value) || (isOptionField && null))}
                  value={value || ''}
                />
              )}
            />
            {children}
          </div>
        </InputLabel>
        {errorNode}
      </>
    );
  }
);
