import actionCreatorFactory from 'typescript-fsa';

import { QuizSessionsParams } from '../../../../../../API/quiz-api';
import { IQuizSession, IQuizSessionDetail } from '../../../../../../interfaces/quiz';

export const actionCreator = actionCreatorFactory();

export const QuizSessionsActions = {
  getQuizSessions: actionCreator.async<QuizSessionsParams, IQuizSession[], Error>('GET_QUIZ_SESSIONS'),
  getQuizSession: actionCreator.async<string, IQuizSessionDetail, Error>('GET_QUIZ_SESSION'),
  deleteQuizSession: actionCreator.async<string, null, Error>('DELETE_QUIZ_SESSION'),
  markSkipableQuizSession: actionCreator.async<string, null, Error>('MARK_SKIPABLE_QUIZ_SESSION'),
  markPassedQuizSession: actionCreator.async<{ id: string; is_passed: boolean }, null, Error>(
    'MARK_PASSED_QUIZ_SESSION'
  )
};
