import React, { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import classNames from 'classnames';
import styles from './available-plans.module.scss';

import { getPlans } from '../../../../helpers/plans';
import { ISubscription } from '../../../../interfaces';
import { CancelSubscription } from '../../common/cancel-subscription/CancelSubscription';
import { ChangePlanModal } from '../../common/change-plan-modal/ChangePlanModal';
import { SubscribedSuccess } from '../../common/subscribed-success/SubscribedSuccess';
import { StripeSubscriptionPlans } from '../store';
import { ExpandPlansButton } from './expand-btn/ExpandPlansButton';
import { HeaderPlans } from './header/HeaderPlans';
import { PlanItem } from './plan-item/PlanItem';

export const AvailablePlans = () => {
  const [expanded, setExpanded] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showUnsubscribeModal, setShowUnsubscribeModal] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [newSubscription, setNewSubscription] = useState<ISubscription>();
  const [selectedPlan, setSelectedPlan] = useState<string>();
  const { subscription } = useSelector((state) => state.billing);
  const isCanceled = subscription?.cancel_at_period_end && subscription.cancel_at;
  const [monthly, setMonthly] = useState(subscription?.plan?.interval === 'month');
  const plans = useMemo(getPlans, []);

  const changePeriod = useCallback(() => {
    setMonthly(!monthly);
  }, [monthly]);

  const handleCollapse = useCallback(() => {
    setExpanded(!expanded);
  }, [expanded]);

  const closeModal = useCallback(() => {
    setShowModal(false);
  }, []);

  const changePlan = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
    const id = e.currentTarget.id as `${StripeSubscriptionPlans}`;
    if (id) {
      setSelectedPlan(id);
    }
    setShowModal(true);
  }, []);

  const isCurrent = useCallback(
    (id: string) => {
      return (
        id === subscription?.plan?.product &&
        ((monthly && subscription?.plan?.interval === 'month') ||
          (!monthly && subscription?.plan?.interval === 'year') ||
          subscription?.plan?.product === StripeSubscriptionPlans.everactFree)
      );
    },
    [monthly, subscription?.plan?.interval, subscription?.plan?.product]
  );

  const onSuccess = useCallback(
    (subscription: ISubscription) => {
      setNewSubscription(subscription);
      closeModal();
      setShowSuccess(true);
    },
    [closeModal]
  );

  const closeSuccess = useCallback(() => {
    setShowSuccess(false);
  }, []);

  const cancelSubscription = useCallback(() => {
    setShowUnsubscribeModal(true);
  }, []);

  const closeUnsubscribeModal = useCallback(() => {
    setShowUnsubscribeModal(false);
  }, []);

  return (
    <>
      <div className={styles.container}>
        <HeaderPlans changePeriod={changePeriod} monthly={monthly} />
        <div className={classNames(styles.collapsed, { [styles.open]: expanded })}>
          <div className="row justify-content-center">
            {plans.map((plan, index) => (
              <PlanItem
                key={index}
                id={plan.id}
                title={plan.title}
                year={plan.year}
                month={plan.month}
                monthly={monthly}
                options={plan.options}
                isPopular={!!plan.isPopular}
                isCurrent={isCurrent}
                changePlan={changePlan}
                isCanceled={!!isCanceled}
                cancelSubscription={cancelSubscription}
              />
            ))}
          </div>
        </div>
        <ExpandPlansButton isExpanded={expanded} onExpend={handleCollapse} />
      </div>
      <ChangePlanModal
        show={showModal}
        onClose={closeModal}
        onSuccess={onSuccess}
        selectedPlanId={selectedPlan}
        interval={monthly ? 'month' : 'year'}
        currentPlan={subscription?.plan}
        onUnsubscribe={cancelSubscription}
      />

      {newSubscription && (
        <SubscribedSuccess
          subscription={newSubscription}
          show={showSuccess}
          onClose={closeSuccess}
          onUnsubscribe={cancelSubscription}
        />
      )}
      <CancelSubscription show={showUnsubscribeModal} onClose={closeUnsubscribeModal} />
    </>
  );
};
