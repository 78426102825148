import { memo } from 'react';
import { DraggableProvided } from 'react-beautiful-dnd';
import { Col, FormControl, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import classnames from 'classnames';
import styles from './fields-edit.module.scss';
import { IconDraggable } from '../../../components/UI/icons';

import { IField } from '../field';
import { IFieldSettings } from '../field/FieldSettings';

export type IEditFieldElement = Pick<IField, 'id' | 'payload' | 'type' | 'scope' | 'order'> & {
  typeElement: IFieldSettings | undefined;
};

interface IProps {
  field: IEditFieldElement;
  handleClick: (field: IEditFieldElement) => void;
  isCustom: boolean;
  provided?: DraggableProvided;
}

export const FieldItemElement = memo(({ field, handleClick, isCustom, provided }: IProps) => {
  const fieldStore = useSelector((state) => state.fields.fields.find((x) => x.id === field.id));

  return (
    <Row
      key={field.id}
      className={'mb-2'}
      onClick={() => isCustom && handleClick(field)}
      ref={provided && provided.innerRef}
      {...(provided ? { ...provided.draggableProps } : {})}
    >
      <Col lg={8} className={'d-flex align-items-center'}>
        {isCustom && (
          <div className={styles.draggableIcon} {...(provided ? { ...provided.dragHandleProps } : {})}>
            <IconDraggable />
          </div>
        )}
        <FormControl
          type="text"
          size={'sm'}
          className={classnames('form-control-underline', isCustom && styles.pointer, styles.hideCaret)}
          placeholder={fieldStore?.payload.title}
          disabled={true}
        />
      </Col>
      {isCustom && <Col className={styles.textField}>{field.typeElement?.label}</Col>}
    </Row>
  );
});
