import { FieldEntity } from '../../../crm/field';

type EmailVariableEntity = FieldEntity | 'organization';

export interface IEmailVariable {
  name: string;
  key: string;
  entity?: EmailVariableEntity;
  value?: string;
}

export const organizationFields: IEmailVariable[] = [
  {
    name: 'name',
    key: 'organization.settings.general_title',
    entity: 'organization'
  },
  {
    name: 'industry',
    key: 'organization.settings.general_industry',
    entity: 'organization'
  },
  {
    name: 'email',
    key: 'organization.settings.general_email',
    entity: 'organization'
  },
  {
    name: 'phone',
    key: 'organization.settings.general_phone',
    entity: 'organization'
  },
  {
    name: 'image',
    key: 'organization.settings.general_image',
    entity: 'organization'
  }
];

export const additionalEmailVariables: IEmailVariable[] = [
  {
    key: 'contact.core.date_create',
    name: 'created date',
    entity: 'contact'
  },
  {
    key: 'student.core.date_create',
    name: 'created date',
    entity: 'student'
  }
];
