import { memo, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

import { ISubscription } from '../../../interfaces';
import { billingActionAsync } from '../../settings/billing/store';
import { SettingsPaths } from '../../settings/routes/SettingsRoutes';
import { SettingCourseActionAsync } from '../../settings/store/SettingCourseActionAsync';
import { BlockAccount } from '../blocks/block-account/BlockAccount';
import { CancelPeriod } from '../blocks/cancelPeriod/CancelPeriod';
import { CardTrial } from '../blocks/card/CardTrial';
import { GetWeekTrial } from '../blocks/get-week-trial/GetWeekTrial';
import { PeriodOverAccount } from '../blocks/period-over/PeriodOverAccount';
import { TrialVersionBillet } from '../blocks/trial-version-billet/TrialVersionBillet';
import { UnableSubscription } from '../blocks/unable-subscription/UnableSubscription';
import { AddBillingInformation } from '../common/add-billing-information/AddBillingInformation';

interface IProps {
  subscription: Pick<
    ISubscription,
    | 'subscription_status'
    | 'subscription_current_period_end'
    | 'subscription_current_period_start'
    | 'subscription_payment_source'
    | 'plan'
    | 'next_payment'
    | 'next_payment_attempt'
    | 'cancel_at_period_end'
    | 'cancel_at'
  > | null;
  fromBilling?: boolean;
}

export const PeriodTimes = memo((props: IProps) => {
  const [showAddBillingInfo, setShowAddBillingInfo] = useState(false);
  const [clientSecret, setClientSecret] = useState<string | null>(null);
  const history = useHistory();
  const dispatch = useDispatch();
  const subscription = {
    ...props.subscription
    // subscription_current_period_start: '2022-03-01T13:40:52+00:00',
    // subscription_current_period_end: '2022-03-21T13:40:52+00:00',
    // subscription_payment_source: { brand: 'visa', exp_month: 2, exp_year: 2044, last4: '4242' } as Pick<
    //   IPaymentCard,
    //   'brand' | 'exp_month' | 'exp_year' | 'last4'
    // > | null,
    // // subscription_payment_source: null as Pick<IPaymentCard, 'brand' | 'exp_month' | 'exp_year' | 'last4'> | null,
    // subscription_status: 'incomplete' as SubscriptionStatuses
  };

  const dateStart =
    subscription?.subscription_current_period_start && moment(subscription.subscription_current_period_start).toDate();
  const dateEnd =
    subscription?.subscription_current_period_end && moment(subscription.subscription_current_period_end).toDate();
  const nowDate = moment();

  const leftDays = moment(dateEnd).diff(nowDate, 'days') + 1;

  const haveCard = !!subscription?.subscription_payment_source;
  const formatDateEnd = moment(dateEnd).format('MMMM D, YYYY');

  const clientSecretCallback = (clientSecret: string) => {
    setClientSecret(clientSecret);
  };

  const getSubscription = useCallback(() => {
    dispatch(billingActionAsync.getSubscription());
  }, [dispatch]);

  const onUpgradeHandler = useCallback(() => {
    if (leftDays <= 7) {
      setShowAddBillingInfo(true);
      dispatch(SettingCourseActionAsync.getClientSecret(clientSecretCallback));
    } else {
      history.push(SettingsPaths.billing);
    }
  }, [dispatch, history, leftDays]);

  const onCloseModal = useCallback(() => {
    setShowAddBillingInfo(false);
  }, []);

  const onRenew = useCallback(() => {
    dispatch(billingActionAsync.renewSubscription(false, getSubscription));
  }, [dispatch, getSubscription]);

  return (
    <>
      {subscription?.subscription_status === 'trialing' &&
        subscription.subscription_payment_source &&
        subscription.next_payment &&
        subscription.subscription_current_period_end &&
        subscription.plan &&
        !subscription.cancel_at_period_end && (
          <CardTrial
            next_payment={subscription.next_payment}
            subscription_payment_source={subscription.subscription_payment_source}
            subscription_current_period_end={subscription.subscription_current_period_end}
            plan={subscription.plan}
          />
        )}
      {dateEnd && dateStart && subscription?.subscription_status === 'trialing' && !haveCard && (
        <>
          {leftDays > 0 && leftDays <= 7 && (
            <div className={props.fromBilling ? 'd-flex' : ''}>
              <TrialVersionBillet
                dateEnd={formatDateEnd}
                left={leftDays}
                haveCard={haveCard}
                onUpgradeHandler={onUpgradeHandler}
                fromBilling={props.fromBilling}
              />
              <div className={props.fromBilling ? 'ml-3 w-100' : ''}>
                <GetWeekTrial onUpgradeHandler={onUpgradeHandler} />
              </div>
            </div>
          )}
          {leftDays > 7 && leftDays <= 14 && (
            <TrialVersionBillet
              dateEnd={formatDateEnd}
              left={leftDays}
              haveCard={haveCard}
              onUpgradeHandler={onUpgradeHandler}
              fromBilling={props.fromBilling}
            />
          )}

          {/*<Events />*/}
        </>
      )}
      {dateEnd && dateStart && haveCard && (
        <>
          {leftDays > 0 && leftDays <= 21 && subscription.subscription_status === 'trialing' && !haveCard && (
            <TrialVersionBillet
              dateEnd={formatDateEnd}
              left={leftDays}
              haveCard={haveCard}
              onUpgradeHandler={onUpgradeHandler}
              fromBilling={props.fromBilling}
            />
          )}
          {(subscription?.subscription_status === 'incomplete' ||
            subscription.subscription_status === 'incomplete_expired') &&
            subscription.subscription_payment_source && (
              <UnableSubscription
                onUpgradeHandler={onUpgradeHandler}
                subscription_payment_source={subscription.subscription_payment_source}
                next_payment_attempt={subscription.next_payment_attempt}
                nowDate={nowDate}
              />
            )}
        </>
      )}
      {haveCard &&
        (!subscription?.subscription_status ||
          subscription.subscription_status === 'past_due' ||
          subscription.subscription_status === 'unpaid' ||
          subscription.subscription_status === 'canceled') && <BlockAccount />}
      {subscription?.cancel_at_period_end && subscription.cancel_at && (
        <CancelPeriod dateEnd={subscription.cancel_at} onRenew={onRenew} />
      )}
      {(!subscription?.subscription_status || leftDays <= 0) && !haveCard && <PeriodOverAccount />}
      {clientSecret && (
        <AddBillingInformation show={showAddBillingInfo} onClose={onCloseModal} clientSecret={clientSecret} />
      )}
    </>
  );
});
