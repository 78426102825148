import { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import classNames from 'classnames';
import styles from './authentication.module.scss';

import { mainPaths } from '../MainRoutes';
import { AuthContext } from './AuthContext';
import { MainAuthComponent } from './common/main-auth/MainAuthComponent';
import { ResetPassword } from './common/reset/ResetPassword';
import { Login } from './Login';

export const Authentication = () => {
  const [isReset, setIsReset] = useState(false);
  const location = useLocation();
  const onForgot = () => {
    setIsReset(true);
  };
  const onAuth = () => {
    setIsReset(false);
  };

  return (
    <AuthContext.Provider value={{ onForgot, onAuth }}>
      <MainAuthComponent>
        {isReset ? (
          <div className={styles.intro}>
            <ResetPassword />
          </div>
        ) : (
          <div className={styles.intro}>
            <div className={styles.title}>Sign in</div>
            <div className={classNames(styles.trial)}>
              or{' '}
              <Link to={{ pathname: mainPaths.signUp, search: location.search }}>start your 14-day free trial →</Link>
            </div>
            <Login />
          </div>
        )}
      </MainAuthComponent>
    </AuthContext.Provider>
  );
};
