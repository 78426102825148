import { useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import classNames from 'classnames';
import styles from './sidebar-account.module.scss';

import { DropMenu } from '../../../components/UI';
import { imageUrl } from '../../../components/UI/image-components/image/Image';
import { IFile } from '../../../interfaces';
import { Logout } from '../../../modules/auth/Logout';

interface IProps {
  dropPosition?: 'up' | 'left' | 'right' | 'down';
  className?: string;
}

export const SidebarAccount = ({ dropPosition = 'right', className }: IProps) => {
  const { profile } = useSelector((state) => state.account.account);
  const [showDialog, setShowDialog] = useState(false);
  // const onCloseDialog = () => {
  //   setShowDialog((_x) => false);
  // };
  const onToggleDialog = () => {
    setShowDialog(!showDialog);
  };
  const src = imageUrl({
    size: { width: 48, height: 48 },
    bucket_name: (profile.photo as IFile)?.bucket_name,
    object_name: (profile.photo as IFile)?.object_name
  });

  return (
    <DropMenu
      show={showDialog}
      onToggle={onToggleDialog}
      className={classNames(styles.container, className)}
      drop={dropPosition}
      node={<img alt={'user avatar'} className={styles.img} src={src} referrerPolicy={'no-referrer'} />}
    >
      <Dropdown.Menu className={styles.wrapper}>
        <Dropdown.Item className={styles.user} disabled>
          <div className={styles.name}>
            {profile.first_name} {profile.last_name}
          </div>
          <div className={styles.email}>{profile.email}</div>
        </Dropdown.Item>
        <Dropdown.Divider className={styles.divider} />
        <Dropdown.Item className={styles.logoutLink}>
          <Logout className={styles.logout}>{'Log out'}</Logout>
        </Dropdown.Item>
      </Dropdown.Menu>
    </DropMenu>
  );
};
