import React, { memo, useCallback, useEffect, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import classNames from 'classnames';
import styles from './drag-section.module.scss';
import { Icon16ChevronRight } from '../../../../../../components/UI/icons';

import { DraggableElement } from '../../../../../../components/draggable/DraggableElement';
import { ArrowScroll } from '../../../../../../components/UI/arrowScroll/ArrowScroll';
import { useLessonNode } from '../../../../hooks/use-lesson-node';
import { useViewCourseContext } from '../../../../students/student-course/ViewCourseContext';
import { ICourseNode } from '../../../store/CourseState';
import { LectureStudent } from '../../lecture/LectureStudent';
import { CurriculumTypes } from './CurriculumTypes';

interface IProps {
  className?: string;
}

export const DragSectionStudent = memo(({ className }: IProps) => {
  const { course, nodeId, onRedirectLessonHandler } = useViewCourseContext();
  const { nodeLesson } = useLessonNode(course, nodeId);

  const [sectionState, setSectionState] = useState<ICourseNode[]>([]);
  const isShow = sectionState.length > 1;

  useEffect(() => {
    const orderedLessons = nodeLesson?.children?.sort((a, b) => a.order - b.order);
    if (orderedLessons && orderedLessons.length > 0) {
      setSectionState(orderedLessons || []);
    }
  }, [nodeLesson?.children]);

  const onActiveHandler = useCallback(
    (event: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
      const id = event.currentTarget.dataset['id'];
      const clickedNode = sectionState.find((x) => x.id === id);
      if (clickedNode && course?.id) {
        onRedirectLessonHandler(clickedNode.id);
      }
    },
    [sectionState, course?.id, onRedirectLessonHandler]
  );

  return isShow ? (
    <div className={classNames(styles.container, className)}>
      <ArrowScroll sectionsLength={sectionState.length} nodeId={nodeId}>
        {sectionState.map((element, i) => (
          <DndProvider backend={HTML5Backend} key={i}>
            <DraggableElement
              containerStyles={styles.draggableElement}
              ItemTypes={CurriculumTypes.SECTION}
              id={element?.id}
              moveField={() => {}}
              key={element?.id}
              index={i}
              canDrag={false}
            >
              <LectureStudent onActive={onActiveHandler} item={element} isActive={element.id === nodeId} />
              {sectionState.length - 1 !== i && <Icon16ChevronRight />}
            </DraggableElement>
          </DndProvider>
        ))}
      </ArrowScroll>
    </div>
  ) : null;
});
