import { memo, SyntheticEvent } from 'react';

import classNames from 'classnames';
import enrollmentManipulateStyles from '../enrollment-manipulate/enrollment-manipulate.module.scss';

import { Button } from '../../../../../components/UI';
import { UnavailableBtnPlan } from '../../../../../components/unvailable-button-plan/UnvaliableBtnPlan';
import Restricted from '../../../../../providers/permission/Restricted';

interface IProps {
  onLoad: boolean;
  checked: boolean;

  onSave(): void;

  onCloseModal(): void;

  onChange(e: SyntheticEvent<HTMLInputElement>): void;
}

export const EnrollFooter = memo(({ onCloseModal, onSave, onLoad }: IProps) => {
  return (
    <>
      <div className={classNames('m-0', enrollmentManipulateStyles.sendEmailText)}>
        {/* <Form.Group controlId="formBasicCheckbox" className={'mb-0'}>
          <FormCheck custom label={'Send email invitation'} defaultChecked={checked} onChange={onChange} />
        </Form.Group>
        <span className={enrollmentManipulateStyles.iconEye}>
          <IconEye />
        </span> */}
      </div>
      <div className={'m-0 d-flex'}>
        <Restricted
          to={'crm.student.enroll'}
          fallback={
            <>
              <Button onClick={onCloseModal} withIcon={false} btnStyle={'transparent'} className={'mr-2'}>
                Cancel
              </Button>
              <Button onClick={onSave} btnProps={{ disabled: onLoad }} type={'update'}>
                Save
              </Button>
            </>
          }
        >
          <UnavailableBtnPlan
            titleTooltip={'Manual enrollment'}
            descriptionTooltip={
              'To enroll students manually, you need to upgrade your subscription plan. This option isn’t included in your current plan.'
            }
          />
        </Restricted>
      </div>
    </>
  );
});
