import { memo } from 'react';
import { FormControl } from 'react-bootstrap';
import { Controller, useFormContext } from 'react-hook-form';

import classnames from 'classnames';
import styles from './amount.module.scss';
import { Icon32User } from '../../../../../../components/UI/icons';

import { useBuilderContext } from '../../../BuilderContext';

interface IProps {
  val: number;
  onChangeVal: (x: number) => void;
}

export const AmountItem = memo(({ onChangeVal, val }: IProps) => {
  const { control, setValue } = useFormContext();
  const { selectedNode } = useBuilderContext();

  return (
    <div className={classnames('d-flex mt-4', styles.container)}>
      <div className={styles.label}>How many contacts do you want to reach</div>

      <div className={styles.textContainer}>
        <Controller
          render={({ field: { onChange, value } }) => {
            return (
              <FormControl
                className={styles.text}
                placeholder={'0'}
                type={'number'}
                onChange={(x) => {
                  onChange(x);
                  onChangeVal(Number(x.currentTarget.value));
                  setValue('range.end', Number(x.currentTarget.value));
                  // setValue(`${selectedNode?.id}.end`, Number(x.currentTarget.value));
                }}
                value={value}
              />
            );
          }}
          defaultValue={val || 1}
          control={control}
          name={'range.end'}
        />
        <Icon32User className={styles.icon} />
      </div>
    </div>
  );
});
