import { memo, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { InjectedColorProps } from 'react-color/lib/components/common/ColorWrap';

import classNames from 'classnames';
import styles from './colorPicker.module.scss';

import { Button, CustomTooltip, DropMenu } from '../../../../../../../components/UI';
import { CustomColorPicker } from './ColorPickerWrapped';

interface IProps {
  backgroundColor: string;
}

export const ColorPicker = memo(({ backgroundColor }: IProps) => {
  const [color, setColor] = useState<any>(backgroundColor);
  const [showDialogNavigate, setShowDialogNavigate] = useState(false);

  const onToggleDialogNavigate = () => {
    setShowDialogNavigate((x) => !x);
  };

  const onLoadImage = () => {};

  const onSetColor = (val: Pick<InjectedColorProps, 'hex' | 'hsl' | 'rgb'>) => {
    console.log('%c⇒ val', 'color: #82AAFF', val);
    setColor(val);
  };

  return (
    <div className={styles.container}>
      <div>
        <DropMenu
          show={showDialogNavigate}
          onToggle={onToggleDialogNavigate}
          node={
            <div className={styles.bg}>
              <div className={styles.text}>Background color</div>
              <div className={styles.bgColor} style={{ backgroundColor }}></div>
            </div>
          }
          drop={'up'}
        >
          <Dropdown.Menu className={styles.wrapper}>
            <CustomColorPicker color={color} setColor={onSetColor} controlName={'settings_background_color'} />
          </Dropdown.Menu>
        </DropMenu>
      </div>
      <div className={'d-flex align-items-center'}>
        <div className={styles.text}>Upload background image</div>
        <CustomTooltip direction={'top'}>
          <Button
            onClick={onLoadImage}
            className={classNames(styles.btn, styles.btnDisable)}
            btnStyle={'outline'}
          ></Button>
        </CustomTooltip>
      </div>
    </div>
  );
});
