import React from 'react';

import classNames from 'classnames';
// import { HelpPanel } from './HelpPanel';
import styles from './help.module.scss';

interface IProps {
  className?: string;
}
export const HelperWrapper: React.FC<IProps> = ({ children, className }) => {
  return (
    <div className={classNames(styles.wrapper, className)}>
      <div className={styles.contentWrapper}>{children}</div>
      {/*<HelpPanel />*/}
    </div>
  );
};
