import React from 'react';
import { Form, FormControl, InputGroup, Row } from 'react-bootstrap';

import classnames from 'classnames';
import styles from './contact-card-lists.module.scss';
import { IconSearch } from '../../../../../components/UI/icons';

interface IProps {
  searchListHandler: (e: React.ChangeEvent<HTMLInputElement>) => void;
  classNames?: string;
  showTitle: boolean;
}

export const ListsSearch = ({ searchListHandler, classNames, showTitle }: IProps) => {
  return (
    <>
      {showTitle && (
        <Row className={'mx-0'}>
          <div className={classnames(styles.manageText, classNames)}>Manage list</div>
        </Row>
      )}
      <Row className={'mt-2 mx-0'}>
        <Form.Row className={styles.magnificContainer}>
          <InputGroup>
            <FormControl
              type="text"
              placeholder="Search by list name"
              className={classnames(styles.input, styles.resetZIndexInputGroup)}
              onChange={searchListHandler}
            />
            <div className={classnames(styles.magnific, styles.magnificZIndex0)}>
              <IconSearch className={styles.magnificIcon} />
            </div>
          </InputGroup>
        </Form.Row>
      </Row>
    </>
  );
};
