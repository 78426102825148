import { AxiosResponse } from 'axios';

import { IDomain } from '../../../../interfaces/domain';
import { LoadState } from '../../../../store';
import { ErrorDomainStatus } from '../connect-new-domain/utils/error-domain-handler';

export interface IDomainSettingsState {
  loadState: LoadState;
  error: AxiosResponse<{ status: ErrorDomainStatus; payload: unknown | null }> | null;
  domains: IDomain[];
}

export const DomainSettingsInitialState: IDomainSettingsState = {
  error: null,
  loadState: LoadState.firstLoad,
  domains: []
};
