import classNames from 'classnames';
import styles from './return-course.module.scss';
import { Icon32Reply } from '../../../../../components/UI/icons';

import { useLessonNode } from '../../../hooks/use-lesson-node';
import { useViewCourseContext } from '../ViewCourseContext';

export const StudentReturnCourse = () => {
  const { onSelect, currentNav, nodeId, course } = useViewCourseContext();
  const { nodeLesson } = useLessonNode(course, nodeId);

  const onReturnLesson = () => {
    onSelect(nodeLesson?.id || '', undefined, true);
  };
  const showReturnLesson = currentNav && currentNav.id !== nodeLesson?.parent_id;

  return (
    <>
      {showReturnLesson && (
        <div className={styles.container} onClick={onReturnLesson}>
          <div>
            <Icon32Reply className={styles.icon} />
          </div>
          <div>
            <div className={styles.title}>Return to lesson</div>
            <div className={classNames(styles.text, 'ellipsis')}>{nodeLesson?.title}</div>
          </div>
        </div>
      )}
    </>
  );
};
