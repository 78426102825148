import { useFormContext } from 'react-hook-form';

import canvasStyles from '../canvas-node.module.scss';
import styles from './canvas-condition.module.scss';
import { Icon16CheckMark, Icon16Cross } from '../../../../../components/UI/icons';

import { useNodePayload } from '../../../../../hooks/use-node-payload';
import { PaletteAutomationElementTypes } from '../../../../../interfaces';
import { NodeCanvasLoader } from '../common/NodeCanvasLoader';
import { EmptyCanvasNode } from '../EmptyCanvasNode';
import { CanvasConditionElement } from './CanvasConditionElement';
import { CanvasConditionList } from './CanvasConditionList';
import { CanvasConditionTag } from './CanvasConditionTag';

interface IProps {
  nodeId: string;
}

export const CanvasNodeCondition = ({ nodeId }: IProps) => {
  const node = useNodePayload<PaletteAutomationElementTypes.PROCESSOR_CONDITION>(nodeId);
  const { watch } = useFormContext();
  const type = watch('object_type') || node?.payload.object_type;
  const obj_id = watch('object_id') || node?.payload.object_id;

  return (
    <>
      <NodeCanvasLoader nodeId={nodeId}>
        <>
          {type === 'tag' && (
            <div className={canvasStyles.bgContainer}>
              <CanvasConditionTag id={obj_id} nodeId={nodeId} />
              <CanvasConditionElement icon={<Icon16CheckMark className={styles.iconTrue} />} title={'Yes'} />
              <CanvasConditionElement icon={<Icon16Cross className={styles.iconFalse} />} title={'No'} />
            </div>
          )}
          {type === 'list' && (
            <div className={canvasStyles.bgContainer}>
              <CanvasConditionList id={obj_id} nodeId={nodeId} />
              <CanvasConditionElement icon={<Icon16CheckMark className={styles.iconTrue} />} title={'Yes'} />
              <CanvasConditionElement icon={<Icon16Cross className={styles.iconFalse} />} title={'No'} />
            </div>
          )}
        </>
      </NodeCanvasLoader>

      {!type && <EmptyCanvasNode text={'Double click to open the options and manage condition'} />}
    </>
  );
};
