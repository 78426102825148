import React, { memo, useCallback, useEffect, useState } from 'react';
import { FormCheck } from 'react-bootstrap';
import { Controller, useFormContext } from 'react-hook-form';
import AutosizeInput from 'react-input-autosize';

import styles from './section-block.module.scss';

import { Button, ModalActionComponent, ModalDelete } from '../../../../../components/UI';
import { getUUID } from '../../../../../helpers';
import { BlockType } from '../../../../../interfaces';
import { LessonFieldNames } from '../../curriculum/lesson/LessonBlocks';
import { TextBlockType } from '../../curriculum/text/Text';

interface IProps extends Pick<TextBlockType, 'id' | 'title'> {
  index: number;
  removeBlock(index: number): void;
  type?: `${BlockType}`;
  order?: number;
  is_hidden?: boolean;
}

export const SectionBlock = memo(({ id, title, index, order, type, removeBlock, is_hidden = false }: IProps) => {
  const [showConformation, setShowConformation] = useState(false);
  const [showDeleteQuizConform, setShowDeleteQuizConform] = useState(false);
  const { control, register, setValue } = useFormContext();
  const itemId = id || getUUID();

  const placeholder = (() => {
    switch (type) {
      case BlockType.VIDEO:
        return 'Video section name';

      case BlockType.TEXT:
        return 'Text section title';

      case BlockType.QUIZ:
        return 'Quiz name';

      case BlockType.AUDIO:
        return 'Audio section name';

      case BlockType.BLOCK_ATTACHMENTS:
        return 'Attachments section name';
      default:
        return 'Default section name';
    }
  })();

  useEffect(() => {
    register(`${LessonFieldNames.courseBlock}.${index}.type`);
    register(`${LessonFieldNames.courseBlock}.${index}.order`);
    register(`${LessonFieldNames.courseBlock}.${index}.is_hidden`);
    setValue(`${LessonFieldNames.courseBlock}.${index}.type`, type);
    setValue(`${LessonFieldNames.courseBlock}.${index}.order`, order);
    setValue(`${LessonFieldNames.courseBlock}.${index}.is_hidden`, is_hidden);
  }, [index, is_hidden, order, register, setValue, type]);

  const onCloseDelete = useCallback(() => {
    setShowConformation(false);
    setShowDeleteQuizConform(false);
  }, []);
  const onClickDelete = useCallback(() => {
    removeBlock(index);
    onCloseDelete();
  }, [index, onCloseDelete, removeBlock]);

  const showModal = useCallback(() => {
    if (type === BlockType.QUIZ) {
      setShowDeleteQuizConform(true);
    } else {
      setShowConformation(true);
    }
  }, [type]);

  return (
    <>
      <div className={styles.panel}>
        <div className={styles.titleWrapper}>
          <FormCheck
            className="custom-control custom-switch custom-switch-sm custom-switch-inverse"
            custom
            type={'switch'}
          >
            <Controller
              name={`courseBlock[${index}].is_hidden`}
              control={control}
              defaultValue={is_hidden || false}
              render={({ field: { onChange, value } }) => (
                <FormCheck.Input
                  type="checkbox"
                  className="custom-control-input"
                  id={itemId}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    onChange(e.target.checked);
                  }}
                  checked={value}
                />
              )}
            />
            <FormCheck.Label className="custom-control-label" htmlFor={itemId} />
          </FormCheck>
          <div className={styles.title}>
            <Controller
              name={`courseBlock[${index}].title`}
              control={control}
              defaultValue={title || ''}
              render={({ field: { onChange, value } }) => (
                <AutosizeInput
                  placeholder={placeholder}
                  inputClassName={styles.input}
                  onChange={onChange}
                  value={value}
                  maxLength={80}
                />
              )}
            />
          </div>
        </div>
        <Button onClick={showModal} btnStyle={'outline'} type={'delete'} size={'sm'} className={styles.delete}>
          Delete section
        </Button>
      </div>
      <ModalDelete
        isShowed={showConformation}
        title={
          <span className={styles.titleTextModal}>
            Delete <span>{title}</span> ?
          </span>
        }
        iconType={'warning'}
        description={'All materials from this section will be permanently deleted.'}
        onClose={onCloseDelete}
        textDelete={'Yes, delete'}
        iconStyles={styles.dropDeleteModal}
        onDelete={onClickDelete}
      />
      <ModalActionComponent
        show={showDeleteQuizConform}
        onCloseModal={onCloseDelete}
        title={<span className={styles.titleTextModal}>Do you want do delete this quiz?</span>}
        iconType={'warning'}
        iconStyles={styles.dropDeleteModal}
      >
        <div className={styles.quizDescription}>Students progress on this quiz will be lost from the analytics.</div>
        <div className={'d-flex justify-content-end mt-3'}>
          <Button onClick={onCloseDelete} btnStyle={'transparent'} withIcon={false}>
            Cancel
          </Button>
          <Button
            onClick={onClickDelete}
            iconPosition={'left'}
            type={'delete'}
            btnProps={{ className: styles.quizBtnDelete }}
          >
            Delete
          </Button>
        </div>
      </ModalActionComponent>
    </>
  );
});
