import axios, { AxiosResponse, CancelToken, CancelTokenSource } from 'axios';

import { IFileUploadResponse } from '../interfaces/fileUpload';

export const retrieveNewURL = async (
  file_extension: string | null = 'jpg',
  bucket_name: string
): Promise<IFileUploadResponse> => {
  const response = await axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/api/1.0/users/uploads/presigned_url?bucket_name=${bucket_name}&file_extension=${file_extension}`,
    {
      headers: {
        'Cache-Control': 'no-cache, no-store, must-revalidate'
      }
    }
  );
  return response.data;
};

export const uploadFile = async (
  file: File,
  url: string,
  cancelSource?: CancelTokenSource,
  onUploadProgress?: (progressEvent: any) => void
): Promise<AxiosResponse> => {
  return await axios.put(url, file, {
    headers: {
      'Content-Type': file.type
    },
    cancelToken: cancelSource?.token,
    onUploadProgress
  });
};
