import actionCreatorFactory from 'typescript-fsa';

import { IRun, IRunEvent } from '../../../interfaces';
import { IAutomation } from './AutomationState';

export const actionCreator = actionCreatorFactory();

export const automationActions = {
  getAutomation: actionCreator.async<string, IAutomation, Error>('GET_AUTOMATION'),
  getAutomations: actionCreator.async<{ token: string }, Array<IAutomation>, Error>('GET_AUTOMATIONS'),
  updateAutomationState: actionCreator.async<{ id: string; state: string | null }, IAutomation, Error>(
    'UPDATE_AUTOMATION_STATE'
  ),
  createAutomation: actionCreator.async<void, IAutomation, Error>('CREATE_AUTOMATION'),
  deleteAutomation: actionCreator.async<{ id: string }, null, Error>('DELETE_AUTOMATION'),
  updateAutomation: actionCreator.async<{ id: string; data: { name: string } }, IAutomation, Error>('UPDATE_AUTOMATION'),
  getListAutomationRuns: actionCreator.async<void, IRun[] | null, Error>('GET_LIST_AUTOMATION_RUNS'),
  getListAutomationEventsRuns: actionCreator.async<void, IRunEvent[] | null, Error>('GET_LIST_AUTOMATION_EVENTS_RUNS')
};
