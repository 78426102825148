import { lazy, memo, Suspense } from 'react';
import { DraggableProvided } from 'react-beautiful-dnd';
import { FieldValues, useFormContext } from 'react-hook-form';

import styles from './blocks.module.scss';
import { Icon16Draggable } from '../../../../../../components/UI/icons';

import { Loader } from '../../../../../../components/UI';
import { getUUID } from '../../../../../../helpers';
import { BlockType } from '../../../../../../interfaces';
import { SectionBlock } from '../../../common/section-block/SectionBlock';
import { LessonFieldNames, LessonFormValues } from '../LessonBlocks';

const Text = lazy(() => import('../../text/Text').then(({ Text }) => ({ default: Text })));
const Video = lazy(() => import('../../video/Video').then(({ Video }) => ({ default: Video })));
const Quiz = lazy(() => import('../../quiz/Quiz').then(({ Quiz }) => ({ default: Quiz })));
const Embed = lazy(() => import('../../embed/Embed').then(({ Embed }) => ({ default: Embed })));
const Audio = lazy(() => import('../../audio/Audio').then(({ Audio }) => ({ default: Audio })));
const Attachments = lazy(() =>
  import('../../attachment-files/AttachmentFiles').then(({ AttachmentFiles }) => ({ default: AttachmentFiles }))
);

interface IProps {
  index: number;
  removeBlock: (index: number) => void;
  provided: DraggableProvided;
}

export const Blocks = memo(({ index, provided, removeBlock }: IProps) => {
  const { watch } = useFormContext<FieldValues & LessonFormValues>();
  const blockWatch = watch(`${LessonFieldNames.courseBlock}.${index}`);

  return (
    <div key={blockWatch.id} className={styles.container} ref={provided.innerRef} {...provided.draggableProps}>
      <div className={styles.titleContainer}>
        <div className={styles.iconDrag} {...provided.dragHandleProps}>
          <Icon16Draggable />
        </div>
        <SectionBlock
          id={blockWatch.id || getUUID()}
          index={index}
          removeBlock={removeBlock}
          title={blockWatch.title}
          is_hidden={blockWatch.is_hidden}
          type={blockWatch.type}
          order={blockWatch.order}
        />
      </div>
      <Suspense fallback={<Loader className={styles.loader} />}>
        {blockWatch.type === BlockType.TEXT && (
          <Text is_hidden={blockWatch.is_hidden} index={index} text={blockWatch.text} />
        )}
        {blockWatch.type === BlockType.VIDEO && (
          <Video is_hidden={blockWatch.is_hidden} index={index} video={blockWatch.video} />
        )}
        {blockWatch.type === BlockType.QUIZ && <Quiz is_hidden={blockWatch.is_hidden} index={index} />}
        {blockWatch.type === BlockType.EMBED && (
          <Embed is_hidden={blockWatch.is_hidden} index={index} code={blockWatch.code} />
        )}
        {blockWatch.type === BlockType.AUDIO && (
          <Audio is_hidden={blockWatch.is_hidden} index={index} audio={blockWatch.audio} />
        )}
        {blockWatch.type === BlockType.BLOCK_ATTACHMENTS && (
          <Attachments is_hidden={blockWatch.is_hidden} index={index} />
        )}
      </Suspense>
    </div>
  );
});
