import { memo } from 'react';

import classNames from 'classnames';
import styles from './back-return.module.scss';
import { Icon32ArrowLeft } from '../UI/icons';

interface IProps {
  text: string;
  onClick?: () => void;
  isDisable?: boolean;
  className?: string;
}

export const BackReturn = memo(({ text, onClick, isDisable, className }: IProps) => {
  return (
    <div className={classNames(styles.container, { [styles.disable]: isDisable }, className)} onClick={onClick}>
      <Icon32ArrowLeft />
      {text}
    </div>
  );
});
