import { memo, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import {
  IAutomationNodeBuilder,
  IFunnelNodeBuilder,
  PaletteAutomationElementTypes,
  PaletteElementTypes
} from '../../../../../interfaces';
import { builderActionAsync, BuilderType } from '../../store/BuilderActionAsync';
import { PayloadAutomation } from './payload-automation/PayloadAutomation';
import { PayloadCondition } from './payload-condition/PayloadCondition';
import { PayloadActionCourseEnroll } from './payload-course-enroll/PayloadActionCourseEnroll';
import { PayloadEventCourseEnroll } from './payload-course-enroll/PayloadEventCourseEnroll';
import { PayloadDelay } from './payload-delay/PayloadDelay';
import { PayloadDelayAutomation } from './payload-delay/PayloadDelayAutomation';
import { PayloadEmail } from './payload-email/PayloadEmail';
import { PayloadEventList } from './payload-list/PayloadEventList';
import { PayloadList } from './payload-list/PayloadList';
import { PayloadOfferPurchased } from './payload-offer-purchased/PayloadOfferPurchased';
import { PayloadPages } from './payload-pages/PayloadPages';
import { PayloadRange } from './payload-range/PayloadRange';
import { PayloadEventTag } from './payload-tag/PayloadEventTag';
import { PayloadTag } from './payload-tag/PayloadTag';
import { PayloadTrafficSource } from './payload-traffic-source/PayloadTrafficSource';
import { PayloadZapier } from './payload-zapier/PayloadZapier';

interface IProps {
  node: IFunnelNodeBuilder<PaletteElementTypes> | IAutomationNodeBuilder<PaletteAutomationElementTypes>;
  builderType: BuilderType;
  id: string;
  initNode: (node: IFunnelNodeBuilder<PaletteElementTypes>) => void;
}

export const PayloadData = memo(({ node, builderType, id, initNode }: IProps) => {
  const dispatch = useDispatch();
  let component;

  useEffect(() => {
    dispatch(builderActionAsync.getNode(id, node.id, builderType));
  }, [id, builderType, dispatch, node.id]);

  if (node.type === PaletteAutomationElementTypes['ACTION_TAG']) {
    component = <PayloadTag automationId={id} nodeId={node.id} />;
  } else if (node.type === PaletteAutomationElementTypes['ACTION_LIST']) {
    component = <PayloadList automationId={id} nodeId={node.id} />;
  } else if (node.type === PaletteAutomationElementTypes['ACTION_SEND_EMAIL'] && builderType === 'automation') {
    component = <PayloadEmail automationId={id} nodeId={node.id} />;
  } else if (node.type === PaletteAutomationElementTypes['PROCESSOR_CONDITION']) {
    component = <PayloadCondition automationId={id} nodeId={node.id} />;
  } else if (node.type === PaletteAutomationElementTypes['EVENT_TAG_MODIFIED']) {
    component = <PayloadEventTag automationId={id} nodeId={node.id} />;
  } else if (node.type === PaletteAutomationElementTypes['EVENT_LIST_MODIFIED']) {
    component = <PayloadEventList automationId={id} nodeId={node.id} />;
  } else if (node.type === PaletteAutomationElementTypes['EVENT_OFFER_PURCHASED']) {
    component = <PayloadOfferPurchased nodeId={node.id} />;
  } else if (node.type === PaletteElementTypes['ACTION_AUTOMATION']) {
    component = <PayloadAutomation funnelId={id} nodeId={node.id} />;
  } else if (node.type === PaletteAutomationElementTypes['PROCESSOR_RANGE']) {
    component = <PayloadRange nodeId={node.id} />;
  } else if (node.type === PaletteAutomationElementTypes['PROCESSOR_DELAY'] && builderType === 'automation') {
    component = <PayloadDelayAutomation nodeId={node.id} />;
  } else if (node.type === PaletteElementTypes['ACTION_DELAY'] && builderType === 'funnel') {
    component = <PayloadDelay nodeId={node.id} />;
  } else if (node.type === PaletteElementTypes['ACTION_EMAIL']) {
    component = <PayloadEmail automationId={id} nodeId={node.id} />;
  } else if (/traffic_source\./.test(node.type)) {
    component = <PayloadTrafficSource nodeId={node.id} />;
  } else if (node.type === PaletteAutomationElementTypes['ACTION_COURSE_ENROLL']) {
    component = <PayloadActionCourseEnroll nodeId={node.id} />;
  } else if (node.type === PaletteAutomationElementTypes['EVENT_COURSE_ENROLMENT']) {
    component = <PayloadEventCourseEnroll nodeId={node.id} />;
  } else if (node.type === PaletteAutomationElementTypes['ACTION_ZAPIER']) {
    component = <PayloadZapier nodeId={node.id} />;
  } else if (node.type === PaletteAutomationElementTypes['EVENT_ZAPIER_TRIGGER_AUTOMATION']) {
    component = <PayloadZapier nodeId={node.id} />;
  } else if (/pages\./.test(node.type)) {
    component = <PayloadPages nodeId={node.id} initNode={initNode} />;
  }

  return <>{component}</>;
});
