import { useCallback, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { object, string } from 'yup';

import classNames from 'classnames';
import styles from './connect-new-domain.module.scss';
import { Icon32Spinner } from '../../../../components/UI/icons';

import { Button } from '../../../../components/UI';
import { domainPattern, subdomainPattern } from '../../../../helpers';
import { useLoading } from '../../../../hooks/use-loading';
import { IDomain } from '../../../../interfaces/domain';
import { domainActionAsync } from '../store/DomainSettingsActionAsync';
import { CompleteSteps } from './components/complete-steps/CompleteSteps';
import { DomainInput } from './components/DomainInput';
import { DomainPreview } from './components/DomainPreview';
import { SubDomainInput } from './components/SubDomainInput';
import { ToggleConnectionDomain } from './components/toggle-connection-domain/ToggleConnectionDomain';

export interface ICustomDomainFormValues {
  domain: string;
  subdomain?: string;
}

const schema = object().shape({
  domain: string().required('Enter your custom domain name').matches(domainPattern, {
    message: 'Enter a valid domain name'
  }),
  subdomain: string().notRequired().matches(subdomainPattern, {
    message: 'Allowed symbols: a-z, 0-9.',
    excludeEmptyString: true
  })
});

export const ConnectNewDomain = () => {
  const [domain, setDomain] = useState(true);
  const [creationDomain, setCreationDomain] = useState<IDomain>();
  const { loading, startLoading, stopLoading } = useLoading();
  const dispatch = useDispatch();

  const methods = useForm<ICustomDomainFormValues>({
    resolver: yupResolver(schema),
    shouldFocusError: true,
    mode: 'all'
  });

  const onAddDomain = () => {
    startLoading();
    dispatch(
      domainActionAsync.createDomain(
        { domain: methods.watch('domain'), subdomain: methods.watch('subdomain') },
        (domain: IDomain) => {
          setCreationDomain(domain);
        },
        stopLoading
      )
    );
  };

  const onToggleDomain = useCallback((val: boolean) => {
    setDomain(val);
  }, []);

  const onVerify = () => {
    stopLoading();
    setCreationDomain(undefined);
    setDomain(false);
  };

  const onGetDomains = () => {
    dispatch(domainActionAsync.getDomains(onVerify));
  };

  return (
    <FormProvider {...methods}>
      <div className={styles.content}>
        {creationDomain ? (
          <div>
            <CompleteSteps isDomain={domain} />
            <div className={'d-flex mt-4'}>
              <Button onClick={onGetDomains} withIcon={false} size={'lg'} className={'mr-2'}>
                {domain ? 'Verify nameservers' : 'Verify subdomain'}
              </Button>
              <Button onClick={onGetDomains} withIcon={false} size={'lg'} btnStyle={'transparent'}>
                Cancel
              </Button>
            </div>
          </div>
        ) : (
          <>
            <div className={styles.header}>Connect new:</div>
            <ToggleConnectionDomain domain={domain} onToggleDomain={onToggleDomain} />

            <DomainInput domain={domain} />
            {!domain && (
              <>
                <SubDomainInput />
                <DomainPreview />
              </>
            )}
            <Button
              onClick={methods.handleSubmit(onAddDomain)}
              withIcon={false}
              size={'lg'}
              loading={loading}
              className={classNames(loading ? 'btn-loading' : '', 'mt-3')}
            >
              {loading ? <Icon32Spinner /> : 'Continue'}
            </Button>
          </>
        )}
      </div>
    </FormProvider>
  );
};
