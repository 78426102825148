import { ReactNode } from 'react';

import { BlockType } from '../../../../../interfaces';

interface IProps {
  title: string;
  img: ReactNode;
  className: string;
  onCreate: (type: keyof typeof BlockType | 'lesson' | 'folder') => void;
  blockType: keyof typeof BlockType | 'lesson' | 'folder';
}

export const CreateLessonElement = ({ className, img, onCreate, title, blockType }: IProps) => {
  return (
    <div data-id={blockType} className={className} onClick={() => onCreate(blockType)}>
      {img}
      {title}
    </div>
  );
};
