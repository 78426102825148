import { useMemo } from 'react';
import { Navbar } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import classNames from 'classnames';
import styles from './course-footer.module.scss';

import { Copyright } from '../../../../components/copyright/Copyright';
import { PolicyContentTypeEnum } from '../../../../interfaces/policy';
import { PoliciesSelector } from '../../../settings/legal/store/PolicySelector';
import { StudentCabinetPaths } from '../../routes/StudentCabinetRoutes';
import { StudentCabinetSelector } from '../../store/StudentCabinetSelector';

interface IProps {
  className?: string;
}

export const CourseFooter = ({ className }: IProps) => {
  const cabinetSettingsMemo = useMemo(StudentCabinetSelector, []);
  const { cabinetSettings } = useSelector((state) => cabinetSettingsMemo(state));
  const policiesMemo = useMemo(PoliciesSelector, []);
  const { terms, custom, privacy } = useSelector((state) => policiesMemo(state));

  return (
    <Navbar className={classNames(styles.container, className)}>
      <Copyright organization={cabinetSettings?.general_title} customStyles={styles.organization} />
      <div className={styles.links}>
        {terms?.show_in_cabinet &&
          (terms?.content?.type === PolicyContentTypeEnum.local ? (
            <Link to={StudentCabinetPaths.policies('terms')} className={styles.link}>
              Terms of Service
            </Link>
          ) : (
            <a
              href={(terms?.content?.type === PolicyContentTypeEnum.external && terms.content?.link) || '/'}
              target={'_blank'}
              rel="noreferrer"
              className={styles.link}
            >
              {terms?.title}
            </a>
          ))}
        {privacy?.show_in_cabinet && (privacy?.content?.type === PolicyContentTypeEnum.local ? (
          <Link to={StudentCabinetPaths.policies('privacy')} className={styles.link}>
            Privacy Policy
          </Link>
        ) : (
          <a
            href={(privacy?.content?.type === PolicyContentTypeEnum.external && privacy.content?.link) || '/'}
            target={'_blank'}
            rel="noreferrer"
            className={styles.link}
          >
            {privacy?.title}
          </a>
        ))}

        {custom
          ?.filter((x) => x.show_in_cabinet)
          .map((policy) => {
            if (policy.content?.type === PolicyContentTypeEnum.local) {
              return (
                <Link to={StudentCabinetPaths.policies(policy.id)} key={policy.id} className={styles.link}>
                  {policy.title}
                </Link>
              );
            }
            return (
              <a
                key={policy.id}
                href={(policy.content?.type === PolicyContentTypeEnum.external && policy.content?.link) || '/'}
                target={'_blank'}
                rel="noreferrer"
                className={styles.link}
              >
                {policy.title}
              </a>
            );
          })}
      </div>
    </Navbar>
  );
};
