import { createSelector } from 'reselect';

import { IAppState } from '../../../../store';

export const EmailSelectorSender = () =>
  createSelector(
    (state: IAppState) => state.email.senders,
    (state: IAppState) => state.email.loadState,
    (senders, loadState) => {
      return {
        senders,
        defaultSender: senders.find((sender) => sender.is_default),
        loadState
      };
    }
  );
