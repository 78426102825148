import ReactDOM from 'react-dom';

import styles from './unsaved.module.scss';

import { Button, ModalActionComponent } from '../../../../../components/UI';

export const UserConfirmation = (message: string, callback: any) => {
  const textObj = JSON.parse(message);

  const container = document.createElement('div');
  container.setAttribute('custom-confirmation-navigation', '');
  document.body.appendChild(container);
  const closeModal = (callbackState: boolean) => {
    ReactDOM.unmountComponentAtNode(container);
    document.body.removeChild(container);
    callback(callbackState);
  };

  const onCloseModal = () => {
    closeModal(false);
  };

  const onConfirm = () => {
    closeModal(true);
  };

  ReactDOM.render(
    <ModalActionComponent
      iconType={'warning'}
      title={textObj.title}
      show={true}
      iconStyles={styles.icon}
      onCloseModal={onCloseModal}
    >
      <div className={styles.description}>{textObj.messageText}</div>
      <div className={'mt-3 d-flex align-items-center justify-content-end'}>
        <Button onClick={onConfirm} btnStyle={'transparent'} withIcon={false}>
          {textObj.cancelText}
        </Button>
        <Button onClick={onCloseModal} withIcon={false} className={'ml-2'}>
          {textObj.confirmText}
        </Button>
      </div>
    </ModalActionComponent>,
    container
  );
};
