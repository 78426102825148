import classNames from 'classnames';
import commonStyles from '../domain-item.module.scss';
import styles from './domain-default.module.scss';
import { Icon32PencilOnPaper } from '../../../../../components/UI/icons';

import { Button } from '../../../../../components/UI';

interface IProps {
  onEditStart: () => void;
}

export const DomainDefault = ({ onEditStart }: IProps) => {
  return (
    <>
      <div className={styles.default}>
        This subdomain is provided by everact.io. It is used as default subdomain if you don't have a custom one.
      </div>
      <Button
        onClick={onEditStart}
        btnStyle={'transparent'}
        customIcon={<Icon32PencilOnPaper />}
        className={classNames('p-0 m-0', commonStyles.edit)}
      />
    </>
  );
};
