import { FC, memo } from 'react';
import { useFormContext } from 'react-hook-form';

import classNames from 'classnames';
import commonOfferStyle from '../../common/commonOffer.module.scss';
import commonStyles from '../price.module.scss';
import styles from './oneTime.module.scss';

import { IPrice } from '../../../store/OffersState';
import { InputPrice } from '../../common/inputPrice/InputPrice';

interface IProps {
  isActive: boolean;
  className?: string;
}

export const OneTime: FC<IProps> = memo(({ isActive, children, className }) => {
  const {
    formState: { errors }
  } = useFormContext<{ price: IPrice }>();

  return (
    <div className={classNames(commonStyles.priceContainerElement, { [commonStyles.active]: isActive }, className)}>
      {children}
      {isActive && (
        <div className={'pl-4'}>
          <InputPrice
            label={'Price, USD'}
            name={'price.price'}
            isOptionField={false}
            inputContainerStyles={'mt-2 mb-0'}
            inputStyles={styles.input}
            errorNode={
              (errors.price as any)?.price && (
                <div className={commonOfferStyle.errorTitle}>{(errors.price as any)?.price?.message}</div>
              )
            }
          />

          <InputPrice
            label={'Compare to'}
            name={'price.old_price'}
            isOptionField={true}
            inputContainerStyles={'mt-3 mb-0'}
            inputStyles={styles.input}
          >
            <div className={styles.description}>This price will be displayed as crossed out</div>
          </InputPrice>
        </div>
      )}
    </div>
  );
});
