import { memo, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import classNames from 'classnames';
import styles from './step-two.module.scss';

import { Button, Loader } from '../../../../../components/UI';
import { ICoupon } from '../../../../../interfaces';
import { billingActionAsync } from '../../../billing/store';
import { UnsubscribeSteps } from '../CancelSubscription';

interface IProps {
  onCancel(): void;

  onNext(step: UnsubscribeSteps): void;
}

export const StepTwo = memo(({ onCancel, onNext }: IProps) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [coupon, setCoupon] = useState<ICoupon>();

  const nextStep = () => {
    onNext('step-3');
  };

  const keepWithOffer = () => {
    if (coupon) {
      dispatch(
        billingActionAsync.applyDiscount(coupon?.id, () => {
          onCancel();
        })
      );
    }
  };

  useEffect(() => {
    dispatch(
      billingActionAsync.getCoupons((coupon) => {
        setCoupon(coupon);
        setLoading(false);
      })
    );
  }, [dispatch]);

  return (
    <div className={styles.container}>
      {loading && <Loader />}
      <div className={styles.title}>
        Just before you go, <br /> we have a special offer for you!
      </div>
      <div className={classNames(styles.offer, { [styles.loading]: loading })}>
        <span>Get</span> <strong>{coupon?.percent_off}% off</strong> on your next {coupon?.duration_in_months} months
        with us
      </div>
      <Button onClick={keepWithOffer} size={'lg'} withIcon={false} className={'mb-2 w-100'}>
        Keep the account and accept the offer
      </Button>
      <Button onClick={nextStep} size={'lg'} withIcon={false} className={'w-100'} btnStyle={'transparent'}>
        Not interested, cancel my account
      </Button>
    </div>
  );
});
