import { memo } from 'react';

import classNames from 'classnames';
import styles from './no-search-result.module.scss';
import { Icon32Magnifier } from '../UI/icons';

interface IProps {
  searchString: string;
  className?: string;
}

export const NoSearchResult = memo(({ searchString, className }: IProps) => {
  return (
    <div className={classNames(styles.container, className)}>
      <Icon32Magnifier width={64} height={64} className={styles.icon} />
      <div className={styles.title}>No search results</div>
      <div className={styles.text}>Could not find «{searchString}»</div>
    </div>
  );
});
