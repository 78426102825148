import React, { forwardRef, Ref } from 'react';

import classNames from 'classnames';
import styles from './dropdown-toggle.module.scss';

import { IconChevronDown } from '../icons';

export type DropdownToggleProps = {
  onClick: (e: Event) => void;
} & React.ComponentPropsWithoutRef<'button'>;

export const DropdownToggle = forwardRef(({ onClick, ...props }: DropdownToggleProps, ref: Ref<HTMLButtonElement>) => (
  <button
    type="button"
    ref={ref}
    className={classNames(styles.container)}
    onClick={(e) => {
      onClick(e);
    }}
    disabled={props.disabled}
  >
    <div className={props.className}>
      {props.children}
      {!props.disabled && <IconChevronDown className={styles.icon} />}
    </div>
  </button>
));
