import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useRouteMatch } from 'react-router-dom';

import classNames from 'classnames';
import styles from '../../../../styles/components/navbar.module.scss';
import { IconArrowLeft } from '../../../components/UI/icons';

import { ItemStatus } from '../../../components/itemStatus/ItemStatus';
import { useQuery } from '../../../hooks/use-query';
import { automationPaths } from '../../../modules/automations/routes/AutomationRoutes';
import { automationActionAsync } from '../../../modules/automations/store/AutomationActionAsync';
import { AutomationState } from '../../../modules/automations/store/AutomationState';
import { funnelStates } from '../../../modules/funnels/funnels/funnel-item/FunnelItem';
import { funnelActionAsync } from '../../../modules/funnels/funnels/store/FunnelActionAsync';
import { FunnelSelector } from '../../../modules/funnels/funnels/store/FunnelSelector';
import { funnelPaths } from '../../../modules/funnels/routes/FunnelRoutes';
import { Navbar } from '../../Navbar';

interface IProps {
  route: string;
  name: 'funnel' | 'automation';
}

interface LocationState {
  fromFunnel?: boolean;
}

export const BuilderHeaderPanel = ({ route, name }: IProps) => {
  const dispatch = useDispatch();
  const path = useRouteMatch<{ id: string }>(route);
  const location = useLocation<LocationState>();
  const query = useQuery();
  const methods = useForm<{ navbarName: string }>();
  const authToken = useSelector((state) => state.auth.authToken);
  const funnel = useSelector((state) => FunnelSelector(state, path?.params.id));
  const previousFunnel = useSelector((state) => FunnelSelector(state, query.get('funnel_id') || undefined));
  const { automations, loadState } = useSelector((state) => state.automations);
  const automation = automations.find((x) => x.id === path?.params.id);

  useEffect(() => {
    if (!automation && name === 'automation' && authToken) {
      dispatch(automationActionAsync.getAutomations(authToken));
    }
    if (!funnel && name === 'funnel' && authToken) {
      dispatch(funnelActionAsync.getFunnels(authToken));
    }
  }, [authToken, automation, dispatch, funnel, name]);

  useEffect(() => {
    if (funnel && name === 'funnel') {
      methods.setValue('navbarName', funnel.name);
    }

    if (automation && name === 'automation') {
      methods.setValue('navbarName', automation.name);
    }
  }, [automation, funnel, methods, name, loadState]);

  const handleBlur = (_e: React.FormEvent<HTMLInputElement>) => {
    const formState = methods.getValues();
    const navbarName = formState['navbarName'];
    if (funnel && name === 'funnel' && funnel.name?.trim() !== navbarName.trim()) {
      dispatch(funnelActionAsync.updateFunnel(funnel.id, { name: navbarName }));
    }
    if (automation && name === 'automation' && automation.name?.trim() !== navbarName.trim()) {
      dispatch(automationActionAsync.updateAutomation(automation.id, { name: navbarName }));
    }
  };

  const label = funnel ? funnelStates[funnel.state].label : automation && AutomationState[automation.state];

  const redirect = () => {
    if (location.state?.fromFunnel && previousFunnel?.id) {
      return funnelPaths.funnelId(previousFunnel.id);
    }
    return name === 'funnel' ? funnelPaths.funnelList : automationPaths.automationList;
  };

  return (
    <div className={`navbar navbar-expand justify-content-end ${styles.navbar}`}>
      {path?.params.id && (
        //@ts-ignore
        <FormProvider {...methods}>
          <Link className={classNames('btn btn-sm btn-icon-left', styles.link)} to={redirect()}>
            <IconArrowLeft className={'icon'} />
            {name === 'funnel' ? (
              'Funnels'
            ) : location.state?.fromFunnel ? (
              <>
                Funnel:&nbsp;<span className={styles.name}>{previousFunnel?.name}</span>
              </>
            ) : (
              'Automations'
            )}
          </Link>
          <Navbar handleBlur={handleBlur} funnel={funnel} automation={automation}>
            <ItemStatus className={styles.customStatus} label={label || ''} />
          </Navbar>
        </FormProvider>
      )}
    </div>
  );
};
