import { components } from 'react-select';

import styles from './selectRole.module.scss';
import { Icon16Trash } from '../../../../../components/UI/icons';

export const RemoveBtn = (props: any) => {
  const onRemoveMember = () => {
    props.onRemove();
  };

  return (
    <components.MenuList {...props}>
      {props.children}
      {props.showRemove && (
        <div onClick={onRemoveMember} className={styles.remove}>
          <Icon16Trash />
          Remove
        </div>
      )}
    </components.MenuList>
  );
};
