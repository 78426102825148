import { IComment, IPaginationResponse } from '../../../../../interfaces';
import { LoadState } from '../../../../../store';

export interface IStudentCommentState {
  loadState: LoadState;
  error: Error | null;
  comments: IPaginationResponse<IComment[]>;
}

export const StudentCommentInitialState: IStudentCommentState = {
  error: null,
  loadState: LoadState.firstLoad,
  comments: {
    data: [],
    meta: {
      page: 1,
      page_size: 10,
      total: 0,
      total_pages: 0
    }
  }
};
