import { useEffect, useMemo, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import Select, { SingleValue } from 'react-select';
import moment from 'moment';

import { useNodePayload } from '../../../../../../hooks/use-node-payload';
import { PaletteAutomationElementTypes } from '../../../../../../interfaces';
import { FixedPeriod } from './fixed-period/FixedPeriod';
import { optionsPayload, OptionTypePayloadDelay, styleOptionsPayloadDelay } from './PayloadDelay';
import { UntilFixed } from './until-fixed/UntilFixed';

interface IProps {
  nodeId: string;
}

export const PayloadDelayAutomation = ({ nodeId }: IProps) => {
  const { setValue, register, reset } = useFormContext();
  const node = useNodePayload<PaletteAutomationElementTypes.PROCESSOR_DELAY>(nodeId);
  const initValue = useMemo(
    () => optionsPayload.find((x) => x.value === node?.payload?.period?.period_type) || null,
    [node?.payload?.period?.period_type]
  );
  const [typeSelect, setTypeSelect] = useState<OptionTypePayloadDelay | null>(initValue);

  useEffect(() => {
    document.querySelectorAll(`.endpoint-${nodeId}`).forEach((item) => {
      item.remove();
    });
  }, [nodeId]);

  useEffect(() => {
    if (node?.payload?.period?.period_type) {
      register('period.period_type');
      setValue('period.period_type', node?.payload?.period?.period_type);
    }
  }, [node?.payload?.period?.period_type, register, setValue]);

  const onChangePeriodType = (opt: SingleValue<OptionTypePayloadDelay>) => {
    setValue('period.period_type', opt?.value);
    if (opt?.value === 'fixed_datetime') {
      setValue('period.when', moment().add(5, 'days').toDate());
    }
    setTypeSelect(opt);
  };

  return (
    <>
      <Form.Group>
        <Form.Label>Type of delay</Form.Label>
        <Select
          value={typeSelect}
          getOptionValue={(option) => option.value}
          options={optionsPayload}
          onChange={onChangePeriodType}
          classNamePrefix={'select'}
          className={'select-container'}
          menuPlacement={'auto'}
          placeholder={<>Select</>}
          components={{
            IndicatorSeparator: () => null
          }}
          styles={styleOptionsPayloadDelay}
        />
      </Form.Group>
      {typeSelect?.value === 'fixed_datetime' && <UntilFixed nodeId={nodeId} />}
      {typeSelect?.value === 'fixed_period' && <FixedPeriod nodeId={nodeId} />}
    </>
  );
};
