import Select from 'react-select';

import classNames from 'classnames';

import {
  formatCourseLabel,
  LoadingMessage,
  NoOptionsMessageCourse,
  SearchContainer
} from '../../../../../components/search-products-field/SearchContainer';
import { customOfferSelectStyles } from '../../../../../components/search-products-field/SearchElement';
import { IOption } from '../../../../../interfaces';
import { ICourse } from '../../../../lms/courses/store/CourseState';
import { IOptionCourse } from '../../create-offer/actions/enroll/ActionEnrollCourse';
import { courseForSelect } from '../../create-offer/common/helper';

interface IProps {
  courses: ICourse[];
  selectedCourses: ICourse[];
  onSetCourses: (data: ICourse[]) => void;
  size?: 'sm' | 'lg';
  placeholder: string;
}

export const SearchCourses = ({ courses, selectedCourses, onSetCourses, placeholder, size }: IProps) => {
  const onFilterData = (searchValue = '') => {
    const data = courses.filter((x) => x.title.includes(searchValue));
    return courseForSelect(data);
  };

  const onChange = (e: string) => {
    onFilterData(e);
  };

  const onSelectCourse = (e: IOptionCourse) => {
    const isExists = selectedCourses.find((x) => x.id === e?.course.id);
    if (e?.course && !isExists) {
      const data = [...selectedCourses, e.course];
      onSetCourses(data);
    }
  };

  return (
    <Select
      options={onFilterData()}
      onChange={onSelectCourse as (e: IOption | null) => void}
      onInputChange={onChange}
      formatOptionLabel={formatCourseLabel}
      placeholder={placeholder}
      classNamePrefix={'select'}
      className={classNames('select-container form-control p-0 d-flex offer', size === 'sm' && 'form-control-sm')}
      styles={customOfferSelectStyles}
      components={{
        IndicatorSeparator: () => null,
        Control: SearchContainer,
        NoOptionsMessage: NoOptionsMessageCourse,
        LoadingMessage
      }}
    />
  );
};
