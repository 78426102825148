import { memo, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import classNames from 'classnames';
import styles from './introduce-form.module.scss';

import { Button } from '../../../../components/UI';
import { Logout } from '../../Logout';

interface IFieldValues {
  firstName: string;
  lastName: string;
}

const schema = yup.object().shape({
  firstName: yup.string().max(250).required(),
  lastName: yup.string().max(250).required()
});

interface IProps {
  onSave(data: IFieldValues): void;
}

export const IntroduceForm = memo(({ onSave }: IProps) => {
  const [loading, setLoading] = useState(false);
  const { profile } = useSelector((state) => state.account.account);
  const {
    register,
    handleSubmit,
    formState: { errors, isDirty }
  } = useForm<IFieldValues>({
    resolver: yupResolver(schema)
  });

  const onSubmit = (data: IFieldValues) => {
    setLoading(true);
    onSave(data);
  };

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        <span>Introduce yourself</span>
        {profile && (
          <>
            for {profile.email} <br />
          </>
        )}
        and we are done
      </div>
      <Form.Group className="mb-3" controlId="firstName">
        <Form.Label>First name</Form.Label>
        <Form.Control {...register('firstName')} isInvalid={!!errors.firstName} />
      </Form.Group>
      <Form.Group className="mb-3" controlId="lastName">
        <Form.Label>Last name</Form.Label>
        <Form.Control {...register('lastName')} isInvalid={!!errors.lastName} />
      </Form.Group>
      <Button
        onClick={handleSubmit(onSubmit)}
        withIcon={false}
        size={'lg'}
        className={classNames('w-100')}
        loading={loading}
        btnProps={{ disabled: !isDirty }}
      >
        Get things done
      </Button>
      <Logout className={styles.logout}>Log out</Logout>
    </div>
  );
});
