import { actionCreatorFactory } from 'typescript-fsa';

import { IFunnel } from '../../../../interfaces';

export const actionCreator = actionCreatorFactory();

export const funnelActions = {
  getFunnel: actionCreator.async<string, IFunnel, Error>('GET_FUNNEL'),
  getFunnels: actionCreator.async<{ token: string }, Array<IFunnel>, Error>('GET_FUNNELS'),
  updateFunnelState: actionCreator.async<{ id: string; state: string | null }, IFunnel, Error>('UPDATE_FUNNEL_STATE'),
  createFunnel: actionCreator.async<void, IFunnel, Error>('CREATE_FUNNEL'),
  deleteFunnel: actionCreator.async<{ funnelKey: string }, null, Error>('DELETE_FUNNEL'),
  updateFunnel: actionCreator.async<{ funnelKey: string; data: { name: string } }, IFunnel, Error>('UPDATE_FUNNEL')
};
