import * as yup from 'yup';

import { FieldScope, FieldTypes } from '../modules/crm/field';

export type TypeSchema = FieldTypes | Exclude<FieldScope, 'custom'>;

export const phoneRegExp = /^[\d\s+()-]+$/;
export const gmailRegExp = /^[a-z0-9](\.?[a-z0-9]){5,}@gmail\.com$/;

export const schema: { [k in TypeSchema]: any } = {
  short_text: yup
    .string()
    .transform((value: string, originalValue: string) => (originalValue?.trim() === '' ? null : value))
    .nullable()
    .max(5000),
  long_text: yup
    .string()
    .transform((value: string, originalValue: string) => (originalValue?.trim() === '' ? null : value))
    .nullable()
    .max(50000),
  numeric: yup
    .number()
    .integer()
    .nullable()
    .transform((value: string, originalValue: string) => (originalValue === '' ? null : value)),
  toggle: yup.bool().default(false).nullable(),

  date_time: yup
    .object({
      date: yup.date().nullable(),
      time: yup
        .string()
        .matches(/^(0?[1-9]|1[0-2]):[0-5][0-9]$/, 'Time is not valid')
        .nullable(),
      zo: yup
        .string()
        .transform((data: { label: string; value: string }) => (data ? data : null))
        .nullable()
    })
    .transform((data: { date: Date; time: string; zo: string }) => (data?.date ? data : null))
    .nullable(),

  date: yup
    .string()
    .transform((value: string, originalValue: string) => (originalValue === '' ? null : value))
    .nullable(),
  address: yup
    .object({
      address_1: yup.string().max(5000).nullable(),
      address_2: yup.string().max(5000).nullable(),
      city: yup.string().max(5000).nullable(),
      country: yup.string().max(5000).nullable(),
      state: yup.string().max(5000).nullable(),
      zip: yup.string().max(5000).nullable()
    })
    .transform((data: Record<string, string> | null) => {
      if (typeof data === 'object' &&
        !Array.isArray(data) &&
        data !== null) {
        Object.entries(data).map(([key, val]) => val === '' && delete data[key]);
        return Object.keys(data).length === 0 ? null : data;
      }
    })
    .nullable(),
  short_address: yup
    .object({
      address_1: yup.string().max(5000),
      address_2: yup.string().max(5000),
      city: yup.string().max(5000),
      country: yup.string().max(5000),
      state: yup.string().max(5000),
      zip: yup.string().max(5000)
    })
    .transform((data: Record<string, string>) => {
      Object.entries(data).map(([key, val]) => val === '' && delete data[key]);
      return Object.keys(data).length === 0 ? null : data;
    })
    .nullable(),
  tax_id: yup
    .string()
    .transform((value: string, originalValue: string) => (originalValue?.trim() === '' ? null : value))
    .nullable()
    .max(5000),
  url: yup
    .string()
    .url()
    .transform((value: string, originalValue: string) => (originalValue?.trim() === '' ? null : value))
    .nullable()
    .max(5000),
  select: yup
    .string()
    .transform((data: { label: string; value: string } | string) => {
      if (typeof data === 'string') {
        return data;
      }
      return data?.label ? data.label : null;
    })
    .nullable(),
  multiselect: yup
    .array(yup.string())
    .transform((data: { label: string; value: string }[]) => {
      if (!data) {
        return null;
      }
      if (data.length > 0 && typeof data[0] === 'string') {
        return data;
      } else if (data.length > 0) {
        return data?.map(({ label }) => label);
      }
    })
    .nullable(),
  email: yup
    .string()
    .transform((value: string, originalValue: string) => (originalValue?.trim() === '' ? null : value))
    .nullable()
    .email(),
  first_name: yup
    .string()
    .transform((value: string, originalValue: string) => (originalValue?.trim() === '' ? null : value))
    .nullable()
    .max(256),
  last_name: yup
    .string()
    .transform((value: string, originalValue: string) => (originalValue?.trim() === '' ? null : value))
    .nullable()
    .max(256),
  phone: yup
    .string()
    .matches(phoneRegExp, 'Phone number is not valid')
    .nullable()
    .transform((value: string, originalValue: string) => (originalValue?.trim() === '' ? null : value)),
  responsible_user: yup
    .string()
    .transform((value: string, originalValue: string) => (originalValue?.trim() === '' ? null : value))
    .nullable(),
  title: yup
    .string()
    .transform((value: string, originalValue: string) => (originalValue?.trim() === '' ? null : value))
    .nullable()
    .max(5000),
  quantity: yup
    .number()
    .transform((value: number, originalValue: string) => (originalValue === '' ? null : value))
    .nullable()
    .integer(),
  sku: yup
    .string()
    .transform((value: string, originalValue: string) => (originalValue?.trim() === '' ? null : value))
    .nullable()
    .max(5000),
  state: yup.string().nullable(),
  course_relation: yup.string().nullable(),
  student_relation: yup.string().nullable(),
  contact_relation: yup.string().nullable(),
  relation: yup.string().nullable(),
  description: yup
    .string()
    .transform((value: string, originalValue: string) => (originalValue?.trim() === '' ? null : value))
    .nullable()
    .max(50000),
  price: yup
    .object({
      amount: yup
        .number()
        .transform((value: number, originalValue: string) => (originalValue === '' ? null : value))
        .nullable()
    })
    .nullable(),
  image: yup
    .object({
      bucket_name: yup.string(),
      object_name: yup.string()
    })
    .nullable()
};
