import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Loader } from '../../../components/UI';
import { domainActionAsync } from '../domain/store/DomainSettingsActionAsync';
import { SettingsHeader } from '../SettingsHeader';
import { CreatePolicy } from './common/create-policy/CreatePolicy';
import { PolicyItem } from './policy-item/PolicyItem';
import { PolicyActionAsync } from './store/PolicyActionAsync';

export const Legal = () => {
  const dispatch = useDispatch();
  const { policyList } = useSelector((state) => state.policy);
  const [showCreate, setShowCreate] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    dispatch(
      PolicyActionAsync.getPolicyList(undefined, (data) => {
        console.log('%c⇒ data', 'color: #89DDF7', data);
        setLoading(false);
      })
    );
    dispatch(domainActionAsync.getDomains());
  }, [dispatch]);

  const onShowCreate = () => {
    setShowCreate(true);
  };

  const onHideCreate = () => {
    setShowCreate(false);
  };

  return (
    <div>
      <SettingsHeader title={'Legal'} />
      {loading ? (
        <Loader />
      ) : (
        <>
          {policyList.map((policy, index) => (
            <PolicyItem key={index} policy={policy} />
          ))}
          {showCreate ? (
            <PolicyItem policy={null} open onDelete={onHideCreate} onCreate={onHideCreate} />
          ) : (
            <CreatePolicy onCLick={onShowCreate} />
          )}
        </>
      )}
    </div>
  );
};
