import classNames from 'classnames';
import styles from './tab.module.scss';

type Props = {
  title: string;
  index: number;
  activeTab: boolean;
  titleContainerClassName?: string;
  titleBtnClassName?: string;
  onClick: (index: number) => void;
};

const TabTitle = ({ title, index, titleBtnClassName, titleContainerClassName, activeTab, onClick }: Props) => {
  return (
    <div className={classNames(styles.tabContainer, titleContainerClassName)}>
      <button
        className={classNames(styles.tabBtnContainer, titleBtnClassName, { [styles.active]: activeTab })}
        onClick={() => onClick(index)}
      >
        {title}
      </button>
    </div>
  );
};

export default TabTitle;
