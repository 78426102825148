import { forwardRef } from 'react';
import { Form } from 'react-bootstrap';
import moment from 'moment';

import classNames from 'classnames';
import styles from './untilFixed.module.scss';
import { IconCalendarTime } from '../../../../../../../components/UI/icons';

interface IProps extends React.HTMLProps<HTMLInputElement> {
  iconclassname?: string;
  utcclassname?: string;
}

export const CustomInput = forwardRef((props: IProps, ref: React.Ref<HTMLInputElement>) => {
  return (
    <Form.Group className={styles.timeContainer}>
      <span className={classNames(styles.icon, props.iconclassname)} onClick={props.onClick}>
        <IconCalendarTime />
      </span>
      <input
        {...props}
        value={props.value === '' ? moment().add(5, 'days').format('Do MMMM YYYY, hh:mm A') : props.value}
        className={classNames(props.className, 'pl-5')}
      />
      <span className={classNames(styles.clock, props.utcclassname)} onClick={props.onClick}>
        <span id="basic-addon2" className={classNames('p-0', styles.utcText)}>
          UTC {moment().format('Z')}
        </span>
      </span>
    </Form.Group>
  );
});
