import actionCreatorFactory from 'typescript-fsa';

import { ICourseNode } from '../../lms/courses/store/CourseState';
import { IDashboard, IStatusDashboard } from './DashboardState';

export const actionCreator = actionCreatorFactory();
export const DashboardActions = {
  getDashboard: actionCreator.async<void, IDashboard, Error>('GET_DASHBOARD'),
  setDashboardEvent: actionCreator.async<{ event: IStatusDashboard }, void, Error>('SET_DASHBOARD_EVENT'),
  getHowToVideos: actionCreator.async<{ id: string }, void, Error>('GET_HOW_TO_VIDEOS'),
  getVideo: actionCreator.async<{ id: string }, void, Error>('GET_VIDEO'),
  getDashboardCourseHierarchy: actionCreator.async<{ id: string }, ICourseNode[], Error>(
    'GET_DASHBOARD_COURSE_HIERARCHY'
  )
};
