import React, { useCallback, useEffect, useMemo, useState } from 'react';
import BootstrapTable, { ColumnFormatter, RowEventHandlerProps } from 'react-bootstrap-table-next';
import ToolkitProvider, { InjectedSearchProps } from 'react-bootstrap-table2-toolkit';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

import classNames from 'classnames';
import styles from './automation-list.module.scss';
import { Icon32Archive, Icon32Gear, IconArchive, IconReload, IconTrash } from '../../../components/UI/icons';

import { EmptyList } from '../../../components/empty-list/EmptyList';
import { ListFilter } from '../../../components/listFilter/ListFilter';
import { NoSearchResult } from '../../../components/no-search-result/NoSearchResult';
import { Button, CustomTooltip, Loader, ModalDelete, Toast } from '../../../components/UI';
import { FilterState } from '../../../interfaces/filters';
import { PageTopPanel } from '../../../layout/shared-components/page-top-panel/PageTopPanel';
import { LoadState } from '../../../store';
import { automationPaths } from '../routes/AutomationRoutes';
import { automationActionAsync } from '../store/AutomationActionAsync';
import { AutomationSelector } from '../store/AutomationSelector';
import { AutomationRequestState, AutomationState, AutomationStatus, IAutomation } from '../store/AutomationState';
import { columnsData, FormatterType, RowType } from './config/tableConfig';

export const AutomationList = () => {
  const [activeFilter, setActiveFilter] = useState<FilterState>('all');
  const isArchiveFilter = activeFilter === 'archived';
  const [isShowDelete, setShowDelete] = useState(false);
  const [currentAutomation, setCurrentAutomation] = useState<IAutomation>();
  const authToken = useSelector((state) => state.auth.authToken);
  const history = useHistory();
  const dispatch = useDispatch();
  const automationMemo = useMemo(AutomationSelector, [activeFilter]);
  const { automationList, loadState } = useSelector((state) => automationMemo(state, activeFilter));
  const searchString = useSelector((state) => state.settings.searchString);

  useEffect(() => {
    const fetcher = async () => {
      if (authToken) {
        dispatch(automationActionAsync.getAutomations(authToken));
      }
    };
    fetcher().then();
  }, [authToken, history, dispatch]);

  let tableSearchProps: InjectedSearchProps | null = null;

  useEffect(() => {
    if (tableSearchProps) {
      tableSearchProps.onSearch(searchString.trim());
    }
  }, [searchString, tableSearchProps]);

  const onFilter = useCallback((selectedKey: string | null = 'all') => {
    if (selectedKey) {
      setActiveFilter(selectedKey as FilterState);
    }
  }, []);

  const onCreatedAutomation = (id: string) => {
    history.push(automationPaths.automationListId(id));
  };

  const onCreate = () => {
    dispatch(automationActionAsync.createAutomation(onCreatedAutomation));
  };

  const nameFormatter: FormatterType = (cell) => {
    return <div className={classNames(styles.link, 'ellipsis')}>{cell}</div>;
  };

  const rowActionsFormatter: FormatterType = (cell, row) => {
    const status = AutomationState[row.data.state] as AutomationStatus;

    const onMoveToArchive = (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      dispatch(
        automationActionAsync.updateAutomationState(
          row.id,
          AutomationRequestState.archived,
          () => {
            Toast('success', `Automation "${row.name}" moved to archive`);
          },
          () => {
            Toast('error', 'Something went wrong!');
          }
        )
      );
    };

    const onReturnToDraft = (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      dispatch(
        automationActionAsync.updateAutomationState(
          row.id,
          AutomationRequestState.draft,
          () => {
            Toast('success', `Automation "${row.name}" returned to draft`);
          },
          () => {
            Toast('error', 'Something went wrong!');
          }
        )
      );
    };

    const onDelete = (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      setCurrentAutomation(row.data);
      setShowDelete(true);
    };

    return (
      <div className={'d-flex align-items-center h-100'}>
        <div className="value">
          <div className={classNames(styles.label, styles[status])}>{status}</div>
        </div>
        <div className={styles.buttons}>
          {status === 'archived' && (
            <>
              <CustomTooltip customText={'Delete'} direction={'top'}>
                <button type={'button'} id={row.id} className={'btn btn-icon'} onClick={onDelete}>
                  <IconTrash />
                </button>
              </CustomTooltip>
              <CustomTooltip customText={'Return to draft'} direction={'top'}>
                <button type={'button'} id={row.id} className={'btn btn-icon'} onClick={onReturnToDraft}>
                  <IconReload />
                </button>
              </CustomTooltip>
            </>
          )}
          {(status === 'draft' || status === 'published') && (
            <CustomTooltip
              customText={status === 'published' ? 'Published automation can’t be  archived' : 'Move to archive'}
              direction={'top'}
            >
              <button
                type={'button'}
                className={'btn btn-icon'}
                onClick={onMoveToArchive}
                disabled={status === 'published'}
              >
                <IconArchive />
              </button>
            </CustomTooltip>
          )}
        </div>
      </div>
    );
  };

  const dateFormatter: FormatterType = (cell) => {
    return moment(cell).format('MMM DD, YYYY');
  };

  const extendsColumns = columnsData.map((column, index) => {
    let formatter: ColumnFormatter<RowType, any, string> = (cell) => cell;
    const isLatestColumn = columnsData.length === index + 1;
    const gridClass = isLatestColumn ? 'col-6' : 'col-3';
    if (column.dataField === 'name') {
      formatter = nameFormatter;
    }
    if (column.dataField === 'date_create') {
      formatter = dateFormatter;
    }
    if (isLatestColumn) {
      formatter = rowActionsFormatter;
    }
    return {
      ...column,
      formatter,
      classes: classNames(gridClass, column.classes, { [`${styles.actions}`]: isLatestColumn }),
      headerClasses: gridClass
    };
  });

  const noDataIndication = () => {
    return tableData.length ? <NoSearchResult searchString={searchString} /> : <Loader />;
  };

  const tableData: RowType[] = automationList.map((automation) => {
    return {
      id: automation.id,
      name: automation.name,
      date_create: automation.created_at,
      state: automation.state,
      data: automation
    };
  });

  const onCloseDeleteModal = () => {
    setShowDelete(false);
  };

  const onDelete = () => {
    if (currentAutomation) {
      dispatch(
        automationActionAsync.deleteAutomation(
          currentAutomation.id,
          () => {
            Toast('success', `Automation "${currentAutomation.name}" successfully deleted!`);
            setCurrentAutomation(undefined);
          },
          () => {
            Toast('error', 'Something went wrong.');
          }
        )
      );
      setShowDelete(false);
    }
  };

  const rowEvents: RowEventHandlerProps<RowType> = {
    onClick: (e, row) => {
      history.push(automationPaths.automationListId(row.id));
    }
  };

  return (
    <>
      <PageTopPanel title={'Automations'}>
        <ListFilter activeKey={activeFilter} onSelect={onFilter} />
        <Button onClick={onCreate} className={'ml-4'}>
          Create automation
        </Button>
      </PageTopPanel>
      {loadState === LoadState.firstLoad ? (
        <Loader />
      ) : tableData.length ? (
        <ToolkitProvider search columns={extendsColumns} keyField={'id'} data={tableData}>
          {({ baseProps, searchProps }) => {
            tableSearchProps = searchProps;
            return (
              <BootstrapTable
                {...baseProps}
                classes={styles.table}
                rowClasses={styles.row}
                headerClasses={styles.row}
                // selectRow={selectRow}
                rowEvents={rowEvents}
                hover
                bordered={false}
                noDataIndication={noDataIndication}
              />
            );
          }}
        </ToolkitProvider>
      ) : (
        <EmptyList
          title={'It looks like it’s empty here'}
          description={
            isArchiveFilter ? 'You don’t have any archived automations' : 'Start by creating your first automation'
          }
          button={isArchiveFilter ? 'Go to all automations' : 'Create new automation'}
          onClick={isArchiveFilter ? onFilter : onCreate}
          icon={isArchiveFilter ? <Icon32Archive width={64} height={64} /> : <Icon32Gear width={64} height={64} />}
          small={isArchiveFilter}
        />
      )}
      <ModalDelete
        isShowed={isShowDelete}
        title={
          <div className={'ellipsis'}>
            Automation <span>{currentAutomation?.name}</span> will be deleted!
          </div>
        }
        description={'This automation will be permanently deleted from your list.'}
        onClose={onCloseDeleteModal}
        onDelete={onDelete}
      />
    </>
  );
};
