import { memo } from 'react';

import styles from './get-week.module.scss';
import TrialBackground from '../../../../../assets/images/dashboard.png';
import { Icon32Card } from '../../../../components/UI/icons';

import { Button } from '../../../../components/UI';
import { Block } from '../block/Block';

interface IProps {
  onUpgradeHandler: () => void;
}

export const GetWeekTrial = memo(({ onUpgradeHandler }: IProps) => {
  return (
    <Block className={styles.container}>
      <img src={TrialBackground} alt={''} className={styles.backgroundImg} />
      <div className={styles.backgroundGradient} />
      <h2 className={styles.titleText}>Get +1 week to your trial</h2>
      <div className={styles.wrapper}>
        <h6 className={styles.info}>Update your cart information to receive +1 week to your demo</h6>
        <Button
          onClick={onUpgradeHandler}
          btnStyle={'secondary'}
          withIcon={true}
          customIcon={<Icon32Card />}
          className={styles.btnContainer}
        >
          Update billing information
        </Button>
      </div>
    </Block>
  );
});
