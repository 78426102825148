import { NavLink } from 'react-router-dom';

import classNames from 'classnames';
import styles from '../../../styles/components/sidebar.module.scss';
import { Soon } from '../../components/UI/icons';

interface IProps {
  name: string;
  icon: JSX.Element;
  to: string;
  index: number;
}

export const StudentCabinetSidebarItem = ({ icon, name, to, index }: IProps) => {
  return (
    <li className={`${styles.navItem} nav-item`} key={index}>
      {to.includes('courses') ? (
        <NavLink to={to} exact className={styles.navLink} activeClassName={styles.active}>
          {icon} {name}
        </NavLink>
      ) : (
        <div className={classNames(styles.navLink)}>
          {icon} {name}
          <Soon />
        </div>
      )}
    </li>
  );
};
