import { lazy, memo, Suspense, useMemo } from 'react';
import { Quill } from 'react-quill';

import classNames from 'classnames';
import styles from './previewEmail.module.scss';
import { Icon32PencilOnPaper, IconTrash } from '../../../../../../../components/UI/icons';

import { FontFamily } from '../../../../../../../components/quill-editor/modules/CustomFontFamily';
import { Size } from '../../../../../../../components/quill-editor/modules/CustomFontSize';
import { CustomVideo } from '../../../../../../../components/quill-editor/modules/CustomVideo';
import { Variables } from '../../../../../../../components/quill-editor/modules/variables/Variables';
import { Button, Loader } from '../../../../../../../components/UI';
import { IMessage } from '../../../../../../../interfaces';

const ReactQuill = lazy(() => import('react-quill'));

Quill.register(CustomVideo, true);
Quill.register(Variables);
Quill.register(FontFamily, true);
Quill.register(Size, true);
Quill.debug(false);

interface IProps {
  message?: IMessage;

  onOpenEditor(): void;

  onClear(): void;
}

export const PreviewEmail = memo(({ message, onOpenEditor, onClear }: IProps) => {
  const modules = useMemo(
    () => ({
      toolbar: false
    }),
    []
  );

  return (
    <>
      <div className={styles.container}>
        <div className={classNames(styles.text, 'snow')}>
          <Suspense fallback={<Loader />}>
            <ReactQuill modules={modules} value={message?.payload?.body || ''} readOnly />
          </Suspense>
        </div>
        <div className={styles.overlay}></div>
        <div className={styles.crossContainer} onClick={onClear}>
          <IconTrash className={styles.icon} />
        </div>
      </div>
      <Button
        onClick={onOpenEditor}
        btnStyle={'secondary'}
        customIcon={<Icon32PencilOnPaper className={styles.icon} />}
        iconPosition={'left'}
        size={'lg'}
        className={'mt-3'}
      >
        Edit message
      </Button>
    </>
  );
});
