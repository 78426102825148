import { useHistory, useRouteMatch } from 'react-router-dom';

import classNames from 'classnames';
import stylesNavbar from '../../../../../../../../styles/components/navbar.module.scss';
import stylesSidebar from '../../../../../../../../styles/components/sidebar.module.scss';
import styles from '../lesson-sidebar.module.scss';
import { Icon32Cross } from '../../../../../../../components/UI/icons';

import { Button } from '../../../../../../../components/UI';
import { dashboardPaths } from '../../../../../../dashboard/routes/DashboardRoutes';
import { mainPaths } from '../../../../../../MainRoutes';
import { StudentCabinetPaths } from '../../../../../../student-cabinet/routes/StudentCabinetRoutes';
import { previewPaths } from '../../../../../preview/routes/PreviewRoutes';
import { useViewCourseContext } from '../../../../../students/student-course/ViewCourseContext';
import { coursePaths } from '../../../../routes/CourseRoutes';

export const BackNavigationPanel = () => {
  const history = useHistory();
  const fromPreview = useRouteMatch(previewPaths.previewLessons());
  const fromDashboard = useRouteMatch(dashboardPaths.dashboardLessons());
  const { closeSidebar } = useViewCourseContext();

  const onClick = () => {
    history.push(fromPreview ? coursePaths.courseList : fromDashboard ? mainPaths.main : StudentCabinetPaths.courses);
  };

  const closeMenu = () => {
    closeSidebar();
  };

  return (
    <div
      className={classNames(
        'd-flex justify-content-between align-items-center',
        styles.container,
        fromPreview && stylesNavbar.linkDisableCursor
      )}
    >
      <Button
        onClick={onClick}
        btnStyle={'transparent'}
        type={'arrow-left'}
        iconPosition={'left'}
        className={classNames({ [stylesNavbar.linkDisable]: fromPreview }, 'ml-4')}
      >
        {fromDashboard ? 'Dashboard' : 'My courses'}
      </Button>
      <button
        onClick={closeMenu}
        className={classNames('btn btn-icon d-block d-lg-none', stylesSidebar.closeSidebar, styles.sideBarClose)}
      >
        <Icon32Cross />
      </button>
    </div>
  );
};
