import React, { memo, useEffect, useState } from 'react';

import ThumbnailPlaceholderIcon from '../../../../../assets/svg/thumbnail-placeholder.svg';

import { IFile } from '../../../../interfaces';
import { Image } from '../image/Image';

interface IProps {
  thumbnailCourse: IFile | null;
  width: number;
  height: number;
  imgProps?: React.ImgHTMLAttributes<HTMLImageElement>;
  className?: string;
}

export const ThumbnailPlaceholderImage = memo(({ height, width, thumbnailCourse, imgProps, className }: IProps) => {
  const [thumbnail, setThumbnail] = useState(thumbnailCourse);

  useEffect(() => {
    setThumbnail(thumbnailCourse);
  }, [thumbnailCourse]);

  const onError = () => {
    setThumbnail(null);
  };

  return (
    <>
      {thumbnail ? (
        <Image
          bucket_name={thumbnail.bucket_name}
          object_name={thumbnail.object_name}
          size={{ width, height }}
          alt={'thumbnail'}
          className={className}
          imgProps={{
            onError
          }}
        />
      ) : (
        <img
          alt={'thumbnail'}
          src={ThumbnailPlaceholderIcon}
          width={width}
          height={height}
          className={className}
          {...imgProps}
        />
      )}
    </>
  );
});
