import React, { memo, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { NavLink, useHistory } from 'react-router-dom';
import moment from 'moment';

import classNames from 'classnames';
import styles from './funnel-items.module.scss';
import {
  Icon32Funnel,
  Icon32KebabMenu,
  IconArchive,
  IconPencilOnPaper,
  IconTrash,
  IconUpload
} from '../../../../components/UI/icons';

import { ItemStatus } from '../../../../components/itemStatus/ItemStatus';
import { ModalDelete } from '../../../../components/UI';
import { ThumbPlaceholder } from '../../../../components/UI/image-components/thumb-placeholder/ThumbPlaceholder';
import { KebabToggle } from '../../../../components/UI/kebabToggle/KebabToggle';
import { FunnelListItem } from '../../../../interfaces';
import { funnelPaths } from '../../routes/FunnelRoutes';
import { funnelActionAsync } from '../store/FunnelActionAsync';

enum States {
  publish = 'Publish',
  draft = 'Draft',
  delete = 'Delete',
  archive = 'Archive'
}

type FunnelItemProps = {
  funnelItem: FunnelListItem;
};

type FunnelStates = {
  label: string;
  action: string;
};

export const funnelStates: Record<string, FunnelStates> = {
  '1': { label: 'published', action: 'publish' },
  '2': { label: 'draft', action: 'draft' },
  '3': { label: 'deleted', action: 'delete' },
  '4': { label: 'archived', action: 'archive' }
};

export const FunnelItem = memo(({ funnelItem }: FunnelItemProps) => {
  const { name, updated_at, id } = funnelItem;
  const history = useHistory();
  const [isShowDelete, setShowDelete] = useState(false);
  // const [thumbIsLoaded, setThumbIsLoaded] = useState(false);

  const dispatch = useDispatch();

  const onDeleteItem = () => {
    dispatch(funnelActionAsync.deleteFunnel(funnelItem.id));
    setShowDelete(false);
  };

  const onChangeState = (eventKey: string | null) => {
    if (typeof eventKey === 'string') {
      if (eventKey === '3') {
        setShowDelete(true);
      } else {
        dispatch(funnelActionAsync.updateFunnelState(funnelItem.id, funnelStates[eventKey].action));
      }
    }
  };

  const getDropdownItems = () => {
    switch (funnelItem.state) {
      case 1:
        return (
          <Dropdown.Item eventKey={'2'}>
            <IconPencilOnPaper className="mr-2" /> {States.draft}
          </Dropdown.Item>
        );
      case 2:
        return (
          <>
            <Dropdown.Item eventKey={'1'}>
              <IconUpload className="mr-2" /> {States.publish}
            </Dropdown.Item>
            <Dropdown.Item eventKey={'4'} className={'iconRed'}>
              <IconArchive className="mr-2" /> {States.archive}
            </Dropdown.Item>
          </>
        );
      case 4:
        return (
          <>
            <Dropdown.Item eventKey={'2'}>
              <IconPencilOnPaper className="mr-2" /> {States.draft}
            </Dropdown.Item>
            <Dropdown.Item eventKey={'3'} className={'iconRed'}>
              <IconTrash className="mr-2" /> {States.delete}
            </Dropdown.Item>
          </>
        );
    }
  };

  const openFunnel = () => {
    history.push(funnelPaths.funnelId(id));
  };

  const setModalShow = () => {
    setShowDelete(false);
  };

  const dropdownItem = getDropdownItems();

  // const onImageLoad = () => {
  //   setThumbIsLoaded(true);
  // };

  return (
    <>
      <div className={classNames(styles.container)}>
        <div className={classNames(styles.preview, styles.image)} onClick={openFunnel}>
          <ThumbPlaceholder title={name} icon={<Icon32Funnel />} />
          {/*{thumbIsLoaded ? null : <ThumbPlaceholder title={name} icon={<Icon32Funnel />} />}
          <img
            src={funnelItem.thumbnail}
            alt="thumbnail"
            onLoad={onImageLoad}
            style={thumbIsLoaded ? {} : { display: 'none' }}
          />*/}
          {funnelItem.state !== 4 ? (
            <ItemStatus label={funnelStates[funnelItem.state].label} className={styles.state} />
          ) : null}
        </div>
        <div className={styles.info}>
          <h3 className={styles.title}>
            <NavLink to={funnelPaths.funnelId(id)}>{name}</NavLink>
          </h3>
          <div className={styles['create-date']}>{moment(updated_at).fromNow()}</div>
          <div className={styles['action-button']}>
            <Dropdown onSelect={onChangeState}>
              <Dropdown.Toggle as={KebabToggle} id="dropdown-basic">
                <Icon32KebabMenu />
              </Dropdown.Toggle>
              <Dropdown.Menu align="right" className={'sm'}>
                {dropdownItem}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      </div>
      <ModalDelete
        isShowed={isShowDelete}
        title={
          <>
            Are you sure you want to delete <span className="text-primary">{funnelItem.name}</span> funnel?
          </>
        }
        description={
          'Deleting the funnel will delete all the data collected and all the linked websites created trough our builder. This action is permanent an cannot be undone.'
        }
        onClose={setModalShow}
        onDelete={onDeleteItem}
      />
    </>
  );
});
