import { fileUrl } from 'src/app/components/file-upload/file-helper';

import { AudioPlayerProvider } from '../../../../../../../components/audio-player/AudioPlayerProvider';
import { IAudioQuizAnswer } from '../../../../../../../interfaces/quiz';

interface IProps {
  result: IAudioQuizAnswer;
}

export const QuizAudioAnswer = ({ result }: IProps) => {
  return (
    <div>
      <AudioPlayerProvider audioSrc={fileUrl(result)} />
    </div>
  );
};
