import React, { memo, useEffect, useState } from 'react';
import { FormControl } from 'react-bootstrap';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import classNames from 'classnames';
import styles from './email-sender.module.scss';
import { IconCheckMark, IconSpinner } from '../../../../../components/UI/icons';

import { EmailActionAsync } from '../../../../common/email-editor';
import { SenderItem } from '../sender-item/SenderItem';
import { WarningMessage } from '../warning-message/WarningMessage';

const schema = yup.object().shape({
  email: yup.string().max(250).email('Wrong email format')
});

export const EmailSender = memo(() => {
  const dispatch = useDispatch();
  const [createLoading, setCreateLoading] = useState(false);

  const methods = useForm<{ email: string }>({
    mode: 'all',
    resolver: yupResolver(schema)
  });
  const { register, reset } = methods;
  const { email } = methods.watch();
  const { senders } = useSelector((state) => state.email);

  useEffect(() => {
    dispatch(EmailActionAsync.getSenders());
  }, [dispatch]);

  const onEnter = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      if (methods.formState.isValid) {
        onSave({ email });
      }
    }
  };

  const onSave = ({ email }: { email: string }) => {
    setCreateLoading(true);
    dispatch(
      EmailActionAsync.createSender(
        email,
        () => {
          setCreateLoading(false);
          reset({ email: '' });
        },
        () => {
          setCreateLoading(false);
          reset({ email: '' });
        }
      )
    );
  };

  return (
    <FormProvider {...methods}>
      <div className={styles.container}>
        <div className={styles.title}>Email sender</div>
        <div className={styles.description}>
          List of email addresses that are used to send emails to your customers.
        </div>
        <div className={styles.input}>
          <FormControl
            placeholder={'example@mail.com'}
            onKeyPress={onEnter}
            {...register('email')}
            disabled={createLoading}
            type={'email'}
          />
          {email && (
            <div
              className={classNames(styles.status, {
                [styles.valid]: methods.formState.isValid,
                [styles.loading]: createLoading
              })}
              onClick={methods.handleSubmit(onSave)}
            >
              {createLoading ? <IconSpinner className={'spinner text-primary'} /> : <IconCheckMark />}
            </div>
          )}
        </div>
        <div className={styles.list}>
          <WarningMessage />
          {senders.map((sender) => (
            <SenderItem sender={sender} key={sender.id} />
          ))}
        </div>
      </div>
    </FormProvider>
  );
});
