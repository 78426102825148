import { memo } from 'react';

import styles from './step-one.module.scss';

import { Button } from '../../../../../components/UI';
import { UnsubscribeSteps } from '../CancelSubscription';

interface IProps {
  onCancel(): void;

  onNext(step: UnsubscribeSteps): void;
}

export const StepOne = memo(({ onCancel, onNext }: IProps) => {
  const nextStep = () => {
    onNext('step-2');
  };

  return (
    <div className={styles.container}>
      <div className={styles.label}>Sorry to see you go 😔</div>
      <div className={styles.title}>Do you really want start cancellation process?</div>
      <Button onClick={onCancel} size={'lg'} withIcon={false} className={'mb-2 w-100'}>
        No, it was by accident
      </Button>
      <Button onClick={nextStep} size={'lg'} withIcon={false} className={'w-100'} btnStyle={'transparent'}>
        Yes, I want to cancel my account
      </Button>
    </div>
  );
});
