import { IRoutes } from '../interfaces';
import { PageNotFound } from '../layout/pages/PageNotFound';
import { AutomationRoutes } from './automations/routes/AutomationRoutes';
import { PageBuilderRoutes } from './common/page-builder/routes/PageBuilderRoutes';
import { CRMRoutes } from './crm/routes/CRMRoutes';
import { Dashboard } from './dashboard/Dashboard';
import { DashboardRoutes } from './dashboard/routes/DashboardRoutes';
import { FunnelRoutes } from './funnels/routes/FunnelRoutes';
import { AnswersRoutes } from './lms/answers/routes/AnswersRoutes';
import { CourseRoutes } from './lms/courses/routes/CourseRoutes';
import { CourseSettingsRoutes } from './lms/courses/settings/routes/CourseSettingsRoutes';
import { EnrollmentRoutes } from './lms/enrollment/routes/EnrollmentRoutes';
import { PreviewRoutes } from './lms/preview/routes/PreviewRoutes';
import { StudentRoutes } from './lms/students/routes/StudentRoutes';
import { MessagesRoutes } from './messages';
import { SettingsRoutes } from './settings/routes/SettingsRoutes';
import { StudentCabinetRoutes } from './student-cabinet/routes/StudentCabinetRoutes';

export const mainPaths = {
  main: '/',
  auth: '/login',
  signUp: '/sign-up',
  confirmation: '/confirmation',
  updateAccount: '/update-account',
  googleOAuth: '/oauth',
  recovery: '/recovery',
  notFound: '/404',
  offer: (id = ':id') => `/offer/${id}`
};

export const PrivateRoutes = (hideRoutes: boolean) =>
  hideRoutes
    ? []
    : [
        ...StudentRoutes,
        ...FunnelRoutes,
        ...CRMRoutes,
        ...AutomationRoutes,
        ...MessagesRoutes,
        ...PageBuilderRoutes,
        ...EnrollmentRoutes,
        ...CourseSettingsRoutes,
        ...PreviewRoutes,
        ...CourseRoutes,
        ...AnswersRoutes
      ];

export const MainRoutes: IRoutes[] = [
  {
    path: mainPaths.main,
    exact: true,
    main: () => (
      <div className={'content h-100'}>
        <Dashboard />
      </div>
    )
  },
  {
    path: mainPaths.notFound,
    main: () => <PageNotFound />
  },
  ...SettingsRoutes,
  ...DashboardRoutes
];

export const MainRoutesStudentCabinet = [...StudentCabinetRoutes];
