import { FC, memo } from 'react';

import classNames from 'classnames';
import styles from './hooray-modal.module.scss';
import Birthday from '../../../assets/images/birthday.png';
import Hooray from '../../../assets/images/hooray.png';
import { Icon32Cross } from '../UI/icons';

import { Button, CommonModal } from '../UI';

interface IProps {
  title: string | JSX.Element;
  className?: string;
  onCloseModal: () => void;
  show: boolean;
  modalStyle?: 'orange' | 'blue';
  contentClassName?: string;
}

export const HoorayModal: FC<IProps> = memo(
  ({ title, className, show, onCloseModal, modalStyle = 'orange', contentClassName, children }) => {
    return (
      <>
        <CommonModal onClose={onCloseModal} show={show} params={{ dialogClassName: styles.dialogContainer }}>
          <Button
            onClick={onCloseModal}
            withIcon={false}
            btnProps={{ className: classNames('btn-transparent p-0', styles.closeHoorayBtn) }}
          >
            <Icon32Cross />
          </Button>
          <img
            alt={'congratulations'}
            src={modalStyle === 'orange' ? Birthday : Hooray}
            width={220}
            height={220}
            className={styles.birthday}
          />
          <div className={classNames(styles.containerBirthday, className, styles[modalStyle])}>
            <div className={styles.hoorayText}>Hooray!!!</div>
            <div className={styles.text}>{title}</div>
          </div>
          <div className={classNames(styles.descriptionContainer, contentClassName)}>{children}</div>
        </CommonModal>
      </>
    );
  }
);
