import { memo, useCallback, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import AutosizeInput from 'react-input-autosize';
import { useDispatch } from 'react-redux';
import { DeltaStatic } from 'quill';

import classNames from 'classnames';
import styles from './editorHeader.module.scss';
import { Icon32Info } from '../../../../components/UI/icons';

import { Button, CloseButton } from '../../../../components/UI';
import { useLoading } from '../../../../hooks/use-loading';
import { IMessage } from '../../../../interfaces';
import { EditorOptions, OptionsKeys } from '../common/editor-options/EditorOptions';
import { EmailActionAsync } from '../store/EmailActionAsync';
import { SendTest } from './send-test/SendTest';

interface IProps {
  active: boolean;
  message?: IMessage;
  body: DeltaStatic | undefined;
  preview?: boolean;
  onCancel(): void;
  onSave(): void;
  changeMode(mode: boolean): void;
}

export type StatusEmailSent = 'success' | 'error';

export const EditorHeader = memo(({ onCancel, onSave, active, message, body, preview, changeMode }: IProps) => {
  const dispatch = useDispatch();
  const { control, watch, handleSubmit } = useFormContext<Pick<IMessage, 'title' | 'payload'>>();
  const { loading, startLoading, stopLoading } = useLoading();
  const [status, setStatus] = useState<StatusEmailSent | undefined>();
  const [email, setEmail] = useState('');

  const onEmptyStatus = useCallback(() => {
    setStatus(undefined);
  }, []);

  const successSent = useCallback(() => {
    setStatus('success');
    stopLoading();
  }, [stopLoading]);

  const errorSent = successSent;

  const onSendTestEmail = useCallback(() => {
    startLoading();

    if (!active && body && email) {
      const subject = watch().payload?.subject;
      dispatch(
        EmailActionAsync.sendTestEmailMessageWithBody(
          {
            subject: subject || '',
            body
          },
          email,
          successSent,
          errorSent
        )
      );
    }
    if (active && message && email) {
      dispatch(EmailActionAsync.sendTestEmailMessageWithoutBody(message.id, email, successSent, errorSent));
    }
  }, [active, body, dispatch, email, errorSent, message, startLoading, successSent, watch]);

  const onSelect = (eventKey: string | null) => {
    switch (eventKey) {
      case OptionsKeys.edit: {
        changeMode(true);
      }
    }
    console.log('%c⇒ eventKey', 'color: #89DDF7', eventKey);
  };

  return (
    <div className={styles.container}>
      <div className={styles.left}>
        {preview ? (
          <Button iconPosition={'left'} btnStyle={'transparent'} type={'arrow-left'} onClick={onCancel}>
            Messages
          </Button>
        ) : (
          <>
            <Button onClick={handleSubmit(onSave)} type={'save'} className={'mr-2'} btnProps={{ disabled: active }}>
              Save
            </Button>
            <SendTest
              onSendTest={handleSubmit(onSendTestEmail)}
              onEmptyStatus={onEmptyStatus}
              email={email}
              setEmail={setEmail}
              loading={loading}
              status={status}
            />
          </>
        )}
      </div>
      <div className={styles.center}>
        {preview ? (
          <div className={styles.title}>{message?.title}</div>
        ) : (
          <Controller
            render={({ field: { onChange, value } }) => (
              <AutosizeInput
                inputClassName={classNames('form-control', styles.messageName)}
                value={value}
                onChange={onChange}
                maxLength={50}
                placeholder={'Email message name'}
                readOnly={preview}
              />
            )}
            defaultValue={message?.title}
            name={'title'}
            control={control}
          />
        )}
      </div>
      <div className={styles.right}>
        {preview ? (
          <>
            <Icon32Info className={'flex-shrink-0'} />
            <div className={styles.borders}>
              <EditorOptions onSelect={onSelect} />
            </div>
            <CloseButton onClose={onCancel} className={styles.close} />
          </>
        ) : (
          <Button onClick={onCancel} btnStyle={'transparent'} withIcon={false}>
            Cancel
          </Button>
        )}
      </div>
    </div>
  );
});
