import { Permissions } from '../../../interfaces/permission';
import { LoadState } from '../../../store';

export interface IPermissionState {
  error: Error | null;
  loadState: LoadState;
  permissions: Permissions | null;
}

export const PermissionInitialState: IPermissionState = {
  error: null,
  loadState: LoadState.firstLoad,
  permissions: null
};
