import { useEffect } from 'react';
import { Col, FormControl, Row } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';

import classNames from 'classnames';
import styles from './fields-edit.module.scss';

import { CommonModal } from '../../../components/UI';
import { FieldActionAsync } from '../field';
import { FieldSelectPayload } from '../field/components/FieldSelectPayload';
import { IFieldSettings } from '../field/FieldSettings';
import { FieldFooterModal } from './FieldFooterModal';

interface IProps {
  fieldId?: string;
  typeElement?: IFieldSettings;
  show: boolean;

  onClose(): void;

  onDeleteFieldHandler(): void;
}

export const FieldPayloadModal = ({ fieldId, show, typeElement, onClose, onDeleteFieldHandler }: IProps) => {
  const dispatch = useDispatch();
  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors }
  } = useFormContext();
  const field = useSelector((state) => state.fields.fields.find((x) => x.id === fieldId));

  useEffect(() => {
    setValue('display_options', field?.payload?.display_options ? 'Display options' : 'Dropdown');
    setValue('title', field?.payload.title);
    setValue(
      'options',
      field?.payload.options?.map((x) => {
        return {
          options: x
        };
      })
    );
  }, [field, setValue, show]);

  const onSave = (data: any) => {
    const display = data.display_options === 'Display options';
    if (field?.payload) {
      dispatch(
        FieldActionAsync.editField({
          id: field.id,
          payload: {
            ...field.payload,
            title: data.title,
            options: _.map(data.options, 'options'),
            display_options: display
          }
        })
      );
    }
    onClose();
  };

  return (
    <CommonModal show={show} onClose={onClose}>
      <div className={styles.container}>
        {field && (
          <>
            <Row className={'p-0 mb-3'}>
              <Col sm={1} className={classNames('pr-0', styles.fieldIcon)}>
                {typeElement?.icon}
              </Col>
              <Col className={'px-0'}>
                <FormControl
                  type="text"
                  size={'sm'}
                  className={classNames('form-control-underline', styles.textColor, { invalid: errors.title })}
                  defaultValue={field.payload && field.payload.title}
                  {...register('title', {
                    required: true,
                    maxLength: 64
                  })}
                />
              </Col>
              <Col className={styles.textField}>{typeElement?.placeholder}</Col>
            </Row>
            {field.payload.options && (
              <div className={'pl-4'}>
                <FieldSelectPayload
                  containerStyles={styles.containerOptions}
                  btnStyles={classNames('btn btn-gradient-secondary', styles.btnOption, styles.textColor)}
                  classNames={styles.defaultBG}
                />
              </div>
            )}
          </>
        )}
        <FieldFooterModal onClose={onClose} onDeleteFieldHandler={onDeleteFieldHandler} onSave={handleSubmit(onSave)} />
      </div>
    </CommonModal>
  );
};
