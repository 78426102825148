import React, { useState } from 'react';
import { Collapse } from 'react-bootstrap';

import classNames from 'classnames';
import styles from './settings-collapse-block.module.scss';
import { Icon32Minus, Icon32Plus } from '../../../../components/UI/icons';

interface IProps {
  title: string | JSX.Element;
  headerChildren?: JSX.Element;
  open?: boolean;
  className?: string;
}

export const SettingsCollapseBlock: React.FC<IProps> = ({ title, open = false, headerChildren, className, children }) => {
  const [isOpen, setIsOpen] = useState(open);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={classNames(className, styles.container)}>
      <div className={classNames(styles.title, { [styles.opened]: isOpen })} onClick={toggleOpen}>
        <button aria-expanded={isOpen} className={classNames('btn btn-icon', styles.button)}>
          {isOpen ? <Icon32Minus /> : <Icon32Plus />}
        </button>
        {title}
        {headerChildren}
      </div>
      <Collapse in={isOpen}>
        <div>{children}</div>
      </Collapse>
    </div>
  );
};
