import querystring from 'query-string';

import { IComment, ICommentRequest, IPaginationParams, IThread } from '../interfaces';
import { baseFetch } from './BaseFetch';

export const toggleCommentShow = async (courseId: string, lessonId: string, isHidden: boolean) => {
  return await baseFetch<{ show_comments: boolean }, IComment>(
    `/courses/${courseId}/nodes/${lessonId}`,
    { show_comments: isHidden },
    'PUT',
    true
  );
};

export const toggleHideShowComment = async (commentId: string, isHidden: boolean) => {
  return await baseFetch<{ is_hidden: boolean }, IComment>(
    `/comments/${commentId}`,
    { is_hidden: isHidden },
    'PUT',
    true
  );
};

export const markAsModeratedComment = async (threadId: string) => {
  return await baseFetch<{}, IComment>(`/threads/${threadId}/moderate`, {}, 'PUT', true);
};

export const getOrCreateThreadComments = async (lessonId: string) => {
  return await baseFetch<{ node_id: string }, IThread>('/threads/lesson', { node_id: lessonId }, 'GET', true);
};

export const getComments = async (
  params: { threadId?: string; isModerated?: boolean } & Pick<ICommentRequest, 'sort'>,
  pagination?: IPaginationParams
) => {
  const queryParams = querystring.stringifyUrl({
    url: '/comments',
    query: {
      is_moderated: params?.isModerated,
      thread_id: params?.threadId,
      sort: params.sort,
      page: pagination?.page,
      page_size: pagination?.page_size
    }
  });

  return await baseFetch<{}, IComment[]>(queryParams, {}, 'GET', true);
};

export const getThreads = async (
  params: { courseId?: string; has_unmoderated_comments?: boolean } & Pick<ICommentRequest, 'sort'>,
  pagination?: IPaginationParams
) => {
  const queryParams = querystring.stringifyUrl({
    url: '/threads',
    query: {
      has_unmoderated_comments: params?.has_unmoderated_comments,
      course_id_in: params?.courseId,
      sort: params.sort,
      page: pagination?.page,
      page_size: pagination?.page_size
    }
  });

  return await baseFetch<{}, IThread[]>(queryParams, {}, 'GET', true);
};

export const postComment = async (content: string, threadId: string) => {
  return await baseFetch<Pick<ICommentRequest, 'content' & { thread_id: string }>, IComment>(
    '/comments',
    { content: content, thread_id: threadId },
    'POST',
    true
  );
};
