import React, { memo, SyntheticEvent, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import classNames from 'classnames';
import styles from './change-plan-modal.module.scss';

import { Button, CommonModal, SpinnerLoader } from '../../../../components/UI';
import { getPlans } from '../../../../helpers/plans';
import { IPlan, ISubscription, Plans } from '../../../../interfaces';
import { permissionActionAsync } from '../../../../providers/permission/store/PermissionActionAsync';
import { billingActionAsync } from '../../billing/store';
import { SettingCourseActionAsync } from '../../store/SettingCourseActionAsync';
import { AddPaymentCard } from '../add-payment-card/AddPaymentCard';
import { PaymentCard } from '../payment-card/PaymentCard';

interface IProps {
  show: boolean;
  selectedPlanId?: string;
  interval: 'month' | 'year';
  selectedPlan?: Plans;
  currentPlan?: IPlan | null;

  onClose(): void;

  onSuccess(subscription: ISubscription): void;

  onUnsubscribe(): void;
}

export const ChangePlanModal = memo(
  ({ show, selectedPlanId, interval, currentPlan, onClose, onSuccess, onUnsubscribe }: IProps) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const { paymentCards } = useSelector((state) => state.settingCourse);
    const [selectedCard, setSelectedCard] = useState<string>();
    const defaultCardId = useRef<string>();
    const plans = getPlans();
    const selectedPlan = plans.find((plan) => plan.id === selectedPlanId);

    const confirmButtonText = useMemo(() => {
      if (selectedPlan?.id === 'everact_free' || currentPlan?.interval === 'year') {
        return 'Confirm downgrade';
      }
      return 'Confirm upgrade';
    }, [currentPlan?.interval, selectedPlan?.id]);

    useEffect(() => {
      const defaultCard = paymentCards.find((card) => card.is_default);
      if (defaultCard) {
        setSelectedCard(defaultCard.id);
        defaultCardId.current = defaultCard.id;
      }
    }, [paymentCards]);

    const selectCard = (event: React.MouseEvent<HTMLElement>) => {
      const id = event.currentTarget.id;
      if (id) {
        setSelectedCard(id);
      }
    };

    const changePlan = () => {
      if (selectedPlan && selectedCard) {
        setLoading(true);
        const planId = (interval === 'month' ? selectedPlan.month.id : selectedPlan.year.id) as Plans;
        if (selectedCard !== defaultCardId.current) {
          dispatch(SettingCourseActionAsync.setDefaultCard(selectedCard));
        }
        if (currentPlan) {
          dispatch(billingActionAsync.updateSubscription(planId, updateSubscriptionCallback));
        } else {
          dispatch(billingActionAsync.createSubscription(planId, updateSubscriptionCallback));
        }
      }
    };

    const updateSubscriptionCallback = (subscription: ISubscription) => {
      dispatch(permissionActionAsync.getPermission());
      setLoading(false);
      onSuccess(subscription);
    };

    const unsubscribe = (e: SyntheticEvent<HTMLAnchorElement>) => {
      e.preventDefault();
      onClose();
      onUnsubscribe();
    };

    return (
      <CommonModal show={show} onClose={onClose} showClose>
        <div className={styles.container}>
          <div className={styles.plan}>
            <div className={styles.label}>Change plan</div>
            <div className={'d-flex'}>
              <div className={styles.name}>{selectedPlan?.title}</div>
              <div className={styles.amount}>
                {interval === 'month' ? `$${selectedPlan?.month.price}/mo` : `$${selectedPlan?.year.price}/ye`}
              </div>
            </div>
          </div>
          <div className={styles.cards}>
            {paymentCards.map((card) => (
              <div onClick={selectCard} id={card.id} key={card.id}>
                <PaymentCard
                  card={card}
                  canBeRemoved={paymentCards.length > 1}
                  className={classNames(styles.card, { [styles.selected]: card.id === selectedCard })}
                />
              </div>
            ))}
            <AddPaymentCard className={styles.card} />
          </div>
          <Button onClick={changePlan} withIcon={false} size={'lg'} className={styles.btn}>
            {loading ? <SpinnerLoader spinnerProps={{ size: 'sm' }} variant={'light'} /> : confirmButtonText}
          </Button>
          <Button
            onClick={onClose}
            withIcon={false}
            size={'lg'}
            className={'w-100 mb-3'}
            btnStyle={'secondary'}
          >
            Cancel
          </Button>
          <div className={styles.note}>
            The subscription will renew automatically. You can{' '}
            <a href={'/'} onClick={unsubscribe}>
              unsubscribe
            </a>{' '}
            at any time. <br />
            By clicking the button, you agree to the{' '}
            <a href={'https://everact.io/policies/terms'} target="_blank" rel="noreferrer">
              Terms & Conditions
            </a>
            .
          </div>
        </div>
      </CommonModal>
    );
  }
);
