import React, { useState } from 'react';
import { useEffect } from 'react';
import { FormControl } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';

import classNames from 'classnames';
import styles from './recipient-email.module.scss';
import { Icon16Return } from '../../../../../../../../components/UI/icons';

import { getUUID, validateEmail } from '../../../../../../../../helpers';
import { IEmailActionsAutomationPayload } from '../../../../../../../../interfaces/node-builder-payload/automation-actions/email';
import { EmailItem } from '../email-item/EmailItem';

interface IProps {
  recipientsDefault?: string[];
}

export const RecipientEmails = ({ recipientsDefault }: IProps) => {
  const { setValue, watch, register } = useFormContext<IEmailActionsAutomationPayload>();
  const [valueEmail, setValueEmail] = useState('');
  const recipients = watch().recipient || recipientsDefault || [];
  const onSelectEmail = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const email = e.currentTarget.value;
    if (e.key === 'Enter' && validateEmail(email) && !recipients.includes(email)) {
      setValue('recipient', [...recipients, email]);
      setValueEmail('');
    }
  };

  useEffect(() => {
    if (recipientsDefault && recipientsDefault.length) {
      register('recipient');
      setValue('recipient', recipientsDefault);
    }
  }, [recipientsDefault, register, setValue]);

  const onChangeEmailValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValueEmail(e.currentTarget.value);
  };

  const isValidEmail = validateEmail(valueEmail);

  return (
    <div>
      <div className={styles.container}>
        <FormControl
          placeholder={'Recipient email'}
          onKeyDown={onSelectEmail}
          className={classNames('form-control form-control-sm mt-3', !isValidEmail && valueEmail && 'invalid')}
          onChange={onChangeEmailValue}
          value={valueEmail}
        />
        {isValidEmail && <Icon16Return className={styles.icon} />}
      </div>
      <div className={'mt-2 row mx-0'}>
        {recipients.length ? (
          recipients.map((email) => <EmailItem text={email} key={getUUID()} />)
        ) : (
          <div className={styles.notice}>Enter recipient email and press “Return”</div>
        )}
      </div>
    </div>
  );
};
