import { CancelTokenSource } from 'axios';

import { ITranscodingStatus, MediaItem } from '../../../../API/video-api';
import { IVimeoVideo } from '../../../../interfaces/vimeo';

export interface IFileUploader {
  isBulk?: boolean;
  file: File;
  courseId: string;
  vimeoData?: IVimeoVideo;
  parentBlockId: string;
  progress: number;
  uploaderData: MediaItem;
  transcodingStatus?: ITranscodingStatus;
  cancelToken?: CancelTokenSource;
}

export interface IFileUploaderState {
  files: IFileUploader[];
  isShowUploader: boolean;
}

export const FileUploaderInitialState: IFileUploaderState = {
  files: [],
  isShowUploader: false
};
