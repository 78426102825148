import { memo, useState } from 'react';
import { useDispatch } from 'react-redux';

import styles from './hooray-card.module.scss';

import { HoorayModal } from '../../../components/hooray/HoorayModal';
import { DashboardActionAsync } from '../store/DashboardActionAsync';

interface IProps {
  showHoorayCard: boolean;
  onCloseModal: () => void;
}

export const HoorayCardAdd = memo(({ showHoorayCard, onCloseModal }: IProps) => {
  const dispatch = useDispatch();
  const onCloseHoorayModal = () => {
    onCloseModal();
    dispatch(DashboardActionAsync.setDashboardEvent('payment_source_added'));
  };

  return (
    <HoorayModal
      title={'You got one more week of trial period'}
      className={styles.container}
      show={showHoorayCard}
      onCloseModal={onCloseHoorayModal}
    >
      <div className={styles.wrapper}>
        <div className={styles.cardTitle}>First payment $15 on 21 Sep 2021 MasterCard **** 5547</div>
        <div className={styles.cardDescription}>
          At the end of the trial period your card will be charged. You can cancel your account anytime before the end
          of your trial.
        </div>
      </div>
    </HoorayModal>
  );
});
