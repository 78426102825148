import { BlockType } from 'src/app/interfaces/courseBlock';
import {  TypeOfUploader } from 'src/app/interfaces/fileUpload';
import {
  AnswerType,
  AnswerTypeEnum,
  IQuiz,
  IQuizAnswer,
  IQuizQuestion,
  PassingGradeTypeValues
} from 'src/app/interfaces/quiz';
import { array, boolean, mixed, number, object, ObjectSchema, string, TestContext } from 'yup';

interface TestContextExtended {
  from: {
    schema: ObjectSchema<any>;
    value: IQuiz;
  }[];
}

const AnswerSchema = object().shape({
  id: string().notRequired(),
  is_true: boolean()
    .required()
    .transform((value) => {
      return !!value;
    }),
  order: number().required(),
  title: string()
    .min(1, 'Enter your answer title')
    .max(5000, 'Answer title must be at most 5000 characters')
    .required(),
  type: string().required()
});

const QuestionSchema = object().shape({
  answers: mixed().when('answer_type', {
    is: (val: AnswerType) =>
      ([AnswerTypeEnum.single_select, AnswerTypeEnum.multiple_select] as AnswerType[]).includes(val),
    then: array<IQuizAnswer>()
      .of(AnswerSchema)
      .test('Answer error', 'Choose the correct answer', (value, context) => {
        const findTrueAnswer = value?.find((answer) => answer.is_true);
        if (!findTrueAnswer) {
          context.createError({
            type: 'Answer error',
            message: 'Choose the correct answer'
          });
          return false;
        }
        return true;
      })
      .required()
  }),
  answer_type: string().required(),
  description: string().max(5000, 'Description must be at most 5000 characters'),
  explanation: string().max(5000, 'Explanation must be at most 5000 characters'),
  id: string().notRequired(),
  order: number().required(),
  points: number()
    .typeError('Enter points for this question (from 1 to 100)')
    .required()
    .positive('Enter points for this question (from 1 to 100)'),
  title: object()
    .nullable(false)
    // .min(1, 'Enter your question title')
    // .max(5000, 'Question title must be at most 5000 characters')
    .typeError('Required field')
    .required(),
  type: string().required()
});

export const lessonSchema = object().shape({
  courseBlock: array().of(
    object().shape({
      id: string(),
      title: string(),
      is_hidden: boolean().required(),
      order: number(),
      type: mixed<BlockType>().oneOf(Object.values(BlockType)),
      quiz: mixed().when('type', {
        is: (val: string) => {
          return val === BlockType.QUIZ;
        },
        then: object().shape({
          type: string(),
          questions: array<IQuizQuestion>().of(QuestionSchema).required(),
          randomize_questions: boolean(),
          passing_grade: object()
            .shape({
              type: mixed<`${PassingGradeTypeValues}`>().oneOf(Object.values(PassingGradeTypeValues)),
              questions_count: mixed()
                .when('type', {
                  is: 'by_question',
                  then: number()
                    .typeError('Field must be a number')
                    .min(1, 'The value must be greater than zero')
                    .required('Required field')
                })
                .test(
                  'Correct answers error',
                  'Value must be less than or equal to the number of questions',
                  (value, context) => {
                    const { from } = context as TestContext & TestContextExtended;
                    const questionLength = from.find((item) => item.value.questions)?.value.questions.length;
                    if (questionLength && value > questionLength) {
                      context.createError({
                        type: 'custom',
                        message: 'Value must be less than or equal to the number of questions'
                      });
                      return false;
                    }
                    return true;
                  }
                )
                .notRequired(),
              points_count: mixed()
                .when('type', {
                  is: 'by_answer_points',
                  then: number()
                    .typeError('Field must be a number')
                    .min(1, 'The value must be greater than zero')
                    .test(
                      'Points count error',
                      'The value must be less than the total number of points',
                      (value, context) => {
                        const { from } = context as TestContext & TestContextExtended;
                        const points = from
                          .find((item) => item.value.questions)
                          ?.value.questions.reduce((acc, question) => {
                            return acc + question.points;
                          }, 0);
                        if (points && value && value > points) {
                          context.createError({
                            type: 'custom',
                            message: 'The value must be less than the total number of points'
                          });
                          return false;
                        }
                        return true;
                      }
                    )
                })
                .notRequired()
            })
            .nullable()
            .default(null)
            .notRequired()
        })
      }),
      // text: object().when('type', { is: (val: string) => val === BlockType.TEXT, then: string().nullable() }),
      video: object().when('type', {
        is: (val: string) => val === BlockType.VIDEO,
        then: object({
          type: mixed<TypeOfUploader>().oneOf(Object.values(TypeOfUploader)),
          video_url: string().url().notRequired(),
          link: string().url().notRequired(),
          code: string().notRequired()
        }).nullable()
      })
    })
  ),
  customCode: object().shape({
    code: string()
      .transform((value: string, originalValue: string) => {
        return originalValue === '' ? null : value;
      })
      .nullable(),
    enabled: boolean().required()
  })
});
