import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import classNames from 'classnames';
import styles from './login.module.scss';
import { IconSmGoogle } from '../../components/UI/icons';

import { Button } from '../../components/UI';
import { useLogin } from '../../hooks/use-google-auth';
import { EmailLogin } from './email-login/EmailLogin';
import { AuthActions } from './store/AuthActions';

export const Login = () => {
  const dispatch = useDispatch();
  const { signIn } = useLogin();
  const { account } = useSelector((state) => state.account);
  const [loading, setLoading] = useState(false);
  const organization = account.organizations.find((org) => org.role === 'owner');

  useEffect(() => {
    if (account.organizations.length > 0 && organization) {
      dispatch(AuthActions.setCurrentOrganization(organization));
    }
  }, [dispatch, account.organizations, organization]);

  return (
    <>
      <Button
        onClick={signIn}
        size={'lg'}
        btnStyle={'secondary'}
        iconPosition={'left'}
        customIcon={<IconSmGoogle width={32} height={32} />}
        className={classNames(styles.btn, 'w-100 mb-3')}
        btnProps={{ disabled: loading }}
      >
        Sign in with Google
      </Button>
      <div className={styles.label}>or using email and password</div>
      <EmailLogin loading={loading} setLoading={setLoading} isAdmin={true} />
      {/*<AccessModal show={showModal} onClose={closeModal} />*/}
      {/* <EmailErrorModal onClose={onCloseModal} actionEmail={actionEmail} redirectPath={mainPaths.main} /> */}
    </>
  );
};
