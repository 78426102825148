import { useCallback, useEffect } from 'react';
import { ColumnDescription } from 'react-bootstrap-table-next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { CRMPaths } from '../../routes/CRMRoutes';
import { OffersActionAsync } from '../store/OffersActionAsync';
import { IOffer, IOfferState } from '../store/OffersState';
import { OfferList } from './OfferList';

const pageSize = 15;

export const OfferListWrapper = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { offers } = useSelector((state) => state.offers);
  
  const createOfferCallback = useCallback(
    (id?: string) => {
      if (id) {
        history.push(CRMPaths.crmOffer(id));
      }
    },
    [history]
  );

  const createOffer = useCallback(() => {
    dispatch(OffersActionAsync.createOffer(undefined, createOfferCallback));
  }, [createOfferCallback, dispatch]);

  const rowClickHandler = useCallback(
    (e: Event, column: ColumnDescription, columnIndex: number, row: IOffer) => {
      history.push(CRMPaths.crmOffer(row.id));
    },
    [history]
  );

  const openOffer = useCallback(() => {
    if (offers.length === 0) {
      createOffer();
    } else {
      history.push(CRMPaths.crmOffer());
    }
  }, [createOffer, history, offers.length]);

  const getOffers = useCallback(
    (page: number, pageSize: number, activeFilter?: IOfferState) => {
      dispatch(OffersActionAsync.getOffers({ page, page_size: pageSize }, activeFilter));
    },
    [dispatch]
  );

  useEffect(() => {
    getOffers(1, pageSize);
  }, [getOffers]);

  return (
    <OfferList
      pageSize={pageSize}
      createOffer={createOffer}
      openOffer={openOffer}
      rowClickHandler={rowClickHandler}
      getOffers={getOffers}
    />
  );
};
