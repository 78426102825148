import { memo, useCallback, useEffect, useState } from 'react';
import Frame from 'react-frame-component';
import InnerHTML from 'dangerously-set-html-content';
import { IThumbnailsData, IThumbnailsSize, videoThumbnails } from 'src/app/API/video-api';
import { VideoPlayer } from 'src/app/components/video/VideoPlayer';
import { TypeOfUploader, VideoField } from 'src/app/interfaces/fileUpload';

import styles from './ready-video.module.scss';

import { VideoControls } from './video-controls/VideoControls';

interface IProps {
  video?: VideoField;
  onOpenModal(): void;
  onRemoveVideo(): void;
  className?: string;
  onErrorAdditional: () => void;
}
export const ReadyVideo = memo(({ video, onOpenModal, onRemoveVideo, className, onErrorAdditional }: IProps) => {
  const [videoThumbnail, setVideoThumbnail] = useState<IThumbnailsSize | undefined>(undefined);
  const isReadyPlayer = video?.type === TypeOfUploader.upload || video?.type === TypeOfUploader.link;

  const isEmbedVideo = video?.code && video?.type === TypeOfUploader.embed;

  const getVideoThumbnails = useCallback(async () => {
    const videoId = video?.video_url?.replace(/^\D+/g, '');
    if (videoId) {
      const result = await videoThumbnails(videoId);
      const activeThumbnail = result.data.find((item: IThumbnailsData) => item.active)?.sizes[0];

      if (activeThumbnail) {
        setVideoThumbnail(activeThumbnail);
      }
    }
  }, [video?.video_url]);

  useEffect(() => {
    if (!videoThumbnail) {
      getVideoThumbnails();
    }
  }, [getVideoThumbnails, videoThumbnail]);

  const onError = useCallback(
    (error: Error) => {
      if (error) {
        onErrorAdditional();
      }
    },
    [onErrorAdditional]
  );

  return (
    <div className={className}>
      {isReadyPlayer && (
        <VideoPlayer key={video?.url || video?.video_url} url={video?.url || video?.video_url} onError={onError} />
      )}
      {isEmbedVideo && video?.code && (
        <div className={styles.videoWrapper}>
          <Frame
            scrolling={'no'}
            className={styles.iframe}
            initialContent={
              // eslint-disable-next-line quotes
              "<!DOCTYPE html><html lang='en'><head><style>iframe {width: 100%;height: 100%;min-height: 432px;}</style><title></title></head><body style='margin: 0'><div></div></body></html>"
            }
          >
            <InnerHTML html={video?.code} />
          </Frame>
        </div>
      )}
      <VideoControls
        videoUrl={video?.video_url}
        videoThumbnail={videoThumbnail}
        onOpenModal={onOpenModal}
        onRemoveVideo={onRemoveVideo}
        getVideoThumbnails={getVideoThumbnails}
        setVideoThumbnail={setVideoThumbnail}
      />
    </div>
  );
});
