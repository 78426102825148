import { applyMiddleware, compose, createStore, Middleware } from 'redux';
import { persistReducer, persistStore } from 'redux-persist';
import { PersistConfig } from 'redux-persist/es/types';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';
// import { createLogger } from 'redux-logger';
import { composeWithDevTools } from '@redux-devtools/extension';

import { IAppState } from './appState';
import { createMainReducer } from './mainReducer';
import { ISettings } from './settings';

const persistWhitelist: Array<keyof IAppState> = ['auth', 'account', 'impersonation', 'navigation'];
const persistBlacklist: Array<keyof IAppState> = ['settings'];
const persistConfig = {
  key: 'root',
  storage,
  whitelist: persistWhitelist,
  blacklist: persistBlacklist
};

const settingsBlacklist: Array<keyof ISettings> = ['isPayloadShow'];
export const settingsConfig: PersistConfig<ISettings> = {
  key: 'settings',
  storage,
  blacklist: settingsBlacklist
};
// const logger = createLogger({});
const composeEnhancers =
  process.env.NODE_ENV === 'production'
    ? compose
    : composeWithDevTools({
        shouldHotReload: false
      });

const middleware: Middleware[] = [thunk];

// if (['development'].includes(process.env.NODE_ENV)) {
// middleware.push(logger);
// }

const enhancer = composeEnhancers(applyMiddleware(...middleware));
const persistedReducer = persistReducer(persistConfig, createMainReducer());
export const store = createStore(persistedReducer, enhancer);
export const persistor = persistStore(store);
