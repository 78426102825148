import { memo } from 'react';
import { useHistory } from 'react-router';

import modalStyles from '../../../../components/UI/global-modal/modal.module.scss';

import { Button, ModalActionComponent } from '../../../../components/UI';
import { StudentCabinetPaths } from '../../routes/StudentCabinetRoutes';

interface IProps {
  show: boolean;
}

export const EnrollAccess = memo(({ show }: IProps) => {
  const history = useHistory();
  const goToCoursesList = () => {
    history.push(StudentCabinetPaths.courses);
  };

  return (
    <ModalActionComponent
      show={show}
      onCloseModal={() => {}}
      title={'You are not enrolled in course'}
      iconType={'warning'}
      iconStyles={modalStyles.warning}
      hideClose
    >
      <div className={modalStyles.description}>
        You don't have access to this course, please contact the author for more information.
      </div>
      <div className={modalStyles.buttons}>
        <Button onClick={goToCoursesList} withIcon={false}>
          Go to my courses
        </Button>
      </div>
    </ModalActionComponent>
  );
});
