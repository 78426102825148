/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';

import { adaptiveHeight } from '../../helpers';
import { Queue } from '../../helpers/queue';
import { getSettingsCallback, getSettingsError } from '../../helpers/utils';
import { useQuery } from '../../hooks/use-query';
import { Confirmation } from '../../modules/auth/confirmation/Confirmation';
import { MainRoutesStudentCabinet } from '../../modules/MainRoutes';
import { PolicyActionAsync } from '../../modules/settings/legal/store/PolicyActionAsync';
import { CommonAuth } from '../../modules/student-cabinet/auth/common-auth/CommonAuth';
import { RecoveryPassword } from '../../modules/student-cabinet/auth/recovery-password/RecoveryPassword';
import { SignUp } from '../../modules/student-cabinet/auth/sign-up/SignUp';
import {
  PoliciesRoute,
  StudentCabinetAuthRoute,
  StudentCabinetPaths,
  StudentCabinetWaitingScreen
} from '../../modules/student-cabinet/routes/StudentCabinetRoutes';
import { StudentCabinetActionAsync } from '../../modules/student-cabinet/store/StudentCabinetActionAsync';
import { LoadState } from '../../store';
import { AppContext } from '../AppContext';
import { useActionStudentQueue } from '../useActionStudentQueue';
import { SidebarStudentCabinet } from './SidebarStudentCabinet';

const queue = new Queue<(params: any) => unknown>();

export const StudentCabinetApp = () => {
  const dispatch = useDispatch();
  const { authToken, loadState: authLoadState } = useSelector((state) => state.auth);
  const { loadState } = useSelector((state) => state.account);
  const path = useRouteMatch<{ id: string }>(StudentCabinetPaths.courseStudentLessons());
  const { location } = useHistory();
  const ErrorEmailModal = useActionStudentQueue(queue);
  const query = useQuery();
  const token = query.get('token');
  const [_loading, setLoading] = useState(false);
  const subdomainURL = window.location.host.split('.')[1] && window.location.host.split('.')[0];

  const onStopLoading = () => setLoading(false);

  useEffect(() => {
    setLoading(false);
    if (subdomainURL) {
      dispatch(
        StudentCabinetActionAsync.getStudentCabinetSettings(
          window.location.hostname.replace('localhost', 'student.everact.dev.wisent.work'),
          (settings) => {
            getSettingsCallback(settings, onStopLoading);
            dispatch(PolicyActionAsync.getPolicyList(settings.id));
          },
          (error) => getSettingsError(error, onStopLoading)
        )
      );
    }
  }, [dispatch]);

  useEffect(() => {
    if (isMobile) {
      adaptiveHeight();
    }
  }, []);

  const commonPath = useRouteMatch([
    StudentCabinetPaths.waitingScreen,
    StudentCabinetPaths.recovery,
    StudentCabinetPaths.policies()
  ]);

  return (
    <AppContext.Provider value={{ queue }}>
      {authToken && !commonPath && loadState === LoadState.allIsLoaded && !path?.params.id && <SidebarStudentCabinet />}
      <Switch>
        <Route path={PoliciesRoute.path} exact={false} children={PoliciesRoute.main()} />
        {authToken && loadState === LoadState.allIsLoaded && (
          <>
            {MainRoutesStudentCabinet.map((route, index) => (
              <Route key={index} path={route.path} exact={route.exact} children={<route.main />} />
            ))}
            {location.pathname === StudentCabinetPaths.main && <Redirect to={StudentCabinetPaths.courses} />}
          </>
        )}
        <Route path={StudentCabinetPaths.waitingScreen} children={StudentCabinetWaitingScreen.main} />
        <Route path={StudentCabinetPaths.auth} children={StudentCabinetAuthRoute.main} sensitive />

        <Route path={StudentCabinetPaths.signUp} children={<SignUp />} />
        <Route
          path={StudentCabinetPaths.confirmation}
          children={
            <CommonAuth>
              <Confirmation />
            </CommonAuth>
          }
        />
        <Route path={StudentCabinetPaths.recovery} exact={false} children={<RecoveryPassword />} />
        {authToken && loadState === LoadState.allIsLoaded && authLoadState !== LoadState.firstLoad && (
          <Redirect to={StudentCabinetPaths.courses} />
        )}
        {!authToken && !token && <Redirect to={StudentCabinetPaths.auth} />}
      </Switch>
      {ErrorEmailModal}
    </AppContext.Provider>
  );
};
