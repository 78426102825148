import { createSelector } from 'reselect';
import { find, flatMapDeep } from 'lodash';

import { flatten, getMembers } from '../../../../components/course/course/CourseHelpers';
import { IAppState } from '../../../../store';
import { ICourseNode } from './CourseState';

export const LessonSelector = (courseId?: string, id?: string) =>
  createSelector(
    (state: IAppState) => state.courses.courses,
    (state: IAppState) => state.courses.loadState,
    (state: IAppState) => state.courses.reorderLoadState,
    (state: IAppState) => state.courses.payload,
    (state: IAppState) => state.courses.error,
    (courses, loadState, reorderLoadState, payload, error) => {
      const course = courses?.find((x) => x.id === courseId);

      if (course && course?.children) {
        const result = find<ICourseNode | undefined>(flatMapDeep(course.children, flatten), ['id', id]);
        return {
          lesson: result,
          loadState,
          reorderLoadState,
          payload,
          error
        };
      }
    }
  );

export const LectureNavigation = createSelector(
  (_: IAppState, props: { courseId?: string; nodeId?: string }) => props,
  (state: IAppState) => state.courses.courses,
  (props, courses) => {
    const course = courses?.find((x) => x.id === props.courseId);
    const hierarchy = course?.children;
    const flatLessons = flatMapDeep(hierarchy, getMembers).filter((x) => x.type === 'lesson') as ICourseNode[];
    const flatSteps = flatMapDeep(hierarchy, getMembers).filter((x) => x.type === 'step') as ICourseNode[];
    const lessonId = find<ICourseNode | undefined>(flatMapDeep(hierarchy, flatten), ['id', props.nodeId])?.parent_id;
    const lesson = flatLessons.find((x) => x.id === lessonId);
    const stepIndex = lesson?.children?.findIndex((x: ICourseNode) => x.id === props.nodeId);
    const currentStep = lesson?.children?.find((x: ICourseNode) => x.id === props.nodeId);
    const flatLessonIndex = flatLessons.findIndex((x) => x.id === lessonId);
    return {
      currentStep: currentStep,
      nextStep: stepIndex !== undefined ? lesson?.children[stepIndex + 1] : undefined,
      prevStep: stepIndex !== undefined ? lesson?.children[stepIndex - 1] : undefined,
      next: flatLessons[flatLessonIndex + 1],
      prev: flatLessons[flatLessonIndex - 1],
      all: flatLessons,
      allSteps: flatSteps
    };
  }
);
