import React, { forwardRef, Ref, useState } from 'react';
import { FormControl } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';

import classNames from 'classnames';
import styles from './dropmove.module.scss';
import { Icon16CheckMark, IconArrowLeft } from '../../../../../components/UI/icons';

import { coursePaths } from '../../routes/CourseRoutes';
import { courseActionAsync } from '../../store/CourseActionAsync';
import { ICourseNode } from '../../store/CourseState';

interface IProps {
  currentHierarchy: ICourseNode[];
  currentNav: ICourseNode | null;
  isCreate: boolean;
  onHideCreation: () => void;
  toPrevFolder: () => void;
  onClose: (e: React.SyntheticEvent<HTMLButtonElement>) => void;
}

type DropTitleType = 'create' | 'folder' | 'parent';

export const DropTitle = forwardRef(
  (
    { currentHierarchy, currentNav, isCreate, onClose, toPrevFolder, onHideCreation }: IProps,
    ref: Ref<HTMLInputElement>
  ) => {
    const [value, setValue] = useState('New folder');
    const pathCourse = useRouteMatch<{ id: string }>(coursePaths.courseLessons());
    const course = useSelector((state) => state.courses.courses.find((x) => x.id === pathCourse?.params.id));

    const dispatch = useDispatch();
    let element: DropTitleType = 'create';

    if (!isCreate && currentHierarchy.length > 0 && !currentHierarchy[0].parent_id) {
      element = 'parent';
    } else if (!isCreate) {
      element = 'folder';
    }

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        onSaveHandler();
      }
    };

    const onChange = (e: string) => {
      setValue(e);
    };

    const onSaveHandler = () => {
      if (pathCourse?.params.id && value.length > 0) {
        dispatch(courseActionAsync.createNode(pathCourse.params.id, 'folder', currentNav?.id, value));
        onHideCreation();
      }
    };

    const returnToFolder = () => {
      onHideCreation();
      toPrevFolder();
    };

    return (
      <div className={classNames('px-3', styles.headerContainer)}>
        <div className={classNames('d-flex align-items-center m-0', styles.titleTextContainer)}>
          {element === 'parent' && <span className={styles.titleText}>{course?.title}</span>}
          {element === 'folder' && (
            <div onClick={toPrevFolder}>
              <IconArrowLeft />
              <span className={styles.titleText}>{currentNav?.title}</span>
            </div>
          )}
          {element === 'create' && (
            <div className={classNames('d-flex w-100 align-items-center', styles.inputContainer)}>
              <IconArrowLeft onClick={returnToFolder} />
              <FormControl
                className={'form-control form-control-sm ml-2'}
                onChange={(e) => onChange(e.currentTarget.value)}
                onKeyDown={handleKeyDown}
                // onBlur={onSaveHandler}
                maxLength={256}
                ref={ref}
                defaultValue={'New folder'}
              />
              {value.length > 0 && (
                <span className={styles.checkMark} onClick={onSaveHandler}>
                  <Icon16CheckMark />
                </span>
              )}
            </div>
          )}
        </div>
        {element !== 'create' && (
          <button onClick={onClose} className={styles.cancel}>
            Cancel
          </button>
        )}
      </div>
    );
  }
);
