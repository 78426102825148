import { memo, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import Select, { ActionMeta, OnChangeValue } from 'react-select';
import { capitalize } from 'lodash';

import { DropMenu } from '../../../../../components/UI';
import { arrStrForSelect } from '../../../../../helpers';
import { IOption, roles } from '../../../../../interfaces';
import { MemberRemoveConformation } from './remove/MemberRemoveConformation';
import { RemoveBtn } from './RemoveBtn';
import { SingleValue } from './SingleValue';

const customStyles = {
  control: () => ({
    width: '100%',
    display: 'flex'
  }),
  option: () => ({
    border: 'none',
    fontSize: '12px',
    padding: '0.5rem 1rem',
    '&:hover': {
      color: '#0072FF'
    }
  }),
  menu: (styles: any) => ({
    ...styles,
    boxShadow: '0 2px 15px 4px rgba(7, 7, 55, 0.06)'
  })
};

export const memberOptions = arrStrForSelect([...roles.filter((x) => x !== 'owner')].map((x) => capitalize(x)));

interface IProps {
  onChange: (newValue: OnChangeValue<IOption, boolean>) => void;
  className?: string;
  removeMember?: (id: string) => void;
  id?: string;
  name?: string;
}

export const SelectRole = memo(({ onChange, className, removeMember, id, name }: IProps) => {
  const [showDialog, setShowDialog] = useState(false);
  const onCloseDialog = () => {
    setShowDialog(false);
  };

  const onSelect = (newValue: OnChangeValue<IOption, boolean>, _actionMeta: ActionMeta<IOption>) => {
    onChange(newValue);
  };

  const onShowConfirm = () => {
    setShowDialog(true);
  };

  return (
    <DropMenu
      show={showDialog}
      onToggle={() => {}}
      node={
        <Select
          styles={customStyles}
          className={className}
          options={memberOptions}
          defaultValue={memberOptions[0]}
          components={{
            IndicatorSeparator: () => null,
            MenuList: (props) => RemoveBtn({ ...props, onRemove: onShowConfirm, showRemove: !!removeMember }),
            SingleValue
          }}
          onChange={onSelect}
          isSearchable={false}
        />
      }
      drop={'down'}
    >
      <Dropdown.Menu onClick={onCloseDialog}>
        <MemberRemoveConformation
          name={name || ''}
          onCancel={onCloseDialog}
          onConfirm={() => id && removeMember?.(id)}
        />
      </Dropdown.Menu>
    </DropMenu>
  );
});
