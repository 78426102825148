import { CustomDispatch } from 'redux';
import { AxiosResponse } from 'axios';

import { checkDomainStatus, createDomain, editDomain, getDomains, removeDomain } from '../../../../API/domain-api';
import { ICreateDomain, IDomain } from '../../../../interfaces/domain';
import { ErrorDomainStatus } from '../connect-new-domain/utils/error-domain-handler';
import { domainActions } from './DomainSettingsActions';

export const domainActionAsync = {
  getDomains: (callback?: () => void) => async (dispatch: CustomDispatch) => {
    try {
      dispatch(domainActions.getDomains.started());
      const response = await getDomains();
      if (response.data) {
        dispatch(domainActions.getDomains.done({ result: response.data }));
      }
      callback?.();
    } catch (error) {
      dispatch(domainActions.getDomains.failed({ error }));
    }
  },
  createDomain:
    (
      params: ICreateDomain,
      callback?: (data: IDomain) => void,
      errorCallback?: (data: AxiosResponse<{ status: ErrorDomainStatus; payload: unknown | null }>) => void
    ) =>
    async (dispatch: CustomDispatch) => {
      try {
        dispatch(domainActions.createStudentDomain.started(params));
        const response = await createDomain(params);
        if (response.data) {
          dispatch(domainActions.createStudentDomain.done({ result: response.data, params }));
          callback?.(response.data);
        }
      } catch (error) {
        errorCallback?.(error.data);
        dispatch(domainActions.createStudentDomain.failed({ error, params }));
      }
    },
  checkDomainStatus: (domainId: string, callback?: () => void) => async (dispatch: CustomDispatch) => {
    try {
      dispatch(domainActions.checkDomainStatus.started(domainId));
      const response = await checkDomainStatus(domainId);
      if (response.data) {
        dispatch(domainActions.checkDomainStatus.done({ result: response.data, params: domainId }));
      }
      callback?.();
    } catch (error) {
      dispatch(domainActions.checkDomainStatus.failed({ error, params: domainId }));
    }
  },
  editStudentDomain:
    (
      { domainId, subdomain }: { domainId: string; subdomain: string },
      callback?: () => void,
      callbackError?: (data: AxiosResponse<{ status: ErrorDomainStatus; payload: unknown | null }>) => void
    ) =>
    async (dispatch: CustomDispatch) => {
      try {
        dispatch(domainActions.editStudentDomain.started({ domainId, subdomain }));
        const response = await editDomain(domainId, subdomain);
        if (response.data) {
          dispatch(domainActions.editStudentDomain.done({ result: response.data, params: { domainId, subdomain } }));
        }
        callback?.();
      } catch (error) {
        callbackError?.(error.data);
        dispatch(domainActions.editStudentDomain.failed({ error, params: { domainId, subdomain } }));
      }
    },
  removeStudentDomain:
    (
      domainId: string,
      callback?: () => void,
      callbackError?: (data: AxiosResponse<{ status: ErrorDomainStatus; payload: unknown | null }>) => void
    ) =>
    async (dispatch: CustomDispatch) => {
      try {
        dispatch(domainActions.removeStudentDomain.started(domainId));
        await removeDomain(domainId);
        dispatch(domainActions.removeStudentDomain.done({ params: domainId }));
        callback?.();
      } catch (error) {
        callbackError?.(error.data);
        dispatch(domainActions.removeStudentDomain.failed({ error, params: domainId }));
      }
    }
};
