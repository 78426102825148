import styles from './quiz-to-moderation.module.scss';
import ErrorImg from '../../../../../../../../assets/images/error.png';
import { Icon32Reload } from '../../../../../../../components/UI/icons';

import { Button } from '../../../../../../../components/UI';

interface IProps {
  onTryAgain: () => void;
}

export const ResultQuizModeratedFailed = ({ onTryAgain }: IProps) => {
  return (
    <>
      <img alt={'error quiz'} src={ErrorImg} className={styles.icon} />
      <div className={styles.title}>The quiz was not passed</div>
      <div className={styles.description}>The course moderator marked the quiz as failed</div>
      <Button
        customIcon={<Icon32Reload />}
        onClick={onTryAgain}
        iconPosition={'left'}
        size={'lg'}
        className={styles.btn}
      >
        Try again
      </Button>
    </>
  );
};
