import { createSelector } from 'reselect';

import { IAppState } from '../../../../store';

export const StudentCourseSelector = (courseId: string | undefined) =>
  createSelector(
    (state: IAppState) => state.studentCourses.studentCourses.courses,
    (state: IAppState) => state.studentCourses.loadState,
    (state: IAppState) => state.studentCourses.error,
    (courses, loadState, error) => {
      return {
        course: courses?.find((course) => course.id === courseId),
        loadState,
        error
      };
    }
  );
