import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  ColumnDescription,
  RowEventHandlerProps,
  SelectRowProps,
  TableChangeState,
  TableChangeType
} from 'react-bootstrap-table-next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { debounce } from 'lodash';

import styles from '../contact-list/contact-list.module.scss';
import { Icon32User } from '../../../../components/UI/icons';

import { EmptyList } from '../../../../components/empty-list/EmptyList';
import { NoSearchResult } from '../../../../components/no-search-result/NoSearchResult';
import { Loader } from '../../../../components/UI';
import { RemotePagination } from '../../../../components/UI/pagination/RemotePagination';
import { PopupCard } from '../../../../components/UI/popup-card/PopupCard';
import { useIntercomUpdate } from '../../../../hooks/use-intercom-update';
import { usePayload } from '../../../../hooks/usePayload';
import { LoadState } from '../../../../store';
import { FieldActionAsync, FieldScope } from '../../field';
import { FieldsEdit } from '../../fields/FieldsEdit';
import { ListsActionAsync } from '../../lists/store/ListsActionAsync';
import { IListField } from '../../lists/store/ListsState';
import { ContactsTableDataSelector } from '../../lists/store/TableDataSelector';
import { TagActionAsync } from '../../tag/store/TagActionAsync';
import { Tags } from '../../tag/table-tags/Tags';
import { ContactCard } from '../contact-card/ContactCard';
import { ContactListActionAsync } from '../store/ContactListActionAsync';
import { ContactsHeader } from './ContactsHeader';

export const Contacts = memo(() => {
  const dispatch = useDispatch();
  const { isPayloadShow, showPayload, hidePayload } = usePayload();
  const { searchString, isSidebarShow: isFieldSettingsShow } = useSelector((state) => state.settings);
  const [contactId, setContactId] = useState<string | undefined>(undefined);
  const { contactList } = useSelector((state) => state.contactsConfig);
  const { fields } = useSelector((state) => state.fields);
  const contactFields = useMemo(
    () =>
      contactList.fields?.map((listField) => ({
        ...listField,
        field: fields?.find((field) => field.id === listField.field_id)
      })) || [],
    [contactList, fields]
  );
  const tableDataMemo = useMemo(ContactsTableDataSelector, []);
  const { tableData, page, page_size, total, loadState } = useSelector((state) => tableDataMemo(state));
  const [contactsTable, setContactsTable] = useState<{
    items: ItemType[];
    totalSize: number;
    page: number;
    sizePerPage: number;
  }>({ items: tableData, totalSize: 0, page: 1, sizePerPage: 25 });
  const [isPageChanged, setIsPageChanged] = useState(true);
  const sizePerPage = 25;
  const location = useLocation<{ showPayload?: boolean; contactIdFromStudent?: string }>();
  const initialCount = useRef<number>(0);
  const [isSearch, setIsSearch] = useState(false);
  const { first_name, last_name } = useMemo(
    () =>
      contactFields.reduce<Partial<Record<`${FieldScope}`, string>>>(
        (a, v) => (v.field ? { ...a, [v.field.scope]: v.field_id } : { ...a }),
        {}
      ),
    [contactFields]
  );

  useIntercomUpdate(isPayloadShow);

  const columnFormatter = useCallback(
    (cell: string, row: RowType, index: number, data: IListField) => {
      if (cell === undefined && data.field?.scope !== 'first_name') {
        return '-';
      }
      if (data.field?.scope === 'first_name') {
        const lastNameField: IListField | undefined = contactFields?.find(
          (field) => field.field?.scope === 'last_name'
        );
        const lastName = (lastNameField && row[lastNameField.field_id]) || '';
        return (
          <div className={styles.name}>
            <div className={styles.link}>{`${cell || '-'} ${lastName}`}</div>
            <Tags contactId={row.id} />
          </div>
        );
      }
      if (data.field?.scope === 'email') {
        return <div className={styles.email}>{cell}</div>;
      }

      return cell;
    },
    [contactFields]
  );

  const changeHandler = useCallback(
    (searchString: string) => {
      if (!isPageChanged) {
        setIsSearch(searchString !== '');
        if (first_name && last_name) {
          dispatch(
            ContactListActionAsync.getContactsList(
              null,
              {
                page: 1,
                page_size: sizePerPage,
                q: searchString,
                sort: [first_name, last_name]
              },
              () => {
                setIsPageChanged(false);
              }
            )
          );
        }
      }
    },
    [dispatch, first_name, isPageChanged, last_name]
  );

  const debouncedSearch = useMemo(() => debounce(changeHandler, 300), [changeHandler]);

  const debouncedChangeHandler = useCallback(debouncedSearch, [debouncedSearch]);

  useEffect(() => {
    debouncedChangeHandler(searchString);
  }, [debouncedChangeHandler, searchString]);

  useEffect(() => {
    if (!isPayloadShow) {
      setContactId(undefined);
    }
  }, [isPayloadShow]);

  useEffect(() => {
    if (location.state?.contactIdFromStudent && loadState === LoadState.allIsLoaded) {
      const contact = tableData.find((x) => x.id === location.state?.contactIdFromStudent);
      if (contact) {
        setContactId(location.state.contactIdFromStudent);
        showPayload();
        window.history.replaceState({}, document.title);
      }
    }
  }, [loadState, location.state?.contactIdFromStudent, showPayload, tableData]);

  useEffect(() => {
    if (!location.state?.showPayload) {
      hidePayload();
    }
  }, [hidePayload, location.state?.showPayload]);

  useEffect(() => {
    dispatch(FieldActionAsync.getFields());
    dispatch(ContactListActionAsync.getContactsConfig());
    dispatch(ListsActionAsync.getLists());
    dispatch(TagActionAsync.getTags());
  }, [dispatch]);

  useEffect(() => {
    if (first_name && last_name) {
      dispatch(
        ContactListActionAsync.getContactsList(null, {
          page: 1,
          page_size: sizePerPage,
          sort: [first_name, last_name]
        })
      );
    }
  }, [dispatch, first_name, last_name]);

  useEffect(() => {
    if (loadState === LoadState.allIsLoaded) {
      initialCount.current = tableData.length;
      setContactsTable({ items: tableData, page: page, sizePerPage: page_size, totalSize: total });
    }
  }, [dispatch, loadState, page, page_size, tableData, total]);

  const openContactCard = useCallback(() => {
    showPayload();
  }, [showPayload]);

  const handleTableChange = (type: TableChangeType | 'search', { page, sizePerPage }: TableChangeState<unknown>) => {
    if (type === 'search') {
      setIsPageChanged(false);
    }
    if (type === 'pagination') {
      setIsPageChanged(true);
      if (first_name && last_name) {
        dispatch(
          ContactListActionAsync.getContactsList(null, {
            page: page,
            page_size: sizePerPage,
            q: searchString,
            sort: [first_name, last_name]
          })
        );
      }
    }
  };

  const columnsData: ColumnDescription<RowType>[] = useMemo(
    () =>
      contactFields
        ?.filter((field) => field.field && ['first_name', 'email', 'phone'].includes(field.field?.scope))
        .map((field) => {
          return {
            dataField: field.field_id,
            text: field.field?.scope === 'first_name' ? 'Full Name' : field.field?.payload.title || '-',
            formatter: columnFormatter,
            formatExtraData: field,
            classes: 'col-4',
            headerClasses: 'col-4'
          };
        }) || [],
    [columnFormatter, contactFields]
  );

  const rowEvents: RowEventHandlerProps<RowType> = {
    onClick: (e, row) => {
      setContactId(row.id);
      showPayload();
    }
  };

  const updateContactId = useCallback((id: string) => {
    setContactId(id);
  }, []);

  const onCreateContact = useCallback(() => {
    if (first_name && last_name) {
      dispatch(
        ContactListActionAsync.getContactsList(null, {
          page,
          page_size,
          q: searchString,
          sort: [first_name, last_name]
        })
      );
    }
  }, [dispatch, first_name, last_name, page, page_size, searchString]);

  return (
    <>
      {loadState === LoadState.firstLoad && columnsData.length === 0 && <Loader />}
      {initialCount.current > 0 && <ContactsHeader onClick={openContactCard} />}
      {loadState === LoadState.allIsLoaded && contactsTable.items.length > 0 && columnsData.length > 0 && (
        <RemotePagination
          page={contactsTable.page}
          sizePerPage={contactsTable.sizePerPage}
          totalSize={contactsTable.totalSize}
          onTableChange={handleTableChange}
          tableProps={{
            keyField: 'id',
            columns: [...columnsData, createDateColumn],
            selectRow: selectRow,
            data: contactsTable.items,
            rowEvents
          }}
        />
      )}

      {loadState === LoadState.idle &&
        (isSearch ? (
          <NoSearchResult searchString={searchString} />
        ) : (
          <EmptyList
            onClick={openContactCard}
            button={'Add contact'}
            title={
              <>
                You have no added <span className={'text-primary'}>contacts</span>
              </>
            }
            description={'All added contacts will be stored in this location for quick access'}
            icon={<Icon32User width={64} height={64} />}
          />
        ))}

      <PopupCard isShow={isPayloadShow}>
        <ContactCard contactId={contactId} setContactId={updateContactId} onCreate={onCreateContact} />
      </PopupCard>
      <PopupCard isShow={isFieldSettingsShow} fullHeight={true}>
        <FieldsEdit
          entity={'contact'}
          title={'Edit contact fields'}
          description={
            'Change the order and the content for the working fields. The settings are applied to all contacts.'
          }
        />
      </PopupCard>
    </>
  );
});

export type RowType = Record<string, string> & { id: string; date_create: string };

type ItemType = {
  id: string;
  date_create: number;
};

const createDateColumn = { dataField: 'date_create', text: 'Date create', sort: true, hidden: true };

const selectRow: SelectRowProps<any> = {
  mode: 'radio',
  clickToSelect: true,
  hideSelectColumn: true,
  classes: styles.selectedRow
};
