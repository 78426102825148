import { useState } from 'react';
import { Form, FormControl } from 'react-bootstrap';

import commonStyles from '../common/upload-common.module.scss';
import { IconCheckMark } from '../../../UI/icons';

import { Button } from '../../../UI';

interface IProps {
  defaultFileEmbed: string | undefined;
  placeholder: string;
  viewportText: string | JSX.Element;
  onSave(value: string): void;
  onCancel: () => void;
}

export const FileEmbed = ({ defaultFileEmbed, placeholder, viewportText, onSave, onCancel }: IProps) => {
  const [value, setValue] = useState('');

  return (
    <Form.Group className={commonStyles.linkContainer}>
      <FormControl
        autoFocus
        defaultValue={defaultFileEmbed}
        placeholder={placeholder}
        onChange={(e) => setValue(e.currentTarget.value)}
        as="textarea"
        rows={5}
        className={commonStyles.videoEmbed}
      />
      <div className={commonStyles.linkActions}>
        <Button
          btnProps={{ disabled: value.length === 0 }}
          customIcon={<IconCheckMark />}
          onClick={() => onSave(value)}
          className={commonStyles.linkButton}
        >
          Save
        </Button>
        <Button btnStyle={'secondary'} withIcon={false} className={commonStyles.linkButton} onClick={onCancel}>
          Cancel
        </Button>
        <div className={commonStyles.linkNote}>
          <p className={commonStyles.linkNoteText}>{viewportText}</p>
        </div>
      </div>
    </Form.Group>
  );
};
