import { memo } from 'react';
import VerificationInput from 'react-verification-input';

import classNames from 'classnames';
import styles from './verification-code.module.scss';

interface IProps {
  disabled?: boolean;
  className?: string;
  isWrong?: boolean;

  onChange(value: string): void;
}

export const VerificationCode = memo(({ onChange, disabled, isWrong, className }: IProps) => {
  return (
    <div className={classNames(className, { [styles.error]: isWrong })}>
      <VerificationInput
        length={4}
        validChars={'0-9'}
        onChange={onChange}
        classNames={styles}
        placeholder={''}
        inputProps={{ disabled }}
        autoFocus
      />
    </div>
  );
});
