import {
  IAutomationNodeBuilder,
  IEdge,
  IFunnelNodeBuilder,
  NodeGeometry,
  PaletteAutomationElementTypes,
  PaletteElementTypes
} from '../../../../interfaces';
import { LoadState } from '../../../../store';

export interface IBuilder {
  edges: IEdge[];
  geometry: NodeGeometry[];
  nodes: Array<IAutomationNodeBuilder<PaletteAutomationElementTypes> | IFunnelNodeBuilder<PaletteElementTypes>>;
}

export interface IBuilderState {
  error: Error | null;
  loadState: LoadState;
  builder: IBuilder;
}

export const BuilderInitialState: IBuilderState = {
  error: null,
  loadState: LoadState.firstLoad,
  builder: {
    edges: [],
    geometry: [],
    nodes: []
  }
};
