import { lazy, Suspense } from 'react';

import { Loader } from '../../../../../components/UI';
import { IRoutes } from '../../../../../interfaces';
import { CourseHeaderPanel } from '../../../../../layout/header/courseHeaderPanel/CourseHeaderPanel';
import { AdminCourse } from '../../admin-course/AdminCourse';
import { SettingsSidebar } from '../../common/sidebar/settings-sidebar/SettingsSidebar';
import { CourseCustomCode } from '../course-custom-code/CourseCustomCode';
import { CourseGeneral } from '../course-general/CourseGeneral';

// const CourseSEO = lazy(() => import('../course-seo/CourseSEO').then(({ CourseSEO }) => ({ default: CourseSEO })));
const CourseCompletion = lazy(() =>
  import('../course-general/CourseSettingsGeneral').then(({ CourseSettingsGeneral }) => ({
    default: CourseSettingsGeneral
  }))
);
const CourseTheme = lazy(() =>
  import('../course-theme/CourseTheme').then(({ CourseTheme }) => ({ default: CourseTheme }))
);

export const courseSettingsPaths = {
  general: (id = ':id') => `/courses/${id}/settings/general`,
  theme: (id = ':id') => `/courses/${id}/settings/theme`,
  completion: (id = ':id') => `/courses/${id}/settings/completion`,
  seo: (id = ':id') => `/courses/${id}/settings/seo`,
  customCode: (id = ':id') => `/courses/${id}/settings/custom-code`
};

export const CourseSettingsRoutes: IRoutes[] = [
  {
    path: courseSettingsPaths.general(),
    main: () => (
      <AdminCourse sidebar={<SettingsSidebar />}>
        <CourseGeneral />
      </AdminCourse>
    ),
    header: () => <CourseHeaderPanel />,
    sidebar: () => <SettingsSidebar />
  },
  {
    path: courseSettingsPaths.theme(),
    main: () => (
      <AdminCourse sidebar={<SettingsSidebar />}>
        <Suspense fallback={<Loader />}>
          <CourseTheme />
        </Suspense>
      </AdminCourse>
    ),
    header: () => <CourseHeaderPanel />,
    sidebar: () => <SettingsSidebar />
  },
  {
    path: courseSettingsPaths.completion(),
    main: () => (
      <AdminCourse sidebar={<SettingsSidebar />}>
        <Suspense fallback={<Loader />}>
          <CourseCompletion />
        </Suspense>
      </AdminCourse>
    ),
    header: () => <CourseHeaderPanel />,
    sidebar: () => <SettingsSidebar />
  },
  // {
  //   path: courseSettingsPaths.seo(),
  //   main: () => (
  //     <AdminCourse sidebar={<SettingsSidebar />}>
  //       <Suspense fallback={<Loader />}>
  //         <CourseSEO />
  //       </Suspense>
  //     </AdminCourse>
  //   ),
  //   sidebar: () => <SettingsSidebar />,
  //   header: () => <CourseHeaderPanel />
  // }
  {
    path: courseSettingsPaths.customCode(),
    main: () => (
      <AdminCourse sidebar={<SettingsSidebar />}>
        <CourseCustomCode />
      </AdminCourse>
    ),
    header: () => <CourseHeaderPanel />,
    sidebar: () => <SettingsSidebar />
  }
];
