import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { toast } from 'react-toastify';

import { IField } from '../../../../modules/crm/field';
import { IconUpload } from '../../icons';
import { ImagePreview } from '../../image-components/image-upload/ImagePreview';
import { ImageUpload } from '../../image-components/image-upload/ImageUpload';
import { ToastMessage } from '../../toast/ToastMessage';

export interface IImageFieldValue {
  file?: File;
  bucket_name: string;
  object_name: string;
}

interface IProps {
  field: IField;
  params?: {
    className?: string;
    imageSize?: { width: number; height: number };
  };
}

export const ImageField = ({ field, ...params }: IProps) => {
  const { setValue, register, watch } = useFormContext();
  const [imagePreviewUrl, setImagePreviewUrl] = useState<string | null>(null);
  const [isPreloaded, setIsPreloaded] = useState(false);
  const value: IImageFieldValue = watch(field.id);
  const isDisabled = field?.payload?.readonly;

  const handleImageChange = (e: React.SyntheticEvent<HTMLInputElement>) => {
    e.preventDefault();
    const reader = new FileReader();
    if (e.currentTarget) {
      const file = e.currentTarget.files && e.currentTarget.files[0];
      if (file) {
        if (file.size <= 5242880) {
          reader.onloadend = () => {
            setValue(field.id, { file }, { shouldDirty: true });
            setIsPreloaded(true);
            setImagePreviewUrl(reader.result as string);
          };

          reader.readAsDataURL(file);
        } else {
          toast.warning(<ToastMessage message={'File size should not be more than 5Mb'} />);
        }
      }
    }
  };

  const deleteImage = () => {
    setImagePreviewUrl(null);
    setValue(field.id, null, { shouldDirty: true });
  };

  return (
    <ImageUpload
      handleImageChange={handleImageChange}
      name={'image'}
      isPreloaded={isPreloaded}
      setImagePreviewUrl={setImagePreviewUrl}
      setIsPreloaded={setIsPreloaded}
      bucket_name={value?.bucket_name}
      object_name={value?.object_name}
      size={params.params?.imageSize}
      disabled={isDisabled}
    >
      <input type={'hidden'} {...register(field.id)} />
      <ImagePreview
        imagePreviewUrl={imagePreviewUrl}
        setImagePreviewUrl={setImagePreviewUrl}
        className={params.params?.className}
        imageSize={params.params?.imageSize}
        disabled={isDisabled}
        onDeleteImage={deleteImage}
      />
      {!isDisabled && (
        <label htmlFor={'image'} className={'btn btn-sm btn-icon-right'}>
          Choose image
          <IconUpload className={'icon'} />
        </label>
      )}
    </ImageUpload>
  );
};
