import { find, flatMapDeep } from 'lodash';

import { flatten } from '../../../components/course/course/CourseHelpers';
import { ICourse, ICourseNode } from '../courses/store/CourseState';

export const useLessonNode = (course?: ICourse, nodeId?: string) => {
  const nodeStep = find<ICourseNode | undefined>(flatMapDeep(course?.children, flatten), ['id', nodeId]);
  const nodeLesson = find<ICourseNode | undefined>(flatMapDeep(course?.children, flatten), ['id', nodeStep?.parent_id]);
  const nodeFolder = find<ICourseNode | undefined>(flatMapDeep(course?.children, flatten), [
    'id',
    nodeLesson?.parent_id
  ]);
  return {
    nodeStep,
    nodeLesson,
    nodeFolder
  };
};
