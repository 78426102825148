import React, { memo, useMemo, useState } from 'react';
import { Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import classnames from 'classnames';
import styles from './contact-card-lists.module.scss';
import { Icon16AddList } from '../../../../../components/UI/icons';

import { getUUID } from '../../../../../helpers';
import { IList } from '../../../lists/store/ListsState';
import { CRMPaths } from '../../../routes/CRMRoutes';
import { ContactListOfListsAllSelector } from '../../store/ContactListSelector';
import { MainContactList } from './components/default-list/MainContactList';
import { ListItem } from './components/list-item/ListItem';
import { ListEmptyCreation } from './ListEmptyCreation';
import { ListsSearch } from './ListsSearch';

interface IProps {
  detachListHandler?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  attachListHandler?: (id: string) => void;
  onCreateList?: () => void;
  attachedIds?: Pick<IList, 'id' | 'title'>[];
  isShowCreate?: boolean;
  titleStyles?: string;
  isDisable?: boolean;
  showTitle?: boolean;
}

export const ListsAttacher = memo(
  ({
    detachListHandler,
    attachListHandler,
    onCreateList,
    attachedIds,
    titleStyles,
    showTitle = true,
    isShowCreate = true,
    isDisable = false
  }: IProps) => {
    const [search, setSearch] = useState('');
    const searchListHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
      setSearch(e.currentTarget.value);
    };
    const allListsMemo = useMemo(ContactListOfListsAllSelector, []);
    const allLists = useSelector((state) => allListsMemo(state, { search: search, attachedIds: attachedIds }));

    return (
      <>
        <ListsSearch searchListHandler={searchListHandler} classNames={titleStyles} showTitle={showTitle} />
        <Row className={'mx-0 mt-3 mb-2'}>
          <MainContactList />
          {attachedIds?.map((x) => (
            <ListItem name={x.title} isSelected key={getUUID()} id={x.id} onDetach={detachListHandler} />
          ))}
        </Row>
        <Row className={'mx-0 pt-3'}>
          {allLists?.allLists?.map((x) => (
            <ListItem
              name={x.title}
              isSelected={false}
              key={getUUID()}
              id={x.id}
              onAttach={attachListHandler}
              isDisable={isDisable && attachedIds && attachedIds?.length > 0}
            />
          ))}
          {allLists?.allLists && allLists.allLists.length > 0 && isShowCreate && (
            <div className={styles.createContainer} onClick={onCreateList}>
              <div>Create list</div>
              <Icon16AddList className={styles.createImage} />
            </div>
          )}
        </Row>
        {allLists?.allLists?.length === 0 && isShowCreate ? (
          <ListEmptyCreation onCreateList={onCreateList} />
        ) : (
          <Row className={classnames('mx-0', styles.linkContainer)}>
            <Link to={CRMPaths.crmLists} className={styles.link}>
              Go to «Lists» page
            </Link>
          </Row>
        )}
      </>
    );
  }
);
