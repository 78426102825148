import { CustomDispatch } from 'redux';

import {
  createProduct,
  deleteProduct,
  editProduct,
  getProductDetail,
  getProducts,
  getProductsConfig
} from '../../../../API/products-api';
import { IPaginationParams } from '../../../../interfaces';
import { ProductsActions } from './ProductsActions';
import { IProductField, ProductType } from './ProductsState';

export const ProductsActionAsync = {
  getProductsConfig: (type: ProductType, callback?: () => void) => async (dispatch: CustomDispatch) => {
    try {
      dispatch(ProductsActions.getProductsConfig.started({ type }));
      const response = await getProductsConfig(type);
      if (response.data) {
        dispatch(ProductsActions.getProductsConfig.done({ result: response.data, params: { type } }));
        callback?.();
      }
    } catch (error) {
      dispatch(ProductsActions.getProductsConfig.failed({ error, params: { type } }));
    }
  },
  getProducts:
    (type: ProductType, pagination?: IPaginationParams, query?: string, sort?: string[], callback?: () => void) =>
    async (dispatch: CustomDispatch) => {
      try {
        dispatch(ProductsActions.getProducts.started());
        const response = await getProducts(type, { ...pagination, q: query, sort });
        if (response && response.data && response.meta) {
          dispatch(ProductsActions.getProducts.done({ result: { data: response.data, meta: response.meta } }));
          callback?.();
        }
      } catch (error) {
        dispatch(ProductsActions.getProducts.failed({ error }));
      }
    },
  getProduct: (id: string) => async (dispatch: CustomDispatch) => {
    try {
      dispatch(ProductsActions.getProduct.started(id));
      const response = await getProductDetail(id);
      if (response.data) {
        dispatch(ProductsActions.getProduct.done({ result: response.data, params: id }));
      }
    } catch (error) {
      dispatch(ProductsActions.getProduct.failed({ error, params: id }));
    }
  },
  createProduct: (fields: IProductField, callback?: () => void) => async (dispatch: CustomDispatch) => {
    try {
      dispatch(ProductsActions.createProduct.started({ fields }));
      const response = await createProduct(fields);
      if (response.data) {
        dispatch(ProductsActions.createProduct.done({ result: response.data, params: { fields } }));
        if (callback) {
          callback();
        }
      }
    } catch (error) {
      dispatch(ProductsActions.createProduct.failed({ error, params: { fields } }));
    }
  },
  editProduct: (id: string, fields: IProductField, callback?: () => void) => async (dispatch: CustomDispatch) => {
    try {
      dispatch(ProductsActions.editProduct.started({ id, fields }));
      const response = await editProduct(id, fields);
      if (response.data) {
        dispatch(ProductsActions.editProduct.done({ result: response.data, params: { id, fields } }));
        if (callback) {
          callback();
        }
      }
    } catch (error) {
      dispatch(ProductsActions.editProduct.failed({ error, params: { id, fields } }));
    }
  },
  deleteProduct: (id: string) => async (dispatch: CustomDispatch) => {
    try {
      dispatch(ProductsActions.deleteProduct.started({ id }));
      await deleteProduct(id);
      dispatch(ProductsActions.deleteProduct.done({ params: { id } }));
    } catch (error) {
      dispatch(ProductsActions.deleteProduct.failed({ error, params: { id } }));
    }
  }
};
