import Skeleton from 'react-loading-skeleton';

import classNames from 'classnames';
import 'react-loading-skeleton/dist/skeleton.css';
import styles from './lesson-loader.module.scss';

interface IProps {
  classNameTitle?: string;
  className?: string;
}

export const LessonLoader = ({ className, classNameTitle }: IProps) => {
  return (
    <>
      <div className={classNames(styles.titleContainer, classNameTitle)}>
        <Skeleton width={224} height={16} />
      </div>
      <div className={classNames(styles.contentContainer, className)}>
        <Skeleton height={380} />
      </div>
    </>
  );
};
