import { useEffect } from 'react';

import styles from './palette-info.module.scss';
import { Icon32Link } from '../../../../components/UI/icons';

export const PaletteInfo = () => {
  useEffect(() => {
    // TODO: get linked data
  }, []);

  return (
    <>
      <div className={'d-flex justify-content-between mt-3 infoPalette'}>
        <div className={styles.title}>Created</div>
        <div className={styles.titleDate}>2 Mar 2021</div>
      </div>
      {/* empty linked  data */}
      <div className={styles.iconContainer}>
        <Icon32Link />
        <div className={styles.text}>No linked funnels and automations</div>
      </div>
    </>
  );
};
