import { useDispatch } from 'react-redux';

import { ModalDelete } from '../../../../components/UI';
import { settingsActions } from '../../../../store/settings';
import { ProductsActionAsync } from '../store/ProductsActionAsync';
import { IProduct } from '../store/ProductsState';

interface IProps {
  show: boolean;
  onClose(): void;
  product: IProduct;
}

export const ProductDelete = ({ onClose, show, product }: IProps) => {
  const dispatch = useDispatch();

  const onDelete = () => {
    dispatch(ProductsActionAsync.deleteProduct(product.id));
    dispatch(settingsActions.setPayloadShowing({ isPayloadShow: false }));
    onClose();
  };

  return (
    <ModalDelete
      onClose={onClose}
      isShowed={show}
      title={
        <>
          Product <span>{product.title}</span>
          will be deleted
        </>
      }
      description={'This product will be permanently deleted. You will not be able to restore it!'}
      onDelete={onDelete}
    />
  );
};
