import { Form } from 'react-bootstrap';
import { useWatch } from 'react-hook-form';
import { useFormContext } from 'react-hook-form';

import styles from '../connect-new-domain.module.scss';

import { ICustomDomainFormValues } from '../ConnectNewDomain';

export const DomainPreview = () => {
  const { formState, control } = useFormContext<ICustomDomainFormValues>();
  const subdomain = useWatch({ name: 'subdomain', control });
  const domain = useWatch({ name: 'domain', control });
  const subdomainString = subdomain ? `${subdomain}.` : subdomain;
  const generalDomain = subdomainString && `https://${subdomainString}${domain}`;

  return (
    <>
      {generalDomain && !formState.errors.domain && (
        <Form.Group className={'mt-3'}>
          <Form.Label className={styles.previewLabel}>Preview:</Form.Label>
          <div className={styles.preview}>{generalDomain}</div>
        </Form.Group>
      )}
    </>
  );
};
