export enum QuillElementsType {
  undo = 'undo',
  redo = 'redo',
  bold = 'bold',
  underline = 'underline',
  italic = 'italic',
  strike = 'strike',
  code = 'code',
  blockquote = 'blockquote',
  sub = 'sub',
  super = 'super',
  color = 'color',
  background = 'background',
  clean = 'clean',
  indent = 'indent',
  outdent = 'outdent',
  link = 'link',
  image = 'image',
  video = 'video',
  formula = 'formula',
  align = 'align',
  center = 'center',
  justify = 'justify',
  right = 'right'
}

export const customQuillSnowIcons: Record<`${QuillElementsType}`, string> = {
  [QuillElementsType.undo]: `<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none' viewBox='0 0 24 24'>
    <path fill='currentColor' fill-rule='evenodd' d='m13.062 18.402.093-.005A6.2 6.2 0 0 0 15.8 6.781a6.207 6.207 0 0 0-7.208.874l-.194.186-1.873 1.758L8.8 9.6a1 1 0 0 1 .993.883l.007.117a1 1 0 0 1-.883.993L8.8 11.6H4a1 1 0 0 1-.308-.048l-.015-.005a.996.996 0 0 1-.398-.254l-.008-.008-.073-.088a1.001 1.001 0 0 0-.19-.463l-.001-.017-.005-.046a1.011 1.011 0 0 1-.002-.06v-4.81a1 1 0 0 1 1.993-.118L5 5.8v2.487l2.005-1.883A8.2 8.2 0 1 1 13 20.405V20.4a1 1 0 1 1 .062-1.998ZM4 11.6l-.052-.001a.97.97 0 0 1-.045-.004L4 11.6Z' clip-rule='evenodd'/>
  </svg>
  `,
  [QuillElementsType.redo]: `<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none' viewBox='0 0 24 24'>
    <path fill='currentColor' fill-rule='evenodd' d='M10.938 18.402A6.2 6.2 0 0 1 8.2 6.782a6.207 6.207 0 0 1 7.207.873l.195.186 1.873 1.758L15.2 9.6a1 1 0 0 0-.994.883l-.006.117a1 1 0 0 0 .883.993l.117.007H20a1.002 1.002 0 0 0 .307-.048l.016-.005a.996.996 0 0 0 .398-.254l.007-.008.073-.088a1.06 1.06 0 0 1 .109-.182.96.96 0 0 0 .08-.281l.003-.017.004-.046.002-.06v-4.81a1 1 0 0 0-1.993-.118L19 5.8v2.487l-2.006-1.883a8.2 8.2 0 1 0-5.995 14.001V20.4a1 1 0 1 0-.061-1.998ZM20 11.6l.052-.001.045-.004-.098.005Z' clip-rule='evenodd'/>
  </svg>`,
  [QuillElementsType.bold]: `<svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
  <path fill-rule='evenodd' clip-rule='evenodd' d='M8 6C7.44772 6 7 6.44772 7 7V17C7 17.5523 7.44772 18 8 18H13.3333C14.2857 18 15.211 17.6459 15.9029 16.9973C16.5969 16.3466 17 15.4498 17 14.5C17 13.5502 16.5969 12.6534 15.9029 12.0027C15.7236 11.8346 15.5287 11.6864 15.3217 11.5589C15.7587 10.9658 16 10.2463 16 9.5C16 8.56587 15.622 7.67372 14.9552 7.01878C14.2889 6.36445 13.3891 6 12.4545 6H8ZM12.4545 11C12.8702 11 13.2652 10.8377 13.5537 10.5543C13.8416 10.2716 14 9.89195 14 9.5C14 9.10805 13.8416 8.72843 13.5537 8.44568C13.2652 8.16233 12.8702 8 12.4545 8H9V11H12.4545ZM9 13V16H13.3333C13.7954 16 14.2267 15.8273 14.535 15.5382C14.8412 15.2512 15 14.8762 15 14.5C15 14.1238 14.8412 13.7488 14.535 13.4618C14.2267 13.1727 13.7954 13 13.3333 13H9Z' fill='currentColor'/>
  </svg>
  `,
  [QuillElementsType.underline]: `<svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
  <path fill-rule='evenodd' clip-rule='evenodd' d='M9 6C9.55228 6 10 6.44772 10 7V12C10 13.1046 10.8954 14 12 14C13.1046 14 14 13.1046 14 12V7C14 6.44772 14.4477 6 15 6C15.5523 6 16 6.44772 16 7V12C16 14.2091 14.2091 16 12 16C9.79086 16 8 14.2091 8 12V7C8 6.44772 8.44772 6 9 6ZM8 18C8 17.4477 8.44772 17 9 17H15C15.5523 17 16 17.4477 16 18C16 18.5523 15.5523 19 15 19H9C8.44772 19 8 18.5523 8 18Z' fill='currentColor'/>
  </svg>
  `,
  [QuillElementsType.italic]: `<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='currentColor' viewBox='0 0 24 24'>
  <path fill='currentColor' d='M10 7a1 1 0 0 1 1-1h4a1 1 0 1 1 0 2h-1.18l-1.6 8H13a1 1 0 1 1 0 2H9a1 1 0 1 1 0-2h1.18l1.6-8H11a1 1 0 0 1-1-1Z'/>
</svg>
`,
  [QuillElementsType.strike]: `<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='currentColor' viewBox='0 0 24 24'>
  <path fill='currentColor' d='M7 9.5A3.5 3.5 0 0 1 10.5 6H15a1 1 0 1 1 0 2h-4.5a1.5 1.5 0 0 0-1.415 2h-2.05A3.53 3.53 0 0 1 7 9.5Zm7.915 4.5h2.05a3.5 3.5 0 0 1-3.465 4H8a1 1 0 1 1 0-2h5.5a1.5 1.5 0 0 0 1.415-2ZM6 11a1 1 0 1 0 0 2h12a1 1 0 1 0 0-2H6Z'/>
</svg>
`,
  [QuillElementsType.code]: `<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none' viewBox='0 0 24 24'>
  <path fill='currentColor' d='M13.812 5.238a1 1 0 0 0-1.225.708l-3.106 11.59a1 1 0 0 0 1.932.518l3.106-11.59a1 1 0 0 0-.707-1.226ZM8.8 8.6a1 1 0 1 0-1.6-1.2l-3 4a1 1 0 0 0 0 1.2l3 4a1 1 0 1 0 1.6-1.2L6.25 12 8.8 8.6Zm8-1.2a1 1 0 0 0-1.6 1.2l2.55 3.4-2.55 3.4a1 1 0 1 0 1.6 1.2l3-4a1 1 0 0 0 0-1.2l-3-4Z'/>
</svg>
`,
  [QuillElementsType.blockquote]: `<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none' viewBox='0 0 24 24'>
  <path fill='currentColor' fill-rule='evenodd' d='m8.5 13-1.421 2.842c-.192.384-.288.575-.265.731a.5.5 0 0 0 .208.337c.13.09.344.09.772.09h.712c.18 0 .269 0 .348-.027a.5.5 0 0 0 .187-.116c.06-.058.1-.139.18-.3l1.441-2.881c.126-.252.19-.378.234-.51a2.14 2.14 0 0 0 .085-.36c.019-.139.019-.28.019-.561V10c0-.932 0-1.398-.152-1.765a2 2 0 0 0-1.083-1.083C9.398 7 8.932 7 8 7c-.932 0-1.398 0-1.765.152a2 2 0 0 0-1.083 1.083C5 8.602 5 9.068 5 10c0 .932 0 1.398.152 1.765a2 2 0 0 0 1.083 1.083C6.602 13 7.068 13 8 13h.5Zm8 0-1.421 2.842c-.192.384-.288.575-.265.731a.5.5 0 0 0 .208.337c.13.09.344.09.772.09h.712c.18 0 .269 0 .348-.027a.5.5 0 0 0 .187-.116c.06-.058.1-.139.18-.3l1.441-2.881c.126-.252.19-.378.234-.51a2.14 2.14 0 0 0 .085-.36c.019-.139.019-.28.019-.561V10c0-.932 0-1.398-.152-1.765a2 2 0 0 0-1.083-1.083C17.398 7 16.932 7 16 7c-.932 0-1.398 0-1.765.152a2 2 0 0 0-1.083 1.083C13 8.602 13 9.068 13 10c0 .932 0 1.398.152 1.765a2 2 0 0 0 1.083 1.083C14.602 13 15.068 13 16 13h.5Z' clip-rule='evenodd'/>
</svg>
`,
  [QuillElementsType.sub]: `<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='currentColor' viewBox='0 0 24 24'>
<path fill='currentColor' d='M13.555 5.168a1 1 0 0 1 .277 1.387L10.202 12l3.63 5.445a1 1 0 0 1-1.664 1.11L9 13.803l-3.168 4.752a1 1 0 0 1-1.664-1.11L7.798 12l-3.63-5.445a1 1 0 0 1 1.664-1.11L9 10.197l3.168-4.752a1 1 0 0 1 1.387-.277ZM17.98 16c-1.084 0-1.98.747-1.98 1.65v.017h1.334v-.018c0-.303.28-.538.645-.538h.042c.364 0 .646.235.646.538a.498.498 0 0 1-.271.434l-1.417.816c-.61.35-.979.93-.979 1.546V21h4v-1.11h-2.312c.012-.008.016-.021.02-.034.003-.014.007-.028.021-.035l1.417-.816c.536-.309.854-.812.854-1.355 0-.902-.896-1.649-1.979-1.649h-.042Z'/>
</svg>

`,
  [QuillElementsType.super]: `<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='currentColor' viewBox='0 0 24 24'>
<path fill='currentColor' d='M17.98 3C16.895 3 16 3.747 16 4.65v.017h1.334v-.018c0-.304.28-.538.645-.538h.042c.364 0 .646.234.646.538a.498.498 0 0 1-.271.434l-1.417.816c-.61.35-.979.93-.979 1.545V8h4V6.889h-2.312c.012-.008.016-.021.02-.034.003-.014.007-.028.021-.036l1.417-.816c.536-.308.854-.811.854-1.354C20 3.747 19.104 3 18.021 3h-.042Zm-4.425 2.168a1 1 0 0 1 .277 1.387L10.202 12l3.63 5.445a1 1 0 0 1-1.664 1.11L9 13.803l-3.168 4.752a1 1 0 0 1-1.664-1.11L7.798 12l-3.63-5.445a1 1 0 0 1 1.664-1.11L9 10.197l3.168-4.752a1 1 0 0 1 1.387-.277Z'/>
</svg>
`,
  [QuillElementsType.color]: `<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none' viewBox='0 0 24 24'>
<g fill='currentColor'>
  <path fill-rule='evenodd' d='M15.072 15.371 14.523 14H9.477l-.549 1.371a1 1 0 1 1-1.856-.742l3.535-8.84c.503-1.257 2.283-1.257 2.786 0l3.535 8.84a1 1 0 0 1-1.857.742ZM12 7.692 13.723 12h-3.446L12 7.692Z' clip-rule='evenodd'/>
  <path d='M6 18a1 1 0 1 0 0 2h12a1 1 0 1 0 0-2H6Z'/>
</g>
</svg>
`,
  [QuillElementsType.background]: `<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none' viewBox='0 0 24 24'>
<g fill='currentColor'>
  <path d='M13.723 13.654 12 9.346l-1.723 4.308h3.446Z'/>
  <path fill-rule='evenodd' d='M4.218 5.092C4 5.52 4 6.08 4 7.2v9.6c0 1.12 0 1.68.218 2.108a2 2 0 0 0 .874.874C5.52 20 6.08 20 7.2 20h9.6c1.12 0 1.68 0 2.108-.218a2 2 0 0 0 .874-.874C20 18.48 20 17.92 20 16.8V7.2c0-1.12 0-1.68-.218-2.108a2 2 0 0 0-.874-.874C18.48 4 17.92 4 16.8 4H7.2c-1.12 0-1.68 0-2.108.218a2 2 0 0 0-.874.874Zm10.305 10.562.549 1.371a1 1 0 0 0 1.857-.743l-3.536-8.84c-.503-1.256-2.283-1.256-2.786 0l-3.535 8.84a1 1 0 1 0 1.856.743l.55-1.371h5.045Z' clip-rule='evenodd'/>
</g>
</svg>
`,
  [QuillElementsType.clean]: `<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none' viewBox='0 0 24 24'>
<path fill='currentColor' fill-rule='evenodd' d='M4.732 12.696a2.5 2.5 0 0 0 0 3.536l2.036 2.035A2.5 2.5 0 0 0 8.536 19h9.985a1 1 0 1 0 0-2h-3.95l3.596-3.597a2.5 2.5 0 0 0 0-3.535l-3.535-3.536a2.5 2.5 0 0 0-3.536 0l-6.364 6.364Zm8.839 2.475L11.743 17H8.536a.5.5 0 0 1-.354-.147l-2.036-2.035a.5.5 0 0 1 0-.707l3.182-3.182 4.243 4.242Z' clip-rule='evenodd'/>
</svg>
`,
  [QuillElementsType.indent]: `<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none' viewBox='0 0 24 24'>
<path fill='currentColor' d='M20 6a1 1 0 0 0-1-1H5a1 1 0 0 0 0 2h14a1 1 0 0 0 1-1ZM5 17a1 1 0 0 0 0 2h14a1 1 0 0 0 0-2H5Zm5-3a1 1 0 0 1 1-1h8a1 1 0 0 1 0 2h-8a1 1 0 0 1-1-1Zm1-5a1 1 0 0 0 0 2h8a1 1 0 0 0 0-2h-8Zm-7 .207v5.586a.5.5 0 0 0 .854.353l2.792-2.792a.5.5 0 0 0 0-.708L4.854 8.854A.5.5 0 0 0 4 9.207Z'/>
</svg>
`,
  [QuillElementsType.outdent]: `<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none' viewBox='0 0 24 24'>
  <path fill='currentColor' fill-rule='evenodd' d='M20 6a1 1 0 0 0-1-1H5a1 1 0 0 0 0 2h14a1 1 0 0 0 1-1ZM5 17a1 1 0 0 0 0 2h14a1 1 0 0 0 0-2H5Zm5-3a1 1 0 0 1 1-1h8a1 1 0 0 1 0 2h-8a1 1 0 0 1-1-1Zm1-5a1 1 0 0 0 0 2h8a1 1 0 0 0 0-2h-8Zm-3 .207v5.586a.5.5 0 0 1-.854.353l-2.792-2.793a.5.5 0 0 1 0-.707l2.792-2.793A.5.5 0 0 1 8 9.207Z' clip-rule='evenodd'/>
</svg>`,
  [QuillElementsType.link]: `<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none' viewBox='0 0 24 24'>
<path fill='currentColor' fill-rule='evenodd' d='M13.914 14.35a1 1 0 0 1 .078 1.328l-.078.087L12.5 17.18c-2.03 2.03-4.575 2.496-6.364.707-1.737-1.737-1.348-4.189.537-6.188l.17-.175 1.414-1.415a1 1 0 0 1 1.492 1.327l-.077.088-1.415 1.414c-1.336 1.336-1.579 2.663-.707 3.535.838.838 2.094.647 3.376-.552l.16-.155 1.414-1.414a1 1 0 0 1 1.414 0Zm1.415-5.656a1 1 0 0 1 .077 1.327l-.078.087-4.242 4.243a1 1 0 0 1-1.492-1.327l.078-.087 4.242-4.243a1 1 0 0 1 1.415 0Zm3.535-3.535c1.738 1.737 1.348 4.189-.537 6.188l-.17.176-1.414 1.414a1 1 0 0 1-1.492-1.327l.078-.087 1.414-1.415c1.336-1.336 1.58-2.663.707-3.535-.837-.838-2.094-.647-3.375.552l-.16.155L12.5 8.694a1 1 0 0 1-1.492-1.327l.078-.087L12.5 5.866c2.03-2.03 4.576-2.496 6.364-.707Z' clip-rule='evenodd'/>
</svg>
`,
  [QuillElementsType.image]: `<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none' viewBox='0 0 24 24'>
  <path fill='currentColor' fill-rule='evenodd' d='M18 5a3 3 0 0 1 3 3v8a3 3 0 0 1-3 3H6a3 3 0 0 1-3-3V8a3 3 0 0 1 3-3h12ZM7.61 11.943l-.097.075L5 14.239V16a1 1 0 0 0 .883.993L6 17h7.647l-4.752-4.927a1 1 0 0 0-1.286-.13Zm7.665 3.029-.093.079-.33.317L16.425 17H18a.995.995 0 0 0 .473-.119l-1.905-1.83a1 1 0 0 0-1.293-.08ZM18 7H6a1 1 0 0 0-1 1v3.569l1.188-1.05a3 3 0 0 1 4.01.032l.137.134 3.128 3.243.334-.32a3 3 0 0 1 4.013-.128l.143.128L19 14.614V8a1 1 0 0 0-.883-.993L18 7Z' clip-rule='evenodd'/>
</svg>
`,
  [QuillElementsType.video]: `<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none' viewBox='0 0 24 24'>
<path fill='currentColor' fill-rule='evenodd' d='M12.838 6H7.162c-.527 0-.981 0-1.356.03-.395.033-.789.104-1.167.297a3 3 0 0 0-1.311 1.311c-.193.378-.264.772-.296 1.167C3 9.18 3 9.635 3 10.161v3.678c0 .527 0 .981.03 1.356.033.395.104.789.297 1.167a3 3 0 0 0 1.311 1.311c.378.193.772.264 1.167.296.375.031.83.031 1.356.031h5.678c.527 0 .981 0 1.356-.03.395-.033.789-.104 1.167-.297a3 3 0 0 0 1.311-1.311c.193-.378.264-.772.296-1.167a5.6 5.6 0 0 0 .007-.089l2.577 1.288A1 1 0 0 0 21 15.5v-7a1 1 0 0 0-1.447-.894l-2.577 1.288a5.647 5.647 0 0 0-.007-.089c-.032-.395-.103-.789-.296-1.167a3 3 0 0 0-1.311-1.311c-.378-.193-.772-.264-1.167-.296A17.9 17.9 0 0 0 12.838 6ZM17 11.118v1.764l2 1v-3.764l-2 1Zm-2-.62V10.2c0-.577 0-.949-.024-1.232-.022-.272-.06-.373-.085-.422a1 1 0 0 0-.437-.437c-.05-.025-.15-.063-.422-.085C13.75 8 13.377 8 12.8 8H7.2c-.577 0-.949 0-1.232.024-.272.022-.373.06-.422.085a1 1 0 0 0-.437.437c-.025.05-.063.15-.085.422C5 9.25 5 9.623 5 10.2v3.6c0 .577 0 .949.024 1.232.022.272.06.373.085.422a1 1 0 0 0 .437.437c.05.025.15.063.422.085C6.25 16 6.623 16 7.2 16h5.6c.577 0 .949 0 1.232-.024.272-.022.373-.06.422-.085a1 1 0 0 0 .437-.437c.025-.05.063-.15.085-.422C15 14.75 15 14.377 15 13.8v-3.302Z' clip-rule='evenodd'/>
</svg>
`,
  [QuillElementsType.formula]: `<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none' viewBox='0 0 24 24'>
<path fill='currentColor' fill-rule='evenodd' d='M6.8 8.6C5.262 7.447 6.078 5 8 5h8a2 2 0 0 1 2 2v2a1 1 0 1 1-2 0V7H8l4.533 3.4a2 2 0 0 1 0 3.2L8 17h8v-2a1 1 0 1 1 2 0v2a2 2 0 0 1-2 2H8c-1.922 0-2.738-2.447-1.2-3.6l4.533-3.4L6.8 8.6Z' clip-rule='evenodd'/>
</svg>
`,
  [QuillElementsType.align]: `<svg fill='none' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'>
<path d='M5 5C4.44772 5 4 5.44772 4 6C4 6.55228 4.44772 7 5 7H19C19.5523 7 20 6.55228 20 6C20 5.44772 19.5523 5 19 5H5Z' fill='currentColor'/>
<path d='M14 18C14 17.448 13.552 17 13 17H5C4.448 17 4 17.448 4 18C4 18.552 4.448 19 5 19H13C13.552 19 14 18.552 14 18Z' fill='currentColor'/>
<path d='M20 14C20 13.448 19.552 13 19 13H5C4.448 13 4 13.448 4 14C4 14.552 4.448 15 5 15H19C19.552 15 20 14.552 20 14Z' fill='currentColor'/>
<path d='M20 10C20 9.448 19.552 9 19 9H5C4.448 9 4 9.448 4 10C4 10.552 4.448 11 5 11H19C19.552 11 20 10.552 20 10Z' fill='currentColor'/>
</svg>`,
  [QuillElementsType.center]: `<svg fill='none' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'>
<path d='M5 5C4.44772 5 4 5.44772 4 6C4 6.55228 4.44772 7 5 7H19C19.5523 7 20 6.55228 20 6C20 5.44772 19.5523 5 19 5H5Z' fill='currentColor'/>
<path d='M16 18C16 17.448 15.552 17 15 17H9C8.448 17 8 17.448 8 18C8 18.552 8.448 19 9 19H15C15.552 19 16 18.552 16 18Z' fill='currentColor'/>
<path d='M20 14C20 13.448 19.552 13 19 13H5C4.448 13 4 13.448 4 14C4 14.552 4.448 15 5 15H19C19.552 15 20 14.552 20 14Z' fill='currentColor'/>
<path d='M20 10C20 9.448 19.552 9 19 9H5C4.448 9 4 9.448 4 10C4 10.552 4.448 11 5 11H19C19.552 11 20 10.552 20 10Z' fill='currentColor'/>
</svg>
`,
  [QuillElementsType.justify]: `<svg fill='none' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'>
    <path d='M19 5C19.5523 5 20 5.44772 20 6C20 6.55228 19.5523 7 19 7H5C4.44772 7 4 6.55228 4 6C4 5.44772 4.44772 5 5 5H19Z'
          fill='currentColor'/>
    <path d='M4 18C4 17.448 4.448 17 5 17H19C19.552 17 20 17.448 20 18C20 18.552 19.552 19 19 19H5C4.448 19 4 18.552 4 18Z'
          fill='currentColor'/>
    <path d='M4 14C4 13.448 4.448 13 5 13H19C19.552 13 20 13.448 20 14C20 14.552 19.552 15 19 15H5C4.448 15 4 14.552 4 14Z'
          fill='currentColor'/>
    <path d='M4 10C4 9.448 4.448 9 5 9H19C19.552 9 20 9.448 20 10C20 10.552 19.552 11 19 11H5C4.448 11 4 10.552 4 10Z'
          fill='currentColor'/>
</svg>
`,
  [QuillElementsType.right]: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M19 5C19.5523 5 20 5.44772 20 6C20 6.55228 19.5523 7 19 7H5C4.44772 7 4 6.55228 4 6C4 5.44772 4.44772 5 5 5H19Z"
          fill="currentColor"/>
    <path d="M10 18C10 17.448 10.448 17 11 17H19C19.552 17 20 17.448 20 18C20 18.552 19.552 19 19 19H11C10.448 19 10 18.552 10 18Z"
          fill="currentColor"/>
    <path d="M4 14C4 13.448 4.448 13 5 13H19C19.552 13 20 13.448 20 14C20 14.552 19.552 15 19 15H5C4.448 15 4 14.552 4 14Z"
          fill="currentColor"/>
    <path d="M4 10C4 9.448 4.448 9 5 9H19C19.552 9 20 9.448 20 10C20 10.552 19.552 11 19 11H5C4.448 11 4 10.552 4 10Z"
          fill="currentColor"/>
</svg>
`
};
