import { ReactNode } from 'react';

import { FieldIcons } from './FieldIcons';
import { FieldTypes } from './store/FieldState';

export interface IFieldSettings {
  label: ReactNode;
  icon: any;
  showIcon: boolean;
  value: FieldTypes[];
  placeholder: string;
}

export const FieldTypesLabels: IFieldSettings[] = [
  {
    label: 'Text',
    icon: <FieldIcons.IconText />,
    showIcon: true,
    placeholder: 'Short text',
    value: ['short_text']
  },
  {
    label: 'Text',
    icon: <FieldIcons.IconText />,
    showIcon: true,
    placeholder: 'Long text',
    value: ['long_text']
  },
  {
    label: 'Numeric',
    icon: <FieldIcons.IconHashtag />,
    showIcon: true,
    placeholder: 'Numeric',
    value: ['numeric']
  },
  {
    label: 'Toggle switch',
    icon: <FieldIcons.IconToggle />,
    showIcon: false,
    placeholder: 'Toggle switch',
    value: ['toggle']
  },
  {
    label: 'Single select',
    icon: <FieldIcons.IconSingleSelect />,
    showIcon: false,
    placeholder: 'Single select',
    value: ['select']
  },
  {
    label: 'Multi select',
    icon: <FieldIcons.IconMultiSelect />,
    showIcon: false,
    placeholder: 'Multi select',
    value: ['multiselect']
  },
  {
    label: 'Date and time',
    icon: <FieldIcons.IconDateTime />,
    showIcon: true,
    placeholder: 'Date',
    value: ['date']
  },
  {
    label: 'Date and time',
    icon: <FieldIcons.IconDateTime />,
    showIcon: true,
    placeholder: 'Date and time',
    value: ['date_time']
  },
  {
    label: 'URL',
    icon: <FieldIcons.IconLink />,
    showIcon: true,
    placeholder: 'URL',
    value: ['url']
  },
  {
    label: 'Address',
    icon: <FieldIcons.IconPin />,
    showIcon: true,
    placeholder: 'Address',
    value: ['address']
  },
  {
    label: 'Address',
    icon: <FieldIcons.IconPin />,
    showIcon: true,
    placeholder: 'Short address',
    value: ['short_address']
  },
  {
    label: 'Tax ID',
    icon: <FieldIcons.IconHashtagTax />,
    showIcon: true,
    placeholder: 'Tax ID',
    value: ['tax_id']
  }
];
