import { memo } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import classNames from 'classnames';
import styles from './restrict-lesson.module.scss';
import { Icon32Warning } from '../../../../components/UI/icons';

import { Button, CommonModal } from '../../../../components/UI';
import { useViewCourseContext } from '../../../lms/students/student-course/ViewCourseContext';
import { StudentCabinetPaths } from '../../routes/StudentCabinetRoutes';
import { NavigationActions } from '../../store/NavigationActions';

interface IProps {
  show: boolean;
  onClose: () => void;
  title?: string;
}

export const RestrictAccess = memo(({ onClose, show, title }: IProps) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { course } = useViewCourseContext();

  const onReturnToCourses = () => {
    dispatch(NavigationActions.deleteLastNode({courseId: course.id}));
    history.push(StudentCabinetPaths.courses);
  };

  return (
    <CommonModal onClose={onClose} show={show} params={{ backdrop: 'static' }}>
      <div className={'p-5 mt-2'}>
        <div className={'d-flex align-items-center'}>
          <Icon32Warning className={styles.icon} />
          <div className={styles.chapter}>
            The author has restricted access to the <span className={styles.title}>{title}</span> lesson
          </div>
        </div>
        <div className={classNames(styles.description, 'px-6')}>
          Perhaps access to the lesson is closed due to content update reasons. You can write to the author to find out
          for sure.
        </div>
        <Button onClick={onReturnToCourses} withIcon={false} className={'ml-auto mt-3'}>
          Go to my courses
        </Button>
      </div>
    </CommonModal>
  );
});
