import { FC, useEffect, useState } from 'react';

import { ICourseNode } from '../../../../../courses/store/CourseState';
import { Breadcrumbs } from '../../../breadcrumps/Breadcrumbs';
import { getPathToQuizNode } from '../../helpers/getPathToQuizNode';

interface IProps {
  hierarchy?: ICourseNode;
  className?: string;
  innerClass?: string;
  maxLengthItems?: number;
}

export const QuizLocation: FC<IProps> = ({ hierarchy, className, innerClass, maxLengthItems, children }) => {
  const [treeNodes, setTreeNodes] = useState<ICourseNode[]>([]);

  useEffect(() => {
    if (hierarchy?.id) {
      const quizBlock = getPathToQuizNode(hierarchy);
      if (quizBlock) {
        setTreeNodes(quizBlock);
      }
    }
  }, [hierarchy, setTreeNodes]);

  return (
    <div className={className}>
      <Breadcrumbs maxLengthItems={maxLengthItems} tree={treeNodes} className={innerClass} />
      {children}
    </div>
  );
};
