import { useFormContext } from 'react-hook-form';
import TextareaAutosize from 'react-textarea-autosize';

import classnames from 'classnames';
import styles from './textmulti.module.scss';

import { ModifyField } from '../../../../modules/crm/field/store/FieldSelector';

interface IProps {
  field: ModifyField;
  maxLength?: number;
}

export const TextMultiField = ({ field, maxLength }: IProps) => {
  const {
    register,
    formState: { errors }
  } = useFormContext();

  return (
    <TextareaAutosize
      minRows={1}
      maxRows={4}
      className={classnames('form-control form-control-underline', styles.container, {
        'is-invalid': !!errors[field.id]
      })}
      placeholder={field.payload && field.payload.title}
      {...register(field.id)}
      maxLength={maxLength}
    />
  );
};
