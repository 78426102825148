import actionCreatorFactory from 'typescript-fsa';

export const actionCreator = actionCreatorFactory();

export const settingsActions = {
  setZoomValue: actionCreator<{ value: number; isDefault: boolean }>('SET_ZOOM_VALUE'),
  commonSearch: actionCreator<{ searchString: string }>('COMMON_SEARCH'),
  setSidebarShowing: actionCreator<{ isSidebarShow: boolean }>('SET_SIDEBAR_SHOWING'),
  setPayloadShowing: actionCreator<{ isPayloadShow: boolean }>('SET_PAYLOAD_SHOWING'),
  setCommonPayloadShowing: actionCreator<{ key: string, isPayloadShow: boolean }>('SET_COMMON_PAYLOAD_SHOWING'),
  setMainListId: actionCreator<{ mainListId: string }>('SET_MAIN_LIST_ID'),
  setSidebarWidth: actionCreator<{ sidebarWidth: number }>('SET_SIDEBAR_WIDTH')
};
